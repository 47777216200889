import React from 'react';

import Brush from './Brush';
import ThumbChart from './ThumbChart';
import AxisBottom from './AxisBottom';
import { getX } from '../../../core/common/reportChart';
import { Todo, TodoFunction } from '../../../../../common/types/common';

interface Props {
  data?: Todo[];
  frameWidth?: number;
  sliderHeight?: number;
  translate: string;
  selection: Todo[];
  changeSelection: TodoFunction;
  id: string;
  getY: TodoFunction;
  yScale: TodoFunction;
  xScale: TodoFunction;
}

export const Slider: React.FC<Props> = ({
  data = [],
  changeSelection,
  selection,
  id,
  getY,
  yScale,
  xScale,
  frameWidth = 200,
  sliderHeight = 100,
  translate,
}) => (
  <svg style={{ width: '100%', height: '100%' }}>
    <g transform={translate}>
      <rect
        width={frameWidth}
        height={sliderHeight}
        className="chart-border-rect chart-fill-rect"
      />
      <ThumbChart
        data={data}
        xScale={xScale}
        yScale={yScale}
        getX={getX}
        getY={getY}
      />
      <g transform={`translate(0, ${sliderHeight})`}>
        <AxisBottom
          scale={xScale}
          height={sliderHeight}
        />
      </g>
      <g transform="translate(1, 1)">
        <Brush
          xScale={xScale}
          height={sliderHeight - 2}
          width={frameWidth - 2}
          changeSelection={changeSelection}
          id={id}
          selection={selection}
        />
      </g>
    </g>
  </svg>
);

export default Slider;
