import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import UserForm from './UserForm';
import { ADMIN_USER_FORM_NAME } from '../../../../../../../redux/constants/admin.user.form.constants';
import * as selector from '../../../../../../../redux/selectors/admin.users.selector';
import { adminSelectUserFormTab } from '../../../../../../../redux/modules/admin.user.tabs.module';

export const mapStateToProps = (state) => ({
  tab: selector.getTab(state),
});

export const mapDispatchToProps = {
  setTab: adminSelectUserFormTab,
};

const form = {
  form: ADMIN_USER_FORM_NAME,
  destroyOnUnmount: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(form)(UserForm));
