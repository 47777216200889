import { cloneDeep } from 'lodash';
import { createAction } from '@reduxjs/toolkit';
import { put, call, takeEvery } from 'redux-saga/effects';
import * as api from '../../core/api';
import { searchInitialState } from '../state/search.state';

export const searchDevices = createAction('search devices', (id: string) => ({ payload: id }));

export const searchDevicesSuccess = createAction('search Devices - success', (results) => ({
  payload: results,
}));

export const searchDevicesFail = createAction('search Devices - fail', (error) => ({
  payload: error,
}));

export const resetDevices = createAction('reset Devices');

export const reducer = {
  [searchDevices.type]: (state) => ({
    ...state,
    search: {
      ...state.search,
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [searchDevicesSuccess.type]: (state, devices) => ({
    ...state,
    search: {
      ...state.search,
      loading: false,
      loaded: true,
      results: {
        ...state.search.results,
        devices,
      },
    },
  }),

  [searchDevicesFail.type]: (state, error) => ({
    ...state,
    search: {
      ...state.search,
      loaded: false,
      loading: false,
      error,
    },
  }),

  [resetDevices.type]: (state) => ({
    ...state,
    search: cloneDeep(searchInitialState),
  }),
};

export function* searchDevicesSaga({ payload: id }) {
  const { response, error } = yield call(api.datatrons.getDevices, id);
  if (response) {
    yield put(searchDevicesSuccess(response));
  } else {
    yield put(searchDevicesFail(error));
  }
}

export function* watchSearchDevices() {
  yield takeEvery(searchDevices, searchDevicesSaga);
}
