import React from 'react';
import PropTypes from 'prop-types';
import { last } from 'lodash';
import { max } from 'd3-array';
import { stack, line } from 'd3-shape';
import { scaleLinear, scaleOrdinal, scaleBand } from 'd3-scale';
import { getX, windowsKeys } from '../../../../core/common/event';

export const isPointAboveThreshold = (point) => point.data.aboveThreshold;
export const isLatest = (point) => point.isLatest;

function WindowChart({
  data, threshold, width, height,
}) {
  const margin = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const translate = `translate(${margin.left}, ${margin.top})`;
  const keys = windowsKeys;

  const stacked = stack().keys(keys);
  const stackedData = stacked(data);

  const zScaleBlue = scaleOrdinal()
    .range(['#8FD3F0', '#65BDE3', '#42A7D2', '#228DBC'])
    .domain(keys);

  const zScaleRed = scaleOrdinal()
    .range(['#FF6868', '#F34343', '#E31616', '#AD0A0A'])
    .domain(keys);

  const xScale = scaleBand()
    .rangeRound([0, chartWidth])
    .padding(0.1)
    .domain(data.map((point) => point.timestamp));

  const yScale = scaleLinear()
    .rangeRound([chartHeight, 0])
    .domain([0, max(data, (p) => p.total)]);

  const getDataSeries = (d) => xScale(getX(d.data));
  const getYTopLine = (d) => yScale(d[1]);
  const getYDiff = (d) => yScale(d[0]) - yScale(d[1]);

  // threshold
  const thresholdLine = line()
    .x((d) => xScale(getX(d)) + (isLatest(d) ? xScale.bandwidth() : 0))
    .y(() => yScale(threshold))(data);

  const thresholdValueX = xScale(getX(last(data))) + (xScale.bandwidth() / 2);

  return (
    <svg style={{ width: '100%', height: '100%' }}>
      <g transform={translate}>
        <g>
          {
            stackedData.map((bar) => (
              <g
                key={bar.index}
              >
                {
                bar.map((point) => (
                  <rect
                    key={bar.key + getX(point.data)}
                    x={getDataSeries(point)}
                    y={getYTopLine(point)}
                    height={getYDiff(point)}
                    width={xScale.bandwidth()}
                    fill={isPointAboveThreshold(point) ? zScaleRed(bar.key) : zScaleBlue(bar.key)}
                  />
                ))
              }
              </g>
            ))
          }
        </g>
        <path
          d={thresholdLine}
          className="anomaly-chart-threshold"
        />
        <text
          className="nomaly-chart-text"
          x={thresholdValueX}
          y={yScale(threshold) - 3}
        >
          { threshold }
        </text>
        <text
          className="nomaly-chart-label"
          transform="rotate(270 24 11)"
        >
          VALUE
        </text>
      </g>
    </svg>
  );
}

WindowChart.propTypes = {
  data: PropTypes.array.isRequired,
  threshold: PropTypes.number.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default WindowChart;
