import { defineMessages } from 'react-intl';

export default defineMessages({
  machineName: {
    id: 'newMachine.machine_name',
    defaultMessage: 'Machine Name',
  },
  machineNamePlaceholder: {
    id: 'newMachine.machine_name_placeholder',
    defaultMessage: 'Type machine name here...',
  },
  machineType: {
    id: 'newMachine.machine_type',
    defaultMessage: 'Machine Type',
  },
  machineTypePlaceholder: {
    id: 'newMachine.machine_type_placeholder',
    defaultMessage: 'Type machine type here...',
  },
  model: {
    id: 'newMachine.model',
    defaultMessage: 'Model',
  },
  modelPlaceholder: {
    id: 'newMachine.model_placeholder',
    defaultMessage: 'Type model here...',
  },
  vendor: {
    id: 'newMachine.vendor',
    defaultMessage: 'Vendor',
  },
  vendorPlaceholder: {
    id: 'newMachine.vendor_placeholder',
    defaultMessage: 'Type vendor here...',
  },
  year: {
    id: 'newMachine.year',
    defaultMessage: 'Year',
  },
  yearPlaceholder: {
    id: 'newMachine.year_placeholder',
    defaultMessage: 'Year',
  },
  serialNumber: {
    id: 'newMachine.serial_number',
    defaultMessage: 'Serial Number',
  },
  serialNumberPlaceholder: {
    id: 'newMachine.serial_number_placeholder',
    defaultMessage: 'Type serial number here...',
  },
  maxPerformance: {
    id: 'newMachine.max_performance',
    defaultMessage: 'Max Performance',
  },
  plannedPerformance: {
    id: 'newMachine.planned_performance',
    defaultMessage: 'Planned Performance',
  },
  cycleSecondsPerPart: {
    id: 'newMachine.cycle_seconds_per_part',
    defaultMessage: 'Cycle Time (seconds per part)',
  },
  selectTime: {
    id: 'newMachine.selectTime',
    defaultMessage: 'Select Time',
  },
  notes: {
    id: 'newMachine.notes',
    defaultMessage: 'Notes',
  },
  notesPlaceholder: {
    id: 'newMachine.notes_placeholder',
    defaultMessage: 'Type some text here...',
  },
  cycleTimePlaceholder: {
    id: 'newMachine.cycle_time_per_part.placeholder',
    defaultMessage: 'Type cycle time here...',
  },
  maxPerformancePlaceholder: {
    id: 'newMachine.max_performance.placeholder',
    defaultMessage: 'Type max performance here..',
  },
  plannedPerformancePlaceholder: {
    id: 'newMachine.planned_performance.placeholder',
    defaultMessage: 'Type planned performance here...',
  },
  oeeSource: {
    id: 'machinePageForm.oeeSource.title',
    defaultMessage: 'OEE Source',
  },
  oeeThresholdGreen: {
    id: 'machinePageForm.oeeThresholdGreen.title',
    defaultMessage: 'Green',
  },
  oeeThresholdYellow: {
    id: 'machinePageForm.oeeThresholdYellow.title',
    defaultMessage: 'Yellow',
  },
  oeeThresholdRed: {
    id: 'machinePageForm.oeeThresholdRed.title',
    defaultMessage: 'Red',
  },
  dmcConfig: {
    id: 'machinePageForm.dmcConfig.title',
    defaultMessage: 'DMC Pattern',
  },
  dmcConfigPlaceholder: {
    id: 'machinePageForm.dmcConfig.placeholder',
    defaultMessage: 'Paste JSON here...',
  },
  useDmc: {
    id: 'machinePageForm.dmcFlag.title',
    defaultMessage: 'Use DMC for Performance',
  },
});
