import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import * as api from '../../core/api';
import { REPORTS_TAB_REMOVE } from '../constants/modals.constants';
import { closeModal } from './modals.module';
import { removeObjectFromList, compareById } from '../../../../common/helpers/object';
import { selectReportsTab } from './reportsTabs.select.module';
import { getNearbyTabIds } from '../selectors/reportsTabs.selector';
import * as form from '../constants/reportsTab.remove.form.constants';

export const removeReportsTab = createAction(
  'remove reports tab',
  (tabId) => tabId,
);

export const removeReportsTabSuccess = createAction(
  'remove reports tab - success',
  (tabId) => tabId,
);

export const removeReportsTabFail = createAction(
  'remove reports tab - fail',
  (error) => error,
);

export const reducer = {
  [removeReportsTabSuccess]: (state, tabId) => ({
    ...state,
    reports: {
      ...state.reports,
      tabs: {
        ...state.reports.tabs,
        list: removeObjectFromList(
          state.reports.tabs.list,
          { id: tabId },
          compareById,
        ),
      },
    },
  }),
};

export function* removeReportsTabSaga({ payload: id }) {
  yield put(startSubmit(form.REMOVE_REPORTS_TAB_FORM_NAME));
  const { response, error } = yield call(api.reports.removeTab, id);

  if (response) {
    const state = yield select();
    const [, nextTabId] = yield call(getNearbyTabIds, state);
    yield put(removeReportsTabSuccess(id));
    yield put(closeModal(REPORTS_TAB_REMOVE));
    yield put(selectReportsTab(nextTabId === id ? null : nextTabId));
    yield put(stopSubmit(form.REMOVE_REPORTS_TAB_FORM_NAME));
  } else {
    yield put(removeReportsTabFail(error));
    yield put(stopSubmit(form.REMOVE_REPORTS_TAB_FORM_NAME, error));
  }
}

export function* watchRemoveReportsTab() {
  yield takeEvery(removeReportsTab.getType(), removeReportsTabSaga);
}
