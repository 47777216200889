import { createAction } from 'redux-act';

export const showHideCalendar = createAction(
  'show/hide calendar panel',
);

export const reducer = {
  [showHideCalendar]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      showCalendar: !state.dashboard.showCalendar,
    },
  }),
};
