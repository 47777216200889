import { connect } from 'react-redux';

import RolesModule from './RolesModule';
import * as selector from '../../../../redux/selectors/admin.roles.selector';

export const mapStateToProps = (state) => ({
  list: selector.getRoles(state),
});

export default connect(mapStateToProps)(RolesModule);
