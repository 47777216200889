import { createAction } from 'redux-act';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import {
  put, call, select, takeLatest,
} from 'redux-saga/effects';

import { animateScroll } from 'react-scroll/modules';
import {
  CLONE_CALENDAR_FORM_NAME,
  CLONE_CALENDAR_PROPS,
} from '../constants/calendars.clone.form.constants';
import * as api from '../../core/api';
import { getFormFields } from '../selectors/calendars.clone.selector';
import { getDefaultCalendarEventsDates, initializeCalendar } from '../../core/common/calendar';
import { fetchCalendarEvents } from './calendars.fetch.calendarEvents.module';
import { resetFilterQueries } from './calendars.query.module';
import { closeModal } from './modals.module';
import { CP_CALENDAR_CLONE } from '../constants/modals.constants';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';

export const cloneCalendar = createAction(
  'clone calendar',
  (calendarId) => ({ calendarId }),
);

export const cloneCalendarSuccess = createAction(
  'clone calendar - success',
  (calendar) => calendar,
);

export const cloneCalendarFail = createAction(
  'clone calendar - fail',
  (error) => error,
);

export const reducer = {
  [cloneCalendar]: (state) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      cloneCalendar: {
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),

  [cloneCalendarSuccess]: (state, calendar) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: [
        calendar,
        ...state.machineCalendars.list,
      ],
      cloneCalendar: {
        loading: false,
        loaded: true,
        error: null,
      },
    },
  }),
  [cloneCalendarFail]: (state, error) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      cloneCalendar: {
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    CLONE_CALENDAR_FORM_NAME,
    ...CLONE_CALENDAR_PROPS,
  ));
}

export function* getFields() {
  const state = yield select();
  return yield call(getFormFields, state);
}

export function* cloneCalendarSaga({ payload: { calendarId } }) {
  const name = yield call(getFields);
  yield call(touchAllFieldsSaga);
  yield put(startSubmit(CLONE_CALENDAR_FORM_NAME));

  const { response, error } = yield call(api.calendars.cloneCalendar, calendarId, { name });

  if (response) {
    const calendar = initializeCalendar(response);
    yield put(cloneCalendarSuccess(calendar));
    const { fromDate, toDate } = yield getDefaultCalendarEventsDates();
    yield put(fetchCalendarEvents({ calendar, fromDate, toDate }));
    yield put(stopSubmit(CLONE_CALENDAR_FORM_NAME));
    yield put(closeModal(CP_CALENDAR_CLONE));
    yield call(animateScroll.scrollToTop);
    yield put(sendNotification({ ...notificationMessage.calendar_created, values: { name: response.name } }));
    yield put(resetFilterQueries());
  } else {
    yield put(cloneCalendarFail(error));
    yield put(stopSubmit(
      CLONE_CALENDAR_FORM_NAME,
      error,
    ));
  }
}

export function* watchCloneCalendar() {
  yield takeLatest(cloneCalendar.getType(), cloneCalendarSaga);
}
