import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import {
  replaceObjectInList,
  compareById,
} from '../../../../common/helpers/object';
import * as api from '../../core/api';
import { getReportById, selectAllReports } from '../selectors/reports.selector';
import { initializeReport } from '../../core/common/report';

export const fetchReportsEntry = createAction(
  'fetch reports entry by id',
  (id) => id,
);

export const fetchReportsEntrySuccess = createAction(
  'fetch reports entry by id - success',
  (report) => report,
);

export const fetchReportsEntryFail = createAction(
  'fetch reports entry by id - fail',
  (id, error) => ({ id, error }),
);

export const reducer = {
  [fetchReportsEntry]: (state, id) => {
    const report = getReportById(id, selectAllReports(state));
    if (!report) return state;

    const newReport = {
      ...report,
      _fetch: {
        ...report._fetch,
        loading: true,
        loaded: false,
        error: null,
      },
    };

    return {
      ...state,
      reports: {
        ...state.reports,
        data: replaceObjectInList(
          state.reports.data,
          newReport,
          compareById,
        ),
      },
    };
  },
  [fetchReportsEntrySuccess]: (state, report) => ({
    ...state,
    reports: {
      ...state.reports,
      data: replaceObjectInList(
        state.reports.data,
        {
          ...report,
          _fetch: {
            ...report._fetch,
            loading: false,
            loaded: true,
            error: null,
          },
        },
        compareById,
      ),
    },
  }),
  [fetchReportsEntryFail]: (state, { id, error }) => {
    const report = getReportById(id, selectAllReports(state));
    if (!report) return state;

    const newReport = {
      ...report,
      _fetch: {
        ...report._fetch,
        loading: false,
        loaded: false,
        error,
      },
    };

    return {
      ...state,
      reports: {
        ...state.reports,
        data: replaceObjectInList(
          state.reports.data,
          newReport,
          compareById,
        ),
      },
    };
  },
};

export function* fetchReportsEntrySaga({ payload: id }) {
  const { response, error } = yield call(api.reports.fetchOne, id);

  if (response) {
    yield put(fetchReportsEntrySuccess(initializeReport(response)));
  } else {
    yield put(fetchReportsEntryFail(id, error));
  }
}

export function* watchFetchReportsEntry() {
  yield takeEvery(fetchReportsEntry.getType(), fetchReportsEntrySaga);
}
