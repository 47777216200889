import React from 'react';
import cn from 'classnames';

import DataPointRow from './dataPointTableRow/DataPointRow';
import { Todo, TodoFunction } from '../../../../../../../../../../common/types/common';
import { dimensionsAreValid } from '../../../../../../../modals/datatronPage/dataPointForm/helpers';

interface Props {
  isHighlighted: boolean;
  readOnly: boolean;
  configFields: Todo[];
  openEditDataPointModal: TodoFunction;
  openArchiveDataPointModal: TodoFunction;
  openViewDataPointModal: TodoFunction;
  openEditArrayIndexModal: TodoFunction;
  canUpdate: boolean;
  canDelete: boolean;
  dataPoint: Todo;
  searchQuery?: string;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
}

export const DataPoint: React.FC<Props> = ({
  configFields,
  isHighlighted,
  openEditDataPointModal,
  openArchiveDataPointModal,
  openViewDataPointModal,
  readOnly,
  canUpdate,
  canDelete,
  dataPoint,
  searchQuery,
  openEditArrayIndexModal,
  ...otherProps
}) => {
  const styles = cn('data-points-line', { highlight: isHighlighted });

  // check if this is valid and if indices are not yet defined
  const isArrayType = dimensionsAreValid(dataPoint?.config?.arrayDimensions);

  return (
    <div className={styles}>
      <DataPointRow
        dataPoint={dataPoint}
        configFields={configFields}
        onEditClick={openEditDataPointModal}
        onArchiveClick={openArchiveDataPointModal}
        onViewClick={openViewDataPointModal}
        onIndexClick={openEditArrayIndexModal}
        isArrayType={isArrayType}
        readOnly={readOnly}
        canUpdate={canUpdate}
        canDelete={canDelete}
        searchQuery={searchQuery}
        {...otherProps}
      />
    </div>
  );
};

export default DataPoint;
