import { createAction } from '@reduxjs/toolkit';

export const changeSelection = createAction(
  'change slider selection for report',
  (domain, reportId) => ({ payload: { domain, reportId } }),
);

export const reset = createAction(
  'reset reports slider selection',
);

export const reducer = {
  [changeSelection.type]: (state, { domain, reportId }) => ({
    ...state,
    reports: {
      ...state.reports,
      sliderSelection: {
        ...state.reports.sliderSelection,
        [reportId]: domain,
      },
    },
  }),

  [reset.type]: (state) => ({
    ...state,
    reports: {
      ...state.reports,
      sliderSelection: {},
    },
  }),
};
