import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const fetchMachineDataPoints = createAction(
  'fetch subscribed data points',
  (machineId) => machineId,
);

export const fetchMachineDataPointsSuccess = createAction(
  'fetch subscribed data points - success',
  (list) => list,
);

export const fetchMachineDataPointsFail = createAction(
  'fetch subscribed data points - fail',
  (error) => error,
);

export const reducer = {
  [fetchMachineDataPoints]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        list: [],
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),
  [fetchMachineDataPointsSuccess]: (state, list) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        list,
        loading: false,
        loaded: true,
        error: null,
      },
    },
  }),
  [fetchMachineDataPointsFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* fetchMachineDataPointsSaga({ payload: machineId }) {
  const { response, error } = yield call(api.machines.getDataPoints, machineId);

  if (response) {
    yield put(fetchMachineDataPointsSuccess(response));
  } else {
    yield put(fetchMachineDataPointsFail(error));
  }
}

export function* watchFetchMachineDataPoints() {
  yield takeEvery(fetchMachineDataPoints.getType(), fetchMachineDataPointsSaga);
}
