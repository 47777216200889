import React from 'react';

export default function OUHeader() {
  return (
    <div className="subheader">
      <div className="subheader-in">
        <div className="subheader--left">
          <ul className="menu tab-menu">
            <li />
          </ul>
        </div>
        <div className="subheader--right" />
      </div>
    </div>
  );
}
