import React from 'react';
import { Field } from 'redux-form';
import DatePickerContainer from '../../../../../_tools/formFields/datePickerV2/DatePicker.container';
import { DATES_FIELD } from '../../../../../../redux/constants/aiApp.cycleDetection.form.constants'; // Make sure to use correct path
import ModalLine from '../../../../core/ModalLine';
import DoneModalFooter from '../../../../core/footers/DoneModalFooter';
import * as datesModes from '../../../../../../../../common/enums/dates.modes';

interface DatesFrameProps {
  onCancelClick: (e?: React.MouseEvent) => void;
  onDoneClick: (e?: React.MouseEvent) => void;
}

const DatesFrame: React.FC<DatesFrameProps> = ({ onCancelClick, onDoneClick }) => (
  <div className='modal-move modal-datepicker'>
    <div className='modal-form-in datepicker-styled'>
      <div className='datepicker-dropdowns--flex'>
        <Field
          component={DatePickerContainer}
          showDatesModeBar={false}
          name={DATES_FIELD}
          datesMode={datesModes.ABSOLUTE}
          showCloseButton={false}
          allowClearDates
          closeAfterDateSelected={false}
          keepDropdownAlwaysOpen
        />
      </div>
    </div>
    <ModalLine />
    <DoneModalFooter onDoneClick={onDoneClick} onCancelClick={onCancelClick} />
  </div>
);

export default DatesFrame;
