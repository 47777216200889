import { connect } from 'react-redux';

import ArchiveMeasurementModal from './ArchiveMeasurementModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { MEASUREMENT_ARCHIVE } from '../../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { archiveMeasurement } from '../../../../redux/modules/measurements.archive.module';

export const mapStateToProps = (state) => {
  const { measurement } = getModalPayload(state, MEASUREMENT_ARCHIVE);
  return { measurementId: measurement.id };
};

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(MEASUREMENT_ARCHIVE)),
  _archive: (id) => dispatch(archiveMeasurement(id)),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  archive: () => dispatchProps._archive(stateProps.measurementId),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ArchiveMeasurementModal);
