import { connect } from 'react-redux';

import UserRow from './UserRow';
import { openModal } from '../../../../redux/modules/modals.module';
import { AP_USER_REMOVE } from '../../../../redux/constants/modals.constants';

export const mapDispatchToProps = (dispatch) => ({
  onRemoveClick: (user) => dispatch(openModal(AP_USER_REMOVE, { user })),
});

export default connect(null, mapDispatchToProps)(UserRow);
