import { formValueSelector } from 'redux-form';

import {
  CLONE_CALENDAR_FORM_NAME,
  CLONE_CALENDAR_NAME_FIELD,
} from '../constants/calendars.clone.form.constants';

export function createFormSelector() {
  return formValueSelector(CLONE_CALENDAR_FORM_NAME);
}

export function getFormFields(state) {
  return createFormSelector()(
    state,
    CLONE_CALENDAR_NAME_FIELD,
  );
}
