import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { fetchMachine } from './machine.fetch.module';
import { loaded, getMachine } from '../selectors/machine.selector';

export const checkReFetch = createAction(
  'check if machine must be fetched again',
  (machineId) => machineId,
);

export function* checkReFetchSaga({ payload: machineId }) {
  const state = yield select();

  const isLoaded = yield call(loaded, state);
  const machine = yield call(getMachine, state);

  if (isLoaded && machine.id === machineId) return;
  yield put(fetchMachine(machineId));
}

export function* watchCheckReFetchMachine() {
  yield takeEvery(checkReFetch.getType(), checkReFetchSaga);
}
