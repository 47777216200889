import React from 'react';
import { useIntl, WrappedComponentProps } from 'react-intl';
import { useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import ComplexModalContainer from '../../core/complexModal/ComplexModal.container';
import commonCoreMessages from '../../../../messages/common.core.messages';
import adminPageMessages from '../../../../messages/adminPage.messages';
import aiAppMessages from '../../../../messages/aiApp.config.messages';
import AiApplMainFrame from './modalFrames/mainFrame/AiApplMainFrame';
import DevicesFiltersFrameContainer from './modalFrames/devicesFilters/DevicesFiltersFrame.container';
import { SUB_PAGE_AI_APP_FORM } from '../../../../redux/constants/modals.constants';
import * as frames from '../../../../core/common/aiApp.form';
import { createAIApp } from '../../../../redux/modules/aiApps.create.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { AI_APP_ADD } from '../../../../redux/constants/modals.constants';
import { AI_APP_FORM_NAME } from '../../../../redux/constants/aiApp.form.constants';

interface AIAppCreateProps extends WrappedComponentProps, InjectedFormProps {
  isAddForm?: boolean;
}

interface FrameConfig {
  id: string;
  component: React.ReactNode;
  mainClassName: string;
  title?: string;
  children?: FrameConfig[];
}

const AIAppCreate: React.FC<AIAppCreateProps & InjectedFormProps<{}, AIAppCreateProps, string>> = ({
  handleSubmit,
  pristine,
  submitting,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const close = () => {
    dispatch(closeModal(AI_APP_ADD));
  };

  const onSubmit = () => {
    dispatch(createAIApp());
  };

  const FRAME_PROPS: { [key: string]: FrameConfig } = {
    [frames.MODAL_MAIN_FRAME]: {
      id: frames.MODAL_MAIN_FRAME,
      component: (
        <AiApplMainFrame
          handleSubmit={handleSubmit(onSubmit)}
          handleCancel={close}
          pristine={pristine}
          submitting={submitting}
        />
      ),
      mainClassName: 'modal-show-main',
    },
    [frames.MODAL_DEVICES_FILTERS_FRAME]: {
      id: frames.MODAL_DEVICES_FILTERS_FRAME,
      component: <DevicesFiltersFrameContainer />,
      mainClassName: 'modal-show-filters',
    },
  };

  const devicesFiltersFrameTitle = formatMessage(adminPageMessages.selectObject, {
    object: formatMessage(commonCoreMessages.devices),
  });

  const modalConfig: FrameConfig = {
    title: formatMessage(aiAppMessages.addAiApp),
    ...FRAME_PROPS[frames.MODAL_MAIN_FRAME],
    children: [
      {
        title: devicesFiltersFrameTitle,
        ...FRAME_PROPS[frames.MODAL_DEVICES_FILTERS_FRAME],
      },
    ],
  };

  return (
    <ComplexModalContainer
      modalId={SUB_PAGE_AI_APP_FORM}
      modalConfig={modalConfig}
      close={close}
      submit={handleSubmit(onSubmit)}
    />
  );
};

export default reduxForm<{}, AIAppCreateProps>({
  form: AI_APP_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AIAppCreate);
