import _ from 'lodash';
import moment from 'moment';
import * as reportTypes from '../../../../common/enums/reportTypes';
import * as periodTypes from '../../../../common/enums/periodTypes';
import datatronStats, * as datatronStatsProps from '../../../../common/enums/datatronStats';
import { getSecondsAmount } from '../../../../common/enums/machinePerformance';

export const getX = (point) => point.timestamp;

export const getStylesByProp = (prop) => {
  switch (prop) {
    case periodTypes.quality: return {
      line: 'line--quality',
      area: 'kpi-color--quality',
    };
    case periodTypes.availability: return {
      line: 'line--availability',
      area: 'kpi-color--availability',
    };
    case periodTypes.performance: return {
      line: 'line--performance',
      area: 'kpi-color--performance',
    };
    case periodTypes.maintenancePlanned: return {
      line: 'line--maintenance-planned',
      area: 'kpi-color--planned',
    };
    case periodTypes.maintenanceUnplanned: return {
      line: 'line--maintenance-unplanned',
      area: 'kpi-color--unplanned',
    };
    case periodTypes.eventError: return {
      line: 'line--event-error',
      area: 'kpi-color--error',
    };
    case periodTypes.eventFatal: return {
      line: 'line--event-fatal',
      area: 'kpi-color--fatal',
    };
    case periodTypes.eventWarning: return {
      line: 'line--event-warning',
      area: 'kpi-color--warning',
    };
    case periodTypes.eventInfo: return {
      line: 'line--event-info',
      area: 'kpi-color--info',
    };
    case periodTypes.eventAnomaly: return {
      line: 'line--event-anomaly',
      area: 'kpi-color--anomaly',
    };
    case datatronStatsProps.datatronRam: return {
      line: 'line--ram',
      area: 'datatron-color--ram',
    };
    case datatronStatsProps.datatronNetwork: return {
      line: 'line--network',
      area: 'datatron-color--network',
    };
    case datatronStatsProps.datatronDisk: return {
      line: 'line--disk',
      area: 'datatron-color--disk',
    };
    case datatronStatsProps.datatronCpu: return {
      line: 'line--cpu',
      area: 'datatron-color--cpu',
    };

    default: {
      return {
        line: '',
        area: '',
      };
    }
  }
};

export const findHighestPoint = (points, props) => points.reduce((maxValue, point) => {
  const currentMax = _.max(Object.values(_.pick(point, props)));
  if (currentMax > maxValue) return currentMax;
  return maxValue;
}, 0);

export const findHighestTotalPoint = (points, props) => points.reduce((maxValue, point) => {
  const currentMax = _.sum(Object.values(_.pick(point, props)));
  if (currentMax > maxValue) return currentMax;
  return maxValue;
}, 0);

export const calculateTotalValue = (type, values) => {
  const allValuesNull = values.reduce((result, value) => result && value === null, true);
  if (allValuesNull) return null;

  if (type === reportTypes.kpi) {
    return values.reduce((final, value) => {
      if (final === null || value === null) return null;
      return final * value;
    }, 1);
  }
  return values.reduce((final, value) => {
    if (final === null && value === null) return null;
    if (final === null) return value;
    if (value === null) return final;
    return final + value;
  }, null);
};

export const createEmptyPoint = (props) => props.reduce((final, prop) => ({
  ...final,
  [prop]: null,
}), {
  total: null,
});

export const allPropsByType = {
  [reportTypes.kpi]: periodTypes.oee,
  [reportTypes.events]: periodTypes.events,
  [reportTypes.maintenance]: periodTypes.maintenanceTypes,
  [reportTypes.datatronStats]: datatronStats,
};

export const unpackChartPoint = ({ chartPoint, chartProps, reportType }) => {
  const periodSeconds = getSecondsAmount(chartPoint.period);
  let startAt = moment(chartPoint.start);

  const keys = Object.keys(_.pick(chartPoint, chartProps));
  const values = Object.values(_.pick(chartPoint, chartProps));

  return _.zipWith(...values, (...result) => {
    const total = calculateTotalValue(reportType, result);
    const timestamp = +startAt;
    startAt = startAt.add(periodSeconds, 'seconds');

    return {
      ..._.zipObject(keys, result),
      timestamp,
      total,
    };
  });
};

export const getDomainForScaleBand = (data, selection = null) => {
  if (!selection || _.isEmpty(selection)) return data.map(getX);
  return data
    .filter((dataPoint) => getX(dataPoint) >= selection[0] && getX(dataPoint) <= selection[1])
    .map(getX);
};

export const getDataForStackedBarChart = (data, selection = null) => {
  if (!selection || _.isEmpty(selection)) return data;
  return data
    .filter((dataPoint) => getX(dataPoint) >= selection[0] && getX(dataPoint) <= selection[1]);
};

export const getAllReportProps = (type) => allPropsByType[type];

export const getAllowedProps = (type, allProps, disabledProps) => _.filter(
  allProps,
  (prop) => !(disabledProps.indexOf(prop) >= 0),
);

export const getChartPoints = (report) => report.data.chart;

export const sortChartData = (chartData) => _.sortBy(chartData, ['timestamp']);

export const mapChartDataToTimestamp = (chartData) => chartData
  .reduce((result, item) => ({
    ...result,
    [item.timestamp]: item,
  }), {});

export const fillEmptySpaceInChartData = ({
  chartData,
  startDateTime,
  endDateTime,
  chartProps,
}) => {
  const startMoment = moment(startDateTime);
  const endMoment = moment(endDateTime);
  const mappedChartData = mapChartDataToTimestamp(chartData);
  const newChartData = [...chartData];

  const cursor = moment(startMoment);
  while (cursor.isBefore(endMoment)) {
    const cursorTs = +cursor;

    if (!mappedChartData[cursorTs]) {
      newChartData.push({
        ...createEmptyPoint(chartProps),
        timestamp: cursorTs,
      });
    }

    cursor.add(1, 'hour');
  }

  return newChartData;
};

export const prepareChartPoints = ({
  chartPoints,
  chartProps,
  reportType,
  startDateTime,
  endDateTime,
}) => {
  const chartData = [];

  _.forEach(
    chartPoints,
    (chartPoint) => {
      const unpackedChartPoint = unpackChartPoint({
        chartPoint,
        chartProps,
        reportType,
      });
      _.forEach(
        unpackedChartPoint,
        (item) => chartData.push(item),
      );
    },
  );

  const final = fillEmptySpaceInChartData({
    chartData,
    startDateTime,
    endDateTime,
    chartProps,
  });

  return sortChartData(final);
};

export const getReportChartData = ({ report, startDateTime, endDateTime }) => {
  const reportType = report.type;
  const disabledProps = report.disabledProps;
  const allProps = getAllReportProps(reportType);
  const allowedProps = getAllowedProps(reportType, allProps, disabledProps);

  const data = prepareChartPoints({
    chartPoints: getChartPoints(report),
    chartProps: allowedProps,
    reportType,
    startDateTime,
    endDateTime,
  });

  return {
    allProps,
    allowedProps,
    disabledProps,
    type: report.type,
    data,
    isEmptyChart: !(data.length > 0),
  };
};
