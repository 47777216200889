import { connect } from 'react-redux';

import Measurements from './Measurements';
import { fetchMeasurementsByExperiment } from '../../../../../redux/modules/experimentsPage.measurementsByExperiment.fetch.module';
import { getMeasurements, isLoading, isLoaded } from '../../../../../redux/selectors/experimentsPage.measurementsByExperiment.selector';

export const mapStateToProps = (state) => ({
  measurements: getMeasurements(state),
  loading: isLoading(state),
  loaded: isLoaded(state),
});

export const mapDispatchToProps = (dispatch, { experimentId }) => ({
  fetch: () => dispatch(fetchMeasurementsByExperiment(experimentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Measurements);
