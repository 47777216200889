import querystring from 'querystring';
import {
  apiRequest, postApiRequest, putApiRequest,
} from './_tools';

export const getTools = (payload) => {
  const query = querystring.stringify(payload);
  return apiRequest(`/api/tools?${query}`);
};

export const getToolsPagination = (payload) => {
  const query = querystring.stringify(payload);
  return apiRequest(`/api/tools/pagination?${query}`);
};

export const createTool = (payload) => postApiRequest('/api/tools', payload);
export const updateTool = (id, payload) => putApiRequest(`/api/tools/${id}`, payload);

export const setToolToMachine = (machineId, toolId, payload) => postApiRequest(`/api/machines/${machineId}/tools/${toolId}`, payload);
export const unsetToolFromMachine = (machineId, toolId, payload) => postApiRequest(`/api/machines/${machineId}/tools/${toolId}/reset`, payload);
