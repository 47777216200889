import React from 'react';
import PropTypes from 'prop-types';

import DeviceMenu from './deviceMenu/DeviceMenu';

export const DeviceHeader = ({
  device,
  toggle,
  isOpen,
}) => (
  <div className="datatron--top">
    <div className="datatron--title">
      <i className="icon icon-cogs" />
      <span>{ device.name }</span>
    </div>
    <DeviceMenu
      device={device}
      isOpen={isOpen}
      toggle={toggle}
    />
  </div>
);

DeviceHeader.propTypes = {
  device: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default DeviceHeader;
