import { createAction } from 'redux-act';

export const addCategoryMaintenanceFilter = createAction(
  'add category filter to machine maintenance list',
  (filter) => filter,
);

export const removeCategoryMaintenanceFilter = createAction(
  'remove category filter to machine maintenance list',
  (filter) => filter,
);

export const reducer = {
  [addCategoryMaintenanceFilter]: (state, filter) => ({
    ...state,
    machine: {
      ...state.machine,
      maintenance: {
        ...state.machine.maintenance,
        filters: {
          ...state.machine.maintenance.filters,
          category: [
            ...state.machine.maintenance.filters.category,
            filter,
          ],
        },
      },
    },
  }),
  [removeCategoryMaintenanceFilter]: (state, filter) => {
    const selectedCategories = state.machine.maintenance.filters.category;
    const index = selectedCategories.indexOf(filter);

    if (index === -1) return state;

    return {
      ...state,
      machine: {
        ...state.machine,
        maintenance: {
          ...state.machine.maintenance,
          filters: {
            ...state.machine.maintenance.filters,
            category: [
              ...selectedCategories.slice(0, index),
              ...selectedCategories.slice(index + 1),
            ],
          },
        },
      },
    };
  },
};
