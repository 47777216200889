import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import RootCoreContainer from './RootCore.container';

export default function Root({ route }) {
  return (
    <RootCoreContainer>
      {renderRoutes(route.routes)}
    </RootCoreContainer>
  );
}

Root.propTypes = {
  route: PropTypes.object.isRequired,
};
