import { createAction } from 'redux-act';

import { SORT_WAY_ASC, SORT_WAY_DESC } from '../../../../common/constants';
import * as entryTypes from '../constants/configRepoExplorer.entry.types.constants';

export const sortDataPoints = createAction(
  'sort data points by label (config repo explorer)',
  (option) => option,
);

export const filterByLabel = createAction(
  'filter data points by label (config repo explorer)',
  (query = '') => query,
);

export const reducer = {
  [sortDataPoints]: (state, option) => {
    const prevOption = state.configRepoExplorer[entryTypes.DATA_POINTS].filters.sort.option;
    const prevWay = state.configRepoExplorer[entryTypes.DATA_POINTS].filters.sort.way;

    let newOption = null;
    let newWay = null;

    if (prevOption === option && prevWay === SORT_WAY_ASC) {
      newOption = prevOption;
      newWay = SORT_WAY_DESC;
    } else if (prevOption !== option) {
      newOption = option;
      newWay = SORT_WAY_ASC;
    }

    return {
      ...state,
      configRepoExplorer: {
        ...state.configRepoExplorer,
        [entryTypes.DATA_POINTS]: {
          ...state.configRepoExplorer[entryTypes.DATA_POINTS],
          filters: {
            ...state.configRepoExplorer[entryTypes.DATA_POINTS].filters,
            sort: { option: newOption, way: newWay },
          },
        },
      },
    };
  },
  [filterByLabel]: (state, q) => ({
    ...state,
    configRepoExplorer: {
      ...state.configRepoExplorer,
      [entryTypes.DATA_POINTS]: {
        ...state.configRepoExplorer[entryTypes.DATA_POINTS],
        filters: {
          ...state.configRepoExplorer[entryTypes.DATA_POINTS].filters,
          q,
        },
      },
    },
  }),
};

/*
export function* fetchBatchesSaga() {
  yield delay(DEBOUNCE_TIME_IN_MILLISECONDS);

  yield put(fetchBatches());
}

export function* watchBatchFiltersUpdates() {
  yield takeLatest(
    [
      sortBatches.getType(),
      filterByName.getType(),
      filterByDate.getType(),
    ],
    fetchBatchesSaga,
  );
}
*/
