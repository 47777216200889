import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { isEmpty } from 'lodash';

import EventRecurrenceTool from './EventRecurrenceTool';
import EventActionsButton from './EventActionsButton';
import stopPropagation from '../../../../../../_tools/stopPropagation';
import { ESCAPE_KEY, ENTER_KEY } from '../../../../../../../../../common/constants/keyboard';
import {
  isTimeRangeValid as checkIfTimeRangeValid,
  isTimeValid,
  getNewTime,
  createMomentFromTime,
  createTimeFromMoment,
} from '../../../../../../../../../common/helpers/calendar.helper';
import { NON_RECURRENT } from '../../../../../../../redux/constants/calendars.entry.constants';

const placeholder = 'HH:MM';
const RELOAD_BUTTON_CLASS = 'reload-button';

export default class EventActions extends Component {
  static propTypes = {
    event: PropTypes.object.isRequired,
    day: PropTypes.string.isRequired,
    hide: PropTypes.func.isRequired,
    className: PropTypes.string,
    onEventRemoveClick: PropTypes.func.isRequired,
    updateCalendarEvent: PropTypes.func.isRequired,
    pendingChanges: PropTypes.object.isRequired,
    onRepeatingEventSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { event, className } = props;

    this.state = {
      startValue: moment(event.startDateTime).format('HH:mm'),
      isStartValueValid: true,
      endValue: moment(event.endDateTime).format('HH:mm'),
      isEndValueValid: true,
      showRecurrenceTool: false,
      isTimeRangeValid: true,
      pendingChanges: {},
    };

    this.popupClassnames = classnames(
      'schedule-item--popup outside on-click',
      className,
    );

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleRepeatTypeChange = this.handleRepeatTypeChange.bind(this);
    this.toggleRecurrenceTool = this.toggleRecurrenceTool.bind(this);
    this.closeRecurrenceTool = this.closeRecurrenceTool.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.startDateInput.focus();
    this.startDateInput.setSelectionRange(2, 2);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.event.startDateTime !== this.props.event.startDateTime
      || nextProps.event.endDateTime !== this.props.event.endDateTime
    ) {
      this.setState({
        startValue: moment(nextProps.event.startDateTime).format('HH:mm'),
        endValue: moment(nextProps.event.endDateTime).format('HH:mm'),
      });
    }
  }

  handleStartDateChange(e) {
    const newValue = e.target.value;
    const { event } = this.props;
    const newState = {
      startValue: newValue,
      isStartValueValid: isTimeValid(newValue),
    };

    if (newState.isStartValueValid) {
      const [startTime, endTime] = getNewTime({
        oldStartTime: (!!this.state.startValue && this.state.isStartValueValid)
          ? this.state.startValue : createTimeFromMoment(moment(event.startDateTime)),
        oldEndTime: (!!this.state.endValue && this.state.isEndValueValid)
          ? this.state.endValue : createTimeFromMoment(moment(event.endDateTime)),
        newStartTime: newValue,
      });
      newState.pendingChanges = {
        ...this.state.pendingChanges,
        startDateTime: createMomentFromTime(
          startTime,
          event.startDateTime,
        ).toISOString(),
        endDateTime: createMomentFromTime(
          endTime,
          event.startDateTime,
        ).toISOString(),
      };
      newState.endValue = endTime;
      newState.isStartValueValid = true;
      newState.isTimeRangeValid = checkIfTimeRangeValid(startTime, endTime);
    }

    this.setState(newState);
  }

  handleEndDateChange(e) {
    const newValue = e.target.value;
    const { event } = this.props;
    const newState = {
      endValue: newValue,
      isEndValueValid: isTimeValid(newValue),
    };

    if (newState.isEndValueValid) {
      const [startTime, endTime] = getNewTime({
        oldStartTime: (!!this.state.startValue && this.state.isStartValueValid)
          ? this.state.startValue : createTimeFromMoment(moment(event.startDateTime)),
        oldEndTime: (!!this.state.endValue && this.state.isEndValueValid)
          ? this.state.endValue : createTimeFromMoment(moment(event.endDateTime)),
        newEndTime: newValue,
      });

      newState.pendingChanges = {
        ...this.state.pendingChanges,
        startDateTime: createMomentFromTime(
          startTime,
          event.startDateTime,
        ).toISOString(),
        endDateTime: createMomentFromTime(
          endTime,
          event.startDateTime,
        ).toISOString(),
      };
      newState.startValue = startTime;
      newState.isStartValueValid = true;
      newState.isTimeRangeValid = checkIfTimeRangeValid(startTime, endTime);
    }

    this.setState(newState);
  }

  handleSave(e) {
    if (e) stopPropagation(e);

    const {
      updateCalendarEvent,
      event,
      onRepeatingEventSave,
      day,
      hide,
    } = this.props;
    const pendingChanges = {
      ...this.props.pendingChanges,
      ...this.state.pendingChanges,
    };

    const emptyChanges = isEmpty(pendingChanges);

    if (!emptyChanges) {
      if (this.props.event.isRecurrentEvent) {
        onRepeatingEventSave({
          event, day, pendingChanges, clearChanges: hide,
        });
      } else {
        updateCalendarEvent(event.calendarId, event, pendingChanges, NON_RECURRENT);
      }

      this.setState({
        pendingChanges: {},
      });
    }
    this.props.hide(pendingChanges);
  }

  handleCancel(e) {
    if (e) stopPropagation(e);
    this.props.hide();
  }

  handleDelete(e) {
    stopPropagation(e);

    const { event, onEventRemoveClick, day } = this.props;
    onEventRemoveClick({ event, day });
  }

  handleRepeatTypeChange(option) {
    this.setState({
      pendingChanges: {
        ...this.state.pendingChanges,
        repeatType: option,
      },
    });
  }

  toggleRecurrenceTool() {
    this.setState({
      showRecurrenceTool: !this.state.showRecurrenceTool,
    });
  }

  closeRecurrenceTool() {
    this.setState({
      showRecurrenceTool: false,
    });
  }

  handleKeyDown(e) {
    if (e.keyCode === ESCAPE_KEY) {
      this.handleCancel();
    } else if (e.keyCode === ENTER_KEY) {
      this.handleSave();
    }
  }

  render() {
    const {
      event,
    } = this.props;
    const {
      startValue,
      endValue,
      isStartValueValid,
      isEndValueValid,
      showRecurrenceTool,
      pendingChanges,
      isTimeRangeValid,
    } = this.state;
    const timeClassnames = classnames(
      'popup--time--change',
      { invalid: !isStartValueValid || !isEndValueValid || !isTimeRangeValid },
    );
    const repeatType = (pendingChanges.repeatType !== undefined)
      ? pendingChanges.repeatType : event.repeatType;
    const repeatEnd = pendingChanges.repeatEnd || event.repeatEnd;

    return (
      <div className="schedule-item-line-in" onKeyDown={this.handleKeyDown}>
        <div className={this.popupClassnames}>
          <div className={timeClassnames}>
            <input
              ref={(input) => { this.startDateInput = input; }}
              type="text"
              placeholder={placeholder}
              onChange={this.handleStartDateChange}
              value={startValue}
              maxLength={5}
            />
            <span className="popup-sep">-</span>
            <input
              type="text"
              placeholder={placeholder}
              onChange={this.handleEndDateChange}
              value={endValue}
              maxLength={5}
            />
          </div>
          <div className="popup--action--buttons">
            <div className="rel">
              <EventActionsButton
                iconClass="icon-reload-clockwise"
                buttonClass={RELOAD_BUTTON_CLASS}
                onClick={this.toggleRecurrenceTool}
              />
              { showRecurrenceTool && (
              <EventRecurrenceTool
                repeatType={repeatType}
                repeatEnd={repeatEnd}
                close={this.closeRecurrenceTool}
                changeRepeatType={this.handleRepeatTypeChange}
                outsideClickIgnoreClass={RELOAD_BUTTON_CLASS}
              />
              )}
            </div>
            <EventActionsButton
              iconClass="icon-remove"
              onClick={this.handleDelete}
            />
            <EventActionsButton
              iconClass="icon-close2"
              onClick={this.handleCancel}
            />
            <EventActionsButton
              iconClass="icon-checkmark"
              onClick={this.handleSave}
            />
          </div>
        </div>
      </div>
    );
  }
}
