import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import { change } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import * as form from '../../../../../../redux/constants/aiApp.cycleDetection.form.constants';
import {
  getSelectedDatatron,
  getSelectedDevices,
} from '../../../../../../redux/selectors/aiApps.selector';
import { getDataPoints } from '../../../../../../core/api/datatrons';
import Input from '../../../../../common/checkboxFilters/input/Input';
import { Loader } from '../../../../../_tools';

interface TypeItem {
  uuid: string;
  label: string;
  identifier: string;
}

const FilterInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className='search-alls search-alls-sm'>
      <div className='search-block'>
        <Input value={value} onChange={handleChange} />
      </div>
    </div>
  );
};

const TypeItemComponent: React.FC<{
  item: TypeItem;
  isSelected: boolean;
  onClick: () => void;
}> = ({ item, isSelected, onClick }) => (
  <div className={`type-item filter-type-uuid ${isSelected ? 'selected' : ''}`} onClick={onClick}>
    <div className='type-item-content'>
      <div className='row'>
        <span className='label col-2'>
          <FormattedMessage id='dataPoint.config.uuid' />:
        </span>
        <span className='value col-10'>{item.uuid}</span>
      </div>
      <div className='row'>
        <span className='label col-2'>
          <FormattedMessage id='dataPoint.config.label' />:
        </span>
        <span className='value col-10'>{item.label}</span>
      </div>
      <div className='row'>
        <span className='label col-2'>
          <FormattedMessage id='dataPoint.config.identifier' />:
        </span>
        <span className='value col-10'>{item.identifier}</span>
      </div>
    </div>
  </div>
);

const SelectedItem: React.FC<{
  item: TypeItem;
  onDelete: () => void;
}> = ({ item, onDelete }) => (
  <div className='selected-item-batch d-flex align-items-center'>
    <div className='selected-item-info flex-grow-1'>
      <span>{item.label}</span>
    </div>
    <div className='selected-item-delete ml-2' onClick={onDelete}>
      <i className='fa fa-trash'></i>
    </div>
  </div>
);

const TypeUuidFilterField: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [typeItems, setTypeItems] = useState<TypeItem[]>([]);
  const [filterValue, setFilterValue] = useState('');
  const [selectedItem, setSelectedItem] = useState<TypeItem | null>(null);
  const dispatch = useDispatch();

  const selectedDevices = useSelector(getSelectedDevices);
  const selectedDatatron = useSelector(getSelectedDatatron);

  useEffect(() => {
    const updateTypes = async () => {
      setIsLoading(true);
      const responses = await Promise.all(
        selectedDevices.map((device) =>
          getDataPoints({
            datatronId: selectedDatatron.code,
            deviceId: device.code,
          }),
        ),
      );

      const items = responses.flatMap(({ response }) => {
        if (!response) {
          return [];
        }
        return response.map((type) => ({
          uuid: type.uuid,
          label: type.label,
          identifier: type.config.identifier,
        }));
      });

      setTypeItems(items);
      setIsLoading(false);
    };

    updateTypes();
  }, [selectedDevices, selectedDatatron]);

  const handleFilterChange = (value: string) => {
    setFilterValue(value);
  };

  const handleItemClick = (item: TypeItem) => {
    setSelectedItem(item);
    dispatch(
      change(form.AI_APP_CYCLE_DETECTION_FORM, form.TYPE_UUID_FIELD, [
        { name: item.label, value: item.uuid },
      ]),
    );
  };

  const handleDeleteClick = () => {
    setSelectedItem(null);
    dispatch(change(form.AI_APP_CYCLE_DETECTION_FORM, form.TYPE_UUID_FIELD, []));
  };

  const filteredItems = useMemo(
    () =>
      typeItems.filter(
        (item) =>
          item.uuid.toLowerCase().includes(filterValue.toLowerCase()) ||
          item.label.toLowerCase().includes(filterValue.toLowerCase()) ||
          item.identifier.toLowerCase().includes(filterValue.toLowerCase()),
      ),
    [typeItems, filterValue],
  );

  const ItemRenderer = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const item = filteredItems[index];
    return (
      <div style={style}>
        <TypeItemComponent
          key={item.uuid}
          item={item}
          isSelected={selectedItem?.uuid === item.uuid}
          onClick={() => handleItemClick(item)}
        />
      </div>
    );
  };

  return (
    <>
      <FilterInput value={filterValue} onChange={handleFilterChange} />

      <div className='type-items-container'>
        {isLoading ? (
          <Loader show={isLoading} fullPage={false} />
        ) : (
          <List height={260} itemCount={filteredItems.length} itemSize={80} width='100%'>
            {ItemRenderer}
          </List>
        )}
      </div>
      {selectedItem && <SelectedItem item={selectedItem} onDelete={handleDeleteClick} />}
    </>
  );
};

export default TypeUuidFilterField;
