import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { last, reverse } from 'lodash';

import ViewToolForm from './ViewToolForm';
import * as form from '../../../../../redux/constants/tools.view.form.constants';
import * as toolStates from '../../../../../../../common/enums/toolStates';
import * as toolLogTypes from '../../../../../../../common/enums/toolLogTypes';
import PermissionedComponent from '../../../../common/permissions/PermissionedComponent.container';
import { TOOLS_PERMISSIONS } from '../../../../../../../common/constants/permissions';

export const mapStateToProps = (state, { tool, permissions }) => {
  const initialValues = {
    [form.LABEL_FIELD]: tool.label,
    [form.TYPE_FIELD]: tool.type,
    [form.MODEL_FIELD]: tool.model,
    [form.SERIAL_FIELD]: tool.serial,
  };

  let hasMostRecentActivity = false;

  if (tool && tool.logs && tool.logs.length > 0) {
    const mostRecentActivity = last(tool.logs);

    initialValues[form.LA_TYPE] = mostRecentActivity.type;
    initialValues[form.LA_TIMESTAMP] = mostRecentActivity.createdAt;
    initialValues[form.LA_COMMENT] = mostRecentActivity.comment;
    if (mostRecentActivity.type === toolLogTypes.mounted) {
      initialValues[form.LA_DURATION] = mostRecentActivity.createdAt;
    }
    if (mostRecentActivity.machine) {
      initialValues[form.LA_MACHINE] = mostRecentActivity.machine.name;
    }
    hasMostRecentActivity = true;
  }

  return {
    initialValues,
    hasMostRecentActivity,
    logs: reverse(tool.logs),
    canEditTool: tool.state !== toolStates.EXHAUSTED && permissions.canEditTool(),
  };
};

const formConfig = {
  form: form.VIEW_TOOL_FORM_NAME,
  destroyOnUnmount: true,
};

export default PermissionedComponent(TOOLS_PERMISSIONS)(connect(mapStateToProps)(reduxForm(formConfig)(ViewToolForm)));
