import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import InputError from '../inputError/InputError';

const TextareaWithError = ({
  meta, input, intl, ...props
}) => {
  const isError = meta.touched && meta.error;
  const className = classnames(props.className || '', { error: isError });

  return (
    <div>
      <textarea
        {...input}
        {...props}
        className={className}
      />
      { isError && (
        <div className="error-line error-line__textarea">
          <InputError error={meta.error} />
        </div>
      )}
    </div>
  );
};

TextareaWithError.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    touched: PropTypes.bool,
  }).isRequired,
  input: PropTypes.object,
  className: PropTypes.string,
};

export default TextareaWithError;
