export interface AppConfigState {
  appName: string;
  allRoles?: string[];
  customLogo: {
    enabled: boolean;
    path: string | null;
  };
  features: {
    preview: boolean;
    hideOee: boolean;
    altasigmaIntegration: boolean;
    loginHistory?: boolean;
    passwordReset?: boolean;
    selfSignUp?: boolean;
    measurements?: {
      downloadUrl: string | null;
    },
    experiments: boolean
  };
  locales: string[];
  socket?: {
    host: string;
    port: string;
  };
  theme: 'default';
}

export const testState: AppConfigState = {
  appName: 'Datatron Berlin',
  customLogo: {
    enabled: false,
    path: null,
  },
  features: {
    preview: true,
    hideOee: false,
    altasigmaIntegration: true,
    experiments: true,
  },
  locales: ['en', 'de', 'ru'],
  theme: 'default',
};

export const initialState: AppConfigState = {
  appName: '',
  customLogo: {
    enabled: false,
    path: null,
  },
  features: {
    preview: false,
    hideOee: false,
    altasigmaIntegration: false,
    experiments: true,
  },
  locales: ['en'],
  theme: 'default',
};
