import { createAction } from '@reduxjs/toolkit';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { INVENTORY_MACHINES } from '../../../../common/constants/inventory';
import { fetchMachineOee } from './machine.oee.fetch.module';

export const fetchPreviewItem = createAction(
  'fetch inventory preview',
  ({ itemType, itemId }) => ({ payload: { itemType, itemId } }),
);

export const fetchPreviewItemSuccess = createAction(
  'fetch inventory preview - success',
  (item) => ({ payload: item }),
);

export const fetchPreviewItemFail = createAction(
  'fetch inventory preview - fail',
  (error) => ({ payload: error }),
);

export const reducer = {
  [fetchPreviewItem.type]: (state) => ({
    ...state,
    inventory: {
      ...state.inventory,
      preview: {
        ...state.inventory.preview,
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),

  [fetchPreviewItemSuccess.type]: (state, item) => ({
    ...state,
    inventory: {
      ...state.inventory,
      preview: {
        ...state.inventory.preview,
        loading: false,
        loaded: true,
        error: null,
        item,
      },
    },
  }),

  [fetchPreviewItemFail.type]: (state, error) => ({
    ...state,
    inventory: {
      ...state.inventory,
      preview: {
        ...state.inventory.preview,
        loading: false,
        loaded: false,
        error,
        item: null,
      },
    },
  }),
};

export function* fetchPreviewItemSaga({ payload: { itemType, itemId } }) {
  let apiEndpoint = api.datatrons.getDatatron;

  if (itemType === INVENTORY_MACHINES) {
    apiEndpoint = api.machines.getMachine;
    yield put(fetchMachineOee(itemId));
  }

  const { response, error } = yield call(apiEndpoint, itemId);

  if (response) {
    yield put(fetchPreviewItemSuccess(response));
  } else {
    yield put(fetchPreviewItemFail(error));
  }
}

export function* watchFetchPreviewItem() {
  yield takeEvery(fetchPreviewItem, fetchPreviewItemSaga);
}
