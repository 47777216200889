import {
  CALENDAR_MANAGER,
  CALENDAR_EDITOR,
  CALENDAR_VIEWER,
  Role,
} from '../constants/permissions';

class CalendarPermissions {
  private isCalendarManager: boolean;

  private isCalendarEditor: boolean;

  private isCalendarViewer: boolean;

  constructor(roles: Role[]) {
    this.isCalendarManager = roles.indexOf(CALENDAR_MANAGER) !== -1;
    this.isCalendarEditor = roles.indexOf(CALENDAR_EDITOR) !== -1;
    this.isCalendarViewer = roles.indexOf(CALENDAR_VIEWER) !== -1;
  }

  canCloneCalendar() {
    return this.isCalendarManager;
  }

  canDeleteCalendar() {
    return this.isCalendarManager;
  }

  canAddCalendar() {
    return this.isCalendarManager;
  }

  canAssignCalendar() {
    return this.isCalendarManager;
  }

  canChangeEvents() {
    return this.isCalendarManager || this.isCalendarEditor;
  }

  canEditCalendar() {
    return this.isCalendarManager;
  }

  availableToAll() {
    return (
      this.isCalendarManager || this.isCalendarEditor || this.isCalendarViewer
    );
  }
}

export default CalendarPermissions;
