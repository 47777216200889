import { v4 as uuid } from 'uuid';

export {
  NOTIFICATION_ERROR,
  NOTIFICATION_EVENT,
} from '../redux/constants/notification.constants';

export const event = 'event';

export const notificationTimeout = 5000;

export function compose(title, description, type) {
  return {
    id: uuid(),
    title,
    description,
    type,
  };
}

export function getNotificationIndex(notifications, id) {
  return notifications.findIndex((notification) => notification.id === id);
}
