import { createAction } from 'redux-act';
import {
  put, call, select, takeLatest,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { animateScroll } from 'react-scroll';

import * as form from '../constants/calendar.form.constants';
import { CP_NEW_CALENDAR } from '../constants/modals.constants';
import { getFormFields } from '../selectors/calendar.form.selector';
import { closeModal } from './modals.module';
import * as api from '../../core/api';
import { getDefaultCalendarEventsDates, initializeCalendar } from '../../core/common/calendar';
import { fetchCalendarEvents } from './calendars.fetch.calendarEvents.module';
import { resetFilterQueries } from './calendars.query.module';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';

export const createCalendar = createAction(
  'create calendar',
);

export const createCalendarSuccess = createAction(
  'create calendar - success',
  (calendar) => calendar,
);

export const createCalendarFail = createAction(
  'create calendar - fail',
);

export const reducer = {
  [createCalendarSuccess]: (state, calendar) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: [
        calendar,
        ...state.machineCalendars.list,
      ],
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.CALENDAR_FORM_NAME,
    ...form.CALENDAR_PROPS,
  ));
}

export function* createCalendarSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const fields = yield call(getFormFields, state);
  yield put(startSubmit(form.CALENDAR_FORM_NAME));

  const { response, error } = yield call(api.calendars.create, fields);

  if (response) {
    const calendar = initializeCalendar(response);
    yield put(createCalendarSuccess(calendar));

    const { fromDate, toDate } = yield getDefaultCalendarEventsDates();
    yield put(fetchCalendarEvents({ calendar, fromDate, toDate }));
    yield put(stopSubmit(form.CALENDAR_FORM_NAME));
    yield put(closeModal(CP_NEW_CALENDAR));
    yield call(animateScroll.scrollToTop);
    yield put(sendNotification({ ...notificationMessage.calendar_created, values: { name: response.name } }));
    yield put(resetFilterQueries());
  } else {
    yield put(createCalendarFail(error));
    yield put(stopSubmit(form.CALENDAR_FORM_NAME, error));
  }
}

export function* watchCreateCalendar() {
  yield takeLatest(createCalendar.getType(), createCalendarSaga);
}
