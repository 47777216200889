import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EditToolModal from './EditToolModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { editTool } from '../../../../redux/modules/tools.edit.module';
import { TOOL_EDIT } from '../../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';

export const mapStateToProps = (state) => getModalPayload(state, TOOL_EDIT);

export const mapDispatchToProps = (dispatch) => ({
  _update: bindActionCreators(editTool, dispatch),
  close: () => dispatch(closeModal(TOOL_EDIT)),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  handleSubmit: (e) => {
    e.preventDefault();
    dispatchProps._update(stateProps.tool);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditToolModal);
