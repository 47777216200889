import { createAction } from 'redux-act';
import {
  put, takeLatest, delay,
} from 'redux-saga/effects';

import { adminFetchOrganizations } from './admin.organizations.fetch.module';
import { closeComponent } from './components.visibility.module';
import { SORT_WAY_ASC, SORT_WAY_DESC } from '../../../../common/constants';
import { AP_ORGANIZATIONS_NAME_FILTER_INPUT } from '../constants/components.constants';

export const DEBOUNCE_TIME_IN_MILLISECONDS = 500;

export const adminSortOrganizations = createAction(
  'admin: sort organizations',
  (option) => option,
);

export const adminFilterOrganizationsByName = createAction(
  'admin: filter organizations by name',
  (query = '') => query,
);

export const adminResetOrganizationsFilters = createAction(
  'admin: reset organizations filters',
);

export const reducer = {
  [adminSortOrganizations]: (state, option) => {
    const prevOption = state.admin.organizations.filters.sort.option;
    const prevWay = state.admin.organizations.filters.sort.way;

    let newOption = null;
    let newWay = null;

    if (prevOption === option && prevWay === SORT_WAY_ASC) {
      newOption = prevOption;
      newWay = SORT_WAY_DESC;
    } else if (prevOption !== option) {
      newOption = option;
      newWay = SORT_WAY_ASC;
    }

    return {
      ...state,
      admin: {
        ...state.admin,
        organizations: {
          ...state.admin.organizations,
          filters: {
            ...state.admin.organizations.filters,
            sort: { option: newOption, way: newWay },
          },
        },
      },
    };
  },
  [adminFilterOrganizationsByName]: (state, q) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        filters: {
          ...state.admin.organizations.filters,
          q,
        },
      },
    },
  }),
  [adminResetOrganizationsFilters]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        filters: {
          ...state.admin.organizations.filters,
          q: '',
          sort: {
            option: null,
            way: null,
          },
        },
      },
    },
  }),
};

export function* adminResetOrganizationFiltersSaga() {
  yield put(closeComponent(AP_ORGANIZATIONS_NAME_FILTER_INPUT));
  yield put(adminFetchOrganizations());
}

export function* watchAdminResetOrganizationFiltersSaga() {
  yield takeLatest([adminResetOrganizationsFilters.getType()], adminResetOrganizationFiltersSaga);
}

export function* adminFetchOrganizationsSaga() {
  yield delay(DEBOUNCE_TIME_IN_MILLISECONDS);
  yield put(adminFetchOrganizations());
}

export function* watchAdminOrganizationsFiltersChange() {
  yield takeLatest(
    [
      adminSortOrganizations.getType(),
      adminFilterOrganizationsByName.getType(),
    ],
    adminFetchOrganizationsSaga,
  );
}
