import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import commandModalMessages from '../../../../messages/command.modal.messages';
import notificationMessages from '../../../../messages/notification.message';

export const ErrorContent: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormattedMessage
        defaultMessage={formatMessage(commandModalMessages.error)}
        id='datatron.modals.error'
        tagName='h3'
      />
      <span>{formatMessage(notificationMessages.something_happened)}</span>
    </>
  );
};
