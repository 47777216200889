import React, { useState } from 'react';
import { AbridgedCommand } from '../../../../../../common/types/api/datatron.devices';
import classnames from 'classnames';
import { JournalDisplay } from './JournalDisplay';

type Props = {
  command: AbridgedCommand,
};

export const CommandHistoryEntry: React.FC<Props> = ({ command }) => {
  const [isOpen, isOpenSet] = useState<boolean>(false);

  const toggle = () => {
    isOpenSet(!isOpen);
  };

  const toggleButtonClassName = classnames('icon', isOpen ? 'icon-caret-down' : 'icon-caret-left');
  return (
    <>
      <tr>
        <td>{command.id}</td>
        <td>{(new Date(command.timestamp)).toString()}</td>
        <td>{command.type}</td>
        <td>{command.latestState}</td>
        <td>{command.status}</td>
        <td>
          <span className="button__toggle  mr-10" onClick={toggle}>
            <i className={toggleButtonClassName}/>
          </span>
        </td>
      </tr>
      {isOpen && command.journal.map((entry) => <JournalDisplay key={entry.createdAt} journal={entry} />)}
    </>
  );
};