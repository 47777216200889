import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

export const Assistant = ({
  assistant,
  assistantLocationPath,
  handleRemoveAssistantClick,
  canDeleteAssistants,
  intl,
}) => {
  const onRemoveClick = () => handleRemoveAssistantClick(assistant);

  const noLocationLabel = intl.formatMessage({ id: 'common.not_selected', defaultMessage: 'Not selected' });
  const assistantLocation = (assistantLocationPath.length)
    ? assistantLocationPath.join(' > ') : noLocationLabel;

  return (
    <div className="assistance--item">
      <div className="assistance--icon">
        <a href="" className="assistance--icon--wrap">
          <span className="icon icon-person" />
        </a>
      </div>
      <div className="assistance--name">
        <p><strong>{assistant.name}</strong></p>
        <p>{assistant.position}</p>
      </div>
      <div className="assistance--contacts">
        <div className="assistance--contact">
          <div className="fa fa-phone" />
          <div>{assistant.phoneNumber}</div>
        </div>
        <div className="assistance--contact">
          <div className="fa fa-envelope" />
          <div>
            <a href={`mailto:${assistant.email}`}>
              {assistant.email}
            </a>
          </div>
        </div>
      </div>
      <div className="assistance--contacts">
        <div className="assistance--contact">
          <div className="icon icon-location" />
          <div>{assistantLocation}</div>
        </div>
        <div className="assistance--contact">
          <div className="icon icon-comment" />
          <div>{assistant.comment}</div>
        </div>
      </div>
      {
        canDeleteAssistants && (
          <button
            className="button light-grey-white assistance--remove"
            type="button"
            onClick={onRemoveClick}
          >
            <span className="icon-close2" />
          </button>
        )
      }
    </div>
  );
};

Assistant.propTypes = {
  assistant: PropTypes.object.isRequired,
  assistantLocationPath: PropTypes.array.isRequired,
  handleRemoveAssistantClick: PropTypes.func.isRequired,
  canDeleteAssistants: PropTypes.bool.isRequired,

};

export default injectIntl(Assistant);
