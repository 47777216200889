import { createAction } from 'redux-act';
import { push } from 'connected-react-router';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { isEmpty } from 'lodash';

import * as api from '../../core/api';
import * as form from '../constants/admin.user.form.constants';
import { getFormFields } from '../selectors/admin.user.form.selector';
import { getUser } from '../selectors/admin.users.selector';
import { getAdminUsersLink } from '../../components/index/routes';
import { compareById, replaceObjectInList } from '../../../../common/helpers/object';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';
import { validateNewPassword } from '../../core/validation/admin.user.form.validation';
import * as formTabs from '../../core/common/admin.user.form.tabs';
import { adminSelectUserFormTab } from './admin.user.tabs.module';
import { adminUpdateUserRoles } from './admin.user.update.roles.module';
import { getRolesChanges } from '../../core/common/admin.user.roles.helper';

export const adminUpdateUser = createAction(
  'admin: update user',
);

export const adminUpdateUserSuccess = createAction(
  'admin: update user - success',
  (item) => item,
);

export const adminUpdateUserFail = createAction(
  'admin: update user - fail',
  (error) => error,
);

export const reducer = {
  [adminUpdateUser]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        selected: {
          ...state.admin.users.selected,
          item: {
            ...state.admin.users.selected.item,
            _update: {
              loaded: false,
              loading: true,
              error: null,
            },
          },
        },
      },
    },
  }),

  [adminUpdateUserSuccess]: (state, user) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        list: replaceObjectInList(
          state.admin.users.list,
          user,
          compareById,
        ),
      },
    },
  }),

  [adminUpdateUserFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        selected: {
          ...state.admin.users.selected,
          item: {
            ...state.admin.users.selected.item,
            _update: {
              loaded: false,
              loading: false,
              error,
            },
          },
        },
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.ADMIN_USER_FORM_NAME,
    ...form.USER_PROPS,
  ));
}

export function* handleErrors(errors) {
  yield put(adminUpdateUserFail(errors));
  yield put(stopSubmit(form.ADMIN_USER_FORM_NAME, errors));

  const errorKeys = Object.keys(errors);
  const hasErrorsInBasic = form.BASIC_FIELDS.reduce((result, item) => result || errorKeys.indexOf(item) >= 0, false);
  const hasErrorsInAdvanced = form.ADVANCED_FIELDS.reduce((result, item) => result || errorKeys.indexOf(item) >= 0, false);

  if (hasErrorsInBasic) {
    yield put(adminSelectUserFormTab(formTabs.BASIC_TAB));
  } else if (hasErrorsInAdvanced) {
    yield put(adminSelectUserFormTab(formTabs.ADVANCED_TAB));
  }
}

export function* adminUpdateUserSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const user = yield call(getUser, state);
  const body = yield call(getFormFields, state);

  const preRequestValidationErrors = yield call(validateNewPassword, body);
  if (!isEmpty(preRequestValidationErrors)) {
    yield handleErrors(preRequestValidationErrors);
    return;
  }

  yield put(startSubmit(form.ADMIN_USER_FORM_NAME));
  const { response, error } = yield call(api.admin.updateUser, user.id, body);

  if (response) {
    const [rolesToRemove, rolesToAdd] = yield call(getRolesChanges, user.roles, body.roles);
    yield put(adminUpdateUserRoles(user.id, rolesToAdd, rolesToRemove));

    yield put(adminUpdateUserSuccess(response));
    yield put(stopSubmit(form.ADMIN_USER_FORM_NAME));
    yield put(push(getAdminUsersLink()));
    yield put(sendNotification({ ...notificationMessages.user_updated, values: { name: response.name } }));
  } else {
    yield handleErrors(error);
  }
}

export function* watchAdminUpdateUser() {
  yield takeEvery(adminUpdateUser.getType(), adminUpdateUserSaga);
}
