import { connect } from 'react-redux';

import Anomaly from './Anomaly';
import * as selector from '../../redux/selectors/anomaly.selector';

export function mapStateToProps(state, { params: { eventId } }) {
  return {
    isAnomalyLoading: selector.isLoading(state, eventId),
    isAnomalyLoaded: selector.isLoaded(state, eventId),
    event: selector.getEvent(state, eventId),
    anomaly: selector.getAnomaly(state, eventId),
  };
}

export default connect(mapStateToProps)(Anomaly);
