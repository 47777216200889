import { connect } from 'react-redux';

import NotificationComponent from './Notification';
import * as module from '../../redux/modules/notifications.module.js';
import {
  NOTIFICATION_ERROR, NOTIFICATION_EVENT,
} from '../../redux/constants/notification.constants';

export function mapStateToProps(state,
  {
    notification:
      {
        title, description, descriptionValues, id, type, titleValues,
      },
  }) {
  return {
    title,
    description,
    type,
    id,
    descriptionValues,
    titleValues,
    isError: type === NOTIFICATION_ERROR,
    isEvent: type === NOTIFICATION_EVENT,
  };
}

export const mapDispatchToProps = {
  remove: module.removeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);
