import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { getDatatron } from '../selectors/datatron.selector';
import * as api from '../../core/api';
import * as datatronUpdateModule from './datatron.update.module';

export const deleteDatatronPhoto = createAction(
  'delete datatron photo',
);

export function* deleteDatatronPhotoSaga() {
  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const { response, error } = yield call(api.datatrons.deleteDatatronPhoto, datatron.id);

  if (response) {
    yield put(datatronUpdateModule.updateDatatronSuccess({ imagePath: null }));
  } else {
    yield put(datatronUpdateModule.updateDatatronFail(error));
  }
}

export function* watchDeleteDatatronPhoto() {
  yield takeEvery(deleteDatatronPhoto.getType(), deleteDatatronPhotoSaga);
}
