import {
  find, cloneDeep, filter as _filter, forEach,
} from 'lodash';
import { createAction } from 'redux-act';
import {
  put, takeLatest, delay,
} from 'redux-saga/effects';

import { fetchEvents } from './dashboard.fetch.events.module';
import { fetchOee } from './dashboard.oee.fetch.module';
import { resetEventsPagination } from './dashboard.loadMode.events.module';
import { EMPTY_RESULTS } from '../constants/search.constants';
import { filterByDate, resetDates } from './dashboard.events.filter.dates.module';
import { clear } from './search.module';
import { replaceObjectInList, removeObjectFromList, compareById } from '../../../../common/helpers/object';

const DEBOUNCE_TIME_IN_MILLISECONDS = 1000;

export const addItemFilter = createAction(
  'add item filter (pending) to dashboard events',
  (filter) => filter,
);

export const removeItemFilter = createAction(
  'remove item filter (pending) from dashboard events',
  (filter) => filter,
);

export const removeItemFiltersByType = createAction(
  'remove item filters by type (pending) from dashboard events',
  (type) => type,
);

export const confirmFilters = createAction(
  'confirm pending filters',
);

export const resetPendingFilters = createAction(
  'reset pending filters',
);

export const clearFilters = createAction(
  'clear filters from dashboard events',
);

export const reducer = {
  [addItemFilter]: (state, { type, item }) => {
    const itemFilters = state.dashboard.itemFilters[type];
    const found = itemFilters.find((savedFilter) => savedFilter.id === item.id);

    if (found && found._isPending) return state;
    let newList = itemFilters;
    if (found && found._isPendingForRemove) {
      newList = replaceObjectInList(
        newList,
        { ...found, _isPending: true, _isPendingForRemove: false },
        compareById,
      );
    } else {
      newList = [
        ...newList,
        { ...item, _isPending: true },
      ];
    }

    return {
      ...state,
      dashboard: {
        ...state.dashboard,
        itemFilters: {
          ...state.dashboard.itemFilters,
          [type]: newList,
        },
      },
    };
  },

  [removeItemFilter]: (state, { type, item }) => {
    const itemFilters = state.dashboard.itemFilters[type];
    const itemFilter = find(itemFilters, (savedFilter) => savedFilter.id === item.id);

    if (!itemFilter) return state;

    let list = itemFilters;
    if (item._isPending) {
      list = removeObjectFromList(
        list,
        item,
        compareById,
      );
    } else {
      list = replaceObjectInList(
        itemFilters,
        {
          ...itemFilter,
          _isPendingForRemove: true,
        },
        compareById,
      );
    }

    return {
      ...state,
      dashboard: {
        ...state.dashboard,
        itemFilters: {
          ...state.dashboard.itemFilters,
          [type]: list,
        },
      },
    };
  },

  [removeItemFiltersByType]: (state, type) => {
    const itemFilters = state.dashboard.itemFilters[type];
    const newList = itemFilters.reduce((result, item) => {
      if (item._isPending) return result;
      return [
        ...result,
        {
          ...item,
          _isPendingForRemove: true,
        },
      ];
    }, []);

    return {
      ...state,
      dashboard: {
        ...state.dashboard,
        itemFilters: {
          ...state.dashboard.itemFilters,
          [type]: newList,
        },
      },
    };
  },

  [confirmFilters]: (state) => {
    const filters = cloneDeep(state.dashboard.itemFilters);

    forEach(
      filters,
      (list, type) => {
        filters[type] = list
          .filter((item) => !item._isPendingForRemove)
          .map((item) => ({
            ...item,
            _isPending: false,
          }));
      },
    );

    return {
      ...state,
      dashboard: {
        ...state.dashboard,
        itemFilters: filters,
        filterQuery: {},
      },
    };
  },

  [resetPendingFilters]: (state) => {
    const filters = cloneDeep(state.dashboard.itemFilters);
    const filterQuery = cloneDeep(state.dashboard.filterQuery);

    forEach(
      filters,
      (list, type) => {
        const restoredList = list
          .map((item) => ({
            ...item,
            _isPendingForRemove: false,
          }));
        filters[type] = _filter(
          restoredList,
          (item) => !item._isPending,
        );
        filterQuery[type] = '';
      },
    );

    return {
      ...state,
      dashboard: {
        ...state.dashboard,
        itemFilters: filters,
        filterQuery,
      },
    };
  },

  [clearFilters]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      itemFilters: {
        ...EMPTY_RESULTS,
      },
    },
  }),
};

export function* fetchEventsSaga() {
  yield put(resetEventsPagination());
  yield put(fetchEvents());
  yield put(fetchOee());
  yield put(clear());
}

export function* watchEventFilters() {
  yield takeLatest(
    [
      confirmFilters.getType(),
      clearFilters.getType(),
    ],
    fetchEventsSaga,
  );
}

export function* fetchEventsDebounceSaga() {
  yield delay(DEBOUNCE_TIME_IN_MILLISECONDS);

  yield put(resetEventsPagination());
  yield put(fetchEvents());
}

export function* watchEventFiltersDebounce() {
  yield takeLatest(
    [
      filterByDate.getType(),
      resetDates.getType(),
    ],
    fetchEventsDebounceSaga,
  );
}
