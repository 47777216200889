import * as experimentStatuses from '../../../../common/enums/experimentStatuses';
import * as measurementStatuses from '../../../../common/enums/measurementStatuses';
import { DEFAULT_TAB } from '../constants/experimentsPage.tabs.constants';

export const experimentsPageInitialState = {
  tab: DEFAULT_TAB,
  experiments: {
    list: [],
    pagination: null,
    loading: false,
    loaded: false,
    error: null,
    filters: {
      query: '',
      status: [
        experimentStatuses.PLANNED,
        experimentStatuses.IN_PROGRESS,
        experimentStatuses.COMPLETED,
        experimentStatuses.FAILED,
      ],
    },
  },
  measurements: {
    list: [],
    pagination: null,
    loading: false,
    loaded: false,
    error: null,
    filters: {
      status: [
        measurementStatuses.IN_PROGRESS,
        measurementStatuses.COMPLETED,
      ],
    },
  },
  measurementsByExperiment: {
    list: [],
    experimentId: null,
    loading: false,
    loaded: false,
    error: null,
  },
};

export const experimentsPageTestState = {
  tab: DEFAULT_TAB,
  experiments: {
    list: [
      {
        id: 123,
        name: 'name 123',
        machine: {
          id: 456,
          name: 'machine 456',
        },
      },
    ],
    pagination: {
      total: 1,
      current_page: 1,
      per_page: 10,
      total_pages: 1,
    },
    loading: false,
    loaded: true,
    error: null,
    filters: {
      query: 'hello',
      status: [
        experimentStatuses.PLANNED,
        experimentStatuses.IN_PROGRESS,
        experimentStatuses.COMPLETED,
        experimentStatuses.FAILED,
      ],
    },
  },
  measurements: {
    list: [
      {
        id: 789,
        name: 'name 789',
        machine: {
          id: 456,
          name: 'machine 456',
        },
      },
    ],
    pagination: {
      total: 1,
      current_page: 1,
      per_page: 10,
      total_pages: 1,
    },
    loading: false,
    loaded: false,
    error: null,
    filters: {
      status: [
        measurementStatuses.IN_PROGRESS,
        measurementStatuses.COMPLETED,
      ],
    },
  },
  measurementsByExperiment: {
    list: [
      {
        id: 456,
        name: 'experiment123, measurement456',
      },
    ],
    experimentId: 123,
    loading: false,
    loaded: true,
    error: null,
  },
};
