if (!process.env.DAD_IS_WEBPACK) {
  require('dotenv').config({ silent: true }); // eslint-disable-line global-require
}

module.exports = {
  appName: process.env.DAD_APP_NAME,
  customLogo: {
    enabled: !!process.env.DAD_APP_LOGO_PATH && !!process.env.DAD_APP_LOGO_SMALL_PATH,
    path: process.env.DAD_APP_LOGO_PATH || null,
    pathSmall: process.env.DAD_APP_LOGO_SMALL_PATH || null,
  },
  theme: process.env.DAD_APP_THEME || 'default',
  socket: {
    host: process.env.DAD_SOCKET_HOST,
    port: process.env.DAD_SOCKET_PORT,
  },
  locales: process.env.DAD_LOCALES ? process.env.DAD_LOCALES.split(',') : [],
  features: {
    selfSignUp: process.env.DAD_FEATURE_SELF_SIGNUP_ENABLED
      ? !!+process.env.DAD_FEATURE_SELF_SIGNUP_ENABLED : true,
    passwordReset: process.env.DAD_FEATURE_PASSWORD_RESET_ENABLED
      ? !!+process.env.DAD_FEATURE_PASSWORD_RESET_ENABLED : true,
    loginHistory: process.env.DAD_FEATURE_LOGIN_HISTORY_ENABLED
      ? !!+process.env.DAD_FEATURE_LOGIN_HISTORY_ENABLED : true,
    preview: process.env.DAD_FEATURE_PREVIEW_ENABLED === '1',
    hideOee: process.env.DAD_FEATURE_HIDE_OEE === '1',
    altasigmaIntegration: !!(process.env.DAD_ALTASIGMA_URL && process.env.DAD_ALTASIGMA_TOKEN),
    measurements: {
      downloadUrl: process.env.DAD_MEASUREMENTS_DOWNLOAD_URL || 'http://localhost',
    },
    experiments: (process.env.DAD_FEATURE_EXPERIMENTS_ENABLED?.toLowerCase() === 'true') || true,
  },
};
