import { createAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { scroller } from 'react-scroll';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Todo } from '../../../../common/types/common';
import { getInventoryLink } from '../../components/index/routes';
import * as datatronsAPI from '../../core/api/datatrons';
import { findFirstErrorField } from '../../core/common/machine';
import { validatePhoto } from '../../core/validation/datatrons.form.validation';
import * as dfc from '../constants/datatron.form.constants';
import { NO_FILE } from '../constants/fileUpload.component.constants';
import { getFormFields } from '../selectors/newDatatron.selector';
import { fetchInventory } from './inventory.fetch.module';
import { AppReducerMapBuilder } from './util';

export const createDatatron = createAction('create datatron');

export const createDatatronSuccess = createAction(
  'create datatron - success',
  (payload: Todo) => ({ payload }),
);

export const createDatatronFail = createAction(
  'create datatron - fail',
  (error: Todo) => ({ payload: error }),
);

export const reducer = AppReducerMapBuilder.new()
  .add(createDatatron, (state) => ({
    ...state,
    newDatatron: {
      ...state.newDatatron,
      creating: true,
    },
  }))
  .add(createDatatronSuccess, (state) => ({
    ...state,
    newDatatron: {
      ...state.newDatatron,
      creating: false,
    },
  }))
  .add(createDatatronFail, (state, error) => ({
    ...state,
    newDatatron: {
      ...state.newDatatron,
      creating: false,
      error,
    },
  }))
  .build();

export function* touchAllFieldsSaga() {
  yield put(touch(dfc.DATATRON_FORM_NAME, ...dfc.DATATRON_PROPS));
}

export function* getFields() {
  const state = yield select();
  const fields = getFormFields(state);

  if (fields[dfc.ORGANIZATION_FIELD]) {
    fields[dfc.ORGANIZATION_FIELD] = fields[dfc.ORGANIZATION_FIELD].value;
  }

  if (fields[dfc.PHOTO_FIELD] === NO_FILE) {
    delete fields[dfc.PHOTO_FIELD];
  }

  return fields;
}

export function* handleErrors(errors) {
  yield put(createDatatronFail(errors));
  yield put(stopSubmit(dfc.DATATRON_FORM_NAME, errors));
  const firstErrorField = yield findFirstErrorField(dfc.DATATRON_PROPS, errors);
  if (firstErrorField) {
    yield scroller.scrollTo(firstErrorField, {
      offset: dfc.DATATRON_FORM_ERROR_FIELD_OFFSET,
    });
  }
}

export function* createDatatronSaga() {
  yield call(touchAllFieldsSaga);
  const fields = yield call(getFields);

  yield put(startSubmit(dfc.DATATRON_FORM_NAME));

  const preRequestValidationErrors = yield call(validatePhoto, fields);
  if (!isEmpty(preRequestValidationErrors)) {
    yield handleErrors(preRequestValidationErrors);
    return;
  }

  const { response, error } = yield call(datatronsAPI.createDatatron, fields);

  if (response) {
    yield put(createDatatronSuccess(response));
    yield put(fetchInventory());
    yield put(stopSubmit(dfc.DATATRON_FORM_NAME));
    yield put(push(getInventoryLink()));
  } else {
    yield handleErrors(error);
  }
}

export function* watchCreateDatatron() {
  yield takeLatest(createDatatron, createDatatronSaga);
}
