import { find } from 'lodash';

import { SCHEDULE_CALENDAR, SHIFT_CALENDAR } from '../../../../common/enums/calendarTypes';
import * as formConstants from '../../redux/constants/machine.form.constants';
import { NO_FILE } from '../../redux/constants/fileUpload.component.constants';

const getCalendarByType = (calendars, type) => {
  const calendar = find(calendars, { type });
  return calendar || null;
};

export const getValuesFromMachine = (machine) => {
  const scheduleCalendar = getCalendarByType(machine.calendars, SCHEDULE_CALENDAR);
  const shiftCalendar = getCalendarByType(machine.calendars, SHIFT_CALENDAR);

  const values = {
    [formConstants.PHOTO_FIELD]: !machine.image ? NO_FILE : null,
    [formConstants.NAME_FIELD]: machine.name || '',
    [formConstants.TYPE_FIELD]: machine.type || '',
    [formConstants.VENDOR_FIELD]: machine.make || '',
    [formConstants.MODEL_FIELD]: machine.model || '',
    [formConstants.YEAR_FIELD]: machine.year || '',
    [formConstants.SERIAL_NUMBER_FIELD]: machine.serialNumber || '',
    [formConstants.NOTES_FIELD]: machine.notes || '',
    [formConstants.PERFORMANCE_MAX_FIELD]: machine.performanceAmountMax,
    [formConstants.PERFORMANCE_PLANNED_FIELD]: machine.performanceAmountWanted,
    [formConstants.CYCLE_SECONDS_PER_PART]: machine.cycleSecondsPerPart,
    [formConstants.OEE_SOURCE]: machine.oeeSource,
    [formConstants.OEE_THRESHOLD_GREEN]: machine.oeeThresholdGreen ? (machine.oeeThresholdGreen * 100) : '',
    [formConstants.OEE_THRESHOLD_YELLOW]: machine.oeeThresholdYellow ? (machine.oeeThresholdYellow * 100) : '',
    [formConstants.OEE_THRESHOLD_RED]: machine.oeeThresholdRed ? (machine.oeeThresholdRed * 100) : '',
    [formConstants.LOCATION_FIELD]: machine.location.id,
    [formConstants.DMC_CONFIG]: machine.dmcConfig ? JSON.stringify(machine.dmcConfig) : '',
    [formConstants.DMC_FLAG]: machine.oeePerformanceDataPointUsesDmc,
    [formConstants.ORGANIZATION_FIELD]: {
      label: machine.organization.name,
      value: machine.organization.id,
    },
    [formConstants.SCHEDULE_CALENDAR_FIELD]: scheduleCalendar ? ({
      label: scheduleCalendar.name,
      value: scheduleCalendar.id,
    }) : null,
    [formConstants.SHIFT_CALENDAR_FIELD]: shiftCalendar ? ({
      label: shiftCalendar.name,
      value: shiftCalendar.id,
    }) : null,
  };

  if (machine.oeeAvailabilityDataPoint) {
    values[formConstants.OEE_A_DATA_POINT_ID] = {
      label: machine.oeeAvailabilityDataPoint.dataPoint.label,
      value: machine.oeeAvailabilityDataPoint.dataPoint.id,
    };
  }

  if (machine.oeePerformanceDataPoint) {
    values[formConstants.OEE_P_DATA_POINT_ID] = {
      label: machine.oeePerformanceDataPoint.dataPoint.label,
      value: machine.oeePerformanceDataPoint.dataPoint.id,
    };
  }

  if (machine.oeeQualityDataPoint) {
    values[formConstants.OEE_Q_DATA_POINT_ID] = {
      label: machine.oeeQualityDataPoint.dataPoint.label,
      value: machine.oeeQualityDataPoint.dataPoint.id,
    };
  }

  if (machine.rfidDataPoint) {
    values[formConstants.RFID_DATA_POINT_ID] = {
      label: machine.rfidDataPoint.dataPoint.label,
      value: machine.rfidDataPoint.dataPoint.id,
    };
  }

  if (machine.analyticsCycleStartDataPoint) {
    values[formConstants.ANALYTICS_CYCLE_START_DATA_POINT_ID] = {
      label: machine.analyticsCycleStartDataPoint.dataPoint.label,
      value: machine.analyticsCycleStartDataPoint.dataPoint.id,
    };
  }

  if (machine.analyticsCycleEndDataPoint) {
    values[formConstants.ANALYTICS_CYCLE_END_DATA_POINT_ID] = {
      label: machine.analyticsCycleEndDataPoint.dataPoint.label,
      value: machine.analyticsCycleEndDataPoint.dataPoint.id,
    };
  }

  return values;
};
