import { PHOTO_FIELD } from '../../redux/constants/machine.form.constants';
import { validateFileSize } from './files.validation';

export const validateMachine = (body) => {
  const errors = {};

  if (body[PHOTO_FIELD]) {
    const fileError = validateFileSize(body[PHOTO_FIELD]);

    if (fileError) {
      errors[PHOTO_FIELD] = fileError;
    }
  }

  return errors;
};
