import { createAction } from 'redux-act';
import { push } from 'connected-react-router';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { ceil, isEmpty } from 'lodash';

import * as api from '../../core/api';
import { validatePassword } from '../../core/validation/admin.user.create.form.validation';
import * as form from '../constants/admin.user.add.form.constants';
import { getFormFields } from '../selectors/admin.user.add.form.selector';
import { getPagination } from '../selectors/admin.users.selector';
import { getAdminUsersLink } from '../../components/index/routes';
import { adminFetchUsers } from './admin.users.fetch.module';
import { adminResetUsersFilters } from './admin.users.sort.filter.module';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const adminCreateUser = createAction(
  'admin: create user',
);

export const adminCreateUserSuccess = createAction(
  'admin: create user - success',
  (item) => item,
);

export const adminCreateUserFail = createAction(
  'admin: create user - fail',
  (error) => error,
);

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.ADMIN_USER_ADD_FORM_NAME,
    ...form.USER_PROPS,
  ));
}

export function* handleErrors(errors) {
  yield put(adminCreateUserFail(errors));
  yield put(stopSubmit(form.ADMIN_USER_ADD_FORM_NAME, errors));
}

export function* adminCreateUserSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const body = yield call(getFormFields, state);
  yield put(startSubmit(form.ADMIN_USER_ADD_FORM_NAME));

  const preRequestValidationErrors = yield call(validatePassword, body);
  if (!isEmpty(preRequestValidationErrors)) {
    yield handleErrors(preRequestValidationErrors);
    return;
  }

  const { response, error } = yield call(api.admin.createUser, body);

  if (response) {
    yield put(adminCreateUserSuccess(response));
    yield put(stopSubmit(form.ADMIN_USER_ADD_FORM_NAME));
    yield put(push(getAdminUsersLink()));

    const pagination = yield call(getPagination, state);
    let pageNumberToFetch = 1;
    if (pagination && pagination.total_pages) {
      pageNumberToFetch = ceil((pagination.total + 1) / pagination.per_page);
    }
    yield put(adminResetUsersFilters());
    yield put(adminFetchUsers(pageNumberToFetch));
    yield put(sendNotification({ ...notificationMessages.user_added, values: { name: `${response.firstName} ${response.lastName}` } }));
  } else {
    yield handleErrors(error);
  }
}

export function* watchAdminCreateUser() {
  yield takeEvery(adminCreateUser.getType(), adminCreateUserSaga);
}
