import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import LocationContainer from './singleLocation/Location.container.js';
import OrganizationContainer from './organization/Organization.container.js';
import Loader from '../_tools/loader/Loader';
import * as routeHandlers from '../index/routeHandlers';

function LocationPage({ loading, loaded, match }) {
  // TODO: fix me, avoid suppressing the linter
  useEffect(() => {
    routeHandlers.onLocationEnter(match);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      { loaded && (
        <div className="col--1 location-page">
          <div className="subheader subheader--with--center">
            <div className="subheader-in">
              <div className="subheader--left" />
              <div className="subheader--right" />
            </div>
          </div>
          <div className="col--2--wrap">
            <div className="col--2 col--2--central scrolled">
              <div className="col--2--left">
                <LocationContainer />
              </div>
              <div className="col--2--right">
                <div className="col--height">
                  <OrganizationContainer />

                  <div className="col--in height40 hide">

                    <div className="col--title">
                      <FormattedMessage
                        id="teams.tags"
                        defaultMessage="Tags"
                      />
                    </div>

                    <div className="text-center">
                      tags here
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      { loading && <Loader show /> }
    </div>
  );
}

LocationPage.propTypes = {
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

export default LocationPage;
