import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { isEmpty } from 'lodash';

import * as form from '../constants/tools.edit.form.constants';
import * as activityTypes from '../constants/tools.activityTypes.constants';
import * as toolStates from '../../../../common/enums/toolStates';
import { TOOL_EDIT } from '../constants/modals.constants';
import { getFormFields } from '../selectors/tools.edit.form.selector';
import { getToolsPagination, getFilters } from '../selectors/tools.selector';
import * as api from '../../core/api';
import { closeModal } from './modals.module';
import { compareById, replaceObjectInList, removeObjectFromList } from '../../../../common/helpers/object';
import { validateEditTool } from '../../core/validation/tools.validation';
import { fetchTools } from './tools.fetch.module';

export const editTool = createAction(
  'edit tool',
  (tool) => tool,
);

export const editToolSuccess = createAction(
  'edit tool - success',
  (tool) => tool,
);

export const editToolFail = createAction(
  'edit tool - fail',
  (error) => error,
);

export const reducer = {
  [editToolSuccess]: (state, tool) => {
    let newMachineTools = [...state.machine.tools.list];
    if (!tool.machine || tool.machine.id !== state.machine.details.id) {
      newMachineTools = removeObjectFromList(state.machine.tools.list, tool, compareById);
    } else if (tool.machine.id === state.machine.details.id) {
      newMachineTools = replaceObjectInList(state.machine.tools.list, tool, compareById);
    }

    return {
      ...state,
      tools: {
        ...state.tools,
        all: {
          ...state.tools.all,
          list: replaceObjectInList(
            state.tools.all.list,
            tool,
            compareById,
          ),
        },
      },
      machine: {
        ...state.machine,
        tools: {
          ...state.machine.tools,
          list: newMachineTools,
        },
      },
    };
  },
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.EDIT_TOOL_FORM_NAME,
    ...form.TOOL_PROPS,
    ...form.NEW_ACTIVITY_PROPS,
  ));
}

export function* callActivityApi(tool, fields) {
  const activityType = fields[form.NA_TYPE];
  if (activityType === activityTypes.SET_AS_EXHAUSTED) return null;

  let response = null;

  switch (activityType) {
    case activityTypes.MOUNT: {
      const apiCall = yield call(api.tools.setToolToMachine, fields[form.NA_MACHINE], tool.id, { mount: true, comment: fields[form.NA_COMMENT] });
      if (apiCall && apiCall.response) response = apiCall.response;
      break;
    }
    case activityTypes.ASSIGN: {
      const apiCall = yield call(api.tools.setToolToMachine, fields[form.NA_MACHINE], tool.id, { mount: false, comment: fields[form.NA_COMMENT] });
      if (apiCall && apiCall.response) response = apiCall.response;
      break;
    }
    case activityTypes.UNMOUNT:
    case activityTypes.UNASSIGN: {
      const apiCall = yield call(api.tools.unsetToolFromMachine, tool.machine.id, tool.id, { comment: fields[form.NA_COMMENT] });
      if (apiCall && apiCall.response) response = apiCall.response;
      break;
    }
    default: break;
  }

  return response;
}

export function* handleErrors(errors) {
  yield put(editToolFail(errors));
  yield put(stopSubmit(form.EDIT_TOOL_FORM_NAME, errors));
}

export function* editToolSaga({ payload: tool }) {
  yield call(touchAllFieldsSaga);

  const state = yield select();
  const fields = yield call(getFormFields, state);
  yield put(startSubmit(form.EDIT_TOOL_FORM_NAME));

  if (fields[form.NA_TYPE] === activityTypes.SET_AS_EXHAUSTED) {
    fields.state = toolStates.EXHAUSTED;
  }

  const preRequestErrors = yield call(validateEditTool, fields);
  if (!isEmpty(preRequestErrors)) {
    yield handleErrors(preRequestErrors);
    return;
  }

  const { response, error } = yield call(api.tools.updateTool, tool.id, fields);

  if (response) {
    let finalResponse = response;
    const extraCallResponse = yield callActivityApi(tool, fields);
    if (!isEmpty(extraCallResponse)) finalResponse = extraCallResponse;

    yield put(editToolSuccess(finalResponse));
    yield put(stopSubmit(form.EDIT_TOOL_FORM_NAME));
    yield put(closeModal(TOOL_EDIT));

    const pagination = yield call(getToolsPagination, state);
    if (pagination) {
      const filters = yield call(getFilters, state);
      const newPagination = yield call(api.tools.getToolsPagination, filters);

      if (newPagination.response) {
        const pageToFetch = pagination.current_page > newPagination.response.pagination.total_pages ?
          newPagination.response.pagination.total_pages : pagination.current_page;
        yield put(fetchTools(pageToFetch));
      } else {
        yield put(fetchTools(pagination.current_page));
      }
    }
  } else {
    yield handleErrors(error);
  }
}

export function* watchEditTool() {
  yield takeEvery(editTool.getType(), editToolSaga);
}
