import { defineMessages } from 'react-intl';

export default defineMessages({
  serialNumber: {
    id: 'common.fields.serial_number',
    defaultMessage: 'Serial Number',
  },
  deviceUUID: {
    id: 'common.fields.device_uuid',
    defaultMessage: 'Device UUID',
  },
  deviceActive: {
    id: 'common.fields.device_active',
    defaultMessage: 'Active',
  },
  deviceType: {
    id: 'common.fields.deviceType',
    defaultMessage: 'Device Type',
  },
  networkName: {
    id: 'common.fields.networkName',
    defaultMessage: 'Network Name',
  },
  parent: {
    id: 'common.fields.parent',
    defaultMessage: 'Parent',
  },
  scaledValue: {
    id: 'common.fields.scaledValue',
    defaultMessage: 'Scaled Value',
  },
  rfid: {
    id: 'common.fields.rfid',
    defaultMessage: 'RFID',
  },
  cycleStart: {
    id: 'common.fields.cycle_start',
    defaultMessage: 'Cycle Start',
  },
  cycleEnd: {
    id: 'common.fields.cycle_end',
    defaultMessage: 'Cycle End',
  },
});
