import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import noDoubleClick from '../../../../_tools/stopPropagation';

export default class ColumnDialogMenuActions extends Component {
  constructor(props) {
    super(props);

    this.handleHide = this.handleHide.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
    this.handleResolve = this.handleResolve.bind(this);
  }

  handleHide(e) {
    const { hide, activityId, eventId } = this.props;

    noDoubleClick(e);

    if (eventId) {
      hide(eventId);
    } else {
      hide(activityId);
    }
  }

  handleAssign(event) {
    const {
      close, assign, assignee, activityId, organizationId,
    } = this.props;

    noDoubleClick(event);
    close();
    assign(activityId, organizationId, assignee);
  }

  handleResolve(event) {
    const { canResolve, resolve, close } = this.props;

    noDoubleClick(event);
    if (canResolve) {
      resolve();
    }
    close();
  }

  render() {
    const { canHide, canResolve, canAssign } = this.props;
    return (
      <ul ref={(node) => (this.node = node)}>
        {
          canAssign && (
            <li>
              <a className="filter-action" onClick={this.handleAssign} id="assignButton">
                <span className="flex">
                  <i className="icon-assign" />
                  <FormattedMessage
                    id="maintenance.stream.event.assign"
                    defaultMessage="Assign"
                  />
                </span>
              </a>
            </li>
          )
        }
        {
          canHide && (
            <li>
              <a className="filter-action" onClick={this.handleHide} id="hideButton">
                <span className="flex">
                  <i className="icon-hide" />
                  <FormattedMessage
                    id="maintenance.stream.event.hide"
                    defaultMessage="Hide"
                  />
                </span>
              </a>
            </li>
          )
        }
        {
          canResolve && (
            <li>
              <a className="filter-action" onClick={this.handleResolve} id="resolveButton">
                <span className="flex">
                  <i className="icon-wrench" />
                  <FormattedMessage
                    id="maintenance.stream.event.resolve"
                    defaultMessage="Resolve"
                  />
                </span>
              </a>
            </li>
          )
        }
      </ul>
    );
  }
}

ColumnDialogMenuActions.propTypes = {
  hide: PropTypes.func.isRequired,
  assign: PropTypes.func.isRequired,
  assignee: PropTypes.object,
  close: PropTypes.func.isRequired,
  resolve: PropTypes.func,
  organizationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  activityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  canHide: PropTypes.bool.isRequired,
  canAssign: PropTypes.bool.isRequired,
  canResolve: PropTypes.bool.isRequired,
};
