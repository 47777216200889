import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import commonMessages from '../../../../messages/common.messages';
import { Button } from '../../../_tools';

const Footer = ({
  onCancelClick,
  onDuplicateClick,
  isDuplicateButtonDisabled,
  isDuplicateButtonLoading,
}) => (
  <div className="modal-buttons">
    <Button
      type="button"
      className="button__cancel"
      onClick={onCancelClick}
    >
      <FormattedMessage {...commonMessages.cancel} />
    </Button>
    <Button
      type="submit"
      className="button__save"
      onClick={onDuplicateClick}
      disabled={isDuplicateButtonDisabled}
      showLoader={isDuplicateButtonLoading}
    >
      <FormattedMessage {...commonMessages.duplicate} />
    </Button>
  </div>
);

Footer.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  isDuplicateButtonDisabled: PropTypes.bool.isRequired,
  isDuplicateButtonLoading: PropTypes.bool.isRequired,
};

export default Footer;
