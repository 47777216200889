import React from 'react';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

export const SelectedItem = ({
  value, onClick,
}) => {
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onClick(value);
  };

  return (
    <li>
      <ListItem onClick={handleClick} title={value.name} />
    </li>
  );
};

SelectedItem.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SelectedItem;
