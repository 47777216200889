import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import PoweredBy from './PoweredBy';
import { getDashboardLink } from '../../index/routes';

const dashboardLink = getDashboardLink();

function Logo({ showPoweredBy, logoPath, logoSmallPath }) {
  return (
    <div className="header-logo">
      <Link to={dashboardLink} className="logo">
        <img
          src={logoPath}
          className="logo-normal"
          alt="Logo"
          role="presentation"
        />
        <img
          src={logoSmallPath}
          alt="Small Logo"
          className="logo-small"
        />
      </Link>
      { showPoweredBy && (<PoweredBy />) }
    </div>
  );
}

Logo.propTypes = {
  showPoweredBy: PropTypes.bool.isRequired,
  logoPath: PropTypes.string.isRequired,
  logoSmallPath: PropTypes.string.isRequired,
};

export default Logo;
