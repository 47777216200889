import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AbridgedCommand } from '../../../../../../common/types/api/datatron.devices';
import PaginationBar from '../../../common/paginationBar/PaginationBar';
import { CommandHistoryEntry } from './commandHistoryEntry';
import { selectCommandHistoryLength } from '../../../../redux/selectors/modals.selector';
import { useSelector } from 'react-redux';

type Props = {
  commandHistory: AbridgedCommand[] | string,
  pageCount: number, // current page
  choosePage: (number) => void,
};

export const CommandHistoryDisplay: React.FC<Props> = ({ commandHistory, pageCount, choosePage }) => {

  const maxPageCount = Math.ceil(useSelector(selectCommandHistoryLength) / 10);

  if (typeof commandHistory === 'string') {
    return <div>{commandHistory}</div>;
  }

  return (
    <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
      <table>
        <thead>
        <tr>
          <FormattedMessage
            id="datatron.modals.command_id_column_header"
            defaultMessage="Command ID"
            tagName="th"
          />
          <FormattedMessage
            id="datatron.modals.timestamp_column_header"
            defaultMessage="Timestamp"
            tagName="th"
          />
          <FormattedMessage
            id="datatron.modals.command_type_column_header"
            defaultMessage="Command"
            tagName="th"
          />
          <FormattedMessage
            id="datatron.modals.journal_state_column_header"
            defaultMessage="Journal State"
            tagName="th"
          />
          <FormattedMessage
            id="datatron.modals.command_status_column_header"
            defaultMessage="Command Status"
            tagName="th"
          />
          <th></th>
        </tr>
        </thead>
        <tbody>
        {commandHistory.map((entry) => <CommandHistoryEntry key={entry.id} command={entry}/>)}
        </tbody>
      </table>
      <PaginationBar total_pages={maxPageCount} current_page={pageCount}
                     choosePage={choosePage}/>
    </div>
  );
};