import { connect } from 'react-redux';

import BatchesHeader from './BatchesHeader';
import { getBatchSort } from '../../../../redux/selectors/batch.selector';
import { sortBatches, filterByDate } from '../../../../redux/modules/batch.sort.filter.module';

export const mapStateToProps = (state) => {
  const sort = getBatchSort(state);
  return {
    sortOption: sort.option,
    sortWay: sort.way,
  };
};

export const mapDispatchToProps = {
  onSortChange: sortBatches,
  filterByDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchesHeader);
