import { connect } from 'react-redux';

import Select from './Select';
import * as selector from '../../../../redux/selectors/search.selector';
import { search } from '../../../../redux/modules/search.module';
import { EXPERIMENTS } from '../../../../../../common/constants/entities';
import { initializeExperimentAsOption } from '../../../../core/common/experiment';

export const mapStateToProps = (state, { input }) => ({
  options: selector
    .getExperimentSearchResults(state)
    .map(initializeExperimentAsOption),
  isLoading: selector.loading(state),
  onChange: (option) => input.onChange(option),
});

export const mapDispatchToProps = (dispatch) => ({
  onInputChange: (query) => {
    dispatch(search(query, true, [EXPERIMENTS]));
    return undefined;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);
