import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form';

import TextareaWithError from './TextareaWithError';
import { getTranslationObject } from '../../../core/common/intl';

const TextareaFormField = ({
  label, name, placeholder, intl, ...props
}) => {
  const translatedTitle = label ? intl.formatMessage(getTranslationObject(label)) : '';
  const translatedPlaceholder = placeholder ? intl.formatMessage(getTranslationObject(placeholder)) : '';

  return (
    <label>
      { translatedTitle }
      <Field
        name={name}
        component={TextareaWithError}
        placeholder={translatedPlaceholder}
        {...props}
      />
    </label>
  );
};

TextareaFormField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,

};

export default injectIntl(TextareaFormField);
