import React from 'react';
import { FormattedMessage } from 'react-intl';

const ImagePlaceholder = () => (
  <div className="image-placeholder">
    <div className="image-placeholder-in">
      <p><img src="/img/photo-placeholder.svg" alt="" /></p>
      <p>
        <FormattedMessage
          id="common.no_image"
          defaultMessage="No Image"
          tagName="strong"
        />
      </p>
      {
          /*
            <span>
              <FormattedMessage
                id="image.drag_and_drop_to_upload_or"
                defaultMessage="Drag & Drop file to upload or" /> <a href="">
                <FormattedMessage id="image.browse_to_upload" defaultMessage="browse" />
              </a>
            </span>
           */
        }
    </div>
  </div>
);

export default ImagePlaceholder;
