import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MachineFormPageHeader from './header/MachineFormPageHeader';
import MachineFormContainer from './forms/MachineForm.container';
import { PHOTO_FIELD } from '../../redux/constants/machine.form.constants';
import { NO_FILE } from '../../redux/constants/fileUpload.component.constants';
import Unauthorized from '../pages/unauthorized/Unauthorized';
import MachineFormDetailsContainer from './forms/details/MachineFormDetails.container';
import * as routeHandlers from '../index/routeHandlers';

export default class MachineFormPage extends Component {
  static propTypes = {
    canCreateMachines: PropTypes.bool,
    backLink: PropTypes.string.isRequired,
    isAddForm: PropTypes.bool,
    isUpdateForm: PropTypes.bool,
    initialValues: PropTypes.object,
    initializeSearchableFields: PropTypes.func,
    detailsFormProps: PropTypes.object,
  };

  static defaultProps = {
    isAddForm: false,
    isUpdateForm: false,
    initialValues: {
      [PHOTO_FIELD]: NO_FILE,
    },
    detailsFormProps: {
      photoUrl: '',
      location: null,
    },
  };

  state = { fieldsInitialized: false };

  componentWillMount = () => {
    const {
      initializeSearchableFields, isUpdateForm, isAddForm, match,
    } = this.props;
    if (isUpdateForm && initializeSearchableFields) {
      initializeSearchableFields();
    }
    if (isAddForm) {
      routeHandlers.onNewMachineEnter();
    } else if (isUpdateForm) {
      routeHandlers.onMachineEditEnter(match);
    }
  };

  componentWillUnmount() {
    const { isUpdateForm, isAddForm } = this.props;

    if (isAddForm) {
      routeHandlers.onNewMachineLeave();
    } else if (isUpdateForm) {
      routeHandlers.onMachineEditLeave();
    }
  }

  render = () => {
    const {
      backLink,
      isAddForm,
      canCreateMachines,
      isUpdateForm,
      initialValues,
      detailsFormProps,
    } = this.props;

    return ((isAddForm && canCreateMachines) || isUpdateForm) ? (
      <div className="col--1">
        <MachineFormPageHeader
          backLink={backLink}
          isAddForm={isAddForm}
          isUpdateForm={isUpdateForm}
        />

        <MachineFormContainer
          isAddForm={isAddForm}
          isUpdateForm={isUpdateForm}
          initialValues={initialValues}
          backLink={backLink}
        >
          <MachineFormDetailsContainer
            {...detailsFormProps}
            canSelectDataPoints={isUpdateForm}
          />
        </MachineFormContainer>
      </div>
    ) : (
      <Unauthorized />
    );
  };
}
