import { connect } from 'react-redux';

import DatePickerMainButtons from '../../dateRangePickerV2/DatePickerMainButtons';
import { DATE_FROM, DATE_TO } from '../../../../../../common/constants';
import * as datesModes from '../../../../../../common/enums/dates.modes';
import { DEFAULT_RELATIVE } from '../../../../redux/constants/relative.constants';

export const mapStateToProps = (state, { input }) => ({
  [DATE_FROM]: (input && input.value && input.value[DATE_FROM]) ? input.value[DATE_FROM] : null,
  [DATE_TO]: (input && input.value && input.value[DATE_TO]) ? input.value[DATE_TO] : null,
  fromRelative: (input && input.value && input.value.fromRelative) ? input.value.fromRelative : DEFAULT_RELATIVE,
  toRelative: (input && input.value && input.value.toRelative) ? input.value.toRelative : DEFAULT_RELATIVE,
  datesMode: (input && input.value && input.value.datesMode) ? input.value.datesMode : datesModes.ABSOLUTE,
  onUpdate() {},
});

export default connect(mapStateToProps)(DatePickerMainButtons);
