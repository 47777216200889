import { createAction } from 'redux-act';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { getAIAppAnomalyFormFields } from '../selectors/aiApp.form.selector';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import * as api from '../../core/api';
import * as form from '../constants/aiApp.anomalyModule.form.constants';
import { closeModal } from './modals.module';
import { ANOMALY_MODEL_ADD } from '../constants/modals.constants';
import { fetchCycleDetectionDetails } from './aiApps.cycleDetection.details.fetch.module';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';
import { NOTIFICATION_EVENT } from '../../core/notifications';

export const createAIAppAnomaly = createAction('create AIAppAnomaly');

export const createAIAppAnomalySuccess = createAction(
  'create AIAppAnomaly - success',
  (item) => item,
);

export const createAIAppAnomalyFail = createAction('create AIAppAnomaly - fail', (error) => error);

export function* touchAllFieldsSaga() {
  yield put(touch(form.AI_APP_ANOMALY_FORM_NAME, ...form.AI_APP_ANOMALY_PROPS));
}

export function* handleErrors(errors) {
  yield put(createAIAppAnomalyFail(errors));
  yield put(stopSubmit(form.AI_APP_ANOMALY_FORM_NAME, errors));
}

export function* createAiAppAnomalySaga() {
  const state = yield select();
  yield call(touchAllFieldsSaga);

  const fields = yield call(getAIAppAnomalyFormFields, state);
  yield put(startSubmit(form.AI_APP_ANOMALY_FORM_NAME));
  const { cycle_detection_id: cycleDetectionId, ...otherFields } = fields;
  const { response, error } = yield call(
    api.aiApps.createAIAppAnomaly,
    cycleDetectionId,
    otherFields,
  );

  if (response) {
    yield put(createAIAppAnomalySuccess(response));
    yield put(fetchCycleDetectionDetails(cycleDetectionId));
    yield put(stopSubmit(form.AI_APP_ANOMALY_FORM_NAME));
    yield put(closeModal(ANOMALY_MODEL_ADD));
    yield put(
      sendNotification(
        notificationMessage.item_created_title,
        {
          ...notificationMessage.item_created_description,
          values: {
            itemName: 'Anomaly Model',
          },
        },
        NOTIFICATION_EVENT,
      ),
    );
  } else {
    yield handleErrors(error);
  }
}

export function* watchCreateAIAppAnomaly() {
  yield takeEvery(createAIAppAnomaly.getType(), createAiAppAnomalySaga);
}
