import React, { useCallback } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import Tab from './Tab';
import { Todo } from '../../../../../common/types/common';

export interface TabItem {
  id: string;
  label: MessageDescriptor;
  values?: {
    all: number;
    enabled: number;
  };
}

interface Props {
  tabList: TabItem[];
  selectTab: (id: Todo) => void;
  selectedTab: string;
}

export const TabSelector: React.FC<Props> = ({ tabList, selectTab, selectedTab }) => {
  const { formatMessage } = useIntl();

  const handleTabSelect = useCallback(
    (id) => (e: React.MouseEvent) => {
      e.preventDefault();
      selectTab(id);
    },
    [selectTab],
  );

  return (
    <div className='big-tabs'>
      <div className='big-tabs-list'>
        <div className='big-tabs-scroll'>
          <div className='big-tabs-scroll-in'>
            {tabList.map((tab, index) => (
              <Tab
                key={tab.id}
                label={formatMessage(tab.label, tab.values || {})}
                onClick={handleTabSelect(tab.id)}
                isActive={selectedTab === tab.id}
                tabIndex={index + 1}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabSelector;
