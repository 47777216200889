import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const searchUsers = createAction(
  'search users',
  (query) => query,
);

export const searchUsersSuccess = createAction(
  'search users- success',
  (users) => users,
);

export const searchUsersFail = createAction(
  'search users - Fail',
  (error) => error,
);

export const reducer = {
  [searchUsers]: (state, query) => ({
    ...state,
    usersSearch: {
      ...state.usersSearch,
      loading: true,
      loaded: false,
      error: '',
      query,
    },
  }),
  [searchUsersSuccess]: (state, users) => ({
    ...state,
    usersSearch: {
      ...state.usersSearch,
      loading: false,
      loaded: true,
      users,
    },
  }),
  [searchUsersFail]: (state, error) => ({
    ...state,
    usersSearch: {
      ...state.usersSearch,
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* searchUsersSaga({ payload: query }) {
  const { response, error } = yield call(api.users.search, query);

  if (response) {
    yield put(searchUsersSuccess(response));
  } else {
    yield put(searchUsersFail(error));
  }
}

export function* watchForSearchUsers() {
  yield takeEvery(searchUsers.getType(), searchUsersSaga);
}
