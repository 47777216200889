import { connect } from 'react-redux';

import UnitSelect from './UnitSelect';
import relativeUnits from '../../../../../../../../common/constants/relative.units';
import messages from '../../../../../../messages/relative.units.messages';

export const mapStateToProps = () => ({
  options: relativeUnits.map((type) => ({
    label: messages[type],
    value: type,
  })),
});

export default connect(mapStateToProps)(UnitSelect);
