import { connect } from 'react-redux';

import AdminPageTabs from './AdminPageTabs';
import {
  getAdminAccountsLink,
  getAdminOrganizationsLink,
  getAdminLocationsLink,
  getAdminUsersLink,
  getAdminRolesLink,
} from '../../index/routes';
import * as adminOrganizationsSelector from '../../../redux/selectors/admin.organizations.selector';
import * as adminLocationsSelector from '../../../redux/selectors/admin.locations.selector';
import * as adminUsersSelector from '../../../redux/selectors/admin.users.selector';
import * as adminAccountsSelector from '../../../redux/selectors/admin.accounts.selector';
import * as adminRolesSelector from '../../../redux/selectors/admin.roles.selector';

export const mapStateToProps = (state, {
  hasAccountsAccess,
  hasOrganizationsAccess,
  hasLocationsAccess,
  hasUsersAccess,
  hasRolesAccess,
  currentPath,
}) => ({
  canUseAccountsTab: hasAccountsAccess,
  canUseOrganizationsTab: hasOrganizationsAccess,
  canUseLocationsTab: hasLocationsAccess,
  canUseUsersTab: hasUsersAccess,
  canUseRolesTab: hasRolesAccess,
  isAccountsTabSelected: currentPath.indexOf(getAdminAccountsLink()) >= 0,
  isOrganizationsTabSelected: currentPath.indexOf(getAdminOrganizationsLink()) >= 0,
  isLocationsTabSelected: currentPath.indexOf(getAdminLocationsLink()) >= 0,
  isUsersTabSelected: currentPath.indexOf(getAdminUsersLink()) >= 0,
  isRolesTabSelected: currentPath.indexOf(getAdminRolesLink()) >= 0,
  accountsCount: adminAccountsSelector.getTotalCount(state),
  organizationsCount: adminOrganizationsSelector.getTotalCount(state),
  locationsCount: adminLocationsSelector.getTotalCount(state),
  usersCount: adminUsersSelector.getTotalCount(state),
  rolesCount: adminRolesSelector.getTotalCount(state),
});

export default connect(mapStateToProps)(AdminPageTabs);
