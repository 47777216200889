import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import ColumnDnd from '../column/Column.dnd';
import * as selectors from '../../../redux/selectors/maintenance.selector';
import {
  COLUMN_INBOX,
} from '../../../redux/constants/column.constants';
import messages from '../../../messages/MaintenanceColumnHeader.messages';

export function mapStateToProps(state) {
  const events = selectors.getFilteredInboxEvents(state);
  return {
    cards: events,
    title: messages.issues,
    type: COLUMN_INBOX,
    noCards: isEmpty(events),
    isDragging: selectors.isDragging(state),
    entriesAmount: selectors.inboxEventsAmount(state),
  };
}

export default connect(mapStateToProps)(ColumnDnd);
