import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import ModalLine from '../../core/ModalLine';
import AddModalFooter from '../../core/footers/AddModalFooter';
import EditModalFooter from '../../core/footers/EditModalFooter';
import Form from './form/Form';
import Measurements from './measurements/Measurements.container';
import messages from '../../../../messages/experimentsPage.messages';
import FormTabs from '../../../common/formTabs/FormTabs';
import tabsMessages from './ExperimentModal.tabs.messages';
import * as tabs from './ExperimentModal.tabs';

export class ExperimentModal extends Component {
  state = { activeTab: tabs.FORM_TAB };

  static propTypes = {
    close: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isAddForm: PropTypes.bool,
    isEditForm: PropTypes.bool,
    useTabs: PropTypes.bool,
    experimentId: PropTypes.string,

  };

  static defaultProps = {
    isAddForm: false,
    isEditForm: false,
    useTabs: false,
  };

  constructor() {
    super();

    this.setActiveTab = this.setActiveTab.bind(this);
  }

  setActiveTab(activeTab) {
    this.setState({ activeTab });
  }

  getTabsList() {
    const { intl } = this.props;
    return [
      {
        label: intl.formatMessage(tabsMessages[tabs.FORM_TAB]),
        isActive: this.state.activeTab === tabs.FORM_TAB,
        onClick: () => this.setActiveTab(tabs.FORM_TAB),
      },
      {
        label: intl.formatMessage(tabsMessages[tabs.MEASUREMENTS_TAB]),
        isActive: this.state.activeTab === tabs.MEASUREMENTS_TAB,
        onClick: () => this.setActiveTab(tabs.MEASUREMENTS_TAB),
      },
    ];
  }

  getTitle() {
    const { isAddForm, isEditForm, intl } = this.props;
    if (isAddForm) {
      return intl.formatMessage(messages.addExperimentModalTitle);
    } if (isEditForm) {
      return intl.formatMessage(messages.editExperimentModalTitle);
    }
    return null;
  }

  getFooter() {
    const {
      isAddForm, isEditForm, handleSubmit, close, submitting, pristine,
    } = this.props;
    const { activeTab } = this.state;
    if (isAddForm) {
      return (
        <AddModalFooter
          onAddClick={handleSubmit}
          onCancelClick={close}
          isAddButtonLoading={submitting}
          isAddButtonDisabled={submitting || pristine}
          cancelButtonTabIndex={9}
          addButtonTabIndex={10}
        />
      );
    } if (isEditForm) {
      return (
        <EditModalFooter
          onSaveClick={handleSubmit}
          onCancelClick={close}
          isSaveButtonLoading={submitting}
          isSaveButtonDisabled={submitting || pristine || activeTab === tabs.MEASUREMENTS_TAB}
          cancelButtonTabIndex={9}
          saveButtonTabIndex={10}
        />
      );
    }
    return null;
  }

  render() {
    const {
      experimentId, handleSubmit, isEditForm, close, useTabs,
    } = this.props;
    const { activeTab } = this.state;
    return (
      <BasicModal isOpen close={close} modalClass="modal--lg">
        <div className="modal--content">
          <h3 className="first-title">{ this.getTitle() }</h3>
          <form className="form" onSubmit={handleSubmit}>
            { useTabs && (
              <FormTabs tabs={this.getTabsList()} />
            )}
            { (activeTab === tabs.FORM_TAB) && (<Form canSelectStatus={!!isEditForm} />) }
            { (activeTab === tabs.MEASUREMENTS_TAB) && (<Measurements experimentId={experimentId} />) }
            <ModalLine />
            {this.getFooter()}
          </form>
        </div>
      </BasicModal>
    );
  }
}

export default injectIntl(ExperimentModal);
