import {
  forEach, sum, nth, isEmpty,
} from 'lodash';

export const calculateOee = (oeeArray = []) => {
  const input = {
    availability: [],
    performance: [],
    quality: [],
  };
  const chartsMap = {};

  forEach(
    oeeArray,
    (oeeItem) => {
      if (oeeItem.availability !== undefined
        && oeeItem.performance !== undefined
        && oeeItem.quality !== undefined
      ) {
        input.availability.push(oeeItem.availability);
        input.performance.push(oeeItem.performance);
        input.quality.push(oeeItem.quality);
      }

      if (oeeItem.charts) {
        forEach(
          oeeItem.charts,
          (c) => {
            if (!chartsMap[c.start]) {
              chartsMap[c.start] = {
                ...c,
                availabilityCount: c.availability.map((item) => (item === null ? 0 : 1)),
                performanceCount: c.performance.map((item) => (item === null ? 0 : 1)),
                qualityCount: c.quality.map((item) => (item === null ? 0 : 1)),
              };
            } else {
              let longerListSize = c.availability.length;
              if (c.performance.length > longerListSize) {
                longerListSize = c.performance.length;
              }
              if (c.quality.length > longerListSize) {
                longerListSize = c.quality.length;
              }

              forEach(
                (new Array(longerListSize)).fill(0),
                (i, index) => {
                  const a = nth(c.availability, index);
                  if (typeof a === 'number') {
                    const current = chartsMap[c.start].availability[index];
                    const currentCount = chartsMap[c.start].availabilityCount[index];

                    chartsMap[c.start].availability[index] = typeof current === 'number' ? current + a : a;
                    chartsMap[c.start].availabilityCount[index] = typeof currentCount === 'number' ? currentCount + 1 : 1;
                  }

                  const p = nth(c.performance, index);
                  if (typeof a === 'number') {
                    const current = chartsMap[c.start].performance[index];
                    const currentCount = chartsMap[c.start].performanceCount[index];

                    chartsMap[c.start].performance[index] = typeof current === 'number' ? current + p : p;
                    chartsMap[c.start].performanceCount[index] = typeof currentCount === 'number' ? currentCount + 1 : 1;
                  }

                  const q = nth(c.quality, index);
                  if (typeof a === 'number') {
                    const current = chartsMap[c.start].quality[index];
                    const currentCount = chartsMap[c.start].qualityCount[index];

                    chartsMap[c.start].quality[index] = typeof current === 'number' ? current + q : q;
                    chartsMap[c.start].qualityCount[index] = typeof currentCount === 'number' ? currentCount + 1 : 1;
                  }
                },
              );
            }
          },
        );
      }
    },
  );

  const result = {
    total: {
      availability: input.availability.length > 0 ? (sum(input.availability) / input.availability.length) : null,
      performance: input.performance.length > 0 ? (sum(input.performance) / input.performance.length) : null,
      quality: input.quality.length > 0 ? (sum(input.quality) / input.quality.length) : null,
    },
    chart: [],
    chartsMap,
  };

  if (!isEmpty(chartsMap)) {
    result.chart = Object
      .values(chartsMap)
      .map((item) => ({
        ...item,
        availability: item.availability.map((i, index) => {
          const count = item.availabilityCount[index];
          if (count > 0) {
            return i / count;
          }
          return null;
        }),
        performance: item.performance.map((i, index) => {
          const count = item.performanceCount[index];
          if (count > 0) {
            return i / count;
          }
          return null;
        }),
        quality: item.quality.map((i, index) => {
          const count = item.qualityCount[index];
          if (count > 0) {
            return i / count;
          }
          return null;
        }),
      }));
  }

  return result;
};
