import { connect } from 'react-redux';

import TableQuerySearchState from '../../../../common/tableQuerySearch/TableQuerySearchState';
import { getNameFilterInputValue } from '../../../../../redux/selectors/batch.selector';
import { filterByName } from '../../../../../redux/modules/batch.sort.filter.module';

export const mapStateToProps = (state) => ({
  value: getNameFilterInputValue(state),
});

export const mapDispatchToProps = {
  onQueryChange: filterByName,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableQuerySearchState);
