import React from 'react';
import { useIntl } from 'react-intl';
import { CommandState } from './ActionsDataPointModal';
import commonMessages from '../../../../messages/common.messages';

interface DefaultContentProps {
  onCloseModal: () => void;
  onSubmit: () => void;
  commandState: CommandState;
}

export const ActionFooterButtons: React.FC<DefaultContentProps> = ({
  onCloseModal,
  onSubmit,
  commandState,
}) => {
  const { formatMessage } = useIntl();

  const buttonText =
    commandState === 'default' || commandState === 'submitting'
      ? formatMessage(commonMessages.cancel)
      : formatMessage(commonMessages.close);

  return (
    <>
      <div className='text-center'>
        <button type='button' className='button__cancel' onClick={onCloseModal}>
          {buttonText}
        </button>
        {commandState === 'default' && (
          <button type='submit' className='button__save' onClick={onSubmit}>
            {formatMessage(commonMessages.save)}
          </button>
        )}
      </div>
    </>
  );
};
