import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import OrganizationRowContainer from './OrganizationRow.container';
import OrganizationsHeaderContainer from './header/OrganizationsHeader.container';
import PaginationBar from '../../../common/paginationBar/PaginationBar';
import * as routeHandlers from '../../../index/routeHandlers';

export const OrganizationsModule = ({
  list, nameQuery, pagination, paginationAvailable, choosePage, route, history,
}) => {
  useEffect(() => () => {
    routeHandlers.onAdminOrganizationsLeave();
  }, []);
  return (
    <div className="user-table">
      <table className="table">
        <OrganizationsHeaderContainer />
        <tbody>
          { list.map((item) => <OrganizationRowContainer key={item.id} organization={item} nameToHighlight={nameQuery} pushPath={history.push} />) }
        </tbody>
      </table>
      { paginationAvailable && (<PaginationBar {...pagination} choosePage={choosePage} />) }
      { renderRoutes(route.routes) }
    </div>
  );
};

OrganizationsModule.propTypes = {
  route: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  paginationAvailable: PropTypes.bool.isRequired,
  choosePage: PropTypes.func.isRequired,
};

OrganizationsModule.defaultProps = {
  nameQuery: '',
  pagination: {},
};

export default OrganizationsModule;
