import { defineMessages } from 'react-intl';

const messages = defineMessages({
  collectModalTitle: {
    id: 'datatron.modals.collect_device.title',
    defaultMessage: 'Start the data collection',
  },
  stopModalTitle: {
    id: 'datatron.modals.stop_device.title',
    defaultMessage: 'Stop the data collection',
  },
  publishModalTitle: {
    id: 'datatron.modals.publish_device.title',
    defaultMessage: 'Publish the enabled data points',
  },
  submitting: {
    id: 'datatron.modals.submitting',
    defaultMessage: 'Submitting...',
  },
  info: {
    id: 'datatron.modals.info',
    defaultMessage: 'Info!',
  },
  success: {
    id: 'datatron.modals.success',
    defaultMessage: 'Success!',
  },
  error: {
    id: 'datatron.modals.error',
    defaultMessage: 'Error!',
  },
  changeSetting: {
    id: 'datatron.modals.change_setting',
    defaultMessage: 'Change setting for ',
  },
  status: {
    id: 'datatron.modals.status',
    defaultMessage: 'Status',
  },
  skipped: {
    id: 'datatron.modals.status.skipped',
    defaultMessage: 'Skipped',
  },
  completed: {
    id: 'datatron.modals.status.completed',
    defaultMessage: 'Completed',
  },
  failed: {
    id: 'datatron.modals.status.failed',
    defaultMessage: 'Failed',
  },
});

export default messages;
