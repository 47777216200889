import React from 'react';
import PropTypes from 'prop-types';

export const Buttons = ({
  canEditExperiment,
  canArchiveExperiment,
  openEditModal,
  openArchiveModal,
  openDownloadModal,
}) => (
  <td className="td-edit td-edit-fixed">
    <div className="td-buttons">
      { canEditExperiment && (
        <button className="button button__xs light-grey-white" onClick={openEditModal}>
          <i className="icon-edit" />
        </button>
      ) }
      { canArchiveExperiment && (
        <button className="button button__xs light-grey-white" onClick={openArchiveModal}>
          <i className="icon-archive1" />
        </button>
      ) }
      <button className="button button__xs light-grey-white" onClick={openDownloadModal}>
        <i className="icon-load" />
      </button>
    </div>
  </td>
);

Buttons.propTypes = {
  openEditModal: PropTypes.func.isRequired,
  openArchiveModal: PropTypes.func.isRequired,
  openDownloadModal: PropTypes.func.isRequired,
  canEditExperiment: PropTypes.bool.isRequired,
  canArchiveExperiment: PropTypes.bool.isRequired,
};

export default Buttons;
