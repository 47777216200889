import { connect } from 'react-redux';

import OULocations from './OULocations';
import * as selector from '../../redux/selectors/ou.selector';

export function mapStateToProps(state) {
  return {
    locations: selector.locations(state),
  };
}

export default connect(mapStateToProps)(OULocations);
