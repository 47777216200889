import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const FileUploadPlaceholder = ({ placeholder }) => (
  <>
    <div>
      <span className="plus-block">
        <span className="fa fa-plus" />
      </span>
      <FormattedMessage
        id={placeholder}
        tagName="div"
      />
    </div>
  </>
);

FileUploadPlaceholder.propTypes = {
  placeholder: PropTypes.string.isRequired,
};

export default FileUploadPlaceholder;
