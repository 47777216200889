/*
 * TODO: The types and interfaces of this file were created for the gradual conversion
 * from JavaScript to TypeScript and may need to be changed, when the landscape
 * of the application is clearer
 */

import { AppState } from '../state/app.state';

type Selector<A extends any[], T> = (state: Readonly<AppState>, ...args: A) => T;

/**
 * A helper function to create a selector with inferred return types
 * @param selectFunction the selector function
 */
export const selector = <A extends any[], T>(
  selectFunction: Selector<A, T>,
): Selector<A, T> => selectFunction;
