import { connect } from 'react-redux';

import AddUserFormModule from './AddUserFormModule';
import { adminCreateUser } from '../../../../../../redux/modules/admin.user.create.module';

export const mapDispatchToProps = {
  adminCreateUser,
};

export default connect(null, mapDispatchToProps)(AddUserFormModule);
