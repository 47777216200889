import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValuesFromDatatronDevice } from '../../../../core/common/datatron.device.form';
import { getFieldsBasedOnConfig } from '../../../../core/common/datatron.deviceType';
import { getInitialValue } from '../../../../core/common/dynamicField';
import { DP_EDIT_DEVICE } from '../../../../redux/constants/modals.constants';
import { updateDevice } from '../../../../redux/modules/datatron.devices.update.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { getDeviceTypeById } from '../../../../redux/selectors/datatron.selector';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { selector } from '../../../../redux/selectors/util';
import { AppState } from '../../../../redux/state/app.state';
import DeviceFormModalContainer from './DeviceFormModal.container';

const modalPayloadSelector = selector((state) =>
  getModalPayload(state, DP_EDIT_DEVICE),
);

export const EditDeviceModal: React.FC = () => {
  const { device } = useSelector(modalPayloadSelector);
  const initialValues = useMemo(
    () => getValuesFromDatatronDevice(device),
    [device],
  );
  const deviceType = useSelector((state: AppState) =>
    getDeviceTypeById(state, device.deviceType.id),
  );
  const deviceFields =
    deviceType && deviceType.hasDeviceSchema ? deviceType.deviceFields : [];
  const configFields = getFieldsBasedOnConfig(
    device.config || {},
    deviceFields,
  );
  const deviceConfigInitialValues = configFields.reduce(
    (result, item) => ({
      ...result,
      [item.field._key]: getInitialValue(item),
    }),
    {},
  );

  const dispatch = useDispatch();
  return (
    <DeviceFormModalContainer
      isEditForm={true}
      loaded={true}
      initialValues={{
        ...initialValues,
        ...deviceConfigInitialValues,
      }}
      deviceClass={device.deviceType.deviceClass}
      close={() => dispatch(closeModal(DP_EDIT_DEVICE))}
      updateDevice={() => dispatch(updateDevice(device))}
    />
  );
};

export default EditDeviceModal;
