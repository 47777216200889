import { isEmpty } from 'lodash';
import { createAction } from 'redux-act';
import {
  put, select, call, takeEvery,
} from 'redux-saga/effects';
import { goBack } from 'connected-react-router';
import moment from 'moment';
import { touch, stopSubmit, startSubmit } from 'redux-form';
import { scroller } from 'react-scroll';

import * as profile from '../../core/common/profile.js';
import { validateNewPassword } from '../../core/validation/profile.validation';
import * as accountsApi from '../../core/api/accounts';
import * as localeModule from './locale.module';
import { NOTIFICATION_EVENT } from '../../core/notifications';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';
import * as profileForm from '../../core/forms/profile.form';
import { findFirstErrorField } from '../../core/common/machine';
import { resetProfilePageForm } from './profile.page.form.module';
import { getUser } from '../selectors/user.selector';

export const saveProfile = createAction(
  'save profile changes',
);

export const saveProfileSuccess = createAction(
  'save profile changes - success',
  (user) => user,
);

export const saveProfileFail = createAction(
  'save profile changes - fail',
  (errors) => errors,
);

export const cancel = createAction(
  'cancel profile changes',
);

export const reducer = {
  [saveProfile]: (state) => ({
    ...state,
    profile: {
      ...state.profile,
      _update: {
        ...state.profile._update,
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),
  [saveProfileSuccess]: (state, user) => ({
    ...state,
    profile: {
      ...state.profile,
      user,
      _update: {
        ...state.profile._update,
        loading: false,
        loaded: true,
        error: null,
      },
    },
  }),
  [saveProfileFail]: (state, error) => ({
    ...state,
    profile: {
      ...state.profile,
      _update: {
        ...state.profile._update,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* saveProfileSaga() {
  yield put(touch(profileForm.profileForm, ...profileForm.formFields));
  yield put(startSubmit(profileForm.profileForm));

  const state = yield select();

  const isPasswordChanged = profile.isPasswordChanged(state);
  const isLocationChanged = profile.isLocationChanged(state);
  const isLanguageChanged = profile.isLanguageChanged(state);

  let payload = {};

  if (isPasswordChanged) {
    const passwords = yield call(profile.getPasswords, state);

    const preRequestValidationErrors = yield call(validateNewPassword, passwords);
    if (!isEmpty(preRequestValidationErrors)) {
      yield put(saveProfileFail(preRequestValidationErrors));
      return;
    }

    payload = { ...payload, ...passwords };
  }

  if (isLocationChanged) {
    payload.locationId = yield call(profile.getLocationId, state);
  }

  if (isLanguageChanged) {
    payload.locale = yield call(profile.getLanguage, state);
  }

  if (isEmpty(payload)) return;

  const { response, error } = yield call(accountsApi.updateUser, payload);

  if (response) {
    yield put(saveProfileSuccess(response));

    const user = yield call(getUser, state);

    if (isLanguageChanged) {
      const newLocale = response.locale;

      yield put(localeModule.changeLocale(newLocale));
      moment.locale(newLocale);

      yield put(sendNotification(
        user.firstName,
        notificationMessage.profile_your_locale_has_been_changed,
        NOTIFICATION_EVENT,
      ));
    }

    if (isLocationChanged) {
      yield put(sendNotification(
        user.firstName,
        notificationMessage.notifications_description_profile_your_location_has_been_changed,
        NOTIFICATION_EVENT,
      ));
    }

    if (isPasswordChanged) {
      yield put(sendNotification(
        user.firstName,
        notificationMessage.notifications_description_profile_your_password_has_been_changed,
        NOTIFICATION_EVENT,
      ));
    }

    yield put(stopSubmit(profileForm.profileForm));
    yield put(resetProfilePageForm());
  } else {
    yield put(saveProfileFail(error));
  }
}

export function* watchSaveChanges() {
  yield takeEvery(saveProfile.getType(), saveProfileSaga);
}

export function* cancelChangesSaga() {
  yield put(goBack());
}

export function* watchCancel() {
  yield takeEvery(cancel.getType(), cancelChangesSaga);
}

export function* saveProfileFailSaga({ payload: errors }) {
  yield put(stopSubmit(profileForm.profileForm, errors));

  const firstErrorField = yield findFirstErrorField(profileForm.formFields, errors);
  if (firstErrorField) {
    yield scroller.scrollTo(firstErrorField, { offset: -120 });
  }
}

export function* watchSaveProfileFail() {
  yield takeEvery(saveProfileFail.getType(), saveProfileFailSaga);
}
