import { createAction } from '@reduxjs/toolkit';
import {
  call, put, takeLatest, delay,
} from 'redux-saga/effects';

import * as api from '../../core/api';

export const searchUsersByOrganization = createAction(
  'search users by organization',
  (organizationId, query) => ({ payload: { organizationId, query } }),
);

export const searchUsersByOrganizationSuccess = createAction(
  'search users by organization - success',
  (users) => ({ payload: users }),
);

export const searchUsersByOrganizationFail = createAction(
  'search users by organization - fail',
  (error) => ({ payload:error }),
);

export const reducer = {
  [searchUsersByOrganization.type]: (state, { organizationId, query }) => ({
    ...state,
    usersSearch: {
      ...state.usersSearch,
      loading: true,
      loaded: false,
      error: '',
      query,
      organizationId,
    },
  }),
  [searchUsersByOrganizationSuccess.type]: (state, users) => ({
    ...state,
    usersSearch: {
      ...state.usersSearch,
      loading: false,
      loaded: true,
      users,
    },
  }),
  [searchUsersByOrganizationFail.type]: (state, error) => ({
    ...state,
    usersSearch: {
      ...state.usersSearch,
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* searchUsersByOrganizationSaga({ payload: { query, organizationId } }) {
  yield delay(500); // debouncing

  const { response, error } = yield call(api.users.searchByOrganization, organizationId, query);

  if (response) {
    yield put(searchUsersByOrganizationSuccess(response));
  } else {
    yield put(searchUsersByOrganizationFail(error));
  }
}

export function* watchForSearchUsersByOrganization() {
  yield takeLatest(searchUsersByOrganization, searchUsersByOrganizationSaga);
}
