import React  from 'react';
import PropTypes from 'prop-types';

import IndicatorsContainer from './Indicators.container';
import Graph from '../../../common/cardGraph/Graph';
import LoaderLocal from '../../../_tools/loader/LoaderLocal';

const CardOee = ({
  loaded, loading, report, startDateTime, endDateTime,
}) => {
  if (loading) return (<LoaderLocal />);
  if (!loaded) return null;

  return (
    <>
      <IndicatorsContainer report={report} />
      <Graph
        report={report}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
      />
    </>
  );
};

CardOee.propTypes = {
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  report: PropTypes.object,
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
};

CardOee.defaultProps = {
  report: {},
};

export default CardOee;
