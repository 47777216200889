import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';
import { reset as resetForm } from 'redux-form';

import { DATATRON_FORM_NAME } from '../constants/datatron.form.constants';
import { DATATRON_FORM_PAGE } from '../constants/pathButton.constants';
import { resetTempLocationPath } from './pathButton.tempLocationPath.write.module';

export const resetDatatronPageForm = createAction(
  'reset datatron form data',
);

export function* resetDatatronPageFormSaga() {
  yield put(resetForm(DATATRON_FORM_NAME));
  yield put(resetTempLocationPath(DATATRON_FORM_PAGE));
}

export function* watchResetDatatronPageForm() {
  yield takeEvery(resetDatatronPageForm.getType(), resetDatatronPageFormSaga);
}
