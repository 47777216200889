import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const fetchDeviceClasses = createAction(
  'fetch device classes',
);

export const fetchDeviceClassesSuccess = createAction(
  'fetch device classes - success',
  (deviceClasses) => deviceClasses,
);

export const fetchDeviceClassesFail = createAction(
  'fetch device classes - fail',
  (error) => error,
);

export const reducer = {
  [fetchDeviceClasses]: (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      deviceClasses: {
        ...state.datatron.deviceClasses,
        list: [],
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),

  [fetchDeviceClassesSuccess]: (state, deviceClasses) => ({
    ...state,
    datatron: {
      ...state.datatron,
      deviceClasses: {
        ...state.datatron.deviceClasses,
        list: deviceClasses,
        loading: false,
        loaded: true,
        error: null,
      },
    },
  }),

  [fetchDeviceClassesFail]: (state, error) => ({
    ...state,
    datatron: {
      ...state.datatron,
      deviceClasses: {
        ...state.datatron.deviceClasses,
        list: [],
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* fetchDeviceClassesSaga() {
  const { response, error } = yield call(api.datatrons.getDeviceClasses);

  if (response) {
    yield put(fetchDeviceClassesSuccess(response));
  } else {
    yield put(fetchDeviceClassesFail(error));
  }
}

export function* watchFetchDeviceClasses() {
  yield takeEvery(fetchDeviceClasses.getType(), fetchDeviceClassesSaga);
}
