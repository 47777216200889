import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getLocationLink } from '../../../index/routes';

export const locationConnector = ' > ';

const LocationLabel = ({ location, explainedPath }) => {
  const locationPath = explainedPath.join(locationConnector);

  return (
    <td className="td-content location-label">
      { location.isSelectedLocation
        ? location.name
        : (
          <Link
            to={getLocationLink(location.id)}
            className="link-text"
          >
            { locationPath }
          </Link>
        )}
    </td>
  );
};

LocationLabel.propTypes = {
  location: PropTypes.object.isRequired,
  explainedPath: PropTypes.array.isRequired,
};

export default LocationLabel;
