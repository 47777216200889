import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import LocationBreadcrumbs from '../../_tools/locationBreadcrumbs/LocationBreadcrumbs';
import DatePicker from '../../_tools/dateRangePickerV2/DatePicker';
import { Export } from '../../common/export/Export';
import { getExportUrl } from '../../../core/common/processings';
import * as propTypes from '../../../core/propTypes';
import { PRODUCTS } from '../../../redux/constants/export.type.constants';
import * as datesModes from '../../../../../common/enums/dates.modes';

export function BatchesPageHeader({
  filterByDate, dateFilters, intl, dateFiltersSelected,
}) {
  const downloadButtonMessage = intl.formatMessage({
    id: 'common.features.export.download_data_table',
    defaultMessage: 'Download data table',
  });

  return (
    <div>
      <div className="subheader">
        <div className="subheader-in">
          <div className="subheader--left">
            <LocationBreadcrumbs />
          </div>
        </div>
      </div>
      <div className="filter--wrap filter--wrap1">
        <div className="filter--line clearfix">
          <div className="back--wrap">
            &nbsp;
          </div>
          <div className="filter-buttons--wrap">
            <div className="pull-right">
              <Export
                askForProcessings
                generateExportUrl={getExportUrl}
                downloadButtonMessage={downloadButtonMessage}
                dateFiltersSelected={dateFiltersSelected}
                downloadProps={dateFilters}
                type={PRODUCTS}
              />
            </div>
            <div className="pull-right">
              <div className="reports-actions">
                <DatePicker
                  onUpdate={filterByDate}
                  datesMode={datesModes.ABSOLUTE}
                  useConfirmationFooter
                  showAbsoluteDatesPresets
                  showDatesModeBar={false}
                  shortPreview
                  {...dateFilters}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

BatchesPageHeader.propTypes = {
  filterByDate: PropTypes.func.isRequired,
  dateFilters: propTypes.dateFilters.isRequired,
  dateFiltersSelected: PropTypes.bool.isRequired,

};

export default injectIntl(BatchesPageHeader);
