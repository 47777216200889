import * as form from '../../redux/constants/experiment.form.constants';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';
import { DEFAULT_RELATIVE } from '../../redux/constants/relative.constants';
import * as datesModes from '../../../../common/enums/dates.modes';

export const getValuesFromExperiment = (experiment = {}) => {
  const dates = {
    [DATE_FROM]: experiment.start || null,
    [DATE_TO]: experiment.end || null,
    fromRelative: DEFAULT_RELATIVE,
    toRelative: DEFAULT_RELATIVE,
    datesMode: datesModes.ABSOLUTE,
  };

  const values = {
    [form.NAME_FIELD]: experiment.name || '',
    [form.SERIES_FIELD]: experiment.series || '',
    [form.DATES_FIELD]: dates,
    [form.COMMENT_FIELD]: experiment.comment || '',
    [form.STATUS_FIELD]: experiment.status || null,
  };

  if (experiment.user) {
    values[form.USER_FIELD] = experiment.user.id;
  }
  if (experiment.machine) {
    values[form.MACHINE_FIELD] = experiment.machine.id;
  }

  return values;
};
