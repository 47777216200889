export const active = 'active';
export const blocked = 'blocked';
export const archived = 'archived';

export const PUBLIC_STATUSES = [
  active,
  blocked,
];

export default [
  active,
  blocked,
  archived,
];
