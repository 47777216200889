import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getBatchDetailedLink } from '../../index/routes';

export default class Batch extends Component {
  static propTypes = {
    batch: PropTypes.object.isRequired,
    pushPath: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.onBranchRowClick = this.onBranchRowClick.bind(this);
  }

  onBranchRowClick() {
    const { batch, pushPath } = this.props;
    pushPath(getBatchDetailedLink(batch.id));
  }

  render() {
    const { batch } = this.props;

    return (
      <tr onClick={this.onBranchRowClick}>
        <td>
          <strong>{ batch.name }</strong>
        </td>
        <td>
          { batch.averageDurationNoOutliers }
          s
        </td>
        <td>
          { batch.pieces }
        </td>
      </tr>
    );
  }
}
