import { connect } from 'react-redux';

import MachineCalendarsHeader from './MachineCalendarsHeader';
import { isCalendarAvailable } from '../../redux/selectors/calendar.selector';
import PermissionedComponent from '../common/permissions/PermissionedComponent.container';
import { CALENDAR_PERMISSIONS } from '../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => ({
  showAddCalendarButton: isCalendarAvailable(state) && permissions.canAddCalendar(),
});

export default PermissionedComponent(CALENDAR_PERMISSIONS)(
  connect(mapStateToProps)(MachineCalendarsHeader),
);
