import { defineMessages, MessageDescriptor } from 'react-intl';
import { ACTIVE_TAB, ARCHIVED_TAB } from '../redux/constants/dataPoints.table.tabs.constants';

type TabMessages = {
  [key: string]: MessageDescriptor;
};

const tabMessages: TabMessages = defineMessages({
  [ACTIVE_TAB]: {
    id: 'datatron.data.tabs.active',
    defaultMessage: 'Current Data Points ({enabled}/{all})',
  },
  [ARCHIVED_TAB]: {
    id: 'datatron.data.tabs.archived',
    defaultMessage: 'Archived Data Point ({all})',
  },
});

export default tabMessages;
