import React from 'react';
import PropTypes from 'prop-types';
import NotificationContainer from './Notification.container.js';

function Notifications({ notifications }) {
  if (!notifications.length) return null;

  return (
    <div className="notifications">
      {
        notifications.map((notification) => (
          <NotificationContainer
            key={notification.id}
            notification={notification}
          />
        ))
      }

    </div>
  );
}

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default Notifications;
