import { connect } from 'react-redux';

import OrganizationRow from './OrganizationRow';
import { openModal } from '../../../../redux/modules/modals.module';
import { AP_ORGANIZATION_REMOVE } from '../../../../redux/constants/modals.constants';

export const mapDispatchToProps = (dispatch) => ({
  onRemoveClick: (organization) => dispatch(openModal(AP_ORGANIZATION_REMOVE, { organization })),
});

export default connect(null, mapDispatchToProps)(OrganizationRow);
