import { connect } from 'react-redux';

import CardPhoto from './CardPhoto';
import { getMachineLink } from '../../index/routes';
import { INVENTORY_MACHINES } from '../../../../../common/constants/inventory';
import PermissionedComponent from '../permissions/PermissionedComponent.container';
import { MACHINES_PERMISSIONS } from '../../../../../common/constants/permissions';

export const mapStateToProps = (state, { machine, permissions }) => ({
  inventoryType: INVENTORY_MACHINES,
  hasAlert: !machine.isAvailable && !machine.isMaintenancePlanned,
  imageUrl: machine.image,
  detailsLink: getMachineLink(machine.id),
  canUpdate: permissions.canUpdateMachines(),
  canDelete: !!machine.image && permissions.canUpdateMachines(),
});

export default PermissionedComponent(MACHINES_PERMISSIONS)(connect(mapStateToProps)(CardPhoto));
