import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addTitle: {
    id: 'datatron.new.add_new',
    defaultMessage: 'Add New DATATRON',
  },
  editTitle: {
    id: 'datatronForm.header.update',
    defaultMessage: 'Update DATATRON',
  },
  cpu: {
    id: 'datatron.datatronCpu',
    defaultMessage: 'CPU',
  },
  disk: {
    id: 'datatron.datatronDisk',
    defaultMessage: 'Disk',
  },
  network: {
    id: 'datatron.datatronNetwork',
    defaultMessage: 'Network',
  },
  ram: {
    id: 'datatron.datatronRam',
    defaultMessage: 'RAM',
  },
  errorNameRequired: {
    id: 'datatrons.create.error.name.required',
    defaultMessage: 'Name is required',
  },
  errorNameMaxLength: {
    id: 'datatrons.create.error.name.maxLength',
    defaultMessage: "Name can't be longer than 100 symbols",
  },
  errorMakeRequired: {
    id: 'datatrons.create.error.make.required',
    defaultMessage: 'Vendor is required',
  },
  errorModelRequired: {
    id: 'datatrons.create.error.model.required',
    defaultMessage: 'Model is required',
  },
  errorModelMaxLength: {
    id: 'datatrons.create.error.model.maxLength',
    defaultMessage: "Model can't be longer than 100 symbols",
  },
  errorYearRequired: {
    id: 'datatrons.create.error.year.required',
    defaultMessage: 'Year is required',
  },
  errorYearIncorrect: {
    id: 'datatrons.create.error.year.incorrect',
    defaultMessage: 'Year is incorrect',
  },
  errorSerialNumberRequired: {
    id: 'datatrons.create.error.serialNumber.required',
    defaultMessage: 'Serial number is required',
  },
  errorSerialNumberMaxLength: {
    id: 'datatrons.create.error.serialNumber.maxLength',
    defaultMessage: "Serial number can't be longer than 100 symbols",
  },
  errorSerialNumberAlreadyUsed: {
    id: 'datatrons.create.error.serialNumber.already_used',
    defaultMessage: 'This serial number already used. Please, try another.',
  },
  errorDeviceUuidRequired: {
    id: 'datatrons.create.error.deviceUUID.required',
    defaultMessage: 'Device UUID is required',
  },
  errorOsConfigRequired: {
    id: 'datatrons.create.error.osConfig.required',
    defaultMessage: 'OS Configuration is required',
  },
  errorOsConfigWriteFilesRequired: {
    id: 'datatrons.create.error.osConfig.writeFiles.required',
    defaultMessage: "Invalid OS Configuration. 'writeFiles' key is required",
  },
  errorOsConfigRunCmdsRequired: {
    id: 'datatrons.create.error.osConfig.runCmds.required',
    defaultMessage: "Invalid OS Configuration. 'runCmds' key is required",
  },
  errorLocationIdRequired: {
    id: 'datatrons.create.error.locationId.required',
    defaultMessage: 'Location is required',
  },
  errorLocationIdInvalid: {
    id: 'datatrons.create.error.locationId.invalid',
    defaultMessage: 'Location is invalid',
  },
  errorOrganizationIdInvalid: {
    id: 'datatrons.create.error.organizationId.invalid',
    defaultMessage: 'Organization is invalid',
  },
  errorOrganizationIdRequired: {
    id: 'datatrons.create.error.organizationId.required',
    defaultMessage: 'Organization is required',
  },
  errorTypeRequired: {
    id: 'datatrons.create.error.type.required',
    defaultMessage: 'Type is required',
  },
  errorTypeMaxLength: {
    id: 'datatrons.create.error.type.maxLength',
    defaultMessage: "Type can't be longer than 100 symbols",
  },
  errorMacAddressRequired: {
    id: 'datatrons.create.error.macAddress.required',
    defaultMessage: 'MAC Address is required',
  },
  errorMacAddressInvalid: {
    id: 'datatrons.create.error.macAddress.invalid',
    defaultMessage: 'MAC Address is invalid',
  },
  errorDeviceTypeIdRequired: {
    id: 'datatrons.create.error.deviceTypeId.required',
    defaultMessage: 'Device Type is required',
  },
  errorIpAddressesInvalid: {
    id: 'datatrons.error.ipAddresses.invalid',
    defaultMessage: 'One of IP address is invalid',
  },
  errorNodeNameInvalid: {
    id: 'datatrons.error.nodeName.invalid',
    defaultMessage: 'The Node Name is invalid',
  },
  activateModalTitle: {
    id: 'datatrons.activate.modal.title',
    defaultMessage: 'Do you want to activate this DATATRON?',
  },
  deactivateModalTitle: {
    id: 'datatrons.deactivate.modal.title',
    defaultMessage: 'Do you want to deactivate this DATATRON?',
  },
});

export default messages;
