import React from 'react';
import PropTypes from 'prop-types';

export default function FormatSelectorOption({
  checked, onChange, fileFormat, title, description,
}) {
  const onClick = () => onChange(fileFormat);

  return (
    <label className="radio">
      <input
        type="radio"
        name="fileFormat"
        checked={checked}
        onChange={onClick}
      />
      <span>
        {title}
        { !!description && (
          <i className="radio-info">
            {' '}
            (
            {description}
            )
          </i>
        )}
      </span>
    </label>
  );
}

FormatSelectorOption.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  fileFormat: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
