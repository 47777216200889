import { connect } from 'react-redux';

import * as selector from '../../../../redux/selectors/machine.selector';
import SubscriptionWarning from './SubscriptionWarning';

export const mapStateToProps = (state) => ({
  openedMachine: selector.machine(state),
});

export default connect(mapStateToProps)(SubscriptionWarning);
