import React from 'react';
import PropTypes from 'prop-types';
import { select, event, selectAll } from 'd3-selection';
import { brushX } from 'd3-brush';

class Brush extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.renderBrush(this.props);
  }

  shouldComponentUpdate(props) {
    this.renderBrush(props);
    return false;
  }

  handleChange(selection) {
    const {
      changeSelection, xScale, width, id,
    } = this.props;
    const selectedDomain = selection
      ? [xScale.invert(selection[0]), xScale.invert(selection[1])]
      : [xScale.invert(0), xScale.invert(width)];

    changeSelection(selectedDomain, id);
  }

  renderBrush({ height, width }) {
    const handleWidth = 16;
    const handleHeight = 16;
    const roundCornerRadius = handleWidth / 2;

    const brush = brushX()
      .extent([[0, 0], [width, height]])
      .handleSize(handleWidth);

    select(this.brush).call(brush);

    selectAll('.handle')
      .attr('fill', 'white')
      .attr('stroke', '#c1c2c2')
      .attr('stroke-width', '2')
      .attr('rx', 16)
      .attr('ry', 16)
      .attr('height', 16)
      .attr('width', 16)
      .attr('transform', `translate(0, ${((height / 2) - (handleHeight / 2)) + roundCornerRadius})`);

    brush.on('start brush end', () => {
      this.handleChange(event.selection);
      selectAll('.handle')
        .attr('height', handleHeight);
    });
  }

  render() {
    return (
      <g
        ref={(r) => (this.brush = r)}
        className="brush"
      />
    );
  }
}

Brush.propTypes = {
  xScale: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  changeSelection: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default Brush;
