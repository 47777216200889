import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionFooterButtons } from './ActionFooterButtons';
import { InfoContent } from './InfoContent';
import { ErrorModalContent } from '../../../error/ErrorModalContent';
import { DP_UPLOAD_FILE } from '../../../../redux/constants/modals.constants';
import { closeModal } from '../../../../redux/modules/modals.module';
import { getModalPayload, isModalOpen } from '../../../../redux/selectors/modals.selector';
import { selector } from '../../../../redux/selectors/util';
import Modal from '../../../_tools/modal/Modal';
import {
  updateBulkExcelDataPoints,
  resetUpdateBulkExcelResponse,
} from '../../../../redux/modules/datatron.devices.dataPoints.update.bulk.excel.module';
import { Loader } from '../../../_tools';
import { DragNdrop } from '../../../common/dragNdrop/DragNdrop';
import { AppState } from '../../../../redux/state/app.state';
import { getDeviceById } from '../../../../redux/selectors/datatron.selector';
import {
  DATA_POINTS_EXCEL_MIME_TYPE,
  MAX_FILE_SIZE,
  NR_FILES_ALLOWED,
  UPLOAD_BULK_DATA_POINT_ACCEPT,
} from '../../../../../../common/constants/datapoints.excel';

const modalOpenSelector = selector((state) => isModalOpen(state, DP_UPLOAD_FILE));
const modalPayloadSelector = selector((state) => getModalPayload(state, DP_UPLOAD_FILE));
interface Props {
  datatronId: string;
}

export type CommandState = 'default' | 'loading' | 'info' | 'error';

const handleUploadExcel =
  (dispatch: Function, datatronId: string, deviceId: string) => (file: File) => {
    dispatch(updateBulkExcelDataPoints(datatronId, deviceId, file));
  };

export const UploadFileModal: React.FC<Props> = ({ datatronId }) => {
  const isOpen = useSelector(modalOpenSelector);
  const { deviceId } = useSelector(modalPayloadSelector);
  const { _update: updatedResponse } = useSelector((state: AppState) =>
    getDeviceById(state, deviceId),
  );
  const fileFormat = useMemo(
    () => ({ fileType: DATA_POINTS_EXCEL_MIME_TYPE, accept: UPLOAD_BULK_DATA_POINT_ACCEPT }),
    [],
  );

  const [files, setFiles] = useState<File[]>([]);
  const [errorFileMessage, setErrorFileMessage] = useState<string>('');
  const [commandState, setCommandState] = useState<CommandState>('default');

  useEffect(() => {
    if (updatedResponse.loading === false) {
      if (updatedResponse.updateBulkResponse) {
        setCommandState('info');
      }
      if (updatedResponse.error) {
        setCommandState('error');
      }
    }
  }, [updatedResponse]);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal(DP_UPLOAD_FILE));
    dispatch(resetUpdateBulkExcelResponse(deviceId));
  };

  const onUpload = () => {
    if (files.length > 0) {
      setCommandState('loading');
      handleUploadExcel(dispatch, datatronId, deviceId)(files[0]);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} contentLabel=''>
      <div className='modal--header' onClick={onClose}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        {commandState === 'info' && <InfoContent updatedResponse={updatedResponse} />}
        {commandState === 'error' && <ErrorModalContent errorMessage={updatedResponse.error} />}
        {commandState === 'loading' && (
          <Loader show={commandState === 'loading'} fullPage={false} />
        )}
        {commandState === 'default' && (
          <DragNdrop
            setFiles={setFiles}
            files={files}
            fileFormat={fileFormat}
            maxSize={MAX_FILE_SIZE}
            nrFilesAllowed={NR_FILES_ALLOWED}
            errorMessage={errorFileMessage}
            setErrorMessage={setErrorFileMessage}
          />
        )}
        <div className='line' />
        <ActionFooterButtons
          files={files}
          commandState={commandState}
          errorFileMessage={errorFileMessage}
          onUpload={onUpload}
          onClose={onClose}
        />
      </div>
    </Modal>
  );
};
