import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const adminFetchRole = createAction(
  'admin: fetch role',
  (id) => id,
);

export const adminFetchRoleSuccess = createAction(
  'admin: fetch role - success',
  (item) => item,
);

export const adminFetchRoleFail = createAction(
  'admin: fetch role - fail',
  (error) => error,
);

export const adminResetRole = createAction(
  'admin: reset role',
);

export const reducer = {
  [adminFetchRole]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      roles: {
        ...state.admin.roles,
        selected: {
          ...state.admin.roles.selected,
          item: null,
          loaded: false,
          loading: true,
          error: null,
        },
      },
    },
  }),

  [adminFetchRoleSuccess]: (state, item) => ({
    ...state,
    admin: {
      ...state.admin,
      roles: {
        ...state.admin.roles,
        selected: {
          ...state.admin.roles.selected,
          item,
          loaded: true,
          loading: false,
          error: null,
        },
      },
    },
  }),

  [adminFetchRoleFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      roles: {
        ...state.admin.roles,
        selected: {
          ...state.admin.roles.selected,
          item: null,
          loaded: false,
          loading: false,
          error,
        },
      },
    },
  }),

  [adminResetRole]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      roles: {
        ...state.admin.roles,
        selected: {
          ...state.admin.roles.selected,
          item: null,
          loaded: false,
          loading: false,
          error: null,
        },
      },
    },
  }),
};

export function* adminFetchRoleSaga({ payload: id }) {
  const { response, error } = yield call(api.admin.getRole, id);

  if (response) {
    yield put(adminFetchRoleSuccess(response));
  } else {
    yield put(adminFetchRoleFail(error));
  }
}

export function* watchAdminFetchRole() {
  yield takeEvery(adminFetchRole.getType(), adminFetchRoleSaga);
}
