import React from 'react';
import PropTypes from 'prop-types';

export const PlainList = ({
  options,
  selected,
}) => {
  const list = options.reduce((result, option) => {
    if (selected.indexOf(option.value) >= 0) {
      return [...result, option];
    }
    return result;
  }, []);

  return (
    <div className="input-result">
      <ul className="list">
        { list.map((item, index) => (
          <li key={index + 1}>
            { item.label }
            { !!item.description && (
              <div className="sub-text">
                { item.description }
              </div>
            ) }
          </li>
        )) }
      </ul>
    </div>
  );
};

PlainList.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
};

export default PlainList;
