import { apiRequest, postApiRequest, putApiRequest } from './_tools';

export function getAllActivities() {
  return apiRequest('/api/activities');
}

export function postActivity(eventId, state) {
  return postApiRequest('/api/activities', { eventId, state });
}

export function updateActivity(activityId, data) {
  return putApiRequest(`/api/activities/${activityId}`, data);
}

export function postEventToActivity(activityId, eventId) {
  return postApiRequest(`/api/activities/${activityId}`, { eventId });
}

export function archiveActivity(activityId) {
  return updateActivity(activityId, { isArchived: true });
}
