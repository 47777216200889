import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';
import aiAppMessages from '../../../messages/aiApp.config.messages';
import { openModal } from '../../../redux/modules/modals.module';
import { AI_APP_ADD } from '../../../redux/constants/modals.constants';
import PermissionedComponent from '../../common/permissions/PermissionedComponent.container';
import { AI_APPS_PERMISSIONS } from '../../../../../common/constants/permissions';

interface AiAppAddButtonProps {
  permissions: {
    canAddAiApp: () => boolean;
  };
}

const AiAppAddButton: React.FC<AiAppAddButtonProps> = ({ permissions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const canAddAIApp: boolean = permissions.canAddAiApp();

  const dispatch = useDispatch();

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  const openAddModal = () => {
    dispatch(openModal(AI_APP_ADD));
  };

  const clickRef = useClickOutside(close);

  return (
    <div className={classnames('button-group__with-dropdown', isOpen && 'opened')} ref={clickRef}>
      <button className='button green button__dropdown' onClick={toggle}>
        <span className='fa fa-plus' />
        <FormattedMessage id='common.add_new' defaultMessage='Add New' />
      </button>
      {isOpen && (
        <div className='button-group--dropdown'>
          <ul>
            {canAddAIApp && (
              <li>
                <a className='white' onClick={openAddModal}>
                  <FormattedMessage {...aiAppMessages.addAiApp} />
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PermissionedComponent(AI_APPS_PERMISSIONS)(AiAppAddButton);
