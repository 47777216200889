import React, { useRef, useState } from 'react';
import { difference } from 'lodash';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import calendarTypes from '../../../../../../common/enums/calendarTypes';
import { ESCAPE_KEY } from '../../../../../../common/constants/keyboard';
import commonMessages from '../../../../messages/common.core.messages';
import { TodoFunction } from '../../../../../../common/types/common';
import { useClickOutside } from '../../../../core/common/hooks/useClickOutside';
import { IntlMessage } from '../../../../core/common/intl';

interface Props {
  query: string,
  placeholder?: IntlMessage,
  calendarType: string,
  updateQuery: TodoFunction,
}

export const GroupSearch: React.FC<Props> = ({
  query,
  placeholder = commonMessages.searchPlaceholder,
  calendarType,
  updateQuery,
}) => {
  const { formatMessage } = useIntl();
  const [compressed, compressedSet] = useState(true);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onQueryChange = (event) => {
    updateQuery(calendarType, event.target.value);
  };

  const openSearch = () => {
    compressedSet(false);
    inputRef.current?.focus();
  };

  const closeSearch = () => {
    updateQuery(calendarType, '');
    compressedSet(true);
  };

  const handleClickOutside = () => {
    const types = difference(calendarTypes, calendarType);

    if (!compressed && query === '' && types.some((type) => type !== '')) {
      closeSearch();
    }
  };


  const handleKeyDown = (e) => {
    if (e.keyCode === ESCAPE_KEY) {
      closeSearch();
    }
  };

  const clickRef = useClickOutside(handleClickOutside);

  return (
    <div className="col--head--search" ref={clickRef}>
      <div className={classnames('search-text', 'col-search', compressed && 'compressed')}>
        <div className="input-group">
          <input
            ref={(input) => {
              inputRef.current = input;
            }}
            type="text"
            className="search-input"
            placeholder={formatMessage(placeholder)}
            value={query}
            onChange={onQueryChange}
            onKeyDown={handleKeyDown}
          />
          <div className="input-group-button" onClick={openSearch}>
            <button className="search-button search-button--trigger">
              <span className="icon icon-search"/>
            </button>
            <button type="button" className="search-button search-button--submit">
              <span className="icon icon-search"/>
            </button>
          </div>
          <div className="input-group-button last" onClick={closeSearch}>
            <button className="search-button search-button--close">
              <span className="icon icon-close"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default GroupSearch;
