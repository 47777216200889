import { isEmpty } from 'lodash';
import { createAction } from 'redux-act';
import { put, select, call } from 'redux-saga/effects';
import { reset } from 'redux-form';

import * as profile from '../../core/common/profile.js';
import * as accountsApi from '../../core/api/accounts.js';
import * as profileForm from '../../core/forms/profile.form.js';
import { sendNotification } from './notifications.module';
import { NOTIFICATION_EVENT } from '../../core/notifications.js';
import notificationMessage from '../../messages/notification.message';
import { validateNewPassword } from '../../core/validation/profile.validation';

export const changePasswordSuccess = createAction(
  'change password success',
);

export const changePasswordFail = createAction(
  'change password fail',
  (error) => error,
);

export const reducer = {
  [changePasswordSuccess]: (state) => ({
    ...state,
    profile: {
      ...state.profile,
      password: {
        error: {},
      },
    },
  }),

  [changePasswordFail]: (state, error) => ({
    ...state,
    profile: {
      ...state.profile,
      password: {
        error,
      },
    },
  }),
};

export function* savePassword() {
  const state = yield select();
  const passwords = yield call(profile.getPasswords, state);

  const preRequestValidationErrors = yield call(validateNewPassword, passwords);
  if (!isEmpty(preRequestValidationErrors)) {
    yield put(changePasswordFail(preRequestValidationErrors));
    return;
  }

  const { response, error } = yield call(accountsApi.changePassword, passwords);

  if (response) {
    yield put(changePasswordSuccess());
    yield put(reset(profileForm.profileForm));
    yield put(sendNotification(
      notificationMessage.notifications_title_profile,
      notificationMessage.notifications_description_profile_your_password_has_been_changed,
      NOTIFICATION_EVENT,
    ));
  } else {
    yield put(changePasswordFail(error));
  }
}
