import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SortHeader from '../../../_tools/sortHeader/SortHeader';
import NameFilterContainer from './nameFilter/NameFilter.container';
import {
  SORT_BY_NAME,
  SORT_BY_AVERAGE_DURATION,
  SORT_BY_PIECES,
} from '../../../../../../common/constants/batch';

const BatchesHeader = ({ onSortChange, sortOption, sortWay }) => (
  <thead>
    <tr>
      <td>
        <div className="flex-name">
          <SortHeader
            option={SORT_BY_NAME}
            way={(sortOption === SORT_BY_NAME) ? sortWay : null}
            onClick={onSortChange}
          >
            <FormattedMessage
              id="common.title"
              defaultMessage="Title"
            />
          </SortHeader>
          <div className="rel-wrap">
            <div className="flex">
              <div className="flex-icon">
                <NameFilterContainer />
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className="td-runtime">
        <SortHeader
          option={SORT_BY_AVERAGE_DURATION}
          way={(sortOption === SORT_BY_AVERAGE_DURATION) ? sortWay : null}
          onClick={onSortChange}
        >
          <FormattedMessage
            id="products.averageDuration"
            defaultMessage="Average duration"
          />
        </SortHeader>
      </td>
      <td className="td-piece">
        <SortHeader
          option={SORT_BY_PIECES}
          way={(sortOption === SORT_BY_PIECES) ? sortWay : null}
          onClick={onSortChange}
        >
          <FormattedMessage
            id="products.pieces"
            defaultMessage="Pieces"
          />
        </SortHeader>
      </td>
    </tr>
  </thead>
);

BatchesHeader.propTypes = {
  sortOption: PropTypes.string,
  sortWay: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
};

export default BatchesHeader;
