import { createAction } from 'redux-act';

import { getEventIndexById, getActivityAndEventIndexesByEventId } from '../selectors/maintenance.selector';
import { modifyArrayByIndex } from '../../core/common/modifyArray';

export const receiveComment = createAction(
  'receive maintenance event comment',
  (comment) => comment,
);

export const reducer = {
  [receiveComment]: (state, comment) => {
    let activityIndex;
    let eventIndex = getEventIndexById(state, comment.eventId);
    let newState = state;

    if (eventIndex !== -1) {
      newState = {
        ...newState,
        maintenance: {
          ...newState.maintenance,
          events: modifyArrayByIndex(newState.maintenance.events, eventIndex, (event) => ({
            ...event,
            comments: [
              ...event.comments,
              comment,
            ],
          })),
        },
      };
    }

    [activityIndex, eventIndex] = getActivityAndEventIndexesByEventId(newState, comment.eventId);
    if (activityIndex !== -1 && eventIndex !== -1) {
      newState = {
        ...newState,
        maintenance: {
          ...newState.maintenance,
          activities: modifyArrayByIndex(newState.maintenance.activities, activityIndex,
            (activity) => ({
              ...activity,
              events: modifyArrayByIndex(activity.events, eventIndex, (event) => ({
                ...event,
                comments: [
                  ...event.comments,
                  comment,
                ],
              })),
            })),
        },
      };
    }

    return newState;
  },
};
