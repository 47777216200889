import { MessageDescriptor } from 'react-intl';

export const getTranslationObject = (input: string | MessageDescriptor): MessageDescriptor => {
  if (typeof input === 'string') {
    return { id: input };
  }
  if (typeof input === 'object') {
    return { ...input };
  }
  return {};
};

/**
 * This type may not be necessary. See MessageDescriptor from react-intl
 */
export interface IntlMessage {
  defaultMessage: string
  id: string
}