import React from 'react';
import PropTypes from 'prop-types';

import UnsubscribeDataPointModalContainer from './unsubscribeDataPointModal/UnsubscribeDataPointModal.container';

export const ModalsRouter = ({
  isUnsubscribeDataPointModalOpen,
}) => (
  <>
    { isUnsubscribeDataPointModalOpen && (<UnsubscribeDataPointModalContainer />) }
  </>
);

ModalsRouter.propTypes = {
  isUnsubscribeDataPointModalOpen: PropTypes.bool.isRequired,
};

export default ModalsRouter;
