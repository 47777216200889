import React from 'react';

import BooleanField from './boolean/BooleanField';
import { Todo } from '../../../../../../../common/types/common';

interface Props {
  column: Todo
  value: any
}

export const ViewOptionsRouter: React.FC<Props> = ({ column, value }) => {
  switch (column.type) {
    case 'boolean':
      return (
        <BooleanField value={value}/>
      );
    default: {
      const result = (!(value === undefined || value === null))
        ? value : '';

      return (<span>{result}</span>);
    }
  }
};

export default ViewOptionsRouter;
