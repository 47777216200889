import { PHOTO_FIELD } from './cardPhoto.form.constants';

export { PHOTO_FIELD } from './cardPhoto.form.constants';

export const DATATRON_FORM_NAME = 'DATATRON_FORM_NAME';

export const NAME_FIELD = 'name';
export const VENDOR_FIELD = 'make';
export const MODEL_FIELD = 'model';
export const YEAR_FIELD = 'year';
export const SERIAL_NUMBER_FIELD = 'serialNumber';
export const DEVICE_UUID_FIELD = 'deviceUUID';
export const MAC_ADDRESS_FIELD = 'macAddress';
export const LOCATION_FIELD = 'locationId';
export const ORGANIZATION_FIELD = 'organizationId';
export const IP_ADDRESS_FIELD = 'ipAddress';
export const NOTES_FIELD = 'notes';
export const NETWORK_SPEED_FIELD = 'networkSpeed';
export const NODE_NAME_FIELD = 'nodeName';
export const NETMASK = 'netmask';
export const GATEWAY = 'gateway';
export const BOOT_ARGUMENTS = 'bootArguments';
export const OS_MODULES = 'osModules';
export const OS_CONFIG = 'osConfig';

export const DATATRON_PROPS = [
  PHOTO_FIELD,
  NAME_FIELD,
  VENDOR_FIELD,
  MODEL_FIELD,
  YEAR_FIELD,
  SERIAL_NUMBER_FIELD,
  DEVICE_UUID_FIELD,
  MAC_ADDRESS_FIELD,
  LOCATION_FIELD,
  ORGANIZATION_FIELD,
  IP_ADDRESS_FIELD,
  NOTES_FIELD,
  NETWORK_SPEED_FIELD,
  NODE_NAME_FIELD,
  NETMASK,
  GATEWAY,
  BOOT_ARGUMENTS,
  OS_MODULES,
  OS_CONFIG,
];

export const DATATRON_FORM_ERROR_FIELD_OFFSET = -120;
export const NETWORK_SPEED_FIELD_DEFAULT = 1;
export const OS_CONFIG_DEFAULT = `writeFiles:
- path: "/var/local/bin/network.custom"
  owner: "root"
  permissions: "0755"
  content: |-
    #!/bin/bash
    #ip addr add a.b.c.d/e dev eth1
runCmds:
- "/var/local/bin/network.custom"
`;
