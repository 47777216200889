import React from 'react';

import InventoryAddButtonContainer from './InventoryAddButton.container';
import { LocationBreadcrumbs } from '../_tools/locationBreadcrumbs/LocationBreadcrumbs';

export default function InventoryHeader() {
  return (
    <div className="subheader">
      <div className="subheader-in">
        <div className="subheader--left">
          <LocationBreadcrumbs />
        </div>
        <div className="subheader--right">
          <InventoryAddButtonContainer />
        </div>
      </div>
    </div>
  );
}
