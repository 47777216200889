import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const AssignButton = ({
  onClick,
  disabled,
}) => (
  <button type="button" className="button__save" onClick={onClick} disabled={disabled}>
    <FormattedMessage
      id="assistance.assign"
      defaultMessage="Assign"
    />
  </button>
);

AssignButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AssignButton;
