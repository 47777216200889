import React from 'react';
import PropTypes from 'prop-types';

import FormTabsItem from './FormTabsItem';

const FormTabs = ({ tabs }) => (
  <div className="big-tabs big-tabs-sm">
    <div className="big-tabs-list">
      <div className="big-tabs-scroll">
        <div className="big-tabs-scroll-in">
          { tabs.map((tab, index) => <FormTabsItem {...tab} key={index + 1} />) }
        </div>
      </div>
    </div>
  </div>
);

FormTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default FormTabs;
