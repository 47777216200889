import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import List from './List';

function LocationComponent({ location }) {
  return (
    <div className="col--in">

      <div className="col--title">
        <FormattedMessage
          id="location.tabs.location"
          defaultMessage="Location"
        />
      </div>

      <div className="table--title">
        <i className="icon icon-location" />
        { location.name }
        {' '}
        (
        { location.type }
        )
      </div>

      <div className="scroll-block without-scroll">
        <List
          selectedLocation={location}
          machines={location.machines}
          datatrons={location.datatrons}
        />
      </div>
    </div>
  );
}

LocationComponent.propTypes = {
  location: PropTypes.object.isRequired,
};

export default LocationComponent;
