import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../../_tools/button/Button';

export const ToolAddButton = ({ canAddTool, openModal }) => {
  if (!canAddTool) return null;

  return (
    <Button className="button green" onClick={openModal}>
      <span className="fa fa-plus" />
      <FormattedMessage
        defaultMessage="Add Tool"
        id="tools.modal.add_tool"
      />
    </Button>
  );
};

ToolAddButton.propTypes = {
  canAddTool: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default ToolAddButton;
