import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import * as fileFormats from '../../../../../../common/constants/fileFormats';
import FormatSelectorOption from './FormatSelectorOption';
import fileFormatMessages from '../../../../messages/fileFormats.messages';
import fileFormatDescriptionMessages from '../../../../messages/fileFormats.description.messages';

export function FormatSelector({ value, onChange, intl }) {
  return (
    <div className="radios">
      <FormatSelectorOption
        title={intl.formatMessage(fileFormatMessages[fileFormats.XLSX])}
        description={intl.formatMessage(fileFormatDescriptionMessages[fileFormats.XLSX])}
        checked={value === fileFormats.XLSX}
        fileFormat={fileFormats.XLSX}
        onChange={onChange}
      />
      <FormatSelectorOption
        title={intl.formatMessage(fileFormatMessages[fileFormats.TSV])}
        description={intl.formatMessage(fileFormatDescriptionMessages[fileFormats.TSV])}
        checked={value === fileFormats.TSV}
        fileFormat={fileFormats.TSV}
        onChange={onChange}
      />
      <FormatSelectorOption
        title={intl.formatMessage(fileFormatMessages[fileFormats.CSV])}
        description={intl.formatMessage(fileFormatDescriptionMessages[fileFormats.CSV])}
        checked={value === fileFormats.CSV}
        fileFormat={fileFormats.CSV}
        onChange={onChange}
      />
    </div>
  );
}

FormatSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,

};

export default injectIntl(FormatSelector);
