import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import RemoveModalFooter from '../../core/footers/RemoveModalFooter';

export const RemoveReportsTabModal = ({
  close, removeReportsTab, tab, submitting,
}) => {
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    removeReportsTab(tab.id);
  };

  return (
    <BasicModal close={close} modalClass="modal--sm">
      <div className="modal--content">
        <FormattedMessage
          id="report.modal.do_you_want_to_remove_tab"
          defaultMessage="Do you want to remove tab?"
          tagName="h3"
        />
        <br />
        <div className="modal--subtitle">
          <FormattedMessage
            id="report.modal.all_reports_on_this_tab_will_be_removed"
            defaultMessage="All reports on this tab will be removed"
            tagName="strong"
          />
        </div>

        <div className="line" />
        <RemoveModalFooter
          onCancelClick={close}
          onRemoveClick={handleSubmit}
          isRemoveButtonDisabled={submitting}
          isRemoveButtonLoading={submitting}
        />
      </div>
    </BasicModal>
  );
};

RemoveReportsTabModal.propTypes = {
  close: PropTypes.func.isRequired,
  removeReportsTab: PropTypes.func.isRequired,
  tab: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default RemoveReportsTabModal;
