import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { forEach } from 'lodash';
import { getLocationLink } from '../../index/routes';

const Info = ({ machine }) => {
  const locationPath = [];
  forEach(
    machine.location.path,
    (locationName, index, array) => {
      const fakeIndex = index + 1;
      locationPath.push(<span key={fakeIndex}>{ locationName }</span>);
      if (index < (array.length - 1)) {
        locationPath.push(<span key={-1 * fakeIndex} className="color-grey-light"> &gt; </span>);
      }
    },
  );

  return (
    <div className="infos">
      <div className="infos-line">
        <div className="links links-text">
          <span className="fa fa-users" />
          <p>{machine.organization.name}</p>
        </div>
      </div>
      <div className="infos-line infos--location">
        <Link
          to={getLocationLink(machine.location.id)}
          className="links links-big"
        >
          <span className="icon icon-location" />
          <span className="links-text">{machine.location.name}</span>
        </Link>
        <span>
          {' '}
          (
          <span className="locations--text">{locationPath}</span>
          )
        </span>
      </div>
    </div>
  );
};

Info.propTypes = {
  machine: PropTypes.object.isRequired,
};

export default Info;
