import {
  PRODUCTS_MANAGER,
  PRODUCTS_VIEWER, Role,
} from '../constants/permissions';

class ProductsPermissions {
  private isProductsManager: boolean;

  private isProductsViewer: boolean;

  constructor(roles: Role[]) {
    this.isProductsManager = roles.indexOf(PRODUCTS_MANAGER) !== -1;
    this.isProductsViewer = roles.indexOf(PRODUCTS_VIEWER) !== -1;
  }

  canCreateBatches() {
    return this.isProductsManager;
  }

  canCreateProducts() {
    return this.isProductsManager;
  }

  availableToAll() {
    return this.isProductsManager || this.isProductsViewer;
  }
}

export default ProductsPermissions;
