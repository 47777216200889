import { createAction } from 'redux-act';

import { getEventIndexById } from '../selectors/dashboard.selector';

export const receiveComment = createAction(
  'receive event comment',
  (comment) => comment,
);

export const reducer = {
  [receiveComment]: (state, comment) => {
    const eventIndex = getEventIndexById(state, comment.eventId);

    if (eventIndex === -1) {
      return state;
    }

    const event = state.dashboard.events[eventIndex];

    return {
      ...state,
      dashboard: {
        ...state.dashboard,
        events: [
          ...state.dashboard.events.slice(0, eventIndex),
          {
            ...event,
            comments: [
              ...event.comments,
              comment,
            ],
          },
          ...state.dashboard.events.slice(eventIndex + 1),
        ],
      },
    };
  },
};
