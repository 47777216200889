import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { selectReportsTab } from './reportsTabs.select.module';

export const fetchReportsTabs = createAction(
  'fetch reports tabs',
);

export const fetchReportsTabsSuccess = createAction(
  'fetch reports tabs - success',
  (list) => list,
);

export const fetchReportsTabsFail = createAction(
  'fetch reports tabs - failed',
  (error) => error,
);

export const reducer = {
  [fetchReportsTabs]: (state) => ({
    ...state,
    reports: {
      ...state.reports,
      tabs: {
        ...state.reports.tabs,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),
  [fetchReportsTabsSuccess]: (state, list) => ({
    ...state,
    reports: {
      ...state.reports,
      tabs: {
        loaded: true,
        loading: false,
        error: null,
        list,
      },
    },
  }),
  [fetchReportsTabsFail]: (state, error) => ({
    ...state,
    reports: {
      ...state.reports,
      tabs: {
        ...state.reports.tabs,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchReportsTabsSaga() {
  const { response, error } = yield call(api.reports.getTabs);

  if (response) {
    yield put(fetchReportsTabsSuccess(response));
    if (response.length) {
      yield put(selectReportsTab(response[0].id));
    }
  } else {
    yield put(fetchReportsTabsFail(error));
  }
}

export function* watchFetchReportsTabs() {
  yield takeEvery(fetchReportsTabs.getType(), fetchReportsTabsSaga);
}
