export const info = 'info';
export const fatal = 'fatal';
export const error = 'error';
export const anomaly = 'anomaly';
export const warning = 'warning';

export const maintenanceInbox = [
  fatal, anomaly,
] as const;

export const EVENT_CATEGORIES = [
  info,
  fatal,
  error,
  anomaly,
  warning,
];
export type EventCategory = typeof EVENT_CATEGORIES[number];

export default EVENT_CATEGORIES;
