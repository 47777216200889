import React from 'react';
import ToolAddButtonContainer from './addToolButton/ToolAddButton.container';
import { LocationBreadcrumbs } from '../_tools/locationBreadcrumbs/LocationBreadcrumbs';

export default function ToolsHeader() {
  return (
    <div className="subheader">
      <div className="subheader-in">
        <div className="subheader--left">
          <LocationBreadcrumbs />
        </div>
        <div className="subheader--right">
          <ToolAddButtonContainer />
        </div>
      </div>
    </div>
  );
}
