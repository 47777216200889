import {
  USERS_MANAGER, Role,
} from '../constants/permissions';

class UsersPermissions {
  private isUsersManager: boolean;

  constructor(roles: Role[]) {
    this.isUsersManager = roles.indexOf(USERS_MANAGER) !== -1;
  }

  canManageUsers() {
    return this.isUsersManager;
  }
}

export default UsersPermissions;
