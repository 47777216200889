import { connect } from 'react-redux';

import ModalMainFrame from './ModalMainFrame';
import { _PAGE_REPORT_FORM as modalId } from '../../../../../../redux/constants/modals.constants';
import {
  MODAL_MAIN_FRAME,
  MODAL_DATE_PERIOD_FRAME,
  MODAL_MACHINES_FILTERS_FRAME,
  MODAL_DATA_POINTS_FILTERS_FRAME,
} from '../../../../../../core/common/report.form';
import { setModalPath } from '../../../../../../redux/modules/modals.module';
import { cacheFormField } from '../../../../../../redux/modules/common.form.module';
import { getFormFields } from '../../../../../../redux/selectors/report.form.selector';
import * as form from '../../../../../../redux/constants/report.form.constants';
import * as reportTypes from '../../../../../../../../common/enums/reportTypes';

export const mapStateToProps = (state) => {
  const formValues = getFormFields(state);
  const selectedType = formValues[form.TYPE_FIELD];
  const canSelectMachines = [reportTypes.kpi, reportTypes.maintenance, reportTypes.events].indexOf(selectedType) >= 0;
  const canSelectDataPoints = [reportTypes.dataPoints].indexOf(selectedType) >= 0;

  return {
    canSelectMachines,
    canSelectDataPoints,
  };
};

export const mapDispatchToProps = {
  setModalPath,
  cacheFormField,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onMachinesFiltersClick: () => {
    dispatchProps.setModalPath(modalId, [MODAL_MAIN_FRAME, MODAL_MACHINES_FILTERS_FRAME]);
    dispatchProps.cacheFormField(form.REPORT_FORM_NAME, form.MACHINES_FILTERS_FIELD);
  },
  onDataPointsFiltersClick: () => {
    dispatchProps.setModalPath(modalId, [MODAL_MAIN_FRAME, MODAL_DATA_POINTS_FILTERS_FRAME]);
    dispatchProps.cacheFormField(form.REPORT_FORM_NAME, form.DATA_POINTS_FILTERS_FIELD);
  },
  onDatePickerClick: () => {
    dispatchProps.setModalPath(modalId, [MODAL_MAIN_FRAME, MODAL_DATE_PERIOD_FRAME]);
    dispatchProps.cacheFormField(form.REPORT_FORM_NAME, form.DATES_FIELD);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ModalMainFrame);
