import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import {
  MACHINE_SET_CALENDARS_FORM_NAME,
  SHIFT_CALENDAR_ID,
  SCHEDULE_CALENDAR_ID,
} from '../constants/machine.setCalendars.form.constants';
import { getCalendarsFormFields, machine as getMachine } from '../selectors/machine.selector';
import * as machinesAPI from '../../core/api/machines';
import { closeModal } from './modals.module';
import {
  SMP_SELECT_CALENDARS,
  SMP_CHANGE_SCHEDULE_CALENDAR,
  SMP_CHANGE_SHIFT_CALENDAR,
} from '../constants/modals.constants';
import { getDefaultCalendarEventsDates, initializeCalendar } from '../../core/common/calendar';
import { fetchMachineCalendarsEvents } from './machine.calendars.fetch.events.module';
import { fetchMachineOee } from './machine.oee.fetch.module';

export const setMachineCalendars = createAction(
  'set machine calendars',
);

export const setMachineCalendarsSuccess = createAction(
  'set machine calendars - success',
  (calendars) => calendars,
);

export const setMachineCalendarsFail = createAction(
  'set machine calendars - fail',
  (error) => error,
);

export const reducer = {
  [setMachineCalendars]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      calendars: {
        ...state.machine.calendars,
        change: {
          ...state.machine.calendars.change,
          loading: true,
          loaded: false,
          error: null,
        },
      },
    },
  }),
  [setMachineCalendarsSuccess]: (state, calendars) => ({
    ...state,
    machine: {
      ...state.machine,
      calendars: {
        ...state.machine.calendars,
        change: {
          ...state.machine.calendars.change,
          loading: false,
          loaded: true,
          error: null,
        },
      },
      details: {
        ...state.machine.details,
        calendars: calendars.map(initializeCalendar),
      },
    },
  }),
  [setMachineCalendarsFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      calendars: {
        ...state.machine.calendars,
        change: {
          ...state.machine.calendars.change,
          loading: false,
          loaded: false,
          error,
        },
      },
    },
  }),
};

export function* getFields(state) {
  const setCalendarsFields = yield call(getCalendarsFormFields, state);

  if (setCalendarsFields[SCHEDULE_CALENDAR_ID]) {
    setCalendarsFields[SCHEDULE_CALENDAR_ID] = setCalendarsFields[SCHEDULE_CALENDAR_ID].value;
  }

  if (setCalendarsFields[SHIFT_CALENDAR_ID]) {
    setCalendarsFields[SHIFT_CALENDAR_ID] = setCalendarsFields[SHIFT_CALENDAR_ID].value;
  }

  return setCalendarsFields;
}

export function* setMachineCalendarsSaga() {
  const state = yield select();

  const machine = yield call(getMachine, state);
  const fields = yield call(getFields, state);
  yield put(startSubmit(MACHINE_SET_CALENDARS_FORM_NAME));

  const { response, error } = yield call(machinesAPI.setMachineCalendars, machine.id, fields);

  if (response) {
    yield put(setMachineCalendarsSuccess(response));
    yield put(stopSubmit(MACHINE_SET_CALENDARS_FORM_NAME));
    yield put(closeModal(SMP_SELECT_CALENDARS));
    yield put(closeModal(SMP_CHANGE_SCHEDULE_CALENDAR));
    yield put(closeModal(SMP_CHANGE_SHIFT_CALENDAR));
    yield put(fetchMachineCalendarsEvents(getDefaultCalendarEventsDates()));
    yield put(fetchMachineOee(machine.id));
  } else {
    yield put(setMachineCalendarsFail(error));
    yield put(stopSubmit(
      MACHINE_SET_CALENDARS_FORM_NAME,
      error,
    ));
  }
}

export function* watchSetMachineCalendars() {
  yield takeEvery(setMachineCalendars.getType(), setMachineCalendarsSaga);
}
