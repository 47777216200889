/** A constant for time format in hours and minutes. */
export const timeFormat = 'HH:mm';

/** A constant for datetime format in RFC2445 standard. */
export const DATE_TIME_RFC2445_FORMAT = 'YYYYMMDDTHHmmss';

/** A constant to represent ascending sort order. */
export const SORT_WAY_ASC = 'ASC';

/** A constant to represent descending sort order. */
export const SORT_WAY_DESC = 'DESC';

/** A constant to represent the date from. */
export const DATE_FROM = 'DATE_FROM';

/** A constant to represent the date to. */
export const DATE_TO = 'DATE_TO';

// Maximum number of items per page
export const MAX_PER_PAGE = 50;

// Default page number
export const DEFAULT_PAGE = 1;

// Default limit of items per page
export const DEFAULT_LIMIT = 10;

// Default field to sort by
export const DEFAULT_SORT_BY = 'createdAt';

// Default sort order
export const DEFAULT_SORT_ORDER = SORT_WAY_DESC;

// Default debounce timeout
export const DEBOUNCE_TIMEOUT = 300;

export const DEFAULT_APP = 'cycleAnalysis';

export const APP_VERSION = 'v1.0.0';
