import { connect } from 'react-redux';

import Tools from './Tools';
import PermissionedComponent from '../common/permissions/PermissionedComponent.container';
import { TOOLS_PERMISSIONS } from '../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => ({
  authorized: permissions.canViewTools(),
});

export default PermissionedComponent(TOOLS_PERMISSIONS)(connect(mapStateToProps)(Tools));
