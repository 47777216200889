import { reduxForm } from 'redux-form';

import AddUserForm from './AddUserForm';
import { ADMIN_USER_ADD_FORM_NAME } from '../../../../../../redux/constants/admin.user.add.form.constants';

const form = {
  form: ADMIN_USER_ADD_FORM_NAME,
  destroyOnUnmount: true,
};

export default reduxForm(form)(AddUserForm);
