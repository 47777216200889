import React from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { injectIntl } from 'react-intl';

import Status from './status/Status';
import ButtonsContainer from './buttons/Buttons.container';
import { HighlightText } from '../../../_tools';

const dateFormat = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const Experiment = ({ experiment, textToHighlight, intl }) => {
  const machine = experiment.machine ? experiment.machine.name : null;
  const user = experiment.user ? `${experiment.user.firstName} ${experiment.user.lastName}` : null;
  const start = experiment.start ? intl.formatDate(experiment.start, dateFormat) : '';
  const end = experiment.end ? intl.formatDate(experiment.end, dateFormat) : '';

  return (
    <tr>
      <Status status={experiment.status} />
      <td>
        <HighlightText text={experiment.series} highlight={textToHighlight} />
      </td>
      <td>
        <HighlightText text={experiment.name} highlight={textToHighlight} />
      </td>
      <td>{start}</td>
      <td>{end}</td>
      <td>{machine}</td>
      <td>{user}</td>
      <td>{ truncate(experiment.comment, { length: 50, separator: ' ' }) }</td>
      <ButtonsContainer experiment={experiment} />
    </tr>
  );
};

Experiment.propTypes = {
  experiment: PropTypes.object.isRequired,
  textToHighlight: PropTypes.string,

};

export default injectIntl(Experiment);
