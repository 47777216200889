import { PASSWORD_FIELD, PASSWORD_CONFIRMATION_FIELD } from '../../redux/constants/admin.user.add.form.constants';

export const validatePassword = (body) => {
  const errors = {};

  if (body[PASSWORD_FIELD] && body[PASSWORD_FIELD] !== body[PASSWORD_CONFIRMATION_FIELD]) {
    errors[PASSWORD_FIELD] = 'users.error.password.noMatch';
    errors[PASSWORD_CONFIRMATION_FIELD] = 'users.error.password.noMatch';
  }

  return errors;
};
