import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import RemoveReportsTabModal from './RemoveReportsTabModal';
import { REPORTS_TAB_REMOVE } from '../../../../redux/constants/modals.constants';
import { removeReportsTab } from '../../../../redux/modules/reportsTabs.remove.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { REMOVE_REPORTS_TAB_FORM_NAME } from '../../../../redux/constants/reportsTab.remove.form.constants';

export const mapStateToProps = (state) => ({ ...getModalPayload(state, REPORTS_TAB_REMOVE) });

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(REPORTS_TAB_REMOVE)),
  removeReportsTab: bindActionCreators(removeReportsTab, dispatch),
});

const form = {
  form: REMOVE_REPORTS_TAB_FORM_NAME,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(form)(RemoveReportsTabModal));
