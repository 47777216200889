import { createAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as appEntities from '../../../../common/constants/entities';
import { getRandomResults } from '../../components/events/filters/FilterUtils.js';
import * as api from '../../core/api';
import { EMPTY_RESULTS } from '../constants/search.constants';
import { allEvents } from '../selectors/dashboard.selector';

const defaultSearchEntities = [
  appEntities.MACHINES,
  appEntities.DATATRONS,
  appEntities.LOCATIONS,
  appEntities.ORGANIZATIONS,
  appEntities.TAGS,
];

export const search = createAction(
  'search in app',
  (query = '', allowDefaultValues = true, entities = defaultSearchEntities) => ({
    payload: {
      query,
      allowDefaultValues,
      entities,
    },
  }),
);

export const clear = createAction('clear search results');

export const searchSuccess = createAction('search in app - success', (results) => ({
  payload: results,
}));

export const searchFail = createAction('search in app - fail', (result) => ({
  payload: result,
}));

export const reducer = {
  [search.type]: (state) => ({
    ...state,
    search: {
      ...state.search,
      loading: true,
      loaded: false,
    },
  }),
  [searchSuccess.type]: (state, results) => ({
    ...state,
    search: {
      ...state.search,
      loading: false,
      loaded: true,
      results: {
        ...state.search.results,
        ...results,
      },
    },
  }),
  [searchFail.type]: (state, error) => ({
    ...state,
    search: {
      ...state.search,
      loading: false,
      loaded: false,
      error,
    },
  }),
  [clear.type]: (state) => ({
    ...state,
    search: {
      loading: false,
      loaded: false,
      results: {
        ...EMPTY_RESULTS,
      },
      error: '',
    },
  }),
};

/* eslint-disable no-param-reassign */

function mapping(results, entity) {
  switch (entity) {
    case appEntities.MACHINES:
      return (event) => {
        results[event.machine.id] = event.machine;
      };
    case appEntities.LOCATIONS:
      return (event) => {
        results[event.machine.location.id] = event.machine.location;
      };
    case appEntities.ORGANIZATIONS:
      return (event) => {
        results[event.machine.organization.id] = event.machine.organization;
      };
    case appEntities.TAGS:
      return (event) => {
        event.machine.tags.forEach((tag) => {
          results[tag.name] = tag;
        });
      };
    //TODO: check this, need to be tested
    case appEntities.DATATRONS:
      return (event) => {
        results[event.datatron.id] = event.datatron;
      };
    default:
      return null;
  }
}

/* eslint-enable no-param-reassign */

const getFallbackItems = (state, forEachCallback, entity) => {
  const events = allEvents(state).map((event) => event);
  const results = {};
  events.forEach(forEachCallback(results, entity));
  return getRandomResults(results);
};

const getApiCall = ({ entity, query }) => {
  switch (entity) {
    case appEntities.MACHINES:
      return call(api.machines.search, query);
    case appEntities.LOCATIONS:
      return call(api.locations.search, query);
    case appEntities.ORGANIZATIONS:
      return call(api.organizations.search, query);
    case appEntities.TAGS:
      return call(api.tags.search, query);
    case appEntities.DATATRONS:
      return call(api.datatrons.searchDatatrons, query);
    case appEntities.EXPERIMENTS:
      return call(api.experiments.getExperiments, { query });
    default:
      return null;
  }
};

export function* searchSaga({ payload: { query, allowDefaultValues, entities } }) {
  const state = yield select();
  const apiResults = yield all(
    entities.reduce((result, entity) => {
      const apiCall = getApiCall({ entity, query });
      if (!apiCall) return result;
      return [...result, apiCall];
    }, []),
  );

  const error = apiResults.reduce((final, apiResult) => {
    if (final) return final;
    if (apiResult.error) return apiResult.error;
    return null;
  }, null);

  if (error) {
    yield put(searchFail(error));
  } else {
    const results = entities.reduce((final, entity, index) => {
      const apiCallResult = apiResults[index];
      let result = apiCallResult.response.list || apiCallResult.response;
      if (!result) result = allowDefaultValues ? getFallbackItems(state, mapping, entity) : [];
      return {
        ...final,
        [entity]: result,
      };
    }, {});

    yield put(searchSuccess(results));
  }
}

export function* watchSearch() {
  yield takeEvery(search, searchSaga);
}
