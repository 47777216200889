import { createAction } from 'redux-act';

export const collapseExpand = createAction(
  'expand or collapse event details',
  (eventId) => eventId,
);

export const reducer = {
  [collapseExpand]: (state, eventId) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      expanded: {
        [eventId]: !state.maintenance.expanded[eventId],
      },
    },
  }),
};
