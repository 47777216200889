import { connect } from 'react-redux';

import ViewDataPointModal from './ViewDataPointModal';
import { DP_VIEW_DATA_POINT } from '../../../../redux/constants/modals.constants';
import { closeModal } from '../../../../redux/modules/modals.module';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { createGetScaledValue } from '../../../../core/common/dataPoint';
import {
  applyCustomOrderOnFields,
  FIRST_FIELDS_ORDER,
} from '../../../../core/common/datatron';
import { getDataPointByIdAndDeviceId, getDeviceById } from '../../../../redux/selectors/datatron.selector';
import { DATA_POINT_COMMON_FIELDS } from '../../../../core/common/dataPoint.constants';

export const mapStateToProps = (state) => {
  const {
    deviceId,
    dataPointId,
  } = getModalPayload(state, DP_VIEW_DATA_POINT);

  const device = getDeviceById(state, deviceId);
  const dataPoint = getDataPointByIdAndDeviceId(state, deviceId, dataPointId);

  return {
    deviceId,
    dataPointId: dataPoint.id,
    dataPoint,
    fields: applyCustomOrderOnFields(
      [
        ...DATA_POINT_COMMON_FIELDS,
        ...(device.configKeyHashToFieldsMap[dataPoint.configKeysHash] || []),
      ],
      FIRST_FIELDS_ORDER,
    ),
    getScaledValue: createGetScaledValue(dataPoint),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(DP_VIEW_DATA_POINT)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDataPointModal);
