import {
  pick, max as _max, filter, sortBy, forEach, isEmpty, ceil, isNumber,
} from 'lodash';
import moment from 'moment';
import { scaleLinear } from 'd3-scale';
import { min, max } from 'd3-array';

import * as reportAxises from '../../../../common/constants/report.axis';
import * as datesModes from '../../../../common/enums/dates.modes';

const EMPTY_TICKS_AND_DOMAINS = {
  leftScaleDomain: [],
  rightScaleDomain: [],
  leftAxisTicks: [],
  rightAxisTicks: [],
  helpLinesTicks: [],
};

export const getX = (chartPoint) => chartPoint.timestamp;
export const getUuid = (chartDataItem) => chartDataItem.uuid;
export const getDataPointUuid = (chartDataItem) => chartDataItem.dataPoint.uuid;
export const getDataPointUnit = (chartDataItem) => chartDataItem.dataPoint.unit;
export const isUnitPercent = (unit) => unit === '%';

export const createEmptyPoint = (entries = []) => entries.reduce((final, entry) => ({
  ...final,
  [entry]: null,
}), {});

export const allEntriesMatch = (match, entries = []) => {
  if (!match) return false;
  return entries.reduce((result, entry) => result && match(entry), true);
};

export const convertChartDataIntoChartPoints = ({
  chartData = [],
  entriesIds = [],
  getId = getUuid,
}) => {
  const tsToData = {};

  forEach(
    chartData,
    (chartDataItem) => {
      const id = getId(chartDataItem);
      if (entriesIds.indexOf(id) >= 0) {
        forEach(
          chartDataItem.values,
          (value) => {
            const ts = value[0];
            if (!tsToData[ts]) tsToData[ts] = { timestamp: ts };
            tsToData[ts][id] = value[1];
          },
        );
      }
    },
  );

  return Object.values(tsToData);
};

export const mapChartPointsToTs = (chartPoints) => chartPoints
  .reduce((result, item) => ({
    ...result,
    [item.timestamp]: item,
  }), {});

export const fillChartPointsGaps = ({
  entriesIds,
  chartPoints,
  dateFrom,
  dateTo,
}) => {
  const startMoment = moment(dateFrom).startOf('hour');
  const endMoment = moment(dateTo);
  const mappedChartPoints = mapChartPointsToTs(chartPoints);
  const chartPointsWithoutGaps = [...chartPoints];

  const cursor = moment(startMoment);
  while (cursor.isBefore(endMoment)) {
    const cursorTs = +cursor;

    if (!mappedChartPoints[cursorTs]) {
      chartPointsWithoutGaps.push({
        ...createEmptyPoint(entriesIds),
        timestamp: cursorTs,
      });
    }

    cursor.add(1, 'hour');
  }

  return chartPointsWithoutGaps;
};

export const sortChartPoints = (chartPoints) => sortBy(chartPoints, ['timestamp']);

export const findMaxPointValue = ({ chartPoints = [], entriesIds = [] }) => chartPoints.reduce((maxValue, chartPoint) => {
  const currentMax = _max(Object.values(pick(chartPoint, entriesIds)));
  if (currentMax > maxValue) return currentMax;
  return maxValue;
}, 0);

export const filterBySelection = ({ chartPoints, selection = null }) => {
  if (!selection || isEmpty(selection)) return chartPoints;
  return chartPoints
    .filter((chartPoint) => getX(chartPoint) >= selection[0]
      && getX(chartPoint) <= selection[1]);
};

export const getTicksAndStepByDomain = (domain, maxTicksCount = 7) => {
  if (!domain || !isNumber(domain[0]) || !isNumber(domain[1])) {
    return { ticks: [], step: null };
  }

  const ticks = [];
  const dMax = domain[1];
  const isDMaxSmall = Math.abs(dMax) <= 1;

  const dDigitsCount = ceil(dMax).toString().length;
  let dDigitsAfterDotCount = 0;
  if (dMax.toString().split('.')[1]) {
    dDigitsAfterDotCount = dMax.toString().split('.')[1].length;
  }

  let step = 10 ** (!isDMaxSmall ? (dDigitsCount - 2) : -1 * (dDigitsAfterDotCount + 1));
  let prevInc = 2;
  let nextInc = 2.5;
  let ticksCount = ceil(domain[1] / step);

  while (ticksCount > maxTicksCount) {
    const currentInc = nextInc;
    step *= currentInc;
    nextInc = (prevInc === 2 && nextInc === 2) ? 2.5 : 2;
    prevInc = currentInc;
    ticksCount = ceil(domain[1] / step);
  }

  for (let i = 0; i < ticksCount; i++) {
    ticks.push(i * step);
  }
  if (ticks.length > 0) {
    ticks.push(ticks[ticks.length - 1] + step);
  }

  return { ticks, step };
};

export const getTicksAndSyncedDomains = ({
  leftScaleDomain,
  rightScaleDomain,
  verticalDataOffset = 0,
}) => {
  if (!leftScaleDomain || !rightScaleDomain) return EMPTY_TICKS_AND_DOMAINS;

  const leftAxisHasData = !!leftScaleDomain[0] || !!leftScaleDomain[1];
  const rightAxisHasData = !!rightScaleDomain[0] || !!rightScaleDomain[1];
  if (!leftAxisHasData && !rightAxisHasData) return EMPTY_TICKS_AND_DOMAINS;

  const leftAxis = getTicksAndStepByDomain(leftScaleDomain);
  const rightAxis = getTicksAndStepByDomain(rightScaleDomain);

  const updatedLeftScaleDomain = leftScaleDomain;
  const updatedRightScaleDomain = rightScaleDomain;

  if (leftAxis.step && rightAxis.step) {
    const visibleLeftTicks = leftAxis.ticks
      .filter((tick) => tick >= leftScaleDomain[0] && tick <= leftScaleDomain[1]);
    const visibleRightTicks = rightAxis.ticks
      .filter((tick) => tick >= rightScaleDomain[0] && tick <= rightScaleDomain[1]);

    if (leftAxis.ticks.length > rightAxis.ticks.length) {
      const diffTicksCount = visibleLeftTicks.length - visibleRightTicks.length;

      updatedRightScaleDomain[1] = visibleRightTicks[visibleRightTicks.length - 1];
      updatedRightScaleDomain[1] += diffTicksCount * rightAxis.step;

      if (leftScaleDomain[1] < leftAxis.ticks[leftAxis.ticks.length - 1]) {
        updatedRightScaleDomain[1] += (1 - ((leftAxis.ticks[leftAxis.ticks.length - 1] - leftScaleDomain[1]) / leftAxis.step)) * rightAxis.step;
      }

      for (let i = rightAxis.ticks.length; i < leftAxis.ticks.length; i++) {
        rightAxis.ticks.push(i * rightAxis.step);
      }
    } else if (rightAxis.ticks.length > leftAxis.ticks.length) {
      const diffTicksCount = visibleRightTicks.length - visibleLeftTicks.length;

      updatedLeftScaleDomain[1] = visibleLeftTicks[visibleLeftTicks.length - 1];
      updatedLeftScaleDomain[1] += diffTicksCount * leftAxis.step;
      if (rightScaleDomain[1] < rightAxis.ticks[rightAxis.ticks.length - 1]) {
        updatedLeftScaleDomain[1] += (1 - ((rightAxis.ticks[rightAxis.ticks.length - 1] - rightScaleDomain[1]) / rightAxis.step)) * leftAxis.step;
      }

      for (let i = leftAxis.ticks.length; i < rightAxis.ticks.length; i++) {
        leftAxis.ticks.push(i * leftAxis.step);
      }
    }
  }

  if (verticalDataOffset) {
    updatedLeftScaleDomain[1] *= 1 + verticalDataOffset;
    updatedRightScaleDomain[1] *= 1 + verticalDataOffset;
  }

  return {
    leftScaleDomain: updatedLeftScaleDomain,
    leftAxisTicks: leftAxis.ticks,
    rightScaleDomain: updatedRightScaleDomain,
    rightAxisTicks: rightAxis.ticks,
    helpLinesTicks: leftAxisHasData ? leftAxis.ticks : rightAxis.ticks,
  };
};

export const getDatesByReport = (report) => {
  let dateFrom = moment();
  let dateTo = moment();

  if (report.datesMode === datesModes.ABSOLUTE) {
    dateFrom = moment(report.from);
    dateTo = moment(report.to);
  } else if (report.datesMode === datesModes.RELATIVE) {
    if (report.fromRelative) {
      dateFrom = moment()
        .subtract(report.fromRelative.count, report.fromRelative.unit);
    }
    if (report.toRelative) {
      dateTo = moment()
        .subtract(report.toRelative.count, report.toRelative.unit);
    }
  }

  return { dateFrom, dateTo };
};

export const getChartComponentPayload = ({
  getId = getUuid,
  getUnit = getDataPointUnit,
  filters = [],
  chartData = [],
  dateFrom = moment(),
  dateTo = moment(),
  selection = null,
  disabledEntriesIds = [],
  frameWidth = 0,
  frameHeight = 0,
  verticalDataOffset = 0,
}) => {
  const leftAxisEntries = filter(
    filters,
    (item) => item.axis === reportAxises.Y_LEFT_AXIS,
  )
    .map((item) => ({
      ...item,
      id: getDataPointUuid(item),
    }))
    .filter((item) => disabledEntriesIds.indexOf(item.id) === -1);
  const rightAxisEntries = filter(
    filters,
    (item) => item.axis === reportAxises.Y_RIGHT_AXIS,
  )
    .map((item) => ({
      ...item,
      id: getDataPointUuid(item),
    }))
    .filter((item) => disabledEntriesIds.indexOf(item.id) === -1);

  const leftAxisEntriesIds = leftAxisEntries.map((item) => item.id);
  const rightAxisEntriesIds = rightAxisEntries.map((item) => item.id);

  const leftAxisChartPoints = filterBySelection({
    chartPoints: sortChartPoints(fillChartPointsGaps({
      chartPoints: convertChartDataIntoChartPoints({
        chartData,
        getId,
        entriesIds: leftAxisEntriesIds,
      }),
      entriesIds: leftAxisEntriesIds,
      dateFrom,
      dateTo,
    })),
    selection,
  });
  const rightAxisChartPoints = filterBySelection({
    chartPoints: sortChartPoints(fillChartPointsGaps({
      chartPoints: convertChartDataIntoChartPoints({
        chartData,
        getId,
        entriesIds: rightAxisEntriesIds,
      }),
      entriesIds: rightAxisEntriesIds,
      dateFrom,
      dateTo,
    })),
    selection,
  });

  const isLeftAxisEnabled = leftAxisEntries && leftAxisEntries.length > 0;
  const isRightAxisEnabled = rightAxisEntries && rightAxisEntries.length > 0;

  const dataForDomain = isLeftAxisEnabled ? leftAxisChartPoints : rightAxisChartPoints;
  const domain = isEmpty(selection) ?
    [min(dataForDomain, getX), max(dataForDomain, getX)] : selection;

  const xScale = scaleLinear()
    .range([0, frameWidth])
    .domain(domain);

  const yLeftScaleMax = ceil(findMaxPointValue({
    chartPoints: leftAxisChartPoints,
    entriesIds: leftAxisEntriesIds,
  }));

  const yRightScaleMax = ceil(findMaxPointValue({
    chartPoints: rightAxisChartPoints,
    entriesIds: rightAxisEntriesIds,
  }));

  const {
    helpLinesTicks,
    leftScaleDomain,
    leftAxisTicks,
    rightScaleDomain,
    rightAxisTicks,
  } = getTicksAndSyncedDomains({
    leftScaleDomain: [0, yLeftScaleMax],
    rightScaleDomain: [0, yRightScaleMax],
    verticalDataOffset,
  });

  const yLeftScale = scaleLinear()
    .range([frameHeight, 0])
    .domain(leftScaleDomain);

  const yRightScale = scaleLinear()
    .range([frameHeight, 0])
    .domain(rightScaleDomain);

  return {
    isLeftAxisEnabled,
    isLeftAxisInPercent: allEntriesMatch((entry) => isUnitPercent(getUnit(entry)), leftAxisEntries),
    leftAxisChartPoints,
    leftAxisEntriesIds,
    leftAxisEntries,
    leftAxisTicks,
    isRightAxisEnabled,
    isRightAxisInPercent: allEntriesMatch((entry) => isUnitPercent(getUnit(entry)), rightAxisEntries),
    rightAxisChartPoints,
    rightAxisEntriesIds,
    rightAxisEntries,
    rightAxisTicks,
    domain,
    xScale,
    yLeftScale,
    yRightScale,
    helpLinesTicks,
  };
};
