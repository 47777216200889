import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';

import MachineForm from './MachineForm';
import { createMachine } from '../../../redux/modules/newMachine.create.module';
import { updateMachine } from '../../../redux/modules/machine.update.module';
import { reset } from '../../../redux/modules/machine.page.form.module';
import * as form from '../../../redux/constants/machine.form.constants';
import { selectIsOeeHidden } from '../../../redux/selectors/appConfig.selector';
import * as machineOeeSource from '../../../../../common/enums/machineOeeSource';

const formConfig = {
  form: form.MACHINE_FORM_NAME,
  destroyOnUnmount: true,
};

export const mapStateToProps = (state, { initialValues = {} }) => {
  const payload = {
    initialValues,
  };

  if (!selectIsOeeHidden(state)) return payload;

  return {
    initialValues: {
      ...initialValues,
      [form.OEE_SOURCE]: initialValues[form.OEE_SOURCE] || machineOeeSource.PERFORMANCE,
      [form.CYCLE_SECONDS_PER_PART]: initialValues[form.CYCLE_SECONDS_PER_PART] || 300,
      [form.PERFORMANCE_MAX_FIELD]: initialValues[form.PERFORMANCE_MAX_FIELD] || 200,
      [form.PERFORMANCE_PLANNED_FIELD]: initialValues[form.PERFORMANCE_PLANNED_FIELD] || 100,
      [form.OEE_THRESHOLD_RED]: initialValues[form.OEE_THRESHOLD_RED] || 1,
      [form.OEE_THRESHOLD_YELLOW]: initialValues[form.OEE_THRESHOLD_YELLOW] || 1,
      [form.OEE_THRESHOLD_GREEN]: initialValues[form.OEE_THRESHOLD_GREEN] || 1,
    },
  };
};

export const mapDispatchToProps = (dispatch, { isAddForm, isUpdateForm }) => {
  const actions = {
    reset,
  };

  if (isAddForm) {
    actions.confirm = bindActionCreators(createMachine, dispatch);
  } else if (isUpdateForm) {
    actions.confirm = () => dispatch(updateMachine(true));
  }

  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(formConfig)(MachineForm));
