import { selector } from './util';

export const selectCommandStateIsSuccess = selector(
  (state) => state.modals.commandState?.success,
);
export const selectCommandStateDeviceId = selector(
  (state) => state.modals.commandState?.deviceId,
);
export const selectCommandStateError = selector(
  (state) => state.modals.commandState?.error,
);
export const selectCommandStateIsSubmitting = selector(
  (state) => state.modals.commandState?.submitting,
);
