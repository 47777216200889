import React from 'react';
import PropTypes from 'prop-types';

const ReportFiltersLabel = ({ filters }) => {
  const items = Object.values(filters).reduce((result, filtersBlock) => [
    ...result,
    ...filtersBlock
      .reduce((blockResult, filter) => {
        if (filter.name) return [...blockResult, filter.name];
        if (filter.dataPoint && filter.dataPoint.label) return [...blockResult, filter.dataPoint.label];
        return blockResult;
      }, []),
  ], []);
  return (
    <div className="reports-machines">
      { items.join(', ') }
    </div>
  );
};

ReportFiltersLabel.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default ReportFiltersLabel;
