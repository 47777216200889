import _ from 'lodash';
import moment from 'moment';
import querystring from 'querystring';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';

export const getX = (point) => point.timestamp;
export const getY = (point) => point.value;

export const getMinMaxValue = (data, valueField = 'value') => data.reduce((final, point) => {
  const newFinal = final;
  const value = point[valueField];
  if (value > final.max) newFinal.max = value;
  if (value < final.min) newFinal.min = value;
  return newFinal;
}, {
  min: 0,
  max: 0,
});

export const windowsKeys = ['lift_25', 'lift_50', 'lift_75', 'lift_99'];

export const prepareWindowsData = (data, threshold = null) => data.reduce((final, current, index, array) => {
  const wantedIndex = Math.round(current.series.length / 2);
  const timestamp = Number(current.series[wantedIndex].timestamp);
  const fields = _.pick(current, windowsKeys);
  const total = Object.values(fields).reduce((result, field) => (result + field), 0);

  const point = {
    timestamp,
    ...fields,
    total,
  };

  if (threshold) point.aboveThreshold = (total >= threshold);
  point.isLatest = (index === array.length - 1);

  return [
    ...final,
    point,
  ];
}, []);

export const getExportUrl = (exportProps) => {
  const query = querystring.stringify({
    ...exportProps,
    machines: (exportProps.machines
      && exportProps.machines.map((item) => item.id)) || [],
    locations: (exportProps.locations
      && exportProps.locations.map((item) => item.id)) || [],
    organizations: (exportProps.organizations
      && exportProps.organizations.map((item) => item.id)) || [],
    tags: (exportProps.tags
      && exportProps.tags.map((item) => item.id)) || [],
    category: (exportProps.categories
      && exportProps.categories.map((item) => item.id)) || [],

    [DATE_FROM]: moment(exportProps[DATE_FROM]).toISOString(),
    [DATE_TO]: moment(exportProps[DATE_TO]).toISOString(),
    timezoneOffset: moment().utcOffset(),
  });

  return `/api/events/download/${exportProps.fileFormat}/?${query}`;
};
