import { connect } from 'react-redux';

import ExperimentsPage from './ExperimentsPage';
import * as selector from '../../redux/selectors/experimentsPage.selector';
import * as tabs from '../../redux/constants/experimentsPage.tabs.constants';
import PermissionedComponent from '../common/permissions/PermissionedComponent.container';
import { EXPERIMENTS_PERMISSIONS } from '../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => {
  const activeTab = selector.getActiveTab(state);
  return {
    authorized: permissions.canViewExperiments() || permissions.canViewMeasurements(),
    isExperimentsTabOpen: activeTab === tabs.EXPERIMENTS_PAGE,
    isMeasurementsTabOpen: activeTab === tabs.MEASUREMENTS_PAGE,
  };
};

export default PermissionedComponent(EXPERIMENTS_PERMISSIONS)(connect(mapStateToProps)(ExperimentsPage));
