import React from 'react';

export default function EventsTableHeader() {
  return (
    <thead>
      <tr>
        <td className="headcol">
          <div className="schedule-in">&nbsp;</div>
        </td>
        <td>
          <div className="schedule-in">00:00</div>
        </td>
        <td>
          <div className="schedule-in">02:00</div>
        </td>
        <td>
          <div className="schedule-in">04:00</div>
        </td>
        <td>
          <div className="schedule-in">06:00</div>
        </td>
        <td>
          <div className="schedule-in">08:00</div>
        </td>
        <td>
          <div className="schedule-in">10:00</div>
        </td>
        <td>
          <div className="schedule-in">12:00</div>
        </td>
        <td>
          <div className="schedule-in">14:00</div>
        </td>
        <td>
          <div className="schedule-in">16:00</div>
        </td>
        <td>
          <div className="schedule-in">18:00</div>
        </td>
        <td>
          <div className="schedule-in">20:00</div>
        </td>
        <td>
          <div className="schedule-in">22:00</div>
        </td>
      </tr>
    </thead>
  );
}
