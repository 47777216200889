import { connect } from 'react-redux';

import CustomHead from './CustomHead';
import { selectAppConfig } from '../../../redux/selectors/appConfig.selector';

export function mapStateToProps(state) {
  return {
    config: selectAppConfig(state),
  };
}

export default connect(mapStateToProps)(CustomHead);
