import { createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { initializeDeviceTypes } from '../../core/common/datatron';
import { getDeviceTypes } from '../../core/api/datatrons';

export const fetchDeviceTypes = createAction('datatrons/deviceTypes/fetch');

export const fetchDeviceTypesSuccess = createAction(
  'datatrons/deviceTypes/fetchSuccess',
  (deviceTypes) => ({ payload: deviceTypes }),
);

export const fetchDeviceTypesFail = createAction(
  'datatrons/deviceTypes/fetchFail',
  (error) => ({ payload: error }),
);

export const reducer = {
  [fetchDeviceTypes.type]: (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      deviceTypes: {
        ...state.datatron.deviceTypes,
        list: [],
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),

  [fetchDeviceTypesSuccess.type]: (state, deviceTypes) => ({
    ...state,
    datatron: {
      ...state.datatron,
      deviceTypes: {
        ...state.datatron.deviceTypes,
        list: deviceTypes,
        loading: false,
        loaded: true,
        error: null,
      },
    },
  }),

  [fetchDeviceTypesFail.type]: (state, error) => ({
    ...state,
    datatron: {
      ...state.datatron,
      deviceTypes: {
        ...state.datatron.deviceTypes,
        list: [],
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* fetchDeviceTypesSaga() {
  const { response, error } = yield call(getDeviceTypes);

  if (response) {
    const deviceTypes = yield call(initializeDeviceTypes, response);
    yield put(fetchDeviceTypesSuccess(deviceTypes));
  } else {
    yield put(fetchDeviceTypesFail(error));
  }
}

export function* watchFetchDeviceTypes() {
  yield takeEvery(fetchDeviceTypes, fetchDeviceTypesSaga);
}
