import React from 'react';
import PropTypes from 'prop-types';

import AccountFormBody from './AccountFormBody';
import AddModalFooter from '../../../../../modals/core/footers/AddModalFooter';
import EditModalFooter from '../../../../../modals/core/footers/EditModalFooter';

export const AccountForm = ({
  submitting,
  isAddForm,
  isEditForm,
  pristine,
  handleCancel,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit}>
    <div className="form">
      <AccountFormBody />

      <div className="line" />
      { isAddForm && (
      <AddModalFooter
        onCancelClick={handleCancel}
        onAddClick={handleSubmit}
        isAddButtonDisabled={pristine || submitting}
      />
      ) }
      { isEditForm && (
      <EditModalFooter
        onCancelClick={handleCancel}
        onSaveClick={handleSubmit}
        isSaveButtonDisabled={pristine || submitting}
      />
      ) }
    </div>
  </form>
);

AccountForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isAddForm: PropTypes.bool,
  isEditForm: PropTypes.bool,
};

AccountForm.defaultProps = {
  isAddForm: false,
  isEditForm: false,
};

export default AccountForm;
