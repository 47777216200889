import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { renderRoutes } from 'react-router-config';

import { Provider } from 'react-redux';
import { history } from '../../redux/store';
import routesConfig from './routesConfig';

export default function App({ store }) {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {/* @ts-ignore */}
        {renderRoutes(routesConfig.routes)}
      </ConnectedRouter>
    </Provider>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};
