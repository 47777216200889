import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { FormField, TextareaFormField } from '../../../../_tools';
import commonMessages from '../../../../../messages/common.messages';
import commonCoreMessages from '../../../../../messages/common.core.messages';
import messages from '../../../../../messages/experimentsPage.messages';
import * as form from '../../../../../redux/constants/experiment.form.constants';
import MachineSelectContainer from '../../../../_tools/formFields/select/MachineSelect.container';
import UserSelectContainer from '../../../../_tools/formFields/select/UserSelect.container';
import ExperimentStatusSelectContainer from '../../../../_tools/formFields/select/ExperimentStatusSelect.container';
import DatePickerContainer from '../../../../_tools/formFields/datePickerV2/DatePicker.container';
import * as datesModes from '../../../../../../../common/enums/dates.modes';

const Form = ({
  canSelectStatus,
}) => (
  <>
    <div className="row row-margin">
      <div className="columns">
        <FormField
          label={commonMessages.name.id}
          name={form.NAME_FIELD}
          tabIndex={1}
          autoFocus
        />
      </div>
      <div className="columns">
        <FormField
          label={messages.series.id}
          name={form.SERIES_FIELD}
          tabIndex={2}
        />
      </div>
      <div className="columns">
        <label>
          <FormattedMessage {...commonCoreMessages.machine} />
          <Field
            name={form.MACHINE_FIELD}
            tabIndex={3}
            component={MachineSelectContainer}
          />
        </label>
      </div>
    </div>
    <div className="row row-margin">
      <div className="columns">
        <label>
          <FormattedMessage {...commonMessages.responsible} />
          <Field
            name={form.USER_FIELD}
            tabIndex={4}
            component={UserSelectContainer}
          />
        </label>
      </div>
      <div className="small-8 columns">
        <label>
          <FormattedMessage {...commonMessages.dates} />
          <Field
            component={DatePickerContainer}
            showDatesModeBar={false}
            name={form.DATES_FIELD}
            datesMode={datesModes.ABSOLUTE}
            shortPreview
            useConfirmationFooter
            tabIndex={5}
            fromLabel={commonMessages.start}
            toLabel={commonMessages.end}
            allowFuture
          />
        </label>
      </div>
    </div>
    { canSelectStatus && (
      <div className="row row-margin">
        <div className="columns">
          <label>
            <FormattedMessage {...commonMessages.status} />
            <Field
              name={form.STATUS_FIELD}
              tabIndex={6}
              component={ExperimentStatusSelectContainer}
            />
          </label>
        </div>
        <div className="columns" />
        <div className="columns" />
      </div>
    ) }
    <TextareaFormField
      label={commonCoreMessages.comment.id}
      name={form.COMMENT_FIELD}
      tabIndex={7}
      cols="30"
      rows="10"
      className="height--md"
      maxLength="255"
    />
  </>
);

Form.propTypes = {
  canSelectStatus: PropTypes.bool.isRequired,
};

export default Form;
