import { defineMessages } from 'react-intl';

import * as toolStates from '../../../common/enums/toolStates';

export default defineMessages({
  [toolStates.NEW]: {
    id: 'tool.states.label.new',
    defaultMessage: 'New',
  },
  [toolStates.MOUNTED]: {
    id: 'tool.states.label.mounted',
    defaultMessage: 'In Use',
  },
  [toolStates.USED]: {
    id: 'tool.states.label.used',
    defaultMessage: 'Used',
  },
  [toolStates.EXHAUSTED]: {
    id: 'tool.states.label.exhausted',
    defaultMessage: 'Exhausted',
  },
});
