import React from 'react';
import PropTypes from 'prop-types';
import { line } from 'd3-shape';
import { forEach } from 'lodash';

import { getX } from '../../../core/common/report.chart.v2';

const ThumbChart = ({
  xScale,

  yLeftScale,
  leftAxisChartPoints,
  leftAxisEntries,
  isLeftAxisEnabled,

  yRightScale,
  rightAxisChartPoints,
  rightAxisEntries,
  isRightAxisEnabled,
}) => {
  const paths = [];

  if (isLeftAxisEnabled) {
    forEach(
      leftAxisEntries,
      (entry) => {
        const getY = (chartPoint) => chartPoint[entry.id];
        const pathD = line()
          .x((d) => xScale(getX(d)))
          .y((d) => yLeftScale(getY(d)))
          .defined((d) => getY(d) !== null)(leftAxisChartPoints);

        if (entry.lineWidth || entry.fillOpacity) {
          paths.push(<path key={entry.id} d={pathD} className="slider__thumb-chart" />);
        }
      },
    );
  }
  if (isRightAxisEnabled) {
    forEach(
      rightAxisEntries,
      (entry) => {
        const getY = (chartPoint) => chartPoint[entry.id];
        const pathD = line()
          .x((d) => xScale(getX(d)))
          .y((d) => yRightScale(getY(d)))
          .defined((d) => getY(d) !== null)(rightAxisChartPoints);

        if (entry.lineWidth || entry.fillOpacity) {
          paths.push(<path key={entry.id} d={pathD} className="slider__thumb-chart" />);
        }
      },
    );
  }

  return (
    <g>
      { paths }
    </g>
  );
};

ThumbChart.propTypes = {
  xScale: PropTypes.func.isRequired,
  yLeftScale: PropTypes.func.isRequired,
  leftAxisChartPoints: PropTypes.array.isRequired,
  leftAxisEntries: PropTypes.array.isRequired,
  isLeftAxisEnabled: PropTypes.bool.isRequired,

  yRightScale: PropTypes.func.isRequired,
  rightAxisChartPoints: PropTypes.array.isRequired,
  rightAxisEntries: PropTypes.array.isRequired,
  isRightAxisEnabled: PropTypes.bool.isRequired,
};

export default ThumbChart;
