import React from 'react';
import PropTypes from 'prop-types';

import MachinePhotoContainer from '../../common/cardPhoto/MachineCardPhoto.container';
import CardOeeContainer from './cardOee/CardOee.container';
import Info from './Info';
import CardButtonsContainer from './cardButtons/CardButtons.container';

const Card = ({
  machine, canOpenDetails, showOee,
}) => (
  <div className="col--in">
    <div className="card-top">
      <h5>{machine.name}</h5>
      <CardButtonsContainer machineId={machine.id} />
    </div>
    <MachinePhotoContainer machine={machine} canOpenDetails={canOpenDetails} canUpdate />
    <Info machine={machine} />
    { showOee && (
      <>
        <div className="line" />
        <CardOeeContainer />
      </>
    ) }
  </div>
);

Card.propTypes = {
  machine: PropTypes.object.isRequired,
  canOpenDetails: PropTypes.bool,
  showOee: PropTypes.bool,
};

Card.defaultProps = {
  canOpenDetails: true,
  showOee: true,
};

Card.defaultProps = {
  canOpenDetails: true,
};

export default Card;
