import { connect } from 'react-redux';

import AccountsModule from './AccountsModule';
import * as selector from '../../../../redux/selectors/admin.accounts.selector';

export const mapStateToProps = (state) => ({
  list: selector.getAccounts(state),
});

export default connect(mapStateToProps)(AccountsModule);
