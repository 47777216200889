import React from 'react';
import PropTypes from 'prop-types';

export default function GroupTitle({ title, count, icon }) {
  return (
    <div className="col--head--title">
      <span className={`col--head--icon ${icon}`} />
      {title}
      <span className="label-num">
        { count }
      </span>
    </div>
  );
}

GroupTitle.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
};
