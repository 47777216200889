import React, { useState } from 'react';
import TopBarProfileDropdownContainer from './TopBarProfileDropdown.container';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/selectors/user.selector';


export const TopBarProfile: React.FC = ( ) => {
  const user = useSelector(getUser);

  const [isOpen, setIsOpen] = useState(false);
  const clickRef = useClickOutside(() => setIsOpen(false));

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="header-profile" ref={clickRef}>
      <a className="profile profile--link" onClick={toggle}>
        <div className="profile--name">{user.name}</div>
        <button type="button" className="menu-icon" aria-label="top-bar-profile-button" />
      </a>
      <TopBarProfileDropdownContainer open={isOpen} toggle={toggle} />
    </div>
  );
};

export default TopBarProfile;
