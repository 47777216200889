import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import commonMessages from '../../../../../../../../messages/common.messages';
import { Todo } from '../../../../../../../../../../common/types/common';

interface SelectAllCheckBoxProps {
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  dataPoints: Todo[];
}

export const SelectAllCheckBox: React.FC<SelectAllCheckBoxProps> = ({
  selectedRows,
  dataPoints,
  setSelectedRows,
}) => {
  const { formatMessage } = useIntl();
  const dataPointIds = useMemo(
    () => dataPoints.map((dataPointItem) => dataPointItem.id),
    [dataPoints],
  );

  const handleCheckboxChange = () => {
    setSelectedRows(selectedRows.length === dataPoints.length ? [] : dataPointIds);
  };

  const handleDivClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!(event.target as HTMLElement).classList.contains('data-point-checkbox')) {
      setSelectedRows(selectedRows.length === dataPoints.length ? [] : dataPointIds);
      event.stopPropagation();
    }
  };

  return (
    <>
      <div
        className='d-flex align-items-center ml-20 mr-20 data-point-select-all-text-action'
        onClick={handleDivClick}
        role='checkbox'
        aria-checked={selectedRows.length === dataPoints.length && selectedRows.length > 0}
        aria-label={formatMessage(commonMessages.selectAll)}
        tabIndex={0}
        aria-disabled={dataPoints.length === 0}
      >
        <input
          type='checkbox'
          className='data-point-checkbox'
          checked={selectedRows.length === dataPoints.length && selectedRows.length > 0}
          onChange={handleCheckboxChange}
        />
        <span className='flex-name-label'>{formatMessage(commonMessages.selectAll)}</span>
      </div>
    </>
  );
};
