import React from 'react';
import { Link } from 'react-router-dom';
import { getLocationLink } from '../../index/routes';

import InventoryMachineContainer from './items/machine/InventoryMachine.container';
import { InventoryDatatron } from './items/datatron/InventoryDatatron';
import { INVENTORY_DATATRONS, INVENTORY_MACHINES } from '../../../../../common/constants/inventory';
import { InventoryItem } from '../../../core/propTypes';

const getPath = (path: string[]): string => path.join(' - ');

type Props = {
  location: InventoryItem
};


export const InventoryLocation: React.FC<Props> = ({ location }) => (
  <div className="inventory-block">
    <div className="title">
      <Link
        to={getLocationLink(location.id)}
        className="links links-big"
      >
        <span className="icon icon-location"/>
        <span className="links-text">{location.name}</span>
      </Link>
      <p>{getPath(location.path)}</p>
    </div>

    <div className="inventory-wrap">
      <div className="inventory">
        {location[INVENTORY_MACHINES].map((machine) => (
          <div
            className="inventory--col"
            key={machine.id}
          >
            <InventoryMachineContainer
              machine={machine}
            />
          </div>
        ))}
        {location[INVENTORY_DATATRONS].map((datatron) => (
          <div
            className="inventory--col"
            key={datatron.id}
          >
            <InventoryDatatron
              datatron={datatron}
            />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default InventoryLocation;
