import { createAction } from 'redux-act';
import { put, call, takeLatest } from 'redux-saga/effects';

import * as api from '../../core/api';
import { initializeCalendarEvent } from '../../core/common/calendar';
import { mergeObjectInList, replaceObjectInList, compareById } from '../../../../common/helpers/object';
import { getCalendarById } from '../selectors/calendar.selector';
import { resetFilterQueries } from './calendars.query.module';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from '../../core/notifications';

export const createCalendarEvent = createAction(
  'create calendar event',
  (calendarId, startDateTime, endDateTime) => ({ calendarId, startDateTime, endDateTime }),
);

export const createCalendarEventSuccess = createAction(
  'create calendar event - success',
  (calendarId, event) => ({ calendarId, event }),
);

export const createCalendarEventFail = createAction(
  'create calendar event - fail',
  (calendarId, error) => ({ calendarId, error }),
);

export const disableCreateCalendarEvent = createAction('disable create calendar event');

export const enableCreateCalendarEvent = createAction('enable create calendar event');

export const reducer = {
  [createCalendarEvent]: (state, { calendarId }) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: mergeObjectInList(
        state.machineCalendars.list,
        {
          id: calendarId,
          newEvent: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
        compareById,
      ),
    },
  }),
  [createCalendarEventSuccess]: (state, { event, calendarId }) => {
    const calendar = getCalendarById(state, calendarId);

    if (!calendar) return state;

    const newCalendar = {
      ...calendar,
      events: {
        ...calendar.events,
        list: [
          initializeCalendarEvent(event, calendar),
          ...calendar.events.list,
        ],
      },
    };

    return {
      ...state,
      machineCalendars: {
        ...state.machineCalendars,
        list: replaceObjectInList(
          state.machineCalendars.list,
          { id: calendarId, ...newCalendar },
          compareById,
        ),
      },
    };
  },
  [createCalendarEventFail]: (state, { calendarId, error }) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: mergeObjectInList(
        state.machineCalendars.list,
        {
          id: calendarId,
          newEvent: {
            loading: false,
            loaded: false,
            error,
          },
        },
        compareById,
      ),
    },
  }),
  [disableCreateCalendarEvent]: (state) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      enabledCreateEvent: false,
    },
  }),
  [enableCreateCalendarEvent]: (state) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      enabledCreateEvent: true,
    },
  }),
};

export function* createCalendarEventSaga({ payload: { calendarId, startDateTime, endDateTime } }) {
  const {
    response, error,
  } = yield call(api.calendars.createEvent, calendarId, { startDateTime, endDateTime });

  if (response) {
    yield put(createCalendarEventSuccess(calendarId, response));
    yield put(sendNotification(
      notificationMessage.successfully_changed_event,
      notificationMessage.successfully_added_event_description,
      NOTIFICATION_EVENT,
    ));
  } else {
    yield put(createCalendarEventFail(calendarId, error));
    yield put(sendNotification(
      notificationMessage.successfully_changed_event,
      {
        ...notificationMessage.failed_to_add_event_description,
        values: { errorMsg: error },
      },
      NOTIFICATION_ERROR,
    ));
  }

  yield put(resetFilterQueries());
}

export function* watchCreateCalendarEvent() {
  yield takeLatest(createCalendarEvent.getType(), createCalendarEventSaga);
}
