import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../modals/core/BasicModal';
import LocationPicker from './LocationPicker';

const LocationPickerModal = ({
  isOpen,
  closeModal,
  onChangeHook,
  initLocationId,
  onSave,
  selectedLocationId,
  isFormField,
  pathButtonId,
  writeToPathButton,
  input: { onChange },
}) => {
  const onSaveClick = () => {
    if (isFormField) {
      onChange(selectedLocationId);
    } else {
      onSave(selectedLocationId);
    }
    if (pathButtonId) writeToPathButton(pathButtonId);
    closeModal();
  };

  return (
    <BasicModal isOpen={isOpen} close={closeModal}>
      <div className="modal--content">
        <FormattedMessage
          id="location.header.add_location"
          defaultMessage="Add Location"
          tagName="h3"
        />
        <LocationPicker
          onChangeHook={onChangeHook}
          initLocationId={initLocationId}
        />
        <div className="line" />
        <div className="text-center">
          <button
            type="button"
            className="button__cancel"
            onClick={closeModal}
          >
            <FormattedMessage
              id="common.cancel"
              defaultMessage="Cancel"
              tagName="span"
            />
          </button>
          <button
            type="submit"
            className="button__save"
            onClick={onSaveClick}
          >
            <FormattedMessage
              id="common.ok"
              defaultMessage="OK"
              tagName="span"
            />
          </button>
        </div>
      </div>
    </BasicModal>
  );
};

LocationPickerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onChangeHook: PropTypes.func,
  initLocationId: PropTypes.string,
  selectedLocationId: PropTypes.string,
  onSave: PropTypes.func,
  isFormField: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  writeToPathButton: PropTypes.func.isRequired,
  pathButtonId: PropTypes.string,
};

LocationPickerModal.defaultProps = {
  onSave() {},
  isFormField: false,
  input: {
    onChange() {},
  },
  pathButtonId: null,
};

export default LocationPickerModal;
