import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';
import { replaceSummaryGroup, findSummaryGroup } from '../selectors/machine.selector';
import { resetSummaryGroupEventsPagination } from './machine.fetch.summaryGroup.loadMore.module';
import { fetchEventsSummaryGroup } from './machine.fetch.summaryGroup.module';

export const switchSummaryGroupMode = createAction(
  'switch summary group mode',
  (machineId, summaryGroup, newMode) => ({ summaryGroup, mode: newMode, machineId }),
);

export const reducer = {
  [switchSummaryGroupMode]: (state, { summaryGroup, mode }) => {
    const stateSummaryGroup = findSummaryGroup(state.machine.summary.data, summaryGroup);

    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          data: replaceSummaryGroup(state.machine.summary.data, {
            ...stateSummaryGroup,
            mode,
          }),
        },
      },
    };
  },
};

export function* switchSummaryGroupModeSaga({ payload: { summaryGroup, machineId } }) {
  yield put(resetSummaryGroupEventsPagination(machineId, summaryGroup));
  yield put(fetchEventsSummaryGroup(machineId, summaryGroup));
}

export function* watchSwitchSummaryGroupMode() {
  yield takeEvery(switchSummaryGroupMode.getType(), switchSummaryGroupModeSaga);
}
