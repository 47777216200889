export const UPDATES_TAB = 'UPDATES_TAB';
export const DATA_TAB = 'DATA_TAB';
export const DETAILS_TAB = 'DETAILS_TAB';
export const ASSISTANCE_TAB = 'ASSISTANCE_TAB';
export const DEFAULT_TAB = DETAILS_TAB;

export type DatatronTabType =
  | typeof UPDATES_TAB
  | typeof DATA_TAB
  | typeof DETAILS_TAB
  | typeof ASSISTANCE_TAB
  | typeof ASSISTANCE_TAB;
