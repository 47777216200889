import React from 'react';
import PropTypes from 'prop-types';
import { stringify } from 'querystring';

export default function MailToLink({
  email, subject, cc, bcc, body, children, ...rest
}) {
  const query = {
    cc,
    bcc,
    body,
    subject,
  };
  const href = `mailto:${email}?${stringify(query)}`;

  return (
    <a href={href} {...rest}>{children}</a>
  );
}

MailToLink.propTypes = {
  email: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  subject: PropTypes.string,
  cc: PropTypes.arrayOf(PropTypes.string),
  bcc: PropTypes.arrayOf(PropTypes.string),
  body: PropTypes.string,
};
