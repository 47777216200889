import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../_tools/modal/Modal';

const UnsubscribeDataPointModal = ({
  isOpen, close, unsubscribe, dataPointId,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    unsubscribe(dataPointId);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      contentLabel=""
    >
      <div
        className="modal--header"
        onClick={close}
      >
        <span className="modal--close">&times;</span>
      </div>
      <div className="modal--content">
        <FormattedMessage
          defaultMessage="Do you really want to unsubscribe Machine from this Data Point?"
          id="machine.modals.unsubscribeDataPoint.text"
          tagName="h3"
        />

        <div className="line" />

        <div className="text-center">
          <button
            type="button"
            className="button__cancel"
            onClick={close}
          >
            <FormattedMessage
              id="common.cancel"
              defaultMessage="Cancel"
            />
          </button>
          <button
            type="submit"
            className="button__save"
            onClick={handleSubmit}
          >
            <FormattedMessage
              defaultMessage="Unsubscribe Data Point"
              id="machine.modals.unsubscribeDataPoint.buttons.unsubscribe"
            />
          </button>
        </div>
      </div>
    </Modal>
  );
};

UnsubscribeDataPointModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  dataPointId: PropTypes.string.isRequired,
};

export default UnsubscribeDataPointModal;
