import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';

import EditReportsTabModal from './EditReportsTabModal';
import { REPORTS_TAB_EDIT } from '../../../../redux/constants/modals.constants';
import { editReportsTab } from '../../../../redux/modules/reportsTabs.edit.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { REPORTS_TAB_EDIT_FORM_NAME, NAME_FIELD } from '../../../../redux/constants/reportsTab.edit.form.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';

export const mapStateToProps = (state) => {
  const { tab } = getModalPayload(state, REPORTS_TAB_EDIT);

  return {
    tab,
    initialValues: {
      [NAME_FIELD]: tab.name,
    },
  };
};

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(REPORTS_TAB_EDIT)),
  editReportsTab: bindActionCreators(editReportsTab, dispatch),
});

const form = {
  form: REPORTS_TAB_EDIT_FORM_NAME,
  destroyOnUnmount: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(form)(EditReportsTabModal));
