import React from 'react';
import classNames from 'classnames';

import { INVENTORY_DATATRONS } from '../../../../../../../common/constants/inventory';
import { Todo } from '../../../../../../../common/types/common';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '../../../../../redux/modules/inventory.preview.module';
import {
  previewItem,
  previewItemType,
} from '../../../../../redux/selectors/inventory.selector';
import BooleanField from '../../../../common/dataPoints/configItem/viewOptions/boolean/BooleanField';

type Props = {
  datatron: Todo; //Datatron[],
};

export const InventoryDatatron: React.FC<Props> = ({ datatron }) => {
  const itemToPreview = useSelector(previewItem) || null;
  const previewType = useSelector(previewItemType);

  const isSelectedForPreview =
    itemToPreview &&
    previewType &&
    previewType === INVENTORY_DATATRONS &&
    itemToPreview.id === datatron.id;

  const dispatch = useDispatch();
  const handleClick = () => dispatch(select(INVENTORY_DATATRONS, datatron.id));

  const itemClassName = classNames(
    'inventory--item',
    'inventory--item-datatron',
    { active: isSelectedForPreview },
  );

  return (
    <div className={itemClassName} onClick={handleClick}>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <BooleanField value={datatron.active} />
          <div className="inventory--item--title" style={{ flexGrow: 1 }}>
            {datatron.name}
          </div>
          <div>Datatron</div>
        </div>
        <div className="inventory--item--content">
          <div className="info-dialog--list">
            <div className="info-dialog--item">
              <div className="info-dialog--text">
                {datatron.organization?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryDatatron;
