import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import SearchResultsItem from './SearchResultsItem';
import NoSearchResults from './NoSearchResults';
import searchMessages from '../../../../messages/search.messages';
import { Todo, TodoFunction } from '../../../../../../common/types/common';

interface Props {
  items: Todo[],
  type: string,
  onSelect: TodoFunction,
  navigationSelectedIndex?: number,
  nameToHighlight?: string,
  shortView?: boolean,

}

export const SearchResults: React.FC<Props> = ({
  type,
  items,
  onSelect,
  navigationSelectedIndex,
  nameToHighlight,
  shortView,
}) => {
  const { formatMessage } = useIntl();

  const title = formatMessage(searchMessages[`select_${type}`]);

  return (
    <div className={cn('search-alls', { 'search-alls-sm': shortView })}>
      <div className={`search-result--type-${type}`}>
        <div className="search-title">{title}</div>
        <ul className="search-alls-list">
          {items.length
            ? items.map((item, index) => (
              <SearchResultsItem
                key={item.id}
                item={item}
                onSelect={onSelect}
                isSelected={index === navigationSelectedIndex}
                nameToHighlight={nameToHighlight}
              />
            ))
            : <NoSearchResults/>}
        </ul>
      </div>
    </div>
  );
};

export default SearchResults;
