import { EMPTY_CALENDAR_QUERIES } from '../constants/calendar.constants';


export const machineCalendarsInitialState = {
  loading: false,
  loaded: false,
  error: null,
  list: [],
  filters: {
    ...EMPTY_CALENDAR_QUERIES,
  },
  newCalendarEvent: {
    loading: false,
    loaded: false,
    error: null,
  },
  available: true,
  cloneCalendar: {
    loading: false,
    loaded: false,
    error: null,
  },
  enabledCreateEvent: true,
  access: true,
};

export const machineCalendarsTestState = {
  loading: false,
  loaded: false,
  error: null,
  list: [],
  filters: {
    ...EMPTY_CALENDAR_QUERIES,
  },
  newCalendarEvent: {
    loading: false,
    loaded: false,
    error: null,
  },
  available: true,
  cloneCalendar: {
    loading: false,
    loaded: false,
    error: null,
  },
  enabledCreateEvent: true,
  access: true,
};