import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../modal/Modal';

function ConfirmRemoveModal({
  isOpen, onClose, onCancel, onConfirm, children,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentLabel="Header"
    >
      <div
        className="modal--header"
        onClick={onClose}
      >
        <span className="modal--close">&times;</span>
      </div>
      <div className="modal--content">
        <div className="pb15">
          { children }
        </div>
        <div className="line" />
        <div className="text-center">
          <button type="button" className="button__cancel" onClick={onCancel}>
            <FormattedMessage
              id="common.cancel"
              defaultMessage="Cancel"
            />
          </button>
          <button type="button" className="button__remove" onClick={onConfirm}>
            <FormattedMessage
              id="common.remove"
              defaultMessage="Remove"
            />
          </button>
        </div>
      </div>
    </Modal>
  );
}

ConfirmRemoveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ConfirmRemoveModal;
