import React from 'react';
import { FormattedMessage } from 'react-intl';

import StatusFilterContainer from './statusFilter/StatusFilter.container';
import commonMessages from '../../../../messages/common.messages';
import commonCoreMessages from '../../../../messages/common.core.messages';

const TableHeader = () => (
  <thead>
    <tr>
      <td className="td-category">
        <div className="menu-tags white--btn">
          <StatusFilterContainer />
        </div>
      </td>
      <FormattedMessage {...commonMessages.start} tagName="td" />
      <FormattedMessage {...commonMessages.end} tagName="td" />
      <FormattedMessage {...commonCoreMessages.machine} tagName="td" />
    </tr>
  </thead>
);

export default TableHeader;
