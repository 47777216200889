import React from 'react';
import PropTypes from 'prop-types';

function RightBarItem({ id, name, onClick }) {
  function onItemSelect() { onClick(id); }

  return (
    <li><a onClick={onItemSelect}>{name}</a></li>
  );
}

RightBarItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RightBarItem;
