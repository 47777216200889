import { defineMessages } from 'react-intl';
import {
  DATA_TAB,
  DETAILS_TAB,
  UPDATES_TAB,
  ASSISTANCE_TAB,
} from '../redux/constants/datatron.tabs.constants';

export default defineMessages({
  [DATA_TAB]: {
    id: 'datatron.data',
    defaultMessage: 'Data',
  },
  [DETAILS_TAB]: {
    id: 'datatron.details',
    defaultMessage: 'Details',
  },
  [ASSISTANCE_TAB]: {
    id: 'datatron.assistance',
    defaultMessage: 'Assistance',
  },
  [UPDATES_TAB]: {
    id: 'datatron.updates',
    defaultMessage: 'Updates',
  },
});
