import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BasicModal from '../../../../../modals/core/BasicModal';
import { getAdminUsersLink } from '../../../../../index/routes';
import commonCoreMessages from '../../../../../../messages/common.core.messages';
import adminPageMessages from '../../../../../../messages/adminPage.messages';
import Form from './AddUserForm.container';
import * as routeHandlers from '../../../../../index/routeHandlers';

export class AddUserFormModule extends React.Component {
  static propTypes = {
    adminCreateUser: PropTypes.func,
  };

  componentWillMount() {
    routeHandlers.onAdminAddUserLeave();
  }

  handleCancel = () => this.props.history.push(getAdminUsersLink());

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.adminCreateUser();
  };

  render() {
    const {
      intl,
    } = this.props;

    const object = intl.formatMessage(commonCoreMessages.user);
    const modalTitle = intl.formatMessage(adminPageMessages.addObject, { object });

    return (
      <BasicModal close={this.handleCancel} modalClass="modal--lg">
        <div className="modal-overflow">
          <div className="modal--content">
            <h3>{modalTitle}</h3>
            <Form handleSubmit={this.handleSubmit} handleCancel={this.handleCancel} />
          </div>
        </div>
      </BasicModal>
    );
  }
}

export default injectIntl(AddUserFormModule);
