import { connect } from 'react-redux';
import { isUndefined } from 'lodash';

import PageTabsBar from './PageTabsBar';
import * as selector from '../../../redux/selectors/experimentsPage.selector';
import * as tabs from '../../../redux/constants/experimentsPage.tabs.constants';
import { EXPERIMENT_ADD } from '../../../redux/constants/modals.constants';
import { selectTab } from '../../../redux/modules/experimentsPage.tabs.module';
import { openModal } from '../../../redux/modules/modals.module';
import PermissionedComponent from '../../common/permissions/PermissionedComponent.container';
import { EXPERIMENTS_PERMISSIONS } from '../../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => {
  const activeTab = selector.getActiveTab(state);
  const experimentsPagination = selector.getExperimentsPagination(state);
  const measurementsPagination = selector.getMeasurementsPagination(state);
  return {
    experimentsCount: (experimentsPagination && !isUndefined(experimentsPagination.total)) ?
      experimentsPagination.total : 0,
    measurementsCount: (measurementsPagination && !isUndefined(measurementsPagination.total)) ?
      measurementsPagination.total : 0,
    isExperimentsTabOpen: activeTab === tabs.EXPERIMENTS_PAGE,
    isMeasurementsTabOpen: activeTab === tabs.MEASUREMENTS_PAGE,
    canAddExperiment: permissions.canAddExperiment(),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openExperimentsTab: () => dispatch(selectTab(tabs.EXPERIMENTS_PAGE)),
  openMeasurementsTab: () => dispatch(selectTab(tabs.MEASUREMENTS_PAGE)),
  openAddExperimentModal: () => dispatch(openModal(EXPERIMENT_ADD)),
});

export default PermissionedComponent(EXPERIMENTS_PERMISSIONS)(connect(mapStateToProps, mapDispatchToProps)(PageTabsBar));
