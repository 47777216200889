import React from 'react';


interface Props {
  children: React.ReactNode
}

export const HighlightTextWrap: React.FC<Props> = ({ children }) => (
  <span className="wrap--text-highlight">{children}</span>
);

export default HighlightTextWrap;
