import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { NON_RECURRENT } from '../../../../../../redux/constants/calendars.entry.constants';

export const SingleEventFooter = ({
  onCancelClick,
  onConfirmClick,
}) => {
  const handleDelete = () => onConfirmClick(NON_RECURRENT);

  return (
    <div className="text-center">
      <button type="button" className="button__cancel" onClick={onCancelClick}>
        <FormattedMessage
          id="common.cancel"
          defaultMessage="Cancel"
        />
      </button>
      <button type="button" className="button__remove" onClick={handleDelete}>
        <FormattedMessage
          id="calendars.events.remove_not_recurrent_event"
          defaultMessage="Remove entry"
        />
      </button>
    </div>
  );
};

SingleEventFooter.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};

export default SingleEventFooter;
