import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import { getPayloadByEventId } from '../../core/api/events';

export const fetchPayloadByEvent = createAction(
  'fetch payload by event',
  (eventId) => eventId,
);

export const fetchPayloadByEventSuccess = createAction(
  'fetch payload by event successfully',
  (eventId, payload) => ({ eventId, payload }),
);

export const fetchPayloadByEventFail = createAction(
  'fetch payload by event fail',
  (eventId, error) => ({ eventId, error }),
);

export const reducer = {
  [fetchPayloadByEvent]: (state, eventId) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      events: state.dashboard.events.reduce((final, event) => {
        if (event.id === eventId) {
          return [
            ...final,
            {
              ...event,
              payload: {
                data: null,
                loaded: false,
                loading: true,
                error: null,
              },
            },
          ];
        }
        return [
          ...final,
          event,
        ];
      }, []),
    },
  }),

  [fetchPayloadByEventSuccess]: (state, { eventId, payload }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      events: state.dashboard.events.reduce((final, event) => {
        if (event.id === eventId) {
          return [
            ...final,
            {
              ...event,
              payload: {
                data: payload,
                loaded: true,
                loading: false,
                error: null,
              },
            },
          ];
        }
        return [
          ...final,
          event,
        ];
      }, []),
    },
  }),

  [fetchPayloadByEventFail]: (state, { eventId, error }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      events: state.dashboard.events.reduce((final, event) => {
        if (event.id === eventId) {
          return [
            ...final,
            {
              ...event,
              payload: {
                data: null,
                loaded: false,
                loading: false,
                error,
              },
            },
          ];
        }
        return [
          ...final,
          event,
        ];
      }, []),
    },
  }),
};

export function* fetchFetchPayloadByEventSaga({ payload: eventId }) {
  const { response, error } = yield call(getPayloadByEventId, eventId);

  if (response) {
    yield put(fetchPayloadByEventSuccess(eventId, response));
  } else {
    yield put(fetchPayloadByEventFail(eventId, error));
  }
}

export function* watchFetchFetchPayloadByEvent() {
  yield takeEvery(fetchPayloadByEvent.getType(), fetchFetchPayloadByEventSaga);
}
