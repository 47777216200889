import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function OUMember({ member }) {
  const classes = classNames(
    'online hide',
    {
      success: member.isWorking,
      alert: !member.isWorking,
    },
  );

  return (
    <tr className={member.isWorking ? '' : 'offline'}>
      <td className="td-icon">
        <span className={classes} />
      </td>
      <td>{ member.name }</td>
      <td>{ member.position }</td>
      <td>{ member.email }</td>
    </tr>
  );
}

OUMember.propTypes = {
  member: PropTypes.object.isRequired,
};
