import React, { BaseSyntheticEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormType } from '../../../core/forms/general';
import messages from '../../../messages/common.messages';
import Button from '../../_tools/button/Button';

interface Props {
  onCancelClick: () => void;
  submitting: boolean;
  pristine: boolean;
  cancelLink: string;
  formType: FormType;
  handleSubmit: (e: BaseSyntheticEvent) => void;
}

const useButtonLabel = (formType: FormType) => {
  const { formatMessage } = useIntl();
  switch (formType) {
    case 'add':
      return formatMessage(messages.add);
    case 'edit':
      return formatMessage(messages.update);
  }
};

export const DatatronFormFooter: React.FC<Props> = ({
  onCancelClick,
  submitting,
  pristine,
  formType,
  cancelLink,
  handleSubmit,
}) => {
  const buttonLabel = useButtonLabel(formType);
  return (
    <div className="central-form">
      <div className="form--left">&nbsp;</div>
      <div className="form--right">
        <div className="text-center">
          <Link
            type="button"
            className="button__cancel"
            onClick={onCancelClick}
            to={cancelLink}
          >
            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
          </Link>
          <Button
            type="submit"
            className="button__save"
            disabled={submitting || pristine}
            showLoader={submitting}
            onClick={handleSubmit}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};
