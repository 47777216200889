import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import * as selector from '../selectors/reports.selector';
import * as api from '../../core/api';
import { initializeReport } from '../../core/common/report';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';
import { updateReportSuccess, updateReportFail } from './report.update.module';
import * as datesModes from '../../../../common/enums/dates.modes';
import { DEFAULT_RELATIVE } from '../constants/relative.constants';

export const setReportControls = createAction(
  'set report controls',
  (reportId, { dates = null, autoRefresh = null }) => ({ reportId, dates, autoRefresh }),
);

export function* setReportControlsSaga({ payload: { reportId, dates, autoRefresh } }) {
  const state = yield select();
  const reports = yield call(selector.selectAllReports, state);
  const report = yield call(selector.getReportById, reportId, reports);

  const apiCallBody = {
    type: report.type,
  };

  if (dates) {
    apiCallBody.datesMode = dates.datesMode;
    if (dates.datesMode === datesModes.ABSOLUTE) {
      apiCallBody.from = dates[DATE_FROM];
      apiCallBody.to = dates[DATE_TO];
    } else if (dates.datesMode === datesModes.RELATIVE) {
      const fromRelative = dates.fromRelative || DEFAULT_RELATIVE;
      const toRelative = dates.toRelative || DEFAULT_RELATIVE;

      apiCallBody.fromRelative = `${fromRelative.count}.${fromRelative.unit}`;
      apiCallBody.toRelative = `${toRelative.count}.${toRelative.unit}`;
    }
  }
  if (autoRefresh) {
    apiCallBody.autoRefresh = autoRefresh;
  }

  if (isEmpty(apiCallBody)) return;

  const { response, error } = yield call(api.reports.update, reportId, apiCallBody);

  if (response) {
    yield put(updateReportSuccess(initializeReport(response)));
  } else {
    yield put(updateReportFail(reportId, error));
  }
}

export function* watchSetReportControls() {
  yield takeEvery(setReportControls.getType(), setReportControlsSaga);
}
