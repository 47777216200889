import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import ReportIcon from '../../../common/placeholderIcons/ReportIcon';
import placeholderMessages from '../../../../messages/placeholders.messages';

export const EmptyPlaceholder = ({ hasFiltersApplied }) => {
  const title = hasFiltersApplied ?
    placeholderMessages.noExperimentsFound : placeholderMessages.noExperimentsAdded;

  return (
    <div className="reports-empty">
      <div>
        <ReportIcon />
        <p>
          <FormattedMessage {...title} tagName="strong" />
        </p>
      </div>
    </div>
  );
};

EmptyPlaceholder.propTypes = {
  hasFiltersApplied: PropTypes.bool.isRequired,
};

export default EmptyPlaceholder;
