import { connect } from 'react-redux';

import LocationsHeader from './LocationsHeader';
import { getSort } from '../../../../../redux/selectors/admin.locations.selector';
import { adminSortLocations } from '../../../../../redux/modules/admin.locations.sort.filter.module';

export const mapStateToProps = (state) => {
  const sort = getSort(state);
  return {
    sortOption: sort.option,
    sortWay: sort.way,
  };
};

export const mapDispatchToProps = {
  onSortChange: adminSortLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsHeader);
