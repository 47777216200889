export const REPORT_LEGEND_DATA_POINT_FORM_NAME = 'REPORT_LEGEND_DATA_POINT_FORM_NAME';

export const AXIS_FIELD = 'axis';
export const COLOR_FIELD = 'color';
export const LINE_WIDTH_FIELD = 'lineWidth';
export const FILL_OPACITY_FIELD = 'fillOpacity';
export const ID_FIELD = 'id';

export const LEGEND_DATA_POINT_PROPS = [
  AXIS_FIELD,
  COLOR_FIELD,
  LINE_WIDTH_FIELD,
  FILL_OPACITY_FIELD,
  ID_FIELD,
];
