import { connect } from 'react-redux';

import TableQuerySearchState from '../../../../common/tableQuerySearch/TableQuerySearchState';
import { getQueryFilterValue } from '../../../../../redux/selectors/tools.selector';
import { filterByName } from '../../../../../redux/modules/tools.filter.module';
import { TOOLS_PAGE_QUERY_FILTER_INPUT } from '../../../../../redux/constants/components.constants';
import placeholderMessages from '../../../../../messages/placeholders.messages';

export const mapStateToProps = (state) => ({
  value: getQueryFilterValue(state),
  stateComponentId: TOOLS_PAGE_QUERY_FILTER_INPUT,
  placeholder: placeholderMessages.searchByTool,
});

export const mapDispatchToProps = {
  onQueryChange: filterByName,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableQuerySearchState);
