import { connect } from 'react-redux';

import TableQuerySearchState from '../../../../../common/tableQuerySearch/TableQuerySearchState';
import { getNameFilterValue } from '../../../../../../redux/selectors/admin.users.selector';
import { adminFilterUsersByName } from '../../../../../../redux/modules/admin.users.sort.filter.module';
import { AP_USERS_NAME_FILTER_INPUT } from '../../../../../../redux/constants/components.constants';

export const mapStateToProps = (state) => ({
  value: getNameFilterValue(state),
  stateComponentId: AP_USERS_NAME_FILTER_INPUT,
});

export const mapDispatchToProps = {
  onQueryChange: adminFilterUsersByName,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableQuerySearchState);
