import querystring from 'querystring';
import {
  apiRequest, postApiRequest, putApiRequest, deleteApiRequest,
} from './_tools';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';

export function getAll() {
  return apiRequest('/api/calendars');
}

export function getCalendarEvents(calendarId, fromDate, toDate) {
  const query = querystring.stringify({
    [DATE_FROM]: fromDate,
    [DATE_TO]: toDate,
  });

  return apiRequest(`/api/calendars/${calendarId}/events?${query}`);
}

export function create(body) {
  return postApiRequest('/api/calendars', body);
}

export function update(calendarId, body) {
  return putApiRequest(`/api/calendars/${calendarId}`, body);
}

export function deleteCalendar(calendarId) {
  return deleteApiRequest(`/api/calendars/${calendarId}`);
}

export function createEvent(calendarId, body) {
  return postApiRequest(`/api/calendars/${calendarId}/events`, body);
}

export function updateEvent(eventId, body) {
  return putApiRequest(`/api/calendars/events/${eventId}`, body);
}

export function deleteEvent(eventId) {
  return deleteApiRequest(`/api/calendars/events/${eventId}`);
}

export function deleteSingleEventOccurrence(eventId, exceptionDate) {
  const query = querystring.stringify({
    exceptionDate,
  });
  return deleteApiRequest(`/api/calendars/events/${eventId}/single?${query}`);
}

export function checkCalendarAvailable() {
  return apiRequest('/api/calendars/available');
}

export function cloneCalendar(calendarId, body) {
  return postApiRequest(`/api/calendars/${calendarId}/clone`, body);
}

export function getEventExceptions(eventId) {
  return apiRequest(`/api/calendars/events/${eventId}/exceptions`);
}
