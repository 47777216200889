import { connect } from 'react-redux';

import { SidePanel } from './SidePanel';
import { getOee, isOeeLoaded, isOeeLoading } from '../../../redux/selectors/dashboard.selector';
import { calculateOee, convertKPIs } from '../../../core/common/indicators';
import { availability, performance, quality } from '../../../../../common/enums/periodTypes';

const EMPTY_OEE = {
  oeeAvailable: false,
  oee: {
    total: {
      availability: 0,
      performance: 0,
      quality: 0,
    },
  },
};

export const mapStateToProps = (state) => {
  const apiData = getOee(state);
  const oeeLoaded = isOeeLoaded(state) && !isOeeLoading(state);
  const oeeAvailable = oeeLoaded && !!apiData && apiData.oeeAvailable && !!apiData.oee;

  const data = !oeeAvailable ? EMPTY_OEE : apiData;

  const main = calculateOee(
    data.oee.total[availability],
    data.oee.total[performance],
    data.oee.total[quality],
  );
  let indicators = convertKPIs(data.oee.total);
  if (!oeeAvailable) {
    main.displayValue = '—';
    main.isAmount = true;
    indicators = indicators.map((item) => ({
      ...item,
      displayValue: '—',
      isAmount: true,
    }));
  }

  return {
    loading: isOeeLoading(state),
    data: indicators,
    main,
  };
};

export default connect(mapStateToProps)(SidePanel);
