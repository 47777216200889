import React from 'react';
import PropTypes from 'prop-types';

import ExperimentsEmptyPlaceholderContainer from './emptyPlaceholder/EmptyPlaceholder.container';
import Loader from '../../_tools/loader/Loader';
import TableHeader from './header/TableHeader';
import Experiment from './experiment/Experiment';
import PaginationBar from '../../common/paginationBar/PaginationBar';

export const ExperimentsTab = ({
  experiments,
  isLoading,
  pagination,
  paginationAvailable,
  choosePage,
  query,
}) => (

  <div className="experiments-table">
    <table className="lined--table valign-middle">
      <TableHeader />
      <tbody>
        { experiments.map((item) => <Experiment key={item.id} experiment={item} textToHighlight={query} />) }
      </tbody>
    </table>

    { paginationAvailable && (<PaginationBar {...pagination} choosePage={choosePage} />) }

    <Loader show={isLoading} />
    { !isLoading && !experiments.length && <ExperimentsEmptyPlaceholderContainer /> }
  </div>
);

ExperimentsTab.propTypes = {
  experiments: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  choosePage: PropTypes.func.isRequired,
};

export default ExperimentsTab;
