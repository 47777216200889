import { connect } from 'react-redux';

import AccountModule from './AccountModule';
import * as selector from '../../../../../redux/selectors/admin.accounts.selector';
import { getValuesFromAccount } from '../../../../../core/common/admin.account.form';
import { adminUpdateAccount } from '../../../../../redux/modules/admin.account.update.module';

export const mapStateToProps = (state) => {
  const loaded = selector.isAccountLoaded(state);
  const account = selector.getAccount(state);
  const props = { isEditForm: true, loaded };

  if (loaded) {
    props.accountId = account.id;
    props.initialValues = getValuesFromAccount(account);
  }

  return props;
};

export const mapDispatchToProps = {
  adminUpdateAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountModule);
