import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../..';
import { DATE_FROM, DATE_TO } from '../../../../../../../../common/constants';
import { datePropType } from '../../../../../../core/propTypes';

export default class AbsolutePresets extends Component {
  static propTypes = {
    [DATE_FROM]: datePropType, // eslint-disable-line react/no-unused-prop-types
    [DATE_TO]: datePropType, // eslint-disable-line react/no-unused-prop-types
    setAbsoluteDates: PropTypes.func.isRequired,
  };

  handleResetDates = (event) => {
    event.preventDefault();

    if (this.props[DATE_FROM] || this.props[DATE_TO]) {
      this.props.setAbsoluteDates(null, null);
    }
  };

  handleThisMonthButtonClick = (event) => {
    event.preventDefault();

    this.props.setAbsoluteDates(
      moment().startOf('month'),
      moment().endOf('day'),
    );
  };

  handleLastMonthButtonClick = (event) => {
    event.preventDefault();

    this.props.setAbsoluteDates(
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    );
  };

  isThisMonthSelected = () => !!this.props[DATE_FROM]
    && !!this.props[DATE_TO]
    && moment(this.props[DATE_FROM]).isSame(moment().startOf('month'), 'day')
    && moment(this.props[DATE_TO]).isSame(moment(), 'day');

  isLastMonthSelected = () => !!this.props[DATE_FROM]
    && !!this.props[DATE_TO]
    && moment(this.props[DATE_FROM]).isSame(moment().subtract(1, 'month').startOf('month'), 'day')
    && moment(this.props[DATE_TO]).isSame(moment().subtract(1, 'month').endOf('month'), 'day');

  anyDateSelected = () => this.props[DATE_FROM] || this.props[DATE_TO];

  render = () => (
    <div className="datepicker--buttons--horizontal">
      <Button
        className={cn('button', 'light-grey-white', { active: this.isThisMonthSelected() })}
        onClick={this.handleThisMonthButtonClick}
      >
        <FormattedMessage
          id="datepicker.this_month"
          defaultMessage="This Month"
        />
      </Button>
      <Button
        className={cn('button', 'light-grey-white', { active: this.isLastMonthSelected() })}
        onClick={this.handleLastMonthButtonClick}
      >
        <FormattedMessage
          id="datepicker.last_month"
          defaultMessage="Last Month"
        />
      </Button>
      <Button
        className={cn('button', 'light-grey-white', { disabled: !this.anyDateSelected() })}
        onClick={this.handleResetDates}
      >
        <FormattedMessage
          id="datepicker.reset_dates"
          defaultMessage="Reset Dates"
        />
      </Button>
    </div>
  );
}
