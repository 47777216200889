import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import CycleDetectionCreate from './CycleDetectionCreate';
import { createAIAppCycleDetection } from '../../../../redux/modules/aiApp.cycleDetection.create.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { CYCLE_DETECTION_ADD } from '../../../../redux/constants/modals.constants';

interface StateProps {
  isAddForm: boolean;
}

interface DispatchProps {
  createCycleDetection: typeof createAIAppCycleDetection;
  close: () => void;
}

const mapStateToProps = (): StateProps => ({
  isAddForm: true,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createCycleDetection: bindActionCreators(createAIAppCycleDetection, dispatch),
  close: () => dispatch(closeModal(CYCLE_DETECTION_ADD)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CycleDetectionCreate);
