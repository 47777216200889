import moment from 'moment';
import querystring from 'querystring';

import { apiRequest } from './_tools';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';

export function getClusterById(clusterId) {
  return apiRequest(`/api/clusters/${clusterId}`);
}

export const getClustersSummaryByMachineId = ({
  machineId,
  category,
  [DATE_FROM]: fromDate,
  [DATE_TO]: toDate,
  query,
  ...otherFilters
}) => {
  const queryFilters = {
    ...otherFilters,
    ...(category && { category }),
    ...(fromDate && { [DATE_FROM]: moment(fromDate).toISOString() }),
    ...(toDate && { [DATE_TO]: moment(toDate).toISOString() }),
    ...(query && { query }),
  };

  const urlQuery = querystring.stringify(queryFilters);
  return apiRequest(`/api/machines/${encodeURIComponent(machineId)}/clusters_summary?${urlQuery}`);
};

export const getClustersGroupByMachineId = ({ machineId, category, name, ...filters }) => {
  const queryFilters = { category, name };

  if (filters[DATE_FROM]) {
    queryFilters[DATE_FROM] = moment(filters[DATE_FROM]).toISOString();
  }
  if (filters[DATE_TO]) {
    queryFilters[DATE_TO] = moment(filters[DATE_TO]).toISOString();
  }

  const query = querystring.stringify(queryFilters);

  return apiRequest(`/api/machines/${machineId}/clusters_summary/clusters?${query}`);
};
