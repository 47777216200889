import { connect } from 'react-redux';

import Buttons from './Buttons';
import { openModal } from '../../../../../redux/modules/modals.module';
import { MEASUREMENT_MERGE, MEASUREMENT_ARCHIVE, MEASUREMENT_DOWNLOAD } from '../../../../../redux/constants/modals.constants';
import { search } from '../../../../../redux/modules/search.module';
import { EXPERIMENTS } from '../../../../../../../common/constants/entities';
import { COMPLETED, ARCHIVED } from '../../../../../../../common/enums/measurementStatuses';
import PermissionedComponent from '../../../../common/permissions/PermissionedComponent.container';
import { EXPERIMENTS_PERMISSIONS } from '../../../../../../../common/constants/permissions';

export const mapStateToProps = (state, { measurement, permissions }) => ({
  canMergeMeasurement: permissions.canMergeMeasurement() && measurement.status === COMPLETED,
  canArchiveMeasurement: permissions.canArchiveMeasurement() && measurement.status !== ARCHIVED,
});

export const mapDispatchToProps = (dispatch, { measurement }) => ({
  openMergeModal: () => {
    dispatch(search('', true, [EXPERIMENTS]));
    dispatch(openModal(MEASUREMENT_MERGE, { measurement }));
  },
  openArchiveModal: () => dispatch(openModal(MEASUREMENT_ARCHIVE, { measurement })),
  openDownloadModal: () => dispatch(openModal(MEASUREMENT_DOWNLOAD, { measurement })),
});

export default PermissionedComponent(EXPERIMENTS_PERMISSIONS)(connect(mapStateToProps, mapDispatchToProps)(Buttons));
