import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { getActivityIndexById } from '../selectors/maintenance.selector';
import { closeModal } from './modals.module';
import { MAINTENANCE_ASSIGN } from '../constants/modals.constants';

export const updateActivity = createAction(
  'update activity',
  (activityId, updateData) => ({ activityId, updateData }),
);

export const updateActivitySuccess = createAction(
  'update activity success',
  (activity) => activity,
);

export const updateActivityFail = createAction(
  'update activity fail',
  (activityId, error) => ({ activityId, error }),
);

export const reducer = {
  [updateActivitySuccess]: (state, updatedActivity) => {
    const index = getActivityIndexById(state, updatedActivity.id);

    if (index === -1) return state;

    const activity = state.maintenance.activities[index];

    return {
      ...state,
      maintenance: {
        ...state.maintenance,
        activities: [
          ...state.maintenance.activities.slice(0, index),
          {
            ...activity,
            ...updatedActivity,
          },
          ...state.maintenance.activities.slice(index + 1),
        ],
      },
    };
  },
};

export function* updateActivitySaga({ payload: { activityId, updateData } }) {
  const { response, error } = yield call(api.activities.updateActivity, activityId, updateData);

  if (response) {
    yield put(updateActivitySuccess(response));
    yield put(closeModal(MAINTENANCE_ASSIGN));
  } else {
    yield put(updateActivityFail(activityId, error));
  }
}

export function* watchUpdateActivity() {
  yield takeEvery(updateActivity.getType(), updateActivitySaga);
}
