import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

import * as propTypes from '../../../core/propTypes';
import { getExportUrl } from '../../../core/common/event';
import StatusFilters from './StatusFilters';
import MachineFiltersContainer from './MachineFilters.container';
import LocationFiltersContainer from './LocationFilters.container';
import OrganizationFiltersContainer from './OrganizationFilters.container';
import TagFiltersContainer from './TagFilters.container';
import { Export } from '../../common/export/Export';
import LocationBreadcrumbs from '../../_tools/locationBreadcrumbs/LocationBreadcrumbs';
import DatePicker from '../../_tools/dateRangePickerV2/DatePicker';
import commonMessages from '../../../messages/common.messages';
import { DASHBOARD_EVENTS } from '../../../redux/constants/export.type.constants';
import Counter from '../../common/counter/Counter';
import * as datesModes from '../../../../../common/enums/dates.modes';

const FiltersBar = ({
  showHideCalendar,
  clearFilters,
  filtersCount,
  filterByDate,
  dateFilters,
  filters,
  dateFiltersSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { formatMessage } = useIntl();

  const filterDropdownClassName = classnames('filters--wrap', { opened: isOpen });
  const downloadButtonMessage = formatMessage(commonMessages.exportEvents);

  return (
    <div>
      <div className="subheader">
        <div className="subheader-in">
          <div className="central">
            <div className="subheader--left">
              <LocationBreadcrumbs />
            </div>
            <div className="subheader--right">
              <button
                className="button__calendar"
                onClick={showHideCalendar}
              >
                <span className="icon-calendar" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="central">
        <div className="filters--wrap--block">
          <div className={filterDropdownClassName}>
            <div className="filter--line">
              <button className="button white filter-opener" onClick={toggle}>
                <span className="icon icon-filter-funnel" />
                <FormattedMessage
                  id="dashboard.filter.filter_events"
                  defaultMessage="Filter Events"
                />
                <Counter value={filtersCount} />
              </button>
              <div className="pull-right">
                <Export
                  dateFiltersSelected={dateFiltersSelected}
                  generateExportUrl={getExportUrl}
                  downloadButtonMessage={downloadButtonMessage}
                  downloadProps={{ ...filters, ...dateFilters }}
                  type={DASHBOARD_EVENTS}
                />
              </div>
              <div className="pull-right">
                <div className="reports-actions">
                  <DatePicker
                    onUpdate={filterByDate}
                    datesMode={datesModes.ABSOLUTE}
                    useConfirmationFooter
                    showAbsoluteDatesPresets
                    showDatesModeBar={false}
                    shortPreview
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dateFilters}
                  />
                </div>
              </div>
            </div>
            <div className="menu-tags">
              <button className="clear--btn" onClick={clearFilters}>
                <span className="icon icon-filter-funnel" />
                <FormattedMessage
                  id="dashboard.filter.reset"
                  defaultMessage="Reset"
                />
              </button>
              <MachineFiltersContainer />
              <LocationFiltersContainer />
              <OrganizationFiltersContainer />
              <TagFiltersContainer />
              <StatusFilters />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FiltersBar.propTypes = {
  filtersCount: PropTypes.number.isRequired,
  clearFilters: PropTypes.func.isRequired,
  showHideCalendar: PropTypes.func.isRequired,
  filterByDate: PropTypes.func.isRequired,
  dateFilters: propTypes.dateFilters.isRequired,
  dateFiltersSelected: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
};

export default FiltersBar;
