import { findAndUpdate, isMatchCriteria } from '../../../../common/helpers/common.helper';

export const updateCluster = (state, clusterGroup, clusterId, newData) => ({
  ...state,
  machine: {
    ...state.machine,
    clustersSummary: {
      ...state.machine.clustersSummary,
      data: state.machine.clustersSummary.data.map((stateClusterGroup) => {
        if (isMatchCriteria(stateClusterGroup, clusterGroup)) {
          return {
            ...stateClusterGroup,
            clusters: {
              ...stateClusterGroup.clusters,
              data: findAndUpdate(
                stateClusterGroup.clusters.data,
                {
                  id: clusterId,
                },
                newData,
                true,
              ),
            },
          };
        }
        return stateClusterGroup;
      }),
    },
  },
});
