import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import EditModalFooter from '../../core/footers/EditModalFooter';
import FormBodyContainer from './FormBody.container';
import adminPageMessages from '../../../../messages/adminPage.messages';
import ModalLine from '../../core/ModalLine';

const EditLegendItem = ({
  close, pristine, submitting, dataPoint, update,
}) => {
  const handleCancel = () => close();
  const handleSubmit = (e) => {
    e.preventDefault();
    update();
  };

  return (
    <BasicModal isOpen close={close} modalClass="modal--sm">
      <div className="modal--content">
        <FormattedMessage {...adminPageMessages.editObject} values={{ object: dataPoint.label }} tagName="h3" />

        <form className="form" onSubmit={handleSubmit}>
          <FormBodyContainer />
          <ModalLine />
          <EditModalFooter
            onCancelClick={handleCancel}
            onSaveClick={handleSubmit}
            isSaveButtonLoading={submitting}
            isSaveButtonDisabled={pristine || submitting}
          />
        </form>
      </div>
    </BasicModal>
  );
};

EditLegendItem.propTypes = {
  close: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  dataPoint: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
};

export default EditLegendItem;
