import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import AddModalFooter from '../../../core/footers/AddModalFooter';
import ModalLine from '../../../core/ModalLine';
import commonCoreMessages from '../../../../../messages/common.core.messages';
import commonFieldsMessages from '../../../../../messages/common.fields.messages';
import * as form from '../../../../../redux/constants/tools.add.form.constants';
import { FormField, TextareaFormField } from '../../../../_tools';
import MachineSelectContainer from '../../../../_tools/formFields/select/MachineSelect.container';

export const AddToolForm = ({
  submitting,
  pristine,
  handleCancel,
  handleSubmit,
  showComment,
}) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form-subtitle">
      <FormattedMessage
        id="tools.modal.tool_details"
        defaultMessage="Tool Details"
      />
    </div>
    <div className="inputs--row">
      <div className="inputs--col--md2">
        <FormField
          label={commonCoreMessages.label.id}
          name={form.LABEL_FIELD}
          tabIndex={1}
          maxLength="255"
          autoFocus
        />
      </div>
      <div className="inputs--col--md2">
        <FormField
          label={commonCoreMessages.type.id}
          name={form.TYPE_FIELD}
          tabIndex={2}
          maxLength="255"
        />
      </div>
    </div>
    <div className="inputs--row">
      <div className="inputs--col--md2">
        <FormField
          label={commonCoreMessages.model.id}
          name={form.MODEL_FIELD}
          tabIndex={3}
          maxLength="255"
        />
      </div>
      <div className="inputs--col--md2">
        <FormField
          label={commonFieldsMessages.serialNumber.id}
          name={form.SERIAL_FIELD}
          tabIndex={4}
          canGenerateUUID
        />
      </div>
    </div>

    <div className="form-subtitle">
      <FormattedMessage
        id="tools.modal.mount_to_machine"
        defaultMessage="Mount to Machine"
      />
    </div>

    <label>
      <FormattedMessage {...commonCoreMessages.machine} />
      <Field
        component={MachineSelectContainer}
        name={form.MACHINE_FIELD}
        isClearable
        tabIndex={5}
      />
    </label>

    { showComment && (
      <TextareaFormField
        label={commonCoreMessages.comment.id}
        name={form.COMMENT_FIELD}
        tabIndex={6}
        cols="30"
        rows="10"
        className="height--md"
        maxLength="255"
      />
    ) }

    <ModalLine />

    <AddModalFooter
      onCancelClick={handleCancel}
      onAddClick={handleSubmit}
      isAddButtonDisabled={pristine || submitting}
      isAddButtonLoading={submitting}
      cancelButtonTabIndex={7}
      addButtonTabIndex={8}
    />
  </form>
);

AddToolForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default AddToolForm;
