import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { DATE_FROM, DATE_TO } from '../../../../../../../../common/constants';

import Calendar from './Calendar';
import { DateProps } from '../../../../../../core/propTypes/propTypes';
import { Todo, TodoFunction } from '../../../../../../../../common/types/common';

interface Props {
  [DATE_FROM]?: DateProps, // eslint-disable-line react/no-unused-prop-types
  [DATE_TO]?: DateProps, // eslint-disable-line react/no-unused-prop-types
  onChange: TodoFunction,
  onMouseOver: TodoFunction,
  fromLabel: Todo,
  toLabel: Todo,
  allowFuture: boolean,
}

export const CalendarDisplay: React.FC<Props> = ({
  [DATE_FROM]: date_from,
  [DATE_TO]: date_to,
  onChange,
  onMouseOver,
  fromLabel,
  toLabel,
  allowFuture,
}) => {

  const handleChangeStart = useCallback((date) => onChange(DATE_FROM, date), [onChange]);

  const handleChangeEnd = useCallback((date) => onChange(DATE_TO, date, true), [onChange]);

  const handleChangeEndTime = useCallback((date) => onChange(DATE_TO, date), [onChange]);

  const getMaxDate = useCallback((toDate?) => {
    if (!toDate) return moment().startOf('day').toISOString();
    return toDate;
  }, []);

  const maxDateFrom = getMaxDate(date_to);
  const maxDateTo = getMaxDate();

  return (
    <div className="datepicker-dropdowns--flex">
      <div className="datepicker-dropdowns--one" onMouseOver={onMouseOver}>
        <div className="datepicker-dropdowns--top">
          <div className="datepicker-wrap">
            <FormattedMessage {...fromLabel} />
          </div>
          <div className="datepicker-wrap">
            <FormattedMessage {...toLabel} />
          </div>
        </div>
        <div className="datepickers-wrap">
          <Calendar
            value={date_from}
            maxDate={maxDateFrom}
            onDateChange={handleChangeStart}
            onTimeChange={handleChangeStart}
            allowFuture={allowFuture}
          />
          <Calendar
            value={date_to}
            maxDate={maxDateTo}
            onDateChange={handleChangeEnd}
            onTimeChange={handleChangeEndTime}
            allowFuture={allowFuture}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarDisplay;