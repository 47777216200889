import { createAction } from '@reduxjs/toolkit';

export const selectTab = createAction(
  'select tab in datatron page',
  (tab) => ({ payload: tab }),
);

export const reducer = {
  [selectTab.type]: (state, tab) => ({
    ...state,
    datatron: {
      ...state.datatron,
      tab,
    },
  }),
};
