import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ColumnExpandCollapseContainer from '../actions/ColumnExpandCollapse.container';
import CommentingContainer from '../actions/Commenting.container';
import ColumnEventInfoContainer from '../event/EventInfo.container';
import ColumnDialogMenu from '../actions/ColumnDialogMenu';

export const CARD_HEIGHT = 174;

export class InboxEvent extends Component {
  constructor(props) {
    super(props);

    this.node = null;
    this.expandCard = this.expandCard.bind(this);
  }

  expandCard() {
    const {
      event, collapseExpand, canSeeAnomaly, fetchAnomaly,
    } = this.props;
    if (canSeeAnomaly) fetchAnomaly(event.id);
    collapseExpand(event.id);
  }

  render() {
    const {
      event,
      isFatal,
      isWarning,
      isInfo,
      isAnomaly,
      isOpened,
      isError,
      canSeeAnomaly,
    } = this.props;

    const className = classNames('maintenance--item', {
      info: isInfo,
      alert: isFatal,
      warning: isWarning,
      anomaly: isAnomaly,
      error: isError,
      opened: isOpened,
    });

    return (
      <div
        className={className}
        ref={(node) => {
          this.node = node;
        }}
        onClick={this.expandCard}
      >
        <ColumnExpandCollapseContainer eventId={event.id} />
        <ColumnDialogMenu
          canHide
          canAssign={false}
          canResolve={false}
          eventId={event.id}
        />
        <ColumnEventInfoContainer event={event} canSeeAnomaly={canSeeAnomaly} />
        <div className="maintenance--item--bottom">
          <ul>
            <li />
            <CommentingContainer
              commentsCount={event.comments.length}
              eventId={event.id}
            />
          </ul>
        </div>
      </div>
    );
  }
}

InboxEvent.propTypes = {
  event: PropTypes.object.isRequired,
  isFatal: PropTypes.bool.isRequired,
  isWarning: PropTypes.bool.isRequired,
  isInfo: PropTypes.bool.isRequired,
  isAnomaly: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  collapseExpand: PropTypes.func.isRequired,
  canSeeAnomaly: PropTypes.bool.isRequired,
  fetchAnomaly: PropTypes.func.isRequired,
};

export default InboxEvent;
