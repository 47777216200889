import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import noDoubleClick from '../../../../_tools/stopPropagation';

export default class Assignee extends Component {
  static propTypes = {
    user: PropTypes.object,
    assign: PropTypes.func.isRequired,
    activityId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
  };

  handleAssign = (event) => {
    noDoubleClick(event);

    const {
      assign, organizationId, activityId, user,
    } = this.props;
    assign(activityId, organizationId, user);
  };

  render = () => {
    const { user } = this.props;

    return (
      <div className="assignee-name">
        <a onClick={this.handleAssign}>
          <span className="icon icon-person" />
          <span>&nbsp;</span>
          { user
            ? <span>{user.name}</span>
            : (
              <FormattedMessage
                id="maintenance.stream.event.unassigned"
                defaultMessage="Unassigned"
              />
            )}
        </a>
      </div>
    );
  };
}
