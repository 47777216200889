import { connect } from 'react-redux';
import { isUndefined } from 'lodash';

import PathButton from './PathButton';
import { openModal } from '../../../redux/modules/modals.module';
import { getLocationPath } from '../../../core/common/location';
import { LOCATION_PICKER } from '../../../redux/constants/modals.constants';

export const mapStateToProps = (state, { location, tempLocationPath }) => {
  const pathFromLocation = location ? getLocationPath(location.name, location.path) : [];

  return {
    locationPathArray: isUndefined(tempLocationPath)
      ? pathFromLocation : tempLocationPath,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModal: () => dispatch(openModal(LOCATION_PICKER)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PathButton);
