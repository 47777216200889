import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Tooltip extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { open: false };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  componentWillMount() {
    this.close();
  }

  open() {
    this.setState({ open: true });
  }

  close() {
    this.setState({ open: false });
  }

  render() {
    const { children } = this.props;
    const { open } = this.state;

    return (
      <div className="tooltip-wrap" onMouseEnter={this.open} onMouseLeave={this.close}>
        <div className="tooltip-icon">
          <span className="icon-info" />
        </div>
        { open && (<div className="tooltip-in-wrap" onMouseEnter={this.open}><div className="tooltip-in">{children}</div></div>)}
      </div>
    );
  }
}
