import React from 'react';
import { FormattedMessage } from 'react-intl';

import MachineCalendarsAddButtonContainer from './MachineCalendarsAddButton.container';

export const InventoryListEmptyPlaceholder = () => (
  <div className="reports-empty">
    <div>
      <div className="pb15">
        <div className="reports-empty-icon">
          <object data="/img/empty-calendar.svg" type="image/svg+xml" />
        </div>
        <p>
          <FormattedMessage
            id="calendars.empty_placeholder.title"
            defaultMessage="No Schedule or Shift calendars added"
            tagName="strong"
          />
        </p>
        <FormattedMessage
          id="calendars.empty_placeholder.description"
          defaultMessage={'Click on "Add New" in order to add new Calendar'}
          tagName="p"
        />
      </div>
      <MachineCalendarsAddButtonContainer />
    </div>
  </div>
);

export default InventoryListEmptyPlaceholder;
