import { connect } from 'react-redux';

import ErrorMessage from './Error';

export function mapStateToProps(state) {
  return {
    error: state.page.error,
    values: {},
  };
}

export default connect(mapStateToProps)(ErrorMessage);
