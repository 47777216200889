import { connect } from 'react-redux';
import { reset } from 'redux-form';

import Activities from './Activity';
import { openModal, closeModal } from '../../../../../redux/modules/modals.module';
import { isModalOpen } from '../../../../../redux/selectors/modals.selector';
import { RESOLVE_MODAL_FORM_NAME } from '../../../../../redux/constants/resolveModal.form.constants';

function getResolveModalId(eventId) {
  return `resolveModal_${eventId}`;
}

export function mapStateToProps(state, { activity }) {
  return {
    isResolveModalOpen: isModalOpen(state, getResolveModalId(activity.id)),
  };
}

export function mapDispatchToProps(dispatch, { activity }) {
  const resolveModalId = getResolveModalId(activity.id);

  return {
    onResolveModalOpen: () => dispatch(openModal(resolveModalId)),
    onResolveModalClose: () => {
      dispatch(closeModal(resolveModalId));
      dispatch(reset(RESOLVE_MODAL_FORM_NAME));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
