import { connect } from 'react-redux';

import EventsTable from './EventsTable';
import {
  createCalendarEvent,
  enableCreateCalendarEvent,
  disableCreateCalendarEvent,
} from '../../../../redux/modules/calendars.create.event.module';
import { enabledCreateEvent } from '../../../../redux/selectors/calendar.selector';

export const mapStateToProps = (state) => ({
  enabledCreateEvent: enabledCreateEvent(state),
});

export const mapDispatchToProps = {
  createCalendarEvent,
  enableCreateCalendarEvent,
  disableCreateCalendarEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsTable);
