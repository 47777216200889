import { createAction } from 'redux-act';
import { put, takeLatest } from 'redux-saga/effects';

import { fetchEventsSummaryGroup } from './machine.fetch.summaryGroup.module';
import { replaceSummaryGroup, findSummaryGroup } from '../selectors/machine.selector';
import { EVENTS_IN_EACH_PART } from '../constants/event.constants';

export const loadMoreSummaryGroupEvents = createAction(
  'load more events of summary group',
  (machineId, summaryGroup) => ({ machineId, summaryGroup }),
);

export const summaryGroupEventsNextPage = createAction(
  'set next events (of summary group) portion for load',
  (summaryGroup) => ({ summaryGroup }),
);

export const resetSummaryGroupEventsPagination = createAction(
  'reset events (of summary group) pagination',
  (machineId, summaryGroup) => ({ machineId, summaryGroup }),
);

export const reducer = {
  [summaryGroupEventsNextPage]: (state, { summaryGroup }) => {
    const stateSummaryGroup = findSummaryGroup(state.machine.summary.data, summaryGroup);

    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          data: replaceSummaryGroup(state.machine.summary.data, {
            ...stateSummaryGroup,
            events: {
              ...stateSummaryGroup.events,
              fromIndex: stateSummaryGroup.events.toIndex,
              toIndex: stateSummaryGroup.events.toIndex + EVENTS_IN_EACH_PART,
            },
          }),
        },
      },
    };
  },
  [resetSummaryGroupEventsPagination]: (state, { summaryGroup }) => {
    const stateSummaryGroup = findSummaryGroup(state.machine.summary.data, summaryGroup);

    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          data: replaceSummaryGroup(state.machine.summary.data, {
            ...stateSummaryGroup,
            events: {
              ...stateSummaryGroup.events,
              data: [],
              loaded: false,
              loading: false,
              fromIndex: 0,
              toIndex: EVENTS_IN_EACH_PART,
            },
          }),
        },
      },
    };
  },
};

export function* loadMoreSummaryGroupEventsSaga({ payload: { machineId, summaryGroup } }) {
  yield put(summaryGroupEventsNextPage(summaryGroup));
  yield put(fetchEventsSummaryGroup(machineId, summaryGroup));
}

export function* watchLoadMoreSummaryGroupEvents() {
  yield takeLatest(loadMoreSummaryGroupEvents.getType(), loadMoreSummaryGroupEventsSaga);
}
