import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';

import EditModalFooter from '../../../../../../modals/core/footers/EditModalFooter';
import ViewModalFooter from '../../../../../../modals/core/footers/ViewModalFooter';
import * as tabs from '../../../../../../../core/common/admin.user.form.tabs';
import commonCoreMessages from '../../../../../../../messages/common.core.messages';
import FormTabs from '../../../../../../common/formTabs/FormTabs';
import BasicUserFormContainer from './basic/BasicUserForm.container';
import AdvancedUserFormContainer from './advanced/AdvancedUserForm.container';

export class UserForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    tab: PropTypes.string.isRequired,
    setTab: PropTypes.func.isRequired,
    isEditForm: PropTypes.bool,
    isViewForm: PropTypes.bool,

  };

  static defaultProps = {
    isEditForm: false,
    isViewForm: false,
    formBodyProps: {},
  };

  getTabsList() {
    const { intl, tab, setTab } = this.props;
    return [
      {
        label: intl.formatMessage(commonCoreMessages.basic),
        isActive: tab === tabs.BASIC_TAB,
        onClick: () => setTab(tabs.BASIC_TAB),
      },
      {
        label: intl.formatMessage(commonCoreMessages.advanced),
        isActive: this.props.tab === tabs.ADVANCED_TAB,
        onClick: () => setTab(tabs.ADVANCED_TAB),
      },
    ];
  }

  render() {
    const {
      submitting,
      isEditForm,
      isViewForm,
      pristine,
      handleSubmit,
      handleCancel,
      tab,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="form form-md">
        <div className={cn('modal-form-in', { 'form--read-only': !!isViewForm })}>
          <FormTabs tabs={this.getTabsList()} />

          <BasicUserFormContainer readOnly={!!isViewForm} hidden={tab !== tabs.BASIC_TAB} />
          <AdvancedUserFormContainer readOnly={!!isViewForm} hidden={tab !== tabs.ADVANCED_TAB} />

          <div className="line" />
          { isEditForm && (
            <EditModalFooter
              onCancelClick={handleCancel}
              onSaveClick={handleSubmit}
              isSaveButtonDisabled={pristine || submitting}
            />
          ) }
          { isViewForm && (
            <ViewModalFooter
              onCancelClick={handleCancel}
              onEditClick={handleSubmit}
            />
          ) }
        </div>
      </form>
    );
  }
}

export default injectIntl(UserForm);
