import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MachineCalendarsAddButtonContainer from './MachineCalendarsAddButton.container';

export function MachineCalendarsHeader({ goBack, showAddCalendarButton }) {
  return (
    <div className="subheader">
      <div className="subheader-in">
        <div className="subheader--left">
          <ul className="menu tab-menu">
            <li className="back-link">
              <button onClick={goBack}>
                <span className="icon-arrow-left" />
                <FormattedMessage
                  id="common.back"
                  defaultMessage="Back"
                />
              </button>
            </li>
            <li>
              <h1 className="h1">
                <FormattedMessage
                  id="inventory.header.shift_machine_schedule"
                  defaultMessage="Shift & Machine Schedule"
                />
              </h1>
            </li>
          </ul>
        </div>
        { showAddCalendarButton && (
          <div className="subheader--right">
            <MachineCalendarsAddButtonContainer />
          </div>
        )}
      </div>
    </div>
  );
}

MachineCalendarsHeader.propTypes = {
  showAddCalendarButton: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default MachineCalendarsHeader;
