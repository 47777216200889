import React from 'react';
import { FormattedMessage } from 'react-intl';

import ReportAddButtonContainer from './ReportAddButton.container';

export const NoReports = () => (
  <div className="reports-empty">
    <div>
      <div className="pb15">
        <div className="reports-empty-icon fz40">
          <span className="icon-chart" />
        </div>
        <h4>
          <FormattedMessage
            id="reports.no_reports_yet"
            defaultMessage="No Reports Yet"
          />
        </h4>
        <p>
          <FormattedMessage
            id="reports.сreate_one_to_see_how_your_machines_are_doing."
            defaultMessage="Create one to see how your machines are doing."
          />
        </p>
      </div>
      <ReportAddButtonContainer />
    </div>
  </div>
);

export default NoReports;
