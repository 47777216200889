import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as organizationsApi from '../../core/api/organizations.js';

export const fetchOU = createAction(
  'fetch ou',
  (ouId) => ouId,
);

export const fetchOUSuccess = createAction(
  'fetch ou successfully',
  (ou) => ou,
);

export const fetchOUFail = createAction(
  'fetch ou fail',
  (error) => error,
);

export const reducer = {
  [fetchOU]: (state) => ({
    ...state,
    ou: {
      ...state.ou,
      loaded: false,
      loading: true,
      error: '',
    },
  }),

  [fetchOUSuccess]: (state, ou) => ({
    ...state,
    ou: {
      ...ou,
      loaded: true,
      loading: false,
    },
  }),

  [fetchOUFail]: (state, error) => ({
    ...state,
    ou: {
      ...state.ou,
      loaded: false,
      loading: false,
      error,
    },
  }),
};

export function* fetchOUSaga({ payload: ouId }) {
  const { response, error } = yield call(organizationsApi.getById, ouId);

  if (response) {
    yield put(fetchOUSuccess(response));
  } else {
    yield put(fetchOUFail(error));
  }
}

export function* watchFetchOU() {
  yield takeEvery(fetchOU.getType(), fetchOUSaga);
}
