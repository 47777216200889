import { connect } from 'react-redux';

import AddToolModal from './AddToolModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { createTool } from '../../../../redux/modules/tools.create.module';
import { TOOL_ADD } from '../../../../redux/constants/modals.constants';

export const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (e) => {
    e.preventDefault();
    dispatch(createTool());
  },
  close: () => dispatch(closeModal(TOOL_ADD)),
});

export default connect(null, mapDispatchToProps)(AddToolModal);
