import React from 'react';
import { scaleLinear } from 'd3-scale';
import { max, min } from 'd3-array';

import Slider from './Slider';
import { findHighestPoint, getX } from '../../../core/common/reportChart';
import { Todo, TodoFunction } from '../../../../../common/types/common';

const MARGIN = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 32,
} as const;
const AXIS_HEIGHT = 20;

interface Props {
  id: string;
  getY: TodoFunction;
  changeSelection: (domain: Todo, reportId: Todo) => void;
  selection: Todo;
  width?: number;
  height?: number;
  data: Todo[];
  [key: string]: Todo;
}

const SliderContainer: React.FC<Props> = ({
  id,
  getY,
  selection,
  changeSelection,
  width = NaN,
  height = NaN,
  data,
  ...rest
}) => {
  const frameWidth = width - MARGIN.left - MARGIN.right;
  const frameHeight = height - MARGIN.top - MARGIN.bottom;
  const sliderHeight = frameHeight - AXIS_HEIGHT;
  const translate = `translate(${MARGIN.left}, ${MARGIN.top})`;

  const xScale = scaleLinear()
    .domain([
      min(data, getX),
      max(data, getX),
    ])
    .range([0, frameWidth]);

  const highestPoint = findHighestPoint(data, ['total']);
  const yScale = scaleLinear()
    .domain([0, highestPoint * 1.15])
    .range([sliderHeight, 0]);

  return (
    <Slider
      id={id}
      data={data}
      getY={getY}
      selection={selection}
      changeSelection={changeSelection}
      frameWidth={frameWidth}
      sliderHeight={sliderHeight}
      translate={translate}
      xScale={xScale}
      yScale={yScale}
      {...rest}
    />
  );
};

export default SliderContainer;
