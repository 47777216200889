import { z } from 'zod';

/** Example: "MTE2NjE3NzUwMDU1MzM" */
export const idSchema = z.string().min(1);


const jsonLiteralSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type JsonLiteral = z.infer<typeof jsonLiteralSchema>;
type Json = JsonLiteral | { [key: string]: Json } | Json[];

/** validates any JSON value */
export const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([jsonLiteralSchema, z.array(jsonSchema), z.record(jsonSchema)]),
);
