import * as entities from '../../../../common/constants/entities';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';

// todo: update API (event filters, reports) to remove that
export const prepareFiltersForAPI = (input) => {
  const filters = {};

  if (input[entities.MACHINES] && input[entities.MACHINES].length > 0) {
    filters.machines = input[entities.MACHINES].map((item) => item.id);
  }

  if (input[entities.LOCATIONS] && input[entities.LOCATIONS].length > 0) {
    filters.locations = input[entities.LOCATIONS].map((item) => item.id);
  }

  if (input[entities.ORGANIZATIONS] && input[entities.ORGANIZATIONS].length > 0) {
    filters.organizations = input[entities.ORGANIZATIONS].map((item) => item.id);
  }

  if (input[entities.TAGS] && input[entities.TAGS].length > 0) {
    filters.tags = input[entities.TAGS].map((item) => item.id);
  }

  if (input[entities.CATEGORIES] && input[entities.CATEGORIES].length > 0) {
    filters.category = input[entities.CATEGORIES].map((item) => item.id);
  }

  if (input[DATE_FROM]) {
    filters[DATE_FROM] = input[DATE_FROM]
      .toDate()
      .toISOString();
  }
  if (input[DATE_TO]) {
    filters[DATE_TO] = input[DATE_TO]
      .toDate()
      .toISOString();
  }

  if (input.query) { filters.query = input.query; }

  return filters;
};

export const getNavigationItemIndex = ({
  directionForward = true,
  list = [],
  currentItemIndex = 0,
}) => {
  const length = list.length;
  let nextIndex = currentItemIndex;

  if (directionForward) {
    nextIndex++;
    if (nextIndex > length - 1) nextIndex = 0;
  } else {
    nextIndex--;
    if (nextIndex < 0) nextIndex = length - 1;
  }

  return nextIndex;
};
