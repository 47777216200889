import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { hexToRGB } from '../../../../../../../../common/helpers/colors.helper';
import { Todo, TodoFunction } from '../../../../../../../../common/types/common';

const getTitle = (item) => {
  let title = item.dataPoint.label;

  if (item.dataPoint.path) {
    const fullPath = [title];
    if (item.dataPoint.path && item.dataPoint.path.deviceName) {
      fullPath.unshift(item.dataPoint.path.deviceName);
    }
    if (item.dataPoint.path && item.dataPoint.path.datatronName) {
      fullPath.unshift(item.dataPoint.path.datatronName);
    }
    title = fullPath.join(' > ');
  }
  if (item.dataPoint.unit) {
    title = `${title} (${item.dataPoint.unit})`;
  }

  return title;
};

interface Props {
  item: Todo;
  onClick: TodoFunction;
  toggle: TodoFunction;
  remove: TodoFunction;
  inactive: boolean;
}

export const LegendItem: React.FC<Props> = ({
  item, onClick, toggle, remove, inactive,
}) => {
  const handleClick = () => onClick(item);
  const handleToggle = () => toggle(item);
  const handleRemove = () => remove(item);

  const title = getTitle(item);

  const previewIconStyle = {
    borderColor: hexToRGB(item.color),
    borderWidth: `${item.lineWidth / 2}px`,
    background: hexToRGB(item.color, item.fillOpacity),
  };

  const archived = !!item.dataPoint.archived;
  const liClassName = cn({ active: !inactive, archived });

  return (
    <li className={liClassName}>
      <button className="link-text report-watcher" onClick={handleToggle}>
        <span className="rectangle" style={previewIconStyle} />
        <i className="icon-eye" />
      </button>
      <button className="link-text report-editor" onClick={archived ? handleRemove : handleClick}>
        <i className={`icon-${archived ? 'remove' : 'edit'}`} />
        <span>{ title }</span>
      </button>
    </li>
  );
};

LegendItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  inactive: PropTypes.bool.isRequired,
};

export default LegendItem;
