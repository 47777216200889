import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';

import * as recurrentEventTypes from '../../../../../../../../../common/enums/recurrent.event.types';
import calendarMessages from '../../../../../../../messages/calendars.messages';
import RecurrenceOption from './RecurrenceOption';
import { DATE_TIME_RFC2445_FORMAT } from '../../../../../../../../../common/constants';
import { TodoFunction } from '../../../../../../../../../common/types/common';
import { useClickOutside } from '../../../../../../../core/common/hooks/useClickOutside';

interface Props {
  repeatType?: string
  repeatEnd?: string
  close: TodoFunction
  changeRepeatType: TodoFunction
}

export const EventRecurrenceTool: React.FC<Props> = ({
  repeatType,
  repeatEnd,
  close,
  changeRepeatType,
}) => {

  const clickRef = useClickOutside(close);

  const handleOptionChoose = (option = null) => {
    changeRepeatType(option);
    close();
  };

  const { formatDate, formatMessage } = useIntl();

  const untilLabel = repeatEnd
    ? formatDate(moment(repeatEnd, DATE_TIME_RFC2445_FORMAT).toISOString())
    : 'none';

  return (
    <div className="popup--action--popup" ref={clickRef}>
      <div className="action--row column--row">
        <div className="action--label">{formatMessage(calendarMessages.repeatTypeTitle)}</div>
        <div className="action--text">
          <RecurrenceOption
            selected={!repeatType}
            onClick={handleOptionChoose}
            value={''}
          />
          <RecurrenceOption
            selected={repeatType === recurrentEventTypes.DAILY}
            onClick={handleOptionChoose}
            value={recurrentEventTypes.DAILY}
          />
          <RecurrenceOption
            selected={repeatType === recurrentEventTypes.WEEKLY}
            onClick={handleOptionChoose}
            value={recurrentEventTypes.WEEKLY}
          />
          <RecurrenceOption
            selected={repeatType === recurrentEventTypes.MONTHLY}
            onClick={handleOptionChoose}
            value={recurrentEventTypes.MONTHLY}
          />
          <RecurrenceOption
            selected={repeatType === recurrentEventTypes.YEARLY}
            onClick={handleOptionChoose}
            value={recurrentEventTypes.YEARLY}
          />
        </div>
      </div>
      {
        repeatEnd && (
          <div className="action--row">
            <div className="action--label">{formatMessage(calendarMessages.repeatEndTitle)}</div>
            <div className="action--text">{untilLabel}</div>
          </div>
        )
      }
    </div>
  );
};

export default EventRecurrenceTool;
