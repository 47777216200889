import { createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { scroller } from 'react-scroll';
import { isEmpty } from 'lodash';

import * as mfc from '../constants/machine.form.constants';
import { getFormFields } from '../selectors/machine.page.form.selector';
import { getInventoryLink } from '../../components/index/routes';
import * as api from '../../core/api';
import { findFirstErrorField } from '../../core/common/forms';
import { NO_FILE } from '../constants/fileUpload.component.constants';
import { validateMachine } from '../../core/validation/machines.form.validation';
import { fetchInventory } from './inventory.fetch.module';

export const createMachine = createAction(
  'create machine',
  (payload) => ({ payload }),
);

export const createMachineSuccess = createAction(
  'create machine - success',
  (payload) => ({ payload }),
);

export const createMachineFail = createAction(
  'create machine - fail',
  (error) => ({ payload: error }),
);

export const reducer = {
  [createMachine.type]: (state) => ({
    ...state,
    newMachine: {
      ...state.newMachine,
      creating: true,
    },
  }),
  [createMachineSuccess.type]: (state) => ({
    ...state,
    newMachine: {
      ...state.newMachine,
      creating: false,
    },
  }),
  [createMachineFail.type]: (state, error) => ({
    ...state,
    newMachine: {
      ...state.newMachine,
      creating: false,
      error,
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    mfc.MACHINE_FORM_NAME,
    ...mfc.MACHINE_PROPS,
  ));
}

export function* getFields() {
  const state = yield select();

  const fields = yield call(getFormFields, state);

  if (fields[mfc.ORGANIZATION_FIELD]) {
    fields[mfc.ORGANIZATION_FIELD] = fields[mfc.ORGANIZATION_FIELD].value;
  }

  if (!fields[mfc.SCHEDULE_CALENDAR_FIELD]) {
    delete fields[mfc.SCHEDULE_CALENDAR_FIELD];
  } else {
    fields[mfc.SCHEDULE_CALENDAR_FIELD] = fields[mfc.SCHEDULE_CALENDAR_FIELD].value;
  }

  if (!fields[mfc.SHIFT_CALENDAR_FIELD]) {
    delete fields[mfc.SHIFT_CALENDAR_FIELD];
  } else {
    fields[mfc.SHIFT_CALENDAR_FIELD] = fields[mfc.SHIFT_CALENDAR_FIELD].value;
  }

  return fields;
}

export function* handleErrors(errors) {
  yield put(createMachineFail(errors));
  yield put(stopSubmit(mfc.MACHINE_FORM_NAME, errors));
  const firstErrorField = yield call(findFirstErrorField, mfc.MACHINE_PROPS, errors);
  if (firstErrorField) {
    yield scroller.scrollTo(
      firstErrorField,
      { offset: mfc.MACHINE_FORM_ERROR_FIELD_OFFSET },
    );
  }
}

export function* createMachineSaga() {
  yield call(touchAllFieldsSaga);
  const fields = yield call(getFields);
  yield put(startSubmit(mfc.MACHINE_FORM_NAME));

  if (fields[mfc.PHOTO_FIELD] === NO_FILE) {
    delete fields[mfc.PHOTO_FIELD];
  }

  const preRequestValidationErrors = yield call(validateMachine, fields);
  if (!isEmpty(preRequestValidationErrors)) {
    yield handleErrors(preRequestValidationErrors);
    return;
  }

  const { response, error } = yield call(api.machines.createMachine, fields);

  if (response) {
    yield put(createMachineSuccess(response));
    yield put(fetchInventory());
    yield put(stopSubmit(mfc.MACHINE_FORM_NAME));
    yield put(push(getInventoryLink()));
  } else {
    yield handleErrors(error);
  }
}

export function* watchCreateMachine() {
  yield takeEvery(createMachine, createMachineSaga);
}
