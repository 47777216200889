import { createAction } from '@reduxjs/toolkit';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { getDatatron, getDeviceById } from '../selectors/datatron.selector';
import * as api from '../../core/api';
import { compareById, replaceObjectInList, removeObjectFromList } from '../../../../common/helpers/object';
import { closeModal } from './modals.module';
import { DP_ARCHIVE_DEVICE } from '../constants/modals.constants';
import notificationMessage from '../../messages/notification.message';
import { sendNotification } from './notifications.module';

export const archiveDevice = createAction(
  'archive datatron device',
  (deviceId) => ({ payload: deviceId }),
);

export const archiveDeviceSuccess = createAction(
  'archive datatron device - success',
  (deviceId) => ({ payload: deviceId }),
);

export const archiveDeviceFail = createAction(
  'archive datatron device - fail',
  (deviceId, error) => ({ payload: { deviceId, error } }),
);

export const reducer = {
  [archiveDevice.type]: (state, deviceId) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const newDevice = {
      ...device,
      _archive: {
        ...device._archive,
        loading: true,
        loaded: false,
        error: null,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById,
          ),
        },
      },
    };
  },
  [archiveDeviceSuccess.type]: (state, deviceId) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: removeObjectFromList(
            state.datatron.devices.list,
            device,
            compareById,
          ),
        },
      },
    };
  },
  [archiveDeviceFail.type]: (state, { deviceId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const newDevice = {
      ...device,
      _archive: {
        ...device._archive,
        loading: false,
        loaded: false,
        error,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById,
          ),
        },
      },
    };
  },
};

export function* archiveDeviceSaga({ payload: deviceId }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const {
    response,
    error,
  } = yield call(api.datatrons.archiveDevice, datatron.id, deviceId);

  if (response) {
    yield put(archiveDeviceSuccess(deviceId));
    yield put(closeModal(DP_ARCHIVE_DEVICE));
    yield put(sendNotification(notificationMessage.device_archived_title));
  } else {
    yield put(archiveDeviceFail(deviceId, error));
  }
}

export function* watchArchiveDevice() {
  yield takeEvery(archiveDevice, archiveDeviceSaga);
}
