import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'redux-form';

import noDoubleClick from '../../../_tools/stopPropagation';
import * as commentForm from '../../../../core/forms/eventComment.form.js';
import messages from './EventCommentInput.message';

export class EventCommentInput extends React.Component {
  onMouseDownInput = () => {
    this.input.getRenderedComponent().blur();
    this.input.getRenderedComponent().focus();
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.submitComment(this.props.eventId);
  };

  render() {
    const {
      intl, isCommentEmptyOrWhitespace, invalid, commentError,
    } = this.props;

    const placeholder = intl.formatMessage({ id: messages.type_comment_here.id });

    return (
      <div className="feedback--item yours">
        <div className="feedback-person">
          <span className="icon icon-comment" />
          <FormattedMessage
            id="dashboard.event.comments.input.you"
            defaultMessage="You"
          />
        </div>
        <form
          className="feedback-comment--form"
          onClick={noDoubleClick}
          onSubmit={this.handleSubmit}
        >
          <div className={`input-group${invalid ? ' error' : ''} ${isCommentEmptyOrWhitespace ? ' disabled-field' : ''}`}>
            <Field
              ref={(input) => { this.input = input; }}
              className="input-group-field no-cross"
              name={commentForm.commentField}
              component="input"
              type="text"
              placeholder={placeholder}
              onMouseDown={this.onMouseDownInput}
              withRef
            />
            <div className="input-group-button">
              <button
                className="button__submit"
                type="submit"
                disabled={isCommentEmptyOrWhitespace || invalid}
              >
                <FormattedMessage
                  id="dashboard.event.comments.input.send"
                  defaultMessage="Send"
                />
              </button>
            </div>
          </div>
          {
            invalid
              && (
              <div className="error-line">
                <FormattedMessage id={commentError} />
              </div>
              )
          }
        </form>
      </div>
    );
  }
}

EventCommentInput.propTypes = {
  eventId: PropTypes.string.isRequired,

  submitComment: PropTypes.func.isRequired,
  isCommentEmptyOrWhitespace: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  commentError: PropTypes.string.isRequired,
};

export default injectIntl(EventCommentInput);
