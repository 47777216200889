import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { useClickOutside } from '../../core/common/hooks/useClickOutside';

interface Props {
  newMachineLink: string,
  newDatatronLink: string,
  canCreateMachines: boolean,
  canCreateDatatrons: boolean,
}

export const InventoryAddButton: React.FC<Props> = ({
  newMachineLink,
  newDatatronLink,
  canCreateMachines,
  canCreateDatatrons,
}) => {
  const [isOpen, isOpenSet] = useState(false);

  const open = () => {
    isOpenSet(true);
  };

  const close = () => {
    isOpenSet(false);
  };

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  const clickRef = useClickOutside(close);

  if (!(canCreateMachines || canCreateDatatrons)) return null;

  return (
    <div className={classnames('button-group__with-dropdown', isOpen && 'opened')} ref={clickRef}>
      <button className="button green button__dropdown" onClick={toggle}>
        <span className="fa fa-plus"/>
        <FormattedMessage
          id="common.add_new"
          defaultMessage="Add New"
        />
      </button>
      {isOpen &&
      <div className="button-group--dropdown">
        <ul>
          {
            canCreateMachines && (
              <li>
                <Link to={newMachineLink}>
                  <FormattedMessage
                    id="common.machine"
                    defaultMessage="Machine"
                  />
                </Link>
              </li>
            )
          }
          {
            canCreateDatatrons && (
              <li>
                <Link to={newDatatronLink}>
                  <FormattedMessage
                    id="common.datatron_capitalize"
                    defaultMessage="DATATRON"
                  />
                </Link>
              </li>
            )
          }
        </ul>
      </div>}
    </div>
  );
};

export default (InventoryAddButton);
