export const getMetricFromState = (state, dataPointId) => {
  if (!dataPointId) return null;

  const metricData = state.datatron.dataPointMetric;
  if (metricData.dataPointId !== dataPointId) return null;

  return metricData;
};

export const getDataPointMetric = (state, dataPointId = null) => {
  const metric = getMetricFromState(state, dataPointId);
  if (!metric) return [];

  return metric.list;
};

export const isLoadMoreButtonAvailable = (state, dataPointId = null) => {
  const metric = getMetricFromState(state, dataPointId);
  if (!metric) return false;

  return metric.moreAvailable;
};

export const isLoading = (state, dataPointId = null) => {
  const metric = getMetricFromState(state, dataPointId);
  if (!metric) return false;

  return metric.loading;
};

export const isLoaded = (state, dataPointId = null) => {
  const metric = getMetricFromState(state, dataPointId);
  if (!metric) return false;

  return metric.loaded;
};

export const isUtcModeActive = (state, dataPointId = null) => {
  const metric = getMetricFromState(state, dataPointId);
  if (!metric) return false;

  return metric.utcMode;
};
