import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../_tools/modal/Modal';
import RemoveBlockLabel from './RemoveBlockLabel';
import Title from './Title';
import Footer from './Footer';

export default class ScheduleRemoveModal extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    deleteCalendar: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    calendar: PropTypes.object.isRequired,
    canRemoveCalendar: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleRemove = this.handleRemove.bind(this);
  }

  handleRemove() {
    const { calendar, deleteCalendar } = this.props;
    deleteCalendar(calendar.id);
  }

  render() {
    const {
      isOpen,
      close,
      calendar,
      canRemoveCalendar,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClose={close}
        contentLabel=""
        modalClass="modal--sm"
      >
        <div className="modal--header" onClick={close}>
          <span className="modal--close">&times;</span>
        </div>

        <div className="modal--content">
          <Title
            canRemoveCalendar={canRemoveCalendar}
            calendarName={calendar.name}
          />

          { !canRemoveCalendar && <RemoveBlockLabel machines={calendar.machines} /> }

          <div className="line" />

          <Footer
            canRemoveCalendar={canRemoveCalendar}
            onCancel={close}
            onRemove={this.handleRemove}
          />
        </div>
      </Modal>
    );
  }
}
