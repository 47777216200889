import { connect } from 'react-redux';

import { openModal } from '../../redux/modules/modals.module';
import { REPORT_ADD } from '../../redux/constants/modals.constants';
import ReportAddButton from './ReportAddButton';

export const mapDispatchToProps = (dispatch) => ({
  openModal: () => dispatch(openModal(REPORT_ADD)),
});

export default connect(null, mapDispatchToProps)(ReportAddButton);
