import React from 'react';

import LegendItem from './legendItem/LegendItem';
import { Todo, TodoFunction } from '../../../../../../../common/types/common';

interface Props {
  onLegendClick: TodoFunction;
  toggle: TodoFunction;
  remove: TodoFunction;
  leftDataPoints: Todo[];
  rightDataPoints: Todo[];
  inactiveEntriesIds: Todo[];
}

export const Legend: React.FC<Props> = ({
  leftDataPoints,
  rightDataPoints,
  onLegendClick,
  toggle,
  remove,
  inactiveEntriesIds,
}) => (
  <div className="report-legend">
    <div className="report-legend-col report-legend-left">
      <ul>
        { leftDataPoints.map((item) => (
          <LegendItem
            key={item.dataPoint.id}
            item={item}
            onClick={onLegendClick}
            toggle={toggle}
            remove={remove}
            inactive={inactiveEntriesIds.indexOf(item.dataPoint.uuid) >= 0}
          />
        )) }
      </ul>
    </div>
    <div className="report-legend-col report-legend-right">
      <ul>
        { rightDataPoints.map((item) => (
          <LegendItem
            key={item.dataPoint.id}
            item={item}
            onClick={onLegendClick}
            toggle={toggle}
            remove={remove}
            inactive={inactiveEntriesIds.indexOf(item.dataPoint.uuid) >= 0}
          />
        )) }
      </ul>
    </div>
  </div>
);

export default Legend;
