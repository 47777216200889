import React from 'react';
import PropTypes from 'prop-types';

import FooterListItem from './FooterListItem';

export default function CalendarFooterList({ machines }) {
  return (
    <div className="calendar--bottom--list">
      <ul>
        { machines.map((machine) => <FooterListItem machine={machine} key={machine.id} />) }
      </ul>
    </div>
  );
}

CalendarFooterList.propTypes = {
  machines: PropTypes.array.isRequired,
};
