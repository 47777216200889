import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

function LoadingInProgress({ isSmall }) {
  const style = classnames(
    'nodata',
    { 'nodata-sm': isSmall },
  );

  return (
    <div className={style}>
      <FormattedMessage
        id="reports.graph.loading"
        defaultMessage="Loading data"
        tagName="div"
      />
    </div>
  );
}

LoadingInProgress.propTypes = {
  isSmall: PropTypes.bool.isRequired,
};

LoadingInProgress.defaultProps = {
  isSmall: false,
};

export default LoadingInProgress;
