import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import TabsBar from '../../common/tabsBar/TabsBar';
import messages from '../../../messages/experimentsPage.messages';

export const PageTabsBar = ({
  isExperimentsTabOpen,
  isMeasurementsTabOpen,
  canAddExperiment,
  openExperimentsTab,
  openMeasurementsTab,
  openAddExperimentModal,
  experimentsCount,
  measurementsCount,
  intl,
}) => {
  const mainButtons = [
    {
      title: intl.formatMessage(messages.experiments),
      count: experimentsCount,
      hasCount: true,
      isActive: isExperimentsTabOpen,
      onClick: openExperimentsTab,
      showEditButton: false,
      showRemoveButton: false,
    },
    {
      title: intl.formatMessage(messages.measurements),
      count: measurementsCount,
      hasCount: true,
      isActive: isMeasurementsTabOpen,
      onClick: openMeasurementsTab,
      showEditButton: false,
      showRemoveButton: false,
    },
  ];
  const extraButtons = [];
  if (isExperimentsTabOpen && canAddExperiment) {
    extraButtons.push({
      title: intl.formatMessage(messages.addExperiment),
      onClick: openAddExperimentModal,
    });
  }

  return (
    <TabsBar
      list={mainButtons}
      extraButtons={extraButtons}
      disableScroll
    />
  );
};

PageTabsBar.propTypes = {
  isExperimentsTabOpen: PropTypes.bool.isRequired,
  isMeasurementsTabOpen: PropTypes.bool.isRequired,
  canAddExperiment: PropTypes.bool.isRequired,
  openExperimentsTab: PropTypes.func.isRequired,
  openMeasurementsTab: PropTypes.func.isRequired,
  openAddExperimentModal: PropTypes.func.isRequired,
  experimentsCount: PropTypes.number.isRequired,
  measurementsCount: PropTypes.number.isRequired,

};

export default injectIntl(PageTabsBar);
