import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CheckboxFiltersFormField from '../../../../../common/checkboxFilters/CheckboxFiltersFormField.container';
import * as form from '../../../../../../redux/constants/report.form.constants';
import { DATA_POINTS } from '../../../../../../../../common/constants/entities';
import ModalLine from '../../../../core/ModalLine';
import DoneModalFooter from '../../../../core/footers/DoneModalFooter';
import { Button } from '../../../../../_tools';

export const DataPointsFiltersFrame = ({
  addDataPointButtonDisabled,
  onDataPointsAddClick,
  onCancelClick,
  onDoneClick,
  hasArchivedDataPoints,
}) => (
  <div className="modal-move modal-datapoint">
    <div className="modal-form-in">
      <Field
        component={CheckboxFiltersFormField}
        name={form.DATA_POINTS_FILTERS_FIELD}
        filtersType={DATA_POINTS}
      />
    </div>

    { hasArchivedDataPoints && (
      <div className="archived-info">
        <span className="icon-info" />
        <FormattedMessage
          id="widgets.reportForm.archived_data_points_will_be_removed"
          defaultMessage="Archived Data Points will be removed from this list"
          tagName="div"
        />
      </div>
    ) }

    <div className="search-button-bottom">
      <Button
        className="button green"
        onClick={onDataPointsAddClick}
        type="button"
        disabled={addDataPointButtonDisabled}
      >
        <span className="fa fa-plus" />
        <FormattedMessage
          id="datatron.data.add_data_point"
          defaultMessage="Add Data Point"
        />
      </Button>
    </div>

    <ModalLine />
    <DoneModalFooter onDoneClick={onDoneClick} onCancelClick={onCancelClick} />
  </div>
);

DataPointsFiltersFrame.propTypes = {
  onDataPointsAddClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  addDataPointButtonDisabled: PropTypes.bool.isRequired,
  hasArchivedDataPoints: PropTypes.bool.isRequired,
};

export default DataPointsFiltersFrame;
