import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';

import * as reportsApi from '../../core/api/reports';
import * as form from '../constants/report.duplicate.form.constants';
import { REPORT_DUPLICATE } from '../constants/modals.constants';
import * as selector from '../selectors/report.duplicate.form.selector';
import { closeModal } from './modals.module';
import { initializeReport } from '../../core/common/report';

export const duplicateReport = createAction(
  'duplicate report',
  (id) => id,
);

export const duplicateReportSuccess = createAction(
  'duplicate report - success',
  (report) => report,
);

export const duplicateReportFail = createAction(
  'duplicate report - fail',
  (id, error) => ({ id, error }),
);

export const reducer = {
  [duplicateReport]: (state) => ({
    ...state,
    reports: {
      ...state.reports,
      newReport: {
        created: false,
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),
  [duplicateReportSuccess]: (state, report) => ({
    ...state,
    reports: {
      ...state.reports,
      data: [
        ...state.reports.data,
        {
          saving: false,
          ...report,
        },
      ],
      newReport: {
        created: true,
        loading: false,
        loaded: true,
        error: null,
      },
    },
  }),
  [duplicateReportFail]: (state, error) => ({
    ...state,
    reports: {
      ...state.reports,
      newReport: {
        created: false,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.DUPLICATE_REPORT_FORM_NAME,
    ...form.REPORT_PROPS,
  ));
}

export function* duplicateReportSaga({ payload: reportId }) {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const name = yield call(selector.getFormFields, state);

  yield put(startSubmit(form.DUPLICATE_REPORT_FORM_NAME));
  const { response, error } = yield call(reportsApi.clone, reportId, name);

  if (response) {
    yield put(stopSubmit(form.DUPLICATE_REPORT_FORM_NAME));
    yield put(duplicateReportSuccess(initializeReport(response)));
    yield put(closeModal(REPORT_DUPLICATE));
  } else {
    yield put(stopSubmit(form.DUPLICATE_REPORT_FORM_NAME, error));
    yield put(duplicateReportFail(reportId, error));
  }
}

export function* watchDuplicateReport() {
  yield takeEvery(duplicateReport.getType(), duplicateReportSaga);
}
