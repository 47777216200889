import { createAction } from 'redux-act';
import { push } from 'connected-react-router';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { ceil } from 'lodash';

import * as api from '../../core/api';
import * as form from '../constants/admin.location.form.constants';
import { getFormFields } from '../selectors/admin.location.form.selector';
import { getPagination } from '../selectors/admin.locations.selector';
import { getAdminLocationsLink } from '../../components/index/routes';
import { fetchAdminLocations } from './admin.locations.fetch.module';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const adminCreateLocation = createAction(
  'admin: create location',
  (id) => id,
);

export const adminCreateLocationSuccess = createAction(
  'admin: create location - success',
  (item) => item,
);

export const adminCreateLocationFail = createAction(
  'admin: create location - fail',
  (error) => error,
);

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.ADMIN_LOCATION_FORM_NAME,
    ...form.LOCATION_PROPS,
  ));
}

export function* adminCreateLocationSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const body = yield call(getFormFields, state);
  yield put(startSubmit(form.ADMIN_LOCATION_FORM_NAME));

  const { response, error } = yield call(api.admin.createLocation, body);

  if (response) {
    yield put(adminCreateLocationSuccess(response));
    yield put(stopSubmit(form.ADMIN_LOCATION_FORM_NAME));
    yield put(push(getAdminLocationsLink()));

    const pagination = yield call(getPagination, state);
    let pageNumberToFetch = 1;
    if (pagination && pagination.total_pages) {
      pageNumberToFetch = ceil((pagination.total + 1) / pagination.per_page);
    }
    yield put(fetchAdminLocations(pageNumberToFetch));
    yield put(sendNotification({ ...notificationMessages.location_added, values: { name: response.name } }));
  } else {
    yield put(adminCreateLocationFail(error));
    yield put(stopSubmit(form.ADMIN_LOCATION_FORM_NAME, error));
  }
}

export function* watchAdminCreateLocation() {
  yield takeEvery(adminCreateLocation.getType(), adminCreateLocationSaga);
}
