export const SET_AS_EXHAUSTED = 'SET_AS_EXHAUSTED';
export const ASSIGN = 'ASSIGN';
export const UNASSIGN = 'UNASSIGN';
export const MOUNT = 'MOUNT';
export const UNMOUNT = 'UNMOUNT';

export const FOR_MOUNTED_TOOL = [UNMOUNT, SET_AS_EXHAUSTED];
export const FOR_UNMOUNTED_TOOL = [ASSIGN, MOUNT, SET_AS_EXHAUSTED];
export const FOR_ASSIGNED_TOOL = [UNASSIGN, ASSIGN, MOUNT, SET_AS_EXHAUSTED];

export default [SET_AS_EXHAUSTED, MOUNT, UNMOUNT, ASSIGN, UNASSIGN];
