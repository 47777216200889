import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as batchApi from '../../core/api/batch';
import { getFilters } from '../selectors/batch.selector';

export const fetchBatches = createAction(
  'fetch all batches',
);

export const fetchBatchesSuccess = createAction(
  'fetch all batches - success',
  (reports) => reports,
);

export const fetchBatchesFail = createAction(
  'fetch all batches - failed',
  (error) => error,
);

export const reducer = {
  [fetchBatches]: (state) => ({
    ...state,
    batches: {
      ...state.batches,
      loaded: false,
      loading: true,
      error: null,
      list: [],
    },
  }),

  [fetchBatchesSuccess]: (state, batches) => ({
    ...state,
    batches: {
      ...state.batches,
      loaded: true,
      loading: false,
      list: batches,
      error: null,
    },
  }),

  [fetchBatchesFail]: (state, error) => ({
    ...state,
    batches: {
      ...state.batches,
      loaded: false,
      loading: false,
      list: [],
      error,
    },
  }),
};

export function* fetchBatchesSaga() {
  const state = yield select();
  const filters = yield call(getFilters, state);

  const { response, error } = yield call(batchApi.getAllFiltered, filters);

  if (response) {
    yield put(fetchBatchesSuccess(response));
  } else {
    yield put(fetchBatchesFail(error));
  }
}

export function* watchFetchBatches() {
  yield takeEvery(fetchBatches.getType(), fetchBatchesSaga);
}
