import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const fetchMeasurementsByExperiment = createAction(
  'fetch measurements by experiment',
  (experimentId = null) => experimentId,
);

export const fetchMeasurementsByExperimentSuccess = createAction(
  'fetch measurements by experiment - success',
  (list = []) => list,
);

export const fetchMeasurementsByExperimentFail = createAction(
  'fetch measurements by experiment - fail',
  (error) => error,
);

export const reducer = {
  [fetchMeasurementsByExperiment]: (state) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      measurementsByExperiment: {
        ...state.experimentsPage.measurementsByExperiment,
        list: [],
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchMeasurementsByExperimentSuccess]: (state, list) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      measurementsByExperiment: {
        ...state.experimentsPage.measurementsByExperiment,
        list,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [fetchMeasurementsByExperimentFail]: (state, error) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      measurementsByExperiment: {
        ...state.experimentsPage.measurementsByExperiment,
        list: [],
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchMeasurementsByExperimentSaga({ payload: experimentId }) {
  const { response, error } = yield call(api.experiments.getMeasurementsByExperimentId, experimentId);

  if (response) {
    yield put(fetchMeasurementsByExperimentSuccess(response));
  } else {
    yield put(fetchMeasurementsByExperimentFail(error));
  }
}

export function* watchFetchMeasurementsByExperiment() {
  yield takeEvery(fetchMeasurementsByExperiment.getType(), fetchMeasurementsByExperimentSaga);
}
