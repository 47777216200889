import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getLocationLink } from '../../index/routes';

export const LocationListPath = ({ location: { id, path, name } }) => {
  const locations = [
    ...path.map((parent) => (
      <li key={parent}>
        {parent}
        <span className="fa fa-chevron-right" />
      </li>
    )),
    <li key={name}>
      <Link to={getLocationLink(id)}>{name}</Link>
    </li>,
  ];

  return (
    <ul className="locations--list">
      {locations}
    </ul>
  );
};

LocationListPath.propTypes = {
  location: PropTypes.object.isRequired,
};

export default LocationListPath;
