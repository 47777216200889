import React from 'react';
import classNames from 'classnames';

import ImageContainer from '../../_tools/image/Image.container';
import CardPhotoDetails from './details/CardPhotoDetails';
import { useImageFetch } from '../../../core/common/hooks/useImageFetch';

interface Props {
  hasAlert?: boolean,
  imageUrl?: string,
  detailsLink?: string,
  canOpenDetails?: boolean,
}

const CardPhoto: React.FC<Props> = ({
  hasAlert = false,
  imageUrl = '',
  canOpenDetails = false,
  detailsLink = '',
}) => {
  const itemClassName = classNames(
    'card--position',
    'card--position2',
    { alert: hasAlert },
  );

  const { imagePath } = useImageFetch(imageUrl);

  return (
    <div className={itemClassName}>
      <div className="image--wrap">
        <ImageContainer
          url={imagePath || ''}
          className="card--image"
          contain
        />
        {canOpenDetails && (
          <CardPhotoDetails link={detailsLink}/>
        )}
      </div>
    </div>
  );
};

export default CardPhoto;
