import { defineMessages } from 'react-intl';

import * as calendarTypes from '../../../../../common/enums/calendarTypes';

export default defineMessages({
  [calendarTypes.SHIFT_CALENDAR]: {
    id: 'calendars.filter.shifts',
    defaultMessage: 'Search shift...',
  },
  [calendarTypes.SCHEDULE_CALENDAR]: {
    id: 'calendars.filter.schedule',
    defaultMessage: 'Search schedule...',
  },
  notFound: {
    id: 'calendars.filter.notFound',
    defaultMessage: 'No calendars was found',
  },
});
