import { connect } from 'react-redux';

import BasicUserForm from './BasicUserForm';
import * as selector from '../../../../../../../../redux/selectors/admin.users.selector';

export const mapStateToProps = (state) => {
  const {
    location,
  } = selector.getUser(state);

  return {
    location,
  };
};

export default connect(mapStateToProps)(BasicUserForm);
