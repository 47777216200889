import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default function AnomalyHeader({ backLink }) {
  return (
    <div className="subheader">
      <div className="subheader-in">
        <div className="subheader--left">
          <ul className="menu tab-menu">
            <li className="back-link">
              <Link to={backLink}>
                <span className="icon-arrow-left" />
                <FormattedMessage
                  id="common.back_to_dashboard"
                  defaultMessage="Back to Dashboard"
                />
              </Link>
            </li>
          </ul>
        </div>
        <div className="subheader--right" />
      </div>
    </div>
  );
}

AnomalyHeader.propTypes = {
  backLink: PropTypes.string.isRequired,
};
