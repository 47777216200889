import React from 'react';
import { FormattedMessage } from 'react-intl';

export const EmptyTablePlaceholder = () => (
  <div>
    <div className="data-empty-icon">
      <img src="/img/inbox.svg" alt="" />
    </div>
    <p>
      <strong>
        <FormattedMessage
          id="datatron.data.table_empty.header"
          defaultMessage="No Data Points"
        />
      </strong>
    </p>
  </div>
);

export default EmptyTablePlaceholder;
