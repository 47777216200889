import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { getClustersGroupByMachineId } from '../../core/api/cluster';
import { findAndUpdate } from '../../../../common/helpers/common.helper';
import { DEFAULT_TAB } from '../constants/machine.cluster.tabs.constants';
import { getClusteredEventsFilters } from '../selectors/machine.selector';

export const fetchClustersGroupByMachine = createAction(
  'fetch clusters group by machine',
  (machineId, category, name) => ({ machineId, category, name }),
);

export const fetchClustersGroupByMachineSuccess = createAction(
  'fetch clusters group by machine successfully',
  (clustersGroups) => clustersGroups,
);

export const fetchClustersGroupByMachineFail = createAction(
  'fetch clusters group by machine fail',
  (error) => error,
);

export const reducer = {
  [fetchClustersGroupByMachine]: (state, { category, name }) => ({
    ...state,
    machine: {
      ...state.machine,
      clustersSummary: {
        ...state.machine.clustersSummary,
        data: findAndUpdate(
          state.machine.clustersSummary.data,
          {
            category,
            name,
          },
          {
            clusters: {
              data: [],
              loaded: false,
              loading: true,
              error: null,
            },
          },
          true,
        ),
      },
    },
  }),

  [fetchClustersGroupByMachineSuccess]: (state, { clusters, category, name }) => ({
    ...state,
    machine: {
      ...state.machine,
      clustersSummary: {
        ...state.machine.clustersSummary,
        data: findAndUpdate(
          state.machine.clustersSummary.data,
          {
            category,
            name,
          },
          {
            clusters: {
              data: clusters.map((cluster) => ({
                ...cluster,
                displayMode: DEFAULT_TAB,
                details: {
                  loaded: false,
                  loading: false,
                  error: null,
                },
              })),
              loaded: true,
              loading: false,
              error: null,
            },
          },
          true,
        ),
      },
    },
  }),

  [fetchClustersGroupByMachineFail]: (state, { error, category, name }) => ({
    ...state,
    machine: {
      ...state.machine,
      clustersSummary: {
        ...state.machine.clustersSummary,
        data: findAndUpdate(
          state.machine.clustersSummary.data,
          {
            category,
            name,
          },
          {
            clusters: {
              loaded: false,
              loading: false,
              data: [],
              error,
            },
          },
          true,
        ),
      },
    },
  }),
};

export function* fetchClustersGroupByMachineSaga({ payload: { machineId, category, name } }) {
  const state = yield select();
  const filters = yield call(getClusteredEventsFilters, state);
  const { response, error } = yield call(
    getClustersGroupByMachineId,
    {
      ...filters,
      category,
      name,
      machineId,
    },
  );

  if (response) {
    yield put(fetchClustersGroupByMachineSuccess({ clusters: response, category, name }));
  } else {
    yield put(fetchClustersGroupByMachineFail({ error, category, name }));
  }
}

export function* watchFetchClustersGroupByMachine() {
  yield takeEvery(fetchClustersGroupByMachine.getType(), fetchClustersGroupByMachineSaga);
}
