import { cloneDeep, forEach, isArray } from 'lodash';
import { Todo } from '../../../../common/types/common';

interface Field {
  _key: string;
  _parent?: string[];
}

export const applyFormValuesOntoConfig = <C extends Todo, F extends Field>(
  config: C,
  formValues: Todo,
  fields: F[],
) => {
  const newConfig = cloneDeep(config);
  const mapConfigFields = fields.reduce(
    (result, item) => ({ ...result, [item._key]: item }),
    {} as Record<string, F>,
  );

  forEach(formValues, (value, key) => {
    const field = mapConfigFields[key];
    const parent = field._parent || [];

    let i = 0;
    let toUpdate = newConfig;

    while (i < parent.length) {
      const currentParent = parent[i++];

      if (!toUpdate[currentParent]) toUpdate[currentParent] = {};
      toUpdate = toUpdate[currentParent];
    }

    if (value !== undefined && value !== null) toUpdate[key] = value;
  });

  return newConfig;
};

export const removeUnnecessaryKeys = (
  config: Todo = {},
  allowedKeys: Todo[] = [],
) => {
  const newConfig = cloneDeep(config);

  forEach(config, (value, key) => {
    if (typeof value === 'object' && !isArray(value)) {
      newConfig[key] = removeUnnecessaryKeys(value, allowedKeys);
    } else if (!(allowedKeys.indexOf(key) >= 0)) delete newConfig[key];
  });

  return newConfig;
};

export const getInitialValue = (configField) => {
  if (!(configField.value === undefined || configField.value === null))
    return configField.value;
  if (configField.field.default !== undefined) return configField.field.default;
  return null;
};

const numberParser = (value) => {
  if (value || value === 0) return Number.parseFloat(value);
  return null;
};

const integerParser = (value) => {
  if (value || value === 0) return Number.parseInt(value, 10);
  return null;
};

const defaultParser = (value) => value;

export const getParser = (type) => {
  if (type === 'number') return numberParser;
  if (type === 'integer') return integerParser;
  return defaultParser;
};
