import { createAction } from 'redux-act';
import {
  put, takeLatest, delay,
} from 'redux-saga/effects';

import { search, clear } from './search.module';

export const changeQuery = createAction(
  'change filter query for events',
  (filterType, query = '') => ({ filterType, query }),
);

export const reducer = {
  [changeQuery]: (state, { filterType, query }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      filterQuery: {
        ...state.dashboard.filterQuery,
        [filterType]: query,
      },
    },
  }),
};

export function* changeQuerySaga({ payload: { filterType, query } }) {
  yield put(clear());
  yield delay(500); // debouncing
  yield put(search(query, true, [filterType]));
}

export function* watchChangeEventFilterQuery() {
  yield takeLatest(changeQuery.getType(), changeQuerySaga);
}
