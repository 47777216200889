import { connect } from 'react-redux';

import Maintenance from './Maintenance';
import * as selector from '../../redux/selectors/maintenance.selector';
import { isModalOpen } from '../../redux/selectors/modals.selector';
import { MAINTENANCE_ASSIGN } from '../../redux/constants/modals.constants';

export const mapStateToProps = (state) => ({
  isAssignModalOpen: isModalOpen(state, MAINTENANCE_ASSIGN),
  isLoading: selector.isLoading(state),
});

export default connect(mapStateToProps)(Maintenance);
