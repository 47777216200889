import React from 'react';
import PropTypes from 'prop-types';

export default function Popup({
  title, close, children, className = '',
}) {
  return (
    <div className="overlay open">
      <a className="cancel" onClick={close} />
      <div className={`popup ${className}`}>
        <div className="popup--title">
          <a className="close" onClick={close}><span className="icon-cross" /></a>
          {title}
        </div>
        <div className="popup-content">
          {children}
        </div>
      </div>
    </div>
  );
}

Popup.propTypes = {
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
