import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTab } from '../../../redux/modules/machine.tabs.module';
import { getSelectedTab } from '../../../redux/selectors/machine.selector';
import { TOOLS_PERMISSIONS } from '../../../../../common/constants/permissions';
import { usePermissionInstance } from '../../common/permissions/usePermissionInstance';
import TabSelector, { TabItem } from '../../common/detailsTabSelector/TabSelector';
import ModalsRouterContainer from './modals/ModalsRouter.container';
import messages from '../../../messages/machine.tabs.message';
import {
  ASSISTANCE_TAB,
  DATA_TAB,
  DOCUMENTS_TAB,
  MACHINE_DETAILS_TAB,
  MAINTENANCE_TAB,
  SCHEDULE_TAB,
  SENSORS_TAB,
  SUMMARY_TAB,
  TOOLS_TAB,
  MachineTabType,
} from '../../../redux/constants/machine.tabs.constants';
import Loader from '../../_tools/loader/Loader';

const tabComponents = {
  [TOOLS_TAB]: React.lazy(() => import('./toolsTab/ToolsTab')),
  [DATA_TAB]: React.lazy(() => import('./dataTab/DataTab')),
  [MACHINE_DETAILS_TAB]: React.lazy(() => import('./machineDetails/MachineDetails.container')),
  [ASSISTANCE_TAB]: React.lazy(
    () => import('../../common/assistanceTab/MachineAssistance.container'),
  ),
  [DOCUMENTS_TAB]: React.lazy(() => import('./documents/Documents.container')),
  [SENSORS_TAB]: React.lazy(() => import('./sensors/Sensors.container')),
  [MAINTENANCE_TAB]: React.lazy(() => import('./maintenance/Maintenance.container')),
  [SCHEDULE_TAB]: React.lazy(() => import('./calendars/CalendarsRouter.container')),
  [SUMMARY_TAB]: React.lazy(() => import('./summary/Summary')),
};

const Details: React.FC = () => {
  const permissions = usePermissionInstance(TOOLS_PERMISSIONS);
  const isToolsTabVisible = permissions.canViewTools();
  const selectedTab: MachineTabType = useSelector(getSelectedTab);
  const dispatch = useDispatch();

  const tabList = useMemo<TabItem[]>(() => {
    const tabs = [
      { id: SUMMARY_TAB, label: messages[SUMMARY_TAB] },
      { id: DATA_TAB, label: messages[DATA_TAB] },
      { id: MACHINE_DETAILS_TAB, label: messages[MACHINE_DETAILS_TAB] },
      { id: MAINTENANCE_TAB, label: messages[MAINTENANCE_TAB] },
      { id: ASSISTANCE_TAB, label: messages[ASSISTANCE_TAB] },
      { id: SCHEDULE_TAB, label: messages[SCHEDULE_TAB] },
    ];

    if (isToolsTabVisible) {
      tabs.splice(1, 0, { id: TOOLS_TAB, label: messages[TOOLS_TAB] });
    }

    return tabs;
  }, [isToolsTabVisible]);

  const handleSelectTab = useCallback((id: MachineTabType) => dispatch(selectTab(id)), [dispatch]);

  const TabComponent =
    tabComponents[selectedTab] ||
    (() => {
      console.error('Unknown tab selected');
      return null;
    });

  return (
    <div className='col--in'>
      <TabSelector tabList={tabList} selectTab={handleSelectTab} selectedTab={selectedTab} />
      <React.Suspense fallback={<Loader show={true} />}>
        <TabComponent />
      </React.Suspense>
      <ModalsRouterContainer />
    </div>
  );
};

export default Details;
