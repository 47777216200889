import { createAction } from 'redux-act';
import {
  put, delay, takeLatest,
} from 'redux-saga/effects';

import { fetchTools } from './tools.fetch.module';

export const DEBOUNCE_TIME_IN_MILLISECONDS = 500;

export const addStateFilter = createAction(
  'add state filter for machine tools',
  (filter) => filter,
);

export const removeStateFilter = createAction(
  'remove state filter for machine tools',
  (filter) => filter,
);

export const filterByName = createAction(
  'filter tools by query',
  (query = '') => query,
);

export const reducer = {
  [addStateFilter]: (state, filter) => ({
    ...state,
    tools: {
      ...state.tools,
      all: {
        ...state.tools.all,
        filters: {
          ...state.tools.all.filters,
          state: [
            ...state.tools.all.filters.state,
            filter,
          ],
        },
      },
    },
  }),
  [removeStateFilter]: (state, filter) => {
    const selectedStates = state.tools.all.filters.state;
    const index = selectedStates.indexOf(filter);

    if (index === -1) return state;

    return {
      ...state,
      tools: {
        ...state.tools,
        all: {
          ...state.tools.all,
          filters: {
            ...state.tools.all.filters,
            state: [
              ...selectedStates.slice(0, index),
              ...selectedStates.slice(index + 1),
            ],
          },
        },
      },
    };
  },
  [filterByName]: (state, query) => ({
    ...state,
    tools: {
      ...state.tools,
      all: {
        ...state.tools.all,
        filters: {
          ...state.tools.all.filters,
          query,
        },
      },
    },
  }),
};

export function* fetchToolsSaga() {
  yield delay(DEBOUNCE_TIME_IN_MILLISECONDS);
  yield put(fetchTools());
}

export function* watchToolsFiltersChange() {
  yield takeLatest([
    addStateFilter.getType(),
    removeStateFilter.getType(),
    filterByName.getType(),
  ], fetchToolsSaga);
}
