import { formValueSelector } from 'redux-form';

import { AI_APP_FORM_NAME, AI_APP_PROPS } from '../constants/aiApp.form.constants';

import {
  AI_APP_CYCLE_DETECTION_FORM,
  AI_APP_CYCLE_DETECTION_PROPS,
} from '../constants/aiApp.cycleDetection.form.constants';

import {
  AI_APP_ANOMALY_FORM_NAME,
  AI_APP_ANOMALY_PROPS,
} from '../constants/aiApp.anomalyModule.form.constants';

const createAiAppFormSelector = () => formValueSelector(AI_APP_FORM_NAME);
export const getFormFields = (state) => createAiAppFormSelector()(state, ...AI_APP_PROPS);

const createAiAppCycleDetectionFormSelector = () => formValueSelector(AI_APP_CYCLE_DETECTION_FORM);

export const getAIAppCycleDetectionFormFields = (state) => {
  const formFields = createAiAppCycleDetectionFormSelector()(
    state,
    ...AI_APP_CYCLE_DETECTION_PROPS,
  );

  if (formFields.typeUuid && Array.isArray(formFields.typeUuid) && formFields.typeUuid.length > 0) {
    // If typeUuid is a non-empty array, extract the value property of the first item
    return {
      ...formFields,
      typeUuid: formFields.typeUuid[0].value,
    };
  } else if (
    formFields.typeUuid &&
    Array.isArray(formFields.typeUuid) &&
    formFields.typeUuid.length === 0
  ) {
    // If typeUuid is an empty array, remove the typeUuid property
    const { typeUuid, ...restFields } = formFields;
    return restFields;
  }

  return formFields;
};

const createAiAppAnomalyFormSelector = () => formValueSelector(AI_APP_ANOMALY_FORM_NAME);
export const getAIAppAnomalyFormFields = (state) =>
  createAiAppAnomalyFormSelector()(state, ...AI_APP_ANOMALY_PROPS);
