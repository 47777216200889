import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import ToolAddButtonContainer from '../../addToolButton/ToolAddButton.container';
import ReportIcon from '../../../common/placeholderIcons/ReportIcon';
import placeholderMessages from '../../../../messages/placeholders.messages';

export const ToolsEmptyPlaceholder = ({ hasFiltersApplied, canAddTool }) => {
  const title = hasFiltersApplied ?
    placeholderMessages.noToolsFound : placeholderMessages.noToolsAdded;
  const description = hasFiltersApplied ?
    placeholderMessages.noToolsFoundDescription : placeholderMessages.noToolsAddedDescription;

  return (
    <div className="reports-empty">
      <div>
        <ReportIcon />
        <p>
          <FormattedMessage {...title} tagName="strong" />
        </p>
        { canAddTool && (
          <>
            <FormattedMessage {...description} tagName="p" />
            <br />
            <br />
            <ToolAddButtonContainer />
          </>
        ) }
      </div>
    </div>
  );
};

ToolsEmptyPlaceholder.propTypes = {
  hasFiltersApplied: PropTypes.bool.isRequired,
  canAddTool: PropTypes.bool.isRequired,
};

export default ToolsEmptyPlaceholder;
