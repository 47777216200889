import React from 'react';
import cn from 'classnames';

interface Props {
  value: boolean
}

export const BooleanField: React.FC<Props> = ({ value }) => (
  <span className={cn('online', { success: value })}/>
);

export default BooleanField;
