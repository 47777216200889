import {
  ACCOUNTS_MANAGER,
  ACCOUNTS_VIEWER,
  Role,
} from '../constants/permissions';

class AccountsPermissions {
  private isAccountsManager: boolean;

  private isAccountsViewer: boolean;

  constructor(roles: Role[]) {
    this.isAccountsManager = roles.indexOf(ACCOUNTS_MANAGER) !== -1;
    this.isAccountsViewer = roles.indexOf(ACCOUNTS_VIEWER) !== -1;
  }

  canCreateAccount() {
    return this.isAccountsManager;
  }

  canUpdateAccount() {
    return this.isAccountsManager;
  }

  availableToAll() {
    return this.isAccountsManager || this.isAccountsViewer;
  }
}

export default AccountsPermissions;
