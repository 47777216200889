import { createAction } from 'redux-act';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as aiAppsApi from '../../core/api/aiApps';

export const fetchAiAppDetails = createAction('fetch aiApp details', (aiAppId: string) => aiAppId);

export const fetchAiAppDetailsSuccess = createAction(
  'fetch aiApp details - success',
  (aiApps) => aiApps,
);

export const fetchAiAppDetailsFail = createAction('fetch aiApp details - failed', (error) => error);

export const resetAiAppDetails = createAction('reset aiApp details');

export const reducer = {
  [fetchAiAppDetails.getType()]: (state) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [fetchAiAppDetailsSuccess.getType()]: (state, aiApp) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      details: aiApp,
      loaded: true,
      loading: false,
      error: null,
    },
  }),

  [fetchAiAppDetailsFail.getType()]: (state, error) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: false,
      error,
    },
  }),

  [resetAiAppDetails.getType()]: (state) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      details: null,
    },
  }),
};

export function* fetchAiAppDetailsSaga({ payload: aiAppId }) {
  const { response, error } = yield call(aiAppsApi.fetchOne, aiAppId);

  if (response) {
    yield put(fetchAiAppDetailsSuccess(response));
  } else {
    yield put(fetchAiAppDetailsFail(error));
  }
}

export function* watchFetchAIAppDetails() {
  yield takeLatest(fetchAiAppDetails, fetchAiAppDetailsSaga);
}
