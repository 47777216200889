import {
  ORGANIZATIONS_MANAGER, Role,
} from '../constants/permissions';

class OrganizationsPermissions {
  private isOrganizationsManager: boolean;

  constructor(roles: Role[]) {
    this.isOrganizationsManager = roles.indexOf(ORGANIZATIONS_MANAGER) !== -1;
  }

  canCreateOrganizations() {
    return this.isOrganizationsManager;
  }

  canUpdateOrganizations() {
    return this.isOrganizationsManager;
  }

  canDeleteOrganizations() {
    return this.isOrganizationsManager;
  }
}

export default OrganizationsPermissions;
