import { connect } from 'react-redux';

import ModalsRouter from './ModalsRouter';
import {
  SMP_UNSUBSCRIBE_DATA_POINT,
} from '../../../../redux/constants/modals.constants';
import { isModalOpen } from '../../../../redux/selectors/modals.selector';

export const mapStateToProps = (state) => ({
  isUnsubscribeDataPointModalOpen: isModalOpen(state, SMP_UNSUBSCRIBE_DATA_POINT),
});

export default connect(mapStateToProps)(ModalsRouter);
