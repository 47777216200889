import { connect } from 'react-redux';

import OrganizationsHeader from './OrganizationsHeader';
import { getSort } from '../../../../../redux/selectors/admin.organizations.selector';
import { adminSortOrganizations } from '../../../../../redux/modules/admin.organizations.sort.filter.module';

export const mapStateToProps = (state) => {
  const sort = getSort(state);
  return {
    sortOption: sort.option,
    sortWay: sort.way,
  };
};

export const mapDispatchToProps = {
  onSortChange: adminSortOrganizations,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsHeader);
