import { isEmpty, cloneDeep, forEach } from 'lodash';
import moment from 'moment';

import weekdaysEnum from '../../../../common/enums/weekdays';
import { initializeCalendar } from './calendar';

export const prepareSchedule = (machineSchedule) => machineSchedule.reduce((final, item) => {
  const newFinal = final;
  newFinal[item.weekday].push(item);
  return newFinal;
}, weekdaysEnum.reduce((final, weekday) => ({
  ...final,
  [weekday]: [],
}), {}));

export const filterPointsByDateTimeRange = (points, start, end) => points.filter((point) => {
  const ts = moment(point.timestamp);
  return ts >= start && ts < end;
});

export const findFirstErrorField = (sortedProps, errors) => {
  if (!errors || isEmpty(errors)) return null;
  const errorsKeys = Object.keys(errors);
  const clonedProps = cloneDeep(sortedProps);

  while (clonedProps.length > 0) {
    const item = clonedProps.shift();
    if (errorsKeys.indexOf(item) >= 0) return item;
  }

  return null;
};

export const removeAssistantFromList = (assistants, userId) => {
  const newList = [];

  forEach(
    assistants,
    (assistant) => {
      if (assistant.id !== userId) {
        newList.push(assistant);
      }
    },
  );

  return newList;
};

export const initializeMachine = (machine) => {
  const result = {
    ...machine,
  };

  if (machine.calendars && machine.calendars.length) {
    result.calendars = machine.calendars.map(initializeCalendar);
  }

  return result;
};

export const getExplainedPath = ({
  path,
  isSelectedLocation,
  name,
}) => {
  let explainedPath = path;

  if (isSelectedLocation) {
    explainedPath.push(name);
  }

  explainedPath = explainedPath.slice(explainedPath.indexOf(name) + 1);

  return explainedPath;
};
