import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

export default function EventPreview({ event, className }) {
  const startDateTime = moment(event.startDateTime);
  const endDateTime = moment(event.endDateTime);

  const timeLabel = `${startDateTime.format('HH:mm')} - ${endDateTime.format('HH:mm')}`;
  const popupClassNames = classnames('schedule-item--popup outside on-hover', className);

  return (
    <div className="schedule-item-line-in">
      <div className={popupClassNames}>{ timeLabel }</div>
    </div>
  );
}

EventPreview.propTypes = {
  event: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};
