import React from 'react';
import { FormattedMessage } from 'react-intl';

import commonMessages from '../../../../messages/common.messages';
import commonCoreMessages from '../../../../messages/common.core.messages';
import commonFieldsMessages from '../../../../messages/common.fields.messages';
import QueryFilterContainer from './queryFilter/QueryFilter.container';
import { ToolStateFilter } from '../../../_tools/toolStateFilter/ToolStateFilter';

const TableHeader = () => (
  <thead>
    <tr>
      <td className="td-category">
        <div className="menu-tags white--btn">
          <ToolStateFilter />
        </div>
      </td>
      <td>
        <FormattedMessage {...commonCoreMessages.label} />
        {', '}
        <FormattedMessage {...commonCoreMessages.type} />
      </td>
      <FormattedMessage {...commonFieldsMessages.serialNumber} tagName="td" />
      <FormattedMessage {...commonCoreMessages.activity} tagName="td" />
      <FormattedMessage
        id="tools.fields.changed"
        defaultMessage="Changed"
        tagName="td"
      />
      <FormattedMessage {...commonMessages.duration} tagName="td" />
      <FormattedMessage {...commonCoreMessages.machine} tagName="td" />
      <FormattedMessage {...commonCoreMessages.comment} tagName="td" />
      <td className="td-edit td-edit-fixed">
        <div className="flex-name">
          <div className="rel-wrap">
            <div className="flex">
              <div className="flex-icon">
                <QueryFilterContainer />
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </thead>
);

export default TableHeader;
