import React, { useState } from 'react';
import classnames from 'classnames';

import MenuItem from './MenuItem';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';

interface Props {
  menuItems: any[]
  closeOnMouseLeave?: boolean
}

export const ThreeDotsMenu: React.FC<Props> = ({ menuItems, closeOnMouseLeave = false }) => {
  const [isOpen, isOpenSet] = useState(false);

  const toggleDropDown = () => isOpenSet(!isOpen);

  const close = () => isOpenSet(false);

  const handleMouseLeave = () => {
    if (closeOnMouseLeave) {
      close();
    }
  };

  const componentClassName = classnames(
    'filter--block',
    'filter--vertical--points',
    { opened: isOpen },
  );

  const clickRef = useClickOutside(close);

  return (
    <div
      className={componentClassName}
      onMouseLeave={handleMouseLeave}
      ref={clickRef}
    >
      <button
        className="filter--btn"
        type="button"
        onClick={toggleDropDown}
      >
        <span className="point-dot" />
      </button>
      <div className="filter--dropdown">
        <ul>
          { menuItems.map((item, index) => (
            <MenuItem
              key={index}
              {...item}
              close={close}
            />
          )) }
        </ul>
      </div>
    </div>
  );
};

export default ThreeDotsMenu;
