import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function MenuItem({ to, children, isActive }) {
  return (
    <li className={isActive ? 'active' : ''}>
      <Link to={to}>
        {children}
      </Link>
    </li>
  );
}

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
};
