import React, { useCallback } from 'react';
import ReactModal from 'react-modal';

interface Props {
  modalClass?: string;
  contentLabel: string;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode | React.ReactNode[];
}

const MODAL_STYLE = {
  overlay: {
    zIndex: '1000',
    backgroundColor: 'rgba(58, 59, 61, 0.55)',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  content: {
    top: '',
    left: '',
    right: '',
    bottom: '',
    border: '',
    background: '',
    WebkitOverflowScrolling: '',
    borderRadius: '',
    padding: '',
    overflow: 'auto',
    position: 'relative',
  },
};

export const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
  contentLabel,
  modalClass = '',
}) => {
  const onAfterClose = useCallback(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const onRequestClose = useCallback(() => {
    document.body.removeAttribute('style');
    onClose();
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterClose={onAfterClose}
      contentLabel={contentLabel}
      style={MODAL_STYLE}
      shouldReturnFocusAfterClose={false}
    >
      <a className="modal-overlay" onClick={onClose} />
      <div className={`modal-content ${modalClass}`}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
