import React from 'react';
import PropTypes from 'prop-types';

export const ExtraButtonsItem = ({
  title,
  onClick,
}) => (
  <button className="button green" onClick={onClick}>
    <span className="fa fa-plus" />
    { title }
  </button>
);

ExtraButtonsItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ExtraButtonsItem;
