import { createAction } from 'redux-act';

import * as entryTypes from '../constants/configRepoExplorer.entry.types.constants';

export const resetConfigRepoExplorer = createAction(
  'reset config repo explorer',
);

export const reducer = {
  [resetConfigRepoExplorer]: (state) => ({
    ...state,
    configRepoExplorer: {
      ...state.configRepoExplorer,
      [entryTypes.EQUIPMENT]: {
        ...state.configRepoExplorer[entryTypes.EQUIPMENT],
        selected: null,
      },
      [entryTypes.DATATRONS]: {
        list: [],
        loaded: false,
        loading: false,
        error: null,
        selected: null,
        disabled: [],
      },
      [entryTypes.DEVICES]: {
        list: [],
        loaded: false,
        loading: false,
        error: null,
        selected: null,
        disabled: [],
      },
      [entryTypes.DATA_POINTS]: {
        list: [],
        loaded: false,
        loading: false,
        error: null,
        selected: null,
        disabled: [],
        filters: {
          q: '',
          sort: { option: null, way: null },
        },
      },
      [entryTypes.MACHINES]: {
        list: [],
        loaded: false,
        loading: false,
        error: null,
        selected: null,
        disabled: [],
      },
      view: entryTypes.DATATRONS,
    },
  }),
};
