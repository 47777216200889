import React from 'react';
import PropTypes from 'prop-types';

import EmptyPlaceholderContainer from './emptyPlaceholder/EmptyPlaceholder.container';
import Loader from '../../_tools/loader/Loader';
import TableHeader from './header/TableHeader';
import Measurement from './measurement/Measurement';
import PaginationBar from '../../common/paginationBar/PaginationBar';

export const MeasurementsTab = ({
  measurements,
  isLoading,
  pagination,
  paginationAvailable,
  choosePage,
}) => (

  <div className="experiments-table">
    <table className="lined--table valign-middle">
      <TableHeader />
      <tbody>
        { measurements.map((item) => <Measurement key={item.id} measurement={item} />) }
      </tbody>
    </table>

    { paginationAvailable && (<PaginationBar {...pagination} choosePage={choosePage} />) }

    <Loader show={isLoading} />
    { !isLoading && !measurements.length && <EmptyPlaceholderContainer /> }
  </div>
);

MeasurementsTab.propTypes = {
  measurements: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  choosePage: PropTypes.func.isRequired,
};

export default MeasurementsTab;
