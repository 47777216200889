import React from 'react';
import { FormattedMessage } from 'react-intl';
import placeholderMessages from '../../../messages/placeholders.messages';

export const NoSelectedPlaceholder: React.FC = () => {
  const title = placeholderMessages.noDataSelected;
  return (
    <div className='folder-empty'>
      <div>
        <div className='open-folder-icon'>
          <i className='fa fa-folder-open-o' aria-hidden='true'></i>
        </div>
        <p>
          <FormattedMessage {...title} tagName='strong' />
        </p>
      </div>
    </div>
  );
};

export default NoSelectedPlaceholder;
