import { reduxForm } from 'redux-form';

import { onSubmitActions } from '../../../../redux/saga/onSubmitActions.saga';

import { RESOLVE_MODAL_FORM_NAME } from '../../../../redux/constants/resolveModal.form.constants';
import { resolveActivity } from '../../../../redux/modules/maintenance.columns.activity.resolve.module';
import { updateActivitySuccess, updateActivityFail } from '../../../../redux/modules/maintenance.columns.activity.update.module';
import { validateResolveEvent } from '../../../../core/validation/resolveEvent.validation';

import ResolveModal from './ResolveModal';

const form = {
  form: RESOLVE_MODAL_FORM_NAME,
  touchOnChange: true,
  onSubmit: onSubmitActions(resolveActivity, updateActivitySuccess, updateActivityFail),
  validate: validateResolveEvent,
};

export default reduxForm(form)(ResolveModal);
