import { connect } from 'react-redux';

import {
  getMembers,
  isLoading,
} from '../../../redux/selectors/location.selector';
import Organization from './Organization';

export function mapStateToProps(state) {
  return {
    members: getMembers(state),
    loadingState: isLoading(state),
  };
}

export default connect(mapStateToProps)(Organization);
