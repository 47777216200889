import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getMachineLink } from '../../../../index/routes';

export default function CalendarFooterListItem({ machine }) {
  return (
    <Link to={getMachineLink(machine.id)}>
      <span className="icon-cogs" />
      <span className="link--content">{ machine.name }</span>
    </Link>
  );
}

CalendarFooterListItem.propTypes = {
  machine: PropTypes.object.isRequired,
};
