import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function OUTags() {
  return (
    <div className="col--in height40 hide">

      <div className="col--title">
        <FormattedMessage
          id="teams.tags"
          defaultMessage="Tags"
        />
      </div>

      <div className="text-center alert-info">
        tags here
      </div>

    </div>
  );
}
