import { defineMessages } from 'react-intl';

import * as fileFormats from '../../../common/constants/fileFormats';

export default defineMessages({
  [fileFormats.XLSX]: {
    id: 'common.fileFormats.xlsx.description',
    defaultMessage: 'Excel',
  },
  [fileFormats.CSV]: {
    id: 'common.fileFormats.csv.description',
    defaultMessage: 'comma-separated',
  },
  [fileFormats.TSV]: {
    id: 'common.fileFormats.tsv.description',
    defaultMessage: 'tab-separated',
  },
});
