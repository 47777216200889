import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { push } from 'connected-react-router';
import { scroller } from 'react-scroll';
import { isEmpty } from 'lodash';

import * as mfc from '../constants/machine.form.constants';
import { getFormFields } from '../selectors/machine.page.form.selector';
import { getMachine } from '../selectors/machine.selector';
import * as api from '../../core/api';
import { getMachineLink } from '../../components/index/routes';
import { findFirstErrorField } from '../../core/common/forms';
import { sendNotification } from './notifications.module';
import { fetchInventory } from './inventory.fetch.module';
import notificationMessages from '../../messages/notification.message';
import { NO_FILE } from '../constants/fileUpload.component.constants';
import { INVENTORY_MACHINES } from '../../../../common/constants/inventory';
import { fetchMachineOee } from './machine.oee.fetch.module';
import { validateMachine } from '../../core/validation/machines.form.validation';

export const updateMachine = createAction(
  'update machine',
);

export const updateMachineSuccess = createAction(
  'update machine - success',
  (payload) => payload,
);

export const updateMachineFail = createAction(
  'update machine - fail',
  (error) => error,
);

export const reducer = {
  [updateMachine]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      _update: {
        ...state.machine._update,
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),
  [updateMachineSuccess]: (state, payload) => {
    const newState = {
      ...state,
      machine: {
        ...state.machine,
        details: {
          ...state.machine.details,
          ...payload,
        },
        _update: {
          ...state.machine._update,
          loading: false,
          loaded: true,
          error: null,
        },
      },
    };

    const inventoryPreview = state.inventory.preview;
    if (inventoryPreview
      && inventoryPreview.itemType === INVENTORY_MACHINES
      && inventoryPreview.item
      && inventoryPreview.item.id === payload.id
    ) {
      newState.inventory = {
        ...state.inventory,
        preview: {
          ...state.inventory.preview,
          item: {
            ...state.inventory.preview.item,
            ...payload,
          },
        },
      };
    }

    return newState;
  },
  [updateMachineFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      _update: {
        ...state.machine._update,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    mfc.MACHINE_FORM_NAME,
    ...mfc.MACHINE_PROPS,
  ));
}

export function* getFields(state) {
  const fields = yield call(getFormFields, state);

  if (!fields[mfc.PHOTO_FIELD]) {
    delete fields[mfc.PHOTO_FIELD];
  }

  if (fields[mfc.ORGANIZATION_FIELD]) {
    fields[mfc.ORGANIZATION_FIELD] = fields[mfc.ORGANIZATION_FIELD].value;
  }

  if (fields[mfc.OEE_A_DATA_POINT_ID]) {
    fields[mfc.OEE_A_DATA_POINT_ID] = fields[mfc.OEE_A_DATA_POINT_ID].value;
  }

  if (fields[mfc.OEE_P_DATA_POINT_ID]) {
    fields[mfc.OEE_P_DATA_POINT_ID] = fields[mfc.OEE_P_DATA_POINT_ID].value;
  }

  if (fields[mfc.OEE_Q_DATA_POINT_ID]) {
    fields[mfc.OEE_Q_DATA_POINT_ID] = fields[mfc.OEE_Q_DATA_POINT_ID].value;
  }

  if (fields[mfc.RFID_DATA_POINT_ID]) {
    fields[mfc.RFID_DATA_POINT_ID] = fields[mfc.RFID_DATA_POINT_ID].value;
  }

  if (fields[mfc.ANALYTICS_CYCLE_START_DATA_POINT_ID]) {
    fields[mfc.ANALYTICS_CYCLE_START_DATA_POINT_ID] = fields[mfc.ANALYTICS_CYCLE_START_DATA_POINT_ID].value;
  }

  if (fields[mfc.ANALYTICS_CYCLE_END_DATA_POINT_ID]) {
    fields[mfc.ANALYTICS_CYCLE_END_DATA_POINT_ID] = fields[mfc.ANALYTICS_CYCLE_END_DATA_POINT_ID].value;
  }

  if (!fields[mfc.SCHEDULE_CALENDAR_FIELD]) {
    delete fields[mfc.SCHEDULE_CALENDAR_FIELD];
  } else {
    fields[mfc.SCHEDULE_CALENDAR_FIELD] = fields[mfc.SCHEDULE_CALENDAR_FIELD].value;
  }

  if (!fields[mfc.SHIFT_CALENDAR_FIELD]) {
    delete fields[mfc.SHIFT_CALENDAR_FIELD];
  } else {
    fields[mfc.SHIFT_CALENDAR_FIELD] = fields[mfc.SHIFT_CALENDAR_FIELD].value;
  }

  if (fields[mfc.OEE_THRESHOLD_GREEN] && parseInt(fields[mfc.OEE_THRESHOLD_GREEN]) >= 0) {
    fields[mfc.OEE_THRESHOLD_GREEN] /= 100;
  }

  if (fields[mfc.OEE_THRESHOLD_YELLOW] && parseInt(fields[mfc.OEE_THRESHOLD_YELLOW]) >= 0) {
    fields[mfc.OEE_THRESHOLD_YELLOW] /= 100;
  }

  if (fields[mfc.OEE_THRESHOLD_RED] && parseInt(fields[mfc.OEE_THRESHOLD_RED]) >= 0) {
    fields[mfc.OEE_THRESHOLD_RED] /= 100;
  }

  return fields;
}

export function* handleErrors(errors) {
  yield put(updateMachineFail(errors));
  yield put(stopSubmit(mfc.MACHINE_FORM_NAME, errors));
  const firstErrorField = yield call(findFirstErrorField, mfc.MACHINE_PROPS, errors);
  if (firstErrorField) {
    yield scroller.scrollTo(
      firstErrorField,
      { offset: mfc.MACHINE_FORM_ERROR_FIELD_OFFSET },
    );
  }
}

export function* updateMachineSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const machine = yield call(getMachine, state);
  const fields = yield call(getFields, state);
  yield put(startSubmit(mfc.MACHINE_FORM_NAME));

  if (fields[mfc.PHOTO_FIELD] === NO_FILE) {
    const deletePhotoCall = yield call(api.machines.deleteMachinePhoto, machine.id);
    if (deletePhotoCall.error) {
      yield put(updateMachineFail(deletePhotoCall.error));
    }
    delete fields[mfc.PHOTO_FIELD];
  }

  const preRequestValidationErrors = yield call(validateMachine, fields);
  if (!isEmpty(preRequestValidationErrors)) {
    yield handleErrors(preRequestValidationErrors);
    return;
  }

  const { response, error } = yield call(api.machines.updateMachine, machine.id, fields);

  if (response) {
    yield put(updateMachineSuccess(response));
    yield put(fetchMachineOee(machine.id));
    yield put(stopSubmit(mfc.MACHINE_FORM_NAME));
    yield put(fetchInventory());
    yield put(push(getMachineLink(machine.id)));
    yield put(sendNotification(notificationMessages.machine_updated_title));
  } else {
    yield handleErrors(error);
  }
}

export function* watchUpdateMachine() {
  yield takeEvery(updateMachine.getType(), updateMachineSaga);
}
