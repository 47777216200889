import { createAction } from 'redux-act';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as aiAppsApi from '../../core/api/aiApps';

export const fetchCycleDetectionDetails = createAction(
  'fetch cycleDetection details',
  (cycleDetection: string) => cycleDetection,
);

export const fetchCycleDetectionDetailsSuccess = createAction(
  'fetch cycleDetection details - success',
  (cycleDetection) => cycleDetection,
);

export const fetchCycleDetectionDetailsFail = createAction(
  'fetch cycleDetection details - failed',
  (error) => error,
);

export const resetCycleDetectionDetails = createAction('reset cycleDetection details');

export const reducer = {
  [fetchCycleDetectionDetails.getType()]: (state) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [fetchCycleDetectionDetailsSuccess.getType()]: (state, cycleDetection) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      cycleDetectionDetails: cycleDetection,
      loaded: true,
      loading: false,
      error: null,
    },
  }),

  [fetchCycleDetectionDetailsFail.getType()]: (state, error) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: false,
      error,
    },
  }),

  [resetCycleDetectionDetails.getType()]: (state) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      cycleDetectionDetails: null,
    },
  }),
};

export function* fetchCycleDetectionDetailsSaga({ payload: cycleDetection }) {
  const { response, error } = yield call(aiAppsApi.fetchCycleDetection, cycleDetection);

  if (response) {
    yield put(fetchCycleDetectionDetailsSuccess(response));
  } else {
    yield put(fetchCycleDetectionDetailsFail(error));
  }
}

export function* watchFetchCycleDetectionDetails() {
  yield takeLatest(fetchCycleDetectionDetails, fetchCycleDetectionDetailsSaga);
}
