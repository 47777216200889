import classnames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Todo } from '../../../../../common/types/common';
import InputError from '../inputError/InputError';
import NotSetPlaceholder from './NotSetPlaceholder';

interface InputWithErrorProps {
  touched?: boolean;
  error?: string;
  meta: {
    error?: string | Todo;
    touched?: boolean;
  };
  input: Todo;
  className?: string;
  _defaultValue?: any;
  pressed?: boolean;
  disabled?: boolean;
}

export const TextAreaWithError: React.FC<InputWithErrorProps> = ({
  touched,
  error,
  meta,
  input,
  className,
  _defaultValue,
  pressed = false,
  disabled = false,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const { value, onChange } = input;
    if (
      (value === null || value === undefined || value === '') &&
      _defaultValue !== undefined
    ) {
      onChange(_defaultValue);
    }
  }, [_defaultValue, input]);

  const isError = meta.touched && meta.error;

  const divClassName = classnames('input-group', 'white-group', { pressed });

  const textAreaProps = {
    ...input,
    ...props,
    disabled,
  };
  delete textAreaProps._defaultValue;

  const isValueEmpty = isEmpty(input.value);

  if (isValueEmpty && disabled) return <NotSetPlaceholder />;

  return (
    <div className="rel">
      <div className={divClassName}>
        <textarea
          style={{ fontFamily: 'monospace' }}
          ref={inputRef}
          {...textAreaProps}
          className={classnames(className || '', { error: isError })}
        />
      </div>
      {isError && (
        <div className="error-line">
          <InputError error={meta.error} />
        </div>
      )}
    </div>
  );
};
