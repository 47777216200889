import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { initializeAccount } from '../../core/common/admin.state';

export const adminFetchAccount = createAction(
  'admin: fetch account',
  (id) => id,
);

export const adminFetchAccountSuccess = createAction(
  'admin: fetch account - success',
  (item) => item,
);

export const adminFetchAccountFail = createAction(
  'admin: fetch account - fail',
  (error) => error,
);

export const adminResetAccount = createAction(
  'admin: reset account',
);

export const reducer = {
  [adminFetchAccount]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      accounts: {
        ...state.admin.accounts,
        selected: {
          ...state.admin.accounts.selected,
          item: null,
          loaded: false,
          loading: true,
          error: null,
        },
      },
    },
  }),

  [adminFetchAccountSuccess]: (state, item) => ({
    ...state,
    admin: {
      ...state.admin,
      accounts: {
        ...state.admin.accounts,
        selected: {
          ...state.admin.accounts.selected,
          item,
          loaded: true,
          loading: false,
          error: null,
        },
      },
    },
  }),

  [adminFetchAccountFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      accounts: {
        ...state.admin.accounts,
        selected: {
          ...state.admin.accounts.selected,
          item: null,
          loaded: false,
          loading: false,
          error,
        },
      },
    },
  }),

  [adminResetAccount]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      accounts: {
        ...state.admin.accounts,
        selected: {
          ...state.admin.accounts.selected,
          item: null,
          loaded: false,
          loading: false,
          error: null,
        },
      },
    },
  }),
};

export function* adminFetchAccountSaga({ payload: id }) {
  const { response, error } = yield call(api.admin.getAccount, id);

  if (response) {
    yield put(adminFetchAccountSuccess(initializeAccount(response)));
  } else {
    yield put(adminFetchAccountFail(error));
  }
}

export function* watchAdminFetchAccount() {
  yield takeEvery(adminFetchAccount.getType(), adminFetchAccountSaga);
}
