import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { getActivityIndexById } from '../selectors/maintenance.selector';
import { insertActivity } from '../../core/common/maintenance.stream.js';
import { updateActivitySuccess, updateActivityFail } from './maintenance.columns.activity.update.module';

export const moveActivity = createAction(
  'move activity',
  (activityId, state, betweenCards) => ({ activityId, state, betweenCards }),
);

export const reducer = {
  [moveActivity]: (storeState,
    { activityId, state, betweenCards: { belowCardId, aboveCardId } }) => {
    const index = getActivityIndexById(storeState, activityId);

    if (index === -1) return storeState;

    const activity = storeState.maintenance.activities[index];
    const newActivity = {
      ...activity,
      state,
    };

    const activitiesWithoutMovedActivity = [
      ...storeState.maintenance.activities.slice(0, index),
      ...storeState.maintenance.activities.slice(index + 1),
    ];

    const newActivities = insertActivity(
      activitiesWithoutMovedActivity, newActivity,
      aboveCardId, belowCardId,
    );

    return {
      ...storeState,
      maintenance: {
        ...storeState.maintenance,
        activities: newActivities,
      },
    };
  },
};

export function* moveActivitySaga({ payload: { activityId, state } }) {
  const { response, error } = yield call(api.activities.updateActivity, activityId, { state });

  if (response) {
    yield put(updateActivitySuccess(response));
  } else {
    yield put(updateActivityFail(activityId, error));
  }
}

export function* watchMoveActivity() {
  yield takeEvery(moveActivity.getType(), moveActivitySaga);
}
