import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { find } from 'lodash';
import { useIntl } from 'react-intl';

export function LanguageInput({ locales, input: { value, onChange } }) {
  const { formatMessage } = useIntl();
  const localesLocalized = useMemo(() => locales.map((locale) => ({
    label: formatMessage({ id: `locales.${locale}` }),
    value: locale,
  })), [formatMessage, locales]);

  const onSelectChange = useCallback((newValue) => {
    onChange(newValue.value);
  }, [onChange]);

  const selectedValue = useMemo(() => {
    if (!value) {
      return null;
    }
    return find(localesLocalized, (item) => item.value === value);
  }, [localesLocalized, value]);

  return (
    <Select
      value={selectedValue}
      isClearable={false}
      onChange={onSelectChange}
      options={localesLocalized}
    />
  );
}

LanguageInput.propTypes = {
  locales: PropTypes.array.isRequired,

  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default LanguageInput;
