import { createAction } from 'redux-act';
import {
  put, takeLatest, delay,
} from 'redux-saga/effects';

import * as fetchMaintenanceEventsModule from './maintenance.fetch.events.module';
import { MAINTENANCE_EVENTS_ON_ONE_PAGE } from '../constants/maintenance.constants';

export const loadMoreMaintenanceEvents = createAction(
  'load more maintenance events',
);

export const maintenanceEventsNextPage = createAction(
  'set next maintenance events portion for load',
);

export const resetMaintenanceEventsPagination = createAction(
  'reset maintenance events pagination',
);

export const reducer = {
  [maintenanceEventsNextPage]: (state) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      loadEventsFrom: state.maintenance.loadEventsTo,
      loadEventsTo: state.maintenance.loadEventsTo + MAINTENANCE_EVENTS_ON_ONE_PAGE,
    },
  }),
  [resetMaintenanceEventsPagination]: (state) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      loadEventsFrom: 0,
      loadEventsTo: MAINTENANCE_EVENTS_ON_ONE_PAGE,
    },
  }),
};

export function* loadMoreMaintenanceEventsSaga() {
  yield delay(500);

  yield put(maintenanceEventsNextPage());
  yield put(fetchMaintenanceEventsModule.fetchMaintenanceEvents());
}

export function* watchLoadMoreMaintenanceEvents() {
  yield takeLatest(loadMoreMaintenanceEvents.getType(), loadMoreMaintenanceEventsSaga);
}
