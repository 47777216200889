import { createAction } from 'redux-act';

export const showNewPassword = createAction(
  'show new password',
);

export const hideNewPassword = createAction(
  'hide new password',
);

export const reducer = {
  [showNewPassword]: (state) => ({
    ...state,
    profile: {
      ...state.profile,
      isNewPasswordShown: true,
    },
  }),

  [hideNewPassword]: (state) => ({
    ...state,
    profile: {
      ...state.profile,
      isNewPasswordShown: false,
    },
  }),
};
