import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { Todo } from '../../../../../../../common/types/common';
import profileMessages from '../../../../../messages/profile.messages';
import { getUser } from '../../../../../redux/selectors/user.selector';

type RevealStates = 'hidden' | 'visible';

const tokenFormats = ['Plain', 'Header', 'Curl', 'CurlWindows', 'PowerShell'] as const;

type TokenFormatsType = (typeof tokenFormats)[number];

export const AccessToken = () => {
  const { formatMessage } = useIntl();

  const user = useSelector(getUser);
  const token = user.apiAccessToken || '';
  const [visibility, setVisibility] = useState<RevealStates>('hidden');

  const [format, formatSet] = useState<TokenFormatsType>('Plain');

  const timeoutRef = useRef<number>();

  const divClassName = classnames('input-group', 'white-group');

  const input = useRef<Todo>();

  const revertToPlaceholder = () => {
    setVisibility('hidden');
    timeoutRef.current = undefined;
  };

  const handleFormatSelect = (e) => {
    formatSet(e.target.value);
  };

  const formatToken = () => {
    switch (format) {
      case 'Plain':
        return token;
      case 'Header':
        return `x-auth-token: Bearer ${token}`;
      case 'Curl':
        return `curl ${window?.location?.origin}/api/users/me -H 'x-auth-token: Bearer ${token}'`;
      case 'CurlWindows':
        return `curl ${window?.location?.origin}/api/users/me -H "x-auth-token: Bearer ${token}"`;
      case 'PowerShell':
        return `Invoke-WebRequest -UseBasicParsing -Uri "${window?.location?.origin}/api/users/me" -Headers @{"x-auth-token"="Bearer ${token}"}`;
      default:
        return token;
    }
  };

  const clickHandler = () => {
    const tokenToCopy = formatToken();

    navigator.clipboard.writeText(tokenToCopy).then(() => {
      setVisibility('visible');
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(revertToPlaceholder, 1500);
    });
  };

  return (
    <div className='rel'>
      <FormattedMessage id='profile.accessToken' defaultMessage='API Access Token' tagName='h1' />
      {token ? (
        <div className={divClassName}>
          <label style={{ marginRight: '5px' }}>
            {formatMessage(profileMessages.profileTokenFormat)}
            <select onChange={handleFormatSelect}>
              {tokenFormats.map((formatOption) => (
                <option key={formatOption} value={formatOption}>
                  {formatMessage(profileMessages[`profileTokenFormat${formatOption}`])}
                </option>
              ))}
            </select>
          </label>
          <label>
            {formatMessage(profileMessages.profileTokenClickToCopy)}
            <div
              onClick={clickHandler}
              ref={(refInput) => {
                input.current = refInput;
              }}
              style={{
                margin: '9px 0 0',
                alignItems: 'stretch',
                display: 'flex',
                borderRadius: '4px',
                outline: 'none',
                boxShadow: 'none',
                border: '1px solid #d4d7dc',
                fontSize: '13px',
                padding: '10px 13px',
                height: '42px',
                background: 'white',
                color: 'black',
              }}
            >
              <p>{token.substring(0, 45) + '...'}</p>
              <span className='icon-clipboard' style={{ fontSize: '20px', marginLeft: 'auto' }} />
              <div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  borderBottom: '1px dotted black',
                }}
              >
                <p
                  style={{
                    visibility,
                    width: '120px',
                    backgroundColor: 'black',
                    color: 'white',
                    textAlign: 'center',
                    padding: '5px 0',
                    borderRadius: '6px',
                    position: 'absolute',
                    zIndex: '1',
                    top: '-40px',
                    left: '-70px',
                    opacity: '50%',
                  }}
                >
                  {formatMessage(profileMessages.profileTokenCopied)}
                </p>
              </div>
            </div>
          </label>
        </div>
      ) : (
        <div className='profile--access-token-missing'>
          <p>
            <FormattedMessage
              id='profile.accessTokenMissing'
              defaultMessage='You do not have an API access token.'
            />
          </p>
        </div>
      )}
    </div>
  );
};
