import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import AdminPageHeader from './AdminPageHeader';
import AdminPageTabsContainer from './tabs/AdminPageTabs.container';
import Unauthorized from '../pages/unauthorized/Unauthorized';
import {
  getAdminAccountsLink,
  getAdminOrganizationsLink,
  getAdminLocationsLink,
  getAdminUsersLink,
  getAdminRolesLink,
  getAdminPageLink,
} from '../index/routes';
import { onAdminPageEnter } from '../index/routeHandlers';

interface AdminPageProps extends RouteConfigComponentProps {
  children?: React.ReactNode;
  hasAccess: boolean;
  hasAccountsAccess: boolean;
  hasOrganizationsAccess: boolean;
  hasLocationsAccess: boolean;
  hasUsersAccess: boolean;
  hasRolesAccess: boolean;
}

const AdminPage: React.FC<AdminPageProps> = ({
  hasAccess,
  hasAccountsAccess,
  hasOrganizationsAccess,
  hasLocationsAccess,
  hasUsersAccess,
  hasRolesAccess,
  route,
}) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    onAdminPageEnter();

    let link: string | null = null;
    if (location.pathname === getAdminPageLink()) {
      if (hasAccountsAccess) {
        link = getAdminAccountsLink();
      } else if (hasOrganizationsAccess) {
        link = getAdminOrganizationsLink();
      } else if (hasLocationsAccess) {
        link = getAdminLocationsLink();
      } else if (hasUsersAccess) {
        link = getAdminUsersLink();
      } else if (hasRolesAccess) {
        link = getAdminRolesLink();
      }
    }

    if (link) {
      history.push(link);
    }
  }, [
    hasAccountsAccess,
    hasOrganizationsAccess,
    hasLocationsAccess,
    hasUsersAccess,
    hasRolesAccess,
    location.pathname,
    history,
  ]);

  if (!hasAccess) {
    return <Unauthorized />;
  }

  return (
    <div className='col--1'>
      <AdminPageHeader />
      <div className='users'>
        <AdminPageTabsContainer
          hasAccountsAccess={hasAccountsAccess}
          hasLocationsAccess={hasLocationsAccess}
          hasOrganizationsAccess={hasOrganizationsAccess}
          hasUsersAccess={hasUsersAccess}
          hasRolesAccess={hasRolesAccess}
          currentPath={location.pathname}
          pushPath={history.push}
        />
        <div className='users-wrap'>{route && renderRoutes(route.routes)}</div>
      </div>
    </div>
  );
};

export default AdminPage;
