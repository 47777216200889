import { createAction } from 'redux-act';
import {
  put, takeLatest, delay,
} from 'redux-saga/effects';

import { adminFetchUsers } from './admin.users.fetch.module';
import { SORT_WAY_ASC, SORT_WAY_DESC } from '../../../../common/constants';
import { closeComponent } from './components.visibility.module';
import {
  AP_USERS_NAME_FILTER_INPUT,
  AP_USERS_EMAIL_FILTER_INPUT,
} from '../constants/components.constants';

export const DEBOUNCE_TIME_IN_MILLISECONDS = 500;

export const adminSortUsers = createAction(
  'admin: sort users',
  (option) => option,
);

export const adminFilterUsersByName = createAction(
  'admin: filter users by name',
  (name = '') => name,
);

export const adminFilterUsersByEmail = createAction(
  'admin: filter users by email',
  (email = '') => email,
);

export const adminResetUsersFilters = createAction(
  'admin: reset users filters',
);

export const reducer = {
  [adminSortUsers]: (state, option) => {
    const prevOption = state.admin.users.filters.sort.option;
    const prevWay = state.admin.users.filters.sort.way;

    let newOption = null;
    let newWay = null;

    if (prevOption === option && prevWay === SORT_WAY_ASC) {
      newOption = prevOption;
      newWay = SORT_WAY_DESC;
    } else if (prevOption !== option) {
      newOption = option;
      newWay = SORT_WAY_ASC;
    }

    return {
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          filters: {
            ...state.admin.users.filters,
            sort: { option: newOption, way: newWay },
          },
        },
      },
    };
  },
  [adminFilterUsersByName]: (state, name) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        filters: {
          ...state.admin.users.filters,
          name,
        },
      },
    },
  }),
  [adminFilterUsersByEmail]: (state, email) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        filters: {
          ...state.admin.users.filters,
          email,
        },
      },
    },
  }),
  [adminResetUsersFilters]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        filters: {
          ...state.admin.users.filters,
          name: '',
          email: '',
          sort: {
            option: null,
            way: null,
          },
        },
      },
    },
  }),
};

export function* adminResetUsersFiltersSaga() {
  yield put(closeComponent(AP_USERS_NAME_FILTER_INPUT));
  yield put(closeComponent(AP_USERS_EMAIL_FILTER_INPUT));
  yield put(adminFetchUsers());
}

export function* watchAdminResetUsersFilters() {
  yield takeLatest(
    [
      adminResetUsersFilters.getType(),
    ],
    adminResetUsersFiltersSaga,
  );
}

export function* adminFetchUsersSaga() {
  yield delay(DEBOUNCE_TIME_IN_MILLISECONDS);
  yield put(adminFetchUsers());
}

export function* watchAdminUsersFiltersChange() {
  yield takeLatest(
    [
      adminSortUsers.getType(),
      adminFilterUsersByName.getType(),
      adminFilterUsersByEmail.getType(),
    ],
    adminFetchUsersSaga,
  );
}
