import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';

import { selectInventoryLocations, getLocationById } from '../selectors/inventory.selector';
import { sendNotification } from './notifications.module';
import { INVENTORY_DATATRONS, INVENTORY_MACHINES } from '../../../../common/constants/inventory';
import notificationMessages from '../../messages/notification.message';

export const receiveMachine = createAction(
  'receive new machine',
  (machine) => machine,
);

export const reducer = {
  [receiveMachine]: (state, machine) => {
    const locationIndex = getLocationById(state, machine.location.id);

    let locations = selectInventoryLocations(state);

    if (locationIndex !== -1) {
      const location = locations[locationIndex];

      locations = [
        {
          ...location,
          [INVENTORY_MACHINES]: [
            ...location[INVENTORY_MACHINES],
            machine,
          ],
        },
        ...state.inventory.locations.slice(0, locationIndex),
        ...state.inventory.locations.slice(locationIndex + 1),
      ];
    } else {
      const location = {
        ...machine.location,
        [INVENTORY_MACHINES]: [machine],
        [INVENTORY_DATATRONS]: [],
      };
      locations = [
        location,
        ...locations,
      ];
    }

    return {
      ...state,
      inventory: {
        ...state.inventory,
        locations,
        machines: [
          ...state.inventory.machines,
          machine,
        ],
      },
    };
  },
};

export function* receiveMachineSaga({ payload: machine }) {
  yield put(sendNotification(
    {
      ...notificationMessages.new_machine_created_title,
      values: {
        machineName: machine.name,
      },
    },
  ));
}

export function* watchReceiveMachine() {
  yield takeEvery(receiveMachine.getType(), receiveMachineSaga);
}
