import { createAction } from 'redux-act';
import {
  put, call, select, takeLatest,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { mergeObjectInList, compareById, removeObjectFromList } from '../../../../common/helpers/object';
import { closeModal } from './modals.module';
import { CP_CALENDAR_REMOVE } from '../constants/modals.constants';
import { resetFilterQueries } from './calendars.query.module';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';
import { getCalendarById } from '../selectors/calendar.selector';

export const deleteCalendar = createAction(
  'delete calendar',
  (calendarId) => calendarId,
);

export const deleteCalendarSuccess = createAction(
  'delete calendar - success',
  (calendarId) => calendarId,
);

export const deleteCalendarFail = createAction(
  'delete calendar event - fail',
  (calendarId, error) => ({ calendarId, error }),
);

export const reducer = {
  [deleteCalendar]: (state, calendarId) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: mergeObjectInList(
        state.machineCalendars.list,
        {
          id: calendarId,
          _delete: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
        compareById,
      ),
    },
  }),
  [deleteCalendarSuccess]: (state, calendarId) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: removeObjectFromList(
        state.machineCalendars.list,
        { id: calendarId },
        compareById,
      ),
    },
  }),
  [deleteCalendarFail]: (state, { calendarId, error }) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: mergeObjectInList(
        state.machineCalendars.list,
        {
          id: calendarId,
          _delete: {
            loading: false,
            loaded: false,
            error,
          },
        },
        compareById,
      ),
    },
  }),
};

export function* deleteCalendarSaga({ payload: calendarId }) {
  const state = yield select();
  const calendar = yield call(getCalendarById, state, calendarId);

  if (!calendar) return;

  const { response, error } = yield call(api.calendars.deleteCalendar, calendarId);

  if (response) {
    yield put(deleteCalendarSuccess(calendarId));
    yield put(closeModal(CP_CALENDAR_REMOVE));
    yield put(sendNotification({ ...notificationMessage.calendar_removed, values: { name: calendar.name } }));
  } else {
    yield put(deleteCalendarFail(calendarId, error));
  }

  yield put(resetFilterQueries());
}

export function* watchDeleteCalendar() {
  yield takeLatest(deleteCalendar.getType(), deleteCalendarSaga);
}
