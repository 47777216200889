import { useCallback, useEffect, useRef } from 'react';

const LISTEN_EVENTS = [
  'click', // fires when mouse releases, use 'mousedown' for the beginning of a click
  'touchstart', // fires when a finger touches a touchscreen
];
/**
 * A React hook that calls the passed function when a click is detected outside of the element it is called for.
 *
 * ```javascript
 * Example Usage:
 * const clickRef = useClickOutside(handlerFunction);
 * return <div ref={clickRef}>{content}</div>
 * ```
 *
 * @param {Function} handler A function that is called when a click is detected outside of the element
 * @param {string[]} eventTypes (optional) Specify the type of events that are listened for. Default: `['click', 'touchstart']`
 *
 * @returns {useRef} A ref that must be given as a prop to the bounding div of the component the listener is for.
 */
export const useClickOutside = (handler: (...args: never) => void, eventTypes?: string[]) => {
  const clickRef = useRef<any>(null);

  /**
   * Runs the handler function if the detected click happened outside of the desired element
   */
  const handleClick = useCallback((ev: Event) =>{
    if (!clickRef.current?.contains(ev.target)) {
      handler();
    }
  }, [handler]);

  useEffect(() => {
    (eventTypes ?? LISTEN_EVENTS).forEach((eventName)=>{
      document.addEventListener(eventName, handleClick);
    });
    return () => {
      (eventTypes ?? LISTEN_EVENTS).forEach((eventName)=>{
        document.removeEventListener(eventName, handleClick);
      });
    };
  }, [eventTypes, handleClick]);

  return clickRef;
};