import React from 'react';
import PropTypes from 'prop-types';

import NotAvailable from '../calendar/notAvailable/NotAvailable';
import MachineCalendarsEmptyPlaceholder from './MachineCalendarsEmptyPlaceholder';
import Unauthorized from '../pages/unauthorized/Unauthorized';
import ShiftCalendarsContainer from './calendars/ShiftCalendars.container';
import ScheduleCalendarsContainer from './calendars/ScheduleCalendars.container';

const MachineCalendarsContent = ({
  isCalendarAccess,
  isCalendarAvailable,
  isEmpty,
}) => {
  if (!isCalendarAccess) {
    return <Unauthorized />;
  } if (!isCalendarAvailable) {
    return <NotAvailable />;
  } if (isEmpty) {
    return <MachineCalendarsEmptyPlaceholder />;
  }

  return (
    <div className="col--2 col--2--central">
      <ShiftCalendarsContainer />
      <ScheduleCalendarsContainer />
    </div>
  );
};

MachineCalendarsContent.propTypes = {
  isCalendarAccess: PropTypes.bool.isRequired,
  isCalendarAvailable: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
};

export default MachineCalendarsContent;
