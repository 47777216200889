import { createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as accountsApi from '../../core/api/accounts.js';

export const fetchUser = createAction(
  'fetch user details',
);

export const fetchUserSuccess = createAction(
  'fetch user success',
  (response) => ({ payload: response }),
);

export const fetchUserFail = createAction(
  'fetch user fail',
  (error) => ({ payload: error }),
);

export const reducer = {
  [fetchUser.type]: (state) => ({
    ...state,
    profile: {
      ...state.profile,
      loading: true,
    },
  }),

  [fetchUserSuccess.type]: (state, user) => ({
    ...state,
    profile: {
      ...state.profile,
      user,
      language: user.locale,
      loading: false,
      loaded: true,
    },
  }),

  [fetchUserFail.type]: (state, error) => ({
    ...state,
    profile: {
      ...state.profile,
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* fetchUserSaga() {
  const { response, error } = yield call(accountsApi.getUser);

  if (response) {
    /*
    if (response.user.locale) {
      moment.locale(response.user.locale);
    }

     */
    yield put(fetchUserSuccess(response.user));
  } else {
    yield put(fetchUserFail(error));
  }
}

export function* watchFetchUser() {
  yield takeEvery(fetchUser, fetchUserSaga);
}
