import React from 'react';
import { components } from 'react-select';

interface Props extends React.ComponentProps<typeof components.Option> {
  value: {
    firstName: string;
    lastName: string;
    name: string;
  }
}

const UserOption: React.FC<Props> = (props) => {
  const { value } = props;
  return (
    <components.Option {...props}>
      <div className="maintenance--assignee-option">
        <strong>
          {`@${value.firstName[0]}${value.lastName[0]}`}
        </strong>
        <span>&nbsp;</span>
        <span>{value.name}</span>
      </div>
    </components.Option>
  );
};

export default UserOption;
