import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { getMachine } from '../selectors/machine.selector';
import { getSubscriptionById } from '../selectors/machine.subscriptions.selector';
import * as api from '../../core/api';
import { replaceObjectInList, removeObjectFromList, compareById } from '../../../../common/helpers/object';
import { SMP_UNSUBSCRIBE_DATA_POINT } from '../constants/modals.constants';
import { closeModal } from './modals.module';

export const unsubscribeMachine = createAction(
  'unsubscribe machine from data point',
  (subscriptionId) => subscriptionId,
);

export const unsubscribeMachineSuccess = createAction(
  'unsubscribe machine from data point - success',
  (subscriptionId) => subscriptionId,
);

export const unsubscribeMachineFail = createAction(
  'unsubscribe machine from data point - fail',
  (subscriptionId, error) => ({ subscriptionId, error }),
);

export const reducer = {
  [unsubscribeMachine]: (state, subscriptionId) => {
    const subscription = getSubscriptionById(state, subscriptionId);
    if (!subscription) return state;

    const newSubscription = {
      ...subscription,
      _unsubscribe: {
        ...subscription._unsubscribe,
        loading: true,
        loaded: false,
        error: null,
      },
    };

    return {
      ...state,
      machine: {
        ...state.machine,
        dataPoints: {
          ...state.machine.dataPoints,
          list: replaceObjectInList(
            state.machine.dataPoints.list,
            newSubscription,
            compareById,
          ),
        },
      },
    };
  },
  [unsubscribeMachineSuccess]: (state, subscriptionId) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        list: removeObjectFromList(
          state.machine.dataPoints.list,
          { id: subscriptionId },
          compareById,
        ),
      },
    },
  }),
  [unsubscribeMachineFail]: (state, { subscriptionId, error }) => {
    const subscription = getSubscriptionById(state, subscriptionId);
    if (!subscription) return state;

    const newSubscription = {
      ...subscription,
      _unsubscribe: {
        ...subscription._unsubscribe,
        loading: false,
        loaded: false,
        error,
      },
    };

    return {
      ...state,
      machine: {
        ...state.machine,
        dataPoints: {
          ...state.machine.dataPoints,
          list: replaceObjectInList(
            state.machine.dataPoints.list,
            newSubscription,
            compareById,
          ),
        },
      },
    };
  },
};

export function* unsubscribeMachineSaga({ payload: subscriptionId }) {
  const state = yield select();
  const machine = yield call(getMachine, state);

  const {
    response,
    error,
  } = yield call(api.machines.deleteSubscription, machine.id, subscriptionId);

  if (response) {
    yield put(unsubscribeMachineSuccess(subscriptionId));
    yield put(closeModal(SMP_UNSUBSCRIBE_DATA_POINT));
  } else {
    yield put(unsubscribeMachineFail(subscriptionId, error));
  }
}

export function* watchUnsubscribeMachine() {
  yield takeEvery(unsubscribeMachine.getType(), unsubscribeMachineSaga);
}
