import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import EventCommentInput from './EventCommentInput';
import * as commentForm from '../../../../core/forms/eventComment.form.js';
import { submitComment } from '../../../../redux/modules/dashboard.event.comment.submit.module.js';
import { isCommentEmptyOrWhitespace, getCommentError } from '../../../../redux/selectors/dashboard.selector';
import { validateComment as validate } from '../../../../core/validation/comment.validation.js';

export function mapStateToProps(state, { eventId }) {
  return {
    form: commentForm.getFormId(eventId),
    validate,
    isCommentEmptyOrWhitespace: isCommentEmptyOrWhitespace(state, eventId),
    commentError: getCommentError(state, eventId),
  };
}

export const mapDispatchToProps = {
  submitComment,
};

export default (connect(mapStateToProps, mapDispatchToProps)(reduxForm()(EventCommentInput)));
