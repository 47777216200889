import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';

import * as form from '../constants/experiment.form.constants';
import { EXPERIMENT_ADD } from '../constants/modals.constants';
import { getFormFields } from '../selectors/experiment.form.selector';
import * as api from '../../core/api';
import { closeModal } from './modals.module';
import { fetchExperiments } from './experiments.fetch.module';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const createExperiment = createAction(
  'create experiment',
);

export const createExperimentSuccess = createAction(
  'create experiment - success',
  (experiment) => experiment,
);

export const createExperimentFail = createAction(
  'create experiment - fail',
  (error) => error,
);

export const reducer = {
  [createExperimentSuccess]: (state, experiment) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      experiments: {
        ...state.experimentsPage.experiments,
        list: [
          experiment,
          ...state.experimentsPage.experiments.list,
        ],
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.EXPERIMENT_FORM_NAME,
    ...form.EXPERIMENT_PROPS,
  ));
}

export function* handleErrors(errors) {
  yield put(createExperimentFail(errors));
  yield put(stopSubmit(form.EXPERIMENT_FORM_NAME, errors));
}

export function* createExperimentSaga() {
  yield call(touchAllFieldsSaga);

  const state = yield select();
  const fields = yield call(getFormFields, state);
  yield put(startSubmit(form.EXPERIMENT_FORM_NAME));

  const { response, error } = yield call(api.experiments.createExperiment, fields);

  if (response) {
    yield put(createExperimentSuccess(response));
    yield put(stopSubmit(form.EXPERIMENT_FORM_NAME));
    yield put(closeModal(EXPERIMENT_ADD));
    yield put(sendNotification({ ...notificationMessages.experiment_added, values: { name: response.name } }));
    yield put(fetchExperiments());
  } else {
    yield handleErrors(error);
  }
}

export function* watchCreateExperiment() {
  yield takeEvery(createExperiment.getType(), createExperimentSaga);
}
