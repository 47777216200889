import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function CategoryFilter({
  selected, handleChange, label, category,
}) {
  function handleFilterChange() {
    handleChange(category, selected);
  }

  return (
    <label className="checkbox">
      <input
        type="checkbox"
        onChange={handleFilterChange}
        checked={selected}
      />
      <FormattedMessage id={label} tagName="span" />
    </label>
  );
}

CategoryFilter.propTypes = {
  selected: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};
