import {
  apiRequest, postApiRequest, deleteApiRequest, putApiRequest,
} from './_tools';

export const fetch = () => apiRequest('/api/reports');
export const fetchOne = (reportId) => apiRequest(`/api/reports/${reportId}`);
export const clone = (reportId, name) => postApiRequest(`/api/reports/${reportId}/clone`, { name });
export const create = (body) => postApiRequest('/api/reports', body);
export const update = (id, body) => putApiRequest(`/api/reports/${id}`, body);
export const remove = (id) => deleteApiRequest(`/api/reports/${id}`);

export const getTabs = () => apiRequest('/api/reports_tabs');
export const createTab = (name) => postApiRequest('/api/reports_tabs', { name });
export const updateTab = (id, body) => putApiRequest(`/api/reports_tabs/${id}`, body);
export const removeTab = (id) => deleteApiRequest(`/api/reports_tabs/${id}`);
