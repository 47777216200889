import { connect } from 'react-redux';

import Select from './CustomSelect.container';
import * as selector from '../../../../redux/selectors/users.selector';

export const mapStateToProps = (state) => ({
  isLoading: selector.loading(state),
  options: selector
    .getUsers(state)
    .map((user) => ({
      value: user.id,
      label: user.name,
    })),
});

export default connect(mapStateToProps)(Select);
