import { connect } from 'react-redux';

import CustomSelectContainer from './CustomSelect.container';
import reportTypes, { kpi } from '../../../../../../common/enums/reportTypes';
import reportTypesMessages from '../../../../messages/reports.types.messages';
import { selectIsOeeHidden } from '../../../../redux/selectors/appConfig.selector';

export const mapStateToProps = (state) => {
  const isKpiReportAvailable = !selectIsOeeHidden(state);
  const types = isKpiReportAvailable ? reportTypes : reportTypes.filter((type) => type !== kpi);

  return {
    options: types.map((type) => ({
      label: reportTypesMessages[type],
      value: type,
    })),
    translateOptionsLabels: true,
  };
};

export default connect(mapStateToProps)(CustomSelectContainer);
