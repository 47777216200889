import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import Explorer from './Explorer';
import { fetchConfigRepoExplorerEntries } from '../../../../../redux/modules/configRepoExplorer.fetch.module';
import { selectEntries } from '../../../../../redux/modules/configRepoExplorer.select.module';
import { setActiveView } from '../../../../../redux/modules/configRepoExplorer.view.module';
import { resetConfigRepoExplorer } from '../../../../../redux/modules/configRepoExplorer.reset.module';
import {
  loading,
  getEquipments,
  getDatatrons,
  getDevices,
  getDataPointsWithSelectedFlag,
  getSelectedEquipment,
  getSelectedDatatron,
  getSelectedDevice,
  getSelectedDataPoints,
  getMachines,
  getSelectedMachine,
  getActiveView,
  allDataPointsSelected,
  getDataPointsFilters,
} from '../../../../../redux/selectors/configRepoExplorer.selector';

export const mapStateToProps = (state, { canChooseDataPoint }) => {
  const dataPointFilters = getDataPointsFilters(state).q;

  return {
    loading: loading(state),
    equipments: getEquipments(state),
    datatrons: getDatatrons(state),
    devices: getDevices(state),
    dataPoints: getDataPointsWithSelectedFlag(state, canChooseDataPoint),
    machines: getMachines(state),
    selectedEquipment: getSelectedEquipment(state),
    selectedDatatron: getSelectedDatatron(state),
    selectedDevice: getSelectedDevice(state),
    selectedDataPoints: getSelectedDataPoints(state),
    selectedMachine: getSelectedMachine(state),
    activeView: getActiveView(state),
    allDataPointsSelected: allDataPointsSelected(state),
    dataPointFiltersEmpty: isEmpty(dataPointFilters),
    highlightText: !isEmpty(dataPointFilters) ? getDataPointsFilters(state).q : '',
  };
};

export const mapDispatchToProps = {
  fetch: fetchConfigRepoExplorerEntries,
  select: selectEntries,
  setActiveView,
  reset: resetConfigRepoExplorer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Explorer);
