import moment from 'moment';
import {
  find, round, range, sortBy, forEach,
} from 'lodash';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';
import { SCHEDULE_CALENDAR, SHIFT_CALENDAR } from '../../../../common/enums/calendarTypes';
import { parseTime } from '../../../../common/helpers/calendar.helper';

export const filterEventsByDate = (events, fromDate, toDate) => {
  const fromDateMoment = moment(fromDate);
  const toDateMoment = moment(toDate);

  return events.reduce((final, current) => {
    const currentEventMoment = moment(current.startDateTime);

    if (currentEventMoment.isSameOrAfter(fromDateMoment)
      && currentEventMoment.isSameOrBefore(toDateMoment)
    ) {
      return [...final, current];
    }
    return final;
  }, []);
};

export const getEventDatesByDayAndHour = (day, hour = 0) => {
  const dayMoment = moment(`${day}T00:00:00.000Z`);

  const startDate = moment(dayMoment)
    .hour(hour);
  let endDate = moment(dayMoment)
    .hour(hour)
    .add(2, 'hour');

  if (!endDate.isSame(dayMoment, 'day')) {
    endDate = moment(dayMoment)
      .hour(23)
      .minute(59)
      .second(59);
  }

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};

export const initializeCalendar = (calendar) => ({
  ...calendar,
  events: {
    [DATE_FROM]: null,
    [DATE_TO]: null,
    list: [],
    loaded: false,
    loading: false,
    error: null,
  },
  _delete: {
    loaded: false,
    loading: false,
    error: null,
  },
});

export const initializeCalendarEvent = (
  calendarEvent,
  calendar,
  originalEvent = calendarEvent,
) => ({
  ...calendarEvent,
  calendarId: calendar.id,
  type: calendar.type,
  startDateTime: calendarEvent.startDateTime,
  endDateTime: calendarEvent.endDateTime,
  _update: {
    loaded: false,
    loading: false,
    error: null,
  },
  _delete: {
    loaded: false,
    loading: false,
    error: null,
  },
  _originalEvent: {
    ...originalEvent,
  },
});

export const initializeCalendarEventsFromAvailability = (availability, events, calendar) => {
  const eventToId = events.reduce((result, event) => ({
    ...result,
    [event.id]: event,
  }), {});

  return availability.map((item) => initializeCalendarEvent(
    {
      ...eventToId[item.eventId],
      ...item,
    },
    calendar,
    eventToId[item.eventId],
  ));
};

export const getDefaultCalendarEventsDates = () => ({
  fromDate: moment().startOf('week').toISOString(),
  toDate: moment().endOf('week').toISOString(),
});

export const initializeCalendarAsOption = (calendar) => ({
  value: calendar.id,
  label: calendar.name,
});

export const isCalendarSetupRequired = (machineCalendars = []) => !find(machineCalendars, { type: SCHEDULE_CALENDAR })
  || !find(machineCalendars, { type: SHIFT_CALENDAR });

export const canRemoveCalendar = (calendar) => calendar && (!calendar.machines || !calendar.machines.length);

export const TITLE_COLUMN_WIDTH = 65;

export const getWidthPerSecond = (tableWidth) => (
  (tableWidth - TITLE_COLUMN_WIDTH)
  / 24) / 3600;

export const getTimeByWidth = (x, tableWidth) => {
  const timeScaleWidth = tableWidth - TITLE_COLUMN_WIDTH;
  const timeScaleX = x - TITLE_COLUMN_WIDTH;
  const time = round((timeScaleX * 24) / timeScaleWidth);

  return time > 0 ? time : 0;
};

export const convertEventsByWeekday = (allEvents, fromDate) => {
  const today = moment();
  const groups = [];

  forEach(
    range(0, 7),
    (weekday) => {
      const day = moment(fromDate).weekday(weekday);
      const events = filterEventsByDate(
        allEvents,
        moment(day).startOf('day'),
        moment(day).endOf('day'),
      );

      const sortedEvents = sortBy(events, ['startDateTime']);

      groups.push({
        events: sortedEvents,
        title: day.format('ddd DD'),
        day: day.format('YYYY-MM-DD'),
        isToday: day.isSame(today, 'day'),
      });
    },
  );

  return groups;
};

export const getCalendarEventProps = (event, widthPerSecond) => {
  const start = moment(event.startDateTime);
  const end = moment(event.endDateTime);

  const duration = end.unix() - start.unix();
  const width = (duration * widthPerSecond);
  const offsetDuration = start.unix() - moment(start).startOf('day').unix();
  const offset = (offsetDuration * widthPerSecond);

  return {
    width,
    left: offset,
  };
};

export const getMinimumEventWidth = (tableWidth) => {
  const timeScaleWidth = tableWidth - TITLE_COLUMN_WIDTH;

  return timeScaleWidth / 24;
};

export const anyCalendarLoading = (calendars) => calendars.reduce((result, calendar) => (result || calendar.events.loading), false);

export const getEventDate = (day, time = '00:00') => {
  const dayMoment = moment(`${day}T00:00:00.000Z`);
  const date = moment(dayMoment).set(parseTime(time));

  return date.toISOString();
};
