import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import typeMessages from '../../../../messages/configRepoExplorer.message';

export const LeftBarItem = ({
  title, type, isSelected, isActive, isPassive, translateTitle, onClick, intl,
}) => {
  const onItemSelect = () => onClick(type);

  const style = classnames(
    'locations-result',
    { selected: isSelected },
    { active: isActive },
    { passive: isPassive },
  );
  const typeLabel = intl.formatMessage(typeMessages[type]);
  const placeholder = intl.formatMessage(typeMessages.select);
  const cleanTitle = translateTitle ? intl.formatMessage(title) : title;

  return (
    <div className={style} onClick={onItemSelect}>
      <label>{ typeLabel }</label>
      <div>{ !isPassive ? cleanTitle : placeholder }</div>
      { !isPassive && (
        <>
          <span className="fa fa-check-circle" />
          <span className="fa fa-times-circle" />
        </>
      ) }
    </div>
  );
};

LeftBarItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isPassive: PropTypes.bool,
  translateTitle: PropTypes.bool,
  onClick: PropTypes.func,

};

LeftBarItem.defaultProps = {
  title: '',
  isPassive: false,
  translateTitle: false,
};

export default injectIntl(LeftBarItem);
