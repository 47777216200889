import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '../../../_tools/button/Button';

export const MachineForm = ({
  onCancelClick,
  submitting,
  pristine,
  isAddForm,
  isUpdateForm,
  cancelLink,
}) => (
  <div className="central-form">
    <div className="form--left">&nbsp;</div>
    <div className="form--right">
      <div className="text-center">
        <Link
          type="button"
          className="button__cancel"
          onClick={onCancelClick}
          to={cancelLink}
        >
          <FormattedMessage
            id="common.cancel"
            defaultMessage="Cancel"
          />
        </Link>
        <Button
          type="submit"
          className="button__save"
          disabled={submitting || pristine}
          showLoader={submitting}
        >
          { isAddForm && (
            <FormattedMessage
              id="common.add"
              defaultMessage="Add"
            />
          )}
          { isUpdateForm && (
            <FormattedMessage
              id="common.update"
              defaultMessage="Update"
            />
          )}
        </Button>
      </div>
    </div>
  </div>
);

MachineForm.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  cancelLink: PropTypes.string.isRequired,
  isAddForm: PropTypes.bool,
  isUpdateForm: PropTypes.bool,
};

export default MachineForm;
