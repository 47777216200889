import * as reportTypes from '../../../../common/enums/reportTypes';
import { selectIsOeeHidden } from './appConfig.selector';
import { createSelector } from '../util';
import { ReportT } from '../../../types/report';

const EMPTY_SELECTION = [];

export const selectAllReports = createSelector((state) => {
  const list = state.reports.data;
  const oeeReportsHidden = selectIsOeeHidden(state);
  if (!oeeReportsHidden) return list;
  return list.filter((item) => item.type !== reportTypes.kpi);
});

export const selectReportsOfSelectedTab = createSelector((state) => {
  const selectedTabId = state.reports.tabs.selectedTabId;
  if (!selectedTabId) return [];
  return selectAllReports(state).filter((report) => report.reportsTab && report.reportsTab.id === selectedTabId);
});

export const selectLoading = createSelector((state) => state.reports.loading);
export const selectHasNoReports = createSelector((state) => !state.reports.data || !state.reports.data.length);
export const selectHasNoReportsInSelectedTab = createSelector((state) => selectHasNoReports(state) || !selectReportsOfSelectedTab(state).length);
export const selectAllSelections = createSelector((state) => state.reports.sliderSelection);
export const selectAllChartTypes = createSelector((state) => state.reports.chartTypes);

export const selectSelectionByReportId = createSelector((state, reportId: ReportT['id']) => {
  const selections = selectAllSelections(state);
  return selections[reportId] ?? EMPTY_SELECTION;
});

export const selectReportIndexById = createSelector((state, reportId: ReportT['id']) => selectAllReports(state).findIndex(({ id }) => id === reportId));
export const selectReportByIndex = createSelector((state, index: number) => selectAllReports(state)[index]);

export const getReportById = ( reportId: ReportT['id'], reports: ReportT[] = []) => reports.find(({ id }) => id === reportId);

export const isReportOutputEmpty = (report: ReportT) => {
  if (report.data.chartVersion === 'v1') {
    return report.data.chart !== undefined && report.data.chart.length === 0;
  }
  if (report.data.chartVersion === 'v2') {
    return report.data.chartV2 !== undefined && report.data.chartV2.length === 0;
  }
  return false;
};

export const isLeftAxisInPercent = (report: ReportT) => report.type === reportTypes.kpi;

export const isTabsBarVisible = (report: ReportT) => report.type !== reportTypes.dataPoints;
