import { useDispatch } from 'react-redux';

import Legend from './Legend';
import { openModal } from '../../../../../redux/modules/modals.module';
import { toggleIndicator } from '../../../../../redux/modules/report.indicators.module';
import { removeLegendDataPoint } from '../../../../../redux/modules/report.legend.dataPoint.remove.module';
import { REPORT_LEGEND_DATA_POINT } from '../../../../../redux/constants/modals.constants';
import * as axis from '../../../../../../../common/constants/report.axis';
import { Todo } from '../../../../../../../common/types/common';
import React, { useCallback } from 'react';
import { ReportT } from '../../../../../../types/report';

interface Props {
  dataPoints: Todo[];
  reportId: ReportT['id'];
  inactiveEntriesIds: Todo[];
}

export const LegendContainer: React.FC<Props> = ({
  dataPoints,
  reportId,
  inactiveEntriesIds,
}) => {
  const leftDataPoints = dataPoints.filter((item) => item.axis === axis.Y_LEFT_AXIS);
  const rightDataPoints = dataPoints.filter((item) => item.axis === axis.Y_RIGHT_AXIS);
  const dispatch = useDispatch();
  const onLegendClick = useCallback(
    (filter) => dispatch(openModal(REPORT_LEGEND_DATA_POINT, { reportId, filter })),
    [dispatch, reportId]);
  const toggle = useCallback(
    (filter) => dispatch(toggleIndicator(reportId, filter.dataPoint.uuid)),
    [dispatch, reportId],
  );
  const remove = useCallback(
    (filter) => dispatch(removeLegendDataPoint(reportId, filter.dataPoint.id)),
    [dispatch, reportId],
  );
  return (
    <Legend
      remove={remove}
      onLegendClick={onLegendClick}
      leftDataPoints={leftDataPoints}
      rightDataPoints={rightDataPoints}
      inactiveEntriesIds={inactiveEntriesIds}
      toggle={toggle}
    />
  );
};

export default LegendContainer;
