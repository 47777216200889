import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CalendarModuleContainer from './CalendarModule.container';
import { getValuesFromCalendar } from '../../../../core/common/calendar.form';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { CP_CALENDAR_EDIT } from '../../../../redux/constants/modals.constants';
import { updateCalendar } from '../../../../redux/modules/calendars.update.module';
import { closeModal } from '../../../../redux/modules/modals.module';

export const mapStateToProps = (state) => {
  const { calendar } = getModalPayload(state, CP_CALENDAR_EDIT);

  return {
    isEditForm: true,
    loaded: true,
    calendarId: calendar.id,
    initialValues: getValuesFromCalendar(calendar),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(CP_CALENDAR_EDIT)),
  updateCalendar: bindActionCreators(updateCalendar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarModuleContainer);
