import { connect } from 'react-redux';
import moment from 'moment';

import DatesWidget from './DatesWidget';
import { getFromDate } from '../../../../redux/selectors/calendar.selector';

export function mapStateToProps(state, { calendar }) {
  return {
    fromDate: getFromDate(calendar) || moment().startOf('week').toISOString(),
  };
}

export default connect(mapStateToProps)(DatesWidget);
