import { defineMessages } from 'react-intl';

export default defineMessages({
  removeModalContent: {
    id: 'report.modal.remove_report_title',
    defaultMessage: 'Do you really want to remove this report?',
  },
  duplicateModalTitle: {
    id: 'report.modal.duplicate_report',
    defaultMessage: 'Duplicate Report',
  },
  untitledTab: {
    id: 'report.untitled_tab',
    defaultMessage: 'Untitled Tab',
  },
});
