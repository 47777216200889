import React from 'react';
import { FormattedMessage } from 'react-intl';

interface ShowMessageProps {
  count: number;
  errorMessage: string;
  warningMessage: string;
}

export const ShowMessage: React.FC<ShowMessageProps> = ({
  count,
  errorMessage,
  warningMessage,
}) => (
  <>
    <div className='success-file'>
      {!errorMessage && (
        <p>
          {count} <FormattedMessage id='datatron.modals.files_selected' />
        </p>
      )}
    </div>
    {errorMessage && <div className='error-message'>{errorMessage}</div>}
    {warningMessage && <div className='warning-message'>{warningMessage}</div>}
  </>
);
