import { AppState } from '../state/app.state';

export const getAiApps = (state: AppState) => state.aiApps.list;

export const getAiAppsPagination = (state: AppState) => state.aiApps.pagination;

export const getPage = (state): number => getAiAppsPagination(state).page;

export const isLoading = (state): boolean => state.aiApps.loading;

export const isLoaded = (state): boolean => state.aiApps.loaded;

export const getFilters = (state: AppState) => state.aiApps.filters;

export const getSort = (state: AppState) => state.aiApps.sort;

export const getQueryFilterValue = (state): string => getFilters(state).queryString;

export const getAiAppsDetails = (state: AppState) => state.aiApps.details;

export const getCycleDetectionDetails = (state: AppState) => state.aiApps.cycleDetectionDetails;

export const getAiAppsDetailsId = (state: AppState) => state.aiApps.details?.id;

export const getCycleTimeDetectionCandidates = (state: AppState) =>
  state.aiApps.cycleDetectionDetails?.candidates;

export const getSelectedDevices = (state: AppState) => state.aiApps.details?.devices;

export const getSelectedDatatron = (state: AppState) => state.aiApps.details?.datatron;

export const filtersApplied = (state: AppState): boolean => {
  const filters = getFilters(state);

  return filters.queryString.length > 0;
};
