import {
  apiRequest,
  putApiRequest,
  postApiRequest,
} from './_tools';

export const getUser = () => apiRequest('/api/users/me');

export const updateUser = (payload) => putApiRequest('/api/users/me', payload);

export function getLoginHistory() {
  return apiRequest('/api/users/me/login-history');
}

export function updateInfo(accountCode, userCode, info) {
  return putApiRequest(`/api/accounts/${accountCode}/users/${userCode}`, info);
}

export function changeLocation(locationId) {
  return postApiRequest('/api/user/location', { locationId });
}

export function changePassword(passwords) {
  return postApiRequest('/api/user/password', passwords);
}
