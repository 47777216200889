import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Member from './Member';

function Organization({ members, loadingState }) {
  if (loadingState === true) {
    return null;
  }

  return (
    <div className="col--in height6">

      <div className="col--title">
        <FormattedMessage
          id="location.tabs.team"
          defaultMessage="Team"
        />
      </div>

      <div className="table--title">
        <i className="icon icon-person" />
        <FormattedMessage
          id="location.members"
          defaultMessage="Members ({count})"
          values={{
            count: members.length,
          }}
        />
      </div>
      <div className="scroll-block without-scroll">
        <table className="table table-scroll members-list">
          <thead>
            <tr>
              <td className="td-icon" />
              <td>
                <FormattedMessage
                  id="teams.name"
                  defaultMessage="Name"
                />
              </td>
              <td>
                <FormattedMessage
                  id="teams.position"
                  defaultMessage="Position"
                />
              </td>
              <td>
                <FormattedMessage
                  id="teams.email"
                  defaultMessage="Email"
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {
              members.map((member) => <Member key={member.id} member={member} />)
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

Organization.propTypes = {
  members: PropTypes.array.isRequired,
  loadingState: PropTypes.bool.isRequired,
};

export default Organization;
