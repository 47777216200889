import React, { useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { Field } from 'redux-form';
import { Todo } from '../../../../../common/types/common';
import { getTranslationObject } from '../../../core/common/intl';
import InputWithError from '../inputWithError/InputWithError';
import { TextAreaWithError } from '../inputWithError/TextAreaWithError';

type Props = {
  label: MessageDescriptor | string;
  name: string;
  placeholder: MessageDescriptor | string;
  canGenerateUUID?: boolean;
  component?: string;
} & Todo;

export const FormField: React.FC<Props> = ({
  label,
  name,
  placeholder = '',
  component,
  canGenerateUUID = false,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const translatedTitle = label
    ? formatMessage(getTranslationObject(label))
    : '';
  const translatedPlaceholder = placeholder
    ? formatMessage(getTranslationObject(placeholder))
    : '';

  const componentProps = component ? {} : { canGenerateUUID };

  const InnerComponent = useMemo(() => {
    if (component === 'textarea') return TextAreaWithError;
    return InputWithError;
  }, [component]);

  return (
    <label>
      {translatedTitle}
      <Field
        name={name}
        component={InnerComponent}
        type="text"
        placeholder={translatedPlaceholder}
        {...componentProps}
        {...props}
      />
    </label>
  );
};

export default FormField;
