import React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import { injectIntl } from 'react-intl';

import UnitSelectContainer from './UnitSelect.container';
import LiveTimestamp from './LiveTimestamp';
import { relativeDatePropType } from '../../../../../../core/propTypes';

export const RelativeInput = ({
  label, value, change, intl,
}) => {
  const handleNumberChange = (event) => {
    if (!event) return;
    const numberValue = parseInt(event.target.value);
    change({
      ...value,
      count: isNumber(numberValue) && !isNaN(numberValue) ? numberValue : 0,
    });
  };

  const handleUnitChange = (newUnit) => {
    change({
      ...value,
      unit: newUnit,
    });
  };

  const labelTranslated = `${intl.formatMessage(label)}:`;

  return (
    <div className="datepicker-wrap">
      <div className="datepicker-dropdowns--top">
        { labelTranslated }
      </div>

      <LiveTimestamp relativeOffset={value} />

      <div className="relative-date-fields">
        <input type="number" min={0} max={999} value={value.count} onChange={handleNumberChange} />
        <UnitSelectContainer change={handleUnitChange} value={value.unit} />
      </div>
    </div>
  );
};

RelativeInput.propTypes = {
  label: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  value: relativeDatePropType,

};

export default injectIntl(RelativeInput);
