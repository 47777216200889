import React from 'react';

import Modal from '../../_tools/modal/Modal';
import { TodoFunction } from '../../../../../common/types/common';

interface Props {
  close: TodoFunction;
  children: React.ReactNode;
  isOpen?: boolean;
  modalClass?: string;
}

const BasicModal: React.FC<Props> = ({ isOpen = true, close, children, modalClass = '' }) => (
  <Modal isOpen={isOpen} onClose={close} contentLabel='' modalClass={modalClass}>
    <div className='modal--header' onClick={close}>
      <span className='modal--close'>&times;</span>
    </div>

    <div className='basic-modal--content'>{children}</div>
  </Modal>
);

export default BasicModal;
