import { connect } from 'react-redux';

import Search from './Search';
import { getDataPointsFilters } from '../../../../../../redux/selectors/configRepoExplorer.selector';
import { sortDataPoints } from '../../../../../../redux/modules/configRepoExplorer.sort.filter.module';

export const mapStateToProps = (state) => {
  const sort = getDataPointsFilters(state).sort;
  return {
    sortOption: sort.option,
    sortWay: sort.way,
  };
};

export const mapDispatchToProps = {
  onSortChange: sortDataPoints,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
