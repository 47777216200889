import React from 'react';
import { FormattedMessage } from 'react-intl';

import commonMessages from '../../../../../../messages/common.messages';
import commonCoreMessages from '../../../../../../messages/common.core.messages';

export const TableHeader = () => (
  <thead>
    <tr>
      <FormattedMessage {...commonCoreMessages.activity} tagName="td" />
      <FormattedMessage {...commonCoreMessages.machine} tagName="td" />
      <FormattedMessage {...commonMessages.duration} tagName="td" />
      <FormattedMessage {...commonMessages.timestamp} tagName="td" />
    </tr>
  </thead>
);

export default TableHeader;
