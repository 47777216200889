import moment from 'moment';
import pkg from 'lodash';
const { forEach } = pkg;

import { DATE_TIME_RFC2445_FORMAT } from '../constants/index';

export const createRecurrenceRule = ({ repeatType, repeatEnd }) => {
  const rules = [];

  if (repeatType) {
    rules.push(`FREQ=${repeatType}`);

    if (repeatEnd) {
      rules.push(`UNTIL=${moment(repeatEnd).utc().format(DATE_TIME_RFC2445_FORMAT)}Z`);
    }
  }

  return rules.join(';');
};

export const isRecurrentEvent = (calendarEvent) => !!(calendarEvent.recurrencePattern && calendarEvent.recurrencePattern.length);

export const parseRecurrencePattern = (recurrencePattern = '') => {
  const result = {
    repeatType: null,
    repeatEnd: null,
  };

  const rules = recurrencePattern.split(';');
  forEach(rules, (rule) => {
    const [key, value] = rule.split('=');
    switch (key) {
      case 'FREQ': {
        result.repeatType = value;
        break;
      }
      case 'UNTIL': {
        result.repeatEnd = moment(value, DATE_TIME_RFC2445_FORMAT).toISOString();
        break;
      }
      default: break;
    }
  });

  return result;
};

export const isTimeValid = (value) => {
  const splitTime = value.split(':');
  return (
    splitTime.length === 2
    && splitTime[0].length === 2
    && splitTime[1].length === 2
    && Number.isInteger(Number(splitTime[0]))
    && Number.isInteger(Number(splitTime[1]))
    && Number(splitTime[0]) >= 0
    && Number(splitTime[0]) <= 23
    && Number(splitTime[1]) >= 0
    && Number(splitTime[1]) <= 59
  );
};
export const parseTime = (timeString) => {
  const splitTime = timeString.split(':');
  return {
    hour: Number(splitTime[0]),
    minute: Number(splitTime[1]),
  };
};
export const createTimeFromMoment = (timeMoment) => timeMoment.format('HH:mm');
export const createMomentFromTime = (time, dateTime = moment()) => moment(dateTime).set(parseTime(time));

export const isTimeRangeValid = (timeOne, timeTwo) => createMomentFromTime(timeOne).isBefore(createMomentFromTime(timeTwo));

export const getNewTime = ({
  oldStartTime,
  oldEndTime,
  newStartTime = null,
  newEndTime = null,
}) => {
  let startTimeMoment = createMomentFromTime(oldStartTime);
  let endTimeMoment = createMomentFromTime(oldEndTime);

  if (newStartTime) {
    const newStartTimeMoment = createMomentFromTime(newStartTime);

    if (newStartTimeMoment.isAfter(endTimeMoment)) {
      const difference = newStartTimeMoment.diff(startTimeMoment);
      endTimeMoment.add(difference);
      if (!endTimeMoment.isSame(startTimeMoment, 'day')) {
        endTimeMoment = moment(endTimeMoment)
          .hour(23)
          .minute(59)
          .second(59)
          .millisecond(999);
      }
    }

    startTimeMoment = newStartTimeMoment;
  } else if (newEndTime) {
    const newEndTimeMoment = createMomentFromTime(newEndTime);

    if (newEndTimeMoment.isBefore(startTimeMoment)) {
      const difference = endTimeMoment.diff(newEndTimeMoment);
      startTimeMoment.subtract(difference);

      if (!startTimeMoment.isSame(endTimeMoment, 'day')) {
        startTimeMoment = moment(endTimeMoment).hour(0).minute(0);
      }
    }

    endTimeMoment = newEndTimeMoment;
  }

  return [
    createTimeFromMoment(startTimeMoment),
    createTimeFromMoment(endTimeMoment),
  ];
};
