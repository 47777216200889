import querystring from 'querystring';

import { apiRequest } from './_tools';
import { prepareFiltersForAPI } from '../common/filters';

export function getDashboardOee(filters) {
  const query = querystring.stringify(prepareFiltersForAPI(filters));

  return apiRequest(`/api/oee/common?${query}`);
}
