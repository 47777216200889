import { useEffect, useRef } from 'react';


/**
 * Replaces UNSAFE_componentWillReceiveProps for functional components.
 * There is probably a better way to replace it, but this is a quick fix.
 *
 * @param {Props} nextProps The current props
 * @param {function} effect A function to be executed with the previous props.
 */
export const useComponentWillReceiveProps = (nextProps, effect) => {
  const props = useRef(nextProps);

  // TODO: fix me, avoid suppressing the linter
  useEffect(() => {
    effect(props.current);
    props.current = nextProps;
  }, [effect]); // eslint-disable-line react-hooks/exhaustive-deps
};