import { createAction } from '@reduxjs/toolkit';
import {
  all, put, call, select, takeEvery,
} from 'redux-saga/effects';

import { initializeDevice } from '../../core/common/datatron';
import { getDatatron } from '../selectors/datatron.selector';
import { fetchDataPoints } from './datatron.devices.dataPoints.fetch.module';
import { getDevices } from '../../core/api/datatrons';

export const fetchDevices = createAction(
  'fetch datatron devices',
);

export const fetchDevicesSuccess = createAction(
  'fetch datatron devices - success',
  (devices) => ({ payload: devices }),
);

export const fetchDevicesFail = createAction(
  'fetch datatron devices - fail',
  (error) => ({ payload: error }),
);

export const reducer = {
  [fetchDevices.type]: (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      devices: {
        ...state.datatron.devices,
        list: [],
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),

  [fetchDevicesSuccess.type]: (state, devices) => ({
    ...state,
    datatron: {
      ...state.datatron,
      devices: {
        ...state.datatron.devices,
        list: devices,
        loading: false,
        loaded: true,
        error: null,
      },
    },
  }),

  [fetchDevicesFail.type]: (state, error) => ({
    ...state,
    datatron: {
      ...state.datatron,
      devices: {
        ...state.datatron.devices,
        list: [],
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* fetchDevicesSaga() {
  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const { response, error } = yield call(getDevices, datatron.id);

  if (response) {
    yield put(fetchDevicesSuccess(response.map(initializeDevice)));
    yield all(response.map((item) => put(fetchDataPoints(item.id))));
    yield all(response.map((item) => put(fetchDataPoints(item.id, true))));
  } else {
    yield put(fetchDevicesFail(error));
  }
}

export function* watchFetchDevices() {
  yield takeEvery(fetchDevices, fetchDevicesSaga);
}
