import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { getLocationLink } from '../../../index/routes';
import EventComments from './EventComments';
import stopPropagation from '../../../_tools/stopPropagation.js';

function EventDetails({ event }) {
  const location = event.machine.location;
  const organization = event.machine.organization;

  return (
    <div
      className="position--bottom"
      onClick={stopPropagation}
    >
      <div className="position-recent">
        <div className="infos">
          <div className="infos--left">
            <button className="button button__xs button-block white hide">
              <i className="icon-linked" />
              <FormattedMessage
                id="common.copy_link"
                defaultMessage="Copy Link"
              />
            </button>
            <div className="line" />
            <Link
              to={getLocationLink(location.id)}
              className="links"
            >
              <span className="icon icon-location" />
              <span className="links-text">{ location.name }</span>
            </Link>
            <p>
              { organization.name }
            </p>
          </div>
        </div>

      </div>

      <div className="position-text">
        <EventComments
          comments={event.comments}
          eventId={event.id}
        />
      </div>
    </div>
  );
}

EventDetails.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventDetails;
