import { createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { isEmpty } from 'lodash';

import * as api from '../../core/api';
import { getDatatron, getDeviceById, getDeviceTypeById } from '../selectors/datatron.selector';
import { compareById, replaceObjectInList } from '../../../../common/helpers/object';
import { initializeDataPoint } from '../../core/common/dataPoint';
import { convertErrors } from '../../core/common/dataPoint.config';
import notificationMessages from '../../messages/notification.message';
import * as notification from '../../core/notifications';
import { DP_ADD_DATA_POINT, DP_EDIT_DATA_POINT } from '../constants/modals.constants';
import {
  DATA_POINT_FORM_NAME,
  ENABLED_FIELD,
  LABEL_FIELD,
  SCALING_FACTOR_FIELD,
  SCALING_OFFSET_FIELD,
} from '../constants/dataPoint.form.constants';
import { highlightDataPoint } from './datatron.devices.dataPoints.highlight.module';
import { closeModal } from './modals.module';
import { sendNotification } from './notifications.module';
import { createConfigKeyHashToFieldsMap } from '../../core/common/datatron.deviceType';

export const createDataPoint = createAction(
  'create data point (of datatron device)',
  (deviceId, newConfig) => ({ payload: { deviceId, newConfig } }),
);

export const createChildDataPoint = createAction(
  'create child data point (of datatron device)',
  (deviceId, newConfig) => ({ payload: { deviceId, newConfig } }),
);

export const createDataPointSuccess = createAction(
  'create data point (of datatron device) - success',
  (deviceId, newDataPoint, configKeyHashToFieldsMap) => ({ payload: { deviceId, newDataPoint, configKeyHashToFieldsMap } }),
);

export const createDataPointFail = createAction(
  'create data point (of datatron device) - fail',
  (deviceId, error) => ({ payload: { deviceId, error } }),
);

export const clearNewDataPointError = createAction(
  'clear the data point error field',
);

export const reducer = {
  [createDataPoint.type]: state => ({
    ...state,
    datatron: {
      ...state.datatron,
      newDataPoint: {
        ...state.datatron.newDataPoint,
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),

  [createDataPointSuccess.type]: (state, { deviceId, newDataPoint, configKeyHashToFieldsMap }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const newDevice = {
      ...device,
      configKeyHashToFieldsMap: {
        ...device.configKeyHashToFieldsMap,
        ...configKeyHashToFieldsMap,
      },
      dataPoints: {
        ...device.dataPoints,
        list: [
          newDataPoint,
          ...device.dataPoints.list,
        ],
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById,
          ),
        },
        newDataPoint: {
          ...state.datatron.newDataPoint,
          loading: false,
          loaded: true,
          error: null,
        },
      },
    };
  },

  [createDataPointFail.type]: (state, { error }) => ({
    ...state,
    datatron: {
      ...state.datatron,
      newDataPoint: {
        ...state.datatron.newDataPoint,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [clearNewDataPointError.type]: (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      newDataPoint: {
        ...state.datatron.newDataPoint,
        error: null,
      },
    },
  }),
};

export function* touchAllFields(fieldNames = []) {
  yield put(touch(
    DATA_POINT_FORM_NAME,
    ENABLED_FIELD,
    LABEL_FIELD,
    SCALING_OFFSET_FIELD,
    SCALING_FACTOR_FIELD,
    ...fieldNames,
  ));
}

export function* createDataPointSaga({ payload: { deviceId, newConfig } }) {
  yield put(startSubmit(DATA_POINT_FORM_NAME));

  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const { response, error } = yield call(
    api.datatrons.createDataPoint,
    {
      datatronId: datatron.id,
      deviceId,
      payload: newConfig,
    },
  );

  if (response) {
    const dataPoint = initializeDataPoint(response);
    const device = yield call(getDeviceById, state, deviceId);
    const deviceType = yield call(getDeviceTypeById, state, device.deviceType.id);
    const configKeyHashToFieldsMap = yield call(createConfigKeyHashToFieldsMap, [dataPoint], deviceType.dataPointFields, {});

    yield put(createDataPointSuccess(deviceId, dataPoint, configKeyHashToFieldsMap));
    yield put(stopSubmit(DATA_POINT_FORM_NAME));
    yield put(closeModal(DP_ADD_DATA_POINT));
    yield put(highlightDataPoint(dataPoint.id));
  } else {
    const errorText = (error.config && error._details) ? error._details : error;
    const errorsObject = convertErrors(error);
    yield put(createDataPointFail(deviceId, errorText));
    yield put(stopSubmit(DATA_POINT_FORM_NAME, errorsObject));
    if (isEmpty(errorsObject)) {
      yield put(sendNotification({
        ...notificationMessages.something_happened,
        values: { error: errorText },
      }, null, notification.NOTIFICATION_ERROR));
    }
  }
}

export function* watchCreateDataPoint() {
  yield takeEvery(createDataPoint, createDataPointSaga);
}

export function* createChildDataPointSaga({ payload: { deviceId, newConfig } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const { response, error } = yield call(
    api.datatrons.createDataPoint,
    {
      datatronId: datatron.id,
      deviceId,
      payload: newConfig,
    },
  );

  if (response) {
    const dataPoint = initializeDataPoint(response);
    const device = yield call(getDeviceById, state, deviceId);
    const deviceType = yield call(getDeviceTypeById, state, device.deviceType.id);
    const configKeyHashToFieldsMap = yield call(createConfigKeyHashToFieldsMap, [dataPoint], deviceType.dataPointFields, {});

    yield put(createDataPointSuccess(deviceId, dataPoint, configKeyHashToFieldsMap));
    yield put(closeModal(DP_ADD_DATA_POINT));
    yield put(closeModal(DP_EDIT_DATA_POINT));
    yield put(highlightDataPoint(dataPoint.id));
  } else {
    const errorText = (error.config && error._details) ? error._details : error;
    const errorsObject = convertErrors(error);
    yield put(createDataPointFail(deviceId, errorText));
    if (isEmpty(errorsObject)) {
      yield put(sendNotification({
        ...notificationMessages.something_happened,
        values: { error: errorText },
      }, null, notification.NOTIFICATION_ERROR));
    }
  }
}

export function* watchCreateChildDataPoint() {
  yield takeEvery(createChildDataPoint, createChildDataPointSaga);
}