import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOCATIONS,
  ORGANIZATIONS,
} from '../../../../common/constants/entities';
import { DATATRONS_PERMISSIONS } from '../../../../common/constants/permissions';
import { getValuesFromDatatron } from '../../core/common/datatron.form';
import { search } from '../../redux/modules/search.module';
import {
  getDatatron,
  selectIsLoaded,
} from '../../redux/selectors/datatron.selector';
import { usePermissionInstance } from '../common/permissions/usePermissionInstance';
import { getDatatronLink, getInventoryLink } from '../index/routes';
import Unauthorized from '../pages/unauthorized/Unauthorized';
import LoadingInProgress from '../_tools/labelsWrapper/labels/LoadingInProgress';
import DatatronFormPage from './DatatronFormPage';

export const UpdateDatatronFormPage: React.FC = () => {
  const permissions = usePermissionInstance(DATATRONS_PERMISSIONS);
  const loaded = useSelector(selectIsLoaded);
  const canUpdateDatatrons = permissions.canUpdateDatatrons();

  const datatron = useSelector(getDatatron);

  const dispatch = useDispatch();

  const initializeSearchableFields = useCallback(() => {
    dispatch(search('', true, [LOCATIONS]));
    dispatch(search('', true, [ORGANIZATIONS]));
  }, [dispatch]);

  if (Object.keys(datatron).length <= 0) {
    return <LoadingInProgress />;
  }

  return canUpdateDatatrons ? (
    <DatatronFormPage
      backLink={loaded ? getDatatronLink(datatron.id) : getInventoryLink()}
      formType={'edit'}
      initialValues={getValuesFromDatatron(datatron)}
      detailsFormProps={{
        photoUrl: datatron.imagePath,
        location: datatron.location,
      }}
      initializeSearchableFields={initializeSearchableFields}
      datatron={datatron}
    />
  ) : (
    <Unauthorized />
  );
};

export default UpdateDatatronFormPage;
