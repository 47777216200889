import { connect } from 'react-redux';

import { getInventoryLink } from '../index/routes';
import MachineFormPage from './MachineFormPage';
import PermissionedComponent from '../common/permissions/PermissionedComponent.container';
import { MACHINES_PERMISSIONS } from '../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => ({
  backLink: getInventoryLink(),
  isAddForm: true,
  canCreateMachines: permissions.canCreateMachines(),
});

export default PermissionedComponent(MACHINES_PERMISSIONS)(
  connect(mapStateToProps)(MachineFormPage),
);
