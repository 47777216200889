import React from 'react';
import PropTypes from 'prop-types';

export const ListItem = ({ onClick, title }) => (
  <>
    <button className="btn-link" type="button" onClick={onClick}>
      <i className="icon-close" />
    </button>
    <span className="search-alls-text pointer" onClick={onClick}>{ title }</span>
  </>
);

ListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ListItem;
