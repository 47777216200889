import { connect } from 'react-redux';

import TableQuerySearchState from '../../../../../common/tableQuerySearch/TableQuerySearchState';
import { getNameFilterValue } from '../../../../../../redux/selectors/admin.organizations.selector';
import { adminFilterOrganizationsByName } from '../../../../../../redux/modules/admin.organizations.sort.filter.module';
import { AP_ORGANIZATIONS_NAME_FILTER_INPUT } from '../../../../../../redux/constants/components.constants';

export const mapStateToProps = (state) => ({
  value: getNameFilterValue(state),
  stateComponentId: AP_ORGANIZATIONS_NAME_FILTER_INPUT,
});

export const mapDispatchToProps = {
  onQueryChange: adminFilterOrganizationsByName,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableQuerySearchState);
