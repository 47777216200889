import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BasicModal from '../../../modals/core/BasicModal';
import commonCoreMessages from '../../../../messages/common.core.messages';
import adminPageMessages from '../../../../messages/adminPage.messages';
import CalendarFormBody from './form/CalendarFormBody';
import AddModalFooter from '../../../modals/core/footers/AddModalFooter';
import EditModalFooter from '../../../modals/core/footers/EditModalFooter';

export const CalendarModule = ({
  intl,
  isAddForm,
  isEditForm,
  loaded,
  createCalendar,
  updateCalendar,
  calendarId,
  pristine,
  submitting,
  close,
}) => {
  if (!loaded) return null;
  const handleCancel = () => close();

  let handleSubmit = null;
  let modalTitle = null;
  const object = intl.formatMessage(commonCoreMessages.calendar);
  if (isAddForm) {
    modalTitle = adminPageMessages.addObject;
    handleSubmit = (e) => {
      e.preventDefault();
      createCalendar();
    };
  } else if (isEditForm) {
    modalTitle = adminPageMessages.renameObject;
    handleSubmit = (e) => {
      e.preventDefault();
      updateCalendar(calendarId);
    };
  }

  modalTitle = intl.formatMessage(modalTitle, { object });

  return (
    <BasicModal close={handleCancel} modalClass="modal--sm">
      <div className="modal-overflow">
        <div className="modal--content">
          <h3>{modalTitle}</h3>

          <form onSubmit={handleSubmit}>
            <div className="form">
              <CalendarFormBody typeFieldVisible={!!isAddForm} />

              <div className="line" />
              { isAddForm && (
                <AddModalFooter
                  onCancelClick={handleCancel}
                  onAddClick={handleSubmit}
                  isAddButtonDisabled={pristine || submitting}
                  isAddButtonLoading={submitting}
                />
              ) }
              { isEditForm && (
                <EditModalFooter
                  onCancelClick={handleCancel}
                  onSaveClick={handleSubmit}
                  isSaveButtonDisabled={pristine || submitting}
                  isSaveButtonLoading={submitting}
                />
              ) }
            </div>
          </form>
        </div>
      </div>
    </BasicModal>
  );
};

CalendarModule.propTypes = {

  loaded: PropTypes.bool.isRequired,
  isAddForm: PropTypes.bool,
  isEditForm: PropTypes.bool,
  createCalendar: PropTypes.func,
  updateCalendar: PropTypes.func,
  calendarId: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

CalendarModule.defaultProps = {
  isAddForm: false,
  isEditForm: false,
  initialValues: {},
};

export default injectIntl(CalendarModule);
