import React, { useCallback } from 'react';

import MachineCard from '../../machine/card/Card.container.js';
import DatatronCard from '../../datatron/card/Card';
import { INVENTORY_DATATRONS, INVENTORY_MACHINES } from '../../../../../common/constants/inventory';
import { reset } from '../../../redux/modules/inventory.preview.module';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsPreviewSelected, previewItem, previewItemType } from '../../../redux/selectors/inventory.selector';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';

export const Preview = () => {
  const isPreviewSelected = useSelector(selectIsPreviewSelected);
  const item = useSelector(previewItem);
  const itemType = useSelector(previewItemType);

  const dispatch = useDispatch();
  const resetPreview = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  // override default 'click' so the event doesn't immediately send a reset signal to the preview
  const clickRef = useClickOutside(resetPreview, ['mousedown', 'touchdown']);

  if (!isPreviewSelected) return null;

  return (
    <div className="aside-popup" key={item.id} ref={clickRef}>
      {itemType === INVENTORY_MACHINES && (
        <MachineCard
          machine={item}
          canOpenDetails
        />
      )}
      {itemType === INVENTORY_DATATRONS && (
        <DatatronCard
          datatron={{ ...item }}
          canOpenDetails
        />
      )}
    </div>
  );
};

export default Preview;
