import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DP_ARCHIVE_DEVICE } from '../../../redux/constants/modals.constants';
import { archiveDevice } from '../../../redux/modules/datatron.devices.archive.module';
import { closeModal } from '../../../redux/modules/modals.module';
import {
  getModalPayload,
  isModalOpen,
} from '../../../redux/selectors/modals.selector';
import { selector } from '../../../redux/selectors/util';
import Modal from '../../_tools/modal/Modal';

const modalOpenSelector = selector((state) =>
  isModalOpen(state, DP_ARCHIVE_DEVICE),
);

const modalPayloadSelector = selector((state) =>
  getModalPayload(state, DP_ARCHIVE_DEVICE),
);

export const ArchiveDeviceModal = () => {
  const isOpen = useSelector(modalOpenSelector);
  const { deviceId } = useSelector(modalPayloadSelector);

  const dispatch = useDispatch();

  const close = () => dispatch(closeModal(DP_ARCHIVE_DEVICE));

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(archiveDevice(deviceId));
  };

  return (
    <Modal isOpen={isOpen} onClose={close} contentLabel="">
      <div className="modal--header" onClick={close}>
        <span className="modal--close">&times;</span>
      </div>
      <div className="modal--content">
        <FormattedMessage
          defaultMessage="Do you really want to archive this Device?"
          id="datatron.modals.archive_device.text"
          tagName="h3"
        />

        <div className="line" />

        <div className="text-center">
          <button type="button" className="button__cancel" onClick={close}>
            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
          </button>
          <button type="submit" className="button__save" onClick={handleSubmit}>
            <FormattedMessage
              defaultMessage="Archive Device"
              id="datatron.modals.archive_device.buttons.archive"
            />
          </button>
        </div>
      </div>
    </Modal>
  );
};
