import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import * as form from '../../../../../redux/constants/calendar.form.constants';
import commonMessages from '../../../../../messages/common.messages';
import formsPlaceholdersMessages from '../../../../../messages/forms.placeholders.messages';
import { FormField } from '../../../../_tools';
import CalendarTypeSelectContainer from '../../../../_tools/formFields/select/CalendarTypeSelect.container';

export const CalendarFormBody = ({ typeFieldVisible }) => (
  <div className="modal-form-in">
    <FormField
      label={commonMessages.name.id}
      name={form.NAME_FIELD}
      placeholder={formsPlaceholdersMessages.name.id}
    />
    { typeFieldVisible && (
      <label>
        <FormattedMessage {...commonMessages.type} />
        <Field
          component={CalendarTypeSelectContainer}
          name={form.TYPE_FIELD}
        />
      </label>
    ) }
  </div>
);

CalendarFormBody.propTypes = {
  typeFieldVisible: PropTypes.bool.isRequired,
};

export default CalendarFormBody;
