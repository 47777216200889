import { connect } from 'react-redux';

import ToolsEmptyPlaceholder from './ToolsEmptyPlaceholder';
import PermissionedComponent from '../../../common/permissions/PermissionedComponent.container';
import { TOOLS_PERMISSIONS } from '../../../../../../common/constants/permissions';
import { filtersApplied } from '../../../../redux/selectors/tools.selector';

export const mapStateToProps = (state, { permissions }) => ({
  hasFiltersApplied: filtersApplied(state),
  canAddTool: permissions.canAddTool(),
});

export default PermissionedComponent(TOOLS_PERMISSIONS)(connect(mapStateToProps)(ToolsEmptyPlaceholder));
