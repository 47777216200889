import { defineMessages } from 'react-intl';

import * as activityTypes from '../redux/constants/tools.activityTypes.constants';

export default defineMessages({
  [activityTypes.ASSIGN]: {
    id: 'tool.newActivity.label.assign',
    defaultMessage: 'Assign',
  },
  [activityTypes.UNASSIGN]: {
    id: 'tool.newActivity.label.unassign',
    defaultMessage: 'Unassign',
  },
  [activityTypes.MOUNT]: {
    id: 'tool.newActivity.label.mount',
    defaultMessage: 'Mount',
  },
  [activityTypes.UNMOUNT]: {
    id: 'tool.newActivity.label.unmount',
    defaultMessage: 'Unmount',
  },
  [activityTypes.SET_AS_EXHAUSTED]: {
    id: 'tool.newActivity.label.setAsExhausted',
    defaultMessage: 'Set as Exhausted',
  },
});
