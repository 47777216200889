import { createAction } from '@reduxjs/toolkit';
import { Todo } from '../../../../common/types/common';

export const sessionRestored = createAction<Todo>('auth/sessionRestored');

export const reducer = {
  [sessionRestored.type]: (state, payload) => ({
    ...state,
    profile: {
      ...state.profile,
      loaded: true,
      user: payload,
    },
  }),
};
