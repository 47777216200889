import React from 'react';
import { FormattedMessage } from 'react-intl';

const NotAvailable = () => (
  <div className="inventory-empty schedule-empty">
    <div className="schedule--placeholder">
      <div className="data-empty-icon">
        <img src="/img/empty-calendar.svg" alt="empty calendar" />
      </div>
      <p>
        <strong>
          <FormattedMessage
            id="machine.schedule.calendar_backend_not_available"
            defaultMessage="Calendar backend not available"
          />
        </strong>
      </p>
    </div>
  </div>
);

export default NotAvailable;
