import { createAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';

import { fetchPreviewItem } from './inventory.preview.fetch.module';

export const select = createAction(
  'select preview item',
  (itemType, itemId) => ({ payload: { itemType, itemId } }),
);

export const reset = createAction(
  'reset preview',
);

export const reducer = {
  [select]: (state, { itemType, itemId }) => ({
    ...state,
    inventory: {
      ...state.inventory,
      preview: {
        ...state.inventory.preview,
        loading: false,
        loaded: false,
        error: null,
        itemId,
        itemType,
        item: null,
      },
    },
  }),

  [reset]: (state) => ({
    ...state,
    inventory: {
      ...state.inventory,
      preview: {
        ...state.inventory.preview,
        loading: false,
        loaded: false,
        error: null,
        itemId: null,
        itemType: null,
        item: null,
      },
    },
  }),
};

export function* selectPreviewItemSaga({ payload }) {
  yield put(fetchPreviewItem(payload));
}

export function* watchSelectPreviewItem() {
  yield takeEvery(select, selectPreviewItemSaga);
}
