import { isEmpty } from 'lodash';
import querystring from 'querystring';

import { apiRequest, postApiRequest, putApiRequest } from './_tools';
import { EVENTS_WITH_COMMENTS_ONLY } from '../../redux/constants/machine.summary.constants';
import { prepareFiltersForAPI } from '../common/filters';

export function getAllEvents({
  withoutActivityOnly, fromIndex, toIndex, ...input
}) {
  const filters = {
    withoutActivityOnly,
    fromIndex,
    toIndex,
    ...prepareFiltersForAPI(input),
  };

  return apiRequest(`/api/events?${querystring.stringify(filters)}`);
}

export function getEventsCount({ withoutActivityOnly, ...input }) {
  const query = querystring.stringify({
    withoutActivityOnly,
    ...prepareFiltersForAPI(input),
  });

  return apiRequest(`/api/events/amount?${query}`);
}

export function getEventsByMachine(machineId) {
  return apiRequest(`/api/machine/${machineId}/events`);
}

export function getEventsSummaryByMachine(machineId, input) {
  const filters = { ...prepareFiltersForAPI(input) };
  if (input.category) filters.category = input.category;

  const query = !isEmpty(filters) ? querystring.stringify(filters) : '';

  return apiRequest(`/api/machines/${machineId}/events_summary?${query}`);
}

function prepareEventsOfSummaryGroupQuery(input) {
  const data = {
    ...input,
    ...prepareFiltersForAPI(input),
  };

  if (input.mode === EVENTS_WITH_COMMENTS_ONLY) data.withCommentsOnly = true;
  return querystring.stringify(data);
}

export function getEventsOfSummaryGroup(input) {
  return apiRequest(`/api/machines/${input.machineId}/events_summary/events?${prepareEventsOfSummaryGroupQuery(input)}`);
}

export function getEventsAmountOfSummaryGroup(input) {
  return apiRequest(`/api/machines/${input.machineId}/events_summary/events/amount?${prepareEventsOfSummaryGroupQuery(input)}`);
}

export function submitComment(text, eventId) {
  return postApiRequest(`/api/events/${eventId}/comments`, { text });
}

export function archiveEvent(eventId) {
  return putApiRequest(`/api/events/${eventId}`, { isArchived: true });
}

export function getPayloadByEventId(eventId) {
  return apiRequest(`/api/events/${eventId}/payload`);
}

export function getEventById({ eventId, requirePayload = false }) {
  const data = {};
  if (requirePayload) data.payload = true;
  const query = querystring.stringify(data);

  return apiRequest(`/api/events/${eventId}?${query}`);
}

export const getEventsDownloadAvailability = ({ withoutActivityOnly, ...input }) => {
  const filters = {
    withoutActivityOnly,
    ...prepareFiltersForAPI(input),
  };

  return apiRequest(`/api/events/download/available?${querystring.stringify(filters)}`);
};
