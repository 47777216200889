import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function RemoveBlockLabel({
  machines,
}) {
  return (
    <p>
      <FormattedMessage
        id="calendars.modals.remove.description.not_allowed"
        defaultMessage="That calendar is linked to the following machine(-s):"
      />
      <br />
      {
        machines.map((machine) => (
          <span key={machine.id}>
            { machine.name }
            <br />
          </span>
        ))
      }
    </p>
  );
}

RemoveBlockLabel.propTypes = {
  machines: PropTypes.array.isRequired,
};

export default RemoveBlockLabel;
