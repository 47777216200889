import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';

import EditLegendItem from './EditLegendItem';
import { closeModal } from '../../../../redux/modules/modals.module';
import { REPORT_LEGEND_DATA_POINT } from '../../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { REPORT_LEGEND_DATA_POINT_FORM_NAME } from '../../../../redux/constants/report.legend.dataPoint.form.constants';
import { getValuesFromFilter } from '../../../../core/common/report.legend.dataPoint.form';
import { updateLegendDataPoint } from '../../../../redux/modules/report.legend.dataPoint.update.module';

export const mapStateToProps = (state) => {
  const { filter, reportId } = getModalPayload(state, REPORT_LEGEND_DATA_POINT);

  return {
    dataPoint: filter.dataPoint,
    reportId,
    initialValues: getValuesFromFilter(filter),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(REPORT_LEGEND_DATA_POINT)),
  updateLegendDataPoint: bindActionCreators(updateLegendDataPoint, dispatch),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  update: () => dispatchProps.updateLegendDataPoint(stateProps.reportId),
});

const form = {
  form: REPORT_LEGEND_DATA_POINT_FORM_NAME,
  destroyOnUnmount: true,
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm(form)(EditLegendItem));
