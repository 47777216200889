import { isEmpty } from 'lodash';
import { max, min } from 'd3-array';
import { ScaleBand, scaleBand, ScaleLinear, scaleLinear } from 'd3-scale';

import React from 'react';
import { KpiChart } from './KpiChart';
import {
  findHighestPoint,
  findHighestTotalPoint,
  getDataForStackedBarChart,
  getDomainForScaleBand,
  getX,
} from '../../../core/common/reportChart';
import { ChartType, LINE_CHART, STACKED_CHART } from '../../../redux/constants/chart.type.constants';
import { BOTTOM_AXIS_OFFSET, LEFT_AXIS_WIDTH, MARGIN, OFFSET_ABOVE_TOP, Y_AXIS_PADDING } from './chart.constants';
import { Todo } from '../../../../../common/types/common';

interface Props {
  chartType: ChartType;
  showAxisBottom?: Todo;
  data: Todo;
  width?: number;
  height?: number;
  selection?: Todo;
  allowedProps: string[];
}

const useChartHeight = (height, showAxisBottom) => {
  const baseChartHeight = height - MARGIN.top - MARGIN.bottom;
  return showAxisBottom
    ? baseChartHeight - BOTTOM_AXIS_OFFSET
    : baseChartHeight;
};

export const KpiChartContainer: React.FC<Props> = ({
  chartType,
  showAxisBottom,
  data,
  width = NaN,
  height = NaN,
  selection,
  allowedProps,
}) => {
  const chartWidth = width - MARGIN.left - MARGIN.right;
  const chartWidthPadding = chartWidth - (2 * Y_AXIS_PADDING);
  const chartHeight = useChartHeight(height, showAxisBottom);
  const translate = `translate(${MARGIN.left}, ${MARGIN.top})`;
  const domain = isEmpty(selection)
    ? [min(data, getX), max(data, getX)] : selection;

  const chartData = getDataForStackedBarChart(data, selection);

  const commonProps: Todo = {
    data: chartData,
    domain,
    chartWidth,
    chartHeight,
    translate,
    leftAxisWidth: LEFT_AXIS_WIDTH,
    chartType,
    allowedProps,
  };

  let xScale: ScaleBand<string> | ScaleLinear<number, number> | null;
  let yScale: ScaleLinear<number, number> | null;

  if (chartType === LINE_CHART || chartType === STACKED_CHART) {
    xScale = scaleLinear()
      .domain(domain)
      .range([0, chartWidthPadding]);

    const highestPoint = findHighestPoint(chartData, allowedProps) + OFFSET_ABOVE_TOP;
    yScale = scaleLinear()
      .domain([0, highestPoint])
      .range([chartHeight, 0]);
  } else {
    const xScaleDomain = getDomainForScaleBand(chartData, selection);
    xScale = scaleBand()
      .range([0, chartWidthPadding])
      .domain(xScaleDomain)
      .paddingInner(0.05)
      .align(0.1);

    const yScaleMax = Math.ceil(findHighestTotalPoint(chartData, allowedProps)) + OFFSET_ABOVE_TOP;
    yScale = scaleLinear()
      .domain([0, yScaleMax])
      .range([chartHeight, 0])
      .clamp(true);

    commonProps.showVerticalLines = false;
  }

  return (
    <KpiChart
      xScale={xScale}
      yScale={yScale}
      width={width}
      height={height}
      chartType={chartType}
      showAxisBottom={showAxisBottom}
      allowedProps={allowedProps}
      data={data}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...commonProps}
    />
  );
};

export default KpiChartContainer;
