import React from 'react';
import { FormattedMessage } from 'react-intl';

const AdminPageHeader = () => (
  <div className="subheader">
    <div className="subheader-in">
      <div className="subheader--left">

        <ul className="menu breadcrumps-menu">
          <li>
            <div className="breadcrumps-title breadcrumps-home">
              <span className="icon-home" />
              <span className="fa fa-chevron-right" />
            </div>
          </li>
          <li>
            <div className="breadcrumps-title">
              <FormattedMessage id="adminPage.header" defaultMessage="Administration" />
            </div>
          </li>
        </ul>

      </div>
      <div className="subheader--right" />
    </div>
  </div>
);

export default AdminPageHeader;
