import { useDispatch } from 'react-redux';

import SliderContainer from '../../../../_tools/sliderV2/SliderContainer';
import { changeSelection } from '../../../../../redux/modules/reports.slider.module';
import { selectSelectionByReportId } from '../../../../../redux/selectors/reports.selector';
import React from 'react';
import { ReportT } from '../../../../../../types/report';
import { useParamSelector } from '../../../../../redux/util';

interface Props {
  report: ReportT;
}

export const SliderV2Container: React.FC<Props> = ({ report }) => {
  const selection = useParamSelector(selectSelectionByReportId, report.id);
  const dispatch = useDispatch();
  return (
    <SliderContainer
      report={report}
      selection={selection}
      changeSelection={(domain, reportId) => dispatch(changeSelection(domain, reportId))}
    />
  );
};
