import { formValueSelector } from 'redux-form';

import * as form from '../constants/datatron.device.form.constants';

export const createFormSelector = () => formValueSelector(form.DATATRON_DEVICE_FORM_NAME);
export const getDeviceFormFields = (state, additionalKeys = []) => createFormSelector()(
  state,
  ...form.DATATRON_DEVICE_PROPS,
  ...additionalKeys,
);
