import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';

import * as form from '../constants/experiment.form.constants';
import { EXPERIMENT_EDIT } from '../constants/modals.constants';
import { getFormFields } from '../selectors/experiment.form.selector';
import { getExperimentsPagination, getExperimentsFilters } from '../selectors/experimentsPage.selector';
import * as api from '../../core/api';
import { closeModal } from './modals.module';
import { compareById, replaceObjectInList } from '../../../../common/helpers/object';
import { fetchExperiments } from './experiments.fetch.module';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const updateExperiment = createAction(
  'update experiment',
  (experimentId) => experimentId,
);

export const updateExperimentSuccess = createAction(
  'update experiment - success',
  (experiment) => experiment,
);

export const updateExperimentFail = createAction(
  'update experiment - fail',
  (error) => error,
);

export const reducer = {
  [updateExperimentSuccess]: (state, experiment) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      experiments: {
        ...state.experimentsPage.experiments,
        list: replaceObjectInList(
          state.experimentsPage.experiments.list,
          experiment,
          compareById,
        ),
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.EXPERIMENT_FORM_NAME,
    ...form.EXPERIMENT_PROPS,
  ));
}

export function* handleErrors(errors) {
  yield put(updateExperimentFail(errors));
  yield put(stopSubmit(form.EXPERIMENT_FORM_NAME, errors));
}

export function* updateExperimentSaga({ payload: experimentId }) {
  yield call(touchAllFieldsSaga);

  const state = yield select();
  const fields = yield call(getFormFields, state);
  yield put(startSubmit(form.EXPERIMENT_FORM_NAME));

  const { response, error } = yield call(api.experiments.updateExperiment, experimentId, fields);

  if (response) {
    yield put(updateExperimentSuccess(response));
    yield put(stopSubmit(form.EXPERIMENT_FORM_NAME));
    yield put(closeModal(EXPERIMENT_EDIT));
    yield put(sendNotification({ ...notificationMessages.experiment_updated, values: { name: response.name } }));

    const pagination = yield call(getExperimentsPagination, state);
    if (pagination) {
      const filters = yield call(getExperimentsFilters, state);
      const newPagination = yield call(api.experiments.getExperimentsPagination, filters);

      if (newPagination.response) {
        const pageToFetch = pagination.current_page > newPagination.response.pagination.total_pages ?
          newPagination.response.pagination.total_pages : pagination.current_page;
        yield put(fetchExperiments(pageToFetch));
      } else {
        yield put(fetchExperiments(pagination.current_page));
      }
    }
  } else {
    yield handleErrors(error);
  }
}

export function* watchUpdateExperiment() {
  yield takeEvery(updateExperiment.getType(), updateExperimentSaga);
}
