import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import commandModalMessages from '../../messages/command.modal.messages';
import { validationFileMessages } from '../../messages/dataPoints.action.names';

interface ErrorProps {
  errorMessage: string;
}

export const ErrorModalContent: React.FC<ErrorProps> = ({ errorMessage }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormattedMessage
        defaultMessage={formatMessage(commandModalMessages.error)}
        id='datatron.modals.error'
        tagName='h3'
      />
      <div className='error-message'>
        <FormattedMessage
          defaultMessage={formatMessage(validationFileMessages.defaultValidation)}
          id={errorMessage}
          tagName='h3'
        />
      </div>
    </>
  );
};
