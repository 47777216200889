import { defineMessages } from 'react-intl';
import {
  FORM_TAB,
  MEASUREMENTS_TAB,
} from './ExperimentModal.tabs';

export default defineMessages({
  [FORM_TAB]: {
    id: 'common.details',
    defaultMessage: 'Details',
  },
  [MEASUREMENTS_TAB]: {
    id: 'experimentsPage.modalsTabs.measurements',
    defaultMessage: 'Measurements',
  },
});
