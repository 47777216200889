import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DATATRONS_PERMISSIONS } from '../../../../../../../../../../common/constants/permissions';
import commonMessages from '../../../../../../../../messages/common.messages';
import messages from '../../../../../../../../messages/dynamicField.messages';
import ThreeDotsMenu from '../../../../../../../_tools/threeDotsMenu/ThreeDotsMenu';
import { usePermissionInstance } from '../../../../../../../common/permissions/usePermissionInstance';
import { ActionsDataPointModal } from '../../../../../../modals/ActionsDataPointModal/ActionsDataPointModal';
import {
  updateBulkDataPoints,
  resetUpdateBulkResponse,
  ActionType,
} from '../../../../../../../../redux/modules/datatron.devices.dataPoints.update.bulk.module';

type Props = {
  deviceId: string;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
};

type MenuItem = {
  onClick: () => void;
  label: string;
  isIcon: boolean;
  icon?: string;
  isBold?: boolean;
};
const useCanUpdateDatatronDeviceDataPoints = () => {
  const datatronsPermissions = usePermissionInstance(DATATRONS_PERMISSIONS);
  return datatronsPermissions.canUpdateDatatronDeviceDataPoints();
};

type BulkUpdateAction = (
  deviceId: string,
  attribute: ActionType,
  selectedValue: boolean,
  selectedRows: string[],
) => void;
type ResetUpdateBulkResponse = (deviceId: string) => void;

export const useBulkUpdateAction = (): [ResetUpdateBulkResponse, BulkUpdateAction] => {
  const dispatch = useDispatch();

  const resetUpdateBulk: ResetUpdateBulkResponse = useCallback(
    (deviceId: string) => {
      dispatch(resetUpdateBulkResponse(deviceId));
    },
    [dispatch],
  );

  const bulkUpdateAction: BulkUpdateAction = (
    deviceId: string,
    attribute: ActionType,
    selectedValue: boolean,
    selectedRows: string[],
  ) => {
    dispatch(updateBulkDataPoints(deviceId, attribute, selectedValue, selectedRows));
  };

  return [resetUpdateBulk, bulkUpdateAction];
};

// Component for managing actions related to data points

export const DataPointsActionMenu: React.FC<Props> = React.memo(
  ({ deviceId, selectedRows, setSelectedRows }) => {
    const canUpdateDatapoints = useCanUpdateDatatronDeviceDataPoints();
    const { formatMessage } = useIntl();

    const [resetUpdateBulk, bulkUpdateAction] = useBulkUpdateAction();
    let menuItems: MenuItem[] = [];

    const handleResetUpdateBulkResponse = () => {
      resetUpdateBulk(deviceId);
      setSelectedRows([]);
    };

    const [modalProps, setModalProps] = useState<{
      isOpen: boolean;
      close: () => void;
      commandLabel: string;
      commandAction: (selectedValue: boolean) => void;
      resetAction: () => void;
    }>({
      isOpen: false,
      close: () => {},
      commandLabel: '',
      commandAction: () => {},
      resetAction: () => {},
    });

    const handleAction =
      (commandLabel: string, commandAction: (selectedValue: boolean) => void) => () => {
        setModalProps((prevProps) => ({
          ...prevProps,
          isOpen: true,
          close: () => setModalProps((props) => ({ ...props, isOpen: false })),
          commandLabel,
          commandAction,
          resetAction: handleResetUpdateBulkResponse,
        }));
      };

    const handleArchiveAction = (selectedValue: boolean) =>
      bulkUpdateAction(deviceId, 'archived', selectedValue, selectedRows);
    const handleEnableAction = (selectedValue: boolean) =>
      bulkUpdateAction(deviceId, 'enabled', selectedValue, selectedRows);
    const handleMaxRateAction = (selectedValue: boolean) =>
      bulkUpdateAction(deviceId, 'isMaxRate', selectedValue, selectedRows);

    if (canUpdateDatapoints) {
      menuItems.push(
        {
          onClick: handleAction(formatMessage(commonMessages.archive), handleArchiveAction),
          label: formatMessage(commonMessages.archive),
          isBold: false,
          isIcon: true,
          icon: 'fa fa-archive',
        },
        {
          onClick: handleAction(formatMessage(messages.enabled), handleEnableAction),
          label: `${formatMessage(commonMessages.enable)} / ${formatMessage(commonMessages.disable)}`,
          isBold: false,
          isIcon: true,
          icon: 'fa fa-toggle-on',
        },
        {
          onClick: handleAction(formatMessage(messages.isMaxRate), handleMaxRateAction),
          label: formatMessage(messages.isMaxRate),
          isBold: false,
          isIcon: true,
          icon: 'fa fa-fast-forward ',
        },
      );
    }

    const hasAccess = menuItems.length > 0 && selectedRows.length > 0;

    return (
      <div className='d-flex align-items-center mr-1'>
        {hasAccess && <ThreeDotsMenu menuItems={menuItems} />}
        <ActionsDataPointModal
          {...modalProps}
          countSelection={selectedRows.length}
          deviceId={deviceId}
        />
      </div>
    );
  },
);
