import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getLocationLink } from '../../index/routes';
import CategoryLabel from '../../_tools/categoryLabel/CategoryLabel';
import stopPropagation from '../../_tools/stopPropagation.js';

export default function EventHeader({
  category,
  machine,
  machineLink,
  title,
  onArrowClick,
}) {
  return (
    <div className="position-text--top">
      <div className="position--label">
        <CategoryLabel category={category} />
      </div>
      <div className="position-title">
        <h2>{title}</h2>
        <p>
          <span className="position-machines">
            <Link
              onClick={stopPropagation}
              to={machineLink}
              className="links"
            >
              <span className="icon icon-cog" />
              <span className="links-text">{machine.name}</span>
            </Link>
          </span>
          <span className="position-locations">
            <Link
              onClick={stopPropagation}
              className="links location-link"
              to={getLocationLink(machine.location.id)}
            >
              <span className="icon icon-location" />
              {machine.location.name}
            </Link>
          </span>
        </p>
      </div>
      <div className="position-button">
        <button className="button__position-extend" onClick={onArrowClick}>
          <i className="icon icon-caret-left" />
          <i className="icon icon-caret-down" />
        </button>
      </div>
    </div>
  );
}

EventHeader.propTypes = {
  machineLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  machine: PropTypes.object.isRequired,
  onArrowClick: PropTypes.func.isRequired,
};
