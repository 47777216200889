import { connect } from 'react-redux';

import { confirmFilters, resetPendingFilters } from '../../../redux/modules/dashboard.events.filter.module';
import Footer from './Footer';

export const mapDispatchToProps = {
  confirmFilters,
  resetPendingFilters,
};

export default connect(null, mapDispatchToProps)(Footer);
