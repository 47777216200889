import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const FooterPreTitle = ({ hasMachines }) => (
  <div className="calendar--bottom--label">
    { hasMachines
      ? (
        <FormattedMessage
          id="calendar.footer.linked"
          defaultMessage="Linked"
        />
      ) : (
        <FormattedMessage
          id="calendar.footer.no_linked"
          defaultMessage="No Linked Machines"
        />
      )}
  </div>
);

FooterPreTitle.propTypes = {
  hasMachines: PropTypes.bool.isRequired,
};

export default FooterPreTitle;
