import { reduxForm } from 'redux-form';

import RoleForm from './RoleForm';
import { ADMIN_ROLE_FORM_NAME } from '../../../../../../redux/constants/admin.role.form.constants';

const form = {
  form: ADMIN_ROLE_FORM_NAME,
  destroyOnUnmount: true,
};

export default reduxForm(form)(RoleForm);
