export const ANOMALY = 'ANOMALY';
export const ERROR = 'ERROR';
export const FATAL = 'FATAL';
export const INFO = 'INFO';
export const WARNING = 'WARNING';

export const DEFAULT = INFO;

export default [
  INFO,
  WARNING,
  ERROR,
  FATAL,
  ANOMALY,
];
