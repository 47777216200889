import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import Form from './form/AddToolForm.container';

export const AddToolModal = ({
  handleSubmit,
  close,
}) => (
  <BasicModal close={close} modalClass="modal--sm2">
    <div className="modal--content">
      <h3 className="first-title">
        <FormattedMessage
          id="tools.modal.add_tool"
          defaultMessage="Add Tool"
        />
      </h3>
      <Form handleSubmit={handleSubmit} handleCancel={close} />
    </div>
  </BasicModal>
);

AddToolModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default AddToolModal;
