const MAX_FILE_SIZE = 5242880;

export const validateFileSize = (file) => {
  let error = null;

  if (file && file.size > MAX_FILE_SIZE) {
    error = 'errors.file_is_too_big';
  }

  return error;
};
