import React from 'react';
import PropTypes from 'prop-types';

export const Input = ({ value, onChange }) => (
  <div className="search-text">
    <input type="text" className="search-input" value={value} onChange={onChange} />
    <div className="input-group-button">
      <button type="button" className="button__search">
        <span className="icon icon-search" />
      </button>
    </div>
  </div>
);

Input.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Input;
