import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Option from './Option';
import calendarMessages from '../../../../../../../messages/calendars.messages';
import * as entryType from '../../../../../../../redux/constants/calendars.entry.constants';

export const EntryTypeBar = ({ intl, value, choose }) => {
  const fieldName = 'removeFormat';

  return (
    <div className="radios">
      <Option
        selected={value === entryType.CURRENT_ONLY}
        name={fieldName}
        value={entryType.CURRENT_ONLY}
        label={intl.formatMessage(calendarMessages.deleteCurrentEntryOnly)}
        onClick={choose}
      />
      <Option
        selected={value === entryType.CURRENT_AND_FUTURE}
        name={fieldName}
        value={entryType.CURRENT_AND_FUTURE}
        label={intl.formatMessage(calendarMessages.deleteCurrentAndFutureEntries)}
        onClick={choose}
      />
      <Option
        selected={value === entryType.ALL}
        name={fieldName}
        value={entryType.ALL}
        label={intl.formatMessage(calendarMessages.deleteAllEntries)}
        onClick={choose}
      />
    </div>
  );
};

EntryTypeBar.propTypes = {

  value: PropTypes.string.isRequired,
  choose: PropTypes.func.isRequired,
};

export default injectIntl(EntryTypeBar);
