import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import { getClusterById } from '../../core/api/cluster';

export const fetchCluster = createAction(
  'fetch cluster',
  (eventId, clusterId) => ({ eventId, clusterId }),
);

export const fetchClusterSuccess = createAction(
  'fetch cluster successfully',
  (eventId, cluster) => ({ eventId, cluster }),
);

export const fetchClusterFail = createAction(
  'fetch cluster fail',
  (eventId, error) => ({ eventId, error }),
);

export const reducer = {
  [fetchCluster]: (state, { eventId }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      events: state.dashboard.events.reduce((final, event) => {
        if (event.id === eventId) {
          return [
            ...final,
            {
              ...event,
              cluster: {
                ...event.cluster,
                loaded: false,
                loading: true,
                error: null,
              },
            },
          ];
        }
        return [
          ...final,
          event,
        ];
      }, []),
    },
  }),

  [fetchClusterSuccess]: (state, { eventId, cluster }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      events: state.dashboard.events.reduce((final, event) => {
        if (event.id === eventId) {
          return [
            ...final,
            {
              ...event,
              cluster: {
                ...event.cluster,
                ...cluster,
                loaded: true,
                loading: false,
                error: null,
              },
            },
          ];
        }
        return [
          ...final,
          event,
        ];
      }, []),
    },
  }),

  [fetchClusterFail]: (state, { eventId, error }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      events: state.dashboard.events.reduce((final, event) => {
        if (event.id === eventId) {
          return [
            ...final,
            {
              ...event,
              cluster: {
                ...event.cluster,
                loaded: false,
                loading: false,
                error,
              },
            },
          ];
        }
        return [
          ...final,
          event,
        ];
      }, []),
    },
  }),
};

export function* fetchFetchClusterSaga({ payload: { eventId, clusterId } }) {
  const { response, error } = yield call(getClusterById, clusterId);

  if (response) {
    yield put(fetchClusterSuccess(eventId, response));
  } else {
    yield put(fetchClusterFail(eventId, error));
  }
}

export function* watchFetchCluster() {
  yield takeEvery(fetchCluster.getType(), fetchFetchClusterSaga);
}
