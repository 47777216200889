import { connect } from 'react-redux';
import RootCore from './RootCore';
import { getLocale } from '../../redux/selectors/locale.selector';
import { isProfileLoaded } from '../../redux/selectors/profile.selector';

export function mapStateToProps(state) {
  return {
    locale: getLocale(state),
    authorized: isProfileLoaded(state),
  };
}

export default connect(mapStateToProps)(RootCore);
