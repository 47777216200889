import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { updateActivity } from './maintenance.columns.activity.update.module';
import { getModalPayload } from '../selectors/modals.selector';
import { getFormFields } from '../selectors/maintenance.assign.user.form.selector';
import { MAINTENANCE_ASSIGN } from '../constants/modals.constants';

export const assignUser = createAction(
  'assign user to the event',
);

export function* assignUserSaga() {
  const state = yield select();
  const { activityId } = yield call(getModalPayload, state, MAINTENANCE_ASSIGN);
  const selectedUser = yield call(getFormFields, state);

  const assigneeId = selectedUser ? selectedUser.value.id : null;

  yield put(updateActivity(activityId, { assigneeId }));
}

export function* watchAssignUser() {
  yield takeEvery(assignUser.getType(), assignUserSaga);
}
