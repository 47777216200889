import { createAction } from 'redux-act';
import { push } from 'connected-react-router';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { ceil } from 'lodash';

import * as api from '../../core/api';
import * as form from '../constants/admin.organization.form.constants';
import { getFormFields } from '../selectors/admin.organization.form.selector';
import { getPagination } from '../selectors/admin.organizations.selector';
import { getAdminOrganizationsLink } from '../../components/index/routes';
import { adminFetchOrganizations } from './admin.organizations.fetch.module';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const adminCreateOrganization = createAction(
  'admin: create organization',
  (id) => id,
);

export const adminCreateOrganizationSuccess = createAction(
  'admin: create organization - success',
  (item) => item,
);

export const adminCreateOrganizationFail = createAction(
  'admin: create organization - fail',
  (error) => error,
);

export const reducer = {
  [adminCreateOrganizationSuccess]: (state, organization) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        all: {
          ...state.admin.organizations.all,
          list: [
            ...state.admin.organizations.all.list,
            organization,
          ],
        },
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.ADMIN_ORGANIZATION_FORM_NAME,
    ...form.ORGANIZATION_PROPS,
  ));
}

export function* adminCreateOrganizationSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const name = yield call(getFormFields, state);
  yield put(startSubmit(form.ADMIN_ORGANIZATION_FORM_NAME));

  const { response, error } = yield call(api.admin.createOrganization, { name });

  if (response) {
    yield put(adminCreateOrganizationSuccess(response));
    yield put(stopSubmit(form.ADMIN_ORGANIZATION_FORM_NAME));
    yield put(push(getAdminOrganizationsLink()));

    const pagination = yield call(getPagination, state);
    let pageNumberToFetch = 1;
    if (pagination && pagination.total_pages) {
      pageNumberToFetch = ceil((pagination.total + 1) / pagination.per_page);
    }
    yield put(adminFetchOrganizations(pageNumberToFetch));
    yield put(sendNotification({ ...notificationMessages.organization_added, values: { name: response.name } }));
  } else {
    yield put(adminCreateOrganizationFail(error));
    yield put(stopSubmit(form.ADMIN_ORGANIZATION_FORM_NAME, error));
  }
}

export function* watchAdminCreateOrganization() {
  yield takeEvery(adminCreateOrganization.getType(), adminCreateOrganizationSaga);
}
