import React from 'react';
import PropTypes from 'prop-types';

import Line from './Line.container';

function LineChart({
  data, xScale, yScale, properties,
}) {
  return (
    <g>
      {
        properties.map((prop) => (
          <Line
            key={prop}
            prop={prop}
            data={data}
            xScale={xScale}
            yScale={yScale}
          />
        ))
      }
    </g>
  );
}

LineChart.propTypes = {
  data: PropTypes.array.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired,
};

export default LineChart;
