import React from 'react';
import { FormattedMessage } from 'react-intl';

import commonCoreMessages from '../../../../../messages/common.core.messages';

const RolesHeader = () => (
  <thead>
    <tr>
      <FormattedMessage {...commonCoreMessages.role} tagName="td" />
      <FormattedMessage {...commonCoreMessages.users} tagName="td" />
      <td className="td-actions">
        <FormattedMessage
          id="users.actions"
          defaultMessage="Actions"
        />
      </td>
    </tr>
  </thead>
);

export default RolesHeader;
