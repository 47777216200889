import { createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { updateLocationInTree } from '../../core/common/locationPicker';
import { fetchLocationPicker } from './locationPicker.fetch.module';
import { getLatestLoadedParentId } from '../selectors/locationPicker.selector';

export const selectLocation = createAction(
  'select location (location picker)',
  (selectedLocationId) => ({ payload: selectedLocationId }),
);

export const reducer = {
  [selectLocation.type]: (state, selectedLocationId) => ({
    ...state,
    locationPicker: {
      ...state.locationPicker,
      tree: updateLocationInTree(
        state.locationPicker.tree,
        selectedLocationId,
        { selected: true },
        { selected: false },
      ),
      loading: false,
      loaded: true,
      error: null,
      selectedLocationId,
    },
  }),
};

export function* selectLocationSaga({ payload: selectedLocationId }) {
  const state = yield select();

  const latestLoadedParentId = yield call(getLatestLoadedParentId, state);
  if (latestLoadedParentId === selectedLocationId) return;

  yield put(fetchLocationPicker(selectedLocationId));
}

export function* watchSelectLocation() {
  yield takeEvery(selectLocation, selectLocationSaga);
}
