import { connect } from 'react-redux';

import ExperimentsTab from './ExperimentsTab';
import * as selector from '../../../redux/selectors/experimentsPage.selector';
import { fetchExperiments } from '../../../redux/modules/experiments.fetch.module';

export const mapStateToProps = (state) => {
  const isLoaded = selector.experimentsLoaded(state);
  const isLoading = selector.experimentsLoading(state);
  const pagination = selector.getExperimentsPagination(state);

  return {
    paginationAvailable: isLoaded && !!pagination && pagination.total_pages > 1,
    pagination,
    experiments: selector.getExperiments(state),
    isLoading,
    query: selector.getQueryFilterValue(state),
  };
};

export const mapDispatchToProps = {
  choosePage: fetchExperiments,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExperimentsTab);
