import _lodash from 'lodash';
const { pick, isEqual } = _lodash;

export const isMatchCriteria = (object, criteria) => {
  const criteriaKeys = Object.keys(criteria);
  return isEqual(pick(object, criteriaKeys), criteria);
};

export const findAndUpdate = (list, criteria, newObject, isMergeStrategy = false) => list.reduce((final, current) => {
  if (isMatchCriteria(current, criteria)) {
    const newCurrent = (isMergeStrategy === true) ? {
      ...current,
      ...newObject,
    } : newObject;

    return [
      ...final,
      newCurrent,
    ];
  }
  return [
    ...final,
    current,
  ];
}, []);

export const sleep = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms));
