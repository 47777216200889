import { defineMessages } from 'react-intl';

import * as relativeUnits from '../../../common/constants/relative.units';

export default defineMessages({
  [relativeUnits.now]: {
    id: 'common.relative_units.now',
    defaultMessage: 'Now',
  },
  [relativeUnits.seconds]: {
    id: 'common.relative_units.N_seconds_ago',
    defaultMessage: '{ count, plural, =-1 {seconds} one {Second} other {{count} seconds} } ago',
  },
  [relativeUnits.minutes]: {
    id: 'common.relative_units.N_minutes_ago',
    defaultMessage: '{ count, plural, =-1 {minutes} one {Minute} other {{count} minutes} } ago',
  },
  [relativeUnits.hours]: {
    id: 'common.relative_units.N_hours_ago',
    defaultMessage: '{ count, plural, =-1 {hours} one {Hour} other {{count} hours} } ago',
  },
  [relativeUnits.days]: {
    id: 'common.relative_units.N_days_ago',
    defaultMessage: '{ count, plural, =-1 {days} one {Day} other {{count} days} } ago',
  },
  [relativeUnits.weeks]: {
    id: 'common.relative_units.N_weeks_ago',
    defaultMessage: '{ count, plural, =-1 {weeks} one {Week} other {{count} weeks} } ago',
  },
  [relativeUnits.months]: {
    id: 'common.relative_units.N_months_ago',
    defaultMessage: '{ count, plural, =-1 {months} one {Month} other {{count} months} } ago',
  },
});
