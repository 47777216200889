import { forEach } from 'lodash';
import moment from 'moment';

import { calculateOee, convertKPIs } from './indicators';
import * as reportTypes from '../../../../common/enums/reportTypes';
import { ReportType } from '../../../../common/enums/reportTypes';
import * as periodTypes from '../../../../common/enums/periodTypes';
import { EventsPeriodType, PeriodType } from '../../../../common/enums/periodTypes';
import * as datesModes from '../../../../common/enums/dates.modes';
import * as relativeUnits from '../../../../common/constants/relative.units';
import { RelativeUnit } from '../../../../common/constants/relative.units';
import * as chartTypes from '../../redux/constants/chart.type.constants';
import { EventsChild, Indicators, InitializedReport, RelativeDate, ReportT } from '../../../types/report';
import { Todo } from '../../../../common/types/common';
import * as oeeHelper from './oee';

export const isIndicatorDisabled = (type, disabledProps) => disabledProps.indexOf(type) >= 0;

export const getShift = (report) => (report.data && report.data.shifts ? report.data.shifts[0] : null);

export const getIndicators = (report: ReportT): Indicators => {
  switch (report.type) {
    case reportTypes.events: {
      const counts = report.data.total;
      const child: EventsChild[] = [];

      let totalAmount = 0;
      forEach(counts, (value) => {
        totalAmount += value;
      });

      forEach(counts, (value, category) => {
        child.push({
          type: category as EventsPeriodType,
          isDisabled: isIndicatorDisabled(category, report.disabledProps),
          label: `events.${category as EventsPeriodType}`,
          value: (value > 0) ? (value / totalAmount) : 0,
          displayValue: value,
          isAmount: true,
        });
      });

      const main = {
        label: 'indicators.all',
        value: (totalAmount > 0) ? 1 : 0,
        displayValue: totalAmount,
        isAmount: true,
      } as const;

      return {
        main,
        child,
      };
    }
    case reportTypes.kpi: {
      const indicators = convertKPIs(report.data.total);
      const main = calculateOee(
        report.data.total[periodTypes.availability],
        report.data.total[periodTypes.performance],
        report.data.total[periodTypes.quality],
      );
      return {
        main,
        child: indicators,
      };
    }
    case reportTypes.maintenance: {
      let total = 0;
      const amounts: Todo = periodTypes.maintenanceTypes.reduce((final, type) => {
        let thisTypeValue;
        if (report.data.total && report.data.total[type]) {
          thisTypeValue = report.data.total[type];
        } else {
          thisTypeValue = 0;
        }

        total += thisTypeValue;
        return {
          ...final,
          [type]: thisTypeValue,
        };
      }, {});
      amounts.total = total;

      return {
        main: {
          label: 'indicators.all',
          value: amounts.total,
          isAmount: true,
          noAnimation: true,
        },
        child: [
          {
            type: periodTypes.maintenancePlanned,
            isDisabled: isIndicatorDisabled(periodTypes.maintenancePlanned, report.disabledProps),
            label: `maintenance.${periodTypes.maintenancePlanned}`,
            value: (amounts.planned > 0) ? (amounts.planned / amounts.total) : 0,
            displayValue: (amounts.planned > 0) ? amounts.planned : 0,
            isAmount: true,
            noAnimation: true,
          },
          {
            type: periodTypes.maintenanceUnplanned,
            isDisabled: isIndicatorDisabled(periodTypes.maintenanceUnplanned, report.disabledProps),
            label: `maintenance.${periodTypes.maintenanceUnplanned}`,
            value: (amounts.unplanned > 0) ? (amounts.unplanned / amounts.total) : 0,
            displayValue: (amounts.unplanned > 0) ? amounts.unplanned : 0,
            isAmount: true,
            noAnimation: true,
          },
        ],
      };
    }
    default: {
      return {
        main: null,
        child: [],
      };
    }
  }
};

const periodTypeToLabel: Record<PeriodType, { label: string }> = {
  [periodTypes.maintenancePlanned]: {
    label: `maintenance.${periodTypes.maintenancePlanned}`,
  },
  [periodTypes.maintenanceUnplanned]: {
    label: `maintenance.${periodTypes.maintenanceUnplanned}`,
  },
  [periodTypes.eventAnomaly]: {
    label: `events.category.${periodTypes.eventAnomaly}`,
  },
  [periodTypes.eventInfo]: {
    label: `events.category.${periodTypes.eventInfo}`,
  },
  [periodTypes.eventFatal]: {
    label: `events.category.${periodTypes.eventFatal}`,
  },
  [periodTypes.eventWarning]: {
    label: `events.category.${periodTypes.eventWarning}`,
  },
  [periodTypes.eventError]: {
    label: `events.category.${periodTypes.eventError}`,
  },
  [periodTypes.quality]: {
    label: 'indicators.quality',
  },
  [periodTypes.performance]: {
    label: 'indicators.performance',
  },
  [periodTypes.availability]: {
    label: 'indicators.availability',
  },
} as const;

const getGaugeProps = (gaugeName: PeriodType) => periodTypeToLabel[gaugeName];

interface GaugeValuesKpi {
  main: {
    label: 'indicators.oee';
    value: number;
  };
  child: {
    label: string;
    type: PeriodType;
    isDisabled: boolean;
    value: number;
  }[];
}

interface GaugeValuesNonKpi {
  main: {
    label: 'indicators.all';
    value: number;
    isAmount: true;
    noAnimation: true;
  };
  child: {
    label: string;
    type: PeriodType;
    isDisabled: boolean;
    value: number;
    displayValue: number;
    isAmount: true;
  }[];
}

export const calculateGaugeValues = (
  data: Record<PeriodType, Todo>,
  disabledProps,
  type: ReportType,
): GaugeValuesKpi | GaugeValuesNonKpi => {
  let mainValue;
  const child: Todo[] = [];

  if (type === reportTypes.kpi) {
    mainValue = 1;
    if (disabledProps.length === Object.keys(data).length) {
      mainValue = 0;
    }

    forEach(
      data,
      (value, key) => {
        if (!isIndicatorDisabled(key, disabledProps)) {
          mainValue *= value;
        }
      },
    );

    forEach(
      data,
      (value, key) => child.push({
        ...getGaugeProps(key as PeriodType),
        type: key as PeriodType,
        isDisabled: isIndicatorDisabled(key, disabledProps),
        value: value || 0,
      }),
    );

    return {
      main: {
        label: 'indicators.oee',
        value: mainValue || 0,
      },
      child,
    };
  }

  mainValue = 0;

  forEach(
    data,
    (value, key) => {
      if (!isIndicatorDisabled(key, disabledProps)) {
        mainValue += value;
      }
    },
  );

  forEach(
    data,
    (value, key) => child.push({
      ...getGaugeProps(key as PeriodType),
      type: key as PeriodType,
      isDisabled: isIndicatorDisabled(key, disabledProps),
      value: (value > 0) ? (value / mainValue) : 0,
      displayValue: value,
      isAmount: true,
    }),
  );

  return {
    main: {
      label: 'indicators.all',
      value: mainValue,
      isAmount: true,
      noAnimation: true,
    },
    child,
  };

};

const parseRelativeDate = (value = ''): RelativeDate => {
  const DEFAULT = { count: 0, unit: relativeUnits.seconds } as const;
  if (value === relativeUnits.now) return DEFAULT;

  const split = value.split('.');
  if (split.length !== 2) return DEFAULT;

  return { count: parseInt(split[0]), unit: split[1] as RelativeUnit };
};

export const initializeReport = (report): InitializedReport => {
  const chartType = report.type === reportTypes.dataPoints ?
    chartTypes.STACKED_CHART : chartTypes.STACKED_BAR_CHART;

  const initializedReport: InitializedReport = {
    ...report,
    from: report.from ? moment(report.from) : null,
    to: report.to ? moment(report.to) : null,
    loaded: false,
    loading: false,
    error: null,
    disabledProps: [],
    chartType,
  };

  if (report.fromRelative) {
    initializedReport.fromRelative = parseRelativeDate(report.fromRelative);
  }
  if (report.toRelative) {
    initializedReport.toRelative = parseRelativeDate(report.toRelative);
  }
  if (report.type === reportTypes.kpi && report.data) {
    initializedReport.data = {
      ...report.data,
      ...oeeHelper.calculateOee(report.data.oee),
    };
  }

  return initializedReport;
};


export const isAutoRefreshAvailable = (report: Report) => report.datesMode === datesModes.RELATIVE;

export const hasArchivedDataPoints = (dataPointFilters: Todo[] = []) => dataPointFilters
  .some((item) => item && item.dataPoint && item.dataPoint.archived);
