import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import ColumnDnd from '../column/Column.dnd';
import * as selectors from '../../../redux/selectors/maintenance.selector';
import {
  COLUMN_PRIORITIZED,
} from '../../../redux/constants/column.constants';
import messages from '../../../messages/MaintenanceColumnHeader.messages';

export function mapStateToProps(state) {
  const activities = selectors.getFilteredPrioritizedEvents(state);
  return {
    cards: activities,
    title: messages.planned,
    type: COLUMN_PRIORITIZED,
    noCards: isEmpty(activities),
    isDragging: selectors.isDragging(state),
  };
}

export default connect(mapStateToProps)(ColumnDnd);
