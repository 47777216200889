import React from 'react';
import PropTypes from 'prop-types';

export const MenuItem = ({
  close, onClick, label, isBold, icon, isIcon,
}) => {
  const handleClick = () => {
    onClick();
    close();
  };

  return (
    <li>
      <a
        className="filter-action"
        onClick={handleClick}
      >
        <span className="flex">
          { isIcon ? (<i className={icon} />) : '' }
          <span>{ isBold ? (<strong>{ label }</strong>) : label }</span>
        </span>
      </a>
    </li>
  );
};

MenuItem.propTypes = {
  close: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isBold: PropTypes.bool,
  isIcon: PropTypes.bool,
};

MenuItem.defaultProps = {
  isBold: false,
  isIcon: false,
};

export default MenuItem;
