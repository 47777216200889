import { createAction } from 'redux-act';

import * as entryTypes from '../constants/configRepoExplorer.entry.types.constants';

export const setActiveView = createAction(
  'set active view in config repo explorer entries',
  (entryType = entryTypes.DATATRONS) => entryType,
);

export const reducer = {
  [setActiveView]: (state, entryType) => ({
    ...state,
    configRepoExplorer: {
      ...state.configRepoExplorer,
      view: entryType,
    },
  }),
};
