import { createAction } from 'redux-act';

export const collapseExpandSummaryGroup = createAction(
  'expand or collapse summary group',
  (summaryGroupIndex) => summaryGroupIndex,
);

export const reducer = {
  [collapseExpandSummaryGroup]: (state, expandedRowIndex) => ({
    ...state,
    machine: {
      ...state.machine,
      summary: {
        ...state.machine.summary,
        expandedRowIndex,
      },
    },
  }),
};
