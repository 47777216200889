import React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { newLanguageField } from '../../../../../core/forms/profile.form.js';
import LanguageInputContainer from './LanguageInput.container';

export function ChangeLanguage() {
  return (
    <div className="form-block">
      <div className="h1">
        <FormattedMessage
          id="profile.changeLanguage"
          defaultMessage="Change Language"
        />
      </div>

      <label>
        <FormattedMessage
          id="profile.Language"
          defaultMessage="Language"
        />
        <Field name={newLanguageField} component={LanguageInputContainer} />
      </label>
    </div>
  );
}

ChangeLanguage.propTypes = {
};

export default ChangeLanguage;
