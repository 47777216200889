import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function EventActionsButtons({
  onClick,
  iconClass,
  buttonClass,
}) {
  const spanClassName = classnames('icon', iconClass);
  const buttonClassName = classnames('button white', buttonClass);

  return (
    <button
      type="button"
      className={buttonClassName}
      onClick={onClick}
    >
      <span className={spanClassName} />
    </button>
  );
}

EventActionsButtons.propTypes = {
  iconClass: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonClass: PropTypes.string,
};

EventActionsButtons.defaultProps = {
  buttonClass: '',
};
