import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReportComponent } from './report/Report';
import ReportsHeader from './ReportsHeader';
import NoReports from './NoReports';
import Loader from '../_tools/loader/Loader';
import ReportsTabsContainer from './tabs/ReportsTabs.container';
import Unauthorized from '../pages/unauthorized/Unauthorized';
import {
  onReportsEnter,
  onReportsLeave,
} from '../index/routeHandlers';

export const Reports = ({
  reports,
  hasNoReports,
  isLoading,
  children,
  authorized,
}) => {
  useEffect(() => {
    onReportsEnter();

    return () => {
      onReportsLeave();
    };
  }, []);

  if (!authorized) {
    return <Unauthorized />;
  }

  return (
    <div className="col--1">
      <ReportsHeader />
      <div className="reports--wrap--all">
        <ReportsTabsContainer />
        <div className="reports-wrap">
          <div className="reports">
            { reports.map((report) => (<ReportComponent report={report} key={report.id} />))}
          </div>
          { hasNoReports && !isLoading && <NoReports />}
          <Loader
            show={isLoading}
            solidBackground
          />
        </div>
      </div>
      { children }
    </div>
  );
};

Reports.propTypes = {
  reports: PropTypes.array.isRequired,
  hasNoReports: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  authorized: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default Reports;
