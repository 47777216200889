import React from 'react';

import RelativeInput from './relativeInput/RelativeInput';
import EditModalFooter from '../../../../modals/core/footers/EditModalFooter';
import { DATE_FROM, DATE_TO } from '../../../../../../../common/constants';

import { DEFAULT_RELATIVE } from '../../../../../redux/constants/relative.constants';
import { TodoFunction } from '../../../../../../../common/types/common';
import { RelativeDateProps } from '../../../../../core/propTypes/propTypes';
import { IntlMessage } from '../../../../../core/common/intl';

interface Props {
  cancel: TodoFunction
  confirm: TodoFunction
  useConfirmationFooter: boolean,
  pristine: boolean,
  setRelativeDate: TodoFunction
  fromRelative?: RelativeDateProps,
  toRelative?: RelativeDateProps,
  fromLabel: IntlMessage,
  toLabel: IntlMessage,
}

export const DropdownRelative: React.FC<Props> = ({
  confirm,
  cancel,
  useConfirmationFooter,
  fromRelative,
  toRelative,
  fromLabel,
  toLabel,
  setRelativeDate,
  pristine,
}) => {
  const handleFromChange = (value) => setRelativeDate(DATE_FROM, value);
  const handleToChange = (value) => setRelativeDate(DATE_TO, value);

  return (
    <div>
      <div className="datepicker-dropdowns--flex datepicker-relative">
        <div className="datepickers-wrap">
          <RelativeInput
            label={fromLabel}
            value={fromRelative || DEFAULT_RELATIVE}
            change={handleFromChange}
          />
          <RelativeInput
            label={toLabel}
            value={toRelative || DEFAULT_RELATIVE}
            change={handleToChange}
          />
        </div>
      </div>

      {useConfirmationFooter && (
        <EditModalFooter
          onCancelClick={cancel}
          onSaveClick={confirm}
          isSaveButtonDisabled={pristine}
        />
      )}
    </div>
  );
};

export default DropdownRelative;
