import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  CommandStatus,
} from '../../../../../common/types/api/config_repository';
import {
  startedListening,
  stoppedListening,
} from '../../../redux/modules/configRepo.callback.receive.module';
import {
  selectCallbackCommandId,
  selectCommandJournalMessages,
} from '../../../redux/selectors/modals.selector';
import { CommandJournalMessage } from "../../../../../common/types/api/callback";

interface Props {
  /** Callback to the parent component to tell it that the command is done */
  setDone: () => void;
}

const STATUS_COLOR: Record<CommandStatus, string | undefined> = {
  PENDING: undefined,
  SUCCESS: 'green',
  FAIL: 'red',
};

export const CommandJournalEntries: React.FC<Props> = ({ setDone }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // start listening for callbacks when component mounts
    dispatch(startedListening());
    return () => {
      // stop listening for callbacks when component unmounts
      dispatch(stoppedListening());
    };
  }, [dispatch]);

  const commandId: number | null = useSelector(selectCallbackCommandId);
  const messages = useSelector(selectCommandJournalMessages);

  const sortedMessages = useMemo(
    () => {
      const messagesSortedByTimestamp = [...messages].sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
      // After being sorted, we go backwards from the end and clear out all intermediate messages when the command has completed.
      const filteredMessages: CommandJournalMessage[] = [];
      let completedCommandName: string | null = null;
      for (let i = messagesSortedByTimestamp.length -1; i > 0; i--) {
        const messageToCheck = messagesSortedByTimestamp[i];
        if (messageToCheck.status !== 'PENDING') {
          completedCommandName = messageToCheck.commandName;
        }
        if (messageToCheck.status !== 'PENDING' || (completedCommandName === null || messageToCheck.commandName !== completedCommandName) ) {
          filteredMessages.unshift(messagesSortedByTimestamp[i]);
        }
      }
      return filteredMessages;
    },
    [messages],
  );

  // Send the message of completion to the Modal component
  useEffect(() => {
    // Tells the parent that the import is done
    if (messages.some(({ state }) => state === 'COMPLETED')) setDone();
  }, [setDone, messages]);

  if (commandId === null) return null;
  if (messages.length === 0) return null;

  return (
    <table>
      <thead>
        <tr>
          <FormattedMessage
            id="datatron.modals.timestamp_column_header"
            defaultMessage="Timestamp"
            tagName="th"
          />
          <FormattedMessage
            id="datatron.modals.command_name_column_header"
            defaultMessage="Command Name"
            tagName="th"
          />
          <FormattedMessage
            id="datatron.modals.journal_state_column_header"
            defaultMessage="Journal State"
            tagName="th"
          />
          <FormattedMessage
            id="datatron.modals.command_status_column_header"
            defaultMessage="Command Status"
            tagName="th"
          />
        </tr>
      </thead>
      <tbody>
        {sortedMessages.map(
          ({ commandJournalId, commandName, state, status, timestamp }) => (
            <tr key={commandJournalId}>
              <td>{new Date(timestamp).toTimeString()}</td>
              <td>{commandName}</td>
              <td>{state}</td>
              <td style={{ color: STATUS_COLOR[status] }}>{status}</td>
            </tr>
          ),
        )}
      </tbody>
    </table>
  );
};
