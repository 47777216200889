import React from 'react';

function Scheduled() {
  return (
    <div>
      <div className="scroll">
        <div className="history">
          <ul>
            <li>
              <span className="history-square success" />
              Planned
            </li>
            <li>
              <span className="history-square alert" />
              Over Capacity
            </li>
          </ul>
        </div>

        <div className="calendar alert-info">
          <div className="calendar--item">
            <div className="calendar--item--left">
              <strong className="week-name">Week 37</strong>
              <p>12.11.16/19.11.16</p>
              <div className="calendar-users">
                <span className="icon icon-person" />
                {' '}
                12
              </div>
            </div>
            <div className="calendar--item--right">
              <img src="/img/calendar.png" alt="" />
            </div>
          </div>

          <div className="calendar--item">
            <div className="calendar--item--left">
              <strong className="week-name">Week 37</strong>
              <p>12.11.16/19.11.16</p>
              <div className="calendar-users">
                <span className="icon icon-person" />
                {' '}
                12
              </div>
            </div>
            <div className="calendar--item--right">
              <img src="/img/calendar.png" alt="" />
            </div>
          </div>

          <div className="calendar--item">
            <div className="calendar--item--left">
              <strong className="week-name">Week 37</strong>
              <p>12.11.16/19.11.16</p>
              <div className="calendar-users">
                <span className="icon icon-person" />
                {' '}
                12
              </div>
            </div>
            <div className="calendar--item--right">
              <img src="/img/calendar.png" alt="" />
            </div>
          </div>

          <div className="calendar--item">
            <div className="calendar--item--left">
              <strong className="week-name">Week 37</strong>
              <p>12.11.16/19.11.16</p>
              <div className="calendar-users">
                <span className="icon icon-person" />
                {' '}
                12
              </div>
            </div>
            <div className="calendar--item--right">
              <img src="/img/calendar.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Scheduled.propTypes = {};

export default Scheduled;
