import React from 'react';
import PropTypes from 'prop-types';

import EventCommentInputContainer from './EventCommentInput.container';
import EventComment from './EventComment';

function EventComments({ comments, eventId, readOnly }) {
  return (
    <div className="feedbacks">
      <div className="feedback-write">
        {
          comments.map((comment) => (
            <EventComment
              comment={comment}
              key={comment.id}
            />
          ))
        }
        { (!(readOnly === true)) && (
          <EventCommentInputContainer eventId={eventId} />
        ) }
      </div>
    </div>
  );
}

EventComments.propTypes = {
  comments: PropTypes.array.isRequired,
  eventId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default EventComments;
