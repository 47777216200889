import React from 'react';
import { useDispatch } from 'react-redux';
import DevicesFiltersFrame from './DevicesFiltersFrame';
import {
  AI_APP_FORM_NAME,
  DEVICES_FILTERS_FIELD,
} from '../../../../../../redux/constants/aiApp.form.constants';
import { SUB_PAGE_AI_APP_FORM as modalId } from '../../../../../../redux/constants/modals.constants';
import { goBackModalPath } from '../../../../../../redux/modules/modals.module';
import { restoreFormField } from '../../../../../../redux/modules/common.form.module';

const DevicesFiltersFrameContainer: React.FC = () => {
  const dispatch = useDispatch();

  const handleCancelClick = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    dispatch(restoreFormField(AI_APP_FORM_NAME, DEVICES_FILTERS_FIELD));
    dispatch(goBackModalPath(modalId));
  };

  const handleDoneClick = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    dispatch(goBackModalPath(modalId));
  };

  return <DevicesFiltersFrame onCancelClick={handleCancelClick} onDoneClick={handleDoneClick} />;
};

export default DevicesFiltersFrameContainer;
