import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { compareById, replaceObjectInList } from '../../../../common/helpers/object';
import { closeModal } from './modals.module';
import { MEASUREMENT_ARCHIVE } from '../constants/modals.constants';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';
import {
  getMeasurementsFilters,
  getMeasurementsPagination,
} from '../selectors/experimentsPage.selector';
import { fetchMeasurements } from './measurements.fetch.module';

export const archiveMeasurement = createAction(
  'archive experiment',
  (measurementId) => measurementId,
);

export const archiveMeasurementSuccess = createAction(
  'archive experiment - success',
  (measurement) => measurement,
);

export const archiveMeasurementFail = createAction(
  'archive experiment - fail',
  (error) => error,
);

export const reducer = {
  [archiveMeasurementSuccess]: (state, measurement) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      measurements: {
        ...state.experimentsPage.measurements,
        list: replaceObjectInList(
          state.experimentsPage.measurements.list,
          measurement,
          compareById,
        ),
      },
    },
  }),
};

export function* archiveMeasurementSaga({ payload: measurementId }) {
  const { response, error } = yield call(api.experiments.archiveMeasurement, measurementId);

  if (response) {
    yield put(archiveMeasurementSuccess(response));
    yield put(closeModal(MEASUREMENT_ARCHIVE));
    yield put(sendNotification({ ...notificationMessages.measurement_archived }));

    const state = yield select();
    const pagination = yield call(getMeasurementsPagination, state);
    if (pagination) {
      const filters = yield call(getMeasurementsFilters, state);
      const newPagination = yield call(api.experiments.getMeasurementsPagination, filters);

      if (newPagination.response) {
        const pageToFetch = pagination.current_page > newPagination.response.pagination.total_pages ?
          newPagination.response.pagination.total_pages : pagination.current_page;
        yield put(fetchMeasurements(pageToFetch));
      } else {
        yield put(fetchMeasurements(pagination.current_page));
      }
    }
  } else {
    yield put(archiveMeasurementFail(error));
  }
}

export function* watchArchiveMeasurement() {
  yield takeEvery(archiveMeasurement.getType(), archiveMeasurementSaga);
}
