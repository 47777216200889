import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import placeholdersMessages from '../../../../messages/placeholders.messages';

function MissingReportDetails({ isSmall }) {
  const style = classnames(
    'nodata',
    { 'nodata-sm': isSmall },
  );

  return (
    <div className={style}>
      <FormattedMessage
        {...placeholdersMessages.noDataToDisplay}
        tagName="div"
      />
      <FormattedMessage
        id="reports.graph.please_add_a_selection_to_the_filter"
        defaultMessage="Please add a selection to the filter"
      />
    </div>
  );
}

MissingReportDetails.propTypes = {
  isSmall: PropTypes.bool.isRequired,
};

MissingReportDetails.defaultProps = {
  isSmall: false,
};

export default MissingReportDetails;
