import { connect } from 'react-redux';
import Profile from './Profile';
import { selectProfileLocation } from '../../../redux/selectors/profile.selector';
import { getLocale } from '../../../redux/selectors/locale.selector';
import { newLanguageField, newLocationField, profileForm } from '../../../core/forms/profile.form.js';
import { reduxForm } from 'redux-form';

export function mapStateToProps(state) {
  const location = selectProfileLocation(state);
  const initLocationId = (location && location.id) ? location.id : null;

  return {
    initialValues: {
      [newLanguageField]: getLocale(state),
      [newLocationField]: initLocationId,
    },
  };
}

export const form = {
  form: profileForm,
  destroyOnUnmount: false,
  enableReinitialize: true,
};

export default connect(mapStateToProps)(reduxForm(form)(Profile));
