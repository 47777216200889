import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';
import { reset as resetForm } from 'redux-form';

import { resetTempLocationPath } from './pathButton.tempLocationPath.write.module';
import { MACHINE_FORM_NAME } from '../constants/machine.form.constants';
import { MACHINE_FORM_PAGE } from '../constants/pathButton.constants';

export const reset = createAction(
  'reset machine form data',
);

export function* resetSaga() {
  yield put(resetForm(MACHINE_FORM_NAME));
  yield put(resetTempLocationPath(MACHINE_FORM_PAGE));
}

export function* watchReset() {
  yield takeEvery(reset.getType(), resetSaga);
}
