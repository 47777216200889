import { reduxForm } from 'redux-form';
import {
  DATATRON_DEVICE_FORM_NAME,
} from '../../../../redux/constants/datatron.device.form.constants';
import { DeviceFormModal } from './DeviceFormModal';

const form = {
  form: DATATRON_DEVICE_FORM_NAME,
};

export default reduxForm(form)(DeviceFormModal);
