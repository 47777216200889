import { defineMessages } from 'react-intl';

import * as datesModes from '../../../common/enums/dates.modes';

export default defineMessages({
  [datesModes.ABSOLUTE]: {
    id: 'dates.modes.absolute',
    defaultMessage: 'Absolute',
  },
  [datesModes.RELATIVE]: {
    id: 'dates.modes.relative',
    defaultMessage: 'Relative',
  },
});
