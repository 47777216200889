import {
  EVENTS_MANAGER, Role,
} from '../constants/permissions';

class EventsPermissions {
  private isEventsManager: boolean;

  constructor(roles: Role[]) {
    this.isEventsManager = roles.indexOf(EVENTS_MANAGER) !== -1;
  }

  canCreateActivities() {
    return this.isEventsManager;
  }

  canUpdateActivities() {
    return this.isEventsManager;
  }

  canAddEventToActivities() {
    return this.isEventsManager;
  }

  canCreateClustersByMachiche() {
    return this.isEventsManager;
  }

  canCreateEventsByMachine() {
    return this.isEventsManager;
  }

  canUpdateEvents() {
    return this.isEventsManager;
  }

  canCreateNewComments() {
    return this.isEventsManager;
  }
}

export default EventsPermissions;
