import React, { FormEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { FormField, TextareaFormField } from '../../../../../_tools';
import DatatronSelectContainer from '../../../../../_tools/formFields/select/DatatronSelect.container';
import InputButtonWithIcon from '../../../../../_tools/formFields/inputButtonWithIcon/InputButtonWithIcon.container';
import ModalLine from '../../../../core/ModalLine';
import AddModalFooter from '../../../../core/footers/AddModalFooter';
import * as form from '../../../../../../redux/constants/aiApp.form.constants';
import commonMessages from '../../../../../../messages/common.messages';
import commonCoreMessages from '../../../../../../messages/common.core.messages';
import formsPlaceholdersMessages from '../../../../../../messages/forms.placeholders.messages';
import {
  MODAL_MAIN_FRAME,
  MODAL_DEVICES_FILTERS_FRAME,
} from '../../../../../../core/common/aiApp.form';
import { SUB_PAGE_AI_APP_FORM as modalId } from '../../../../../../redux/constants/modals.constants';
import { setModalPath } from '../../../../../../redux/modules/modals.module';
import { cacheFormField } from '../../../../../../redux/modules/common.form.module';
import { getFormFields } from '../../../../../../redux/selectors/aiApp.form.selector';
import { search } from '../../../../../../redux/modules/search.module';
import { DATATRONS } from '../../../../../../../../common/constants/entities';

interface OwnProps {
  handleSubmit: (e: FormEvent) => void;
  handleCancel: () => void;
  submitting: boolean;
  pristine: boolean;
}

const ModalMainFrame: React.FC<OwnProps> = ({
  handleSubmit,
  handleCancel,
  submitting,
  pristine,
}) => {
  const formValues = useSelector(getFormFields);
  const selectedType = formValues[form.DATATRON_SELECT_FIELD];
  const dispatch = useDispatch();
  const canSelectDevice = !!selectedType?.value;

  const onDeviceFiltersClick = () => {
    dispatch(setModalPath(modalId, [MODAL_MAIN_FRAME, MODAL_DEVICES_FILTERS_FRAME]));
    dispatch(cacheFormField(form.AI_APP_FORM_NAME, form.DEVICES_FILTERS_FIELD));
  };

  useEffect(() => {
    dispatch(search('', true, [DATATRONS]));
  }, [dispatch]);

  return (
    <div className='modal-move modal-main'>
      <div className='modal-form-in'>
        <FormField
          label={commonMessages.name.id}
          name={form.NAME_FIELD}
          placeholder={formsPlaceholdersMessages.name.id}
          autoFocus
          tabIndex={1}
        />

        <label>
          <FormattedMessage {...commonCoreMessages.datatron} tagName='span' />
          <Field
            component={DatatronSelectContainer}
            name={form.DATATRON_SELECT_FIELD}
            tabIndex={2}
            iconClassName='icon icon-search'
          />
        </label>

        {canSelectDevice && (
          <>
            <div className='like-label search-block-category'>
              <FormattedMessage {...commonCoreMessages.devices} tagName='span' />
              <Field
                name={form.DEVICES_FILTERS_FIELD}
                component={InputButtonWithIcon}
                placeholder={commonCoreMessages.devices}
                onClick={onDeviceFiltersClick}
                iconClassName='icon icon-search'
                tabIndex={3}
              />
            </div>
          </>
        )}

        <div className='description'>
          <TextareaFormField
            label={commonCoreMessages.description.id}
            placeholder={commonCoreMessages.description.id}
            name={form.DESCRIPTION_FIELD}
            tabIndex={4}
            cols='30'
            rows='10'
            className='height--md'
          />
        </div>
      </div>
      <ModalLine />

      <AddModalFooter
        onCancelClick={handleCancel}
        onAddClick={handleSubmit}
        isAddButtonDisabled={pristine || submitting}
        isAddButtonLoading={submitting}
        cancelButtonTabIndex={5}
        addButtonTabIndex={6}
      />
    </div>
  );
};

export default ModalMainFrame;
