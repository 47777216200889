import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'redux-form';

import commonCoreMessages from '../../../../../../messages/common.core.messages';
import commonMessages from '../../../../../../messages/common.messages';
import toolsLogsMessages from '../../../../../../messages/tools.logs.messages';
import * as form from '../../../../../../redux/constants/tools.view.form.constants';
import { FormField, TextareaFormField } from '../../../../../_tools';
import LiveTimeOffset from '../../../../../_tools/liveTimeOffset/LiveTimeOffset.formField.container';

const DATE_TIME_FORMAT = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const MostRecentActivity = ({ intl }) => {
  const formatType = (value) => intl.formatMessage(toolsLogsMessages[value]);
  const formatTimestamp = (value) => intl.formatDate(value, DATE_TIME_FORMAT);

  return (
    <>
      <div className="form-subtitle">
        <FormattedMessage
          id="tools.modal.last_activity_details"
          defaultMessage="Last Activity Details"
        />
      </div>

      <div className="inputs--row">
        <div className="inputs--col--md2">
          <FormField
            label={commonCoreMessages.type.id}
            name={form.LA_TYPE}
            disabled
            format={formatType}
          />
        </div>
        <div className="inputs--col--md2">
          <FormField
            label={commonCoreMessages.machine.id}
            name={form.LA_MACHINE}
            disabled
          />
        </div>
      </div>

      <div className="inputs--row">
        <div className="inputs--col--md2">
          <FormField
            label={commonMessages.timestamp.id}
            name={form.LA_TIMESTAMP}
            disabled
            format={formatTimestamp}
          />
        </div>
        <div className="inputs--col--md2">
          <label>
            <FormattedMessage {...commonMessages.duration} />
            <div className="rel color-text fw-500">
              <Field
                name={form.LA_DURATION}
                component={LiveTimeOffset}
              />
            </div>
          </label>
        </div>
      </div>

      <TextareaFormField
        label={commonCoreMessages.comment.id}
        name={form.LA_COMMENT}
        cols="30"
        rows="10"
        maxLength="255"
        className="height--md"
        disabled
      />
    </>
  );
};

MostRecentActivity.propTypes = {

};

export default injectIntl(MostRecentActivity);
