import { defineMessages } from 'react-intl';

import { active, blocked } from '../../../common/enums/userStatuses';

export default defineMessages({
  [`status_${active}`]: {
    id: 'users.status.active',
    defaultMessage: 'Active',
  },
  [`status_${blocked}`]: {
    id: 'users.status.blocked',
    defaultMessage: 'Blocked',
  },
  signInCount: {
    id: 'users.info.signInCount',
    defaultMessage: 'Sign In Count',
  },
  lastSignInAt: {
    id: 'users.info.lastSignInAt',
    defaultMessage: 'Last Sign In at',
  },
  emailConfirmedAt: {
    id: 'users.info.emailConfirmedAt',
    defaultMessage: 'Email Confirmed at',
  },
  lastSignInIp: {
    id: 'users.info.lastSignInIp',
    defaultMessage: 'Last Sign In IP Address',
  },
  tosAccepted: {
    id: 'users.info.tosAccepted',
    defaultMessage: 'ToS Accepted',
  },
  assistanceMachines: {
    id: 'users.info.assistant_for_machines',
    defaultMessage: 'Assistant for Machines',
  },
  assistanceDatatrons: {
    id: 'users.info.assistant_for_datatrons',
    defaultMessage: 'Assistant for DATATRONs',
  },
});
