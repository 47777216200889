import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SelectedItem from './SelectedItem';
import ListItem from './ListItem';
import searchMessages from '../../../../messages/search.messages';

export const SelectedItems = ({
  type, list, onClick, onClickTitle, intl,
}) => {
  const title = intl.formatMessage(searchMessages[`selected_${type}`]);

  return (
    <div className="search-alls search-alls-sm">
      <div className="search-title">
        <ListItem onClick={onClickTitle} title={title} />
      </div>

      <ul className="search-alls-list">
        { list.map((value) => (
          <SelectedItem
            value={value}
            onClick={onClick}
            key={`${type}_${value.id}`}
          />
        ))}
      </ul>
    </div>
  );
};

SelectedItems.propTypes = {
  type: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickTitle: PropTypes.func.isRequired,

};

export default injectIntl(SelectedItems);
