import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Field } from 'redux-form';

import * as newAssistanceForm from '../../../../../redux/constants/machine.newAssistance.form.constants';
import { InputError } from '../../../../_tools';

export function NewAssistantComment({
  meta,
}) {
  const isError = meta.touched && meta.error;
  const className = classnames({ error: isError });

  return (
    <label>
      <FormattedMessage
        id="assistance.comment"
        defaultMessage="Comment"
      />
      <Field
        name={newAssistanceForm.ASSISTANT_COMMENT_NAME}
        component="textarea"
        cols="30"
        rows="10"
        className={className}
      />
      <div className="error-line">
        <InputError error={meta.error} />
      </div>
    </label>
  );
}

NewAssistantComment.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    touched: PropTypes.bool,
  }).isRequired,
};

export default NewAssistantComment;
