import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { getEventIndexById } from '../selectors/maintenance.selector';
import { insertActivity } from '../../core/common/maintenance.stream.js';
import { fetchMaintenanceCounts } from './maintenance.fetch.eventsCount.module';

export const createActivity = createAction(
  'create activity',
  (eventId, state, betweenCards) => ({ eventId, state, betweenCards }),
);

export const createActivitySuccess = createAction(
  'create activity success',
  (activity, betweenCards) => ({ activity, betweenCards }),
);

export const createActivityFail = createAction(
  'create activity fail',
  (eventId, error) => ({ eventId, error }),
);

export const reducer = {
  [createActivity]: (state, { eventId }) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      movedEvents: {
        ...state.maintenance.movedEvents,
        [eventId]: true,
      },
    },
  }),
  [createActivitySuccess]: (state,
    { activity, betweenCards: { belowCardId, aboveCardId } }) => {
    const newActivities = insertActivity(
      state.maintenance.activities.slice(), activity,
      aboveCardId, belowCardId,
    );

    let events = state.maintenance.events;
    const eventIndex = getEventIndexById(state, activity.events[0].id);

    if (eventIndex !== -1) {
      events = [
        ...events.slice(0, eventIndex),
        ...events.slice(eventIndex + 1),
      ];
    }

    return {
      ...state,
      maintenance: {
        ...state.maintenance,
        activities: newActivities,
        events,
        movedEvents: {
          ...state.maintenance.movedEvents,
          [activity.events[0].id]: false,
        },
      },
    };
  },
  [createActivityFail]: (state, { eventId }) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      movedEvents: {
        ...state.maintenance.movedEvents,
        [eventId]: false,
      },
    },
  }),
};

export function* createActivitySaga({ payload: { eventId, state, betweenCards } }) {
  const { response, error } = yield call(api.activities.postActivity, eventId, state);

  if (response) {
    yield put(fetchMaintenanceCounts());
    yield put(createActivitySuccess(response, betweenCards));
  } else {
    yield put(createActivityFail(eventId, error));
  }
}

export function* watchCreateActivity() {
  yield takeEvery(createActivity.getType(), createActivitySaga);
}
