import { connect } from 'react-redux';

import AdvancedUserForm from './AdvancedUserForm';
import * as selector from '../../../../../../../../redux/selectors/admin.users.selector';
import { selectAllRoles } from '../../../../../../../../redux/selectors/appConfig.selector';
import { getAllOrganizations } from '../../../../../../../../redux/selectors/admin.organizations.selector';
import { ROLES_PERMISSIONS } from '../../../../../../../../../../common/constants/permissions';
import PermissionedComponent from '../../../../../../../common/permissions/PermissionedComponent.container';

export const mapStateToProps = (state, { permissions }) => {
  const allRolesOptions = selectAllRoles(state).map((role) => ({
    label: role,
    value: role,
  }));
  const allOrganizationsOptions = getAllOrganizations(state).map((organization) => ({
    label: organization.name,
    value: organization.id,
  }));
  const user = selector.getUser(state);
  const allAssistanceMachinesOptions = (user.assistanceMachines || []).map((item) => ({
    label: item.machine.name,
    description: item.comment,
    value: item.machine.id,
  }));
  const hasRolesAccess = permissions.canManageRoles();

  return {
    allRolesOptions,
    allOrganizationsOptions,
    allAssistanceMachinesOptions,
    hasRolesAccess,
  };
};

export default PermissionedComponent(ROLES_PERMISSIONS)(connect(mapStateToProps)(AdvancedUserForm));
