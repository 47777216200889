import { createAction } from 'redux-act';
import {
  put, call, takeEvery, all,
} from 'redux-saga/effects';

import * as reportsApi from '../../core/api/reports.js';
import { fetchReportsEntry } from './reports.entry.fetch.module';
import { initializeReport } from '../../core/common/report';

export const fetchReports = createAction(
  'fetch all reports',
);

export const fetchReportsSuccess = createAction(
  'fetch all reports - success',
  (reports) => reports,
);

export const fetchReportsFail = createAction(
  'fetch all reports - failed',
  (error) => error,
);

export const reducer = {
  [fetchReports]: (state) => ({
    ...state,
    reports: {
      ...state.reports,
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [fetchReportsSuccess]: (state, reports = []) => ({
    ...state,
    reports: {
      ...state.reports,
      loaded: true,
      loading: false,
      error: null,
      data: reports.map(initializeReport),
    },
  }),

  [fetchReportsFail]: (state, error) => ({
    ...state,
    reports: {
      ...state.reports,
      loaded: false,
      loading: false,
      error,
    },
  }),
};

export function* fetchReportsSaga() {
  const { response, error } = yield call(reportsApi.fetch);

  if (response) {
    yield put(fetchReportsSuccess(response));
    yield all(response.map((report) => put(fetchReportsEntry(report.id))));
  } else {
    yield put(fetchReportsFail(error));
  }
}

export function* watchFetchReports() {
  yield takeEvery(fetchReports.getType(), fetchReportsSaga);
}
