import React from 'react';
import PropTypes from 'prop-types';

import AddCalendarModuleContainer from './calendarForm/AddCalendarModule.container';
import EditCalendarModuleContainer from './calendarForm/EditCalendarModule.container';
import EventRemoveModalContainer from './events/eventRemoveModal/EventRemoveModal.container';
import CalendarRemoveModalContainer from './calendarRemoveModal/CalendarRemoveModal.container';
import CalendarCloneModalContainer from './calendarCloneModal/CalendarCloneModal.container';
import EventEditModalContainer from './events/eventEditModal/EventEditModal.container';

export default function ModalsRouter({
  isNewCalendarModalOpen,
  isEditCalendarModalOpen,
  isEventRemoveModalOpen,
  isCalendarRemoveModalOpen,
  isCalendarCloneModalOpen,
  isEventEditModalOpen,
}) {
  return (
    <div>
      { isNewCalendarModalOpen && (<AddCalendarModuleContainer />) }
      { isEditCalendarModalOpen && (<EditCalendarModuleContainer />) }
      { isCalendarRemoveModalOpen && (<CalendarRemoveModalContainer />) }
      { isCalendarCloneModalOpen && <CalendarCloneModalContainer /> }
      { isEventEditModalOpen && <EventEditModalContainer /> }
      { isEventRemoveModalOpen && (<EventRemoveModalContainer />) }
    </div>
  );
}

ModalsRouter.propTypes = {
  isNewCalendarModalOpen: PropTypes.bool.isRequired,
  isEditCalendarModalOpen: PropTypes.bool.isRequired,
  isEventRemoveModalOpen: PropTypes.bool.isRequired,
  isCalendarRemoveModalOpen: PropTypes.bool.isRequired,
  isCalendarCloneModalOpen: PropTypes.bool.isRequired,
  isEventEditModalOpen: PropTypes.bool.isRequired,
};
