import React, { useState } from 'react';
import { FileDropZone } from './FileDropZone';
import { FileListDropBox } from './FileListDropBox';
import { useFileUpload } from './useFileUpload';
import { ShowMessage } from './ShowMessage';

interface FileUploaderProps {
  fileFormat: { fileType: string; accept: string };
  maxSize: number;
  nrFilesAllowed: number;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  files: File[];
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const DragNdrop: React.FC<FileUploaderProps> = React.memo(
  ({
    fileFormat,
    maxSize,
    nrFilesAllowed,
    setFiles,
    files,
    errorMessage,
    setErrorMessage,
  }: FileUploaderProps) => {
    const [warning, setWarning] = useState('');
    const { inputFile, onFileDrop, onFileChange, onRemoveFile } = useFileUpload({
      files,
      setFiles,
      setErrorMessage,
      fileType: fileFormat.fileType,
      maxSize,
      nrFilesAllowed,
      setWarning,
    });
    const preventDefault = (event: React.DragEvent<HTMLDivElement>) => event.preventDefault();

    return (
      <div className='modal-action-content'>
        <div className='drag-drop'>
          <div
            className={`document-uploader ${files.length > 0 ? 'upload-box active' : 'upload-box'}`}
            onDrop={onFileDrop}
            onDragOver={preventDefault}
          >
            <FileDropZone
              onFileChange={onFileChange}
              accept={fileFormat.accept}
              maxSize={maxSize}
              inputFile={inputFile}
              multiple={nrFilesAllowed > 1}
            />
            <FileListDropBox files={files} onRemoveFile={onRemoveFile} />
            <ShowMessage
              count={files.length}
              errorMessage={errorMessage}
              warningMessage={warning}
            />
          </div>
        </div>
      </div>
    );
  },
);
