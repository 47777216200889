import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import RemoveModalFooter from '../../core/footers/RemoveModalFooter';
import adminPageMessages from '../../../../messages/adminPage.messages';
import commonCoreMessages from '../../../../messages/common.core.messages';
import RemoveLocationError from './error/RemoveLocationError';

const handleSubmit = (e) => {
  e.preventDefault();
};

export const RemoveLocationModal = ({
  close,
  remove,
  error,
  location,
  intl,
}) => {
  const object = intl.formatMessage(commonCoreMessages.location);
  const modalTitle = intl.formatMessage(adminPageMessages.removeObject, { object });
  const warning = intl.formatMessage(adminPageMessages.removeObjectWarning, { object: location.name });
  const handleRemove = () => remove(location.id);
  const hasError = !!error;

  return (
    <BasicModal
      close={close}
      modalClass="modal--sm"
    >
      <div className="modal--content">
        <h3>{ modalTitle }</h3>

        <form className="form" onSubmit={handleSubmit}>
          <div className="modal--subtitle">
            <strong>{ warning }</strong>
          </div>
          { hasError && <RemoveLocationError location={location} error={error} /> }
          <div className="line" />
          <RemoveModalFooter
            onCancelClick={close}
            onRemoveClick={handleRemove}
            isRemoveButtonDisabled={hasError}
          />
        </form>
      </div>
    </BasicModal>
  );
};

RemoveLocationModal.propTypes = {
  close: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  error: PropTypes.object,

};

export default injectIntl(RemoveLocationModal);
