import { useDispatch } from 'react-redux';

import React from 'react';
import { GraphTabs } from '../../../common/cardGraph/GraphTabs';
import {
  ChartType,
  LINE_CHART,
  STACKED_BAR_CHART,
  STACKED_CHART,
} from '../../../../redux/constants/chart.type.constants';
import { setReportChartType } from '../../../../redux/modules/reports.graph.tabs.module';
import { Todo } from '../../../../../../common/types/common';

interface Props {
  id: Todo;
  chartType: ChartType;
}

export const ReportGraphTabsContainer: React.FC<Props> = ({ id, chartType }) => {
  const dispatch = useDispatch();

  return (
    <GraphTabs
      toLineChart={() => dispatch(setReportChartType(id, LINE_CHART))}
      toStackedChart={() => dispatch(setReportChartType(id, STACKED_CHART))}
      toStackedBarChart={() => dispatch(setReportChartType(id, STACKED_BAR_CHART))}
      isLineChartSelected={chartType === LINE_CHART}
      isStackedChartSelected={chartType === STACKED_CHART}
      isStackedBarChartSelected={chartType === STACKED_BAR_CHART}
      isStackedChartVisible={false}
    />
  );
};
