import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import RoleRow from './RoleRow';
import RolesHeader from './header/RolesHeader';

export const RolesModule = ({
  list, route, history,
}) => (
  <div className="user-table">
    <table className="table">
      <RolesHeader />
      <tbody>
        { list.map((item) => <RoleRow key={item.role} role={item} pushPath={history.push} />) }
      </tbody>
    </table>
    { renderRoutes(route.routes) }
  </div>
);

RolesModule.propTypes = {
  route: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
};

export default RolesModule;
