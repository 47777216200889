import React from 'react';
import { getChartComponentPayload, getDatesByReport, getUuid } from '../../../core/common/report.chart.v2';
import {
  BOTTOM_AXIS_OFFSET,
  DATA_OFFSET_ABOVE_TOP,
  LEFT_AXIS_WIDTH,
  MARGIN,
  RIGHT_AXIS_WIDTH,
  Y_AXIS_PADDING,
} from './chart.constants';
import { Chart } from './Chart';
import { Todo } from '../../../../../common/types/common';
import { ReportT } from '../../../../types/report';

interface Props {
  showAxisBottom,
  width: number;
  height: number;
  selection: Todo;
  report: ReportT;
}

export const ChartContainer: React.FC<Props> = ({
  showAxisBottom,
  width,
  height,
  selection,
  report,
}) => {
  const frameWidth = width - MARGIN.left - MARGIN.right;
  const frameWidthPadding = frameWidth - (2 * Y_AXIS_PADDING);
  const translate = `translate(${MARGIN.left}, ${MARGIN.top})`;
  let frameHeight = height - MARGIN.top - MARGIN.bottom;

  if (showAxisBottom) frameHeight -= BOTTOM_AXIS_OFFSET;

  const {
    leftAxisChartPoints,
    leftAxisEntries,
    leftAxisTicks,
    rightAxisChartPoints,
    rightAxisEntries,
    rightAxisTicks,
    xScale,
    helpLinesTicks,
  } = getChartComponentPayload({
    ...getDatesByReport(report),
    chartData: report.data.chartV2,
    selection,
    filters: report.filters.dataPoints,
    getId: getUuid,
    disabledEntriesIds: report.disabledProps,
    frameWidth: frameWidthPadding,
    frameHeight,
    verticalDataOffset: DATA_OFFSET_ABOVE_TOP,
  });

  return (
    <Chart
      leftAxisEntries={leftAxisEntries}
      rightAxisTicks={rightAxisTicks}
      xScale={xScale}
      leftAxisChartPoints={leftAxisChartPoints}
      frameWidth={frameWidth}
      frameHeight={frameHeight}
      rightAxisChartPoints={rightAxisChartPoints}
      rightAxisWidth={RIGHT_AXIS_WIDTH}
      leftAxisWidth={LEFT_AXIS_WIDTH}
      leftAxisTicks={leftAxisTicks}
      rightAxisEntries={rightAxisEntries}
      translate={translate}
      helpLinesTicks={helpLinesTicks}
      showVerticalLines={false}
    />
  );
};

export default ChartContainer;
