import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { getFiltersQuery } from '../selectors/admin.locations.selector';

export const fetchAdminLocationsPagination = createAction(
  'admin: fetch locations pagination',
  (page = 1) => page,
);

export const fetchAdminLocationsPaginationSuccess = createAction(
  'admin: fetch locations pagination - success',
  (pagination) => pagination,
);

export const fetchAdminLocationsPaginationFail = createAction(
  'admin: fetch locations pagination - fail',
  (error) => error,
);

export const reducer = {
  [fetchAdminLocationsPaginationSuccess]: (state, pagination) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        pagination,
      },
    },
  }),
};

export function* fetchAdminLocationsPaginationSaga({ payload: page }) {
  const state = yield select();
  const filters = yield call(getFiltersQuery, state);

  const { response, error } = yield call(api.admin.getLocationsPagination, { page, ...filters });

  if (response) {
    yield put(fetchAdminLocationsPaginationSuccess(response.pagination));
  } else {
    yield put(fetchAdminLocationsPaginationFail(error));
  }
}

export function* watchFetchAdminLocationsPagination() {
  yield takeEvery(fetchAdminLocationsPagination.getType(), fetchAdminLocationsPaginationSaga);
}
