import React from 'react';
import PropTypes from 'prop-types';

import { getAdminUserLink, getAdminEditUserLink } from '../../../index/routes';
import { HighlightText } from '../../../_tools';

export const UserRow = ({
  user,
  // onRemoveClick,
  nameToHighlight,
  emailToHighlight,
  pushPath,
}) => {
  const handleViewClick = () => pushPath(getAdminUserLink(user.id));
  const handleEditClick = () => pushPath(getAdminEditUserLink(user.id));
  // const handleRemoveClick = () => onRemoveClick(location);

  const organizationsLabel = (user && user.organizations && user.organizations.length) ?
    user.organizations.map((item) => item.name).join(', ') : '-';

  return (
    <tr>
      <td>
        <strong>
          <HighlightText text={user.name} highlight={nameToHighlight} />
        </strong>
      </td>
      <td>
        <strong>
          <HighlightText text={user.email} highlight={emailToHighlight} />
        </strong>
      </td>
      <td>
        <strong>{organizationsLabel}</strong>
      </td>
      <td className="td-actions">
        <button className="button light-grey-white" onClick={handleViewClick}>
          <i className="icon-eye" />
        </button>
        <button className="button light-grey-white" onClick={handleEditClick}>
          <i className="icon-edit" />
        </button>
        {
          /*
           <button className="button light-grey-white hide" onClick={handleRemoveClick}>
           <i className="icon-close2" />
           </button>
           */
        }
      </td>
    </tr>
  );
};

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
  // onRemoveClick: PropTypes.func.isRequired,
  pushPath: PropTypes.func.isRequired,
  nameToHighlight: PropTypes.string,
  emailToHighlight: PropTypes.string,
};

UserRow.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default UserRow;
