import { connect } from 'react-redux';

import Select from './Select';
import * as selector from '../../../../redux/selectors/machine.subscriptions.selector';
import { initializeSubscriptionAsDataPointOption } from '../../../../core/common/machine.subscription';

export const mapStateToProps = (state, { input, filter }) => {
  let subscriptions = selector.getSubscriptions(state);

  if (filter) {
    subscriptions = subscriptions.filter(filter);
  }

  return {
    options: subscriptions.map(initializeSubscriptionAsDataPointOption),
    onChange: (option) => input.onChange(option),
  };
};

export default connect(mapStateToProps)(Select);
