import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';

import messages from '../../../../../messages/dates.modes.messages';

export const DropdownTabsItem = ({
  type, isActive, select, intl,
}) => {
  const onClick = () => select(type);

  return (
    <li className={cn('tabs-title', isActive ? 'is-active' : '')}>
      <a onClick={onClick}>
        { intl.formatMessage(messages[type]) }
      </a>
    </li>
  );
};

DropdownTabsItem.propTypes = {
  type: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,

};

export default injectIntl(DropdownTabsItem);
