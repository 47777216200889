import { connect } from 'react-redux';

import ConfirmationModal from './ConfirmationModal';
import { closeModal } from '../../../redux/modules/modals.module';
import { CONFIRMATION } from '../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../redux/selectors/modals.selector';

export const mapStateToProps = (state) => ({
  ...getModalPayload(state, CONFIRMATION),
});

export const mapDispatchToProps = (dispatch) => ({
  close: () => {
    dispatch(closeModal(CONFIRMATION));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
