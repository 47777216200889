import { createAction } from 'redux-act';
import { call, takeEvery, all } from 'redux-saga/effects';

import * as api from '../../core/api';

export const adminUpdateUserRoles = createAction(
  'admin: update user roles',
  (userId, rolesToAdd = [], rolesToRemove = []) => ({ userId, rolesToAdd, rolesToRemove }),
);

export function* adminUpdateUserRolesSaga({ payload: { userId, rolesToAdd, rolesToRemove } }) {
  if (!userId) return;

  yield all([
    ...rolesToAdd.map((role) => call(api.admin.addUserRole, userId, role)),
    ...rolesToRemove.map((role) => call(api.admin.removeUserRole, userId, role)),
  ]);
}

export function* watchAdminUpdateUserRoles() {
  yield takeEvery(adminUpdateUserRoles.getType(), adminUpdateUserRolesSaga);
}
