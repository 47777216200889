import {
  apiRequest,
  deleteApiRequest,
  postApiRequest,
  patchApiRequest,
  postFormDataRequest,
  putApiRequest,
  putFormDataRequest,
} from './_tools';

export const getDeviceClasses = () => apiRequest('/api/device_classes');

export const getDeviceTypes = () => apiRequest('/api/device_types');
export const getDatatrons = () => apiRequest('/api/datatrons');
export const searchDatatrons = (q) =>
  apiRequest(`/api/datatrons/search?q=${q}`);
export const getDatatron = (datatronId) =>
  apiRequest(`/api/datatrons/${datatronId}`);
export const createDatatron = (payload) =>
  postFormDataRequest('/api/datatrons', payload);
export const updateDatatron = (id, payload) =>
  putFormDataRequest(`/api/datatrons/${id}`, payload);
export const deleteDatatronPhoto = (id) =>
  deleteApiRequest(`/api/datatrons/${id}/photo`);
export const getDatatronStats = (id, payload) =>
  postApiRequest(`/api/datatrons/${id}/stats`, payload);
export const activateDatatron = (datatronId: string) =>
  postApiRequest(`/api/datatrons/${datatronId}/activate`);
export const deactivateDatatron = (datatronId: string) =>
  postApiRequest(`/api/datatrons/${datatronId}/deactivate`);

export const addNewAssistant = (machineId, data) =>
  postApiRequest(`/api/datatrons/${machineId}/assistants`, data);
export const removeAssistant = (machineId, userId) =>
  deleteApiRequest(`/api/datatrons/${machineId}/assistants/${userId}`);

export const getDevices = (datatronId) =>
  apiRequest(`/api/datatrons/${datatronId}/devices`);
export const createDevice = (datatronId, payload) =>
  postApiRequest(`/api/datatrons/${datatronId}/devices`, payload);
export const updateDevice = (datatronId, deviceId, payload) =>
  putApiRequest(`/api/datatrons/${datatronId}/devices/${deviceId}`, payload);
export const archiveDevice = (datatronId, deviceId) =>
  deleteApiRequest(`/api/datatrons/${datatronId}/devices/${deviceId}`);
export const publishDevice = (datatronId, deviceId) =>
  postApiRequest(`/api/datatrons/${datatronId}/devices/${deviceId}/publish`);
export const downloadDevice = (datatronId, deviceId) =>
  apiRequest(`/api/datatrons/${datatronId}/devices/${deviceId}/download`);
export const importDevice = (datatronId, deviceId) =>
  postApiRequest(`/api/datatrons/${datatronId}/devices/${deviceId}/import`);
export const collectCommand = (datatronId, deviceId) =>
  postApiRequest(`/api/datatrons/${datatronId}/devices/${deviceId}/collect`);
export const stopCommand = (datatronId, deviceId) =>
  postApiRequest(`/api/datatrons/${datatronId}/devices/${deviceId}/stop`);
export const activateDevice = (datatronId, deviceId) =>
  postApiRequest(`/api/datatrons/${datatronId}/devices/${deviceId}/activate`);
export const deactivateDevice = (datatronId, deviceId) =>
  postApiRequest(`/api/datatrons/${datatronId}/devices/${deviceId}/deactivate`);
export const fetchDeviceCommandHistory = (
  datatronId: string,
  deviceId: string,
  queryString: string,
) =>
  apiRequest(
    `/api/datatrons/${datatronId}/devices/${deviceId}/commands?${queryString}`,
  );

export const getDataPoints = ({
  datatronId,
  deviceId,
  includeSubscriptionInfo = false,
  archived = false,
}) => {
  const query = new URLSearchParams({
    includeSubscriptionInfo: includeSubscriptionInfo ? 'true' : '',
    archived: archived ? 'true' : '',
  });
  return apiRequest(
    `/api/datatrons/${datatronId}/devices/${deviceId}/data_points?${query}`,
  );
};

export const createDataPoint = ({ datatronId, deviceId, payload }) =>
  postApiRequest(
    `/api/datatrons/${datatronId}/devices/${deviceId}/data_points`,
    payload,
  );

export const updateDataPoint = ({
  datatronId,
  deviceId,
  dataPointId,
  payload,
}) =>
  putApiRequest(
    `/api/datatrons/${datatronId}/devices/${deviceId}/data_points/${dataPointId}`,
    payload,
  );

export const archiveDataPoint = ({ datatronId, deviceId, dataPointId }) =>
  deleteApiRequest(
    `/api/datatrons/${datatronId}/devices/${deviceId}/data_points/${dataPointId}`,
  );


export const updateBulkDataPoints = ({
  datatronId,
  deviceId,
  payload,
}) =>
  patchApiRequest(
    `/api/datatrons/${datatronId}/devices/${deviceId}/data_points/bulk`,
    payload,
  );
export const getDataPointMetric = ({
  datatronId,
  deviceId,
  dataPointId,
  startTimestamp,
}) => {
  const query = new URLSearchParams({
    startTimestamp,
  });
  return apiRequest(
    `/api/datatrons/${datatronId}/devices/${deviceId}/data_points/${dataPointId}/data?${query}`,
  );
};

export const downloadDatapoints = ({
  datatronId,
  deviceId,
}: { datatronId: string, deviceId: string }) => apiRequest(
  `/api/datatrons/${datatronId}/devices/${deviceId}/download-datapoints`,
);

export const uploadDatapoints = ({
  datatronId,
  deviceId,
  file,
}) =>  postFormDataRequest(
  `/api/datatrons/${datatronId}/devices/${deviceId}/upload-datapoints`, { file },
);


