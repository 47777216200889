import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Loader from '../../_tools/loader/Loader';
import BatchDetailsPageHeaderContainer from './BatchDetailsPageHeader.container';
import ProcessingsContainer from './processings/Processings.container';
import * as routeHandlers from '../../index/routeHandlers';

function BatchDetailsPage({
  batch, loaded, loading, match,
}) {
  // TODO: fix me, avoid suppressing the linter
  useEffect(() => {
    routeHandlers.onBatchDetailsPageEnter(match);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="col--1 non-compare">
      <BatchDetailsPageHeaderContainer />
      { loaded && (
        <div className="col--in">

          <div className="col--title">
            <FormattedMessage
              id="products.batch.processings.title"
              defaultMessage="Processings of {name} ({serial})"
              values={batch}
            />
          </div>

          <div className="scroll-block without-scroll">
            <ProcessingsContainer />
          </div>
        </div>
      ) }
      <Loader
        show={loading}
        solidBackground
      />
    </div>
  );
}

BatchDetailsPage.propTypes = {
  batch: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default BatchDetailsPage;
