import { CommandJournalMessage } from '../../../../common/types/api/callback';

export type CommandJournalMessageState = {
  /** Determines if command journal messages should be collected. When false,
   * all messages are discarded.
   */
  listening: boolean;
  /** Sets the command-id as soon as it is received from the API. */
  commandId: number | null;
  /** The list of collected command journal messages. Command journal messages
   * are only collected when listening is true. When the command-id is null all
   * messages are collected to make sure that none of them are missed. Once the
   * command-id is set, only messages with the same command-id are collected and
   * the current list is filtered.
   */
  messages: CommandJournalMessage[];
};

export const initial: CommandJournalMessageState = {
  listening: false,
  commandId: null,
  messages: [],
};

export const testState: CommandJournalMessageState = {
  listening: true,
  commandId: 1,
  messages: [
    {
      commandId: 1,
      commandName: 'config',
      commandJournalId: 17,
      state: 'CREATED',
      status: 'PENDING',
      timestamp: new Date('2021-01-01T00:00:00.000Z'),
    },
  ],
};
