import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { isEqual } from 'lodash';

import DatePickerMainButtons from './DatePickerMainButtons';
import Dropdown from './dropdown/Dropdown';
import { DATE_FROM, DATE_TO } from '../../../../../common/constants';
import { DEFAULT_RELATIVE } from '../../../redux/constants/relative.constants';
import commonMessages from '../../../messages/common.messages';
import { DateProps, RelativeDateProps } from '../../../core/propTypes/propTypes';
import { Todo, TodoFunction } from '../../../../../common/types/common';

const DELAY_BEFORE_CLOSE = 700;

interface Props {
  [DATE_FROM]: DateProps, // eslint-disable-line react/no-unused-prop-types
  [DATE_TO]: DateProps, // eslint-disable-line react/no-unused-prop-types
  fromRelative: RelativeDateProps,
  toRelative: RelativeDateProps,
  datesMode: string,
  onUpdate: TodoFunction,
  keepDropdownAlwaysOpen?: boolean,
  useConfirmationFooter?: boolean,
  showDatesModeBar?: boolean,
  showAbsoluteDatesPresets?: boolean,
  disabled?: boolean,
  shortPreview?: boolean,
  tabIndex?: number,
  fromLabel?: Todo,
  toLabel?: Todo,
  allowFuture?: boolean,
}

export const DatePicker: React.FC<Props> = ({
  allowFuture = false,
  disabled = false,
  shortPreview = false,
  keepDropdownAlwaysOpen = false,
  useConfirmationFooter = false,
  showDatesModeBar = true,
  showAbsoluteDatesPresets = false,
  fromRelative = DEFAULT_RELATIVE,
  toRelative = DEFAULT_RELATIVE,
  fromLabel = commonMessages.from,
  toLabel = commonMessages.to,
  datesMode,
  [DATE_TO]: date_to,
  [DATE_FROM]: date_from,
  onUpdate,
  tabIndex,
}) => {


  const [isOpen, isOpenSet] = useState(false);
  const [preSelected, preSelectedSet] = useState({
    [DATE_FROM]: date_from ? moment(date_from) : null,
    [DATE_TO]: date_to ? moment(date_to) : null,
    fromRelative,
    toRelative,
  });
  const [datesModeState, datesModeStateSet] = useState(datesMode);

  let debounceId: Todo = useRef<Todo>(null);

  const isPristine = useCallback(() => {
    const datesModePristine = datesMode === datesModeState;

    const fromRelativePristine = isEqual(fromRelative, preSelected.fromRelative);
    const toRelativePristine = isEqual(toRelative, preSelected.toRelative);

    const fromPristine = moment.isMoment(date_from) ?
      moment(date_from).isSame(preSelected[DATE_FROM]) :
      isEqual(date_from, preSelected[DATE_FROM]);
    const toPristine = moment.isMoment(date_to) ?
      moment(date_to).isSame(preSelected[DATE_TO]) :
      isEqual(date_to, preSelected[DATE_TO]);

    return datesModePristine
      && fromRelativePristine
      && toRelativePristine
      && fromPristine
      && toPristine;
  }, [date_from, date_to, datesMode, datesModeState, fromRelative, preSelected, toRelative]);

  const handleSave = useCallback(() => {

    if (isPristine()) return;
    onUpdate({ ...preSelected, datesModeState });
  }, [datesModeState, isPristine, onUpdate, preSelected]);

  useEffect(() => {
    if (!isOpen || keepDropdownAlwaysOpen) {
      handleSave();
    }
  }, [handleSave, isOpen, keepDropdownAlwaysOpen]);

  const setAbsoluteDates = useCallback((fromDate, toDate) => {
    preSelectedSet({
      ...preSelected,
      [DATE_FROM]: fromDate ? moment(fromDate) : null,
      [DATE_TO]: toDate ? moment(toDate) : null,
    });
  }, [preSelected]);

  const setRelativeDates = useCallback((fromDate, toDate) => {
    preSelectedSet({
      ...preSelected,
      fromRelative: fromDate || null,
      toRelative: toDate || null,
    });
  }, [preSelected]);

  const open = useCallback(() => {
    isOpenSet(true);
  }, []);

  const close = useCallback(() => {
    isOpenSet(false);
  }, []);


  const handleCancel = useCallback(() => {
    isOpenSet(false);
    datesModeStateSet(datesMode);
    preSelectedSet({
      [DATE_FROM]: date_from ? moment(date_from) : null,
      [DATE_TO]: date_to ? moment(date_to) : null,
      fromRelative: fromRelative,
      toRelative: toRelative,
    });
  }, [date_from, date_to, datesMode, fromRelative, toRelative]);

  const toggle = useCallback(() => {
    if (!keepDropdownAlwaysOpen) return;

    isOpenSet(!isOpen);
  }, [isOpen, keepDropdownAlwaysOpen]);

  const runWithDebounce = useCallback((func: TodoFunction, delay) => {
    debounceId.current = setTimeout(func, delay);
  }, []);

  const preventDebounceAction = useCallback(() => {
    if (debounceId.current) clearTimeout(debounceId.current);
  }, []);

  const setDatesMode = useCallback((newDatesMode) => {
    datesModeStateSet(newDatesMode);
  }, []);

  const setAbsoluteDate = useCallback((dateType, selectedDate, selectedDateOnly = false) => {
    let newDateFrom = preSelected[DATE_FROM];
    let newDateTo = preSelected[DATE_TO];

    if (dateType === DATE_FROM) {
      newDateFrom = selectedDate;
      if (newDateFrom && newDateTo && moment(newDateFrom).isAfter(moment(newDateTo))) {
        newDateTo = newDateFrom;
      }
    } else if (dateType === DATE_TO) {
      newDateTo = selectedDate;
      if (newDateFrom && newDateTo && moment(newDateTo).isBefore(moment(newDateFrom))) {
        newDateFrom = newDateTo;
      }
      if (selectedDateOnly) {
        newDateTo = moment(newDateTo).endOf('day');
      }
    }

    setAbsoluteDates(newDateFrom, newDateTo);
    // runWithDebounce(toggle, DELAY_BEFORE_CLOSE);
  }, [preSelected, setAbsoluteDates]);

  const setRelativeDate = useCallback((dateType, selectedDate) => {
    let newDateFrom = preSelected.fromRelative;
    let newDateTo = preSelected.toRelative;

    if (dateType === DATE_FROM) {
      newDateFrom = selectedDate;
      if (newDateFrom
        && newDateTo
        // @ts-ignore
        && moment()
          .subtract(newDateFrom.count, newDateFrom.unit)
          // @ts-ignore
          .isAfter(moment().subtract(newDateTo.count, newDateTo.unit))
      ) {
        newDateTo = newDateFrom;
      }
    } else if (dateType === DATE_TO) {
      newDateTo = selectedDate;
      if (newDateFrom
        && newDateTo
        // @ts-ignore
        && moment()
          .subtract(newDateTo.count, newDateTo.unit)
          // @ts-ignore
          .isBefore(moment().subtract(newDateFrom.count, newDateFrom.unit))
      ) {
        newDateFrom = newDateTo;
      }
    }

    setRelativeDates(newDateFrom, newDateTo);
    runWithDebounce(toggle, DELAY_BEFORE_CLOSE);
  }, [preSelected, runWithDebounce, setRelativeDates, toggle]);

  return (
    <div className="datepicker-block large">
      <DatePickerMainButtons
        isOpen={isOpen}
        onClick={open}
        disabled={disabled}
        shortPreview={shortPreview}
        datesMode={datesModeState}
        tabIndex={tabIndex}
        {...preSelected}
      />
      {(isOpen || keepDropdownAlwaysOpen) && (
        <Dropdown
          closeDropdown={close}
          setDatesMode={setDatesMode}
          setAbsoluteDate={setAbsoluteDate}
          // @ts-ignore
          setAbsoluteDates={setAbsoluteDates}
          setRelativeDate={setRelativeDate}
          onCalendarHover={preventDebounceAction}
          cancel={handleCancel}
          keepDropdownAlwaysOpen={keepDropdownAlwaysOpen}
          useConfirmationFooter={useConfirmationFooter}
          showDatesModeBar={showDatesModeBar}
          showAbsoluteDatesPresets={showAbsoluteDatesPresets}
          datesMode={datesModeState}
          pristine={isPristine()}
          fromLabel={fromLabel}
          toLabel={toLabel}
          allowFuture={allowFuture}
          {...preSelected}
        />
      )}
    </div>
  );
};

export default DatePicker;