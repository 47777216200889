import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../_tools/button/Button';

export const MachineCalendarsAddButton = ({ openNewCalendarModal }) => (
  <Button className="button green" onClick={openNewCalendarModal}>
    <span className="fa fa-plus" />
    <FormattedMessage
      id="calendars.buttons.add_calendar"
      defaultMessage="Add Calendar"
    />
  </Button>
);

MachineCalendarsAddButton.propTypes = {
  openNewCalendarModal: PropTypes.func.isRequired,
};

export default MachineCalendarsAddButton;
