import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';

import messages from '../../../messages/tool.states.messages';
import * as toolStates from '../../../../../common/enums/toolStates';

export const stateToClassName = {
  [toolStates.NEW]: 'success',
  [toolStates.MOUNTED]: 'info',
  [toolStates.USED]: 'error',
  [toolStates.EXHAUSTED]: 'alert',
};

export const ToolStateLabel = ({ state, intl }) => {
  const className = cn('label', stateToClassName[state]);
  const label = intl.formatMessage(messages[state]);

  return (
    <div className={className}>
      <span>{ label }</span>
    </div>
  );
};

ToolStateLabel.propTypes = {
  state: PropTypes.string.isRequired,

};

export default injectIntl(ToolStateLabel);
