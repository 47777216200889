import { createAction } from 'redux-act';

import * as chartTypes from '../constants/chart.type.constants';

export const toLineChart = createAction(
  'switch to line chart',
);

export const toStackedChart = createAction(
  'switch to stacked chart',
);

export const toStackedBarChart = createAction(
  'switch to stacked bar chart',
);

export const reducer = {
  [toLineChart]: (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      chartType: chartTypes.LINE_CHART,
    },
  }),

  [toStackedChart]: (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      chartType: chartTypes.STACKED_CHART,
    },
  }),

  [toStackedBarChart]: (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      chartType: chartTypes.STACKED_BAR_CHART,
    },
  }),
};
