import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function Title({
  canRemoveCalendar,
  calendarName,
}) {
  if (!canRemoveCalendar) {
    return (
      <FormattedMessage
        id="calendars.modals.remove.title.not_allowed"
        defaultMessage="Calendar {name} cannot be removed"
        tagName="h3"
        values={{
          name: calendarName,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      id="calendars.modals.remove.title"
      defaultMessage="Do you really want to remove that calendar?"
      tagName="h3"
    />
  );
}

Title.propTypes = {
  canRemoveCalendar: PropTypes.bool.isRequired,
  calendarName: PropTypes.string.isRequired,
};

export default Title;
