import { createAction } from 'redux-act';

export const setAppConfig = createAction(
  'set application config',
  (newConfig) => newConfig,
);

export const reducer = {
  [setAppConfig]: (state, newConfig) => ({
    ...state,
    appConfig: {
      ...state.appConfig,
      ...newConfig,
    },
  }),
};
