import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import commonMessages from '../../../../messages/common.messages';
import { Button } from '../../../_tools';

const Footer = ({
  onCancelClick,
  onDoneClick,
  isDoneButtonDisabled,
  isDoneButtonLoading,
  hideCancelButton,
  doneButtonIntl,
}) => (
  <div className={cn('modal-buttons', { 'justify-right': hideCancelButton })}>
    <Button
      type="button"
      className={cn('button__cancel', { hide: hideCancelButton })}
      onClick={onCancelClick}
    >
      <FormattedMessage {...commonMessages.cancel} />
    </Button>
    <Button
      type="submit"
      className="button__save"
      onClick={onDoneClick}
      disabled={isDoneButtonDisabled}
      showLoader={isDoneButtonLoading}
    >
      <FormattedMessage {...doneButtonIntl} />
    </Button>
  </div>
);

Footer.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  doneButtonIntl: PropTypes.object,
  isDoneButtonDisabled: PropTypes.bool,
  isDoneButtonLoading: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
};

Footer.defaultProps = {
  isDoneButtonDisabled: false,
  isDoneButtonLoading: false,
  hideCancelButton: false,
  doneButtonIntl: commonMessages.done,
};

export default Footer;
