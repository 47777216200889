import React from 'react';
import PropTypes from 'prop-types';

import TabsBar from '../../common/tabsBar/TabsBar';
import stopPropagation from '../../_tools/stopPropagation';

export const ReportsTabs = ({
  tabs,
  selectedTabId,
  selectTab,
  openAddTabModal,
  openEditTabModal,
  openRemoveTabModal,
}) => {
  const list = tabs.map((tab) => ({
    title: tab.name,
    count: tab.count || 0,
    hasCount: true,
    isActive: tab.id === selectedTabId,
    onClick: () => selectTab(tab.id),
    showEditButton: true,
    showRemoveButton: true,
    onEditButtonClick: (e) => {
      stopPropagation(e);
      openEditTabModal(tab);
    },
    onRemoveButtonClick: (e) => {
      stopPropagation(e);
      openRemoveTabModal(tab);
    },
  }));

  return (
    <TabsBar
      list={list}
      canAddTab
      onAddClick={openAddTabModal}
    />
  );
};

ReportsTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectedTabId: PropTypes.string,
  selectTab: PropTypes.func.isRequired,
  openAddTabModal: PropTypes.func.isRequired,
  openEditTabModal: PropTypes.func.isRequired,
  openRemoveTabModal: PropTypes.func.isRequired,
};

export default ReportsTabs;
