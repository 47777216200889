import { connect } from 'react-redux';

import MachineCalendarsAddButton from './MachineCalendarsAddButton';
import { CP_NEW_CALENDAR } from '../../redux/constants/modals.constants';
import { openModal } from '../../redux/modules/modals.module';

export const mapDispatchToProps = (dispatch) => ({
  openNewCalendarModal: () => dispatch(openModal(CP_NEW_CALENDAR)),
});

export default connect(null, mapDispatchToProps)(MachineCalendarsAddButton);
