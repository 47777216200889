import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import EntriesList from './EntriesList';
import adminPageMessages from '../../../../../messages/adminPage.messages';
import commonCoreMessages from '../../../../../messages/common.core.messages';

export const RemoveOrganizationError = ({
  organization,
  error,
  intl,
}) => {
  const errorMessage = intl.formatMessage(
    adminPageMessages.cantRemoveError,
    {
      object: organization.name,
      error: intl.formatMessage({ id: error.reason }),
    },
  );

  return (
    <div>
      <br />
      <span className="alert alert-danger">{ errorMessage }</span>
      <br />
      { !!error.machines && error.machines.length > 0 && (
        <EntriesList
          label={intl.formatMessage(commonCoreMessages.machines)}
          list={error.machines}
        />
      )}
      { !!error.datatrons && error.datatrons.length > 0 && (
        <EntriesList
          label={intl.formatMessage(commonCoreMessages.datatrons)}
          list={error.datatrons}
        />
      )}
      { !!error.members && error.members.length > 0 && (
        <EntriesList
          label={intl.formatMessage(commonCoreMessages.users)}
          list={error.members}
        />
      )}
    </div>
  );
};

RemoveOrganizationError.propTypes = {
  organization: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,

};

export default injectIntl(RemoveOrganizationError);
