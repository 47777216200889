import keycloak from '../../auth/keycloakClient';
import { Json, Todo } from '../../../../common/types/common';
import { z } from 'zod';

export const IS_API = '@@API';

export const HEADERS = {
  JSON: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export function isApiCallResult(result) {
  return !!(result && result[IS_API]);
}

/** An expected success from the API */
interface ParsedResponse<TData> {
  success: true;
  type: 'parsed';
  status: number;
  data: TData;
}

/** An unexpected error (like invalid session or malformed data) */
interface GeneralErrorResponse {
  success: false;
  type: 'generalError';
  status: number;
  error: string;
}

interface ParseErrorResponse<TInput> {
  success: false;
  type: 'parseError';
  status: number;
  error: z.ZodError<TInput>;
}

export type ApiResponse<TSchema extends z.ZodTypeAny> =
  | ParsedResponse<z.output<TSchema>>
  | GeneralErrorResponse
  | ParseErrorResponse<z.input<TSchema>>;

export const apiRequestWithSchema = async <TDataSchema extends z.ZodTypeAny>(
  url: string,
  dataSchema: TDataSchema,
  options: Todo = {},
): Promise<ApiResponse<TDataSchema>> => {
  const validSession = await keycloak
    .updateToken(30) // TODO: this could be made configurable
    .then(() => true)
    .catch(() => false);

  if (!validSession) {
    return {
      success: false,
      type: 'generalError',
      status: 401,
      error: 'session expired',
    };
  }

  const token = keycloak.token;
  if (!token) {
    return {
      success: false,
      type: 'generalError',
      status: 401,
      error: 'no token',
    };
  }

  const passedHeaders = options ? options.headers : {};
  const optionsNoCache = {
    ...options,
    headers: {
      ...passedHeaders,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'x-auth-token': `Bearer ${token}`,
    },
  };

  const response = await fetch(url, optionsNoCache);

  return response
    .json()
    .then((data): ApiResponse<z.infer<TDataSchema>> => {
      const safeParsed = dataSchema.safeParse(data);
      if (safeParsed.success) {
        return {
          success: true,
          type: 'parsed',
          status: response.status,
          data: safeParsed.data,
        };
      }
      console.error('API response did not match schema', safeParsed.error);
      return {
        success: false,
        type: 'parseError',
        status: response.status,
        error: safeParsed.error,
      };
    })
    .catch((error) => ({
      success: false,
      type: 'generalError',
      status: response.status,
      error: `could not parse response body as json: ${error.message}`,
    }));
};

// TODO: try to remove this function and use apiRequestWithSchema instead. It's a temporary solution to avoid breaking changes.
export const apiRequest = (url: string, options: Todo = {}) => {
  const passedHeaders = options ? options.headers : {};
  const optionsNoCache = {
    ...options,
    headers: {
      ...passedHeaders,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
  };
  return keycloak
    .updateToken(30) // TODO: this could be made configurable
    .then(() => {
      optionsNoCache.headers = {
        ...optionsNoCache.headers,
        'x-auth-token': `Bearer ${keycloak.token}`,
      };
      return fetch(url, Object.assign({}, optionsNoCache, { credentials: 'include' }));
    })
    .then((response: Todo) => {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('image') !== -1) {
        return response;
      }

      if (
        contentType &&
        contentType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') !==
          -1
      ) {
        return response;
      }

      if (response.ok) {
        return response
          .json()
          .then((data) => ({ response: data, status: response.status }))
          .catch(() => ({ response: {}, status: response.status })); // If the json can't be parsed, return an empty object for the response
      }

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response
          .json()
          .then((data: Json) => ({
            [IS_API]: true,
            error: data,
            status: response.status,
          }))
          .catch(() => ({
            response: {},
            status: response.status,
          })); // If the json can't be parsed, return an empty object for the response
      }
      return response.text().then((text) => ({
        [IS_API]: true,
        error: text,
        status: response.status,
      }));
    });
};

export function putApiRequest(url, body, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'PUT',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
    },
    body: JSON.stringify(body),
  });
}

export function putFormDataRequest(url, data) {
  const formData = new FormData(); // eslint-disable-line no-undef

  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  return apiRequest(url, {
    method: 'PUT',
    body: formData,
  });
}

export function patchApiRequest(url, body = {}, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'PATCH',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
    },
    body: JSON.stringify(body),
  });
}

export function postApiRequest(url, body = {}, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'POST',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
    },
    body: JSON.stringify(body),
  });
}

export function postFormDataRequest(url, data) {
  const formData = new FormData(); // eslint-disable-line no-undef

  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  return apiRequest(url, {
    method: 'POST',
    body: formData,
  });
}

export function postUploadRequest(url, files) {
  const data = new FormData(); // eslint-disable-line no-undef
  data.append('files', files);

  return apiRequest(url, {
    method: 'POST',
    body: data,
  });
}

export function deleteApiRequest(url, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'DELETE',
    headers: options.headers,
  });
}
