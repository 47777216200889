import querystring from 'querystring';
import { apiRequest, postApiRequest } from './_tools';
import { Todo } from '../../../../common/types/common';

export const fetch = (payload) => {
  const query = querystring.stringify(payload);
  return apiRequest(`/api/ai-apps?${query}`);
};

export const fetchOne = (aiAppId: string) => apiRequest(`/api/ai-apps/${aiAppId}`);

export const createCycleDetection = (aiAppId: string, body: Todo) =>
  postApiRequest(`/api/ai-apps/${aiAppId}/cycle-detections`, body);

export const fetchCycleDetection = (cycleDetectionId: string) =>
  apiRequest(`/api/cycle-detections/${cycleDetectionId}`);

export const triggerCycleDetection = (cycleDetectionId: string) =>
  postApiRequest(`/api/cycle-detections/${cycleDetectionId}/trigger`);

export const triggerAnomalyModel = (cycleDetectionId: string, anomalyModelId: string) =>
  postApiRequest(`/api/cycle-detections/${cycleDetectionId}/anomalies/${anomalyModelId}/trigger`);

export const create = (body: Todo) => postApiRequest('/api/ai-apps', body);

export const createAIAppAnomaly = (aiAppId: string, body: Todo) =>
  postApiRequest(`/api/cycle-detections/${aiAppId}/anomalies`, body);

export const syncAnomalies = (aiAppId: string, body: Todo) =>
  postApiRequest(`/api/ai-apps/${aiAppId}/anomalies/sync`, body);
