import React from 'react';
import PropTypes from 'prop-types';

export const TimezoneButton = ({ onClick }) => (
  <button className="timer-button" onClick={onClick}>
    <i className="icon-counterclockwise" />
  </button>
);

TimezoneButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TimezoneButton;
