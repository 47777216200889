import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OUMember from './OUMember';

export default function OUMembers({ members, name }) {
  return (
    <div className="col--2--left">
      <div className="col--in">

        <div className="col--title">
          { name }
        </div>

        <div className="scroll-block">
          <table className="table">
            <thead>
              <tr>
                <td className="td-icon" />
                <td>
                  <FormattedMessage
                    id="teams.name"
                    defaultMessage="Name"
                  />
                </td>
                <td>
                  <FormattedMessage
                    id="teams.position"
                    defaultMessage="Position"
                  />
                </td>
                <td>
                  <FormattedMessage
                    id="teams.email"
                    defaultMessage="Email"
                  />
                </td>
              </tr>
            </thead>
            <tbody>
              {
                members.map((member) => <OUMember key={member.id} member={member} />)
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

OUMembers.propTypes = {
  members: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};
