import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import commonMessages from '../../../../messages/common.messages';
import { Button } from '../../../_tools';
import { intlMessage } from '../../../../core/propTypes';

const Footer = ({
  onLeftBtnClick,
  onRightBtnClick,
  leftBtnLabel,
  rightBtnLabel,
  leftBtnClass,
  rightBtnClass,
  isLeftBtnDisabled,
  isRightBtnDisabled,
  isLeftBtnLoading,
  isRightBtnLoading,
  isLeftBtnHidden,
  isRightBtnHidden,
}) => (
  <div className={cn('modal-buttons', { single: isLeftBtnHidden || isRightBtnHidden })}>
    { !isLeftBtnHidden && (
      <Button
        type="button"
        className={cn(leftBtnClass)}
        onClick={onLeftBtnClick}
        disabled={isLeftBtnDisabled}
        showLoader={isLeftBtnLoading}
      >
        <FormattedMessage {...leftBtnLabel} />
      </Button>
    ) }
    { !isRightBtnHidden && (
      <Button
        type="submit"
        className={cn(rightBtnClass)}
        onClick={onRightBtnClick}
        disabled={isRightBtnDisabled}
        showLoader={isRightBtnLoading}
      >
        <FormattedMessage {...rightBtnLabel} />
      </Button>
    ) }
  </div>
);

Footer.propTypes = {
  onLeftBtnClick: PropTypes.func,
  onRightBtnClick: PropTypes.func,
  leftBtnLabel: intlMessage,
  rightBtnLabel: intlMessage,
  leftBtnClass: PropTypes.string,
  rightBtnClass: PropTypes.string,
  isLeftBtnDisabled: PropTypes.bool,
  isRightBtnDisabled: PropTypes.bool,
  isLeftBtnLoading: PropTypes.bool,
  isRightBtnLoading: PropTypes.bool,
  isLeftBtnHidden: PropTypes.bool,
  isRightBtnHidden: PropTypes.bool,
};

Footer.defaultProps = {
  onLeftBtnClick: () => {},
  onRightBtnClick: () => {},
  leftBtnLabel: commonMessages.cancel,
  rightBtnLabel: commonMessages.done,
  leftBtnClass: 'button_cancel',
  rightBtnClass: 'button__save',
  isLeftBtnDisabled: false,
  isRightBtnDisabled: false,
  isLeftBtnLoading: false,
  isRightBtnLoading: false,
  isLeftBtnHidden: false,
  isRightBtnHidden: false,
};

export default Footer;
