import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { CLONE_CALENDAR_NAME_FIELD } from '../../../../redux/constants/calendars.clone.form.constants';
import { FormField } from '../../../_tools';
import commonMessages from '../../../../messages/common.messages';
import Button from '../../../_tools/button/Button';

export const CalendarCloneModalForm = ({
  submitting,
  close,
  cloneCalendar,
  calendarId,
  intl,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    cloneCalendar(calendarId);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    close();
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <FormField
        label={commonMessages.name.id}
        name={CLONE_CALENDAR_NAME_FIELD}
        autoFocus
      />

      <div className="line" />

      <div className="text-center">
        <button type="button" className="button__cancel" onClick={handleCancel}>
          {intl.formatMessage(commonMessages.cancel)}
        </button>
        <Button
          showLoader={submitting}
          disabled={submitting}
          className="button__save"
          type="submit"
        >
          { intl.formatMessage(commonMessages.clone) }
        </Button>
      </div>
    </form>
  );
};

CalendarCloneModalForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  cloneCalendar: PropTypes.func.isRequired,
  calendarId: PropTypes.string.isRequired,

};

export default injectIntl(CalendarCloneModalForm);
