import { createAction } from '@reduxjs/toolkit';
import {
  call, put, takeEvery, delay,
} from 'redux-saga/effects';

import * as notification from '../../core/notifications.js';

export const sendNotification = createAction(
  'send in-app notification',
  (
    title,
    description = {},
    type = notification.NOTIFICATION_EVENT,
  ) => ({ payload: { title, description, type } }),
);

export const addNotification = createAction(
  'add in-app notification',
  (newNotification) => ({ payload: newNotification }),
);

export const removeNotification = createAction(
  'remove in-app notification',
  (id) => ({ payload: id }),
);

export const reducer = {
  [addNotification]: (state, newNotification) => ({
    ...state,
    notifications: [
      ...state.notifications,
      newNotification,
    ],
  }),

  [removeNotification]: (state, id) => {
    const notifications = state.notifications;
    const index = notification.getNotificationIndex(notifications, id);

    if (index === -1) {
      return state;
    }

    return {
      ...state,
      notifications: [
        ...notifications.slice(0, index),
        ...notifications.slice(index + 1),
      ],
    };
  },
};

export function* sendNotificationSaga({ payload: { title, description, type } }) {
  const newNotification = yield call(
    notification.compose,
    title,
    description,
    type,
  );
  yield put(addNotification(newNotification));
  yield delay(notification.notificationTimeout);
  yield put(removeNotification(newNotification.id));
}

export function* watchSendNotification() {
  yield takeEvery(sendNotification, sendNotificationSaga);
}
