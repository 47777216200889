import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { initializeUser } from '../../core/common/admin.state';

export const adminFetchUser = createAction(
  'admin: fetch user',
  (id) => id,
);

export const adminFetchUserSuccess = createAction(
  'admin: fetch user - success',
  (item) => item,
);

export const adminFetchUserFail = createAction(
  'admin: fetch user - fail',
  (error) => error,
);

export const adminUserReset = createAction(
  'admin: reset user',
);

export const reducer = {
  [adminFetchUser]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        selected: {
          ...state.admin.users.selected,
          item: null,
          loaded: false,
          loading: true,
          error: null,
        },
      },
    },
  }),

  [adminFetchUserSuccess]: (state, item) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        selected: {
          ...state.admin.users.selected,
          item,
          loaded: true,
          loading: false,
          error: null,
        },
      },
    },
  }),

  [adminFetchUserFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        selected: {
          ...state.admin.users.selected,
          item: null,
          loaded: false,
          loading: false,
          error,
        },
      },
    },
  }),

  [adminUserReset]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        selected: {
          ...state.admin.users.selected,
          item: null,
          loaded: false,
          loading: false,
          error: null,
        },
      },
    },
  }),
};

export function* adminFetchUserSaga({ payload: id }) {
  const { response, error } = yield call(api.admin.getUser, id);

  if (response) {
    yield put(adminFetchUserSuccess(initializeUser(response)));
  } else {
    yield put(adminFetchUserFail(error));
  }
}

export function* watchAdminFetchUser() {
  yield takeEvery(adminFetchUser.getType(), adminFetchUserSaga);
}
