import { createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as reportsSelector from '../selectors/reports.selector';
import * as api from '../../core/api';
import { initializeReport } from '../../core/common/report';
import { updateReportSuccess } from './report.update.module';
import { DATA_POINTS } from '../../../../common/constants/entities';
import { SagaIterator } from 'redux-saga';

export const removeLegendDataPoint = createAction(
  'remove legend data point from report',
  (reportId, dataPointId) => ({ payload: { reportId, dataPointId } }),
);

export function* removeLegendDataPointSaga({ payload: { reportId, dataPointId } }: ReturnType<typeof removeLegendDataPoint>): SagaIterator {
  const state = yield select();

  const reports = yield call(reportsSelector.selectAllReports, state);
  const report = yield call(reportsSelector.getReportById, reportId, reports);

  if (!report) return;

  const apiCallBody = {
    name: report.name,
    from: report.from,
    to: report.to,
    type: report.type,
    interval: report.interval,
    filters: {
      ...report.filters,
      [DATA_POINTS]: report.filters[DATA_POINTS].reduce((result, filter) => {
        if (dataPointId === filter.dataPoint.id) return result;
        return [
          ...result,
          { ...filter, id: filter.dataPoint.id, dataPoint: undefined },
        ];
      }, []),
    },
  };

  const { response } = yield call(api.reports.update, reportId, apiCallBody);

  if (response) {
    yield put(updateReportSuccess({
      ...initializeReport(response),
      disabledProps: report.disabledProps || [],
    }));
  }
}

export function* watchRemoveLegendDataPoint(): SagaIterator {
  yield takeEvery(removeLegendDataPoint.toString(), removeLegendDataPointSaga);
}
