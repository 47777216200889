import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';

import { HIDDEN_EVENTS } from '../constants/localeStorage.constants';

import { sendNotification } from './notifications.module';
import { NOTIFICATION_ERROR } from '../../core/notifications';
import notificationMessages from '../../messages/notification.message';

export const hideEvent = createAction(
  'hide event from the column',
  (eventId) => eventId,
);

export const revealEvent = createAction(
  'reveal hidden event in the column',
  (eventId) => eventId,
);

export const initHiddenEvents = createAction(
  'init hidden events',
);

export const setHiddenEvents = createAction(
  'set hidden events',
  (hiddenEvents) => hiddenEvents,
);

export const reducer = {
  [hideEvent]: (state, eventId) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      hiddenEvents: {
        ...state.maintenance.hiddenEvents,
        [eventId]: true,
      },
    },
  }),
  [revealEvent]: (state, eventId) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      hiddenEvents: {
        ...state.maintenance.hiddenEvents,
        [eventId]: false,
      },
    },
  }),

  [setHiddenEvents]: (state, hiddenEvents) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      hiddenEvents,
    },
  }),
};

export function* hideEventSaga({ payload: eventId }) {
  let hiddenEvents = window.localStorage.getItem(HIDDEN_EVENTS);

  try {
    hiddenEvents = JSON.parse(hiddenEvents) || {};
  } catch (e) {
    yield put(sendNotification(
      notificationMessages.failed_to_persist_hidden_event_title,
      notificationMessages.failed_to_persist_hidden_event_description,
      NOTIFICATION_ERROR,
    ));
    hiddenEvents = {};
  }

  hiddenEvents[eventId] = true;

  window.localStorage.setItem(HIDDEN_EVENTS, JSON.stringify(hiddenEvents));
}

export function* watchHideEvent() {
  yield takeEvery(hideEvent.getType(), hideEventSaga);
}

export function* revealEventSaga({ payload: eventId }) {
  let hiddenEvents = window.localStorage.getItem(HIDDEN_EVENTS);

  try {
    hiddenEvents = JSON.parse(hiddenEvents) || {};
  } catch (e) {
    yield put(sendNotification(
      notificationMessages.failed_to_persist_hidden_event_title,
      notificationMessages.failed_to_persist_hidden_event_description,
      NOTIFICATION_ERROR,
    ));
    hiddenEvents = {};
  }

  delete hiddenEvents[eventId];

  window.localStorage.setItem(HIDDEN_EVENTS, JSON.stringify(hiddenEvents));
}

export function* watchRevealEvent() {
  yield takeEvery(revealEvent.getType(), revealEventSaga);
}

export function* initHiddenEventsSaga() {
  let hiddenEvents = window.localStorage.getItem(HIDDEN_EVENTS);

  try {
    hiddenEvents = JSON.parse(hiddenEvents) || {};
  } catch (e) {
    yield put(sendNotification(
      notificationMessages.failed_to_persist_hidden_event_title,
      notificationMessages.failed_to_persist_hidden_event_description,
      NOTIFICATION_ERROR,
    ));
    window.localStorage.removeItem(HIDDEN_EVENTS);
  }

  yield put(setHiddenEvents(hiddenEvents));
}

export function* watchInitHiddenEvents() {
  yield takeEvery(initHiddenEvents.getType(), initHiddenEventsSaga);
}
