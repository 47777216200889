import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { push } from 'connected-react-router';

import * as api from '../../core/api';
import { replaceObjectInList, compareById } from '../../../../common/helpers/object';
import { getFormFields } from '../selectors/admin.location.form.selector';
import * as form from '../constants/admin.location.form.constants';
import { getAdminLocationsLink } from '../../components/index/routes';
import { getLocation } from '../selectors/admin.locations.selector';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const adminUpdateLocation = createAction(
  'admin: update location',
);

export const adminUpdateLocationSuccess = createAction(
  'admin: update location - success',
  (location) => location,
);

export const adminUpdateLocationFail = createAction(
  'admin: update location - fail',
  (error) => error,
);

export const reducer = {
  [adminUpdateLocation]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        selected: {
          ...state.admin.locations.selected,
          item: {
            ...state.admin.locations.selected.item,
            _update: {
              loaded: false,
              loading: true,
              error: null,
            },
          },
        },
      },
    },
  }),

  [adminUpdateLocationSuccess]: (state, location) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        list: replaceObjectInList(
          state.admin.locations.list,
          location,
          compareById,
        ),
      },
    },
  }),

  [adminUpdateLocationFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        selected: {
          ...state.admin.locations.selected,
          item: {
            ...state.admin.locations.selected.item,
            _update: {
              loaded: false,
              loading: false,
              error,
            },
          },
        },
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.ADMIN_LOCATION_FORM_NAME,
    ...form.LOCATION_PROPS,
  ));
}

export function* adminUpdateLocationSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const location = yield call(getLocation, state);
  const body = yield call(getFormFields, state);
  yield put(startSubmit(form.ADMIN_LOCATION_FORM_NAME));

  const { response, error } = yield call(api.admin.updateLocation, location.id, body);

  if (response) {
    yield put(adminUpdateLocationSuccess(response));
    yield put(stopSubmit(form.ADMIN_LOCATION_FORM_NAME));
    yield put(push(getAdminLocationsLink()));
    yield put(sendNotification({ ...notificationMessages.location_updated, values: { name: response.name } }));
  } else {
    yield put(adminUpdateLocationFail(error));
    yield put(stopSubmit(form.ADMIN_LOCATION_FORM_NAME, error));
  }
}

export function* watchAdminUpdateLocation() {
  yield takeEvery(adminUpdateLocation.getType(), adminUpdateLocationSaga);
}
