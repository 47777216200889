import React from 'react';
import PropTypes from 'prop-types';

import ExtraButtonsItem from './ExtraButtonsItem';

export const ExtraButtons = ({
  list,
}) => (
  <div className="big-tabs-actions">
    { list.map((item, index) => <ExtraButtonsItem {...item} key={index + 1} />) }
  </div>
);

ExtraButtons.propTypes = {
  list: PropTypes.array.isRequired,
};

ExtraButtons.defaultProps = {
  list: [],
};

export default ExtraButtons;
