import { connect } from 'react-redux';

import AccountModule from './AccountModule';
import { adminCreateAccount } from '../../../../../redux/modules/admin.account.create.module';

export const mapStateToProps = () => ({
  isAddForm: true,
  loaded: true,
});

export const mapDispatchToProps = {
  adminCreateAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountModule);
