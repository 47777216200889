import { DragSource } from 'react-dnd';

import ActivitiesCard from './ActivitiesCard';
import { DND_COLUMN_ITEM_TYPE } from '../../../../redux/constants/column.constants';

const columnActivitySource = {
  beginDrag(props) {
    props.beginDrag();

    return {
      cardId: props.cardId,
      type: props.type,
    };
  },
  endDrag(props, monitor) {
    const dropResult = monitor.getDropResult();

    if (dropResult) {
      const { cardId, toColumn, betweenCards } = dropResult;
      props.moveActivity(cardId, toColumn, betweenCards);
    }

    props.endDrag();
  },
};

function collect(connect) {
  return {
    connectDragSource: connect.dragSource(),
  };
}

export default DragSource(DND_COLUMN_ITEM_TYPE, columnActivitySource, collect)(ActivitiesCard);
