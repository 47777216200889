import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { push } from 'connected-react-router';
import { difference } from 'lodash';

import * as api from '../../core/api';
import { adminFetchRoles } from './admin.roles.fetch.module';
import { getFormFields } from '../selectors/admin.role.form.selector';
import * as form from '../constants/admin.role.form.constants';
import { getAdminRolesLink } from '../../components/index/routes';
import { getRole } from '../selectors/admin.roles.selector';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const adminUpdateRole = createAction(
  'admin: update role',
);

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.ADMIN_ROLE_FORM_NAME,
    ...form.ROLE_PROPS,
  ));
}

export function* adminUpdateRoleSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  yield put(startSubmit(form.ADMIN_ROLE_FORM_NAME));

  const role = yield call(getRole, state);
  const oldUserIds = role.users.map((user) => user.id);
  const newUserIds = yield call(getFormFields, state);

  const userIdsToRemoveRole = difference(oldUserIds, newUserIds);
  yield userIdsToRemoveRole.map((userId) => call(api.admin.removeUserRole, userId, role.role));

  yield put(stopSubmit(form.ADMIN_ROLE_FORM_NAME));
  yield put(push(getAdminRolesLink()));
  yield put(adminFetchRoles());
  yield put(sendNotification({ ...notificationMessages.role_updated, values: { name: role.role } }));
}

export function* watchAdminUpdateRole() {
  yield takeEvery(adminUpdateRole.getType(), adminUpdateRoleSaga);
}
