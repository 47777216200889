import { connect } from 'react-redux';

import LoadMoreButton from './LoadMoreButton';
import { fetchDataPointMetric } from '../../../../../../redux/modules/dataPoint.metric.fetch.module';
import { isLoading } from '../../../../../../redux/selectors/dataPoint.metric.selector';

export const mapStateToProps = (state, { dataPointId }) => ({
  isLoading: isLoading(state, dataPointId),
});

export const mapDispatchToProps = (dispatch, { deviceId, dataPointId }) => ({
  onClick: () => dispatch(fetchDataPointMetric(deviceId, dataPointId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadMoreButton);
