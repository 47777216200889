import { createAction } from 'redux-act';
import { startSubmit, stopSubmit } from 'redux-form';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import * as form from '../constants/machineSubscription.update.form';
import { getMachine } from '../selectors/machine.selector';
import { getFormFields } from '../selectors/machine.subscriptions.update.selector';
import { getSubscriptionById } from '../selectors/machine.subscriptions.selector';
import { replaceObjectInList, compareById } from '../../../../common/helpers/object';

export const updateMachineSubscription = createAction(
  'update machine subscriptions',
  (subscriptionId) => subscriptionId,
);

export const updateMachineSubscriptionSuccess = createAction(
  'update machine subscriptions - success',
  (subscription) => subscription,
);

export const updateMachineSubscriptionFail = createAction(
  'update machine subscriptions - fail',
  (error) => error,
);

export const reducer = {
  [updateMachineSubscription]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        update: {
          ...state.machine.dataPoints.update,
          loading: true,
          loaded: false,
          error: null,
        },
      },
    },
  }),
  [updateMachineSubscriptionSuccess]: (state, updatedSubscription) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        list: replaceObjectInList(
          state.machine.dataPoints.list,
          updatedSubscription,
          compareById,
        ),
        update: {
          ...state.machine.dataPoints.update,
          loading: false,
          loaded: true,
          error: null,
        },
      },
    },
  }),
  [updateMachineSubscriptionFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        update: {
          ...state.machine.dataPoints.update,
          loading: false,
          loaded: false,
          error,
        },
      },
    },
  }),
};

export function* updateMachineSubscriptionSaga({ payload: subscriptionId }) {
  const state = yield select();

  const subscription = yield call(getSubscriptionById, state, subscriptionId);
  if (!subscription) return;

  const fields = yield call(getFormFields, state);
  yield put(startSubmit(form.machineSubscriptionUpdateForm));

  const machine = yield call(getMachine, state);
  const {
    response,
    error,
  } = yield call(api.machines.updateSubscription, machine.id, subscription.id, fields);

  if (response) {
    yield put(updateMachineSubscriptionSuccess(response));
    yield put(stopSubmit(form.machineSubscriptionUpdateForm));
  } else {
    yield put(updateMachineSubscriptionFail(error));
    yield put(stopSubmit(form.machineSubscriptionUpdateForm, error));
  }
}

export function* watchUpdateMachineSubscription() {
  yield takeEvery(updateMachineSubscription.getType(), updateMachineSubscriptionSaga);
}
