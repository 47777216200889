import React from 'react';
import PropTypes from 'prop-types';
import { area } from 'd3-shape';

export default function Area({
  data, height, xScale, yScale, className, getX, getY,
}) {
  const path = area()
    .x((d) => xScale(getX(d)))
    .y0((d) => yScale(getY(d)))
    .y1(height)(data);

  return (
    <path
      d={path}
      className={className}
    />
  );
}

Area.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  getX: PropTypes.func.isRequired,
  getY: PropTypes.func.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
};
