import { defineMessages } from 'react-intl';

import {
  kpi, maintenance, events, dataPoints, datatronStats,
} from '../../../common/enums/reportTypes';

export default defineMessages({
  [kpi]: {
    id: 'reports.type.kpi',
    defaultMessage: 'OEE',
  },
  [maintenance]: {
    id: 'reports.type.maintenance',
    defaultMessage: 'Maintenance',
  },
  [events]: {
    id: 'reports.type.events',
    defaultMessage: 'Category',
  },
  [dataPoints]: {
    id: 'reports.type.dataPoints',
    defaultMessage: 'Data Points',
  },
  [datatronStats]: {
    id: 'reports.type.datatronStats',
    defaultMessage: 'DATATRON Stats',
  },
});
