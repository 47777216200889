import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BasicModal from '../../../../../modals/core/BasicModal';
import { getAdminUsersLink, getAdminEditUserLink } from '../../../../../index/routes';
import commonCoreMessages from '../../../../../../messages/common.core.messages';
import adminPageMessages from '../../../../../../messages/adminPage.messages';
import Form from './form/UserForm.container';
import * as routeHandlers from '../../../../../index/routeHandlers';

export class UserModule extends React.Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired,
    isEditForm: PropTypes.bool,
    isViewForm: PropTypes.bool,
    initialValues: PropTypes.object,
    userId: PropTypes.string,
    adminUpdateUser: PropTypes.func,
  };

  static defaultProps = {
    isEditForm: false,
    isViewForm: false,
    initialValues: {},
    formBodyProps: {},
  };

  componentWillMount() {
    routeHandlers.onAdminUserEnter(this.props.match);
  }

  componentWillUnmount() {
    routeHandlers.onAdminUserLeave();
  }

  handleCancel = () => this.props.history.push(getAdminUsersLink());

  render() {
    const {
      intl,
      isEditForm,
      isViewForm,
      loaded,
      initialValues,
      userId,
      adminUpdateUser,
      history,
    } = this.props;

    if (!loaded) {
      return null;
    }

    let handleSubmit = null;

    const object = intl.formatMessage(commonCoreMessages.user);
    let modalTitle = null;
    if (isEditForm) {
      modalTitle = adminPageMessages.editObject;
      handleSubmit = (e) => {
        e.preventDefault();
        adminUpdateUser();
      };
    } else if (isViewForm) {
      modalTitle = adminPageMessages.viewObject;
      handleSubmit = (e) => {
        e.preventDefault();
        history.push(getAdminEditUserLink(userId));
      };
    }
    modalTitle = intl.formatMessage(modalTitle, { object });

    return (
      <BasicModal close={this.handleCancel} modalClass="modal--lg">
        <div className="modal-overflow">
          <div className="modal--content">
            <h3>{modalTitle}</h3>
            <Form
              isEditForm={isEditForm}
              isViewForm={isViewForm}
              initialValues={initialValues}
              handleCancel={this.handleCancel}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </BasicModal>
    );
  }
}

export default injectIntl(UserModule);
