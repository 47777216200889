export const usersTestState = {
  list: [{
    name: 'John',
  }],
  loading: false,
  loaded: true,
  error: null,
};

export const usersInitialState = {
  list: [],
  loading: false,
  loaded: false,
  error: null,
};
