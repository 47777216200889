import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RemoveLocationModal from './RemoveLocationModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { adminRemoveLocation } from '../../../../redux/modules/admin.location.remove.module';
import { adminLocationReset } from '../../../../redux/modules/admin.location.fetch.module';
import { AP_LOCATION_REMOVE } from '../../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { getRemoveError } from '../../../../redux/selectors/admin.locations.selector';

export const mapStateToProps = (state) => ({
  ...getModalPayload(state, AP_LOCATION_REMOVE),
  error: getRemoveError(state),
});

export const mapDispatchToProps = (dispatch) => ({
  close: () => {
    dispatch(closeModal(AP_LOCATION_REMOVE));
    dispatch(adminLocationReset());
  },
  remove: bindActionCreators(adminRemoveLocation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveLocationModal);
