import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';

import commonCoreMessages from '../../../../../../../../messages/common.core.messages';
import commonUserMessages from '../../../../../../../../messages/common.user.messages';
import * as form from '../../../../../../../../redux/constants/admin.user.form.constants';
import { FormField } from '../../../../../../../_tools';
import BooleanSelectContainer from '../../../../../../../_tools/formFields/select/Boolean.container';
import UserStatusSelectContainer from '../../../../../../../_tools/formFields/select/UserStatusSelect.container';
import FormEditableList from '../../../../../../../_tools/formFields/formEditableList/FormEditableList';

export const AdvancedUserForm = ({
  hidden,
  readOnly,
  allRolesOptions,
  allOrganizationsOptions,
  allAssistanceMachinesOptions,
  hasRolesAccess,
  intl,
}) => {
  const rolesLabel = intl.formatMessage(commonCoreMessages.roles);
  const organizationsLabel = intl.formatMessage(commonCoreMessages.organizations);
  const assistanceMachinesLabel = intl.formatMessage(commonUserMessages.assistanceMachines);

  return (
    <div className={cn('tab-advanced', { hide: hidden })}>
      <div className="tab-row">
        <div className="tab-col">
          { !readOnly && (
            <FormField
              label={commonCoreMessages.newPassword.id}
              name={form.NEW_PASSWORD_FIELD}
              tabIndex={1}
              autoFocus
            />
          ) }
          <label>
            <FormattedMessage {...commonCoreMessages.profileStatus} />
            <Field
              name={form.STATUS_FIELD}
              component={UserStatusSelectContainer}
              disabled={readOnly}
              tabIndex={3}
            />
          </label>
          <Field
            name={form.ROLES_FIELD}
            component={FormEditableList}
            options={allRolesOptions}
            disabled={!hasRolesAccess || readOnly}
            label={rolesLabel}
          />
          { readOnly && (
            <>
              <Field
                name={form.MACHINES_ASSISTANCE_FIELD}
                component={FormEditableList}
                options={allAssistanceMachinesOptions}
                disabled
                allOptionsSelected
                label={assistanceMachinesLabel}
              />
              <FormField
                label={commonUserMessages.signInCount.id}
                name={form.RO_SIGN_IN_COUNT}
                disabled
              />
              <FormField
                label={commonUserMessages.lastSignInAt.id}
                name={form.RO_CURRENT_SIGN_IN_AT}
                disabled
              />
            </>
          ) }
        </div>
        <div className="tab-col">
          { !readOnly && (
            <FormField
              label={commonCoreMessages.newPasswordConfirmation.id}
              name={form.NEW_PASSWORD_CONFIRMATION_FIELD}
              tabIndex={2}
            />
          ) }
          <label>
            <FormattedMessage {...commonCoreMessages.working} />
            <Field
              name={form.IS_WORKING_FIELD}
              component={BooleanSelectContainer}
              isDisabled={readOnly}
              tabIndex={4}
            />
          </label>
          <Field
            name={form.ORGANIZATIONS_FIELD}
            component={FormEditableList}
            options={allOrganizationsOptions}
            disabled={readOnly}
            label={organizationsLabel}
          />
          { readOnly && (
            <>
              <FormField
                label={commonUserMessages.emailConfirmedAt.id}
                name={form.RO_CONFIRMED_AT}
                disabled
              />
              <FormField
                label={commonUserMessages.lastSignInIp.id}
                name={form.RO_LAST_SIGN_IN_IP}
                disabled
              />
              <label>
                <FormattedMessage {...commonUserMessages.tosAccepted} />
                <Field
                  name={form.RO_TOS_ACCEPTED}
                  component={BooleanSelectContainer}
                  isDisabled
                />
              </label>
            </>
          ) }
        </div>
      </div>
    </div>
  );
};

AdvancedUserForm.propTypes = {
  hidden: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,

};

AdvancedUserForm.defaultProps = {
};

export default injectIntl(AdvancedUserForm);
