import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as selector from '../../../redux/selectors/aiApps.selector';
import Table, { TableRow } from '../../common/table/table';
import AiAppsNoSelection from '../placeholder/AiAppsEmptyPlaceholder';
import { FormattedMessage, useIntl } from 'react-intl';
import { openModal } from '../../../redux/modules/modals.module';
import { CYCLE_DETECTION_ADD } from '../../../redux/constants/modals.constants';
import commonMessages from '../../../messages/common.messages';
import commonCoreMessages from '../../../messages/common.core.messages';
import aiAppConfigMessages from '../../../messages/aiApp.config.messages';
import { fetchCycleDetectionDetails } from '../../../redux/modules/aiApps.cycleDetection.details.fetch.module';
import { CYCLE_DETECTION_DISPLAY_HEADER } from '../../../redux/constants/aiApp.cycleDetection.form.constants';
import Counter from '../../common/counter/Counter';

const CycleDetectionsSection: React.FC = () => {
  const dispatch = useDispatch();
  const aiAppsDetails = useSelector(selector.getAiAppsDetails);
  const cycleDetectionDetails = useSelector(selector.getCycleDetectionDetails);
  const { formatMessage } = useIntl();
  if (!aiAppsDetails) return <AiAppsNoSelection />;

  const { appName, description, status, datatron, devices, cycleDetections } = aiAppsDetails;

  const handleOpenCycleDetectionModal = () => {
    dispatch(openModal(CYCLE_DETECTION_ADD));
  };

  const renderActions = (row: TableRow) => (
    <div className='td-buttons'>
      <button
        title={formatMessage(commonMessages.details)}
        className='button button__xs light-grey-white'
        onClick={() => dispatch(fetchCycleDetectionDetails(row.id))}
      >
        <i className='icon-eye' />
      </button>
    </div>
  );

  return (
    <div className='container mt-5'>
      <div className='info-grid'>
        <div className='info-card'>
          <div className='ai-app-details--list'>
            <div className='ai-app-details--item'>
              <div className='ai-app-details--icon'>
                <span className='icon-info' />
              </div>
              <div className='ai-app-details--text'>
                <div className='ai-app-details--position w50'>
                  <label>
                    <FormattedMessage tagName='b' {...commonMessages.name} />
                  </label>
                  <p>{appName}</p>
                </div>
                <div className='ai-app-details--position w50'>
                  <label>
                    <FormattedMessage tagName='b' {...commonCoreMessages.description} />
                  </label>
                  <p className='text-clamp'>{description}</p>
                </div>
              </div>
            </div>

            <div className='ai-app-details--item'>
              <div className='ai-app-details--icon'></div>
              <div className='ai-app-details--text'>
                <div className='ai-app-details--position w50'>
                  <label>
                    <FormattedMessage tagName='b' {...commonMessages.status} />
                  </label>
                  <p>{status}</p>
                </div>
                <div className='ai-app-details--position w50'>
                  <label>
                    <FormattedMessage tagName='b' {...commonCoreMessages.datatron} />
                  </label>
                  <p>{datatron.name}</p>
                </div>
              </div>
            </div>

            <div className='ai-app-details--item'>
              <div className='ai-app-details--icon'></div>
              <div className='ai-app-details--text'>
                <div className='ai-app-details--position '>
                  <label>
                    <FormattedMessage tagName='b' {...commonCoreMessages.devices} />
                  </label>
                  <p>
                    {devices.map((device) => (
                      <span className='device-list' key={device.id}>
                        {device.name}
                      </span>
                    ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='table--title'>
        <FormattedMessage tagName='b' {...aiAppConfigMessages.cycleDetection} />
        <Counter value={cycleDetections.length} />
        <button
          className='add-anomaly-btn btn btn-success dropdown-toggle button light-grey-white'
          onClick={handleOpenCycleDetectionModal}
        >
          <span className='fa fa-plus me-2' />
          <FormattedMessage tagName='b' {...commonMessages.add} />{' '}
          <FormattedMessage tagName='b' {...aiAppConfigMessages.cycleDetection} />
        </button>
      </div>
      <div className='table-responsive'>
        <Table
          headers={CYCLE_DETECTION_DISPLAY_HEADER}
          //TODO: fix types
          rows={cycleDetections}
          renderActions={renderActions}
          selectedId={cycleDetectionDetails?.id}
          hasStatus
          tableBodyClass='no-overflow'
        />
      </div>
    </div>
  );
};

export default CycleDetectionsSection;
