import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import typeMessages from '../../../../messages/configRepoExplorer.message';
import SubscriptionWarningContainer from './SubscriptionWarning.container';
import AlreadySelectedWarning from './AlreadySelectedWarning';
import HighlightText from '../../highlightText/HighlightText';

export const RightBarItem = ({
  id,
  title,
  type,
  onClick,
  showCheckbox,
  isSelected,
  isDisabled,
  subscription,
  hasTypeLabel,
  translateTitle,
  highlightText,
  intl,
}) => {
  const onItemSelect = () => onClick(id);

  const label = !translateTitle ? title : intl.formatMessage(title);

  let prefix = '';
  if (hasTypeLabel) {
    const typeLabel = intl.formatMessage(typeMessages[type]);
    prefix = `${typeLabel} - `;
  }

  if (!showCheckbox) {
    return (
      <li>
        <a onClick={!isDisabled ? onItemSelect : null}>
          { prefix + label }
          { isDisabled && <AlreadySelectedWarning /> }
          <SubscriptionWarningContainer subscription={subscription} />
        </a>
      </li>
    );
  }

  return (
    <li>
      <label className="checkbox checkbox-white">
        <input
          type="checkbox"
          onChange={onItemSelect}
          checked={isSelected}
          disabled={!!subscription || isDisabled}
        />
        <span>
          { prefix }
          {
            highlightText ? (
              <HighlightText text={label} highlight={highlightText} />
            ) : label
}
          { isDisabled && <AlreadySelectedWarning /> }
          <SubscriptionWarningContainer subscription={subscription} />
        </span>
      </label>
    </li>
  );
};

RightBarItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  subscription: PropTypes.object,
  hasTypeLabel: PropTypes.bool,
  translateTitle: PropTypes.bool,
  highlightText: PropTypes.string,
};

RightBarItem.defaultProps = {
  isSelected: false,
  isDisabled: false,
  hasTypeLabel: false,
  translateTitle: false,
  subscription: null,
  highlightText: '',
};

export default injectIntl(RightBarItem);
