import React from 'react';
import LocationBreadcrumbs from '../../_tools/locationBreadcrumbs/LocationBreadcrumbs';

function Archived() {
  return (
    <div className="subheader">
      <div className="subheader-in">
        <div className="subheader--left">
          <LocationBreadcrumbs />
        </div>
        <div className="subheader--right" />
      </div>
    </div>
  );
}

export default Archived;
