import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function IncludeProcessingsCheckbox({ value, onChange }) {
  function handleChange(e) {
    onChange(e.target.checked);
  }

  return (
    <div className="dropdown--checkbox">
      <label className="checkbox">
        <input type="checkbox" name="includeProcessings" onChange={handleChange} checked={value} />
        <FormattedMessage
          id="products.export.options.include_processings"
          defaultMessage="Include processing steps"
          tagName="span"
        />
      </label>
    </div>
  );
}

IncludeProcessingsCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};
