import React from 'react';
import { FormattedMessage } from 'react-intl';

import { country } from '../../../../../../common/enums/locationTypes';
import locationTypeMessages from '../../../../messages/location.types.messages';

export default function LeftSidePlaceholder() {
  return (
    <div className="locations-result passive">
      <FormattedMessage
        {...locationTypeMessages[country]}
        tagName="label"
      />
      <div>
        <FormattedMessage
          id="location.select"
          defaultMessage="Select"
        />
      </div>
    </div>
  );
}
