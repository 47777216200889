import Modal from '../../../_tools/modal/Modal';
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { closeModal } from '../../../../redux/modules/modals.module';
import { DP_COMMAND_HISTORY } from '../../../../redux/constants/modals.constants';
import { useDispatch, useSelector } from 'react-redux';
import { Todo } from '../../../../../../common/types/common';
import { getModalPayload, selectCommandHistory } from '../../../../redux/selectors/modals.selector';
import { fetchCommandHistory, setCommandHistory } from '../../../../redux/modules/datatron.devices.command.module';
import { CommandHistoryDisplay } from './commandHistoryDisplay';
import { AbridgedCommand } from '../../../../../../common/types/api/datatron.devices';

type Props = {
  deviceId: string,
} & Todo;

export type CommandHistoryState = 'loading' | 'done' | 'reloading';

export const CommandHistoryModal: React.FC<Props> = () => {
  const [commandHistoryState, commandHistoryStateSet] = useState<CommandHistoryState>('loading');
  /**
   * pageCount is always one lower than the actual, human-readable page count displayed in the pagination element
   */
  const [pageCount, pageCountSet] = useState(0);

  const { deviceId } = useSelector((state) => getModalPayload(state, DP_COMMAND_HISTORY));

  const commandHistory: AbridgedCommand[] | string = useSelector(selectCommandHistory);

  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModal(DP_COMMAND_HISTORY));
    setCommandHistory({
      commands: [],
      total: 0,
    });
  };

  /**
   * Set the page number to navigate to and fetch the data for that page.
   *
   * @param page {number} The human-readable page number.
   */
  const choosePage = (page: number) => {
    pageCountSet(page - 1);

    const offset = (page - 1) * 10;
    dispatch(fetchCommandHistory(deviceId, { offset, length: 10 }));
  };

  /**
   * If the length is greater than 0, that means loading has completed, wheter it is an error (string) or actual data
   */
  useEffect(() => {
    if (commandHistory.length > 0) commandHistoryStateSet('done');
  }, [commandHistory]);

  const handleSubmit = (event) => {
    commandHistoryStateSet('reloading');
    event.preventDefault();
    // Select pageCount + 1 , because pageCount is 1 less than the actual human readable page
    choosePage(pageCount + 1);
  };

  return (
    <Modal
      isOpen
      onClose={close}
      contentLabel=""
      modalClass={commandHistoryState !== 'loading' ? 'modal-content-wide' : ''}
    >
      <div
        className="modal--header"
        onClick={close}
      >
        <span className="modal--close">&times;</span>
      </div>
      <div className="modal--content">
        {commandHistoryState !== 'done' && <FormattedMessage
          defaultMessage="Loading..."
          id="datatron.modals.command_history.loading"
          tagName="h3"
        />}
        {commandHistoryState !== 'loading' && <div>
          <FormattedMessage
            defaultMessage="Latest Commands for this Device"
            id="datatron.modals.command_history.loaded"
            tagName="h3"
          />
          <CommandHistoryDisplay commandHistory={commandHistory} pageCount={pageCount + 1} choosePage={choosePage}/>
        </div>}

        <div className="line"/>

        <div className="text-center">
          <button
            type="button"
            className={commandHistoryState !== 'done' ? 'button__cancel' : 'button__save'}
            onClick={close}
          >
            {commandHistoryState !== 'done' && <FormattedMessage
              id="common.cancel"
              defaultMessage="Cancel"
            />}
            {commandHistoryState === 'done' && <FormattedMessage
              id="common.close"
              defaultMessage="Close"
            />}
          </button>
          {commandHistoryState === 'done' && <button
            type="submit"
            className="button__cancel"
            onClick={handleSubmit}
          >
            <FormattedMessage
              defaultMessage="Reload"
              id="datatron.modals.command_history.reload"
            />
          </button>
          }
        </div>
      </div>
    </Modal>
  );
};
