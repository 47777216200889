import { createAction } from 'redux-act';

export const selectMode = createAction(
  'select calendar mode',
  (mode) => mode,
);

export const reducer = {
  [selectMode]: (state, mode) => ({
    ...state,
    calendar: {
      ...state.calendar,
      mode,
    },
  }),
};
