import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import commonMessages from '../../../../messages/common.core.messages';
import { getDatatron } from '../../../../redux/selectors/datatron.selector';
import LocationListPath from '../../../_tools/locationListPath/LocationListPath';
import datatronFormMessages from '../../../datatronFormPage/forms/details/DatatronFormDetails.messages';
import Card from '../../card/Card';

const usePrettyNetworkSpeed = (networkSpeed?: number) => {
  if (!networkSpeed) return '';
  if (networkSpeed < 1) return `${networkSpeed * 1000} Mb/s`;
  return `${networkSpeed} Gb/s`;
};

interface EntryProps {
  message: MessageDescriptor;
  value: string | number;
}

const Entry: React.FC<EntryProps> = ({ message, value }) => {
  const { formatMessage } = useIntl();
  return (
    <div className="machine-details--position w33">
      <label>{formatMessage(message)}</label>
      <p>{value}</p>
    </div>
  );
};

export const Details: React.FC = () => {
  const datatron = useSelector(getDatatron);

  const prettyNetworkSpeed = usePrettyNetworkSpeed(datatron?.networkSpeed);
  const { formatMessage } = useIntl();

  return (
    <div className="inventory-tab--in related">
      <Card datatron={datatron} />
      <div className="machine-details--list">
        <div className="machine-details--item">
          <div className="machine-details--icon">
            <span className="icon icon-cogs" />
          </div>
          <div className="machine-details--text">
            <Entry
              message={datatronFormMessages.model}
              value={datatron.model}
            />
            <Entry
              message={datatronFormMessages.serialNumber}
              value={datatron.serialNumber}
            />
            <Entry
              message={datatronFormMessages.deviceUUID}
              value={datatron.deviceUUID}
            />
          </div>
        </div>

        <div className="machine-details--item">
          <div className="machine-details--icon" />
          <div className="machine-details--text">
            <Entry
              message={datatronFormMessages.macAddress}
              value={datatron.macAddress}
            />
            <Entry
              message={datatronFormMessages.ipAddress}
              value={datatron.ipAddress}
            />
            <Entry
              message={datatronFormMessages.networkSpeed}
              value={prettyNetworkSpeed}
            />
          </div>
        </div>

        <div className="machine-details--item">
          <div className="machine-details--icon" />
          <div className="machine-details--text">
            <Entry
              message={datatronFormMessages.netmask}
              value={datatron.netmask}
            />
            <Entry
              message={datatronFormMessages.gateway}
              value={datatron.gateway}
            />
            <Entry
              message={datatronFormMessages.bootArguments}
              value={datatron.bootArguments}
            />
          </div>
        </div>

        <div className="machine-details--item">
          <div className="machine-details--icon" />
          <div className="machine-details--text">
            <Entry
              message={datatronFormMessages.vendor}
              value={datatron.make}
            />
            <Entry message={datatronFormMessages.year} value={datatron.year} />
            <Entry
              message={datatronFormMessages.nodeName}
              value={datatron?.nodeName}
            />
          </div>
        </div>

        <div className="machine-details--item machine-details--item-notes">
          <div className="machine-details--icon" />
          <div className="machine-details--text">
            <div className="machine-details--position">
              <label>{formatMessage(datatronFormMessages.osModules)}</label>
              <p>{datatron.osModules}</p>
            </div>
          </div>
        </div>

        <div className="machine-details--item machine-details--item-notes">
          <div className="machine-details--icon" />
          <div className="machine-details--text">
            <div className="machine-details--position">
              <label>{formatMessage(datatronFormMessages.osConfig)}</label>
              <p
                style={{
                  // This styling is used to clamp the text to 3 lines
                  whiteSpace: 'pre-line',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  fontFamily: 'monospace',
                }}
              >
                {datatron.osConfig}
              </p>
            </div>
          </div>
        </div>

        <div className="machine-details--item">
          <div className="machine-details--icon">
            <span className="icon icon-location" />
          </div>
          <div className="machine-details--text">
            <div className="machine-details--position">
              <label>{formatMessage(commonMessages.location)}</label>
              <LocationListPath location={datatron.location} />
            </div>
          </div>
        </div>

        <div className="machine-details--item">
          <div className="machine-details--icon">
            <span className="fa fa-users" />
          </div>
          <div className="machine-details--text">
            <div className="machine-details--position">
              <label>{formatMessage(commonMessages.organization)}</label>
              {datatron.organization?.name}
            </div>
          </div>
        </div>

        <div className="machine-details--item machine-details--item-notes">
          <div className="machine-details--icon">
            <span className="icon icon-notes" />
          </div>
          <div className="machine-details--text">
            <div className="machine-details--position">
              <label>{formatMessage(datatronFormMessages.notes)}</label>
              <p style={{ whiteSpace: 'pre-line' }}>{datatron.notes}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
