import { createAction } from 'redux-act';
import {
  put, select, call, takeLatest, delay,
} from 'redux-saga/effects';

import { SORT_WAY_ASC, SORT_WAY_DESC } from '../../../../common/constants';
import { fetchEventsSummaryByMachine } from './machine.fetch.summary.module';
import { fetchClustersSummaryByMachine } from './machine.fetch.clusters.summary.module';
import { machine } from '../selectors/machine.selector';

const DELAY_TIME_IN_MILLISECONDS = 200;

export const sortGroups = createAction(
  'sort grouped events',
  (option) => option,
);

export const filterGroupsByDate = createAction(
  'filter grouped events by date',
  (dates) => dates,
);

export const filterGroupsByName = createAction(
  'filter grouped events by name',
  (query) => query,
);

export const addGroupCategoryFilter = createAction(
  'add category filter for grouped events',
  (filter) => filter,
);

export const removeGroupCategoryFilter = createAction(
  'remove category filter for grouped events',
  (filter) => filter,
);

export const resetGroupsFilters = createAction(
  'reset all filters for grouped events',
);

export const sortClusters = createAction(
  'sort clustered events',
  (option) => option,
);

export const filterClustersByDate = createAction(
  'filter clustered events by date',
  (dates) => dates,
);

export const filterClustersByName = createAction(
  'filter clustered events by name',
  (query) => query,
);

export const addClusterCategoryFilter = createAction(
  'add category filter for clustered events',
  (filter) => filter,
);

export const removeClusterCategoryFilter = createAction(
  'remove category filter for clustered events',
  (filter) => filter,
);

export const resetClustersFilters = createAction(
  'reset all filters for clustered events',
);

export const reducer = {
  [sortGroups]: (state, option) => {
    const prevOption = state.machine.summary.sort.option;
    const prevWay = state.machine.summary.sort.way;

    let newOption = null;
    let newWay = null;

    if (prevOption === option && prevWay === SORT_WAY_ASC) {
      newOption = prevOption;
      newWay = SORT_WAY_DESC;
    } else if (prevOption !== option) {
      newOption = option;
      newWay = SORT_WAY_ASC;
    }

    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          sort: { option: newOption, way: newWay },
        },
      },
    };
  },
  [filterGroupsByDate]: (state, newDates) => {
    const filters = {
      ...state.machine.summary.filters,
      ...newDates,
    };

    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          filters,
        },
      },
    };
  },
  [filterGroupsByName]: (state, query) => ({
    ...state,
    machine: {
      ...state.machine,
      summary: {
        ...state.machine.summary,
        filters: {
          ...state.machine.summary.filters,
          query,
        },
      },
    },
  }),
  [addGroupCategoryFilter]: (state, filter) => ({
    ...state,
    machine: {
      ...state.machine,
      summary: {
        ...state.machine.summary,
        filters: {
          ...state.machine.summary.filters,
          category: [
            ...state.machine.summary.filters.category,
            filter,
          ],
        },
      },
    },
  }),
  [removeGroupCategoryFilter]: (state, filter) => {
    const category = state.machine.summary.filters.category;
    const index = category.indexOf(filter);

    if (index === -1) {
      return state;
    }

    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          filters: {
            ...state.machine.summary.filters,
            category: [
              ...category.slice(0, index),
              ...category.slice(index + 1),
            ],
          },
        },
      },
    };
  },
  [resetGroupsFilters]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      summary: {
        ...state.machine.summary,
        filters: {
          ...state.machine.summary.filters,
          category: [],
          query: '',
        },
      },
    },
  }),

  [sortClusters]: (state, option) => {
    const prevOption = state.machine.clustersSummary.sort.option;
    const prevWay = state.machine.clustersSummary.sort.way;

    let newOption = null;
    let newWay = null;

    if (prevOption === option && prevWay === SORT_WAY_ASC) {
      newOption = prevOption;
      newWay = SORT_WAY_DESC;
    } else if (prevOption !== option) {
      newOption = option;
      newWay = SORT_WAY_ASC;
    }

    return {
      ...state,
      machine: {
        ...state.machine,
        clustersSummary: {
          ...state.machine.clustersSummary,
          sort: { option: newOption, way: newWay },
        },
      },
    };
  },
  [filterClustersByDate]: (state, newDates) => {
    const filters = {
      ...state.machine.clustersSummary.filters,
      ...newDates,
    };

    return {
      ...state,
      machine: {
        ...state.machine,
        clustersSummary: {
          ...state.machine.clustersSummary,
          filters,
        },
      },
    };
  },
  [filterClustersByName]: (state, query) => ({
    ...state,
    machine: {
      ...state.machine,
      clustersSummary: {
        ...state.machine.clustersSummary,
        filters: {
          ...state.machine.clustersSummary.filters,
          query,
        },
      },
    },
  }),
  [addClusterCategoryFilter]: (state, filter) => ({
    ...state,
    machine: {
      ...state.machine,
      clustersSummary: {
        ...state.machine.clustersSummary,
        filters: {
          ...state.machine.clustersSummary.filters,
          category: [
            ...state.machine.clustersSummary.filters.category,
            filter,
          ],
        },
      },
    },
  }),
  [removeClusterCategoryFilter]: (state, filter) => {
    const category = state.machine.clustersSummary.filters.category;
    const index = category.indexOf(filter);

    if (index === -1) {
      return state;
    }

    return {
      ...state,
      machine: {
        ...state.machine,
        clustersSummary: {
          ...state.machine.clustersSummary,
          filters: {
            ...state.machine.clustersSummary.filters,
            category: [
              ...category.slice(0, index),
              ...category.slice(index + 1),
            ],
          },
        },
      },
    };
  },
  [resetClustersFilters]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      clustersSummary: {
        ...state.machine.clustersSummary,
        filters: {
          ...state.machine.clustersSummary.filters,
          category: [],
          query: '',
        },
      },
    },
  }),
};

export function* groupFiltersUpdatesSaga() {
  const state = yield select();
  const { id } = yield call(machine, state);

  yield delay(DELAY_TIME_IN_MILLISECONDS);
  yield put(fetchEventsSummaryByMachine(id));
}

export function* watchGroupFiltersUpdates() {
  yield delay(500);

  yield takeLatest([
    filterGroupsByDate.getType(),
    filterGroupsByName.getType(),
    addGroupCategoryFilter.getType(),
    removeGroupCategoryFilter.getType(),
    resetGroupsFilters.getType(),
  ], groupFiltersUpdatesSaga);
}

export function* clusterFiltersUpdatesSaga() {
  const state = yield select();
  const { id } = yield call(machine, state);

  yield delay(DELAY_TIME_IN_MILLISECONDS);
  yield put(fetchClustersSummaryByMachine(id));
}

export function* watchClusterFiltersUpdates() {
  yield delay(500);

  yield takeLatest([
    filterClustersByDate.getType(),
    filterClustersByName.getType(),
    addClusterCategoryFilter.getType(),
    removeClusterCategoryFilter.getType(),
    resetClustersFilters.getType(),
  ], clusterFiltersUpdatesSaga);
}
