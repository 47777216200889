import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const adminFetchAccounts = createAction(
  'admin: fetch accounts',
);

export const adminFetchAccountsSuccess = createAction(
  'admin: fetch accounts - success',
  (list) => list,
);

export const adminFetchAccountsFail = createAction(
  'admin: fetch accounts - fail',
  (error) => error,
);

export const reducer = {
  [adminFetchAccounts]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      accounts: {
        ...state.admin.accounts,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [adminFetchAccountsSuccess]: (state, list) => ({
    ...state,
    admin: {
      ...state.admin,
      accounts: {
        ...state.admin.accounts,
        list,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [adminFetchAccountsFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      accounts: {
        ...state.admin.accounts,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* adminFetchAccountsSaga() {
  const { response, error } = yield call(api.admin.getAccounts);

  if (response) {
    yield put(adminFetchAccountsSuccess(response));
  } else {
    yield put(adminFetchAccountsFail(error));
  }
}

export function* watchAdminFetchAccounts() {
  yield takeEvery(adminFetchAccounts.getType(), adminFetchAccountsSaga);
}
