import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { mergeObjectInList, replaceObjectInList, compareById } from '../../../../common/helpers/object';
import { initializeCalendarEventsFromAvailability } from '../../core/common/calendar';
import { getCalendarById } from '../selectors/calendar.selector';
import { resetFilterQueries } from './calendars.query.module';

export const fetchCalendarEvents = createAction(
  'fetch calendar events',
  ({ calendar, fromDate, toDate }) => ({ calendar, fromDate, toDate }),
);

export const reloadCalendarEvents = createAction(
  'reload calendar events',
  (calendarId) => calendarId,
);

export const fetchCalendarEventsSuccess = createAction(
  'fetch calendar events - success',
  (events, calendar, fromDate, toDate) => ({
    events, calendar, fromDate, toDate,
  }),
);

export const fetchCalendarEventsFail = createAction(
  'fetch calendar events - fail',
  (calendarId, error) => ({ calendarId, error }),
);

export const reducer = {
  [fetchCalendarEvents]: (state, { calendar, fromDate, toDate }) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: mergeObjectInList(
        state.machineCalendars.list,
        {
          id: calendar.id,
          events: {
            fromDate,
            toDate,
            list: [],
            loading: true,
            loaded: false,
            error: null,
          },
        },
        compareById,
      ),
    },
  }),

  [fetchCalendarEventsSuccess]: (state, {
    events, calendar, fromDate, toDate,
  }) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: replaceObjectInList(
        state.machineCalendars.list,
        {
          ...calendar,
          events: {
            fromDate,
            toDate,
            list: events,
            loading: false,
            loaded: true,
            error: null,
          },
        },
        compareById,
      ),
    },
  }),

  [fetchCalendarEventsFail]: (state, { calendarId, error }) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: mergeObjectInList(
        state.machineCalendars.list,
        {
          id: calendarId,
          events: {
            fromDate: null,
            toDate: null,
            list: [],
            loading: false,
            loaded: false,
            error,
          },
        },
        compareById,
      ),
    },
  }),
};

export function* fetchCalendarEventsSaga({ payload: { calendar, fromDate, toDate } }) {
  const {
    response, error,
  } = yield call(api.calendars.getCalendarEvents, calendar.id, fromDate, toDate);

  if (response) {
    const preparedEvents = initializeCalendarEventsFromAvailability(
      response.availability,
      response.events,
      calendar,
    );
    yield put(fetchCalendarEventsSuccess(preparedEvents, calendar, fromDate, toDate));
  } else {
    yield put(fetchCalendarEventsFail(calendar.id, error));
  }

  yield put(resetFilterQueries());
}

export function* watchFetchCalendarEvents() {
  yield takeEvery(fetchCalendarEvents.getType(), fetchCalendarEventsSaga);
}

export function* reloadCalendarEventsSaga({ payload: calendarId }) {
  const state = yield select();
  const calendar = yield call(getCalendarById, state, calendarId);

  if (!calendar) return;

  const { fromDate, toDate } = calendar.events;
  yield put(fetchCalendarEvents({ calendar, fromDate, toDate }));
}

export function* watchReloadCalendarEvents() {
  yield takeEvery(reloadCalendarEvents.getType(), reloadCalendarEventsSaga);
}
