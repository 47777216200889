import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { DATE_FROM, DATE_TO } from '../../../../../common/constants';
import * as datesModes from '../../../../../common/enums/dates.modes';
import * as relativeUnits from '../../../../../common/constants/relative.units';
import { datePropType, relativeDatePropType } from '../../../core/propTypes';
import InputError from '../inputError/InputError';
import relativeUnitsMessages from '../../../messages/relative.units.messages';
import commonMessages from '../../../messages/common.messages';

const handleMouseDown = (e) => e.preventDefault();

export const DatePickerMainButtons = ({
  isOpen,
  onClick,
  disabled,
  intl,
  meta,
  shortPreview,
  datesMode,
  tabIndex,
  ...props
}) => {
  const hasError = meta.touched && meta.error;

  const inputClassName = classnames(
    'datepicker-position',
    { opened: isOpen },
    { disabled },
  );

  const dateFormat = {
    day: 'numeric',
    month: shortPreview ? 'short' : 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const isAbsoluteDatesActive = datesMode === datesModes.ABSOLUTE;
  const isRelativeDatesActive = datesMode === datesModes.RELATIVE;

  const fromInputValue = (isAbsoluteDatesActive && props[DATE_FROM]) ?
    intl.formatDate(props[DATE_FROM], dateFormat) : '...';
  const toInputValue = (isAbsoluteDatesActive && props[DATE_TO]) ?
    intl.formatDate(props[DATE_TO], dateFormat) : '...';

  let fromRelativeInputValue = '...';
  if (isRelativeDatesActive && props.fromRelative) {
    fromRelativeInputValue = props.fromRelative.count > 0 ?
      intl.formatMessage(
        relativeUnitsMessages[props.fromRelative.unit],
        { count: props.fromRelative.count },
      ) : intl.formatMessage(relativeUnitsMessages[relativeUnits.now]);
  }

  let toRelativeInputValue = '...';
  if (isRelativeDatesActive && props.toRelative) {
    toRelativeInputValue = props.toRelative.count > 0 ?
      intl.formatMessage(
        relativeUnitsMessages[props.toRelative.unit],
        { count: props.toRelative.count },
      ) : intl.formatMessage(relativeUnitsMessages[relativeUnits.now]);
  }

  const absoluteDatePeriodValue = (props[DATE_FROM] || props[DATE_TO]) ?
    `${fromInputValue} - ${toInputValue}` : '';
  const relativeDatePeriodValue = (props.fromRelative || props.toRelative) ?
    `${fromRelativeInputValue} - ${toRelativeInputValue}` : '';

  let label = '';
  if (isAbsoluteDatesActive) {
    label = absoluteDatePeriodValue;
  } else if (isRelativeDatesActive) {
    label = relativeDatePeriodValue;
  }

  const placeholder = intl.formatMessage(commonMessages.selectDatePeriod);

  return (
    <>
      <div className={inputClassName}>
        <button
          type="button"
          onClick={onClick}
          className="button button-block light-grey-white button-date"
          onMouseDown={handleMouseDown}
          tabIndex={tabIndex}
        >
          { label || placeholder }
        </button>
        <span className="icon-calendar" />
      </div>
      { hasError && (
        <div className="error-line">
          <InputError error={meta.error} />
        </div>
      )}
    </>
  );
};

DatePickerMainButtons.propTypes = {
  [DATE_FROM]: datePropType, // eslint-disable-line react/no-unused-prop-types
  [DATE_TO]: datePropType, // eslint-disable-line react/no-unused-prop-types
  fromRelative: relativeDatePropType,
  toRelative: relativeDatePropType,
  datesMode: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  shortPreview: PropTypes.bool,
  tabIndex: PropTypes.number,

  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    touched: PropTypes.bool,
  }),
};

DatePickerMainButtons.defaultProps = {
  isOpen: false,
  onClick() {},
  meta: {},
  disabled: false,
  shortPreview: false,
};

export default injectIntl(DatePickerMainButtons);
