import { connect } from 'react-redux';

import Select from '../../../../../_tools/formFields/select/Select';
import {
  getUsers,
  loading,
  loaded,
} from '../../../../../../redux/selectors/usersSearch.selector';

const getOptions = (users = []) => users.map((user) => ({
  label: user.name,
  value: user,
}));

export const mapStateToProps = (state, { input }) => ({
  options: getOptions(getUsers(state)),
  isLoading: loading(state),
  loaded: loaded(state),
  isClearable: true,
  onChange: (option) => input.onChange(option),
});

export default connect(mapStateToProps)(Select);
