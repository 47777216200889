import React, { useEffect } from 'react';

import LeftBarItem from './items/LeftBarItem';
import RightBarItem from './items/RightBarItem';
import LeftBarPlaceholder from './placeholder/LeftBarPlaceholder';
import RightBarPlaceholder from './placeholder/RightBarPlaceholder';
import { getParentOfLocationFromPath, splitTreeIntoPickerSides } from '../../../core/common/locationPicker';
import { TodoFunction } from '../../../../../common/types/common';
import { getTree, isLoading } from '../../../redux/selectors/locationPicker.selector';
import { fetchLocationPicker } from '../../../redux/modules/locationPicker.fetch.module';
import { selectLocation } from '../../../redux/modules/locationPicker.select.module';
import { initLocationPicker } from '../../../redux/modules/locationPicker.initialize.module';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  initLocationId?: string,
  onChangeHook?: TodoFunction,
}


export const LocationPicker: React.FC<Props> = ({ initLocationId, onChangeHook }) => {

  const loading = useSelector(isLoading);

  const tree = useSelector(getTree);

  const { leftSide, rightSide } = splitTreeIntoPickerSides(tree);

  const dispatch = useDispatch();

  const fetch = (parentId) => dispatch(fetchLocationPicker(parentId));
  const select = (locationId) => dispatch(selectLocation(locationId));
  const init = (initLocationIdArg) => dispatch(initLocationPicker(initLocationIdArg));

  const loadLocations = (parentId = null) => {
    fetch(parentId);
  };
  // TODO: fix me, avoid suppressing the linter
  useEffect(() => {
    if (initLocationId) {
      init(initLocationId);
    } else {
      loadLocations(null);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelect = (locationId) => {
    if (onChangeHook) onChangeHook(locationId);
    select(locationId);
  };

  const onDeselect = (locationId) => {
    const parent = getParentOfLocationFromPath(leftSide, locationId);
    const parentId = (parent && parent.id) ? parent.id : null;

    if (onChangeHook) onChangeHook(parentId);
    select(parentId);
  };

  return (
    <div className="locations-all">
      <div className="locations-block">
        <div className="locations--left">
          <div className="locations-results">
            {(!loading) && leftSide.map((item) => (
              <LeftBarItem
                key={item.id}
                id={item.id}
                name={item.name}
                type={item.type}
                isActive
                isSelected
                onClick={onDeselect}
              />
            ))}
            {(!loading) && (!leftSide.length) && (<LeftBarPlaceholder/>)}
          </div>
        </div>
        <div className="locations--right">
          <ul className="locations-list">
            {(!loading) && rightSide.map((item) => (
              <RightBarItem
                key={item.id || 'placeholder'}
                id={item.id || ''}
                name={item.name}
                onClick={onSelect}
              />
            ))}
          </ul>
          {(!loading) && (!rightSide.length) && (<RightBarPlaceholder/>)}
        </div>
      </div>
    </div>
  );
};

export default LocationPicker;
