import React from 'react';
import { FormattedMessage } from 'react-intl';

export const AlreadySelectedWarning = () => (
  <div className="small-alert-text">
    <FormattedMessage
      tagName="div"
      className="small-alert-text"
      id="configRepo.explorer.item_selected"
      defaultMessage="This item already selected"
    />
  </div>
);

export default AlreadySelectedWarning;
