import { FormattedMessage } from 'react-intl';
import { UPLOAD_DEFAULT_ACCEPT } from '../../../../../common/constants/datapoints.excel';
import { bytesToMega } from '../../../../../common/utils';

import React from 'react';

interface FileDropZoneProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept: string;
  maxSize: number;
  multiple: boolean;
  inputFile: React.RefObject<HTMLInputElement>;
}

export const FileDropZone: React.FC<FileDropZoneProps> = ({
  onFileChange,
  accept,
  maxSize,
  inputFile,
  multiple,
}) => (
  <>
    <div className='upload-info'>
      <div>
        <p>
          <FormattedMessage id='datatron.modals.drop_files_for_importing' />
        </p>
        <p>
          <FormattedMessage id='datatron.modals.maximum_allowed_upload_file_size' />
          {bytesToMega(maxSize)}MB.
        </p>
        <p>
          <FormattedMessage id='datatron.modals.supported_files_format' />
          {accept ?? UPLOAD_DEFAULT_ACCEPT}
        </p>
      </div>
    </div>
    <input
      type='file'
      hidden
      id='browse'
      onChange={onFileChange}
      accept={accept}
      multiple={multiple}
      ref={inputFile}
    />
    <label htmlFor='browse' className='browse-btn'>
      <FormattedMessage id='common.browse' />
    </label>
  </>
);
