import { connect } from 'react-redux';

import RoleFormBody from './RoleFormBody';
import * as selector from '../../../../../../redux/selectors/admin.roles.selector';

export const mapStateToProps = (state) => {
  const role = selector.getRole(state);
  const allUsersOptions = role.users.map((user) => ({
    label: user.name,
    value: user.id,
  }));

  return {
    allUsersOptions,
  };
};

export default connect(mapStateToProps)(RoleFormBody);
