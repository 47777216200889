import { createAction } from 'redux-act';

export const showNextDataPoints = createAction(
  'show next machine subscriptions',
  (nextCount) => nextCount,
);

export const reducer = {
  [showNextDataPoints]: (state, nextCount) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        pagination: {
          ...state.machine.dataPoints.pagination,
          current: state.machine.dataPoints.pagination.current + nextCount,
        },
      },
    },
  }),
};
