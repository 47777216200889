import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import * as _ from 'lodash';

import * as eventsApi from '../../core/api/events.js';
import * as selector from '../selectors/dashboard.selector';

export const fetchEvents = createAction(
  'fetch all events',
  (isUpdating) => !!isUpdating,
);

export const fetchEventsSuccess = createAction(
  'fetch all events successfully',
  (events) => events,
);

export const fetchEventsFail = createAction(
  'fetch all events fail',
  (error) => error,
);

export const reducer = {
  [fetchEvents]: (state, isUpdating) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      loaded: false,
      loading: true,
      isUpdating,
      error: '',
    },
  }),

  [fetchEventsSuccess]: (state, events) => {
    let newEvents = events;
    if (selector.loadEventsFrom(state) !== 0) {
      newEvents = _.uniqBy(
        [...selector.allEvents(state), ...events],
        'id',
      );
    }

    return {
      ...state,
      dashboard: {
        ...state.dashboard,
        events: newEvents,
        loaded: true,
        loading: false,
      },
    };
  },

  [fetchEventsFail]: (state, error) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      loaded: false,
      loading: false,
      error,
    },
  }),
};

export function* fetchEventsSaga() {
  const state = yield select();
  const itemFilters = yield call(selector.itemFilters, state);
  const fromIndex = yield call(selector.loadEventsFrom, state);
  const toIndex = yield call(selector.loadEventsTo, state);
  const dateFilters = yield call(selector.dateFilters, state);

  const { response, error } = yield call(eventsApi.getAllEvents, {
    ...itemFilters,
    ...dateFilters,
    fromIndex,
    toIndex,
  });

  if (response) {
    yield put(fetchEventsSuccess(response));
  } else {
    yield put(fetchEventsFail(error));
  }
}

export function* watchFetchEvents() {
  yield takeEvery(fetchEvents.getType(), fetchEventsSaga);
}
