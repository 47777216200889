import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SortHeader from '../../../../_tools/sortHeader/SortHeader';
import NameFilterContainer from './nameFilter/NameFilter.container';
import {
  SORT_BY_NAME,
} from '../../../../../../../common/constants/admin.organizations';

const OrganizationsHeader = ({ onSortChange, sortOption, sortWay }) => (
  <thead>
    <tr>
      <td>
        <div className="flex-name">
          <SortHeader
            option={SORT_BY_NAME}
            way={(sortOption === SORT_BY_NAME) ? sortWay : null}
            onClick={onSortChange}
          >
            <FormattedMessage
              id="common.name"
              defaultMessage="Name"
            />
          </SortHeader>
          <div className="rel-wrap">
            <div className="flex">
              <div className="flex-icon">
                <NameFilterContainer />
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className="td-actions">
        <FormattedMessage
          id="users.actions"
          defaultMessage="Actions"
        />
      </td>
    </tr>
  </thead>
);

OrganizationsHeader.propTypes = {
  sortOption: PropTypes.string,
  sortWay: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
};

export default OrganizationsHeader;
