import React from 'react';
import PropTypes from 'prop-types';

import Kpi from '../../_tools/kpi/Kpi';

export const SidePanel = ({ data, main, loading }) => (
  <div className="round-charts-wrap">
    <div className="round-charts">
      <Kpi
        data={main}
        isActive
        isLoading={loading}
        background="kpi-background--dark"
        percentageClass="kpi__percentage kpi__percentage--white"
        viewOnly
      />
      { data.map((kpi, index) => (
        <Kpi
          data={kpi}
          key={index}
          isLoading={loading}
          background="kpi-background--dark"
          percentageClass="kpi__percentage kpi__percentage--white"
          viewOnly
        />
      ))}
    </div>
  </div>
);

SidePanel.propTypes = {
  main: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
