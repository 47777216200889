import { connect } from 'react-redux';

import LocationModule from './LocationModule';
import * as selector from '../../../../../redux/selectors/admin.locations.selector';
import { getValuesFromLocation } from '../../../../../core/common/admin.location.form';

export const mapStateToProps = (state) => {
  const loaded = selector.isLocationLoaded(state);
  const location = selector.getLocation(state);
  const props = { isViewForm: true, loaded, formType: 'view' };

  if (loaded) {
    props.locationId = location.id;
    props.initialValues = getValuesFromLocation(location);
    props.formBodyProps = {
      parent: location.parent,
    };
  }

  return props;
};

export default connect(mapStateToProps)(LocationModule);
