import React from 'react';
import PropTypes from 'prop-types';

export const DeviceClass = ({ deviceClass, choose, close }) => {
  const onClick = () => {
    close();
    choose(deviceClass);
  };

  return (
    <li className={`device-item-${deviceClass}`}>
      <a onClick={onClick}>
        { deviceClass }
      </a>
    </li>
  );
};

DeviceClass.propTypes = {
  deviceClass: PropTypes.string.isRequired,
  choose: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default DeviceClass;
