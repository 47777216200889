import querystring from 'querystring';
import {
  apiRequest,
  deleteApiRequest,
  putApiRequest,
  postApiRequest,
} from './_tools';

export const getLocations = (filters) => {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/locations?${query}`);
};
export const getLocationsPagination = (filters) => {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/locations/pagination?${query}`);
};
export const getLocation = (id) => apiRequest(`/api/locations/${id}`);
export const removeLocation = (id) => deleteApiRequest(`/api/locations/${id}`);
export const createLocation = (body) => postApiRequest('/api/locations', body);
export const updateLocation = (id, body) => putApiRequest(`/api/locations/${id}`, body);

export const getUsers = (filters) => {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/users?${query}`);
};
export const getUser = (id) => apiRequest(`/api/users/${id}`);
export const removeUser = (id) => deleteApiRequest(`/api/users/${id}`);
export const createUser = (body) => postApiRequest('/api/users', body);
export const updateUser = (id, body) => putApiRequest(`/api/users/${id}`, body);
export const addUserRole = (id, role) => postApiRequest(`/api/users/${id}/roles`, { role });
export const removeUserRole = (id, role) => deleteApiRequest(`/api/users/${id}/roles/${role}`);

export const getAllOrganizations = () => apiRequest('/api/organizations/all');
export const getOrganizations = (filters) => {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/organizations?${query}`);
};
export const getOrganizationsPagination = (filters) => {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/organizations/pagination?${query}`);
};
export const getOrganization = (id) => apiRequest(`/api/organizations/${id}`);
export const updateOrganization = (id, body) => putApiRequest(`/api/organizations/${id}`, body);
export const removeOrganization = (id) => deleteApiRequest(`/api/organizations/${id}`);
export const createOrganization = (body) => postApiRequest('/api/organizations', body);

export const getAccounts = () => apiRequest('/api/accounts');
export const getAccount = (id) => apiRequest(`/api/accounts/${id}`);
export const updateAccount = (id, body) => putApiRequest(`/api/accounts/${id}`, body);
export const createAccount = (body) => postApiRequest('/api/accounts', body);

export const getRoles = () => apiRequest('/api/roles');
export const getRole = (id) => apiRequest(`/api/roles/${id}`);
