import { createAction } from 'redux-act';
import { put, call, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import * as api from '../../core/api';
import { mergeObjectInList, replaceObjectInList, compareById } from '../../../../common/helpers/object';
import { getCalendarById } from '../selectors/calendar.selector';
import { reloadCalendarEvents } from './calendars.fetch.calendarEvents.module';
import * as calendarEntryAction from '../constants/calendars.entry.constants';
import { closeModal } from './modals.module';
import { CP_EVENT_EDIT } from '../constants/modals.constants';
import { createRecurrenceRule } from '../../../../common/helpers/calendar.helper';
import {
  overwriteEventPayload,
  getChangesWithOriginalDay,
  checkCorrespondenceOfDates,
} from '../../core/common/calendar.events';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from '../../core/notifications';

export const updateCalendarEvent = createAction(
  'update calendar event',
  (calendarId, event, changes, actionType, date) => ({
    calendarId, event, changes, actionType, date,
  }),
);

export const updateCalendarEventSuccess = createAction(
  'update calendar event - success',
  (calendarId, event) => ({ calendarId, event }),
);

export const updateCalendarEventFail = createAction(
  'update calendar event - fail',
  (calendarId, eventId, error) => ({ calendarId, eventId, error }),
);

export const reducer = {
  [updateCalendarEvent]: (state, { event, calendarId }) => {
    const calendar = getCalendarById(state, calendarId);
    if (!calendar) return state;

    const newCalendar = {
      ...calendar,
      events: {
        ...calendar.events,
        list: mergeObjectInList(
          calendar.events.list,
          {
            id: event.id,
            _update: {
              loaded: false,
              loading: true,
              error: null,
            },
          },
          compareById,
        ),
      },
    };

    return {
      ...state,
      machineCalendars: {
        ...state.machineCalendars,
        list: replaceObjectInList(
          state.machineCalendars.list,
          newCalendar,
          compareById,
        ),
      },
    };
  },
  [updateCalendarEventFail]: (state, { calendarId, eventId, error }) => {
    const calendar = getCalendarById(state, calendarId);
    if (!calendar) return state;

    const newCalendar = {
      ...calendar,
      events: {
        ...calendar.events,
        list: mergeObjectInList(
          calendar.events.list,
          {
            id: eventId,
            _update: {
              loaded: false,
              loading: false,
              error,
            },
          },
          compareById,
        ),
      },
    };

    return {
      ...state,
      machineCalendars: {
        ...state.machineCalendars,
        list: replaceObjectInList(
          state.machineCalendars.list,
          newCalendar,
          compareById,
        ),
      },
    };
  },
};

export function* updateAllEvents({ calendarId, event, changes }) {
  const { response, error } = yield call(
    api.calendars.updateEvent,
    event.id,
    getChangesWithOriginalDay(event, changes),
  );

  if (response) {
    yield put(updateCalendarEventSuccess(calendarId, response));
  } else {
    yield put(updateCalendarEventFail(calendarId, event.id, error));
  }
}

export function* updateSingleEvent({
  calendarId, event, changes, date,
}) {
  const eventId = event.id;
  const { startDateTime, endDateTime } = changes;

  const { response: deleteResponse, error: deleteError } = yield call(
    api.calendars.deleteSingleEventOccurrence,
    eventId,
    date,
  );

  if (deleteResponse) {
    const { response, error } = yield call(
      api.calendars.createEvent,
      calendarId,
      {
        startDateTime,
        endDateTime,
      },
    );

    if (response) {
      yield put(updateCalendarEventSuccess(calendarId, response));
    } else {
      yield put(updateCalendarEventFail(calendarId, eventId, error));
    }
  } else {
    yield put(updateCalendarEventFail(calendarId, eventId, deleteError));
  }
}

export function* updateCurrentAndFutureEvents({
  calendarId, event, changes, date,
}) {
  const repeatEnd = moment(date)
    .startOf('day')
    .subtract(1, 'second')
    .toISOString();

  const { response: updateResponse, error: updateError } = yield call(
    api.calendars.updateEvent,
    event.id,
    {
      ...event,
      repeatEnd,
      recurrencePattern: createRecurrenceRule({ repeatEnd, repeatType: event.repeatType }),
    },
  );

  if (updateResponse) {
    const newEventDate = moment(date);
    const newEventPayload = {
      ...event,
      ...changes,
    };

    newEventPayload.startDateTime = moment(newEventPayload.startDateTime)
      .year(newEventDate.year())
      .month(newEventDate.month())
      .date(newEventDate.date())
      .toISOString();
    newEventPayload.endDateTime = moment(newEventPayload.endDateTime)
      .year(newEventDate.year())
      .month(newEventDate.month())
      .date(newEventDate.date())
      .toISOString();

    const { response, error } = yield call(api.calendars.createEvent, calendarId, newEventPayload);

    if (response) {
      yield put(updateCalendarEventSuccess(calendarId, updateResponse));
    } else {
      yield put(updateCalendarEventFail(calendarId, event.id, error));
    }
  } else {
    yield put(updateCalendarEventFail(calendarId, event.id, updateError));
  }
}

export function* updateUnsetEventRepeat({ calendarId, event, changes }) {
  const eventId = event.id;

  const { response: exceptionsResponse, error: exceptionsError } = yield call(
    api.calendars.getEventExceptions,
    eventId,
  );

  if (exceptionsResponse && exceptionsResponse.length) {
    if (checkCorrespondenceOfDates(exceptionsResponse, event)) {
      return yield call(updateAllEvents, { calendarId, event, changes });
    }
  } else if (exceptionsError) {
    return yield put(updateCalendarEventFail(calendarId, eventId, exceptionsError));
  }

  const { response: updateResponse, error: updateError } = yield call(
    api.calendars.updateEvent,
    eventId,
    {
      ...event,
      ...changes,
    },
  );

  if (updateResponse) {
    const { response, error } = yield call(
      api.calendars.createEvent,
      calendarId,
      {
        startDateTime: event.startDateTime,
        endDateTime: event.endDateTime,
      },
    );

    if (response) {
      return yield put(updateCalendarEventSuccess(calendarId, updateResponse));
    }

    return yield put(updateCalendarEventFail(calendarId, event.id, error));
  }

  return yield put(updateCalendarEventFail(calendarId, eventId, updateError));
}

const updateEvent = {
  [calendarEntryAction.NON_RECURRENT]: updateAllEvents,
  [calendarEntryAction.ALL]: updateAllEvents,
  [calendarEntryAction.CURRENT_ONLY]: updateSingleEvent,
  [calendarEntryAction.CURRENT_AND_FUTURE]: updateCurrentAndFutureEvents,
  [calendarEntryAction.UNSET_REPEAT]: updateUnsetEventRepeat,
};

export function* updateCalendarEventSaga({ payload }) {
  const newPayload = overwriteEventPayload(payload);

  yield call(updateEvent[newPayload.actionType], newPayload);
}

export function* watchUpdateCalendarEvent() {
  yield takeLatest(updateCalendarEvent.getType(), updateCalendarEventSaga);
}

export function* afterSuccessActionSaga({ payload: { calendarId } }) {
  yield put(reloadCalendarEvents(calendarId));
  yield put(closeModal(CP_EVENT_EDIT));
  yield put(sendNotification(
    notificationMessage.successfully_changed_event,
    notificationMessage.successfully_changed_event_description,
    NOTIFICATION_EVENT,
  ));
}

export function* watchSuccessUpdateCalendarEventAction() {
  yield takeLatest(updateCalendarEventSuccess.getType(), afterSuccessActionSaga);
}

export function* afterFailedActionSaga({ payload: { error } }) {
  yield put(sendNotification(
    notificationMessage.successfully_changed_event,
    {
      ...notificationMessage.failed_to_update_event_description,
      values: { errorMsg: error || '' },
    },
    NOTIFICATION_ERROR,
  ));
}

export function* watchFailedUpdateCalendarEventAction() {
  yield takeLatest(updateCalendarEventFail.getType(), afterFailedActionSaga);
}
