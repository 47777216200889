import React from 'react';
import { useIntl } from 'react-intl';
import commonCoreMessages from '../../../../../../../../messages/common.core.messages';
import { Todo } from '../../../../../../../../../../common/types/common';
import { DataPointsActionMenu } from './DataPointsActionMenu';
import { SelectAllCheckBox } from './SelectAllCheckBox';

interface Props {
  deviceId: string;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  dataPoints: Todo[];
}

export const DataPointsTableSelectors: React.FC<Props> = ({
  deviceId,
  selectedRows,
  dataPoints,
  setSelectedRows,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className='datapoints-filter'>
        <SelectAllCheckBox
          setSelectedRows={setSelectedRows}
          dataPoints={dataPoints}
          selectedRows={selectedRows}
        />
        <div className='d-flex align-items-center mr-20'>
          <DataPointsActionMenu
            deviceId={deviceId}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
          <span
            className={`flex-name-label highlight-action-text ${selectedRows.length > 0 && 'selected'}`}
          >
            {formatMessage(commonCoreMessages.actions)}
          </span>
        </div>
        <div className='split-border' />
      </div>
    </>
  );
};
