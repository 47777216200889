export const ADMIN_USER_FORM_NAME = 'ADMIN_USER_FORM_NAME';

export const FIRST_NAME_FIELD = 'firstName';
export const LAST_NAME_FIELD = 'lastName';
export const MIDDLE_NAME_FIELD = 'middleName';
export const POSITION_FIELD = 'position';
export const EMAIL_FIELD = 'email';
export const PHONE_NUMBER_FIELD = 'phoneNumber';
export const LOCALE_FIELD = 'locale';
export const IS_WORKING_FIELD = 'isWorking';
export const NEW_PASSWORD_FIELD = 'newPassword';
export const NEW_PASSWORD_CONFIRMATION_FIELD = 'newPasswordConfirmation';
export const STATUS_FIELD = 'status';
export const LOCATION_ID_FIELD = 'locationId';
export const ROLES_FIELD = 'roles';
export const ORGANIZATIONS_FIELD = 'organizations';
export const MACHINES_ASSISTANCE_FIELD = 'machinesAssistance';

// "RO" is READ ONLY
export const RO_TOS_ACCEPTED = 'tosAccepted';
export const RO_SIGN_IN_COUNT = 'signInCount';
export const RO_LAST_SIGN_IN_AT = 'lastSignInAt';
export const RO_LAST_SIGN_IN_IP = 'lastSignInIp';
export const RO_CONFIRMED_AT = 'confirmedAt';
export const RO_CURRENT_SIGN_IN_AT = 'currentSignInAt';

export const USER_PROPS = [
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  MIDDLE_NAME_FIELD,
  POSITION_FIELD,
  EMAIL_FIELD,
  PHONE_NUMBER_FIELD,
  LOCALE_FIELD,
  IS_WORKING_FIELD,
  NEW_PASSWORD_FIELD,
  NEW_PASSWORD_CONFIRMATION_FIELD,
  STATUS_FIELD,
  LOCATION_ID_FIELD,
  ORGANIZATIONS_FIELD,
  ROLES_FIELD,
];

export const BASIC_FIELDS = [
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  MIDDLE_NAME_FIELD,
  LOCATION_ID_FIELD,
  POSITION_FIELD,
  EMAIL_FIELD,
  PHONE_NUMBER_FIELD,
  LOCALE_FIELD,
];
export const ADVANCED_FIELDS = [
  NEW_PASSWORD_FIELD,
  NEW_PASSWORD_CONFIRMATION_FIELD,
  STATUS_FIELD,
  IS_WORKING_FIELD,
  ROLES_FIELD,
  ORGANIZATIONS_FIELD,
];
