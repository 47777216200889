import React, { useEffect } from 'react';
import { Todo, TodoFunction } from '../../../../common/types/common';
import { FormType } from '../../core/forms/general';
import {
  OS_CONFIG,
  OS_CONFIG_DEFAULT,
  PHOTO_FIELD,
  NETWORK_SPEED_FIELD,
  NETWORK_SPEED_FIELD_DEFAULT,
} from '../../redux/constants/datatron.form.constants';
import { NO_FILE } from '../../redux/constants/fileUpload.component.constants';
import * as routeHandlers from '../index/routeHandlers';
import DatatronForm from './forms/DatatronForm';
import DatatronFormDetails from './forms/details/DatatronFormDetails';
import { DatatronFormPageHeader } from './headers/DatatronFormPageHeader';

interface Props {
  backLink: string;
  formType: FormType;
  initialValues?: Todo;
  initializeSearchableFields?: TodoFunction;
  detailsFormProps?: {
    photoUrl?: string;
    location?: Todo;
  };
  datatron?: Todo;
}

export const DatatronFormPage: React.FC<Props> = ({
  formType,
  initialValues = {
    [PHOTO_FIELD]: NO_FILE,
    [OS_CONFIG]: OS_CONFIG_DEFAULT,
    [NETWORK_SPEED_FIELD]: NETWORK_SPEED_FIELD_DEFAULT,
  },
  detailsFormProps = {
    photoUrl: '',
    location: null,
  },
  initializeSearchableFields,
  datatron,
  backLink,
}) => {
  useEffect(() => {
    if (formType === 'add') {
      routeHandlers.onNewDatatronEnter();
      return () => routeHandlers.onNewDatatronLeave();
    }

    // form type is 'edit'
    if (initializeSearchableFields) {
      initializeSearchableFields();
    }
    routeHandlers.onDatatronEditEnter(datatron?.id);
    return () => routeHandlers.onDatatronEditLeave();
  }, [datatron?.id, formType, initializeSearchableFields]);

  return (
    <div className='col--1'>
      <DatatronFormPageHeader backLink={backLink} formType={formType} />
      {/* @ts-ignore */}
      <DatatronForm formType={formType} initialValues={initialValues} backLink={backLink}>
        <DatatronFormDetails canSetUUID={formType === 'add'} {...detailsFormProps} />
      </DatatronForm>
    </div>
  );
};

export default DatatronFormPage;
