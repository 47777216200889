import { createAction } from 'redux-act';

export const filterByDate = createAction(
  'filter processings (in batch details) by date',
  (batchId, newDates) => ({ batchId, newDates }),
);

export const reducer = {
  [filterByDate]: (state, { newDates }) => ({
    ...state,
    batchDetails: {
      ...state.batchDetails,
      processings: {
        ...state.batchDetails.processings,
        filters: {
          ...state.batchDetails.processings.filters,
          ...newDates,
        },
      },
    },
  }),
};
