import { defineMessages } from 'react-intl';

import * as fileFormats from '../../../common/constants/fileFormats';

export default defineMessages({
  [fileFormats.XLSX]: {
    id: 'common.fileFormats.xlsx',
    defaultMessage: 'XLSX',
  },
  [fileFormats.CSV]: {
    id: 'common.fileFormats.csv',
    defaultMessage: 'CSV',
  },
  [fileFormats.TSV]: {
    id: 'common.fileFormats.tsv',
    defaultMessage: 'TSV',
  },
});
