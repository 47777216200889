import { connect } from 'react-redux';

import DatesFrame from './DatesFrame';
import { _PAGE_REPORT_FORM as modalId } from '../../../../../../redux/constants/modals.constants';
import { goBackModalPath } from '../../../../../../redux/modules/modals.module';
import { restoreFormField } from '../../../../../../redux/modules/common.form.module';
import * as form from '../../../../../../redux/constants/report.form.constants';

export const mapDispatchToProps = {
  goBackModalPath,
  restoreFormField,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onCancelClick: (e) => {
    if (e) { e.preventDefault(); }
    dispatchProps.restoreFormField(form.REPORT_FORM_NAME, form.DATES_FIELD);
    dispatchProps.goBackModalPath(modalId);
  },
  onDoneClick: (e) => {
    if (e) { e.preventDefault(); }
    dispatchProps.goBackModalPath(modalId);
  },
});

export default connect(null, mapDispatchToProps, mergeProps)(DatesFrame);
