import { connect } from 'react-redux';

import ComplexModal from './ComplexModal';
import * as selector from '../../../../redux/selectors/modals.selector';
import { flatModalConfig, getActivePath } from '../../../../core/common/modals';
import { setModalPath } from '../../../../redux/modules/modals.module';

export const mapStateToProps = (state, { modalId, modalConfig }) => {
  const flattenModalConfig = flatModalConfig(modalConfig);
  const activePathIds = selector.getModalPath(state, modalId);
  const activePath = getActivePath(flattenModalConfig, activePathIds);
  const modalTitle = activePath.map((item) => item.title).join(' > ');

  return { flattenModalConfig, activePath, modalTitle };
};

export const mapDispatchToProps = {
  setModalPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComplexModal);
