import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3-selection';
import { axisLeft, axisBottom } from 'd3-axis';

export default class HelpLines extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    yScale: PropTypes.func.isRequired,
    xScale: PropTypes.func.isRequired,
    ticks: PropTypes.array.isRequired,
    showVerticalLines: PropTypes.bool.isRequired,
    /* eslint-enable react/no-unused-prop-types */
  };

  componentDidMount() {
    this.renderHelpLines(this.props);
  }

  componentDidUpdate() {
    this.renderHelpLines(this.props);
  }

  renderHelpLines({
    yScale, xScale, height, width, ticks, showVerticalLines,
  }) {
    const horizontal = axisLeft(yScale)
      .tickValues(ticks)
      .tickSize(-width);

    select(this.horizontal)
      .call(horizontal);

    if (showVerticalLines) {
      const vertical = axisBottom(xScale)
        .ticks(4)
        .tickSize(height);

      select(this.vertical)
        .call(vertical);
    }
  }

  render() {
    const { showVerticalLines } = this.props;
    return (
      <g>
        <g
          className="help-line"
          ref={(node) => (this.horizontal = node)}
        />
        { showVerticalLines && (
          <g
            className="help-line"
            ref={(node) => (this.vertical = node)}
          />
        )}
      </g>
    );
  }
}
