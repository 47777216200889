import { connect } from 'react-redux';

import UserModule from './UserModule';
import * as selector from '../../../../../../redux/selectors/admin.users.selector';
import { getValuesFromUser } from '../../../../../../core/common/admin.user.form';

export const mapStateToProps = (state, { match }) => {
  const loaded = selector.isUserLoaded(state) && match.isExact;
  const user = selector.getUser(state);
  const props = { isViewForm: true, loaded };

  if (loaded) {
    props.userId = user.id;
    props.initialValues = getValuesFromUser(user);
  }

  return props;
};

export default connect(mapStateToProps)(UserModule);
