export const country = 'country';
export const state = 'state';
export const city = 'city';
export const district = 'district';
export const factory = 'factory';
export const hall = 'hall';
export const floor = 'floor';
export const assembly = 'assembly';
export const line = 'line';
export const production = 'production';
export const organization = 'organization';

export default [
  organization,
  country,
  state,
  city,
  district,
  factory,
  hall,
  floor,
  assembly,
  line,
  production,
];
