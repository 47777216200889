import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import SliderContainer from '../../../_tools/slider/SliderContainer';
import { changeSelection } from '../../../../redux/modules/reports.slider.module';
import { selectSelectionByReportId } from '../../../../redux/selectors/reports.selector';
import { Todo } from '../../../../../../common/types/common';
import { useParamSelector } from '../../../../redux/util';
import { ReportT } from '../../../../../types/report';

interface Props {
  id: ReportT['id'];
  data: Todo[];
  [key: string]: Todo;
}

const getY = (point) => point.total;

export const ReportSliderContainer: React.FC<Props> = ({
  id,
  data,
  ...rest
}) => {
  const selection = useParamSelector(selectSelectionByReportId, id);
  const dispatch = useDispatch();
  const dispatchChangeSelection = useCallback(
    (domain: Todo, reportId: ReportT['id']) => dispatch(changeSelection(domain, reportId)),
    [dispatch],
  );
  return (
    <SliderContainer
      id={id}
      selection={selection}
      getY={getY}
      changeSelection={dispatchChangeSelection}
      data={data}
      {...rest}
    />
  );
};
