import React from 'react';
import { DATATRONS_PERMISSIONS } from '../../../../common/constants/permissions';
import { usePermissionInstance } from '../common/permissions/usePermissionInstance';
import { getInventoryLink } from '../index/routes';
import Unauthorized from '../pages/unauthorized/Unauthorized';
import DatatronFormPage from './DatatronFormPage';

const NewDatatronFormPage: React.FC = () => {
  const permissions = usePermissionInstance(DATATRONS_PERMISSIONS);
  const canCreateDatatrons = permissions.canCreateDatatrons();

  if (!canCreateDatatrons) {
    return <Unauthorized />;
  }

  return <DatatronFormPage backLink={getInventoryLink()} formType="add" />;
};

export default NewDatatronFormPage;
