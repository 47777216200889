import { AppState } from './state/app.state';
import { useSelector } from 'react-redux';

export type Selector<A extends any[], T> = (state: Readonly<AppState>, ...args: A) => T;

/**
 * A helper function to create a selector with inferred return types
 * @param selectFunction the selector function
 */
export const createSelector = <A extends any[], T>(
  selectFunction: Selector<A, T>,
): Selector<A, T> => selectFunction;

/**
 * A wrapper around the react-redux {@link useSelector} hook that allows to give additional parameter to the selector.
 * @param selector the selector
 * @param args the arguments that will be put into the selector
 */
export const useParamSelector = <A extends any[], T>(
  selector: Selector<A, T>,
  ...args: A
) => useSelector((state: AppState) => selector(state, ...args));

/**
 * A utility type for the result of the "call" redux-saga effect.
 */
export type CallResult<T extends (...args: any[]) => Promise<any>> = Awaited<ReturnType<T>>;