import React from 'react';
import PropTypes from 'prop-types';

import ToolState from './ToolState';
import ToolRecentActivity from './ToolRecentActivity';
import ActionButtonsContainer from './ActionButtons.container';
import { HighlightText } from '../../../_tools';

export const ToolTableRow = ({ tool, textToHighlight }) => (
  <tr>
    <ToolState state={tool.state} />
    <td>
      <HighlightText text={tool.label} highlight={textToHighlight} />
      <br />
      <span className="font-weight-light">
        <HighlightText text={tool.type} highlight={textToHighlight} />
      </span>
    </td>
    <td>
      <HighlightText text={tool.serial} highlight={textToHighlight} />
    </td>
    <ToolRecentActivity tool={tool} />
    <ActionButtonsContainer tool={tool} />
  </tr>
);

ToolTableRow.propTypes = {
  tool: PropTypes.object.isRequired,
  textToHighlight: PropTypes.string,
};

ToolTableRow.defaultProps = {
  textToHighlight: '',
};

export default ToolTableRow;
