import { connect } from 'react-redux';

import { fetchCalendarEvents } from '../../../redux/modules/calendars.fetch.calendarEvents.module';
import CalendarList from './CalendarsList';
import PermissionedComponent from '../../common/permissions/PermissionedComponent.container';
import { CALENDAR_PERMISSIONS } from '../../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => ({
  canChangeEvents: permissions.canChangeEvents(),
});

export const mapDispatchToProps = {
  fetchCalendarEvents,
};

export default PermissionedComponent(CALENDAR_PERMISSIONS)(
  connect(mapStateToProps, mapDispatchToProps)(CalendarList),
);
