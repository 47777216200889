import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Filter from './Filter.container';
import {
  CATEGORY_ANOMALY,
  CATEGORY_ERROR,
  CATEGORY_FATAL,
  CATEGORY_INFO,
  CATEGORY_WARNING,
} from '../../../redux/constants/event.constants';
import { status } from '../../../../../common/enums/dashboardFilterTypes';
import Counter from '../../common/counter/Counter';
import messages from '../../../messages/ColumnFilters.messages';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';
import { useDispatch, useSelector } from 'react-redux';
import {
  addItemFilter,
  confirmFilters,
  removeItemFilter,
  resetPendingFilters,
} from '../../../redux/modules/dashboard.events.filter.module';
import { CATEGORIES } from '../../../../../common/constants/entities';
import { getFilterList } from '../../../redux/selectors/dashboard.selector';

export const options = [
  {
    value: CATEGORY_FATAL,
    label: messages.fatal,
  },
  {
    value: CATEGORY_ERROR,
    label: messages.error,
  },
  {
    value: CATEGORY_WARNING,
    label: messages.warning,
  },
  {
    value: CATEGORY_INFO,
    label: messages.info,
  },
  {
    value: CATEGORY_ANOMALY,
    label: messages.anomaly,
  },
];


export const StatusFiltersV2: React.FC = () => {
  const dispatch = useDispatch();
  const addFilter = useCallback((category) => dispatch(addItemFilter({
    type: CATEGORIES,
    item: { id: category },
  })), [dispatch]);
  const removeFilter = useCallback((category) => dispatch(removeItemFilter({
    type: CATEGORIES,
    item: { id: category },
  })), [dispatch]);
  const pendingFilterReset = useCallback(() => dispatch(resetPendingFilters()), [dispatch]);
  const filterConfirm = useCallback(() => dispatch(confirmFilters()), [dispatch]);

  const filters = useSelector((state) => getFilterList(state, CATEGORIES)).map(item => item.id);

  const [isOpen, setIsOpen] = useState(false);

  const handleFilterChange = useCallback((filter, selected) => {
    if (!selected) {
      addFilter(filter);
    } else {
      removeFilter(filter);
    }
  }, [addFilter, removeFilter]);

  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
      pendingFilterReset();
    }
  };

  const toggle = () => setIsOpen(!isOpen);

  const cancel = useCallback(() => {
    pendingFilterReset();
    setIsOpen(false);
  }, [pendingFilterReset]);

  const save = useCallback(() => {
    filterConfirm();
    setIsOpen(false);
  }, [filterConfirm]);

  const className = classNames('filter--block', {
    opened: isOpen,
    passive: filters.length === 0,
  }, `filter-type--${status}`);

  const clickRef = useClickOutside(handleClickOutside);

  return (
    <div className={className} ref={clickRef}>
      <button
        className="filter--btn"
        type="button"
        onClick={toggle}
      >
        <span className="icon icon-filter"/>
        <FormattedMessage
          id="dashboard.filter.categories"
          defaultMessage="Categories"
        />
        <Counter value={filters.length}/>
      </button>
      <div className="filter--dropdown">
        <ul>
          {options.map((option, index) => (
            <Filter
              key={index}
              option={option}
              filters={filters}
              onChange={handleFilterChange}
            />
          ))}
        </ul>
        <div className="search--buttons">
          <button
            className="button button__sm grey"
            onClick={cancel}
          >
            <FormattedMessage
              id="common.cancel"
              defaultMessage="Cancel"
            />
          </button>
          <button
            className="button button__sm green"
            onClick={save}
          >
            <FormattedMessage
              id="common.apply"
              defaultMessage="Apply"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusFiltersV2;
