import React from 'react';
import ActionButtonsContainer from './ActionButtons';
import { AiApp } from '../../../../redux/state/aiApps.state';

export const AiAppTableRow: React.FC<{ aiApp: AiApp }> = ({ aiApp }) => {
  const className = 'ai-app-label ' + aiApp.status.toLowerCase();
  return (
    <tr>
      <td className='td-category'>
        <div className={className}>
          <span>{aiApp.status}</span>
        </div>
      </td>
      <td>{aiApp.appName}</td>
      <td>{aiApp.datatron.name}</td>
      <td>
        {aiApp.devices.map((device) => (
          <span className='device-list' key={device.id}>
            {device.name}
          </span>
        ))}
      </td>
      <td>{aiApp.description}</td>
      <td>{aiApp.createdAt}</td>
      <ActionButtonsContainer aiAppId={aiApp.id} />
    </tr>
  );
};

export default AiAppTableRow;
