import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { getEventById } from '../../core/api/events';
import { isLazyLoadRequired } from '../selectors/anomaly.selector';

export const fetchAnomaly = createAction(
  'fetch anomaly',
  (id) => id,
);

export const fetchAnomalySuccess = createAction(
  'fetch anomaly successfully',
  (id, event) => ({ id, event }),
);

export const fetchAnomalyFail = createAction(
  'fetch anomaly fail',
  (id, error) => ({ id, error }),
);

export const reducer = {
  [fetchAnomaly]: (state, id) => ({
    ...state,
    anomaly: {
      ...state.anomaly,
      list: {
        ...state.anomaly.list,
        [id]: {
          id,
          loading: true,
          loaded: false,
          error: null,
          ...state.anomaly.list[id],
        },
      },
    },
  }),

  [fetchAnomalySuccess]: (state, { id, event }) => ({
    ...state,
    anomaly: {
      ...state.anomaly,
      list: {
        ...state.anomaly.list,
        [id]: {
          ...state.anomaly.list[id],
          loading: false,
          loaded: true,
          error: null,
          noExtraLazyLoad: true,
          event,
        },
      },
    },
  }),

  [fetchAnomalyFail]: (state, { id, error }) => ({
    ...state,
    anomaly: {
      ...state.anomaly,
      list: {
        ...state.anomaly.list,
        [id]: {
          ...state.anomaly.list[id],
          loading: false,
          loaded: false,
          error,
        },
      },
    },
  }),
};

export function* fetchAnomalySaga({ payload: eventId }) {
  const state = yield select();

  const proceedLazyLoad = yield call(isLazyLoadRequired, state, eventId);
  if (!proceedLazyLoad) return;

  const { response, error } = yield call(getEventById, { eventId, requirePayload: true });

  if (response) {
    yield put(fetchAnomalySuccess(eventId, response));
  } else {
    yield put(fetchAnomalyFail(eventId, error));
  }
}

export function* watchFetchAnomalySaga() {
  yield takeEvery(fetchAnomaly.getType(), fetchAnomalySaga);
}
