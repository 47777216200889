import { createAction } from 'redux-act';
import { SagaIterator } from 'redux-saga';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import * as entryTypes from '../constants/configRepoExplorer.entry.types.constants';
import { CallResult } from '../util';

export const fetchConfigRepoExplorerEntries = createAction(
  'fetch config repo explorer entries',
  (
    entryType = entryTypes.DATATRONS,
    datatronId = null,
    deviceId = null,
    machineId = null,
  ) => ({
    entryType,
    datatronId,
    deviceId,
    machineId,
  }),
);

export const fetchConfigRepoExplorerEntriesSuccess = createAction(
  'fetch config repo explorer entries - success',
  (entryType, list) => ({ entryType, list }),
);

export const fetchConfigRepoExplorerEntriesFail = createAction(
  'fetch config repo explorer entries - fail',
  (entryType, error) => ({ entryType, error }),
);

export const reducer = {
  [fetchConfigRepoExplorerEntries.getType()]: (state, { entryType }) => ({
    ...state,
    configRepoExplorer: {
      ...state.configRepoExplorer,
      [entryType]: {
        ...state.configRepoExplorer[entryType],
        list: [],
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),

  [fetchConfigRepoExplorerEntriesSuccess.getType()]: (state, { entryType, list }) => ({
    ...state,
    configRepoExplorer: {
      ...state.configRepoExplorer,
      [entryType]: {
        ...state.configRepoExplorer[entryType],
        list,
        loading: false,
        loaded: true,
        error: null,
      },
    },
  }),

  [fetchConfigRepoExplorerEntriesFail.getType()]: (state, { entryType, error }) => ({
    ...state,
    configRepoExplorer: {
      ...state.configRepoExplorer,
      [entryType]: {
        ...state.configRepoExplorer[entryType],
        list: [],
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

function* fetchAllMachines(): SagaIterator {
  const response: CallResult<typeof api.machines.getAll> = yield call(api.machines.getAll);

  if (response.type === 'generalError') {
    yield put(fetchConfigRepoExplorerEntriesFail(entryTypes.MACHINES, response.error));
    return;
  }

  if (response.type === 'parseError') {
    yield put(fetchConfigRepoExplorerEntriesFail(entryTypes.MACHINES, response.error.message));
    return;
  }

  const { data } = response;
  if ('status' in data) {
    yield put(fetchConfigRepoExplorerEntriesFail(entryTypes.MACHINES, data.message));
    return;
  }

  yield put(fetchConfigRepoExplorerEntriesSuccess(entryTypes.MACHINES, data));
}

export function* fetchConfigRepoExplorerEntriesSaga({
  payload: {
    entryType,
    datatronId,
    deviceId,
    machineId,
  },
}): SagaIterator {
  let unpackDataPointsFromSubscriptions = false;
  let apiCall = call(api.datatrons.getDatatrons);

  if (entryType === entryTypes.DEVICES) {
    apiCall = call(api.datatrons.getDevices, datatronId);
  } else if (entryType === entryTypes.DATA_POINTS) {
    if (machineId) {
      apiCall = call(api.machines.getDataPoints, machineId);
      unpackDataPointsFromSubscriptions = true;
    } else {
      apiCall = call(api.datatrons.getDataPoints, { datatronId, deviceId, includeSubscriptionInfo: true });
    }
  } else if (entryType === entryTypes.MACHINES) {
    yield call(fetchAllMachines);
    return;
  }

  const { response, error } = yield apiCall;

  if (response) {
    const list = !unpackDataPointsFromSubscriptions ? response : response.map((item) => item.dataPoint);
    yield put(fetchConfigRepoExplorerEntriesSuccess(entryType, list));
  } else {
    yield put(fetchConfigRepoExplorerEntriesFail(entryType, error));
  }
}

export function* watchFetchConfigRepoExplorerEntries() {
  yield takeEvery(fetchConfigRepoExplorerEntries, fetchConfigRepoExplorerEntriesSaga);
}
