import React from 'react';
import PropTypes from 'prop-types';

import ExplorerContainer from '../../../../../_tools/configRepoExplorer/modal/explorer/Explorer.container';
import ModalLine from '../../../../core/ModalLine';
import DoneModalFooter from '../../../../core/footers/DoneModalFooter';

export const AddDataPointsFrame = ({ onCancelClick, onDoneClick }) => (
  <div className="modal-move modal-datapoint-select">
    <ExplorerContainer
      canChooseDevice
      canChooseDataPoint
      canChooseManyDataPoints={false}
      machinesEnabled
      ignoreSubscriptions
    />
    <ModalLine />
    <DoneModalFooter onDoneClick={onDoneClick} onCancelClick={onCancelClick} />
  </div>
);

AddDataPointsFrame.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
};

export default AddDataPointsFrame;
