import { DATATRONS_MANAGER, Role } from '../constants/permissions';

class DatatronsPermissions {
  private isDatatronsManager: boolean;

  constructor(roles: Role[]) {
    this.isDatatronsManager = roles.indexOf(DATATRONS_MANAGER) !== -1;
  }

  canAddDatatronAssistants() {
    return this.isDatatronsManager;
  }

  canDeleteDatatronAssistants() {
    return this.isDatatronsManager;
  }

  canCreateDatatrons() {
    return this.isDatatronsManager;
  }

  canUpdateDatatrons() {
    return this.isDatatronsManager;
  }

  canCreateDatatronsDeviceTypes() {
    return this.isDatatronsManager;
  }

  canCreateDatatronDevices() {
    return this.isDatatronsManager;
  }

  canUpdateDatatronDevices() {
    return this.isDatatronsManager;
  }

  canDeleteDatatronDevices() {
    return this.isDatatronsManager;
  }

  canManageDatatronDevices() {
    return this.isDatatronsManager;
  }

  canCreateDatatronDeviceDataPoints() {
    return this.isDatatronsManager;
  }

  canUpdateDatatronDeviceDataPoints() {
    return this.isDatatronsManager;
  }

  canDeleteDatatronDeviceDataPoints() {
    return this.isDatatronsManager;
  }
}

export default DatatronsPermissions;
