import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ImageContainer from '../../_tools/image/Image.container';

let MACHINE_IMAGE_PLACEHOLDER;

if (process.env.NODE_ENV !== 'test') {
  MACHINE_IMAGE_PLACEHOLDER = require('../../../../img/position.png'); // eslint-disable-line global-require
} else {
  MACHINE_IMAGE_PLACEHOLDER = '/machine.jpg';
}

export default function EventMachineImage({ machineLink, machineImage }) {
  return (
    <Link
      to={machineLink}
      className="position-pic--wrap"
    >
      <ImageContainer
        url={machineImage}
        className="position-pic"
      />
    </Link>
  );
}

EventMachineImage.propTypes = {
  machineLink: PropTypes.string.isRequired,
  machineImage: PropTypes.string.isRequired,
};

EventMachineImage.defaultProps = {
  machineImage: MACHINE_IMAGE_PLACEHOLDER,
};
