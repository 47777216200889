import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { initializeOrganization } from '../../core/common/admin.state';

export const adminFetchOrganization = createAction(
  'admin: fetch organization',
  (id) => id,
);

export const adminFetchOrganizationSuccess = createAction(
  'admin: fetch organization - success',
  (item) => item,
);

export const adminFetchOrganizationFail = createAction(
  'admin: fetch organization - fail',
  (error) => error,
);

export const adminResetOrganization = createAction(
  'admin: reset organization',
);

export const reducer = {
  [adminFetchOrganization]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        selected: {
          ...state.admin.organizations.selected,
          item: null,
          loaded: false,
          loading: true,
          error: null,
        },
      },
    },
  }),

  [adminFetchOrganizationSuccess]: (state, item) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        selected: {
          ...state.admin.organizations.selected,
          item,
          loaded: true,
          loading: false,
          error: null,
        },
      },
    },
  }),

  [adminFetchOrganizationFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        selected: {
          ...state.admin.organizations.selected,
          item: null,
          loaded: false,
          loading: false,
          error,
        },
      },
    },
  }),

  [adminResetOrganization]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        selected: {
          ...state.admin.organizations.selected,
          item: null,
          loaded: false,
          loading: false,
          error: null,
        },
      },
    },
  }),
};

export function* adminFetchOrganizationSaga({ payload: id }) {
  const { response, error } = yield call(api.admin.getOrganization, id);

  if (response) {
    yield put(adminFetchOrganizationSuccess(initializeOrganization(response)));
  } else {
    yield put(adminFetchOrganizationFail(error));
  }
}

export function* watchAdminFetchOrganization() {
  yield takeEvery(adminFetchOrganization.getType(), adminFetchOrganizationSaga);
}
