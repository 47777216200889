import { createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { flatten } from 'lodash';
import {
  INVENTORY_DATATRONS,
  INVENTORY_MACHINES,
} from '../../../../common/constants/inventory';
import { getInventory } from '../../core/api/inventory';
import { CallResult } from '../util';
import { InventoryItem } from 'client/js/schema/inventory';

export const fetchInventory = createAction('fetch inventory');

export const fetchInventorySuccess = createAction(
  'fetch inventory - success',
  (inventoryItems: InventoryItem[]) => ({ payload: inventoryItems }),
);

export const fetchInventoryError = createAction(
  'fetch inventory - error',
  (error) => ({ payload: error }),
);

export const reducer = {
  [fetchInventory.type]: (state) => ({
    ...state,
    inventory: {
      ...state.inventory,
      locations: [],
      machines: [],
      datatrons: [],
      loading: true,
      loaded: false,
    },
  }),
  [fetchInventorySuccess.type]: (state, locations) => ({
    ...state,
    inventory: {
      ...state.inventory,
      loading: false,
      loaded: true,
      locations,
      machines: flatten(
        locations.map((location) => location[INVENTORY_MACHINES]),
      ),
      datatrons: flatten(
        locations.map((location) => location[INVENTORY_DATATRONS]),
      ),
    },
  }),
  [fetchInventoryError.type]: (state, error) => ({
    ...state,
    inventory: {
      ...state.inventory,
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* fetchInventorySaga() {
  const response: CallResult<typeof getInventory> = yield call(getInventory);

  if (response.type === 'generalError') {
    yield put(fetchInventoryError(response.error));
    return;
  }

  if (response.type === 'parseError') {
    yield put(fetchInventoryError(response.error.message));
    return;
  }

  if ('status' in response.data) { // authorized error
    yield put(fetchInventoryError(response.data.message));
    return;
  }

  yield put(fetchInventorySuccess(response.data));
}

export function* watchFetchInventory() {
  yield takeEvery(fetchInventory, fetchInventorySaga);
}
