import React from 'react';
import { FormattedMessage } from 'react-intl';

export const SelectDateRange = () => (
  <div className="export-placeholder">
    <FormattedMessage
      id="export.error.please_select_date_range"
      defaultMessage="Please select date range"
    />
  </div>
);

export default SelectDateRange;
