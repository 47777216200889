import { connect } from 'react-redux';

import UsersModule from './UsersModule';
import * as selector from '../../../../redux/selectors/admin.users.selector';
import { adminFetchUsers } from '../../../../redux/modules/admin.users.fetch.module';

export const mapStateToProps = (state) => {
  const loaded = selector.areUsersLoaded(state);
  const pagination = selector.getPagination(state);
  const nameQuery = selector.getNameFilterValue(state);
  const emailQuery = selector.getEmailFilterValue(state);

  return {
    list: selector.getUsers(state),
    paginationAvailable: loaded && !!pagination && pagination.total_pages > 1,
    pagination,
    nameQuery,
    emailQuery,
  };
};

export const mapDispatchToProps = {
  choosePage: adminFetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersModule);
