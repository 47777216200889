import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ToolsHeader from './ToolsHeader';
import ToolsTableContainer from './toolsTable/ToolsTable.container';
import Unauthorized from '../pages/unauthorized/Unauthorized';
import * as routeHandlers from '../index/routeHandlers';

const Tools = ({ authorized }) => {
  useEffect(() => {
    routeHandlers.onToolPageEnter();
  }, []);

  if (!authorized) {
    return <Unauthorized />;
  }

  return (
    <div className="col--1">
      <ToolsHeader />
      <ToolsTableContainer />
    </div>
  );
};

Tools.propTypes = {
  authorized: PropTypes.bool.isRequired,
};

export default Tools;
