import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { getMachineEditLink } from '../../../index/routes';

const CardButtons = ({
  machineId, canUpdateMachines, canSync, syncMachineToAltasigma,
}) => {
  if (!canUpdateMachines) {
    return null;
  }

  const onSyncClick = () => syncMachineToAltasigma(machineId);

  return (
    <div className="d-flex">
      <Link className="button button__xs white" type="button" to={getMachineEditLink(machineId)}>
        <i className="icon-edit" />
        <FormattedMessage
          id="common.edit"
          defaultMessage="Edit"
        />
      </Link>
      { canSync && (
        <button className="button button__xs white m-left-10" type="button" onClick={onSyncClick}>
          <i className="icon-reload-clockwise" />
          <FormattedMessage
            id="common.sync_to_altasigma"
            defaultMessage="Sync to AltaSigma"
          />
        </button>
      ) }
    </div>
  );
};

CardButtons.propTypes = {
  machineId: PropTypes.string.isRequired,
  canUpdateMachines: PropTypes.bool.isRequired,
  canSync: PropTypes.bool.isRequired,
  syncMachineToAltasigma: PropTypes.func.isRequired,
};

export default CardButtons;
