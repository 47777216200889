import { findIndex, forEach } from 'lodash';
import { selectAllReports } from './reports.selector';
import { createSelector } from '../util';

export const getTabs = createSelector((state) => state.reports.tabs.list);

export const getTabsWithCounts = createSelector((state) => {
  const reports = selectAllReports(state);
  const tabs = getTabs(state);
  const countsByTab = {};

  forEach(
    reports,
    (report) => {
      if (report.reportsTab && report.reportsTab.id) {
        const tabId = report.reportsTab.id;
        if (!countsByTab[tabId]) countsByTab[tabId] = 0;
        countsByTab[tabId]++;
      }
    },
  );

  return tabs.map((tab) => ({
    ...tab,
    count: countsByTab[tab.id],
  }));
});
export const areTabsLoaded = createSelector((state) => state.reports.tabs.loaded);
export const getSelectedTabId = createSelector((state) => state.reports.tabs.selectedTabId);

export const getNearbyTabIds = (state) => {
  const selectedTabId = getSelectedTabId(state);
  const allTabs = getTabs(state);
  const tabsCount = allTabs.length;

  const selectedTabIndex = findIndex(allTabs, (item) => item.id === selectedTabId);
  if (selectedTabIndex === -1) return [null, null];

  let prevTabIndex = selectedTabIndex - 1;
  if (prevTabIndex < 0) prevTabIndex = tabsCount - 1;
  let nextTabIndex = selectedTabIndex + 1;
  if (nextTabIndex > tabsCount - 1) nextTabIndex = 0;

  return [allTabs[prevTabIndex].id, allTabs[nextTabIndex].id];
};
