import React from 'react';
import PropTypes from 'prop-types';
import { Element as ScrollElement } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import cn from 'classnames';

import * as machineForm from '../../../../../../redux/constants/machine.form.constants';
import { FormField, TextareaFormField } from '../../../../../_tools';

import Checkbox from '../../../../../_tools/formFields/checkbox/Checkbox';
import SelectContainer from '../../../../../_tools/formFields/select/MachineSubscriptionDataPointSelect.container';
import formMessages from '../../MachineFormDetails.messages';

export const OeeDataPoints = ({
  canSetDmcConfig,
  altasigmaOnly,
}) => (
  <>
    <label className="pb14">
      <FormattedMessage
        id="machine.form.oee.data_points.label"
        defaultMessage="Select Data Points for OEE"
      />
    </label>

    <div className={cn('inputs--row', { 'inputs--row3': !altasigmaOnly })}>
      <div className="inputs--col">
        <label>
          <FormattedMessage
            id="machine.form.oee.data_points.performance.label"
            defaultMessage="Performance"
          />
          <ScrollElement name={machineForm.OEE_P_DATA_POINT_ID}>
            <Field
              name={machineForm.OEE_P_DATA_POINT_ID}
              component={SelectContainer}
            />
          </ScrollElement>
        </label>
      </div>
      <div className="inputs--col">
        <label>
          <FormattedMessage
            id="machine.form.oee.data_points.availability.label"
            defaultMessage="Availability"
          />
          <ScrollElement name={machineForm.OEE_A_DATA_POINT_ID}>
            <Field
              name={machineForm.OEE_A_DATA_POINT_ID}
              component={SelectContainer}
            />
          </ScrollElement>
        </label>
      </div>
      { !altasigmaOnly && (
        <div className="inputs--col">
          <label>
            <FormattedMessage
              id="machine.form.oee.data_points.quality.label"
              defaultMessage="Quality"
            />
            <ScrollElement name={machineForm.OEE_Q_DATA_POINT_ID}>
              <Field
                name={machineForm.OEE_Q_DATA_POINT_ID}
                component={SelectContainer}
              />
            </ScrollElement>
          </label>
        </div>
      ) }
    </div>

    { !altasigmaOnly && (
      <>
        <div className="inputs--row">
          <div className="checkbox-item-cols" style={{ paddingBottom: '15px', marginTop: '-10px' }}>
            <div className="checkbox-item">
              <ScrollElement name={machineForm.DMC_FLAG}>
                <Field
                  label={formMessages.useDmc}
                  name={machineForm.DMC_FLAG}
                  component={Checkbox}
                  translateLabel
                />
              </ScrollElement>
            </div>
          </div>
        </div>
        { canSetDmcConfig && (
          <ScrollElement name={machineForm.DMC_CONFIG}>
            <TextareaFormField
              label={formMessages.dmcConfig.id}
              name={machineForm.DMC_CONFIG}
              placeholder={formMessages.dmcConfigPlaceholder.id}
              cols="30"
              rows="10"
            />
          </ScrollElement>
        ) }

        <label className="pb14">
          <FormattedMessage
            id="machine.form.oee.threshold.label"
            defaultMessage="Set Thresholds for OEE"
          />
        </label>
        <div className="inputs--row inputs--row3">
          <div className="inputs--col">
            <ScrollElement name={machineForm.OEE_THRESHOLD_GREEN}>
              <FormField
                label={formMessages.oeeThresholdGreen.id}
                placeholder={formMessages.oeeThresholdGreen.id}
                name={machineForm.OEE_THRESHOLD_GREEN}
                type="number"
                min={0}
                max={100}
              />
            </ScrollElement>
          </div>
          <div className="inputs--col">
            <ScrollElement name={machineForm.OEE_THRESHOLD_YELLOW}>
              <FormField
                label={formMessages.oeeThresholdYellow.id}
                placeholder={formMessages.oeeThresholdYellow.id}
                name={machineForm.OEE_THRESHOLD_YELLOW}
                type="number"
                min={0}
                max={100}
              />
            </ScrollElement>
          </div>
          <div className="inputs--col">
            <ScrollElement name={machineForm.OEE_THRESHOLD_RED}>
              <FormField
                label={formMessages.oeeThresholdRed.id}
                placeholder={formMessages.oeeThresholdRed.id}
                name={machineForm.OEE_THRESHOLD_RED}
                type="number"
                min={0}
                max={100}
              />
            </ScrollElement>
          </div>
        </div>
      </>
    ) }
  </>
);

OeeDataPoints.propTypes = {
  canSetDmcConfig: PropTypes.bool.isRequired,
  altasigmaOnly: PropTypes.bool,
};

OeeDataPoints.defaultProps = {
  altasigmaOnly: false,
};

export default OeeDataPoints;
