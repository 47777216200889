import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import PageHeader from './pageHeader/PageHeader';
import PageTabsBarContainer from './pageTabsBar/PageTabsBar.container';
import ExperimentsTabContainer from './experimentsTab/ExperimentsTab.container';
import MeasurementsTabContainer from './measurementsTab/MeasurementsTab.container';
import Unauthorized from '../pages/unauthorized/Unauthorized';
import * as routeHandlers from '../index/routeHandlers';

const ExperimentsPage = ({ authorized, isExperimentsTabOpen, isMeasurementsTabOpen }) => {
  useEffect(() => {
    routeHandlers.onExperimentsPageEnter();
  }, []);

  if (!authorized) {
    return <Unauthorized />;
  }

  return (
    <div className="col--1">
      <PageHeader />

      <div className="users">
        <PageTabsBarContainer />
        <div className="users-wrap">
          <div className="user-table">
            { isExperimentsTabOpen && <ExperimentsTabContainer /> }
            { isMeasurementsTabOpen && <MeasurementsTabContainer /> }
          </div>
        </div>
      </div>
    </div>
  );
};

ExperimentsPage.propTypes = {
  authorized: PropTypes.bool.isRequired,
  isExperimentsTabOpen: PropTypes.bool.isRequired,
  isMeasurementsTabOpen: PropTypes.bool.isRequired,
};

export default ExperimentsPage;
