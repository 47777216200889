import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { newLocationField } from '../../../core/forms/profile.form.js';
import ChangeLanguage from './modules/language/ChangeLanguage';
import ChangePassword from './modules/password/ChangePassword';
import ChangeLocation from './modules/location/ChangeLocation';
import UserLocationModalContainer from './modules/location/UserLocationModal.container';
import { AccessToken } from './modules/token/AccessToken';
import { isProfileLoaded } from '../../../redux/selectors/profile.selector';
import { bindActionCreators } from 'redux';
import { cancel, saveProfile } from '../../../redux/modules/profile.save.module';
import { fetchUser } from '../../../redux/modules/user.module';
import { resetProfilePageForm } from '../../../redux/modules/profile.page.form.module';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  pristine: boolean,
}

export const Profile: React.FC<Props> = ({ pristine }) => {

  const loaded = useSelector(isProfileLoaded);

  const dispatch = useDispatch();

  const dispatchSaveProfile = bindActionCreators(saveProfile, dispatch);
  const dispatchCancel = bindActionCreators(cancel, dispatch);
  const dispatchFetchUser = bindActionCreators(fetchUser, dispatch);
  const dispatchResetProfilePageForm = bindActionCreators(resetProfilePageForm, dispatch);

  const handleMouseDown = (e) => e.preventDefault();

  const handleSaveClick = useCallback((e) => {
    if (e) {
      e.preventDefault();
    }
    dispatchSaveProfile();
    dispatch(fetchUser());
  }, [dispatch, dispatchSaveProfile]);

  useEffect(() => {
    dispatchFetchUser();
    return () => {
      dispatchResetProfilePageForm();
    };
  }, [dispatchFetchUser, dispatchResetProfilePageForm]);

  if (!loaded)
    return null;

  const saveButtonClassNames = classnames('button__save', { disabled: pristine });

  return (
    <div className="col--1">
      <div className="subheader subheader--with--center">
        <div className="subheader-in">
          <div className="subheader--left">
            <ul className="menu tab-menu">
              <li>
                <h1 className="h1">
                  <FormattedMessage
                    id="settings.newProfile"
                    defaultMessage="New Profile"
                  />
                </h1>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <form onSubmit={handleSaveClick} className="form">
        <div className="central-form">
          <ChangePassword/>
          <ChangeLanguage/>
          <ChangeLocation/>
          <AccessToken/>
        </div>

        <div className="line"/>

        <div className="central-form text-center">
          <button
            type="button"
            className="button__cancel"
            onClick={dispatchCancel}
          >
            <FormattedMessage
              id="profile.Cancel"
              defaultMessage="Cancel"
            />
          </button>
          <button
            type="submit"
            className={saveButtonClassNames}
            onClick={handleSaveClick}
            onMouseDown={handleMouseDown}
          >
            <FormattedMessage
              id="profile.Save"
              defaultMessage="Save"
            />
          </button>
        </div>
        <Field
          name={newLocationField}
          component={UserLocationModalContainer}
        />
      </form>
    </div>
  );

};

export default Profile;