import { isUndefined, isBoolean } from 'lodash';

import * as toolStates from '../enums/toolStates';
import * as idProtector from '../../server/services/idProtector';
import { isString, isValidDate, isUuid } from './helpers';

export const TOOL_LABEL_MIN_LENGTH = 3;
export const TOOL_LABEL_MAX_LENGTH = 255;

export const TOOL_TYPE_MIN_LENGTH = 3;
export const TOOL_TYPE_MAX_LENGTH = 255;

export const TOOL_MODEL_MIN_LENGTH = 3;
export const TOOL_MODEL_MAX_LENGTH = 255;

export const TOOL_SERIAL_MAX_LENGTH = 255;

export const TOOL_COMMENT_MAX_LENGTH = 255;

export const ERROR_INVALID_STATE = 'tools.validation.state.invalid';
export const ERROR_MACHINE_REQUIRED = 'errors.field.required';

export const validateLabel = (body) => {
  const errors = {};

  if (body.label) {
    if (!isString(body.label)) {
      errors.label = 'tools.validation.label.invalid';
    } else if (body.label.length < TOOL_LABEL_MIN_LENGTH) {
      errors.label = 'tools.validation.label.min_length';
    } else if (body.label.length > TOOL_LABEL_MAX_LENGTH) {
      errors.label = 'tools.validation.label.max_length';
    }
  }

  return errors;
};

export const validateType = (body) => {
  const errors = {};

  if (!body.type) {
    errors.type = 'errors.field.required';
  } else if (!isString(body.type)) {
    errors.type = 'errors.field.invalid';
  } else if (body.type.length < TOOL_TYPE_MIN_LENGTH) {
    errors.type = 'tools.validation.type.min_length';
  } else if (body.type.length > TOOL_TYPE_MAX_LENGTH) {
    errors.type = 'tools.validation.type.max_length';
  }

  return errors;
};

export const validateModel = (body) => {
  const errors = {};

  if (!body.model) {
    errors.model = 'errors.field.required';
  } else if (!isString(body.model)) {
    errors.model = 'errors.field.invalid';
  } else if (body.model.length < TOOL_MODEL_MIN_LENGTH) {
    errors.model = 'tools.validation.model.min_length';
  } else if (body.model.length > TOOL_MODEL_MAX_LENGTH) {
    errors.model = 'tools.validation.model.max_length';
  }

  return errors;
};

export const validateSerial = (body) => {
  const errors = {};

  if (body.serial) {
    if (!isString(body.serial)) {
      errors.serial = 'errors.field.invalid';
    } if (body.serial.length > TOOL_SERIAL_MAX_LENGTH) {
      errors.serial = 'tools.validation.serial.max_length';
    }
  }

  return errors;
};

export const validateState = (body, newTool = true) => {
  const errors = {};

  if (body.state
    && (
      (newTool && toolStates.statesForNewTool.indexOf(body.state) === -1)
      || (!newTool && toolStates.stateForExistingTool.indexOf(body.state) === -1))
  ) {
    errors.state = ERROR_INVALID_STATE;
  }

  if (!newTool && body.state === toolStates.MOUNTED && !body.machineId) {
    errors.machineId = 'tools.error.machineId.required_for_state_mounted';
  }

  return errors;
};

export const validateStorageId = (body) => {
  const errors = {};

  if (body.storageId && !idProtector.isValid(body.storageId)) {
    errors.storageId = 'errors.field.invalid';
  }

  return errors;
};

export const validateMachineId = (body) => {
  const errors = {};

  if (body.machineId && !idProtector.isValid(body.machineId)) {
    errors.machineId = 'errors.field.invalid';
  }

  return errors;
};

export const validateMountFlag = (body) => {
  const errors = {};

  if (body.mount && !isBoolean(body.mount)) {
    errors.mount = 'tools.validation.mount.invalid';
  }

  return errors;
};

export const validateComment = (body) => {
  const errors = {};

  if (body.comment) {
    if (!isString(body.comment)) {
      errors.comment = 'tools.validation.comment.invalid';
    } else if (body.comment.length > TOOL_COMMENT_MAX_LENGTH) {
      errors.comment = 'tools.validation.comment.max_length';
    }
  }

  return errors;
};

export const validateTool = (body) => ({
  ...validateLabel(body),
  ...validateType(body),
  ...validateModel(body),
  ...validateSerial(body),
  ...validateState(body),
  ...validateStorageId(body),
});

export const validateUpdateTool = (body) => {
  let errors = {};

  if (!isUndefined(body.label)) {
    errors = { ...errors, ...validateLabel(body) };
  }

  if (!isUndefined(body.type)) {
    errors = { ...errors, ...validateType(body) };
  }

  if (!isUndefined(body.model)) {
    errors = { ...errors, ...validateModel(body) };
  }

  if (!isUndefined(body.serial)) {
    errors = { ...errors, ...validateSerial(body) };
  }

  if (!isUndefined(body.state)) {
    errors = { ...errors, ...validateState(body, false) };
  }

  if (!isUndefined(body.storageId)) {
    errors = { ...errors, ...validateStorageId(body) };
  }

  if (!isUndefined(body.comment)) {
    errors = { ...errors, ...validateComment(body) };
  }

  return errors;
};

export const validateLinkToMachine = (body) => ({
  ...validateMountFlag(body),
  ...validateComment(body),
});

export const validateTimestamp = (body) => {
  const errors = {};

  if (body.timestamp && (!isString(body.timestamp) || !isValidDate(body.timestamp))) {
    errors.timestamp = 'tools.validation.timestamp.invalid';
  }

  return errors;
};

export const validateDataPointUuid = (body) => {
  const errors = {};

  if (!body.dataPointUuid) {
    errors.dataPointUuid = 'tools.validation.dataPointUuid.required';
  } else if (!isString(body.dataPointUuid) || !isUuid(body.dataPointUuid)) {
    errors.dataPointUuid = 'tools.validation.dataPointUuid.invalid';
  }

  return errors;
};
