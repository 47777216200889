import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ItemFilters from './ItemFilters';
import {
  addItemFilter,
  removeItemFilter,
  removeItemFiltersByType,
  resetPendingFilters,
  confirmFilters,
} from '../../../redux/modules/dashboard.events.filter.module';
import { changeQuery } from '../../../redux/modules/dashboard.events.filter.query.module';
import { makeGetFilteredSearchResults } from '../../../redux/selectors/search.selector';
import {
  getSelectedFilters,
  getFilterQuery,
  getFilterList,
  selectCanApplyItemFilters,
} from '../../../redux/selectors/dashboard.selector';

export const mapStateToProps = (state, { itemType }) => {
  const getFilteredSearchResults = makeGetFilteredSearchResults(getSelectedFilters);

  return {
    query: getFilterQuery(state, itemType),
    results: getFilteredSearchResults(state, itemType),
    selectedFilters: getSelectedFilters(state, itemType),
    filterType: itemType,
    filters: getFilterList(state, itemType),
    canApplyItemFilters: selectCanApplyItemFilters(state),
  };
};

export const mapDispatchToProps = (dispatch, { itemType }) => ({
  onQueryChange: (query) => dispatch(changeQuery(itemType, query)),
  addFilter: (filter) => dispatch(addItemFilter(filter)),
  removeFilter: (filter) => dispatch(removeItemFilter(filter)),
  removeAllFiltersByType: (type) => dispatch(removeItemFiltersByType(type)),
  pendingFiltersReset: bindActionCreators(resetPendingFilters, dispatch),
  filtersConfirm: bindActionCreators(confirmFilters, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemFilters);
