import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dashboard_filter_locationCount: {
    id: 'dashboard.filter.locationsCount',
    defaultMessage: 'Locations',
  },
  dashboard_filter_machines: {
    id: 'dashboard.filter.machines',
    defaultMessage: 'Machines',
  },
  dashboard_filter_organizations: {
    id: 'dashboard.filter.organizations',
    defaultMessage: 'Organizations',
  },
  dashboard_filter_tags: {
    id: 'dashboard.filter.tags',
    defaultMessage: 'Tags',
  },
});

export default messages;
