import { defineMessages } from 'react-intl';

const messages = defineMessages({
  profileTokenFormat: {
    id: 'profile.token.format',
    defaultMessage: 'Token Format',
  },
  profileTokenFormatPlain: {
    id: 'profile.token.format.plain',
    defaultMessage: 'Plain',
  },
  profileTokenFormatCurl: {
    id: 'profile.token.format.curl',
    defaultMessage: 'cURL',
  },
  profileTokenFormatCurlWindows: {
    id: 'profile.token.format.curl_windows',
    defaultMessage: 'cURL (Windows)',
  },
  profileTokenFormatPowerShell: {
    id: 'profile.token.format.powershell',
    defaultMessage: 'PowerShell',
  },
  profileTokenFormatHeader: {
    id: 'profile.token.format.header',
    defaultMessage: 'Header',
  },
  profileTokenClickToCopy: {
    id: 'profile.token.click_to_copy',
    defaultMessage: 'Click to copy',
  },
  profileTokenCopied: {
    id: 'profile.token.copied',
    defaultMessage: 'Copied!',
  },
});
export default messages;
