import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { measurement } from '../../../../../core/propTypes';
import Status from '../../../../experimentsPage/measurementsTab/measurement/status/Status';
import commonMessages from '../../../../../messages/common.messages';
import LoaderLocal from '../../../../_tools/loader/LoaderLocal';
import EmptyPlaceholderContainer
  from '../../../../experimentsPage/measurementsTab/emptyPlaceholder/EmptyPlaceholder.container';

const dateFormat = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export class Measurements extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    measurements: PropTypes.arrayOf(measurement).isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,

  };

  componentDidMount() {
    const { fetch } = this.props;
    fetch();
  }

  render() {
    const {
      measurements, loading, loaded, intl,
    } = this.props;

    return (
      <div className="experiments-table">
        <table className="table-lined valign-middle">
          <thead>
            <tr>
              <FormattedMessage
                id="tools.filters.state.label"
                defaultMessage="Status"
                tagName="td"
              />
              <FormattedMessage {...commonMessages.start} tagName="td" />
              <FormattedMessage {...commonMessages.end} tagName="td" />
            </tr>
          </thead>
          { (measurements.length > 0) && measurements.map((item) => {
            const start = item.start ? intl.formatDate(item.start, dateFormat) : '';
            const end = item.end ? intl.formatDate(item.end, dateFormat) : '';

            return (
              <tbody key={item.id}>
                <tr>
                  <Status status={item.status} />
                  <td>
                    { start }
                  </td>
                  <td>
                    { end }
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
        { loading && <LoaderLocal /> }
        { loaded && !measurements.length && <EmptyPlaceholderContainer /> }
      </div>
    );
  }
}

export default injectIntl(Measurements);
