import { DragSource } from 'react-dnd';

import InboxCard from './InboxCard';
import { DND_COLUMN_ITEM_TYPE } from '../../../../redux/constants/column.constants';

const columnEventSource = {
  beginDrag(props) {
    props.beginDrag();

    return {
      cardId: props.cardId,
      type: props.type,
    };
  },
  endDrag(props, monitor) {
    const dropResult = monitor.getDropResult();

    if (dropResult) {
      const { cardId, toColumn, betweenCards } = dropResult;
      props.createActivity(cardId, toColumn, betweenCards);
    }

    props.endDrag();
  },
};

function collect(connect) {
  return {
    connectDragSource: connect.dragSource(),
  };
}

export default DragSource(DND_COLUMN_ITEM_TYPE, columnEventSource, collect)(InboxCard);
