import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import messages from '../../../../messages/report.page.messages';
import RemoveModalFooter from '../../core/footers/RemoveModalFooter';

const handleSubmit = (e) => {
  e.preventDefault();
};

const ReportRemoveModal = ({
  close,
  remove,
  report,
  submitting,
}) => {
  const onRemove = () => remove(report.id);

  return (
    <BasicModal
      close={close}
      modalClass="modal--sm"
    >
      <div className="modal--content">
        <FormattedMessage {...messages.removeModalContent} tagName="h3" />
        <form className="form" onSubmit={handleSubmit}>
          <div className="line" />
          <RemoveModalFooter
            onCancelClick={close}
            onRemoveClick={onRemove}
            isRemoveButtonDisabled={submitting}
            isRemoveButtonLoading={submitting}
          />
        </form>
      </div>
    </BasicModal>
  );
};

ReportRemoveModal.propTypes = {
  close: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default ReportRemoveModal;
