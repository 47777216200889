import React, { useState } from 'react';
import cn from 'classnames';
import { Todo, TodoFunction } from '../../../../../../../../common/types/common';
import { useClickOutside } from '../../../../../../core/common/hooks/useClickOutside';

interface Props {
  title: string
  options: Todo[]
  onSelect: TodoFunction
  titleIcons?: Todo[]
}

export const ButtonWithDropdown: React.FC<Props> = ({
  title,
  options,
  onSelect,
  titleIcons = [],
}) => {

  const [isOpen, isOpenSet] = useState(false);

  const open = () => isOpenSet(true);

  const close = () => isOpenSet(false);

  const select = (value) => {
    onSelect(value);
    close();
  };

  const clickRef = useClickOutside(close);

  return (
    <div className="flex align-center" ref={clickRef}>
      <div className={cn('report-interval', 'report-interval-reload', { opened: isOpen })}>
        <button className="button light-grey-white button-sm" onClick={open}>
          {titleIcons.map((icon) => (<span key={icon} className={icon}/>))}
          <span className="texted">{title}</span>
        </button>
        <div className="filter--dropdown">
          <ul>
            {options.map((item) => {
              const handleClick = () => select(item.value);
              return (
                <li key={item.value} onClick={handleClick}>
                  <a className="filter-action">{item.label}</a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ButtonWithDropdown;
