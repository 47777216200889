import * as entryTypes from '../constants/configRepoExplorer.entry.types.constants';
import commonCoreMessages from '../../messages/common.core.messages';

export const configRepoExplorerInitialState = {
  [entryTypes.EQUIPMENT]: {
    list: [
      {
        id: entryTypes.DATATRONS,
        type: entryTypes.DATATRONS,
        name: commonCoreMessages.datatrons,
        translateName: true,
      },
      {
        id: entryTypes.MACHINES,
        type: entryTypes.MACHINES,
        name: commonCoreMessages.machines,
        translateName: true,
      },
    ],
    selected: null,
  },
  [entryTypes.DATATRONS]: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    selected: null,
    disabled: [],
  },
  [entryTypes.DEVICES]: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    selected: null,
    disabled: [],
  },
  [entryTypes.DATA_POINTS]: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    selected: null,
    disabled: [],
    filters: {
      sort: { option: null, way: null },
      q: '',
    },
  },
  [entryTypes.MACHINES]: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    selected: null,
    disabled: [],
  },
  view: entryTypes.DATATRONS,
};

export const configRepoExplorerTestState = {
  [entryTypes.EQUIPMENT]: {
    list: [
      {
        id: entryTypes.DATATRONS,
        type: entryTypes.DATATRONS,
        name: commonCoreMessages.datatrons,
        translateName: true,
      },
      {
        id: entryTypes.MACHINES,
        type: entryTypes.MACHINES,
        name: commonCoreMessages.machines,
        translateName: true,
      },
    ],
    selected: entryTypes.MACHINES,
  },

  [entryTypes.DATATRONS]: {
    list: [
      {
        id: 111,
        name: 'Datatron One',
      },
    ],
    loaded: true,
    loading: false,
    error: null,
    selected: 111,
    disabled: [],
  },

  [entryTypes.DEVICES]: {
    list: [
      {
        id: 222,
        name: 'Device First',
      },
    ],
    loaded: true,
    loading: false,
    error: null,
    selected: 222,
    disabled: [],
  },

  [entryTypes.DATA_POINTS]: {
    list: [
      {
        id: 333,
        name: 'Data Point 4',
      },
      {
        id: 334,
        name: 'Data Point 5',
      },
    ],
    loaded: false,
    loading: false,
    error: null,
    selected: [333, 334],
    disabled: [444],
    filters: {
      sort: { option: null, way: null },
      q: '',
    },
  },

  [entryTypes.MACHINES]: {
    list: [
      {
        id: 111,
        name: 'Machine One',
      },
    ],
    loaded: true,
    loading: false,
    error: null,
    selected: 111,
    disabled: [],
  },

  view: entryTypes.DATATRONS,
};
