import { connect } from 'react-redux';

import ArchiveExperimentModal from './ArchiveExperimentModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { EXPERIMENT_ARCHIVE } from '../../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { archiveExperiment } from '../../../../redux/modules/experiments.archive.module';

export const mapStateToProps = (state) => {
  const { experiment } = getModalPayload(state, EXPERIMENT_ARCHIVE);
  return { experimentId: experiment.id };
};

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(EXPERIMENT_ARCHIVE)),
  _archive: (id) => dispatch(archiveExperiment(id)),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  archive: () => dispatchProps._archive(stateProps.experimentId),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ArchiveExperimentModal);
