import { connect } from 'react-redux';

import Calendar from './Calendar';

import { selectMode } from '../../redux/modules/calendar.mode.module';
import * as selector from '../../redux/selectors/calendar.selector';
import * as calendarMode from '../../redux/constants/calendar.constants.js';

export function mapStateToProps(state) {
  return {
    mode: selector.calendarMode(state),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    selectWeeklyMode() {
      return dispatch(selectMode(calendarMode.WEEKLY_CALENDAR));
    },
    selectMonthlyMode() {
      return dispatch(selectMode(calendarMode.MONTHLY_CALENDAR));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
