import { connect } from 'react-redux';

import TimezoneButton from './TimezoneButton';
import { switchTimezoneMode } from '../../../../../../../redux/modules/dataPoint.metric.set.timezone.module';

export const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(switchTimezoneMode()),
});

export default connect(null, mapDispatchToProps)(TimezoneButton);
