import React, { BaseSyntheticEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import adminPageMessages from '../../../../messages/adminPage.messages';
import BasicModal from '../../core/BasicModal';
import Footer from './footer/Footer';
import Form from './form/Form.container';

interface Props {
  close: () => void;
  pristine: boolean;
  submitting: boolean;
  addDevice?: () => void;
  updateDevice?: () => void;
  deviceClass: string;
  isAddForm: boolean;
  isEditForm: boolean;
}

type FormType = 'add' | 'edit' | 'unknown';

const useTitle = (formType: FormType, deviceClass: string) => {
  const { formatMessage } = useIntl();
  switch (formType) {
    case 'add':
      return formatMessage(adminPageMessages.addObject, {
        object: deviceClass,
      });
    case 'edit':
      return formatMessage(adminPageMessages.editObject, {
        object: deviceClass,
      });
    default:
      console.error('Invalid form type: ', formType);
      return '';
  }
};

export const DeviceFormModal: React.FC<Props> = ({
  close,
  pristine,
  submitting,
  addDevice = () => {},
  updateDevice = () => {},
  isAddForm = false,
  isEditForm = false,
  deviceClass,
}) => {
  const formType = isAddForm ? 'add' : isEditForm ? 'edit' : 'unknown';

  const title = useTitle(formType, deviceClass);
  const handleSubmit = useCallback(
    (e: BaseSyntheticEvent) => {
      e.preventDefault();
      switch (formType) {
        case 'add':
          addDevice();
          return;
        case 'edit':
          updateDevice();
          return;
        default:
          console.error('Invalid form type: ', formType);
      }
    },
    [addDevice, formType, updateDevice],
  );

  return (
    <BasicModal isOpen close={close}>
      <div className='modal--content'>
        <h3>{title}</h3>

        <form className='form' onSubmit={handleSubmit} autoComplete='off'>
          <Form
            canSelectDeviceType={formType === 'add'}
            canSetDeviceUuid={formType === 'add'}
            deviceClass={deviceClass}
          />

          <div className='line' />

          <Footer
            handleSubmit={handleSubmit}
            handleCancel={close}
            isAddForm={formType === 'add'}
            isEditForm={formType === 'edit'}
            loading={submitting}
            disabled={submitting || pristine}
          />
        </form>
      </div>
    </BasicModal>
  );
};
