import React from 'react';
import PropTypes from 'prop-types';

import { getAdminEditAccountLink } from '../../../index/routes';

export const AccountRow = ({ account, pushPath }) => {
  const handleEditClick = () => pushPath(getAdminEditAccountLink(account.id));

  return (
    <tr>
      <td>
        <strong>{ account.name }</strong>
      </td>
      <td>
        <strong>{ account.description }</strong>
      </td>
      <td className="td-actions">
        <button className="button light-grey-white" onClick={handleEditClick}>
          <i className="icon-edit" />
        </button>
      </td>
    </tr>
  );
};

AccountRow.propTypes = {
  account: PropTypes.object.isRequired,
  pushPath: PropTypes.func.isRequired,
};

export default AccountRow;
