import { connect } from 'react-redux';

import TableQuerySearchState from '../../../../../common/tableQuerySearch/TableQuerySearchState';
import { getEmailFilterValue } from '../../../../../../redux/selectors/admin.users.selector';
import { AP_USERS_EMAIL_FILTER_INPUT } from '../../../../../../redux/constants/components.constants';
import { adminFilterUsersByEmail } from '../../../../../../redux/modules/admin.users.sort.filter.module';
import msg from '../../../../../../messages/placeholders.messages';

export const mapStateToProps = (state) => ({
  value: getEmailFilterValue(state),
  stateComponentId: AP_USERS_EMAIL_FILTER_INPUT,
  placeholder: msg.searchByEmail,
});

export const mapDispatchToProps = {
  onQueryChange: adminFilterUsersByEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableQuerySearchState);
