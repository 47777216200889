import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorModalContent } from '../../../error/ErrorModalContent';
import { ActionFooterButtons } from './ActionFooterButtons';
import Modal from '../../../_tools/modal/Modal';
import { DP_DOWNLOAD_FILE } from '../../../../redux/constants/modals.constants';
import { downloadDatapoints } from '../../../../core/api/datatrons';
import { closeModal } from '../../../../redux/modules/modals.module';
import { getModalPayload, isModalOpen } from '../../../../redux/selectors/modals.selector';
import { selector } from '../../../../redux/selectors/util';
import { useDownloadFile } from '../../../../core/common/hooks/useDownloadFile';
import { Loader } from '../../../_tools';
import { DATA_POINTS_XLSX } from '../../../../../../common/constants/datapoints.excel';

const modalOpenSelector = selector((state) => isModalOpen(state, DP_DOWNLOAD_FILE));
const modalPayloadSelector = selector((state) => getModalPayload(state, DP_DOWNLOAD_FILE));

interface Props {
  datatronId: string;
}

export type CommandState = 'default' | 'loading' | 'success' | 'error';

export const DownloadFileModal: React.FC<Props> = ({ datatronId }) => {
  const isOpen = useSelector(modalOpenSelector);
  const { deviceId } = useSelector(modalPayloadSelector);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [commandState, commandStateSet] = useState<CommandState>('default');

  const preDownloading = () => commandStateSet('loading');
  const downloadExcel = () => downloadDatapoints({ datatronId, deviceId });

  const dispatch = useDispatch();
  const onClose = () => dispatch(closeModal(DP_DOWNLOAD_FILE));

  const onErrorDownloadFile = (errorMsg: string) => {
    setErrorMessage(errorMsg);
    commandStateSet('error');
  };

  const postDownloading = () => {
    commandStateSet('success');
    setErrorMessage('');
  };

  const { download } = useDownloadFile({
    apiDefinition: downloadExcel,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName: () => DATA_POINTS_XLSX,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} contentLabel=''>
      <div className='modal--header' onClick={onClose}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        {commandState === 'default' && (
          <FormattedMessage
            tagName='h3'
            id='datatron.modals.download_data_points_text'
            defaultMessage='Would you like the Excel file with datapoints?'
          />
        )}
        {commandState === 'success' && (
          <>
            <FormattedMessage tagName='h3' id='datatron.modals.success' />
            <div className='success-message'>
              <FormattedMessage id='datatron.modals.download_excel_success_message' tagName='h3' />
            </div>
          </>
        )}
        {commandState === 'loading' && (
          <Loader show={commandState === 'loading'} fullPage={false} />
        )}
        {commandState === 'error' && <ErrorModalContent errorMessage={errorMessage} />}
        <div className='line' />
        <ActionFooterButtons commandState={commandState} download={download} onClose={onClose} />
      </div>
    </Modal>
  );
};
