import { connect } from 'react-redux';

import Calendar from './Calendar';
import { convertEventsByWeekday, getDefaultCalendarEventsDates } from '../../../core/common/calendar';

export const mapStateToProps = (state, { calendar }) => {
  const fromDate = calendar.events.fromDate || getDefaultCalendarEventsDates().fromDate;

  return {
    groupedEvents: convertEventsByWeekday(calendar.events.list, fromDate),
    fromDate,
  };
};

export default connect(mapStateToProps)(Calendar);
