import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { getClustersSummaryByMachineId } from '../../core/api/cluster';
import { getClusteredEventsFilters } from '../selectors/machine.selector';

export const fetchClustersSummaryByMachine = createAction(
  'fetch clusters summary by machine',
  (machineId) => machineId,
);

export const fetchClustersSummaryByMachineSuccess = createAction(
  'fetch clusters summary by machine successfully',
  (clustersGroups) => clustersGroups,
);

export const fetchClustersSummaryByMachineFail = createAction(
  'fetch clusters summary by machine fail',
  (error) => error,
);

export const reducer = {
  [fetchClustersSummaryByMachine]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      clustersSummary: {
        ...state.machine.clustersSummary,
        expandedRowIndex: null,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchClustersSummaryByMachineSuccess]: (state, clustersGroups) => ({
    ...state,
    machine: {
      ...state.machine,
      clustersSummary: {
        ...state.machine.clustersSummary,
        loaded: true,
        loading: false,
        data: clustersGroups.map((item) => ({
          ...item,
          clusters: {
            loaded: false,
            loading: false,
            data: [],
            error: null,
          },
        })),
        error: null,
      },
    },
  }),

  [fetchClustersSummaryByMachineFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      clustersSummary: {
        ...state.machine.clustersSummary,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchClustersSummaryByMachineSaga({ payload: machineId }) {
  const state = yield select();
  const filters = yield call(getClusteredEventsFilters, state);

  const { response, error } = yield call(getClustersSummaryByMachineId, {
    machineId,
    ...filters,
  });

  if (response) {
    yield put(fetchClustersSummaryByMachineSuccess(response));
  } else {
    yield put(fetchClustersSummaryByMachineFail(error));
  }
}

export function* watchFetchClustersSummaryByMachine() {
  yield takeEvery(fetchClustersSummaryByMachine.getType(), fetchClustersSummaryByMachineSaga);
}
