import React from 'react';
import PropTypes from 'prop-types';

import noDoubleClick from '../../../../_tools/stopPropagation';

function Commenting({ commentsCount, eventId, expandCollapse }) {
  function handleClick(event) {
    noDoubleClick(event);
    expandCollapse(eventId);
  }

  return (
    <li>
      <a onClick={handleClick}>
        <span className="icon icon-comment" />
        {' '}
        {commentsCount}
      </a>
    </li>
  );
}

Commenting.propTypes = {
  commentsCount: PropTypes.number.isRequired,
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  expandCollapse: PropTypes.func.isRequired,
};

export default Commenting;
