import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Processing from './Processing';

function Processings({ processings }) {
  let i = 0;
  return (
    <table className="table table-link table-scroll">
      <thead>
        <tr>
          <td>
            <FormattedMessage
              id="products.batch.processings.single.product"
              defaultMessage="Product"
            />
          </td>
          <td>
            <FormattedMessage
              id="products.batch.processings.single.comment"
              defaultMessage="Comment"
            />
          </td>
          <td>
            <FormattedMessage
              id="products.batch.processings.single.status"
              defaultMessage="Status"
            />
          </td>
          <td>
            <FormattedMessage
              id="products.batch.processings.single.quality"
              defaultMessage="Quality"
            />
          </td>
          <td>
            <FormattedMessage
              id="products.batch.processings.single.startedAt"
              defaultMessage="Started at"
            />
          </td>
          <td>
            <FormattedMessage
              id="products.batch.processings.single.duration"
              defaultMessage="Duration"
            />
          </td>
        </tr>
      </thead>
      <tbody>
        { processings.map((processing) => (
          <Processing
            key={i++}
            processing={processing}
          />
        )) }
      </tbody>
    </table>
  );
}

Processings.propTypes = {
  processings: PropTypes.array.isRequired,
};

export default Processings;
