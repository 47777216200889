import { connect } from 'react-redux';

import AddDataPointsFrame from './AddDataPointsFrame';
import { _PAGE_REPORT_FORM as modalId } from '../../../../../../redux/constants/modals.constants';
import { goBackModalPath } from '../../../../../../redux/modules/modals.module';
import { saveSelectedDataPoint, cancelDataPointsSelection } from '../../../../../../redux/modules/report.form.dataPoints.module';

export const mapDispatchToProps = {
  goBackModalPath,
  saveSelectedDataPoint,
  cancelDataPointsSelection,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onCancelClick: (e) => {
    if (e) { e.preventDefault(); }
    dispatchProps.goBackModalPath(modalId);
    dispatchProps.cancelDataPointsSelection();
  },
  onDoneClick: (e) => {
    if (e) { e.preventDefault(); }
    dispatchProps.saveSelectedDataPoint();
    dispatchProps.goBackModalPath(modalId);
  },
});

export default connect(null, mapDispatchToProps, mergeProps)(AddDataPointsFrame);
