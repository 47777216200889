export const now = 'now';
export const seconds = 'seconds';
export const minutes = 'minutes';
export const hours = 'hours';
export const days = 'days';
export const weeks = 'weeks';
export const months = 'months';

export const RELATIVE_UNITS = [
  seconds,
  minutes,
  hours,
  days,
  weeks,
  months,
] as const;

export type RelativeUnit = typeof RELATIVE_UNITS[number];

export default RELATIVE_UNITS;
