import React from 'react';
import PropTypes from 'prop-types';
import numberAbbreviate from 'number-abbreviate';

const MAX_UN_ABBREVIATED_VALUE = 100000;

function Percentage({
  data, percentageClass, colorClass, isAmount, displayValue,
}) {
  let valueToShow;

  if (isAmount === true) {
    valueToShow = data;
    if (!(displayValue === null || displayValue === undefined)) {
      valueToShow = displayValue;
    }

    if (valueToShow >= MAX_UN_ABBREVIATED_VALUE) {
      valueToShow = numberAbbreviate(valueToShow);
    }
  } else {
    valueToShow = `${Math.round(data * 100)}%`;
  }

  return (
    <div className={`${colorClass} ${percentageClass}`}>
      { valueToShow }
    </div>
  );
}

Percentage.propTypes = {
  data: PropTypes.number.isRequired,
  displayValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percentageClass: PropTypes.string.isRequired,
  colorClass: PropTypes.string.isRequired,
  isAmount: PropTypes.bool,
};

Percentage.defaultProps = {
  data: 0,
};

export default Percentage;
