import React from 'react';

import { useDispatch } from 'react-redux';
import Kpi from '../../_tools/kpi/Kpi';
import { calculateGaugeValues } from '../../../core/common/report';
import { toggleIndicator } from '../../../redux/modules/report.indicators.module';
import { ReportT } from '../../../../types/report';

interface Props {
  report: ReportT;
}

export const Indicators: React.FC<Props> = ({ report }) => {
  const { main, child } = calculateGaugeValues(
    report.data.total,
    report.disabledProps,
    report.type,
  );

  const reportId = report.id;
  const disabledProps = report.disabledProps;

  const dispatch = useDispatch();
  const toggle = (type) => dispatch(toggleIndicator(reportId, type));

  return (
    <div className="round-charts round-charts-inline round-charts--left-alignment">
      <Kpi
        data={main}
        isActive
        background="kpi-background--light"
        percentageClass="kpi__percentage"
      />
      {
        child.map((indicator, index) => {
          const isDisabled = disabledProps.indexOf(indicator.type) >= 0;
          return (
            <Kpi
              data={indicator}
              key={index}
              background="kpi-background--light"
              percentageClass="kpi__percentage"
              toggle={toggle}
              isDisabled={isDisabled}
            />
          );
        })
      }
    </div>
  );
};

export default Indicators;
