import { connect } from 'react-redux';

import CheckboxFiltersContainer from './CheckboxFilters.container';

export const mapStateToProps = (state, { input }) => ({
  setSelectedList: (list) => input.onChange(list || []),
  selectedList: input.value || [],
});

export default connect(mapStateToProps)(CheckboxFiltersContainer);
