import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import RadialChart from './radialChart/RadialChart';
import Percentage from './Percentage';
import KpiMotion from './Kpi.motion';
import * as datatronStats from '../../../../../common/enums/datatronStats';
import { useBoundingClientRect } from '../useBoundingClientRect';

const ZERO = 'ZERO';

const indicatorToColor = {
  'indicators.oee': 'kpi-color--oee',
  'indicators.availability': 'kpi-color--availability',
  'indicators.performance': 'kpi-color--performance',
  'indicators.quality': 'kpi-color--quality',

  'events.category.info': 'kpi-color--info',
  'events.category.fatal': 'kpi-color--fatal',
  'events.category.error': 'kpi-color--error',
  'events.category.anomaly': 'kpi-color--anomaly',
  'events.category.warning': 'kpi-color--warning',

  'maintenance.planned': 'kpi-color--planned',
  'maintenance.unplanned': 'kpi-color--unplanned',

  'indicators.no_data': 'kpi-color--fatal',
  'indicators.all': 'kpi-color--oee',

  [`datatron.${datatronStats.datatronCpu}`]: 'datatron-color--cpu',
  [`datatron.${datatronStats.datatronDisk}`]: 'datatron-color--disk',
  [`datatron.${datatronStats.datatronNetwork}`]: 'datatron-color--network',
  [`datatron.${datatronStats.datatronRam}`]: 'datatron-color--ram',

  [ZERO]: 'kpi-color--grey',
};

const percentageToColor = {
  'indicators.oee': 'kpi-color__percentage--oee',
  'indicators.availability': 'kpi-color__percentage--availability',
  'indicators.performance': 'kpi-color__percentage--performance',
  'indicators.quality': 'kpi-color__percentage--quality',

  'events.category.info': 'kpi-color__percentage--info',
  'events.category.fatal': 'kpi-color__percentage--fatal',
  'events.category.error': 'kpi-color__percentage--error',
  'events.category.anomaly': 'kpi-color__percentage--anomaly',
  'events.category.warning': 'kpi-color__percentage--warning',

  'maintenance.planned': 'kpi-color__percentage--planned',
  'maintenance.unplanned': 'kpi-color__percentage--unplanned',

  'indicators.no_data': 'kpi-color__percentage--fatal',
  'indicators.all': 'kpi-color__percentage--oee',

  [`datatron.${datatronStats.datatronCpu}`]: 'datatron-color__percentage--cpu',
  [`datatron.${datatronStats.datatronDisk}`]: 'datatron-color__percentage--disk',
  [`datatron.${datatronStats.datatronNetwork}`]: 'datatron-color__percentage--network',
  [`datatron.${datatronStats.datatronRam}`]: 'datatron-color__percentage--ram',

  [ZERO]: 'kpi-color__percentage--grey',
};

const isArray = (item) => item.length !== undefined;

function Kpi({
  data, isActive, isDisabled, isLoading, background, percentageClass, toggle, viewOnly,
}) {
  if (isArray(data.value)) data.value = data.value[0];

  function onClick() {
    toggle(data.type);
  }

  let isZero = false;
  if (data.value === 0) {
    isZero = true;
  }

  const className = classnames({
    'round-chart--item': true,
    active: isActive,
    passive: isDisabled,
    'cursor-default': viewOnly,
  });

  const percentageClassName = classnames({
    [percentageClass]: true,
    active: isActive,
    'cursor-default': viewOnly,
  });

  const [ref, rect] = useBoundingClientRect<HTMLDivElement>();

  return (
    <div className={className} onClick={onClick}>
      <div className="position-relative">
        <div ref={ref} className="round-chart">
          <RadialChart
            data={data.value}
            fill={indicatorToColor[(!isZero) ? data.label : ZERO]}
            background={background}
            width={rect.width}
            height={rect.height}
          />
          <div className="percentage__container">
            <KpiMotion from={0} to={data.value}>
              <Percentage
                isAmount={data.isAmount}
                percentageClass={percentageClassName}
                displayValue={data.displayValue || data.value}
                colorClass={percentageToColor[(!isZero) ? data.label : ZERO]}
              />
            </KpiMotion>
          </div>
          { isLoading ? <div className="loader-wrap"><div className="loader" /></div> : ''}
        </div>
      </div>
      <FormattedMessage
        id={data.label}
        tagName="p"
      />
    </div>
  );
}

Kpi.propTypes = {
  data: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  background: PropTypes.string.isRequired,
  percentageClass: PropTypes.string.isRequired,
  toggle: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  viewOnly: PropTypes.bool,
};

Kpi.defaultProps = {
  isActive: false,
  isDisabled: false,
  isLoading: false,
  viewOnly: false,
  toggle() {},
};

export default Kpi;
