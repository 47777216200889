import { connect } from 'react-redux';
import { isUndefined, last } from 'lodash';

import LocationPickerModal from '../../../_tools/locationPicker/LocationPickerModal.container';
import { DATATRON_FORM_PAGE } from '../../../../redux/constants/pathButton.constants';
import { getPreSavedPath } from '../../../../redux/selectors/pathButton.selector';

export const mapStateToProps = (state, { location }) => {
  const preSavedPath = getPreSavedPath(state, DATATRON_FORM_PAGE);

  const preSavedLocationId = (preSavedPath && preSavedPath.length) ? last(preSavedPath).id : null;
  const initLocationId = (location && location.id) ? location.id : null;

  return {
    isFormField: true,
    initLocationId: isUndefined(preSavedPath) ? initLocationId : preSavedLocationId,
    pathButtonId: DATATRON_FORM_PAGE,
  };
};

export default connect(mapStateToProps)(LocationPickerModal);
