import * as addForm from '../../redux/constants/tools.add.form.constants';
import * as editForm from '../../redux/constants/tools.edit.form.constants';
import * as validation from '../../../../common/validation/tools.validation';
import * as activityTypes from '../../redux/constants/tools.activityTypes.constants';

export const validateNewTool = (body) => {
  let errors = {};

  if (body[addForm.MACHINE_FIELD]) {
    errors = { ...errors, ...validation.validateMachineId(body) };
  }

  if (body[addForm.COMMENT_FIELD]) {
    errors = { ...errors, ...validation.validateComment(body) };
  }

  return errors;
};

export const validateEditTool = (body) => {
  let errors = {};

  if (body[editForm.NA_COMMENT]) {
    errors = { ...errors, ...validation.validateComment(body) };
  }

  if ([
    activityTypes.ASSIGN,
    activityTypes.MOUNT,
  ].indexOf(body[editForm.NA_TYPE]) >= 0 && !body[editForm.NA_MACHINE]) {
    errors[editForm.NA_MACHINE] = validation.ERROR_MACHINE_REQUIRED;
  }

  return errors;
};
