import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getMachineLink } from '../../../index/routes';

export const SubscriptionWarning = ({ subscription, openedMachine }) => {
  if (!subscription) return null;

  if (openedMachine && openedMachine.id && subscription.machine.id === openedMachine.id) {
    return (
      <div className="small-alert-text">
        <FormattedMessage
          tagName="div"
          className="small-alert-text"
          id="configRepo.explorer.dataPoint.warning.this_data_point_already_subscribed_to_this_machine"
          defaultMessage="This Data Point already subscribed to this Machine"
        />
      </div>
    );
  }

  return (
    <div className="small-alert-text">
      <FormattedMessage
        tagName="div"
        className="small-alert-text"
        id="configRepo.explorer.dataPoint.warning.this_data_point_already_subscribed_to"
        defaultMessage="This Data Point already subscribed to {machine}"
        values={{
          machine: (
            <Link to={getMachineLink(subscription.machine.id)}>
              { subscription.machine.name }
            </Link>
          ),
        }}
      />
    </div>
  );
};

SubscriptionWarning.propTypes = {
  subscription: PropTypes.object,
  openedMachine: PropTypes.object,
};

SubscriptionWarning.defaultProps = {
  subscription: null,
  openedMachine: null,
};

export default SubscriptionWarning;
