import { connect } from 'react-redux';

import Footer from './Footer';
import commonMessages from '../../../../messages/common.messages';

export const mapStateToProps = (state, { onOkBtnClick }) => ({
  onRightBtnClick: onOkBtnClick,
  rightBtnLabel: commonMessages.ok,
  isLeftBtnHidden: true,
});

export default connect(mapStateToProps)(Footer);
