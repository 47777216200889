import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';

export const IndicatorsTitle = ({ shiftStartMoment, isShiftExist }) => {
  const intl = useIntl();

  if (!isShiftExist) {
    return (
      <div className="h1">
        <FormattedMessage
          id="machines.oee.most_recent_shift"
          defaultMessage="OEE for the most recent shift"
        />
      </div>
    );
  }

  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const showRelativeDate = today.isSame(shiftStartMoment, 'day')
    || yesterday.isSame(shiftStartMoment, 'day');
  const shiftStartDate = shiftStartMoment.toDate();
  const shiftDateFormatted = showRelativeDate
    ? intl.formatRelativeTime(shiftStartDate, 'day') : intl.formatDate(shiftStartDate);

  return (
    <div className="h1">
      <FormattedMessage
        id="machines.oee.most_recent_shift.with_date"
        defaultMessage="OEE for the most recent shift ({ shiftDateFormatted })"
        values={{ shiftDateFormatted }}
      />
    </div>
  );
};

IndicatorsTitle.propTypes = {
  isShiftExist: PropTypes.bool.isRequired,
  shiftStartMoment: PropTypes.object,
};

export default IndicatorsTitle;
