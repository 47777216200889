import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import placeholdersMessages from '../../../../messages/placeholders.messages';

function EmptyData({ isSmall }) {
  const style = classnames(
    'nodata',
    { 'nodata-sm': isSmall },
  );

  return (
    <div className={style}>
      <FormattedMessage
        {...placeholdersMessages.noDataToDisplay}
        tagName="div"
      />
    </div>
  );
}

EmptyData.propTypes = {
  isSmall: PropTypes.bool.isRequired,
};

EmptyData.defaultProps = {
  isSmall: false,
};

export default EmptyData;
