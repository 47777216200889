import React from 'react';
import PropTypes from 'prop-types';

import MostRecentActivity from './MostRecentActivity';
import commonCoreMessages from '../../../../../../messages/common.core.messages';
import commonFieldsMessages from '../../../../../../messages/common.fields.messages';
import * as form from '../../../../../../redux/constants/tools.view.form.constants';
import { FormField } from '../../../../../_tools';

export const Details = ({
  hasMostRecentActivity,
}) => (
  <>
    <div className="inputs--row">
      <div className="inputs--col--md2">
        <FormField
          label={commonCoreMessages.label.id}
          name={form.LABEL_FIELD}
          disabled
        />
      </div>
      <div className="inputs--col--md2">
        <FormField
          label={commonCoreMessages.type.id}
          name={form.TYPE_FIELD}
          disabled
        />
      </div>
    </div>
    <div className="inputs--row">
      <div className="inputs--col--md2">
        <FormField
          label={commonCoreMessages.model.id}
          name={form.MODEL_FIELD}
          disabled
        />
      </div>
      <div className="inputs--col--md2">
        <FormField
          label={commonFieldsMessages.serialNumber.id}
          name={form.SERIAL_FIELD}
          disabled
        />
      </div>
    </div>

    { hasMostRecentActivity && <MostRecentActivity /> }
  </>
);

Details.propTypes = {
  hasMostRecentActivity: PropTypes.bool.isRequired,
};

export default Details;
