import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Columns from './columns/Columns';
import Loader from '../_tools/loader/Loader';
import AssignModalContainer from './column/assignModal/AssignModal.container';
import { onMaintenanceEnter } from '../index/routeHandlers';

const Maintenance = ({ isLoading, isAssignModalOpen }) => {
  useEffect(() => {
    onMaintenanceEnter();
  }, []);

  return (
    <div>
      <Columns />
      <Loader
        show={isLoading}
        fullHeight
        solidBackground
      />
      { isAssignModalOpen && <AssignModalContainer /> }
    </div>
  );
};

Maintenance.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAssignModalOpen: PropTypes.bool.isRequired,
};

export default Maintenance;
