import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReportIcon from '../../common/placeholderIcons/ReportIcon';

export const NoBatchesPlaceholder = () => (
  <div className="reports-empty">
    <div>
      <ReportIcon />
      <p>
        <FormattedMessage
          id="products.no_products_placeholder"
          defaultMessage="No Products tracked yet"
          tagName="strong"
        />
      </p>
    </div>
  </div>
);

export default NoBatchesPlaceholder;
