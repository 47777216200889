import React from 'react';
import PropTypes from 'prop-types';

import CardsMotion from './Cards.motion';
import NoCardsContainer from './NoCards.container';
import Cards from './Cards';

function CardsTemplate({
  cards, row, cardId, animate, type, noCards,
}) {
  if (noCards) {
    return (
      <NoCardsContainer type={type} />
    );
  }

  if (animate) {
    return (
      <CardsMotion
        cards={cards}
        row={row}
        cardId={cardId}
        type={type}
      />
    );
  }

  return (
    <Cards
      cards={cards}
      type={type}
    />
  );
}

CardsTemplate.propTypes = {
  noCards: PropTypes.bool.isRequired,
  cards: PropTypes.array.isRequired,
  row: PropTypes.number.isRequired,
  cardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  animate: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

CardsTemplate.defaultProps = {
  cardId: -1,
  row: -1,
  animate: false,
};

export default CardsTemplate;
