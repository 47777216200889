import { connect } from 'react-redux';

import OrganizationsModule from './OrganizationsModule';
import * as selector from '../../../../redux/selectors/admin.organizations.selector';
import { adminFetchOrganizations } from '../../../../redux/modules/admin.organizations.fetch.module';

export const mapStateToProps = (state) => {
  const loaded = selector.areOrganizationsLoaded(state);
  const pagination = selector.getPagination(state);
  const nameQuery = selector.getNameFilterValue(state);

  return {
    list: selector.getOrganizations(state),
    paginationAvailable: loaded && !!pagination && pagination.total_pages > 1,
    pagination,
    nameQuery,
  };
};

export const mapDispatchToProps = {
  choosePage: adminFetchOrganizations,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsModule);
