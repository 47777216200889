import { createAction } from 'redux-act';

export const setCalendarBackendAvailability = createAction(
  'check is calendar available',
  (status) => status,
);

export const reducer = {
  [setCalendarBackendAvailability]: (state, status) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      available: status.isCalendarAvailable,
      access: status.isCalendarAccess,
    },
  }),
};
