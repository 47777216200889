import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EventRemoveModal from './EventRemoveModal';
import { CP_EVENT_REMOVE } from '../../../../../redux/constants/modals.constants';
import { closeModal } from '../../../../../redux/modules/modals.module';
import { deleteCalendarEvent } from '../../../../../redux/modules/calendars.delete.event.module';
import { isModalOpen, getModalPayload } from '../../../../../redux/selectors/modals.selector';

export function mapStateToProps(state) {
  return {
    isOpen: isModalOpen(state, CP_EVENT_REMOVE),
    ...getModalPayload(state, CP_EVENT_REMOVE),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    close: () => dispatch(closeModal(CP_EVENT_REMOVE)),
    deleteCalendarEvent: bindActionCreators(deleteCalendarEvent, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventRemoveModal);
