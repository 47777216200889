import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import AccountRow from './AccountRow';
import AccountsHeader from './header/AccountsHeader';

export const AccountsModule = ({
  list, route, history,
}) => (
  <div className="user-table">
    <table className="table">
      <AccountsHeader />
      <tbody>
        { list.map((item) => <AccountRow key={item.id} account={item} pushPath={history.push} />) }
      </tbody>
    </table>
    { renderRoutes(route.routes) }
  </div>
);

AccountsModule.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  list: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default AccountsModule;
