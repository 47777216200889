import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { closeModal } from './modals.module';
import { EXPERIMENT_ARCHIVE } from '../constants/modals.constants';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';
import {
  getExperimentById,
  getExperimentsPagination,
  getExperimentsFilters,
} from '../selectors/experimentsPage.selector';
import { fetchExperiments } from './experiments.fetch.module';
import { updateExperimentSuccess } from './experiments.update.module';

export const archiveExperiment = createAction(
  'archive experiment',
  (experimentId) => experimentId,
);

export const archiveExperimentFail = createAction(
  'archive experiment - fail',
  (error) => error,
);

export function* archiveExperimentSaga({ payload: experimentId }) {
  const state = yield select();
  const experiment = yield call(getExperimentById, state, experimentId);
  const { response, error } = yield call(api.experiments.archiveExperiment, experimentId);

  if (response) {
    yield put(updateExperimentSuccess(response));
    yield put(closeModal(EXPERIMENT_ARCHIVE));
    yield put(sendNotification({ ...notificationMessages.experiment_archived, values: { name: experiment.name } }));

    const pagination = yield call(getExperimentsPagination, state);
    if (pagination) {
      const filters = yield call(getExperimentsFilters, state);
      const newPagination = yield call(api.experiments.getExperimentsPagination, filters);

      if (newPagination.response) {
        const pageToFetch = pagination.current_page > newPagination.response.pagination.total_pages ?
          newPagination.response.pagination.total_pages : pagination.current_page;
        yield put(fetchExperiments(pageToFetch));
      } else {
        yield put(fetchExperiments(pagination.current_page));
      }
    }
  } else {
    yield put(archiveExperimentFail(error));
  }
}

export function* watchArchiveExperiment() {
  yield takeEvery(archiveExperiment.getType(), archiveExperimentSaga);
}
