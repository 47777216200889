import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getAdminLocationLink, getAdminEditLocationLink } from '../../../index/routes';
import { HighlightText } from '../../../_tools';
import locationTypeMessages from '../../../../messages/location.types.messages';

export const LocationRow = ({
  location, onRemoveClick, nameToHighlight, pushPath,
}) => {
  const handleViewClick = () => pushPath(getAdminLocationLink(location.id));
  const handleEditClick = () => pushPath(getAdminEditLocationLink(location.id));
  const handleRemoveClick = () => onRemoveClick(location);
  const parent = location.path ? location.path.join(' > ') : '';

  return (
    <tr>
      <td>
        <strong>
          <HighlightText
            text={location.name}
            highlight={nameToHighlight}
          />
        </strong>
      </td>
      <td>
        <FormattedMessage {...locationTypeMessages[location.type]} tagName="strong" />
      </td>
      <td>
        <strong>{ parent }</strong>
      </td>
      <td className="td-actions">
        <button className="button light-grey-white" onClick={handleViewClick}>
          <i className="icon-eye" />
        </button>
        <button className="button light-grey-white" onClick={handleEditClick}>
          <i className="icon-edit" />
        </button>
        <button className="button light-grey-white" onClick={handleRemoveClick}>
          <i className="icon-close2" />
        </button>
      </td>
    </tr>
  );
};

LocationRow.propTypes = {
  location: PropTypes.object.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  pushPath: PropTypes.func.isRequired,
  nameToHighlight: PropTypes.string,
};

LocationRow.defaultProps = {
  nameToHighlight: '',
};

export default LocationRow;
