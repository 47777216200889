import React, { useState } from 'react';
import classNames from 'classnames';

import noDoubleClick from '../../../../_tools/stopPropagation';
import ColumnDialogMenuActionsContainer from './ColumnDialogMenuActions.container';
import { Todo, TodoFunction } from '../../../../../../../common/types/common';
import { useClickOutside } from '../../../../../core/common/hooks/useClickOutside';

interface Props {
  organizationId: string,
  activityId: string,
  eventId: string,
  resolve: TodoFunction,
  canHide: boolean,
  canAssign: boolean,
  canResolve: boolean,
  assignee?: Todo,
}


export const ColumnDialogMenu: React.FC<Props> = ({
  organizationId = '',
  activityId = '',
  eventId = '',
  resolve = () => {},
  canHide,
  canAssign,
  canResolve,
  assignee,
}) => {
  const [isOpen, isOpenSet] = useState(false);

  const close = () => {
    isOpenSet(false);
  };

  const toggle = (e) => {
    noDoubleClick(e);
    isOpenSet(!isOpen);
  };

  const className = classNames('filter--block filter--vertical--points', {
    opened: isOpen,
  });

  const clickRef = useClickOutside(close);

  return (
    <div className={className} ref={clickRef}>
      <button
        className="filter--btn"
        id="three_dot_button"
        type="button"
        onClick={toggle}
      >
        <span className="point-dot"/>
      </button>
      {isOpen &&
        <div className="filter--dropdown">
        <ColumnDialogMenuActionsContainer
          eventId={eventId}
          organizationId={organizationId}
          activityId={activityId}
          close={close}
          resolve={resolve}
          canHide={canHide}
          canAssign={canAssign}
          canResolve={canResolve}
          assignee={assignee}
        />
      </div>}
    </div>
  );
};

export default ColumnDialogMenu;
