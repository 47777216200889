import React from 'react';
import PropTypes from 'prop-types';

export const TabsBarTools = ({
  showPlusButton,
  showLeftArrowButton,
  showRightArrowButton,
  isLeftButtonDisabled,
  isRightButtonDisabled,
  onPlusButtonClick,
  onLeftButtonClick,
  onRightButtonClick,
}) => (
  <div className="big-tabs-actions">
    { showPlusButton && (
      <button
        className="button light-grey-white button-plus"
        onClick={onPlusButtonClick}
      >
        <span className="fa fa-plus" />
      </button>
    ) }
    <div className="big-tabs-button">
      { showLeftArrowButton && (
        <button
          className="button light-grey-white"
          onClick={onLeftButtonClick}
          disabled={isLeftButtonDisabled}
        >
          <span className="icon-slide-left" />
        </button>
      ) }
      { showRightArrowButton && (
        <button
          className="button light-grey-white"
          onClick={onRightButtonClick}
          disabled={isRightButtonDisabled}
        >
          <span className="icon-slide-right" />
        </button>
      ) }
    </div>
  </div>
);

TabsBarTools.propTypes = {
  showPlusButton: PropTypes.bool,
  showLeftArrowButton: PropTypes.bool,
  showRightArrowButton: PropTypes.bool,
  isLeftButtonDisabled: PropTypes.bool,
  isRightButtonDisabled: PropTypes.bool,
  onPlusButtonClick: PropTypes.func,
  onLeftButtonClick: PropTypes.func,
  onRightButtonClick: PropTypes.func,
};

TabsBarTools.defaultProps = {
  showPlusButton: false,
  showLeftArrowButton: false,
  showRightArrowButton: false,
  isLeftButtonDisabled: false,
  isRightButtonDisabled: false,
  onPlusButtonClick: () => {},
  onLeftButtonClick: () => {},
  onRightButtonClick: () => {},
};

export default TabsBarTools;
