import { connect } from 'react-redux';

import MetricData from './MetricData';
import {
  getDataPointMetric, isLoadMoreButtonAvailable, isUtcModeActive, isLoaded,
} from '../../../../../redux/selectors/dataPoint.metric.selector';

export const mapStateToProps = (state, { dataPointId }) => {
  const dataList = getDataPointMetric(state, dataPointId);
  const hasNoData = !dataList.length && isLoaded(state, dataPointId);
  return {
    dataList: getDataPointMetric(state, dataPointId),
    isLoadMoreButtonAvailable: isLoadMoreButtonAvailable(state, dataPointId),
    utcMode: isUtcModeActive(state, dataPointId),
    hasNoData,
  };
};

export default connect(mapStateToProps)(MetricData);
