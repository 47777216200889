import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';

import { selectInventoryLocations, getLocationById } from '../selectors/inventory.selector';
import { sendNotification } from './notifications.module';
import { INVENTORY_DATATRONS, INVENTORY_MACHINES } from '../../../../common/constants/inventory';
import notificationMessage from '../../messages/notification.message';

export const receiveDatatron = createAction(
  'receive new datatron',
  (datatron) => datatron,
);

export const reducer = {
  [receiveDatatron]: (state, datatron) => {
    const locationIndex = getLocationById(state, datatron.location.id);

    let locations = selectInventoryLocations(state);

    if (locationIndex !== -1) {
      const location = locations[locationIndex];

      locations = [
        {
          ...location,
          [INVENTORY_DATATRONS]: [
            ...location[INVENTORY_DATATRONS],
            datatron,
          ],
        },
        ...state.inventory.locations.slice(0, locationIndex),
        ...state.inventory.locations.slice(locationIndex + 1),
      ];
    } else {
      const location = {
        ...datatron.location,
        [INVENTORY_DATATRONS]: [datatron],
        [INVENTORY_MACHINES]: [],
      };
      locations = [
        location,
        ...locations,
      ];
    }

    return {
      ...state,
      inventory: {
        ...state.inventory,
        locations,
        datatrons: [
          ...state.inventory.datatrons,
          datatron,
        ],
      },
    };
  },
};

export function* receiveDatatronSaga({ payload: datatron }) {
  yield put(sendNotification(
    {
      ...notificationMessage.new_datatron_created_title,
      values: {
        datatronName: datatron.name,
      },
    },
  ));
}

export function* watchReceiveDatatron() {
  yield takeEvery(receiveDatatron.getType(), receiveDatatronSaga);
}
