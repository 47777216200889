import { connect } from 'react-redux';

import CustomSelectContainer from './CustomSelect.container';
import commonUserMessages from '../../../../messages/common.user.messages';
import { PUBLIC_STATUSES } from '../../../../../../common/enums/userStatuses';

const options = PUBLIC_STATUSES.map((status) => ({
  label: commonUserMessages[`status_${status}`],
  value: status,
}));

export const mapStateToProps = () => ({
  options,
  translateOptionsLabels: true,
});

export default connect(mapStateToProps)(CustomSelectContainer);
