import { connect } from 'react-redux';

import Select from './CustomSelect.container';
import * as selector from '../../../../redux/selectors/machine.selector';

export const mapStateToProps = (state) => ({
  options: selector
    .getAllMachines(state)
    .map((machine) => ({
      value: machine.id,
      label: machine.name,
    })),
});

export default connect(mapStateToProps)(Select);
