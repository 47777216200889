import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DATATRONS_PERMISSIONS } from '../../../../../common/constants/permissions';
import {
  activateDatatron,
  deactivateDatatron,
} from '../../../core/api/datatrons';
import { useOpen } from '../../../core/common/hooks/useOpen';
import commandModalMessages from '../../../messages/command.modal.messages';
import commonMessages from '../../../messages/common.messages';
import messages from '../../../messages/datatron.messages';
import { updateActiveStatusOfDatatron } from '../../../redux/modules/datatron.active.module';
import BooleanField from '../../common/dataPoints/configItem/viewOptions/boolean/BooleanField';
import { usePermissionInstance } from '../../common/permissions/usePermissionInstance';
import { Loader } from '../../_tools';
import { Modal } from '../../_tools/modal/Modal';

interface Props {
  datatronId: string;
  isActive: boolean;
}

type ActionState = 'default' | 'submitting' | 'success' | 'validationError' | 'error';

const useAction = (datatronId: string, isActive: boolean, isOpen: boolean) => {
  const [commandState, commandStateSet] = useState<ActionState>('default');
  const [validationState, setvalidationState] = useState<string>('');

  const dispatch = useDispatch();
  const handleSubmit = useCallback(() => {
    const action = isActive ? deactivateDatatron : activateDatatron;
    commandStateSet('submitting');
    action(datatronId)
      .then((res) => {
        if (res.status === 200) {
          commandStateSet('success');
          dispatch(updateActiveStatusOfDatatron(datatronId, !isActive));
        } else if (res.status === 400) {
          commandStateSet('validationError');
          setvalidationState(res.error.errors);
        } else {
          commandStateSet('error');
        }
      })
      .catch(() => {
        commandStateSet('error');
      });
  }, [datatronId, isActive, dispatch]);
  useEffect(() => {
    if (!isOpen) {
      // reset state when modal is closed
      commandStateSet('default');
    }
  }, [isOpen]);
  return {
    validationState,
    commandState,
    handleSubmit,
  };
};

const useActionLabel = (isActivated: boolean) => {
  const { formatMessage } = useIntl();
  return isActivated
    ? formatMessage(commonMessages.deactivate)
    : formatMessage(commonMessages.activate);
};

const useModalTitle = (isActivated: boolean, actionState: ActionState, validationState: string) => {
  const { formatMessage } = useIntl();
  switch (actionState) {
    case 'default':
      return isActivated
        ? formatMessage(messages.deactivateModalTitle)
        : formatMessage(messages.activateModalTitle);
    case 'submitting':
      return formatMessage(commandModalMessages.submitting);
    case 'success':
      return formatMessage(commandModalMessages.success);
    case 'validationError':
      return validationState;
    case 'error':
      return formatMessage(commandModalMessages.error);
  }
};

const useCanUpdateDatatrons = () => {
  const datatronsPermissions = usePermissionInstance(DATATRONS_PERMISSIONS);
  return datatronsPermissions.canUpdateDatatrons();
};

export const ActivateButton: React.FC<Props> = ({ datatronId, isActive }) => {
  const { isOpen, close, open } = useOpen();
  const canUpdateDatatrons = useCanUpdateDatatrons();
  const { commandState, validationState, handleSubmit } = useAction(
    datatronId,
    isActive,
    isOpen,
  );
  const actionLabel = useActionLabel(isActive);
  const modalTitle = useModalTitle(isActive, commandState, validationState);
  const { formatMessage } = useIntl();

  return (
    <div>
      <button
        className="button button__xs white"
        onClick={open}
        disabled={!canUpdateDatatrons}
      >
        <BooleanField value={isActive} />
        <span style={{ marginLeft: '6px' }}>{actionLabel}</span>
      </button>
      <Modal isOpen={isOpen} contentLabel="TODO" onClose={close}>
        <div className="modal--header" onClick={close}>
          <span className="modal--close">&times;</span>
        </div>
        <div className="modal--content">
          <h3>{modalTitle}</h3>
          {commandState === 'submitting' && (
            <div style={{ height: '100%', width: '100%' }}>
              <Loader show fullPage={false} />
            </div>
          )}
          {commandState === 'error' && <span>Something went wrong!</span>}
          <div className="line" />
          <div className="text-center">
            {(commandState === 'default' || commandState === 'submitting') && (
              <button type="button" className="button__cancel" onClick={close}>
                {formatMessage(commonMessages.cancel)}
              </button>
            )}
            {commandState === 'default' && (
              <button
                type="submit"
                className="button__save"
                onClick={handleSubmit}
              >
                {actionLabel}
              </button>
            )}
            {commandState === 'success' && (
              <button type="submit" className="button__save" onClick={close}>
                {formatMessage(commonMessages.close)}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
