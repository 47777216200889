import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as entryTypes from '../../../../../../redux/constants/configRepoExplorer.entry.types.constants';

export const RightBarPlaceholderEmpty = ({ entryType }) => (
  <div className="inventory-empty">
    <div>
      <div className="inventory-empty-icon" />
      <strong>
        { entryType === entryTypes.DATATRONS && (
          <FormattedMessage
            id="configRepo.explorer.placeholder.empty.datatrons"
            defaultMessage="No DATATRONs configured"
          />
        ) }
        { entryType === entryTypes.DEVICES && (
          <FormattedMessage
            id="configRepo.explorer.placeholder.empty.devices"
            defaultMessage="No Devices configured"
          />
        ) }
        { entryType === entryTypes.DATA_POINTS && (
          <FormattedMessage
            id="configRepo.explorer.placeholder.empty.data_points"
            defaultMessage="No Data Points configured"
          />
        ) }
        { entryType === entryTypes.MACHINES && (
          <FormattedMessage
            id="configRepo.explorer.placeholder.empty.machines"
            defaultMessage="No Machines configured"
          />
        ) }
      </strong>
    </div>
  </div>
);

RightBarPlaceholderEmpty.propTypes = {
  entryType: PropTypes.string.isRequired,
};

export default RightBarPlaceholderEmpty;
