import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useClickOutside } from '../../../../../../core/common/hooks/useClickOutside';
import classnames from 'classnames';

interface Props {
  onClick: (string) => () => void;
  disabled?: boolean;
}

export const AddDataPointButton: React.FC<Props> = ({ onClick, disabled = false }) => {
  const addOptions = ['simple', 'array'];

  const [isOpen, setIsOpen] = useState(false);

  const clickRef = useClickOutside(() => {
    if (isOpen) setIsOpen(false);
  });

  const toggle = () => setIsOpen(!isOpen);

  const dropdownClassName = classnames('button-group__with-dropdown', {
    opened: isOpen,
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'right' }}>
      <div className={dropdownClassName} ref={clickRef}>
        <button className='button green button__dropdown' onClick={toggle} disabled={disabled}>
          <span className='fa fa-plus' />
          <FormattedMessage id='datatron.data.add_data_point' defaultMessage='Add Data Point' />
        </button>
        <div className='button-group--dropdown' style={{ zIndex: '999' }}>
          <ul>
            {addOptions.map((item) => (
              <li key={item} className={`datapoint-item-${item}`}>
                <a onClick={onClick(item)}>{item}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AddDataPointButton;
