import React from 'react';
import PropTypes from 'prop-types';

const FrameWrapper = ({ hide, children }) => {
  if (hide) return null;

  return (
    <>
      { children }
    </>
  );
};

FrameWrapper.propTypes = {
  hide: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FrameWrapper.defaultProps = {
  hide: false,
};

export default FrameWrapper;
