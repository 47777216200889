import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import DeviceClassContainer from './DeviceClass.container';
import { useClickOutside } from '../../../../../core/common/hooks/useClickOutside';
import { getDeviceClassesWithDeviceTypes } from '../../../../../redux/selectors/datatron.selector';
import { applyCustomOrder } from '../../../../../../../common/helpers/object';
import { useSelector } from 'react-redux';

const CUSTOM_DEVICE_CLASSES_SORTING = [
  'PLC',
  'NCU',
  'AnalogCard',
  'Video',
];


const DataAddButton: React.FC = () => {
  const unsortedDeviceClasses = useSelector(getDeviceClassesWithDeviceTypes);
  const deviceClasses = applyCustomOrder(unsortedDeviceClasses, CUSTOM_DEVICE_CLASSES_SORTING);

  const [isOpen, setIsOpen] = useState(false);

  const clickRef = useClickOutside(() => {
    if (isOpen) setIsOpen(false);
  });

  const toggle = () => setIsOpen(!isOpen);

  const dropdownClassName = classnames(
    'button-group__with-dropdown',
    { opened: isOpen },
  );

  return (
    <div className={dropdownClassName} ref={clickRef}>
      <button className="button green button__dropdown" onClick={toggle}>
        <span className="fa fa-plus"/>
        <FormattedMessage
          id="common.add_new"
          defaultMessage="Add New"
        />
      </button>
      <div className="button-group--dropdown">
        <ul>
          {deviceClasses.map((item) => (
            <DeviceClassContainer
              key={item}
              deviceClass={item}
              close={() => setIsOpen(false)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DataAddButton;
