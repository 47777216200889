import React, { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { FormField } from '../../../../../_tools';
import DatePickerMainButtonsContainer from '../../../../../_tools/formFields/datePickerV2/DatePickerMainButtons.container';
import InputButtonWithIcon from '../../../../../_tools/formFields/inputButtonWithIcon/InputButtonWithIcon.container';
import ModalLine from '../../../../core/ModalLine';
import AddModalFooter from '../../../../core/footers/AddModalFooter';
import * as form from '../../../../../../redux/constants/aiApp.cycleDetection.form.constants';
import aiAppMessages from '../../../../../../messages/aiApp.config.messages';
import commonCoreMessages from '../../../../../../messages/common.core.messages';
import {
  MODAL_MAIN_FRAME,
  MODAL_DATE_PERIOD_FRAME,
  MODAL_TYPE_UUID_FILTERS_FRAME,
} from '../../../../../../core/common/aiApp.form';
import { SUB_PAGE_AI_APP_FORM as modalId } from '../../../../../../redux/constants/modals.constants';
import { setModalPath } from '../../../../../../redux/modules/modals.module';
import { cacheFormField } from '../../../../../../redux/modules/common.form.module';

interface OwnProps {
  handleSubmit: (e: FormEvent) => void;
  handleCancel: () => void;
  submitting: boolean;
  pristine: boolean;
}

const CycleDetectionMainFrame: React.FC<OwnProps> = ({
  handleSubmit,
  handleCancel,
  submitting,
  pristine,
}) => {
  const dispatch = useDispatch();

  const onDatePickerClick = () => {
    dispatch(setModalPath(modalId, [MODAL_MAIN_FRAME, MODAL_DATE_PERIOD_FRAME]));
    dispatch(cacheFormField(form.AI_APP_CYCLE_DETECTION_FORM, form.DATES_FIELD));
  };

  const onTypeUuidFiltersClick = () => {
    dispatch(setModalPath(modalId, [MODAL_MAIN_FRAME, MODAL_TYPE_UUID_FILTERS_FRAME]));
    dispatch(cacheFormField(form.AI_APP_CYCLE_DETECTION_FORM, form.TYPE_UUID_FIELD));
  };

  return (
    <div className='modal-move modal-main'>
      <div className='modal-form-in'>
        <div className='like-label'>
          <FormattedMessage {...commonCoreMessages.datePeriod} tagName='span' />
          <div className='form-group' onClick={onDatePickerClick}>
            <div className='datepicker-block'>
              <Field
                component={DatePickerMainButtonsContainer}
                name={form.DATES_FIELD}
                tabIndex={1}
              />
            </div>
          </div>
        </div>
        <div className='like-label search-block-category'>
          <FormattedMessage {...aiAppMessages.typeUUID} tagName='span' />
          <Field
            name={form.TYPE_UUID_FIELD}
            component={InputButtonWithIcon}
            placeholder={aiAppMessages.typeUUID}
            onClick={onTypeUuidFiltersClick}
            iconClassName='icon icon-search'
            tabIndex={2}
          />
        </div>
        <div className='inputs--row'>
          <div className='inputs--col--md2'>
            <FormField
              component={FormField}
              label={aiAppMessages.minCycleTime}
              placeholder={aiAppMessages.minCycleTime.id}
              name={form.MIN_CYCLE_TIME_FIELD}
              _defaultValue={form.MIN_CYCLE_TIME_DEFAULT}
              tabIndex={3}
              type='number'
            />
          </div>
          <div className='inputs--col--md2'>
            <FormField
              component={FormField}
              label={aiAppMessages.maxCycleTime}
              placeholder={aiAppMessages.maxCycleTime.id}
              name={form.MAX_CYCLE_TIME_FIELD}
              _defaultValue={form.MAX_CYCLE_TIME_DEFAULT}
              tabIndex={4}
              type='number'
            />
          </div>
        </div>
      </div>
      <ModalLine />

      <AddModalFooter
        onCancelClick={handleCancel}
        onAddClick={handleSubmit}
        isAddButtonDisabled={pristine || submitting}
        isAddButtonLoading={submitting}
        cancelButtonTabIndex={5}
        addButtonTabIndex={6}
      />
    </div>
  );
};

export default CycleDetectionMainFrame;
