import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { FormField } from '../../../_tools';
import BasicModal from '../../core/BasicModal';
import DuplicateModalFooter from '../../core/footers/DuplicateModalFooter';
import messages from '../../../../messages/report.page.messages';
import commonMessages from '../../../../messages/common.messages';
import { NAME_FIELD } from '../../../../redux/constants/report.duplicate.form.constants';

const DuplicateRemoveModal = ({
  close,
  duplicate,
  report,
  submitting,
}) => {
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    duplicate(report.id);
  };

  return (
    <BasicModal
      close={close}
      modalClass="modal--sm"
    >
      <div className="modal--content">
        <FormattedMessage {...messages.duplicateModalTitle} tagName="h3" />
        <form className="form" onSubmit={handleSubmit}>
          <FormField
            label={commonMessages.name.id}
            name={NAME_FIELD}
            autoFocus
          />

          <div className="line" />

          <DuplicateModalFooter
            isDuplicateButtonDisabled={submitting}
            isDuplicateButtonLoading={submitting}
            onCancelClick={close}
            onEditClick={handleSubmit}
          />
        </form>
      </div>
    </BasicModal>
  );
};

DuplicateRemoveModal.propTypes = {
  close: PropTypes.func.isRequired,
  duplicate: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
};

export default DuplicateRemoveModal;
