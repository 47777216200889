import { defineMessages } from 'react-intl';
import {
  EQUIPMENT,
  DATATRONS,
  DEVICES,
  DATA_POINTS,
  MACHINES,
} from '../redux/constants/configRepoExplorer.entry.types.constants';

export default defineMessages({
  [EQUIPMENT]: {
    id: 'configRepo.explorer.type.equipment',
    defaultMessage: 'Equipment',
  },
  [DATATRONS]: {
    id: 'configRepo.explorer.type.datatron',
    defaultMessage: 'DATATRON',
  },
  [DEVICES]: {
    id: 'configRepo.explorer.type.device',
    defaultMessage: 'Device',
  },
  [DATA_POINTS]: {
    id: 'configRepo.explorer.type.data_point',
    defaultMessage: 'Data Point',
  },
  [MACHINES]: {
    id: 'configRepo.explorer.type.machines',
    defaultMessage: 'Machines',
  },
  select: {
    id: 'configRepo.explorer.placeholder.select',
    defaultMessage: 'Select',
  },
});
