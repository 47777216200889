import React from 'react';
import PropTypes from 'prop-types';
import { min, max } from 'd3-array';
import { scaleLinear } from 'd3-scale';

import Line from '../../kpiChart/chartRouter/lineChart/Line';

export const getX = (point) => point.timestamp;
export const getY = (point) => point.value;

export const getMinMaxValue = (data) => data.reduce((final, point) => {
  const newFinal = final;
  const value = getY(point);
  if (value > final.max) newFinal.max = value;
  if (value < final.min) newFinal.min = value;
  return newFinal;
}, {
  min: 0,
  max: 0,
});

function AnomalySignalChart({ data, width, height }) {
  const margin = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const translate = `translate(${margin.left}, ${margin.top})`;

  const domain = [min(data, getX), max(data, getX)];

  const xScale = scaleLinear()
    .domain(domain)
    .range([0, chartWidth]);

  const yValues = getMinMaxValue(data);

  const yScale = scaleLinear()
    .domain([yValues.min, yValues.max])
    .range([chartHeight, 0]);

  return (
    <svg style={{ width: '100%', height: '100%' }}>
      <g transform={translate}>
        <g>
          <Line
            data={data}
            className="line--performance"
            getX={getX}
            getY={getY}
            xScale={xScale}
            yScale={yScale}
          />
        </g>
      </g>
    </svg>
  );
}

AnomalySignalChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AnomalySignalChart;
