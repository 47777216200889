import { createAction, PayloadActionCreator } from '@reduxjs/toolkit';
import { AppState } from '../state/app.state';

type Reducer<PAC extends PayloadActionCreator> = (
  state: AppState,
  action: ReturnType<PAC>
) => AppState;

/**
 * A Mapping from Action-Type -> Reducer
 */
export type AppReducerMap = Record<string, Reducer<any>>;

/** A helper class to create a reducer map with inferred types */
export class AppReducerMapBuilder {
  private readonly appReducerMap: AppReducerMap;

  private constructor(appReducerMap: AppReducerMap) {
    this.appReducerMap = appReducerMap;
  }

  static new(): AppReducerMapBuilder {
    return new AppReducerMapBuilder({});
  }

  add<PAC extends ReturnType<typeof createAction>>(
    actionCreator: PAC,
    reducer: (state: AppState, action: ReturnType<PAC>['payload']) => AppState,
  ): AppReducerMapBuilder {
    return new AppReducerMapBuilder({
      ...this.appReducerMap,
      [actionCreator.toString()]: (state, action) => reducer(state, action),
    });
  }

  build(): AppReducerMap {
    return this.appReducerMap;
  }
}
