import { apiRequest } from './_tools';

export function search(query) {
  return apiRequest(`/api/tags/search?q=${query}`)
    .then(({ response, error }) => {
      if (response) {
        return {
          error,
          response: response.map((tag) => ({ id: tag.name, name: tag.name })),
        };
      }

      return { response, error };
    });
}
