import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import commonMessages from '../../../../../messages/common.messages';
import ThreeDotsMenu from '../../../../_tools/threeDotsMenu/ThreeDotsMenu';

export class Actions extends Component {
  static propTypes = {
    calendar: PropTypes.object.isRequired,
    openRemoveModal: PropTypes.func.isRequired,
    openCloneModal: PropTypes.func.isRequired,
    openEditModal: PropTypes.func.isRequired,
    canClone: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired,
    canEdit: PropTypes.bool.isRequired,

  };

  getItems = () => {
    const {
      intl, canClone, canDelete, canEdit,
    } = this.props;
    const items = [];

    if (canEdit) {
      items.push({
        label: intl.formatMessage(commonMessages.rename),
        onClick: this.handleEdit,
        isIcon: true,
        icon: 'icon-edit',
      });
    }

    if (canClone) {
      items.push({
        label: intl.formatMessage(commonMessages.duplicate),
        onClick: this.handleClone,
        isIcon: true,
        icon: 'icon-clone',
      });
    }

    if (canDelete) {
      items.push({
        label: intl.formatMessage(commonMessages.delete),
        onClick: this.handleDelete,
        isIcon: true,
        icon: 'icon-close2',
      });
    }

    return items;
  };

  handleDelete = () => {
    const { calendar, openRemoveModal } = this.props;
    openRemoveModal({ calendar });
  };

  handleClone = () => {
    const { calendar, openCloneModal } = this.props;
    openCloneModal({ calendar });
  };

  handleEdit = () => {
    const { calendar, openEditModal } = this.props;
    openEditModal({ calendar });
  };

  render() {
    const items = this.getItems();
    if (!items.length) return <span />;

    return <ThreeDotsMenu menuItems={items} />;
  }
}

export default injectIntl(Actions);
