import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ColumnEvent from './InboxEvent';
import * as selectors from '../../../../../redux/selectors/maintenance.selector';
import { collapseExpand } from '../../../../../redux/modules/maintenance.expanded.module.js';
import * as eventCategories from '../../../../../../../common/enums/eventCategory';
import { fetchAnomaly } from '../../../../../redux/modules/anomaly.fetch.module';

export function mapDispatchToProps(dispatch) {
  return {
    collapseExpand: bindActionCreators(collapseExpand, dispatch),
    fetchAnomaly: bindActionCreators(fetchAnomaly, dispatch),
  };
}

export function mapStateToProps(state, { event }) {
  return {
    canSeeAnomaly: event.category === eventCategories.anomaly,
    isFatal: selectors.isFatalEvent(event),
    isWarning: selectors.isWarningEvent(event),
    isInfo: selectors.isInfoEvent(event),
    isAnomaly: selectors.isAnomalyEvent(event),
    isError: selectors.isErrorEvent(event),
    isOpened: selectors.isDetailsShown(state, event.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnEvent);
