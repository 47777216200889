import React from 'react';
import { FormattedMessage } from 'react-intl';
import { filtersApplied } from '../../../redux/selectors/aiApps.selector';
import placeholderMessages from '../../../messages/placeholders.messages';
import { useSelector } from 'react-redux';

export const AiAppsEmptyPlaceholder: React.FC = () => {
  const hasFiltersApplied = useSelector(filtersApplied);

  const title = hasFiltersApplied
    ? placeholderMessages.noDataToDisplay
    : placeholderMessages.noDataAdded;

  return (
    <div className='folder-empty'>
      <div>
        <div className='open-folder-icon'>
          <i className='fa fa-folder-open-o' aria-hidden='true'></i>
        </div>
        <p>
          <FormattedMessage {...title} tagName='strong' />
        </p>
      </div>
    </div>
  );
};

export default AiAppsEmptyPlaceholder;
