import React from 'react';
import { FormattedMessage } from 'react-intl';

import commonMessages from '../../../messages/common.messages';

export const NotSetPlaceholder = () => (
  <div className="input-result grey">
    <FormattedMessage {...commonMessages.notSet} />
  </div>
);

export default NotSetPlaceholder;
