import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as machinesAPI from '../../core/api/machines';
import { removeObjectFromList, compareById } from '../../../../common/helpers/object';
import { closeModal } from './modals.module';
import { SMP_ASSISTANT_REMOVE } from '../constants/modals.constants';

export const removeAssistant = createAction(
  'remove machine assistant',
  (machineId, userId) => ({ machineId, userId }),
);

export const removeAssistantSuccess = createAction(
  'remove machine assistant - success',
  (userId) => userId,
);

export const removeAssistantFail = createAction(
  'remove machine assistant - fail',
  (error) => error,
);

export const reducer = {
  [removeAssistantSuccess]: (state, userId) => ({
    ...state,
    machine: {
      ...state.machine,
      details: {
        ...state.machine.details,
        assistants: removeObjectFromList(
          state.machine.details.assistants,
          { id: userId },
          compareById,
        ),
      },
    },
  }),
};

export function* removeAssistantSaga({ payload: { machineId, userId } }) {
  const { response, error } = yield call(machinesAPI.removeAssistant, machineId, userId);

  if (response) {
    yield put(closeModal(SMP_ASSISTANT_REMOVE));
    yield put(removeAssistantSuccess(userId));
  } else {
    yield put(removeAssistantFail(error));
  }
}

export function* watchRemoveAssistant() {
  yield takeEvery(removeAssistant.getType(), removeAssistantSaga);
}
