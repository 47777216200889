import React from 'react';
import PropTypes from 'prop-types';
import { arc } from 'd3-shape';

function Arc({
  innerRadius, outerRadius, data, className,
}) {
  const path = arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(-Math.PI * 2 * (1 - data))
    .endAngle(-Math.PI * 2)();

  return (
    <path
      d={path}
      className={className}
    />
  );
}

Arc.propTypes = {
  data: PropTypes.number.isRequired,
  outerRadius: PropTypes.number.isRequired,
  innerRadius: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

Arc.defaultProps = {
  data: 0,
};

export default Arc;
