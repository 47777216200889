import { difference } from 'lodash';

import * as form from '../../redux/constants/report.legend.dataPoint.form.constants';
import colors from '../../redux/constants/report.dataPoints.colors.constants';

export const getValuesFromFilter = (filter) => ({
  [form.COLOR_FIELD]: filter.color,
  [form.LINE_WIDTH_FIELD]: filter.lineWidth,
  [form.FILL_OPACITY_FIELD]: filter.fillOpacity,
  [form.AXIS_FIELD]: filter.axis,
  [form.ID_FIELD]: filter.dataPoint.id,
});

export const getAvailableColors = (reservedColors = []) => difference(colors, reservedColors);
