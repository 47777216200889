import { connect } from 'react-redux';
import Select from './Select';
import * as selector from '../../../../redux/selectors/search.selector';

import { initializeDatatronAsOption, DatatronOptionSelect } from '../../../../core/common/datatron';
import { searchDevices } from '../../../../redux/modules/search.devices.module';
import {
  AI_APP_FORM_NAME,
  DEVICES_FILTERS_FIELD,
} from '../../../../redux/constants/aiApp.form.constants';
import { change, WrappedFieldInputProps } from 'redux-form';
import { AppState } from '../../../../redux/store';
import { Todo } from '../../../../../../common/types/common';

const mapStateToProps = (
  state: AppState,
): { options: DatatronOptionSelect[]; isLoading: boolean } => ({
  options: selector.getDatatronSearchResults(state).map(initializeDatatronAsOption),
  isLoading: selector.loading(state),
});

type InputProps = {
  input: WrappedFieldInputProps;
};

const mapDispatchToProps = (dispatch: Function, { input }: InputProps) => ({
  onChange: (option: Todo) => {
    if (input.value?.value !== option.value) {
      dispatch(change(AI_APP_FORM_NAME, DEVICES_FILTERS_FIELD, []));
    }
    input.onChange(option);
    dispatch(searchDevices(option.value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);
