import moment from 'moment';
import { pick } from 'lodash';

import { DATE_FROM, DATE_TO } from '../../../../common/constants';
import { Todo } from '../../../../common/types/common';

export const getAllBatches = (state) => state.batches.list;

export const areBatchesLoading = (state) => state.batches.loading;

export const getNameFilterInputValue = (state) => state.batches.filters.q;

export const getBatchSort = (state) => state.batches.filters.sort;

export const dateFilters = (state) => pick(state.batches.filters, [DATE_FROM, DATE_TO]);

export const areDateFiltersSelected = (state) => {
  const dates = dateFilters(state);
  return !!dates[DATE_FROM] && !!dates[DATE_TO];
};

export const getFilters = (state) => {
  const filters: Todo = {
    ...pick(state.batches.filters, ['q']),
    ...dateFilters(state),
  };

  if (filters[DATE_FROM]) {
    filters[DATE_FROM] = moment(filters[DATE_FROM])
      .toISOString();
  }

  if (filters[DATE_TO]) {
    filters[DATE_TO] = moment(filters[DATE_TO])
      .toISOString();
  }

  delete filters.sort;
  const sort = getBatchSort(state);
  if (sort.option && sort.way) {
    filters[sort.option] = sort.way;
  }

  return filters;
};

export const hasFilters = (state) => {
  const filters = getFilters(state);
  return !(!filters.q && !filters[DATE_FROM] && !filters[DATE_TO]);
};
