import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import commonMessages from '../../../../messages/common.messages';
import { NAME_FIELD } from '../../../../redux/constants/reportsTab.add.form.constants';
import { FormField } from '../../../_tools';
import EditModalFooter from '../../core/footers/EditModalFooter';

export const EditReportsTabModal = ({
  close, editReportsTab, tab, submitting, pristine,
}) => {
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    editReportsTab(tab.id);
  };

  return (
    <BasicModal close={close} modalClass="modal--sm">
      <div className="modal--content">
        <FormattedMessage
          id="report.modal.rename_tab"
          defaultMessage="Rename Tab"
          tagName="h3"
        />

        <form className="form" onSubmit={handleSubmit}>
          <FormField
            label={commonMessages.name.id}
            name={NAME_FIELD}
            autoFocus
          />

          <div className="line" />

          <EditModalFooter
            onSaveClick={handleSubmit}
            onCancelClick={close}
            isSaveButtonDisabled={submitting || pristine}
            isSaveButtonLoading={submitting}
          />
        </form>
      </div>
    </BasicModal>
  );
};

EditReportsTabModal.propTypes = {
  close: PropTypes.func.isRequired,
  editReportsTab: PropTypes.func.isRequired,
  tab: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default EditReportsTabModal;
