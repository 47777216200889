import { createAction } from 'redux-act';
import {
  put, call, takeEvery, all,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { initializeCalendar, getDefaultCalendarEventsDates } from '../../core/common/calendar';
import { fetchCalendarEvents } from './calendars.fetch.calendarEvents.module';
import { resetFilterQueries } from './calendars.query.module';
import { setCalendarBackendAvailability } from './calendars.available.module';

const ACCESS_DENIED = 401;

export const fetchMachineCalendars = createAction(
  'fetch calendars',
  (loadEvents = true) => loadEvents,
);

export const fetchMachineCalendarsSuccess = createAction(
  'fetch calendars - success',
  (calendars) => calendars,
);

export const fetchMachineCalendarsFail = createAction(
  'fetch calendars - fail',
  (error) => error,
);

export const machineCalendarsData = createAction(
  'machine calendars data',
);

export const reducer = {
  [fetchMachineCalendars]: (state) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list: [],
      loading: true,
      loaded: false,
      error: null,
    },
  }),

  [fetchMachineCalendarsSuccess]: (state, list) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      list,
      loading: false,
      loaded: true,
      error: null,
    },
  }),

  [fetchMachineCalendarsFail]: (state, error) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      loaded: false,
      loading: false,
      error,
    },
  }),
};

export function* fetchMachineCalendarsSaga({ payload: loadEvents }) {
  const { response, error } = yield call(api.calendars.getAll);

  if (response) {
    const calendars = response.map(initializeCalendar);

    yield put(fetchMachineCalendarsSuccess(calendars));

    if (loadEvents) {
      const { fromDate, toDate } = yield getDefaultCalendarEventsDates();
      yield all(calendars.map((calendar) => put(fetchCalendarEvents({
        calendar,
        fromDate,
        toDate,
      }))));
    }

    yield put(resetFilterQueries());
  } else {
    yield put(fetchMachineCalendarsFail(error));
  }
}

export function* watchFetchMachineCalendars() {
  yield takeEvery(fetchMachineCalendars.getType(), fetchMachineCalendarsSaga);
}

export function* machineCalendarsDataSaga() {
  const { response, status } = yield call(api.calendars.checkCalendarAvailable);

  const isCalendarAccess = status !== ACCESS_DENIED;
  const isCalendarAvailable = isCalendarAccess && response.available;

  yield put(setCalendarBackendAvailability({
    isCalendarAvailable,
    isCalendarAccess,
  }));

  if (isCalendarAvailable) {
    yield put(fetchMachineCalendars());
  }
}

export function* watchMachineCalendarsData() {
  yield takeEvery(machineCalendarsData.getType(), machineCalendarsDataSaga);
}
