import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { reset as resetForm } from 'redux-form';

import * as eventsApi from '../../core/api/events.js';
import { getCommentText } from '../selectors/dashboard.selector';
import { getFormId } from '../../core/forms/eventComment.form.js';

export const submitComment = createAction(
  'send event comment',
  (eventId) => eventId,
);

export const submitCommentSuccess = createAction(
  'send event comment successfully',
  (eventId) => eventId,
);

export const submitCommentFail = createAction(
  'send event comment fail',
  (eventId, error) => ({ eventId, error }),
);

export const reducer = {
  [submitComment]: (state, eventId) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      comments: {
        ...state.dashboard.comments,
        [eventId]: {
          loaded: false,
          loading: true,
          error: '',
        },
      },
    },
  }),

  [submitCommentSuccess]: (state, eventId) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      comments: {
        ...state.dashboard.comments,
        [eventId]: {
          loaded: true,
          loading: false,
        },
      },
    },
  }),

  [submitCommentFail]: (state, { eventId, error }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      comments: {
        ...state.dashboard.comments,
        [eventId]: {
          loaded: false,
          loading: false,
          error,
        },
      },
    },
  }),
};

export function* submitCommentSaga({ payload: eventId }) {
  const state = yield select();
  const text = yield call(getCommentText, state, eventId);

  const { response, error } = yield call(eventsApi.submitComment, text, eventId);

  if (response) {
    yield put(submitCommentSuccess(eventId));
    yield put(resetForm(getFormId(eventId)));
  } else {
    yield put(submitCommentFail(eventId, error));
  }
}

export function* watchSubmitComment() {
  yield takeEvery(submitComment.getType(), submitCommentSaga);
}
