import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { InputError } from '../../index';

export const Checkbox = ({
  input,
  meta,
  label,
  translateLabel,
  intl,
  disabled,
}) => {
  const onChangeHandler = (option) => {
    input.onChange(option);
  };

  const isError = meta.touched && meta.error;
  const className = classnames('checkbox', 'checkbox-white', { error: isError });

  return (
    <label className={className}>
      <input
        type="checkbox"
        onChange={onChangeHandler}
        checked={!!input.value}
        disabled={disabled}
      />
      {' '}
      <span>{ translateLabel ? intl.formatMessage(label) : label }</span>
      <div className="error-line">
        <InputError error={meta.error} />
      </div>
    </label>
  );
};

Checkbox.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,

  disabled: PropTypes.bool,
  translateLabel: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Checkbox.defaultProps = {
  translateLabel: false,
  disabled: false,
  label: '',
};

export default injectIntl(Checkbox);
