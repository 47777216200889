import { Todo } from '../../../../common/types/common';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  DEFAULT_SORT_BY,
  DEFAULT_SORT_ORDER,
} from '../../../../common/constants';

interface Device {
  id: number;
  code: string;
  name: string;
}

interface Datatron {
  id: number;
  code: string;
  name: string;
}

export interface AiApp {
  id: string;
  app: string;
  appName: string;
  appVersion: string;
  description: string;
  status: string;
  datatron: Datatron;
  devices: Device[];
  createdAt: string;
  updatedAt: string;
}

interface CycleDetection {
  cycle_analysis_identifier: string;
  data_points: Todo[];
  type_identifier: string;
  candidates: Todo[];
  start_date: string;
  end_date: string;
  cycle_duration_min: number;
  cycle_duration_max: number;
  status: string;
  id: string;
  created_at: string;
  updated_at: string;
  anomaly_models: Todo[];
  anomaly_id_selected: string | null;
}

interface AiAppDetails extends AiApp {
  cycleDetections: CycleDetection[];
}

interface Pagination {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

interface Filters {
  queryString: string;
}

export interface Sort {
  sortBy: string;
  sortOrder: 'ASC' | 'DESC';
}

export interface AiAppsState {
  list: AiApp[];
  pagination: Pagination;
  loading: boolean;
  loaded: boolean;
  error: string | null;
  filters: Filters;
  sort: Sort;
  details: AiAppDetails | null;
  cycleDetectionDetails: CycleDetection | null;
}

export const aiAppsInitialState: AiAppsState = {
  list: [],
  pagination: {
    total: 0,
    page: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT,
    totalPages: 1,
  },
  loading: false,
  loaded: false,
  error: null,
  filters: {
    queryString: '',
  },
  sort: {
    sortBy: DEFAULT_SORT_BY,
    sortOrder: DEFAULT_SORT_ORDER,
  },
  details: null,
  cycleDetectionDetails: null,
};

export const aiAppsTestState: AiAppsState = {
  list: [
    {
      id: '123',
      appName: 'name 123',
      description: 'description 123',
      app: '',
      appVersion: '',
      status: '',
      datatron: {
        id: 0,
        code: 'fsadfas',
        name: 'data-tron-id',
      },
      devices: [],
      createdAt: '',
      updatedAt: '',
    },
  ],
  pagination: {
    total: 1,
    page: 1,
    limit: 10,
    totalPages: 1,
  },
  loading: false,
  loaded: true,
  error: null,
  filters: {
    queryString: 'hello',
  },
  sort: {
    sortBy: DEFAULT_SORT_BY,
    sortOrder: DEFAULT_SORT_ORDER,
  },
  details: null,
  cycleDetectionDetails: null,
};
