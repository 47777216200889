export const events = 'events';
export const kpi = 'kpi';
export const maintenance = 'maintenance';
export const dataPoints = 'dataPoints';
export const datatronStats = 'datatronStats';

export const REPORT_TYPES = [
  events,
  kpi,
  maintenance,
  dataPoints,
  datatronStats,
] as const;

export type ReportType = typeof REPORT_TYPES[number];

export default REPORT_TYPES;
