import { createStore, applyMiddleware } from 'redux';
import { reducer as form } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import { intlReducer } from 'react-intl-redux';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import reducer from './reducer';
import rootSaga from './saga';
import * as states from './state/app.state';
import formPlugins from '../core/forms/plugins';

export const history = typeof document !== 'undefined' && document.location ?
  createBrowserHistory() :
  createMemoryHistory();

const sagaMiddleware = createSagaMiddleware();
let middleware = applyMiddleware(routerMiddleware(history), sagaMiddleware);
if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'production') {
  middleware = require('redux-devtools-extension') // eslint-disable-line
    .composeWithDevTools(middleware);
}

const routerReducer = connectRouter(history);

const combinedReducer = (state, action) => ({
  ...reducer(state, action),
  router: routerReducer(state.router, action),
  form: form.plugin(formPlugins)(state.form, action),
  intl: intlReducer(state.intl, action),
});

const store = createStore(
  combinedReducer,
  {
    ...(process.env.NODE_ENV === 'test' ? states.testState : states.initialState),
    routing: {
      locationBeforeTransitions: null,
    },
  },
  middleware,
);

sagaMiddleware.run(rootSaga);

export default store;
