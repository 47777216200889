type AnomalyItem = {
  id: number;
  event: {
    info: boolean;
    payload: {
      anomaly: boolean;
    };
  };
  loaded: boolean;
  loading: boolean;
  error: null | Error;
};

export type AnomalyState = {
  list: {
    [id: number]: AnomalyItem;
  };
};

export const anomalyInitialState: AnomalyState = {
  list: {},
};

export const anomlayTestState: AnomalyState = {
  list: {
    23456: {
      id: 23456,
      event: {
        info: true,
        payload: {
          anomaly: true,
        },
      },
      loaded: true,
      loading: false,
      error: null,
    },
  },
};
