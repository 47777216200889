import React from 'react';
import PropTypes from 'prop-types';

function Member({ member }) {
  const styles = {
    row: (member.isWorking === true) ? '' : 'offline',
    icon: (member.isWorking === true) ? 'success' : 'alert',
  };

  return (
    <tr className={styles.row}>
      <td className="td-icon">
        {/* TODO Add real data */}
        <span className={`online ${styles.icon}`} style={{ display: 'none' }} />
      </td>
      <td>{ member.name }</td>
      <td>{ member.position }</td>
      <td>{ member.email }</td>
    </tr>
  );
}

Member.propTypes = {
  member: PropTypes.object.isRequired,
};

export default Member;
