import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { getFiltersQuery } from '../selectors/admin.users.selector';

export const adminFetchUsers = createAction(
  'admin: fetch users',
  (page = 1) => page,
);

export const adminFetchUsersSuccess = createAction(
  'admin: fetch users - success',
  (list, pagination) => ({ list, pagination }),
);

export const adminFetchUsersFail = createAction(
  'admin: fetch users - fail',
  (error) => error,
);

export const reducer = {
  [adminFetchUsers]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [adminFetchUsersSuccess]: (state, { list, pagination }) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        pagination,
        list,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [adminFetchUsersFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* adminFetchUsersSaga({ payload: page }) {
  const state = yield select();
  const filters = yield call(getFiltersQuery, state);

  const { response, error } = yield call(api.admin.getUsers, { page, ...filters });

  if (response) {
    yield put(adminFetchUsersSuccess(response.list, response.pagination));
  } else {
    yield put(adminFetchUsersFail(error));
  }
}

export function* watchAdminFetchUsers() {
  yield takeEvery(adminFetchUsers.getType(), adminFetchUsersSaga);
}
