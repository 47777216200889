import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import commonMessages from '../../../messages/common.messages';

export default function LoadMore({ show, onClick }) {
  if (!show) return null;

  return (
    <button
      className="button__maintenance-more"
      onClick={onClick}
    >
      <span className="fa fa-refresh" />
      <FormattedMessage {...commonMessages.loadMore} />
    </button>
  );
}

LoadMore.propTypes = {
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
