import { connect } from 'react-redux';

import DeviceClass from './DeviceClass';
import { openModal } from '../../../../../redux/modules/modals.module';
import { DP_ADD_DEVICE } from '../../../../../redux/constants/modals.constants';

export const mapDispatchToProps = (dispatch) => ({
  choose: (deviceClass) => dispatch(openModal(DP_ADD_DEVICE, { deviceClass })),
});

export default connect(null, mapDispatchToProps)(DeviceClass);
