import React from 'react';
import PropTypes from 'prop-types';
import noDoubleClick from '../../../../_tools/stopPropagation';

function ColumnExpandCollapse({ eventId, expandCollapse }) {
  function handleClick(event) {
    noDoubleClick(event);
    expandCollapse(eventId);
  }

  return (
    <a className="button__toggle" onClick={handleClick}>
      <span className="icon icon-caret-left" />
      <span className="icon icon-caret-down" />
    </a>
  );
}

ColumnExpandCollapse.propTypes = {
  expandCollapse: PropTypes.func.isRequired,
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ColumnExpandCollapse;
