import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import GroupTitle from './group/Title';
import GroupSearch from './group/GroupSearch';
import CalendarsListContainer from './CalendarsList.container';

export function Calendars({
  title,
  titleIcon,
  query,
  searchPlaceholder,
  typeCalendars,
  calendarType,
  count,
  updateQuery,
}) {
  const { formatMessage } = useIntl();
  return (
    <div className="col--2--right">
      <div className="col--in">
        <div className="col--head">
          <GroupTitle
            title={formatMessage(title)}
            count={count}
            icon={titleIcon}
          />
          <GroupSearch
            query={query}
            placeholder={searchPlaceholder}
            calendarType={calendarType}
            updateQuery={updateQuery}
          />
        </div>

        <CalendarsListContainer
          query={query}
          count={count}
          typeCalendars={typeCalendars}
        />
      </div>
    </div>
  );
}

Calendars.propTypes = {
  title: PropTypes.object.isRequired,
  titleIcon: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.object.isRequired,
  typeCalendars: PropTypes.array.isRequired,
  calendarType: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  updateQuery: PropTypes.func.isRequired,

};

export default Calendars;
