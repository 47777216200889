import { Todo } from '../../../../common/types/common';

export interface ProfileState {
  isLocationModalOpen: boolean;
  preSavedLocationPath?: Todo;
  error?: Todo;
  loading: boolean;
  loaded: boolean;
  _update: Todo;
  user: Todo | null;
}

export const profileInitialState: ProfileState = {
  isLocationModalOpen: false,
  loading: false,
  loaded: false,
  _update: {
    loading: false,
    loaded: false,
    error: null,
  },
  user: null,
};

export const profileTestState: ProfileState = {
  isLocationModalOpen: false,
  preSavedLocationPath: null,
  loading: false,
  loaded: false,
  error: null,
  user: {
    id: 1,
    location: {
      id: 2,
      name: 'Moscow',
    },
    locale: 'en',
    roles: [],
  },
  _update: {
    loading: false,
    loaded: false,
    error: null,
  },
};
