import { connect } from 'react-redux';

import TopBarLogoutButton from './TopBarLogoutButton';

export function mapStateToProps() {
  return {
    logoutLink: '/logout',
  };
}

export default connect(mapStateToProps)(TopBarLogoutButton);
