import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CalendarRemoveModal from './CalendarRemoveModal';
import { CP_CALENDAR_REMOVE } from '../../../../redux/constants/modals.constants';
import { closeModal } from '../../../../redux/modules/modals.module';
import { deleteCalendar } from '../../../../redux/modules/calendars.delete.module';
import { getModalPayload, isModalOpen } from '../../../../redux/selectors/modals.selector';
import { canRemoveCalendar } from '../../../../core/common/calendar';

export function mapStateToProps(state) {
  const { calendar } = getModalPayload(state, CP_CALENDAR_REMOVE);
  return {
    isOpen: isModalOpen(state, CP_CALENDAR_REMOVE),
    calendar,
    canRemoveCalendar: canRemoveCalendar(calendar),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    close: () => dispatch(closeModal(CP_CALENDAR_REMOVE)),
    deleteCalendar: bindActionCreators(deleteCalendar, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarRemoveModal);
