import React, { useCallback } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { dataPointsActionNames } from '../../../../messages/dataPoints.action.names';

interface Entry {
  identifier: string;
  action: string;
  uuid: string;
}

export interface TableBodyProps {
  data: Entry[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const renderRows = (data: Entry[], isOpen: boolean, intl: IntlShape) => {
  if (!isOpen) return null;
  const entries = data.map((entry, index) => (
    <tr className='bulk-operation-summary-table-row' key={`${entry.uuid}-${index}`}>
      <td>{intl.formatMessage(dataPointsActionNames[entry.action])}</td>
      <td>{entry.identifier}</td>
      <td>{entry.uuid}</td>
      <td></td>
    </tr>
  ));
  return <>{entries}</>;
};

/**
 * TableBody renders a table body with expandable rows based on `isOpen` state.
 * `toggle` is used to expand or collapse table rows.
 */

export const TableBody: React.FC<TableBodyProps> = ({ data, isOpen, setIsOpen }) => {
  const toggleButtonClassName: string = `icon ${isOpen ? 'icon-caret-down' : 'icon-caret-left'}`;
  const intl = useIntl();

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <>
      <tr className='bulk-operation-summary-table-row'>
        <td>
          <FormattedMessage id='common.core.actions' />
        </td>
        <td>
          <FormattedMessage id='dataPoint.config.identifier' />
        </td>
        <td>
          <FormattedMessage id='dataPoint.config.uuid' />
        </td>
        <td>
          <span id='my-toggle-button' className='button__toggle' onClick={toggle}>
            <i className={toggleButtonClassName} />
          </span>
        </td>
      </tr>
      {renderRows(data, isOpen, intl)}
    </>
  );
};
