import { connect } from 'react-redux';

import EmptyPlaceholder from './EmptyPlaceholder';
import { measurementsFiltersApplied } from '../../../../redux/selectors/experimentsPage.selector';

export const mapStateToProps = (state) => ({
  hasFiltersApplied: measurementsFiltersApplied(state),
});

export default connect(mapStateToProps)(EmptyPlaceholder);
