import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3-selection';
import { axisLeft } from 'd3-axis';
import { round } from 'lodash';
import classnames from 'classnames';

export const formatPercent = (point) => {
  const percent = round((round(point, 2) * 100));
  if (percent % 100 !== 0) return null;
  if (percent > 300 || percent <= 0) return null;
  return `${String(percent)}%`;
};

export const formatNumeric = (point) => {
  if (Number.isInteger(point) && point > 0) return point;
  return null;
};

export class AxisLeft extends Component {
  componentDidMount() {
    this.renderAxis(this.props);
  }

  shouldComponentUpdate(props) {
    this.renderAxis(props);
    return false;
  }

  renderAxis(props) {
    const { scale, width, isPercent } = props;
    const axis = axisLeft(scale)
      .tickPadding(-1 * width)
      .tickFormat(isPercent ? formatPercent : formatNumeric);

    select(this.ticks)
      .call(axis);
  }

  render() {
    const { isPercent } = this.props;
    const className = classnames({ percentage: isPercent });

    return (
      <g className={className}>
        <g ref={(r) => (this.ticks = r)} />
      </g>
    );
  }
}

AxisLeft.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  scale: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  isPercent: PropTypes.bool.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

AxisLeft.defaultProps = {
  isPercent: true,
};

export default AxisLeft;
