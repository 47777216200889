import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { getMeasurementsFilters } from '../selectors/experimentsPage.selector';

export const fetchMeasurements = createAction(
  'fetch measurements',
  (page = 1) => page,
);

export const fetchMeasurementsSuccess = createAction(
  'fetch measurements - success',
  (list, pagination) => ({ list, pagination }),
);

export const fetchMeasurementsFail = createAction(
  'fetch measurements - fail',
  (error) => error,
);

export const reducer = {
  [fetchMeasurements]: (state) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      measurements: {
        ...state.experimentsPage.measurements,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchMeasurementsSuccess]: (state, { list, pagination }) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      measurements: {
        ...state.experimentsPage.measurements,
        pagination,
        list,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [fetchMeasurementsFail]: (state, error) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      measurements: {
        ...state.experimentsPage.measurements,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchMeasurementsSaga({ payload: page }) {
  const state = yield select();
  const filters = yield call(getMeasurementsFilters, state);

  const { response, error } = yield call(api.experiments.getMeasurements, { page, ...filters });

  if (response) {
    yield put(fetchMeasurementsSuccess(response.list, response.pagination));
  } else {
    yield put(fetchMeasurementsFail(error));
  }
}

export function* watchFetchMeasurements() {
  yield takeEvery(fetchMeasurements.getType(), fetchMeasurementsSaga);
}
