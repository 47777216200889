import { connect } from 'react-redux';

import PathButtonContainer from '../../../_tools/pathButton/PathButton.container';
import { getPreSavedPathNames } from '../../../../redux/selectors/pathButton.selector';
import { MACHINE_FORM_PAGE } from '../../../../redux/constants/pathButton.constants';

export const mapStateToProps = (state) => ({
  tempLocationPath: getPreSavedPathNames(state, MACHINE_FORM_PAGE),
});

export default connect(mapStateToProps)(PathButtonContainer);
