import React from 'react';

export interface TableRow {
  id: string;
  [key: string]: unknown;
}

interface TableProps {
  headers: string[];
  rows: TableRow[];
  renderActions?: (row: TableRow) => React.ReactNode;
  selectedId?: string;
  hasStatus?: boolean;
  tableBodyClass?: string;
}

const formatHeader = (header: string): string =>
  header
    .split(/(?=[A-Z])|_/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

const getRowClassName = (selectedId: string | undefined, row: TableRow) =>
  selectedId && selectedId === row.id ? 'selected' : '';

const getCellClassName = (hasStatus: boolean, header: string, row: TableRow) =>
  hasStatus && header === 'status' ? `status ${row[header]?.toString().toLowerCase()}` : '';

const Table: React.FC<TableProps> = ({
  headers,
  rows,
  renderActions,
  selectedId,
  hasStatus = false,
  tableBodyClass = 'overflow-y',
}) => (
  <div className='table-wrapper ai-app-no-pagination-display-table'>
    <table className='min-w-full bg-white border border-gray-300'>
      <thead>
        <tr className='bg-gray-100'>
          {headers.map((header, index) => (
            <th
              key={index}
              className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
            >
              {formatHeader(header)}
            </th>
          ))}
          {renderActions && (
            <th className='px-6 py-3 td-edit-fixed-action text-left text-xs font-medium text-gray-500 uppercase tracking-wider' />
          )}
        </tr>
      </thead>
      <tbody className={`bg-white divide-y divide-gray-300 ${tableBodyClass}`}>
        {rows.map((row, rowIndex) => (
          <tr key={`${row.id}-${rowIndex}`} className={getRowClassName(selectedId, row)}>
            {headers.map((header, index) => (
              <td
                key={`${row.id}-${index}`}
                className={`px-6 py-4 whitespace-nowrap ${getCellClassName(hasStatus, header, row)}`}
              >
                {row[header]}
              </td>
            ))}
            {renderActions && (
              <td className='td-edit td-edit-fixed-action'>{renderActions(row)}</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default Table;
