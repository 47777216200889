import React from 'react';
import PropTypes from 'prop-types';

export const ActionButtons = ({
  canEditTools,
  openEditModal,
  openDetailsModal,
}) => (
  <td className="td-edit td-edit-fixed">
    <div className="td-buttons">
      <button className="button button__xs light-grey-white" onClick={openDetailsModal}>
        <i className="icon-eye" />
      </button>
      { canEditTools && (
        <button className="button button__xs light-grey-white" onClick={openEditModal}>
          <i className="icon-edit" />
        </button>
      ) }
    </div>
  </td>
);

ActionButtons.propTypes = {
  canEditTools: PropTypes.bool.isRequired,
  openEditModal: PropTypes.func.isRequired,
  openDetailsModal: PropTypes.func.isRequired,
};

export default ActionButtons;
