import { createAction } from 'redux-act';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { getAIAppCycleDetectionFormFields } from '../selectors/aiApp.form.selector';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import * as api from '../../core/api';
import * as form from '../constants/aiApp.cycleDetection.form.constants';
import { closeModal } from './modals.module';
import { CYCLE_DETECTION_ADD } from '../constants/modals.constants';
import { fetchAiAppDetails } from './aiApps.details.fetch.module';
import { getAiAppsDetailsId } from '../selectors/aiApps.selector';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';
import { NOTIFICATION_EVENT } from '../../core/notifications';

export const createAIAppCycleDetection = createAction('AiApp: create cycle detection');

export const createAIAppCycleDetectionSuccess = createAction(
  'AiApp: create cycle detection - success',
  (item) => item,
);

export const createAIAppCycleDetectionFail = createAction(
  'AiApp: create cycle detection - fail',
  (error) => error,
);

export function* touchAllFieldsSaga() {
  yield put(touch(form.AI_APP_CYCLE_DETECTION_FORM, ...form.AI_APP_CYCLE_DETECTION_PROPS));
}

export function* handleErrors(errors) {
  yield put(createAIAppCycleDetectionFail(errors));
  yield put(stopSubmit(form.AI_APP_CYCLE_DETECTION_FORM, errors));
}

export function* createAiAppsSaga() {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const aiAppId = yield call(getAiAppsDetailsId, state);
  const fields = yield call(getAIAppCycleDetectionFormFields, state);
  yield put(startSubmit(form.AI_APP_CYCLE_DETECTION_FORM));

  const { response, error } = yield call(api.aiApps.createCycleDetection, aiAppId, fields);

  if (response) {
    yield put(createAIAppCycleDetectionSuccess(response));
    yield put(fetchAiAppDetails(aiAppId));
    yield put(stopSubmit(form.AI_APP_CYCLE_DETECTION_FORM));
    yield put(closeModal(CYCLE_DETECTION_ADD));
    yield put(
      sendNotification(
        notificationMessage.item_created_title,
        {
          ...notificationMessage.item_created_description,
          values: {
            itemName: 'Cycle Detection',
          },
        },
        NOTIFICATION_EVENT,
      ),
    );
  } else {
    yield handleErrors(error);
  }
}

export function* watchCreateAIAppCycleDetection() {
  yield takeEvery(createAIAppCycleDetection.getType(), createAiAppsSaga);
}
