import { connect } from 'react-redux';

import CustomSelectContainer from '../../../../_tools/formFields/select/CustomSelect.container';
import * as activityTypes from '../../../../../redux/constants/tools.activityTypes.constants';
import messages from '../../../../../messages/tool.newActivity.messages';

export const mapStateToProps = (state, { isToolMounted, isToolAssigned }) => {
  let options = activityTypes.FOR_UNMOUNTED_TOOL;

  if (isToolMounted) {
    options = activityTypes.FOR_MOUNTED_TOOL;
  } else if (isToolAssigned) {
    options = activityTypes.FOR_ASSIGNED_TOOL;
  }

  return {
    translateOptionsLabels: true,
    options: options.map((type) => ({
      label: messages[type],
      value: type,
    })),
  };
};

export default connect(mapStateToProps)(CustomSelectContainer);
