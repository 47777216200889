import {
  find, filter, isEmpty, sortBy, reverse, cloneDeep,
} from 'lodash';
import * as entryTypes from '../constants/configRepoExplorer.entry.types.constants';
import { SORT_BY_LABEL } from '../../../../common/constants/configRepoExplorer';
import { SORT_WAY_DESC } from '../../../../common/constants';

export const getEquipments = (state) => state.configRepoExplorer[entryTypes.EQUIPMENT].list;
export const getSelectedEquipment = (state) => {
  const selected = state.configRepoExplorer[entryTypes.EQUIPMENT].selected;
  if (!selected) return null;
  return find(
    getEquipments(state),
    { id: selected },
  );
};

export const getDatatrons = (state) => state.configRepoExplorer[entryTypes.DATATRONS].list;
export const areDatatronsLoaded = (state) => state.configRepoExplorer[entryTypes.DATATRONS].loaded;
export const areDatatronsLoading = (state) => state.configRepoExplorer[entryTypes.DATATRONS].loading;
export const getSelectedDatatronId = (state) => state.configRepoExplorer[entryTypes.DATATRONS].selected;
export const getSelectedDatatron = (state) => {
  const selected = state.configRepoExplorer[entryTypes.DATATRONS].selected;
  if (!selected) return null;
  return find(
    getDatatrons(state),
    { id: selected },
  );
};

export const getDevices = (state) => state.configRepoExplorer[entryTypes.DEVICES].list;
export const areDevicesLoaded = (state) => state.configRepoExplorer[entryTypes.DEVICES].loaded;
export const areDevicesLoading = (state) => state.configRepoExplorer[entryTypes.DEVICES].loading;
export const getSelectedDeviceId = (state) => state.configRepoExplorer[entryTypes.DEVICES].selected;
export const getSelectedDevice = (state) => {
  const selected = state.configRepoExplorer[entryTypes.DEVICES].selected;
  if (!selected) return null;
  return find(
    getDevices(state),
    { id: selected },
  );
};

export const getDataPointsFilters = (state) => state.configRepoExplorer[entryTypes.DATA_POINTS].filters;
export const getDataPoints = (state) => {
  let list = state.configRepoExplorer[entryTypes.DATA_POINTS].list;
  const filters = getDataPointsFilters(state);

  if (!isEmpty(filters.q)) {
    const query = filters.q.toLowerCase();
    list = list.filter((item) => item.label.toLowerCase().indexOf(query) >= 0);
  }

  if (filters.sort && filters.sort.option && filters.sort.way) {
    switch (filters.sort.option) {
      case SORT_BY_LABEL: {
        list = sortBy(list, (item) => item.label);
        if (filters.sort.way === SORT_WAY_DESC) {
          list = reverse(cloneDeep(list));
        }
        break;
      }
      default: break;
    }
  }

  return list;
};
export const areDataPointsLoaded = (state) => state.configRepoExplorer[entryTypes.DATA_POINTS].loaded;
export const areDataPointsLoading = (state) => state.configRepoExplorer[entryTypes.DATA_POINTS].loading;
export const getSelectedDataPointIds = (state) => state.configRepoExplorer[entryTypes.DATA_POINTS].selected || [];
export const getSelectedDataPoints = (state) => {
  const selected = state.configRepoExplorer[entryTypes.DATA_POINTS].selected || [];
  if (!selected || selected.length === 0) return [];
  return filter(
    getDataPoints(state),
    (item) => selected.indexOf(item.id) >= 0,
  );
};
export const getDataPointsWithSelectedFlag = (state, filterSelected = false) => {
  const allDataPoints = getDataPoints(state);
  const selectedIds = state.configRepoExplorer[entryTypes.DATA_POINTS].selected || [];
  const disabledIds = state.configRepoExplorer[entryTypes.DATA_POINTS].disabled || [];

  const list = allDataPoints.map((item) => ({
    ...item,
    isSelected: selectedIds.indexOf(item.id) >= 0,
    isDisabled: disabledIds.indexOf(item.id) >= 0,
  }));

  if (filterSelected) {
    return list.filter((item) => !item.isSelected);
  }

  return list;
};
export const allDataPointsSelected = (state) => {
  const allDataPoints = getDataPoints(state).filter((item) => !item.subscription);
  const selectedIds = state.configRepoExplorer[entryTypes.DATA_POINTS].selected || [];
  return selectedIds.length >= allDataPoints.length;
};

export const getMachines = (state) => state.configRepoExplorer[entryTypes.MACHINES].list;
export const areMachinesLoaded = (state) => state.configRepoExplorer[entryTypes.MACHINES].loaded;
export const areMachinesLoading = (state) => state.configRepoExplorer[entryTypes.MACHINES].loading;
export const getSelectedMachineId = (state) => state.configRepoExplorer[entryTypes.MACHINES].selected;
export const getSelectedMachine = (state) => {
  const selected = getSelectedMachineId(state);
  if (!selected) return null;
  return find(
    getMachines(state),
    { id: selected },
  );
};

export const loading = (state) => areDatatronsLoading(state)
  || areDevicesLoading(state)
  || areDataPointsLoading(state)
  || areMachinesLoading(state);

export const loaded = (state) => areDatatronsLoaded(state)
  || areDevicesLoaded(state)
  || areDataPointsLoaded(state)
  || areMachinesLoaded(state);

export const getActiveView = (state) => state.configRepoExplorer.view || entryTypes.DATATRONS;

export const getLabelFilterInputValue = (state) => state.configRepoExplorer[entryTypes.DATA_POINTS].filters.q;
