import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { CLONE_CALENDAR_FORM_NAME } from '../../../../redux/constants/calendars.clone.form.constants';
import CalendarCloneModalForm from './CalendarCloneModalForm';
import { cloneCalendar } from '../../../../redux/modules/calendars.clone.module';

const form = {
  form: CLONE_CALENDAR_FORM_NAME,
  destroyOnUnmount: true,
};

export const mapDispatchToProps = {
  cloneCalendar,
};

export default connect(null, mapDispatchToProps)(reduxForm(form)(CalendarCloneModalForm));
