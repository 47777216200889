import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Element as ScrollElement } from 'react-scroll';

import {
  oldPasswordField,
  newPasswordField,
  confirmNewPasswordField,
} from '../../../../../core/forms/profile.form.js';
import Tooltip from '../../../../_tools/tooltip/Tooltip';
import FormField from '../../../../_tools/formField/FormField';

export class ChangePassword extends Component {
  state = { passwordsVisible: false };

  showPasswords = () => this.setState({ passwordsVisible: true });

  hidePasswords = () => this.setState({ passwordsVisible: false });

  render = () => {
    const { passwordsVisible } = this.state;

    return (
      <div className="form-block">

        <div className="profile--top">
          <div className="h1">
            <FormattedMessage
              id="profile.changePassword"
              defaultMessage="Change Password"
            />
            <Tooltip>
              <FormattedMessage
                id="new_password.password_has_to_be_longer_than"
                tagName="span"
                defaultMessage="Password has to be longer than"
              />
              {' '}
              <FormattedMessage
                id="new_password.information_characters"
                tagName="strong"
                defaultMessage="9 characters"
              />
              {' '}
              <FormattedMessage
                id="new_password.and_include_a"
                tagName="span"
                defaultMessage="and include a"
              />
              {' '}
              <FormattedMessage
                id="new_password.special_character_and_a_number"
                tagName="strong"
                defaultMessage="special character and a number"
              />
            </Tooltip>
          </div>
        </div>

        <ScrollElement name={oldPasswordField}>
          <FormField
            label="profile.oldPassword"
            name={oldPasswordField}
            type="password"
          />
        </ScrollElement>

        <div className="profile--row--wrap">
          <div className="profile--row">
            <div className="profile--l">
              <ScrollElement name={newPasswordField}>
                <FormField
                  label="profile.newPassword"
                  name={newPasswordField}
                  type={passwordsVisible ? 'text' : 'password'}
                  showPassword={this.showPasswords}
                  hidePassword={this.hidePasswords}
                  pressed={passwordsVisible}
                />
              </ScrollElement>
            </div>
            <div className="profile--r">
              <ScrollElement name={confirmNewPasswordField}>
                <FormField
                  label="profile.newPassword"
                  name={confirmNewPasswordField}
                  type={passwordsVisible ? 'text' : 'password'}
                />
              </ScrollElement>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default ChangePassword;
