import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { RouteComponentProps } from 'react-router';
import BasicModal from '../../../../modals/core/BasicModal';
import { getAdminEditLocationLink, getAdminLocationsLink } from '../../../../index/routes';
import commonCoreMessages from '../../../../../messages/common.core.messages';
import adminPageMessages from '../../../../../messages/adminPage.messages';
import Form from './form/LocationForm.container';
import * as routeHandlers from '../../../../index/routeHandlers';
import { Todo } from '../../../../../../../common/types/common';

interface Props extends RouteComponentProps {
  loaded: boolean;
  initialValues?: Todo;
  locationId: string;
  formBodyProps?: Todo;
  adminCreateLocation: (...args: Todo[]) => Todo;
  adminUpdateLocation: (...args: Todo[]) => Todo;
  formType: 'add' | 'edit' | 'view';
}

const useTitleAndConfirm = (
  formType: 'add' | 'edit' | 'view',
  adminCreateLocation: Todo,
  adminUpdateLocation: Todo,
  router: Todo,
  locationId: Todo,
) => {
  const { formatMessage } = useIntl();
  const object = formatMessage(commonCoreMessages.location);
  switch (formType) {
    case 'add':
      return {
        modalTitle: formatMessage(adminPageMessages.addObject, { object }),
        confirmForm: adminCreateLocation,
      };
    case 'edit':
      return {
        modalTitle: formatMessage(adminPageMessages.editObject, { object }),
        confirmForm: adminUpdateLocation,
      };
    case 'view':
      return {
        modalTitle: formatMessage(adminPageMessages.viewObject, { object }),
        confirmForm: () => router.push(getAdminEditLocationLink(locationId)),
      };
    default: {
      throw Error(`Invalid formType=${formType}!`);
    }
  }
};

export const LocationModule: React.FC<Props> = ({
  loaded,
  formType,
  initialValues = {},
  locationId,
  formBodyProps = {},
  adminCreateLocation,
  adminUpdateLocation,
  match,
  history,
}, context) => {
  useEffect(() => {
    if (formType === 'edit' || formType === 'view') routeHandlers.onAdminLocationEnter(match);
    return () => {
      if (formType === 'add') routeHandlers.onAdminAddLocationLeave();
      if (formType === 'edit' || formType === 'view') routeHandlers.onAdminLocationLeave();
    };
  }, [formType, match]);

  const cancel = () => history.push(getAdminLocationsLink());
  const { modalTitle, confirmForm } = useTitleAndConfirm(
    formType,
    adminCreateLocation,
    adminUpdateLocation,
    context.router,
    locationId,
  );

  if (!loaded) return null;

  return (
    <BasicModal close={cancel} modalClass="modal--sm">
      <div className="modal-overflow">
        <div className="modal--content">
          <h3>{modalTitle}</h3>
          <Form
            cancel={cancel}
            isAddForm={formType === 'add'}
            isEditForm={formType === 'edit'}
            isViewForm={formType === 'view'}
            initialValues={initialValues}
            confirmForm={confirmForm}
            formBodyProps={formBodyProps}
          />
        </div>
      </div>
    </BasicModal>
  );
};

export default LocationModule;
