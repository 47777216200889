import React from 'react';
import PropTypes from 'prop-types';

import CardTemplate from '../card/Card.template';

function Cards({ cards, type }) {
  return (
    <div
      className="maintenance--items"
      id={type}
    >
      {
        cards.map((card) => (
          <CardTemplate
            card={card}
            key={card.id}
            type={type}
          />
        ))
      }
    </div>
  );
}

Cards.propTypes = {
  cards: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default Cards;
