import React from 'react';
import PropTypes from 'prop-types';

const ShowHidePassword = ({ showPassword, hidePassword }) => (
  <div className="input-group-button">
    <button
      className="button"
      id="showPassword"
      type="button"
      onMouseDown={showPassword}
      onMouseUp={hidePassword}
      onMouseLeave={hidePassword}
    >
      <span className="icon-watch" />
    </button>
  </div>
);

ShowHidePassword.propTypes = {
  showPassword: PropTypes.func.isRequired,
  hidePassword: PropTypes.func.isRequired,
};

export default ShowHidePassword;
