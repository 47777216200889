export type NewDatatronState = {
  creating: boolean;
  error: null | Error;
};

export const newDatatronInitialState: NewDatatronState = {
  creating: false,
  error: null,
};

export const newDatatronTestState: NewDatatronState = {
  creating: false,
  error: null,
};
