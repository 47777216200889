import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../_tools';
import commonMessages from '../../../../../messages/common.messages';

const Footer = ({
  isAddForm,
  isEditForm,
  handleCancel,
  handleSubmit,
  loading,
  disabled,
}) => {
  let submitMessage = null;
  if (isAddForm) {
    submitMessage = commonMessages.add;
  } else if (isEditForm) {
    submitMessage = commonMessages.save;
  }
  return (
    <div className="modal-buttons">
      <Button
        type="button"
        className="button__cancel"
        onClick={handleCancel}
      >
        <FormattedMessage
          id="common.cancel"
          defaultMessage="Cancel"
        />
      </Button>
      <Button
        type="submit"
        className="button__save"
        disabled={disabled}
        showLoader={loading}
        onClick={handleSubmit}
      >
        { submitMessage && <FormattedMessage {...submitMessage} /> }
      </Button>
    </div>
  );
};

Footer.propTypes = {
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isAddForm: PropTypes.bool,
  isEditForm: PropTypes.bool,
};

Footer.defaultProps = {
  isAddForm: false,
  isEditForm: false,
};

export default Footer;
