import { connect } from 'react-redux';

import PageSpinner from './PageSpinner.tsx';

export function mapStateToProps(state) {
  return {
    shown: state.page.isSpinnerShown,
  };
}

export default connect(mapStateToProps)(PageSpinner);
