import React, { useState } from 'react';

import IncludeProcessingsCheckbox from './options/IncludeProcessingsCheckbox';
import ExportButton from './ExportButton';
import Download from './Download';
import { XLSX } from '../../../../../common/constants/fileFormats';
import { Todo, TodoFunction } from '../../../../../common/types/common';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';
import { selectIsExportFileEmpty } from '../../../redux/selectors/export.widget.selector';
import { useDispatch, useSelector } from 'react-redux';
import { verifyDownload } from '../../../redux/modules/export.widget.verification.module';

interface Props {
  type: string
  askForProcessings?: boolean
  askForFileFormat?: boolean
  dateFiltersSelected: boolean
  downloadButtonMessage: string
  generateExportUrl: TodoFunction
  downloadProps: Todo
}

export const Export: React.FC<Props> = ({
  type,
  askForProcessings = false,
  askForFileFormat = true,
  dateFiltersSelected,
  downloadButtonMessage,
  generateExportUrl,
  downloadProps = {},
}) => {

  const isExportFileEmpty = useSelector(selectIsExportFileEmpty);
  const dispatch = useDispatch();

  const [isOpen, isOpenSet] = useState(false);
  const [fileFormat, fileFormatSet] = useState(XLSX);
  const [includeProcessingsState, includeProcessingsStateSet] = useState(false);

  const verifyDownloadLocal = ({ includeProcessings }) => {
    dispatch(verifyDownload( { type, filters: { ...downloadProps, includeProcessings } }));
  };

  const toggle = () => {
    if (!isOpen && dateFiltersSelected) {
      verifyDownloadLocal({ includeProcessings: includeProcessingsState });
    }
    isOpenSet(!isOpen);
  };

  const close = () => {
    isOpenSet(false);
  };

  const toggleProcessingsCheckbox = () => {
    if (isOpen) {
      verifyDownloadLocal({ includeProcessings: !includeProcessingsState });
    }
    includeProcessingsStateSet(!includeProcessingsState);
  };

  const handleFileFormatChange = (newFileFormat) => {
    fileFormatSet(newFileFormat);
  };

  const clickRef = useClickOutside(close);

  return (
    <div className="download--wrap" ref={clickRef}>
      <div className="datepicker-block">
        <ExportButton onClick={toggle}/>
        {isOpen && (
          <div className="download-dropdowns">
            {askForProcessings && (
              <IncludeProcessingsCheckbox
                value={includeProcessingsState}
                onChange={toggleProcessingsCheckbox}
              />
            )}
            <Download
              afterDownloadButtonClick={close}
              fileFormat={fileFormat}
              onFileFormatChange={handleFileFormatChange}
              askForFileFormat={askForFileFormat}
              downloadButtonMessage={downloadButtonMessage}
              generateExportUrl={generateExportUrl}
              downloadProps={{ ...downloadProps, includeProcessings: includeProcessingsState }}
              dateFiltersSelected={dateFiltersSelected}
              close={close}
              isExportFileEmpty={isExportFileEmpty}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Export;
