import { faker } from '@faker-js/faker';
import { apiRequest } from './_tools';

const MOCK_USERS_COUNT = 100;

const mockUsers = Array.from({ length: MOCK_USERS_COUNT })
  .map((_, i) => {
    const firstName = faker.person.firstName();
    const lastName = faker.person.firstName();

    const user = {
      id: i + 1234,
      fullName: `${firstName} ${lastName}`,
      userName: `${firstName[0]}${lastName[0]}`,
    };

    user.label = [user.fullName, user.userName].join(' ');

    return user;
  });

const normalize = (query) => query.toLowerCase().split(/\s+/);
const inQuery = (normalizedQuery) => (str) => normalizedQuery.reduce((result, token) => result || str.includes(token), false);
const satisfies = (includesInQuery) => (user) => includesInQuery(user.label.toLowerCase());

export function search(query) {
  const success = true;

  return new Promise((resolve, reject) => {
    if (!success) {
      return reject({
        error: 'Cannot archive event',
        status: 500,
        '@@API': true,
      });
    }

    const normalizedQuery = normalize(query);
    const includesInQuery = inQuery(normalizedQuery);
    const satisfiesToQuery = satisfies(includesInQuery);

    return resolve({ response: mockUsers.filter(satisfiesToQuery).slice(0, 10) });
  });
}

export function searchByOrganization(organizationId, query) {
  return apiRequest(`/api/organizations/${organizationId}/users?q=${query}`);
}

export function getUsersList() {
  return apiRequest('/api/users?returnAll=true');
}
