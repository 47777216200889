import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EventEditModal from './EventEditModal';
import { CP_EVENT_EDIT } from '../../../../../redux/constants/modals.constants';
import { closeModal } from '../../../../../redux/modules/modals.module';
import { isModalOpen, getModalPayload } from '../../../../../redux/selectors/modals.selector';
import { updateCalendarEvent } from '../../../../../redux/modules/calendars.update.event.module';

export const mapStateToProps = (state) => ({
  isOpen: isModalOpen(state, CP_EVENT_EDIT),
  ...getModalPayload(state, CP_EVENT_EDIT),
});

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(CP_EVENT_EDIT)),
  updateCalendarEvent: bindActionCreators(updateCalendarEvent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventEditModal);
