import React, { useMemo, useState } from 'react';
import PaginationBar from '../../paginationBar/PaginationBar';
import { TableBody } from './TableBody';
import {
  EXCEL_BULK_SUMMARY_PAGE_SIZE,
  EXCEL_BULK_SUMMARY_DEFAULT_PAGE,
} from '../../../../../../common/constants/datapoints.excel';
import { EntryDisplayBulk } from './BulkOperationSummary';
import { FormattedMessage } from 'react-intl';

export type DisplayTableProps = Readonly<{
  result: EntryDisplayBulk[];
  responseType: 'completed' | 'failed';
}>;

/**
 * Custom hook for pagination.
 * @param {EntryDisplayBulk[]} result - The array of entries to paginate.
 * @param {number} pageCount - The current page number.
 * @returns {Object} An object containing:
 *   - filteredData: The entries for the current page.
 *   - maxPageCount: The maximum number of pages based on the total number of entries.
 */

const usePagination = (result: EntryDisplayBulk[], pageCount: number) => {
  const entriesPerPage = EXCEL_BULK_SUMMARY_PAGE_SIZE;
  const filteredData: EntryDisplayBulk[] = useMemo(
    () => result.slice((pageCount - 1) * entriesPerPage, pageCount * entriesPerPage),
    [result, pageCount, entriesPerPage],
  );
  const maxPageCount: number = Math.ceil(result.length / entriesPerPage);
  return { filteredData, maxPageCount };
};

export const DisplayTable: React.FC<DisplayTableProps> = ({ result, responseType }) => {
  const [pageCount, setPageCount] = useState<number>(EXCEL_BULK_SUMMARY_DEFAULT_PAGE);
  const [isPaginationBarOpen, setPaginationBarOpen] = useState<boolean>(false);
  const onPageChange = (page: number) => {
    setPageCount(page);
  };
  const { filteredData, maxPageCount } = usePagination(result, pageCount);

  return (
    <>
      <div
        className={`label ${responseType === 'completed' ? 'success' : 'alert'} margin-vertical-10`}
      >
        <FormattedMessage id={`datatron.modals.status.${responseType}`} />
        <span className='label-num'>{result.length}</span>
      </div>
      <table>
        <tbody>
          <TableBody
            data={filteredData}
            isOpen={isPaginationBarOpen}
            setIsOpen={setPaginationBarOpen}
          />
        </tbody>
      </table>
      {isPaginationBarOpen && (
        <PaginationBar
          total_pages={maxPageCount}
          current_page={pageCount}
          choosePage={onPageChange}
        />
      )}
    </>
  );
};
