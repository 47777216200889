import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import commonMessages from '../../../../messages/common.messages';

const Footer = ({ onCancelClick, onEditClick, isEditButtonDisabled }) => (
  <div className="modal-buttons">
    <button type="button" className="button__cancel" onClick={onCancelClick}>
      <FormattedMessage {...commonMessages.cancel} />
    </button>
    <button type="submit" className="button__save" onClick={onEditClick} disabled={isEditButtonDisabled}>
      <FormattedMessage {...commonMessages.edit} />
    </button>
  </div>
);

Footer.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  isEditButtonDisabled: PropTypes.bool,
};

Footer.defaultProps = {
  isEditButtonDisabled: false,
};

export default Footer;
