import PropTypes from 'prop-types';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';
import { INVENTORY_DATATRONS, INVENTORY_MACHINES } from '../../../../common/constants/inventory';
import { Todo } from '../../../../common/types/common';

export const datePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);

export const relativeDatePropType = PropTypes.shape({
  count: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
});

export const dateFilters = PropTypes.shape({
  [DATE_FROM]: datePropType,
  [DATE_TO]: datePropType,
});

export type InventoryItem = {
  id: string
  name: string,
  path: string[],
  [INVENTORY_MACHINES]: Todo[],
  [INVENTORY_DATATRONS]: Todo[],
};

export const measurement = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
});

export const intlMessage = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
});
