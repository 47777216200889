import React from 'react';
import PropTypes from 'prop-types';
import { forEach } from 'lodash';

export const EntriesList = ({
  label,
  list,
}) => {
  const separatedList = [];

  forEach(
    list,
    (item) => {
      separatedList.push(<div key={`span${String(item.id)}`}>{item.name}</div>);
    },
  );

  return (
    <>
      <br />
      <h3>{ label }</h3>
      { separatedList }
    </>
  );
};

EntriesList.propTypes = {
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
};

export default EntriesList;
