import { isUndefined } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DP_IMPORT_ACTION } from '../../../redux/constants/modals.constants';
import { fetchDataPoints } from '../../../redux/modules/datatron.devices.dataPoints.fetch.module';
import { importDevice } from '../../../redux/modules/datatron.devices.import.module';
import { closeModal } from '../../../redux/modules/modals.module';
import {
  selectCommandStateDeviceId,
  selectCommandStateError,
  selectCommandStateIsSubmitting,
  selectCommandStateIsSuccess,
} from '../../../redux/selectors/modal.commandState.selector';
import {
  getModalPayload,
  selectCallbackCommandId,
} from '../../../redux/selectors/modals.selector';
import { AppState } from '../../../redux/state/app.state';
import Modal from '../../_tools/modal/Modal';
import { CommandJournalEntries } from './CommandJournalEntries';

export type ImportState = 'ready' | 'importing' | 'done' | 'error';

const modalPayloadSelector = (state: AppState) =>
  getModalPayload(state, DP_IMPORT_ACTION);

export const ImportModal: React.FC = () => {
  const [importState, importStateSet] = useState<ImportState>('ready');
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const { deviceId } = useSelector(modalPayloadSelector);

  const commandStateDeviceId = useSelector(selectCommandStateDeviceId);
  const commandSuccess = useSelector(selectCommandStateIsSuccess);
  const commandError = useSelector(selectCommandStateError);
  const submitting = useSelector(selectCommandStateIsSubmitting);

  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(closeModal(DP_IMPORT_ACTION));
    dispatch(fetchDataPoints(deviceId, false));
  }, [deviceId, dispatch]);

  const handleSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      timeout.current = setTimeout(importStateSet, 60 * 1000, 'error');
      importStateSet('importing');
      event.preventDefault();
      dispatch(importDevice(deviceId));
    },
    [deviceId, dispatch],
  );

  const commandId = useSelector(selectCallbackCommandId);
  useEffect(() => {
    if (commandId !== null && commandId < 0) {
      importStateSet('error');
    }

    if (
      importState === 'importing' &&
      !submitting &&
      commandStateDeviceId === deviceId
    ) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
      if (commandSuccess) {
        importStateSet('done');
      } else if (commandError) {
        importStateSet('error');
      }
    }
  }, [
    commandError,
    commandId,
    commandStateDeviceId,
    commandSuccess,
    deviceId,
    importState,
    submitting,
  ]);

  const setDone = useCallback(() => importStateSet('done'), []);

  return (
    <Modal isOpen onClose={close} contentLabel="">
      <div className="modal--header" onClick={close}>
        <span className="modal--close">&times;</span>
      </div>
      <div className="modal--content">
        {importState === 'ready' && (
          <FormattedMessage
            defaultMessage="Do you really want to import this Device?"
            id="datatron.modals.import_device.text"
            tagName="h3"
          />
        )}
        {importState !== 'ready' && (
          <div>
            {importState === 'importing' && (
              <FormattedMessage
                defaultMessage="Importing..."
                id="datatron.modals.import_device.waiting"
                tagName="h3"
              />
            )}
            {importState === 'done' && (
              <FormattedMessage
                defaultMessage="Import Completed"
                id="datatron.modals.import_device.done"
                tagName="h3"
              />
            )}
            {importState === 'error' && (
              <FormattedMessage
                defaultMessage="Import Failed"
                id="datatron.modals.import_device.error"
                tagName="h3"
              />
            )}
            {importState === 'error' && (
              <div style={{ color: 'red' }}>
                {!isUndefined(commandError) && <>commandError</>}
              </div>
            )}
          </div>
        )}

        <CommandJournalEntries setDone={setDone} />

        <div className="line" />

        <div className="text-center">
          <button
            type="button"
            className={
              importState !== 'done' ? 'button__cancel' : 'button__save'
            }
            onClick={close}
          >
            {importState === 'ready' && (
              <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
            )}
            {importState !== 'ready' && (
              <FormattedMessage id="common.close" defaultMessage="Close" />
            )}
          </button>
          {importState === 'ready' && (
            <button
              type="submit"
              className="button__save"
              onClick={handleSubmit}
            >
              <FormattedMessage
                defaultMessage="Import"
                id="datatron.modals.archive_device.buttons.import"
              />
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
