import { defineMessages } from 'react-intl';

export default defineMessages({
  already_used: {
    id: 'errors.field.already_used',
    defaultMessage: 'Already used',
  },
  duplicate: {
    id: 'errors.field.duplicate',
    defaultMessage: 'Duplicate',
  },
  invalid_json: {
    id: 'errors.field.invalid_json',
    defaultMessage: 'Invalid JSON',
  },
  required: {
    id: 'errors.field.required',
    defaultMessage: 'Required',
  },
  must_be_int: {
    id: 'errors.field.must_be_int',
    defaultMessage: 'Must be Integer',
  },
  must_be_uuid: {
    id: 'errors.field.must_be_uuid',
    defaultMessage: 'Must be UUID',
  },
  must_be_mac_address: {
    id: 'errors.field.must_be_mac_address',
    defaultMessage: 'Must be MAC Address',
  },
  must_be_string: {
    id: 'errors.field.must_be_string',
    defaultMessage: 'Must be String',
  },
  must_be_bigdecimal: {
    id: 'errors.field.must_be_bigdecimal',
    defaultMessage: 'Must be Decimal',
  },
  must_be_boolean: {
    id: 'errors.field.must_be_boolean',
    defaultMessage: 'Must be Boolean',
  },
  must_be_uri: {
    id: 'errors.field.must_be_uri',
    defaultMessage: 'Must be URI',
  },
  must_be_enumeration: {
    id: 'errors.field.must_be_enumeration',
    defaultMessage: 'Must match enum',
  },
  must_be_ip_address: {
    id: 'errors.field.must_be_ip_address',
    defaultMessage: 'Must be IP address (v4 or v6)',
  },
  invalid: {
    id: 'errors.field.invalid',
    defaultMessage: 'Invalid',
  },
  file_is_too_big: {
    id: 'errors.file_is_too_big',
    defaultMessage: 'File is too big. Max 5mb',
  },
  'tools.validation.label.min_length': {
    id: 'tools.validation.label.min_length',
    defaultMessage: 'Min. 3 symbols',
  },
  'tools.validation.label.max_length': {
    id: 'tools.validation.label.max_length',
    defaultMessage: 'Max. 255 symbols',
  },
  'tools.validation.type.min_length': {
    id: 'tools.validation.type.min_length',
    defaultMessage: 'Min. 3 symbols',
  },
  'tools.validation.type.max_length': {
    id: 'tools.validation.type.max_length',
    defaultMessage: 'Max. 255 symbols',
  },
  'tools.validation.model.min_length': {
    id: 'tools.validation.model.min_length',
    defaultMessage: 'Min. 3 symbols',
  },
  'tools.validation.model.max_length': {
    id: 'tools.validation.model.max_length',
    defaultMessage: 'Max. 255 symbols',
  },
  'tools.validation.comment.max_length': {
    id: 'tools.validation.comment.max_length',
    defaultMessage: 'Max. 255 symbols',
  },
  'experiments.validation.label.min_length': {
    id: 'experiments.validation.name.min_length',
    defaultMessage: 'Min. 3 symbols',
  },
  'experiments.validation.series.min_length': {
    id: 'experiments.validation.series.min_length',
    defaultMessage: 'Min. 3 symbols',
  },
  'experiments.validation.label.max_length': {
    id: 'experiments.validation.name.max_length',
    defaultMessage: 'Max. 255 symbols',
  },
  'experiments.validation.series.max_length': {
    id: 'experiments.validation.series.max_length',
    defaultMessage: 'Max. 255 symbols',
  },
  'experiments.validation.comment.max_length': {
    id: 'experiments.validation.comment.max_length',
    defaultMessage: 'Max. 1024 symbols',
  },
});
