import React, { useEffect } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { onAIAppsEnter } from '../index/routeHandlers';
import AiAppsTable from './aiAppsTable/AiAppsTable';
import { renderRoutes } from 'react-router-config';

interface AIAppsProps extends RouteConfigComponentProps {}

const AIAppsPage: React.FC<AIAppsProps> = ({ match, route }) => {
  useEffect(() => {
    onAIAppsEnter();
  }, []);

  if (!match.isExact) {
    return renderRoutes(route?.routes ?? []);
  }

  return (
    <div>
      <div className='col--1'>
        <div className='subheader'>
          <div className='subheader-in'></div>
        </div>
      </div>
      <div className='col--1 location-page'>
        <div className='subheader-in'>
          <div className='subheader--left' />
          <div className='subheader--right' />
        </div>
        <div className='reports--wrap--all'>
          <div className='tools-wrap'>
            <AiAppsTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIAppsPage;
