import { defineMessages } from 'react-intl';

export default defineMessages({
  error: {
    id: 'maintenance.stream.error',
    defaultMessage: 'Error',
  },
  warning: {
    id: 'maintenance.stream.warning',
    defaultMessage: 'Warning',
  },
  anomaly: {
    id: 'maintenance.stream.anomaly',
    defaultMessage: 'Anomaly',
  },
  info: {
    id: 'maintenance.stream.info',
    defaultMessage: 'Info',
  },
  fatal: {
    id: 'maintenance.stream.fatal',
    defaultMessage: 'Fatal',
  },
});
