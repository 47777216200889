import React from 'react';
import PropTypes from 'prop-types';

export default function EventBody({ message }) {
  return (
    <div className="position-text--center">
      <p>{message}</p>
    </div>
  );
}

EventBody.propTypes = {
  message: PropTypes.string,
};

EventBody.defaultProps = {
  message: '',
};
