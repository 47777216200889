import { createSelector } from 'reselect';
import {
  anomaly as CATEGORY_ANOMALY,
  error as CATEGORY_ERROR,
  fatal as CATEGORY_FATAL,
  info as CATEGORY_INFO,
  maintenanceInbox,
  warning as CATEGORY_WARNING,
} from '../../../../common/enums/eventCategory';
import { COLUMN_IN_PROGRESS, COLUMN_INBOX, COLUMN_PRIORITIZED } from '../constants/column.constants';

export const allEvents = (state) => state.maintenance.events;

export const expanded = (state) => state.maintenance.expanded;

export const getHiddenEvents = (state) => state.maintenance.hiddenEvents;

export const getMovedEvents = (state) => state.maintenance.movedEvents;

export const isLoading = (state) => state.maintenance.loading || state.maintenance.activitiesState.loading;

export const getRevealedEvents = createSelector(
  allEvents,
  getHiddenEvents,
  getMovedEvents,
  (events, hiddenEvents, movedEvents) => events.filter(({ id }) => !hiddenEvents[id] && !movedEvents[id]),
);

export const allActivities = (state) => state.maintenance.activities;

// TODO: change hiding when UI enables to hide activities directly
export const getRevealedActivities = createSelector(
  allActivities,
  getHiddenEvents,
  (activities, hiddenEvents) => activities.filter(({ id }) => !hiddenEvents[id]),
);

export function isFatalEvent(event) {
  return event.category === CATEGORY_FATAL;
}

export function isAnomalyEvent(event) {
  return event.category === CATEGORY_ANOMALY;
}

export function isWarningEvent(event) {
  return event.category === CATEGORY_WARNING;
}

export function isInfoEvent(event) {
  return event.category === CATEGORY_INFO;
}

export function isErrorEvent(event) {
  return event.category === CATEGORY_ERROR;
}

export function isDetailsShown(state, eventId) {
  return !!expanded(state)[eventId];
}

export function isEventArchiving(event) {
  return !!event.archiving;
}

export function isActivityArchiving(activity) {
  return !!activity.archiving;
}

export function getEventIndexById(state, eventId) {
  return state.maintenance.events.findIndex(({ id }) => id === eventId);
}

export function getActivityAndEventIndexesByEventId(state, eventId) {
  let eventIndex = -1;

  const activityIndex = state.maintenance.activities.findIndex(({ events }) => {
    eventIndex = events.findIndex(({ id }) => id === eventId);
    return eventIndex !== -1;
  });

  return [activityIndex, eventIndex];
}

export function getActivityIndexById(state, activityId) {
  return state.maintenance.activities.findIndex(({ id }) => id === activityId);
}

export const getFilters = (column) => (state) => state.maintenance.columns[column];

export const getInboxFilters = getFilters(COLUMN_INBOX);
export const getInboxCategories = (state) => {
  const inboxFilters = getInboxFilters(state);
  return (inboxFilters && inboxFilters.length > 0) ? inboxFilters : maintenanceInbox;
};

export const getPrioritizedFilters = getFilters(COLUMN_PRIORITIZED);
export const getInProgressFilters = getFilters(COLUMN_IN_PROGRESS);

function filterEvents(events, filters) {
  return events.filter((event) => (filters.length ? filters.indexOf(event.category) !== -1 : true));
}

export const getFilteredInboxEvents = createSelector(
  getRevealedEvents,
  getInboxFilters,
  (events, filters) => filterEvents(events, filters).filter((event) => !event.isArchived),
);

function hasRelatedEvent(activity, filters) {
  return filterEvents(activity.events, filters).length > 0;
}

export const getFilteredPrioritizedEvents = createSelector(
  getRevealedActivities,
  getPrioritizedFilters,
  (activities, filters) => activities.filter((activity) => activity.state === COLUMN_PRIORITIZED
    && activity.isArchived === false
    && hasRelatedEvent(activity, filters)), // TODO add pagination
);

export const getFilteredInProgressEvents = createSelector(
  getRevealedActivities,
  getInProgressFilters,
  (activities, filters) => activities.filter((activity) => activity.state === COLUMN_IN_PROGRESS
    && activity.isArchived === false
    && hasRelatedEvent(activity, filters)), // TODO add pagination
);

export function isDragging(state) {
  return state.maintenance.dnd.isDragging;
}

export const loadEventsFrom = (state) => state.maintenance.loadEventsFrom;

export const loadEventsTo = (state) => state.maintenance.loadEventsTo;

export const moreEventsAvailable = (state) => state.maintenance.moreAvailable;

export const inboxEventsAmount = (state) => state.maintenance.counts.events;
