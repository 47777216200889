export const LEFT_AXIS_WIDTH = 32;
export const RIGHT_AXIS_WIDTH = 32;
export const BOTTOM_AXIS_OFFSET = 15;
export const DATA_OFFSET_ABOVE_TOP = 0.05;
export const Y_AXIS_PADDING = 20;
export const MARGIN = {
  top: 0,
  right: RIGHT_AXIS_WIDTH,
  bottom: 0,
  left: LEFT_AXIS_WIDTH,
};
