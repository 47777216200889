import { cloneDeep, find, findIndex, forEach, last } from 'lodash';
import { Todo } from '../../../../common/types/common';

export const modifyLocationsTree = (tree, newLocations, parentId) => {
  const thisLevel: Todo[] = [];

  forEach(
    tree,
    (location) => {
      if (location.id === parentId) {
        thisLevel.push({
          ...location,
          child: newLocations,
        });
      } else if (location.child && location.child.length > 0) {
        thisLevel.push({
          ...location,
          child: modifyLocationsTree(location.child, newLocations, parentId),
        });
      } else {
        thisLevel.push(location);
      }
    },
  );

  return thisLevel;
};

export const addNewLocationsIntoExistingTree = (oldTree, newLocations, parentLocationId = null) => {
  if (!parentLocationId) return cloneDeep(newLocations);

  return modifyLocationsTree(cloneDeep(oldTree), newLocations, parentLocationId);
};

export const flattenLocationTreeIntoPaths = (tree, prefix: Todo[] = []) => {
  const paths: Todo[] = [];

  forEach(
    tree,
    (location) => {
      const currentPath = [...prefix, location];

      paths.push(currentPath);

      if (location.child && location.child.length > 0) {
        forEach(
          flattenLocationTreeIntoPaths(location.child, currentPath),
          (childLocation) => paths.push(childLocation),
        );
      }
    },
  );

  return paths;
};

export const findSelectedPath = (paths) => find(
  paths,
  (path) => last<Todo>(path).selected === true,
);

export const splitTreeIntoPickerSides = (tree) => {
  const paths = flattenLocationTreeIntoPaths(tree);
  const selectedPath = findSelectedPath(paths);

  if (!selectedPath) {
    return {
      leftSide: [],
      rightSide: tree,
    };
  }
  return {
    leftSide: selectedPath,
    rightSide: last<Todo>(selectedPath).child || [],
  };
};

export const updateLocationInTree = (
  tree,
  locationId,
  newData = {},
  otherLocationsNewData = {},
) => cloneDeep(tree)
  .map((location) => {
    if (location.id === locationId) {
      return {
        ...location,
        ...newData,
      };
    }
    if (location.child && location.child.length > 0) {
      return {
        ...location,
        ...otherLocationsNewData,
        child: updateLocationInTree(location.child, locationId, newData, otherLocationsNewData),
      };
    }
    return {
      ...location,
      ...otherLocationsNewData,
    };
  });

export const getParentOfLocationFromPath = (pathArray, locationId) => {
  const locationIndex = findIndex(pathArray, { id: locationId });
  const parentLocationIndex = locationIndex - 1;
  if (parentLocationIndex >= 0) return pathArray[parentLocationIndex];
  return null;
};
