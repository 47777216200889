import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import RemoveReportModal from './RemoveReportModal';
import { removeReport } from '../../../../redux/modules/reports.remove.module.js';
import { closeModal } from '../../../../redux/modules/modals.module';
import { REPORT_REMOVE } from '../../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { REMOVE_REPORT_FORM_NAME } from '../../../../redux/constants/report.remove.form.constants';

export const mapStateToProps = (state) => ({
  ...getModalPayload(state, REPORT_REMOVE),
});

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(REPORT_REMOVE)),
  remove: bindActionCreators(removeReport, dispatch),
});

const form = {
  form: REMOVE_REPORT_FORM_NAME,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(form)(RemoveReportModal));
