import React from 'react';
import { VariableSizeList as WindowList } from 'react-window';

import DataPoint from './dataPoint/DataPoint';
import { Todo, TodoFunction } from '../../../../../../../../../common/types/common';

const getItemSize = () => 165;

interface Props {
  dataPoints: Todo[];
  readOnly: boolean;
  highlightedDataPointIds: Todo[];
  configKeyHashToFieldsMap: Todo;
  openViewDataPointModal: TodoFunction;
  openEditDataPointModal: TodoFunction;
  openArchiveDataPointModal: TodoFunction;
  openEditArrayIndexModal: TodoFunction;
  canUpdate: boolean;
  canDelete: boolean;
  searchQuery?: string;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
}

export const List: React.FC<Props> = ({
  dataPoints,
  readOnly,
  highlightedDataPointIds,
  configKeyHashToFieldsMap,
  openViewDataPointModal,
  openEditDataPointModal,
  openArchiveDataPointModal,
  canUpdate,
  canDelete,
  searchQuery,
  openEditArrayIndexModal,
  ...otherProps
}) => {
  const renderRow = ({ data, index, style }) => {
    const item = data[index];

    return (
      <div className={index % 2 ? 'data-points-line-dark' : 'data-points-line-light'} style={style}>
        <DataPoint
          openEditArrayIndexModal={openEditArrayIndexModal}
          dataPoint={item}
          configFields={configKeyHashToFieldsMap[item.configKeysHash] || []}
          readOnly={readOnly}
          isHighlighted={highlightedDataPointIds.indexOf(item.id) >= 0}
          openViewDataPointModal={openViewDataPointModal}
          openEditDataPointModal={openEditDataPointModal}
          openArchiveDataPointModal={openArchiveDataPointModal}
          canUpdate={canUpdate}
          canDelete={canDelete}
          searchQuery={searchQuery}
          {...otherProps}
        />
      </div>
    );
  };

  return (
    <WindowList
      height={600}
      width={'100%'}
      itemSize={getItemSize}
      itemCount={dataPoints.length}
      itemData={dataPoints}
    >
      {renderRow}
    </WindowList>
  );
};

export default List;
