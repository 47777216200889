import React from 'react';
import { FormattedMessage } from 'react-intl';

import placeholdersMessages from '../../../../../../messages/placeholders.messages';

export const EmptyPlaceholder = () => (
  <div className="nodata">
    <div className="inventory-empty-icon" />
    <FormattedMessage
      {...placeholdersMessages.noDataToDisplay}
      tagName="div"
    />
  </div>
);

export default EmptyPlaceholder;
