import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const CardPhotoDetails = ({ link }) => (
  <div className="card-description">
    <div className="card-description--left" />
    <div className="card-description--right">
      <Link
        to={link}
        className="button--card"
      >
        <FormattedMessage
          id="common.details"
          defaultMessage="Details"
        />
      </Link>
    </div>
  </div>
);

CardPhotoDetails.propTypes = {
  link: PropTypes.string.isRequired,
};

export default CardPhotoDetails;
