import {
  LOCATIONS_MANAGER, Role,
} from '../constants/permissions';

class LocationsPermissions {
  private isLocationsManager: boolean;

  constructor(roles: Role[]) {
    this.isLocationsManager = roles.indexOf(LOCATIONS_MANAGER) !== -1;
  }

  canCreateLocations() {
    return this.isLocationsManager;
  }

  canUpdateLocations() {
    return this.isLocationsManager;
  }

  canDeleteLocations() {
    return this.isLocationsManager;
  }
}

export default LocationsPermissions;
