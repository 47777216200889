import { connect } from 'react-redux';

import Radio from './Radio';


export const mapStateToProps = () => ({
  translateOptionsLabels: true,
});

export default connect(mapStateToProps)(Radio);
