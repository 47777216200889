import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import * as entities from '../../../../../common/constants/entities';
import SearchDropdown from '../../search/SearchDropdown';
import TopBarSearchInput from './TopBarSearchInput';
import { getDatatronLink, getLocationLink, getMachineLink, getOULink } from '../../index/routes';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';
import { changeQuery } from '../../../redux/modules/search.topBar.module';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchResults } from '../../../redux/selectors/search.selector';

const SEARCH_CATEGORIES = [
  entities.MACHINES,
  entities.ORGANIZATIONS,
  entities.LOCATIONS,
  entities.DATATRONS,
];


export const TopBarSearch: React.FC = () => {
  const history = useHistory();

  const results = useSelector(getSearchResults);

  const dispatch = useDispatch();
  const onQueryChange = (query) => dispatch(changeQuery(query));

  const [isOpen, isOpenSet] = useState(false);
  const [query, querySet] = useState('');

  const queryChange = (newValue) => {
    querySet(newValue);
    onQueryChange(newValue);
  };

  const closeSearchDropdown = () => {
    isOpenSet(false);
  };

  const onCrossClick = () => {
    queryChange('');
    closeSearchDropdown();
  };

  const openSearchDropdown = () => {
    isOpenSet(true);
  };

  const handleQueryChange = (event) => {
    const value = event.target.value;
    queryChange(value);
  };

  const openElement = (element) => {
    queryChange('');
    closeSearchDropdown();

    const links = {
      [entities.MACHINES]: getMachineLink,
      [entities.ORGANIZATIONS]: getOULink,
      [entities.LOCATIONS]: getLocationLink,
      [entities.DATATRONS]: getDatatronLink,
    };
    history.push(links[element.type](element.item.id));
  };

  const outsideRef = useClickOutside(closeSearchDropdown);

  return (
    <div className="header-search">
      <div className="search-inline">
        <div className="search-block"  ref={outsideRef}>
          <TopBarSearchInput
            value={query}
            onChange={handleQueryChange}
            onClick={() => {
              openSearchDropdown();
            }}
            onCrossClick={onCrossClick}
          />
          {isOpen && query && (
            <div className="search-dropdown">
              <SearchDropdown
                nameToHighlight={query}
                selectItem={openElement}
                results={results}
                categoriesIncludedForDisplay={SEARCH_CATEGORIES}
                close={closeSearchDropdown}
                canApplyItemFilters={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

};

export default TopBarSearch;
