import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function ExportButton({ onClick }) {
  return (
    <button className="download--btn" onClick={onClick}>
      <span className="icon icon-load" />
      <FormattedMessage
        id="products.export.options.export"
        defaultMessage="Export"
      />
    </button>
  );
}

ExportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
