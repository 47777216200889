import { connect } from 'react-redux';

import ColumnExpandCollapse from './ColumnExpandCollapse';
import * as module from '../../../../../redux/modules/maintenance.expanded.module.js';

export const mapDispatchToProps = {
  expandCollapse: module.collapseExpand,
};

export default connect(null, mapDispatchToProps)(ColumnExpandCollapse);
