import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactCursorPosition from 'react-cursor-position';

import LoaderLocal from '../../_tools/loader/LoaderLocal';
import DatesWidgetContainer from './datesWidget/DatesWidget.container';
import EventsWrapper from './events/EventsWrapper';
import Footer from './footer/Footer';
import Header from './header/Header';

const Calendar = ({
  calendar,
  groupedEvents,
  fromDate,
  readOnly,
  showHeader,
  showFooter,
  className,
  changeDates,
  draggable,
}) => {
  const componentClassName = classnames('calendar--position', className);
  const machines = calendar.machines;

  return (
    <div className={componentClassName}>
      {showHeader && <Header calendar={calendar} />}
      <DatesWidgetContainer calendar={calendar} changeDates={changeDates} />
      <ReactCursorPosition>
        <EventsWrapper
          calendarId={calendar.id}
          groupedEvents={groupedEvents}
          fromDate={fromDate}
          readOnly={readOnly}
          draggable={draggable}
          type={calendar.type}
        />
      </ReactCursorPosition>
      {showFooter && <Footer machines={machines} />}
      {calendar.events.loading && <LoaderLocal />}
    </div>
  );
};

Calendar.propTypes = {
  calendar: PropTypes.object.isRequired,
  groupedEvents: PropTypes.array.isRequired,
  fromDate: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  className: PropTypes.string,
  changeDates: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
};

Calendar.defaultProps = {
  readOnly: false,
  showHeader: true,
  showFooter: true,
  className: '',
  draggable: false,
};

export default Calendar;
