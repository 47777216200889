import { connect } from 'react-redux';

import BatchDetailsPageHeader from './BatchDetailsPageHeader';
import { getBatchesLink } from '../../index/routes';
import { dateFilters, batch, areDateFiltersSelected } from '../../../redux/selectors/batchDetails.selector';
import { filterByDate } from '../../../redux/modules/batch.details.filter.dates.module';

export const mapStateToProps = (state) => ({
  backLink: getBatchesLink(),
  dateFilters: dateFilters(state),
  dateFiltersSelected: areDateFiltersSelected(state),
  batchId: batch(state).id,
});

export const mapDispatchToProps = {
  filterByDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchDetailsPageHeader);
