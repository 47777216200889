import React from 'react';
import { FormattedMessage } from 'react-intl';

import PathButtonContainer from './PathButton.container';

const ChangeLocation = () => (
  <div className="form-block">
    <div className="h1">
      <FormattedMessage
        id="profile.location"
        defaultMessage="Location"
      />
    </div>

    <div className="like-label">
      <FormattedMessage
        id="profile.Address"
        defaultMessage="Address"
      />

      <PathButtonContainer />
    </div>
  </div>
);

export default ChangeLocation;
