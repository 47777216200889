import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../messages/datatron.tabs.message';
import {
  ASSISTANCE_TAB,
  DATA_TAB,
  DETAILS_TAB,
  UPDATES_TAB,
  DatatronTabType,
} from '../../../redux/constants/datatron.tabs.constants';
import { getSelectedTab } from '../../../redux/selectors/datatron.selector';
import Assistance from '../../common/assistanceTab/DatatronAssistance.container';
import TabSelector, { TabItem } from '../../common/detailsTabSelector/TabSelector';
import { selectTab } from '../../../redux/modules/datatron.tabs.module';
import Data from './dataTab/Data';
import DetailsElement from './details/Details';
import Updates from './updates/Updates';

const tabComponents = {
  [DATA_TAB]: Data,
  [DETAILS_TAB]: DetailsElement,
  [ASSISTANCE_TAB]: Assistance,
  [UPDATES_TAB]: Updates,
};

const Details: React.FC = () => {
  const selectedTab: DatatronTabType = useSelector(getSelectedTab);
  const dispatch = useDispatch();

  const tabList = useMemo<TabItem[]>(
    () => [
      { id: DETAILS_TAB, label: messages[DETAILS_TAB] },
      { id: DATA_TAB, label: messages[DATA_TAB] },
      { id: ASSISTANCE_TAB, label: messages[ASSISTANCE_TAB] },
    ],
    [],
  );

  const handleSelectTab = useCallback(
    (id: DatatronTabType) => {
      dispatch(selectTab(id));
    },
    [dispatch],
  );

  const TabComponent =
    tabComponents[selectedTab] ||
    (() => {
      console.error('Unknown tab selected');
      return null;
    });

  return (
    <div className='col--in'>
      <TabSelector tabList={tabList} selectTab={handleSelectTab} selectedTab={selectedTab} />
      <TabComponent />
    </div>
  );
};

export default React.memo(Details);
