import { createAction } from 'redux-act';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as aiAppsApi from '../../core/api/aiApps';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';
import { replaceObjectInList, compareById } from '../../../../common/helpers/object';
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from '../../core/notifications';
import { stringifyObject } from '../../../../common/helpers/string.helper';
import { Todo } from '../../../../common/types/common';

interface State {
  aiApps: {
    loaded: boolean;
    loading: boolean;
    error: Todo;
    cycleDetectionDetails: Todo;
  };
}

interface AnomalyModel {
  id: string;
  [key: string]: unknown;
}

export const triggerAnomalyModel = createAction(
  'trigger anomalyModel',
  (cycleDetectionId: string, anomalyModelId: string) => ({ cycleDetectionId, anomalyModelId }),
);

export const triggerAnomalyModelSuccess = createAction(
  'trigger anomalyModel - success',
  (anomalyModel: AnomalyModel) => anomalyModel,
);

export const triggerAnomalyModelFail = createAction(
  'trigger anomalyModel - failed',
  (error: Todo) => error,
);

export const reducer = {
  [triggerAnomalyModel.getType()]: (state: State) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [triggerAnomalyModelSuccess.getType()]: (state: State, anomalyModel: AnomalyModel) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      cycleDetectionDetails: {
        ...state.aiApps.cycleDetectionDetails,
        anomaly_models: replaceObjectInList(
          state.aiApps.cycleDetectionDetails.anomaly_models,
          anomalyModel,
          compareById,
        ),
      },
      loaded: true,
      loading: false,
      error: null,
    },
  }),

  [triggerAnomalyModelFail.getType()]: (state: State, error: Todo) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: false,
      error,
    },
  }),
};

/**
 * Saga to handle triggering an anomaly model.
 *
 * This saga triggers an anomaly model based on the provided cycle detection and anomaly model parameters.
 * It dispatches success or failure actions and sends corresponding notifications based on the response.
 *
 * @param {object} action - The action object.
 * @param {string} action.payload.cycleDetection - The cycle detection parameter.
 * @param {string} action.payload.anomalyModel - The anomaly model parameter.
 */

export function* triggerAnomalyModelSaga(action: ReturnType<typeof triggerAnomalyModel>) {
  const {
    payload: { cycleDetectionId, anomalyModelId },
  } = action;

  const { response, error } = yield call(
    aiAppsApi.triggerAnomalyModel,
    cycleDetectionId,
    anomalyModelId,
  );

  if (response) {
    yield put(triggerAnomalyModelSuccess(response));
    yield put(
      sendNotification(
        notificationMessage.successfully_triggered_event,
        notificationMessage.successfully_triggered_event_description,
        NOTIFICATION_EVENT,
      ),
    );
  } else {
    yield put(triggerAnomalyModelFail(error));

    yield put(
      sendNotification(
        notificationMessage.server_error,
        {
          ...notificationMessage.something_happened,
          values: { error: stringifyObject(error) || '' },
        },
        NOTIFICATION_ERROR,
      ),
    );
  }
}

/**
 * Watcher saga to listen for the `triggerAnomalyModel` action and trigger the `triggerAnomalyModelSaga`.
 */
export function* watchTriggerAnomalyModel() {
  yield takeLatest(triggerAnomalyModel, triggerAnomalyModelSaga);
}
