import React from 'react';
import cn from 'classnames';
import { TodoFunction } from '../../../../../common/types/common';

type Props = {
  label?: string,
  className?: string,
  onClick?: TodoFunction,
  isDisabled?: boolean,
  isCurrent?: boolean,
  children?: React.ReactNode,
};

export const Button: React.FC<Props> = ({
  label = '',
  className = '',
  onClick = () => {
  },
  isDisabled = false,
  isCurrent = false,
  children,
}) => {
  const link = !isDisabled ? (<a onClick={onClick}>{label}</a>) : label;
  return (
    <li className={cn({ disabled: isDisabled, current: isCurrent }, className)}>
      {!children ? link : children}
    </li>
  );
};

Button.defaultProps = {
  label: '',
  className: '',
  onClick() {
  },
  isDisabled: false,
  isCurrent: false,
};

export default Button;
