import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { hideEvent } from '../../../../../redux/modules/maintenance.columns.event.hide.module';
import { openModal } from '../../../../../redux/modules/modals.module';
import { MAINTENANCE_ASSIGN } from '../../../../../redux/constants/modals.constants';
import ColumnDialogMenuActions from './ColumnDialogMenuActions';

export const mapDispatchToProps = (dispatch) => ({
  hide: bindActionCreators(hideEvent, dispatch),
  assign: (activityId, organizationId, assignee) => {
    dispatch(openModal(MAINTENANCE_ASSIGN, { activityId, organizationId, assignee }));
  },
});

export default connect(null, mapDispatchToProps)(ColumnDialogMenuActions);
