import React from 'react';
import PropTypes from 'prop-types';

import ColumnActivityContainer from './activity/Activity.container.js';

function CardWithActivities(props) {
  const { canUpdateActivities, connectDragSource } = props;

  const activityContainer = (
    <div>
      <ColumnActivityContainer {...props} />
    </div>
  );

  if (canUpdateActivities) {
    return connectDragSource(activityContainer);
  }

  return activityContainer;
}

CardWithActivities.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  canUpdateActivities: PropTypes.bool.isRequired,
};

export default CardWithActivities;
