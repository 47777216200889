import React from 'react';
import PropTypes from 'prop-types';
import { last } from 'lodash';
import moment from 'moment';

import { LiveTimeOffset } from '../../_tools';
import { isLatestLogAffectDuration, getTotalDuration } from '../../../core/common/tools';

const DASH = '–';

export const ToolDuration = ({ logs }) => {
  if (!logs || !logs.length) {
    return (<td>{ DASH }</td>);
  }

  const latestLog = last(logs);
  const totalDuration = getTotalDuration(logs);
  const latestLogAffectDuration = isLatestLogAffectDuration(logs);

  let totalValue = moment().from(moment().subtract(totalDuration, 'seconds'), true);
  if (latestLogAffectDuration) {
    totalValue = <LiveTimeOffset timestamp={latestLog.createdAt} secondsOffset={totalDuration} />;
  } else if (!totalDuration) {
    totalValue = DASH;
  }

  return (
    <td>
      { latestLogAffectDuration ? (<LiveTimeOffset timestamp={latestLog.createdAt} />) : DASH }
      <br />
      <span className="font-weight-light">
        { totalValue }
      </span>
    </td>
  );
};

ToolDuration.propTypes = {
  logs: PropTypes.array.isRequired,
};

export default ToolDuration;
