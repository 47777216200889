import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import ViewModalFooter from '../../../core/footers/ViewModalFooter';
import ModalLine from '../../../core/ModalLine';
import commonMessages from '../../../../../messages/common.messages';
import FormTabs from '../../../../common/formTabs/FormTabs';
import * as tabs from './ViewToolForm.tabs';
import Details from './details/Details';
import HistoryComponent from './history/History';

export class ViewToolForm extends Component {
  static propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hasMostRecentActivity: PropTypes.bool.isRequired,
    canEditTool: PropTypes.bool.isRequired,
    logs: PropTypes.array.isRequired,

  };

  state = { tab: tabs.DEFAULT_TAB };

  openDetails = () => this.setState({ tab: tabs.DETAILS_TAB });

  openHistory = () => this.setState({ tab: tabs.HISTORY_TAB });

  getTabs = () => ([
    {
      label: this.props.intl.formatMessage(commonMessages.details),
      isActive: this.state.tab === tabs.DETAILS_TAB,
      onClick: this.openDetails,
    },
    {
      label: this.props.intl.formatMessage(commonMessages.history),
      isActive: this.state.tab === tabs.HISTORY_TAB,
      onClick: this.openHistory,
    },
  ]);

  render() {
    const { tab } = this.state;
    const {
      handleCancel,
      handleSubmit,
      hasMostRecentActivity,
      canEditTool,
      logs,
    } = this.props;
    const formTabs = this.getTabs();

    return (
      <form className="form form--read-only" onSubmit={handleSubmit}>
        <FormTabs tabs={formTabs} />

        { tab === tabs.DETAILS_TAB && (
          <Details hasMostRecentActivity={hasMostRecentActivity} />
        ) }
        { tab === tabs.HISTORY_TAB && (
          <HistoryComponent logs={logs} />
        ) }

        <ModalLine />

        <ViewModalFooter
          onCancelClick={handleCancel}
          onEditClick={handleSubmit}
          isEditButtonDisabled={!canEditTool}
        />
      </form>
    );
  }
}

export default injectIntl(ViewToolForm);
