import { defineMessages } from 'react-intl';

export default defineMessages({
  add_file: {
    id: 'newMachine.add_file',
    defaultMessage: 'Add file',
  },
  add_photo: {
    id: 'newMachine.add_photo',
    defaultMessage: 'Add photo',
  },
});
