import { reduxForm } from 'redux-form';

import AccountForm from './AccountForm';
import { ADMIN_ACCOUNT_FORM_NAME } from '../../../../../../redux/constants/admin.account.form.constants';

const form = {
  form: ADMIN_ACCOUNT_FORM_NAME,
  destroyOnUnmount: true,
};

export default reduxForm(form)(AccountForm);
