import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import EditToolForm from './EditToolForm';
import * as form from '../../../../../redux/constants/tools.edit.form.constants';
import * as activityTypes from '../../../../../redux/constants/tools.activityTypes.constants';
import * as toolStates from '../../../../../../../common/enums/toolStates';
import { getFormFields } from '../../../../../redux/selectors/tools.edit.form.selector';

export const mapStateToProps = (state, { tool }) => {
  const initialValues = {
    [form.LABEL_FIELD]: tool.label,
    [form.TYPE_FIELD]: tool.type,
    [form.MODEL_FIELD]: tool.model,
    [form.SERIAL_FIELD]: tool.serial,
    [form.NA_TYPE]: null,
    [form.NA_COMMENT]: null,
    [form.NA_MACHINE]: null,
  };

  const isToolMounted = tool.state === toolStates.MOUNTED;
  const isToolAssigned = (tool.state === toolStates.NEW || tool.state === toolStates.USED) && !!tool.machine;
  const formFields = getFormFields(state);
  const showMachinesSelect = formFields[form.NA_TYPE] === activityTypes.MOUNT
    || formFields[form.NA_TYPE] === activityTypes.ASSIGN;
  const showComment = formFields[form.NA_TYPE] !== null;

  return {
    initialValues,
    isToolMounted,
    isToolAssigned,
    showMachinesSelect,
    showComment,
  };
};

const formConfig = {
  form: form.EDIT_TOOL_FORM_NAME,
  destroyOnUnmount: true,
};

export default connect(mapStateToProps)(reduxForm(formConfig)(EditToolForm));
