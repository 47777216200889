import React from 'react';
import { stack } from 'd3-shape';
import { getStylesByProp, getX } from '../../../../../core/common/reportChart';
import Area from './Area';
import { Todo } from '../../../../../../../common/types/common';

interface Props {
  data: Todo
  height: number
  keys: string[]
  xScale: Todo,
  yScale: Todo,
}

const StackedChart: React.FC<Props> = ({
  data, height, keys, xScale, yScale,
}) => {
  const colors = keys.map((prop) => getStylesByProp(prop).area);
  const stackGenerator = stack()
    .keys(keys);

  const series = stackGenerator(data);
  const getDataSeries = (d) => getX(d.data);
  const getYTopLine = (d) => d[1];

  return (
    <g>
      {
        series.reverse().map((s, i) => (
          <Area
            className={colors[colors.length - i - 1]}
            data={s}
            xScale={xScale}
            yScale={yScale}
            getY={getYTopLine}
            getX={getDataSeries}
            height={height}
            key={i}
          />
        ))
      }
    </g>
  );
};

export default StackedChart;
