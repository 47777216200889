import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';
import { isObject } from 'lodash';

import * as api from '../../core/api';
import { sendNotification } from './notifications.module';
import { openModal } from './modals.module';
import { SMP_SYNC_FAILED } from '../constants/modals.constants';
import notificationMessages from '../../messages/notification.message';
import * as notification from '../../core/notifications';

export const syncMachineToAltasigma = createAction(
  'sync machine to altasigma',
  (machineId) => machineId,
);

export function* syncMachineToAltasigmaSaga({ payload: machineId }) {
  const { response, error } = yield call(api.machines.syncMachineToAltasigma, machineId);

  if (response) {
    yield put(sendNotification(notificationMessages.sync_to_altasigma_success));
  } else {
    const errorHasDataPointsMap = isObject(error);

    if (!errorHasDataPointsMap) {
      yield put(sendNotification(notificationMessages.sync_to_altasigma_failed, null, notification.NOTIFICATION_ERROR));
    } else {
      const missingDataPoints = Object.keys(error);

      const list = missingDataPoints.reduce((result, item) => {
        const required = error[item] === false;
        if (!required) return result;
        return [
          ...result,
          item,
        ];
      }, []);

      yield put(openModal(
        SMP_SYNC_FAILED,
        {
          title: notificationMessages.sync_to_altasigma_failed,
          description: notificationMessages.sync_to_altasigma_failed_description,
          list,
        },
      ));
    }
  }
}

export function* watchSyncMachineToAltasigma() {
  yield takeEvery(syncMachineToAltasigma.getType(), syncMachineToAltasigmaSaga);
}
