import React from 'react';

import { HighlightText } from '../../../_tools';
import { TodoFunction } from '../../../../../../common/types/common';

interface Props {
  item: {
    name: string
  }
  onSelect: TodoFunction
  isSelected: boolean,
  nameToHighlight?: string,
}
const SearchResultsItem: React.FC<Props> = ({
  item, onSelect, isSelected, nameToHighlight,
}) => {
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSelect(item);
  };

  return (
    <li onClick={handleClick}>
      <span className="search-alls-text">
        { isSelected && '> '}
        <HighlightText text={item.name} highlight={nameToHighlight} />
      </span>
    </li>
  );
};


export default SearchResultsItem;
