import { reduxForm } from 'redux-form';

import ExperimentModal from './ExperimentModal';
import * as formConstants from '../../../../redux/constants/experiment.form.constants';

const form = {
  form: formConstants.EXPERIMENT_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
};

export default reduxForm(form)(ExperimentModal);
