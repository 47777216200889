import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';

import * as api from '../../core/api';
import * as form from '../constants/reportsTab.edit.form.constants';
import { REPORTS_TAB_EDIT } from '../constants/modals.constants';
import * as selector from '../selectors/reportsTab.edit.form.selector';
import { closeModal } from './modals.module';
import { replaceObjectInList, compareById } from '../../../../common/helpers/object';

export const editReportsTab = createAction(
  'edit reports tab',
  (tabId) => tabId,
);

export const editReportsTabSuccess = createAction(
  'edit reports tab - success',
  (tab) => tab,
);

export const editReportsTabFail = createAction(
  'edit reports tab - fail',
  (error) => error,
);

export const reducer = {
  [editReportsTabSuccess]: (state, tab) => ({
    ...state,
    reports: {
      ...state.reports,
      tabs: {
        ...state.reports.tabs,
        list: replaceObjectInList(
          state.reports.tabs.list,
          tab,
          compareById,
        ),
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.REPORTS_TAB_EDIT_FORM_NAME,
    ...form.REPORTS_TAB_PROPS,
  ));
}

export function* editReportsTabSaga({ payload: id }) {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const name = yield call(selector.getFormFields, state);

  yield put(startSubmit(form.REPORTS_TAB_EDIT_FORM_NAME));
  const { response, error } = yield call(api.reports.updateTab, id, { name });

  if (response) {
    yield put(editReportsTabSuccess(response));
    yield put(stopSubmit(form.REPORTS_TAB_EDIT_FORM_NAME));
    yield put(closeModal(REPORTS_TAB_EDIT));
  } else {
    yield put(editReportsTabFail(error));
    yield put(stopSubmit(form.REPORTS_TAB_EDIT_FORM_NAME, error));
  }
}

export function* watchEditReportsTab() {
  yield takeEvery(editReportsTab.getType(), editReportsTabSaga);
}
