import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import * as form from '../../../../../../redux/constants/report.form.constants';
import commonMessages from '../../../../../../messages/common.messages';
import commonCoreMessages from '../../../../../../messages/common.core.messages';
import formsPlaceholdersMessages from '../../../../../../messages/forms.placeholders.messages';
import { FormField } from '../../../../../_tools';
import ReportTypeSelectContainer from '../../../../../_tools/formFields/select/ReportTypeSelect.container';
import DatePickerMainButtonsContainer from '../../../../../_tools/formFields/datePickerV2/DatePickerMainButtons.container';
import InputButtonWithIcon from '../../../../../_tools/formFields/inputButtonWithIcon/InputButtonWithIcon.container';
import ModalLine from '../../../../core/ModalLine';
import AddModalFooter from '../../../../core/footers/AddModalFooter';
import EditModalFooter from '../../../../core/footers/EditModalFooter';

export const ModalMainFrame = ({
  canSelectMachines,
  canSelectDataPoints,
  onMachinesFiltersClick,
  onDataPointsFiltersClick,
  onDatePickerClick,
  handleSubmit,
  handleCancel,
  submitting,
  pristine,
  isAddForm,
  isEditForm,
}) => (
  <div className="modal-move modal-main">
    <div className="modal-form-in">
      <FormField
        label={commonMessages.name.id}
        name={form.NAME_FIELD}
        placeholder={formsPlaceholdersMessages.name.id}
        autoFocus
        tabIndex={1}
      />

      <label>
        <FormattedMessage {...commonMessages.type} />
        <Field
          component={ReportTypeSelectContainer}
          name={form.TYPE_FIELD}
          tabIndex={2}
        />
      </label>

      { canSelectMachines && (
        <div className="like-label search-block-category">
          <FormattedMessage {...commonCoreMessages.machines} tagName="span" />
          <Field
            name={form.MACHINES_FILTERS_FIELD}
            component={InputButtonWithIcon}
            onClick={onMachinesFiltersClick}
            iconClassName="icon icon-search"
            tabIndex={3}
            useButtonTag
          />
        </div>
      ) }

      { canSelectDataPoints && (
        <div className="like-label search-block-category">
          <FormattedMessage {...commonCoreMessages.dataPoints} tagName="span" />
          <Field
            name={form.DATA_POINTS_FILTERS_FIELD}
            component={InputButtonWithIcon}
            onClick={onDataPointsFiltersClick}
            iconClassName="icon icon-caret-right"
            tabIndex={3}
            useButtonTag
          />
        </div>
      ) }

      <div className="like-label">
        <FormattedMessage {...commonCoreMessages.datePeriod} tagName="span" />
        <div className="form-group" onClick={onDatePickerClick}>
          <div className="datepicker-block">
            <Field
              component={DatePickerMainButtonsContainer}
              name={form.DATES_FIELD}
              tabIndex={4}
            />
          </div>
        </div>
      </div>
    </div>
    <ModalLine />
    { isAddForm && (
      <AddModalFooter
        onCancelClick={handleCancel}
        onAddClick={handleSubmit}
        isAddButtonDisabled={pristine || submitting}
        isAddButtonLoading={submitting}
        cancelButtonTabIndex={5}
        addButtonTabIndex={6}
      />
    ) }
    { isEditForm && (
      <EditModalFooter
        onCancelClick={handleCancel}
        onSaveClick={handleSubmit}
        isSaveButtonDisabled={pristine || submitting}
        isSaveButtonLoading={submitting}
        cancelButtonTabIndex={5}
        addButtonTabIndex={6}
      />
    ) }
  </div>
);

ModalMainFrame.propTypes = {
  canSelectMachines: PropTypes.bool.isRequired,
  canSelectDataPoints: PropTypes.bool.isRequired,
  onMachinesFiltersClick: PropTypes.func.isRequired,
  onDataPointsFiltersClick: PropTypes.func.isRequired,
  onDatePickerClick: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isAddForm: PropTypes.bool.isRequired,
  isEditForm: PropTypes.bool.isRequired,
};

export default ModalMainFrame;
