import React from 'react';
import { FormattedMessage } from 'react-intl';

import DataAddButton from './addDeviceButton/DataAddButton';

type Props = {
  canCreateDatatronDevices: boolean;
};

export const NoDevices: React.FC<Props> = ({ canCreateDatatronDevices }) => (
  <div className="inventory-empty">
    <div>
      <div className="inventory-empty-icon"/>
      <FormattedMessage
        id="datatron.devices.no_devices_added"
        defaultMessage="No Devices Added"
        tagName="strong"
      />
      {
        canCreateDatatronDevices && (
          <>
            <div className="pb15">
              <FormattedMessage
                id="datatron.devices.no_devices_added.description"
                defaultMessage='Click on "Add New" in order to create new Device'
                tagName="p"
              />
            </div>
            <DataAddButton/>
          </>
        )
      }
    </div>
  </div>
);

export default NoDevices;
