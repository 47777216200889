import React from 'react';
import DatePicker from 'react-datepicker';
import TimeInput from './timeInput/TimeInput';
import { userLocale } from '../../../../../../redux/selectors/profile.selector';
import { useSelector } from 'react-redux';
import { DateProps } from '../../../../../../core/propTypes/propTypes';
import { TodoFunction } from '../../../../../../../../common/types/common';

interface Props {
  value?: DateProps,
  maxDate: DateProps,
  onDateChange: TodoFunction,
  onTimeChange: TodoFunction,
  allowFuture: boolean,
}

const Calendar: React.FC<Props> = ({
  value, maxDate, onDateChange, onTimeChange, allowFuture,
}) => {
  const locale = useSelector(userLocale);
  const currentDate = value ? value.toDate() : null;
  return (
    <div className="datepicker-wrap">
      <DatePicker
        inline
        allowSameDay
        dateFormat="MM/DD/YYYY"
        selected={currentDate}
        maxDate={allowFuture ? null : new Date(maxDate)}
        onChange={onDateChange}
        locale={locale}
      />
      <TimeInput change={onTimeChange} value={value} disabled={!value}/>
    </div>
  );
};

export default Calendar;
