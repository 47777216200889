import { createAction } from 'redux-act';

export const selectTab = createAction(
  'select new single machine tab',
  (tab) => tab,
);

export const resetSelectedTab = createAction(
  'should tab be reset',
  (isResetSelectedTab) => isResetSelectedTab,
);

export const reducer = {
  [selectTab]: (state, tab) => ({
    ...state,
    machine: {
      ...state.machine,
      tab,
      resetSelectedTab: true,
    },
  }),
  [resetSelectedTab]: (state, isResetSelectedTab) => ({
    ...state,
    machine: {
      ...state.machine,
      resetSelectedTab: isResetSelectedTab,
    },
  }),
};
