import { DASHBOARD_EVENTS } from '../constants/export.type.constants';

type DownloadVerificationType = string | null;

type ExportWidgetDownloadVerification = {
  type: DownloadVerificationType;
  ready: boolean;
  entriesCount: number;
  loaded: boolean;
  loading: boolean;
  error: null | Error;
};

export  type ExportWidgetState = {
  downloadVerification: ExportWidgetDownloadVerification;
};

export const exportWidgetInitialState: ExportWidgetState = {
  downloadVerification: {
    type: null,
    ready: false,
    entriesCount: 0,
    loaded: false,
    loading: false,
    error: null,
  },
};

export const exportWidgetTestState: ExportWidgetState = {
  downloadVerification: {
    type: DASHBOARD_EVENTS,
    ready: true,
    entriesCount: 500,
    loaded: true,
    loading: false,
    error: null,
  },
};
