import {
  forEach,
  groupBy,
  intersection,
  isArray,
  isEmpty,
  isEqual,
  mergeWith,
  uniq,
  uniqBy,
} from 'lodash';
import { Todo } from '../../../../common/types/common';

// eslint-disable-next-line @typescript-eslint/naming-convention
const _mergeCustomizer = (objValue, srcValue) => {
  if (isArray(objValue)) return uniq(objValue.concat(srcValue));
  return undefined;
};

// helper for schema parsing
export const findCommonEnum = (items: Todo[] = []) => {
  const enumsToItem: Record<string, Todo> = items.reduce(
    (result, item, index) => ({ ...result, [index]: [] }),
    {},
  );
  const enumValues = {};

  items.forEach((item, index) => {
    forEach(item.properties, (property, key) => {
      if (property.enum) {
        enumsToItem[index].push(key);
        enumValues[key] = [...(enumValues[key] || []), ...property.enum];
      }
    });
  });

  const keys: string[] = intersection(...Object.values(enumsToItem));
  const fields = keys.reduce(
    (result: Todo[], key) => [
      ...result,
      {
        _key: key,
        type: 'enum',
        enum: enumValues[key],
        _isCommonEnum: true,
      },
    ],
    [],
  );
  return { keys, fields };
};

export const mergePropertiesWithSameKey = (properties: Todo[] = []) => {
  if (!properties || !properties.length) return null;

  return mergeWith({}, ...properties, _mergeCustomizer);
};

// initial schema parsing
export const getProperties = (
  properties: Todo = {},
  required: Todo[] = [],
  _parent: Todo[] = [],
  setValidFor: string[] | null = null,
) => {
  const fields: Todo[] = [];
  const validFor: Todo = {};

  forEach(properties, (value: Todo, key) => {
    const property = {
      ...value,
      _key: key,
      _required: required.indexOf(key) >= 0,
      _parent,
    };

    if (!property.type && property.enum) {
      property.type = 'enum';

      if (setValidFor && setValidFor?.length && setValidFor?.indexOf(property._key) >= 0) {
        validFor[property._key] = property.enum;
      }
    }

    fields.push(property);
  });

  if (!isEmpty(validFor)) {
    return fields.map((item) => ({ ...item, _validFor: validFor }));
  }

  return fields;
};

export const flatSchemaToFields = (parsedSchema, _parent: Todo[] = []) => {
  let fields: Todo[] = [];

  let common: Todo = null;
  let setValidFor = null;
  let objectToExplore = [parsedSchema];

  if (parsedSchema.allOf) {
    objectToExplore = parsedSchema.allOf;
  } else if (parsedSchema.oneOf) {
    objectToExplore = parsedSchema.oneOf;
    common = findCommonEnum(parsedSchema.oneOf);
  }

  if (common) {
    common.fields?.forEach((item) => fields.push({ ...item, _parent }));
    setValidFor = common.keys;
  }

  forEach(objectToExplore, (block) => {
    if (block.properties) {
      const propsToAppend = getProperties(
        block.properties,
        block.required,
        _parent,
        setValidFor,
      ).map((item) => {
        const newItem = { ...item };

        if (newItem.allOf || newItem.oneOf) {
          const childProps = flatSchemaToFields(newItem, [..._parent, newItem._key]);
          fields = [...fields, ...childProps];
          newItem._child = childProps;
        }

        if (newItem.type === 'object' && !newItem.additionalProperties) {
          const childProps = flatSchemaToFields(newItem, [..._parent, newItem._key]);
          fields = [...fields, ...childProps];
          newItem._child = childProps;
          return null; // Skip adding the parent object to the fields array
        }

        return newItem;
      });
      fields = [...fields, ...propsToAppend.filter((item) => item !== null)];
    }
    if (block.allOf || block.oneOf) {
      fields = [...fields, ...flatSchemaToFields(block, _parent)];
    }
  });

  const groupedFields = groupBy(fields, '_key');
  const mergedGroups: Todo[] = [];
  forEach(groupedFields, (group) => mergedGroups.push(mergePropertiesWithSameKey(group)));
  return mergedGroups;
};

// get fields based on flatten schema and provided config
export const getConfigValue = (key, config = {}, parent = []) => {
  let scope = config;
  let i = 0;

  while (i < parent.length) {
    scope = scope[parent[i++]];
  }

  return scope[key] === undefined ? '' : scope[key];
};

function getNestedConfig(config: Todo, parentKeys: string[]) {
  if (parentKeys.length === 0) {
    return config;
  }

  const [currentKey, ...restKeys] = parentKeys;
  if (config.hasOwnProperty(currentKey)) {
    return getNestedConfig(config[currentKey], restKeys);
  }

  return undefined;
}

export const getFieldsBasedOnConfig = (
  config: Todo = {},
  fields: Todo[] = [],
  _parent: Todo[] = [],
): Todo[] =>
  fields.flatMap((field) => {
    const key = field._key;
    const value = getConfigValue(key, config);
    const sameLevel = isEqual(_parent, field._parent || []);
    if (!sameLevel) {
      const parentKeys = field._parent;
      if (parentKeys && parentKeys.length > 0) {
        const nestedConfig = getNestedConfig(config, parentKeys);
        return {
          value: nestedConfig ? getConfigValue(key, nestedConfig) : '',
          field,
        };
      }
      return [];
    }

    if (
      ((value && typeof value === 'object' && !isArray(value)) || field.type === 'object') &&
      // Don't descend into custom config with additional properties as this will be editable directly.
      !(field._key === 'custom' && field.additionalProperties) &&
      key !== 'internal'
    ) {
      // No changes in this block
    } else {
      let allowed = true;
      if (field._validFor && !isEmpty(field._validFor)) {
        forEach(field._validFor, (allowedValues, validForKey) => {
          if (validForKey !== key && !(allowedValues.indexOf(config[validForKey]) >= 0)) {
            allowed = false;
          }
        });
      }

      if (allowed) {
        return {
          value,
          field,
        };
      }
    }
    return [];
  });

export const getFieldsBasedOnConfigKeys = (
  configKeys: Todo[] = [],
  fields: Todo[] = [],
  _parent: Todo[] = [],
) => {
  let result: Todo[] = [];

  forEach(fields, (field) => {
    const key = field._key;

    if (field.type === 'object' && field._child) {
      result = [
        ...result,
        ...getFieldsBasedOnConfigKeys(
          configKeys,
          uniqBy(field._child, (i) => i._key),
          [..._parent, key],
        ),
      ];
    } else {
      const expectedConfigKey = [..._parent, key].join('.');
      if (configKeys.indexOf(expectedConfigKey) >= 0) {
        result.push(field);
      }
    }
  });

  return result;
};

export const createConfigKeyHashToFieldsMap = (
  dataPoints: Todo[] = [],
  dataPointFields: Todo[] = [],
  inputMap: Todo = {},
) => {
  const result = {
    ...inputMap,
  };

  dataPoints.forEach((dataPoint) => {
    if (!result[dataPoint.configKeysHash]) {
      result[dataPoint.configKeysHash] = getFieldsBasedOnConfigKeys(
        dataPoint.configKeys,
        dataPointFields,
      );
    }
  });

  return result;
};

// For formatting JSON as string. Used, for example, for custom configs.
export const formatDeviceConfigValue = (value: unknown): string | unknown => {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value);
  }
  return value;
};
