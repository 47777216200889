import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import InputError from '../inputError/InputError';
import commonMessages from '../../../messages/common.messages';

const PathButton = ({
  openModal, locationPathArray, meta, disabled, tabIndex,
}) => {
  const isError = meta && meta.error;
  const className = cn({ error: isError });

  const emptyPlaceholder = disabled ?
    commonMessages.notSet : commonMessages.chooseLocation;

  return (
    <div className="rel input-group">
      <div className={className}>
        <button onClick={openModal} className="likefield location-line-field" type="button" disabled={disabled} tabIndex={tabIndex}>
          <ul className="menu breadcrumps-menu">
            <li>
              <a className="breadcrumps-title breadcrumps-home">
                <span className="icon-location" />
              </a>
            </li>
            { (!locationPathArray || !locationPathArray.length)
              ? (
                <li>
                  <div className="breadcrumps-title">
                    <FormattedMessage
                      {...emptyPlaceholder}
                      tagName="span"
                    />
                  </div>
                </li>
              ) : locationPathArray.map((locationName, index, array) => (
                <li key={locationName}>
                  <div className="breadcrumps-title">
                    <span>{ locationName }</span>
                    { !(index === (array.length - 1)) && (<span className="fa fa-chevron-right" />)}
                  </div>
                </li>
              ))}
          </ul>
        </button>
        { isError && <div className="error-line"><InputError error={meta.error} /></div> }
      </div>
    </div>
  );
};

PathButton.propTypes = {
  openModal: PropTypes.func.isRequired,
  locationPathArray: PropTypes.array,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

PathButton.defaultProps = {
  locationPathArray: [],
  disabled: false,
};

export default PathButton;
