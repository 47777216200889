import React, { useEffect } from 'react';
import classnames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import SidePanelContainer from './sidePanel/SidePanel.container.js';
import EventsContainer from '../events/Events.container';
import CalendarContainer from '../calendar/Calendar.container';
import { onDashboardEnter, onDashboardLeave } from '../index/routeHandlers';
import * as selector from '../../redux/selectors/dashboard.selector';
import { selectIsOeeHidden } from '../../redux/selectors/appConfig.selector';
import { showHideCalendar } from '../../redux/modules/dashboard.calendar.module';

export const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchShowHideCalendar = () => dispatch(showHideCalendar);
  const showCalendar = useSelector(selector.showCalendar);
  const showSideBar = !useSelector(selectIsOeeHidden);

  useEffect(() => {
    onDashboardEnter();
    return () => onDashboardLeave();
  }, []);

  const calendarClassName = classnames('col--3--right', {
    'col--3--right__hide': !showCalendar,
  });

  const calendarOverlayClassName = classnames('calendar-overlay', {
    'calendar-overlay__hide': !showCalendar,
  });

  return (
    <div className="col--3">
      { showSideBar && (
        <div className="col--3--left">
          <SidePanelContainer />
        </div>
      ) }
      <div className="col--3--center">
        <EventsContainer />
      </div>
      <div className={calendarClassName}>
        <CalendarContainer />
      </div>
      <div
        className={calendarOverlayClassName}
        onClick={dispatchShowHideCalendar}
      />
    </div>
  );
};

export default Dashboard;
