import React from 'react';
import PropTypes from 'prop-types';

const UUIDGenerator = ({ onClick }) => (
  <div className="input-group-button">
    <button
      className="button"
      type="button"
      onClick={onClick}
    >
      <span className="icon-loading" />
    </button>
  </div>
);

UUIDGenerator.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default UUIDGenerator;
