import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import Form from './form/ViewToolForm.container';

export const ViewToolModal = ({
  tool,
  switchToEditMode,
  close,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    switchToEditMode(tool);
  };

  return (
    <BasicModal close={close} modalClass="modal--sm2">
      <div className="modal--content">
        <h3 className="first-title">
          <FormattedMessage
            id="tools.modal.view_tool"
            defaultMessage="View Tool"
          />
        </h3>
        <Form tool={tool} handleSubmit={handleSubmit} handleCancel={close} />
      </div>
    </BasicModal>
  );
};

ViewToolModal.propTypes = {
  tool: PropTypes.object.isRequired,
  switchToEditMode: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default ViewToolModal;
