import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import EventsTableRow from './row/EventsTableRow';
import {
  getEventDatesByDayAndHour, getWidthPerSecond, getTimeByWidth,
} from '../../../../core/common/calendar';

const DEBOUNCE_DELAY = 200;

export class EventsTable extends Component {
  static propTypes = {
    groupedEvents: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    createCalendarEvent: PropTypes.func.isRequired,
    calendarId: PropTypes.string.isRequired,
    position: PropTypes.shape({
      x: PropTypes.number.isRequired,
    }).isRequired,
    readOnly: PropTypes.bool.isRequired,
    draggable: PropTypes.bool.isRequired,
    type: PropTypes.string,
    enabledCreateEvent: PropTypes.bool.isRequired,
    enableCreateCalendarEvent: PropTypes.func.isRequired,
    disableCreateCalendarEvent: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { widthPerSecond: getWidthPerSecond(props.width) };

    this.handleClick = this.handleClick.bind(this);
    this.debouncedSetWidthPerSecond = debounce(this.setWidthPerSecond, DEBOUNCE_DELAY);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { width: nextWidth } = nextProps;
    const { width } = this.props;

    if (width !== nextWidth) {
      this.debouncedSetWidthPerSecond(nextWidth);
    }
  }

  setWidthPerSecond(width) {
    this.setState({ widthPerSecond: getWidthPerSecond(width) });
  }

  handleClick(day) {
    const {
      position,
      width,
      createCalendarEvent,
      calendarId,
      readOnly,
      enabledCreateEvent,
      enableCreateCalendarEvent,
    } = this.props;

    if (readOnly) return;

    if (!enabledCreateEvent) {
      enableCreateCalendarEvent();
      return;
    }

    const hour = getTimeByWidth(position.x, width, this.widthPerSecond);
    const { startDate, endDate } = getEventDatesByDayAndHour(day, hour);

    createCalendarEvent(calendarId, startDate, endDate);
  }

  render() {
    const {
      readOnly,
      groupedEvents,
      width,
      calendarId,
      draggable,
      type,
      disableCreateCalendarEvent,
      enableCreateCalendarEvent,
    } = this.props;
    const { widthPerSecond } = this.state;

    let index = 1;
    return (
      <tbody>
        {
          groupedEvents.map((item) => (
            <EventsTableRow
              {...item}
              tableWidth={width}
              widthPerSecond={widthPerSecond}
              onClick={this.handleClick}
              readOnly={readOnly}
              draggable={draggable}
              key={index++}
              calendarId={calendarId}
              type={type}
              disableCreateCalendarEvent={disableCreateCalendarEvent}
              enableCreateCalendarEvent={enableCreateCalendarEvent}
            />
          ))
        }
      </tbody>
    );
  }
}

export default EventsTable;
