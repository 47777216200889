export const AI_APP_FORM_NAME = 'AI_APP_FORM_NAME';
export const NAME_FIELD = 'appName';
export const DATATRON_SELECT_FIELD = 'datatron';
export const DEVICES_FILTERS_FIELD = 'devices';
export const DESCRIPTION_FIELD = 'description';

export const AI_APP_PROPS = [
  NAME_FIELD,
  DATATRON_SELECT_FIELD,
  DEVICES_FILTERS_FIELD,
  DESCRIPTION_FIELD,
];
