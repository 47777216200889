import React from 'react';

export function Updates() {
  return (
    <div className="inventory-tab--in">
      Updates
    </div>
  );
}

Updates.propTypes = {
};

export default Updates;
