import { connect } from 'react-redux';

import TopBarProfileDropdown from './TopBarProfileDropdown';
import * as routes from '../../index/routes.js';
import PermissionedComponent from '../../common/permissions/PermissionedComponent.container';
import {
  ACCOUNTS_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
  ORGANIZATIONS_PERMISSIONS,
  ROLES_PERMISSIONS,
  USERS_PERMISSIONS,
} from '../../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => {
  const hasAccountsAccess = permissions[ACCOUNTS_PERMISSIONS].availableToAll();
  const hasOrganizationsAccess = permissions[ORGANIZATIONS_PERMISSIONS].canCreateOrganizations();
  const hasLocationsAccess = permissions[LOCATIONS_PERMISSIONS].canCreateLocations();
  const hasUsersAccess = permissions[USERS_PERMISSIONS].canManageUsers();
  const hasRolesAccess = permissions[ROLES_PERMISSIONS].canManageRoles();

  const hasAdminAccess = hasAccountsAccess
    || hasOrganizationsAccess
    || hasLocationsAccess
    || hasUsersAccess
    || hasRolesAccess;

  return {
    profileLink: routes.getLink(routes.app.profile),
    adminPageLink: routes.getAdminPageLink(),
    hasAdminAccess,
  };
};

export default PermissionedComponent([
  USERS_PERMISSIONS,
  ORGANIZATIONS_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
  ROLES_PERMISSIONS,
  ACCOUNTS_PERMISSIONS,
])(connect(mapStateToProps)(TopBarProfileDropdown));
