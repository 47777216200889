import { formValueSelector } from 'redux-form';

import * as form from '../forms/profile.form.js';
import * as selector from '../../redux/selectors/profile.selector';

export const isPasswordChanged = (state) => {
  const formSelector = formValueSelector(form.profileForm);
  const oldPassword = formSelector(state, form.oldPasswordField);
  const newPassword = formSelector(state, form.newPasswordField);

  return !(
    (!oldPassword || oldPassword === '')
    && (!newPassword || newPassword === '')
  );
};

export const getPasswords = (state) => {
  const formSelector = formValueSelector(form.profileForm);
  const oldPassword = formSelector(state, form.oldPasswordField);
  const newPassword = formSelector(state, form.newPasswordField);
  const confirmNewPassword = formSelector(state, form.confirmNewPasswordField);

  return {
    oldPassword,
    newPassword,
    confirmNewPassword,
  };
};

export const getLanguage = (state) => {
  const formSelector = formValueSelector(form.profileForm);

  return formSelector(state, form.newLanguageField);
};

export const isLanguageChanged = (state) => {
  const formSelector = formValueSelector(form.profileForm);

  const newLanguage = formSelector(state, form.newLanguageField);
  const oldLanguage = selector.userLocale(state);

  return oldLanguage !== newLanguage;
};

export const getLocationId = (state) => {
  const formSelector = formValueSelector(form.profileForm);

  return formSelector(state, form.newLocationField);
};

export const isLocationChanged = (state) => {
  const newLocationId = getLocationId(state) || null;

  const oldLocation = selector.selectProfileLocation(state);
  const oldLocationId = (oldLocation && oldLocation.id) ? oldLocation.id : null;

  return oldLocationId !== newLocationId;
};
