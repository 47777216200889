export const EXPERIMENT_FORM_NAME = 'EXPERIMENT_FORM_NAME';

export const NAME_FIELD = 'name';
export const SERIES_FIELD = 'series';
export const STATUS_FIELD = 'status';
export const DATES_FIELD = 'dates';
export const START_FIELD = 'start';
export const END_FIELD = 'end';
export const COMMENT_FIELD = 'comment';
export const MACHINE_FIELD = 'machineId';
export const USER_FIELD = 'userId';

export const EXPERIMENT_PROPS = [
  NAME_FIELD,
  SERIES_FIELD,
  STATUS_FIELD,
  DATES_FIELD,
  COMMENT_FIELD,
  MACHINE_FIELD,
  USER_FIELD,
];
