import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ThreeDotsMenu from '../../../_tools/threeDotsMenu/ThreeDotsMenu';
import ReportName from './ReportName';
import ReportFiltersLabel from './ReportFiltersLabel';
import EditMenuItem from './menuItems/EditMenuItem';
import DuplicateMenuItem from './menuItems/DuplicateMenuItem';
import RemoveMenuItem from './menuItems/RemoveMenuItem';
import reportsTypesMessages from '../../../../messages/reports.types.messages';

export const ReportHeader = ({
  report, openRemoveModal, openDuplicateModal, openEditModal,
}) => {
  const handleEdit = () => openEditModal(report);
  const handleDuplicate = () => openDuplicateModal(report);
  const handleRemove = () => openRemoveModal(report);

  return (
    <div className="reports--top">
      <div className="reports--top--up">
        <div className="reports-path">
          <ReportName reportName={report.name} />
        </div>

        <div className="reports-label">
          <FormattedMessage {...reportsTypesMessages[report.type]} />
        </div>

        <ThreeDotsMenu
          menuItems={[
            {
              label: <EditMenuItem />,
              onClick: handleEdit,
            },
            {
              label: <DuplicateMenuItem />,
              onClick: handleDuplicate,
            },
            {
              label: <RemoveMenuItem />,
              onClick: handleRemove,
            },
          ]}
        />
      </div>
      <ReportFiltersLabel filters={report.filters} />
    </div>
  );
};

ReportHeader.propTypes = {
  report: PropTypes.object.isRequired,
  openRemoveModal: PropTypes.func.isRequired,
  openDuplicateModal: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
};

export default ReportHeader;
