export const ADD_TOOL_FORM_NAME = 'ADD_TOOL_FORM_NAME';

export const LABEL_FIELD = 'label';
export const TYPE_FIELD = 'type';
export const MODEL_FIELD = 'model';
export const SERIAL_FIELD = 'serial';
export const MACHINE_FIELD = 'machineId';
export const COMMENT_FIELD = 'comment';

export const TOOL_PROPS = [
  LABEL_FIELD,
  TYPE_FIELD,
  MODEL_FIELD,
  SERIAL_FIELD,
];

export const MACHINE_PROPS = [
  MACHINE_FIELD,
  COMMENT_FIELD,
];
