export const NEW = 'new';
export const MOUNTED = 'mounted';
export const USED = 'used';
export const EXHAUSTED = 'exhausted';

export const DEFAULT = NEW;

export const statesForNewTool = [NEW];
export const stateForExistingTool = [EXHAUSTED];
export const allStates = [NEW, MOUNTED, USED, EXHAUSTED];

export default allStates;
