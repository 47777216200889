import { connect } from 'react-redux';

import { DropdownCheckboxSelect } from '../../../../_tools';
import { addStatusFilter, removeStatusFilter } from '../../../../../redux/modules/experiments.filter.module';
import { getExperimentsFilters } from '../../../../../redux/selectors/experimentsPage.selector';
import * as experimentStatuses from '../../../../../../../common/enums/experimentStatuses';
import messages from '../../../../../messages/experiments.status.messages';

export const mapStateToProps = (state) => ({
  selected: getExperimentsFilters(state).status,
  options: [
    {
      id: experimentStatuses.PLANNED,
      label: messages[experimentStatuses.PLANNED],
    },
    {
      id: experimentStatuses.IN_PROGRESS,
      label: messages[experimentStatuses.IN_PROGRESS],
    },
    {
      id: experimentStatuses.COMPLETED,
      label: messages[experimentStatuses.COMPLETED],
    },
    {
      id: experimentStatuses.FAILED,
      label: messages[experimentStatuses.FAILED],
    },
    {
      id: experimentStatuses.ARCHIVED,
      label: messages[experimentStatuses.ARCHIVED],
    },
  ],
});

export const mapDispatchToProps = {
  addFilter: addStatusFilter,
  removeFilter: removeStatusFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownCheckboxSelect);
