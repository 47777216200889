import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import commonCoreMessages from '../../../../../../../../messages/common.core.messages';
import * as form from '../../../../../../../../redux/constants/admin.user.form.constants';
import { FormField } from '../../../../../../../_tools';
import LocaleSelectContainer from '../../../../../../../_tools/formFields/select/LocaleSelect.container';
import PathButtonContainer from './PathButton.container';
import LocationModalContainer from './LocationModal.container';

export const BasicUserForm = ({ hidden, readOnly, location }) => (
  <div className={cn('tab-basic', { hide: hidden })}>
    <div className="tab-row">
      <div className="tab-col">
        <FormField
          label={commonCoreMessages.firstName.id}
          name={form.FIRST_NAME_FIELD}
          disabled={readOnly}
          tabIndex={1}
          autoFocus
        />
        <FormField
          label={commonCoreMessages.lastName.id}
          name={form.LAST_NAME_FIELD}
          disabled={readOnly}
          tabIndex={3}
        />
        <FormField
          label={commonCoreMessages.middleName.id}
          name={form.MIDDLE_NAME_FIELD}
          disabled={readOnly}
          tabIndex={5}
        />
        <label>
          <FormattedMessage {...commonCoreMessages.location} />
          <Field
            name={form.LOCATION_ID_FIELD}
            component={PathButtonContainer}
            disabled={readOnly}
            location={location}
            tabIndex={7}
          />
        </label>
      </div>
      <div className="tab-col">
        <FormField
          label={commonCoreMessages.position.id}
          name={form.POSITION_FIELD}
          disabled={readOnly}
          tabIndex={2}
        />
        <FormField
          label={commonCoreMessages.email.id}
          name={form.EMAIL_FIELD}
          disabled={readOnly}
          tabIndex={4}
        />
        <FormField
          label={commonCoreMessages.phoneNumber.id}
          name={form.PHONE_NUMBER_FIELD}
          disabled={readOnly}
          tabIndex={6}
        />
        <label>
          <FormattedMessage {...commonCoreMessages.locale} />
          <Field
            name={form.LOCALE_FIELD}
            component={LocaleSelectContainer}
            disabled={readOnly}
            tabIndex={8}
          />
        </label>
      </div>
    </div>
    <Field
      name={form.LOCATION_ID_FIELD}
      component={LocationModalContainer}
      location={location}
    />
  </div>
);

BasicUserForm.propTypes = {
  hidden: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  location: PropTypes.object,
};

export default BasicUserForm;
