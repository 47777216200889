import React from 'react';
import PropTypes from 'prop-types';

import NameContainer from './name/Name.container';
import ActionsContainer from './actions/Actions.container';

function Header({
  calendar,
}) {
  return (
    <div className="calendar--top">
      <NameContainer calendar={calendar} />
      <ActionsContainer calendar={calendar} />
    </div>
  );
}

Header.propTypes = {
  calendar: PropTypes.object.isRequired,
};

export default Header;
