export const DATA_TAB = 'data';
export const TOOLS_TAB = 'tools';
export const MACHINE_DETAILS_TAB = 'machineDetails';
export const ASSISTANCE_TAB = 'assistance';
export const DOCUMENTS_TAB = 'documents';
export const SENSORS_TAB = 'sensors';
export const MAINTENANCE_TAB = 'maintenance';
export const SCHEDULE_TAB = 'shift_&_schedule';
export const SUMMARY_TAB = 'summary';
export const DEFAULT_TAB = SUMMARY_TAB;

export type MachineTabType =
  | typeof DATA_TAB
  | typeof TOOLS_TAB
  | typeof MACHINE_DETAILS_TAB
  | typeof ASSISTANCE_TAB
  | typeof DOCUMENTS_TAB
  | typeof SENSORS_TAB
  | typeof MAINTENANCE_TAB
  | typeof SCHEDULE_TAB
  | typeof SUMMARY_TAB;
