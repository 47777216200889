import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import LocationLabel from '../path/LocationLabel';
import Name from '../path/Name';
import StatusIcon from '../StatusIcon';
import { getExplainedPath } from '../../../../core/common/machine';
import { getMachineLink } from '../../../index/routes';

class Machine extends Component {
  static propTypes = {
    machine: PropTypes.object.isRequired,
    selectedLocation: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { machine, selectedLocation } = this.props;

    this.explainedPath = getExplainedPath({
      path: machine.location.path,
      isSelectedLocation: machine.location.isSelectedLocation,
      name: selectedLocation.name,
    });
  }

  render() {
    const machine = this.props.machine;

    return (
      <tr className={cn({ offline: !machine.isAvailable })}>
        <StatusIcon isAvailable={machine.isAvailable} />
        <Name label={machine.name} link={getMachineLink(machine.id)} />
        <LocationLabel
          location={machine.location}
          explainedPath={this.explainedPath}
        />
      </tr>
    );
  }
}

export default Machine;
