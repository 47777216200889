import React from 'react';
import { FormattedMessage } from 'react-intl';

function EmptyEvents() {
  return (
    <div className="event-empty">
      <div>
        <div className="event-empty-icon">
          <span className="icon icon-calendar" />
        </div>
        <FormattedMessage
          id="dashboard.you_have_no_events"
          defaultMessage="You Have No Events"
          tagName="strong"
        />
      </div>
    </div>
  );
}

export default EmptyEvents;
