import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl-redux';

import NotificationsContainer from '../notification/Notifications.container';
import PageSpinnerContainer from '../pageSpinner/PageSpinner.container';
import TopBar from '../topBar/TopBar';
import CustomHeadContainer from '../_tools/customHead/CustomHead.container';
import ModalsContainer from '../modals/Modals.container';

const RootCore = ({ locale, authorized, children }) => (
  <IntlProvider key={locale}>
    { authorized && (
      <div className="App">
        <CustomHeadContainer />
        <TopBar />
        <PageSpinnerContainer>
          <div className="content">
            {children}
          </div>
        </PageSpinnerContainer>
        <NotificationsContainer />
        <ModalsContainer />
      </div>
    ) }
  </IntlProvider>
);

RootCore.propTypes = {
  locale: PropTypes.string.isRequired,
  authorized: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default RootCore;
