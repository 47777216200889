import { createAction } from 'redux-act';

import { DEFAULT_TAB } from '../constants/experimentsPage.tabs.constants';

export const selectTab = createAction(
  'select tab on experiments page',
  (tab = DEFAULT_TAB) => tab,
);

export const reducer = {
  [selectTab]: (state, tab) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      tab,
    },
  }),
};
