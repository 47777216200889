import React from 'react';

import HelpLines from './HelpLines';
import AxisVertical from './AxisVertical';
import AxisBottom from './AxisBottom';
import AreaChart from './areaChart/AreaChart';
import { Todo, TodoFunction } from '../../../../../common/types/common';

interface Props {
  frameHeight: number;
  frameWidth: number;
  helpLinesTicks: Todo[];
  isLeftAxisEnabled?: boolean;
  isLeftAxisInPercent?: boolean;
  isRightAxisEnabled?: boolean;
  isRightAxisInPercent?: boolean;
  leftAxisChartPoints: Todo[];
  leftAxisEntries: Todo[];
  leftAxisTicks: Todo[];
  leftAxisWidth: number;
  rightAxisChartPoints: Todo[];
  rightAxisEntries: Todo[];
  rightAxisTicks: Todo[];
  rightAxisWidth: number;
  showAxisBottom?: boolean;
  showVerticalLines?: boolean;
  translate: string;
  xScale: TodoFunction;
  yLeftScale?: TodoFunction;
  yRightScale?: TodoFunction;
}

export const Chart: React.FC<Props> = ({
  frameHeight,
  frameWidth,
  helpLinesTicks,
  isLeftAxisEnabled = true,
  isLeftAxisInPercent = true,
  isRightAxisEnabled = true,
  isRightAxisInPercent = true,
  leftAxisChartPoints,
  leftAxisEntries,
  leftAxisTicks,
  leftAxisWidth,
  rightAxisChartPoints,
  rightAxisEntries,
  rightAxisTicks,
  rightAxisWidth,
  showAxisBottom = false,
  showVerticalLines = true,
  translate,
  xScale,
  yLeftScale,
  yRightScale,
}) => (
  <svg style={{ width: '100%', height: '100%' }}>
    { isLeftAxisEnabled && (
      <AxisVertical
        scale={yLeftScale}
        width={leftAxisWidth}
        ticks={leftAxisTicks}
        isPercent={isLeftAxisInPercent}
      />
    ) }
    { isRightAxisEnabled && (
      <AxisVertical
        scale={yRightScale}
        width={rightAxisWidth}
        widthOffset={frameWidth}
        ticks={rightAxisTicks}
        isPercent={isRightAxisInPercent}
        inverse
      />
    ) }
    <g transform={translate}>
      <rect
        width={frameWidth}
        height={frameHeight}
        className="chart-border-rect"
        fill="none"
      />
      <HelpLines
        width={frameWidth}
        height={frameHeight}
        xScale={xScale}
        yScale={isLeftAxisEnabled ? yLeftScale : yRightScale}
        ticks={helpLinesTicks}
        showVerticalLines={showVerticalLines}
      />
      <AreaChart
        yLeftScale={yLeftScale}
        yRightScale={yRightScale}
        xScale={xScale}
        height={frameHeight}
        leftAxisChartPoints={leftAxisChartPoints}
        leftAxisEntries={leftAxisEntries}
        rightAxisChartPoints={rightAxisChartPoints}
        rightAxisEntries={rightAxisEntries}
        isLeftAxisEnabled={isLeftAxisEnabled}
        isRightAxisEnabled={isRightAxisEnabled}
      />
    </g>
    { showAxisBottom && (<AxisBottom scale={xScale} height={frameHeight} />)}
  </svg>
);

export default Chart;
