import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { getFiltersQuery } from '../selectors/admin.locations.selector';

export const fetchAdminLocations = createAction(
  'admin: fetch locations',
  (page = 1) => page,
);

export const fetchAdminLocationsSuccess = createAction(
  'admin: fetch locations - success',
  (list, pagination) => ({ list, pagination }),
);

export const fetchAdminLocationsFail = createAction(
  'admin: fetch locations - fail',
  (error) => error,
);

export const reducer = {
  [fetchAdminLocations]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchAdminLocationsSuccess]: (state, { list, pagination }) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        pagination,
        list,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [fetchAdminLocationsFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchAdminLocationsSaga({ payload: page }) {
  const state = yield select();
  const filters = yield call(getFiltersQuery, state);

  const { response, error } = yield call(api.admin.getLocations, { page, ...filters });

  if (response) {
    yield put(fetchAdminLocationsSuccess(response.list, response.pagination));
  } else {
    yield put(fetchAdminLocationsFail(error));
  }
}

export function* watchFetchAdminLocations() {
  yield takeEvery(fetchAdminLocations.getType(), fetchAdminLocationsSaga);
}
