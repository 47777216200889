import { createAction } from '@reduxjs/toolkit';

import { selectAllReports, getReportById } from '../selectors/reports.selector';
import { replaceObjectInList, compareById } from '../../../../common/helpers/object';

export const setReportChartType = createAction(
  'set report chart type',
  (reportId, chartType) => ({ payload: { reportId, chartType } }),
);

export const reducer = {
  [setReportChartType.toString()]: (state, { reportId, chartType }) => {
    const allReports = selectAllReports(state);
    const report = getReportById(reportId, allReports);
    if (!report) return state;

    return {
      ...state,
      reports: {
        ...state.reports,
        data: replaceObjectInList(
          allReports,
          { ...report, chartType },
          compareById,
        ),
      },
    };
  },
};
