import React from 'react';
import PropTypes from 'prop-types';

export default function ReportName({ reportName }) {
  return (
    <span className="report-name">{reportName}</span>
  );
}

ReportName.propTypes = {
  reportName: PropTypes.string,
};
