import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

function Processing({ processing }) {
  return (
    <tr>
      <td>
        <strong>{ processing.product.name }</strong>
      </td>
      <td>
        { processing.comment }
      </td>
      <td>
        { processing.status }
      </td>
      <td>
        { processing.quality }
      </td>
      <td>
        <FormattedDate
          value={new Date(processing.startedAt)}
          year="numeric"
          month="long"
          day="numeric"
          hour="numeric"
          minute="numeric"
        />
      </td>
      <td>
        { processing.duration }
        s
      </td>
    </tr>
  );
}

Processing.propTypes = {
  processing: PropTypes.object.isRequired,
};

export default Processing;
