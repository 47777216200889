import { createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as locationsApi from '../../core/api/locations';

export const fetchLocationById = createAction(
  'fetch location by id',
  (locationId) => ({ payload: locationId }),
);

export const fetchLocationByIdSuccess = createAction(
  'fetch location by id successfully',
  (location) => ({ payload: location }),
);

export const fetchLocationByIdFail = createAction(
  'fetch location by id fail',
  (error) => ({ payload: error }),
);

export const reducer = {
  [fetchLocationById.type]: (state) => ({
    ...state,
    location: {
      ...state.location,
      _status: {
        loaded: false,
        loading: true,
        error: '',
      },
    },
  }),

  [fetchLocationByIdSuccess.type]: (state, location) => ({
    ...state,
    location: {
      ...state.location,
      ...location,
      _status: {
        ...state.location.status,
        loaded: true,
        loading: false,
      },
    },
  }),

  [fetchLocationByIdFail.type]: (state, error) => ({
    ...state,
    location: {
      ...state.location,
      _status: {
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchLocationByIdSaga({ payload: locationId }) {
  const { response, error } = yield call(locationsApi.getById, locationId);

  if (response) {
    yield put(fetchLocationByIdSuccess(response));
  } else {
    yield put(fetchLocationByIdFail(error));
  }
}

export function* watchFetchLocationById() {
  yield takeEvery(fetchLocationById, fetchLocationByIdSaga);
}
