import React from 'react';
import PropTypes from 'prop-types';

export const Option = ({
  selected, name, label, value, onClick,
}) => {
  const handleClick = () => onClick(value);

  return (
    <label className="radio">
      <input
        type="radio"
        name={name}
        checked={selected}
        onChange={handleClick}
      />
      <span>{ label }</span>
    </label>
  );
};

Option.propTypes = {
  selected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Option;
