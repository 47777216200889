import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Status from './status/Status';
import ButtonsContainer from './buttons/Buttons.container';

const dateFormat = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const Measurement = ({ measurement, intl }) => {
  const machine = measurement.machine ? measurement.machine.name : null;
  const start = measurement.start ? intl.formatDate(measurement.start, dateFormat) : '';
  const end = measurement.end ? intl.formatDate(measurement.end, dateFormat) : '';

  return (
    <tr>
      <Status status={measurement.status} />
      <td>{start}</td>
      <td>{end}</td>
      <td>{machine}</td>
      <ButtonsContainer measurement={measurement} />
    </tr>
  );
};

Measurement.propTypes = {
  measurement: PropTypes.object.isRequired,

};

export default injectIntl(Measurement);
