import socket from 'socket.io-client'; // eslint-disable-line import/no-extraneous-dependencies
import * as socketTypes from '../../common/socketTypes';
import { commandJournalMessageSchema } from '../../common/types/api/callback';
import keycloak from './auth/keycloakClient';
import { receivedCommandJournalMessage } from './redux/modules/configRepo.callback.receive.module';
import { receiveComment as receiveDashboardEventComment } from './redux/modules/dashboard.event.comment.receive.module';
import { fetchEvents } from './redux/modules/dashboard.fetch.events.module';
import { receiveDatatron } from './redux/modules/inventory.datatron.receive.module';
import { receiveMachine } from './redux/modules/inventory.machine.receive.module';
import { receiveMachineEventComment } from './redux/modules/machine.event.comment.receive.module';
import { receiveComment as receiveMaintenanceEventComment } from './redux/modules/maintenance.event.comment.receive.module';
import { receiveRoles } from './redux/modules/profile.roles.receive.module';
import store from './redux/store';

export const initializeSocketClient = (): void => {
  const socketClient = socket({
    path: '/socket.io',
    extraHeaders: {
      'x-auth-token': `Bearer ${keycloak.token}`,
    },
  });

  socketClient.on(socketTypes.newComment, (comment) => {
    store.dispatch(receiveDashboardEventComment(comment));
    store.dispatch(receiveMaintenanceEventComment(comment));
    store.dispatch(receiveMachineEventComment(comment));
  });

  socketClient.on(socketTypes.dashboardUpdate, () => {
    const state = store.getState();
    if (state.routing.locationBeforeTransitions.pathname === '/app/dashboard') {
      store.dispatch(fetchEvents(true));
    }
  });

  socketClient.on(socketTypes.newMachine, (machine) => store.dispatch(receiveMachine(machine)));

  socketClient.on(socketTypes.newDatatron, (datatron) => store.dispatch(receiveDatatron(datatron)));

  socketClient.on(socketTypes.userRolesUpdate, (roles) => store.dispatch(receiveRoles(roles)));

  socketClient.on(socketTypes.configRepositoryCallback, (message: any) => {
    const parsed = commandJournalMessageSchema.safeParse(message);
    if (!parsed.success) {
      console.error('parse command journal message: ', parsed.error);
      return;
    }
    store.dispatch(receivedCommandJournalMessage(parsed.data));
  });
};
