import { selector } from './util';

export const isModalOpen = selector((state, id) => !!state.modals.visible[id]);
export const getModalPayload = selector(
  (state, id) => state.modals.payloads[id] || {},
);
export const getModalPath = selector(
  (state, id) => state.modals.paths[id] || [],
);

export const selectCallbackCommandId = selector(
  (state) => state.callback.commandId,
);
export const selectCommandJournalMessages = selector(
  (state) => state.callback.messages,
);

export const selectCommandHistory = selector(
  (state) => state.datatron.deviceCommands,
);
export const selectCommandHistoryLength = selector(
  (state) => state.datatron.deviceCommandTotal,
);
