import moment from 'moment';
import { pick } from 'lodash';

import { DATE_FROM, DATE_TO } from '../../../../common/constants';

export const batch = (state) => state.batchDetails;
export const batchProcessings = (state) => state.batchDetails.processings.list;
export const loading = (state) => state.batchDetails.loading;
export const loaded = (state) => state.batchDetails.loaded;
export const dateFilters = (state) => pick(
  state.batchDetails.processings.filters,
  [DATE_FROM, DATE_TO],
);
export const areDateFiltersSelected = (state) => {
  const dates = dateFilters(state);
  return !!dates[DATE_FROM] && !!dates[DATE_TO];
};

export const getFilters = (state) => {
  const filters = {
    ...dateFilters(state),
  };

  if (filters[DATE_FROM]) {
    filters[DATE_FROM] = moment(filters[DATE_FROM])
      .toISOString();
  }

  if (filters[DATE_TO]) {
    filters[DATE_TO] = moment(filters[DATE_TO])
      .toISOString();
  }

  return filters;
};
