import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

const AddNewAssistanceButton = ({ onClick, isTopButton }) => {
  const classes = classnames(
    'button',
    'green',
    { new_assistance: isTopButton },
  );

  return (
    <button
      type="button"
      className={classes}
      onClick={onClick}
    >
      <span className="fa fa-plus" />
      <FormattedMessage
        id="common.add_new"
        defaultMessage="Add New"
      />
    </button>
  );
};

AddNewAssistanceButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isTopButton: PropTypes.bool,
};

AddNewAssistanceButton.defaultProps = {
  isTopButton: false,
};

export default AddNewAssistanceButton;
