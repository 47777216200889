import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isUndefined } from 'lodash';

import calendarMessages, { getRepeatTypeMessage } from '../../../../../../messages/calendars.messages';
import commonMessages from '../../../../../../messages/common.messages';
import { capitalizeFirstChar } from '../../../../../../../../common/helpers/string.helper';

export const PendingEventInfo = ({
  intl,
  pendingChanges,
}) => {
  let pendingDates = null;

  if (pendingChanges.startDateTime && pendingChanges.endDateTime) {
    pendingDates = {
      start: intl.formatTime(pendingChanges.startDateTime),
      end: intl.formatTime(pendingChanges.endDateTime),
    };
  }

  let pendingRecurrenceType;
  if (!isUndefined(pendingChanges.repeatType)) {
    pendingRecurrenceType = [
      capitalizeFirstChar(intl.formatMessage(calendarMessages.newRepeatTypeTitle)),
      intl.formatMessage(getRepeatTypeMessage(pendingChanges.repeatType)),
    ].join(' ');
  }

  return (
    <p>
      { (pendingDates !== null) && (
        <span>
          <FormattedMessage {...commonMessages.newDate} />
          :
          {' '}
          { pendingDates.start }
          {' '}
          -
          {pendingDates.end}
        </span>
      )}
      { (pendingRecurrenceType !== null) && (
        <>
          <br />
          { pendingRecurrenceType }
        </>
      )}
    </p>
  );
};

PendingEventInfo.propTypes = {

  pendingChanges: PropTypes.object,
};

PendingEventInfo.defaultProps = {
  pendingChanges: {},
};

export default injectIntl(PendingEventInfo);
