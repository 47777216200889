import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { cloneDeep, isEmpty } from 'lodash';

import Modal from '../../../_tools/modal/Modal';
import { FormField, TextareaFormField } from '../../../_tools';
import messages from './ResolveModal.message';
import { validateResolveEvent } from '../../../../core/validation/resolveEvent.validation';
import { resolved } from '../../../../../../common/enums/activityState';

export function ResolveModal({
  isOpen,
  onClose,
  handleSubmit,
  submitting,
  intl,
  onSubmit,
  activityId,
}) {
  const modalLabel = intl.formatMessage(messages.resolve_element);
  const titlePlaceholder = intl.formatMessage(messages.type_title_here);
  const commentsPlaceholder = intl.formatMessage(messages.type_some_text_here);

  function onSubmitClose(values, dispatch) {
    const finishValues = cloneDeep(values);
    finishValues.id = activityId;
    finishValues.state = resolved;

    // Validate on submit in case untouched form won't be validated by redux-form
    const errors = validateResolveEvent(finishValues);

    if (!isEmpty(errors)) {
      return Promise.reject(errors);
    }

    return onSubmit(finishValues, dispatch).then(() => {
      onClose();
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentLabel={modalLabel}
    >

      <div className="modal--header">
        <span className="modal--close" onClick={onClose}>&times;</span>
      </div>
      <div className="modal--content">
        <FormattedMessage
          id="maintenance.stream.event.resolve_element"
          defaultMessage="Resolve Element"
          tagName="h3"
        />
        <form className="form" onSubmit={handleSubmit(onSubmitClose)}>

          <FormField
            label="maintenance.stream.event.resolve.title"
            name="resolvedTitle"
            placeholder={titlePlaceholder}
          />

          <TextareaFormField
            label="maintenance.stream.event.resolve.comments"
            name="resolvedComments"
            placeholder={commentsPlaceholder}
            cols="30"
            rows="10"
          />

          <div className="line" />
          <div className="text-center">
            <button type="button" className="button__cancel" onClick={onClose}>
              <FormattedMessage
                id="common.cancel"
                defaultMessage="Cancel"
              />
            </button>
            <button type="submit" className="button__save" disabled={submitting}>
              <FormattedMessage
                id="common.save"
                defaultMessage="Save"
              />
            </button>
          </div>
        </form>
      </div>

    </Modal>
  );
}

ResolveModal.propTypes = {
  activityId: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,

};

export default injectIntl(ResolveModal);
