export const AI_APP_ANOMALY_FORM_NAME = 'AI_APP_ANOMALY_FORM_NAME';

export const CYCLE_DETECTION_ID = 'cycle_detection_id';
export const CANDIDATE_UUID = 'candidate_uuid';
export const NUM_EPOCHS = 'num_epochs';
export const BATCH_SIZE = 'batch_size';
export const MIN_BATCH_SIZE = 16;
export const MAX_BATCH_SIZE = 256;
export const MIN_NUM_EPOCHS = 10;
export const MAX_NUM_EPOCHS = 200;

export const AI_APP_ANOMALY_PROPS = [CYCLE_DETECTION_ID, CANDIDATE_UUID, NUM_EPOCHS, BATCH_SIZE];

const STATUS = 'status';

export const ANOMALY_MODEL_HEADER = [STATUS, CANDIDATE_UUID, NUM_EPOCHS, BATCH_SIZE];
