import { createAction } from 'redux-act';

export const collapseExpandSensor = createAction(
  'expand or collapse machine sensor',
  (sensorId) => sensorId,
);

export const reducer = {
  [collapseExpandSensor]: (state, sensorId) => ({
    ...state,
    machine: {
      ...state.machine,
      expandedSensors: {
        [sensorId]: !state.machine.expandedSensors[sensorId],
      },
    },
  }),
};
