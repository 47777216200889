import { createAction } from 'redux-act';
import {
  put, call, select, takeLatest,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { find, merge, cloneDeep } from 'lodash';
import { animateScroll } from 'react-scroll';

import * as api from '../../core/api';
import { removeObjectFromList, compareById } from '../../../../common/helpers/object';
import * as form from '../constants/calendar.form.constants';
import { getFormFields } from '../selectors/calendar.form.selector';
import { closeModal } from './modals.module';
import { CP_CALENDAR_EDIT } from '../constants/modals.constants';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';

export const updateCalendar = createAction(
  'update calendar',
  (id) => id,
);

export const updateCalendarSuccess = createAction(
  'update calendar - success',
  (calendar) => calendar,
);

export const updateCalendarFail = createAction(
  'update calendar - fail',
);

export const reducer = {
  [updateCalendarSuccess]: (state, newCalendar) => {
    const oldCalendar = find(state.machineCalendars.list, (item) => item.id === newCalendar.id);
    const calendar = merge(cloneDeep(oldCalendar), cloneDeep(newCalendar));

    return {
      ...state,
      machineCalendars: {
        ...state.machineCalendars,
        list: [
          calendar,
          ...removeObjectFromList(
            state.machineCalendars.list,
            newCalendar,
            compareById,
          ),
        ],
      },
    };
  },
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.CALENDAR_FORM_NAME,
    ...form.CALENDAR_PROPS,
  ));
}

export function* updateCalendarSaga({ payload: id }) {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const fields = yield call(getFormFields, state);
  yield put(startSubmit(form.CALENDAR_FORM_NAME));

  const { response, error } = yield call(api.calendars.update, id, fields);

  if (response) {
    yield put(updateCalendarSuccess(response));
    yield put(stopSubmit(form.CALENDAR_FORM_NAME));
    yield put(closeModal(CP_CALENDAR_EDIT));
    yield call(animateScroll.scrollToTop);
    yield put(sendNotification({ ...notificationMessage.calendar_renamed, values: { name: response.name } }));
  } else {
    yield put(updateCalendarFail(id, error));
    yield put(stopSubmit(form.CALENDAR_FORM_NAME, error));
  }
}

export function* watchUpdateCalendar() {
  yield takeLatest(updateCalendar.getType(), updateCalendarSaga);
}
