export const initializeLocation = (location) => ({
  ...location,
  _update: {
    loaded: false,
    loading: false,
    error: null,
  },
  _remove: {
    loaded: false,
    loading: false,
    error: null,
  },
});

export const initializeUser = (user) => ({
  ...user,
  _update: {
    loaded: false,
    loading: false,
    error: null,
  },
  _remove: {
    loaded: false,
    loading: false,
    error: null,
  },
});

export const initializeOrganization = (organization) => ({
  ...organization,
  _update: {
    loaded: false,
    loading: false,
    error: null,
  },
  _remove: {
    loaded: false,
    loading: false,
    error: null,
  },
});

export const initializeAccount = (account) => ({
  ...account,
  _update: {
    loaded: false,
    loading: false,
    error: null,
  },
});
