import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import BasicModal from '../../../../modals/core/BasicModal';
import { getAdminOrganizationsLink } from '../../../../index/routes';
import commonCoreMessages from '../../../../../messages/common.core.messages';
import adminPageMessages from '../../../../../messages/adminPage.messages';
import Form from './form/OrganizationForm.container';
import * as routeHandlers from '../../../../index/routeHandlers';

const useTitleAndHandleSubmit = (
  isAddForm,
  adminCreateOrganization,
  adminUpdateOrganization,
) => {
  const { formatMessage } = useIntl();
  const object = formatMessage(commonCoreMessages.organization);
  return isAddForm ? {
    modalTitle: formatMessage(adminPageMessages.addObject, { object }),
    handleSubmit: (e) => {
      e.preventDefault();
      adminCreateOrganization();
    },
  } : {
    modalTitle: formatMessage(adminPageMessages.editObject, { object }),
    handleSubmit: (e) => {
      e.preventDefault();
      adminUpdateOrganization();
    },
  };
};

export const OrganizationModuleV2 = ({
  isAddForm,
  isEditForm,
  initialValues,
  loaded,
  adminCreateOrganization,
  adminUpdateOrganization,
  history,
  match,
}) => {
  // TODO: fix me, avoid suppressing the linter
  useEffect(() => {
    if (isEditForm) {
      routeHandlers.onAdminOrganizationEnter(match);
    }
    return () => {
      routeHandlers.onAdminOrganizationEnter(match);
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = useCallback(() => history.push(getAdminOrganizationsLink()), [history]);
  const { modalTitle, handleSubmit } = useTitleAndHandleSubmit(isAddForm, adminCreateOrganization, adminUpdateOrganization);

  if (!loaded) return null;

  return (
    <BasicModal close={handleCancel} modalClass="modal--sm">
      <div className="modal-overflow">
        <div className="modal--content">
          <h3>{modalTitle}</h3>
          <Form
            isAddForm={isAddForm}
            isEditForm={isEditForm}
            initialValues={initialValues}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </BasicModal>
  );
};

OrganizationModuleV2.propTypes = {
  loaded: PropTypes.bool.isRequired,
  isAddForm: PropTypes.bool,
  isEditForm: PropTypes.bool,
  initialValues: PropTypes.object,
  adminCreateOrganization: PropTypes.func,
  adminUpdateOrganization: PropTypes.func,
};

OrganizationModuleV2.defaultProps = {
  isAddForm: false,
  isEditForm: false,
  initialValues: {},
};

export default OrganizationModuleV2;
