
type LocationT = {
  id: number;
  name: string;
  type: string;
  child?: LocationT[];
  selected?: boolean;
};

export type LocationPickerState = {
  tree: LocationT[];
  selectedLocationId: number | null;
  latestLoadedParentId: number | null;
  loaded: boolean;
  loading: boolean;
  error: null | Error;
};

export const locationPickerInitialState: LocationPickerState = {
  tree: [],
  selectedLocationId: null,
  latestLoadedParentId: null,
  loaded: false,
  loading: false,
  error: null,
};

export const locationPickerTestState: LocationPickerState = {
  tree: [
    {
      id: 1,
      name: 'North America',
      type: 'continent',
      child: [
        {
          id: 3,
          name: 'United States',
          type: 'country',
          selected: true,
        },
        {
          id: 4,
          name: 'Canada',
          type: 'country',
        },
      ],
    },
    {
      id: 2,
      name: 'South America',
      type: 'continent',
    },
  ],
  selectedLocationId: 3,
  latestLoadedParentId: 1,
  loaded: false,
  loading: false,
  error: null,
};
