import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { FormField, DynamicField } from '../../../../_tools';
import commonMessages from '../../../../../messages/common.messages';
import commonFieldsMessages from '../../../../../messages/common.fields.messages';
import * as form from '../../../../../redux/constants/datatron.device.form.constants';
import DeviceTypeSelectContainer from './DeviceTypeSelect.container';

const DeviceForm = ({
  canSelectDeviceType, canSetDeviceUuid, deviceClass, additionalFields,
}) => (
  <div className="modal--scroll">
    <label>
      <FormattedMessage
        id="datatron.modals.add_device.fields.select_device_type"
        defaultMessage="Select Device Type"
      />
      <Field
        name={form.DEVICE_TYPE_FIELD}
        component={DeviceTypeSelectContainer}
        deviceClass={deviceClass}
        disabled={!canSelectDeviceType}
      />
    </label>

    <FormField
      label={commonMessages.name.id}
      name={form.NAME_FIELD}
    />

    <FormField
      label={commonFieldsMessages.serialNumber.id}
      name={form.SERIAL_NUMBER_FIELD}
    />

    <FormField
      label={commonFieldsMessages.deviceUUID.id}
      name={form.DEVICE_UUID_FIELD}
      canGenerateUUID
      disabled={!canSetDeviceUuid}
    />

    { additionalFields.map((field) => <DynamicField key={field._key} field={field} />) }
  </div>
);

DeviceForm.propTypes = {
  canSelectDeviceType: PropTypes.bool.isRequired,
  canSetDeviceUuid: PropTypes.bool.isRequired,
  deviceClass: PropTypes.string.isRequired,
  additionalFields: PropTypes.array.isRequired,
};

export default DeviceForm;
