import { defineMessages } from 'react-intl';

import * as dataPointCategories from '../../../../../common/enums/dataPointCategories';

export default defineMessages({
  fatal: {
    id: 'notifications.fatal',
    defaultMessage: 'Fatal',
  },
  warning: {
    id: 'notifications.warning',
    defaultMessage: 'Warning',
  },
  anomaly: {
    id: 'notifications.anomaly',
    defaultMessage: 'Anomaly',
  },
  info: {
    id: 'notifications.info',
    defaultMessage: 'Info',
  },
  error: {
    id: 'notifications.error',
    defaultMessage: 'Error',
  },
  [dataPointCategories.FATAL]: {
    id: 'notifications.fatal',
    defaultMessage: 'Fatal',
  },
  [dataPointCategories.WARNING]: {
    id: 'notifications.warning',
    defaultMessage: 'Warning',
  },
  [dataPointCategories.ANOMALY]: {
    id: 'notifications.anomaly',
    defaultMessage: 'Anomaly',
  },
  [dataPointCategories.INFO]: {
    id: 'notifications.info',
    defaultMessage: 'Info',
  },
  [dataPointCategories.ERROR]: {
    id: 'notifications.error',
    defaultMessage: 'Error',
  },
  available: {
    id: 'notifications.available',
    defaultMessage: 'Available',
  },
});
