import { cloneDeep } from 'lodash';
import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as machinesApi from '../../core/api/machines';
import * as calendarsApi from '../../core/api/calendars';
import { initializeMachine } from '../../core/common/machine';
import { basic } from '../state/machine.state';
import { fetchMachineCalendarsEvents } from './machine.calendars.fetch.events.module';
import { searchUsersByOrganization } from './users.organization.search.module';
import { getDefaultCalendarEventsDates } from '../../core/common/calendar';
import { machineCalendarsInitialState } from '../state/machineCalendars.state';
import { fetchEventsSummaryByMachine } from './machine.fetch.summary.module';
import { fetchClustersSummaryByMachine } from './machine.fetch.clusters.summary.module';
import { setCalendarBackendAvailability } from './calendars.available.module';
import { fetchMachineCalendars } from './calendars.fetch.module';
import { fetchMachineDataPoints } from './machine.dataPoints.fetch.module';
import { fetchMachineOee } from './machine.oee.fetch.module';
import { fetchMachineTools } from './machine.tools.fetch.module';

const ACCESS_DENIED = 401;

export const fetchMachine = createAction(
  'fetch machine',
  (machineId) => machineId,
);

export const fetchMachineSuccess = createAction(
  'fetch machine successfully',
  (machine) => machine,
);

export const fetchMachineFail = createAction(
  'fetch machine fail',
  (error) => error,
);

export const resetMachine = createAction(
  'reset machine',
);

export const fetchMachineData = createAction(
  'fetch machine data',
  (params) => params,
);

export const reducer = {
  [fetchMachine]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      loaded: false,
      loading: true,
      error: '',
    },
  }),

  [fetchMachineSuccess]: (state, machine) => ({
    ...state,
    machine: {
      ...state.machine,
      details: initializeMachine(machine),
      loaded: true,
      loading: false,
    },
  }),

  [fetchMachineFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      loaded: false,
      loading: false,
      error,
    },
  }),

  [resetMachine]: (state) => ({
    ...state,
    machine: {
      ...cloneDeep(basic),
      tab: state.machine.resetSelectedTab ? basic.tab : state.machine.tab,
      resetSelectedTab: state.machine.resetSelectedTab,
    },
    machineCalendars: cloneDeep(machineCalendarsInitialState),
  }),
};

export function* fetchMachineExtraData(machineId) {
  if (!machineId) {
    return;
  }

  yield put(fetchEventsSummaryByMachine(machineId));
  yield put(fetchClustersSummaryByMachine(machineId));
  yield put(fetchMachineOee(machineId));
  yield put(fetchMachineDataPoints(machineId));
  yield put(fetchMachineTools(machineId));

  const { response, status } = yield call(calendarsApi.checkCalendarAvailable);
  const isCalendarAccess = status !== ACCESS_DENIED;
  const isCalendarAvailable = isCalendarAccess && response.available;
  yield put(setCalendarBackendAvailability({
    isCalendarAvailable,
    isCalendarAccess,
  }));
  if (isCalendarAvailable) {
    yield put(fetchMachineCalendars(false));
  }
}

export function* fetchMachineSaga({ payload: machineId }) {
  const { response, error } = yield call(machinesApi.getMachine, machineId);

  if (response) {
    yield put(fetchMachineSuccess(response));
    yield put(fetchMachineCalendarsEvents(getDefaultCalendarEventsDates()));
    yield put(searchUsersByOrganization(response.organization.id, ''));
    yield call(fetchMachineExtraData, machineId);
  } else {
    yield put(fetchMachineFail(error));
  }
}

export function* watchFetchMachine() {
  yield takeEvery(fetchMachine.getType(), fetchMachineSaga);
}

export function* fetchMachineDataSaga({ payload }) {
  const machineId = payload.machine;

  if (!machineId) {
    return;
  }

  yield put(fetchMachine(machineId));
}

export function* watchFetchMachineData() {
  yield takeEvery(fetchMachineData.getType(), fetchMachineDataSaga);
}
