import {
  REPORTS_MANAGER, Role,
} from '../constants/permissions';

class ReportsPermissions {
  private isReportsManager: boolean;

  constructor(roles: Role[]) {
    this.isReportsManager = roles.indexOf(REPORTS_MANAGER) !== -1;
  }

  canCloneReports() {
    return this.isReportsManager;
  }

  canCreateReports() {
    return this.isReportsManager;
  }

  canUpdateReports() {
    return this.isReportsManager;
  }

  canDeleteReports() {
    return this.isReportsManager;
  }

  canManageReportsTabs() {
    return this.isReportsManager;
  }

  availableToAll() {
    return this.isReportsManager;
  }
}

export default ReportsPermissions;
