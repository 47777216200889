import { createAction } from 'redux-act';

export const switchTimezoneMode = createAction(
  'switch timezone mode for data point metric',
);

export const reducer = {
  [switchTimezoneMode]: (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      dataPointMetric: {
        ...state.datatron.dataPointMetric,
        utcMode: !state.datatron.dataPointMetric.utcMode,
      },
    },
  }),
};
