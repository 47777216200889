import React from 'react';
import PropTypes from 'prop-types';
import { line } from 'd3-shape';

export default function Line({
  data, className, getX, getY, xScale, yScale,
}) {
  const path = line()
    .x((d) => xScale(getX(d)))
    .y((d) => yScale(getY(d)))
    .defined((d) => getY(d) !== null)(data);

  return (
    <path
      d={path}
      className={className}
    />
  );
}

Line.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  getX: PropTypes.func.isRequired,
  getY: PropTypes.func.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
};
