import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DATATRONS_PERMISSIONS } from '../../../../../common/constants/permissions';
import PermissionedComponent from '../../common/permissions/PermissionedComponent.container';
import { getDatatronEditLink } from '../../index/routes';

interface Props {
  datatronId: string;
  canUpdateDatatrons: boolean;
}

const CardEditButton: React.FC<Props> = ({ datatronId, canUpdateDatatrons }) => {
  if (!canUpdateDatatrons) return null;

  return (
    <Link
      className="button button__xs white"
      type="button"
      to={getDatatronEditLink(datatronId)}
    >
      <i className="icon-edit" />
      <FormattedMessage id="common.edit" defaultMessage="Edit" />
    </Link>
  );
};

export const mapStateToProps = (state, { permissions }) => {
  const canUpdateDatatrons = permissions.canUpdateDatatrons();
  return { canUpdateDatatrons };
};

export default PermissionedComponent(DATATRONS_PERMISSIONS)(
  connect(mapStateToProps)(CardEditButton),
);
