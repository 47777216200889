import { defineMessages } from 'react-intl';

export default defineMessages({
  red: {
    id: 'colors.red',
    defaultMessage: 'Red',
  },
  orange: {
    id: 'colors.orange',
    defaultMessage: 'Orange',
  },
  yellow: {
    id: 'colors.yellow',
    defaultMessage: 'Yellow',
  },
  green: {
    id: 'colors.green',
    defaultMessage: 'Green',
  },
  cyan: {
    id: 'colors.cyan',
    defaultMessage: 'Cyan',
  },
  blue: {
    id: 'colors.blue',
    defaultMessage: 'Blue',
  },
  purple: {
    id: 'colors.purple',
    defaultMessage: 'Purple',
  },
  brown: {
    id: 'colors.brown',
    defaultMessage: 'Brown',
  },
  pink: {
    id: 'colors.pink',
    defaultMessage: 'Pink',
  },
});
