import { useCallback, useState } from 'react';

/**
 * A hook that returns an isOpen state and functions to open, close and toggle
 * the state.
 */
export const useOpen = () => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((prev) => !prev), []);
  return { isOpen, open, close, toggle };
};
