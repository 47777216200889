import { cloneDeep } from 'lodash';

import moment from 'moment';
import { parseSchema } from '../../../../common/helpers/json.schema';
import { applyCustomOrder } from '../../../../common/helpers/object';
import { flatSchemaToFields } from './datatron.deviceType';
import { getDatatronStats } from '../api/datatrons';
import { Todo } from '../../../../common/types/common';

export const fetchDatatronStats = async (datatronId, networkSpeed, nodeIdentifier) => {
  const startTime = moment().startOf('hour').subtract(4, 'hours');

  const isArray = (item) => typeof item === 'object' && item.length !== undefined;
  // TODO remove ts-ignore
  // @ts-ignore
  const { response } = await getDatatronStats(datatronId, {
    node: nodeIdentifier,
    networkSpeed: networkSpeed ?? 1,
    startTime: startTime.unix(),
    endTime: null,
    step: null,
  });
  return {
    stats: {
      total: {
        datatronCpu: !isArray(response?.cpu) ? -1 : response.cpu.slice(-1),
        datatronDisk: !isArray(response?.disk) ? -1 : response.disk.slice(-1),
        datatronNetwork: !isArray(response?.network) ? -1 : response.network.slice(-1),
        datatronRam: !isArray(response?.mem) ? -1 : response.mem.slice(-1),
      },
      chart: [
        {
          startAt: startTime.toISOString(),
          period: 'hour',
          datatronCpu: !isArray(response?.cpu) ? -1 : response.cpu,
          datatronDisk: !isArray(response?.disk) ? -1 : response.disk,
          datatronNetwork: !isArray(response?.network) ? -1 : response.network,
          datatronRam: !isArray(response?.mem) ? -1 : response.mem,
        },
      ],
    },
  };
};

// Device Type fields sorting
export const FIRST_FIELDS_ORDER = [
  'enabled',
  'uuid',
  'label',
  'rate',
  'isMaxRate',
  'unit',
  'scalingFactor',
  'scalingOffset',
];

const getFieldKey = (field) => field._key;
const getConfigFieldKey = (configField) => configField.field._key;

export const applyCustomOrderOnFields = (fields, order: string[] = []) =>
  applyCustomOrder(fields, order, getFieldKey);
export const applyCustomOrderOnConfigFields = (fields, order: Todo[] = []) =>
  applyCustomOrder(fields, order, getConfigFieldKey);

export const initializeDatatron = (datatron) => ({
  ...datatron,
  ...fetchDatatronStats(datatron.id, datatron.networkSpeed, {
    instance: datatron.ipAddress || 'node-exporter:9100',
  }),
});

export const initializeDevice = (device) => ({
  ...device,
  dataPoints: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    query: '',
    sort: {
      option: null,
      way: null,
    },
  },
  archivedDataPoints: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    query: '',
    sort: {
      option: null,
      way: null,
    },
  },
  _update: {
    isRenaming: false,
    loaded: false,
    loading: false,
    error: null,
  },
  _archive: {
    loaded: false,
    loading: false,
    error: null,
  },
});

export const initializeDeviceType = async (deviceType) => {
  const hasDeviceSchema = !!deviceType && !!deviceType.deviceSchema;
  const hasDataPointSchema = !!deviceType && !!deviceType.dataPointSchema;

  const [parsedDeviceSchema, parsedDataPointSchema] = await Promise.all([
    hasDeviceSchema && parseSchema(deviceType.deviceSchema),
    hasDataPointSchema && parseSchema(deviceType.dataPointSchema),
  ]).catch((err) => [err, err]);

  const result = {
    ...cloneDeep(deviceType),
    hasDeviceSchema,
    hasDataPointSchema,
  };

  if (hasDeviceSchema) {
    result.parsedDeviceSchema = parsedDeviceSchema;
    result.deviceFields = applyCustomOrderOnFields(
      flatSchemaToFields(parsedDeviceSchema),
      FIRST_FIELDS_ORDER,
    );
  }
  if (hasDataPointSchema) {
    result.parsedDataPointSchema = parsedDataPointSchema;
    result.dataPointFields = applyCustomOrderOnFields(
      flatSchemaToFields(parsedDataPointSchema),
      FIRST_FIELDS_ORDER,
    );
  }

  return result;
};

export const initializeDeviceTypes = async (deviceTypes) =>
  Promise.all(deviceTypes.map(initializeDeviceType));

export type DatatronOptionSelect = {
  value: string;
  label: string;
};
export const initializeDatatronAsOption = (datatron): DatatronOptionSelect => ({
  value: datatron.id as string,
  label: datatron.name as string,
});
