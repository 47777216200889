import React from 'react';
import Busy from '../_tools/Busy';
import { Todo } from '../../../../common/types/common';

interface Props {
  shown: boolean
  children?: Todo,
}

export const PageSpinner: React.FC<Props> = ({ shown, children }) => {
  if (!shown) return children;

  return (
    <div className="fullViewportHeight">
      <Busy isBusy/>
    </div>
  );
};

export default PageSpinner;