import React from 'react';
import PropTypes from 'prop-types';
import { area } from 'd3-shape';

export default function Area({
  chartPoints, xScale, yScale, getX, getY, color, fillOpacity,
}) {
  const path = area()
    .x((d) => xScale(getX(d)))
    .y0(yScale(0))
    .y1((d) => yScale(getY(d)))
    .defined((d) => getY(d) !== null)(chartPoints);

  const style = {
    fill: `#${color}`,
    opacity: fillOpacity,
  };

  return (<path d={path} style={style} />);
}

Area.propTypes = {
  chartPoints: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  fillOpacity: PropTypes.number.isRequired,
  getX: PropTypes.func.isRequired,
  getY: PropTypes.func.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
};
