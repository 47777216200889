import React from 'react';
import PropTypes from 'prop-types';

import ConfirmRemoveModal from '../../../../_tools/confirmation/ConfirmRemoveModal';
import * as inventoryTypes from '../../../../../../../common/constants/inventory';
import MachineAssistantInfo from './machine/MachineAssistantInfo';
import DatatronAssistantInfo from './datatron/DatatronAssistantInfo';

const RemoveAssistantModal = ({
  close, removeAssistant, inventoryType, target, assistant,
}) => {
  const handleConfirm = () => removeAssistant(target.id, assistant.id);

  return (
    <ConfirmRemoveModal
      isOpen
      onClose={close}
      onCancel={close}
      onConfirm={handleConfirm}
    >
      { (inventoryType === inventoryTypes.INVENTORY_MACHINES) && (
        <MachineAssistantInfo
          target={target}
          assistant={assistant}
        />
      ) }
      { (inventoryType === inventoryTypes.INVENTORY_DATATRONS) && (
        <DatatronAssistantInfo
          target={target}
          assistant={assistant}
        />
      )}
    </ConfirmRemoveModal>
  );
};

RemoveAssistantModal.propTypes = {
  close: PropTypes.func.isRequired,
  inventoryType: PropTypes.string.isRequired,
  assistant: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  removeAssistant: PropTypes.func.isRequired,
};

export default RemoveAssistantModal;
