import React from 'react';
import PropTypes from 'prop-types';

import MachineFormPage from './MachineFormPage';
import Unauthorized from '../pages/unauthorized/Unauthorized';

export const UpdateMachineFormPage = ({ loaded, canUpdateMachines, ...props }) => {
  if (!canUpdateMachines) return <Unauthorized />;
  if (!loaded) return null;
  return <MachineFormPage {...props} />;
};

UpdateMachineFormPage.propTypes = {
  loaded: PropTypes.bool.isRequired,
  canUpdateMachines: PropTypes.bool.isRequired,
};

export default UpdateMachineFormPage;
