import { connect } from 'react-redux';

import LocationRow from './LocationRow';
import { openModal } from '../../../../redux/modules/modals.module';
import { AP_LOCATION_REMOVE } from '../../../../redux/constants/modals.constants';

export const mapDispatchToProps = (dispatch) => ({
  onRemoveClick: (location) => dispatch(openModal(AP_LOCATION_REMOVE, { location })),
});

export default connect(null, mapDispatchToProps)(LocationRow);
