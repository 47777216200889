export const ADMIN_LOCATION_FORM_NAME = 'ADMIN_LOCATION_FORM_NAME';
export const NAME_FIELD = 'name';
export const TYPE_FIELD = 'type';
export const PARENT_ID_FIELD = 'parentId';

export const LOCATION_PROPS = [
  NAME_FIELD,
  TYPE_FIELD,
  PARENT_ID_FIELD,
];
