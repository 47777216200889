import { createAction } from 'redux-act';
import {
  put, delay, takeEvery,
} from 'redux-saga/effects';

import { removeObjectFromList, compareByItself } from '../../../../common/helpers/object';

const UNHIGHLIGHT_TIMEOUT = 2000;

export const highlightDataPoint = createAction(
  'highlight data point row',
  (dataPointId) => dataPointId,
);

export const unhighlightDataPoint = createAction(
  'unhighlight data point row',
  (dataPointId) => dataPointId,
);

export const reducer = {
  [highlightDataPoint]: (state, dataPointId) => ({
    ...state,
    datatron: {
      ...state.datatron,
      highlightedDataPointIds: [
        ...state.datatron.highlightedDataPointIds,
        dataPointId,
      ],
    },
  }),

  [unhighlightDataPoint]: (state, dataPointId) => ({
    ...state,
    datatron: {
      ...state.datatron,
      highlightedDataPointIds: removeObjectFromList(
        state.datatron.highlightedDataPointIds,
        dataPointId,
        compareByItself,
      ),
    },
  }),
};

export function* highlightDataPointSaga({ payload: dataPointId }) {
  yield delay(UNHIGHLIGHT_TIMEOUT);
  yield put(unhighlightDataPoint(dataPointId));
}

export function* watchHighlightDataPoint() {
  yield takeEvery(highlightDataPoint.getType(), highlightDataPointSaga);
}
