import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LocationLabel from '../path/LocationLabel';
import Name from '../path/Name';
import StatusIcon from '../StatusIcon';
import { getExplainedPath } from '../../../../core/common/machine';
import { getDatatronLink } from '../../../index/routes';

class Datatron extends Component {
  static propTypes = {
    datatron: PropTypes.object.isRequired,
    selectedLocation: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { datatron, selectedLocation } = this.props;

    this.explainedPath = getExplainedPath({
      path: datatron.location.path,
      isSelectedLocation: datatron.location.isSelectedLocation,
      name: selectedLocation.name,
    });
  }

  render() {
    const datatron = this.props.datatron;

    return (
      <tr>
        <StatusIcon isAvailable />
        <Name label={datatron.name} link={getDatatronLink(datatron.id)} />
        <LocationLabel
          location={datatron.location}
          explainedPath={this.explainedPath}
        />
      </tr>
    );
  }
}

export default Datatron;
