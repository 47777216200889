import { apiRequest } from './_tools';

export function search(query) {
  return apiRequest(`/api/organizations?q=${query}`);
}

export function getById(id) {
  return apiRequest(`/api/organizations/${id}`);
}

export function getByUserId(userId) {
  return apiRequest(`/api/organizations/users/${userId}`);
}
