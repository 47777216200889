import React from 'react';
import PropTypes from 'prop-types';
import { FormattedTime, FormattedDate } from 'react-intl';
import classnames from 'classnames';
import stopPropagation from '../../_tools/stopPropagation.js';

export default function EventFooter({
  timestamp, commentsCount, eventCount, showTrain, onTrainClick,
}) {
  const commentsCountCss = classnames(
    'link-text',
    { passive: (commentsCount === 0) },
  );

  const trainCountCss = classnames(
    'link-text',
    { passive: (eventCount === 0) },
  );

  return (
    <div className="position-text--bottom">
      <div className="position-list">
        <div className="position-list--left">
          <ul className="inline-list">
            <li onClick={stopPropagation} className="inline-list--date">
              <span className="icon icon-calendar" />
              <FormattedDate value={timestamp} />
            </li>
            <li onClick={stopPropagation} className="inline-list--time">
              <span className="icon icon-time-filled" />
              <FormattedTime value={timestamp} />
            </li>
            <li>
              <a className={commentsCountCss}>
                <span className="icon icon-comment" />
                {commentsCount}
              </a>
            </li>
            {
              showTrain && (
                <li>
                  <a className={trainCountCss} onClick={onTrainClick}>
                    <span className="icon icon-locomotive" />
                    {eventCount}
                  </a>
                </li>
              )
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

EventFooter.propTypes = {
  timestamp: PropTypes.string.isRequired,
  commentsCount: PropTypes.number.isRequired,
  eventCount: PropTypes.number,
  showTrain: PropTypes.bool.isRequired,
  onTrainClick: PropTypes.func.isRequired,
};
