import React, { useCallback, useEffect, useState } from 'react';
import { getLocationPath } from '../../../core/common/location';
import { selectProfileLocation } from '../../../redux/selectors/profile.selector';
import { useDispatch, useSelector } from 'react-redux';
import { setLocationFilter } from '../../../redux/modules/location.filter.module';
import { LocationItem } from './LocationItem';
import { selectLocationFilter, selectLocationFilterChildren } from '../../../redux/selectors/location.selector';
import { findByName } from '../../../core/api/locations';
import { isArray, isUndefined } from 'lodash';
import { useHistory } from 'react-router';


const useFindLocationPath = async (locationName: string) => {
  const locationFetcher = useCallback(async (): Promise<string[]> => {
    if (!locationName) return [];
    const locationResponse = await findByName(locationName);

    if (locationResponse.status === 200) {
      // @ts-ignore
      return getLocationPath(locationResponse.response.list[0].name, locationResponse.response.list[0].path);
    }

    return [];
  }, [locationName]);
  return locationFetcher();
};

export const LocationBreadcrumbs: React.FC = () => {
  const history = useHistory();

  const location = useSelector(selectProfileLocation);

  const children = useSelector(selectLocationFilterChildren);
  // const childrenArrayEntry = children[0];
  const [locationPathArray, locationPathArraySet] = useState<string[]>(location ? getLocationPath(location.name, location.path) : []);

  const filterStartLocation = useSelector(selectLocationFilter);
  const startPath = useFindLocationPath(filterStartLocation);

  const dispatch = useDispatch();
  // TODO: fix me, avoid suppressing the linter
  useEffect(() => {
    startPath.then((path: string[]) => {
      if (isArray(path) && !isUndefined(filterStartLocation)) {
        dispatch(setLocationFilter(filterStartLocation));
        locationPathArraySet(path);
      } else if (locationPathArray.length > 0) {
        dispatch(setLocationFilter(location.name));
      } else {
        dispatch(setLocationFilter(''));
      }
    });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  const enhancedArray = locationPathArray.concat(children.length > 0 ? [`select ${children[0]?.type}`] : []);

  const handleChildClick = (newLocation: string) => {
    if (newLocation === '') {
      locationPathArraySet([]);
    } else {
      const index = locationPathArray.indexOf(newLocation);
      if (index === -1)
        locationPathArraySet(locationPathArray.concat([newLocation]));
      else
        locationPathArraySet(locationPathArray.slice(0, index + 1));
    }

    if (history.location.pathname.indexOf('/app/inventory') === 0) {
      history.push('/app/inventory/');
    }
  };

  const handleHomeClick = (e) => {
    e.preventDefault();
    dispatch(setLocationFilter(''));
    handleChildClick('');
  };

  return (
    <ul className="menu breadcrumps-menu">
      <li>
        <div className="breadcrumps-title breadcrumps-home" onClick={handleHomeClick} style={{ position: 'relative' }}>
          <span className="icon-home"/>
          {(enhancedArray.length > 0) && (<span className="fa fa-chevron-right"/>)}
        </div>
      </li>
      {
        enhancedArray.map(
          (locationName, index, array) => <LocationItem key={locationName} index={index} locationName={locationName}
                                                        array={array} parentHandler={handleChildClick}
                                                        children={index === array.length - 1 ? children : undefined}/>)
      }
    </ul>
  );
};

export default LocationBreadcrumbs;
