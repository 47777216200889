import React, { useEffect } from 'react';
import { Todo, TodoFunction } from '../../../../../../common/types/common';
import { ArrayOuterIndex } from './ArrayOuterIndex';
import {
  ensureIndicesFitDimensions,
  generateNewState,
  indicesAreValid,
} from '../dataPointForm/helpers';
import { isUndefined } from 'lodash';
import { ArrayFullDisplay } from '../dataPointForm/arrayPage/ArrayFullDisplay';

interface Props {
  indicesParentSet: TodoFunction;
  dimensions: Todo;
  arrayIndex: Todo;
  type: string;
}

/**
 * Handles all input-related logic for the array indices.
 * The component is not aware of the expected index format, generating that based on the definition of the dimensions.
 */
export const ArrayIndexForm: React.FC<Props> = ({
  indicesParentSet,
  dimensions,
  type,
  arrayIndex,
}) => {
  /**
   * Since indices aren't aware of their expected format, ensure they fit the dimensions, and insert default values if necessary.
   */

  /**
   * If the arrayIndex uses a default value, set hte state to a default vlaue based on the defined dimensions
   */
  useEffect(() => {
    if (arrayIndex === '' && !isUndefined(dimensions)) {
      const starterIndices = ensureIndicesFitDimensions(arrayIndex, dimensions);
      indicesParentSet(starterIndices);
    }
  }, [arrayIndex, dimensions, indicesParentSet]);

  if (isUndefined(dimensions)) return <></>;

  /**
   * use generateNewState, which used to be a react reducer, to generate the new state to propagate to the
   * form state
   */
  const updateState = ({ index, newValue }) => {
    const newState = generateNewState(arrayIndex, { index, newValue });

    const error = indicesAreValid({ index: newState, dimensions })
      ? undefined
      : 'Invalid index';

    indicesParentSet(newState, error);
  };

  /**
   * Update the state without needing children to be aware of their own index.
   */
  const updateChild = (index: number | undefined) => (childIndex, newValue) => {
    if (isUndefined(index)) updateState({ index: childIndex, newValue });
    else updateState({ index: [index, ...childIndex], newValue });
  };

  const indexElement = !Array.isArray(arrayIndex[0]) ? (
    <ArrayOuterIndex
      dimensions={dimensions}
      indices={arrayIndex}
      updateParent={updateChild(undefined)}
    />
  ) : (
    dimensions.map((dimension, index) => (
      <ArrayOuterIndex
        dimensions={dimension}
        indices={arrayIndex[index]}
        updateParent={updateChild(index)}
      />
    ))
  );

  return (
    <div style={{ fontSize: '20px' }}>
      <ArrayFullDisplay dimensions={dimensions} type={type} />
      <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
        {indexElement}
      </div>
    </div>
  );
};
