import { connect } from 'react-redux';

import Events from './Events';
import * as selector from '../../redux/selectors/dashboard.selector';
import { loadMoreEvents } from '../../redux/modules/dashboard.loadMode.events.module';

export function mapStateToProps(state) {
  return {
    events: selector.filteredEvents(state),
    noEvents: selector.hasNoEvents(state),
    isLoading: selector.eventsLoading(state),
    isUpdating: selector.isUpdating(state),
  };
}

export const mapDispatchToProps = {
  loadMoreEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
