import { connect } from 'react-redux';

import FormBody from './FormBody';
import * as form from '../../../../redux/constants/report.legend.dataPoint.form.constants';
import { getFormFields } from '../../../../redux/selectors/report.legend.dataPoint.form.selector';

export const mapStateToProps = (state) => {
  const formValues = getFormFields(state);

  const fillOpacity = formValues[form.FILL_OPACITY_FIELD];
  const lineWidth = formValues[form.LINE_WIDTH_FIELD];

  return {
    canSelectZeroLineWidth: fillOpacity > 0,
    canSelectZeroFillOpacity: lineWidth > 0,
  };
};

export default connect(mapStateToProps)(FormBody);
