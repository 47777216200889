import { forEach } from 'lodash';

import { reportsEntities } from '../constants/entities';
import * as reportTypes from '../enums/reportTypes';
import * as periodTypes from '../enums/periodTypes';
import * as datesModesOptions from '../enums/dates.modes';
import * as chartVersions from '../constants/chart.versions';

export const areFiltersEmpty = (filters) => {
  let result = true;

  forEach(
    reportsEntities,
    (entity) => {
      if (filters[entity] && filters[entity].length) {
        result = false;
      }
    },
  );

  return result;
};

export const doReportFiltersContainMultipleCategories = (filters) => {
  let filledFiltersCategoriesCount = 0;

  forEach(
    reportsEntities,
    (entity) => {
      if (filters[entity] && filters[entity].length > 0) {
        filledFiltersCategoriesCount++;
      }
    },
  );

  return filledFiltersCategoriesCount > 1;
};

export const getReportDefaultData = (type) => {
  let data = null;

  switch (type) {
    case reportTypes.events: {
      data = {
        hasTotalData: true,
        hasChartsData: true,
        chartVersion: chartVersions.CHART_VERSION_V1,
        total: periodTypes.events.reduce((final, current) => ({
          ...final,
          [current]: 0,
        }), {}),
        chart: [],
      };
      break;
    }
    case reportTypes.maintenance: {
      data = {
        hasTotalData: true,
        hasChartsData: true,
        chartVersion: chartVersions.CHART_VERSION_V1,
        total: periodTypes.maintenanceTypes.reduce((final, current) => ({
          ...final,
          [current]: 0,
        }), {}),
        chart: [],
      };
      break;
    }
    case reportTypes.kpi: {
      data = {
        hasTotalData: true,
        hasChartsData: true,
        chartVersion: chartVersions.CHART_VERSION_V1,
        total: periodTypes.oee.reduce((final, current) => ({
          ...final,
          [current]: 0,
        }), {}),
        chart: [],
      };
      break;
    }
    case reportTypes.dataPoints: {
      data = {
        hasTotalData: false,
        hasChartsData: true,
        chartVersion: chartVersions.CHART_VERSION_V2,
        total: null,
        chartV2: [],
      };
      break;
    }
    default: break;
  }

  return data;
};

export const getAllowedCategories = (filters) => {
  if (areFiltersEmpty(filters)) {
    return reportsEntities.reduce((final, entity) => ({
      ...final,
      [entity]: true,
    }), {});
  }

  const result = reportsEntities.reduce((final, entity) => ({
    ...final,
    [entity]: false,
  }), {});

  if (doReportFiltersContainMultipleCategories(filters)) {
    return result;
  }

  forEach(
    reportsEntities,
    (entity) => {
      if (filters[entity].length > 0) {
        result[entity] = true;
      }
    },
  );

  return result;
};

export const isReportEmpty = (report) => {
  const filtersEmpty = areFiltersEmpty(report.filters);
  const absoluteDatesEmpty = !report.from || !report.to;
  const relativeDatesEmpty = !report.fromRelative || !report.toRelative;

  return filtersEmpty
    || (report.datesMode === datesModesOptions.ABSOLUTE && absoluteDatesEmpty)
    || (report.datesMode === datesModesOptions.RELATIVE && relativeDatesEmpty);
};
