import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../../../../messages/dynamicField.messages';
import placeholdersMessages from '../../../../../../../messages/placeholders.messages';
import {
  SORT_BY_ENABLED,
  SORT_BY_IDENTIFIER,
  SORT_BY_LABEL,
  SORT_BY_UUID,
} from '../../../../../../../redux/constants/dataPoints.list.constants';
import { sort } from '../../../../../../../redux/modules/datatron.devices.dataPoints.sort.filter.module';
import { getSortByDeviceId } from '../../../../../../../redux/selectors/datatron.selector';
import SortHeader from '../../../../../../_tools/sortHeader/SortHeader';
import { QueryFilter } from './queryFilter/QueryFilter';
import { Todo } from 'common/types/common';
import { DataPointsTableSelectors } from './selectors/DataPointsTableSelectors';

interface Props {
  deviceId: string;
  showArchived: boolean;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  dataPoints: Todo[];
}

const sortOptions = [
  { option: SORT_BY_UUID, label: <FormattedMessage {...messages.uuid} tagName='b' /> },
  { option: SORT_BY_LABEL, label: <FormattedMessage {...messages.label} tagName='b' /> },
  { option: SORT_BY_IDENTIFIER, label: <FormattedMessage {...messages.identifier} tagName='b' /> },
  { option: SORT_BY_ENABLED, label: <FormattedMessage {...messages.enabled} tagName='b' /> },
];

export const DataPointsTableFilters: React.FC<Props> = ({
  deviceId,
  showArchived,
  selectedRows,
  setSelectedRows,
  dataPoints,
}) => {
  const { option: sortOption, way: sortWay } = useSelector((state) =>
    getSortByDeviceId(state, deviceId, showArchived),
  );

  const dispatch = useDispatch();
  const onSortChange = useCallback(
    (option) => dispatch(sort(option, deviceId, showArchived)),
    [dispatch, deviceId, showArchived],
  );

  return (
    <div className='datapoints-filter-wrap'>
      <div className='datapoints-filter'>
        {showArchived === false && (
          <div className='flex-name'>
            <DataPointsTableSelectors
              deviceId={deviceId}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              dataPoints={dataPoints}
            />
          </div>
        )}
        <span className='flex-name-label'>Sort by</span>
        {sortOptions.map(({ option, label }) => (
          <div key={option} className='flex-name'>
            <SortHeader
              onClick={() => onSortChange(option)}
              option={option}
              way={sortOption === option ? sortWay : null}
            >
              {label}
            </SortHeader>
          </div>
        ))}
      </div>

      <div className='rel-wrap'>
        <div className='flex'>
          <div className='flex-icon'>
            <QueryFilter
              deviceId={deviceId}
              showArchived={showArchived}
              placeholder={placeholdersMessages.searchByDataPoint}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataPointsTableFilters;
