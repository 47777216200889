import { connect } from 'react-redux';

import AnomalyHeader from './AnomalyHeader';
import { getDashboardLink } from '../index/routes';

export function mapStateToProps() {
  return {
    backLink: getDashboardLink(),
  };
}

export default connect(mapStateToProps)(AnomalyHeader);
