import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const fetchMachineOee = createAction(
  'fetch machine oee',
  (machineId) => machineId,
);

export const fetchMachineOeeSuccess = createAction(
  'fetch machine oee - success',
  (machine) => machine,
);

export const fetchMachineOeeFail = createAction(
  'fetch machine oee - fail',
  (error) => error,
);

export const resetMachineOee = createAction(
  'reset machine oee',
);

export const reducer = {
  [fetchMachineOee]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      oee: {
        ...state.machine.oee,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchMachineOeeSuccess]: (state, oee) => ({
    ...state,
    machine: {
      ...state.machine,
      oee: {
        ...state.machine.oee,
        ...oee,
        total: oee[0],
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [fetchMachineOeeFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      oee: {
        loaded: false,
        loading: false,
        error,
      },
    },
  }),

  [resetMachineOee]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      oee: {
        loaded: false,
        loading: false,
        error: null,
      },
    },
  }),
};

export function* fetchMachineOeeSaga({ payload: machineId }) {
  const { response, error } = yield call(api.machines.getMachineOee, machineId);

  if (response) {
    yield put(fetchMachineOeeSuccess(response));
  } else {
    yield put(fetchMachineOeeFail(error));
  }
}

export function* watchFetchMachineOee() {
  yield takeEvery(fetchMachineOee.getType(), fetchMachineOeeSaga);
}
