import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import MachineCalendarsHeaderContainer from './MachineCalendarsHeader.container';
import ModalsRouterContainer from './modals/ModalsRouter.container';
import MachineCalendarsContentContainer from './MachineCalendarsContent.container';
import * as routeHandlers from '../index/routeHandlers';

export default function MachineCalendars({ history }) {
  useEffect(() => {
    routeHandlers.onMachineCalendarsEnter();
  }, []);

  return (
    <div className="col--1">
      <MachineCalendarsHeaderContainer goBack={history.goBack} />
      <div className="col--2--wrap">
        <MachineCalendarsContentContainer />
      </div>
      <ModalsRouterContainer />
    </div>
  );
}

MachineCalendars.propTypes = {
  history: PropTypes.object.isRequired,
};
