import { defineMessages } from 'react-intl';

export default defineMessages({
  inProgress: {
    id: 'maintenance.column.in_progress',
    defaultMessage: 'In Progress',
  },
  planned: {
    id: 'maintenance.column.prioritized',
    defaultMessage: 'Prioritized',
  },
  issues: {
    id: 'maintenance.column.inbox',
    defaultMessage: 'Inbox',
  },
});
