import { connect } from 'react-redux';

import CheckboxFilters from './CheckboxFilters';
import { search } from '../../../redux/modules/search.module';
import * as selector from '../../../redux/selectors/search.selector';
import * as entities from '../../../../../common/constants/entities';

export const mapStateToProps = (state, { filtersType, selectedList = [] }) => {
  const hasSearch = filtersType === entities.MACHINES;
  const selectedListIds = selectedList.map((item) => item.id);

  const results = selector.getSearchResults(state)[filtersType] || [];
  const list = results
    .filter((item) => !selectedListIds.includes(item.id))
    .map((item) => ({ id: item.id, name: item.name }));

  const optimizedList = hasSearch || filtersType === entities.DEVICES ? list : [];

  return {
    hasSearch,
    list: optimizedList,
    selectedList: selectedList.map((item) => ({ ...item, isSelected: true })),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  search: (type, query) => {
    dispatch(search(query, true, [type]));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxFilters);
