import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import OUHeader from './OUHeader';
import OUMembersContainer from './OUMembers.container';
import OULocationsContainer from './OULocations.container';
import OUTags from './OUTags';
import Loader from '../_tools/loader/Loader';
import * as routeHandlers from '../index/routeHandlers';

function OU({ isLoading, match }) {
  // TODO: fix me, avoid suppressing the linter
  useEffect(() => {
    routeHandlers.onOUEnter(match);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <div className="col--1">
        <OUHeader />

        <div className="col--2--wrap">
          <div className="col--2 col--2--central scrolled">
            <OUMembersContainer />
            <div className="col--2--right">
              <div className="col--height">
                <OULocationsContainer />
                <OUTags />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader
        show={isLoading}
        solidBackground
      />
    </div>
  );
}

OU.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

export default OU;
