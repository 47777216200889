import {
  forEach, isNull, isNumber, last,
} from 'lodash';

import * as toolLogTypes from '../../../../common/enums/toolLogTypes';

export const isLatestLogAffectDuration = (logs = []) => {
  if (!logs || !logs.length) return false;
  const latestLog = last(logs);

  return latestLog.type === toolLogTypes.mounted;
};

export const getTotalDuration = (logs = []) => {
  if (!logs || !logs.length) return 0;

  let duration = 0;

  forEach(
    logs,
    (log) => {
      if (log.type === toolLogTypes.mounted && !isNull(log.duration) && !isNaN(log.duration) && isNumber(log.duration)) {
        duration += log.duration;
      }
    },
  );

  return duration;
};
