import * as form from '../../redux/constants/admin.user.form.constants';

export const getValuesFromUser = (user) => ({
  [form.FIRST_NAME_FIELD]: user.firstName || '',
  [form.LAST_NAME_FIELD]: user.lastName || '',
  [form.MIDDLE_NAME_FIELD]: user.middleName || '',
  [form.POSITION_FIELD]: user.position || '',
  [form.EMAIL_FIELD]: user.email || '',
  [form.PHONE_NUMBER_FIELD]: user.phoneNumber || '',
  [form.LOCALE_FIELD]: user.locale || '',
  [form.IS_WORKING_FIELD]: user.isWorking,
  [form.STATUS_FIELD]: user.status,
  [form.ROLES_FIELD]: [...user.roles],
  [form.ORGANIZATIONS_FIELD]: (user.organizations || []).map((item) => item.id),
  [form.LOCATION_ID_FIELD]: user.location ? user.location.id : null,
  [form.RO_CONFIRMED_AT]: user.confirmedAt,
  [form.RO_LAST_SIGN_IN_AT]: user.lastSignInAt,
  [form.RO_LAST_SIGN_IN_IP]: user.lastSignInIp,
  [form.RO_TOS_ACCEPTED]: user.tosAccepted,
  [form.RO_SIGN_IN_COUNT]: user.signInCount,
  [form.RO_CURRENT_SIGN_IN_AT]: user.currentSignInAt,
});
