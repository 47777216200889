import { connect } from 'react-redux';

import InboxCardDnD from './InboxCard.dnd.js';
import { beginDrag, endDrag } from '../../../../redux/modules/maintenance.columns.dnd.module.js';
import { createActivity } from '../../../../redux/modules/maintenance.columns.activity.create.module';
import PermissionedComponent from '../../../common/permissions/PermissionedComponent.container';
import { EVENTS_PERMISSIONS } from '../../../../../../common/constants/permissions';

export function mapStateToProps(state, { event, permissions }) {
  return {
    cardId: event.id,
    canCreateActivities: permissions.canCreateActivities(),
  };
}

export const mapDispatchToProps = {
  beginDrag,
  endDrag,
  createActivity,
};

export default PermissionedComponent(EVENTS_PERMISSIONS)(
  connect(mapStateToProps, mapDispatchToProps)(InboxCardDnD),
);
