import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import ButtonWithDropdown from '../common/ButtonWithDropdown';
import autoRefreshMessages from '../../../../../../messages/reports.autoRefresh.messages';
import autoRefreshOptions from '../../../../../../../../common/enums/report.autoRefresh';

export const AutoRefreshControl = ({ report, setReportControls, intl }) => {
  const handleChange = (value) => setReportControls(report.id, { autoRefresh: value });

  const title = intl.formatMessage(autoRefreshMessages[report.autoRefresh]);
  const options = autoRefreshOptions.map((value) => ({
    value,
    label: intl.formatMessage(autoRefreshMessages[value]),
  }));

  return (
    <ButtonWithDropdown
      title={title}
      options={options}
      onSelect={handleChange}
      titleIcons={['icon-reload-clockwise', 'icon-time']}
    />
  );
};

AutoRefreshControl.propTypes = {
  report: PropTypes.object.isRequired,
  setReportControls: PropTypes.func.isRequired,

};

export default injectIntl(AutoRefreshControl);
