import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../_tools/modal/Modal';
import EventInfo from '../common/eventInfo/EventInfo';
import RepeatingEventFooter from '../common/footer/RepeatingEventFooter';
import SingleEventFooter from '../common/footer/SingleEventFooter';
import { REMOVE_EVENT } from '../../../../../redux/constants/modals.calendars.event.actions.constants';

export class EventRemoveModal extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    deleteCalendarEvent: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    event: PropTypes.object.isRequired,
    day: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete(actionType) {
    const { event, deleteCalendarEvent, day } = this.props;
    deleteCalendarEvent(event.calendarId, { ...event, ...event._originalEvent }, day, actionType);
  }

  handleCancel() {
    this.props.close();
  }

  render() {
    const { event, isOpen, close } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClose={close}
        contentLabel=""
      >
        <div
          className="modal--header"
          onClick={close}
        >
          <span className="modal--close">&times;</span>
        </div>
        <div className="modal--content">
          {
            event.isRecurrentEvent
              ? (
                <FormattedMessage
                  id="calendars.events.modals.remove_recurring_event"
                  defaultMessage="Remove recurring event"
                  tagName="h3"
                />
              )
              : (
                <FormattedMessage
                  id="calendars.events.modals.remove_single.title"
                  defaultMessage="Do you really want to remove that entry?"
                  tagName="h3"
                />
              )
          }

          <form className="form">
            <EventInfo event={event} />

            <div className="line" />

            { event.isRecurrentEvent ? (
              <RepeatingEventFooter
                onCancelClick={this.handleCancel}
                onConfirmClick={this.handleDelete}
                actionType={REMOVE_EVENT}
              />
            ) : (
              <SingleEventFooter
                onCancelClick={close}
                onConfirmClick={this.handleDelete}
              />
            )}
          </form>
        </div>
      </Modal>
    );
  }
}

export default EventRemoveModal;
