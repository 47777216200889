import React from 'react';
import PropTypes from 'prop-types';

import LiveTimeOffsetCore from './LiveTimeOffsetCore';
import NotSetPlaceholder from '../inputWithError/NotSetPlaceholder';

export const LiveTimeOffset = ({ timestamp, secondsOffset }) => {
  if (!timestamp) return <NotSetPlaceholder />;

  return <LiveTimeOffsetCore timestamp={timestamp} secondsOffset={secondsOffset} />;
};

LiveTimeOffset.propTypes = {
  timestamp: PropTypes.string,
  secondsOffset: PropTypes.number,
};

export default LiveTimeOffset;
