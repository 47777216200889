import { Todo } from '../../../../common/types/common';
import { adminInitialState, adminTestState } from './admin.state';
import { anomalyInitialState, anomlayTestState, AnomalyState } from './anomaly.state';
import { aiAppsInitialState, aiAppsTestState, AiAppsState } from './aiApps.state';

import * as appConfig from './appConfig.state';
import { AppConfigState } from './appConfig.state';
import { batchInitialState, batchTestState } from './batch.state';
import { batchDetailsInitialState, batchDetailsTestState } from './batchDetails.state';
import { calendarInitialState, calendarTestState } from './calendar.state';
import * as callback from './callback.state';
import { CommandJournalMessageState } from './callback.state';
import {
  componentsVisibilityInitialState,
  componentsVisibilityTestState,
} from './componentsVisibility.state';
import {
  configRepoExplorerInitialState,
  configRepoExplorerTestState,
} from './configRepoExplorer.state';
import { dashboardInitialState, dashboardTestState } from './dashboard.state';
import * as datatron from './datatron.state';
import { experimentsPageInitialState, experimentsPageTestState } from './experimentsPage.state';
import {
  ExportWidgetState,
  exportWidgetInitialState,
  exportWidgetTestState,
} from './export.widget.state';
import { formInitialState, formTestState } from './form.state';
import { IntlState, intlState } from './intl.state';
import { inventoryInitialState, inventoryTestState, InventoryState } from './inventory.state';
import { locationInitialState, locationTestState, LocationState } from './location.state';
import {
  LocationPickerState,
  locationPickerInitialState,
  locationPickerTestState,
} from './locationPicker.state';
import { machineInitialState, machineTestState } from './machine.state';
import { machineCalendarsInitialState, machineCalendarsTestState } from './machineCalendars.state';
import { maintenanceInitialState, maintenanceTestState } from './maintenance.state';
import { modalsInitialState, modalsTestState } from './modals.state';
import {
  NewDatatronState,
  newDatatronInitialState,
  newDatatronTestState,
} from './newDatatron.state';
import { NewMachineState, newMachineInitialState, newMachineTestState } from './newMachine.state';
import {
  NotificationState,
  notificationsInitialState,
  notificationsTestState,
} from './notifications.state';
import { ouInitialState, ouTestState } from './ou.state';
import { pageInitialState, pageTestState } from './page.state';
import { pathButtonWidgetInitialState, pathButtonWidgetTestState } from './pathButton.widget.state';
import { profileInitialState, profileTestState, ProfileState } from './profile.state';
import { reportsInitialState, reportsTestState, ReportsState } from './reports.state';
import { routingTestState } from './routing.state';
import { searchInitialState, searchTestState } from './search.state';
import { toolsInitialState, toolsTestState } from './tools.state';
import { usersInitialState, usersTestState } from './users.state';
import { usersSearchInitialState, usersSearchTestState } from './usersSearch.state';

/** The redux application-state */
export interface AppState {
  admin: Todo;
  anomaly: AnomalyState;
  aiApps: AiAppsState;
  appConfig: AppConfigState;
  batchDetails: Todo;
  batches: Todo;
  calendar: Todo;
  callback: CommandJournalMessageState;
  componentsVisibility: Todo;
  configRepoExplorer: Todo;
  dashboard: Todo;
  datatron: datatron.State;
  experimentsPage: Todo;
  exportWidget: ExportWidgetState;
  form: Todo;
  intl: IntlState;
  inventory: InventoryState;
  location: LocationState;
  locationPicker: LocationPickerState;
  machine: Todo;
  machineCalendars: Todo;
  maintenance: Todo;
  modals: Todo;
  newDatatron: NewDatatronState;
  newMachine: NewMachineState;
  notifications: NotificationState;
  ou: Todo;
  page: Todo;
  pathButtonWidget: Todo;
  profile: ProfileState;
  reports: ReportsState;
  routing?: Todo;
  search: Todo;
  tools: Todo;
  users: Todo;
  usersSearch: Todo;
}

export const testState: AppState = {
  admin: adminTestState,
  anomaly: anomlayTestState,
  aiApps: aiAppsTestState,
  appConfig: appConfig.testState,
  batchDetails: batchDetailsTestState,
  batches: batchTestState,
  calendar: calendarTestState,
  callback: callback.testState,
  componentsVisibility: componentsVisibilityTestState,
  configRepoExplorer: configRepoExplorerTestState,
  dashboard: dashboardTestState,
  datatron: datatron.testState,
  experimentsPage: experimentsPageTestState,
  exportWidget: exportWidgetTestState,
  form: formTestState,
  intl: intlState,
  inventory: inventoryTestState,
  locationPicker: locationPickerTestState,
  location: locationTestState,
  machine: machineTestState,
  machineCalendars: machineCalendarsTestState,
  maintenance: maintenanceTestState,
  modals: modalsTestState,
  newDatatron: newDatatronTestState,
  newMachine: newMachineTestState,
  notifications: notificationsTestState,
  ou: ouTestState,
  page: pageTestState,
  pathButtonWidget: pathButtonWidgetTestState,
  profile: profileTestState,
  reports: reportsTestState,
  routing: routingTestState,
  search: searchTestState,
  tools: toolsTestState,
  users: usersTestState,
  usersSearch: usersSearchTestState,
};

export const initialState: AppState = {
  admin: adminInitialState,
  anomaly: anomalyInitialState,
  aiApps: aiAppsInitialState,
  appConfig: appConfig.initialState,
  batchDetails: batchDetailsInitialState,
  batches: batchInitialState,
  calendar: calendarInitialState,
  callback: callback.initial,
  componentsVisibility: componentsVisibilityInitialState,
  configRepoExplorer: configRepoExplorerInitialState,
  dashboard: dashboardInitialState,
  datatron: datatron.initial,
  experimentsPage: experimentsPageInitialState,
  exportWidget: exportWidgetInitialState,
  form: formInitialState,
  intl: intlState,
  inventory: inventoryInitialState,
  locationPicker: locationPickerInitialState,
  location: locationInitialState,
  machine: machineInitialState,
  machineCalendars: machineCalendarsInitialState,
  maintenance: maintenanceInitialState,
  modals: modalsInitialState,
  newDatatron: newDatatronInitialState,
  newMachine: newMachineInitialState,
  notifications: notificationsInitialState,
  ou: ouInitialState,
  page: pageInitialState,
  pathButtonWidget: pathButtonWidgetInitialState,
  profile: profileInitialState,
  reports: reportsInitialState,
  search: searchInitialState,
  tools: toolsInitialState,
  users: usersInitialState,
  usersSearch: usersSearchInitialState,
};
