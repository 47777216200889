import React from 'react';
import cn from 'classnames';

import HighlightTextWrap from './HighlightTextWrap';

interface Props {
  text: string
  highlight?: string
}

const HighlightText: React.FC<Props> = ({ text, highlight = '' }) => {
  const defaultText = <HighlightTextWrap>{text}</HighlightTextWrap>;
  if (!highlight) return defaultText;

  const expr = new RegExp(`(${highlight})`, 'gmi');

  return (
    <HighlightTextWrap>
      {text.split(expr).map((part, i) => {
        if (!part) return null;
        return (
          <span
            key={i}
            className={cn({ 'text-highlight': part.toLowerCase() === highlight.toLowerCase() })}
          >
            {part}
          </span>
        );
      })}
    </HighlightTextWrap>
  );
};

export default HighlightText;
