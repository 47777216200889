import { createAction } from 'redux-act';
import {
  put, delay, takeLatest,
} from 'redux-saga/effects';

import { fetchMeasurements } from './measurements.fetch.module';

export const DEBOUNCE_TIME_IN_MILLISECONDS = 500;

export const addStatusFilter = createAction(
  'add status filter for measurements',
  (filter) => filter,
);

export const removeStatusFilter = createAction(
  'remove status filter for measurements',
  (filter) => filter,
);

export const reducer = {
  [addStatusFilter]: (state, filter) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      measurements: {
        ...state.experimentsPage.measurements,
        filters: {
          ...state.experimentsPage.measurements.filters,
          status: [
            ...state.experimentsPage.measurements.filters.status,
            filter,
          ],
        },
      },
    },
  }),
  [removeStatusFilter]: (state, filter) => {
    const selected = state.experimentsPage.measurements.filters.status;
    const index = selected.indexOf(filter);

    if (index === -1) return state;

    return {
      ...state,
      experimentsPage: {
        ...state.experimentsPage,
        measurements: {
          ...state.experimentsPage.measurements,
          filters: {
            ...state.experimentsPage.measurements.filters,
            status: [
              ...selected.slice(0, index),
              ...selected.slice(index + 1),
            ],
          },
        },
      },
    };
  },
};

export function* fetchMeasurementsSaga() {
  yield delay(DEBOUNCE_TIME_IN_MILLISECONDS);
  yield put(fetchMeasurements());
}

export function* watchMeasurementsFiltersChange() {
  yield takeLatest([
    addStatusFilter.getType(),
    removeStatusFilter.getType(),
  ], fetchMeasurementsSaga);
}
