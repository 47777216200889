import React from 'react';
import PropTypes from 'prop-types';

import ToolsEmptyPlaceholderContainer from './emptyPlaceholder/ToolsEmptyPlaceholder.container';
import Loader from '../../_tools/loader/Loader';
import TableHeader from './header/TableHeader';
import ToolTableRow from './toolsTableRow/ToolTableRow';
import PaginationBar from '../../common/paginationBar/PaginationBar';

export const ToolsList = ({
  tools,
  isLoading,
  pagination,
  paginationAvailable,
  choosePage,
  query,
}) => (
  <div className="reports--wrap--all">
    <div className="tools-wrap">
      <div className="download--wrap hide">
        <div className="datepicker-block">
          <button className="download--btn">
            <span className="icon icon-load" />
            <span>Export</span>
          </button>
        </div>
      </div>

      <table className="lined--table valign-middle">
        <TableHeader />
        <tbody>
          { tools.map((tool) => <ToolTableRow key={tool.id} tool={tool} textToHighlight={query} />) }
        </tbody>
      </table>

      { paginationAvailable && (<PaginationBar {...pagination} choosePage={choosePage} />) }

      <Loader show={isLoading} />
      { !isLoading && !tools.length && <ToolsEmptyPlaceholderContainer /> }
    </div>
  </div>
);

ToolsList.propTypes = {
  tools: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ToolsList;
