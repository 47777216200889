import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { HighlightText } from '../../../_tools';
import * as entities from '../../../../../../common/constants/entities';

const getTitle = (item, type) => {
  let title = item.name;

  if (type === entities.DATA_POINTS && item.path) {
    const fullPath = [title];
    if (item.path && item.path.deviceName) {
      fullPath.unshift(item.path.deviceName);
    }
    if (item.path && item.path.datatronName) {
      fullPath.unshift(item.path.datatronName);
    }
    title = fullPath.join(' > ');
  }

  return title;
};

export const ListItem = ({
  item, onClick, stringToHighlight, type,
}) => {
  const handleClick = () => onClick(item);

  const btn = item.isSelected && (
    <button className="btn-link" type="button" onClick={handleClick}>
      <i className="icon-close" />
    </button>
  );

  const title = getTitle(item, type);

  return (
    <li className={cn({ archived: item.archived })}>
      { btn }
      <span className="search-alls-text" onClick={handleClick}>
        <HighlightText text={title} highlight={stringToHighlight} />
      </span>
    </li>
  );
};

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  stringToHighlight: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default ListItem;
