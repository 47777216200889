import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getLocationLink } from '../index/routes';

export default function OULocation({ location }) {
  return (
    <tr>
      <td>
        <Link
          to={getLocationLink(location.id)}
          className="links"
        >
          <span className="icon icon-location mt0" />
          <span className="links-text">{ location.name }</span>
        </Link>
      </td>
    </tr>
  );
}

OULocation.propTypes = {
  location: PropTypes.object.isRequired,
};
