import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function ErrorMessage({ error, values }) {
  return (
    <div className="content">
      <FormattedMessage
        id="error.tittle"
        defaultMessage="Something has happened"
      />

      <FormattedMessage
        id={error}
        values={values}
      />
    </div>
  );
}
Error.propTypes = {
  error: PropTypes.string.isRequired,
  values: PropTypes.object,
};
