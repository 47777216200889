import { connect } from 'react-redux';

import Area from './Area';
import { getX } from '../../../../../core/common/report.chart.v2';

export function mapStateToProps(state, { entry }) {
  return {
    color: entry.color,
    lineWidth: entry.lineWidth,
    fillOpacity: entry.fillOpacity,
    getY: (chartPoint) => chartPoint[entry.id],
    getX,
  };
}

export default connect(mapStateToProps)(Area);
