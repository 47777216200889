import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function CalendarMonthly() {
  return (
    <div>
      <div className="history">
        <div className="history--row">
          <div className="history--left">
            <ul>
              <li>
                <span className="history-square success" />
                <FormattedMessage
                  id="dashboard.calendar.monthly.planned"
                  defaultMessage="Planned"
                />
              </li>
              <li>
                <span className="history-square alert" />
                <FormattedMessage
                  id="dashboard.calendar.monthly.over_capacity"
                  defaultMessage="Over Capacity"
                />
              </li>
            </ul>
          </div>
          <div className="history--right">
            <span className="icon icon-person" />
            {' '}
            12
          </div>
        </div>
      </div>
      <div className="calendar--month">
        <div className="calendar--month--top">
          <a className="calendar--month--prev">
            <span className="fa fa-chevron-left" />
          </a>
          <a className="calendar--month--next">
            <span className="fa fa-chevron-right" />
          </a>
          <div className="calendar--month--title">
            January
          </div>
        </div>
        <div className="calendar--month--days">
          <div className="calendar--month--day--item">
            <div className="calendar--month--day">
              Mon, 1
            </div>
            <div className="calendar--month--day--text" />
          </div>
          <div className="calendar--month--day--item">
            <div className="calendar--month--day">
              Tue, 1
            </div>
            <div className="calendar--month--day--text" />
          </div>
          <div className="calendar--month--day--item">
            <div className="calendar--month--day">
              Wed, 3
            </div>
            <div className="calendar--month--day--text" />
          </div>
          <div className="calendar--month--day--item">
            <div className="calendar--month--day">
              Thur, 4
            </div>
            <div className="calendar--month--day--text" />
          </div>
          <div className="calendar--month--day--item">
            <div className="calendar--month--day">
              Fri, 5
            </div>
            <div className="calendar--month--day--text" />
          </div>
          <div className="calendar--month--day--item passive">
            <div className="calendar--month--day">
              Sat, 6
            </div>
            <div className="calendar--month--day--text" />
          </div>
          <div className="calendar--month--day--item passive">
            <div className="calendar--month--day">
              Sun, 7
            </div>
            <div className="calendar--month--day--text" />
          </div>
        </div>
      </div>
    </div>
  );
}

CalendarMonthly.propTypes = {};
