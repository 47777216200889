import { connect } from 'react-redux';

import LocationModule from './LocationModule';
import * as selector from '../../../../../redux/selectors/admin.locations.selector';
import { getValuesFromLocation } from '../../../../../core/common/admin.location.form';
import { adminUpdateLocation } from '../../../../../redux/modules/admin.location.update.module';

export const mapStateToProps = (state) => {
  const loaded = selector.isLocationLoaded(state);
  const location = selector.getLocation(state);
  const props = { isEditForm: true, loaded, formType: 'edit' };

  if (loaded) {
    props.locationId = location.id;
    props.initialValues = getValuesFromLocation(location);
    props.formBodyProps = {
      parent: location.parent,
    };
  }

  return props;
};

export const mapDispatchToProps = {
  adminUpdateLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationModule);
