import { connect } from 'react-redux';

import EventComponent from './Event';
import * as selector from '../../../redux/selectors/dashboard.selector';
import {
  expandComments,
  expandMasterEvent,
  hideEventDetails,
} from '../../../redux/modules/dashboard.event.expand.module';
import { fetchCluster } from '../../../redux/modules/dashboard.event.fetch.cluster.module';
import { fetchAnomaly } from '../../../redux/modules/anomaly.fetch.module';

export function mapStateToProps(state, { event }) {
  return {
    displayMode: selector.getDisplayMode(state, event.id),
  };
}

export const mapDispatchToProps = {
  expandComments,
  expandMasterEvent,
  hideEventDetails,
  fetchAnomaly,
  fetchCluster,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventComponent);
