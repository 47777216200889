import { connect } from 'react-redux';

import LocationPickerModal from '../../../../_tools/locationPicker/LocationPickerModal.container';
import * as selector from '../../../../../redux/selectors/profile.selector';
import { PROFILE_PAGE } from '../../../../../redux/constants/pathButton.constants';

export const mapStateToProps = (state) => {
  const location = selector.selectProfileLocation(state);
  const initLocationId = (location && location.id) ? location.id : null;

  return {
    isFormField: true,
    initLocationId,
    pathButtonId: PROFILE_PAGE,
  };
};

export default connect(mapStateToProps)(LocationPickerModal);
