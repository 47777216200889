import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EventActions from './EventActions';
import { CP_EVENT_REMOVE, CP_EVENT_EDIT } from '../../../../../../../redux/constants/modals.constants';
import { openModal } from '../../../../../../../redux/modules/modals.module';
import { updateCalendarEvent } from '../../../../../../../redux/modules/calendars.update.event.module';

export function mapDispatchToProps(dispatch) {
  return {
    onEventRemoveClick: (payload) => dispatch(openModal(CP_EVENT_REMOVE, payload)),
    updateCalendarEvent: bindActionCreators(updateCalendarEvent, dispatch),
    onRepeatingEventSave: (payload) => dispatch(openModal(CP_EVENT_EDIT, payload)),
  };
}

export default connect(null, mapDispatchToProps)(EventActions);
