import { connect } from 'react-redux';

import Assistant from './Assistant';
import { getLocationPath } from '../../../../core/common/location';

export const mapStateToProps = (state, { assistant }) => ({
  assistantLocationPath: (assistant && assistant.location) ? getLocationPath(
    assistant.location.name,
    assistant.location.path,
  ).slice(-2) : [],
});

export default connect(mapStateToProps)(Assistant);
