import { connect } from 'react-redux';

import ActionButtons from './ActionButtons';
import PermissionedComponent from '../../../common/permissions/PermissionedComponent.container';
import { TOOLS_PERMISSIONS } from '../../../../../../common/constants/permissions';
import { openModal } from '../../../../redux/modules/modals.module';
import { TOOL_DETAILS, TOOL_EDIT } from '../../../../redux/constants/modals.constants';
import * as toolStates from '../../../../../../common/enums/toolStates';

export const mapStateToProps = (state, { permissions, tool }) => ({
  canEditTools: permissions.canEditTool() && tool.state !== toolStates.EXHAUSTED,
});

export const mapDispatchToProps = (dispatch, { tool }) => ({
  openEditModal: () => dispatch(openModal(TOOL_EDIT, { tool })),
  openDetailsModal: () => dispatch(openModal(TOOL_DETAILS, { tool })),
});

export default PermissionedComponent(TOOLS_PERMISSIONS)(connect(mapStateToProps, mapDispatchToProps)(ActionButtons));
