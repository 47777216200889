import { connect } from 'react-redux';

import Name from './Name';
import { getCalendarName, getCalendarsQueryByType } from '../../../../../redux/selectors/calendar.selector';

export const mapStateToProps = (state, { calendar }) => ({
  name: getCalendarName(calendar),
  filterByName: getCalendarsQueryByType(state, calendar.type),
});

export default connect(mapStateToProps)(Name);
