import moment from 'moment';
import { Todo } from '../../common/types/common';

const validDate = (date: string | Date): Date | null => {
  if (date instanceof Date) return date;
  if (typeof date === 'string') {
    const newDate = new Date(date);
    return isNaN(newDate.getTime()) ? null : newDate;
  }
  return null;
};

export const encode = (id: number, createdAt): string => {
  const date = validDate(createdAt);
  if (date === null) {
    throw new Error(`indexProtector: Invalid createdAt provided: ${createdAt}`);
  }

  const code = Buffer.from(`${id}${date.getTime()}`, 'utf8');
  return code.toString('base64').replace(/=/g, '');
};

export interface TimestampAndID {
  id: number;
  timestamp: Date;
}

export const decode = (value): TimestampAndID | null => {
  try {
    const decoded = String(
      Buffer.from(String(value), 'base64').toString('utf8'),
    );
    if (decoded.length < 14) return null;
    const id = decoded.substring(0, decoded.length - 13);
    const timestamp = Number(decoded.substring(id.length));

    return {
      id: Number(id),
      timestamp: moment(timestamp).utc().toDate(),
    };
  } catch (err) {
    return null;
  }
};

export const isValid = (value, noDecode = false) => {
  let valueToCheck;

  if (noDecode) {
    valueToCheck = value;
  } else {
    const decoded = decode(value);
    valueToCheck = decoded && decoded.id ? decoded.id : null;
  }

  return typeof valueToCheck === 'number' && valueToCheck > 0;
};

export interface IdAndCreatedAt {
  id: number;
  createdAt: Todo;
}

export const addVirtualCodeField = <T extends IdAndCreatedAt>(
  thing: T,
): T & { code: string } => ({
    ...thing,
    code: encode(thing.id, thing.createdAt),
  });

/**
 * Encode the parentId before sending it back to the frontend
 */
export const encodeParentId = (dataPoint, index, array) => ({
  ...dataPoint,
  parentId: dataPoint.parentId
    ? array.find((item) => item.id === dataPoint.parentId).code
    : null,
});
