export const VIEW_TOOL_FORM_NAME = 'VIEW_TOOL_FORM_NAME';

export const LABEL_FIELD = 'label';
export const TYPE_FIELD = 'type';
export const MODEL_FIELD = 'model';
export const SERIAL_FIELD = 'serial';

export const LA_TYPE = 'last_activity__type';
export const LA_TIMESTAMP = 'last_activity__timestamp';
export const LA_DURATION = 'last_activity__duration';
export const LA_COMMENT = 'last_activity__comment';
export const LA_MACHINE = 'last_activity__machine';

export const TOOL_PROPS = [
  LABEL_FIELD,
  TYPE_FIELD,
  MODEL_FIELD,
  SERIAL_FIELD,
];

export const LAST_ACTIVITY_PROPS = [
  LA_TYPE,
  LA_TIMESTAMP,
  LA_DURATION,
  LA_COMMENT,
  LA_MACHINE,
];
