import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { push } from 'connected-react-router';

import * as api from '../../core/api';
import { replaceObjectInList, compareById } from '../../../../common/helpers/object';
import { getFormFields } from '../selectors/admin.organization.form.selector';
import * as form from '../constants/admin.organization.form.constants';
import { getAdminOrganizationsLink } from '../../components/index/routes';
import { getOrganization } from '../selectors/admin.organizations.selector';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const adminUpdateOrganization = createAction(
  'admin: update organization',
);

export const adminUpdateOrganizationSuccess = createAction(
  'admin: update organization - success',
  (organization) => organization,
);

export const adminUpdateOrganizationFail = createAction(
  'admin: update organization - fail',
  (error) => error,
);

export const reducer = {
  [adminUpdateOrganization]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        selected: {
          ...state.admin.organizations.selected,
          item: {
            ...state.admin.organizations.selected.item,
            _update: {
              loaded: false,
              loading: true,
              error: null,
            },
          },
        },
      },
    },
  }),

  [adminUpdateOrganizationSuccess]: (state, organization) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        list: replaceObjectInList(
          state.admin.organizations.list,
          organization,
          compareById,
        ),
      },
    },
  }),

  [adminUpdateOrganizationFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        selected: {
          ...state.admin.organizations.selected,
          item: {
            ...state.admin.organizations.selected.item,
            _update: {
              loaded: false,
              loading: false,
              error,
            },
          },
        },
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.ADMIN_ORGANIZATION_FORM_NAME,
    ...form.ORGANIZATION_PROPS,
  ));
}

export function* adminUpdateOrganizationSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const organization = yield call(getOrganization, state);
  const name = yield call(getFormFields, state);
  yield put(startSubmit(form.ADMIN_ORGANIZATION_FORM_NAME));

  const { response, error } = yield call(api.admin.updateOrganization, organization.id, { name });

  if (response) {
    yield put(adminUpdateOrganizationSuccess(response));
    yield put(stopSubmit(form.ADMIN_ORGANIZATION_FORM_NAME));
    yield put(push(getAdminOrganizationsLink()));
    yield put(sendNotification({ ...notificationMessages.organization_updated, values: { name: response.name } }));
  } else {
    yield put(adminUpdateOrganizationFail(error));
    yield put(stopSubmit(form.ADMIN_ORGANIZATION_FORM_NAME, error));
  }
}

export function* watchAdminUpdateOrganization() {
  yield takeEvery(adminUpdateOrganization.getType(), adminUpdateOrganizationSaga);
}
