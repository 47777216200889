import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as activitiesApi from '../../core/api/activities.js';

export const fetchMaintenanceActivities = createAction(
  'fetch all maintenance activities',
);

export const fetchMaintenanceActivitiesSuccess = createAction(
  'fetch all maintenance activities successfully',
  (activities) => activities,
);

export const fetchMaintenanceActivitiesFail = createAction(
  'fetch all maintenance activities fail',
  (error) => error,
);

export const reducer = {
  [fetchMaintenanceActivities]: (state) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      activitiesState: {
        loaded: false,
        loading: true,
        error: '',
      },
    },
  }),

  [fetchMaintenanceActivitiesSuccess]: (state, activities) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      activities,
      activitiesState: {
        ...state.maintenance.activitiesState,
        loaded: true,
        loading: false,
      },
    },
  }),

  [fetchMaintenanceActivitiesFail]: (state, error) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      activitiesState: {
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchMaintenanceActivitiesSaga() {
  const { response, error } = yield call(activitiesApi.getAllActivities);

  if (response) {
    yield put(fetchMaintenanceActivitiesSuccess(response));
  } else {
    yield put(fetchMaintenanceActivitiesFail(error));
  }
}

export function* watchFetchMaintenanceActivities() {
  yield takeEvery(fetchMaintenanceActivities.getType(), fetchMaintenanceActivitiesSaga);
}
