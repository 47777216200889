import { createAction } from '@reduxjs/toolkit';

import { SORT_WAY_ASC, SORT_WAY_DESC } from '../../../../common/constants';
import { getDeviceById } from '../selectors/datatron.selector';
import { compareById, replaceObjectInList } from '../../../../common/helpers/object';


export const sort = createAction(
  'sort datapoints list',
  (option, deviceId, archived = false) => ({ payload: { deviceId, archived, option } }),
);

export const filterByQuery = createAction(
  'filter datapoints list by query',
  (query, deviceId, archived = false) => ({ payload: { deviceId, archived, query } }),
);

export const filterByField = createAction(
  'filter datapoints list by field value',
  (field, value, deviceId, archived = false) => ({ payload: { deviceId, archived, field, value } }),
);

const getListName = (archived) => {
  if (!archived) return 'dataPoints';
  return 'archivedDataPoints';
};


export const reducer = {
  [sort.type]: (state, { deviceId, archived, option }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) {
      return state;
    }

    const listName = getListName(archived);
    const prevOption = device[listName].sort.option;
    const prevWay = device[listName].sort.way;

    let newOption = null;
    let newWay: string | null = null;

    if (prevOption === option && prevWay === SORT_WAY_ASC) {
      newOption = prevOption;
      newWay = SORT_WAY_DESC;
    } else if (prevOption !== option) {
      newOption = option;
      newWay = SORT_WAY_ASC;
      if (option === 'enabled' && prevWay === null) {
        newWay = SORT_WAY_DESC;
      }
    }

    const newDevice = {
      ...device,
      [listName]: {
        ...device[listName],
        sort: { option: newOption, way: newWay },
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById,
          ),
        },
      },
    };
  },
  [filterByQuery.type]: (state, { deviceId, archived, query }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) {
      return state;
    }

    const listName = getListName(archived);
    const newDevice = {
      ...device,
      [listName]: {
        ...device[listName],
        query,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById,
          ),
        },
      },
    };
  },
  [filterByField.type]: (state, { deviceId, archived, field, value }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) {
      return state;
    }

    const listName = getListName(archived);
    const newDevice = {
      ...device,
      [listName]: {
        ...device[listName],
        filters: { ...device[listName].filters, [field]: value },
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById,
          ),
        },
      },
    };
  },
};
