import { forEach } from 'lodash';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { DATE_FROM, DATE_TO } from '../../../../common/constants';
import { commentField, getFormId } from '../../core/forms/eventComment.form';
import { NOT_EXPANDED } from '../constants/dashboard.constants';
import { EMPTY_RESULTS } from '../constants/search.constants';
import { Todo } from '../../../../common/types/common';

export const allEvents = (state) => state.dashboard.events;

export const expanded = (state) => state.dashboard.expanded;

export const categoryFilters = (state) => state.dashboard.categoryFilters;

export const itemFilters = (state) => state.dashboard.itemFilters;

export const dateFilters = (state) => state.dashboard.dateFilters;

export const areDatesSelected = (state) => {
  const dates = dateFilters(state);
  return !!dates[DATE_FROM] && !!dates[DATE_TO];
};

export const getOee = (state) => state.dashboard.oee.data;

export const isOeeLoaded = (state) => state.dashboard.oee.loaded;

export const isOeeLoading = (state) => !!state.dashboard.oee.loading;

export const eventsLoading = (state) => !!state.dashboard.loading;

export const showCalendar = (state) => state.dashboard.showCalendar;

export const isUpdating = (state) => !!state.dashboard.isUpdating;

export const loadEventsFrom = (state) => state.dashboard.loadEventsFrom;

export const loadEventsTo = (state) => state.dashboard.loadEventsTo;

export const filteredEvents = createSelector(
  allEvents,
  (events) => events,
);

export const hasNoEvents = createSelector(
  filteredEvents,
  (events) => !events || !events.length,
);

export const getEventById = createSelector(
  allEvents,
  (state, eventId) => eventId,
  (events, eventId) => events.find((event) => event.id === eventId),
);

export function getDisplayMode(state, eventId) {
  return expanded(state)[eventId] || NOT_EXPANDED;
}

export function getCommentText(state, eventId) {
  const formId = getFormId(eventId);
  const selector = formValueSelector(formId);
  return selector(state, commentField);
}

export function getEventIndexById(state, eventId) {
  return allEvents(state).findIndex(({ id }) => id === eventId);
}

export function isCommentEmptyOrWhitespace(state, eventId) {
  const text = getCommentText(state, eventId) || '';

  return text.trim() === '';
}

export function getCommentError(state, eventId) {
  const formId = getFormId(eventId);
  const form = state.form[formId];

  if (!form) {
    return '';
  }

  const errors = form.syncErrors;

  if (!errors) {
    return '';
  }

  return errors[commentField];
}

export function getFilterQuery(state, itemType) {
  return state.dashboard.filterQuery[itemType];
}

export const getFilterList = (state, itemType) => (state.dashboard.itemFilters[itemType] || [])
  .filter((item) => !item._isPendingForRemove);

export const getSelectedFilters = (state, itemType) => ({
  ...EMPTY_RESULTS,
  [itemType]: getFilterList(state, itemType),
});

export const getFiltersCount = (state) => {
  let count = 0;

  forEach(
    state.dashboard.itemFilters,
    (list, itemType) => {
      const filtersList = getFilterList(state, itemType);
      count += filtersList.length || 0;
    },
  );

  count += state.dashboard.categoryFilters.length;

  return count;
};

export function getUniqueLocationsByEvents(events) {
  let locations = [];

  if (events) {
    const uniqueLocationIds: Todo[] = [];
    locations = events.reduce((final, current) => {
      const thisLocation = current.machine.location;

      if (uniqueLocationIds.indexOf(thisLocation.id) === -1) {
        uniqueLocationIds.push(thisLocation.id);
        return [
          ...final,
          thisLocation,
        ];
      }

      return final;
    }, []);
  }

  return locations;
}

export const selectCanApplyItemFilters = (state) => {
  let pendingCount = 0;

  forEach(
    state.dashboard.itemFilters,
    (list) => {
      forEach(
        list,
        (listItem) => {
          if (listItem._isPending || listItem._isPendingForRemove) pendingCount++;
        },
      );
    },
  );

  return pendingCount > 0;
};
