export const ADMIN_USER_ADD_FORM_NAME = 'ADMIN_USER_ADD_FORM_NAME';

export const FIRST_NAME_FIELD = 'firstName';
export const LAST_NAME_FIELD = 'lastName';
export const MIDDLE_NAME_FIELD = 'middleName';
export const POSITION_FIELD = 'position';
export const EMAIL_FIELD = 'email';
export const PHONE_NUMBER_FIELD = 'phoneNumber';
export const LOCALE_FIELD = 'locale';
export const PASSWORD_FIELD = 'password';
export const PASSWORD_CONFIRMATION_FIELD = 'passwordConfirmation';
export const LOCATION_ID_FIELD = 'locationId';

export const USER_PROPS = [
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  MIDDLE_NAME_FIELD,
  POSITION_FIELD,
  EMAIL_FIELD,
  PHONE_NUMBER_FIELD,
  LOCALE_FIELD,
  PASSWORD_FIELD,
  PASSWORD_CONFIRMATION_FIELD,
  LOCATION_ID_FIELD,
];
