import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { InputError } from '../../';

const emptyFunc = () => {};
const handleMouseDown = (e) => e.preventDefault();

export const InputButtonWithIcon = ({
  items,
  onClick,
  iconClassName,
  useButtonTag,
  tabIndex,
  placeholder,
  meta,
}) => {
  const label = items.map((item) => item.name).join(', ');
  const { formatMessage } = useIntl();
  const translatedPlaceholder = placeholder ? formatMessage(placeholder) : '';
  const isError = meta && meta.error;

  return (
    <div className='search-block'>
      <div className='search-text' onClick={onClick} onMouseDown={handleMouseDown}>
        {useButtonTag ? (
          <button type='button' className='search-input' tabIndex={tabIndex}>
            {label}
          </button>
        ) : (
          <input
            type='text'
            className={` ${isError ? 'error' : 'search-input'}`}
            value={label}
            onChange={emptyFunc}
            tabIndex={tabIndex}
            placeholder={translatedPlaceholder}
          />
        )}
        {isError && (
          <div className='error-line'>
            <InputError error={meta.error} />
          </div>
        )}

        <div className='input-group-button'>
          <button type='button' className='button__search'>
            <span className={iconClassName} />
          </button>
        </div>
      </div>
    </div>
  );
};

InputButtonWithIcon.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  iconClassName: PropTypes.string.isRequired,
  useButtonTag: PropTypes.bool,
  tabIndex: PropTypes.number,
  placeholder: PropTypes.object,
};

InputButtonWithIcon.defaultProps = {
  useButtonTag: false,
};

export default InputButtonWithIcon;
