import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import DuplicateReportModal from './DuplicateReportModal';
import { duplicateReport } from '../../../../redux/modules/report.duplicate.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { REPORT_DUPLICATE } from '../../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { DUPLICATE_REPORT_FORM_NAME, NAME_FIELD } from '../../../../redux/constants/report.duplicate.form.constants';

export const mapStateToProps = (state) => {
  const { report } = getModalPayload(state, REPORT_DUPLICATE);
  return {
    report,
    initialValues: {
      [NAME_FIELD]: report.name || '',
    },
  };
};

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(REPORT_DUPLICATE)),
  duplicate: bindActionCreators(duplicateReport, dispatch),
});

const form = {
  form: DUPLICATE_REPORT_FORM_NAME,
  destroyOnUnmount: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(form)(DuplicateReportModal));
