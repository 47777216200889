import { createAction } from 'redux-act';
import { put, call, takeLatest } from 'redux-saga/effects';

import * as api from '../../core/api';
import { DASHBOARD_EVENTS, PRODUCTS } from '../constants/export.type.constants';

export const verifyDownload = createAction(
  'verify download ability',
  (type, filters) => ({ type, filters }),
);

export const verifyDownloadSuccess = createAction(
  'verify download ability - success',
  (type, ready, entriesCount) => ({ type, ready, entriesCount }),
);

export const verifyDownloadFail = createAction(
  'verify download ability - fail',
  (type, error) => ({ type, error }),
);

export const reducer = {
  [verifyDownload]: (state, { type }) => ({
    ...state,
    exportWidget: {
      ...state.exportWidget,
      downloadVerification: {
        ...state.exportWidget.downloadVerification,
        type,
        ready: false,
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),
  [verifyDownloadSuccess]: (state, { type, ready, entriesCount }) => ({
    ...state,
    exportWidget: {
      ...state.exportWidget,
      downloadVerification: {
        ...state.exportWidget.downloadVerification,
        type,
        ready,
        entriesCount,
        loading: false,
        loaded: true,
        error: null,
      },
    },
  }),
  [verifyDownloadFail]: (state, { type, error }) => ({
    ...state,
    exportWidget: {
      ...state.exportWidget,
      downloadVerification: {
        ...state.exportWidget.downloadVerification,
        type,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export const getVerificationMethod = ({ type, filters }) => {
  switch (type) {
    case DASHBOARD_EVENTS: return api.events.getEventsDownloadAvailability;
    case PRODUCTS: {
      if (filters.includeProcessings) return api.batch.getProcessingsDownloadAvailability;
      return api.batch.getProductsDownloadAvailability;
    }
    default: return null;
  }
};

export function* verifyDownloadSaga({ payload: { type, filters } }) {
  const apiMethod = getVerificationMethod({ type, filters });
  if (!apiMethod) return;

  const { response, error } = yield call(apiMethod, filters);

  if (response) {
    const { available, entriesCount } = response;
    yield put(verifyDownloadSuccess(type, available, entriesCount));
  } else {
    yield put(verifyDownloadFail(type, error));
  }
}

export function* watchVerifyDownload() {
  yield takeLatest(verifyDownload.getType(), verifyDownloadSaga);
}
