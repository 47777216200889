import { connect } from 'react-redux';

import {
  INFO,
} from '../../../../../../common/enums/dataPointCategories';

import CategoryFilter from './CategoryFilter';

export const mapStateToProps = (state, { filters, onChange }) => ({
  handleChange: onChange,
  selected: filters.indexOf(INFO) !== -1,
  label: 'maintenance.stream.info',
  category: INFO,
});

export default connect(mapStateToProps)(CategoryFilter);
