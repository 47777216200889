import React from 'react';
import PropTypes from 'prop-types';

import LocationFormBody from './LocationFormBody';
import AddModalFooter from '../../../../../modals/core/footers/AddModalFooter';
import EditModalFooter from '../../../../../modals/core/footers/EditModalFooter';
import ViewModalFooter from '../../../../../modals/core/footers/ViewModalFooter';

export const LocationForm = ({
  confirmForm,
  submitting,
  isAddForm,
  isEditForm,
  isViewForm,
  pristine,
  formBodyProps,
  cancel,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    confirmForm();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form">
        <LocationFormBody {...formBodyProps} readOnly={!!isViewForm} />

        <div className="line" />
        { isAddForm && (
          <AddModalFooter
            onCancelClick={cancel}
            onAddClick={handleSubmit}
            isAddButtonDisabled={pristine || submitting}
          />
        ) }
        { isEditForm && (
          <EditModalFooter
            onCancelClick={cancel}
            onSaveClick={handleSubmit}
            isSaveButtonDisabled={pristine || submitting}
          />
        ) }
        { isViewForm && (
          <ViewModalFooter
            onCancelClick={cancel}
            onEditClick={handleSubmit}
          />
        ) }
      </div>
    </form>
  );
};

LocationForm.propTypes = {
  confirmForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isAddForm: PropTypes.bool,
  isEditForm: PropTypes.bool,
  isViewForm: PropTypes.bool,
  formBodyProps: PropTypes.object,
};

LocationForm.defaultProps = {
  isAddForm: false,
  isEditForm: false,
  isViewForm: false,
  formBodyProps: {},
};

LocationForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default LocationForm;
