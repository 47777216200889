import React from 'react';
import PropTypes from 'prop-types';
import { Motion } from 'react-motion';

import { CARD_HEIGHT } from '../card/inbox/InboxEvent';
import CardTemplate from '../card/Card.template';

function CardsMotion({
  cards, row, cardId, type,
}) {
  return (
    <div className="maintenance--items">
      {
        cards.map((card, i) => {
          if (card.id === cardId) {
            return null;
          }

          const isCardOnRow = i >= row && row !== -1;

          const style = isCardOnRow
            ? { y: CARD_HEIGHT }
            : { y: 0 };

          return (
            <Motion
              style={style}
              key={card.id}
            >
              {({ y }) => (
                <div
                  style={{
                    transform: `translate(0, ${y}px)`,
                  }}
                >
                  <CardTemplate
                    card={card}
                    type={type}
                  />
                </div>
              )}
            </Motion>
          );
        })
      }
    </div>
  );
}

CardsMotion.propTypes = {
  cards: PropTypes.array.isRequired,
  row: PropTypes.number.isRequired,
  cardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
};

CardsMotion.defaultProps = {
  eventId: -1,
  row: -1,
};

export default CardsMotion;
