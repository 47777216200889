import React from 'react';
import { AI_APPS_PERMISSIONS } from '../../../../../../common/constants/permissions';
import { usePermissionInstance } from '../../../common/permissions/usePermissionInstance';
import { Link } from 'react-router-dom';
import { getAiAppLink } from '../../../index/routes';
interface ActionButtonsProps {
  aiAppId: string;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ aiAppId }) => {
  const { canEditAiApp } = usePermissionInstance(AI_APPS_PERMISSIONS);

  if (!canEditAiApp) {
    return null;
  }

  return (
    <td className='td-edit td-edit-fixed'>
      <div className='td-buttons'>
        <Link to={getAiAppLink(aiAppId)}>
          <button className='button button__xs light-grey-white' aria-label='Open details'>
            <i className='icon-caret-right' />
          </button>
        </Link>
      </div>
    </td>
  );
};

export default React.memo(ActionButtons);
