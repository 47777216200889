import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import { getClusterById } from '../../core/api/cluster';
import { updateCluster } from '../../core/common/cluster';

export const fetchClusterOfClusterGroupByMachine = createAction(
  'fetch cluster of clusters group by machine',
  (clusterId, { name, category }) => ({ clusterId, clusterGroup: { name, category } }),
);

export const fetchClusterOfClusterGroupByMachineSuccess = createAction(
  'fetch cluster of clusters group by machine successfully',
  (clusterGroup, clusterId, cluster) => ({ clusterGroup, clusterId, cluster }),
);

export const fetchClusterOfClusterGroupByMachineFail = createAction(
  'fetch cluster of clusters group by machine fail',
  (clusterGroup, clusterId, error) => ({ clusterGroup, clusterId, error }),
);

export const reducer = {
  [fetchClusterOfClusterGroupByMachine]: (state, { clusterId, clusterGroup }) => updateCluster(
    state,
    clusterGroup,
    clusterId,
    {
      details: {
        loading: true,
        loaded: false,
        error: null,
      },
    },
  ),

  [fetchClusterOfClusterGroupByMachineSuccess]: (state, { clusterId, clusterGroup, cluster }) => updateCluster(
    state,
    clusterGroup,
    clusterId,
    {
      ...cluster,
      details: {
        loading: false,
        loaded: true,
        error: null,
      },
    },
  ),

  [fetchClusterOfClusterGroupByMachineFail]: (state, { clusterId, clusterGroup, error }) => updateCluster(
    state,
    clusterGroup,
    clusterId,
    {
      details: {
        loading: false,
        loaded: false,
        error,
      },
    },
  ),
};

export function* fetchClusterOfClusterGroupByMachineSaga({ payload: { clusterId, clusterGroup } }) {
  const { response, error } = yield call(getClusterById, clusterId);

  if (response) {
    yield put(fetchClusterOfClusterGroupByMachineSuccess(clusterGroup, clusterId, response));
  } else {
    yield put(fetchClusterOfClusterGroupByMachineFail(clusterGroup, clusterId, error));
  }
}

export function* watchFetchClusterOfClusterGroupByMachine() {
  yield takeEvery(
    fetchClusterOfClusterGroupByMachine.getType(),
    fetchClusterOfClusterGroupByMachineSaga,
  );
}
