import { find } from 'lodash';
import { createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getDataPointsOfDevice, getDeviceById } from '../selectors/datatron.selector';
import * as api from '../../core/api';
import { initializeDataPoint } from '../../core/common/dataPoint';
import { compareById, replaceObjectInList } from '../../../../common/helpers/object';
import { UploadExcelResponse } from '../../../../common/types/api/datatron.datapoints';
import { Todo } from '../../../../common/types/common';

interface Action {
  datatronId: string;
  deviceId: string;
  file: File;
}
interface SuccessUpdateResponse {
  deviceId: string;
  bulkUploadResponse: UploadExcelResponse;
}

interface FailUpdateResponse {
  deviceId: string;
  error: Todo;
}

export const updateBulkExcelDataPoints = createAction(
  'update by excel bulk data points',
  (datatronId: string, deviceId: string, file: File) => ({
    payload: { datatronId, deviceId, file },
  }),
);

export const updateBulkExcelDataPointsSuccess = createAction(
  'update by excel bulk data points - success',
  (deviceId: string, bulkUploadResponse: UploadExcelResponse) => ({
    payload: { deviceId, bulkUploadResponse },
  }),
);

export const updateBulkExcelDataPointsFail = createAction(
  'update by excel bulk data points - fail',
  (deviceId: string, error) => ({ payload: { deviceId, error } }),
);

export const resetUpdateBulkExcelResponse = createAction(
  'reset update bulk excel response',
  (deviceId: string) => ({ payload: { deviceId } }),
);

export const reducer = {
  [updateBulkExcelDataPoints.type]: (state: Todo, action: Action) => {
    const { deviceId } = action;
    const device = getDeviceById(state, deviceId);

    if (!device) return state;

    const newDevice = {
      ...device,
      _update: {
        ...device._update,
        loading: true,
        error: null,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  },

  [resetUpdateBulkExcelResponse.type]: (state: Todo, action: Pick<Action, 'deviceId'>) => {
    const { deviceId } = action;
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const newDevice = {
      ...device,
      _update: {
        isRenaming: false,
        loaded: false,
        loading: false,
        error: null,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  },

  [updateBulkExcelDataPointsSuccess.type]: (
    state: Todo,
    { deviceId, bulkUploadResponse }: SuccessUpdateResponse,
  ) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;
    if (device._update.loading === false) return state;

    const deviceDataPoints = getDataPointsOfDevice(device);
    const { datapoints, bulkReport } = bulkUploadResponse;

    const archivedDataPoints = bulkReport.completed
      .filter((data) => data.action === 'archived')
      .map((data) => {
        const dataPointToRemove = find(deviceDataPoints, { uuid: data.uuid });
        return dataPointToRemove;
      });

    const newDevice = {
      ...device,
      dataPoints: {
        query: '',
        sort: { option: null, way: null },
        list: datapoints,
        loading: false,
        loaded: true,
        error: null,
      },
      archivedDataPoints: {
        ...device.archivedDataPoints,
        list: [...archivedDataPoints, ...device.archivedDataPoints.list],
      },
      _update: {
        ...device._update,
        loading: false,
        updateBulkResponse: bulkReport,
        error: null,
      },
    };
    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          loading: false,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  },

  [updateBulkExcelDataPointsFail.type]: (state: Todo, { deviceId, error }: FailUpdateResponse) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;
    if (device._update.loading === false) return state;

    const newDevice = {
      ...device,
      _update: {
        ...device._update,
        loading: false,
        error,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  },
};

/**
 * Saga to handle bulk excel data points update.
 * @param {object} action - The action object.
 * @param {string} action.payload.datatronId - The datatron ID.
 * @param {string} action.payload.deviceId - The device ID.
 * @param {File} action.payload.file - The excel file to be uploaded.
 */

export function* updateBulkExcelDataPointsSaga({
  payload: { datatronId, deviceId, file },
}: {
  payload: Action;
}) {
  try {
    const { response, error } = yield call(api.datatrons.uploadDatapoints, {
      datatronId,
      deviceId,
      file,
    });

    if (response) {
      const { bulkReport } = response;
      const datapoints = response.datapoints.map(initializeDataPoint);
      yield put(updateBulkExcelDataPointsSuccess(deviceId, { datapoints, bulkReport }));
    } else {
      yield put(updateBulkExcelDataPointsFail(deviceId, error));
    }
  } catch (error) {
    yield put(updateBulkExcelDataPointsFail(deviceId, JSON.stringify(error)));
  }
}

export function* watchUpdateBulkExcelDataPoints() {
  yield takeEvery(updateBulkExcelDataPoints, updateBulkExcelDataPointsSaga);
}
