import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { getExperimentsFilters } from '../selectors/experimentsPage.selector';

export const fetchExperimentsPagination = createAction(
  'fetch experiments pagination',
  (page = 1) => page,
);

export const fetchExperimentsPaginationSuccess = createAction(
  'fetch experiments pagination - success',
  (pagination) => pagination,
);

export const fetchExperimentsPaginationFail = createAction(
  'fetch experiments pagination - fail',
  (error) => error,
);

export const reducer = {
  [fetchExperimentsPaginationSuccess]: (state, pagination) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      experiments: {
        ...state.experimentsPage.experiments,
        pagination,
      },
    },
  }),
};

export function* fetchExperimentsPaginationSaga({ payload: page }) {
  const state = yield select();
  const filters = yield call(getExperimentsFilters, state);

  const { response, error } = yield call(api.experiments.getExperimentsPagination, { page, ...filters });

  if (response) {
    yield put(fetchExperimentsPaginationSuccess(response.pagination));
  } else {
    yield put(fetchExperimentsPaginationFail(error));
  }
}

export function* watchFetchExperimentsPagination() {
  yield takeEvery(fetchExperimentsPagination.getType(), fetchExperimentsPaginationSaga);
}
