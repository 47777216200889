import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RouteConfigComponentProps, renderRoutes } from 'react-router-config';
import { DEFAULT_TAB } from '../../redux/constants/datatron.tabs.constants';
import {
  DP_ARCHIVE_DATA_POINT,
  DP_ARCHIVE_DEVICE,
  DP_COMMAND_HISTORY,
  DP_IMPORT_ACTION,
  DP_QUICK_COMMAND,
  DP_DOWNLOAD_FILE,
  DP_UPLOAD_FILE,
} from '../../redux/constants/modals.constants';
import { selectTab } from '../../redux/modules/datatron.tabs.module';
import {
  getDatatron,
  selectIsLoaded,
  selectIsLoading,
} from '../../redux/selectors/datatron.selector';
import { isModalOpen } from '../../redux/selectors/modals.selector';
import { selector } from '../../redux/selectors/util';
import Loader from '../_tools/loader/Loader';
import { LocationBreadcrumbs } from '../_tools/locationBreadcrumbs/LocationBreadcrumbs';
import * as routeHandlers from '../index/routeHandlers';
import Details from './details/Details';
import {
  ImportModal,
  QuickCommandModal,
  ArchiveDataPointModal,
  ArchiveDeviceModal,
  CommandHistoryModal,
  DownloadFileModal,
  UploadFileModal,
} from './modals';

interface Props extends RouteConfigComponentProps<{ datatron: string }> {
  children: React.ReactNode;
}

/** A simple map for type safe selectors that are used within this component */
const isModalOpenSelector = {
  [DP_ARCHIVE_DATA_POINT]: selector((state) =>
    isModalOpen(state, DP_ARCHIVE_DATA_POINT),
  ),
  [DP_ARCHIVE_DEVICE]: selector((state) =>
    isModalOpen(state, DP_ARCHIVE_DEVICE),
  ),
  [DP_IMPORT_ACTION]: selector((state) => isModalOpen(state, DP_IMPORT_ACTION)),
  [DP_COMMAND_HISTORY]: selector((state) =>
    isModalOpen(state, DP_COMMAND_HISTORY),
  ),
  [DP_QUICK_COMMAND]: selector((state) => isModalOpen(state, DP_QUICK_COMMAND)),
  [DP_DOWNLOAD_FILE]: selector((state) => isModalOpen(state, DP_DOWNLOAD_FILE)),
  [DP_UPLOAD_FILE]: selector((state) => isModalOpen(state, DP_UPLOAD_FILE)),

} as const;

export const Datatron: React.FC<Props> = ({ match, route }) => {
  const datatron = useSelector(getDatatron);
  const isLoading = useSelector(selectIsLoading);
  const isLoaded = useSelector(selectIsLoaded);
  const isArchiveDataPointModalOpen = useSelector(
    isModalOpenSelector[DP_ARCHIVE_DATA_POINT],
  );
  const isArchiveDeviceModalOpen = useSelector(
    isModalOpenSelector[DP_ARCHIVE_DEVICE],
  );
  const isImportDeviceModalOpen = useSelector(
    isModalOpenSelector[DP_IMPORT_ACTION],
  );
  const isCommandHistoryModalOpen = useSelector(
    isModalOpenSelector[DP_COMMAND_HISTORY],
  );
  const isQuickCommandModalOpen = useSelector(
    isModalOpenSelector[DP_QUICK_COMMAND],
  );
  const isDownloadFileModalOpen = useSelector(
    isModalOpenSelector[DP_DOWNLOAD_FILE],
  );
  const isUploadModalOpen = useSelector(
    isModalOpenSelector[DP_UPLOAD_FILE],
  );

  const datatronId = datatron?.id ?? match.params.datatron;
  const dispatch = useDispatch();
  useEffect(() => {
    routeHandlers.onDatatronEnter(datatronId);
    dispatch(selectTab(DEFAULT_TAB));
    return () => routeHandlers.onDatatronLeave();
  }, [datatronId, dispatch]);

  if (!match.isExact) {
    return renderRoutes(route?.routes ?? []);
  }

  return (
    <div className="machine-page col--1">
      <div className="subheader">
        <div className="subheader-in">
          <div className="subheader--left">
            <LocationBreadcrumbs />
          </div>
          <div className="subheader--right" />
        </div>
      </div>
      <div className="col--2--wrap">
        <div className="col--2 col--2--data">
          <div className="col--2--right">{isLoaded && <Details />}</div>
        </div>
      </div>
      <Loader show={isLoading} fullHeight solidBackground />
      {isArchiveDataPointModalOpen && <ArchiveDataPointModal />}
      {isArchiveDeviceModalOpen && <ArchiveDeviceModal />}
      {isImportDeviceModalOpen && <ImportModal />}
      {isCommandHistoryModalOpen && <CommandHistoryModal />}
      {isQuickCommandModalOpen && <QuickCommandModal />}
      {isDownloadFileModalOpen && <DownloadFileModal datatronId={datatron.id}/>}
      {isUploadModalOpen && <UploadFileModal datatronId={datatron.id}/>}
    </div>
  );
};
