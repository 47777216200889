import { connect } from 'react-redux';

import {
  getX, getStylesByProp,
} from '../../../../../core/common/reportChart';
import Line from './Line';

export function mapStateToProps(state, { prop }) {
  return {
    className: getStylesByProp(prop).line,
    getY: (point) => point[prop],
    getX,
  };
}

export default connect(mapStateToProps)(Line);
