import { range } from 'lodash';

export function getRandomResults(results) {
  const keys = Object.keys(results);
  const count = keys.length < 3 ? keys.length : 3;
  return range(count).map(() => {
    const index = Math.floor(Math.random() * keys.length);
    const item = results[keys[index]];
    keys.splice(index, 1);
    return item;
  });
}
