import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterByQuery } from '../../../../../../../../redux/modules/datatron.devices.dataPoints.sort.filter.module';
import { getQueryByDeviceId } from '../../../../../../../../redux/selectors/datatron.selector';
import { TableQuerySearch, Props as ChildProps } from '../../../../../../../common/tableQuerySearch/TableQuerySearch';

export interface Props extends Omit<ChildProps, 'value' | 'onQueryChange'> {
  deviceId: string;
  showArchived: boolean;
}

export const QueryFilter: React.FC<Props> = ({
  deviceId,
  showArchived,
  ...rest
}) => {
  const dispatch = useDispatch();
  const query = useSelector((state) =>
    getQueryByDeviceId(state, deviceId, showArchived),
  );
  const onQueryChange = useCallback(
    (filteredQuery) => {
      dispatch(filterByQuery(filteredQuery, deviceId, showArchived));
    },
    [dispatch, deviceId, showArchived],
  );

  return (
    <TableQuerySearch value={query} onQueryChange={onQueryChange} {...rest} />
  );
};
