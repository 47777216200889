import { useCallback, useEffect, useState } from 'react';
import { apiRequest } from '../../api/_tools';

export const useImageFetch = (url: string) => {
  const [imagePath, imagePathSet] = useState<string>('');

  const fetchImage = useCallback(() =>
    apiRequest(url, {}).then((res) => {
      const response = new Response(res.body);
      response.blob().then((blob) => {
        const newUrl = URL.createObjectURL(blob);
        imagePathSet(newUrl);
      });

    }), [url]);

  useEffect(() => {
    if (imagePath === '')
      fetchImage().then();
    return () => {
      URL.revokeObjectURL(imagePath);
    };
  }, [fetchImage, imagePath]);

  return { imagePath, fetchImage };
};