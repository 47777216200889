import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import UserRowContainer from './UserRow.container';
import UsersHeaderContainer from './header/UsersHeader.container';
import PaginationBar from '../../../common/paginationBar/PaginationBar';
import * as routeHandlers from '../../../index/routeHandlers';

export const UsersModule = ({
  list, nameQuery, emailQuery, pagination, paginationAvailable, choosePage, route, history,
}) => {
  useEffect(() => () => {
    routeHandlers.onAdminUsersLeave();
  }, []);
  return (
    <div className="user-table">
      <table className="table">
        <UsersHeaderContainer />
        <tbody>
          { list.map((item) => <UserRowContainer key={item.id} user={item} nameToHighlight={nameQuery} emailToHighlight={emailQuery} pushPath={history.push} />) }
        </tbody>
      </table>
      { paginationAvailable && (<PaginationBar {...pagination} choosePage={choosePage} />) }
      { renderRoutes(route.routes) }
    </div>
  );
};

UsersModule.propTypes = {
  route: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  paginationAvailable: PropTypes.bool.isRequired,
  choosePage: PropTypes.func.isRequired,
  nameQuery: PropTypes.string,
  emailQuery: PropTypes.string,
};

UsersModule.defaultProps = {
  nameQuery: '',
  emailQuery: '',
};

export default UsersModule;
