import { connect } from 'react-redux';

import DataPointsFiltersFrame from './DataPointsFiltersFrame';
import { _PAGE_REPORT_FORM as modalId } from '../../../../../../redux/constants/modals.constants';
import {
  MODAL_MAIN_FRAME,
  MODAL_DATA_POINTS_FILTERS_FRAME,
  MODAL_DATA_POINTS_ADD_FRAME,
} from '../../../../../../core/common/report.form';
import { hasArchivedDataPoints } from '../../../../../../core/common/report';
import { goBackModalPath, setModalPath } from '../../../../../../redux/modules/modals.module';
import { restoreFormField } from '../../../../../../redux/modules/common.form.module';
import { setDisabledEntries } from '../../../../../../redux/modules/configRepoExplorer.disabled.list.module';
import { setActiveView } from '../../../../../../redux/modules/configRepoExplorer.view.module';
import * as form from '../../../../../../redux/constants/report.form.constants';
import { getFormFields } from '../../../../../../redux/selectors/report.form.selector';
import { DATA_POINTS, EQUIPMENT } from '../../../../../../redux/constants/configRepoExplorer.entry.types.constants';

export const mapStateToProps = (state) => {
  const formValues = getFormFields(state);
  const selectedDataPoints = formValues[form.DATA_POINTS_FILTERS_FIELD] || [];
  const addDataPointButtonDisabled = selectedDataPoints.length >= 8;

  return {
    addDataPointButtonDisabled,
    selectedDataPointsIds: selectedDataPoints.map((item) => item.id),
    hasArchivedDataPoints: hasArchivedDataPoints(selectedDataPoints),
  };
};

export const mapDispatchToProps = {
  goBackModalPath,
  setModalPath,
  restoreFormField,
  setDisabledEntries,
  setActiveView,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onDataPointsAddClick: (e) => {
    e.preventDefault();
    dispatchProps.setDisabledEntries(DATA_POINTS, stateProps.selectedDataPointsIds);
    dispatchProps.setActiveView(EQUIPMENT);
    dispatchProps.setModalPath(modalId, [MODAL_MAIN_FRAME, MODAL_DATA_POINTS_FILTERS_FRAME, MODAL_DATA_POINTS_ADD_FRAME]);
  },
  onCancelClick: (e) => {
    if (e) { e.preventDefault(); }
    dispatchProps.restoreFormField(form.REPORT_FORM_NAME, form.DATA_POINTS_FILTERS_FIELD);
    dispatchProps.goBackModalPath(modalId);
  },
  onDoneClick: (e) => {
    if (e) { e.preventDefault(); }
    dispatchProps.goBackModalPath(modalId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DataPointsFiltersFrame);
