import {
  COLUMN_PRIORITIZED, COLUMN_IN_PROGRESS, COLUMN_INBOX,
} from '../constants/column.constants';
import { MAINTENANCE_EVENTS_ON_ONE_PAGE } from '../constants/maintenance.constants';

const machine = {
  id: 8845377,
  name: 'Robotron-S2300',
  location: 'Berlin',
  link: '/app/inventory/machines/123',
  image: '/img/position.png',
};

const comments = [
  {
    id: 1,
    text: `All users on MySpace will know that there are millions
      of people out there. Every day besides so many people
      joining this community, there are many others who will
      be looking out for friends. This will mean that
      they are naturally looking out for good people who are
      interesting enough. For this,
      the profile has to be very interesting.`,
    user: {
      name: 'Jurgen Walter',
      id: 1,
      firstName: 'Jurgen',
      lastName: 'Walter',
    },
  },
  {
    id: 2,
    text: 'All users on MySpace will know that there are millions of people out there.',
    user: {
      name: 'Terry Lawrence',
      id: 2,
      firstName: 'Terry',
      lastName: 'Lawrence',
    },
  },
];

const testEvents = [
  {
    id: 1,
    title: 'Event 1',
    timestamp: new Date(2013, 2, 2, 1, 121),
    comments: [...comments],
    assignee: {
      id: 3,
      fullName: 'K. Smith',
      userName: 'ksmith',
    },
    category: 'fatal',
    machine: { ...machine },
  },
];

export const maintenanceTestState = {
  events: testEvents,
  expanded: {},
  columns: {
    [COLUMN_INBOX]: [],
    [COLUMN_PRIORITIZED]: [],
    [COLUMN_IN_PROGRESS]: [],
  },
  hiddenEvents: {},
  movedEvents: {},
  assignUser: {
    activityId: '1',
    organizationId: '2',
  },
  dnd: {
    isDragging: true,
  },
  activities: [],
  activitiesState: {
    loaded: false,
    loading: false,
    error: '',
  },
  loaded: false,
  loading: false,
  error: '',
  loadEventsFrom: 0,
  loadEventsTo: MAINTENANCE_EVENTS_ON_ONE_PAGE,
  moreAvailable: true,
  counts: {
    events: testEvents.length,
    loaded: false,
    loading: false,
    error: null,
  },
};

export const maintenanceInitialState = {
  events: [],
  expanded: {},
  columns: {
    [COLUMN_INBOX]: [],
    [COLUMN_PRIORITIZED]: [],
    [COLUMN_IN_PROGRESS]: [],
  },
  hiddenEvents: {},
  movedEvents: {},
  assignUser: {
    activityId: null,
    organizationId: null,
  },
  dnd: {
    isDragging: false,
  },
  activities: [],
  activitiesState: {
    loaded: false,
    loading: false,
    error: '',
  },
  loaded: false,
  loading: false,
  error: '',
  loadEventsFrom: 0,
  loadEventsTo: MAINTENANCE_EVENTS_ON_ONE_PAGE,
  moreAvailable: true,
  counts: {
    events: 0,
    loaded: false,
    loading: false,
    error: null,
  },
};
