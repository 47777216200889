import { connect } from 'react-redux';

import BatchDetailsPage from './BatchDetailsPage';
import * as selector from '../../../redux/selectors/batchDetails.selector';

export function mapStateToProps(state) {
  return {
    batch: selector.batch(state),
    loading: selector.loading(state),
    loaded: selector.loaded(state),
  };
}

export default connect(mapStateToProps)(BatchDetailsPage);
