import React from 'react';
import PropTypes from 'prop-types';
import AssistantContainer from './assistant/Assistant.container';
import NoAssistance from './NoAssistance';
import NewAssistanceModalContainer from './modals/newAssistant/NewAssistantModal.container';
import RemoveAssistantModalContainer from './modals/removeAssistant/RemoveAssistantModal.container';
import AddNewAssistanceButton from './AddNewAssistanceButton';

const Assistance = ({
  assistants,
  isLoaded,
  inventoryType,
  handleRemoveAssistantClick,
  handleAddNewAssistantClick,
  isNewAssistantModalOpen,
  isRemoveAssistantModalOpen,
  canAddAssistants,
  canDeleteAssistants,
}) => (
  <div>
    {
      canAddAssistants &&
        <AddNewAssistanceButton onClick={handleAddNewAssistantClick} isTopButton />
    }
    <div className="inventory-tab--in related">
      { isLoaded && !!assistants.length && (
        <div className="assistance--list">
          {
            assistants.map((assistant) => (
              <AssistantContainer
                key={assistant.id}
                assistant={assistant}
                inventoryType={inventoryType}
                handleRemoveAssistantClick={handleRemoveAssistantClick}
                canDeleteAssistants={canDeleteAssistants}
              />
            ))
          }
        </div>
      )}
      {
        isLoaded &&
        !assistants.length && (
          <NoAssistance onClick={handleAddNewAssistantClick} canAddAssistants={canAddAssistants} />
        )
      }
    </div>
    { isNewAssistantModalOpen && (
      <NewAssistanceModalContainer
        inventoryType={inventoryType}
      />
    )}
    { isRemoveAssistantModalOpen && (
      <RemoveAssistantModalContainer
        inventoryType={inventoryType}
      />
    )}
  </div>
);

Assistance.propTypes = {
  assistants: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  inventoryType: PropTypes.string.isRequired,
  isNewAssistantModalOpen: PropTypes.bool.isRequired,
  isRemoveAssistantModalOpen: PropTypes.bool.isRequired,
  canAddAssistants: PropTypes.bool.isRequired,
  canDeleteAssistants: PropTypes.bool.isRequired,
  handleRemoveAssistantClick: PropTypes.func.isRequired,
  handleAddNewAssistantClick: PropTypes.func.isRequired,
};

export default Assistance;
