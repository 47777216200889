import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { forEach, filter } from 'lodash';

import LeftBarItem from '../../items/LeftBarItem';
import RightBarItem from '../../items/RightBarItem';
import RightBarPlaceholderSuccess from './placeholder/RightBarPlaceholderSuccess';
import RightBarPlaceholderEmpty from './placeholder/RightBarPlaceholderEmpty';
import * as entryTypes from '../../../../../redux/constants/configRepoExplorer.entry.types.constants';
import { getEntryTitle } from '../../../../../core/common/configRepoExplorer';
import LoaderLocal from '../../../loader/LoaderLocal';
import SelectAllButton from './headbar/SelectAllButton';
import Search from './headbar/Search.container';

export class ConfigRepoExplorer extends Component {
  static propTypes = {
    canChooseDataPoint: PropTypes.bool.isRequired,
    canChooseManyDataPoints: PropTypes.bool.isRequired,

    equipments: PropTypes.array.isRequired,
    datatrons: PropTypes.array.isRequired,
    devices: PropTypes.array.isRequired,
    dataPoints: PropTypes.array.isRequired,
    machines: PropTypes.array.isRequired,

    selectedEquipment: PropTypes.object,
    selectedDatatron: PropTypes.object,
    selectedDevice: PropTypes.object,
    selectedDataPoints: PropTypes.array,
    selectedMachine: PropTypes.object,

    fetch: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    activeView: PropTypes.string.isRequired,
    setActiveView: PropTypes.func.isRequired,
    machinesEnabled: PropTypes.bool,
    ignoreSubscriptions: PropTypes.bool,
    allDataPointsSelected: PropTypes.bool,
    dataPointFiltersEmpty: PropTypes.bool,
    highlightText: PropTypes.string,
  };

  static defaultProps = {
    allDataPointsSelected: false,
    dataPointFiltersEmpty: true,
    highlightText: '',
  };

  constructor(props) {
    super(props);

    this.changeWaitingFor = this.changeWaitingFor.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDeselect = this.handleDeselect.bind(this);
    this.getOptionsToSelect = this.getOptionsToSelect.bind(this);
    this.getSelectedOptions = this.getSelectedOptions.bind(this);
  }

  componentWillMount() {
    const { setActiveView, fetch, machinesEnabled } = this.props;
    setActiveView(entryTypes.DATATRONS);
    fetch(entryTypes.DATATRONS);
    if (machinesEnabled) {
      fetch(entryTypes.MACHINES);
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  getSelectedOptions() {
    const {
      selectedEquipment,
      selectedMachine,
      selectedDevice,
      selectedDatatron,
      selectedDataPoints,
      canChooseDataPoint,
    } = this.props;
    const list = [];

    if (selectedEquipment) {
      list.push({
        ...selectedEquipment,
        type: entryTypes.EQUIPMENT,
      });
    }

    if (selectedDatatron) {
      list.push({
        ...selectedDatatron,
        type: entryTypes.DATATRONS,
      });
    }

    if (selectedMachine) {
      list.push({
        ...selectedMachine,
        type: entryTypes.MACHINES,
      });
    }

    if (selectedDatatron && selectedDevice) {
      list.push({
        ...selectedDevice,
        type: entryTypes.DEVICES,
      });
    }

    if (
      (
        (selectedDatatron && selectedDevice)
        || selectedMachine
      )
      && canChooseDataPoint
      && selectedDataPoints
      && selectedDataPoints.length
    ) {
      forEach(
        selectedDataPoints,
        (item) => {
          list.push({
            ...item,
            type: entryTypes.DATA_POINTS,
          });
        },
      );
    }

    return list;
  }

  getOptionsToSelect() {
    const {
      equipments,
      dataPoints,
      devices,
      datatrons,
      machines,
      activeView,
    } = this.props;

    if (activeView === entryTypes.EQUIPMENT) return equipments;
    if (activeView === entryTypes.MACHINES) return machines;
    if (activeView === entryTypes.DATA_POINTS) return dataPoints;
    if (activeView === entryTypes.DEVICES) return devices;
    return datatrons;
  }

  getLeftSidePlaceholders(selectedOptions = []) {
    const { activeView, loading } = this.props;
    const list = [];

    if (!loading && !selectedOptions.length) {
      if (activeView === entryTypes.EQUIPMENT) {
        list.push(<LeftBarItem
          key={entryTypes.EQUIPMENT}
          type={entryTypes.EQUIPMENT}
          isPassive
          isActive={activeView === entryTypes.EQUIPMENT}
          isSelected={activeView === entryTypes.EQUIPMENT}
        />);
      } else if (activeView === entryTypes.DATATRONS) {
        list.push(<LeftBarItem
          key={entryTypes.DATATRONS}
          type={entryTypes.DATATRONS}
          isPassive
          isActive={activeView === entryTypes.DATATRONS}
          isSelected={activeView === entryTypes.DATATRONS}
        />);
      }
    }

    return list;
  }

  handleSelectAll() {
    const {
      select,
      canChooseManyDataPoints,
      activeView,
      allDataPointsSelected,
      ignoreSubscriptions,
    } = this.props;

    if (allDataPointsSelected) {
      select(activeView, []);
    } else if (canChooseManyDataPoints && activeView === entryTypes.DATA_POINTS) {
      let optionsToSelect = this.getOptionsToSelect().filter((item) => !item.isDisabled);
      if (!ignoreSubscriptions) {
        optionsToSelect = optionsToSelect.filter((item) => !item.subscription);
      }

      select(activeView, optionsToSelect.map((item) => item.id));
    }
  }

  handleSelect(itemId) {
    const {
      select,
      fetch,
      selectedDatatron,
      canChooseManyDataPoints,
      selectedDataPoints,
      activeView,
    } = this.props;

    switch (activeView) {
      case entryTypes.EQUIPMENT: {
        select(activeView, itemId);
        this.changeWaitingFor(itemId);
        break;
      }
      case entryTypes.DATATRONS: {
        select(activeView, itemId);
        this.changeWaitingFor(entryTypes.DEVICES);
        fetch(entryTypes.DEVICES, itemId);
        break;
      }
      case entryTypes.MACHINES: {
        select(activeView, itemId);
        this.changeWaitingFor(entryTypes.DATA_POINTS);
        fetch(entryTypes.DATA_POINTS, null, null, itemId);
        break;
      }
      case entryTypes.DEVICES: {
        select(activeView, itemId);
        this.changeWaitingFor(entryTypes.DATA_POINTS);
        fetch(entryTypes.DATA_POINTS, selectedDatatron.id, itemId);
        break;
      }
      case entryTypes.DATA_POINTS: {
        if (canChooseManyDataPoints) {
          const selectedDataPointIds = (selectedDataPoints || []).map((item) => item.id);
          const isSelected = selectedDataPointIds.indexOf(itemId) >= 0;

          const newList = isSelected
            ? filter(selectedDataPointIds, (item) => item !== itemId)
            : [
              ...selectedDataPointIds,
              itemId,
            ];
          select(activeView, newList);
        } else {
          select(activeView, [itemId]);
        }
        break;
      }
      default: break;
    }
  }

  handleDeselect(type) {
    const { select } = this.props;

    switch (type) {
      case entryTypes.EQUIPMENT: {
        select(entryTypes.EQUIPMENT, null);
        select(entryTypes.DATATRONS, null);
        select(entryTypes.MACHINES, null);
        select(entryTypes.DEVICES, null);
        select(entryTypes.DATA_POINTS, null);
        break;
      }
      case entryTypes.DATATRONS: {
        select(entryTypes.DATATRONS, null);
        select(entryTypes.DEVICES, null);
        select(entryTypes.DATA_POINTS, null);
        break;
      }
      case entryTypes.DEVICES: {
        select(entryTypes.DEVICES, null);
        select(entryTypes.DATA_POINTS, null);
        break;
      }
      case entryTypes.DATA_POINTS: {
        select(entryTypes.DATA_POINTS, null);
        break;
      }
      case entryTypes.MACHINES: {
        select(entryTypes.MACHINES, null);
        select(entryTypes.DATA_POINTS, null);
        break;
      }
      default: break;
    }
    this.changeWaitingFor(type);
  }

  changeWaitingFor(value) {
    this.props.setActiveView(value);
  }

  render() {
    const {
      loading,
      canChooseDataPoint,
      canChooseManyDataPoints,
      selectedDataPoints,
      activeView,
      ignoreSubscriptions,
      allDataPointsSelected,
      dataPointFiltersEmpty,
      highlightText,
    } = this.props;

    const selectedOptions = this.getSelectedOptions();
    const optionsToSelect = this.getOptionsToSelect();
    const leftSidePlaceholders = this.getLeftSidePlaceholders(selectedOptions);

    const showCheckboxes = canChooseManyDataPoints
      && activeView === entryTypes.DATA_POINTS;
    const showSuccessPlaceholder = !loading
      && activeView === entryTypes.DATA_POINTS
      && (
        (canChooseManyDataPoints && selectedDataPoints.length > 0 && !optionsToSelect.length)
        || (canChooseDataPoint && selectedDataPoints.length > 0)
      );
    const showNoDataPlaceholder = !loading
      && !optionsToSelect.length
      && dataPointFiltersEmpty
      && !showSuccessPlaceholder;

    const hasItems = !showSuccessPlaceholder && !showNoDataPlaceholder && optionsToSelect && optionsToSelect.length > 0;

    return (
      <div className="locations-all">
        <div className="locations-block">
          <div className="locations--left">
            <div className="locations-results">
              { selectedOptions.map((item) => (
                <LeftBarItem
                  key={item.id}
                  title={getEntryTitle({ entry: item, entryType: item.type })}
                  translateTitle={item.translateName || false}
                  type={item.type}
                  isActive
                  isSelected
                  onClick={this.handleDeselect}
                />
              )) }
              { leftSidePlaceholders }
            </div>
          </div>
          <div className="locations--right locations--right--wrap">
            { (canChooseManyDataPoints && activeView === entryTypes.DATA_POINTS) && (
              <div className="locations--right-top">
                <Search>
                  { hasItems && (
                    <SelectAllButton
                      onClick={this.handleSelectAll}
                      selected={allDataPointsSelected}
                    />
                  ) }
                </Search>
              </div>
            )}
            <div className="locations--right-content">
              <ul className="locations-list">
                { hasItems && optionsToSelect.map((item) => (
                  <RightBarItem
                    id={item.id}
                    title={getEntryTitle({ entry: item, entryType: activeView })}
                    translateTitle={item.translateName || false}
                    type={activeView}
                    hasTypeLabel={activeView !== entryTypes.EQUIPMENT}
                    key={item.id}
                    onClick={this.handleSelect}
                    isSelected={item.isSelected}
                    isDisabled={item.isDisabled}
                    showCheckbox={showCheckboxes}
                    highlightText={highlightText}
                    subscription={!ignoreSubscriptions ? item.subscription : null}
                  />
                )) }
              </ul>
              { loading && <LoaderLocal /> }
              { !!showSuccessPlaceholder && <RightBarPlaceholderSuccess /> }
              { !!showNoDataPlaceholder && <RightBarPlaceholderEmpty entryType={activeView} /> }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfigRepoExplorer;
