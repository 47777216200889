import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as eventsApi from '../../core/api/events.js';
import * as selector from '../selectors/maintenance.selector';
import * as entities from '../../../../common/constants/entities';

export const fetchMaintenanceCounts = createAction(
  'fetch maintenance counts',
);

export const fetchMaintenanceCountsSuccess = createAction(
  'fetch maintenance counts successfully',
  (events) => events,
);

export const fetchMaintenanceCountsFail = createAction(
  'fetch maintenance counts fail',
  (error) => error,
);

export const reducer = {
  [fetchMaintenanceCounts]: (state) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      counts: {
        ...state.maintenance.counts,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchMaintenanceCountsSuccess]: (state, eventsCount) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      counts: {
        ...state.maintenance.counts,
        events: eventsCount,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [fetchMaintenanceCountsFail]: (state, error) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      counts: {
        ...state.maintenance.counts,
        events: 0,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchMaintenanceCountsSaga() {
  const state = yield select();
  const finalCategories = yield call(selector.getInboxCategories, state);

  const events = yield call(eventsApi.getEventsCount, {
    [entities.CATEGORIES]: (finalCategories || []).map((category) => ({ id: category })),
    withoutActivityOnly: true,
  });

  if (events.response) {
    yield put(fetchMaintenanceCountsSuccess(events.response));
  } else {
    yield put(fetchMaintenanceCountsFail(events.error));
  }
}

export function* watchFetchMaintenanceCounts() {
  yield takeEvery(fetchMaintenanceCounts.getType(), fetchMaintenanceCountsSaga);
}
