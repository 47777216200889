import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MachineAssistantInfo = ({ assistant, target }) => (
  <>
    <FormattedMessage
      defaultMessage="Do you really want to remove this Assistant?"
      id="assistant.remove_assistant_confirmation"
      tagName="h3"
    />
    <br />
    <FormattedMessage
      id="common.machine"
      defaultMessage="Machine"
      tagName="h3"
    />
    <FormattedMessage
      id="machine.details.machine_name"
      defaultMessage="Machine Name"
    />
    :
    { target.name }
    <br />
    <FormattedMessage
      id="machine.details.model"
      defaultMessage="Model"
    />
    :
    { target.model }
    <br />
    <FormattedMessage
      id="machine.details.vendor"
      defaultMessage="Vendor"
    />
    :
    { target.make }
    <br />
    <br />
    <FormattedMessage
      id="common.assistant"
      defaultMessage="Assistant"
      tagName="h3"
    />
    <FormattedMessage
      id="common.full_name"
      defaultMessage="Full Name"
    />
    :
    { assistant.name }
    <br />
    <FormattedMessage
      id="common.phone_number"
      defaultMessage="Phone Number"
    />
    :
    { assistant.phoneNumber }
    <br />
    <FormattedMessage
      id="assistant.comment"
      defaultMessage="Comment"
    />
    :
    { assistant.comment }
    <br />
  </>
);

MachineAssistantInfo.propTypes = {
  assistant: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
};

export default MachineAssistantInfo;
