import { connect } from 'react-redux';

import MachinesFiltersFrame from './MachinesFiltersFrame';
import * as form from '../../../../../../redux/constants/report.form.constants';
import { _PAGE_REPORT_FORM as modalId } from '../../../../../../redux/constants/modals.constants';
import { goBackModalPath } from '../../../../../../redux/modules/modals.module';
import { restoreFormField } from '../../../../../../redux/modules/common.form.module';

export const mapDispatchToProps = {
  restoreFormField,
  goBackModalPath,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onCancelClick: (e) => {
    if (e) { e.preventDefault(); }
    dispatchProps.restoreFormField(form.REPORT_FORM_NAME, form.MACHINES_FILTERS_FIELD);
    dispatchProps.goBackModalPath(modalId);
  },
  onDoneClick: (e) => {
    if (e) { e.preventDefault(); }
    dispatchProps.goBackModalPath(modalId);
  },
});

export default connect(null, mapDispatchToProps, mergeProps)(MachinesFiltersFrame);
