import { createAction } from '@reduxjs/toolkit';
import { filter } from 'lodash';

import { selectReportByIndex, selectReportIndexById } from '../selectors/reports.selector';

export const toggleIndicator = createAction(
  'toggle report indicator',
  (reportId, indicatorType) => ({ payload: { reportId, indicatorType } }),
);

export const reducer = {
  [toggleIndicator.toString()]: (state, { reportId, indicatorType }) => {
    const reportIndex = selectReportIndexById(state, reportId);

    if (reportIndex === -1) {
      return state;
    }

    const report = selectReportByIndex(state, reportIndex);
    if (report.disabledProps.indexOf(indicatorType) >= 0) {
      report.disabledProps = filter(report.disabledProps, (item) => item !== indicatorType);
    } else {
      report.disabledProps.push(indicatorType);
    }

    return {
      ...state,
      reports: {
        ...state.reports,
        data: [
          ...state.reports.data.slice(0, reportIndex),
          report,
          ...state.reports.data.slice(reportIndex + 1),
        ],
      },
    };
  },
};
