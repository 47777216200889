import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import RemoveModalFooter from '../../core/footers/RemoveModalFooter';
import adminPageMessages from '../../../../messages/adminPage.messages';
import commonCoreMessages from '../../../../messages/common.core.messages';
import RemoveOrganizationError from './error/RemoveOrganizationError';

const handleSubmit = (e) => {
  e.preventDefault();
};

export const RemoveOrganizationModal = ({
  close,
  remove,
  error,
  organization,
  intl,
}) => {
  const object = intl.formatMessage(commonCoreMessages.organization);
  const modalTitle = intl.formatMessage(adminPageMessages.removeObject, { object });
  const warning = intl.formatMessage(adminPageMessages.removeObjectWarning, { object: organization.name });
  const handleRemove = () => remove(organization.id);
  const hasError = !!error;

  return (
    <BasicModal
      close={close}
      modalClass="modal--sm"
    >
      <div className="modal--content">
        <h3>{ modalTitle }</h3>

        <form className="form" onSubmit={handleSubmit}>
          <div className="modal--subtitle">
            <strong>{ warning }</strong>
          </div>
          { hasError && <RemoveOrganizationError organization={organization} error={error} /> }
          <div className="line" />
          <RemoveModalFooter
            onCancelClick={close}
            onRemoveClick={handleRemove}
            isRemoveButtonDisabled={hasError}
          />
        </form>
      </div>
    </BasicModal>
  );
};

RemoveOrganizationModal.propTypes = {
  close: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,

  error: PropTypes.object,
};

export default injectIntl(RemoveOrganizationModal);
