import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { getFiltersQuery } from '../selectors/admin.locations.selector';

export const fetchAdminOrganizationsPagination = createAction(
  'admin: fetch organizations pagination',
  (page = 1) => page,
);

export const fetchAdminOrganizationsPaginationSuccess = createAction(
  'admin: fetch organizations pagination - success',
  (pagination) => pagination,
);

export const fetchAdminOrganizationsPaginationFail = createAction(
  'admin: fetch organizations pagination - fail',
  (error) => error,
);

export const reducer = {
  [fetchAdminOrganizationsPaginationSuccess]: (state, pagination) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        pagination,
      },
    },
  }),
};

export function* fetchAdminOrganizationsPaginationSaga({ payload: page }) {
  const state = yield select();
  const filters = yield call(getFiltersQuery, state);

  const { response, error } = yield call(api.admin.getOrganizationsPagination, { page, ...filters });

  if (response) {
    yield put(fetchAdminOrganizationsPaginationSuccess(response.pagination));
  } else {
    yield put(fetchAdminOrganizationsPaginationFail(error));
  }
}

export function* watchFetchAdminOrganizationsPagination() {
  yield takeEvery(fetchAdminOrganizationsPagination.getType(), fetchAdminOrganizationsPaginationSaga);
}
