import React from 'react';
import { Field } from 'redux-form';
import CheckboxFiltersFormField from '../../../../../common/checkboxFilters/CheckboxFiltersFormField.container';
import { DEVICES } from '../../../../../../../../common/constants/entities';
import * as form from '../../../../../../redux/constants/aiApp.form.constants';
import ModalLine from '../../../../core/ModalLine';
import DoneModalFooter from '../../../../core/footers/DoneModalFooter';

interface DevicesFiltersFrameProps {
  onCancelClick: (e?: React.MouseEvent) => void;
  onDoneClick: (e?: React.MouseEvent) => void;
}

const DevicesFiltersFrame: React.FC<DevicesFiltersFrameProps> = ({
  onDoneClick,
  onCancelClick,
}) => (
  <div className='modal-move modal-filters'>
    <div className='modal-form-in'>
      <Field
        component={CheckboxFiltersFormField}
        name={form.DEVICES_FILTERS_FIELD}
        filtersType={DEVICES}
      />
    </div>
    <ModalLine />
    <DoneModalFooter onDoneClick={onDoneClick} onCancelClick={onCancelClick} />
  </div>
);

export default DevicesFiltersFrame;
