import querystring from 'querystring';
import {
  apiRequest,
  putApiRequest,
  postApiRequest,
  deleteApiRequest,
} from './_tools';

export const getExperiments = (filters) => {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/experiments?${query}`);
};
export const getExperimentsPagination = (filters) => {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/experiments/pagination?${query}`);
};
export const getExperiment = (id) => apiRequest(`/api/experiments/${id}`);
export const createExperiment = (body) => postApiRequest('/api/experiments', body);
export const updateExperiment = (id, body) => putApiRequest(`/api/experiments/${id}`, body);
export const archiveExperiment = (id) => deleteApiRequest(`/api/experiments/${id}`);

export const getMeasurementsByExperimentId = (experimentId) => apiRequest(`/api/experiments/${experimentId}/measurements`);

export const getMeasurements = (filters) => {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/measurements?${query}`);
};
export const getMeasurementsPagination = (filters) => {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/measurements/pagination?${query}`);
};
export const archiveMeasurement = (id) => deleteApiRequest(`/api/measurements/${id}`);
export const mergeMeasurement = (measurementId, experimentId) => postApiRequest(`/api/measurements/${measurementId}/merge`, { experimentId });
