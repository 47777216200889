import React from 'react';
import { Element as ScrollElement } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import * as machineForm from '../../../../../../redux/constants/machine.form.constants';
import SelectContainer from '../../../../../_tools/formFields/select/MachineSubscriptionDataPointSelect.container';

import commonFieldsMessages from '../../../../../../messages/common.fields.messages';

const filter = (subscription) => subscription
  && subscription.dataPoint
  && subscription.dataPoint.config
  && subscription.dataPoint.config.dataClass === 'digital'
  && subscription.dataPoint.config.dataType
  && subscription.dataPoint.config.dataType.name === 'boolean';

export const AltasigmaDataPoints = () => (
  <div>
    <label className="pb14">
      <FormattedMessage
        id="machine.form.analytics.data_point.label"
        defaultMessage="Select Data Point for Analytics"
      />
    </label>
    <div className="inputs--row">
      <div className="inputs--col">
        <label>
          <FormattedMessage {...commonFieldsMessages.cycleStart} />
          <ScrollElement name={machineForm.ANALYTICS_CYCLE_START_DATA_POINT_ID}>
            <Field
              name={machineForm.ANALYTICS_CYCLE_START_DATA_POINT_ID}
              component={SelectContainer}
              filter={filter}
            />
          </ScrollElement>
        </label>
      </div>
      <div className="inputs--col">
        <label>
          <FormattedMessage {...commonFieldsMessages.cycleEnd} />
          <ScrollElement name={machineForm.ANALYTICS_CYCLE_END_DATA_POINT_ID}>
            <Field
              name={machineForm.ANALYTICS_CYCLE_END_DATA_POINT_ID}
              component={SelectContainer}
              filter={filter}
            />
          </ScrollElement>
        </label>
      </div>
    </div>
  </div>
);

export default AltasigmaDataPoints;
