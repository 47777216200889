import { connect } from 'react-redux';

import ExperimentModalContainer from './ExperimentModal.container';
import { createExperiment } from '../../../../redux/modules/experiments.create.module';
import { getValuesFromExperiment } from '../../../../core/common/experiment.form';
import { closeModal } from '../../../../redux/modules/modals.module';
import { EXPERIMENT_ADD } from '../../../../redux/constants/modals.constants';

export const mapStateToProps = () => ({
  isAddForm: true,
  initialValues: getValuesFromExperiment(),
});

export const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (e) => {
    e.preventDefault();
    dispatch(createExperiment());
  },
  close: () => dispatch(closeModal(EXPERIMENT_ADD)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExperimentModalContainer);
