import { connect } from 'react-redux';

import Logo from './Logo';
import { selectAppConfig } from '../../../redux/selectors/appConfig.selector';
import { DEFAULT } from '../../../../../common/enums/themes';

export function mapStateToProps(state) {
  const config = selectAppConfig(state);
  const showCustomLogo = config.customLogo.enabled;

  let DEFAULT_LOGO_PATH = '/img/logo.svg';
  let DEFAULT_LOGO_SMALL_PATH = '/img/logo-sm.svg';

  if (config.theme !== DEFAULT) {
    DEFAULT_LOGO_PATH = `/img/${config.theme}/logo.svg`;
    DEFAULT_LOGO_SMALL_PATH = `/img/${config.theme}/logo-sm.svg`;
  }

  return {
    showPoweredBy: showCustomLogo,
    logoPath: showCustomLogo ? config.customLogo.path : DEFAULT_LOGO_PATH,
    logoSmallPath: showCustomLogo ? config.customLogo.pathSmall : DEFAULT_LOGO_SMALL_PATH,
  };
}

export default connect(mapStateToProps)(Logo);
