import React from 'react';

interface FileListProps {
  files: File[];
  onRemoveFile: (index: number) => void;
}

export const FileListDropBox: React.FC<FileListProps> = ({ files, onRemoveFile }) => (
  <div className='file-list'>
    <div className='file-list__container'>
      {files.map((file, index) => (
        <div className='file-item' key={`${file.name}-${index}`}>
          <div className='file-info'>
            <p>{file.name}</p>
          </div>
          <div className='file-actions'>
            <button
              onClick={() => onRemoveFile(index)}
              type='button'
              className='icon-close2'
              aria-label='remove'
            />
          </div>
        </div>
      ))}
    </div>
  </div>
);
