import React from 'react';
import PropTypes from 'prop-types';

const StatusIcon = ({ isAvailable }) => {
  const icon = (isAvailable === true) ? 'success' : 'alert';

  return (
    <td className="td-icon">
      {/* TODO Add real data */}
      <span className={`online ${icon}`} style={{ display: 'none' }} />
    </td>
  );
};

StatusIcon.propTypes = {
  isAvailable: PropTypes.bool.isRequired,
};

export default StatusIcon;
