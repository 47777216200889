import { connect } from 'react-redux';

import Actions from './Actions';
import { openModal } from '../../../../../redux/modules/modals.module';
import {
  CP_CALENDAR_REMOVE,
  CP_CALENDAR_CLONE,
  CP_CALENDAR_EDIT,
} from '../../../../../redux/constants/modals.constants';
import PermissionedComponent from '../../../permissions/PermissionedComponent.container';
import { CALENDAR_PERMISSIONS } from '../../../../../../../common/constants/permissions';

const mapStateToProps = (state, { permissions }) => ({
  canDelete: permissions.canDeleteCalendar(),
  canClone: permissions.canCloneCalendar(),
  canEdit: permissions.canEditCalendar(),
});

export const mapDispatchToProps = (dispatch) => ({
  openRemoveModal: (payload) => dispatch(openModal(CP_CALENDAR_REMOVE, payload)),
  openCloneModal: (payload) => dispatch(openModal(CP_CALENDAR_CLONE, payload)),
  openEditModal: (payload) => dispatch(openModal(CP_CALENDAR_EDIT, payload)),
});

export default PermissionedComponent(CALENDAR_PERMISSIONS)(
  connect(mapStateToProps, mapDispatchToProps)(Actions),
);
