import React from 'react';
import PropTypes from 'prop-types';

import TailEvent from './TailEvent';

const onClick = (e) => e.stopPropagation();

const TailEvents = ({ events }) => (
  <div className="position--bottom" onClick={onClick}>
    <div className="comments--list">
      { events.map((event) => <TailEvent key={event.id} event={event} />) }
    </div>
  </div>
);

TailEvents.propTypes = {
  events: PropTypes.array.isRequired,
};

export default TailEvents;
