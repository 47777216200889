import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { push } from 'connected-react-router';

import * as api from '../../core/api';
import { replaceObjectInList, compareById } from '../../../../common/helpers/object';
import { getFormFields } from '../selectors/admin.account.form.selector';
import * as form from '../constants/admin.account.form.constants';
import { getAdminAccountsLink } from '../../components/index/routes';
import { getAccount } from '../selectors/admin.accounts.selector';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const adminUpdateAccount = createAction(
  'admin: update account',
);

export const adminUpdateAccountSuccess = createAction(
  'admin: update account - success',
  (account) => account,
);

export const adminUpdateAccountFail = createAction(
  'admin: update account - fail',
  (error) => error,
);

export const reducer = {
  [adminUpdateAccount]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      accounts: {
        ...state.admin.accounts,
        selected: {
          ...state.admin.accounts.selected,
          item: {
            ...state.admin.accounts.selected.item,
            _update: {
              loaded: false,
              loading: true,
              error: null,
            },
          },
        },
      },
    },
  }),

  [adminUpdateAccountSuccess]: (state, account) => ({
    ...state,
    admin: {
      ...state.admin,
      accounts: {
        ...state.admin.accounts,
        list: replaceObjectInList(
          state.admin.accounts.list,
          account,
          compareById,
        ),
      },
    },
  }),

  [adminUpdateAccountFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      accounts: {
        ...state.admin.accounts,
        selected: {
          ...state.admin.accounts.selected,
          item: {
            ...state.admin.accounts.selected.item,
            _update: {
              loaded: false,
              loading: false,
              error,
            },
          },
        },
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.ADMIN_ACCOUNT_FORM_NAME,
    ...form.ACCOUNT_PROPS,
  ));
}

export function* adminUpdateAccountSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const account = yield call(getAccount, state);
  const body = yield call(getFormFields, state);
  yield put(startSubmit(form.ADMIN_ACCOUNT_FORM_NAME));

  const { response, error } = yield call(api.admin.updateAccount, account.id, body);

  if (response) {
    yield put(adminUpdateAccountSuccess(response));
    yield put(stopSubmit(form.ADMIN_ACCOUNT_FORM_NAME));
    yield put(push(getAdminAccountsLink()));
    yield put(sendNotification({ ...notificationMessages.account_updated, values: { name: response.name } }));
  } else {
    yield put(adminUpdateAccountFail(error));
    yield put(stopSubmit(form.ADMIN_ACCOUNT_FORM_NAME, error));
  }
}

export function* watchAdminUpdateAccount() {
  yield takeEvery(adminUpdateAccount.getType(), adminUpdateAccountSaga);
}
