import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormType } from '../../../core/forms/general';
import messages from '../../../messages/datatron.messages';

interface Props {
  backLink: string;
  formType: FormType;
}

const useTitle = (formType: FormType) => {
  const { formatMessage } = useIntl();
  switch (formType) {
    case 'add':
      return formatMessage(messages.addTitle);
    case 'edit':
      return formatMessage(messages.editTitle);
  }
};

export const DatatronFormPageHeader: React.FC<Props> = ({
  backLink,
  formType,
}) => {
  const title = useTitle(formType);
  return (
    <div className="subheader subheader--with--center">
      <div className="subheader-in">
        <div className="subheader--left">
          <ul className="menu tab-menu">
            <li className="back-link">
              <Link to={backLink}>
                <span className="icon-arrow-left" />
                <FormattedMessage id="common.back" defaultMessage="Back" />
              </Link>
            </li>
            <li>
              <h1 className="h1">{title}</h1>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
