import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function TopBarLogoutButton({ logoutLink }) {
  return (
    <a href={logoutLink} className="topMenu--link">
      <span className="icon icon-logout" />
      <FormattedMessage
        id="topBar.profile.logout"
        defaultMessage="Logout"
      />
    </a>
  );
}

TopBarLogoutButton.propTypes = {
  logoutLink: PropTypes.string.isRequired,
};
