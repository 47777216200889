const { SORT_WAY_ASC, SORT_WAY_DESC } = require('../constants/index');

function isAlphanumeric(input) {
  const regexp = /^[a-zA-Z0-9-_]+$/;
  return input.search(regexp) !== -1;
}

function isDomain(input) {
  return /(([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}/.test(input);
}

function isIpv4(input) {
  return /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    input,
  ); // eslint-disable-line max-len
}

function isGatewayIpValid(ip) {
  return /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(ip);
}

function isNetMaskValid(netMask) {
  return /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/.test(
    netMask,
  );
}

function isReservedIp(input) {
  if (!input) return false;
  if (input.toLowerCase() === 'localhost') return true;
  /* eslint-disable no-useless-escape */
  const pattern =
    '(^0.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^10.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^127.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^100.6[4-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^100.7[0-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^100.8[0-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^100.9[0-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^100.1[0-1]{1}[0-1]{1}[0-9]{1}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^100.12[0-7]{1}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^169.254.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^172.1[6-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^172.2[0-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^172.3[0-1]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^192.0.0.[0-9]{1,3}$)|' +
    '(^192.0.2.[0-9]{1,3}$)|' +
    '(^192.88.99.[0-9]{1,3}$)|' +
    '(^198.1[8-9]{1}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^198.51.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^203.0.113.[0-9]{1,3}$)|' +
    '(^22[4-9]{1}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^23[0-9]{1}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^24[0-9]{1}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^25[0-5]{1}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|' +
    '(^192.168.[0-9]{1,3}.[0-9]{1,3}$)';
  /* eslint-enable no-useless-escape */
  return new RegExp(pattern).test(input);
}

function changeSomeStep(listSteps, newStateStep) {
  function replacement(beforeStep) {
    if (beforeStep.id === newStateStep.id) {
      return newStateStep;
    }
    return beforeStep;
  }
  return listSteps.map(replacement);
}

function isServerValid(server) {
  return (isDomain(server) || isIpv4(server)) && !isReservedIp(server);
}

const isValidDate = (date) => {
  const myDate = typeof date === 'string' ? new Date(date) : date;
  return !(myDate && myDate.toString() === 'Invalid Date');
};

const isValidSortOption = (value) => value === SORT_WAY_ASC || value === SORT_WAY_DESC;

const isValueMatchEnum = (value, enumArray = []) => enumArray.indexOf(value) >= 0;

const isBoolean = (value) => value !== undefined && typeof value === 'boolean';

const isNumber = (value) => value && typeof value === 'number';

const isPositiveNumber = (value) =>
  value && typeof value === 'number' && !Number.isNaN(value) && value >= 0;

const isInteger = (value) => value && Number.isInteger(value);

const isString = (value) => typeof value === 'string';

const isUuid = (value) =>
  /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}){1}/.test(value);

const isMissing = (value) => value === null || value === undefined;

const isGifImage = (file) => file && file.type === 'image/gif';

const isPngImage = (file) => file && file.type === 'image/png';

const isJpegImage = (file) => file && (file.type === 'image/jpeg' || file.type === 'image/pjpeg');

module.exports = {
  isAlphanumeric,
  isDomain,
  isIpv4,
  isReservedIp,
  changeSomeStep,
  isServerValid,
  isValidDate,
  isValidSortOption,
  isValueMatchEnum,
  isBoolean,
  isNumber,
  isInteger,
  isString,
  isUuid,
  isMissing,
  isJpegImage,
  isGifImage,
  isPngImage,
  isPositiveNumber,
  isGatewayIpValid,
  isNetMaskValid,
};
