import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LocationPickerModal from './LocationPickerModal';
import { getSelectedLocationId } from '../../../redux/selectors/locationPicker.selector';
import { isModalOpen } from '../../../redux/selectors/modals.selector';
import { LOCATION_PICKER } from '../../../redux/constants/modals.constants';
import { closeModal } from '../../../redux/modules/modals.module';
import { saveLocationPickerValueToPathButton } from '../../../redux/modules/pathButton.tempLocationPath.write.module';

export const mapStateToProps = (state) => ({
  selectedLocationId: getSelectedLocationId(state),
  isOpen: isModalOpen(state, LOCATION_PICKER),
});

export const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal(LOCATION_PICKER)),
  writeToPathButton: bindActionCreators(saveLocationPickerValueToPathButton, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationPickerModal);
