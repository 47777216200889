import { connect } from 'react-redux';

import Select from '../../../../_tools/formFields/select/CustomSelect.container';
import { getDeviceTypesByClass } from '../../../../../redux/selectors/datatron.selector';

export const mapStateToProps = (state, { deviceClass }) => {
  const deviceTypes = getDeviceTypesByClass(state, deviceClass);

  return {
    options: deviceTypes.map((item) => ({
      label: item.name,
      value: item.id,
    })),
  };
};

export default connect(mapStateToProps)(Select);
