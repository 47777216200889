import { connect } from 'react-redux';

import EventDetails from './EventDetails';
import * as selector from '../../../../redux/selectors/anomaly.selector';

export function mapStateToProps(state, { event }) {
  return {
    isAnomalyLoading: selector.isLoading(state, event.id),
    isAnomalyLoaded: selector.isLoaded(state, event.id),
    anomaly: selector.getAnomaly(state, event.id),
  };
}

export default connect(mapStateToProps)(EventDetails);
