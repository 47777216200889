import { Todo } from '../../../../common/types/common';

export const getLocation = (state) => state.location;

export const selectLocationFilter = (state) => state.location.filter;
export const selectLocationFilterChildren = (state) => state.location.filterChildren;

export const isLoading = (state) => !!state.location._status.loading;
export const isLoaded = (state) => !!state.location._status.loaded;

export const getMembers = (state) => {
  if (!state.location.organizations) {
    return [];
  }
  const uniqueMemberIds: Todo[] = [];

  return state.location.organizations.reduce((final, org) => {
    const newMembers = org.members.reduce((allNewMembers, member) => {
      if (uniqueMemberIds.indexOf(member.id) === -1) {
        uniqueMemberIds.push(member.id);

        return [
          ...allNewMembers,
          member,
        ];
      }
      return allNewMembers;
    }, []);

    return [
      ...final,
      ...newMembers,
    ];
  }, []);
};
