import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../_tools/button/Button';

export const ReportAddButton = ({ openModal }) => (
  <Button className="button green" onClick={openModal}>
    <span className="fa fa-plus" />
    <FormattedMessage
      defaultMessage="Add Report"
      id="reports.add_report"
    />
  </Button>
);

ReportAddButton.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default ReportAddButton;
