import moment from 'moment';
import { findIndex } from 'lodash';

import * as calendarEntryAction from '../../redux/constants/calendars.entry.constants';

export const overwriteEventPayload = (payload) => {
  const newPayload = { ...payload };

  if (payload.actionType === calendarEntryAction.CURRENT_AND_FUTURE) {
    const payloadDateMoment = moment(payload.date);

    const firstDayMoment = moment(payload.event.startDateTime)
      .startOf('day');
    const secondDayMoment = moment(payload.event.startDateTime)
      .add(1, 'day')
      .startOf('day');

    if (payloadDateMoment.isSame(firstDayMoment, 'day')) {
      newPayload.actionType = calendarEntryAction.ALL;
    } else if (payloadDateMoment.isSame(secondDayMoment, 'day')) {
      newPayload.actionType = calendarEntryAction.UNSET_REPEAT;
    }
  }

  return newPayload;
};

export const getChangesWithOriginalDay = (event, changes = {}) => {
  let startDateTime = moment(event.startDateTime);
  let endDateTime = moment(event.endDateTime);

  if (changes.startDateTime && changes.endDateTime) {
    startDateTime = moment(changes.startDateTime)
      .year(startDateTime.year())
      .month(startDateTime.month())
      .date(startDateTime.date());

    endDateTime = moment(changes.endDateTime)
      .year(endDateTime.year())
      .month(endDateTime.month())
      .date(endDateTime.date());
  }

  return {
    ...event,
    ...changes,
    startDateTime: startDateTime.toISOString(),
    endDateTime: endDateTime.toISOString(),
  };
};

export const checkCorrespondenceOfDates = (exceptionsResponse, event) => (
  findIndex(exceptionsResponse, (exception) => (
    moment(exception.startDate).isSame(event._originalEvent.startDateTime, 'day')
  )) !== -1
);
