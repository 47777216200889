import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { getExperimentsFilters } from '../selectors/experimentsPage.selector';

export const fetchExperiments = createAction(
  'fetch experiments',
  (page = 1) => page,
);

export const fetchExperimentsSuccess = createAction(
  'fetch experiments - success',
  (list, pagination) => ({ list, pagination }),
);

export const fetchExperimentsFail = createAction(
  'fetch experiments - fail',
  (error) => error,
);

export const reducer = {
  [fetchExperiments]: (state) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      experiments: {
        ...state.experimentsPage.experiments,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchExperimentsSuccess]: (state, { list, pagination }) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      experiments: {
        ...state.experimentsPage.experiments,
        pagination,
        list,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [fetchExperimentsFail]: (state, error) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      experiments: {
        ...state.experimentsPage.experiments,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchExperimentsSaga({ payload: page }) {
  const state = yield select();
  const filters = yield call(getExperimentsFilters, state);

  const { response, error } = yield call(api.experiments.getExperiments, { page, ...filters });

  if (response) {
    yield put(fetchExperimentsSuccess(response.list, response.pagination));
  } else {
    yield put(fetchExperimentsFail(error));
  }
}

export function* watchFetchExperiments() {
  yield takeEvery(fetchExperiments.getType(), fetchExperimentsSaga);
}
