import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { CallResult } from '../util';

export const fetchAllMachines = createAction(
  'fetch all machines',
);

export const fetchAllMachinesSuccess = createAction(
  'fetch all machines - success',
  (list) => list,
);

export const fetchAllMachinesFail = createAction(
  'fetch all machines - fail',
  (error) => error,
);

export const reducer = {
  [fetchAllMachines.getType()]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      allMachines: {
        ...state.machine.allMachines,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchAllMachinesSuccess.getType()]: (state, list) => ({
    ...state,
    machine: {
      ...state.machine,
      allMachines: {
        ...state.machine.allMachines,
        list,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [fetchAllMachinesFail.getType()]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      allMachines: {
        ...state.machine.allMachines,
        list: [],
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchAllMachinesSaga() {
  const response: CallResult<typeof api.machines.getAll> = yield call(api.machines.getAll);

  if (response.type === 'generalError') {
    yield put(fetchAllMachinesFail(response.error));
    return;
  }

  if (response.type === 'parseError') {
    yield put(fetchAllMachinesFail(response.error.message));
    return;
  }

  const { data } = response;
  if ('status' in data) {
    yield put(fetchAllMachinesFail(data.message));
    return;
  }

  yield put(fetchAllMachinesSuccess(data));
}

export function* watchFetchAllMachines() {
  yield takeEvery(fetchAllMachines.getType(), fetchAllMachinesSaga);
}
