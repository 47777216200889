import { createAction } from 'redux-act';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';

export const filterByDate = createAction(
  'filter dashboard events by date',
  (newDates) => newDates,
);

export const resetDates = createAction(
  'reset filters dashboard events by date',
);

export const reducer = {
  [filterByDate]: (state, newDates) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      dateFilters: {
        ...state.dashboard.dateFilters,
        ...newDates,
      },
    },
  }),

  [resetDates]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      dateFilters: {
        [DATE_FROM]: null,
        [DATE_TO]: null,
      },
    },
  }),
};
