import React, { useState } from 'react';
import classNames from 'classnames';

import Label from './Label';
import CategoryError from './filters/CategoryErrorFilter.container';
import CategoryWarning from './filters/CategoryWarningFilter.container';
import CategoryFatal from './filters/CategoryFatalFilter.container';
import CategoryInfo from './filters/CategoryInfoFilter.container';
import CategoryAnomaly from './filters/CategoryAnomalyFilter.container';
import SubscriptionCategoryAnomaly from './filters/SubscriptionCategoryAnomalyFilter.container';
import SubscriptionCategoryError from './filters/SubscriptionCategoryErrorFilter.container';
import SubscriptionCategoryFatal from './filters/SubscriptionCategoryFatalFilter.container';
import SubscriptionCategoryInfo from './filters/SubscriptionCategoryInfoFilter.container';
import SubscriptionCategoryWarning from './filters/SubscriptionCategoryWarningFilter.container';
import { TodoFunction } from '../../../../../common/types/common';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';

interface Props {
  filters: string[]
  addFilter: TodoFunction,
  removeFilter: TodoFunction,
  isMaintenance?: boolean,
  isMachineSubscription?: boolean,
}

const getFilterComponents = (isMaintenance, isMachineSubscription) => {
  if (isMaintenance) return [
    CategoryFatal,
    CategoryAnomaly,
  ];
  else if (isMachineSubscription) return [
    SubscriptionCategoryAnomaly,
    SubscriptionCategoryError,
    SubscriptionCategoryFatal,
    SubscriptionCategoryInfo,
    SubscriptionCategoryWarning,
  ];
  else return [
    CategoryFatal,
    CategoryWarning,
    CategoryError,
    CategoryInfo,
    CategoryAnomaly,
  ];
};

export const CategoryFilters: React.FC<Props> = ({
  filters,
  addFilter,
  removeFilter,
  isMaintenance,
  isMachineSubscription,
}) => {
  const [isOpen, isOpenSet] = useState(false);

  const filterComponents = getFilterComponents(isMaintenance, isMachineSubscription);

  const close = () => {
    isOpenSet(false);
  };

  const handleFilterChange = (filter, selected) => {

    if (!selected) {
      addFilter(filter);
    } else {
      removeFilter(filter);
    }
    close();
  };

  const toggle = () => {
    isOpenSet(!isOpen);
  };

  const styles = {
    main: classNames(
      'filter--block',
      { opened: isOpen },
    ),
    button: classNames(
      'filter--btn',
      { 'no-filter': filters.length === 0 },
    ),
  };

  const clickRef = useClickOutside(close);

  return (
    <div ref={clickRef}>
      <div className={styles.main}>
        <button
          className={styles.button}
          type="button"
          onClick={toggle}
        >
          <span className="icon icon-filter"/>
          <Label count={filters.length}/>
        </button>
        <div className="filter--dropdown">
          <ul>
            {filterComponents.map((Filter, index) => (
              <li key={index}>
                <Filter
                  onChange={handleFilterChange}
                  filters={filters}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CategoryFilters;
