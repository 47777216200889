import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ExperimentModalContainer from './ExperimentModal.container';
import { getValuesFromExperiment } from '../../../../core/common/experiment.form';
import { updateExperiment } from '../../../../redux/modules/experiments.update.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { EXPERIMENT_EDIT } from '../../../../redux/constants/modals.constants';

export const mapStateToProps = (state) => {
  const { experiment } = getModalPayload(state, EXPERIMENT_EDIT);

  return {
    isEditForm: true,
    useTabs: true,
    experimentId: experiment.id,
    initialValues: getValuesFromExperiment(experiment),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  _update: bindActionCreators(updateExperiment, dispatch),
  close: () => dispatch(closeModal(EXPERIMENT_EDIT)),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  handleSubmit: (e) => {
    e.preventDefault();
    dispatchProps._update(stateProps.experimentId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ExperimentModalContainer);
