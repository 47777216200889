import * as userFormTabs from '../../core/common/admin.user.form.tabs';

export const adminInitialState = {
  accounts: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    selected: {
      item: null,
      loaded: false,
      loading: false,
      error: null,
    },
  },
  organizations: {
    pagination: null,
    list: [],
    loaded: false,
    loading: false,
    error: null,
    selected: {
      item: null,
      loaded: false,
      loading: false,
      error: null,
    },
    all: {
      list: [],
      loaded: false,
      loading: false,
      error: null,
    },
    filters: {
      q: '',
      sort: {
        option: null,
        way: null,
      },
    },
  },
  locations: {
    pagination: null,
    list: [],
    loaded: false,
    loading: false,
    error: null,
    selected: {
      item: null,
      loaded: false,
      loading: false,
      error: null,
    },
    newLocation: {
      loaded: false,
      loading: false,
      error: null,
    },
    filters: {
      q: '',
      sort: {
        option: null,
        way: null,
      },
    },
  },
  users: {
    pagination: null,
    list: [],
    loaded: false,
    loading: false,
    error: null,
    selected: {
      item: null,
      loaded: false,
      loading: false,
      error: null,
      tab: userFormTabs.BASIC_TAB,
    },
    filters: {
      name: '',
      email: '',
      sort: {
        option: null,
        way: null,
      },
    },
  },
  roles: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    selected: {
      item: null,
      loaded: false,
      loading: false,
      error: null,
    },
  },
};

export const adminTestState = {
  accounts: {
    list: [
      {
        id: '100',
        name: 'Test Account',
        description: 'Fake account',
        selected: true,
      },
      {
        id: '200',
        name: 'Test Account 2',
        description: 'Fake account 2',
      },
    ],
    loaded: true,
    loading: false,
    error: null,
    selected: {
      item: {
        id: '200',
        name: 'Test Account 2',
        description: 'Fake account 2',
        _update: {
          loading: false,
          loaded: false,
          error: null,
        },
        _remove: {
          loading: false,
          loaded: false,
          error: null,
        },
      },
      loaded: true,
      loading: false,
      error: null,
    },
  },
  organizations: {
    pagination: {
      total: 2,
      current_page: 1,
      per_page: 10,
      total_pages: 1,
    },
    list: [
      {
        id: '200',
        name: 'Test Organization',
      },
      {
        id: '300',
        name: 'Test Organization 2',
      },
    ],
    loaded: true,
    loading: false,
    error: null,
    selected: {
      item: {
        id: '200',
        name: 'Test Organization',
      },
      loaded: true,
      loading: false,
      error: null,
    },
    filters: {
      q: 'Germ',
      sort: {
        option: 'name',
        way: 'asc',
      },
    },
  },
  locations: {
    pagination: {
      total: 1,
      current_page: 1,
      per_page: 50,
      total_pages: 1,
    },
    list: [
      {
        id: '400',
        name: 'Test Location',
        type: 'country',
      },
    ],
    loaded: true,
    loading: false,
    error: null,
    selected: {
      item: {
        id: '400',
        name: 'Test Location',
        type: 'country',
        parent: {
          id: '399',
          name: 'Parent for Test Location',
          type: 'country',
        },
      },
      loaded: true,
      loading: false,
      error: null,
    },
    newLocation: {
      loaded: true,
      loading: false,
      error: null,
    },
    filters: {
      isQueryInputOpen: false,
      q: 'Germ',
      sort: {
        option: 'name',
        way: 'asc',
      },
    },
  },
  users: {
    pagination: {
      total: 1,
      current_page: 1,
      per_page: 10,
      total_pages: 1,
    },
    list: [
      {
        id: '500',
        name: 'FirstName MiddleName LastName',
        firstName: 'FirstName',
        middleName: 'MiddleName',
        lastName: 'LastName',
        position: 'Position',
        email: 'email@email.email',
        phoneNumber: '123456789',
        locale: 'en',
        isWorking: true,
      },
    ],
    loaded: true,
    loading: false,
    error: null,
    selected: {
      item: {
        id: '500',
        name: 'FirstName MiddleName LastName',
        firstName: 'FirstName',
        middleName: 'MiddleName',
        lastName: 'LastName',
        position: 'Position',
        email: 'email@email.email',
        phoneNumber: '123456789',
        locale: 'en',
        isWorking: true,
      },
      loaded: true,
      loading: false,
      error: null,
      tab: userFormTabs.BASIC_TAB,
    },
    filters: {
      name: 'name_query',
      email: 'email_query',
      sort: {
        option: 'user_sort_by_name',
        way: 'desc',
      },
    },
  },
  roles: {
    list: [
      'TestRole',
      'TestRole2',
    ],
    loaded: true,
    loading: false,
    error: null,
    selected: {
      item: null,
      loaded: false,
      loading: false,
      error: null,
    },
  },
};
