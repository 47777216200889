import React from 'react';

import ChartContainer from '../../../../_tools/chartV2/ChartContainer';
import { selectSelectionByReportId } from '../../../../../redux/selectors/reports.selector';
import { ReportT } from '../../../../../../types/report';
import { useParamSelector } from '../../../../../redux/util';

interface Props {
  report: ReportT;
}

export const ChartV2Container: React.FC<Props> = ({
  report,
}) => {
  const selection = useParamSelector(selectSelectionByReportId, report.id);
  return (
    <ChartContainer
      showAxisBottom={false}
      width={NaN}
      height={NaN}
      selection={selection}
      report={report}
    />
  );
};
