import React from 'react';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

export const List = ({
  list, onEntryClick, stringToHighlight, type,
}) => (
  <div className="search-alls-list-wrap">
    <ul className="search-alls-list">
      { list.map((item) => (
        <ListItem
          key={item.id}
          item={item}
          type={type}
          onClick={onEntryClick}
          stringToHighlight={stringToHighlight}
        />
      )) }
    </ul>
  </div>
);

List.propTypes = {
  list: PropTypes.array.isRequired,
  onEntryClick: PropTypes.func.isRequired,
  stringToHighlight: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default List;
