import { defineMessages } from 'react-intl';

import * as experimentStatuses from '../../../common/enums/experimentStatuses';

export default defineMessages({
  [experimentStatuses.PLANNED]: {
    id: 'experimentsPage.experiments.status.planned',
    defaultMessage: 'Planned',
  },
  [experimentStatuses.IN_PROGRESS]: {
    id: 'experimentsPage.experiments.status.in_progress',
    defaultMessage: 'In Progress',
  },
  [experimentStatuses.COMPLETED]: {
    id: 'experimentsPage.experiments.status.completed',
    defaultMessage: 'Completed',
  },
  [experimentStatuses.FAILED]: {
    id: 'experimentsPage.experiments.status.failed',
    defaultMessage: 'Failed',
  },
  [experimentStatuses.ARCHIVED]: {
    id: 'experimentsPage.experiments.status.archived',
    defaultMessage: 'Archived',
  },
});
