import {
  EXPERIMENTS_MANAGER,
  EXPERIMENTS_VIEWER,
  Role,
} from '../constants/permissions';
import { Todo } from '../types/common';

export default class ExperimentsPermissions {
  private isExperimentsManager: boolean;

  private isExperimentsViewer: boolean;

  constructor(roles: Role[], config: Todo) {
    if (!config?.features.experiments) {
      this.isExperimentsManager = false;
      this.isExperimentsViewer = false;
    } else {
      this.isExperimentsManager = roles.indexOf(EXPERIMENTS_MANAGER) !== -1;
      this.isExperimentsViewer = roles.indexOf(EXPERIMENTS_VIEWER) !== -1;
    }
  }

  canAddExperiment() {
    return this.isExperimentsManager;
  }

  canEditExperiment() {
    return this.isExperimentsManager;
  }

  canArchiveExperiment() {
    return this.isExperimentsManager;
  }

  canAddMeasurement() {
    return this.isExperimentsManager;
  }

  canEditMeasurement() {
    return this.isExperimentsManager;
  }

  canArchiveMeasurement() {
    return this.isExperimentsManager;
  }

  canMergeMeasurement() {
    return this.isExperimentsManager;
  }

  canViewExperiments() {
    return this.isExperimentsManager || this.isExperimentsViewer;
  }

  canViewMeasurements() {
    return this.isExperimentsManager || this.isExperimentsViewer;
  }
}
