import React from 'react';
import PropTypes from 'prop-types';

import { getAdminRoleLink, getAdminEditRoleLink } from '../../../index/routes';

export const RoleRow = ({ role, pushPath }) => {
  const handleViewClick = () => pushPath(getAdminRoleLink(role.role));
  const handleEditClick = () => pushPath(getAdminEditRoleLink(role.role));

  const showButtons = role.users > 0;

  return (
    <tr>
      <td>
        <strong>{ role.role }</strong>
      </td>
      <td>
        <strong>{ role.users }</strong>
      </td>
      <td className="td-actions">
        { showButtons && (
          <>
            <button className="button light-grey-white" onClick={handleViewClick}>
              <i className="icon-eye" />
            </button>
            <button className="button light-grey-white" onClick={handleEditClick}>
              <i className="icon-edit" />
            </button>
          </>
        ) }
      </td>
    </tr>
  );
};

RoleRow.propTypes = {
  role: PropTypes.object.isRequired,
};

export default RoleRow;
