import { connect } from 'react-redux';

import TableQuerySearchState from '../../../../../../common/tableQuerySearch/TableQuerySearchState';
import { getLabelFilterInputValue } from '../../../../../../../redux/selectors/configRepoExplorer.selector';
import { filterByLabel } from '../../../../../../../redux/modules/configRepoExplorer.sort.filter.module';

export const mapStateToProps = (state) => ({
  value: getLabelFilterInputValue(state),
});

export const mapDispatchToProps = {
  onQueryChange: filterByLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableQuerySearchState);
