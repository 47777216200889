import { createAction } from 'redux-act';
import { delay, put, takeLatest } from 'redux-saga/effects';

import { fetchExperiments } from './experiments.fetch.module';

export const DEBOUNCE_TIME_IN_MILLISECONDS = 500;

export const addStatusFilter = createAction(
  'add status filter for experiments',
  (filter) => filter,
);

export const removeStatusFilter = createAction(
  'remove status filter for experiments',
  (filter) => filter,
);

export const filterByText = createAction(
  'filter experiments by query',
  (query = '') => query,
);

export const reducer = {
  [addStatusFilter]: (state, filter) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      experiments: {
        ...state.experimentsPage.experiments,
        filters: {
          ...state.experimentsPage.experiments.filters,
          status: [
            ...state.experimentsPage.experiments.filters.status,
            filter,
          ],
        },
      },
    },
  }),
  [removeStatusFilter]: (state, filter) => {
    const selected = state.experimentsPage.experiments.filters.status;
    const index = selected.indexOf(filter);

    if (index === -1) return state;

    return {
      ...state,
      experimentsPage: {
        ...state.experimentsPage,
        experiments: {
          ...state.experimentsPage.experiments,
          filters: {
            ...state.experimentsPage.experiments.filters,
            status: [
              ...selected.slice(0, index),
              ...selected.slice(index + 1),
            ],
          },
        },
      },
    };
  },
  [filterByText]: (state, query) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      experiments: {
        ...state.experimentsPage.experiments,
        filters: {
          ...state.experimentsPage.experiments.filters,
          query,
        },
      },
    },
  }),
};

export function* fetchExperimentsSaga() {
  yield delay(DEBOUNCE_TIME_IN_MILLISECONDS);
  yield put(fetchExperiments());
}

export function* watchExperimentsFiltersChange() {
  yield takeLatest([
    addStatusFilter.getType(),
    removeStatusFilter.getType(),
    filterByText.getType(),
  ], fetchExperimentsSaga);
}
