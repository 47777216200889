import React, { useState } from 'react';
import classnames from 'classnames';

interface Props {
  dimension: number[];
  update: (indices: number[], newValue: any) => void;
}

/**
 * Display a single dimension of an array. This is a single set of two input boxes between the brackets of an
 * `ARRAY [] OF` statement. It also handles deleting itself.
 */
export const DimensionsDisplay: React.FC<Props> = ({ dimension, update }) => {
  // mark text as red if there is an error
  const [error, errorSet] = useState(dimension && dimension[0] > dimension[1]);

  const updateThis = (index: number, newValue) => {
    update([index], newValue);
  };

  /**
   * Causes the dimension to be deleted, by setting it to undefined.
   */
  const removeDimension = (e) => {
    e.preventDefault();
    update([], undefined);
  };

  /**
   * Handle updates and error checking.
   */
  const onChange = (index) => (e) => {
    e.preventDefault();
    // check if the value is a number
    const newValue = parseInt(e.target.value, 10);
    const valueUpdate = isNaN(newValue) ? '' : newValue;
    // check if the left dimension limit is less than the right dimension limit
    const newValues = index === 0 ? [valueUpdate, dimension[1]] : [dimension[0], valueUpdate];
    if (isNaN(newValue) || newValues[0] > newValues[1]) {
      errorSet(true);
    } else {
      errorSet(false);
    }

    // update the state
    updateThis(index, valueUpdate);
  };

  const outerDivClasses = classnames('dimensionsVerticalCenterFlex', 'dimensionInner', { 'dimensionInnerError': error });
  const inputClasses = classnames('input dimensionsTextInput', { 'dimensionInnerError': error });

  /**
   * Define input boxes for each dimension.
   */
  const getInput = (index) => (
    <input type="number" onChange={onChange(index)} className={inputClasses} value={`${dimension[index]}`}
           style={{ width: `${dimension[index].toString().length * 15}px` }}/>);

  if (!dimension || dimension.length !== 2) return <></>;
  return (
    <div className={outerDivClasses}>
      {getInput(0)}{<span style={{ paddingLeft: '2px', paddingRight: '2px', color: 'black' }}>..</span>}{getInput(1)}
      <span className='dimensionButtons dimensionInnerRemove' onClick={removeDimension}/>
    </div>
  );
};