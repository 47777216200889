import React from 'react';

import { FormattedMessage } from 'react-intl';

import TagsCloudHeader from './TagsCloudHeader';

function TagsCloud() {
  return (
    <div className="col--1">
      <TagsCloudHeader />

      <div className="col--2--wrap">
        <div className="col--2 col--2--central scrolled">
          <div className="col--2--left">
            <div className="col--in">

              <div className="col--title">
                <FormattedMessage
                  id="teams.tag"
                  defaultMessage="Tag"
                />
              </div>

              <div className="scroll-block">
                <div className="text-center">tag here</div>
              </div>

            </div>
          </div>
          <div className="col--2--right">
            <div className="col--height">
              <div className="col--in height50">

                <div className="col--title">
                  <FormattedMessage
                    id="teams.location"
                    defaultMessage="Location"
                  />
                </div>

                <div className="scroll-block">
                  <table className="table">
                    <thead>
                      <tr>
                        <td />
                        <td>
                          <FormattedMessage
                            id="teams.city"
                            defaultMessage="City"
                          />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="td-icon">
                          <span className="icon icon-location" />
                        </td>
                        <td>
                          <a className="link-text">Westershade</a>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-icon">
                          <span className="icon icon-location" />
                        </td>
                        <td>
                          <a className="link-text">Westershade</a>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-icon">
                          <span className="icon icon-location" />
                        </td>
                        <td>
                          <a className="link-text">Westershade</a>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-icon">
                          <span className="icon icon-location" />
                        </td>
                        <td>
                          <a className="link-text">Westershade</a>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-icon">
                          <span className="icon icon-location" />
                        </td>
                        <td>
                          <a className="link-text">Westershade</a>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-icon">
                          <span className="icon icon-location" />
                        </td>
                        <td>
                          <a className="link-text">Westershade</a>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-icon">
                          <span className="icon icon-location" />
                        </td>
                        <td>
                          <a className="link-text">Westershade</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col--in height50">

                <div className="col--title">
                  <FormattedMessage
                    id="teams.team"
                    defaultMessage="Team"
                  />
                </div>

                <div className="scroll-block">

                  <div className="table--title">
                    <i className="icon icon-person" />
                    Team Swift
                  </div>

                  <table className="table">
                    <thead>
                      <tr>
                        <td />
                        <td>
                          <FormattedMessage
                            id="teams.name"
                            defaultMessage="Name"
                          />
                        </td>
                        <td>
                          <FormattedMessage
                            id="teams.position"
                            defaultMessage="Position"
                          />
                        </td>
                        <td>
                          <FormattedMessage
                            id="teams.email"
                            defaultMessage="Email"
                          />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="td-icon">
                          <span className="online success" />
                        </td>
                        <td>
                          <a className="link-text">Jay Harris</a>
                        </td>
                        <td>CEO</td>
                        <td>abc@sadasd.hotmail.com</td>
                      </tr>
                      <tr>
                        <td className="td-icon">
                          <span className="online success" />
                        </td>
                        <td>
                          <a className="link-text">Jay Harris</a>
                        </td>
                        <td>CEO</td>
                        <td>abc@sadasd.hotmail.com</td>
                      </tr>
                      <tr className="offline">
                        <td className="td-icon">
                          <span className="online alert" />
                        </td>
                        <td>
                          <a className="link-text">Jay Harris</a>
                        </td>
                        <td>CEO</td>
                        <td>abc@sadasd.hotmail.com</td>
                      </tr>
                      <tr>
                        <td className="td-icon">
                          <span className="online success" />
                        </td>
                        <td>
                          <a className="link-text">Jay Harris</a>
                        </td>
                        <td>CEO</td>
                        <td>abc@sadasd.hotmail.com</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TagsCloud.propTypes = {
};

export default TagsCloud;
