import { connect } from 'react-redux';

import { getMachineLink, getInventoryLink } from '../index/routes';
import UpdateMachineFormPage from './UpdateMachineFormPage';
import * as selector from '../../redux/selectors/machine.selector';
import { getValuesFromMachine } from '../../core/common/machine.form';
import { search } from '../../redux/modules/search.module';
import { LOCATIONS, ORGANIZATIONS } from '../../../../common/constants/entities';
import PermissionedComponent from '../common/permissions/PermissionedComponent.container';
import { MACHINES_PERMISSIONS } from '../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => {
  const loaded = selector.loaded(state);
  const canUpdateMachines = permissions.canUpdateMachines();

  if (!loaded) {
    return {
      backLink: getInventoryLink(),
      isUpdateForm: true,
      loaded,
      canUpdateMachines,
    };
  }

  const machine = selector.machine(state);

  return {
    backLink: getMachineLink(machine.id),
    isUpdateForm: true,
    initialValues: getValuesFromMachine(machine),
    locationName: machine.location.name,
    organizationName: machine.organization.name,
    loaded,
    detailsFormProps: {
      photoUrl: machine.image,
      location: machine.location,
    },
    photoUrl: machine.image,
    canUpdateMachines,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  initializeSearchableFields: () => {
    dispatch(search('', true, [LOCATIONS]));
    dispatch(search('', true, [ORGANIZATIONS]));
  },
});

export default PermissionedComponent(MACHINES_PERMISSIONS)(
  connect(mapStateToProps, mapDispatchToProps)(UpdateMachineFormPage),
);
