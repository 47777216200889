import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function Filter({
  checked, handleChange, label, value,
}) {
  function handleFilterChange() {
    handleChange(value, checked);
  }

  return (
    <label className="checkbox">
      <input
        type="checkbox"
        onChange={handleFilterChange}
        checked={checked}
      />
      <FormattedMessage {...label} tagName="span" />
    </label>
  );
}

Filter.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
};
