import React, { useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { Filters } from '../../dashboard/filters/Filters';
import * as keyboardKeys from '../../../../../common/constants/keyboard';
import { getNavigationItemIndex } from '../../../core/common/filters';
import Counter from '../../common/counter/Counter';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';
import { Todo, TodoFunction } from '../../../../../common/types/common';

interface Props {
  addFilter: TodoFunction,
  removeFilter: TodoFunction,
  removeAllFiltersByType: TodoFunction,
  pendingFiltersReset?: TodoFunction,
  filtersConfirm?: TodoFunction,
  filterType: string,
  filters: Todo[],
  label: Record<any, any>,
  query?: string,
  results: Record<any, any>,
  selectedFilters: Record<any, any>,
  onQueryChange: TodoFunction,
  canApplyItemFilters: boolean,
}

export const ItemFilters: React.FC<Props> = ({
  addFilter,
  removeFilter,
  removeAllFiltersByType,
  pendingFiltersReset,
  filtersConfirm,
  filterType,
  filters,
  label,
  query = '',
  results,
  selectedFilters,
  onQueryChange,
  canApplyItemFilters,
}) => {
  const { formatMessage } = useIntl();

  const [isOpen, isOpenSet] = useState(false);
  const [navigationMode, navigationModeSet] = useState(false);
  const [navigationItemIndex, navigationItemIndexSet] = useState(0);

  const setNavigationItemIndex = (newIndex) => navigationItemIndexSet(newIndex);

  const open = () => {
    onQueryChange('');
    isOpenSet(true);
  };

  const close = () => isOpenSet(false);

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  const handleClickOutside = () => {
    if (isOpen) {
      close();
      if (pendingFiltersReset) pendingFiltersReset();
    }
  };

  const enableNavigation = () => {
    navigationModeSet(true);
    navigationItemIndexSet(0);
  };

  const disableNavigation = () => navigationModeSet(false);

  const handleKeyDown = (e) => {
    if (!isOpen) return;

    switch (e.keyCode) {
      case keyboardKeys.ESCAPE_KEY: {
        if (navigationMode) {
          disableNavigation();
        } else {
          close();
          if (canApplyItemFilters && pendingFiltersReset) {
            pendingFiltersReset();
          }
        }
        break;
      }
      case keyboardKeys.ENTER_KEY: {
        disableNavigation();
        if (canApplyItemFilters && filtersConfirm) {
          filtersConfirm();
          close();
        }
        break;
      }
      case keyboardKeys.ARROW_UP_KEY: {
        e.preventDefault();
        if (!navigationMode) {
          enableNavigation();
        } else {
          const nextNavigationIndex = getNavigationItemIndex({
            directionForward: false,
            list: results[filterType],
            currentItemIndex: navigationItemIndex,
          });
          setNavigationItemIndex(nextNavigationIndex);
        }
        break;
      }
      case keyboardKeys.ARROW_DOWN_KEY: {
        e.preventDefault();
        if (!navigationMode) {
          enableNavigation();
        } else {
          const nextNavigationIndex = getNavigationItemIndex({
            directionForward: true,
            list: results[filterType],
            currentItemIndex: navigationItemIndex,
          });
          setNavigationItemIndex(nextNavigationIndex);
        }
        break;
      }
      case keyboardKeys.SPACE_KEY: {
        if (navigationMode) {
          e.preventDefault();
          const item = results[filterType][navigationItemIndex];
          if (!item) return;
          addFilter({ type: filterType, item });
          disableNavigation();
        }
        break;
      }
      default:
        break;
    }
  };

  const handleQueryChange = (q) => {
    disableNavigation();
    onQueryChange(q);
  };

  const className = classNames(
    'filter--block',
    {
      opened: open,
      passive: filters.length === 0,
    },
    `filter-type--${filterType}`,
  );

  const translatedLabel = formatMessage(label);

  const navigationSelectedIndex = navigationMode ? navigationItemIndex : undefined;

  const clickRef = useClickOutside(handleClickOutside);

  return (
    <div
      className={className}
      onKeyDown={handleKeyDown}
      ref={clickRef}
    >
      <button
        className="filter--btn"
        type="button"
        onClick={toggle}
      >
        <span className="icon icon-filter"/>
        <span>{translatedLabel}</span>
        <Counter value={filters.length}/>
      </button>
      {isOpen && (
        <div className="filter--dropdown">
          <ul>
            <li>
              <Filters
                query={query}
                results={results}
                selectedFilters={selectedFilters}
                onQueryChange={handleQueryChange}
                onFilterAdd={addFilter}
                onFilterRemove={removeFilter}
                onFiltersRemoveByType={removeAllFiltersByType}
                categoriesIncludedForDisplay={[filterType]}
                canApplyItemFilters={canApplyItemFilters}
                close={close}
                navigationSelectedIndex={navigationSelectedIndex}
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ItemFilters;