import { cloneDeep, isEmpty } from 'lodash';

export const findFirstErrorField = (sortedProps, errors) => {
  if (!errors || isEmpty(errors)) return null;
  const errorsKeys = Object.keys(errors);
  const clonedProps = cloneDeep(sortedProps);

  while (clonedProps.length > 0) {
    const item = clonedProps.shift();
    if (errorsKeys.indexOf(item) >= 0) return item;
  }

  return null;
};
