import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { take } from 'lodash';

import FooterList from './list/FooterList';
import FooterPreTitle from './FooterPreTitle';
import commonMessages from '../../../../messages/common.messages';

export const PRE_DISPLAY_LIMIT = 2;

const isLimitExceeded = (machines) => machines.length > PRE_DISPLAY_LIMIT;

const getPreviewTitle = (machines, andTranslatedLabel) => {
  const machineNames = machines.map((machine) => machine.name);

  if (isLimitExceeded(machineNames)) {
    return `${take(machineNames, PRE_DISPLAY_LIMIT).join(', ')} ${andTranslatedLabel} `;
  }

  return machineNames.join(', ');
};

export class CalendarFooter extends Component {
  static propTypes = {
    machines: PropTypes.array.isRequired,

  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { isOpen } = this.state;
    const { machines, intl } = this.props;

    const arrowClassName = classnames(
      'icon',
      `icon-caret-${isOpen ? 'down' : 'left'}`,
    );
    const calendarBottomClassName = classnames(
      'calendar--bottom',
      `${isOpen ? 'opened' : ''}`,
    );
    const nonPreviewMachinesCount = machines.length - PRE_DISPLAY_LIMIT;
    const labels = {
      and: intl.formatMessage(commonMessages.and),
      more: intl.formatMessage(commonMessages.more),
    };
    const hasMachines = machines && machines.length > 0;

    return (
      <div>
        <div className={calendarBottomClassName}>
          <div>
            <FooterPreTitle hasMachines={hasMachines} />
            { getPreviewTitle(machines, labels.and) }
            { isLimitExceeded(machines) && (
              <a onClick={this.toggle}>
                { nonPreviewMachinesCount }
                {' '}
                { labels.more }
              </a>
            ) }
          </div>
          { hasMachines && (
            <button className="button__toggle" onClick={this.toggle}>
              <i className={arrowClassName} />
            </button>
          )}
        </div>
        { isOpen && (<FooterList machines={machines} />) }
      </div>
    );
  }
}

export default injectIntl(CalendarFooter);
