import { connect } from 'react-redux';

import BatchesPageHeader from './BatchesPageHeader';
import { dateFilters, areDateFiltersSelected } from '../../../redux/selectors/batch.selector';
import { filterByDate } from '../../../redux/modules/batch.sort.filter.module';

export function mapStateToProps(state) {
  return {
    dateFilters: dateFilters(state),
    dateFiltersSelected: areDateFiltersSelected(state),
  };
}

export const mapDispatchToProps = {
  filterByDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchesPageHeader);
