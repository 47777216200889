import { createAction } from '@reduxjs/toolkit';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { last } from 'lodash';

import * as api from '../../core/api';
import { getDatatron } from '../selectors/datatron.selector';
import { getDataPointMetric } from '../selectors/dataPoint.metric.selector';
import { Todo } from '../../../../common/types/common';

export const fetchDataPointMetric = createAction(
  'fetch data point metric',
  (deviceId, dataPointId) => ({ payload: { deviceId, dataPointId } }),
);

export const fetchDataPointMetricSuccess = createAction(
  'fetch data point metric - success',
  (dataPointId, list = []) => ({ payload: { dataPointId, list } }),
);

export const fetchDataPointMetricFail = createAction(
  'fetch data point metric - fail',
  (dataPointId, error) => ({ payload: { dataPointId, error } }),
);

export const reducer = {
  [fetchDataPointMetric.type]: (state, { dataPointId }) => ({
    ...state,
    datatron: {
      ...state.datatron,
      dataPointMetric: {
        ...state.datatron.dataPointMetric,
        loading: true,
        loaded: false,
        error: null,
        dataPointId,
      },
    },
  }),

  [fetchDataPointMetricSuccess.type]: (state, { dataPointId, list }) => ({
    ...state,
    datatron: {
      ...state.datatron,
      dataPointMetric: {
        ...state.datatron.dataPointMetric,
        list: [
          ...state.datatron.dataPointMetric.list,
          ...list,
        ],
        loading: false,
        loaded: true,
        error: null,
        moreAvailable: list.length > 0,
        dataPointId,
      },
    },
  }),

  [fetchDataPointMetricFail.type]: (state, { dataPointId, error }) => ({
    ...state,
    datatron: {
      ...state.datatron,
      dataPointMetric: {
        ...state.datatron.dataPointMetric,
        list: [],
        loading: false,
        loaded: false,
        error,
        moreAvailable: false,
        dataPointId,
      },
    },
  }),
};

export function* fetchDataPointMetricSaga({ payload: { deviceId, dataPointId } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const list = yield call(getDataPointMetric, state, dataPointId);

  const lastEntry: Todo = last(list); // TODO add type info
  const startTimestamp = lastEntry ? (lastEntry[0] + 1) : 1;

  const { response, error } = yield call(api.datatrons.getDataPointMetric, {
    datatronId: datatron.id, deviceId, dataPointId, startTimestamp,
  });

  if (response) {
    yield put(fetchDataPointMetricSuccess(dataPointId, response.metric));
  } else {
    yield put(fetchDataPointMetricFail(dataPointId, error));
  }
}

export function* watchFetchDataPointMetric() {
  yield takeEvery(fetchDataPointMetric, fetchDataPointMetricSaga);
}
