import { cloneDeep } from 'lodash';
import { createAction } from '@reduxjs/toolkit';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { initializeDatatron } from '../../core/common/datatron';
import { basic } from '../state/datatron.state';
import { searchUsersByOrganization } from './users.organization.search.module';
import { fetchDevices } from './datatron.devices.fetch.module';

export const fetchDatatron = createAction('fetch datatron', (id: string) => ({ payload: id }));

export const fetchDatatronSuccess = createAction('fetch datatron - success', (datatron) => ({
  payload: datatron,
}));

export const fetchDatatronFail = createAction('fetch datatron - fail', (error) => ({
  payload: error,
}));

export const resetDatatron = createAction('reset datatron');

export const reducer = {
  [fetchDatatron.type]: (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [fetchDatatronSuccess.type]: (state, datatron) => ({
    ...state,
    datatron: {
      ...state.datatron,
      /*
       * TODO: fix me, implement a different solution
       * In Redux, reducers should be pure functions that take the current state
       * and an action as input and return a new state based on the action type and payload.
       * Reducers should not have any side effects, such as making API calls,
       * like this case  on initializeDatatron ends up calling await getDatatronStats api
       */
      details: initializeDatatron(datatron),
      loaded: true,
      loading: false,
    },
  }),

  [fetchDatatronFail.type]: (state, error) => ({
    ...state,
    datatron: {
      ...state.datatron,
      loaded: false,
      loading: false,
      error,
    },
  }),

  [resetDatatron.type]: (state) => ({
    ...state,
    datatron: cloneDeep(basic),
  }),
};

export function* fetchDatatronSaga({ payload: id }) {
  const { response, error } = yield call(api.datatrons.getDatatron, id);

  if (response) {
    yield put(fetchDatatronSuccess(response));
    yield put(searchUsersByOrganization(response.organization?.id, ''));
    yield put(fetchDevices());
  } else {
    yield put(fetchDatatronFail(error));
  }
}

export function* watchFetchDatatron() {
  yield takeEvery(fetchDatatron, fetchDatatronSaga);
}
