import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as selector from '../../../redux/selectors/aiApps.selector';
import Table, { TableRow } from '../../common/table/table';
import NoSelectedPlaceholder from '../placeholder/NoSelectedPlaceholder';
import { FormattedMessage, useIntl } from 'react-intl';
import { openModal } from '../../../redux/modules/modals.module';
import { ANOMALY_MODEL_ADD } from '../../../redux/constants/modals.constants';
import commonMessages from '../../../messages/common.messages';
import aiAppConfigMessages from '../../../messages/aiApp.config.messages';
import { ANOMALY_MODEL_HEADER } from '../../../redux/constants/aiApp.anomalyModule.form.constants';
import { triggerAnomalyModel } from '../../../redux/modules/aiApps.anomalyModel.trigger.module';
import {
  CREATED_STATUS,
  COMPLETED_STATUS,
} from '../../../redux/constants/aiApp.cycleDetection.form.constants';
import { triggerCycleDetection } from '../../../redux/modules/aiApps.cycleDetection.trigger.module';
import AnomalyModelDisplay from './AnomalyModelDisplay';
import Counter from '../../common/counter/Counter';

/**
 * A React functional component that renders the Anomaly Model Section.
 *
 * This component displays the details of an anomaly model, including its status, duration, start and end dates, candidates, and anomaly models.
 * It also allows users to trigger the anomaly model and view details.
 *
 * @return {JSX.Element} The JSX element representing the Anomaly Model Section.
 */
const AnomalyModelSection: React.FC = () => {
  const dispatch = useDispatch();
  const { formatMessage, formatDate } = useIntl();
  const cycleDetectionDetails = useSelector(selector.getCycleDetectionDetails);
  const [selectedAnomalyModel, setSelectedAnomalyModel] = useState<any>();

  useEffect(() => {
    setSelectedAnomalyModel(null);
  }, [cycleDetectionDetails]);

  if (!cycleDetectionDetails) return <NoSelectedPlaceholder />;

  const {
    id: cycleDetectionId,
    status,
    cycle_duration_max: cycleDurationMax,
    cycle_duration_min: cycleDurationMin,
    start_date: startDate,
    end_date: endDate,
    anomaly_models: anomalyModels,
    candidates,
  } = cycleDetectionDetails;

  const candidateHeaders = candidates.length > 0 ? Object.keys(candidates[0]) : [];

  const handleOpenAnomalyModelModal = () => {
    dispatch(openModal(ANOMALY_MODEL_ADD));
  };

  const renderAnomalyModelActions = (row: TableRow) => (
    <div className='td-buttons'>
      {row.status === CREATED_STATUS && (
        <button
          className='button button__xs light-grey-white'
          title={formatMessage(commonMessages.run)}
          onClick={() => dispatch(triggerAnomalyModel(cycleDetectionId, row.id))}
        >
          <i className='fa fa-terminal' />
        </button>
      )}
      {row.status === COMPLETED_STATUS && (
        <button
          className='button button__xs light-grey-white'
          title={formatMessage(commonMessages.details)}
          onClick={() => setSelectedAnomalyModel(row)}
        >
          <i className='fa fa-eye' />
        </button>
      )}
    </div>
  );

  return (
    <div className='container mt-5'>
      <div className='info-grid'>
        <div className='info-card'>
          <div className='ai-app-details--list'>
            <div className='ai-app-details--item'>
              <div className='ai-app-details--icon'>
                <span className='icon-info' />
              </div>
              <div className='ai-app-details--text'>
                <div className='ai-app-details--position w33'>
                  <label>
                    <FormattedMessage tagName='b' {...commonMessages.status} />
                  </label>
                  <p>
                    {status}{' '}
                    {status === CREATED_STATUS && (
                      <button
                        title={formatMessage(commonMessages.run)}
                        className='button button__xs light-grey-white'
                        onClick={() => dispatch(triggerCycleDetection(cycleDetectionId))}
                      >
                        <i className='fa fa-terminal' />
                        <FormattedMessage tagName='b' {...commonMessages.run} />
                      </button>
                    )}
                  </p>
                </div>
                <div className='ai-app-details--position w33'>
                  <label>
                    <FormattedMessage tagName='b' {...commonMessages.duration} />
                  </label>
                  <p>
                    {cycleDurationMin} - {cycleDurationMax}
                    <FormattedMessage tagName='b' {...commonMessages.minutes} />
                  </p>
                </div>

                <div className='ai-app-details--position w33'>
                  <label>
                    <FormattedMessage tagName='b' {...commonMessages.startDate} />
                    {'-'}
                    <FormattedMessage tagName='b' {...commonMessages.endDate} />
                  </label>
                  <p>
                    {formatDate(startDate)}-{formatDate(endDate)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='table--title'>
        <FormattedMessage tagName='b' {...aiAppConfigMessages.candidates} />
        <Counter value={candidates.length} />
      </div>
      <div className='table-responsive'>
        <Table headers={candidateHeaders} rows={candidates} />
      </div>

      <div className='table--title'>
        <FormattedMessage tagName='b' {...aiAppConfigMessages.anomalyModel} />
        <Counter value={anomalyModels.length} />
        <button
          className='add-anomaly-btn btn btn-success dropdown-toggle button light-grey-white'
          onClick={handleOpenAnomalyModelModal}
          disabled={candidates.length === 0}
        >
          <span className='fa fa-plus me-2' />
          <FormattedMessage tagName='b' {...commonMessages.add} />{' '}
          <FormattedMessage tagName='b' {...aiAppConfigMessages.anomalyModel} />
        </button>
      </div>

      <div className='table-responsive'>
        <Table
          headers={ANOMALY_MODEL_HEADER}
          rows={anomalyModels}
          renderActions={renderAnomalyModelActions}
          hasStatus={true}
          selectedId={selectedAnomalyModel && selectedAnomalyModel.id}
        />
      </div>

      {selectedAnomalyModel && <AnomalyModelDisplay anomaly={selectedAnomalyModel} />}
    </div>
  );
};

export default AnomalyModelSection;
