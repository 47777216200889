import { PHOTO_FIELD } from './cardPhoto.form.constants';

export { PHOTO_FIELD } from './cardPhoto.form.constants';

export const MACHINE_FORM_NAME = 'MACHINE_FORM_NAME';
export const NAME_FIELD = 'name';
export const TYPE_FIELD = 'type';
export const VENDOR_FIELD = 'make';
export const MODEL_FIELD = 'model';
export const YEAR_FIELD = 'year';
export const SERIAL_NUMBER_FIELD = 'serialNumber';
export const NOTES_FIELD = 'notes';
export const PERFORMANCE_MAX_FIELD = 'performanceAmountMax';
export const PERFORMANCE_PLANNED_FIELD = 'performanceAmountWanted';
export const LOCATION_FIELD = 'locationId';
export const ORGANIZATION_FIELD = 'organizationId';
export const SCHEDULE_CALENDAR_FIELD = 'scheduleCalendarId';
export const SHIFT_CALENDAR_FIELD = 'shiftCalendarId';
export const CYCLE_SECONDS_PER_PART = 'cycleSecondsPerPart';
export const OEE_SOURCE = 'oeeSource';
export const OEE_A_DATA_POINT_ID = 'oeeAvailabilityDataPointId';
export const OEE_P_DATA_POINT_ID = 'oeePerformanceDataPointId';
export const OEE_Q_DATA_POINT_ID = 'oeeQualityDataPointId';
export const OEE_THRESHOLD_GREEN = 'oeeThresholdGreen';
export const OEE_THRESHOLD_YELLOW = 'oeeThresholdYellow';
export const OEE_THRESHOLD_RED = 'oeeThresholdRed';
export const DMC_FLAG = 'oeePerformanceDataPointUsesDmc';
export const DMC_CONFIG = 'dmcConfig';
export const RFID_DATA_POINT_ID = 'rfidDataPointId';
export const ANALYTICS_CYCLE_START_DATA_POINT_ID = 'analyticsCycleStartDataPointId';
export const ANALYTICS_CYCLE_END_DATA_POINT_ID = 'analyticsCycleEndDataPointId';

export const MACHINE_PROPS = [
  PHOTO_FIELD,
  NAME_FIELD,
  TYPE_FIELD,
  VENDOR_FIELD,
  MODEL_FIELD,
  YEAR_FIELD,
  SERIAL_NUMBER_FIELD,
  LOCATION_FIELD,
  ORGANIZATION_FIELD,
  NOTES_FIELD,
  PERFORMANCE_MAX_FIELD,
  PERFORMANCE_PLANNED_FIELD,
  CYCLE_SECONDS_PER_PART,
  OEE_SOURCE,
  SCHEDULE_CALENDAR_FIELD,
  SHIFT_CALENDAR_FIELD,
  OEE_A_DATA_POINT_ID,
  OEE_P_DATA_POINT_ID,
  OEE_Q_DATA_POINT_ID,
  OEE_THRESHOLD_GREEN,
  OEE_THRESHOLD_YELLOW,
  OEE_THRESHOLD_RED,
  DMC_FLAG,
  DMC_CONFIG,
  RFID_DATA_POINT_ID,
  ANALYTICS_CYCLE_START_DATA_POINT_ID,
  ANALYTICS_CYCLE_END_DATA_POINT_ID,
];

export const MACHINE_FORM_ERROR_FIELD_OFFSET = -120;
