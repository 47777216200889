import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import messages from '../../../../messages/location.types.messages';

function LeftBarItem({
  id, name, type, isSelected, isActive, onClick,
}) {
  function onItemSelect() { onClick(id); }

  const style = classnames(
    'locations-result',
    { selected: isSelected },
    { active: isActive },
  );

  return (
    <div className={style} onClick={onItemSelect}>
      <FormattedMessage {...messages[type]} tagName="label" />
      <div>{ name }</div>
      <span className="fa fa-check-circle" />
      <span className="fa fa-times-circle" />
    </div>
  );
}

LeftBarItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LeftBarItem;
