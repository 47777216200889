import { find } from 'lodash';

import experimentStatuses from '../../../../common/enums/experimentStatuses';
import { PUBLIC_MEASUREMENT_STATUSES } from '../../../../common/enums/measurementStatuses';

export const getActiveTab = (state) => state.experimentsPage.tab;

export const getExperiments = (state) => state.experimentsPage.experiments.list;
export const getExperimentById = (state, id) => {
  if (!id) return null;
  const experiments = getExperiments(state);
  return find(experiments, { id });
};
export const getExperimentsPagination = (state) => state.experimentsPage.experiments.pagination;
export const experimentsLoading = (state) => state.experimentsPage.experiments.loading;
export const experimentsLoaded = (state) => state.experimentsPage.experiments.loaded;
export const getExperimentsFilters = (state) => state.experimentsPage.experiments.filters;
export const getQueryFilterValue = (state) => getExperimentsFilters(state).query;
export const experimentsFiltersApplied = (state) => {
  const filters = getExperimentsFilters(state);

  return !(!filters.query && (filters.status.length === 0 || filters.status.length === experimentStatuses.length));
};

export const getMeasurements = (state) => state.experimentsPage.measurements.list;
export const getMeasurementsPagination = (state) => state.experimentsPage.measurements.pagination;
export const measurementsLoading = (state) => state.experimentsPage.measurements.loading;
export const measurementsLoaded = (state) => state.experimentsPage.measurements.loaded;
export const getMeasurementsFilters = (state) => state.experimentsPage.measurements.filters;
export const measurementsFiltersApplied = (state) => {
  const filters = getMeasurementsFilters(state);

  return !(filters.status.length === 0 || filters.status.length === PUBLIC_MEASUREMENT_STATUSES.length);
};
