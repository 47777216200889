import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../_tools';
import commonMessages from '../../../../messages/common.messages';

const Footer = ({
  onCancelClick,
  onAddClick,
  isAddButtonDisabled,
  isAddButtonLoading,
  cancelButtonTabIndex,
  addButtonTabIndex,
}) => (
  <div className="modal-buttons">
    <Button
      type="button"
      className="button__cancel"
      onClick={onCancelClick}
      tabIndex={cancelButtonTabIndex}
    >
      <FormattedMessage {...commonMessages.cancel} />
    </Button>
    <Button
      type="submit"
      className="button__save"
      onClick={onAddClick}
      disabled={isAddButtonDisabled}
      showLoader={isAddButtonLoading}
      tabIndex={addButtonTabIndex}
    >
      <FormattedMessage {...commonMessages.add} />
    </Button>
  </div>
);

Footer.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  isAddButtonDisabled: PropTypes.bool,
  isAddButtonLoading: PropTypes.bool,
  cancelButtonTabIndex: PropTypes.number,
  addButtonTabIndex: PropTypes.number,
};

Footer.defaultProps = {
  isAddButtonDisabled: false,
  isAddButtonLoading: false,
};

export default Footer;
