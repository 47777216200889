import { connect } from 'react-redux';

import Form from './Form';
import { getDeviceFormFields } from '../../../../../redux/selectors/datatron.devices.selector';
import { getDeviceTypeById } from '../../../../../redux/selectors/datatron.selector';
import { DEVICE_TYPE_FIELD } from '../../../../../redux/constants/datatron.device.form.constants';

export const mapStateToProps = (state) => {
  const formValues = getDeviceFormFields(state);
  const selectedDeviceTypeId = formValues[DEVICE_TYPE_FIELD];
  const deviceType = getDeviceTypeById(state, selectedDeviceTypeId);

  return {
    additionalFields: (deviceType && deviceType.hasDeviceSchema) ? deviceType.deviceFields : [],
  };
};

export default connect(mapStateToProps)(Form);
