import { connect } from 'react-redux';

import TableQuerySearchState from '../../../../common/tableQuerySearch/TableQuerySearchState';
import { getQueryFilterValue } from '../../../../../redux/selectors/experimentsPage.selector';
import { filterByText } from '../../../../../redux/modules/experiments.filter.module';
import { EXPERIMENTS_QUERY_FILTER_INPUT } from '../../../../../redux/constants/components.constants';
import placeholderMessages from '../../../../../messages/placeholders.messages';

export const mapStateToProps = (state) => ({
  value: getQueryFilterValue(state),
  stateComponentId: EXPERIMENTS_QUERY_FILTER_INPUT,
  placeholder: placeholderMessages.searchByExperiment,
});

export const mapDispatchToProps = {
  onQueryChange: filterByText,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableQuerySearchState);
