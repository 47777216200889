import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function Cancel({
  onClick,
}) {
  return (
    <button type="button" className="button__cancel" onClick={onClick}>
      <FormattedMessage
        id="common.cancel"
        defaultMessage="Cancel"
      />
    </button>
  );
}

Cancel.propTypes = {
  onClick: PropTypes.func.isRequired,
};
