import { formValueSelector } from 'redux-form';

import {
  machineSubscriptionUpdateForm,
  categoryField, titleField, isEventField,
} from '../constants/machineSubscription.update.form';

export const createFormSelector = () => formValueSelector(machineSubscriptionUpdateForm);

export const getFormFields = (state) => createFormSelector()(
  state,
  categoryField,
  titleField,
  isEventField,
);
