import React from 'react';
import PropTypes from 'prop-types';

import DropdownTabsItem from './DropdownTabsItem';
import * as datesModes from '../../../../../../../common/enums/dates.modes';

export const DropdownTabs = ({ select, selected }) => (
  <ul className="tabs">
    <DropdownTabsItem
      type={datesModes.ABSOLUTE}
      select={select}
      isActive={selected === datesModes.ABSOLUTE}
    />
    <DropdownTabsItem
      type={datesModes.RELATIVE}
      select={select}
      isActive={selected === datesModes.RELATIVE}
    />
  </ul>
);

DropdownTabs.propTypes = {
  selected: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
};

export default DropdownTabs;
