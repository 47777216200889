import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { isEmpty } from 'lodash';

import * as form from '../constants/tools.add.form.constants';
import { TOOL_ADD } from '../constants/modals.constants';
import { getFormFields } from '../selectors/tools.add.form.selector';
import * as api from '../../core/api';
import { closeModal } from './modals.module';
import { validateNewTool } from '../../core/validation/tools.validation';
import { fetchTools } from './tools.fetch.module';

export const createTool = createAction(
  'create tool',
);

export const createToolSuccess = createAction(
  'create tool - success',
  (tool) => tool,
);

export const createToolFail = createAction(
  'create tool - fail',
  (error) => error,
);

export const reducer = {
  [createToolSuccess]: (state, tool) => ({
    ...state,
    tools: {
      ...state.tools,
      all: {
        ...state.tools.all,
        list: [
          tool,
          ...state.tools.all.list,
        ],
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.ADD_TOOL_FORM_NAME,
    ...form.TOOL_PROPS,
    ...form.MACHINE_PROPS,
  ));
}

export function* handleErrors(errors) {
  yield put(createToolFail(errors));
  yield put(stopSubmit(form.ADD_TOOL_FORM_NAME, errors));
}

export function* createToolSaga() {
  yield call(touchAllFieldsSaga);

  const state = yield select();
  const fields = yield call(getFormFields, state);
  yield put(startSubmit(form.ADD_TOOL_FORM_NAME));

  const preRequestErrors = yield call(validateNewTool, fields);
  if (!isEmpty(preRequestErrors)) {
    yield handleErrors(preRequestErrors);
    return;
  }

  const { response, error } = yield call(api.tools.createTool, fields);

  if (response) {
    let createdTool = response;

    if (fields[form.MACHINE_FIELD]) {
      const assignToolCall = yield call(api.tools.setToolToMachine, fields[form.MACHINE_FIELD], response.id, { mount: true, comment: fields[form.COMMENT_FIELD] });
      if (assignToolCall.response) {
        createdTool = assignToolCall.response;
      }
    }
    yield put(createToolSuccess(createdTool));
    yield put(stopSubmit(form.ADD_TOOL_FORM_NAME));
    yield put(closeModal(TOOL_ADD));
    yield put(fetchTools());
  } else {
    yield handleErrors(error);
  }
}

export function* watchCreateTool() {
  yield takeEvery(createTool.getType(), createToolSaga);
}
