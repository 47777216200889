import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { getFilters } from '../selectors/tools.selector';

export const fetchTools = createAction(
  'fetch tools',
  (page = 1) => page,
);

export const fetchToolsSuccess = createAction(
  'fetch tools - success',
  (list = [], pagination = null) => ({ list, pagination }),
);

export const fetchToolsFail = createAction(
  'fetch tools - fail',
  (error) => error,
);

export const reducer = {
  [fetchTools]: (state) => ({
    ...state,
    tools: {
      ...state.tools,
      all: {
        ...state.tools.all,
        list: [],
        pagination: null,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchToolsSuccess]: (state, { list, pagination }) => ({
    ...state,
    tools: {
      ...state.tools,
      all: {
        ...state.tools.all,
        list,
        pagination,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [fetchToolsFail]: (state, error) => ({
    ...state,
    tools: {
      ...state.tools,
      all: {
        ...state.tools.all,
        list: [],
        pagination: null,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchToolsSaga({ payload: page }) {
  const state = yield select();
  const filters = yield call(getFilters, state);

  const payload = {
    ...filters,
    page,
  };

  const { response, error } = yield call(api.tools.getTools, payload);

  if (response) {
    yield put(fetchToolsSuccess(response.list, response.pagination));
  } else {
    yield put(fetchToolsFail(error));
  }
}

export function* watchFetchTools() {
  yield takeEvery(fetchTools.getType(), fetchToolsSaga);
}
