import React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import SelectContainer from '../../../_tools/formFields/select/CustomSelect.container';
import Radio from '../../../_tools/formFields/radio/Radio';
import * as form from '../../../../redux/constants/report.legend.dataPoint.form.constants';
import * as axises from '../../../../../../common/constants/report.axis';
import colorsMessages from '../../../../messages/colors.messages';
import * as colors from '../../../../redux/constants/report.dataPoints.colors.constants';
import commonCoreMessages from '../../../../messages/common.core.messages';

export const renderColorOption = (option) => (
  <>
    <span className="rectangle" style={{ borderColor: `#${option.value}` }} />
    <span>{ option.label }</span>
  </>
);

const colorOptions = [
  { value: colors.RED, label: colorsMessages.red },
  { value: colors.ORANGE, label: colorsMessages.orange },
  { value: colors.YELLOW, label: colorsMessages.yellow },
  { value: colors.GREEN, label: colorsMessages.green },
  { value: colors.CYAN, label: colorsMessages.cyan },
  { value: colors.BLUE, label: colorsMessages.blue },
  { value: colors.PURPLE, label: colorsMessages.purple },
  { value: colors.PINK, label: colorsMessages.pink },
];
const axisOptions = [
  { value: axises.Y_LEFT_AXIS, label: commonCoreMessages.left },
  { value: axises.Y_RIGHT_AXIS, label: commonCoreMessages.right },
];

const getLineWidthOptions = (canSelectZeroLineWidth = true) => {
  const lineWidthOptions = [
    { value: 1, label: '1px' },
    { value: 2, label: '2px' },
    { value: 3, label: '3px' },
    { value: 4, label: '4px' },
  ];

  if (canSelectZeroLineWidth) {
    lineWidthOptions.unshift({ value: 0, label: '0px' });
  }

  return lineWidthOptions;
};

const getFillOpacityOptions = (canSelectZeroFillOpacity = true) => {
  const fillOpacityOptions = [
    { value: 0.25, label: '25%' },
    { value: 0.5, label: '50%' },
    { value: 0.75, label: '75%' },
    { value: 1, label: '100%' },
  ];

  if (canSelectZeroFillOpacity) {
    fillOpacityOptions.unshift({ value: 0, label: '0%' });
  }

  return fillOpacityOptions;
};

const FormBody = ({ canSelectZeroLineWidth, canSelectZeroFillOpacity }) => (
  <>
    <label>
      <FormattedMessage {...commonCoreMessages.color} />
      <Field
        component={SelectContainer}
        name={form.COLOR_FIELD}
        options={colorOptions}
        components={{
          Option: renderColorOption,
          ValueContainer: renderColorOption,
        }}
        translateOptionsLabels
      />
    </label>

    <label className="radio-item-cols">
      <FormattedMessage {...commonCoreMessages.axis} />
      <Field
        component={Radio}
        name={form.AXIS_FIELD}
        options={axisOptions}
        translateOptionsLabels
      />
    </label>

    <div className="inputs--row">
      <div className="inputs--col--sm2">
        <label>
          <FormattedMessage {...commonCoreMessages.lineWidth} />
          <Field
            component={SelectContainer}
            name={form.LINE_WIDTH_FIELD}
            options={getLineWidthOptions(canSelectZeroLineWidth)}
          />
        </label>
      </div>
      <div className="inputs--col--sm2">
        <label>
          <FormattedMessage {...commonCoreMessages.fillOpacity} />
          <Field
            component={SelectContainer}
            name={form.FILL_OPACITY_FIELD}
            options={getFillOpacityOptions(canSelectZeroFillOpacity)}
          />
        </label>
      </div>
    </div>
  </>
);

FormBody.propTypes = {
  canSelectZeroLineWidth: PropTypes.bool.isRequired,
  canSelectZeroFillOpacity: PropTypes.bool.isRequired,
};

export default FormBody;
