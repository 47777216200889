import { connect } from 'react-redux';

import EventHeader from './EventHeader';
import * as routes from '../../index/routes.js';

export function mapStateToProps(state, { machine }) {
  return {
    machineLink: routes.getMachineLink(machine.id),
  };
}

export default connect(mapStateToProps)(EventHeader);
