import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm, Field } from 'redux-form';
import BasicModal from '../../core/BasicModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { ANOMALY_MODEL_ADD } from '../../../../redux/constants/modals.constants';
import * as form from '../../../../redux/constants/aiApp.anomalyModule.form.constants';
import { FormField } from '../../../_tools';
import AddModalFooter from '../../core/footers/AddModalFooter';
import aiAppMessages from '../../../../messages/aiApp.config.messages';
import SelectContainer from '../../../_tools/formFields/select/CustomSelect.container';
import * as selector from '../../../../redux/selectors/aiApps.selector';
import { createAIAppAnomaly } from '../../../../redux/modules/aiApp.anomaly.create.module';

const AnomalyModelCreate: React.FC<InjectedFormProps<FormData>> = ({
  handleSubmit,
  submitting,
  pristine,
}) => {
  const dispatch = useDispatch();
  const candidates = useSelector(selector.getCycleTimeDetectionCandidates);
  const { id: cycleDetectionId } = useSelector(selector.getCycleDetectionDetails);

  const onSubmit = () => {
    dispatch(createAIAppAnomaly());
  };

  const close = () => {
    dispatch(closeModal(ANOMALY_MODEL_ADD));
  };

  const candidatesOptions = candidates.map((candidate) => ({
    value: candidate.uuid,
    label: candidate.label,
  }));

  return (
    <BasicModal close={close} modalClass='modal--m'>
      <div className='modal--content'>
        <FormattedMessage {...aiAppMessages.addAnomalyModel} tagName='h3' />
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <label>
            <FormattedMessage {...aiAppMessages.candidateUUID} />
            <Field
              component={SelectContainer}
              name={form.CANDIDATE_UUID}
              options={candidatesOptions}
              tabIndex={2}
              autoFocus
            />
          </label>

          <div className='inputs--row'>
            <div className='inputs--col--md2'>
              <FormField
                label={aiAppMessages.batchSize.id}
                name={form.BATCH_SIZE}
                placeholder={aiAppMessages.batchSize.id}
                type='number'
                min={form.MIN_BATCH_SIZE}
                max={form.MAX_BATCH_SIZE}
                tabIndex={3}
              />
            </div>
            <div className='inputs--col--md2'>
              <FormField
                label={aiAppMessages.numEpochs.id}
                name={form.NUM_EPOCHS}
                placeholder={aiAppMessages.numEpochs.id}
                type='number'
                min={form.MIN_NUM_EPOCHS}
                max={form.MAX_NUM_EPOCHS}
                tabIndex={4}
              />
            </div>
          </div>
          <FormField
            name={form.CYCLE_DETECTION_ID}
            _defaultValue={cycleDetectionId}
            tabIndex={1}
            type={'hidden'}
            disabled
          />
          <div className='line' />
          <AddModalFooter
            onAddClick={handleSubmit(onSubmit)}
            onCancelClick={close}
            isAddButtonDisabled={submitting || pristine}
            isAddButtonLoading={submitting}
          />
        </form>
      </div>
    </BasicModal>
  );
};

export default reduxForm<FormData>({
  form: form.AI_APP_ANOMALY_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AnomalyModelCreate);
