import { connect } from 'react-redux';

import ReportGraphControls from './ReportGraphControls';
import { setReportControls } from '../../../../../redux/modules/report.controls.module';
import { isAutoRefreshAvailable } from '../../../../../core/common/report';

export const mapStateToProps = (state, { report }) => ({
  isAutoRefreshAvailable: isAutoRefreshAvailable(report),
});

export const mapDispatchToProps = {
  setReportControls,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportGraphControls);
