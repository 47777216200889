import React from 'react';
import PropTypes from 'prop-types';

import Brush from './Brush';
import ThumbChart from './ThumbChart';
import AxisBottom from './AxisBottom';

function Slider({
  frameWidth,
  sliderHeight,
  translate,
  reportId,
  xScale,
  yLeftScale,
  leftAxisChartPoints,
  leftAxisEntries,
  isLeftAxisEnabled,
  yRightScale,
  rightAxisChartPoints,
  rightAxisEntries,
  isRightAxisEnabled,
  changeSelection,
  selection,
}) {
  return (
    <svg style={{ width: '100%', height: '100%' }}>
      <g transform={translate}>
        <rect
          width={frameWidth}
          height={sliderHeight}
          className="chart-border-rect chart-fill-rect"
        />
        <ThumbChart
          xScale={xScale}
          yLeftScale={yLeftScale}
          leftAxisChartPoints={leftAxisChartPoints}
          leftAxisEntries={leftAxisEntries}
          isLeftAxisEnabled={isLeftAxisEnabled}
          yRightScale={yRightScale}
          rightAxisChartPoints={rightAxisChartPoints}
          rightAxisEntries={rightAxisEntries}
          isRightAxisEnabled={isRightAxisEnabled}
        />
        <g transform={`translate(0, ${sliderHeight})`}>
          <AxisBottom
            scale={xScale}
            height={sliderHeight}
          />
        </g>
        <g transform="translate(1, 1)">
          <Brush
            xScale={xScale}
            height={sliderHeight - 2}
            width={frameWidth - 2}
            changeSelection={changeSelection}
            reportId={reportId}
            selection={selection}
          />
        </g>
      </g>
    </svg>
  );
}

Slider.propTypes = {
  frameWidth: PropTypes.number.isRequired,
  sliderHeight: PropTypes.number.isRequired,
  translate: PropTypes.string.isRequired,
  selection: PropTypes.array.isRequired,
  changeSelection: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
  xScale: PropTypes.func.isRequired,
  yLeftScale: PropTypes.func.isRequired,
  leftAxisChartPoints: PropTypes.array.isRequired,
  leftAxisEntries: PropTypes.array.isRequired,
  isLeftAxisEnabled: PropTypes.bool.isRequired,
  yRightScale: PropTypes.func.isRequired,
  rightAxisChartPoints: PropTypes.array.isRequired,
  rightAxisEntries: PropTypes.array.isRequired,
  isRightAxisEnabled: PropTypes.bool.isRequired,
};

export default Slider;
