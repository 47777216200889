import { connect } from 'react-redux';

import DownloadExperimentModal from './DownloadExperimentModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { EXPERIMENT_DOWNLOAD } from '../../../../redux/constants/modals.constants';

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(EXPERIMENT_DOWNLOAD)),
});

export default connect(null, mapDispatchToProps)(DownloadExperimentModal);
