import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import ModalLine from '../../core/ModalLine';

export const DownloadExperimentModal = ({
  close,
}) => (
  <BasicModal close={close} modalClass="modal--sm2">
    <div className="modal--content">
      <h3 className="first-title">
        <FormattedMessage
          id="experiments.modal.download_experiment"
          defaultMessage="Download Experiment"
        />
      </h3>

      <div className="export--line export--line--modal">
        <div className="radios radios-logos">
          <label className="radio">
            <input type="radio" name="fileFormat" value="on" />
            <span>
              <img src="/img/knime_logo.svg" alt="" />
            </span>
          </label>
          <label className="radio">
            <input type="radio" name="fileFormat" value="on" />
            <span>
              <img src="/img/jupyter_logo.svg" alt="" />
            </span>
          </label>
          <label className="radio">
            <input type="radio" name="fileFormat" value="on" />
            <span>
              <img src="/img/parquet_logo.svg" alt="" />
            </span>
          </label>
        </div>
      </div>

      <ModalLine />
      <div className="modal-buttons">
        <button className="button__cancel">Cancel</button>
        <button className="button__save">Download</button>
      </div>
    </div>
  </BasicModal>
);

DownloadExperimentModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default DownloadExperimentModal;
