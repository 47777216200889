import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { getMachine } from '../selectors/machine.selector';
import {
  getSelectedDataPointIds,
  getSelectedDeviceId,
  getSelectedDatatronId,
} from '../selectors/configRepoExplorer.selector';
import * as api from '../../core/api';
import { DEFAULT } from '../../../../common/enums/dataPointCategories';
import { closeModal } from './modals.module';
import { CONFIG_REPO_EXPLORER } from '../constants/modals.constants';

export const subscribeMachine = createAction(
  'subscribe machine to data points',
);

export const subscribeMachineSuccess = createAction(
  'subscribe machine to data points - success',
  (dataPoints) => dataPoints,
);

export const subscribeMachineFail = createAction(
  'subscribe machine to data points - fail',
  (error) => error,
);

export const reducer = {
  [subscribeMachine]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        subscribe: {
          ...state.machine.dataPoints.subscribe,
          loading: true,
          loaded: false,
          error: null,
        },
      },
    },
  }),
  [subscribeMachineSuccess]: (state, dataPoints) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        list: [
          ...dataPoints,
          ...state.machine.dataPoints.list,
        ],
        subscribe: {
          ...state.machine.dataPoints.subscribe,
          loading: false,
          loaded: true,
          error: null,
        },
      },
    },
  }),
  [subscribeMachineFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        subscribe: {
          ...state.machine.dataPoints.subscribe,
          loading: false,
          loaded: false,
          error,
        },
      },
    },
  }),
};

export function* subscribeMachineSaga() {
  const state = yield select();

  const machine = yield call(getMachine, state);
  const dataPointIds = yield call(getSelectedDataPointIds, state);
  const deviceId = yield call(getSelectedDeviceId, state);
  const datatronId = yield call(getSelectedDatatronId, state);

  const dataPoints = dataPointIds.map((id) => ({
    dataPointId: id,
    deviceId,
    datatronId,
    category: DEFAULT,
    isEvent: false,
  }));

  const {
    response,
    error,
  } = yield call(api.machines.subscribeToDataPoints, machine.id, dataPoints);

  if (response) {
    yield put(subscribeMachineSuccess(response));
    yield put(closeModal(CONFIG_REPO_EXPLORER));
  } else {
    yield put(subscribeMachineFail(error));
  }
}

export function* watchSubscribeMachine() {
  yield takeEvery(subscribeMachine.getType(), subscribeMachineSaga);
}
