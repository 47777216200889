import { DropTarget } from 'react-dnd';

import { DND_COLUMN_ITEM_TYPE } from '../../../redux/constants/column.constants';
import { canMoveEvent } from '../../../core/common/maintenance.stream';
import Column from './Column';
import { CARD_HEIGHT } from './card/inbox/InboxEvent';

function clamp(n, min, max) {
  return Math.max(Math.min(n, max), min);
}

function calculateRow(y, cardsCount) {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

  return clamp(
    Math.round(((y + scrollTop) - CARD_HEIGHT) / CARD_HEIGHT),
    0,
    (cardsCount - 1),
  );
}

function calculateNeighbors(cards, row) {
  const belowCardId = cards[row] ? cards[row].id : -1;
  const aboveCardId = cards[row - 1] ? cards[row - 1].id : -1;

  return { belowCardId, aboveCardId };
}

const columnTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();

    const { y } = monitor.getClientOffset();

    const row = calculateRow(y, props.cards.length);

    return {
      cardId: item.cardId,
      fromColumn: item.type,
      toColumn: props.type,
      betweenCards: calculateNeighbors(props.cards, row),
    };
  },
  canDrop(props, monitor) {
    return canMoveEvent(monitor.getItem().type, props.type);
  },
  hover(props, monitor, component) {
    const { y } = monitor.getClientOffset();
    const row = calculateRow(y, props.cards.length);

    const { cardId } = monitor.getItem();

    component.handleDrag({
      row,
      cardId,
    });
  },
};

function collect(connectDnd, monitor) {
  return {
    connectDropTarget: connectDnd.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
  };
}

export default DropTarget(DND_COLUMN_ITEM_TYPE, columnTarget, collect)(Column);
