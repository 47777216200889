import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { removeObjectFromList, compareById } from '../../../../common/helpers/object';
import { closeModal } from './modals.module';
import { DP_ASSISTANT_REMOVE } from '../constants/modals.constants';

export const removeAssistant = createAction(
  'remove datatron assistant',
  (datatronId, userId) => ({ datatronId, userId }),
);

export const removeAssistantSuccess = createAction(
  'remove datatron assistant - success',
  (userId) => userId,
);

export const removeAssistantFail = createAction(
  'remove datatron assistant - fail',
  (error) => error,
);

export const reducer = {
  [removeAssistantSuccess]: (state, userId) => ({
    ...state,
    datatron: {
      ...state.datatron,
      details: {
        ...state.datatron.details,
        assistants: removeObjectFromList(
          state.datatron.details.assistants,
          { id: userId },
          compareById,
        ),
      },
    },
  }),
};

export function* removeAssistantSaga({ payload: { datatronId, userId } }) {
  const { response, error } = yield call(api.datatrons.removeAssistant, datatronId, userId);

  if (response) {
    yield put(closeModal(DP_ASSISTANT_REMOVE));
    yield put(removeAssistantSuccess(userId));
  } else {
    yield put(removeAssistantFail(error));
  }
}

export function* watchRemoveDatatronAssistant() {
  yield takeEvery(removeAssistant.getType(), removeAssistantSaga);
}
