import querystring from 'querystring';
import { apiRequest } from './_tools';
import { prepareFiltersForAPI } from '../common/filters';

export function getAll() {
  return apiRequest('/api/batches');
}

export function getAllFiltered(filters) {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/batches/search?${query}`);
}

export function getById(id) {
  return apiRequest(`/api/batches/${id}`);
}

export function getProcessingById(id, filters) {
  const query = querystring.stringify(filters);
  return apiRequest(`/api/batches/${id}/processings?${query}`);
}

export const getProductsDownloadAvailability = (filters) => {
  const query = querystring.stringify(prepareFiltersForAPI(filters));
  return apiRequest(`/api/products/download/available?${query}`);
};

export const getProcessingsDownloadAvailability = (filters) => {
  const query = querystring.stringify(prepareFiltersForAPI(filters));
  return apiRequest(`/api/processings/download/available?${query}`);
};
