import { createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { updateLocationInTree } from '../../core/common/locationPicker';
import { getLocationParentTree } from '../../core/api/locations';
import { fetchLocationPicker } from './locationPicker.fetch.module';

export const initLocationPicker = createAction(
  'init location picker with location',
  (initLocationId) => ({ payload: initLocationId }),
);

export const initLocationPickerSuccess = createAction(
  'init location picker with location success',
  (newTree, initLocationId) => ({ payload: { newTree, initLocationId } }),
);

export const initLocationPickerFail = createAction(
  'init location picker with location fail',
  (error) => ({ payload: error }),
);

export const reducer = {
  [initLocationPicker.type]: (state) => ({
    ...state,
    locationPicker: {
      ...state.locationPicker,
      loading: true,
      loaded: false,
      error: null,
    },
  }),

  [initLocationPickerSuccess.type]: (state, { newTree, initLocationId }) => ({
    ...state,
    locationPicker: {
      ...state.locationPicker,
      tree: newTree,
      loading: false,
      loaded: true,
      error: null,
      selectedLocationId: initLocationId,
    },
  }),

  [initLocationPickerFail.type]: (state, error) => ({
    ...state,
    locationPicker: {
      ...state.locationPicker,
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* initLocationPickerSaga({ payload: initLocationId }) {
  if (initLocationId === undefined || initLocationId === 'undefined') {
    yield put(fetchLocationPicker(null));
  }

  const { response, error } = yield call(getLocationParentTree, initLocationId);

  if (response) {
    const tree = yield call(
      updateLocationInTree,
      [response],
      initLocationId,
      { selected: true },
      { selected: false },
    );

    yield put(initLocationPickerSuccess(tree, initLocationId));
    yield put(fetchLocationPicker(null));
  } else {
    yield put(initLocationPickerFail(error));
  }
}

export function* watchInitLocationPicker() {
  yield takeEvery(initLocationPicker, initLocationPickerSaga);
}
