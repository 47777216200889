import { Todo } from '../common';

export type CommandCallback = {
  command: CallbackCommand;
  journal: CommandJournal;
};

export const COMMAND_STATUSES = ['PENDING', 'SUCCESS', 'FAIL'] as const;

export type CommandStatus = (typeof COMMAND_STATUSES)[number];

export const COMMAND_STATES = [
  'CREATED',
  'SUBMITTED',
  'ACCEPTED',
  'STARTED',
  'RECEIVED',
  'VALIDATED',
  'PERSISTED',
  'EXPIRED',
  'COMPLETED',
] as const;

export type CommandState = (typeof COMMAND_STATES)[number];

type CallbackCommand = {
  id: number;
  createdAt: string;
  name?: string;
  deviceId: number;
  journal: Todo;
  requestPayload: CommandRequest;
  requestUuid: string; // UUID v4
  responsePayload: CommandResponsePayload;
  status: CommandStatus;
  updatedAt: string;
};

export type CommandJournal = {
  id: number;
  commandId: number;
  createdAt: string;
  payload: CommandResponsePayload;
  state: CommandState;
  updatedAt: string;
};

const CommandNames = ['collect', 'stop', 'list', 'config', 'status'] as const;

export type CommandName = (typeof CommandNames)[number];

type CommandRequest = {
  command: { command: CommandName } | StopRequest;
  deviceId: number;
  requestId: string; // UUID v4
};

type StopRequest = {
  command: 'stop';
  mode: string;
};

type CommandResponsePayload = {
  command: CommandResponse;
};

type CommandResponse = StopResponse | ListResponse;

type ListResponse = {
  command: 'list';
  result: 'OK' | 'ERR';
  symbols: Symbols[];
  error?: string;
  version?: string;
};

type StopResponse = {
  command: 'stop';
  error?: string;
  mode: string;
  result?: string; // TODO seems like possible results may be able to be defined
  version?: string;
};

type Symbols = Todo;

export type ConfigRepoCommandsRoute = {
  total: number;
  commands: ConfigRepoCommandResponse[];
};

export type ConfigRepoCommandResponse = {
  id: number;
  name: CommandName;
  requestUuid: string;
  deviceId: number;
  status: CommandStatus;
  requestPayload: CommandRequest;
  responsePayload: CommandResponsePayload;
  createdAt: string;
  updatedAt: string;
  journal: CommandJournal[];
};
