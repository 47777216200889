import classnames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Todo } from '../../../../../../../common/types/common';
import tabMessages from '../../../../../messages/dataPoints.table.tabs.message';
import {
  ACTIVE_TAB,
  ARCHIVED_TAB,
  DataPointsTableTab,
  DEFAULT_TAB,
} from '../../../../../redux/constants/dataPoints.table.tabs.constants';
import {
  DP_ADD_DATA_POINT,
  DP_ARCHIVE_DATA_POINT,
  DP_EDIT_DATA_POINT,
  DP_VIEW_DATA_POINT,
} from '../../../../../redux/constants/modals.constants';
import { fetchDataPointMetric } from '../../../../../redux/modules/dataPoint.metric.fetch.module';
import { resetDataPointMetric } from '../../../../../redux/modules/dataPoint.metric.reset.module';
import { openModal } from '../../../../../redux/modules/modals.module';
import {
  getDataPointsByDeviceId,
  getDeviceTypeById,
} from '../../../../../redux/selectors/datatron.selector';
import TabSelector from '../../../../common/detailsTabSelector/TabSelector';
import AddDataPointButton from './addDataPointButton/AddDataPointButton';
import DataPointsTable from './dataPointsTable/DataPointsTable';
import DeviceDetails from './details/DeviceDetails';
import DeviceHeader from './header/DeviceHeader';
import { useOpen } from '../../../../../core/common/hooks/useOpen';
import { AppState } from '../../../../../redux/state/app.state';

interface Props {
  device: Todo;
}

export const Device: React.FC<Props> = ({ device }) => {
  const deviceId = device.id;
  const deviceType = useSelector((state: Readonly<AppState>) =>
    getDeviceTypeById(state, device.deviceType.id),
  );

  const dispatch = useDispatch();

  const openNewDataPointModal = useCallback(
    (formType) => () => {
      dispatch(
        openModal(DP_ADD_DATA_POINT, {
          deviceId,
          deviceType,
          formType,
          isIndexDataPoint: false,
        }),
      );
    },
    [deviceId, deviceType, dispatch],
  );

  const openEditDataPointModal = useCallback(
    (event) => {
      const dataPointId = event.currentTarget.id;
      dispatch(
        openModal(DP_EDIT_DATA_POINT, {
          deviceId,
          deviceType,
          dataPointId,
        }),
      );
    },
    [deviceId, deviceType, dispatch],
  );

  const openViewDataPointModal = useCallback(
    (event) => {
      const dataPointId = event.currentTarget.id;
      dispatch(resetDataPointMetric());
      dispatch(fetchDataPointMetric(deviceId, dataPointId));
      dispatch(openModal(DP_VIEW_DATA_POINT, { deviceId, dataPointId }));
    },
    [deviceId, dispatch],
  );

  const openArchiveDataPointModal = useCallback(
    (event) => {
      const dataPointId = event.currentTarget.id;
      dispatch(openModal(DP_ARCHIVE_DATA_POINT, { deviceId, dataPointId }));
    },
    [deviceId, dispatch],
  );

  const openEditArrayIndexModal = useCallback(
    (event) =>
      dispatch(
        openModal(DP_ADD_DATA_POINT, {
          deviceId,
          deviceType,
          parentDataPointId: event.currentTarget.id,
          formType: 'index',
          isIndexDataPoint: true,
        }),
      ),
    [deviceId, deviceType, dispatch],
  );

  const { isOpen, toggle } = useOpen();

  const [tableTab, tableTabSet] = useState<DataPointsTableTab>(DEFAULT_TAB);

  const showArchived = tableTab === ARCHIVED_TAB;

  const dataPoints = useSelector((state) => getDataPointsByDeviceId(state, deviceId, false));
  const archivedDataPoints = useSelector((state) => getDataPointsByDeviceId(state, deviceId, true));

  const dataPointCount = useMemo(() => {
    const enabledCount = dataPoints.filter((dp) => dp.enabled).length;
    return { all: dataPoints.length, enabled: enabledCount };
  }, [dataPoints]);

  const dataPointArchivedCount = useMemo(() => {
    const enabledCount = archivedDataPoints.filter((dp) => dp.enabled).length;
    return { all: archivedDataPoints.length, enabled: enabledCount };
  }, [archivedDataPoints]);

  const tabList = useMemo(
    () => [
      {
        id: ACTIVE_TAB,
        label: tabMessages[ACTIVE_TAB],
        values: dataPointCount,
      },
      {
        id: ARCHIVED_TAB,
        label: tabMessages[ARCHIVED_TAB],
        values: dataPointArchivedCount,
      },
    ],
    [dataPointCount, dataPointArchivedCount],
  );

  const mainClassName = classnames('datatron--position');

  return (
    <div className={mainClassName}>
      <DeviceHeader device={device} isOpen={isOpen} toggle={toggle} />
      <DeviceDetails device={device} deviceType={deviceType} />

      {isOpen && (
        <>
          <AddDataPointButton
            onClick={openNewDataPointModal}
            disabled={tableTab === ARCHIVED_TAB}
          />

          <TabSelector tabList={tabList} selectedTab={tableTab} selectTab={tableTabSet} />

          <DataPointsTable
            dataPoints={showArchived ? archivedDataPoints : dataPoints}
            deviceId={deviceId}
            deviceType={deviceType}
            configKeyHashToFieldsMap={device.configKeyHashToFieldsMap || {}}
            showArchived={showArchived}
            openViewDataPointModal={openViewDataPointModal}
            openEditDataPointModal={openEditDataPointModal}
            openArchiveDataPointModal={openArchiveDataPointModal}
            openEditArrayIndexModal={openEditArrayIndexModal}
          />
        </>
      )}
    </div>
  );
};

export default Device;
