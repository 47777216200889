import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReportModuleContainer from './ReportModule.container';
import { createReport } from '../../../../redux/modules/report.create.module';
import { getValuesFromReport } from '../../../../core/common/report.form';
import { closeModal } from '../../../../redux/modules/modals.module';
import { REPORT_ADD } from '../../../../redux/constants/modals.constants';

export const mapStateToProps = () => ({
  isAddForm: true,
  initialValues: getValuesFromReport(),
});

export const mapDispatchToProps = (dispatch) => ({
  createReport: bindActionCreators(createReport, dispatch),
  close: () => dispatch(closeModal(REPORT_ADD)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportModuleContainer);
