import { connect } from 'react-redux';

import PathButtonContainer from '../../../../../_tools/pathButton/PathButton.container';
import { getPreSavedPathNames } from '../../../../../../redux/selectors/pathButton.selector';
import { ADMIN_USER_FORM } from '../../../../../../redux/constants/pathButton.constants';

export const mapStateToProps = (state) => ({
  tempLocationPath: getPreSavedPathNames(state, ADMIN_USER_FORM),
});

export default connect(mapStateToProps)(PathButtonContainer);
