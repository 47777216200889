import { createAction } from 'redux-act';
import { touch, startSubmit, stopSubmit } from 'redux-form';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import { replaceObjectInList, compareById } from '../../../../common/helpers/object';
import * as form from '../constants/report.form.constants';
import * as selector from '../selectors/report.form.selector';
import * as api from '../../core/api';
import { initializeReport } from '../../core/common/report';
import { getApiPayload } from '../../core/common/report.form';
import { closeModal } from './modals.module';
import { REPORT_EDIT } from '../constants/modals.constants';
import { validateDates } from '../../core/validation/reports.validation';

export const updateReport = createAction(
  'update report',
  (id) => id,
);

export const updateReportSuccess = createAction(
  'update report - success',
  (report) => report,
);

export const updateReportFail = createAction(
  'update report - fail',
  (id, error) => ({ id, error }),
);

export const reducer = {
  [updateReportSuccess]: (state, report) => ({
    ...state,
    reports: {
      ...state.reports,
      data: replaceObjectInList(
        state.reports.data,
        report,
        compareById,
      ),
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.REPORT_FORM_NAME,
    ...form.REPORT_PROPS,
  ));
}

export function* updateReportSaga({ payload: reportId }) {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const fields = yield call(selector.getFormFields, state);
  yield put(startSubmit(form.REPORT_FORM_NAME));

  const preValidationErrors = yield call(validateDates, fields);
  if (!isEmpty(preValidationErrors)) {
    yield put(stopSubmit(form.REPORT_FORM_NAME, preValidationErrors));
    yield put(updateReportFail(reportId, preValidationErrors));
    return;
  }

  const apiCallBody = yield call(getApiPayload, fields);

  const { response, error } = yield call(api.reports.update, reportId, apiCallBody);

  if (response) {
    yield put(stopSubmit(form.REPORT_FORM_NAME));
    yield put(updateReportSuccess(initializeReport(response)));
    yield put(closeModal(REPORT_EDIT));
  } else {
    yield put(stopSubmit(form.REPORT_FORM_NAME, error));
    yield put(updateReportFail(reportId, error));
  }
}

export function* watchUpdateReport() {
  yield takeEvery(updateReport.getType(), updateReportSaga);
}
