import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Todo } from '../../../../../common/types/common';

interface Props {
  error: string | {
    id: string,
    values?: Todo
  },
}

export const InputError: React.FC<Props> = ({ error = '' }) => {
  const isFormattedMessage = typeof error === 'object';

  const id = isFormattedMessage ? error.id : error;
  const values = isFormattedMessage ? error.values : {};

  return (
    <small className="error">
      {
        id
        && (
          <FormattedMessage
            id={id}
            values={values}
          />
        )
      }
    </small>
  );
};

export default InputError;