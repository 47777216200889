import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RemoveOrganizationModal from './RemoveOrganizationModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { adminRemoveOrganization } from '../../../../redux/modules/admin.organization.remove.module';
import { adminResetOrganization } from '../../../../redux/modules/admin.organization.fetch.module';
import { AP_ORGANIZATION_REMOVE } from '../../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { getRemoveError } from '../../../../redux/selectors/admin.organizations.selector';

export const mapStateToProps = (state) => ({
  ...getModalPayload(state, AP_ORGANIZATION_REMOVE),
  error: getRemoveError(state),
});

export const mapDispatchToProps = (dispatch) => ({
  close: () => {
    dispatch(closeModal(AP_ORGANIZATION_REMOVE));
    dispatch(adminResetOrganization());
  },
  remove: bindActionCreators(adminRemoveOrganization, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveOrganizationModal);
