import React from 'react';
import { FormattedMessage } from 'react-intl';

export const RightBarPlaceholderSuccess = () => (
  <div className="locations-empty">
    <div>
      <div className="locations-empty-icon">
        <object data="/img/location.svg" type="image/svg+xml" />
      </div>
      <p>
        <strong>
          <FormattedMessage
            id="configRepo.explorer.placeholder.you_are_all_set"
            defaultMessage="You're all set!"
          />
        </strong>
      </p>
    </div>
  </div>
);

export default RightBarPlaceholderSuccess;
