import moment from 'moment';
import { Todo } from '../../../../common/types/common';
import { ReportT } from '../../../types/report';

const today = moment();
today.set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});

export interface ReportsState {
  data: ReportT[];
  newReport: {
    loading: boolean;
    created: boolean;
  };
  loading: boolean;
  loaded: boolean;
  error: string | null;
  sliderSelection: Todo;
  chartTypes: Todo;
  tabs: {
    list: Todo[];
    loaded: boolean;
    loading: boolean;
    error: Todo | null;
    selectedTabId: string | null;
  };
}

export const reportsInitialState: ReportsState = {
  data: [],
  newReport: {
    loading: false,
    created: false,
  },
  loading: false,
  loaded: false,
  error: '',
  sliderSelection: {},
  chartTypes: {},
  tabs: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    selectedTabId: null,
  },
};

export const reportsTestState: ReportsState = {
  data: [
    {
      id: 'abcdefg',
      title: 'Last 3 days',
      datesMode: 'ABSOLUTE',
      type: 'kpi',
      total: null,
      from: moment(today).subtract(3, 'days'),
      to: moment(today),
      filters: {
        locations: [], machines: [{ id: 1, name: 'machine 1' }], organizations: [], tags: [],
      },
      data: [
        {
          a: 0.6,
          p: 0.5,
          q: 0.4,
          timestamp: moment(new Date(2013, 2, 1, 1, 10)),
        },
        {
          a: 0.8,
          p: 0.9,
          q: 0.7,
          timestamp: moment(new Date(2013, 2, 1, 2, 71)),
        },
        {
          a: 0.5,
          p: 0.6,
          q: 0.4,
          timestamp: moment(new Date(2013, 2, 1, 3, 72)),
        },
      ],
      reportsTab: {
        id: '123',
        name: 'test',
      },
    },
  ],
  newReport: {
    loading: false,
    created: false,
  },
  loading: false,
  loaded: false,
  error: '',
  sliderSelection: {},
  chartTypes: {},
  tabs: {
    list: [
      { id: '123', name: 'test' },
    ],
    loaded: true,
    loading: false,
    error: null,
    selectedTabId: '123',
  },
};
