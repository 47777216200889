import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Name = ({ label, link }) => (
  <td className="td-title machine-label">
    <Link
      to={link}
      className="links"
    >
      <span className="icon icon-cog mt0" />
      <span className="links-text">{ label }</span>
    </Link>
  </td>
);

Name.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Name;
