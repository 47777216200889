import { createAction } from 'redux-act';
import { touch, startSubmit, stopSubmit } from 'redux-form';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as form from '../constants/report.legend.dataPoint.form.constants';
import * as selector from '../selectors/report.legend.dataPoint.form.selector';
import * as reportsSelector from '../selectors/reports.selector';
import * as api from '../../core/api';
import { initializeReport } from '../../core/common/report';
import { REPORT_LEGEND_DATA_POINT } from '../constants/modals.constants';
import { updateReportSuccess } from './report.update.module';
import { closeModal } from './modals.module';
import { DATA_POINTS } from '../../../../common/constants/entities';

export const updateLegendDataPoint = createAction(
  'update report legend data point',
  (reportId) => reportId,
);

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.REPORT_LEGEND_DATA_POINT_FORM_NAME,
    ...form.LEGEND_DATA_POINT_PROPS,
  ));
}

export function* updateLegendDataPointSaga({ payload: reportId }) {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const fields = yield call(selector.getFormFields, state);
  yield put(startSubmit(form.REPORT_LEGEND_DATA_POINT_FORM_NAME));

  const reports = yield call(reportsSelector.selectAllReports, state);
  const report = yield call(reportsSelector.getReportById, reportId, reports);
  if (!report) return;

  const apiCallBody = {
    name: report.name,
    from: report.from,
    to: report.to,
    type: report.type,
    interval: report.interval,
    filters: {
      ...report.filters,
      [DATA_POINTS]: report.filters[DATA_POINTS].reduce((result, filter) => ([
        ...result,
        filter.dataPoint.id === fields[form.ID_FIELD] ?
          fields : { ...filter, id: filter.dataPoint.id, dataPoint: undefined },
      ]), []),
    },
  };

  const { response, error } = yield call(api.reports.update, reportId, apiCallBody);

  if (response) {
    yield put(stopSubmit(form.REPORT_LEGEND_DATA_POINT_FORM_NAME));
    yield put(updateReportSuccess({
      ...initializeReport(response),
      disabledProps: report.disabledProps || [],
    }));
    yield put(closeModal(REPORT_LEGEND_DATA_POINT));
  } else {
    yield put(stopSubmit(form.REPORT_LEGEND_DATA_POINT_FORM_NAME, error));
  }
}

export function* watchUpdateLegendDataPoint() {
  yield takeEvery(updateLegendDataPoint.getType(), updateLegendDataPointSaga);
}
