import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3-selection';
import { axisBottom } from 'd3-axis';
import { injectIntl } from 'react-intl';

export const getDateFormat = (intl) => (value) => intl.formatDate(value, {
  month: 'short',
  day: '2-digit',
});

export class AxisBottom extends Component {
  componentDidMount() {
    this.renderAxis(this.props);
  }

  shouldComponentUpdate(props) {
    this.renderAxis(props);
    return false;
  }

  renderAxis(props) {
    const { scale, intl, height } = props;
    const axis = axisBottom(scale)
      .tickSize(-height)
      .tickPadding(10)
      .tickFormat(getDateFormat(intl));

    select(this.ticks)
      .call(axis);
  }

  render() {
    return (
      <g className="slider__axis">
        <g ref={(r) => (this.ticks = r)} />
      </g>
    );
  }
}

AxisBottom.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  scale: PropTypes.func.isRequired,

  height: PropTypes.number.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

export default injectIntl(AxisBottom);
