
type ExpandedSensors = {
  [sensorId: string]: boolean;
};

export type NewMachineState = {
  creating: boolean;
  error: string;
  tab: string;
  sensors: { [key: string]: unknown }[];
  expandedSensors: ExpandedSensors;
  isLocationModalOpen: boolean;
};

export const newMachineTestState: NewMachineState = {
  creating: false,
  error: '',
  tab: 'equipment',
  sensors: [],
  expandedSensors: {},
  isLocationModalOpen: true,
};

export const newMachineInitialState: NewMachineState = {
  creating: false,
  error: '',
  tab: 'equipment',
  sensors: [],
  expandedSensors: {},
  isLocationModalOpen: false,
};
