import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function Footer({
  canRemoveCalendar, onCancel, onRemove,
}) {
  return (
    <div className="text-center">
      <button type="button" className="button__cancel" onClick={onCancel}>
        <FormattedMessage
          id="common.cancel"
          defaultMessage="Cancel"
        />
      </button>
      {
        canRemoveCalendar && (
          <button type="button" className="button__remove" onClick={onRemove}>
            <FormattedMessage
              id="common.remove"
              defaultMessage="Remove"
            />
          </button>
        )
      }
    </div>
  );
}

Footer.propTypes = {
  canRemoveCalendar: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Footer;
