import { defineMessages } from 'react-intl';

import * as measurementStatuses from '../../../common/enums/measurementStatuses';

export default defineMessages({
  [measurementStatuses.IN_PROGRESS]: {
    id: 'experimentsPage.measurements.status.in_progress',
    defaultMessage: 'In Progress',
  },
  [measurementStatuses.COMPLETED]: {
    id: 'experimentsPage.measurements.status.completed',
    defaultMessage: 'Completed',
  },
  [measurementStatuses.MERGED]: {
    id: 'experimentsPage.measurements.status.merged',
    defaultMessage: 'Merged',
  },
  [measurementStatuses.ARCHIVED]: {
    id: 'experimentsPage.measurements.status.archived',
    defaultMessage: 'Archived',
  },
});
