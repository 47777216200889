import { connect } from 'react-redux';

import Assistance from './Assistance';
import { isModalOpen } from '../../../redux/selectors/modals.selector';
import { getRemoveAssistantModalId } from '../../../core/modals/assistance.modals';
import { ASSISTANT_ADD_NEW } from '../../../redux/constants/modals.constants';
import { openModal } from '../../../redux/modules/modals.module';

export const mapStateToProps = (state, { inventoryType }) => ({
  isNewAssistantModalOpen: isModalOpen(state, ASSISTANT_ADD_NEW),
  isRemoveAssistantModalOpen: isModalOpen(state, getRemoveAssistantModalId(inventoryType)),
});

export const mapDispatchToProps = (dispatch, { inventoryType, target }) => ({
  handleRemoveAssistantClick: (assistant) => {
    const modalId = getRemoveAssistantModalId(inventoryType);
    dispatch(openModal(modalId, { assistant, inventoryType, target }));
  },
  handleAddNewAssistantClick: () => {
    dispatch(openModal(ASSISTANT_ADD_NEW, { inventoryType, target }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Assistance);
