import React from 'react';
import { FormattedMessage } from 'react-intl';

function PoweredBy() {
  return (
    <div className="powered-by--wrap">
      <FormattedMessage
        defaultMessage="Powered by"
        id="common.powered_by"
      />
      &nbsp;
      <a
        href="https://www.datatroniq.com"
        className="powered-by"
        target="_blank"
        rel="noopener noreferrer"
      >
        Datatroniq
      </a>
    </div>
  );
}
export default PoweredBy;
