import React from 'react';
import { isArray } from 'lodash';
import permissionsList from '../../../../../common/permissions/permissionsList';
import { AppConfigState } from '../../../redux/state/appConfig.state';
import { Permission } from '../../../../../common/constants/permissions';

type Props = {
  roles: string[];
  config: AppConfigState;
};

const PermissionedComponent =
  (types: Permission | Permission[]) => (WrappedComponent) => {
    class Wrapper extends React.Component<Props> {
      state = { permissions: {} };

      constructor(props) {
        super(props);
        this.state.permissions = {};
        this.setupPermissions = this.setupPermissions.bind(this);
        this.setupPermissions(props.roles, this.props.config);
      }

      componentDidMount() {
        this.setupPermissions(this.props.roles, this.props.config);
      }

      componentDidUpdate() {
        this.setupPermissions(this.props.roles, this.props.config);
      }

      setupPermissions(roles, config) {
        let permissions = {};

        if (isArray(types)) {
          types.forEach((type) => {
            permissions[type] = new permissionsList[type](roles, config);
          });
        } else {
          permissions = new permissionsList[types](roles, config);
        }

        this.state.permissions = permissions;
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            permissions={this.state.permissions}
          />
        );
      }
    }

    return Wrapper;
  };

export default PermissionedComponent;
