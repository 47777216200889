import { createAction } from '@reduxjs/toolkit';

import {
  compareById,
  replaceObjectInList,
} from '../../../../common/helpers/object';
import { getDeviceById } from '../selectors/datatron.selector';

export const updateActiveStatusOfDevice = createAction(
  'update active status of datatron device',
  (deviceId: string, newActive: boolean) => ({
    payload: { deviceId, newActive },
  }),
);

export const reducer = {
  [updateActiveStatusOfDevice.type]: (state, { deviceId, newActive }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;
    const newDevice = { ...device, active: newActive };
    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById,
          ),
        },
      },
    };
  },
};
