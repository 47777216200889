import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReportModuleContainer from './ReportModule.container';
import { getValuesFromReport } from '../../../../core/common/report.form';
import { updateReport } from '../../../../redux/modules/report.update.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { REPORT_EDIT } from '../../../../redux/constants/modals.constants';

export const mapStateToProps = (state) => {
  const { report } = getModalPayload(state, REPORT_EDIT);

  return {
    isEditForm: true,
    reportId: report.id,
    initialValues: getValuesFromReport(report),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  updateReport: bindActionCreators(updateReport, dispatch),
  close: () => dispatch(closeModal(REPORT_EDIT)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportModuleContainer);
