import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ToolStateFilterItem = ({
  selected, handleChange, label, id,
}) => {
  const handleFilterChange = () => handleChange(id, selected);

  return (
    <li>
      <label className="checkbox">
        <input
          type="checkbox"
          onChange={handleFilterChange}
          checked={selected}
        />
        <FormattedMessage {...label} tagName="span" />
      </label>
    </li>
  );
};

ToolStateFilterItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default ToolStateFilterItem;
