import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import commonMessages from '../../../../messages/common.messages';
import { Button } from '../../../_tools';

const Footer = ({
  onCancelClick,
  onRemoveClick,
  isRemoveButtonDisabled,
  isRemoveButtonLoading,
}) => (
  <div className="modal-buttons">
    <Button
      type="button"
      className="button__cancel"
      onClick={onCancelClick}
    >
      <FormattedMessage {...commonMessages.cancel} />
    </Button>
    <Button
      type="submit"
      className="button__remove"
      onClick={onRemoveClick}
      disabled={isRemoveButtonDisabled}
      showLoader={isRemoveButtonLoading}
    >
      <FormattedMessage {...commonMessages.remove} />
    </Button>
  </div>
);

Footer.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  isRemoveButtonDisabled: PropTypes.bool,
  isRemoveButtonLoading: PropTypes.bool,
};

Footer.defaultProps = {
  isRemoveButtonDisabled: false,
  isRemoveButtonLoading: false,
};

export default Footer;
