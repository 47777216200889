import { connect } from 'react-redux';

import * as selector from '../../redux/selectors/machine.selector';
import { Machine } from './Machine';
import { DEFAULT_TAB } from '../../redux/constants/machine.tabs.constants';
import { selectTab } from '../../redux/modules/machine.tabs.module';

export const mapStateToProps = (state) => ({
  machine: selector.machine(state),
  isLoading: selector.isLoading(state),
  resetSelectedTab: selector.resetSelectedTab(state),
  selectedTab: selector.selectedTab(state),
});

export const mapDispatchToProps = {
  selectTab,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  initTab: () => {
    if (stateProps.resetSelectedTab) {
      return dispatchProps.selectTab(DEFAULT_TAB);
    }

    return dispatchProps.selectTab(stateProps.selectedTab);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Machine);
