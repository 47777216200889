import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { RouteComponentProps } from 'react-router';
import DetailsContainer from './details/Details';
import Card from './card/Card.container.js';
import Loader from '../_tools/loader/Loader';
import { LocationBreadcrumbs } from '../_tools/locationBreadcrumbs/LocationBreadcrumbs';
import * as routeHandlers from '../index/routeHandlers';
import { Todo, TodoFunction } from '../../../../common/types/common';
import { renderRoutes, RouteConfig } from 'react-router-config';

interface MachineProps extends RouteComponentProps {
  machine: Todo;
  isLoading: boolean;
  initTab: TodoFunction;
  route: RouteConfig;
}

export const Machine: React.FC<MachineProps> = ({ match, machine, isLoading, initTab, route }) => {
  // TODO: fix me, avoid suppressing the linter
  useEffect(() => {
    routeHandlers.onMachineEnter(match);
    initTab();
    return () => {
      routeHandlers.onMachineLeave();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!match.isExact) {
    return renderRoutes(route?.routes ?? []);
  }

  return (
    <div className='machine-page col--1'>
      <div className='subheader'>
        <div className='subheader-in'>
          <div className='subheader--left'>
            <LocationBreadcrumbs />
          </div>
          <div className='subheader--right' />
        </div>
      </div>
      <div className='col--2--wrap'>
        <div className='col--2'>
          <div className='col--2--left'>
            {!isEmpty(machine) && <Card machine={machine} canOpenDetails={false} />}
          </div>
          <div className='col--2--right'>{!isEmpty(machine) && <DetailsContainer />}</div>
        </div>
      </div>
      <Loader show={isLoading} fullHeight solidBackground />
    </div>
  );
};

export default Machine;
