import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const RENDER_TIMEOUT = 10000;

export class LiveTimeOffset extends Component {
  static propTypes = {
    timestamp: PropTypes.string.isRequired,
    secondsOffset: PropTypes.number,
  };

  static defaultProps = {
    secondsOffset: 0,
  };

  state = { timestamp: null };

  componentDidMount = () => {
    this.updateTimestamp();
    this.timerId = setInterval(
      () => this.updateTimestamp(),
      RENDER_TIMEOUT,
    );
  };

  componentDidUpdate = () => {
    if (!this.timerId) {
      this.updateTimestamp();
      this.timerId = setInterval(
        () => this.updateTimestamp(),
        RENDER_TIMEOUT,
      );
    }
  };

  componentWillUnmount = () => {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  };

  updateTimestamp = () => {
    const { timestamp, secondsOffset } = this.props;

    const ts = moment(timestamp);
    if (secondsOffset > 0) {
      ts.subtract(secondsOffset, 'seconds');
    }

    this.setState({ count: moment().from(ts, true) });
  };

  render = () => {
    const { count } = this.state;

    return (
      <span>
        { count }
      </span>
    );
  };
}

export default LiveTimeOffset;
