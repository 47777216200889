import { connect } from 'react-redux';

import FiltersBar from './FiltersBar';
import { clearFilters } from '../../../redux/modules/dashboard.events.filter.module';
import { showHideCalendar } from '../../../redux/modules/dashboard.calendar.module.js';
import {
  getFiltersCount, dateFilters, itemFilters, categoryFilters, areDatesSelected,
} from '../../../redux/selectors/dashboard.selector';
import { filterByDate } from '../../../redux/modules/dashboard.events.filter.dates.module';

export function mapStateToProps(state) {
  return {
    filtersCount: getFiltersCount(state),
    dateFilters: dateFilters(state),
    filters: {
      category: categoryFilters(state),
      ...itemFilters(state),
    },
    dateFiltersSelected: areDatesSelected(state),
  };
}

export const mapDispatchToProps = {
  clearFilters,
  showHideCalendar,
  filterByDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersBar);
