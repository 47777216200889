import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FormTabsItem = ({ isActive, label, onClick }) => (
  <div className={cn('big-tab', { active: isActive })} onClick={onClick}>
    <div className="big-tab-wrap">
      <div className="big-tab-in">
        {label}
      </div>
    </div>
  </div>
);

FormTabsItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FormTabsItem;
