import React from 'react';
import { DisplayTable } from './DisplayTable';
import { FormattedMessage } from 'react-intl';

export interface EntryDisplayBulk {
  identifier: string;
  action: string;
  uuid: string;
}

type UpdateBulkResponse = Readonly<{
  completed: EntryDisplayBulk[];
  failed: EntryDisplayBulk[];
}>;

export type BulkOperationSummaryProps = {
  updateBulkResponse: UpdateBulkResponse;
};

export const BulkOperationSummary: React.FC<BulkOperationSummaryProps> = ({
  updateBulkResponse: { completed, failed },
}) => {
  if (completed.length === 0 && failed.length === 0) {
    return (
      <p className='info-message'>
        <FormattedMessage
          id='datatron.modals.status.no_data_effected'
          defaultMessage='No data has been affected.'
        />
      </p>
    );
  }

  return (
    <div className='data-point-modal-display-container'>
      {completed.length > 0 && (
        <DisplayTable key={'completed'} result={completed} responseType={'completed'} />
      )}
      {failed.length > 0 && <DisplayTable key={'failed'} result={failed} responseType={'failed'} />}
    </div>
  );
};
