import React from 'react';

import ReportAddButtonContainer from './ReportAddButton.container';
import { LocationBreadcrumbs } from '../_tools/locationBreadcrumbs/LocationBreadcrumbs';

const ReportsHeader = () => (
  <div className="subheader">
    <div className="subheader-in">
      <div className="subheader--left">
        <LocationBreadcrumbs />
      </div>
      <div className="subheader--right">
        <ReportAddButtonContainer />
      </div>
    </div>
  </div>
);

export default ReportsHeader;
