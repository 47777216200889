import { connect } from 'react-redux';
import { last, isUndefined } from 'lodash';

import LocationPickerModal from '../../../../../_tools/locationPicker/LocationPickerModal.container';
import { ADMIN_LOCATION_FORM } from '../../../../../../redux/constants/pathButton.constants';
import { getPreSavedPath } from '../../../../../../redux/selectors/pathButton.selector';

export const mapStateToProps = (state, { location }) => {
  const preSavedPath = getPreSavedPath(state, ADMIN_LOCATION_FORM);

  const preSavedLocationId = (preSavedPath && preSavedPath.length) ? last(preSavedPath).id : null;
  const initLocationId = (location && location.id) ? location.id : null;

  return {
    isFormField: true,
    initLocationId: isUndefined(preSavedPath) ? initLocationId : preSavedLocationId,
    pathButtonId: ADMIN_LOCATION_FORM,
  };
};

export default connect(mapStateToProps)(LocationPickerModal);
