import { connect } from 'react-redux';

import Select from './Select';
import * as selector from '../../../../redux/selectors/calendar.selector';
import { initializeCalendarAsOption } from '../../../../core/common/calendar';
import { SCHEDULE_CALENDAR } from '../../../../../../common/enums/calendarTypes';

export const mapStateToProps = (state, { input }) => ({
  options: selector
    .getCalendarsByType(state, SCHEDULE_CALENDAR)
    .map(initializeCalendarAsOption),
  onChange: (option) => input.onChange(option),
});

export default connect(mapStateToProps)(Select);
