import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PureInput from '../PureInput';
import InputError from '../inputError/InputError';

export default class InputGroup extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    field: PropTypes.object.isRequired,
    input: PropTypes.object,
    labelClassName: PropTypes.string.isRequired,
    inputClassName: PropTypes.string.isRequired,
    errorClassName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    labelClassName: 'small-2',
    inputClassName: 'small-2',
    errorClassName: 'small-2',
  };

  render() {
    const {
      label, id, placeholder, input, field,
      labelClassName, inputClassName, errorClassName,
    } = this.props;

    return (
      <div className="row">
        <div className={classNames('columns', labelClassName)}>
          <label htmlFor={id} className="text-right middle">{label}</label>
        </div>
        <div className={classNames('columns', inputClassName)}>
          <PureInput type="text" id={id} placeholder={placeholder} field={field} {...input} />
        </div>
        <div className={classNames('columns', errorClassName)}>
          <label htmlFor={id} className="text-right middle">
            <InputError touched={field.touched} message={field.error} />
          </label>
        </div>

      </div>
    );
  }
}
