import { DATA_POINT_ARRAY_COMMON_FIELDS } from '../../../../core/common/dataPoint.constants';
import React from 'react';
import { DataPointFormField } from './DataPointFormField';
import labels from '../../../../messages/dynamicField.messages';
import { useIntl } from 'react-intl';

interface Props {
  dataPoint;
}

export const ReadOnlyDataPoint: React.FC<Props> = ({ dataPoint }) => {
  const { formatMessage } = useIntl();

  const fieldsToExclude = ['label', 'arrayDimensions', 'comment'];

  const commonFields = DATA_POINT_ARRAY_COMMON_FIELDS.filter(
    (field) => fieldsToExclude.indexOf(field._key) < 0,
  ).map((field) => ({
    key: field._key,
    value: dataPoint[field._key],
    type: field.type || 'string',
    label: formatMessage(labels[field._key]),
  }));

  const configFields = Object.keys(dataPoint.config)
    .filter((field) => fieldsToExclude.indexOf(field) < 0)
    .map((current) => ({
      key: current,
      value: dataPoint.config[current]?.name || dataPoint.config[current],
      type: 'string',
      label: formatMessage(labels[current]),
      readOnly: true,
    }));

  return (
    <>
      {[...commonFields, ...configFields]
        .sort((a, b) => a.key.localeCompare(b.key))
        .map((field) => (
          <DataPointFormField
            key={field.key}
            onChange={() => {}}
            field={field}
          />
        ))}
    </>
  );
};
