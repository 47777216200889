import { createAction } from 'redux-act';

import { EMPTY_CALENDAR_QUERIES } from '../constants/calendar.constants.js';

export const resetFilterQueries = createAction(
  'update machine calendar filter query',
);

export const updateFilterQuery = createAction(
  'update machine calendar filter query',
  (type, query) => ({ type, query }),
);

export const reducer = {
  [resetFilterQueries]: (state) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      filters: {
        ...EMPTY_CALENDAR_QUERIES,
      },
    },
  }),
  [updateFilterQuery]: (state, { type, query }) => ({
    ...state,
    machineCalendars: {
      ...state.machineCalendars,
      filters: {
        ...state.machineCalendars.filters,
        [type]: query,
      },
    },
  }),
};
