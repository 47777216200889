import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as eventsApi from '../../core/api/events.js';

export const fetchEventsByMachine = createAction(
  'fetch all events by machine',
  (machineSerialNumber) => machineSerialNumber,
);

export const fetchEventsByMachineSuccess = createAction(
  'fetch all events by machine successfully',
  (events) => events,
);

export const fetchEventsByMachineFail = createAction(
  'fetch all events by machine fail',
  (error) => error,
);

export const reducer = {
  [fetchEventsByMachine]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      eventsState: {
        loaded: false,
        loading: true,
        error: '',
      },
    },
  }),

  [fetchEventsByMachineSuccess]: (state, events) => ({
    ...state,
    machine: {
      ...state.machine,
      events,
      eventsState: {
        ...state.machine.eventsState,
        loaded: true,
        loading: false,
      },
    },
  }),

  [fetchEventsByMachineFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      eventsState: {
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchEventsByMachineSaga({ payload: machineSerialNumber }) {
  const { response, error } = yield call(eventsApi.getEventsByMachine, machineSerialNumber);

  if (response) {
    yield put(fetchEventsByMachineSuccess(response));
  } else {
    yield put(fetchEventsByMachineFail(error));
  }
}

export function* watchFetchEventsByMachine() {
  yield takeEvery(fetchEventsByMachine.getType(), fetchEventsByMachineSaga);
}
