import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ViewToolModal from './ViewToolModal';
import { closeModal, openModal } from '../../../../redux/modules/modals.module';
import { createTool } from '../../../../redux/modules/tools.create.module';
import { TOOL_EDIT, TOOL_DETAILS } from '../../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';

export const mapStateToProps = (state) => getModalPayload(state, TOOL_DETAILS);

export const mapDispatchToProps = (dispatch) => ({
  create: bindActionCreators(createTool, dispatch),
  switchToEditMode: (tool) => {
    dispatch(closeModal(TOOL_DETAILS));
    dispatch(openModal(TOOL_EDIT, { tool }));
  },
  close: () => dispatch(closeModal(TOOL_DETAILS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewToolModal);
