import React from 'react';
import PropTypes from 'prop-types';

import TableHeader from './TableHeader';
import TableItem from './TableItem';
import EmptyPlaceholder from './EmptyPlaceholder';

export const HistoryComponent = ({
  logs,
}) => (
  <div className="modal-tabs-table">
    <table className="table table-link">
      <TableHeader />
      <tbody>
        { logs.map((item, index) => (
          <TableItem
            key={item.id}
            item={item}
            isLatestItem={index === 0}
          />
        )) }
      </tbody>
    </table>
    { !logs.length && <EmptyPlaceholder /> }
  </div>
);

HistoryComponent.propTypes = {
  logs: PropTypes.array.isRequired,
};

export default HistoryComponent;
