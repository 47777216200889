import { forEach, last } from 'lodash';

const CONFIG_PREFIX = 'config.';

export const convertErrors = (errors) => {
  const newErrors = {};

  forEach(
    errors,
    (value, key) => {
      if (key.substring(0, CONFIG_PREFIX.length) === CONFIG_PREFIX) {
        const subkeys = key.replace(CONFIG_PREFIX, '').split('.');
        newErrors[last(subkeys)] = value;
      } else {
        newErrors[key] = value;
      }
    },
  );

  return newErrors;
};
