import { connect } from 'react-redux';

import { openModal } from '../../../redux/modules/modals.module';
import { TOOL_ADD } from '../../../redux/constants/modals.constants';
import ToolAddButton from './ToolAddButton';
import PermissionedComponent from '../../common/permissions/PermissionedComponent.container';
import { TOOLS_PERMISSIONS } from '../../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => ({
  canAddTool: permissions.canAddTool(),
});

export const mapDispatchToProps = (dispatch) => ({
  openModal: () => dispatch(openModal(TOOL_ADD)),
});

export default PermissionedComponent(TOOLS_PERMISSIONS)(connect(mapStateToProps, mapDispatchToProps)(ToolAddButton));
