import { createAction } from 'redux-act';
import { push } from 'connected-react-router';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';

import * as api from '../../core/api';
import * as form from '../constants/admin.account.form.constants';
import { getFormFields } from '../selectors/admin.account.form.selector';
import { getAdminAccountsLink } from '../../components/index/routes';
import { adminFetchAccounts } from './admin.accounts.fetch.module';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const adminCreateAccount = createAction(
  'admin: create account',
);

export const adminCreateAccountSuccess = createAction(
  'admin: create account - success',
  (item) => item,
);

export const adminCreateAccountFail = createAction(
  'admin: create account - fail',
  (error) => error,
);

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.ADMIN_ACCOUNT_FORM_NAME,
    ...form.ACCOUNT_PROPS,
  ));
}

export function* adminCreateAccountSaga() {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const body = yield call(getFormFields, state);
  yield put(startSubmit(form.ADMIN_ACCOUNT_FORM_NAME));

  const { response, error } = yield call(api.admin.createAccount, body);

  if (response) {
    yield put(adminCreateAccountSuccess(response));
    yield put(stopSubmit(form.ADMIN_ACCOUNT_FORM_NAME));
    yield put(push(getAdminAccountsLink()));
    yield put(adminFetchAccounts());
    yield put(sendNotification({ ...notificationMessages.account_added, values: { name: response.name } }));
  } else {
    yield put(adminCreateAccountFail(error));
    yield put(stopSubmit(form.ADMIN_ACCOUNT_FORM_NAME, error));
  }
}

export function* watchAdminCreateAccount() {
  yield takeEvery(adminCreateAccount.getType(), adminCreateAccountSaga);
}
