import { connect } from 'react-redux';

import {
  CATEGORY_WARNING,
} from '../../../../redux/constants/event.constants';

import CategoryFilter from './CategoryFilter';

export function mapStateToProps(state, { filters, onChange }) {
  return {
    handleChange: onChange,
    selected: filters.indexOf(CATEGORY_WARNING) !== -1,
    label: 'maintenance.stream.warning',
    category: CATEGORY_WARNING,
  };
}

export default connect(mapStateToProps)(CategoryFilter);
