import { connect } from 'react-redux';

import CustomSelectContainer from './CustomSelect.container';
import { selectAppLocales } from '../../../../redux/selectors/appConfig.selector';

export const mapStateToProps = (state) => ({
  options: selectAppLocales(state).map((locale) => ({
    label: { id: `locales.${locale}` },
    value: locale,
  })),
  translateOptionsLabels: true,
});

export default connect(mapStateToProps)(CustomSelectContainer);
