import { createAction } from 'redux-act';
import { change } from 'redux-form';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { uniqBy } from 'lodash';

import * as form from '../constants/report.form.constants';
import * as selector from '../selectors/report.form.selector';
import * as explorerSelector from '../selectors/configRepoExplorer.selector';
import { resetConfigRepoExplorer } from './configRepoExplorer.reset.module';
import { fetchConfigRepoExplorerEntries } from './configRepoExplorer.fetch.module';
import { Y_LEFT_AXIS } from '../../../../common/constants/report.axis';
import { getAvailableColors } from '../../core/common/report.legend.dataPoint.form';
import * as entryTypes from '../constants/configRepoExplorer.entry.types.constants';

export const saveSelectedDataPoint = createAction(
  'save selected data point to form',
);

export const cancelDataPointsSelection = createAction(
  'cancel data points selection on form',
);

export function* saveSelectedDataPointSaga() {
  const state = yield select();

  const selectedDataPoints = yield call(explorerSelector.getSelectedDataPoints, state);
  if (!selectedDataPoints || !selectedDataPoints.length) return;

  const formFields = yield call(selector.getFormFields, state);
  const currentDataPoints = formFields[form.DATA_POINTS_FILTERS_FIELD] || [];

  const reservedColors = currentDataPoints.map((item) => item.color);
  const availableColors = yield call(getAvailableColors, reservedColors);

  const newDataPoints = [
    ...currentDataPoints,
    ...selectedDataPoints.map((item) => ({
      id: item.id,
      name: item.label,
      path: item.path,
      axis: Y_LEFT_AXIS,
      fillOpacity: 0,
      lineWidth: 3,
      color: availableColors.shift(),
    })),
  ];
  const uniqueDataPoints = uniqBy(newDataPoints, (item) => item.id);

  yield put(change(form.REPORT_FORM_NAME, form.DATA_POINTS_FILTERS_FIELD, uniqueDataPoints));
  yield put(resetConfigRepoExplorer());
  yield put(fetchConfigRepoExplorerEntries(entryTypes.DATATRONS));
  yield put(fetchConfigRepoExplorerEntries(entryTypes.MACHINES));
}

export function* watchSaveSelectedDataPoint() {
  yield takeEvery(saveSelectedDataPoint.getType(), saveSelectedDataPointSaga);
}

export function* cancelDataPointsSelectionSaga() {
  yield put(resetConfigRepoExplorer());
  yield put(fetchConfigRepoExplorerEntries(entryTypes.DATATRONS));
  yield put(fetchConfigRepoExplorerEntries(entryTypes.MACHINES));
}

export function* watchCancelDataPointsSelection() {
  yield takeEvery(cancelDataPointsSelection.getType(), cancelDataPointsSelectionSaga);
}
