import { connect } from 'react-redux';

import CardPhoto from './CardPhoto';
import { getDatatronLink } from '../../index/routes';
import { INVENTORY_DATATRONS } from '../../../../../common/constants/inventory';
import PermissionedComponent from '../permissions/PermissionedComponent.container';
import { DATATRONS_PERMISSIONS } from '../../../../../common/constants/permissions';

export const mapStateToProps = (state, { datatron, permissions }) => ({
  inventoryType: INVENTORY_DATATRONS,
  imageUrl: datatron.imagePath,
  detailsLink: getDatatronLink(datatron.id),
  canUpdate: permissions.canUpdateDatatrons(),
  canDelete: !!datatron.imagePath && permissions.canUpdateDatatrons(),
});

export default PermissionedComponent(DATATRONS_PERMISSIONS)(
  connect(mapStateToProps)(CardPhoto),
);
