import React from 'react';
import PropTypes from 'prop-types';
import CategoryLabel from '../../../_tools/categoryLabel/CategoryLabel';

const TailEvent = ({ event }) => (
  <div className="comments--list--item">
    <div className="comments--list--label">
      <CategoryLabel category={event.category} />
    </div>
    <div className="comments--list--text">
      <div className="comments--list--title">
        { event.title }
      </div>
      <div className="comments--list--details">
        { event.details }
      </div>
    </div>
  </div>
);

TailEvent.propTypes = {
  event: PropTypes.object.isRequired,
};

export default TailEvent;
