import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import commonMessages from '../../../messages/common.core.messages';

export class FiltersSearchInput extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,

  };

  static defaultProps = {
    value: '',
  };

  componentDidMount() {
    this.input.focus();
  }

  render() {
    const {
      value, onChange, intl,
    } = this.props;

    return (
      <input
        ref={(input) => { this.input = input; }}
        type="text"
        className="search-input"
        onChange={onChange}
        placeholder={intl.formatMessage(commonMessages.searchPlaceholder)}
        value={value}
      />
    );
  }
}

export default injectIntl(FiltersSearchInput);
