import { defineMessages } from 'react-intl';
import {
  DATA_TAB,
  TOOLS_TAB,
  MACHINE_DETAILS_TAB,
  ASSISTANCE_TAB,
  MAINTENANCE_TAB,
  SCHEDULE_TAB,
  SUMMARY_TAB,
} from '../redux/constants/machine.tabs.constants';

export default defineMessages({
  [DATA_TAB]: {
    id: 'machine.data',
    defaultMessage: 'Data',
  },
  [TOOLS_TAB]: {
    id: 'machine.tools',
    defaultMessage: 'Tools',
  },
  [MACHINE_DETAILS_TAB]: {
    id: 'machine.machineDetails',
    defaultMessage: 'Machine Details',
  },
  [ASSISTANCE_TAB]: {
    id: 'machine.assistance',
    defaultMessage: 'Assistance',
  },
  [MAINTENANCE_TAB]: {
    id: 'machine.maintenance',
    defaultMessage: 'Maintenance',
  },
  [SCHEDULE_TAB]: {
    id: 'machine.shift_&_schedule',
    defaultMessage: 'Shift & Schedule',
  },
  [SUMMARY_TAB]: {
    id: 'machine.updates',
    defaultMessage: 'Updates',
  },
});
