import { reduxForm } from 'redux-form';

import LocationForm from './LocationForm';
import { ADMIN_LOCATION_FORM_NAME } from '../../../../../../redux/constants/admin.location.form.constants';

const form = {
  form: ADMIN_LOCATION_FORM_NAME,
  destroyOnUnmount: true,
};

export default reduxForm(form)(LocationForm);
