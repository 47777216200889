import React from 'react';
import PropTypes from 'prop-types';

import RoleFormBodyContainer from './RoleFormBody.container';
import EditModalFooter from '../../../../../modals/core/footers/EditModalFooter';
import ViewModalFooter from '../../../../../modals/core/footers/ViewModalFooter';

export const RoleForm = ({
  handleCancel,
  handleSubmit,
  submitting,
  isEditForm,
  isViewForm,
  pristine,
}) => (
  <form onSubmit={handleSubmit}>
    <div className="form">
      <RoleFormBodyContainer readOnly={!!isViewForm} />

      <div className="line" />
      { isEditForm && (
      <EditModalFooter
        onCancelClick={handleCancel}
        onSaveClick={handleSubmit}
        isSaveButtonDisabled={pristine || submitting}
      />
      ) }
      { isViewForm && (
      <ViewModalFooter
        onCancelClick={handleCancel}
        onEditClick={handleSubmit}
      />
      ) }
    </div>
  </form>
);

RoleForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isEditForm: PropTypes.bool,
  isViewForm: PropTypes.bool,
};

RoleForm.defaultProps = {
  isEditForm: false,
  isViewForm: false,
};

export default RoleForm;
