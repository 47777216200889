import React from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';

function KpiMotion({
  from, to, children, ...props
}) {
  if (from === to) {
    return React.cloneElement(children, { data: from, ...props });
  }
  return (
    <Motion defaultStyle={{ x: from }} style={{ x: spring(to) }}>
      {
        (value) => React.cloneElement(children, { data: value.x, ...props })
      }
    </Motion>
  );
}

KpiMotion.propTypes = {
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default KpiMotion;
