import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SortHeader from '../../../../_tools/sortHeader/SortHeader';
import NameFilterContainer from './nameFilter/NameFilter.container';
import EmailFilterContainer from './emailFilter/EmailFilter.container';
import {
  SORT_BY_NAME,
  SORT_BY_EMAIL,
} from '../../../../../../../common/constants/admin.users';
import commonCoreMessages from '../../../../../messages/common.core.messages';

const UsersHeader = ({ onSortChange, sortOption, sortWay }) => (
  <thead>
    <tr>
      <td>
        <div className="flex-name">
          <SortHeader
            option={SORT_BY_NAME}
            way={(sortOption === SORT_BY_NAME) ? sortWay : null}
            onClick={onSortChange}
          >
            <FormattedMessage {...commonCoreMessages.name} />
          </SortHeader>
          <div className="rel-wrap">
            <div className="flex">
              <div className="flex-icon">
                <NameFilterContainer />
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="flex-name">
          <SortHeader
            option={SORT_BY_EMAIL}
            way={(sortOption === SORT_BY_EMAIL) ? sortWay : null}
            onClick={onSortChange}
          >
            <FormattedMessage {...commonCoreMessages.email} />
          </SortHeader>
          <div className="rel-wrap">
            <div className="flex">
              <div className="flex-icon">
                <EmailFilterContainer />
              </div>
            </div>
          </div>
        </div>
      </td>
      <FormattedMessage {...commonCoreMessages.organizations} tagName="td" />
      <td className="td-actions">
        <FormattedMessage {...commonCoreMessages.actions} />
      </td>
    </tr>
  </thead>
);

UsersHeader.propTypes = {
  sortOption: PropTypes.string,
  sortWay: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
};

export default UsersHeader;
