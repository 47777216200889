import React from 'react';

import DataAddButton from './DataAddButton';

export const DataAddButtonTopBar = (props) => (
  <div className="new_data">
    <DataAddButton {...props} />
  </div>
);

export default DataAddButtonTopBar;
