import { Role, AI_APPS_MANAGER, AI_APPS_VIEWER } from '../constants/permissions';

class AiAppsPermissions {
  private isAiAppsManager: boolean;

  private isAiAppsViewer: boolean;

  constructor(roles: Role[]) {
    this.isAiAppsManager = roles.indexOf(AI_APPS_MANAGER) !== -1;
    this.isAiAppsViewer = roles.indexOf(AI_APPS_VIEWER) !== -1;
  }

  canManageAiApps() {
    return this.isAiAppsManager;
  }

  canAddAiApp() {
    return this.isAiAppsManager;
  }

  canEditAiApp() {
    return this.isAiAppsManager;
  }

  canViewAiApps() {
    return this.isAiAppsManager || this.isAiAppsViewer;
  }
}

export default AiAppsPermissions;
