import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import * as reportsApi from '../../core/api/reports';

import { selectReportIndexById } from '../selectors/reports.selector';
import { closeModal } from './modals.module';
import { REPORT_REMOVE } from '../constants/modals.constants';
import * as form from '../constants/report.remove.form.constants';

export const removeReport = createAction(
  'remove report by id',
  (reportId, empty) => ({ reportId, empty }),
);

export const reducer = {
  [removeReport]: (state, { reportId }) => {
    const index = selectReportIndexById(state, reportId);

    if (index === -1) return state;

    return {
      ...state,
      reports: {
        ...state.reports,
        data: [
          ...state.reports.data.slice(0, index),
          ...state.reports.data.slice(index + 1),
        ],
      },
    };
  },
};

export function* removeReportSaga({ payload: { reportId, empty } }) {
  if (empty) return;
  yield put(startSubmit(form.REMOVE_REPORT_FORM_NAME));

  const { response, error } = yield call(reportsApi.remove, reportId);

  if (response) {
    yield put(closeModal(REPORT_REMOVE));
    yield put(stopSubmit(form.REMOVE_REPORT_FORM_NAME));
  } else {
    yield put(stopSubmit(form.REMOVE_REPORT_FORM_NAME, error));
    console.error('Cannot remove report', error); // eslint-disable-line
  }
}

export function* watchRemoveReport() {
  yield takeEvery(removeReport.getType(), removeReportSaga);
}
