import { createAction } from 'redux-act';
import {
  put, takeLatest, delay,
} from 'redux-saga/effects';
import { search, clear } from './search.module';

import * as appEntities from '../../../../common/constants/entities';

const SEARCH_CATEGORIES = [
  appEntities.MACHINES,
  appEntities.ORGANIZATIONS,
  appEntities.LOCATIONS,
  appEntities.DATATRONS,
];

export const chooseElement = createAction(
  'choose element from search',
  (element) => element,
);

export const changeQuery = createAction(
  'change query for search',
  (query) => query,
);

export function* changeQuerySaga({ payload: query }) {
  yield put(clear());
  yield delay(500);
  yield put(search(query, true, SEARCH_CATEGORIES));
}

export function* watchChangeTopBarQuery() {
  yield takeLatest(changeQuery.getType(), changeQuerySaga);
}
