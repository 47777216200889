import { connect } from 'react-redux';

import ItemFiltersContainer from './ItemFilters.container';
import { TAGS } from '../../../../../common/constants/entities';
import messages from './ItemFilters.messages';

export const mapStateToProps = () => ({
  itemType: TAGS,
  label: messages.dashboard_filter_tags,
});

export default connect(mapStateToProps)(ItemFiltersContainer);
