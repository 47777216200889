import classnames from 'classnames';
import React, { useCallback, useState } from 'react';
import { TodoFunction } from '../../../../../../common/types/common';

interface Props {
  dimensions: number[];
  index: number;
  updateParent: TodoFunction;
}

/**
 * A wrapper of the <input /> component that also does error handling.
 */
export const IndexInput: React.FC<Props> = ({ dimensions, index, updateParent }) => {
  const [error, errorSet] = useState(false);

  /**
   * Updates the index input form state and additionally checks for errors.
   */
  const onChange = useCallback(
    (e) => {
      e.preventDefault();
      const newIndex = parseInt(e.target.value);
      const updateIndex = isNaN(newIndex) ? '' : newIndex;
      errorSet(newIndex < dimensions[0] || newIndex > dimensions[1]);
      updateParent(updateIndex);
    },
    [dimensions, updateParent],
  );

  // Highlight text in red if the index includes an error.
  const outerDivClasses = classnames('dimensionsVerticalCenterFlex', {
    dimensionInnerError: error,
  });

  return (
    <div className={outerDivClasses}>
      <input
        type='number'
        className='dimensionsTextInput'
        value={index ?? dimensions[0]}
        onChange={onChange}
        style={{
          fontSize: '20px',
          height: '30px',
          width: `${index?.toString().length * 15}px`,
        }}
        min={dimensions[0]}
        max={dimensions[1]}
      />
    </div>
  );
};
