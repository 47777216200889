import { createAction } from 'redux-act';

export const addCategoryFilter = createAction(
  'add category filter for machine subscriptions',
  (filter) => filter,
);

export const removeCategoryFilter = createAction(
  'remove category filter for machine subscriptions',
  (filter) => filter,
);

export const reducer = {
  [addCategoryFilter]: (state, filter) => ({
    ...state,
    machine: {
      ...state.machine,
      dataPoints: {
        ...state.machine.dataPoints,
        filters: {
          ...state.machine.dataPoints.filters,
          category: [
            ...state.machine.dataPoints.filters.category,
            filter,
          ],
        },
      },
    },
  }),
  [removeCategoryFilter]: (state, filter) => {
    const selectedCategories = state.machine.dataPoints.filters.category;
    const index = selectedCategories.indexOf(filter);

    if (index === -1) return state;

    return {
      ...state,
      machine: {
        ...state.machine,
        dataPoints: {
          ...state.machine.dataPoints,
          filters: {
            ...state.machine.dataPoints.filters,
            category: [
              ...selectedCategories.slice(0, index),
              ...selectedCategories.slice(index + 1),
            ],
          },
        },
      },
    };
  },
};
