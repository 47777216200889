import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'common.forms.name.placeholder',
    defaultMessage: 'Type name here...',
  },
  type: {
    id: 'common.forms.type.placeholder',
    defaultMessage: 'Type type here...',
  },
  description: {
    id: 'common.forms.description.placeholder',
    defaultMessage: 'Type description here...',
  },
});
