import _ from 'lodash';
import config from '../config/public';

export const getRandomInt = (min, max) => Math.floor((Math.random() * ((max - min) + 1)) + min);

export const sequelizeToPlain = (object) => {
  if (object && object.get && typeof object.get === 'function') {
    return _.cloneDeep(object.get({ plain: true }));
  }
  return _.cloneDeep(object);
};

export const diffInSec = (from, to) => ((to.getTime() - from.getTime()) / 1000);

export const isPreviewEnabled = () => config.features.preview;


export const bytesToMega = (bytes) => {
  const megabytes = (bytes / (1024 * 1024)).toFixed(2);
  return megabytes;
};