import React from 'react';

import * as form from '../../../../../../redux/constants/admin.organization.form.constants';
import commonMessages from '../../../../../../messages/common.messages';
import formsPlaceholdersMessages from '../../../../../../messages/forms.placeholders.messages';
import { FormField } from '../../../../../_tools';

export const OrganizationFormBody = () => (
  <div className="modal-form-in">
    <FormField
      label={commonMessages.name.id}
      name={form.NAME_FIELD}
      placeholder={formsPlaceholdersMessages.name.id}
      autoFocus
    />
  </div>
);

export default OrganizationFormBody;
