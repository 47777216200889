import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';
import { initializeLocation } from '../../core/common/admin.state';

export const fetchAdminLocation = createAction(
  'admin: fetch location',
  (id) => id,
);

export const fetchAdminLocationSuccess = createAction(
  'admin: fetch location - success',
  (item) => item,
);

export const fetchAdminLocationFail = createAction(
  'admin: fetch location - fail',
  (error) => error,
);

export const adminLocationReset = createAction(
  'admin: reset location',
);

export const reducer = {
  [fetchAdminLocation]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        selected: {
          ...state.admin.locations.selected,
          item: null,
          loaded: false,
          loading: true,
          error: null,
        },
      },
    },
  }),

  [fetchAdminLocationSuccess]: (state, item) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        selected: {
          ...state.admin.locations.selected,
          item,
          loaded: true,
          loading: false,
          error: null,
        },
      },
    },
  }),

  [fetchAdminLocationFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        selected: {
          ...state.admin.locations.selected,
          item: null,
          loaded: false,
          loading: false,
          error,
        },
      },
    },
  }),

  [adminLocationReset]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        selected: {
          ...state.admin.locations.selected,
          item: null,
          loaded: false,
          loading: false,
          error: null,
        },
      },
    },
  }),
};

export function* fetchAdminLocationSaga({ payload: id }) {
  const { response, error } = yield call(api.admin.getLocation, id);

  if (response) {
    yield put(fetchAdminLocationSuccess(initializeLocation(response)));
  } else {
    yield put(fetchAdminLocationFail(error));
  }
}

export function* watchFetchAdminLocation() {
  yield takeEvery(fetchAdminLocation.getType(), fetchAdminLocationSaga);
}
