import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../../_tools';
import commonMessages from '../../../../../../messages/common.messages';

export const LoadMoreButton = ({ onClick, isLoading }) => (
  <div className="text-center">
    <Button
      className="button light-grey-white"
      showLoader={isLoading}
      onClick={onClick}
    >
      { !isLoading && <i className="icon-reload-clockwise color-link mr-1" /> }
      <FormattedMessage {...commonMessages.loadMore} />
    </Button>
  </div>
);

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoadMoreButton;
