import { createAction } from 'redux-act';

import { updateCluster } from '../../core/common/cluster';
import {
  DEFAULT_TAB,
  HEAD_EVENT_COMMENTS,
  TAIL_EVENTS_LIST,
} from '../constants/machine.cluster.tabs.constants';

export const expandTailEventsOfCluster = createAction(
  'expand tail events of cluster of cluster group',
  (clusterGroupCategory, clusterGroupName, clusterId) => ({
    clusterId,
    clusterGroup: {
      name: clusterGroupName,
      category: clusterGroupCategory,
    },
  }),
);

export const expandCommentsOfHeadEventOfCluster = createAction(
  'expand comments of head event of cluster of cluster group',
  (clusterGroupCategory, clusterGroupName, clusterId) => ({
    clusterId,
    clusterGroup: {
      name: clusterGroupName,
      category: clusterGroupCategory,
    },
  }),
);

export const collapseCluster = createAction(
  'collapse cluster of cluster group',
  (clusterGroupCategory, clusterGroupName, clusterId) => ({
    clusterId,
    clusterGroup: {
      name: clusterGroupName,
      category: clusterGroupCategory,
    },
  }),
);

export const reducer = {
  [collapseCluster]: (state, { clusterId, clusterGroup }) => updateCluster(
    state,
    clusterGroup,
    clusterId,
    { displayMode: DEFAULT_TAB },
  ),
  [expandCommentsOfHeadEventOfCluster]: (state, { clusterId, clusterGroup }) => updateCluster(
    state,
    clusterGroup,
    clusterId,
    { displayMode: HEAD_EVENT_COMMENTS },
  ),
  [expandTailEventsOfCluster]: (state, { clusterId, clusterGroup }) => updateCluster(
    state,
    clusterGroup,
    clusterId,
    { displayMode: TAIL_EVENTS_LIST },
  ),
};
