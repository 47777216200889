import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DP_ARCHIVE_DATA_POINT } from '../../../redux/constants/modals.constants';
import { archiveDataPoint } from '../../../redux/modules/datatron.devices.dataPoints.archive.module';
import { closeModal } from '../../../redux/modules/modals.module';
import {
  getModalPayload,
  isModalOpen,
} from '../../../redux/selectors/modals.selector';
import { selector } from '../../../redux/selectors/util';
import Modal from '../../_tools/modal/Modal';

const modalOpenSelector = selector((state) =>
  isModalOpen(state, DP_ARCHIVE_DATA_POINT),
);

const modalPayloadSelector = selector((state) =>
  getModalPayload(state, DP_ARCHIVE_DATA_POINT),
);

export const ArchiveDataPointModal: React.FC = () => {
  const isOpen = useSelector(modalOpenSelector);
  const { deviceId, dataPointId } = useSelector(modalPayloadSelector);

  const dispatch = useDispatch();

  const close = () => dispatch(closeModal(DP_ARCHIVE_DATA_POINT));

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(archiveDataPoint(deviceId, dataPointId));
  };

  return (
    <Modal isOpen={isOpen} onClose={close} contentLabel="">
      <div className="modal--header" onClick={close}>
        <span className="modal--close">&times;</span>
      </div>
      <div className="modal--content">
        <FormattedMessage
          defaultMessage="Do you really want to archive this Data Point?"
          id="datatron.modals.archive_data_point.text"
          tagName="h3"
        />

        <div className="line" />

        <div className="text-center">
          <button type="button" className="button__cancel" onClick={close}>
            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
          </button>
          <button type="submit" className="button__save" onClick={handleSubmit}>
            <FormattedMessage
              defaultMessage="Archive Data Point"
              id="datatron.modals.archive_data_point.buttons.archive"
            />
          </button>
        </div>
      </div>
    </Modal>
  );
};
