import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';

import stopPropagation from '../../../../_tools/stopPropagation';
import * as measurementStatuses from '../../../../../../../common/enums/measurementStatuses';
import messages from '../../../../../messages/measurements.status.messages';

export const statusToClassName = {
  [measurementStatuses.MERGED]: 'info',
  [measurementStatuses.IN_PROGRESS]: 'warning',
  [measurementStatuses.COMPLETED]: 'success',
  [measurementStatuses.ARCHIVED]: 'secondary',
};

export const Status = ({ status, intl }) => {
  const className = cn('label', statusToClassName[status]);
  const label = intl.formatMessage(messages[status]);

  return (
    <td
      className="td-category"
      onClick={stopPropagation}
    >
      <div className={className}>
        <span>{ label }</span>
      </div>
    </td>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,

};

export default injectIntl(Status);
