import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DownloadLinksExperimentModal from './DownloadLinksExperimentModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { EXPERIMENT_DOWNLOAD_LINKS } from '../../../../redux/constants/modals.constants';
import { fetchMeasurementsByExperiment } from '../../../../redux/modules/experimentsPage.measurementsByExperiment.fetch.module';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { getMeasurements, isLoading, isLoaded } from '../../../../redux/selectors/experimentsPage.measurementsByExperiment.selector';
import { selectMeasurementsDownloadUrl } from '../../../../redux/selectors/appConfig.selector';

export const mapStateToProps = (state) => ({
  ...getModalPayload(state, EXPERIMENT_DOWNLOAD_LINKS),
  measurements: getMeasurements(state),
  loading: isLoading(state),
  loaded: isLoaded(state),
  url: selectMeasurementsDownloadUrl(state),
});

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(EXPERIMENT_DOWNLOAD_LINKS)),
  _fetch: bindActionCreators(fetchMeasurementsByExperiment, dispatch),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  fetch: () => {
    dispatchProps._fetch(stateProps.experimentId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DownloadLinksExperimentModal);
