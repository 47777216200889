import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isNull } from 'lodash';

import Modal from '../../../../_tools/modal/Modal';
import EventInfo from '../common/eventInfo/EventInfo';
import RepeatingEventFooter from '../common/footer/RepeatingEventFooter';
import {
  UPDATE_EVENT,
} from '../../../../../redux/constants/modals.calendars.event.actions.constants';
import {
  CURRENT_ONLY,
} from '../../../../../redux/constants/calendars.entry.constants';

export class EventEditModal extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    updateCalendarEvent: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    event: PropTypes.object.isRequired,
    day: PropTypes.string.isRequired,
    pendingChanges: PropTypes.object.isRequired,
    clearChanges: PropTypes.func.isRequired,
  };

  getPendingChanges = (actionType) => {
    const pendingChanges = { ...this.props.pendingChanges };

    if (
      actionType === CURRENT_ONLY &&
      !pendingChanges.startDateTime &&
      !pendingChanges.endDateTime &&
      isNull(pendingChanges.repeatType)
    ) {
      pendingChanges.startDateTime = this.props.event.startDateTime;
      pendingChanges.endDateTime = this.props.event.endDateTime;
    }

    return pendingChanges;
  };

  handleUpdate = (actionType) => {
    const { event, updateCalendarEvent, day } = this.props;

    updateCalendarEvent(
      event.calendarId,
      { ...event, ...event._originalEvent },
      this.getPendingChanges(actionType),
      actionType,
      day,
    );
  };

  handleCancel = () => {
    this.props.clearChanges();
    this.props.close();
  };

  render() {
    const {
      event, isOpen, close, pendingChanges,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClose={close}
        contentLabel=""
      >
        <div className="modal--header" onClick={close}>
          <span className="modal--close">&times;</span>
        </div>
        <div className="modal--content">
          <FormattedMessage
            id="calendars.events.modals.edit_recurring_event"
            defaultMessage="Update recurring event"
            tagName="h3"
          />
          <form className="form">
            <span className="modal--single--text">
              <FormattedMessage
                id="calendars.events.modals.edit_recurring_event.description"
                defaultMessage="You're changing repeating event. Do you want to change duration of this occurrence or change this and future occurrences, or past, this and future occurrences?"
              />
            </span>
            <EventInfo event={event} pendingChanges={pendingChanges} />
            <div className="line" />
            <RepeatingEventFooter
              onCancelClick={this.handleCancel}
              onConfirmClick={this.handleUpdate}
              actionType={UPDATE_EVENT}
            />
          </form>
        </div>
      </Modal>
    );
  }
}

export default EventEditModal;
