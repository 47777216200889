import React from 'react';

import * as entities from '../../../../common/constants/entities';
import Group from './group/Group';
import FooterContainer from './footer/Footer.container';
import { LoaderLocal } from '../_tools';
import { selectIsLoaded } from '../../redux/selectors/search.selector';
import { useSelector } from 'react-redux';
import { Todo, TodoFunction } from '../../../../common/types/common';

interface Props {
  results?: Todo,
  selectedResults?: Todo,
  selectItem?: TodoFunction,
  deselectItem?: TodoFunction,
  deselectAllItems?: TodoFunction,
  close: TodoFunction,
  filtersMode?: boolean,
  canApplyItemFilters: boolean,
  navigationSelectedIndex?: number,
  nameToHighlight: string,
  categoriesIncludedForDisplay: Todo
}

const SearchDropdown: React.FC<Props> = ({
  results = {},
  selectedResults = {},
  selectItem = () => {
  },
  deselectItem = () => {
  },
  deselectAllItems = () => {
  },
  filtersMode = false,
  canApplyItemFilters,
  navigationSelectedIndex,
  nameToHighlight,
  categoriesIncludedForDisplay,
  close,
}) => {
  const isAllowed = (type: Todo, categories: Todo[] = []) => !categories.length || categories.indexOf(type) >= 0;

  const machinesAllowed = isAllowed(entities.MACHINES, categoriesIncludedForDisplay);
  const datatronsAllowed = isAllowed(entities.DATATRONS, categoriesIncludedForDisplay);
  const locationsAllowed = isAllowed(entities.LOCATIONS, categoriesIncludedForDisplay);
  const organizationsAllowed = isAllowed(entities.ORGANIZATIONS, categoriesIncludedForDisplay);
  const tagsAllowed = isAllowed(entities.TAGS, categoriesIncludedForDisplay);
  const loaded = useSelector(selectIsLoaded);

  return (
    <>
      {machinesAllowed && (
        <Group
          nameToHighlight={nameToHighlight}
          type={entities.MACHINES}
          items={results[entities.MACHINES]}
          selectedItems={selectedResults[entities.MACHINES]}
          onSelect={selectItem}
          onDeselect={deselectItem}
          onDeselectAll={deselectAllItems}
          navigationSelectedIndex={navigationSelectedIndex}
        />
      )}
      {datatronsAllowed && (
        <Group
          nameToHighlight={nameToHighlight}
          type={entities.DATATRONS}
          items={results[entities.DATATRONS]}
          selectedItems={selectedResults[entities.DATATRONS]}
          onSelect={selectItem}
          onDeselect={deselectItem}
          onDeselectAll={deselectAllItems}
          navigationSelectedIndex={navigationSelectedIndex}
        />
      )}
      {locationsAllowed && (
        <Group
          nameToHighlight={nameToHighlight}
          type={entities.LOCATIONS}
          items={results[entities.LOCATIONS]}
          selectedItems={selectedResults[entities.LOCATIONS]}
          onSelect={selectItem}
          onDeselect={deselectItem}
          onDeselectAll={deselectAllItems}
          navigationSelectedIndex={navigationSelectedIndex}
        />
      )}
      {organizationsAllowed && (
        <Group
          nameToHighlight={nameToHighlight}
          type={entities.ORGANIZATIONS}
          items={results[entities.ORGANIZATIONS]}
          selectedItems={selectedResults[entities.ORGANIZATIONS]}
          onSelect={selectItem}
          onDeselect={deselectItem}
          onDeselectAll={deselectAllItems}
          navigationSelectedIndex={navigationSelectedIndex}
        />
      )}
      {tagsAllowed && (
        <Group
          nameToHighlight={nameToHighlight}
          type={entities.TAGS}
          items={results[entities.TAGS]}
          selectedItems={selectedResults[entities.TAGS]}
          onSelect={selectItem}
          onDeselect={deselectItem}
          onDeselectAll={deselectAllItems}
          navigationSelectedIndex={navigationSelectedIndex}
        />
      )}
      {filtersMode && (
        <FooterContainer
          canApplyItemFilters={canApplyItemFilters}
          close={close}
        />
      )}
      {!loaded && <LoaderLocal/>}
    </>
  );
};

export default SearchDropdown;
