import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import { getFiltersQuery } from '../selectors/admin.organizations.selector';

export const adminFetchOrganizations = createAction(
  'admin: fetch organizations',
  (page = 1) => page,
);

export const adminFetchOrganizationsSuccess = createAction(
  'admin: fetch organizations - success',
  (list, pagination) => ({ list, pagination }),
);

export const adminFetchOrganizationsFail = createAction(
  'admin: fetch organizations - fail',
  (error) => error,
);

export const reducer = {
  [adminFetchOrganizations]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [adminFetchOrganizationsSuccess]: (state, { list, pagination }) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        pagination,
        list,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [adminFetchOrganizationsFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* adminFetchOrganizationsSaga({ payload: page }) {
  const state = yield select();
  const filters = yield call(getFiltersQuery, state);

  const { response, error } = yield call(api.admin.getOrganizations, { page, ...filters });

  if (response) {
    yield put(adminFetchOrganizationsSuccess(response.list, response.pagination));
  } else {
    yield put(adminFetchOrganizationsFail(error));
  }
}

export function* watchAdminFetchOrganizations() {
  yield takeEvery(adminFetchOrganizations.getType(), adminFetchOrganizationsSaga);
}
