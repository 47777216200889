import { connect } from 'react-redux';

import EmptyPlaceholder from './EmptyPlaceholder';
import { experimentsFiltersApplied } from '../../../../redux/selectors/experimentsPage.selector';

export const mapStateToProps = (state) => ({
  hasFiltersApplied: experimentsFiltersApplied(state),
});

export default connect(mapStateToProps)(EmptyPlaceholder);
