import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BasicModal from '../core/BasicModal';
import DoneModalFooter from '../core/footers/DoneModalFooter';
import commonMessages from '../../../messages/common.messages';
import ModalLine from '../core/ModalLine';

export const ConfirmationModal = ({
  close,
  title,
  titleValues,
  description,
  descriptionValues,
  intl,
}) => {
  const titleTranslated = intl.formatMessage(title, titleValues);
  const descriptionTranslated = intl.formatMessage(description, descriptionValues);

  return (
    <BasicModal
      close={close}
      modalClass="modal--sm"

    >
      <div className="modal--content">
        <h3>{ titleTranslated }</h3>

        <div className="modal--subtitle">
          { descriptionTranslated }
        </div>

        <ModalLine />
        <DoneModalFooter
          onCancelClick={close}
          onDoneClick={close}
          hideCancelButton
          doneButtonIntl={commonMessages.ok}
        />
      </div>
    </BasicModal>
  );
};

ConfirmationModal.propTypes = {
  close: PropTypes.func.isRequired,
  title: PropTypes.object.isRequired,
  titleValues: PropTypes.object,
  description: PropTypes.object.isRequired,
  descriptionValues: PropTypes.object,

};

ConfirmationModal.defaultProps = {
  titleValues: {},
  descriptionValues: {},
};

export default injectIntl(ConfirmationModal);
