import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { last } from 'lodash';
import cn from 'classnames';

import BasicModal from '../BasicModal';
import FrameWrapper from './FrameWrapper';
import { LoaderLocal } from '../../../_tools';

/*
  All frames are visible according to
  Artur's requirements (Design->HTML guy)
  to make animations working
*/

export default class ComplexModal extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    submit: PropTypes.func,
    isOpen: PropTypes.bool,
    modalId: PropTypes.string.isRequired,
    modalClass: PropTypes.string,
    modalTitle: PropTypes.string,
    modalConfig: PropTypes.object.isRequired,
    flattenModalConfig: PropTypes.array.isRequired,
    activePath: PropTypes.array,
    setModalPath: PropTypes.func.isRequired,
    showLoader: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: true,
    modalClass: '',
    activePath: [],
    submit() {},
    showLoader: false,
  };

  componentWillMount() {
    const { modalId, setModalPath, modalConfig } = this.props;
    setModalPath(modalId, [modalConfig.id]);
  }

  getActivePathItem() {
    const { activePath } = this.props;
    const lastPathItem = last(activePath);
    return lastPathItem || null;
  }

  getFooter() {
    const activePathItem = this.getActivePathItem();
    return (activePathItem && activePathItem.footer) ? activePathItem.footer : null;
  }

  render() {
    const {
      isOpen,
      close,
      modalClass,
      modalTitle,
      flattenModalConfig,
      submit,
      showLoader,
    } = this.props;
    const active = this.getActivePathItem();
    const mainClassName = cn(
      'modal-overflow',
      (active && active.mainClassName) ? active.mainClassName : '',
    );

    return (
      <BasicModal isOpen={isOpen} close={close} modalClass={modalClass}>
        <div className={mainClassName}>
          <div className="modal--content">
            <h3>{modalTitle}</h3>
            <form className="form" onSubmit={submit}>
              <div className="modal-overflow-in">
                { flattenModalConfig.map((pathItem) => (
                  <FrameWrapper key={pathItem.id}>
                    { pathItem.component }
                  </FrameWrapper>
                )) }
              </div>
            </form>
          </div>
        </div>
        { showLoader && <LoaderLocal /> }
      </BasicModal>
    );
  }
}
