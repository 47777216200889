import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

interface Props {
  fullHeight?: boolean;
  solidBackground?: boolean;
  fullPage?: boolean;
  show: boolean;
}

// Generate circularG elements dynamicallyconst circularGElements, circleCount is always equal with 8
const circularGElements = () =>
  Array.from({ length: 8 }, (_, index) => (
    <div key={`circularG_${index + 1}`} id={`circularG_${index + 1}`} className='circularG' />
  ));

export const Loader: React.FC<Props> = ({
  fullHeight = false,
  solidBackground = false,
  show,
  fullPage = true,
}) => {
  if (!show) return null;

  const classes = classNames({
    wait: fullPage,
    'full-height': fullHeight,
    'solid-background': solidBackground,
  });

  return (
    <div className={classes}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div id='circularG'>
          {circularGElements()}
          <div className='logo-icon'>
            <span className='icon icon-dashboard' />
          </div>
        </div>
        <div className='wait-text'>
          <FormattedMessage id='dashboard.event.wait-text' defaultMessage='Please wait' />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Loader);
