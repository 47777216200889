import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const fetchAllUsers = createAction(
  'fetch all users',
);

export const fetchAllUsersSuccess = createAction(
  'fetch all users - success',
  (list) => list,
);

export const fetchAllUsersFail = createAction(
  'fetch all users - fail',
  (error) => error,
);

export const reducer = {
  [fetchAllUsers]: (state) => ({
    ...state,
    users: {
      ...state.users,
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [fetchAllUsersSuccess]: (state, list) => ({
    ...state,
    users: {
      ...state.users,
      list,
      loaded: true,
      loading: false,
      error: null,
    },
  }),

  [fetchAllUsersFail]: (state, error) => ({
    ...state,
    users: {
      ...state.users,
      list: [],
      loaded: false,
      loading: false,
      error,
    },
  }),
};

export function* fetchAllUsersSaga() {
  const { response, error } = yield call(api.users.getUsersList);

  if (response) {
    yield put(fetchAllUsersSuccess(response.list));
  } else {
    yield put(fetchAllUsersFail(error));
  }
}

export function* watchFetchAllUsers() {
  yield takeEvery(fetchAllUsers.getType(), fetchAllUsersSaga);
}
