import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NewAssistanceModal from './NewAssistantModal';
import { closeModal } from '../../../../../redux/modules/modals.module';
import { ASSISTANT_ADD_NEW } from '../../../../../redux/constants/modals.constants';
import * as machineNewAssistantAddModule from '../../../../../redux/modules/machine.newAssistant.add.module';
import * as datatronNewAssistantAddModule from '../../../../../redux/modules/datatron.newAssistant.add.module';
import * as inventoryTypes from '../../../../../../../common/constants/inventory';
import { getModalPayload } from '../../../../../redux/selectors/modals.selector';

export const mapStateToProps = (state) => ({
  ...getModalPayload(state, ASSISTANT_ADD_NEW),
});

export const mapDispatchToProps = (dispatch, { inventoryType }) => {
  let addNewAssistant = null;

  switch (inventoryType) {
    case inventoryTypes.INVENTORY_MACHINES: {
      addNewAssistant = machineNewAssistantAddModule.addNewAssistant;
      break;
    }
    case inventoryTypes.INVENTORY_DATATRONS: {
      addNewAssistant = datatronNewAssistantAddModule.addNewAssistant;
      break;
    }
    default: break;
  }

  return {
    close: () => dispatch(closeModal(ASSISTANT_ADD_NEW)),
    addNewAssistant: bindActionCreators(addNewAssistant, dispatch),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(NewAssistanceModal);
