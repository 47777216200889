import { createAction } from 'redux-act';
import {
  NOT_EXPANDED,
  EXPANDED_COMMENTS,
  EXPANDED_MASTER_EVENT,
} from '../constants/dashboard.constants';

export const expandComments = createAction(
  'expand event details',
  (eventId) => eventId,
);

export const expandMasterEvent = createAction(
  'expand master event details',
  (eventId) => eventId,
);

export const hideEventDetails = createAction(
  'hide event details',
  (eventId) => eventId,
);

export const resetExpandedEvents = createAction(
  'reset expanded events on dashboard',
);

export const reducer = {
  [expandComments]: (state, eventId) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      expanded: {
        [eventId]: EXPANDED_COMMENTS,
      },
    },
  }),
  [expandMasterEvent]: (state, eventId) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      expanded: {
        [eventId]: EXPANDED_MASTER_EVENT,
      },
    },
  }),
  [hideEventDetails]: (state, eventId) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      expanded: {
        [eventId]: NOT_EXPANDED,
      },
    },
  }),
  [resetExpandedEvents]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      expanded: {},
    },
  }),
};
