import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3-selection';
import { axisBottom } from 'd3-axis';
import moment from 'moment';

import { Y_AXIS_PADDING } from './chart.constants';

const AXIS_LEFT_OFFSET = 35 + Y_AXIS_PADDING;

export const formatTimestamp = (point) => {
  const pointMoment = moment(point);
  const hour = pointMoment.hour();
  const minute = pointMoment.minute();

  if (
    (hour % 4 !== 0)
    || (minute !== 0)
  ) return null;

  return pointMoment.format('HH:mm');
};

export class AxisBottom extends Component {
  componentDidMount() {
    this.renderAxis(this.props);
  }

  shouldComponentUpdate(props) {
    this.renderAxis(props);
    return false;
  }

  renderAxis(props) {
    const { scale, height } = props;
    const axis = axisBottom(scale)
      .tickSize(0)
      .tickFormat(formatTimestamp)
      .tickPadding(height + 5);

    select(this.ticks)
      .attr('class', 'chart-axis-bottom')
      .attr('transform', `translate(${AXIS_LEFT_OFFSET}, 0)`)
      .call(axis);
  }

  render() {
    return (
      <g>
        <g ref={(r) => (this.ticks = r)} />
      </g>
    );
  }
}

AxisBottom.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  scale: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

export default AxisBottom;
