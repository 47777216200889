import { connect } from 'react-redux';

import Filter from './Filter';

export function mapStateToProps(state, { filters, onChange, option }) {
  return {
    handleChange: onChange,
    checked: filters.indexOf(option.value) !== -1,
    label: option.label,
    value: option.value,
  };
}

export default connect(mapStateToProps)(Filter);
