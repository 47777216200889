import querystring from 'querystring';
import moment from 'moment';

import { DATE_FROM, DATE_TO } from '../../../../common/constants';

export const getExportUrl = (payload) => {
  const queryParams = {
    ...payload,
    timezoneOffset: moment().utcOffset(),
  };

  queryParams[DATE_FROM] = payload[DATE_FROM].toISOString();
  queryParams[DATE_TO] = payload[DATE_TO].toISOString();

  const query = querystring.stringify(queryParams);

  if (payload.includeProcessings === true) {
    return `/api/processings/download/${payload.fileFormat}/?${query}`;
  }
  return `/api/products/download/${payload.fileFormat}/?${query}`;
};
