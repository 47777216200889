import { createAction } from '@reduxjs/toolkit';
import { fetchDeviceCommandHistory } from '../../core/api/datatrons';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getDatatron } from '../selectors/datatron.selector';
import { CommandsResponse } from '../../../../common/types/api/datatron.devices';

export const fetchCommandHistory = createAction(
  'fetch device command history',
  (deviceId, queryParameters) => ({ payload: { deviceId, queryParameters } }),
);

export const setCommandHistory = createAction(
  'set device command history',
  ({ commands, total }: CommandsResponse) => ({ payload: { commands, total } }),
);

export const setCommandError = createAction(
  'set device command error',
  (error) => ({ payload: { error } }),
);

export const reducer = {
  [setCommandHistory.type]: (state, { commands, total }: CommandsResponse) => ({
    ...state,
    datatron: {
      ...state.datatron,
      deviceCommands: commands,
      deviceCommandTotal: total,
    },
  }),
  [setCommandError.type]: (state, { error }) => ({
    ...state,
    datatron: {
      ...state.datatron,
      deviceCommands: error,
      deviceCommandTotal: 0,
    },
  }),
};

export function* fetchCommandHistorySaga({ payload: { deviceId, queryParameters } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const queryString = (new URLSearchParams(queryParameters)).toString();

  const {
    response,
    error,
  } = yield call(fetchDeviceCommandHistory, datatron.id, deviceId, queryString);

  if (response) {
    yield put(setCommandHistory(response));
  } else {
    yield put(setCommandError(error));
  }
}

export function* wachCommandHistory() {
  yield takeEvery(fetchCommandHistory, fetchCommandHistorySaga);
}