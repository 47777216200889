import React from 'react';
import PropTypes from 'prop-types';
import { line } from 'd3-shape';

export default function Line({
  chartPoints, color, lineWidth, getX, getY, xScale, yScale,
}) {
  const path = line()
    .x((d) => xScale(getX(d)))
    .y((d) => yScale(getY(d)))
    .defined((d) => getY(d) !== null)(chartPoints);

  return (
    <path d={path} style={{ stroke: `#${color}`, fill: 'none', strokeWidth: `${lineWidth}px` }} />
  );
}

Line.propTypes = {
  chartPoints: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  lineWidth: PropTypes.number.isRequired,
  getX: PropTypes.func.isRequired,
  getY: PropTypes.func.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
};
