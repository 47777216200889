import { uniqBy } from 'lodash';
import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as eventsApi from '../../core/api/events.js';
import { replaceSummaryGroup, findSummaryGroup, getGroupedEventsFilters } from '../selectors/machine.selector';
import { EVENTS_IN_EACH_PART } from '../constants/event.constants';
import { DEFAULT_MODE } from '../constants/machine.summary.constants';

export const fetchEventsSummaryGroup = createAction(
  'fetch events of summary group',
  (machineId, summaryGroup) => ({ machineId, summaryGroup }),
);

export const fetchEventsSummaryGroupSuccess = createAction(
  'fetch events of summary group successfully',
  (events) => events,
);

export const fetchEventsSummaryGroupFail = createAction(
  'fetch events of summary group fail',
  (error) => error,
);

export const reducer = {
  [fetchEventsSummaryGroup]: (state, { summaryGroup }) => {
    const stateSummaryGroup = findSummaryGroup(state.machine.summary.data, summaryGroup);

    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          data: replaceSummaryGroup(state.machine.summary.data, {
            ...stateSummaryGroup,
            mode: stateSummaryGroup.mode || DEFAULT_MODE,
            events: {
              ...stateSummaryGroup.events,
              fromIndex: stateSummaryGroup.events.fromIndex || 0,
              toIndex: stateSummaryGroup.events.toIndex || EVENTS_IN_EACH_PART,
              loaded: false,
              loading: true,
              error: null,
            },
          }),
        },
      },
    };
  },
  [fetchEventsSummaryGroupSuccess]: (state, { summaryGroup, events }) => {
    const stateSummaryGroup = findSummaryGroup(state.machine.summary.data, summaryGroup);
    const newEventsArray = uniqBy([
      ...stateSummaryGroup.events.data,
      ...events,
    ], 'id');
    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          data: replaceSummaryGroup(state.machine.summary.data, {
            ...stateSummaryGroup,
            events: {
              ...stateSummaryGroup.events,
              loaded: true,
              loading: false,
              data: newEventsArray,
              error: null,
              moreAvailable: (newEventsArray.length < stateSummaryGroup.amount),
            },
          }),
        },
      },
    };
  },
  [fetchEventsSummaryGroupFail]: (state, { summaryGroup, error }) => {
    const stateSummaryGroup = findSummaryGroup(state.machine.summary.data, summaryGroup);

    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          data: replaceSummaryGroup(state.machine.summary.data, {
            ...stateSummaryGroup,
            events: {
              ...stateSummaryGroup.events,
              loaded: false,
              loading: false,
              error,
            },
          }),
        },
      },
    };
  },
};

export function* fetchEventsSummaryGroupSaga({ payload: { machineId, summaryGroup } }) {
  const state = yield select();
  const stateSummaryGroup = yield call(findSummaryGroup, state.machine.summary.data, summaryGroup);
  const filters = yield call(getGroupedEventsFilters, state);

  const { response, error } = yield call(
    eventsApi.getEventsOfSummaryGroup,
    {
      ...filters,
      machineId,
      category: stateSummaryGroup.category,
      title: stateSummaryGroup.title,
      fromIndex: stateSummaryGroup.events.fromIndex,
      toIndex: stateSummaryGroup.events.toIndex,
      mode: stateSummaryGroup.mode,
    },
  );

  if (response) {
    yield put(fetchEventsSummaryGroupSuccess({
      events: response,
      summaryGroup,
    }));
  } else {
    yield put(fetchEventsSummaryGroupFail({
      error,
      summaryGroup,
    }));
  }
}

export function* watchFetchEventsSummaryGroup() {
  yield takeEvery(fetchEventsSummaryGroup.getType(), fetchEventsSummaryGroupSaga);
}
