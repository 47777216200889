import { createAction } from 'redux-act';

import { getEventIndexById } from '../selectors/machine.selector';

export const receiveMachineEventComment = createAction(
  'receive machine event comment',
  (comment) => comment,
);

export const reducer = {
  [receiveMachineEventComment]: (state, comment) => {
    const eventIndex = getEventIndexById(state, comment.eventId);

    if (eventIndex === -1) {
      return state;
    }

    const event = state.machine.events[eventIndex];

    return {
      ...state,
      machine: {
        ...state.machine,
        events: [
          ...state.machine.events.slice(0, eventIndex),
          {
            ...event,
            comments: [
              ...event.comments,
              comment,
            ],
          },
          ...state.machine.events.slice(eventIndex + 1),
        ],
      },
    };
  },
};
