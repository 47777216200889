import React from 'react';

import { ScaleLinear } from 'd3-scale';
import LineChart from './lineChart/LineChart';
import { StackedBarsChart } from './stackedBarChart/StackedBarsChart';
import StackedChart from './stackedChart/StackedChart';
import {
  LINE_CHART,
  STACKED_BAR_CHART,
  STACKED_CHART,
} from '../../../../redux/constants/chart.type.constants';
import { Y_AXIS_PADDING } from '../chart.constants';
import { Todo } from '../../../../../../common/types/common';
import { XScale } from '../types';

type Props = XScale & {
  yScale: ScaleLinear<number, number>;
  allowedProps: string[];
  height: number;
  data: Todo[];
};

export const ChartRouter: React.FC<Props> = ({
  data,
  yScale,
  allowedProps,
  height,
  ...rest
}) => {
  switch (rest.chartType) {
    case LINE_CHART: {
      return (
        <g transform={`translate(${Y_AXIS_PADDING}, 0)`}>
          <LineChart
            data={data}
            xScale={rest.xScale}
            yScale={yScale}
            properties={allowedProps}
          />
        </g>
      );
    }
    case STACKED_CHART: {
      return (
        <g transform={`translate(${Y_AXIS_PADDING}, 0)`}>
          <StackedChart
            data={data}
            height={height}
            keys={allowedProps}
            yScale={yScale}
            xScale={rest.xScale}
          />
        </g>
      );
    }
    case STACKED_BAR_CHART: {
      return (
        <g transform={`translate(${Y_AXIS_PADDING}, 0)`}>
          <StackedBarsChart
            data={data}
            keys={allowedProps}
            yScale={yScale}
            xScale={rest.xScale}
          />
        </g>
      );
    }
  }
  return null;
};

export default ChartRouter;
