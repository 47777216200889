import React from 'react';
import PropTypes from 'prop-types';

export const CheckboxListItem = ({
  item,
  checked,
  onChange,
}) => {
  const handleChange = () => onChange(item.value, !checked);
  return (
    <li>
      <label className="checkbox checkbox-white">
        <input type="checkbox" checked={checked} onChange={handleChange} />
        <span>{item.label}</span>
      </label>
    </li>
  );
};

CheckboxListItem.propTypes = {
  item: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxListItem;
