import { createAction } from 'redux-act';
import { forEach, filter } from 'lodash';

export const receiveRoles = createAction(
  'profile receive new roles',
  (roles) => roles,
);

export const reducer = {
  [receiveRoles]: (state, { addedRoles = [], removedRoles = [] }) => {
    const currentUserRoles = state.profile.user.roles || [];
    let newUserRoles = [...currentUserRoles];

    forEach(addedRoles, (item) => newUserRoles.push(item));
    newUserRoles = filter(newUserRoles, (item) => removedRoles.indexOf(item) === -1);

    return {
      ...state,
      profile: {
        ...state.profile,
        user: {
          ...state.profile.user,
          roles: newUserRoles,
        },
      },
    };
  },
};
