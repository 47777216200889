import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import relativeUnitsMessages from '../../../../../../messages/relative.units.messages';
import * as relativeUnits from '../../../../../../../../common/constants/relative.units';

const DATE_FORMAT = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export class LiveTimestamp extends Component {
  static propTypes = {
    relativeOffset: PropTypes.object.isRequired,

  };

  state = { timestamp: null };

  componentDidMount = () => {
    const { relativeOffset } = this.props;

    if (relativeOffset && relativeOffset.count > 0) {
      this.timerId = setInterval(
        () => this.updateTimestamp(),
        1000,
      );
    }
  };

  componentDidUpdate = () => {
    const { relativeOffset } = this.props;

    if (!this.timerId && relativeOffset && relativeOffset.count > 0) {
      this.timerId = setInterval(
        () => this.updateTimestamp(),
        1000,
      );
    }
  };

  componentWillUnmount = () => {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  };

  updateTimestamp = () => {
    const { relativeOffset } = this.props;
    const ts = moment();

    if (relativeOffset.count > 0) {
      ts.subtract(relativeOffset.count, relativeOffset.unit);
    }

    this.setState({ timestamp: +ts });
  };

  render = () => {
    const { relativeOffset, intl } = this.props;
    const { timestamp } = this.state;

    const empty = timestamp === null;
    const isNow = relativeOffset.count === 0;

    let label = '...';
    if (isNow) {
      label = intl.formatMessage(relativeUnitsMessages[relativeUnits.now]);
    } else if (!empty) {
      label = intl.formatDate(this.state.timestamp, DATE_FORMAT);
    }

    return (
      <div className="now">
        { label }
      </div>
    );
  };
}

export default injectIntl(LiveTimestamp);
