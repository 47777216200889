import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';

import AddReportsTabModal from './AddReportsTabModal';
import { REPORTS_TAB_ADD } from '../../../../redux/constants/modals.constants';
import { addReportsTab } from '../../../../redux/modules/reportsTabs.add.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { REPORTS_TAB_ADD_FORM_NAME } from '../../../../redux/constants/reportsTab.add.form.constants';

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(REPORTS_TAB_ADD)),
  addReportsTab: bindActionCreators(addReportsTab, dispatch),
});

const form = {
  form: REPORTS_TAB_ADD_FORM_NAME,
  destroyOnUnmount: true,
};

export default connect(null, mapDispatchToProps)(reduxForm(form)(AddReportsTabModal));
