import { connect } from 'react-redux';

import TableQuerySearchState from '../../../../../common/tableQuerySearch/TableQuerySearchState';
import { getNameFilterValue } from '../../../../../../redux/selectors/admin.locations.selector';
import { adminFilterLocationsByName } from '../../../../../../redux/modules/admin.locations.sort.filter.module';
import { AP_LOCATIONS_NAME_FILTER_INPUT } from '../../../../../../redux/constants/components.constants';

export const mapStateToProps = (state) => ({
  value: getNameFilterValue(state),
  stateComponentId: AP_LOCATIONS_NAME_FILTER_INPUT,
});

export const mapDispatchToProps = {
  onQueryChange: adminFilterLocationsByName,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableQuerySearchState);
