import React from 'react';

import LoaderLocal from '../../_tools/loader/LoaderLocal';
import ReportHeaderContainer from './header/ReportHeader.container';
import { ReportGraph } from './graph/ReportGraph';
import { Indicators } from './Indicators';
import { ReportT } from '../../../../../client/types/report';

interface Props {
  report: ReportT;
}

export const ReportComponent: React.FC<Props> = ({ report }) => (
  <div className="reports--col">
    <div className={`reports--item report-type--${report.type}`}>
      <ReportHeaderContainer report={report} />
      <div className="reports--in">
        { report.data && report.data.hasTotalData && (<Indicators report={report} />) }
        { report.data && report.data.hasChartsData && (<ReportGraph report={report} />) }
        { report.loading && (<LoaderLocal />) }
      </div>
    </div>
  </div>
);
