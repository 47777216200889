import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import MetricDataHeader from './header/MetricDataHeader';
import LoadMoreButtonContainer from './loadMoreButton/LoadMoreButton.container';
import MetricDataEmptyPlaceholder from './emptyPlaceholder/MetricDataEmptyPlaceholder';

export const MetricData = ({
  dataList, isLoadMoreButtonAvailable, utcMode, deviceId, dataPointId, getScaledValue, hasNoData,
}) => (
  <>
    <div className="data-points-modal-table">
      <table>
        <MetricDataHeader />
        <tbody>
          { dataList.map((item) => {
            const ts = utcMode ?
              moment(item[0]).toISOString() : moment(item[0]).toISOString(true);
            return (
              <tr key={String(item[0]) + String(item[1])}>
                <td>{ ts }</td>
                <td>{ item[1] }</td>
                <td>{ getScaledValue(item[1]) }</td>
              </tr>
            );
          }) }
        </tbody>
      </table>
      { hasNoData && <MetricDataEmptyPlaceholder /> }
    </div>
    { isLoadMoreButtonAvailable && (
      <LoadMoreButtonContainer
        deviceId={deviceId}
        dataPointId={dataPointId}
      />
    ) }
  </>
);

MetricData.propTypes = {
  dataList: PropTypes.array.isRequired,
  isLoadMoreButtonAvailable: PropTypes.bool.isRequired,
  utcMode: PropTypes.bool.isRequired,
  hasNoData: PropTypes.bool.isRequired,
  deviceId: PropTypes.string.isRequired,
  dataPointId: PropTypes.string.isRequired,
  getScaledValue: PropTypes.func.isRequired,
};

export default MetricData;
