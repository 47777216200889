import { uniqBy } from 'lodash';
import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as eventsApi from '../../core/api/events.js';
import * as selector from '../selectors/maintenance.selector';
import { MAINTENANCE_EVENTS_ON_ONE_PAGE } from '../constants/maintenance.constants';
import { fetchMaintenanceCounts } from './maintenance.fetch.eventsCount.module';
import { createActivitySuccess } from './maintenance.columns.activity.create.module';
import * as entities from '../../../../common/constants/entities';

export const fetchMaintenanceEvents = createAction(
  'fetch all maintenance events',
);

export const fetchMaintenanceEventsSuccess = createAction(
  'fetch all maintenance events successfully',
  (events) => events,
);

export const fetchMaintenanceEventsFail = createAction(
  'fetch all maintenance events fail',
  (error) => error,
);

export const resetMaintenanceEventsPagination = createAction(
  'reset maintenance events pagination',
);

export const reducer = {
  [fetchMaintenanceEvents]: (state) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      loaded: false,
      loading: true,
      error: '',
    },
  }),

  [fetchMaintenanceEventsSuccess]: (state, events) => {
    let newEvents = events;
    if (selector.loadEventsFrom(state) !== 0) {
      newEvents = uniqBy(
        [...selector.allEvents(state), ...events],
        'id',
      );
    }
    return ({
      ...state,
      maintenance: {
        ...state.maintenance,
        events: newEvents,
        loaded: true,
        loading: false,
        moreAvailable: (events.length >= MAINTENANCE_EVENTS_ON_ONE_PAGE),
      },
    });
  },

  [fetchMaintenanceEventsFail]: (state, error) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      loaded: false,
      loading: false,
      error,
    },
  }),

  [resetMaintenanceEventsPagination]: (state) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      loadEventsFrom: 0,
      loadEventsTo: MAINTENANCE_EVENTS_ON_ONE_PAGE,
    },
  }),
};

export function* fetchMaintenanceEventsSaga() {
  const state = yield select();
  const fromIndex = yield call(selector.loadEventsFrom, state);
  const toIndex = yield call(selector.loadEventsTo, state);
  const finalCategories = yield call(selector.getInboxCategories, state);

  const events = yield call(eventsApi.getAllEvents, {
    [entities.CATEGORIES]: (finalCategories || []).map((category) => ({ id: category })),
    withoutActivityOnly: true,
    fromIndex,
    toIndex,
  });

  if (events.response) {
    if (fromIndex === 0) {
      yield put(fetchMaintenanceCounts());
    }
    yield put(fetchMaintenanceEventsSuccess(events.response));
  } else {
    yield put(fetchMaintenanceEventsFail(events.error));
  }
}

export function* watchFetchMaintenanceEvents() {
  yield takeEvery(fetchMaintenanceEvents.getType(), fetchMaintenanceEventsSaga);
}

export function* autoFetchMaintenanceEvents() {
  const state = yield select();

  const totalInboxEventsCount = yield call(selector.inboxEventsAmount, state);
  const loadedInboxEvents = yield call(selector.allEvents, state);

  if (totalInboxEventsCount > 0 && loadedInboxEvents.length === 0) {
    yield put(fetchMaintenanceEvents());
  }
}

export function* watchAutoFetchMaintenanceEvents() {
  yield takeEvery(createActivitySuccess.getType(), autoFetchMaintenanceEvents);
}
