import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';

import FiltersBarContainer from './filters/FiltersBar.container';
import Loader from '../_tools/loader/Loader';
import EventsMotion from './Events.motion';
import EmptyEvents from './EmptyEvents';

export default function Events({
  events,
  noEvents,
  isLoading,
  isUpdating,
  loadMoreEvents,
}) {
  return (
    <div>
      <FiltersBarContainer />
      <div className="scroll">
        <div className="central">
          {
            noEvents && !isLoading
              ? <EmptyEvents />
              : (
                <InfiniteScroll
                  pageStart={0}
                  initialLoad={false}
                  loadMore={loadMoreEvents}
                  hasMore
                >
                  <EventsMotion events={events} />
                </InfiniteScroll>
              )
          }
        </div>
      </div>
      <Loader show={isLoading && !isUpdating} />
    </div>
  );
}

Events.propTypes = {
  events: PropTypes.array.isRequired,
  noEvents: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  loadMoreEvents: PropTypes.func.isRequired,
};
