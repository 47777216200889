import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import EventPreview from './EventPreview';
import EventActionsContainer from './tools/EventActions.container';
import stopPropagation from '../../../../../_tools/stopPropagation';
import * as calendarTypes from '../../../../../../../../common/enums/calendarTypes';

import { getCalendarEventProps } from '../../../../../../core/common/calendar';
import { Todo } from '../../../../../../../../common/types/common';
import { useClickOutside } from '../../../../../../core/common/hooks/useClickOutside';

interface Props {
  event: Todo
  day: string
  widthPerSecond: number
  readOnly: boolean
}

export const Events: React.FC<Props> = ({
  event,
  day,
  widthPerSecond,
  readOnly,
}) => {
  const [isOpen, isOpenSet] = useState(false);
  const [isOptionsOpen, isOptionsOpenSet] = useState(false);

  const [componentStyle, componentStyleSet] = useState(getCalendarEventProps(event, widthPerSecond));
  const [componentClassNames, componentClassNamesSet] = useState<Todo>();
  const [popupClassNames, popupClassNamesSet] = useState<Todo>();
  const handleMouseEnter = () => {
    if (!isOptionsOpen) {
      isOpenSet(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isOptionsOpen) {
      isOpenSet(false);
    }
  };

  const handleClick = (e) => {
    stopPropagation(e);

    isOpenSet(readOnly);
    isOptionsOpenSet(!readOnly);
  };

  const closeAll = () => {
    isOpenSet(false);
    isOptionsOpenSet(false);
  };

  const initializeStyles = useCallback(() => {

    componentStyleSet(getCalendarEventProps(event, widthPerSecond));
    componentClassNamesSet(classnames(
      'schedule-item-line',
      {
        warning: event.type === calendarTypes.SCHEDULE_CALENDAR,
        'calendar-schedule': event.type === calendarTypes.SCHEDULE_CALENDAR,
        'calendar-shift': event.type === calendarTypes.SHIFT_CALENDAR,
        'read-only': readOnly,
      },
    ));
    popupClassNamesSet(classnames(
      {
        left: event.type === calendarTypes.SCHEDULE_CALENDAR,
        right: event.type === calendarTypes.SHIFT_CALENDAR,
      },
    ));
  }, [event, readOnly, widthPerSecond]);

  useEffect(() => {
    initializeStyles();
  }, [initializeStyles]);

  componentClassNamesSet(classnames(
    componentClassNames,
    { hover: isOpen },
    { selected: isOptionsOpen },
  ));

  const clickRef = useClickOutside(closeAll);

  return (
    <div
      className={componentClassNames}
      style={componentStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      ref={clickRef}
    >
      {(isOpen || isOptionsOpen) && (
        <EventPreview
          event={event}
          className={popupClassNames}
        />
      )}
      {!readOnly && isOptionsOpen && (
        <EventActionsContainer
          event={event}
          day={day}
          hide={closeAll}
          className={popupClassNames}
          pendingChanges={{}}
        />
      )}
    </div>
  );
};

export default (Events);
