import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as eventsApi from '../../core/api/events.js';
import { replaceSummaryGroup, findSummaryGroup, getGroupedEventsFilters } from '../selectors/machine.selector';
import { resetSummaryGroupEventsPagination } from './machine.fetch.summaryGroup.loadMore.module';

export const fetchEventsAmountSummaryGroupSuccess = createAction(
  'fetch events amount (of summary group) successfully',
  (events) => events,
);

export const fetchEventsAmountSummaryGroupFail = createAction(
  'fetch events amount (of summary group) fail',
  (error) => error,
);

export const reducer = {
  [fetchEventsAmountSummaryGroupSuccess]: (state, { summaryGroup, amount }) => {
    const stateSummaryGroup = findSummaryGroup(state.machine.summary.data, summaryGroup);

    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          data: replaceSummaryGroup(state.machine.summary.data, {
            ...stateSummaryGroup,
            amount,
          }),
        },
      },
    };
  },
  [fetchEventsAmountSummaryGroupFail]: (state, { summaryGroup, error }) => {
    const stateSummaryGroup = findSummaryGroup(state.machine.summary.data, summaryGroup);

    return {
      ...state,
      machine: {
        ...state.machine,
        summary: {
          ...state.machine.summary,
          data: replaceSummaryGroup(state.machine.summary.data, {
            ...stateSummaryGroup,
            events: {
              ...stateSummaryGroup.events,
              loaded: false,
              loading: false,
              error,
            },
          }),
        },
      },
    };
  },
};

export function* fetchEventsAmountSummaryGroupSaga({ payload: { machineId, summaryGroup } }) {
  const state = yield select();
  const stateSummaryGroup = yield call(findSummaryGroup, state.machine.summary.data, summaryGroup);
  const filters = yield call(getGroupedEventsFilters, state);

  const { response, error } = yield call(
    eventsApi.getEventsAmountOfSummaryGroup,
    {
      ...filters,
      machineId,
      category: stateSummaryGroup.category,
      title: stateSummaryGroup.title,
      fromIndex: stateSummaryGroup.events.fromIndex,
      toIndex: stateSummaryGroup.events.toIndex,
      mode: stateSummaryGroup.mode,
    },
  );

  if (typeof response === 'number' && response >= 0) {
    yield put(fetchEventsAmountSummaryGroupSuccess({
      amount: response,
      summaryGroup,
    }));
  } else {
    yield put(fetchEventsAmountSummaryGroupFail({
      error,
      summaryGroup,
    }));
  }
}

export function* watchFetchEventsAmountSummaryGroup() {
  yield takeEvery(resetSummaryGroupEventsPagination.getType(), fetchEventsAmountSummaryGroupSaga);
}
