import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SortHeader from '../../../../sortHeader/SortHeader';
import { SORT_BY_LABEL } from '../../../../../../../../common/constants/configRepoExplorer';
import LabelFilterContainer from './labelFilter/LabelFilter.container.js';

const Search = ({
  children,
  onSortChange,
  sortWay,
  sortOption,
}) => (
  <ul className="locations-list locations-list-select-all">
    <li>
      <div className="flex-name">
        <SortHeader
          option={SORT_BY_LABEL}
          way={(sortOption === SORT_BY_LABEL) ? sortWay : null}
          onClick={onSortChange}
        >
          <FormattedMessage
            id="common.title"
            defaultMessage="Title"
          />
        </SortHeader>
        <div className="rel-wrap">
          <div className="flex">
            <div className="flex-icon">
              <LabelFilterContainer />
            </div>
          </div>
        </div>
      </div>
    </li>
    { children }
  </ul>
);

Search.propTypes = {
  onSortChange: PropTypes.func.isRequired,
  sortWay: PropTypes.string,
  sortOption: PropTypes.string,
  children: PropTypes.node,
};

export default Search;
