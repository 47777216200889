import React from 'react';
import PropTypes from 'prop-types';
import ImagePlaceholder from './ImagePlaceholder';

const Image = ({
  url,
  className,
  contain,
  showPlaceholderOnly,
}) => {
  const style = {
    backgroundImage: `url(${url})`,
    backgroundSize: contain && 'contain',
  };

  if (showPlaceholderOnly) return (<ImagePlaceholder />);
  return (<div className={`${className} image-render`} style={style} />);
};

Image.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  contain: PropTypes.bool.isRequired,
  showPlaceholderOnly: PropTypes.bool.isRequired,
};

Image.defaultProps = {
  contain: false,
};

export default Image;
