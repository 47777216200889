import React, { useState } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { setLocationFilter } from '../../../redux/modules/location.filter.module';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';

type Props = {
  locationName: string,
  index: number,
  array: string[]
  parentHandler: (string) => void
  children?: LocationResponse[]
};

type LocationResponse = {};

export const LocationItem: React.FC<Props> = ({ locationName, index, array, parentHandler, children }) => {

  const [showDropdown, showDropdownSet] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault();
    if (children) {
      showDropdownSet(!showDropdown);
    } else {
      dispatch(setLocationFilter(locationName));
      parentHandler(locationName);
    }

  };

  const handleChildClick = (newLocation: string): (e) => void => (e: Event): void => {
    e.preventDefault();
    showDropdownSet(false);
    dispatch(setLocationFilter(newLocation));
    parentHandler(newLocation);
  };

  const closeChild = () => {
    showDropdownSet(false);
  };

  const clickRef = useClickOutside(children ? closeChild : () => {});

  const makeChildElement = (child): JSX.Element => <div
    style={{ background: 'white', padding: '10px', width: '112px' }} key={child.name}
    onClick={handleChildClick(child.name)}>{child.name}</div>;

  return (
    <div style={showDropdown ? {
      borderStyle: 'solid',
      borderColor: 'black',
      borderWidth: '1px',
    } : {}}>
      <div style={{ position: 'static', width: 'inherit' }} ref={clickRef}>
        <li className={classnames({ active: false })} key={locationName} style={{ height: '100%', width: 'inherit' }}>
          <div className="breadcrumps-title" style={{ top: '50%', marginTop: '-5px', position: 'static' }}
               onClick={handleClick}>
            <span>{locationName}</span>
            <span className="button__plus hide">
                <span className="icon-plus"/>
              </span>
            {!(index === (array.length - 1)) && (
              <span className="fa fa-chevron-right"/>
            )}
            <span className="fa fa-chevron-down"/>
          </div>
          {children &&
            <div style={{
              display: showDropdown ? 'block' : 'none',
              overflow: 'visible',
              position: 'fixed',
              // top: '50px',
            }}>
              <div style={{ borderStyle: 'solid',
                borderColor: 'black',
                borderWidth: '1px',
              }}>
                {children.map(makeChildElement)}
              </div>
            </div>}
        </li>
      </div>
    </div>
  );
};