import { connect } from 'react-redux';

import CalendarCloneModal from './CalendarCloneModal';
import { CP_CALENDAR_CLONE } from '../../../../redux/constants/modals.constants';
import { closeModal } from '../../../../redux/modules/modals.module';
import { getModalPayload, isModalOpen } from '../../../../redux/selectors/modals.selector';

export function mapStateToProps(state) {
  const { calendar } = getModalPayload(state, CP_CALENDAR_CLONE);

  return {
    isOpen: isModalOpen(state, CP_CALENDAR_CLONE),
    calendar,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    close: () => dispatch(closeModal(CP_CALENDAR_CLONE)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarCloneModal);
