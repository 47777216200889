import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const fetchMachineTools = createAction(
  'fetch machine tools',
  (machineId) => machineId,
);

export const fetchMachineToolsSuccess = createAction(
  'fetch machine tools - success',
  (list) => list,
);

export const fetchMachineToolsFail = createAction(
  'fetch machine tools - fail',
  (error) => error,
);

export const reducer = {
  [fetchMachineTools]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      tools: {
        ...state.machine.tools,
        list: [],
        loading: true,
        loaded: false,
        error: null,
      },
    },
  }),
  [fetchMachineToolsSuccess]: (state, list = []) => ({
    ...state,
    machine: {
      ...state.machine,
      tools: {
        ...state.machine.tools,
        list,
        loading: false,
        loaded: true,
        error: null,
      },
    },
  }),
  [fetchMachineToolsFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      tools: {
        ...state.machine.tools,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* fetchMachineToolsSaga({ payload: machineId }) {
  const { response, error } = yield call(api.machines.getTools, machineId);

  if (response) {
    yield put(fetchMachineToolsSuccess(response.list));
  } else {
    yield put(fetchMachineToolsFail(error));
  }
}

export function* watchFetchMachineTools() {
  yield takeEvery(fetchMachineTools.getType(), fetchMachineToolsSaga);
}
