import { defineMessages } from 'react-intl';

export default defineMessages({
  resolve_element: {
    id: 'maintenance.stream.event.resolve_element',
    defaultMessage: 'Resolve Element',
  },
  title: {
    id: 'maintenance.stream.event.resolve.title',
    defaultMessage: 'Title',
  },
  comments: {
    id: 'maintenance.stream.event.resolve.comments',
    defaultMessage: 'Comments',
  },
  type_title_here: {
    id: 'maintenance.stream.event.resolve.type_title_here',
    defaultMessage: 'Type title here...',
  },
  type_some_text_here: {
    id: 'maintenance.stream.event.resolve.type_some_text_here',
    defaultMessage: 'Type some text here...',
  },
  title_required: {
    id: 'maintenance.stream.event.resolve.title.required',
    defaultMessage: 'Title is required',
  },
  title_too_long: {
    id: 'maintenance.stream.event.resolve.title.too_long',
    defaultMessage: 'Title is too long',
  },
  comments_required: {
    id: 'maintenance.stream.event.resolve.comments.required',
    defaultMessage: 'Comments are required',
  },
  comments_too_long: {
    id: 'maintenance.stream.event.resolve.comments.too_long',
    defaultMessage: 'Comments are too long',
  },
});
