import { connect } from 'react-redux';

import SyncFailedModal from './SyncFailedModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { SMP_SYNC_FAILED } from '../../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';

export const mapStateToProps = (state) => ({
  ...getModalPayload(state, SMP_SYNC_FAILED),
});

export const mapDispatchToProps = (dispatch) => ({
  close: () => {
    dispatch(closeModal(SMP_SYNC_FAILED));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SyncFailedModal);
