import React from 'react';
import { stack } from 'd3-shape';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { getStylesByProp, getX as getPointX } from '../../../../../core/common/reportChart';
import { StackedBar } from './StackedBar';
import { Todo } from '../../../../../../../common/types/common';

interface Props {
  data: Todo[];
  keys: Todo[];
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number>;
}

const getX = (d) => getPointX(d.data);
const getY = (d) => d[0];
const getYMax = (d) => d[1];

export const StackedBarsChart: React.FC<Props> = ({
  data, keys, xScale, yScale,
}) => {
  const colors = keys.map((prop) => getStylesByProp(prop).area);
  const stackGenerator = stack().keys(keys);
  const series = stackGenerator(data);
  const width = xScale.bandwidth();

  return (
    <g>
      {
        series.reverse().map((s, i) => (
          <StackedBar
            className={colors[colors.length - i - 1]}
            data={s}
            xScale={xScale}
            yScale={yScale}
            getY={getY}
            getX={getX}
            getYMax={getYMax}
            width={width}
            key={i}
          />
        ))
      }
    </g>
  );
};
