import { createAction } from 'redux-act';
import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';

import * as api from '../../core/api';
import * as selector from '../selectors/dashboard.selector';
import { calculateOee } from '../../core/common/oee';

export const fetchOee = createAction(
  'fetch dashboard OEE',
);

export const fetchOeeSuccess = createAction(
  'fetch dashboard OEE - success',
  (oee) => oee,
);

export const fetchOeeFail = createAction(
  'fetch Dashboard OEE - fail',
  (error) => error,
);

export const reducer = {
  [fetchOee]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      oee: {
        ...state.dashboard.oee,
        data: null,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchOeeSuccess]: (state, oee) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      oee: {
        ...state.dashboard.oee,
        data: oee,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [fetchOeeFail]: (state, error) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      oee: {
        ...state.dashboard.oee,
        data: null,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchOeeSaga() {
  const state = yield select();
  const itemFilters = yield call(selector.itemFilters, state);

  const { response, error } = yield call(api.oee.getDashboardOee, itemFilters);

  if (response) {
    const oee = yield call(calculateOee, response.oee);
    yield put(fetchOeeSuccess({
      ...response,
      oee,
    }));
  } else {
    yield put(fetchOeeFail(error));
  }
}

export function* watchFetchOee() {
  yield takeEvery(fetchOee.getType(), fetchOeeSaga);
}
