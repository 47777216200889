import { connect } from 'react-redux';

import ToolsTable from './ToolsTable';
import * as selector from '../../../redux/selectors/tools.selector';
import { fetchTools } from '../../../redux/modules/tools.fetch.module';

export const mapStateToProps = (state) => {
  const isLoaded = selector.isLoaded(state);
  const isLoading = selector.isLoading(state);
  const pagination = selector.getToolsPagination(state);

  return {
    paginationAvailable: isLoaded && !!pagination && pagination.total_pages > 1,
    pagination,
    tools: selector.getTools(state),
    isLoading,
    query: selector.getQueryFilterValue(state),
  };
};

export const mapDispatchToProps = {
  choosePage: fetchTools,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolsTable);
