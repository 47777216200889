import { createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { findByName, getChildLocations } from '../../core/api/locations';
import { CallResult } from '../util';

export const setLocationFilter = createAction(
  'set location filter',
  (location) => ({ payload: { location } }),
);

export const setLocationFilterChildren = createAction(
  'set location filter children',
  (children) => ({ payload: { children } }),
);

export const reducer = {
  [setLocationFilter.type]: (state, { location }) => ({
    ...state,
    location: {
      ...state.location,
      filter: location,
    },
  }),
  [setLocationFilterChildren.type]: (state, { children }) => ({
    ...state,
    location: {
      ...state.location,
      filterChildren: children,
    }
    ,
  }
  ),
};

export function* setLocationFilterSaga({ payload: { location } }) {

  if (location === '') {
    const response: CallResult<typeof getChildLocations> = yield call(getChildLocations, '', { per_page: 10000 });
    if (response.success && 'list' in response.data) {
      yield put(setLocationFilterChildren(response.data.list));
    }
    return;
  }

  const { response: nameResponse } = yield call(findByName, location);

  const parentLocationId = nameResponse?.list[0]?.id;

  if (parentLocationId) {
    const response: CallResult<typeof getChildLocations> = yield call(getChildLocations, parentLocationId, { per_page: 10000 });
    if (response.success && 'list' in response.data) {
      yield put(setLocationFilterChildren(response.data.list));
    }
  }
}

export function* watchSetLocationFilter() {
  yield takeEvery(setLocationFilter, setLocationFilterSaga);
}