import { defineMessages } from 'react-intl';

export default defineMessages({
  addObject: {
    id: 'admin_page.labels.add_object',
    defaultMessage: 'Add {object}',
  },
  viewObject: {
    id: 'admin_page.labels.view_object',
    defaultMessage: 'View {object}',
  },
  editObject: {
    id: 'admin_page.labels.edit_object',
    defaultMessage: 'Edit {object}',
  },
  renameObject: {
    id: 'admin_page.labels.rename_object',
    defaultMessage: 'Rename {object}',
  },
  removeObject: {
    id: 'admin_page.labels.remove_object',
    defaultMessage: 'Remove {object}',
  },
  selectedObject: {
    id: 'admin_page.labels.selected_object',
    defaultMessage: 'Selected {object}',
  },
  selectObject: {
    id: 'admin_page.labels.select_object',
    defaultMessage: 'Select {object}',
  },
  removeObjectWarning: {
    id: 'admin_page.warnings.object_remove',
    defaultMessage: '{object} will be removed. You can’t undo this action.',
  },
  cantRemoveError: {
    id: 'admin_page.errors.object_cannot_be_removed',
    defaultMessage: '{object} cannot be removed, because {error}',
  },
});
