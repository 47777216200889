import { useEffect } from 'react';
import PropTypes from 'prop-types';

const CustomHead = ({ config }) => {
  const { appName, features } = config;
  const isPreviewEnabled = features && !!features.preview;
  const appTitle = isPreviewEnabled ? `${appName} (Preview enabled)` : appName;

  useEffect(() => {
    document.title = appTitle;
  }, [appTitle]);

  return null;
};

CustomHead.propTypes = {
  config: PropTypes.object.isRequired,
};

export default CustomHead;
