import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';
import { updateIntl } from 'react-intl-redux';
import moment from 'moment';

import * as Api from '../../core/api';

export const changeLocale = createAction(
  'change locale',
  (locale, callback = () => {}) => ({ locale, callback }),
);

export function* changeLocaleSaga({ payload: { locale, callback } }) {
  const { response } = yield call(Api.locales.getLocales, locale);

  if (response) {
    localStorage.setItem('locale', locale);
    yield put(updateIntl({ locale, messages: response }));
    moment.locale(locale);
  }

  callback();
}

export function* watchChangeLocale() {
  yield takeEvery(changeLocale.getType(), changeLocaleSaga);
}
