import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import OULocation from './OULocation';

export default function OULocations({ locations }) {
  return (
    <div className="col--in height6">

      <div className="col--title">
        <FormattedMessage
          id="teams.location"
          defaultMessage="Location"
        />
      </div>

      <div className="scroll-block">
        <table className="table">
          <thead>
            <tr>
              <td>
                <FormattedMessage
                  id="organizations.locations.name"
                  defaultMessage="Name"
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {
              locations.map((location) => <OULocation key={location.id} location={location} />)
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

OULocations.propTypes = {
  locations: PropTypes.array.isRequired,
};
