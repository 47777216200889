import { newPasswordField, confirmNewPasswordField } from '../forms/profile.form';

export const validateNewPassword = (body) => {
  const errors = {};

  if (body[newPasswordField] && body[newPasswordField] !== body[confirmNewPasswordField]) {
    errors[newPasswordField] = 'users.error.password.noMatch';
    errors[confirmNewPasswordField] = 'users.error.password.noMatch';
  }

  return errors;
};
