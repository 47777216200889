import React from 'react';
import { FormattedMessage } from 'react-intl';

import Kpi from '../../_tools/kpi/Kpi';
import { Todo } from '../../../../../common/types/common';
import { getDatatronStatsIndicators } from '../../../core/common/indicators';


interface Props {
  datatronStats: Todo
}

const Indicators: React.FC<Props> = ({ datatronStats }) => {
  const indicators = getDatatronStatsIndicators(datatronStats);

  if (indicators.every((indicator) => indicator.value === -1)) {
    return <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <FormattedMessage
        id="datatron.report.indicators.noIndicators"
        defaultMessage="Monitoring is disabled or unavailable"
      /></div>;
  }
  return (
    <div className="indicators-info">
      <div className="h1">
        <FormattedMessage
          id="datatron.stats"
          defaultMessage="Datatron stats"
        />
      </div>
      <div className="round-charts round-charts-inline round-charts-center">
        {
          indicators.map((indicator, index) => (
            <Kpi
              data={indicator}
              key={index}
              background="kpi-background--light"
              percentageClass="kpi__percentage"
            />
          ))
        }
      </div>
    </div>
  );
};

export default Indicators;
