import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { SCHEDULE_CALENDAR } from '../../../../../../common/enums/calendarTypes';

const Title = ({
  type,
  name,
}) => {
  if (type === SCHEDULE_CALENDAR) {
    return (
      <FormattedMessage
        id="calendars.modals.clone.schedule.title"
        defaultMessage='Clone Schedule "{name}"'
        tagName="h3"
        values={{ name }}
      />
    );
  }

  return (
    <FormattedMessage
      id="calendars.modals.clone.shift.title"
      defaultMessage='Clone Shift "{name}"'
      tagName="h3"
      values={{ name }}
    />
  );
};

Title.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Title;
