import React from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import * as form from '../../../../../../redux/constants/admin.role.form.constants';
import FormEditableList from '../../../../../_tools/formFields/formEditableList/FormEditableList';
import commonCoreMessages from '../../../../../../messages/common.core.messages';

export const RoleFormBody = ({ readOnly, allUsersOptions, intl }) => {
  const usersLabel = intl.formatMessage(commonCoreMessages.users);

  return (
    <div className={cn('modal-form-in', { 'form--read-only': readOnly })}>
      <Field
        name={form.USERS_FIELD}
        component={FormEditableList}
        options={allUsersOptions}
        disabled={readOnly}
        label={usersLabel}
      />
    </div>
  );
};

RoleFormBody.propTypes = {

  allUsersOptions: PropTypes.array.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default injectIntl(RoleFormBody);
