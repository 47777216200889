import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const adminFetchAllOrganizations = createAction(
  'admin: fetch all organizations',
);

export const adminFetchAllOrganizationsSuccess = createAction(
  'admin: fetch all organizations - success',
  (list) => list,
);

export const adminFetchAllOrganizationsFail = createAction(
  'admin: fetch all organizations - fail',
  (error) => error,
);

export const reducer = {
  [adminFetchAllOrganizations]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        all: {
          ...state.admin.organizations.all,
          list: [],
          loaded: false,
          loading: true,
          error: null,
        },
      },
    },
  }),

  [adminFetchAllOrganizationsSuccess]: (state, list) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        all: {
          ...state.admin.organizations.all,
          list,
          loaded: true,
          loading: false,
          error: null,
        },
      },
    },
  }),

  [adminFetchAllOrganizationsFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        all: {
          ...state.admin.organizations.all,
          list: [],
          loaded: false,
          loading: false,
          error,
        },
      },
    },
  }),
};

export function* adminFetchAllOrganizationsSaga() {
  const { response, error } = yield call(api.admin.getAllOrganizations);

  if (response) {
    yield put(adminFetchAllOrganizationsSuccess(response));
  } else {
    yield put(adminFetchAllOrganizationsFail(error));
  }
}

export function* watchAdminFetchAllOrganizations() {
  yield takeEvery(adminFetchAllOrganizations.getType(), adminFetchAllOrganizationsSaga);
}
