import { connect } from 'react-redux';

import MenuItem from './MenuItem';
import * as selector from '../../../redux/selectors/routing.selector';

export function mapStateToProps(state, { to }) {
  return {
    isActive: selector.pathname(state).includes(to),
  };
}

export default connect(mapStateToProps)(MenuItem);
