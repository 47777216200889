import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as selector from '../../../redux/selectors/aiApps.selector';
import { fetchAiApps, updateAiAppsPage } from '../../../redux/modules/aiApps.fetch.module';
import AiAppsEmptyPlaceholder from '../placeholder/AiAppsEmptyPlaceholder';
import Loader from '../../_tools/loader/Loader';
import TableHeader from './header/TableHeader';
import AiAppTableRow from './aiAppsTableRow/AiAppTableRow';
import PaginationBar from '../../common/paginationBar/PaginationBar';
import AiAppAddButton from '../addButtons/AiAppAddButton';

const AiAppsTable: React.FC = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(selector.isLoaded);
  const isLoading = useSelector(selector.isLoading);

  const pagination = useSelector(selector.getAiAppsPagination);
  const transformedPaginationProps = {
    ...pagination,
    total_pages: pagination.totalPages,
    current_page: pagination.page,
  };
  const aiApps = useSelector(selector.getAiApps);

  const paginationAvailable = isLoaded && !!pagination && pagination.totalPages > 1;
  const choosePage = (page: number) => {
    dispatch(updateAiAppsPage(page));
    dispatch(fetchAiApps());
  };

  return (
    <div className='table--wrapped'>
      <div className='table--title'>
        <i className='icon icon-chart' />
        <FormattedMessage defaultMessage='AI Apps' id='topBar.aiApps' />
        <div className='add-anomaly-btn'>
          <AiAppAddButton />
        </div>
      </div>
      <table className='lined--table valign-middle ai-app-table'>
        <TableHeader />
        <tbody>
          {aiApps.map((aiApp) => (
            <AiAppTableRow key={aiApp.id} aiApp={aiApp} />
          ))}
        </tbody>
      </table>

      {paginationAvailable && (
        <PaginationBar {...transformedPaginationProps} choosePage={choosePage} />
      )}
      <Loader show={isLoading} />
      {!aiApps.length && <AiAppsEmptyPlaceholder />}
    </div>
  );
};

export default AiAppsTable;
