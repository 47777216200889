import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Events from './event/Events';
import DraggableEvent from './event/DraggableEvent';
import stopPropagation from '../../../../_tools/stopPropagation';
import { SHIFT_CALENDAR, SCHEDULE_CALENDAR } from '../../../../../../../common/enums/calendarTypes';

const ROW_TYPE = {
  [SHIFT_CALENDAR]: 'shift-row',
  [SCHEDULE_CALENDAR]: 'schedule-row',
};

export default function EventsTableRow({
  events,
  title,
  tableWidth,
  widthPerSecond,
  onClick,
  day,
  readOnly,
  draggable,
  isToday,
  calendarId,
  type,
  enableCreateCalendarEvent,
  disableCreateCalendarEvent,
}) {
  function handleClick() {
    onClick(day);
  }

  function handleTitleClick(e) {
    stopPropagation(e);
  }

  const className = classnames(ROW_TYPE[type], {
    info: isToday,
  });

  return (
    <tr onClick={handleClick} className={className}>
      <td className="headcol" onClick={handleTitleClick}>
        <div className="schedule-in">
          <span>{ title }</span>
        </div>
      </td>
      <td>
        <div className="schedule-in">
          { draggable ? (
            events.map((event, i) => (
              <DraggableEvent
                event={event}
                key={`${event.type} ${event.startDateTime}`}
                day={day}
                tableWidth={tableWidth}
                widthPerSecond={widthPerSecond}
                readOnly={readOnly}
                calendarId={calendarId}
                endDateLimit={events[i + 1] ? events[i + 1].startDateTime : ''}
                startDateLimit={events[i - 1] ? events[i - 1].endDateTime : ''}
                rowType={ROW_TYPE[type]}
                enableCreateCalendarEvent={enableCreateCalendarEvent}
                disableCreateCalendarEvent={disableCreateCalendarEvent}
              />
            ))) : (
            events.map((event) => (
              <Events
                event={event}
                key={`${event.type} ${event.startDateTime}`}
                day={day}
                widthPerSecond={widthPerSecond}
                readOnly={readOnly}
              />
            ))
          )}
        </div>
      </td>
      <td>
        <div className="schedule-in" />
      </td>
      <td>
        <div className="schedule-in" />
      </td>
      <td>
        <div className="schedule-in" />
      </td>
      <td>
        <div className="schedule-in" />
      </td>
      <td>
        <div className="schedule-in" />
      </td>
      <td>
        <div className="schedule-in" />
      </td>
      <td>
        <div className="schedule-in" />
      </td>
      <td>
        <div className="schedule-in" />
      </td>
      <td>
        <div className="schedule-in" />
      </td>
      <td>
        <div className="schedule-in" />
      </td>
      <td>
        <div className="schedule-in" />
      </td>
    </tr>
  );
}

EventsTableRow.propTypes = {
  events: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  tableWidth: PropTypes.number.isRequired,
  widthPerSecond: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  day: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  draggable: PropTypes.bool.isRequired,
  isToday: PropTypes.bool.isRequired,
  calendarId: PropTypes.string.isRequired,
  type: PropTypes.string,
  enableCreateCalendarEvent: PropTypes.func.isRequired,
  disableCreateCalendarEvent: PropTypes.func.isRequired,
};
