import React from 'react';
import { FormattedMessage } from 'react-intl';

const NoSearchResults = () => (
  <li className="disabled">
    <span className="search-alls-text">
      <FormattedMessage
        id="search.no_results"
        defaultMessage="No results"
      />
    </span>
  </li>
);

export default NoSearchResults;
