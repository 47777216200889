import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  SORT_WAY_ASC,
  SORT_WAY_DESC,
} from '../../../../../common/constants';

function SortHeader({
  option, way, children, onClick,
}) {
  function onSortChange() {
    onClick(option);
  }

  const style = classnames(
    'asc_desc',
    {
      asc: way === SORT_WAY_ASC,
      desc: way === SORT_WAY_DESC,
    },
  );

  return (
    <span className={style} onClick={onSortChange}>
      { children }
    </span>
  );
}

SortHeader.propTypes = {
  option: PropTypes.string.isRequired,
  way: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SortHeader;
