import { createAction } from 'redux-act';
import {
  put, takeLatest, delay,
} from 'redux-saga/effects';

import * as fetchEventModule from './dashboard.fetch.events.module';

export const EVENTS_ON_ONE_PAGE = 10;

export const loadMoreEvents = createAction(
  'load more events',
);

export const eventsNextPage = createAction(
  'set next events portion for load',
);

export const resetEventsPagination = createAction(
  'reset events pagination',
);

export const reducer = {
  [eventsNextPage]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      loadEventsFrom: state.dashboard.loadEventsTo,
      loadEventsTo: state.dashboard.loadEventsTo + EVENTS_ON_ONE_PAGE,
    },
  }),
  [resetEventsPagination]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      loadEventsFrom: 0,
      loadEventsTo: EVENTS_ON_ONE_PAGE,
    },
  }),
};

export function* loadMoreEventsSaga() {
  yield delay(500);

  yield put(eventsNextPage());
  yield put(fetchEventModule.fetchEvents());
}

export function* watchLoadMoreEvents() {
  yield takeLatest(loadMoreEvents.getType(), loadMoreEventsSaga);
}
