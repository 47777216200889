import { connect } from 'react-redux';

import Select from './Select';
import commonMessages from '../../../../messages/common.messages';

const options = [
  { label: commonMessages.yes, value: true },
  { label: commonMessages.no, value: false },
];

export const mapStateToProps = (state, { input }) => ({
  options,
  onChange: (option) => input.onChange(option.value),
  translateOptionsLabels: true,
});

export default connect(mapStateToProps)(Select);
