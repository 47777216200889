import {
  TOOLS_MANAGER,
  TOOLS_VIEWER, Role,
} from '../constants/permissions';

export default class ToolsPermissions {
  private isToolsManager: boolean;

  private isToolsViewer: boolean;

  constructor(roles: Role[]) {
    this.isToolsManager = roles.indexOf(TOOLS_MANAGER) !== -1;
    this.isToolsViewer = roles.indexOf(TOOLS_VIEWER) !== -1;
  }

  canAddTool() {
    return this.isToolsManager;
  }

  canEditTool() {
    return this.isToolsManager;
  }

  canViewTools() {
    return this.isToolsManager || this.isToolsViewer;
  }
}
