import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import ComplexModalContainer from '../../core/complexModal/ComplexModal.container';
import commonCoreMessages from '../../../../messages/common.core.messages';
import adminPageMessages from '../../../../messages/adminPage.messages';
import reportPageMessages from '../../../../messages/report.page.messages';
import ModalMainFrameContainer from './modalFrames/mainFrame/ModalMainFrame.container';
import DatesFrameContainer from './modalFrames/datesFrame/DatesFrame.container';
import MachinesFiltersFrameContainer from './modalFrames/machinesFilters/MachinesFiltersFrame.container';
import DataPointsFiltersFrameContainer from './modalFrames/dataPointsFilters/DataPointsFiltersFrame.container';
import AddDataPointsFrameContainer from './modalFrames/addDataPoints/AddDataPointsFrame.container';
import { _PAGE_REPORT_FORM } from '../../../../redux/constants/modals.constants';
import * as frames from '../../../../core/common/report.form';

const FRAME_PROPS = {
  [frames.MODAL_MAIN_FRAME]: {
    id: frames.MODAL_MAIN_FRAME,
    component: <ModalMainFrameContainer />,
    mainClassName: 'modal-show-main',
  },
  [frames.MODAL_MACHINES_FILTERS_FRAME]: {
    id: frames.MODAL_MACHINES_FILTERS_FRAME,
    component: <MachinesFiltersFrameContainer />,
    mainClassName: 'modal-show-filters',
  },
  [frames.MODAL_DATA_POINTS_FILTERS_FRAME]: {
    id: frames.MODAL_DATA_POINTS_FILTERS_FRAME,
    component: <DataPointsFiltersFrameContainer />,
    mainClassName: 'modal-show-datapoint',
  },
  [frames.MODAL_DATA_POINTS_ADD_FRAME]: {
    id: frames.MODAL_DATA_POINTS_ADD_FRAME,
    component: <AddDataPointsFrameContainer />,
    mainClassName: 'modal-show-datapoint-select',
  },
  [frames.MODAL_DATE_PERIOD_FRAME]: {
    id: frames.MODAL_DATE_PERIOD_FRAME,
    component: <DatesFrameContainer />,
    mainClassName: 'modal-show-datepicker',
  },
};

export const ReportModule = ({
  intl,
  isAddForm,
  isEditForm,
  reportId,
  createReport,
  updateReport,
  submitting,
  pristine,
  close,
}) => {
  const datePeriodFrameTitle = intl.formatMessage(adminPageMessages.selectObject, {
    object: intl.formatMessage(commonCoreMessages.datePeriod),
  });
  const machinesFiltersFrameTitle = intl.formatMessage(adminPageMessages.selectObject, {
    object: intl.formatMessage(commonCoreMessages.machines),
  });
  const dataPointsFiltersFrameTitle = intl.formatMessage(adminPageMessages.selectObject, {
    object: intl.formatMessage(commonCoreMessages.dataPoints),
  });
  const dataPointsAddFrameTitle = intl.formatMessage(adminPageMessages.addObject, { object: '' });
  const untitledTabName = intl.formatMessage(reportPageMessages.untitledTab);

  const modalConfig = {
    title: null,
    ...FRAME_PROPS[frames.MODAL_MAIN_FRAME],
  };

  const object = intl.formatMessage(commonCoreMessages.report);
  let handleSubmit = () => {};

  if (isAddForm) {
    handleSubmit = (e) => {
      e.preventDefault();
      createReport({ untitledTabName });
    };
    modalConfig.title = adminPageMessages.addObject;
  } else if (isEditForm) {
    handleSubmit = (e) => {
      e.preventDefault();
      updateReport(reportId);
    };

    modalConfig.title = adminPageMessages.editObject;
  }

  modalConfig.title = intl.formatMessage(modalConfig.title, { object });
  modalConfig.component = (
    <ModalMainFrameContainer
      handleSubmit={handleSubmit}
      handleCancel={close}
      submitting={submitting}
      pristine={pristine}
      isAddForm={!!isAddForm}
      isEditForm={!!isEditForm}
    />
  );
  modalConfig.children = [
    {
      title: datePeriodFrameTitle,
      ...FRAME_PROPS[frames.MODAL_DATE_PERIOD_FRAME],
    },
    {
      title: machinesFiltersFrameTitle,
      ...FRAME_PROPS[frames.MODAL_MACHINES_FILTERS_FRAME],
    },
    {
      title: dataPointsFiltersFrameTitle,
      ...FRAME_PROPS[frames.MODAL_DATA_POINTS_FILTERS_FRAME],
      children: [
        {
          title: dataPointsAddFrameTitle,
          ...FRAME_PROPS[frames.MODAL_DATA_POINTS_ADD_FRAME],
        },
      ],
    },
  ];

  return (
    <ComplexModalContainer
      modalId={_PAGE_REPORT_FORM}
      modalConfig={modalConfig}
      close={close}
      submit={handleSubmit}
    />
  );
};

ReportModule.propTypes = {

  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isAddForm: PropTypes.bool,
  isEditForm: PropTypes.bool,
  reportId: PropTypes.string,
  createReport: PropTypes.func,
  updateReport: PropTypes.func,
  close: PropTypes.func,
};

ReportModule.defaultProps = {
  isAddForm: false,
  isEditForm: false,
};

ReportModule.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default injectIntl(ReportModule);
