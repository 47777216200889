import { createAction } from 'redux-act';

export const openComponent = createAction(
  'open component',
  (id, payload) => ({ id, payload }),
);

export const closeComponent = createAction(
  'close component',
  (id) => id,
);

export const reducer = {
  [openComponent]: (state, { id, payload }) => ({
    ...state,
    componentsVisibility: {
      ...state.componentsVisibility,
      visible: {
        ...state.componentsVisibility.visible,
        [id]: true,
      },
      payloads: {
        ...state.componentsVisibility.payloads,
        [id]: payload,
      },
    },
  }),
  [closeComponent]: (state, id) => ({
    ...state,
    componentsVisibility: {
      ...state.componentsVisibility,
      visible: {
        ...state.componentsVisibility.visible,
        [id]: false,
      },
      payloads: {
        ...state.componentsVisibility.payloads,
        [id]: null,
      },
    },
  }),
};
