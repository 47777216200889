import React from 'react';
import PropTypes from 'prop-types';
import { forEach } from 'lodash';

import LineContainer from './elements/Line.container';
import AreaContainer from './elements/Area.container';
import { Y_AXIS_PADDING } from '../chart.constants';

const AreaChart = ({
  yLeftScale,
  yRightScale,
  xScale,
  height,
  leftAxisChartPoints,
  rightAxisChartPoints,
  leftAxisEntries,
  rightAxisEntries,
  isLeftAxisEnabled,
  isRightAxisEnabled,
}) => {
  const entities = [];

  if (isLeftAxisEnabled && leftAxisEntries.length > 0) {
    forEach(
      leftAxisEntries,
      (entry) => {
        entities.push(
          <LineContainer
            chartPoints={leftAxisChartPoints}
            height={height}
            xScale={xScale}
            yScale={yLeftScale}
            entry={entry}
            key={`line-${entry.id}`}
          />,
        );
        entities.push(
          <AreaContainer
            chartPoints={leftAxisChartPoints}
            height={height}
            xScale={xScale}
            yScale={yLeftScale}
            entry={entry}
            key={`area-${entry.id}`}
          />,
        );
      },
    );
  }

  if (isRightAxisEnabled && rightAxisEntries.length > 0) {
    forEach(
      rightAxisEntries,
      (entry) => {
        entities.push(
          <LineContainer
            chartPoints={rightAxisChartPoints}
            height={height}
            xScale={xScale}
            yScale={yRightScale}
            entry={entry}
            key={`line-${entry.id}`}
          />,
        );
        entities.push(
          <AreaContainer
            chartPoints={rightAxisChartPoints}
            height={height}
            xScale={xScale}
            yScale={yRightScale}
            entry={entry}
            key={`area-${entry.id}`}
          />,
        );
      },
    );
  }

  return (
    <g transform={`translate(${Y_AXIS_PADDING}, 0)`}>
      { entities }
    </g>
  );
};

AreaChart.propTypes = {
  yLeftScale: PropTypes.func,
  yRightScale: PropTypes.func,
  xScale: PropTypes.func.isRequired,
  leftAxisChartPoints: PropTypes.array.isRequired,
  leftAxisEntries: PropTypes.array.isRequired,
  isLeftAxisEnabled: PropTypes.bool.isRequired,
  rightAxisChartPoints: PropTypes.array.isRequired,
  rightAxisEntries: PropTypes.array.isRequired,
  isRightAxisEnabled: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
};

export default AreaChart;
