export const REPORT_FORM_NAME = 'REPORT_FORM_NAME';

export const NAME_FIELD = 'name';
export const TYPE_FIELD = 'type';
export const DATES_FIELD = 'dates';
export const MACHINES_FILTERS_FIELD = 'filters_machines';
export const DATA_POINTS_FILTERS_FIELD = 'filters_dataPoints';
export const REPORTS_TAB_FIELD = 'reportsTabId';

export const REPORT_PROPS = [
  NAME_FIELD,
  TYPE_FIELD,
  DATES_FIELD,
  MACHINES_FILTERS_FIELD,
  DATA_POINTS_FILTERS_FIELD,
  REPORTS_TAB_FIELD,
];
