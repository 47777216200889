import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import * as form from '../../../../../../redux/constants/admin.location.form.constants';
import commonMessages from '../../../../../../messages/common.messages';
import commonFieldsMessages from '../../../../../../messages/common.fields.messages';
import formsPlaceholdersMessages from '../../../../../../messages/forms.placeholders.messages';
import { FormField } from '../../../../../_tools';
import LocationTypeSelectContainer from '../../../../../_tools/formFields/select/LocationTypeSelect.container';
import LocationModalContainer from './LocationModal.container';
import PathButtonContainer from './PathButton.container';

export const LocationFormBody = ({ readOnly, parent }) => (
  <div className={cn('modal-form-in', { 'form--read-only': readOnly })}>
    <FormField
      label={commonMessages.name.id}
      name={form.NAME_FIELD}
      placeholder={formsPlaceholdersMessages.name.id}
      disabled={readOnly}
      autoFocus
    />
    <label>
      <FormattedMessage {...commonMessages.type} />
      <Field
        component={LocationTypeSelectContainer}
        name={form.TYPE_FIELD}
        isDisabled={readOnly}
      />
    </label>
    <label>
      <FormattedMessage {...commonFieldsMessages.parent} />
      <Field
        component={PathButtonContainer}
        name={form.PARENT_ID_FIELD}
        location={parent}
        disabled={readOnly}
      />
    </label>

    <Field
      name={form.PARENT_ID_FIELD}
      component={LocationModalContainer}
      location={parent}
    />
  </div>
);

LocationFormBody.propTypes = {
  parent: PropTypes.object,
};

export default LocationFormBody;
