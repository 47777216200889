import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import TabsBarItem from './TabsBarItem';
import TabsBarTools from './tabsBarTools/TabsBarTools';
import ExtraButtons from './extraButtons/ExtraButtons';

const SLIDER_SETTINGS = {
  variableWidth: true,
  speed: 300,
  arrows: false,
  dots: false,
  centerMode: false,
  swipe: true,
  swipeToSlide: true,
  touchMove: false,
};

export class TabsBar extends Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    extraButtons: PropTypes.array,
    canAddTab: PropTypes.bool,
    onAddClick: PropTypes.func,
    disableScroll: PropTypes.bool,
  };

  static defaultProps = {
    extraButtons: [],
    canAddTab: false,
    disableScroll: false,
    onAddClick: () => {},
  };

  constructor(props) {
    super(props);

    this.leftArrowClick = this.leftArrowClick.bind(this);
    this.rightArrowClick = this.rightArrowClick.bind(this);
  }

  leftArrowClick() {
    this.slider.slickPrev();
  }

  rightArrowClick() {
    this.slider.slickNext();
  }

  render() {
    const {
      list,
      extraButtons,
      canAddTab,
      onAddClick,
      disableScroll,
    } = this.props;

    const tabs = list.map((tab, index) => <TabsBarItem {...tab} key={index + 1} />);

    return (
      <div className="big-tabs">
        <div className="big-tabs-list">
          <div className="big-tabs-scroll">
            <div className="big-tabs-scroll-in">
              { disableScroll ? tabs : (
                <Slider ref={(c) => (this.slider = c)} {...SLIDER_SETTINGS}>
                  { tabs }
                </Slider>
              ) }
            </div>
          </div>
          { !!extraButtons && extraButtons.length > 0 && <ExtraButtons list={extraButtons} /> }
          { !disableScroll && (
            <TabsBarTools
              showPlusButton={canAddTab}
              isLeftButtonDisabled={false}
              isRightButtonDisabled={false}
              showLeftArrowButton
              showRightArrowButton
              onLeftButtonClick={this.leftArrowClick}
              onRightButtonClick={this.rightArrowClick}
              onPlusButtonClick={onAddClick}
            />
          ) }
        </div>
      </div>
    );
  }
}

export default TabsBar;
