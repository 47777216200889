import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export const MachineFormPageHeader = ({ backLink, isAddForm, isUpdateForm }) => (
  <div className="subheader subheader--with--center">
    <div className="subheader-in">
      <div className="subheader--left">
        <ul className="menu tab-menu">
          <li className="back-link">
            <Link to={backLink}>
              <span className="icon-arrow-left" />
              <FormattedMessage
                id="common.back"
                defaultMessage="Back"
              />
            </Link>
          </li>
          <li>
            <h1 className="h1">
              { isAddForm && (
                <FormattedMessage
                  id="newMachine.addNewMachine"
                  defaultMessage="Add New Machine"
                />
              ) }
              { isUpdateForm && (
                <FormattedMessage
                  id="machineForm.header.update"
                  defaultMessage="Update Machine"
                />
              ) }
            </h1>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

MachineFormPageHeader.propTypes = {
  backLink: PropTypes.string.isRequired,
  isAddForm: PropTypes.bool,
  isUpdateForm: PropTypes.bool,
};

MachineFormPageHeader.defaultProps = {
  isAddForm: false,
  isUpdateForm: false,
};

export default MachineFormPageHeader;
