import { connect } from 'react-redux';

import {
  CATEGORY_ERROR,
} from '../../../../redux/constants/event.constants';

import CategoryFilter from './CategoryFilter';

export function mapStateToProps(state, { filters, onChange }) {
  return {
    handleChange: onChange,
    selected: filters.indexOf(CATEGORY_ERROR) !== -1,
    label: 'maintenance.stream.error',
    category: CATEGORY_ERROR,
  };
}

export default connect(mapStateToProps)(CategoryFilter);
