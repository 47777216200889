import { pick } from 'lodash';

import * as form from '../../redux/constants/report.form.constants';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';
import * as entities from '../../../../common/constants/entities';
import * as reportTypes from '../../../../common/enums/reportTypes';
import * as datesModes from '../../../../common/enums/dates.modes';
import { DEFAULT_RELATIVE } from '../../redux/constants/relative.constants';

export const getValuesFromReport = (report = { filters: [] }) => {
  const machineFilters = (report && report.filters && report.filters[entities.MACHINES]) ?
    report.filters[entities.MACHINES] : [];
  const dataPointsFilters = (report && report.filters && report.filters[entities.DATA_POINTS] ?
    report.filters[entities.DATA_POINTS] : [])
    .map((item) => ({
      ...item,
      id: item.dataPoint.id,
      name: item.dataPoint.label,
      path: item.dataPoint.path || [],
      archived: item.dataPoint.archived || false,
      ...pick(item, ['axis', 'lineWidth', 'fillOpacity', 'color']),
    }));
  const dates = {
    [DATE_FROM]: report.from || null,
    [DATE_TO]: report.to || null,
    fromRelative: report.fromRelative || DEFAULT_RELATIVE,
    toRelative: report.toRelative || DEFAULT_RELATIVE,
    datesMode: report.datesMode || datesModes.ABSOLUTE,
  };

  return {
    [form.NAME_FIELD]: report.name || '',
    [form.TYPE_FIELD]: report.type || '',
    [form.DATES_FIELD]: { ...dates },
    [form.MACHINES_FILTERS_FIELD]: [...machineFilters],
    [form.DATA_POINTS_FILTERS_FIELD]: [...dataPointsFilters],
  };
};

export const MODAL_MAIN_FRAME = 'MODAL_MAIN_FRAME';
export const MODAL_DATE_PERIOD_FRAME = 'MODAL_DATE_PERIOD_FRAME';
export const MODAL_MACHINES_FILTERS_FRAME = 'MODAL_MACHINES_FILTERS_FRAME';
export const MODAL_DATA_POINTS_FILTERS_FRAME = 'MODAL_DATA_POINTS_FILTERS_FRAME';
export const MODAL_DATA_POINTS_ADD_FRAME = 'MODAL_DATA_POINTS_ADD_FRAME';

export const getApiPayload = (fields, reportsTabId = null) => {
  const sendMachines = [reportTypes.kpi, reportTypes.events, reportTypes.maintenance].indexOf(fields[form.TYPE_FIELD]) >= 0;
  const sendDatatrons = fields[form.TYPE_FIELD] === reportTypes.dataPoints;

  const payload = {
    ...fields,
    from: fields[form.DATES_FIELD][DATE_FROM],
    to: fields[form.DATES_FIELD][DATE_TO],
    datesMode: fields[form.DATES_FIELD].datesMode,
    filters: {
      [entities.MACHINES]: sendMachines ?
        (fields[form.MACHINES_FILTERS_FIELD] || []).map((item) => ({ id: item.id })) : [],
      [entities.DATA_POINTS]: sendDatatrons ?
        (fields[form.DATA_POINTS_FILTERS_FIELD] || [])
          .map((item) => pick(item, ['id', 'color', 'axis', 'fillOpacity', 'lineWidth'])) : [],
    },
    [form.MACHINES_FILTERS_FIELD]: undefined,
    [form.DATA_POINTS_FILTERS_FIELD]: undefined,
    [form.DATES_FIELD]: undefined,
  };

  const fromRelative = fields[form.DATES_FIELD].fromRelative;
  if (fromRelative) {
    payload.fromRelative = `${fromRelative.count}.${fromRelative.unit}`;
  }
  const toRelative = fields[form.DATES_FIELD].toRelative;
  if (toRelative) {
    payload.toRelative = `${toRelative.count}.${toRelative.unit}`;
  }

  if (reportsTabId) payload[form.REPORTS_TAB_FIELD] = reportsTabId;

  return payload;
};
