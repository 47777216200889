import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';
import ToolStateFilterItem from './ToolStateFilterItem';
import * as toolStates from '../../../../../common/enums/toolStates';
import toolStatesMessages from '../../../messages/tool.states.messages';
import Counter from '../../common/counter/Counter';
import React, { useState } from 'react';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';
import { addStateFilter, removeStateFilter } from '../../../redux/modules/machine.tools.filter.module';
import { useDispatch, useSelector } from 'react-redux';
import { getFilters } from '../../../redux/selectors/tools.selector';

const options = [
  {
    id: toolStates.NEW,
    label: toolStatesMessages[toolStates.NEW],
  },
  {
    id: toolStates.MOUNTED,
    label: toolStatesMessages[toolStates.MOUNTED],
  },
  {
    id: toolStates.USED,
    label: toolStatesMessages[toolStates.USED],
  },
  {
    id: toolStates.EXHAUSTED,
    label: toolStatesMessages[toolStates.EXHAUSTED],
  },
];

export const ToolStateFilter: React.FC = () => {
  const { state: selected } = useSelector(getFilters);

  const dispatch = useDispatch();
  const addFilter = (id) => dispatch(addStateFilter(id));
  const removeFilter = (id) => dispatch(removeStateFilter(id));


  const [isOpen, isOpenSet] = useState(false);

  const open = () => {
    isOpenSet(true);
  };

  const close = () => {
    isOpenSet(false);
  };

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  const styles = {
    main: classNames(
      'filter--block',
      { opened: isOpen },
    ),
    button: classNames(
      'filter--btn',
      { 'no-filter': selected.length === 0 },
    ),
  };

  const handleFilterChange = (id, isSelected) => {
    if (!isSelected) {
      addFilter(id);
    } else {
      removeFilter(id);
    }

    close();
  };

  const clickRef = useClickOutside(close);

  return (
    <div ref={clickRef}>
      <div className={styles.main}>
        <button
          className={styles.button}
          type="button"
          onClick={toggle}
        >
          <span className="icon icon-filter"/>
          <FormattedMessage
            id="tools.filters.state.label"
            defaultMessage="Status"
          />
          <Counter value={selected.length}/>
        </button>
        {isOpen &&
        <div className="filter--dropdown">
            <ul>
              {options.map((option) => (
                <ToolStateFilterItem
                  key={option.id}
                  selected={selected.indexOf(option.id) >= 0}
                  handleChange={handleFilterChange}
                  {...option}
                />
              ))}
            </ul>
        </div>
        }
      </div>
    </div>
  );

};

export default ToolStateFilter;
