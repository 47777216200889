/*
  See RFC5545 standard docs for details:
  https://tools.ietf.org/html/rfc5545
  3.3.10.  Recurrence Rule
*/

export const DAILY = 'DAILY';
export const WEEKLY = 'WEEKLY';
export const MONTHLY = 'MONTHLY';
export const YEARLY = 'YEARLY';

export default [DAILY, WEEKLY, MONTHLY, YEARLY];
