import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import Form from './form/EditToolForm.container';

export const EditToolModal = ({
  tool,
  handleSubmit,
  close,
}) => (
  <BasicModal close={close} modalClass="modal--sm2">
    <div className="modal--content">
      <h3 className="first-title">
        <FormattedMessage
          id="tools.modal.edit_tool"
          defaultMessage="Edit Tool"
        />
      </h3>
      <Form tool={tool} handleSubmit={handleSubmit} handleCancel={close} />
    </div>
  </BasicModal>
);

EditToolModal.propTypes = {
  tool: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default EditToolModal;
