import React from 'react';

import EmptyTablePlaceholder from './EmptyTablePlaceholder';

export const EmptyTable = () => (
  <div className="flex-el">
    <EmptyTablePlaceholder />
  </div>
);

export default EmptyTable;
