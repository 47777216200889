import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AnomalySignalChart from './charts/AnomalySignalChart';
import AnomalyWindowChartContainer from './charts/AnomalyWindowChart.container';
import { useBoundingClientRect } from '../useBoundingClientRect';

function AnomalyDetails({ payload }) {
  const [signalRef, signalRect] = useBoundingClientRect<HTMLDivElement>();
  const [windowRef, windowRect] = useBoundingClientRect<HTMLDivElement>();

  return (
    <div>
      <FormattedMessage
        defaultMessage="Signals"
        id="events.event.chart.signals"
        tagName="h4"
      />
      <div ref={signalRef} className="bordered" style={{ height: '200px' }}>
        <AnomalySignalChart
          data={payload.resampled_series}
          width={signalRect.width}
          height={signalRect.height}
        />
      </div>
      <FormattedMessage
        defaultMessage="Anomalies"
        id="events.event.chart.anomalies"
        tagName="h4"
      />
      <div ref={windowRef} className="bordered" style={{ height: '200px' }}>
        <AnomalyWindowChartContainer
          data={payload.windows}
          threshold={payload.threshold}
          width={windowRect.width}
          height={windowRect.height}
        />
      </div>
    </div>
  );
}

AnomalyDetails.propTypes = {
  payload: PropTypes.object.isRequired,
};

export default AnomalyDetails;
