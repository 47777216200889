import { getLocationsSchema } from '../../schema/locations';
import { apiRequest, apiRequestWithSchema } from './_tools';

export function search(query) {
  return apiRequest(`/api/locations?q=${query}`);
}

export const findByName = (name) => search(name);

export function getById(id) {
  return apiRequest(`/api/locations/${id}`);
}

export function getByType(type) {
  return apiRequest(`/api/locations/type/${type}`);
}

export const getChildLocations = (parentId, params) => {
  const query = new URLSearchParams({
    ...params,
    parent: parentId || '',
    force_parent: 'true',
  }).toString();
  return apiRequestWithSchema(`/api/locations?${query}`, getLocationsSchema);
};

export function getLocationParentTree(id) {
  return apiRequest(`/api/locations/${id}/path`);
}
