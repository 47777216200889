import classname from 'classnames';
import { isUndefined } from 'lodash';
import React from 'react';
import { Todo } from '../../../../../../common/types/common';
import { useComponent } from './helpers';

interface Props {
  onChange: (newValue: Todo, error?: Todo) => void;
  field: Todo;
}

export const DataPointFormField: React.FC<Props> = ({ onChange, field }) => {
  const components = useComponent(field, onChange);
  if (Array.isArray(components) || field.type === 'object') {
    return <>{components}</>;
  }
  return (
    <div
      className={classname('form-item', `form-item-${field.key}`, {
        'form-error-outline': !isUndefined(field.error),
      })}
      key={field.key}
    >
      <label>
        {field.label}
        {components}
        {!isUndefined(field.error) && (
          <span style={{ color: 'red' }}>{field.error}</span>
        )}
      </label>
    </div>
  );
};
