import { DATE_FROM, DATE_TO } from '../../../../common/constants';

export const batchInitialState = {
  loaded: false,
  loading: false,
  list: [],
  error: null,
  selected: {
    list: [],
  },
  filters: {
    isQueryInputOpen: false,
    q: '',
    sort: {
      option: null,
      way: null,
    },
    [DATE_FROM]: null,
    [DATE_TO]: null,
  },
  processings: {
    loaded: false,
    loading: false,
    list: [],
    error: null,
  },
};

export const batchTestState = {
  loaded: false,
  loading: false,
  error: null,
  list: [
    {
      id: 'MTE0OTE1NjU1MTMwNTU=',
      name: 'Batch name',
      batchNumber: 'NO.1',
    },
  ],
  selected: {
    list: [],
  },
  filters: {
    isQueryInputOpen: false,
    q: 'some batch',
    sort: {
      option: null,
      way: null,
    },
    [DATE_FROM]: null,
    [DATE_TO]: null,
  },
  processings: {
    loaded: false,
    loading: false,
    list: [],
    error: null,
  },
};
