import { reduxForm } from 'redux-form';

import ReportModule from './ReportModule';
import * as formConstants from '../../../../redux/constants/report.form.constants';

const form = {
  form: formConstants.REPORT_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
};

export default reduxForm(form)(ReportModule);
