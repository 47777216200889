import React, { FormEvent } from 'react';
import { useIntl, WrappedComponentProps } from 'react-intl';
import { reduxForm } from 'redux-form';
import ComplexModalContainer from '../../core/complexModal/ComplexModal.container';
import commonCoreMessages from '../../../../messages/common.core.messages';
import adminPageMessages from '../../../../messages/adminPage.messages';
import aiAppMessages from '../../../../messages/aiApp.config.messages';
import CycleDetectionMainFrame from './modalFrames/mainFrame/CycleDetectionMainFrame';
import DatesFrameContainer from './modalFrames/datesFrame/DatesFrame.container';
import TypeUuidSelectFrameContainer from './modalFrames/typeUuidFilter/TypeUuidFilterFrame.container';
import { SUB_PAGE_AI_APP_FORM } from '../../../../redux/constants/modals.constants';
import * as frames from '../../../../core/common/aiApp.form';
import { AI_APP_CYCLE_DETECTION_FORM } from '../../../../redux/constants/aiApp.cycleDetection.form.constants';

interface CycleDetectionProps extends WrappedComponentProps {
  createCycleDetection: () => void;
  submitting: boolean;
  pristine: boolean;
  close: () => void;
  isAddForm?: boolean;
}
interface FrameConfig {
  id: string;
  component: React.ReactNode;
  mainClassName: string;
  title?: string;
  children?: FrameConfig[];
}

const CycleDetectionCreate: React.FC<CycleDetectionProps> = ({
  createCycleDetection,
  submitting,
  pristine,
  close,
}) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    createCycleDetection();
  };

  const { formatMessage } = useIntl();

  const FRAME_PROPS: { [key: string]: FrameConfig } = {
    [frames.MODAL_MAIN_FRAME]: {
      id: frames.MODAL_MAIN_FRAME,
      component: (
        <CycleDetectionMainFrame
          handleSubmit={handleSubmit}
          handleCancel={close}
          pristine={pristine}
          submitting={submitting}
        />
      ),
      mainClassName: 'modal-show-main',
    },
    [frames.MODAL_DATE_PERIOD_FRAME]: {
      id: frames.MODAL_DATE_PERIOD_FRAME,
      component: <DatesFrameContainer />,
      mainClassName: 'modal-show-datepicker',
    },
    [frames.MODAL_TYPE_UUID_FILTERS_FRAME]: {
      id: frames.MODAL_TYPE_UUID_FILTERS_FRAME,
      component: <TypeUuidSelectFrameContainer />,
      mainClassName: 'modal-show-filters',
    },
  };

  const datePeriodFrameTitle = formatMessage(adminPageMessages.selectObject, {
    object: formatMessage(commonCoreMessages.datePeriod),
  });
  const typeUuidFiltersFrameTitle = formatMessage(adminPageMessages.selectObject, {
    object: formatMessage(aiAppMessages.typeUUID),
  });

  const modalConfig: FrameConfig = {
    title: formatMessage(aiAppMessages.addCycleDetection),
    ...FRAME_PROPS[frames.MODAL_MAIN_FRAME],
    children: [
      {
        title: datePeriodFrameTitle,
        ...FRAME_PROPS[frames.MODAL_DATE_PERIOD_FRAME],
      },
      {
        title: typeUuidFiltersFrameTitle,
        ...FRAME_PROPS[frames.MODAL_TYPE_UUID_FILTERS_FRAME],
      },
    ],
  };

  return (
    <ComplexModalContainer
      modalId={SUB_PAGE_AI_APP_FORM}
      modalConfig={modalConfig}
      close={close}
      submit={handleSubmit}
    />
  );
};

export default reduxForm<{}, CycleDetectionProps>({
  form: AI_APP_CYCLE_DETECTION_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(CycleDetectionCreate);
