import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function Label({ count }) {
  if (count === 0) {
    return (
      <FormattedMessage
        id="maintenance.stream.category.empty"
        defaultMessage="Category"
      />
    );
  }
  return (
    <FormattedMessage
      id="maintenance.stream.category"
      defaultMessage="Category ({count})"
      values={{ count }}
    />
  );
}

Label.propTypes = {
  count: PropTypes.number.isRequired,
};

export default Label;
