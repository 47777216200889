import React from 'react';
import { FormattedMessage } from 'react-intl';

import commonMessages from '../../../../../messages/common.messages';

export default function EditMenuItem() {
  return (
    <span className="flex">
      <i className="icon-edit" />
      {' '}
      <FormattedMessage {...commonMessages.edit} />
    </span>
  );
}
