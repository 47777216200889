import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CalendarModuleContainer from './CalendarModule.container';
import { createCalendar } from '../../../../redux/modules/calendars.create.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { CP_NEW_CALENDAR } from '../../../../redux/constants/modals.constants';

export const mapStateToProps = () => ({
  isAddForm: true,
  loaded: true,
});

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(CP_NEW_CALENDAR)),
  createCalendar: bindActionCreators(createCalendar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarModuleContainer);
