import React from 'react';
import PropTypes from 'prop-types';

import ToolStateLabel from '../../../_tools/toolStateLabel/ToolStateLabel';
import stopPropagation from '../../../_tools/stopPropagation';

export const ToolState = ({ state }) => (
  <td
    className="td-category"
    onClick={stopPropagation}
  >
    <ToolStateLabel state={state} />
  </td>
);

ToolState.propTypes = {
  state: PropTypes.string.isRequired,
};

export default ToolState;
