import { createAction } from '@reduxjs/toolkit';
import { put, call, select, takeEvery } from 'redux-saga/effects';

import { fetchDatatron } from './datatron.fetch.module';
import { selectIsLoaded, getDatatron } from '../selectors/datatron.selector';

export const checkReFetch = createAction(
  'check if datatron must be fetched again',
  (datatronId: string) => ({ payload: datatronId }),
);

export function* checkReFetchSaga({ payload: datatronId }) {
  const state = yield select();

  const isDatatronLoaded = yield call(selectIsLoaded, state);
  const datatron = yield call(getDatatron, state);

  if (isDatatronLoaded && datatron.id === datatronId) return;
  yield put(fetchDatatron(datatronId));
}

export function* watchCheckReFetchDatatron() {
  yield takeEvery(checkReFetch, checkReFetchSaga);
}
