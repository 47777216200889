import moment from 'moment';
import { isEqual } from 'lodash';

import * as form from '../../redux/constants/report.form.constants';
import * as datesModes from '../../../../common/enums/dates.modes';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';

const ERROR_REQUIRED = 'reports.validation.dates.required';
const ERROR_INVALID = 'reports.validation.dates.invalid';
const ERROR_INVALID_NO_EQUALITY = 'reports.validation.dates.invalid.cannot_be_equal';

export const validateDates = (body) => {
  const errors = {};
  const dates = body[form.DATES_FIELD];

  if (dates.datesMode === datesModes.ABSOLUTE) {
    if (!dates[DATE_FROM] || !dates[DATE_TO]) {
      errors[form.DATES_FIELD] = ERROR_REQUIRED;
    } else {
      const fromDate = moment(dates[DATE_FROM]).utc().startOf('day');
      const toDate = moment(dates[DATE_TO]).utc().endOf('day');
      const today = moment().utc().startOf('day');

      if (fromDate.isAfter(toDate) || fromDate.isAfter(today)) {
        errors[form.DATES_FIELD] = ERROR_INVALID;
      }
    }
  } else if (dates.datesMode === datesModes.RELATIVE) {
    if (isEqual(dates.fromRelative, dates.toRelative)) {
      errors[form.DATES_FIELD] = ERROR_INVALID_NO_EQUALITY;
    }
  }

  return errors;
};
