import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../_tools/modal/Modal';
import Title from './Title';
import CalendarCloneModalFormContainer from './CalendarCloneModalForm.container';

const CalendarCloneModal = ({
  close,
  isOpen,
  calendar,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={close}
    contentLabel=""
    modalClass="modal--sm"
  >
    <div className="modal--header" onClick={close}>
      <span className="modal--close">&times;</span>
    </div>
    <div className="modal--content">
      <Title
        type={calendar.type}
        name={calendar.name}
      />
      <CalendarCloneModalFormContainer close={close} calendarId={calendar.id} />
    </div>
  </Modal>
);

CalendarCloneModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  calendar: PropTypes.object.isRequired,
};

export default CalendarCloneModal;
