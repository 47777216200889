import { Dimensions, dimensionsToString } from '../helpers';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  dimensions: Dimensions
  type: string
}

/**
 * Show the user what the full array definition looks like, so they can ensure the builder has build what they expect.
 */
export const ArrayFullDisplay: React.FC<Props> = ({ dimensions, type }) => (
  <div>
    <div style={{ fontSize: '13px' }}>
      <FormattedMessage
        id="dataPoint.array.display.label"
        defaultMessage="Array Definition"
        tagName="span"
      />
    </div>
    <div className={'dimensionDisplay'}>
      <span>{`${dimensionsToString(dimensions)} ${type}`}</span>
    </div>
  </div>
);