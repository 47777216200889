import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ColumnExpandCollapseContainer from '../actions/ColumnExpandCollapse.container';
import Assignee from '../actions/Assignee';
import CommentingContainer from '../actions/Commenting.container';
import ColumnEventInfoContainer from '../event/EventInfo.container';
import ColumnDialogMenu from '../actions/ColumnDialogMenu';

export class ActivityEvent extends Component {
  constructor(props) {
    super(props);

    this.expandCard = this.expandCard.bind(this);
  }

  expandCard() {
    const {
      event, collapseExpand, fetchAnomaly, canSeeAnomaly,
    } = this.props;
    if (canSeeAnomaly) fetchAnomaly(event.id);
    collapseExpand(event.id);
  }

  render() {
    const {
      event,
      activity,
      isFatal,
      isWarning,
      isInfo,
      isAnomaly,
      isOpened,
      isError,
      resolve,
      isInProgress,
      assign,
      canSeeAnomaly,
      canUpdateActivities,
    } = this.props;

    const className = classNames('maintenance--item', {
      info: isInfo,
      alert: isFatal,
      warning: isWarning,
      anomaly: isAnomaly,
      error: isError,
      opened: isOpened,
    });

    return (
      <div
        className={className}
        onClick={this.expandCard}
      >
        <ColumnExpandCollapseContainer eventId={event.id} />
        <ColumnDialogMenu
          assign={assign}
          organizationId={event.machine.organization.id}
          activityId={activity.id}
          resolve={resolve}
          canHide
          canAssign={canUpdateActivities}
          canResolve={canUpdateActivities && isInProgress}
          assignee={activity.assignee}
        />
        <ColumnEventInfoContainer
          event={event}
          canResolve={canUpdateActivities && isInProgress}
          resolve={resolve}
          canSeeAnomaly={canSeeAnomaly}
        />
        <div className="maintenance--item--bottom">
          <ul>
            <Assignee
              user={activity.assignee}
              assign={assign}
              activityId={activity.id}
              organizationId={event.machine.organization.id}
            />
            <CommentingContainer
              commentsCount={event.comments.length}
              eventId={event.id}
            />
          </ul>
        </div>
      </div>
    );
  }
}

ActivityEvent.propTypes = {
  activity: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  isFatal: PropTypes.bool.isRequired,
  isWarning: PropTypes.bool.isRequired,
  isInfo: PropTypes.bool.isRequired,
  isAnomaly: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  resolve: PropTypes.func,
  collapseExpand: PropTypes.func.isRequired,
  isInProgress: PropTypes.bool,
  assign: PropTypes.func,
  fetchAnomaly: PropTypes.func.isRequired,
  canSeeAnomaly: PropTypes.bool.isRequired,
  canUpdateActivities: PropTypes.bool.isRequired,
};

export default ActivityEvent;
