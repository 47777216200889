import { connect } from 'react-redux';

import {
  FATAL,
} from '../../../../../../common/enums/dataPointCategories';

import CategoryFilter from './CategoryFilter';

export const mapStateToProps = (state, { filters, onChange }) => ({
  handleChange: onChange,
  selected: filters.indexOf(FATAL) !== -1,
  label: 'maintenance.stream.fatal',
  category: FATAL,
});

export default connect(mapStateToProps)(CategoryFilter);
