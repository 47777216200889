import { connect } from 'react-redux';

import AnomalyWindowChart from './AnomalyWindowChart';
import { prepareWindowsData } from '../../../../core/common/event';

export function mapStateToProps(state, { data, threshold }) {
  return {
    data: prepareWindowsData(data, threshold),
  };
}

export default connect(mapStateToProps)(AnomalyWindowChart);
