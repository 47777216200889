import {
  ROLES_MANAGER, Role,
} from '../constants/permissions';

class RolesPermissions {
  private isRolesManager: boolean;

  constructor(roles: Role[]) {
    this.isRolesManager = roles.indexOf(ROLES_MANAGER) !== -1;
  }

  canManageRoles() {
    return this.isRolesManager;
  }
}

export default RolesPermissions;
