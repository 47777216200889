import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3-selection';
import { axisLeft, axisRight } from 'd3-axis';
import { round } from 'lodash';
import classnames from 'classnames';

export const formatPercent = (point) => {
  const percent = round((round(point, 2) * 100));
  if (percent % 50 !== 0) return null;
  if (percent > 300 || percent <= 0) return null;
  return `${String(percent)}%`;
};

export const formatNumeric = (point) => {
  if (Number.isInteger(point) && point > 0 && (point % 2 === 0 || point % 5 === 0)) return point;
  return null;
};

export class AxisVertical extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    scale: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    widthOffset: PropTypes.number,
    inverse: PropTypes.bool,
    isPercent: PropTypes.bool.isRequired,
    /* eslint-enable react/no-unused-prop-types */
  };

  static defaultProps = {
    isPercent: true,
    widthOffset: 0,
    inverse: false,
  };

  componentDidMount() {
    this.renderAxis(this.props);
  }

  shouldComponentUpdate(props) {
    this.renderAxis(props);
    return false;
  }

  renderAxis(props) {
    const {
      scale, width, isPercent, inverse, ticks,
    } = props;
    const axis = !inverse ?
      axisLeft(scale)
        .tickValues(ticks)
        .tickPadding(-1 * width)
        .tickFormat(isPercent ? formatPercent : formatNumeric) :
      axisRight(scale)
        .tickValues(ticks)
        .tickPadding(-1 * width)
        .tickFormat(isPercent ? formatPercent : formatNumeric);

    select(this.ticks)
      .call(axis);
  }

  render() {
    const { isPercent, widthOffset, width } = this.props;
    const className = classnames({ percentage: isPercent });
    const transform = widthOffset ? `translate(${widthOffset + (2 * width)}, 0)` : '';

    return (
      <g className={className} transform={transform}>
        <g ref={(r) => (this.ticks = r)} />
      </g>
    );
  }
}

export default AxisVertical;
