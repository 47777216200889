import { connect } from 'react-redux';

import OrganizationModule from './OrganizationModule';
import { adminCreateOrganization } from '../../../../../redux/modules/admin.organization.create.module';

export const mapStateToProps = () => ({
  isAddForm: true,
  loaded: true,
});

export const mapDispatchToProps = {
  adminCreateOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationModule);
