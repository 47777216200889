import { useEffect, useRef } from 'react';
import { SHOW_TIMEOUT_WARNING_MESSAGE } from '../../../../../common/constants/datapoints.excel';
import { bytesToMega } from '../../../../../common/utils';
import { useIntl } from 'react-intl';
import { validationFileMessages } from '../../../messages/dataPoints.action.names';

interface FileUploadOptions {
  fileType: string;
  maxSize: number;
  nrFilesAllowed: number;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setWarning: React.Dispatch<React.SetStateAction<string>>;
}

interface FileUploadResult {
  onFileDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveFile: (index: number) => void;
  inputFile: React.RefObject<HTMLInputElement>;
}

export const useFileUpload = ({
  fileType,
  maxSize,
  nrFilesAllowed,
  files,
  setFiles,
  setErrorMessage,
  setWarning,
}: FileUploadOptions): FileUploadResult => {
  const inputFile = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();

  const showWarning = (message: string) => {
    setWarning(message);
    setTimeout(() => {
      setWarning('');
    }, SHOW_TIMEOUT_WARNING_MESSAGE);
  };

  useEffect(() => {
    setErrorMessage('');
    files.forEach((file) => {
      if (fileType && !file.type.includes(fileType)) {
        setErrorMessage(
          `${formatMessage(validationFileMessages.unsupportedFileTypeDetected)} ${fileType}.`,
        );
      }
      if (maxSize && file.size > maxSize) {
        setErrorMessage(
          `${formatMessage(validationFileMessages.fileSizeExceedsTheLimit)} ${bytesToMega(maxSize)} MB.`,
        );
      }
    });
  }, [files, fileType, maxSize, setErrorMessage, formatMessage]);

  const onFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (!droppedFiles || droppedFiles.length === 0) return;

    if (droppedFiles.length > nrFilesAllowed) {
      showWarning(
        `${formatMessage(validationFileMessages.exceededMaxUploadFileLimit)} ${nrFilesAllowed}.`,
      );
      return;
    } else if (files.length === nrFilesAllowed) {
      setFiles(Array.from(droppedFiles));
      return;
    }
    const newFiles = Array.from(droppedFiles);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (!selectedFiles || selectedFiles.length === 0) return;
    if (selectedFiles.length > nrFilesAllowed) {
      showWarning(
        `${formatMessage(validationFileMessages.exceededMaxUploadFileLimit)} ${nrFilesAllowed}.`,
      );
      return;
    } else if (files.length === nrFilesAllowed) {
      setFiles(Array.from(selectedFiles));
      return;
    }
    const newFiles = Array.from(selectedFiles);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const onRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    if (inputFile.current) {
      inputFile.current.value = '';
    }
  };

  return { inputFile, onFileDrop, onFileChange, onRemoveFile };
};
