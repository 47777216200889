import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import ModalLine from '../../core/ModalLine';
import OkModalFooter from '../../core/footers/OkModalFooter.container.js';
import { measurement } from '../../../../core/propTypes';
import LoaderLocal from '../../../_tools/loader/LoaderLocal';

export default class DownloadLinksExperimentModal extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    measurements: PropTypes.arrayOf(measurement).isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { fetch } = this.props;
    fetch();
  }

  render() {
    const {
      close, measurements, loaded, loading, url,
    } = this.props;

    return (
      <BasicModal close={close} modalClass="modal--sm2">
        <div className="modal--content">
          <h3 className="first-title">
            <FormattedMessage
              id="experiments.modal.download_links_experiment"
              defaultMessage="Download Measurements for Experiment"
            />
          </h3>

          <div className="export--line export--line--modal">
            { loaded && (
            <>
              { measurements.map((item) => (
                <a href={url + item.id} target="_blank" rel="noopener noreferrer" key={item.id}>{ url + item.id }</a>
              )) }
              { (!measurements || !measurements.length) && (
              <FormattedMessage
                id="experiments.modal.download_links_experiment.no_data_available"
                defaultMessage="No Data available"
                tagName="h4"
              />
              ) }
            </>
            )}
            { loading && (<LoaderLocal />) }
          </div>

          <ModalLine />

          <OkModalFooter
            onOkBtnClick={close}
            hideCancelButton
          />
        </div>
      </BasicModal>
    );
  }
}
