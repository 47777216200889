import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import LocationRowContainer from './LocationRow.container';
import LocationsHeaderContainer from './header/LocationsHeader.container';
import PaginationBar from '../../../common/paginationBar/PaginationBar';

export const LocationsModule = ({
  list, nameQuery, pagination, paginationAvailable, choosePage, route, history,
}) => (
  <div className="user-table">
    <table className="table">
      <LocationsHeaderContainer />
      <tbody>
        { list.map((item) => <LocationRowContainer key={item.id} location={item} nameToHighlight={nameQuery} pushPath={history.push} />) }
      </tbody>
    </table>
    { paginationAvailable && (<PaginationBar {...pagination} choosePage={choosePage} />) }
    { renderRoutes(route.routes) }
  </div>
);

LocationsModule.propTypes = {
  route: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  nameQuery: PropTypes.string,
  pagination: PropTypes.object,
  paginationAvailable: PropTypes.bool.isRequired,
  choosePage: PropTypes.func.isRequired,
};

LocationsModule.defaultProps = {
  nameQuery: '',
  pagination: {},
};

export default LocationsModule;
