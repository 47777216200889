import { connect } from 'react-redux';

import ColumnFilters from '../../../_tools/categoryFilter/CategoryFilters';

import { addFilter, removeFilter } from '../../../../redux/modules/maintenance.columns.module';

export function mapStateToProps(state, { type }) {
  return {
    filters: state.maintenance.columns[type],
    isMaintenance: true,
  };
}

export function mapDispatchToProps(dispatch, { type }) {
  return {
    addFilter: (filter) => dispatch(addFilter(type, filter)),
    removeFilter: (filter) => dispatch(removeFilter(type, filter)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnFilters);
