import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import { remove } from 'lodash';

import PlainList from './plainList/PlainList';
import CheckboxList from './checkboxList/CheckboxList';
import { InputError } from '../..';

export const FormEditableList = ({
  input,
  meta,
  options,
  label,
  disabled,
  translateOptionsLabels,
  allOptionsSelected,
  intl,
}) => {
  const valueSelected = !allOptionsSelected ?
    (input.value || []) : options.map((item) => item.value);

  const onChangeHandler = (value, checked) => {
    const newValueSelected = !checked ?
      remove(valueSelected, (item) => item !== value) : [...valueSelected, value];
    input.onChange(newValueSelected);
  };

  const isError = meta.touched && meta.error;
  const className = cn(
    'like-label',
    { error: isError },
  );

  const finalOptions = translateOptionsLabels ?
    options.map((item) => ({
      ...item,
      label: intl.formatMessage(item.label),
    })) : options;

  return (
    <div className={className}>
      <span>{label}</span>
      { disabled ?
        (<PlainList options={finalOptions} selected={valueSelected} />)
        : (<CheckboxList options={finalOptions} selected={valueSelected} onChange={onChangeHandler} />) }
      <div className="error-line">
        <InputError error={meta.error} />
      </div>
    </div>
  );
};

FormEditableList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  translateOptionsLabels: PropTypes.bool,
  allOptionsSelected: PropTypes.bool,

};

FormEditableList.defaultProps = {
  disabled: false,
  translateOptionsLabels: false,
  allOptionsSelected: false,
};

export default injectIntl(FormEditableList);
