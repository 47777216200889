import React from 'react';
import PropTypes from 'prop-types';

import { getAdminEditOrganizationLink } from '../../../index/routes';
import { HighlightText } from '../../../_tools';

export const OrganizationRow = ({
  organization, onRemoveClick, nameToHighlight, pushPath,
}) => {
  const handleEditClick = () => pushPath(getAdminEditOrganizationLink(organization.id));
  const handleRemoveClick = () => onRemoveClick(organization);

  return (
    <tr>
      <td>
        <strong>
          <HighlightText
            text={organization.name}
            highlight={nameToHighlight}
          />
        </strong>
      </td>
      <td className="td-actions">
        <button className="button light-grey-white" onClick={handleEditClick}>
          <i className="icon-edit" />
        </button>
        <button className="button light-grey-white" onClick={handleRemoveClick}>
          <i className="icon-close2" />
        </button>
      </td>
    </tr>
  );
};

OrganizationRow.propTypes = {
  organization: PropTypes.object.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  nameToHighlight: PropTypes.string,
};

OrganizationRow.defaultProps = {
  nameToHighlight: '',
};

export default OrganizationRow;
