import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import messages from './CategoryLabel.messages';
import * as dataPointCategories from '../../../../../common/enums/dataPointCategories';

export const categoryToLabelClassName = {
  fatal: 'alert',
  warning: 'warning',
  anomaly: 'info',
  info: 'success',
  error: 'error',
  [dataPointCategories.FATAL]: 'alert',
  [dataPointCategories.WARNING]: 'warning',
  [dataPointCategories.ANOMALY]: 'info',
  [dataPointCategories.INFO]: 'success',
  [dataPointCategories.ERROR]: 'error',
};

export const CategoryLabel = ({ category }) => {
  const { formatMessage } = useIntl();
  const className = classnames(
    'label',
    categoryToLabelClassName[category],
  );
  const label = formatMessage(messages[category]);

  return (
    <div className={className}>
      <span>
        { label }
      </span>
    </div>
  );
};

CategoryLabel.propTypes = {
  category: PropTypes.string.isRequired,

};

export default CategoryLabel;
