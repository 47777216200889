import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';

import InputError from '../inputError/InputError';
import ShowHidePassword from './ShowHidePassword';
import UUIDGenerator from './UUIDGenerator';
import NotSetPlaceholder from './NotSetPlaceholder';

export default class InputWithError extends Component {
  static propTypes = {
    touched: PropTypes.bool,
    error: PropTypes.string,
    meta: PropTypes.shape({
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      touched: PropTypes.bool,
    }).isRequired,
    input: PropTypes.object,
    className: PropTypes.string,
    canGenerateUUID: PropTypes.bool,
    _defaultValue: PropTypes.any,
    showPassword: PropTypes.func,
    hidePassword: PropTypes.func,
    pressed: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    canGenerateUUID: false,
    pressed: false,
    disabled: false,
  };

  componentWillMount = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { input: { value, onChange }, _defaultValue } = this.props;

    if ((value === null || value === undefined || value === '') && _defaultValue !== undefined) {
      onChange(_defaultValue);
    }
  };

  initializeValue(string = '') {
    const input = this.input;

    input.focus();
    input.value = string;
    input.setSelectionRange(string.length, string.length);
  }

  handleGenerateButton = () => this.props.input.onChange(uuid());

  render = () => {
    const {
      meta,
      input,
      canGenerateUUID,
      showPassword,
      hidePassword,
      pressed,
      disabled,
      ...props
    } = this.props;

    const isError = meta.touched && meta.error;

    const className = classnames(
      props.className || '',
      {
        error: isError,
      },
    );
    const divClassName = classnames(
      'input-group',
      'white-group',
      { pressed },
    );

    const inputProps = {
      ...input,
      ...props,
      disabled,
    };
    delete inputProps._defaultValue;

    const canSeePassword = !!showPassword && !!hidePassword;
    const isValueEmpty = isEmpty(input.value);

    if (isValueEmpty && disabled) return <NotSetPlaceholder/>;

    return (
      <div className="rel">
        <div className={divClassName}>
          <input
            ref={(refInput) => {
              this.input = refInput;
            }}
            {...inputProps}
            className={className}
          />
          {!disabled && canGenerateUUID && (
            <UUIDGenerator
              onClick={this.handleGenerateButton}
            />
          )}
          {canSeePassword && (
            <ShowHidePassword
              showPassword={showPassword}
              hidePassword={hidePassword}
            />
          )}
        </div>
        {
          isError
          && (
            <div className="error-line">
              <InputError error={meta.error}/>
            </div>
          )
        }
      </div>
    );
  };
}
