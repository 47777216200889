import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { fetchCalendarEvents } from './calendars.fetch.calendarEvents.module';
import { getMachineCalendars } from '../selectors/machine.selector';

export const fetchMachineCalendarsEvents = createAction(
  'fetch machine calendar events (SMP)',
  ({ fromDate, toDate }) => ({ fromDate, toDate }),
);

export const reducer = {
  [fetchMachineCalendarsEvents]: (state, { fromDate, toDate }) => ({
    ...state,
    machine: {
      ...state.machine,
      calendars: {
        ...state.machine.calendars,
        view: {
          ...state.machine.calendars.view,
          fromDate,
          toDate,
        },
      },
    },
  }),
};

export function* fetchMachineCalendarsEventsSaga({ payload: { fromDate, toDate } }) {
  const state = yield select();
  const machineCalendars = yield call(getMachineCalendars, state);
  yield machineCalendars.map((calendar) => put(fetchCalendarEvents({
    calendar,
    fromDate,
    toDate,
  })));
}

export function* watchFetchMachineCalendarsEvents() {
  yield takeEvery(fetchMachineCalendarsEvents.getType(), fetchMachineCalendarsEventsSaga);
}
