import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteConfigComponentProps } from 'react-router-config';
import { FormattedMessage } from 'react-intl';
import { onAIAppsDetailsEnter, onAIAppsDetailsLeave } from '../../index/routeHandlers';
import CycleDetectionsSection from './CycleDetectionsSection';
import AnomalyModelSection from './AnomalyModelSection';
import aiAppConfigMessages from '../../../messages/aiApp.config.messages';
import { Loader } from '../../_tools';
import * as selector from '../../../redux/selectors/aiApps.selector';

interface AIAppsProps extends RouteConfigComponentProps {}

const AiAppsDetails: React.FC<AIAppsProps> = ({ match }) => {
  useEffect(() => {
    const { aiApp }: { aiApp: string } = match.params as any;
    if (aiApp) {
      onAIAppsDetailsEnter(aiApp);
    }
    return () => {
      onAIAppsDetailsLeave();
    };
  }, [match.params]);
  const isLoading = useSelector(selector.isLoading);

  return (
    <div>
      <div className='col--1'>
        <div className='subheader'>
          <div className='subheader-in'>
            <div className='subheader--left'></div>
            <div className='subheader--right'></div>
          </div>
        </div>
      </div>
      <div className='col--1 location-page'>
        <div className='subheader-in'>
          <div className='subheader--left' />
          <div className='subheader--right' />
        </div>
        <div className='col--2--wrap'>
          <div className='col--2 col--2--central scrolled'>
            <div className='col--2--left'>
              <div className='col--height'>
                <div className='col--in height40'>
                  <div className='col--title'>
                    <FormattedMessage tagName='b' {...aiAppConfigMessages.cycleDetection} />
                  </div>
                  <CycleDetectionsSection />
                </div>
              </div>
            </div>
            <div className='col--2--right'>
              <div className='col--height'>
                <div className='col--in height40'>
                  <div className='col--title'>
                    <FormattedMessage tagName='b' {...aiAppConfigMessages.anomalyModel} />
                  </div>
                  <AnomalyModelSection />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader show={isLoading} />
    </div>
  );
};

export default AiAppsDetails;
