import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getDefaultCalendarEventsDates } from '../../../../core/common/calendar';
import commonMessages from '../../../../messages/common.messages';

export class CalendarDatesWidget extends Component {
  static propTypes = {

    calendar: PropTypes.object.isRequired,
    fromDate: PropTypes.string.isRequired,
    changeDates: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.changeRange = this.changeRange.bind(this);
    this.handleClickToday = this.handleClickToday.bind(this);
    this.handleClickLeft = this.handleClickLeft.bind(this);
    this.handleClickRight = this.handleClickRight.bind(this);
  }

  changeRange(fromDate, toDate) {
    const { changeDates, calendar } = this.props;

    changeDates({ calendar, fromDate, toDate });
  }

  handleClickToday() {
    const { fromDate, toDate } = getDefaultCalendarEventsDates();
    this.changeRange(fromDate, toDate);
  }

  handleClickLeft() {
    const fromDate = moment(this.props.fromDate)
      .startOf('week')
      .subtract(7, 'day')
      .toISOString();
    const toDate = moment(this.props.fromDate)
      .endOf('week')
      .subtract(7, 'day')
      .toISOString();
    this.changeRange(fromDate, toDate);
  }

  handleClickRight() {
    const fromDate = moment(this.props.fromDate)
      .startOf('week')
      .add(7, 'day')
      .toISOString();
    const toDate = moment(this.props.fromDate)
      .endOf('week')
      .add(7, 'day')
      .toISOString();
    this.changeRange(fromDate, toDate);
  }

  render() {
    const { intl, fromDate } = this.props;

    const monthFormatted = intl.formatDate(fromDate, { month: 'long' });
    const yearFormatted = intl.formatDate(fromDate, { year: 'numeric' });
    const todayLabel = intl.formatMessage(commonMessages.today);
    const weekNumber = moment(fromDate).format('w');

    return (
      <div className="parts--top">
        <div className="parts--top--left">
          <span className="icon icon-calendar" />
          <div className="parts--top-title">
            { monthFormatted }
            {' '}
            { yearFormatted }
            <FormattedMessage
              id="calendars.week_number"
              defaultMessage="Week {weekNumber}"
              values={{ weekNumber }}
              tagName="span"
            />
          </div>
        </div>
        <div className="parts--top--right">
          <div className="reports-actions">
            <div className="input-group">
              <div className="input-group-button">
                <button className="button__calendar" onClick={this.handleClickLeft}>
                  <span className="icon-slide-left" />
                </button>
              </div>
              <div className="input-group-fields input-cover" onClick={this.handleClickToday}>
                <input type="text" value={todayLabel} className="cursor-pointer" readOnly />
              </div>
              <div className="input-group-button">
                <button className="button__calendar" onClick={this.handleClickRight}>
                  <span className="icon-slide-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(CalendarDatesWidget);
