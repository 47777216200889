import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import LocationBreadcrumbs from '../../_tools/locationBreadcrumbs/LocationBreadcrumbs';
import { Export } from '../../common/export/Export';
import { getExportUrl } from '../../../core/common/processings';
import { PRODUCTS } from '../../../redux/constants/export.type.constants';
import * as datesModes from '../../../../../common/enums/dates.modes';
import DatePicker from '../../_tools/dateRangePickerV2/DatePicker';

export const BatchDetailsPageHeader = ({
  backLink,
  dateFilters,
  filterByDate,
  batchId,
  dateFiltersSelected,
  intl,
}) => {
  const handleDatePickerUpdate = (data) => filterByDate(batchId, data);

  const downloadButtonMessage = intl.formatMessage({
    id: 'common.features.export.download_data_table',
    defaultMessage: 'Download data table',
  });

  return (
    <div>
      <div className="subheader">
        <div className="subheader-in">
          <div className="subheader--left">
            <LocationBreadcrumbs />
          </div>
        </div>
      </div>
      <div className="filter--wrap filter--wrap1">
        <div className="filter--line clearfix">
          <div className="back--wrap">
            <ul className="menu tab-menu">
              <li className="back-link">
                <Link to={backLink}>
                  <span className="icon-arrow-left" />
                  <FormattedMessage
                    id="common.back_to_batches"
                    defaultMessage="Back to Batches"
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div className="filter-buttons--wrap">
            <div className="pull-right">
              <Export
                askForProcessings
                generateExportUrl={getExportUrl}
                downloadButtonMessage={downloadButtonMessage}
                dateFiltersSelected={dateFiltersSelected}
                downloadProps={dateFilters}
                type={PRODUCTS}
              />
            </div>
            <div className="pull-right">
              <div className="reports-actions">
                <DatePicker
                  onUpdate={handleDatePickerUpdate}
                  datesMode={datesModes.ABSOLUTE}
                  useConfirmationFooter
                  showAbsoluteDatesPresets
                  showDatesModeBar={false}
                  shortPreview
                  {...dateFilters}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BatchDetailsPageHeader.propTypes = {
  backLink: PropTypes.string.isRequired,
  filterByDate: PropTypes.func.isRequired,
  dateFilters: PropTypes.shape({
    from: PropTypes.any,
    to: PropTypes.any,
  }).isRequired,
  batchId: PropTypes.string,
  dateFiltersSelected: PropTypes.bool.isRequired,

};

export default injectIntl(BatchDetailsPageHeader);
