import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import * as entryType from '../../../../../../redux/constants/calendars.entry.constants';
import {
  UPDATE_EVENT,
  REMOVE_EVENT,
} from '../../../../../../redux/constants/modals.calendars.event.actions.constants';
import EntryTypeBar from './entryTypeBar/EntryTypeBar';
import commonMessages from '../../../../../../messages/common.messages';

const CONFIRM_MESSAGES = {
  [UPDATE_EVENT]: commonMessages.update,
  [REMOVE_EVENT]: commonMessages.remove,
};

export default class RepeatingEventFooter extends Component {
  static propTypes = {
    onCancelClick: PropTypes.func.isRequired,
    onConfirmClick: PropTypes.func.isRequired,
    actionType: PropTypes.oneOf([UPDATE_EVENT, REMOVE_EVENT]),
  };

  state = {
    type: entryType.DEFAULT_OPTION,
  };

  onConfirmClick = () => {
    this.props.onConfirmClick(this.state.type);
  };

  getConfirmButton = () => {
    const { actionType } = this.props;

    const classes = cn({
      button__remove: actionType === REMOVE_EVENT,
      button__save: actionType === UPDATE_EVENT,
    });

    return (
      <button
        type="button"
        className={classes}
        onClick={this.onConfirmClick}
      >
        <FormattedMessage {...CONFIRM_MESSAGES[actionType]} />
      </button>
    );
  };

  changeType = (type) => {
    this.setState({ type });
  };

  render() {
    const { onCancelClick } = this.props;

    return (
      <div className="export--line">
        <EntryTypeBar
          value={this.state.type}
          choose={this.changeType}
        />
        <div className="line" />
        <div className="text-center">
          <button
            type="button"
            className="button__cancel"
            onClick={onCancelClick}
          >
            <FormattedMessage
              id="common.cancel"
              defaultMessage="Cancel"
            />
          </button>
          { this.getConfirmButton() }
        </div>
      </div>
    );
  }
}
