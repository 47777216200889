import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CommandState } from './UploadFileModal';
import commonMessages from '../../../../messages/common.messages';
import { NR_FILES_ALLOWED } from '../../../../../../common/constants/datapoints.excel';

interface ActionFooterButtonsProps {
  commandState: CommandState;
  files: File[];
  errorFileMessage: string;
  onUpload: () => void;
  onClose: () => void;
}

export const ActionFooterButtons: React.FC<ActionFooterButtonsProps> = ({
  files,
  commandState,
  errorFileMessage,
  onUpload,
  onClose,
}) => {
  const { formatMessage } = useIntl();

  const buttonText =
    commandState === 'default' || commandState === 'loading'
      ? formatMessage(commonMessages.cancel)
      : formatMessage(commonMessages.close);

  return (
    <>
      <div className='text-center'>
        <button type='button' className='button__cancel' onClick={onClose}>
          {buttonText}
        </button>
        {files.length === NR_FILES_ALLOWED &&
          errorFileMessage === '' &&
          commandState === 'default' && (
            <button type='submit' className='button__save' onClick={onUpload}>
              <FormattedMessage
                defaultMessage={formatMessage(commonMessages.import)}
                id='common.import'
              />
            </button>
          )}
      </div>
    </>
  );
};
