import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isString } from 'lodash';

const NotificationComponent = ({
  id,
  title,
  description,
  remove,
  isError,
  isEvent,
}) => {
  const handleClick = () => remove(id);

  const notificationClassName = classNames(
    'notification',
    {
      'notification--red': isError,
      'notification--green': isEvent,
    },
  );

  const mainText = isString(title) ? title : <FormattedMessage {...title} />;
  let additionalText = '';
  if (!isEmpty(description)) {
    additionalText = isString(description) ? description : <FormattedMessage {...description} />;
  }

  return (
    <div className={notificationClassName}>
      <div className="notification__description">
        <strong>
          { mainText }
        </strong>
        <span>&nbsp;</span>
        { additionalText }
      </div>
      <button className="notification--close" onClick={handleClick}>
        <span className="icon-close" />
      </button>
    </div>
  );
};

NotificationComponent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  remove: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  isEvent: PropTypes.bool.isRequired,
};

export default NotificationComponent;
