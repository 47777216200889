import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CalendarContainer from '../../common/calendar/Calendar.container';
import messages from './Search.messages';

export function CalendarsList({
  query,
  typeCalendars,
  intl,
  count,
  fetchCalendarEvents,
  canChangeEvents,
}) {
  if (query.length && !count) {
    return (
      <div className="no-calendars">
        <strong>
          {intl.formatMessage(messages.notFound)}
        </strong>
      </div>
    );
  }

  return (
    <div className="calendars">
      { typeCalendars.map((calendar, index) => (
        <CalendarContainer
          calendar={calendar}
          key={index}
          changeDates={fetchCalendarEvents}
          draggable={canChangeEvents}
          readOnly={!canChangeEvents}
        />
      )) }
    </div>
  );
}

CalendarsList.propTypes = {
  query: PropTypes.string.isRequired,
  typeCalendars: PropTypes.array.isRequired,

  count: PropTypes.number.isRequired,
  fetchCalendarEvents: PropTypes.func.isRequired,
  canChangeEvents: PropTypes.bool.isRequired,
};

export default injectIntl(CalendarsList);
