export const profileForm = 'profileForm';

export const confirmNewPasswordField = 'confirmNewPassword';
export const newPasswordField = 'newPassword';
export const oldPasswordField = 'oldPassword';
export const newLanguageField = 'newLanguage';
export const newLocationField = 'newLocation';

export const formFields = [
  oldPasswordField,
  newPasswordField,
  confirmNewPasswordField,
  newLanguageField,
  newLocationField,
];
