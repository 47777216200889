import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { getMachine } from '../selectors/machine.selector';
import * as api from '../../core/api';
import * as machineUpdateModule from './machine.update.module';

export const deleteMachinePhoto = createAction(
  'delete machine photo',
);

export function* deleteMachinePhotoSaga() {
  const state = yield select();
  const machine = yield call(getMachine, state);

  const { response, error } = yield call(api.machines.deleteMachinePhoto, machine.id);

  if (response) {
    yield put(machineUpdateModule.updateMachineSuccess(response));
  } else {
    yield put(machineUpdateModule.updateMachineFail(error));
  }
}

export function* watchDeleteMachinePhoto() {
  yield takeEvery(deleteMachinePhoto.getType(), deleteMachinePhotoSaga);
}
