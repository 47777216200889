import { connect } from 'react-redux';

import { isLoading, isLoaded } from '../../redux/selectors/location.selector';
import LocationPage from './LocationPage';

export function mapStateToProps(state) {
  return {
    loading: isLoading(state),
    loaded: isLoaded(state),
  };
}

export default connect(mapStateToProps)(LocationPage);
