import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ColumnFiltersContainer from '../filters/ColumnFilters.container';

function ColumnHeader({ title, cardsCount, type }) {
  return (
    <div className="maintenance--top">
      <div className="maintenance-title">
        <FormattedMessage {...title} />
        {' '}
        (
        {cardsCount}
        )
      </div>
      <div className="pull-right">
        <ColumnFiltersContainer type={type} />
      </div>
    </div>
  );
}

ColumnHeader.propTypes = {
  cardsCount: PropTypes.number.isRequired,
  title: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default ColumnHeader;
