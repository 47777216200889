import React from 'react';

import TopBarSearch from './search/TopBarSearch';
import { TopBarProfile } from './profile/TopBarProfile';
import LogoContainer from './logos/Logo.container';
import MenuContainer from './menu/Menu.container';

export default function TopBar() {
  return (
    <>
      <header className='header'>
        <div className='header--row-thin'>
          <TopBarProfile />
          <TopBarSearch />
        </div>
        <div className='header--row'>
          <LogoContainer />
          <MenuContainer />
        </div>
      </header>
    </>
  );
}
