import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';
import { reset as resetForm } from 'redux-form';

import { resetTempLocationPath } from './pathButton.tempLocationPath.write.module';
import { ADMIN_USER_ADD_FORM_NAME } from '../constants/admin.user.add.form.constants';
import { ADMIN_USER_FORM } from '../constants/pathButton.constants';

export const adminResetUserForm = createAction(
  'reset admin user form data',
);

export function* adminResetUserFormSaga() {
  yield put(resetForm(ADMIN_USER_ADD_FORM_NAME));
  yield put(resetTempLocationPath(ADMIN_USER_FORM));
}

export function* watchAdminResetUserForm() {
  yield takeEvery(adminResetUserForm.getType(), adminResetUserFormSaga);
}
