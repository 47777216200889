import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import MergeMeasurementModal from './MergeMeasurementModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { mergeMeasurement } from '../../../../redux/modules/measurements.merge.module';
import { MEASUREMENT_MERGE } from '../../../../redux/constants/modals.constants';
import * as formConstants from '../../../../redux/constants/measurement.merge.form.constants';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';

export const mapStateToProps = (state) => {
  const { measurement } = getModalPayload(state, MEASUREMENT_MERGE);

  return {
    measurementId: measurement.id,
    initialValues: {
      [formConstants.EXPERIMENT_FIELD]: null,
    },
  };
};

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(MEASUREMENT_MERGE)),
  _merge: (id) => dispatch(mergeMeasurement(id)),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  handleSubmit: (e) => {
    e.preventDefault();
    dispatchProps._merge(stateProps.measurementId);
  },
});

const form = {
  form: formConstants.MEASUREMENT_MERGE_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm(form)(MergeMeasurementModal));
