import React from 'react';
import { useDispatch } from 'react-redux';
import DatesFrame from './DatesFrame';
import {
  AI_APP_CYCLE_DETECTION_FORM,
  DATES_FIELD,
} from '../../../../../../redux/constants/aiApp.cycleDetection.form.constants';
import { SUB_PAGE_AI_APP_FORM as modalId } from '../../../../../../redux/constants/modals.constants';
import { goBackModalPath } from '../../../../../../redux/modules/modals.module';
import { restoreFormField } from '../../../../../../redux/modules/common.form.module';

const DatesFrameContainer: React.FC = () => {
  const dispatch = useDispatch();

  const handleCancelClick = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    dispatch(restoreFormField(AI_APP_CYCLE_DETECTION_FORM, DATES_FIELD));
    dispatch(goBackModalPath(modalId));
  };

  const handleDoneClick = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    dispatch(goBackModalPath(modalId));
  };

  return <DatesFrame onCancelClick={handleCancelClick} onDoneClick={handleDoneClick} />;
};

export default DatesFrameContainer;
