import { createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as datatronApi from '../../core/api';
import { getDataPointsOfDevice, getDatatron, getDeviceById } from '../selectors/datatron.selector';
import { compareById, replaceObjectInList } from '../../../../common/helpers/object';
import { Todo } from '../../../../common/types/common';

export type ActionType = 'archived' | 'enabled' | 'isMaxRate';

type Action = {
  deviceId: string;
  action: ActionType;
  actionValue: boolean;
  dataPointCodes: string[];
};
type ActionSagaParam = {
  payload: Action;
};
export const updateBulkDataPoints = createAction(
  'update bulk data points',
  (deviceId: string, action: ActionType, actionValue: boolean, dataPointCodes: string[]) => ({
    payload: { deviceId, action, actionValue, dataPointCodes },
  }),
);

export const updateBulkDataPointsSuccess = createAction(
  'update bulk data points - success',
  (deviceId: string, updatedDataPoints) => ({ payload: { deviceId, updatedDataPoints } }),
);

export const updateBulkDataPointsFail = createAction(
  'update bulk data points - fail',
  (deviceId: string, error) => ({ payload: { deviceId, error } }),
);

export const resetUpdateBulkResponse = createAction(
  'reset update bulk response',
  (deviceId: string) => ({ payload: { deviceId } }),
);

export const reducer = {
  [updateBulkDataPoints.type]: (state, action) => {
    const { deviceId } = action;
    const device = getDeviceById(state, deviceId);

    if (!device) return state;

    const newDevice = {
      ...device,
      _update: {
        ...device._update,
        loading: true,
        error: null,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  },

  [resetUpdateBulkResponse.type]: (state, action) => {
    const { deviceId } = action;
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const newDevice = {
      ...device,
      _update: {
        isRenaming: false,
        loaded: false,
        loading: false,
        error: null,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  },

  [updateBulkDataPointsSuccess.type]: (state, { deviceId, updatedDataPoints }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;
    if (device._update.loading === false) return state;

    const deviceDataPoints = getDataPointsOfDevice(device);
    const dataPointToArchive: Todo[] = [];

    const newDataPoints = deviceDataPoints
      .map((dataPoint) => {
        const updatedDataPoint = updatedDataPoints.completed.find((dp) => dp.id === dataPoint.id);

        if (!updatedDataPoint) {
          return dataPoint;
        }

        const { enabled, isMaxRate, archived } = updatedDataPoint;

        if (archived !== undefined) {
          dataPointToArchive.push(dataPoint);
          return null;
        }

        if (updatedDataPoint.id !== updatedDataPoint.newId) {
          dataPointToArchive.push(dataPoint);
        }

        const newDataPoint = {
          ...dataPoint,
          id: updatedDataPoint.newId,
          uuid: updatedDataPoint.newUuid,
          enabled: enabled !== undefined ? enabled : dataPoint.enabled,
          config: {
            ...dataPoint.config,
            isMaxRate: isMaxRate !== undefined ? isMaxRate : dataPoint.config.isMaxRate,
          },
        };

        return newDataPoint;
      })
      .filter(Boolean);

    const newDevice = {
      ...device,
      _update: {
        ...device._update,
        loading: false,
        updateBulkResponse: updatedDataPoints,
        error: null,
      },
      dataPoints: {
        ...device.dataPoints,
        list: newDataPoints,
      },
      archivedDataPoints: {
        ...device.archivedDataPoints,
        list: [...device.archivedDataPoints.list, ...dataPointToArchive],
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          loading: false,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  },

  [updateBulkDataPointsFail.type]: (state, { deviceId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;
    if (device._update.loading === false) return state;

    const newDevice = {
      ...device,
      _update: {
        ...device._update,
        loading: false,
        error,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  },
};

/**
 * Saga to handle bulk updates for data points.
 *
 * This saga performs bulk updates for data points based on the provided action type,
 * action value, and data point codes for a specific device.
 *
 * @param {object} ActionSagaParam - The action payload object.
 * @param {string} ActionSagaParam.payload.deviceId - The device ID for which data points are to be updated.
 * @param {ActionType} ActionSagaParam.payload.action - The type of action to be performed on the data points ('archived', 'enabled', 'isMaxRate').
 * @param {boolean} ActionSagaParam.payload.actionValue - The boolean value associated with the action.
 * @param {string[]} ActionSagaParam.payload.dataPointCodes - An array of data point codes to be updated.
 */

export function* updateBulkDataPointsSaga({
  payload: { deviceId, action, actionValue, dataPointCodes },
}: ActionSagaParam) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const { response, error } = yield call(datatronApi.datatrons.updateBulkDataPoints, {
    datatronId: datatron.id,
    deviceId: deviceId,
    payload: { action, actionValue, dataPointCodes },
  });

  if (response) {
    yield put(updateBulkDataPointsSuccess(deviceId, response));
  } else {
    yield put(updateBulkDataPointsFail(deviceId, error));
  }
}

export function* watchUpdateBulkDataPoints() {
  yield takeEvery(updateBulkDataPoints, updateBulkDataPointsSaga);
}
