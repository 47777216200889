import { get } from 'lodash';
import React, { useCallback } from 'react';
import { Todo } from '../../../../../../../../../../../common/types/common';
import ConfigItem from '../../../../../../../../common/dataPoints/configItem/ConfigItem';

const SEARCH_HIGHLIGHT_KEYS = ['uuid', 'label', 'identifier', 'dataType.name'];

interface Field {
  _key: string;
  type: string | undefined;
  _isCommon: boolean;
  _parent?: string[];
  hidden?: boolean;
  _required?: boolean;
  enum?: string[];
  item?: Todo;
}

interface Props {
  fields: Field[];
  dataPoint: Todo;
  className?: string;
  searchQuery?: string;
}

const getTypeOfField = (dataPoint: Todo): string => {
  // case 1a: dataPoint has its own type under "type"
  if (dataPoint.config.type) {
    return dataPoint.config.type;
  }

  // case 1b: dataPoint has its own type under "dataType.name"
  const dataType = dataPoint.config.dataType?.name;
  if (dataType) {
    return dataType;
  }

  // case 2: dataPoint gets its type from its parent
  const parent = dataPoint.parent;
  if (parent) {
    return getTypeOfField(parent);
  }

  // case 3: no type was found
  return 'unknown';
};

export const ConfigItems: React.FC<Props> = ({ fields, dataPoint, className, searchQuery }) => {
  const type = getTypeOfField(dataPoint);

  const fieldsMapper = useCallback(
    (item: Field) => {
      const key = item._parent ? [...item._parent, item._key].join('.') : item._key;
      const value = get(item._isCommon ? dataPoint : dataPoint.config, key);
      const highlight = !!searchQuery && SEARCH_HIGHLIGHT_KEYS.indexOf(key) >= 0;

      const itemUpdate = item._key === 'arrayDimensions' ? { ...item, additional: type } : item;

      return (
        <ConfigItem
          key={key}
          column={itemUpdate}
          value={value}
          highlight={highlight}
          highlightString={highlight ? searchQuery : undefined}
        />
      );
    },
    [dataPoint, searchQuery, type],
  );

  return <div className={`data-points-params ${className}`}>{fields.map(fieldsMapper)}</div>;
};

export default ConfigItems;
