import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { TodoFunction } from '../../../../../common/types/common';

interface Props {
  toLineChart: TodoFunction;
  toStackedChart: TodoFunction;
  toStackedBarChart: TodoFunction;
  isLineChartSelected: boolean;
  isStackedChartSelected: boolean;
  isStackedBarChartSelected: boolean;
  isLineChartVisible?: boolean;
  isStackedChartVisible?: boolean;
  isStackedBarChartVisible?: boolean;
}

export const GraphTabs: React.FC<Props> = ({
  toLineChart,
  toStackedChart,
  toStackedBarChart,
  isLineChartSelected,
  isStackedChartSelected,
  isStackedBarChartSelected,
  isLineChartVisible = true,
  isStackedChartVisible = true,
  isStackedBarChartVisible = true,
}) => {
  const lineChartClassName = classnames('tabs-title', {
    'is-active': isLineChartSelected,
    hide: !isLineChartVisible,
  });

  const stackedChartClassName = classnames('tabs-title', 'ml-30', {
    'is-active': isStackedChartSelected,
    hide: !isStackedChartVisible,
  });

  const stackedBarChartClassName = classnames('tabs-title', {
    'is-active': isStackedBarChartSelected,
    hide: !isStackedBarChartVisible,
  });

  return (
    <ul className='tabs'>
      <li className={stackedChartClassName}>
        <a onClick={toStackedChart} id='stackedChartTab'>
          <FormattedMessage id='machine.graph.stacked-chart' defaultMessage='Stacked Chart' />
        </a>
      </li>
      <li className={stackedBarChartClassName}>
        <a onClick={toStackedBarChart} id='stackedBarChartTab'>
          <FormattedMessage
            id='machine.graph.stacked-bar-chart'
            defaultMessage='Stacked Bar Chart'
          />
        </a>
      </li>
      <li className={lineChartClassName}>
        <a onClick={toLineChart} id='lineChartTab'>
          <FormattedMessage id='machine.graph.line-chart' defaultMessage='Line Chart' />
        </a>
      </li>
    </ul>
  );
};
