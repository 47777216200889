import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Rnd } from 'react-rnd';
import moment from 'moment';
import { isEmpty, isEqual } from 'lodash';

import EventPreview from './EventPreview';
import EventActionsContainer from './tools/EventActions.container';
import stopPropagation from '../../../../../_tools/stopPropagation';
import * as calendarTypes from '../../../../../../../../common/enums/calendarTypes';
import {
  getCalendarEventProps,
  getEventDate,
  getMinimumEventWidth,
  getTimeByWidth,
} from '../../../../../../core/common/calendar';
import { Todo, TodoFunction } from '../../../../../../../../common/types/common';
import { useClickOutside } from '../../../../../../core/common/hooks/useClickOutside';
import { useComponentWillReceiveProps } from '../../../../../../core/common/hooks/useComponentWillReceiveProps';

const EVENT_TOP_OFFSET = -3;
const EVENT_HEIGHT = 26;
export const TITLE_COLUMN_WIDTH = 65;
const ENABLE_RESIZING = {
  left: true,
  right: true,
  top: false,
  bottom: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};
const DIR_LEFT = 'left';

interface Props {
  event: Todo
  day: string
  tableWidth: number
  widthPerSecond: number
  readOnly: boolean
  endDateLimit: string
  startDateLimit: string
  rowType: string
  disableCreateCalendarEvent: TodoFunction
  enableCreateCalendarEvent: TodoFunction
}

export const DraggableEvent: React.FC<Props> = (props: Props) => {

  const {
    event,
    day,
    tableWidth,
    widthPerSecond,
    readOnly,
    endDateLimit,
    startDateLimit,
    rowType,
    disableCreateCalendarEvent,
    enableCreateCalendarEvent,
  } = props;

  const {
    width,
    left,
  } = getCalendarEventProps(event, widthPerSecond);
  const [eventWidth, eventWidthSet] = useState(width);
  const [eventLeftOffset, eventLeftOffsetSet] = useState(left);

  const [isOpen, isOpenSet] = useState(false);
  const [isOptionsOpen, isOptionsOpenSet] = useState(false);

  const [minWidth, minWidthSet] = useState(getMinimumEventWidth(tableWidth));
  const [currentEvent, currentEventSet] = useState(event);
  const [disableOutside, disableOutsideSet] = useState(true);
  const [pendingChanges, pendingChangesSet] = useState<Todo>({});
  const [widthRangePosition, widthRangePositionSet] = useState({
    left: tableWidth - TITLE_COLUMN_WIDTH,
    right: tableWidth,
  });

  const [componentClassNames, componentClassNamesSet] = useState<string>(classnames(
    { hover: isOpen },
    { selected: isOptionsOpen },
  ));
  const [popupClassNames, popupClassNamesSet] = useState<string>('');


  const willReceivePropsCallback = useCallback((prevProps) => {
    if (!isEqual(prevProps.event, event)) {
      currentEventSet(event);
    }

    const {
      width: currentWidth,
      left: currentLeft,
    } = getCalendarEventProps(currentEvent, widthPerSecond);
    const leftPosition = 0;
    const rightPosition = tableWidth - TITLE_COLUMN_WIDTH;

    eventWidthSet(currentWidth);
    eventLeftOffsetSet(currentLeft);

    widthRangePositionSet({
      left: leftPosition,
      right: rightPosition,
    });

    minWidthSet(getMinimumEventWidth(tableWidth));
  }, [currentEvent, event, tableWidth, widthPerSecond]);

  const onResizeStart = () => {
    disableCreateCalendarEvent();
  };

  const onResize = useCallback((e, dir, ref, delta, position) => {
    if (dir === DIR_LEFT) {
      eventLeftOffsetSet(position.x);
    } else {
      const newWidth = eventWidth + delta.width;
      const newEventWidth = position.x + newWidth < widthRangePosition.right
        ? newWidth
        : widthRangePosition.right - eventLeftOffset;

      eventWidthSet(newEventWidth);
    }

    isOpenSet(false);
    isOptionsOpenSet(false);
  }, [eventLeftOffset, eventWidth, widthRangePosition.right]);

  const onResizeStop = useCallback((e, dir, refToElement, delta, position) => {
    e.stopImmediatePropagation();

    if (dir === 'right') {
      const endDateLimitHour = moment(endDateLimit).hour();
      const offset = position.x + eventWidth + TITLE_COLUMN_WIDTH;
      const hourByWidth = getTimeByWidth(offset, tableWidth);
      const hour = !endDateLimitHour || hourByWidth < endDateLimitHour
        ? hourByWidth
        : endDateLimitHour;
      const endDateTime = getEventDate(day, hour.toString());

      isOpenSet(false);
      isOptionsOpenSet(true);
      currentEventSet({
        ...currentEvent, endDateTime,
      });
      pendingChangesSet({
        startDateTime: pendingChanges.startDateTime || event.startDateTime,
        endDateTime,
      });
      return;
    }

    const startDateLimitHour = moment(startDateLimit).hour();
    const offset = position.x + TITLE_COLUMN_WIDTH;
    const hourByWidth = getTimeByWidth(offset, tableWidth);
    const hour = !startDateLimitHour || hourByWidth > startDateLimitHour
      ? hourByWidth
      : startDateLimitHour;
    const startDateTime = getEventDate(day, hour.toString());

    isOpenSet(false);
    isOptionsOpenSet(true);
    currentEventSet({
      ...currentEvent, startDateTime,
    });
    pendingChangesSet({
      endDateTime: pendingChanges.endDateTime || event.endDateTime,
      startDateTime,
    });
    return;
  }, [currentEvent, day, endDateLimit, event.endDateTime, event.startDateTime, eventWidth, pendingChanges.endDateTime, pendingChanges.startDateTime, startDateLimit, tableWidth]);

  const handleMouseEnter = () => {
    if (!isOptionsOpen) {
      isOpenSet(true);
      disableOutsideSet(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isOptionsOpen) {
      isOpenSet(false);
    }
  };

  const handleClick = (e) => {
    stopPropagation(e);

    disableCreateCalendarEvent();
    isOpenSet(false);
    isOptionsOpenSet(true);
  };

  const hide = useCallback((pendingChangesLocal) => {
    let eventLocal = pendingChangesLocal;

    if (!isEmpty(pendingChangesLocal)) {
      eventLocal = {
        ...pendingChangesLocal,
        endDateTime: !endDateLimit
        || moment(pendingChangesLocal.endDateTime).isBefore(endDateLimit)
          ? pendingChangesLocal.endDateTime
          : eventLocal.endDateTime,
        startDateTime: !startDateLimit
        || moment(pendingChangesLocal.startDateTime).isAfter(startDateLimit)
          ? pendingChangesLocal.startDateTime
          : eventLocal.startDateTime,
      };
    }

    const currentEventLocal = eventLocal ? {
      ...currentEvent,
      ...eventLocal,
    } : event;

    enableCreateCalendarEvent();

    isOpenSet(false);
    isOptionsOpenSet(false);
    currentEventSet(currentEventLocal);
    pendingChangesSet({});
    disableOutsideSet(false);
  }, [currentEvent, enableCreateCalendarEvent, endDateLimit, event, startDateLimit]);

  const handleClickOutside = () => {
    if (!disableOutside) return;

    isOpenSet(false);
    isOptionsOpenSet(false);
    disableOutsideSet(true);
    currentEventSet(event);
  };

  const initializeStyles = useCallback(() => {
    componentClassNamesSet(classnames(
      'schedule-item-line',
      {
        warning: event.type === calendarTypes.SCHEDULE_CALENDAR,
        'calendar-schedule': event.type === calendarTypes.SCHEDULE_CALENDAR,
        'calendar-shift': event.type === calendarTypes.SHIFT_CALENDAR,
      },
    ));
    popupClassNamesSet(classnames(
      {
        left: event.type === calendarTypes.SCHEDULE_CALENDAR,
        right: event.type === calendarTypes.SHIFT_CALENDAR,
      },
    ));
  }, [event.type]);

  // TODO: fix me, avoid suppressing the linter
  useEffect(() => {
    initializeStyles();
    return enableCreateCalendarEvent;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const position = { x: eventLeftOffset - 10, y: EVENT_TOP_OFFSET };
  const size = { width: eventWidth, height: EVENT_HEIGHT };

  const clickRef = useClickOutside(handleClickOutside);

  useComponentWillReceiveProps(props, willReceivePropsCallback);

  return (
    <div ref={clickRef}>
      <Rnd
        size={size}
        position={position}
        className={componentClassNames}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        enableResizing={ENABLE_RESIZING}
        disableDragging
        minWidth={minWidth}
        onResizeStart={onResizeStart}
        onResize={onResize}
        onResizeStop={onResizeStop}
        bounds={`.${rowType}`}
      >
        {(isOpen || (readOnly && isOptionsOpen)) && (
          <EventPreview
            event={currentEvent}
            className={popupClassNames}
          />
        )}
        {!readOnly && isOptionsOpen && (
          <EventActionsContainer
            event={currentEvent}
            day={day}
            hide={hide}
            className={popupClassNames}
            pendingChanges={pendingChanges}
          />
        )}
      </Rnd>
    </div>
  );
};


export default DraggableEvent;
