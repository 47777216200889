import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { last, truncate } from 'lodash';

import ToolDuration from '../../../common/toolDuration/ToolDuration';
import { getMachineLink } from '../../../index/routes';
import toolLogsMessages from '../../../../messages/tools.logs.messages';

const DASH = '–';

export const ToolRecentActivity = ({ tool, intl }) => {
  const mostRecentLog = (tool.logs && tool.logs.length) ? last(tool.logs) : null;
  const machine = tool.machine ? (
    <Link to={getMachineLink(tool.machine.id)}>
      { tool.machine.name }
    </Link>
  ) : DASH;

  if (!mostRecentLog) {
    return (
      <>
        <td>–</td>
        <td>–</td>
        <td>–</td>
        <td>{ machine }</td>
        <td>–</td>
      </>
    );
  }

  return (
    <>
      <td>{ intl.formatMessage(toolLogsMessages[mostRecentLog.type]) }</td>
      <td>
        { intl.formatDate(mostRecentLog.createdAt) }
        <br />
        <span className="font-weight-light">
          { intl.formatTime(mostRecentLog.createdAt) }
        </span>
      </td>
      <ToolDuration logs={tool.logs} />
      <td>{ machine }</td>
      <td className="td-comment">
        { truncate(mostRecentLog.comment, { length: 50, separator: ' ' }) }
      </td>
    </>
  );
};

ToolRecentActivity.propTypes = {
  tool: PropTypes.object.isRequired,

};

export default injectIntl(ToolRecentActivity);
