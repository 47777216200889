import React from 'react';

export const PaginationTool = () => (
  <ul className="pagination text-center">
    <li className="pagination-previous disabled" />
    <li><a href="">1</a></li>
    <li><span className="ellipsis" /></li>
    <li><a href="">5</a></li>
    <li><a href="">6</a></li>
    <li><a href="">7</a></li>
    <li><a href="">8</a></li>
    <li><a href="">9</a></li>
    <li className="current">10</li>
    <li><a href="">11</a></li>
    <li><a href="">12</a></li>
    <li><a href="">13</a></li>
    <li><a href="">14</a></li>
    <li><a href="">15</a></li>
    <li><span className="ellipsis" /></li>
    <li><a href="">25</a></li>
    <li className="pagination-next">
      <a href="" />
    </li>
  </ul>
);

export default PaginationTool;
