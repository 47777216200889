import { defineMessages } from 'react-intl';

export default defineMessages({
  experiments: {
    id: 'experimentsPage.experiments',
    defaultMessage: 'Experiments',
  },
  measurements: {
    id: 'experimentsPage.measurements',
    defaultMessage: 'Measurements',
  },
  addExperiment: {
    id: 'experimentsPage.buttons.add_experiment',
    defaultMessage: 'Add Experiment',
  },
  series: {
    id: 'experimentsPage.fields.series',
    defaultMessage: 'Experiment Series',
  },
  addExperimentModalTitle: {
    id: 'experimentsPage.modals.add_experiment.title',
    defaultMessage: 'Add Experiment',
  },
  editExperimentModalTitle: {
    id: 'experimentsPage.modals.edit_experiment.title',
    defaultMessage: 'Edit Experiment',
  },
  archiveExperimentModalText: {
    id: 'experimentsPage.modals.archive_experiment.title',
    defaultMessage: 'Do you really want to archive this Experiment?',
  },
  archiveMeasurementModalText: {
    id: 'experimentsPage.modals.archive_measurement.title',
    defaultMessage: 'Do you really want to archive this Measurement?',
  },
});
