import React from 'react';

const calendarImg = process.env.NODE_ENV === 'test' ? '' : '/img/calendar.png';

export default function CalendarWeekly() {
  return (
    <div className="calendar">
      <div className="calendar--item">
        <div className="calendar--item--left">
          <strong className="week-name">Week 37</strong>
          <p>12.11.16/19.11.16</p>
          <div className="calendar-users">
            <span className="icon icon-person" />
            {' '}
            12
          </div>
        </div>
        <div className="calendar--item--right">
          <img src={calendarImg} alt="" />
        </div>
      </div>

      <div className="calendar--item">
        <div className="calendar--item--left">
          <strong className="week-name">Week 37</strong>
          <p>12.11.16/19.11.16</p>
          <div className="calendar-users">
            <span className="icon icon-person" />
            {' '}
            12
          </div>
        </div>
        <div className="calendar--item--right">
          <img src={calendarImg} alt="" />
        </div>
      </div>

      <div className="calendar--item">
        <div className="calendar--item--left">
          <strong className="week-name">Week 37</strong>
          <p>12.11.16/19.11.16</p>
          <div className="calendar-users">
            <span className="icon icon-person" />
            {' '}
            12
          </div>
        </div>
        <div className="calendar--item--right">
          <img src={calendarImg} alt="" />
        </div>
      </div>

      <div className="calendar--item">
        <div className="calendar--item--left">
          <strong className="week-name">Week 37</strong>
          <p>12.11.16/19.11.16</p>
          <div className="calendar-users">
            <span className="icon icon-person" />
            {' '}
            12
          </div>
        </div>
        <div className="calendar--item--right">
          <img src={calendarImg} alt="" />
        </div>
      </div>
    </div>
  );
}
