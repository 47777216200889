import { createAction } from 'redux-act';

import { BASIC_TAB } from '../../core/common/admin.user.form.tabs';

export const adminSelectUserFormTab = createAction(
  'admin: select tab in user form',
  (tab) => tab,
);

export const adminSelectDefaultUserFormTab = createAction(
  'admin: select tab in user form',
);

export const reducer = {
  [adminSelectUserFormTab]: (state, tab) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        selected: {
          ...state.admin.users.selected,
          tab,
        },
      },
    },
  }),

  [adminSelectDefaultUserFormTab]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        selected: {
          ...state.admin.users.selected,
          tab: BASIC_TAB,
        },
      },
    },
  }),
};
