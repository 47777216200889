import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AnomalyHeaderContainer from './AnomalyHeader.container';
import Loader from '../_tools/loader/Loader';
import AnomalyDetails from '../_tools/anomaly/AnomalyDetails';
import * as routeHandlers from '../index/routeHandlers';

function Anomaly({
  event, anomaly, isAnomalyLoaded, isAnomalyLoading, match,
}) {
  // TODO: fix me, avoid suppressing the linter
  useEffect(() => {
    routeHandlers.onAnomalyPageEnter(match);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="col--1">
        <AnomalyHeaderContainer />
        {
          isAnomalyLoaded && (
            <div className="anomally--wrap">
              <div className="col--in">

                <div className="col--title">
                  { event.title }
                </div>

                <div className="scroll-block">
                  <div className="col--3">
                    <div className="col--3--col">
                      <div className="col--in">
                        <div className="h3">
                          <FormattedMessage
                            id="pages.anomaly.before"
                            defaultMessage="Before"
                          />
                        </div>
                        <AnomalyDetails payload={anomaly} />
                      </div>
                    </div>
                    <div className="col--3--col">
                      <div className="col--in">
                        <div className="h3">
                          <FormattedMessage
                            id="pages.anomaly.main_chart"
                            defaultMessage="Anomaly"
                          />
                        </div>
                        <AnomalyDetails payload={anomaly} />
                      </div>
                    </div>
                    <div className="col--3--col">
                      <div className="col--in">
                        <div className="h3">
                          <FormattedMessage
                            id="pages.anomaly.after"
                            defaultMessage="After"
                          />
                        </div>
                        <AnomalyDetails payload={anomaly} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          )
        }
      </div>
      <Loader
        show={isAnomalyLoading}
        solidBackground
      />
    </div>
  );
}

Anomaly.propTypes = {
  isAnomalyLoading: PropTypes.bool.isRequired,
  isAnomalyLoaded: PropTypes.bool.isRequired,
  event: PropTypes.object,
  anomaly: PropTypes.object,
  match: PropTypes.object.isRequired,
};

export default Anomaly;
