import React from 'react';
import { useIntl } from 'react-intl';
import { getRepeatTypeMessage } from '../../../../../../../messages/calendars.messages';
import { TodoFunction } from '../../../../../../../../../common/types/common';

interface Props {
  selected: boolean
  value?: string
  onClick: TodoFunction

}

export const RecurrenceOption: React.FC<Props> = ({
  selected,
  onClick,
  value,
}) => {
  const handleSelect = () => {
    onClick(value);
  };

  const { formatMessage } = useIntl();

  return (
    <label className="radio radio-round">
      <input type="radio" checked={selected} onChange={handleSelect}/>
      <span>{formatMessage(getRepeatTypeMessage(value))}</span>
    </label>
  );
};

export default RecurrenceOption;
