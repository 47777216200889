export const monday = 'Monday';
export const tuesday = 'Tuesday';
export const wednesday = 'Wednesday';
export const thursday = 'Thursday';
export const friday = 'Friday';
export const saturday = 'Saturday';
export const sunday = 'Sunday';

export default [
  monday, tuesday, wednesday, thursday, friday, saturday, sunday,
];
