import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function RightSidePlaceholder() {
  return (
    <div className="locations-empty">
      <div>
        <div className="locations-empty-icon">
          <object data="/img/location.svg" type="image/svg+xml" />
        </div>
        <p>
          <strong>
            <FormattedMessage
              id="location.location_is_set_up"
              defaultMessage="Location is Set Up"
            />
          </strong>
        </p>
      </div>
    </div>
  );
}
