import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const TabsBarItem = ({
  title,
  count,
  hasCount,
  isActive,
  onClick,
  showEditButton,
  showRemoveButton,
  onEditButtonClick,
  onRemoveButtonClick,
}) => (
  <div className={cn('big-tab', { active: isActive })} onClick={onClick}>
    <div className="big-tab-wrap">
      <div className="big-tab-in">
        { title }
        { hasCount && (<span className="label-num">{ count }</span>) }
        { showEditButton && isActive && (
          <button className="btn-link" onClick={onEditButtonClick}>
            <i className="icon-edit" />
          </button>
        ) }
        { showRemoveButton && isActive && (
          <button className="btn-link" onClick={onRemoveButtonClick}>
            <i className="icon-close2" />
          </button>
        ) }
      </div>
    </div>
  </div>
);

TabsBarItem.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasCount: PropTypes.bool,
  showEditButton: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  onEditButtonClick: PropTypes.func,
  onRemoveButtonClick: PropTypes.func,
};

TabsBarItem.defaultProps = {
  isActive: false,
  count: 0,
  hasCount: false,
  showEditButton: false,
  showRemoveButton: false,
  onEditButtonClick() {},
  onRemoveButtonClick() {},
};

export default TabsBarItem;
