import React from 'react';
import { Todo, TodoFunction } from '../../../../../../../../../../../common/types/common';
import { DATA_POINT_COMMON_FIELDS } from '../../../../../../../../../core/common/dataPoint.constants';
import {
  applyCustomOrderOnFields,
  FIRST_FIELDS_ORDER,
} from '../../../../../../../../../core/common/datatron';
import DataPointButtons from './buttons/DataPointButtons';
import ConfigItems from './ConfigItems';
import { DataPointCheckboxRow } from '../../../filters/selectors/DataPointCheckboxRow';

interface Props {
  onEditClick: TodoFunction;
  onArchiveClick: TodoFunction;
  onViewClick: TodoFunction;
  onIndexClick: TodoFunction;
  isArrayType: boolean;
  readOnly: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  dataPoint: Todo;
  configFields: Todo[];
  searchQuery?: string;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
}

export const DataPointRow: React.FC<Props> = ({
  dataPoint,
  configFields,
  onEditClick,
  onArchiveClick,
  onViewClick,
  readOnly,
  canUpdate,
  canDelete,
  searchQuery,
  onIndexClick,
  isArrayType,
  selectedRows,
  setSelectedRows,
}) => {
  const dataPointFields = applyCustomOrderOnFields(
    [...DATA_POINT_COMMON_FIELDS, ...configFields],
    FIRST_FIELDS_ORDER,
  );

  return (
    <>
      {!readOnly && (
        <DataPointCheckboxRow
          dataPointId={dataPoint.id}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
      <ConfigItems dataPoint={dataPoint} fields={dataPointFields} searchQuery={searchQuery} />
      {!readOnly && (canUpdate || canDelete) && (
        <DataPointButtons
          onIndexClick={onIndexClick}
          isArrayType={isArrayType}
          onEditClick={onEditClick}
          onArchiveClick={onArchiveClick}
          onViewClick={onViewClick}
          canUpdate={canUpdate}
          canDelete={canDelete}
          dataPointId={dataPoint.id}
        />
      )}
    </>
  );
};

export default DataPointRow;
