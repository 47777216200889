import { createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { addNewLocationsIntoExistingTree } from '../../core/common/locationPicker';
import { getTree } from '../selectors/locationPicker.selector';
import { getChildLocations } from '../../core/api/locations';
import { CallResult } from '../util';

export const fetchLocationPicker = createAction(
  'fetch locations for location picker',
  (parentLocationId) => ({ payload: parentLocationId }),
);

export const fetchLocationPickerSuccess = createAction(
  'fetch locations for location picker successfully',
  (newTree, latestLoadedParentId) => ({ payload: { newTree, latestLoadedParentId } }),
);

export const fetchLocationPickerFail = createAction(
  'fetch locations for location picker fail',
  (error) => ({ payload: error }),
);

export const reducer = {
  [fetchLocationPicker.type]: (state) => ({
    ...state,
    locationPicker: {
      ...state.locationPicker,
      loading: true,
      loaded: false,
      error: null,
    },
  }),

  [fetchLocationPickerSuccess.type]: (state, { newTree, latestLoadedParentId }) => ({
    ...state,
    locationPicker: {
      ...state.locationPicker,
      tree: newTree,
      latestLoadedParentId,
      loading: false,
      loaded: true,
      error: null,
    },
  }),

  [fetchLocationPickerFail.type]: (state, error) => ({
    ...state,
    locationPicker: {
      ...state.locationPicker,
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* fetchLocationByIdSaga({ payload: parentLocationId }) {
  const response: CallResult<typeof getChildLocations> = yield call(getChildLocations, parentLocationId, { per_page: 10000 });

  if (response.success) {
    if ('message' in response.data) {
      // it's an error
      yield put(fetchLocationPickerFail(response.data.message));
    } else {
      const state = yield select();
      const oldTree = yield call(getTree, state);
      const newTree = yield call(
        addNewLocationsIntoExistingTree,
        oldTree,
        response.data.list,
        parentLocationId,
      );
      yield put(fetchLocationPickerSuccess(newTree, parentLocationId));
    }
  } else {
    const { error, type } = response;
    if (type === 'generalError') {
      yield put(fetchLocationPickerFail(error));
    } else {
      yield put(fetchLocationPickerFail(error.message));
    }
  }
}

export function* watchFetchLocationPickerById() {
  yield takeEvery(fetchLocationPicker, fetchLocationByIdSaga);
}
