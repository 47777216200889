import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { ceil } from 'lodash';

import * as api from '../../core/api';
import { closeModal } from './modals.module';
import { AP_LOCATION_REMOVE } from '../constants/modals.constants';
import { removeObjectFromList, compareById } from '../../../../common/helpers/object';
import { getPagination, getLocations } from '../selectors/admin.locations.selector';
import { fetchAdminLocations } from './admin.locations.fetch.module';
import { fetchAdminLocationsPagination } from './admin.locations.pagination.fetch.module';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const adminRemoveLocation = createAction(
  'admin: remove location',
  (id) => id,
);

export const adminRemoveLocationSuccess = createAction(
  'admin: remove location - success',
  (id) => id,
);

export const adminRemoveLocationFail = createAction(
  'admin: remove location - fail',
  (error) => error,
);

export const reducer = {
  [adminRemoveLocation]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        selected: {
          ...state.admin.locations.selected,
          item: {
            ...state.admin.locations.selected.item,
            _remove: {
              loaded: false,
              loading: true,
              error: null,
            },
          },
        },
      },
    },
  }),

  [adminRemoveLocationSuccess]: (state, id) => {
    const newState = {
      ...state,
      admin: {
        ...state.admin,
        locations: {
          ...state.admin.locations,
          selected: {
            ...state.admin.locations.selected,
            item: null,
            loaded: false,
            loading: false,
            error: null,
          },
        },
      },
    };

    if (newState.admin.locations.pagination) {
      newState.admin.locations.pagination = {
        ...newState.admin.locations.pagination,
        total: newState.admin.locations.pagination.total - 1,
      };
    }

    if (newState.admin.locations.list) {
      newState.admin.locations.list = removeObjectFromList(
        newState.admin.locations.list,
        { id },
        compareById,
      );
    }

    return newState;
  },

  [adminRemoveLocationFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        selected: {
          ...state.admin.locations.selected,
          item: {
            ...state.admin.locations.selected.item,
            _remove: {
              loaded: false,
              loading: false,
              error,
            },
          },
        },
      },
    },
  }),
};

export function* adminRemoveLocationSaga({ payload: id }) {
  const { response, error } = yield call(api.admin.removeLocation, id);

  if (response) {
    yield put(adminRemoveLocationSuccess(id));
    yield put(closeModal(AP_LOCATION_REMOVE));
    const state = yield select();
    const pagination = yield call(getPagination, state);

    if (pagination && pagination.current_page) {
      yield put(fetchAdminLocationsPagination(pagination.current_page));

      if (pagination.current_page === pagination.total_pages
        && ceil((pagination.total - 1) / pagination.per_page) !== pagination.current_page
      ) {
        yield put(fetchAdminLocations(pagination.current_page - 1));
      } else {
        const list = yield call(getLocations, state);
        if (list && list.length === 0) {
          yield put(fetchAdminLocations(pagination.current_page));
        }
      }
    }
    yield put(sendNotification({ ...notificationMessages.location_removed, values: { name: response.name } }));
  } else {
    yield put(adminRemoveLocationFail(error));
  }
}

export function* watchAdminRemoveLocation() {
  yield takeEvery(adminRemoveLocation.getType(), adminRemoveLocationSaga);
}
