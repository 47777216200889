import React from 'react';
import { FormattedMessage } from 'react-intl';

import TimezoneButtonContainer from './timezoneButton/TimezoneButton.container';
import commonMessages from '../../../../../../messages/common.messages';
import commonFieldsMessages from '../../../../../../messages/common.fields.messages';
import dataPointConfigMessages from '../../../../../../messages/dataPoint.config.messages';

export const MetricDataHeader = () => (
  <thead>
    <tr>
      <td>
        <FormattedMessage {...commonMessages.timestamp} />
        <TimezoneButtonContainer />
      </td>
      <FormattedMessage {...dataPointConfigMessages.value} tagName='td' />
      <FormattedMessage {...commonFieldsMessages.scaledValue} tagName='td' />
    </tr>
  </thead>
);

export default MetricDataHeader;
