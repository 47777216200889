import React, { useCallback, useEffect, useState } from 'react';
import { datatronStats } from '../../../../../common/enums/reportTypes';
import { Todo } from '../../../../../common/types/common';
import { fetchDatatronStats } from '../../../core/common/datatron';
import Graph from '../../common/cardGraph/Graph';
import DatatronPhotoContainer from '../../common/cardPhoto/DatatronCardPhoto.container';
import { ActivateButton } from './ActivateButton';
import CardEditButtonContainer from './CardEditButton.container';
import Indicators from './Indicators';
import Info from './Info';

interface Props {
  datatron: Todo;
  canOpenDetails?: boolean;
}

const Card: React.FC<Props> = ({ datatron, canOpenDetails = false }) => {
  const [stats, statsSet] = useState({});

  const refreshDatatron = useCallback(() => {
    const { id, networkSpeed } = datatron;
    statsSet({ fetching: true });
    fetchDatatronStats(id, networkSpeed, {
      instance: datatron.ipAddress || 'node-exporter',
    }).then(({ stats: response }) => {
      statsSet(response);
    });
  }, [datatron]);

  useEffect(() => {
    refreshDatatron();
  }, [refreshDatatron]);

  const report = {
    type: datatronStats,
    data: stats,
    disabledProps: [],
  };

  return (
    <div className='col--in'>
      <div className='card-cols'>
        <div className='card-colL-lc-wrap mr-20'>
          <div className='card-top' style={{ gap: '4px' }}>
            <h5>{datatron.name}</h5>
            <CardEditButtonContainer datatronId={datatron.id} />
            <ActivateButton datatronId={datatron.id} isActive={datatron.active} />
          </div>
          <div className='card-col-lc'>
            <div className='card-col-l'>
              <DatatronPhotoContainer
                datatron={datatron}
                canOpenDetails={canOpenDetails}
                canUpdate
              />
            </div>
            <div className='card-col-c'>
              <Info datatron={datatron} />
              <div className='line' />
              {stats && <Indicators datatronStats={stats} />}
            </div>
          </div>
        </div>
        <div className='card-col-r'>
          <Graph report={report} isDatatron />
        </div>
      </div>
    </div>
  );
};

export default Card;
