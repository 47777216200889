import * as eventCategories from './eventCategory';

export const availability = 'availability';
export const quality = 'quality';
export const performance = 'performance';

export const maintenancePlanned = 'planned';
export const maintenanceUnplanned = 'unplanned';

export const eventInfo = eventCategories.info;
export const eventFatal = eventCategories.fatal;
export const eventError = eventCategories.error;
export const eventAnomaly = eventCategories.anomaly;
export const eventWarning = eventCategories.warning;

export const maintenanceTypes = [
  maintenancePlanned, maintenanceUnplanned,
] as const;
export type MaintenancePeriodType = typeof maintenanceTypes[number];

export const events = [
  eventInfo, eventFatal, eventError, eventAnomaly, eventWarning,
] as const;
export type EventsPeriodType = typeof events[number];

export const oee = [
  availability, performance, quality,
] as const;
export type OeePeriodType = typeof oee[number];

export const PERIOD_TYPES = [
  ...maintenanceTypes,
  ...events,
  ...oee,
] as const;
export type PeriodType = typeof PERIOD_TYPES[number];

export default PERIOD_TYPES;
