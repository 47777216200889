import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_FILTER_INPUT } from '../../../redux/constants/components.constants';
import {
  closeComponent,
  openComponent,
} from '../../../redux/modules/components.visibility.module';
import { isComponentOpen } from '../../../redux/selectors/componentsVisibility.selector';
import {
  Props as ParentProps,
  TableQuerySearchStateless,
} from './TableQuerySearchStateless';

interface Props extends Omit<ParentProps, 'isOpen' | 'open' | 'close'> {
  stateComponentId: string;
}

const TableQuerySearchState: React.FC<Props> = ({
  stateComponentId,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    isComponentOpen(state, stateComponentId || DEFAULT_FILTER_INPUT),
  );
  const open = useCallback(() => {
    dispatch(openComponent(stateComponentId || DEFAULT_FILTER_INPUT));
  }, [dispatch, stateComponentId]);
  const close = useCallback(() => {
    dispatch(closeComponent(stateComponentId || DEFAULT_FILTER_INPUT));
  }, [dispatch, stateComponentId]);
  return (
    <TableQuerySearchStateless
      open={open}
      close={close}
      isOpen={isOpen}
      {...rest}
    />
  );
};

export default TableQuerySearchState;
