import { connect } from 'react-redux';

import ItemFiltersContainer from './ItemFilters.container';
import { MACHINES } from '../../../../../common/constants/entities';
import messages from './ItemFilters.messages';

export const mapStateToProps = () => ({
  itemType: MACHINES,
  label: messages.dashboard_filter_machines,
});

export default connect(mapStateToProps)(ItemFiltersContainer);
