import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

export function Unauthorized() {
  const history = useHistory();

  return (
    <div className="errorPage">
      <div>
        <div className="pb15">
          <div className="reports-empty-icon">
            <img src="/img/no-report.svg" alt=""/>
          </div>
          <div className="h1">401</div>
          <FormattedMessage
            id="pages.401.head"
            defaultMessage="Access Denied."
            tagName="p"
          />
        </div>
        <button
          onClick={history.goBack}
          className="button__back"
        >
          <FormattedMessage
            id="pages.401.back_button"
            defaultMessage="Go Back"
          />
        </button>
      </div>
    </div>
  );
}

Unauthorized.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default Unauthorized;
