import Slider from './Slider';
import { getChartComponentPayload, getDatesByReport, getUuid } from '../../../core/common/report.chart.v2';
import React from 'react';
import { Todo, TodoFunction } from '../../../../../common/types/common';
import { ReportT } from '../../../../types/report';

const MARGIN = {
  top: 0,
  right: 32,
  bottom: 0,
  left: 32,
} as const;
const AXIS_HEIGHT = 20;

interface Props {
  width?: number;
  height?: number;
  report: ReportT;
  selection: Todo;
  changeSelection: TodoFunction;
}

export const SliderContainer: React.FC<Props> = ({
  width,
  height,
  report,
  selection,
  changeSelection,
}) => {
  const frameWidth = width !== undefined ? width - MARGIN.left - MARGIN.right : 0;
  const frameHeight = height !== undefined ? height - MARGIN.top - MARGIN.bottom - AXIS_HEIGHT : 0;
  const translate = `translate(${MARGIN.left}, ${MARGIN.top})`;

  const {
    leftAxisChartPoints,
    leftAxisEntries,
    isLeftAxisEnabled,
    rightAxisChartPoints,
    rightAxisEntries,
    isRightAxisEnabled,
    xScale,
    yLeftScale,
    yRightScale,
  } = getChartComponentPayload({
    ...getDatesByReport(report),
    chartData: report.data.chartV2,
    filters: report.filters.dataPoints,
    getId: getUuid,
    disabledEntriesIds: report.disabledProps,
    frameWidth,
    frameHeight,
    verticalDataOffset: 0.05,
  });

  return (
    <Slider
      yLeftScale={yLeftScale}
      changeSelection={changeSelection}
      yRightScale={yRightScale}
      sliderHeight={frameHeight}
      rightAxisEntries={rightAxisEntries}
      leftAxisEntries={leftAxisEntries}
      isRightAxisEnabled={isRightAxisEnabled}
      rightAxisChartPoints={rightAxisChartPoints}
      xScale={xScale}
      leftAxisChartPoints={leftAxisChartPoints}
      isLeftAxisEnabled={isLeftAxisEnabled}
      reportId={report.id}
      frameWidth={frameWidth}
      selection={selection}
      translate={translate}
    />
  );
};

export default SliderContainer;
