import { defineMessages } from 'react-intl';

import * as autoRefresh from '../../../common/enums/report.autoRefresh';

export default defineMessages({
  [autoRefresh.OFF]: {
    id: 'reports.autoRefresh.off',
    defaultMessage: 'Off',
  },
  [autoRefresh.FIVE_SECONDS]: {
    id: 'reports.autoRefresh.5_seconds',
    defaultMessage: '5 seconds',
  },
  [autoRefresh.TEN_SECONDS]: {
    id: 'reports.autoRefresh.10_seconds',
    defaultMessage: '10 seconds',
  },
  [autoRefresh.FIVE_MIN]: {
    id: 'reports.autoRefresh.5_minutes',
    defaultMessage: '5 minutes',
  },
  [autoRefresh.HALF_HOUR]: {
    id: 'reports.autoRefresh.30_minutes',
    defaultMessage: '30 minutes',
  },
  [autoRefresh.ONE_HOUR]: {
    id: 'reports.autoRefresh.1_hour',
    defaultMessage: '1 hour',
  },
});
