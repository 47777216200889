import { isNumber, round } from 'lodash';

import { DATA_POINT_COMMON_FIELDS } from './dataPoint.constants';
import { keyify } from './object';

export const initializeDataPoint = (dataPoint) => {
  const configKeys = keyify(dataPoint.config);

  return {
    ...dataPoint,
    configKeys,
    configKeysHash: JSON.stringify(configKeys),
    _update: {
      loading: false,
      loaded: false,
      error: null,
    },
    _archive: {
      loading: false,
      loaded: false,
      error: null,
    },
  };
};

export const getCommonConfigFields = (dataPoint = {}) => DATA_POINT_COMMON_FIELDS.map(field => ({
  field,
  value: (dataPoint[field._key] !== undefined) ? dataPoint[field._key] : null,
}));

export const createGetScaledValue = dataPoint => (value) => {
  if (!dataPoint || !value || !isNumber(value) || isNaN(value)) return null;

  let result = value;

  if (isNumber(dataPoint.scalingFactor)) result *= dataPoint.scalingFactor;
  if (isNumber(dataPoint.scalingOffset)) result += dataPoint.scalingOffset;

  return round(result, 2);
};
