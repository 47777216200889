import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';

import stopPropagation from '../../../_tools/stopPropagation.js';

const EventComment = ({ comment }) => (
  <div
    className="feedback--item"
    onClick={stopPropagation}
  >
    <div className="feedback-person-wrap">
      <div className="feedback-person">
        <span className="icon icon-comment" />
        {comment.user.name}
      </div>
      <ul className="inline-list">
        <li className="inline-list--date">
          <span className="icon icon-calendar" />
          <FormattedDate value={comment.createdAt} tagName="span" />
        </li>
        <li className="inline-list--time">
          <span className="icon icon-time-filled" />
          <FormattedTime value={comment.createdAt} tagName="span" />
        </li>
      </ul>
    </div>
    <div className="feedback-comment">
      {comment.text}
    </div>
  </div>
);

EventComment.propTypes = {
  comment: PropTypes.object.isRequired,
};

export default EventComment;
