import React, { BaseSyntheticEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { FormType } from '../../../core/forms/general';
import { DATATRON_FORM_NAME } from '../../../redux/constants/datatron.form.constants';
import { updateDatatron } from '../../../redux/modules/datatron.update.module';
import { reset } from '../../../redux/modules/machine.page.form.module';
import { createDatatron } from '../../../redux/modules/newDatatron.create.module';
import { DatatronFormFooter } from './DatatronFormFooter';

interface Props {
  submitting: boolean;
  pristine: boolean;
  children: React.ReactNode;
  formType: FormType;
  backLink: string;
}

const DatatronForm: React.FC<Props> = ({
  backLink,
  submitting,
  formType,
  pristine,
  children,
}) => {
  const dispatch = useDispatch();

  const getConfirmHandler = useCallback(() => {
    switch (formType) {
      case 'add':
        return () => dispatch(createDatatron());
      case 'edit':
        return () => dispatch(updateDatatron());
    }
  }, [dispatch, formType]);

  const confirm = getConfirmHandler();

  const handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    confirm();
  };

  return (
    <form>
      <div className="form">
        {children}

        <div className="line" />

        <DatatronFormFooter
          onCancelClick={reset}
          cancelLink={backLink}
          submitting={submitting}
          formType={formType}
          pristine={pristine}
          handleSubmit={handleSubmit}
        />
      </div>
    </form>
  );
};

const form = {
  form: DATATRON_FORM_NAME,
  destroyOnUnmount: true,
};

// @ts-ignore
export default reduxForm(form)(DatatronForm);
