import { connect } from 'react-redux';

import Processings from './Processings';
import * as selector from '../../../../redux/selectors/batchDetails.selector';

export function mapStateToProps(state) {
  return {
    processings: selector.batchProcessings(state),
  };
}

export default connect(mapStateToProps)(Processings);
