import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { anomaly } from '../../../../../common/enums/eventCategory';
import EventHeaderContainer from './EventHeader.container';
import EventBody from './EventBody';
import EventMachineImageContainer from './EventMachineImage.container';
import EventFooter from './EventFooter';
import EventDetailsContainer from './details/EventDetails.container';
import TailEvents from './tailEvents/TailEvents';
import {
  NOT_EXPANDED,
  EXPANDED_COMMENTS,
  EXPANDED_MASTER_EVENT,
} from '../../../redux/constants/dashboard.constants';
import stopPropagation from '../../_tools/stopPropagation.js';

export default function EventComponent({
  event, displayMode, expandComments,
  fetchCluster, expandMasterEvent, hideEventDetails, fetchAnomaly,
}) {
  function handleClick() {
    if (displayMode === EXPANDED_COMMENTS) {
      return hideEventDetails(event.id);
    }

    expandComments(event.id);
    if (event.category === anomaly) {
      // disable re-load on next expand
      fetchAnomaly(event.id);
    }
    return null;
  }

  function handleClickOnTrain(e) {
    stopPropagation(e);

    if (displayMode === EXPANDED_MASTER_EVENT) {
      return hideEventDetails(event.id);
    }

    fetchCluster(event.id, event.cluster.id);
    expandMasterEvent(event.id);
    return null;
  }

  function handleClickOnArrow(e) {
    stopPropagation(e);

    if (displayMode !== NOT_EXPANDED) {
      return hideEventDetails(event.id);
    }

    return handleClick();
  }

  const className = classnames(
    'position',
    { opened: displayMode !== NOT_EXPANDED },
  );

  return (
    <div
      className={className}
      onClick={handleClick}
    >
      <div className="position--top">
        <EventMachineImageContainer machine={event.machine} />
        <div className="position-text">
          <EventHeaderContainer
            title={event.title}
            machine={event.machine}
            category={event.category}
            onArrowClick={handleClickOnArrow}
          />
          <EventBody message={event.details} />
          <EventFooter
            timestamp={event.timestamp}
            commentsCount={event.comments.length}
            eventCount={event.cluster ? event.cluster.eventCount : null}
            onTrainClick={handleClickOnTrain}
            showTrain={(!(event.cluster === undefined))}
          />
        </div>
      </div>
      {
        (displayMode === EXPANDED_COMMENTS) && <EventDetailsContainer event={event} />
      }
      {
        (displayMode === EXPANDED_MASTER_EVENT) && (
        <TailEvents
          events={event.cluster.tail || []}
        />
        )
      }
    </div>
  );
}

export const eventPropType = {
  event: PropTypes.object.isRequired,
  displayMode: PropTypes.string.isRequired,
  fetchAnomaly: PropTypes.func.isRequired,
  fetchCluster: PropTypes.func.isRequired,
  expandComments: PropTypes.func.isRequired,
  expandMasterEvent: PropTypes.func.isRequired,
  hideEventDetails: PropTypes.func.isRequired,
};

EventComponent.propTypes = eventPropType;
