import { reduxForm } from 'redux-form';

import OrganizationForm from './OrganizationForm';
import { ADMIN_ORGANIZATION_FORM_NAME } from '../../../../../../redux/constants/admin.organization.form.constants';

const form = {
  form: ADMIN_ORGANIZATION_FORM_NAME,
  destroyOnUnmount: true,
};

export default reduxForm(form)(OrganizationForm);
