import { connect } from 'react-redux';

import Batches from './Batches';
import * as selector from '../../../redux/selectors/batch.selector';
import { fetchBatchDetails } from '../../../redux/modules/batch.details.fetch.module';
import { selectIsPreviewEnabled } from '../../../redux/selectors/appConfig.selector';

export const mapStateToProps = (state) => ({
  batches: selector.getAllBatches(state),
  loading: selector.areBatchesLoading(state),
  hasFilters: selector.hasFilters(state),
  canSeePaginationTool: selectIsPreviewEnabled(state),
});

export const mapDispatchToProps = {
  loadBatchProcessings: fetchBatchDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Batches);
