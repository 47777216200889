import { createAction } from 'redux-act';

import * as entryTypes from '../constants/configRepoExplorer.entry.types.constants';

export const setDisabledEntries = createAction(
  'set disabled entries',
  (entryType = entryTypes.DATATRONS, ids = []) => ({ entryType, ids }),
);

export const reducer = {
  [setDisabledEntries]: (state, { entryType, ids }) => ({
    ...state,
    configRepoExplorer: {
      ...state.configRepoExplorer,
      [entryType]: {
        ...state.configRepoExplorer[entryType],
        disabled: ids,
      },
    },
  }),
};
