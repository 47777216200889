import { cloneDeep } from 'lodash';
import {
  DEFAULT_TAB,
} from '../constants/machine.tabs.constants';
import {
  STACKED_BAR_CHART,
} from '../constants/chart.type.constants';
import { DATE_FROM, DATE_TO } from '../../../../common/constants';
import * as toolStates from '../../../../common/enums/toolStates';

export const machineTestState = {
  chartType: STACKED_BAR_CHART,
  events: [
    {
      id: 1,
      title: 'Disk array OXO2 latency warning',
      status: 'fatal',
      timestamp: new Date(2013, 2, 2, 1, 10),
      details: 'message',
      machineId: 1,
    },
  ],
  eventsState: {},
  details: {
    id: 8845377,
    name: 'Robotron-S2300',
    departmentPath: '3rd Floor',
    link: '/app/inventory/machines/123',
    image: '/img/position.png',
    documents: [
      {
        id: 1,
      },
    ],
  },
  tab: DEFAULT_TAB,
  resetSelectedTab: true,
  summary: {
    loaded: true,
    loading: false,
    data: [{
      title: 'some title',
      amount: 500,
      latestAt: '2017-04-27T15:54:00.000Z',
    }],
    error: null,
    expandedRowIndex: 0,
    filters: {
      category: null,
      [DATE_FROM]: null,
      [DATE_TO]: null,
      query: '',
    },
    sort: {
      option: null,
      way: null,
    },
  },
  clustersSummary: {
    loaded: false,
    loading: false,
    data: [
      {
        category: 'anomaly',
        title: 'Cluster #1',
        name: 'cluster_q',
        count: 2,
        max: '2017-06-19T20:22:02.312Z',
        clusters: [
          {
            id: 'MTE0OTc5OTMxMDc4MzI=',
            title: 'Cluster #1',
            name: 'cluster_q',
            category: 'anomaly',
            timestamp: '2017-06-19T20:22:02.312Z',
            uuid: '4a138fe2-920c-4fe8-a9e9-7a547302d8d3',
            eventCount: 3,
            displayMode: 'NOTHING',
            details: {
              loaded: false,
              loading: false,
              error: null,
            },
          },
        ],
      },
    ],
    error: null,
    expandedRowIndex: null,
    filters: {
      category: null,
      [DATE_FROM]: null,
      [DATE_TO]: null,
      query: '',
    },
    sort: {
      option: null,
      way: null,
    },
  },
  sensors: [],
  expandedSensors: {},
  sensorsState: {},
  newAssistant: {
    isModalOpen: false,
    selected: null,
  },
  calendars: {
    view: {
      fromDate: null,
      toDate: null,
    },
    change: {
      loading: false,
      loaded: false,
      error: null,
    },
  },
  _update: {
    loading: false,
    loaded: false,
    error: null,
  },
  dataPoints: {
    list: [],
    loading: false,
    loaded: false,
    error: null,
    subscribe: {
      loading: false,
      loaded: false,
      error: null,
    },
    filters: {
      category: [],
    },
    pagination: {
      current: 50,
      next: 100,
    },
  },
  maintenance: {
    filters: {
      category: [],
    },
  },
  oee: { loaded: false },
  tools: {
    list: [
      {
        id: 123,
        label: 'label 123',
        type: 'type 123',
        model: 'model 123',
        state: 'mounted',
        machine: {
          id: 456,
          name: 'machine 456',
        },
      },
    ],
    loading: false,
    loaded: true,
    error: null,
    filters: {
      state: [toolStates.NEW, toolStates.MOUNTED, toolStates.USED],
    },
  },
  allMachines: {
    list: [],
    loading: false,
    loaded: false,
    error: null,
  },
};

export const basic = {
  _update: {
    loading: false,
    loaded: false,
    error: null,
  },
  chartType: STACKED_BAR_CHART,
  events: [],
  eventsState: {},
  details: {},
  tab: DEFAULT_TAB,
  resetSelectedTab: true,
  summary: {
    loaded: false,
    loading: false,
    data: [],
    error: null,
    expandedRowIndex: null,
    filters: {
      category: [],
      [DATE_FROM]: null,
      [DATE_TO]: null,
      query: '',
    },
    sort: {
      option: null,
      way: null,
    },
  },
  clustersSummary: {
    loaded: false,
    loading: false,
    data: [],
    error: null,
    expandedRowIndex: null,
    filters: {
      category: [],
      [DATE_FROM]: null,
      [DATE_TO]: null,
      query: '',
    },
    sort: {
      option: null,
      way: null,
    },
  },
  sensors: [],
  expandedSensors: {},
  sensorsState: {},
  newAssistant: {
    isModalOpen: false,
    selected: null,
  },
  calendars: {
    view: {
      fromDate: null,
      toDate: null,
    },
    change: {
      loading: false,
      loaded: false,
      error: null,
    },
  },
  dataPoints: {
    list: [],
    loading: false,
    loaded: false,
    error: null,
    subscribe: {
      loading: false,
      loaded: false,
      error: null,
    },
    filters: {
      category: [],
    },
    pagination: {
      current: 100,
      next: 100,
    },
  },
  maintenance: {
    filters: {
      category: [],
    },
  },
  oee: { loaded: false },
  tools: {
    list: [],
    loading: false,
    loaded: false,
    error: null,
    filters: {
      state: [toolStates.NEW, toolStates.MOUNTED, toolStates.USED],
    },
  },
  allMachines: {
    list: [],
    loading: false,
    loaded: false,
    error: null,
  },
};

export const machineInitialState = cloneDeep(basic);
