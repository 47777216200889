import React from 'react';

import { DATE_FROM, DATE_TO } from '../../../../../../../common/constants';
import Calendars from './calendars/CalendarDisplay';
import AbsolutePresets from './presets/AbsolutePresets';
import EditModalFooter from '../../../../modals/core/footers/EditModalFooter';
import { DateProps } from '../../../../../core/propTypes/propTypes';
import { Todo, TodoFunction } from '../../../../../../../common/types/common';

export interface DropdownAbsoluteProps {
  [DATE_FROM]: DateProps, // eslint-disable-line react/no-unused-prop-types
  [DATE_TO]: DateProps, // eslint-disable-line react/no-unused-prop-types
  setAbsoluteDate: TodoFunction,
  setAbsoluteDates: TodoFunction,
  onCalendarHover: TodoFunction,
  useConfirmationFooter: boolean,
  showAbsoluteDatesPresets: boolean,
  pristine: boolean,
  confirm: TodoFunction,
  cancel: TodoFunction,
  fromLabel: Todo,
  toLabel: Todo,
  allowFuture: boolean,
}

export const DropdownAbsolute: React.FC<DropdownAbsoluteProps> = ({
  [DATE_FROM]: date_from,
  [DATE_TO]: date_to,
  setAbsoluteDate,
  setAbsoluteDates,
  onCalendarHover = () => {
  },
  useConfirmationFooter,
  showAbsoluteDatesPresets,
  pristine,
  confirm,
  cancel,
  fromLabel,
  toLabel,
  allowFuture,
}) => {


  const dates = {
    [DATE_FROM]: date_from,
    [DATE_TO]: date_to,
  };

  return (
    <div>
      <Calendars
        onChange={setAbsoluteDate}
        onMouseOver={onCalendarHover}
        fromLabel={fromLabel}
        toLabel={toLabel}
        allowFuture={allowFuture}
        {...dates}
      />
      {showAbsoluteDatesPresets && (
        <AbsolutePresets
          setAbsoluteDates={setAbsoluteDates}
          {...dates}
        />
      )}
      {useConfirmationFooter && (
        <EditModalFooter
          onSaveClick={confirm}
          onCancelClick={cancel}
          isSaveButtonDisabled={pristine}
        />
      )}
    </div>
  );
};

export default DropdownAbsolute;
