import KpiChartContainer from '../../../_tools/kpiChart/KpiChartContainer';
import { selectSelectionByReportId } from '../../../../redux/selectors/reports.selector';
import React from 'react';
import { Todo } from '../../../../../../common/types/common';
import { ChartType } from '../../../../redux/constants/chart.type.constants';
import { useParamSelector } from '../../../../redux/util';
import { ReportT } from '../../../../../types/report';

interface Props {
  id: ReportT['id'];
  chartType: ChartType;
  data: Todo;
  allowedProps: Todo;
  [key: string]: Todo;
}

export const ReportKpiChartContainer: React.FC<Props> = ({
  id,
  chartType,
  data,
  allowedProps,
  ...rest
}) => {
  const selection = useParamSelector(selectSelectionByReportId, id);
  return (
    <KpiChartContainer
      chartType={chartType}
      data={data}
      selection={selection}
      allowedProps={allowedProps}
      {...rest}
    />
  );
};

export default ReportKpiChartContainer;
