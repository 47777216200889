import { createAction } from 'redux-act';
import {
  put, call, select, takeLatest, all,
} from 'redux-saga/effects';

import * as batchApi from '../../core/api/batch';
import { getFilters } from '../selectors/batchDetails.selector';
import { filterByDate } from './batch.details.filter.dates.module';

export const fetchBatchDetails = createAction(
  'fetch batch details',
  (batchId) => ({ batchId }),
);

export const fetchBatchDetailsSuccess = createAction(
  'fetch batch details - success',
  (batch, processings) => ({ batch, processings }),
);

export const fetchBatchDetailsFail = createAction(
  'fetch processings by batch - failed',
  (error) => error,
);

export const reducer = {
  [fetchBatchDetails]: (state) => ({
    ...state,
    batchDetails: {
      ...state.batchDetails,
      processings: {
        ...state.batchDetails.processings,
        list: [],
      },
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [fetchBatchDetailsSuccess]: (state, { batch, processings }) => ({
    ...state,
    batchDetails: {
      ...state.batchDetails,
      ...batch,
      processings: {
        ...state.batchDetails.processings,
        list: processings,
      },
      loaded: true,
      loading: false,
      error: null,
    },
  }),

  [fetchBatchDetailsFail]: (state, error) => ({
    ...state,
    batchDetails: {
      ...state.batchDetails,
      processings: {
        ...state.batchDetails.processings,
        list: [],
      },
      loaded: false,
      loading: false,
      error,
    },
  }),
};

export function* fetchBatchDetailsSaga({ payload: { batchId } }) {
  const state = yield select();
  const filters = yield call(getFilters, state);

  const [
    processingsCall,
    batchCall,
  ] = yield all([
    call(batchApi.getProcessingById, batchId, filters),
    call(batchApi.getById, batchId),
  ]);

  const success = batchCall.response && processingsCall.response;

  if (success) {
    yield put(fetchBatchDetailsSuccess(
      batchCall.response,
      processingsCall.response,
    ));
  } else {
    const error = batchCall.error || processingsCall.error;
    yield put(fetchBatchDetailsFail(error));
  }
}

export function* watchFetchBatchDetails() {
  yield takeLatest(
    [
      fetchBatchDetails.getType(),
      filterByDate.getType(),
    ],
    fetchBatchDetailsSaga,
  );
}
