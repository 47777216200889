import { Todo } from '../../../../common/types/common';

export type LocationState = {
  id?: string;
  name?: string;
  type?: string;
  _status: {
    loaded: boolean,
    loading: boolean,
    error: string,
  },
  filter?: string,
  filterChildren: Todo[]
};

export const locationInitialState: LocationState = {
  _status: {
    loaded: false,
    loading: false,
    error: '',
  },
  filter: undefined,
  filterChildren: [],
};



export const locationTestState: LocationState = {
  id: 'MjE0ODY2MzgwNzQ4OTE=',
  name: 'Test location',
  type: 'city',
  _status: {
    loaded: false,
    loading: false,
    error: '',
  },
  filter: '',
  filterChildren: [],
};
