import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function NoCards({ isInProgress, isPrioritized, isInbox }) {
  return (
    <div className="maintenance-empty">
      {
        isInProgress
        && (
        <div className="flex-el">
          <div>
            <div className="maintenance-empty-icon">
              <img src="/img/in_progress.svg" alt="" />
            </div>
            <p>
              <strong>
                <FormattedMessage
                  id="maintenance.no_updates_in_progress"
                  defaultMessage="No Updates in Progress"
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id="maintenance.add_the_first_machine_to_see_events_coming_in"
                defaultMessage="Add the first machine to see events coming in."
              />
            </p>
          </div>
        </div>
        )
      }
      {
        isPrioritized
        && (
        <div className="flex-el">
          <div>
            <div className="maintenance-empty-icon">
              <img src="/img/prioritized.svg" alt="" />
            </div>
            <p>
              <strong>
                <FormattedMessage
                  id="maintenance.nothing_is_prioritized"
                  defaultMessage="Nothing is Prioritized"
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id="maintenance.move_an_update_from_the_inbox_to_get_started"
                defaultMessage="Move an update from the inbox to get started."
              />
            </p>
          </div>
        </div>
        )
      }
      {
        isInbox
        && (
        <div className="flex-el">
          <div>
            <div className="maintenance-empty-icon">
              <img src="/img/inbox.svg" alt="" />
            </div>
            <p>
              <strong>
                <FormattedMessage
                  id="maintenance.inbox_empty"
                  defaultMessage="Inbox Empty"
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id="maintenance.seems_that_everything_goes_perfect"
                defaultMessage="Seems that everything goes perfect."
              />
            </p>
          </div>
        </div>
        )
      }
    </div>
  );
}

NoCards.propTypes = {
  isInProgress: PropTypes.bool.isRequired,
  isPrioritized: PropTypes.bool.isRequired,
  isInbox: PropTypes.bool.isRequired,
};

export default NoCards;
