import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import CheckboxFiltersFormField from '../../../../../common/checkboxFilters/CheckboxFiltersFormField.container';
import { MACHINES } from '../../../../../../../../common/constants/entities';
import * as form from '../../../../../../redux/constants/report.form.constants';
import ModalLine from '../../../../core/ModalLine';
import DoneModalFooter from '../../../../core/footers/DoneModalFooter';

export const MachinesFiltersFrame = ({ onDoneClick, onCancelClick }) => (
  <div className="modal-move modal-filters">
    <div className="modal-form-in">
      <Field
        component={CheckboxFiltersFormField}
        name={form.MACHINES_FILTERS_FIELD}
        filtersType={MACHINES}
      />
    </div>
    <ModalLine />
    <DoneModalFooter onDoneClick={onDoneClick} onCancelClick={onCancelClick} />
  </div>
);

MachinesFiltersFrame.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
};

export default MachinesFiltersFrame;
