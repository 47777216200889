export const RED = 'D0021B';
export const ORANGE = 'FB9D12';
export const YELLOW = 'FFE216';
export const GREEN = '7ED321';
export const CYAN = '55EFC3';
export const BLUE = '3478C7';
export const PURPLE = '8554DC';
export const PINK = 'F180AF';

export default [
  RED,
  ORANGE,
  YELLOW,
  GREEN,
  CYAN,
  BLUE,
  PURPLE,
  PINK,
];
