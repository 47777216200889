import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import commandModalMessages from '../../../../messages/command.modal.messages';
import {
  BulkOperationSummary,
  BulkOperationSummaryProps,
} from '../../../common/dataPoints/bulkOperationSummary/BulkOperationSummary';

interface InfoContentProps {
  updatedResponse: BulkOperationSummaryProps;
}

export const InfoContent: React.FC<InfoContentProps> = ({ updatedResponse }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormattedMessage
        defaultMessage={formatMessage(commandModalMessages.info)}
        id='datatron.modals.info'
        tagName='h3'
      />
      {updatedResponse.updateBulkResponse && (
        <BulkOperationSummary updateBulkResponse={updatedResponse.updateBulkResponse} />
      )}
    </>
  );
};
