import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReportIcon from '../../common/placeholderIcons/ReportIcon';

export const ErrorPlaceholder = () => (
  <div className='reports-empty'>
    <div className='pb15'>
      <ReportIcon />
      <p>
        <FormattedMessage
          id='inventory.error_placeholder.title'
          defaultMessage='Error: Datatron Config Repository Missing'
          tagName='strong'
        />
      </p>
      <FormattedMessage
        id='inventory.error_placeholder.description'
        defaultMessage={'Please contact your administrator to resolve this issue.'}
        tagName='p'
      />
    </div>
  </div>
);
