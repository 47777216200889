import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import AddToolForm from './AddToolForm';
import { ADD_TOOL_FORM_NAME, MACHINE_FIELD } from '../../../../../redux/constants/tools.add.form.constants';
import { getFormFields } from '../../../../../redux/selectors/tools.add.form.selector';

export const mapStateToProps = (state) => {
  const initialValues = {
    [MACHINE_FIELD]: null,
  };

  const formFields = getFormFields(state);
  const showComment = !!formFields[MACHINE_FIELD];

  return {
    initialValues, showComment,
  };
};

const form = {
  form: ADD_TOOL_FORM_NAME,
  destroyOnUnmount: true,
};

export default connect(mapStateToProps)(reduxForm(form)(AddToolForm));
