import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Element as ScrollElement } from 'react-scroll';
import { Field } from 'redux-form';
import { Todo } from '../../../../../../common/types/common';
import * as dfc from '../../../../redux/constants/datatron.form.constants';
import { FormField } from '../../../_tools';
import fileUploadMessages from '../../../_tools/fileUpload/FileUpload.message';
import PhotoField from '../../../_tools/formFields/photoField/PhotoField';
import OrganizationSelectContainer from '../../../_tools/formFields/select/OrganizationSelect.container';
import datatronFormMessages from './DatatronFormDetails.messages';
import LocationModalContainer from './LocationModal.container';
import PathButtonContainer from './PathButton.container';

interface Props {
  canSetUUID: boolean;
  photoUrl?: string;
  location?: Todo;
}

export const DatatronFormDetails: React.FC<Props> = ({
  canSetUUID,
  photoUrl = '',
  location = null,
}) => (
  <div className='central-form'>
    <FormattedMessage
      id='datatron.datatron_details'
      defaultMessage='DATATRON Details'
      tagName='h1'
    />

    <div className='form--row'>
      <div className='form--left'>
        <ScrollElement name={dfc.PHOTO_FIELD}>
          <Field
            name={dfc.PHOTO_FIELD}
            placeholder={fileUploadMessages.add_photo.id}
            fakePreviewUrl={photoUrl}
            component={PhotoField}
          />
        </ScrollElement>
      </div>
      <div className='form--right'>
        <ScrollElement name={dfc.NAME_FIELD}>
          <FormField
            label={datatronFormMessages.datatronName.id}
            name={dfc.NAME_FIELD}
            autoFocus
            placeholder={datatronFormMessages.datatronNamePlaceholder.id}
          />
        </ScrollElement>

        <ScrollElement name={dfc.VENDOR_FIELD}>
          <FormField
            label={datatronFormMessages.vendor.id}
            name={dfc.VENDOR_FIELD}
            placeholder={datatronFormMessages.vendorPlaceholder.id}
          />
        </ScrollElement>
      </div>
    </div>

    <div className='inputs--row'>
      <div className='inputs--col--md'>
        <ScrollElement name={dfc.MODEL_FIELD}>
          <FormField
            label={datatronFormMessages.model.id}
            name={dfc.MODEL_FIELD}
            placeholder={datatronFormMessages.modelPlaceholder.id}
          />
        </ScrollElement>
      </div>
      <div className='inputs--col--sm'>
        <ScrollElement name={dfc.YEAR_FIELD}>
          <FormField
            label={datatronFormMessages.year.id}
            name={dfc.YEAR_FIELD}
            placeholder={datatronFormMessages.year.id}
          />
        </ScrollElement>
      </div>
    </div>

    <ScrollElement name={dfc.SERIAL_NUMBER_FIELD}>
      <FormField
        label={datatronFormMessages.serialNumber.id}
        name={dfc.SERIAL_NUMBER_FIELD}
        placeholder={datatronFormMessages.serialNumberPlaceholder.id}
      />
    </ScrollElement>

    <ScrollElement name={dfc.DEVICE_UUID_FIELD}>
      <FormField
        label={datatronFormMessages.deviceUUID.id}
        name={dfc.DEVICE_UUID_FIELD}
        canGenerateUUID
        disabled={!canSetUUID}
        placeholder={datatronFormMessages.deviceUUIDPlaceholder.id}
      />
    </ScrollElement>

    <ScrollElement name={dfc.MAC_ADDRESS_FIELD}>
      <FormField
        label={datatronFormMessages.macAddress.id}
        name={dfc.MAC_ADDRESS_FIELD}
        placeholder={datatronFormMessages.macAddressPlaceholder.id}
      />
    </ScrollElement>

    <div className='inputs--row'>
      <div className='inputs--col--md'>
        <div className='like-label datatron-form--location'>
          <FormattedMessage id='newMachine.selectLocation' defaultMessage='Select Location' />
          <ScrollElement name={dfc.LOCATION_FIELD}>
            <Field name={dfc.LOCATION_FIELD} component={PathButtonContainer} location={location} />
          </ScrollElement>
        </div>
      </div>
      <div className='inputs--col--sm2'>
        <ScrollElement name={dfc.NETWORK_SPEED_FIELD}>
          <FormField
            label={datatronFormMessages.networkSpeed.id}
            name={dfc.NETWORK_SPEED_FIELD}
            placeholder={datatronFormMessages.networkSpeedPlaceholder.id}
          />
        </ScrollElement>
      </div>
    </div>

    <label className='newDatatron-organization'>
      <FormattedMessage id='newMachine.selectOrganization' defaultMessage='Select Organization' />
      <ScrollElement name={dfc.ORGANIZATION_FIELD}>
        <Field name={dfc.ORGANIZATION_FIELD} component={OrganizationSelectContainer} />
      </ScrollElement>
    </label>

    <ScrollElement name={dfc.NODE_NAME_FIELD}>
      <FormField
        label={datatronFormMessages.nodeName}
        name={dfc.NODE_NAME_FIELD}
        placeholder={datatronFormMessages.nodeNamePlaceholder}
      />
    </ScrollElement>

    <ScrollElement name={dfc.IP_ADDRESS_FIELD}>
      <FormField
        label={datatronFormMessages.ipAddress}
        name={dfc.IP_ADDRESS_FIELD}
        placeholder={datatronFormMessages.ipAddressPlaceholder}
      />
    </ScrollElement>

    <ScrollElement name={dfc.NETMASK}>
      <FormField
        label={datatronFormMessages.netmask}
        name={dfc.NETMASK}
        placeholder={datatronFormMessages.netmaskPlaceholder}
      />
    </ScrollElement>

    <ScrollElement name={dfc.GATEWAY}>
      <FormField
        label={datatronFormMessages.gateway}
        name={dfc.GATEWAY}
        placeholder={datatronFormMessages.gatewayPlaceholder}
      />
    </ScrollElement>

    <ScrollElement name={dfc.BOOT_ARGUMENTS}>
      <FormField
        label={datatronFormMessages.bootArguments}
        name={dfc.BOOT_ARGUMENTS}
        placeholder={datatronFormMessages.bootArgumentsPlaceholder}
      />
    </ScrollElement>

    <ScrollElement name={dfc.OS_MODULES}>
      <FormField
        label={datatronFormMessages.osModules}
        name={dfc.OS_MODULES}
        placeholder={datatronFormMessages.osModulesPlaceholder}
      />
    </ScrollElement>

    <ScrollElement name={dfc.OS_CONFIG}>
      <FormField
        label={datatronFormMessages.osConfig}
        name={dfc.OS_CONFIG}
        component='textarea'
        cols='30'
        rows='10'
        placeholder={datatronFormMessages.osConfigPlaceholder}
      />
    </ScrollElement>

    <ScrollElement name={dfc.NOTES_FIELD}>
      <FormField
        label={datatronFormMessages.notes.id}
        name={dfc.NOTES_FIELD}
        component='textarea'
        cols='30'
        rows='10'
        placeholder={datatronFormMessages.notesPlaceholder.id}
      />
    </ScrollElement>

    <Field name={dfc.LOCATION_FIELD} component={LocationModalContainer} location={location} />
  </div>
);

export default DatatronFormDetails;
