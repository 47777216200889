import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { ceil } from 'lodash';

import * as form from '../constants/measurement.merge.form.constants';
import { MEASUREMENT_MERGE } from '../constants/modals.constants';
import { getExperiment } from '../selectors/measurement.merge.form.selector';
import {
  getMeasurementsPagination,
  getMeasurements,
} from '../selectors/experimentsPage.selector';
import * as api from '../../core/api';
import { closeModal } from './modals.module';
import { compareById, removeObjectFromList } from '../../../../common/helpers/object';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';
import { fetchMeasurementsPagination } from './measurements.fetch.pagination.module';
import { fetchMeasurements } from './measurements.fetch.module';

export const mergeMeasurement = createAction(
  'merge experiment',
  (measurementId) => measurementId,
);

export const mergeMeasurementSuccess = createAction(
  'merge experiment - success',
  (measurementId) => measurementId,
);

export const mergeMeasurementFail = createAction(
  'merge experiment - fail',
  (error) => error,
);

export const reducer = {
  [mergeMeasurementSuccess]: (state, measurementId) => ({
    ...state,
    experimentsPage: {
      ...state.experimentsPage,
      measurements: {
        ...state.experimentsPage.measurements,
        list: removeObjectFromList(
          state.experimentsPage.measurements.list,
          { id: measurementId },
          compareById,
        ),
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(form.MEASUREMENT_MERGE_FORM_NAME, form.EXPERIMENT_FIELD));
}

export function* mergeMeasurementSaga({ payload: measurementId }) {
  yield call(touchAllFieldsSaga);

  const state = yield select();
  const experiment = yield call(getExperiment, state);
  const experimentId = experiment && experiment.value ? experiment.value : null;
  yield put(startSubmit(form.MEASUREMENT_MERGE_FORM_NAME));

  const { response, error } = yield call(api.experiments.mergeMeasurement, measurementId, experimentId);

  if (response) {
    yield put(mergeMeasurementSuccess(measurementId));
    yield put(stopSubmit(form.MEASUREMENT_MERGE_FORM_NAME));
    yield put(closeModal(MEASUREMENT_MERGE));
    yield put(sendNotification({ ...notificationMessages.measurement_merge }));

    const pagination = yield call(getMeasurementsPagination, state);
    if (pagination && pagination.current_page) {
      yield put(fetchMeasurementsPagination(pagination.current_page));

      if (pagination.current_page === pagination.total_pages
        && ceil((pagination.total - 1) / pagination.per_page) !== pagination.current_page
      ) {
        yield put(fetchMeasurements(pagination.current_page - 1));
      } else {
        const list = yield call(getMeasurements, state);
        if (list && list.length === 0) {
          yield put(fetchMeasurements(pagination.current_page));
        }
      }
    }
  } else {
    yield put(mergeMeasurementFail(error));
    yield put(stopSubmit(form.MEASUREMENT_MERGE_FORM_NAME, error));
  }
}

export function* watchMergeMeasurement() {
  yield takeEvery(mergeMeasurement.getType(), mergeMeasurementSaga);
}
