import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';
import { updateActivity } from './maintenance.columns.activity.update.module';

export const resolveActivity = createAction(
  'resolve activity',
  (activity) => activity,
);

export function* resolveActivitySaga({ payload: activity }) {
  yield put(updateActivity(activity.id, activity));
}

export function* watchResolveActivity() {
  yield takeEvery(resolveActivity.getType(), resolveActivitySaga);
}
