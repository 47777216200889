import { resolvedTitleField, resolvedCommentsField } from '../forms/resolveEvent.form';

export const validateResolveEvent = (form) => {
  const errors = {};

  if (!form[resolvedTitleField]) {
    errors[resolvedTitleField] = { id: 'maintenance.stream.event.resolve.title.required' };
  } if (form[resolvedTitleField] && form[resolvedTitleField].length > 255) {
    errors[resolvedTitleField] = { id: 'maintenance.stream.event.resolve.title.too_long' };
  }

  if (!form[resolvedCommentsField]) {
    errors[resolvedCommentsField] = { id: 'maintenance.stream.event.resolve.comments.required' };
  } else if (form[resolvedCommentsField] && form[resolvedCommentsField].length > 255) {
    errors[resolvedCommentsField] = { id: 'maintenance.stream.event.resolve.comments.too_long' };
  }

  return errors;
};
