import { connect } from 'react-redux';
import moment from 'moment';

import Indicators from './Indicators';
import { getIndicators, getShift } from '../../../../core/common/report';

export function mapStateToProps(state, { report }) {
  const shift = getShift(report);
  const isShiftExist = !!shift;

  return {
    ...getIndicators(report),
    isShiftExist,
    shiftStartMoment: isShiftExist ? moment(shift.start) : null,
  };
}

export default connect(mapStateToProps)(Indicators);
