import React from 'react';
import PropTypes from 'prop-types';

import Assign from './buttons/Assign';
import Cancel from './buttons/Cancel';

export const AssignModalFooter = ({
  onCancel,
  onAssign,
  assignButtonDisabled,
}) => (
  <div className="text-center">
    <Cancel onClick={onCancel} />
    <Assign onClick={onAssign} disabled={assignButtonDisabled} />
  </div>
);

AssignModalFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  assignButtonDisabled: PropTypes.bool.isRequired,
};

export default AssignModalFooter;
