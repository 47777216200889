import { createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { find, isEmpty } from 'lodash';

import * as api from '../../core/api';
import {
  getDataPointFromListById,
  getDataPointsOfDevice,
  getDatatron,
  getDeviceById,
  getDeviceTypeById,
} from '../selectors/datatron.selector';
import { compareById, mergeObjectInList, replaceObjectInList } from '../../../../common/helpers/object';
import {
  DATA_POINT_FORM_NAME,
  ENABLED_FIELD,
  LABEL_FIELD,
  SCALING_FACTOR_FIELD,
  SCALING_OFFSET_FIELD,
} from '../constants/dataPoint.form.constants';
import { highlightDataPoint } from './datatron.devices.dataPoints.highlight.module';
import { initializeDataPoint } from '../../core/common/dataPoint';
import { convertErrors } from '../../core/common/dataPoint.config';
import { closeModal } from './modals.module';
import { DP_EDIT_DATA_POINT } from '../constants/modals.constants';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';
import * as notification from '../../core/notifications';
import { createConfigKeyHashToFieldsMap } from '../../core/common/datatron.deviceType';

export const updateDataPoint = createAction(
  'update data point',
  (deviceId, dataPointId, newConfig) => ({ payload: { deviceId, dataPointId, newConfig } }),
);

export const updateDataPointSuccess = createAction(
  'update data point - success',
  (deviceId, dataPointId, newDataPoint, configKeyHashToFieldsMap) => ({
    payload: {
      deviceId, dataPointId, newDataPoint, configKeyHashToFieldsMap,
    },
  }),
);

export const updateDataPointFail = createAction(
  'update data point - fail',
  (deviceId, dataPointId, error) => ({ payload: { deviceId, dataPointId, error } }),
);

export const reducer = {
  [updateDataPoint.type]: (state, { deviceId, dataPointId }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const dataPoints = getDataPointsOfDevice(device);
    const dataPoint = getDataPointFromListById(dataPoints, dataPointId);
    if (!dataPoint) return state;

    const newDataPoint = {
      ...dataPoint,
      _update: {
        loading: true,
        loaded: false,
        error: null,
      },
    };

    const newDevice = {
      ...device,
      dataPoints: {
        ...device.dataPoints,
        list: replaceObjectInList(
          getDataPointsOfDevice(device),
          newDataPoint,
          compareById,
        ),
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: mergeObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById,
          ),
        },
        newDataPoint: {
          error: null,
        },
      },
    };
  },

  [updateDataPointSuccess.type]: (state, {
    deviceId, dataPointId, newDataPoint, configKeyHashToFieldsMap,
  }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const deviceDataPoints = getDataPointsOfDevice(device);

    const isDataPointReplaced = dataPointId !== newDataPoint.id;
    const prevDataPoint = find(deviceDataPoints, { id: dataPointId });

    const newDevice = {
      ...device,
      configKeyHashToFieldsMap: {
        ...device.configKeyHashToFieldsMap,
        ...configKeyHashToFieldsMap,
      },
      dataPoints: {
        ...device.dataPoints,
        list: replaceObjectInList(
          deviceDataPoints,
          newDataPoint,
          item => item.id === dataPointId,
        ),
      },
    };

    if (isDataPointReplaced) {
      newDevice.archivedDataPoints = {
        ...device.archivedDataPoints,
        list: [
          prevDataPoint,
          ...device.archivedDataPoints.list,
        ],
      };
    }

    return {
      ...state,
      datatron: {
        ...state.datatron,
        newDataPoint: {
          error: null,
        },
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById,
          ),
        },
      },
    };
  },

  [updateDataPointFail.type]: (state, { deviceId, dataPointId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const dataPoints = getDataPointsOfDevice(device);
    const dataPoint = getDataPointFromListById(dataPoints, dataPointId);
    if (!dataPoint) return state;

    const newDataPoint = {
      ...dataPoint,
      _update: {
        loading: false,
        loaded: false,
        error,
      },
    };

    const newDevice = {
      ...device,
      dataPoints: {
        ...device.dataPoints,
        list: replaceObjectInList(
          getDataPointsOfDevice(device),
          newDataPoint,
          compareById,
        ),
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        newDataPoint: {
          error,
        },
        devices: {
          ...state.datatron.devices,
          list: mergeObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById,
          ),
        },
      },
    };
  },
};

export function* touchAllFields() {
  yield put(touch(
    DATA_POINT_FORM_NAME,
    ENABLED_FIELD,
    LABEL_FIELD,
    SCALING_OFFSET_FIELD,
    SCALING_FACTOR_FIELD,
  ));
}

export function* updateDataPointSaga({ payload: { deviceId, dataPointId, newConfig } }) {
  yield put(startSubmit(DATA_POINT_FORM_NAME));

  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const { response, error } = yield call(
    api.datatrons.updateDataPoint,
    {
      datatronId: datatron.id,
      deviceId,
      dataPointId,
      payload: newConfig,
    },
  );

  if (response) {
    const dataPoint = initializeDataPoint(response);

    const device = yield call(getDeviceById, state, deviceId);
    const deviceType = yield call(getDeviceTypeById, state, device.deviceType.id);
    const configKeyHashToFieldsMap = yield call(createConfigKeyHashToFieldsMap, [dataPoint], deviceType.dataPointFields, {});

    yield put(updateDataPointSuccess(deviceId, dataPointId, dataPoint, configKeyHashToFieldsMap));
    yield put(stopSubmit(DATA_POINT_FORM_NAME));
    yield put(closeModal(DP_EDIT_DATA_POINT));
    yield put(highlightDataPoint(response.id));
  } else {
    const errorText = (error.config && error._details) ? error._details : error;
    const errorsObject = convertErrors(error);

    yield put(updateDataPointFail(deviceId, dataPointId, errorText));
    yield put(stopSubmit(DATA_POINT_FORM_NAME, errorsObject));
    if (isEmpty(errorsObject)) {
      yield put(sendNotification({
        ...notificationMessages.something_happened,
        values: { error: errorText },
      }, null, notification.NOTIFICATION_ERROR));
    }
  }
}

export function* watchUpdateDataPoint() {
  yield takeEvery(updateDataPoint, updateDataPointSaga);
}
