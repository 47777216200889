import {
  COLUMN_IN_PROGRESS, COLUMN_PRIORITIZED, COLUMN_INBOX,
} from '../../redux/constants/column.constants';

const availableEventMoves = {
  [COLUMN_INBOX]: {
    [COLUMN_PRIORITIZED]: true,
    [COLUMN_IN_PROGRESS]: true,
  },
  [COLUMN_PRIORITIZED]: {
    [COLUMN_IN_PROGRESS]: true,
    [COLUMN_PRIORITIZED]: true,
  },
  [COLUMN_IN_PROGRESS]: {
    [COLUMN_PRIORITIZED]: true,
    [COLUMN_IN_PROGRESS]: true,
  },
};

export const canMoveEvent = (from, to) => !!availableEventMoves[from][to];

export function getEventIndex(events, eventId) {
  return events.findIndex(({ id }) => id === eventId);
}

export function insertEvent(events, newEvent, aboveEventId, belowEventId) {
  if (aboveEventId) {
    const aboveIndex = getEventIndex(events, aboveEventId);
    events.splice(aboveIndex + 1, 0, newEvent);
  } else if (belowEventId) {
    const belowIndex = getEventIndex(events, belowEventId);
    events.splice(belowIndex, 0, newEvent);
  } else {
    events.push(newEvent);
  }

  return events;
}

export function getActivityIndex(activities, activityId) {
  return activities.findIndex(({ id }) => id === activityId);
}

export function insertActivity(activities, newActivity, aboveActivityId, belowActivityId) {
  if (aboveActivityId) {
    const aboveIndex = getActivityIndex(activities, aboveActivityId);
    activities.splice(aboveIndex + 1, 0, newActivity);
  } else if (belowActivityId) {
    const belowIndex = getActivityIndex(activities, belowActivityId);
    activities.splice(belowIndex, 0, newActivity);
  } else {
    activities.push(newActivity);
  }

  return activities;
}
