import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import BatchesPageHeaderContainer from './BatchesPageHeader.container';
import BatchesHeaderContainer from './header/BatchesHeader.container';
import Batch from './Batch';
import LoaderLocal from '../../_tools/loader/LoaderLocal';
import NoBatchesPlaceholder from './NoBatchesPlaceholder';
import PaginationTool from './PaginationTool';
import { onProductsPageEnter, onProductsPageLeave } from '../../index/routeHandlers';

export const Batches = ({
  loading, batches, hasFilters, canSeePaginationTool, history, match, route,
}) => {
  useEffect(() => {
    onProductsPageEnter();

    return () => {
      onProductsPageLeave();
    };
  }, []);

  if (!match.isExact) {
    return renderRoutes(route.routes);
  }

  const noAnyData = !loading && !batches.length && !hasFilters;

  return (
    <div className="col--1 non-compare pb20">
      {
        !noAnyData ? (
          <>
            <BatchesPageHeaderContainer />
            <div className="col--in">
              <div className="scroll-block without-scroll">
                <table className="table table-link table-scroll">
                  <BatchesHeaderContainer />
                  <tbody>
                    { !loading && batches.map((batch) => (
                      <Batch
                        key={batch.id}
                        batch={batch}
                        pushPath={history.push}
                      />
                    )) }
                  </tbody>
                </table>
                { canSeePaginationTool && <PaginationTool />}
              </div>
              { loading && <LoaderLocal /> }
            </div>
          </>
        ) : (<NoBatchesPlaceholder />)
      }
    </div>
  );
};

Batches.propTypes = {
  batches: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  canSeePaginationTool: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default Batches;
