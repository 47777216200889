import { connect } from 'react-redux';

import AdminPage from './AdminPage';
import PermissionedComponent from '../common/permissions/PermissionedComponent.container';
import {
  USERS_PERMISSIONS,
  ORGANIZATIONS_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
  ROLES_PERMISSIONS,
  ACCOUNTS_PERMISSIONS,
} from '../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => {
  const hasAccountsAccess = permissions[ACCOUNTS_PERMISSIONS].availableToAll();
  const hasOrganizationsAccess = permissions[ORGANIZATIONS_PERMISSIONS].canCreateOrganizations();
  const hasLocationsAccess = permissions[LOCATIONS_PERMISSIONS].canCreateLocations();
  const hasUsersAccess = permissions[USERS_PERMISSIONS].canManageUsers();
  const hasRolesAccess = permissions[ROLES_PERMISSIONS].canManageRoles();

  const hasAccess = hasAccountsAccess
    || hasOrganizationsAccess
    || hasLocationsAccess
    || hasUsersAccess
    || hasRolesAccess;

  return {
    hasAccess,
    hasAccountsAccess,
    hasOrganizationsAccess,
    hasLocationsAccess,
    hasUsersAccess,
    hasRolesAccess,
  };
};

export default PermissionedComponent([
  USERS_PERMISSIONS,
  ORGANIZATIONS_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
  ROLES_PERMISSIONS,
  ACCOUNTS_PERMISSIONS,
])(connect(mapStateToProps)(AdminPage));
