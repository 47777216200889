import { Todo, TodoFunction } from '../../../../../../common/types/common';
import React from 'react';
import { IndexInput } from './IndexInput';

interface Props {
  dimensions: number[][];
  indices: Todo | undefined;
  updateParent: TodoFunction;
}

/**
 * Handles the indices grouped together within a single set of [square brackets].
 */
export const ArrayOuterIndex: React.FC<Props> = ({
  dimensions,
  indices,
  updateParent,
}) => {
  /**
   * Pass updates from children to the parent, so the child doesn't need to be aware of its index within the parent.
   */
  const childChange = (index: number) => (newValue) => {
    updateParent([index], newValue);
  };

  return (
    <div
      className="dimensionsVerticalCenterFlex"
      style={{ paddingLeft: '10px' }}
    >
      <span>[</span>
      {dimensions.map((dimension, index, array) => (
        <React.Fragment key={index}>
          <IndexInput
            dimensions={dimension}
            index={indices[index]}
            updateParent={childChange(index)}
          />
          {index !== array.length - 1 ? <span>,</span> : null}
        </React.Fragment>
      ))}
      <span>]</span>
    </div>
  );
};
