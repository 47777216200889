import { connect } from 'react-redux';

import AssistanceContainer from './Assistance.container';
import * as selector from '../../../redux/selectors/datatron.selector';
import { INVENTORY_DATATRONS } from '../../../../../common/constants/inventory';
import PermissionedComponent from '../permissions/PermissionedComponent.container';
import { DATATRONS_PERMISSIONS } from '../../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => ({
  target: selector.getDatatron(state),
  assistants: selector.getAssistants(state),
  isLoading: selector.selectIsLoading(state),
  isLoaded: selector.selectIsLoaded(state),
  inventoryType: INVENTORY_DATATRONS,
  canAddAssistants: permissions.canAddDatatronAssistants(),
  canDeleteAssistants: permissions.canDeleteDatatronAssistants(),
});

export default PermissionedComponent(DATATRONS_PERMISSIONS)(connect(mapStateToProps)(AssistanceContainer));
