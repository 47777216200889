import { connect } from 'react-redux';

import CustomSelectContainer from './CustomSelect.container';
import locationTypes from '../../../../../../common/enums/locationTypes';
import messages from '../../../../messages/location.types.messages';

export const mapStateToProps = () => ({
  options: locationTypes.map((type) => ({
    label: messages[type],
    value: type,
  })),
  translateOptionsLabels: true,
});

export default connect(mapStateToProps)(CustomSelectContainer);
