import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';

import * as api from '../../core/api';
import * as form from '../constants/reportsTab.add.form.constants';
import { REPORTS_TAB_ADD } from '../constants/modals.constants';
import * as selector from '../selectors/reportsTab.add.form.selector';
import { closeModal } from './modals.module';
import { selectReportsTab } from './reportsTabs.select.module';

export const addReportsTab = createAction(
  'add reports tab',
);

export const addReportsTabSuccess = createAction(
  'add reports tab - success',
  (tab) => tab,
);

export const addReportsTabFail = createAction(
  'add reports tab - fail',
  (error) => error,
);

export const reducer = {
  [addReportsTabSuccess]: (state, tab) => ({
    ...state,
    reports: {
      ...state.reports,
      tabs: {
        ...state.reports.tabs,
        list: [
          ...state.reports.tabs.list,
          tab,
        ],
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.REPORTS_TAB_ADD_FORM_NAME,
    ...form.REPORTS_TAB_PROPS,
  ));
}

export function* addReportsTabSaga() {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const name = yield call(selector.getFormFields, state);

  yield put(startSubmit(form.REPORTS_TAB_ADD_FORM_NAME));
  const { response, error } = yield call(api.reports.createTab, name);

  if (response) {
    yield put(addReportsTabSuccess(response));
    yield put(stopSubmit(form.REPORTS_TAB_ADD_FORM_NAME));
    yield put(closeModal(REPORTS_TAB_ADD));
    yield put(selectReportsTab(response.id));
  } else {
    yield put(addReportsTabFail(error));
    yield put(stopSubmit(form.REPORTS_TAB_ADD_FORM_NAME, error));
  }
}

export function* watchAddReportsTab() {
  yield takeEvery(addReportsTab.getType(), addReportsTabSaga);
}
