import { createAction } from 'redux-act';
import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';

import { getTree } from '../selectors/locationPicker.selector';
import { flattenLocationTreeIntoPaths, findSelectedPath } from '../../core/common/locationPicker';

export const saveLocationPickerValueToPathButton = createAction(
  'save location picker value to path button',
  (pathButtonId) => pathButtonId,
);

export const writeTempLocationPath = createAction(
  'write temp location path of button by ID',
  (pathButtonId, path) => ({ pathButtonId, path }),
);

export const resetTempLocationPath = createAction(
  'reset temp location path of button by ID',
  (pathButtonId) => pathButtonId,
);

export const reducer = {
  [writeTempLocationPath]: (state, { pathButtonId, path }) => ({
    ...state,
    pathButtonWidget: {
      ...state.pathButtonWidget,
      tempLocationPath: {
        ...state.pathButtonWidget.tempLocationPath,
        [pathButtonId]: path,
      },
    },
  }),
  [resetTempLocationPath]: (state, pathButtonId) => ({
    ...state,
    pathButtonWidget: {
      ...state.pathButtonWidget,
      tempLocationPath: {
        ...state.pathButtonWidget.tempLocationPath,
        [pathButtonId]: undefined,
      },
    },
  }),
};

export function* saveLocationPickerValueToPathButtonSaga({ payload: pathButtonId }) {
  const state = yield select();

  const tree = yield call(getTree, state);
  const paths = yield call(flattenLocationTreeIntoPaths, tree);
  const selectedPath = yield call(findSelectedPath, paths);

  yield put(writeTempLocationPath(pathButtonId, selectedPath || null));
}

export function* watchSaveLocationPickerValueToPathButton() {
  yield takeEvery(
    saveLocationPickerValueToPathButton.getType(),
    saveLocationPickerValueToPathButtonSaga,
  );
}
