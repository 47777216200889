import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { ceil } from 'lodash';

import * as api from '../../core/api';
import { closeModal } from './modals.module';
import { AP_ORGANIZATION_REMOVE } from '../constants/modals.constants';
import { removeObjectFromList, compareById } from '../../../../common/helpers/object';
import { getPagination, getOrganizations } from '../selectors/admin.organizations.selector';
import { adminFetchOrganizations } from './admin.organizations.fetch.module';
import { fetchAdminOrganizationsPagination } from './admin.organizations.pagination.fetch.module';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';

export const adminRemoveOrganization = createAction(
  'admin: remove organization',
  (id) => id,
);

export const adminRemoveOrganizationSuccess = createAction(
  'admin: remove organization - success',
  (id) => id,
);

export const adminRemoveOrganizationFail = createAction(
  'admin: remove organization - fail',
  (error) => error,
);

export const reducer = {
  [adminRemoveOrganization]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        selected: {
          ...state.admin.organizations.selected,
          item: {
            ...state.admin.organizations.selected.item,
            _remove: {
              loaded: false,
              loading: true,
              error: null,
            },
          },
        },
      },
    },
  }),

  [adminRemoveOrganizationSuccess]: (state, id) => {
    const newState = {
      ...state,
      admin: {
        ...state.admin,
        organizations: {
          ...state.admin.organizations,
          selected: {
            ...state.admin.organizations.selected,
            item: null,
            loaded: false,
            loading: false,
            error: null,
          },
          all: {
            ...state.admin.organizations.all,
            list: removeObjectFromList(
              state.admin.organizations.all.list,
              { id },
              compareById,
            ),
          },
        },
      },
    };

    if (newState.admin.organizations.pagination) {
      newState.admin.organizations.pagination = {
        ...newState.admin.organizations.pagination,
        total: newState.admin.organizations.pagination.total - 1,
      };
    }

    if (newState.admin.organizations.list) {
      newState.admin.organizations.list = removeObjectFromList(
        newState.admin.organizations.list,
        { id },
        compareById,
      );
    }

    return newState;
  },

  [adminRemoveOrganizationFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      organizations: {
        ...state.admin.organizations,
        selected: {
          ...state.admin.organizations.selected,
          item: {
            ...state.admin.organizations.selected.item,
            _remove: {
              loaded: false,
              loading: false,
              error,
            },
          },
        },
      },
    },
  }),
};

export function* adminRemoveOrganizationSaga({ payload: id }) {
  const { response, error } = yield call(api.admin.removeOrganization, id);

  if (response) {
    yield put(adminRemoveOrganizationSuccess(id));
    yield put(closeModal(AP_ORGANIZATION_REMOVE));
    const state = yield select();
    const pagination = yield call(getPagination, state);

    if (pagination && pagination.current_page) {
      yield put(fetchAdminOrganizationsPagination(pagination.current_page));
      if (pagination.current_page === pagination.total_pages
        && ceil((pagination.total - 1) / pagination.per_page) !== pagination.current_page
      ) {
        yield put(adminFetchOrganizations(pagination.current_page - 1));
      } else {
        const list = yield call(getOrganizations, state);
        if (list && list.length === 0) {
          yield put(adminFetchOrganizations(pagination.current_page));
        }
      }
    }

    yield put(sendNotification({ ...notificationMessages.organization_removed, values: { name: response.name } }));
  } else {
    yield put(adminRemoveOrganizationFail(error));
  }
}

export function* watchAdminRemoveOrganization() {
  yield takeEvery(adminRemoveOrganization.getType(), adminRemoveOrganizationSaga);
}
