import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import commonMessages from '../../../messages/common.core.messages';

export function TopBarSearchInput({
  value, onChange, onClick, onCrossClick, intl,
}) {
  const placeholder = intl.formatMessage(commonMessages.searchPlaceholder);

  return (
    <div className="input-group">
      <input
        type="text"
        className="input-group-field search-input"
        onClick={onClick}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
      <div className="input-group-button">
        <button type="submit" className="button__search">
          <span className="icon icon-search" />
        </button>
      </div>
      {
        (value && value.length > 0) ? (
          <div className="input-group-button input-group-button__cross">
            <button type="button" className="button__cross" onClick={onCrossClick}>
              <span className="fa fa-times" />
            </button>
          </div>
        ) : null
      }
    </div>
  );
}

TopBarSearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCrossClick: PropTypes.func.isRequired,

};

export default injectIntl(TopBarSearchInput);
