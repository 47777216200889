import { connect } from 'react-redux';

import Card from './Card';
import { getShift } from '../../../core/common/report';
import { kpi } from '../../../../../common/enums/reportTypes';
import { selectIsOeeHidden } from '../../../redux/selectors/appConfig.selector';

export const mapStateToProps = (state, { machine }) => {
  const fakeReport = {
    type: kpi,
    data: machine.superOee,
    disabledProps: [],
  };

  const shift = getShift(fakeReport);
  const isShiftExist = !!shift;
  const startDateTime = isShiftExist ? shift.start : null;
  const endDateTime = isShiftExist ? shift.end : null;

  return {
    fakeReport,
    startDateTime,
    endDateTime,
    showOee: !selectIsOeeHidden(state),
  };
};

export default connect(mapStateToProps)(Card);
