import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from '../../../../_tools/dateRangePickerV2/DatePicker';
import { DATE_FROM, DATE_TO } from '../../../../../../../common/constants';
import ButtonWithDropdown from './common/ButtonWithDropdown';
import AutoRefreshControl from './autoRefresh/AutoRefreshControl';

const emptyFunc = () => {};

export const ReportGraphControls = ({ report, setReportControls, isAutoRefreshAvailable }) => {
  const handleDatesChange = (dates) => setReportControls(report.id, { dates });
  const dates = {
    [DATE_FROM]: report.from,
    [DATE_TO]: report.to,
    fromRelative: report.fromRelative,
    toRelative: report.toRelative,
    datesMode: report.datesMode,
  };

  return (
    <div className="report-filters">

      <div className="flex align-center">
        <DatePicker
          {...dates}
          onUpdate={handleDatesChange}
          showCloseButton
          closeAfterDateSelected
          shortPreview
          useConfirmationFooter
        />
        <button className="button light-grey-white button-sm hide">
          <i className="icon-save" />
        </button>
      </div>

      <div className="report-filters-periods hide">
        <label className="radio-button">
          <input type="radio" />
          <span>1D</span>
        </label>
        <label className="radio-button">
          <input type="radio" />
          <span>1W</span>
        </label>
        <label className="radio-button">
          <input type="radio" />
          <span>2W</span>
        </label>
        <label className="radio-button">
          <input type="radio" />
          <span>1M</span>
        </label>
        <label className="radio-button">
          <input type="radio" />
          <span>3M</span>
        </label>
      </div>

      <div className="hide">
        <ButtonWithDropdown
          title="Interval"
          options={[
            { value: 'shift', label: 'Shift' },
            { value: '1min', label: '1 min' },
            { value: '1hour', label: '1 hour' },
            { value: '1day', label: '1 day' },
            { value: '1week', label: '1 week' },
            { value: '1month', label: '1 month' },
          ]}
          onSelect={emptyFunc}
          titleIcons={['icon-interval']}
        />
      </div>

      { isAutoRefreshAvailable && (<AutoRefreshControl report={report} setReportControls={setReportControls} />) }
    </div>
  );
};

ReportGraphControls.propTypes = {
  report: PropTypes.object.isRequired,
  setReportControls: PropTypes.func.isRequired,
  isAutoRefreshAvailable: PropTypes.bool.isRequired,
};

export default ReportGraphControls;
