import React from 'react';
import PropTypes from 'prop-types';

export const Counter = ({ value }) => {
  if (!value) return null;
  return (<span className="label-num">{ value }</span>);
};

Counter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Counter.defaultProps = {
  value: 0,
};

export default Counter;
