import { defineMessages } from 'react-intl';
import {
  PERFORMANCE,
  CYCLE_TIME,
} from '../../../common/enums/machineOeeSource';

export default defineMessages({
  [PERFORMANCE]: {
    id: 'machine.oeeSource.use_performance',
    defaultMessage: 'Use Performance',
  },
  [CYCLE_TIME]: {
    id: 'machine.oeeSource.use_cycle_time',
    defaultMessage: 'Use Cycle Time',
  },
});
