import { connect } from 'react-redux';

import LocationsModule from './LocationsModule';
import * as adminLocationsSelector from '../../../../redux/selectors/admin.locations.selector';
import { fetchAdminLocations } from '../../../../redux/modules/admin.locations.fetch.module';

export const mapStateToProps = (state) => {
  const loaded = adminLocationsSelector.areLocationsLoaded(state);
  const pagination = adminLocationsSelector.getPagination(state);
  const nameQuery = adminLocationsSelector.getNameFilterValue(state);

  return {
    list: adminLocationsSelector.getLocations(state),
    paginationAvailable: loaded && !!pagination && pagination.total_pages > 1,
    pagination,
    nameQuery,
  };
};

export const mapDispatchToProps = {
  choosePage: fetchAdminLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsModule);
