import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../../../_tools';
import Modal from '../../../_tools/modal/Modal';
import { getDeviceById } from '../../../../redux/selectors/datatron.selector';
import { AppState } from '../../../../redux/state/app.state';
import { DefaultContent } from './DefaultContent';
import { InfoContent } from './InfoContent';
import { ErrorContent } from './ErrorContent';
import { ActionFooterButtons } from './ActionFooterButtons';

export type CommandState = 'default' | 'submitting' | 'info' | 'error';

interface ActionsDataPointModalProps {
  isOpen: boolean;
  close: () => void;
  commandLabel: string;
  commandAction: (attr: boolean) => void;
  resetAction: () => void;
  countSelection: number;
  deviceId: string;
}

export const ActionsDataPointModal: React.FC<ActionsDataPointModalProps> = ({
  isOpen,
  close,
  commandLabel,
  commandAction,
  resetAction,
  countSelection,
  deviceId,
}) => {
  const [radioValue, setRadioValue] = useState(true);
  const [commandState, setCommandState] = useState<CommandState>('default');
  const { _update: updateResult } = useSelector((state: AppState) =>
    getDeviceById(state, deviceId),
  );

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(event.target.value === 'yes');
  };

  const onSubmit = () => {
    commandAction(radioValue);
  };

  const onCloseModal = () => {
    setRadioValue(true);
    if (commandState !== 'default') {
      setCommandState('default');
      resetAction();
    }
    close();
  };

  useEffect(() => {
    if (updateResult.loading === true) {
      setCommandState('submitting');
    } else if (updateResult.updateBulkResponse) {
      setCommandState('info');
    } else if (updateResult.error) {
      setCommandState('error');
    } else {
      setCommandState('default');
    }
  }, [updateResult]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} contentLabel=''>
      <div className='modal--header' onClick={onCloseModal}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        {commandState === 'submitting' && (
          <Loader show={commandState === 'submitting'} fullPage={false} />
        )}
        {commandState === 'default' && (
          <DefaultContent
            commandLabel={commandLabel}
            radioValue={radioValue}
            countSelection={countSelection}
            onRadioChange={onRadioChange}
          />
        )}
        {commandState === 'info' && <InfoContent updateResult={updateResult} />}
        {commandState === 'error' && <ErrorContent />}
        <div className='line' />
        <ActionFooterButtons
          onCloseModal={onCloseModal}
          onSubmit={onSubmit}
          commandState={commandState}
        />
      </div>
    </Modal>
  );
};
