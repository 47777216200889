import { connect } from 'react-redux';

import OUMembers from './OUMembers';
import * as selector from '../../redux/selectors/ou.selector';

export function mapStateToProps(state) {
  return {
    members: selector.members(state),
    name: selector.name(state),
  };
}

export default connect(mapStateToProps)(OUMembers);
