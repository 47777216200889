import store from '../../redux/store';
import { LOCATIONS, ORGANIZATIONS } from '../../../../common/constants/entities';
import { NAME_FILTER_INPUT } from '../../redux/constants/components.constants';
import {
  adminFetchAccount,
  adminResetAccount,
} from '../../redux/modules/admin.account.fetch.module';
import { adminFetchAccounts } from '../../redux/modules/admin.accounts.fetch.module';
import {
  adminLocationReset,
  fetchAdminLocation,
} from '../../redux/modules/admin.location.fetch.module';
import { resetAdminLocationForm } from '../../redux/modules/admin.location.form.module';
import { fetchAdminLocations } from '../../redux/modules/admin.locations.fetch.module';
import { adminResetLocationsFilters } from '../../redux/modules/admin.locations.sort.filter.module';
import {
  adminFetchOrganization,
  adminResetOrganization,
} from '../../redux/modules/admin.organization.fetch.module';
import { adminFetchAllOrganizations } from '../../redux/modules/admin.organizations.all.fetch.module';
import { adminFetchOrganizations } from '../../redux/modules/admin.organizations.fetch.module';
import { adminResetOrganizationsFilters } from '../../redux/modules/admin.organizations.sort.filter.module';
import { adminFetchRole, adminResetRole } from '../../redux/modules/admin.role.fetch.module';
import { adminFetchRoles } from '../../redux/modules/admin.roles.fetch.module';
import { adminFetchUser, adminUserReset } from '../../redux/modules/admin.user.fetch.module';
import { adminResetUserForm } from '../../redux/modules/admin.user.form.module';
import { adminSelectDefaultUserFormTab } from '../../redux/modules/admin.user.tabs.module';
import { adminFetchUsers } from '../../redux/modules/admin.users.fetch.module';
import { adminResetUsersFilters } from '../../redux/modules/admin.users.sort.filter.module';
import { fetchAnomaly } from '../../redux/modules/anomaly.fetch.module';
import { fetchBatchDetails } from '../../redux/modules/batch.details.fetch.module';
import { fetchBatches } from '../../redux/modules/batch.fetch.module';
import { filterByName } from '../../redux/modules/batch.sort.filter.module';
import {
  fetchMachineCalendars,
  machineCalendarsData,
} from '../../redux/modules/calendars.fetch.module';
import { closeComponent } from '../../redux/modules/components.visibility.module';
import { resetExpandedEvents } from '../../redux/modules/dashboard.event.expand.module';
import { fetchEvents } from '../../redux/modules/dashboard.fetch.events.module';
import { resetEventsPagination } from '../../redux/modules/dashboard.loadMode.events.module';
import { fetchOee } from '../../redux/modules/dashboard.oee.fetch.module';
import { fetchDeviceClasses } from '../../redux/modules/datatron.deviceClasses.fetch.module';
import { fetchDeviceTypes } from '../../redux/modules/datatron.deviceTypes.fetch.module';
import { fetchDatatron, resetDatatron } from '../../redux/modules/datatron.fetch.module';
import { resetDatatronPageForm } from '../../redux/modules/datatron.page.form.module';
import { checkReFetch as checkDatatronReFetch } from '../../redux/modules/datatron.refetch.check.module';
import { fetchExperiments } from '../../redux/modules/experiments.fetch.module';
import { fetchInventory } from '../../redux/modules/inventory.fetch.module';
import { reset as resetInventoryPreview } from '../../redux/modules/inventory.preview.module.js';
import { fetchLocationById } from '../../redux/modules/location.fetch.module';
import { fetchMachineDataPoints } from '../../redux/modules/machine.dataPoints.fetch.module';
import { fetchMachineData, resetMachine } from '../../redux/modules/machine.fetch.module';
import { resetMachineOee } from '../../redux/modules/machine.oee.fetch.module';
import { reset as resetMachinePageForm } from '../../redux/modules/machine.page.form.module';
import { checkReFetch as checkMachineReFetch } from '../../redux/modules/machine.refetch.check.module';
import { fetchAllMachines } from '../../redux/modules/machines.fetch.module';
import { fetchMaintenanceActivities } from '../../redux/modules/maintenance.fetch.activities.module';
import { fetchMaintenanceEvents } from '../../redux/modules/maintenance.fetch.events.module';
import { fetchMeasurements } from '../../redux/modules/measurements.fetch.module';
import { fetchOU } from '../../redux/modules/ou.fetch.module';
import { fetchReports } from '../../redux/modules/reports.fetch.module';
import { fetchAiApps } from '../../redux/modules/aiApps.fetch.module';
import {
  fetchAiAppDetails,
  resetAiAppDetails,
} from '../../redux/modules/aiApps.details.fetch.module';
import { resetCycleDetectionDetails } from '../../redux/modules/aiApps.cycleDetection.details.fetch.module';
import { reset as resetSlider } from '../../redux/modules/reports.slider.module';
import { fetchReportsTabs } from '../../redux/modules/reportsTabs.fetch.module';
import { search } from '../../redux/modules/search.module';
import { fetchTools } from '../../redux/modules/tools.fetch.module';
import { fetchAllUsers } from '../../redux/modules/users.fetch.module';

export const onNewMachineEnter = () => {
  store.dispatch(search('', true, [LOCATIONS, ORGANIZATIONS]));
  store.dispatch(fetchMachineCalendars(false));
};

export const onNewMachineLeave = () => store.dispatch(resetMachinePageForm());

export const onNewDatatronEnter = () => store.dispatch(search());

export const onNewDatatronLeave = () => store.dispatch(resetDatatronPageForm());

export const onDashboardEnter = () => {
  store.dispatch(fetchEvents());
  store.dispatch(fetchOee());
};

export const onDashboardLeave = () => {
  store.dispatch(resetExpandedEvents());
  store.dispatch(resetEventsPagination());
};

export const onInventoryLeave = () => store.dispatch(resetInventoryPreview());

export const onMachineEnter = ({ params }) => {
  onInventoryLeave();
  store.dispatch(fetchMachineData(params));
};

export const onMachineLeave = () => {
  store.dispatch(resetMachine());
  store.dispatch(resetMachineOee());
};

export const onMachineEditEnter = ({ params: { machine } }) => {
  store.dispatch(checkMachineReFetch(machine));
  store.dispatch(fetchMachineDataPoints(machine));
};

export const onMachineEditLeave = () => {
  store.dispatch(resetMachinePageForm());
};

export const onDatatronEnter = (datatron) => {
  onInventoryLeave();
  store.dispatch(fetchDatatron(datatron));
  store.dispatch(fetchDeviceClasses());
  store.dispatch(fetchDeviceTypes());
};

export const onDatatronLeave = () => {
  store.dispatch(resetDatatron());
};

export const onDatatronEditEnter = (datatron) => {
  store.dispatch(checkDatatronReFetch(datatron));
};

export const onDatatronEditLeave = () => store.dispatch(resetDatatronPageForm());

export const onMaintenanceEnter = () => {
  store.dispatch(fetchMaintenanceEvents());
  store.dispatch(fetchMaintenanceActivities());
};

export const onReportsLeave = () => {
  store.dispatch(resetSlider());
};

export const onInventoryEnter = () => {
  store.dispatch(fetchInventory());
};

export const onReportsEnter = () => {
  store.dispatch(fetchReportsTabs());
  store.dispatch(fetchReports());
};

export const onAIAppsEnter = () => {
  store.dispatch(fetchAiApps());
};

export const onAIAppsDetailsEnter = (aiApp: string) => {
  store.dispatch(fetchAiAppDetails(aiApp));
};

export const onAIAppsDetailsLeave = () => {
  store.dispatch(resetAiAppDetails());
  store.dispatch(resetCycleDetectionDetails());
};

export const onOUEnter = ({ params }) => {
  store.dispatch(fetchOU(params.ouId));
};

export const onLocationEnter = ({ params }) => {
  store.dispatch(fetchLocationById(params.location));
};

export const onProductsPageEnter = () => {
  store.dispatch(fetchBatches());
};

export const onProductsPageLeave = () => {
  store.dispatch(closeComponent(NAME_FILTER_INPUT));
  store.dispatch(filterByName(''));
};

export const onBatchDetailsPageEnter = ({ params }) => {
  store.dispatch(fetchBatchDetails(params.batch));
};

export const onAnomalyPageEnter = ({ params: { eventId } }) => {
  store.dispatch(fetchAnomaly(eventId));
};

export function onMachineCalendarsEnter() {
  store.dispatch(machineCalendarsData());
}

export const onAdminPageEnter = () => {
  store.dispatch(fetchAdminLocations());
  store.dispatch(adminFetchUsers());
  store.dispatch(adminFetchAllOrganizations());
  store.dispatch(adminFetchOrganizations());
  store.dispatch(adminFetchAccounts());
  store.dispatch(adminFetchRoles());
};

export const onAdminLocationsLeave = () => store.dispatch(adminResetLocationsFilters());
export const onAdminLocationEnter = ({ params }) =>
  store.dispatch(fetchAdminLocation(params.locationId));
export const onAdminLocationLeave = () => {
  store.dispatch(adminLocationReset());
  store.dispatch(resetAdminLocationForm());
};
export const onAdminAddLocationLeave = () => {
  store.dispatch(resetAdminLocationForm());
};

export const onAdminUsersLeave = () => store.dispatch(adminResetUsersFilters());
export const onAdminAddUserLeave = () => {
  store.dispatch(adminResetUserForm());
};
export const onAdminUserEnter = ({ params: { userId } }) => store.dispatch(adminFetchUser(userId));
export const onAdminUserLeave = () => {
  store.dispatch(adminUserReset());
  store.dispatch(adminResetUserForm());
  store.dispatch(adminSelectDefaultUserFormTab());
};

export const onAdminOrganizationsLeave = () => store.dispatch(adminResetOrganizationsFilters());
export const onAdminOrganizationEnter = ({ params: { organizationId } }) =>
  store.dispatch(adminFetchOrganization(organizationId));
export const onAdminOrganizationLeave = () => {
  store.dispatch(adminResetOrganization());
};

export const onAdminAccountEnter = ({ params: { accountId } }) =>
  store.dispatch(adminFetchAccount(accountId));
export const onAdminAccountLeave = () => {
  store.dispatch(adminResetAccount());
};

export const onAdminRoleEnter = ({ params: { roleId } }) => store.dispatch(adminFetchRole(roleId));
export const onAdminRoleLeave = () => {
  store.dispatch(adminResetRole());
};

export const onToolPageEnter = () => {
  store.dispatch(fetchTools());
  store.dispatch(fetchAllMachines());
};

export const onExperimentsPageEnter = () => {
  store.dispatch(fetchExperiments());
  store.dispatch(fetchMeasurements());
  store.dispatch(fetchAllMachines());
  store.dispatch(fetchAllUsers());
};
