import React from 'react';

import Button from './Button';
import { TodoFunction } from '../../../../../common/types/common';

const LEFT_SIDE_MAX_ENTRIES_COUNT = 1;
const RIGHT_SIDE_MAX_ENTRIES_COUNT = 1;
const NEARBY_ENTRIES_COUNT = 5;
const SPACE_BUTTON = 'SPACE_BUTTON';
const NUMERIC_BUTTON = 'NUMERIC_BUTTON';
const LEFT_ARROW_BUTTON = 'LEFT_ARROW_BUTTON';
const RIGHT_ARROW_BUTTON = 'RIGHT_ARROW_BUTTON';

const Buttons = [
  SPACE_BUTTON,
  NUMERIC_BUTTON,
  LEFT_ARROW_BUTTON,
  RIGHT_ARROW_BUTTON,
] as const;

type ButtonTypes = typeof Buttons[number];

type ButtonList = {
  type: ButtonTypes
  pageNumber: number
};

type Props = {
  total_pages: number,
  current_page: number,
  choosePage: TodoFunction,
};

export const PaginationBar: React.FC<Props> = ({ total_pages, current_page, choosePage }) => {
  const getButton = ({ type, pageNumber }: ButtonList, key) => {
    let button: React.ReactNode = null;
    switch (type) {
      case NUMERIC_BUTTON: {
        const onClick = () => choosePage(pageNumber);
        button = (
          <Button
            label={String(pageNumber)}
            isCurrent={current_page === pageNumber}
            isDisabled={current_page === pageNumber}
            onClick={onClick}
            key={key}
          />
        );
        break;
      }
      case SPACE_BUTTON: {
        button = (
          <Button key={key}>
            <span className="ellipsis"/>
          </Button>
        );
        break;
      }
      case LEFT_ARROW_BUTTON: {
        const onClick = () => choosePage(pageNumber - 1);
        button = (
          <Button
            isDisabled={pageNumber === 1}
            className="pagination-previous"
            onClick={onClick}
            key={key}
          />
        );
        break;
      }
      case RIGHT_ARROW_BUTTON: {
        const onClick = () => choosePage(pageNumber + 1);
        button = (
          <Button
            isDisabled={pageNumber === total_pages}
            className="pagination-next"
            onClick={onClick}
            key={key}
          />
        );
        break;
      }
      default:
        break;
    }

    return button;
  };

  const getButtonsArray = (): ButtonList[] => {
    const buttons: ButtonList[] = [];

    let leftSideLimit = current_page - NEARBY_ENTRIES_COUNT;
    if (leftSideLimit < 1) leftSideLimit = 1;

    for (let i = current_page - 1; i >= leftSideLimit; i--) {
      buttons.unshift({ type: NUMERIC_BUTTON, pageNumber: i });
    }

    if (LEFT_SIDE_MAX_ENTRIES_COUNT + 1 <= leftSideLimit) {
      if (LEFT_SIDE_MAX_ENTRIES_COUNT + 1 !== leftSideLimit) {
        buttons.unshift({ type: SPACE_BUTTON, pageNumber: 0 });
      }
      for (let i = LEFT_SIDE_MAX_ENTRIES_COUNT; i >= 1; i--) {
        buttons.unshift({ type: NUMERIC_BUTTON, pageNumber: i });
      }
    }

    let rightSideLimit = current_page + NEARBY_ENTRIES_COUNT;
    if (rightSideLimit > total_pages) rightSideLimit = total_pages;

    for (let i = current_page; i <= rightSideLimit; i++) {
      buttons.push({ type: NUMERIC_BUTTON, pageNumber: i });
    }
    if (total_pages - RIGHT_SIDE_MAX_ENTRIES_COUNT >= rightSideLimit) {
      if (rightSideLimit !== total_pages - RIGHT_SIDE_MAX_ENTRIES_COUNT) {
        buttons.push({ type: SPACE_BUTTON, pageNumber: 0 });
      }
      for (let i = total_pages - RIGHT_SIDE_MAX_ENTRIES_COUNT + 1; i <= total_pages; i++) {
        buttons.push({ type: NUMERIC_BUTTON, pageNumber: i });
      }
    }

    return [
      { type: LEFT_ARROW_BUTTON, pageNumber: current_page },
      ...buttons,
      { type: RIGHT_ARROW_BUTTON, pageNumber: current_page },
    ];
  };

  return (
    <ul className="pagination text-center">
      {getButtonsArray().map((button, index) => getButton(button, index + 1))}
    </ul>
  );
};

export default PaginationBar;
