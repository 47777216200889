import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const Footer = ({
  confirmFilters,
  resetPendingFilters,
  close,
  canApplyItemFilters,
}) => {
  const onCancelClick = () => {
    resetPendingFilters();
    close();
  };
  const onApplyClick = () => {
    confirmFilters();
    close();
  };

  return (
    <div className="search--buttons">
      <button
        className="button button__sm grey"
        onClick={onCancelClick}
      >
        <FormattedMessage
          id="common.cancel"
          defaultMessage="Cancel"
        />
      </button>
      <button
        className="button button__sm green"
        onClick={onApplyClick}
        disabled={!canApplyItemFilters}
      >
        <FormattedMessage
          id="common.apply"
          defaultMessage="Apply"
        />
      </button>
    </div>
  );
};

Footer.propTypes = {
  confirmFilters: PropTypes.func.isRequired,
  resetPendingFilters: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  canApplyItemFilters: PropTypes.bool.isRequired,
};

export default Footer;
