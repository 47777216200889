import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import { sendNotification } from './notifications.module';
import * as accountsApi from '../../core/api/accounts.js';
import { NOTIFICATION_EVENT } from '../../core/notifications.js';
import notificationMessage from '../../messages/notification.message';

export const changeLocation = createAction(
  'change location',
  (locationId) => locationId,
);

export const changeLocationSuccess = createAction(
  'change location success',
  (user) => user,
);

export const changeLocationFail = createAction(
  'change location fail',
  (error) => error,
);

export const reducer = {
  [changeLocationSuccess]: (state, user) => ({
    ...state,
    profile: {
      ...state.profile,
      user,
      loading: false,
      loaded: true,
    },
  }),
};

export function* changeLocationSaga({ payload: locationId }) {
  const { response, error } = yield call(accountsApi.changeLocation, locationId);

  if (response) {
    yield put(changeLocationSuccess(response));
    yield put(sendNotification(
      notificationMessage.notifications_title_profile.id,
      notificationMessage.notifications_description_profile_your_location_has_been_changed.id,
      NOTIFICATION_EVENT,
    ));
  } else {
    yield put(changeLocationFail(error));
  }
}

export function* watchChangeLocation() {
  yield takeEvery(changeLocation.getType(), changeLocationSaga);
}
