import React from 'react';

import EventsTableContainer from './EventsTable.container';
import EventsTableHeader from './EventsTableHeader';

const EventsWrapper = (props) => (
  <div className="schedule">
    <div className="schedule--table">
      <table>
        <EventsTableHeader />
        <EventsTableContainer {...props} />
      </table>
    </div>
  </div>
);

export default EventsWrapper;
