import { createAction } from 'redux-act';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as aiAppsApi from '../../core/api/aiApps';
import { replaceObjectInList, compareById } from '../../../../common/helpers/object';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from '../../core/notifications';
import { stringifyObject } from '../../../../common/helpers/string.helper';
import { Todo } from '../../../../common/types/common';

export const triggerCycleDetection = createAction(
  'trigger cycleDetection',
  (cycleDetection: string) => cycleDetection,
);

export const triggerCycleDetectionSuccess = createAction(
  'trigger cycleDetection - success',
  (cycleDetection: CycleDetection) => cycleDetection,
);

export const triggerCycleDetectionFail = createAction(
  'trigger cycleDetection - failed',
  (error: Todo) => error,
);

interface CycleDetection {
  id: string;
  [key: string]: unknown;
}

interface State {
  aiApps: {
    loaded: boolean;
    loading: boolean;
    error: Todo;
    details: {
      cycleDetections: CycleDetection[];
    };
    cycleDetectionDetails: CycleDetection | null;
  };
}

export const reducer = {
  [triggerCycleDetection.getType()]: (state: State) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [triggerCycleDetectionSuccess.getType()]: (state: State, cycleDetection: CycleDetection) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      details: {
        ...state.aiApps.details,
        cycleDetections: replaceObjectInList(
          state.aiApps.details.cycleDetections,
          cycleDetection,
          compareById,
        ),
      },
      cycleDetectionDetails: cycleDetection,

      loaded: true,
      loading: false,
      error: null,
    },
  }),

  [triggerCycleDetectionFail.getType()]: (state: State, error: Todo) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: false,
      error,
    },
  }),
};

/**
 * Saga to handle triggering a cycle detection.
 *
 * This saga triggers a cycle detection based on the provided cycle detection parameter.
 * It dispatches success or failure actions and sends corresponding notifications based on the response.
 * On success, it updates the cycle detections list and cycle detection details in the state.
 *
 * @param {object} action - The action object.
 * @param {string} action.payload - The cycle detection parameter.
 */
export function* triggerCycleDetectionSaga(action: ReturnType<typeof triggerCycleDetection>) {
  const { response, error } = yield call(aiAppsApi.triggerCycleDetection, action.payload);

  if (response) {
    yield put(triggerCycleDetectionSuccess(response));
    yield put(
      sendNotification(
        notificationMessage.successfully_triggered_event,
        notificationMessage.successfully_triggered_event_description,
        NOTIFICATION_EVENT,
      ),
    );
  } else {
    yield put(triggerCycleDetectionFail(error));

    yield put(
      sendNotification(
        notificationMessage.server_error,
        {
          ...notificationMessage.something_happened,
          values: { error: stringifyObject(error) || '' },
        },
        NOTIFICATION_ERROR,
      ),
    );
  }
}

/**
 * Watcher saga to listen for the `triggerCycleDetection` action and trigger the `triggerCycleDetectionSaga`.
 */
export function* watchTriggerCycleDetection() {
  yield takeLatest(triggerCycleDetection, triggerCycleDetectionSaga);
}
