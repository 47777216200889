import { createAction } from 'redux-act';

export const collapseExpandClustersGroup = createAction(
  'expand or collapse clusters group',
  (index) => index,
);

export const reducer = {
  [collapseExpandClustersGroup]: (state, expandedRowIndex) => ({
    ...state,
    machine: {
      ...state.machine,
      clustersSummary: {
        ...state.machine.clustersSummary,
        expandedRowIndex,
      },
    },
  }),
};
