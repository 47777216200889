import React from 'react';
import { FormattedMessage } from 'react-intl';

import commonMessages from '../../../../../messages/common.messages';

export default function DuplicateMenuItem() {
  return (
    <span className="flex">
      <i className="icon-duplicate" />
      {' '}
      <FormattedMessage {...commonMessages.duplicate} />
    </span>
  );
}
