import { connect } from 'react-redux';

import UsersHeader from './UsersHeader';
import { getSort } from '../../../../../redux/selectors/admin.users.selector';
import { adminSortUsers } from '../../../../../redux/modules/admin.users.sort.filter.module';

export const mapStateToProps = (state) => {
  const sort = getSort(state);
  return {
    sortOption: sort.option,
    sortWay: sort.way,
  };
};

export const mapDispatchToProps = {
  onSortChange: adminSortUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersHeader);
