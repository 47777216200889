import { connect } from 'react-redux';

import MachineCalendarsContent from './MachineCalendarsContent';
import * as selector from '../../redux/selectors/calendar.selector';

export const mapStateToProps = (state) => ({
  isCalendarAvailable: selector.isCalendarAvailable(state),
  isEmpty: selector.areCalendarsLoaded(state) && selector.isCalendarsEmpty(state),
  isCalendarAccess: selector.isCalendarAccess(state),
});

export default connect(mapStateToProps)(MachineCalendarsContent);
