import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { renderRoutes } from 'react-router-config';
import { Preview } from './preview/Preview';
import InventoryList from './list/InventoryList';
import InventoryHeader from './InventoryHeader';
import { onInventoryEnter, onInventoryLeave } from '../index/routeHandlers';

const Inventory = ({ route, match }) => {
  useEffect(() => {
    onInventoryEnter();

    return () => {
      onInventoryLeave();
    };
  }, []);

  if (!match.isExact) {
    return renderRoutes(route.routes);
  }

  return (
    <div>
      <div className="col--1">
        <InventoryHeader />
        <InventoryList />
      </div>
      <Preview />
    </div>
  );
};

Inventory.propTypes = {
  children: PropTypes.node,
  route: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Inventory;
