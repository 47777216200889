import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';

import { PROFILE_PAGE } from '../constants/pathButton.constants';
import { resetTempLocationPath } from './pathButton.tempLocationPath.write.module';

export const resetProfilePageForm = createAction(
  'reset profile page form',
);

export function* resetProfilePageFormSaga() {
  yield put(resetTempLocationPath(PROFILE_PAGE));
}

export function* watchResetProfilePageForm() {
  yield takeEvery(resetProfilePageForm.getType(), resetProfilePageFormSaga);
}
