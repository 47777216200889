import { createAction } from 'redux-act';
import { change, formValueSelector } from 'redux-form';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

export const cacheFormField = createAction(
  'form - cache form field',
  (form, field) => ({ form, field }),
);

export const restoreFormField = createAction(
  'form - restore form field',
  (form, field) => ({ form, field }),
);

export function* cacheFormFieldSaga({ payload: { form, field } }) {
  const state = yield select();
  const formSelector = yield call(formValueSelector, form);
  const value = yield call(formSelector, state, field);

  yield put(change(form, `CACHE_${field}`, value));
}

export function* watchCacheFormField() {
  yield takeEvery(cacheFormField.getType(), cacheFormFieldSaga);
}

export function* restoreFormFieldSaga({ payload: { form, field } }) {
  const state = yield select();
  const formSelector = yield call(formValueSelector, form);
  const value = yield call(formSelector, state, `CACHE_${field}`);

  yield put(change(form, field, value));
}

export function* watchRestoreFormField() {
  yield takeEvery(restoreFormField.getType(), restoreFormFieldSaga);
}
