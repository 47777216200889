import { createAction } from 'redux-act';
import {
  put, takeLatest, delay,
} from 'redux-saga/effects';

import { fetchBatches } from './batch.fetch.module';
import { SORT_WAY_ASC, SORT_WAY_DESC } from '../../../../common/constants';

const DEBOUNCE_TIME_IN_MILLISECONDS = 500;

export const sortBatches = createAction(
  'sort batches',
  (option) => option,
);

export const filterByName = createAction(
  'filter batches by name',
  (query = '') => query,
);

export const filterByDate = createAction(
  'filter batches by date',
  (newDates) => newDates,
);

export const reducer = {
  [sortBatches]: (state, option) => {
    const prevOption = state.batches.filters.sort.option;
    const prevWay = state.batches.filters.sort.way;

    let newOption = null;
    let newWay = null;

    if (prevOption === option && prevWay === SORT_WAY_ASC) {
      newOption = prevOption;
      newWay = SORT_WAY_DESC;
    } else if (prevOption !== option) {
      newOption = option;
      newWay = SORT_WAY_ASC;
    }

    return {
      ...state,
      batches: {
        ...state.batches,
        filters: {
          ...state.batches.filters,
          sort: { option: newOption, way: newWay },
        },
      },
    };
  },
  [filterByName]: (state, q) => ({
    ...state,
    batches: {
      ...state.batches,
      filters: {
        ...state.batches.filters,
        q,
      },
    },
  }),
  [filterByDate]: (state, newDates) => ({
    ...state,
    batches: {
      ...state.batches,
      filters: {
        ...state.batches.filters,
        ...newDates,
      },
    },
  }),
};

export function* fetchBatchesSaga() {
  yield delay(DEBOUNCE_TIME_IN_MILLISECONDS);

  yield put(fetchBatches());
}

export function* watchBatchFiltersUpdates() {
  yield takeLatest(
    [
      sortBatches.getType(),
      filterByName.getType(),
      filterByDate.getType(),
    ],
    fetchBatchesSaga,
  );
}
