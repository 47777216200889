import { connect } from 'react-redux';

import InventoryMachine from './InventoryMachine';
import { select } from '../../../../../redux/modules/inventory.preview.module.js';
import { INVENTORY_MACHINES } from '../../../../../../../common/constants/inventory';
import * as selector from '../../../../../redux/selectors/inventory.selector';

export const mapStateToProps = (state, { machine }) => {
  const previewItem = selector.previewItem(state) || null;
  const previewType = selector.previewItemType(state);

  const isSelectedForPreview = previewItem && previewType
    && previewType === INVENTORY_MACHINES
    && previewItem.id === machine.id;

  return {
    machine,
    isSelectedForPreview,
  };
};

export const mapDispatchToProps = {
  select,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryMachine);
