import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'lodash';

import commonCoreMessages from '../../../../messages/common.core.messages';
import SortHeader from '../../../_tools/sortHeader/SortHeader';
import {
  SORT_BY_NAME,
  SORT_BY_CREATED_AT,
  SORT_BY_DESCRIPTION,
} from '../../../../../../common/constants/aiApp';
import { DEBOUNCE_TIMEOUT, SORT_WAY_ASC, SORT_WAY_DESC } from '../../../../../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAiApps,
  updateAiAppsFilters,
  updateAiAppsSort,
} from '../../../../redux/modules/aiApps.fetch.module';
import { getQueryFilterValue, getSort } from '../../../../redux/selectors/aiApps.selector';
import TableQuerySearchState from '../../../common/tableQuerySearch/TableQuerySearchState';
import { AI_APP_QUERY_FILTER_INPUT } from '../../../../redux/constants/components.constants';
import placeholderMessages from '../../../../messages/placeholders.messages';

const TableHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { sortBy, sortOrder } = useSelector(getSort);
  const searchValue = useSelector(getQueryFilterValue);

  const onSortChange = useCallback(
    (newSortBy: string) => {
      const newSortOrder =
        newSortBy === sortBy
          ? sortOrder === SORT_WAY_ASC
            ? SORT_WAY_DESC
            : SORT_WAY_ASC
          : SORT_WAY_DESC;
      dispatch(updateAiAppsSort({ sortBy: newSortBy, sortOrder: newSortOrder }));
      dispatch(fetchAiApps());
    },
    [dispatch, sortBy, sortOrder],
  );

  const debouncedFetchAiApps = useMemo(
    () => debounce(() => dispatch(fetchAiApps()), DEBOUNCE_TIMEOUT),
    [dispatch],
  );

  const onQueryChange = useCallback(
    (queryString: string) => {
      dispatch(updateAiAppsFilters(queryString));
      debouncedFetchAiApps.cancel();
      debouncedFetchAiApps();
    },
    [dispatch, debouncedFetchAiApps],
  );

  return (
    <thead>
      <tr>
        <td className='td-category'>
          <FormattedMessage id='common.status' defaultMessage='Status' />
        </td>
        <td>
          <SortHeader
            option={SORT_BY_NAME}
            way={sortBy == SORT_BY_NAME ? sortOrder : null}
            onClick={onSortChange}
          >
            <FormattedMessage {...commonCoreMessages.name} />
          </SortHeader>
        </td>
        <td>
          <FormattedMessage {...commonCoreMessages.datatron} />
        </td>
        <td>
          <FormattedMessage {...commonCoreMessages.devices} />
        </td>
        <td>
          <SortHeader
            option={SORT_BY_DESCRIPTION}
            way={sortBy == SORT_BY_DESCRIPTION ? sortOrder : null}
            onClick={onSortChange}
          >
            <FormattedMessage {...commonCoreMessages.description} />
          </SortHeader>
        </td>
        <td>
          <SortHeader
            option={SORT_BY_CREATED_AT}
            way={sortBy == SORT_BY_CREATED_AT ? sortOrder : null}
            onClick={onSortChange}
          >
            <FormattedMessage id='common.create' defaultMessage='Create' />
          </SortHeader>
        </td>

        <td className='td-edit td-edit-fixed'>
          <div className='flex-name'>
            <div className='rel-wrap'>
              <div className='flex'>
                <div className='flex-icon'>
                  <TableQuerySearchState
                    value={searchValue}
                    stateComponentId={AI_APP_QUERY_FILTER_INPUT}
                    placeholder={placeholderMessages.searchByAiApp}
                    onQueryChange={onQueryChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </thead>
  );
};

export default TableHeader;
