import * as formConstants from '../../redux/constants/datatron.device.form.constants';

/** This is a workaround and instead of defining the result, the input should be
 * defined instead. So once we have a proper "Device" class we should put that
 * on the input and let the result type be inferred by TypeScript */
interface Result {
  [formConstants.DEVICE_TYPE_FIELD]: string;
  [formConstants.NAME_FIELD]: string;
  [formConstants.DEVICE_UUID_FIELD]: string;
  [formConstants.SERIAL_NUMBER_FIELD]: string;
}

export const getValuesFromDatatronDevice = (device): Result => ({
  [formConstants.DEVICE_TYPE_FIELD]: device.deviceType.id,
  [formConstants.NAME_FIELD]: device.name || '',
  [formConstants.DEVICE_UUID_FIELD]: device.deviceUUID || '',
  [formConstants.SERIAL_NUMBER_FIELD]: device.serialNumber || '',
});
