import { connect } from 'react-redux';

import ItemFiltersContainer from './ItemFilters.container';
import { ORGANIZATIONS } from '../../../../../common/constants/entities';
import messages from './ItemFilters.messages';

export const mapStateToProps = () => ({
  itemType: ORGANIZATIONS,
  label: messages.dashboard_filter_organizations,
});

export default connect(mapStateToProps)(ItemFiltersContainer);
