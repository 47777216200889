import React from 'react';
import cn from 'classnames';

import ButtonLoader from './ButtonLoader';
import { TodoFunction } from '../../../../../common/types/common';

const handleMouseDown = (e) => e.preventDefault();

interface Props {
  onClick: TodoFunction,
  children: React.ReactNode | React.ReactNode[],
  autoUnfocus?: boolean,
  showLoader?: boolean,
  className?: string,
  type?: 'button' | 'submit' | 'reset',
  disabled?: boolean
}

export const Button: React.FC<Props> = ({
  autoUnfocus = true, showLoader = false, className = '', children, ...props
}) => (
  <button
    onMouseDown={autoUnfocus ? handleMouseDown : undefined}
    className={cn(className, { flexed: showLoader })}
    {...props}
  >
    {showLoader && <ButtonLoader/>}
    {children}
  </button>
);

export default Button;
