import React from 'react';
import PropTypes from 'prop-types';

export const Buttons = ({
  // openMergeModal, canMergeMeasurement,
  openArchiveModal,
  canArchiveMeasurement,
}) => (
  <td className="td-edit td-edit-fixed">
    <div className="td-buttons">
      {
        /*
        canMergeMeasurement && (
        <button className="button button__xs light-grey-white" onClick={openMergeModal}>
          <i className="icon-merge" />
        </button>
      )
         */
       }

      { canArchiveMeasurement && (
        <button className="button button__xs light-grey-white" onClick={openArchiveModal}>
          <i className="icon-archive1" />
        </button>
      ) }
    </div>
  </td>
);

Buttons.propTypes = {
  // openMergeModal: PropTypes.func.isRequired,
  // canMergeMeasurement: PropTypes.bool.isRequired,
  openArchiveModal: PropTypes.func.isRequired,
  canArchiveMeasurement: PropTypes.bool.isRequired,
};

export default Buttons;
