import React, { useState } from 'react';
import { AbridgedJournal } from '../../../../../../common/types/api/datatron.devices';
import classnames from 'classnames';

type Props = {
  journal: AbridgedJournal
};

export const JournalDisplay: React.FC<Props> = ({ journal }) => {
  const [isOpen, isOpenSet] = useState<boolean>(false);

  const toggle = () => {
    isOpenSet(!isOpen);
  };

  const toggleButtonClassName = classnames('icon', isOpen ? 'icon-caret-down' : 'icon-caret-left');

  const expandable = journal.payload?.command?.result === 'ERR';

  return (
    <>
      <tr>
        <td/>
        <td style={{ textAlign: 'right' }}>{(new Date(journal.createdAt)).toTimeString()}</td>
        <td/>
        <td>{journal.state}</td>
        <td>{journal.payload?.command?.result}</td>
        <td>
          {expandable && <span className="button__toggle  mr-10" onClick={toggle}>
            <i className={toggleButtonClassName}/>
          </span>}
        </td>
      </tr>
    {isOpen && expandable &&
      <tr>
        <td />
        <td style={{ textAlign: 'right' }}>{'error: ' + journal.payload?.command?.error}</td>
        <td />
        {journal.payload?.command?.version && journal.payload.command.version !== 'unknown' &&
          <td>{'version: ' + journal.payload?.command?.version}</td>}
      </tr>}
  </>
  );
};