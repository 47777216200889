import React from 'react';
import PropTypes from 'prop-types';

import MachineFormFooter from './footer/MachineFormFooter';

export default class MachineFormNew extends React.Component {
  static propTypes = {
    confirm: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    isAddForm: PropTypes.bool.isRequired,
    isUpdateForm: PropTypes.bool.isRequired,
    backLink: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.confirm();
  };

  render() {
    const {
      reset, backLink, submitting, isAddForm, isUpdateForm, pristine, children,
    } = this.props;

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className="form">
          {children}

          <div className="line" />

          <MachineFormFooter
            cancelLink={backLink}
            onCancelClick={reset}
            submitting={submitting}
            isAddForm={isAddForm}
            isUpdateForm={isUpdateForm}
            pristine={pristine}
          />
        </div>
      </form>
    );
  }
}
