import { forEach } from 'lodash';
import * as periodTypes from '../../../../common/enums/periodTypes';
import { KpiChild, KpiMain } from '../../../types/report';
import { Todo } from '../../../../common/types/common';

export const convertKPIs = (total): KpiChild[] => ([
  {
    type: periodTypes.availability,
    label: 'indicators.availability',
    value: total[periodTypes.availability] || 0,
  },
  {
    type: periodTypes.performance,
    label: 'indicators.performance',
    value: total[periodTypes.performance] || 0,
  },
  {
    type: periodTypes.quality,
    label: 'indicators.quality',
    value: total[periodTypes.quality] || 0,
  },
]);

export const calculateOee = (
  availability: number,
  performance: number,
  quality: number,
): KpiMain => {
  const oee = availability * performance * quality;
  return {
    label: 'indicators.oee',
    value: oee || 0,
  };
};

export const getDatatronStatsIndicators = (datatronStats) => {
  const indicators: Todo = [];

  forEach(
    datatronStats.total,
    (value, key) => {
      indicators.push({
        type: key,
        value,
        label: `datatron.${key}`,
      });
    },
  );

  return indicators;
};
