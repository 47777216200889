import { connect } from 'react-redux';

import Calendars from './Calendars';
import messages from './Search.messages';
import * as selector from '../../../redux/selectors/calendar.selector';
import { SCHEDULE_CALENDAR } from '../../../../../common/enums/calendarTypes';
import { updateFilterQuery } from '../../../redux/modules/calendars.query.module';
import calendarsMessages from '../../../messages/calendars.messages';

const calendarType = SCHEDULE_CALENDAR;

export function mapStateToProps(state) {
  const title = calendarsMessages.scheduleCalendars;
  const titleIcon = 'schedule--icon';
  const query = selector.getCalendarsQueryByType(state, calendarType);
  const searchPlaceholder = messages[calendarType];
  const typeCalendars = selector.getFilteredCalendarsList(state, calendarType);

  return {
    title,
    titleIcon,
    query,
    searchPlaceholder,
    typeCalendars,
    calendarType,
    count: typeCalendars.length || 0,
  };
}

export const mapDispatchToProps = (dispatch) => ({
  updateQuery: (type, query) => dispatch(updateFilterQuery(type, query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendars);
