export const EDIT_TOOL_FORM_NAME = 'EDIT_TOOL_FORM_NAME';

export const LABEL_FIELD = 'label';
export const TYPE_FIELD = 'type';
export const MODEL_FIELD = 'model';
export const SERIAL_FIELD = 'serial';

export const NA_TYPE = 'activityType';
export const NA_COMMENT = 'comment';
export const NA_MACHINE = 'machineId';

export const TOOL_PROPS = [
  LABEL_FIELD,
  TYPE_FIELD,
  MODEL_FIELD,
  SERIAL_FIELD,
];

export const NEW_ACTIVITY_PROPS = [
  NA_TYPE,
  NA_COMMENT,
  NA_MACHINE,
];
