import React from 'react';
import { round } from 'lodash';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { Todo, TodoFunction } from '../../../../../../../common/types/common';

interface Props {
  data: Todo[];
  className: string;
  getX: TodoFunction;
  getY: TodoFunction;
  getYMax: TodoFunction;
  width: number;
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number>;
}

export const StackedBar: React.FC<Props> = ({
  data, xScale, yScale, className, width, getX, getY, getYMax,
}) => (
  <g>
    { data.map((d) => (
      <rect
        key={getX(d)}
        x={xScale(getX(d))}
        y={round(yScale(getYMax(d)), 5)}
        width={width}
        height={round(yScale(getY(d)) - yScale(getYMax(d)), 5)}
        className={className}
      />
    )) }
  </g>
);
