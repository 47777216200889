import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../../core/api';

export const adminFetchRoles = createAction(
  'admin: fetch roles',
);

export const adminFetchRolesSuccess = createAction(
  'admin: fetch roles - success',
  (list) => list,
);

export const adminFetchRolesFail = createAction(
  'admin: fetch roles - fail',
  (error) => error,
);

export const reducer = {
  [adminFetchRoles]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      roles: {
        ...state.admin.roles,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [adminFetchRolesSuccess]: (state, list) => ({
    ...state,
    admin: {
      ...state.admin,
      roles: {
        ...state.admin.roles,
        list,
        loaded: true,
        loading: false,
        error: null,
      },
    },
  }),

  [adminFetchRolesFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      roles: {
        ...state.admin.roles,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* adminFetchRolesSaga() {
  const { response, error } = yield call(api.admin.getRoles);

  if (response) {
    yield put(adminFetchRolesSuccess(response));
  } else {
    yield put(adminFetchRolesFail(error));
  }
}

export function* watchAdminFetchRoles() {
  yield takeEvery(adminFetchRoles.getType(), adminFetchRolesSaga);
}
