import { createAction } from '@reduxjs/toolkit';
import { CommandJournalMessage } from '../../../../common/types/api/callback';
import { AppReducerMapBuilder } from './util';

export const receivedCommandJournalMessage = createAction(
  'received command journal message',
  (message: CommandJournalMessage) => ({ payload: { message } }),
);

export const setCallbackCommandId = createAction(
  'config repository callback command id set',
  (commandId) => ({ payload: { commandId } }),
);

export const startedListening = createAction(
  'started listening for command journal messages',
);

export const stoppedListening = createAction(
  'stopped listening for command journal messages',
);

export const reducer = AppReducerMapBuilder.new()
  .add(receivedCommandJournalMessage, (state, { message }) => {
    const { commandId, listening, messages } = state.callback;
    // if we're not listening, don't do anything
    if (!listening) return state;
    // if we have a commandId and it doesn't match, don't do anything
    if (commandId !== null && message.commandId !== commandId) return state;
    // if we already have this message, don't do anything
    if (messages.some((m) => m.commandJournalId === message.commandJournalId)) {
      return state;
    }
    return {
      ...state,
      callback: {
        ...state.callback,
        messages: [...messages, message],
      },
    };
  })
  .add(setCallbackCommandId, (state, { commandId }) => {
    const { listening, commandId: currentCommandId, messages } = state.callback;
    // if we're not listening, don't do anything
    if (!listening) return state;
    // if we already have this commandId, don't do anything
    if (currentCommandId === commandId) return state;
    return {
      ...state,
      callback: {
        listening,
        commandId,
        // filter out messages that don't match the new commandId
        messages: messages.filter((m) => m.commandId === commandId),
      },
    };
  })
  .add(startedListening, (state) => ({
    ...state,
    callback: {
      listening: true,
      commandId: null,
      messages: [],
    },
  }))
  .add(stoppedListening, (state) => ({
    ...state,
    callback: {
      listening: false,
      commandId: null,
      messages: [],
    },
  }))
  .build();
