import { connect } from 'react-redux';

import { beginDrag, endDrag } from '../../../../redux/modules/maintenance.columns.dnd.module.js';
import { moveActivity } from '../../../../redux/modules/maintenance.columns.activity.move.module';
import ActivitiesCardDnd from './ActivitiesCard.dnd';
import PermissionedComponent from '../../../common/permissions/PermissionedComponent.container';
import { EVENTS_PERMISSIONS } from '../../../../../../common/constants/permissions';

export function mapStateToProps(state, { activity, permissions }) {
  return {
    cardId: activity.id,
    canUpdateActivities: permissions.canUpdateActivities(),
  };
}

export const mapDispatchToProps = {
  beginDrag,
  endDrag,
  moveActivity,
};

export default PermissionedComponent(EVENTS_PERMISSIONS)(
  connect(mapStateToProps, mapDispatchToProps)(ActivitiesCardDnd),
);
