import { connect } from 'react-redux';

import Reports from './Reports';
import * as selector from '../../redux/selectors/reports.selector';
import PermissionedComponentContainer from '../common/permissions/PermissionedComponent.container';
import { REPORTS_PERMISSIONS } from '../../../../common/constants/permissions';

export const mapStateToProps = (state, { permissions }) => ({
  reports: selector.selectReportsOfSelectedTab(state),
  hasNoReports: selector.selectHasNoReportsInSelectedTab(state),
  isLoading: selector.selectLoading(state),
  authorized: permissions.availableToAll(),
});

export default PermissionedComponentContainer(REPORTS_PERMISSIONS)(connect(mapStateToProps)(Reports));
