import { defineMessages } from 'react-intl';

export default defineMessages({
  source: {
    id: 'dataPoint.config.source',
    defaultMessage: 'Source',
  },
  unit: {
    id: 'dataPoint.config.unit',
    defaultMessage: 'Unit',
  },
  label: {
    id: 'dataPoint.config.label',
    defaultMessage: 'Label',
  },
  enabled: {
    id: 'dataPoint.config.enabled',
    defaultMessage: 'Enabled',
  },
  dataClass: {
    id: 'dataPoint.config.dataClass',
    defaultMessage: 'Data Class',
  },
  identifier: {
    id: 'dataPoint.config.identifier',
    defaultMessage: 'Identifier',
  },
  address: {
    id: 'dataPoint.config.address',
    defaultMessage: 'Address',
  },
  dataType: {
    id: 'dataPoint.config.dataType',
    defaultMessage: 'Data Type',
  },
  dataBlockNumber: {
    id: 'dataPoint.config.dataBlockNumber',
    defaultMessage: 'Data Block Number',
  },
  rate: {
    id: 'dataPoint.config.rate',
    defaultMessage: 'Rate',
  },
  name: {
    id: 'dataPoint.config.name',
    defaultMessage: 'Name',
  },
  bitOffset: {
    id: 'dataPoint.config.bitOffset',
    defaultMessage: 'Bit Offset',
  },
  offset: {
    id: 'dataPoint.config.offset',
    defaultMessage: 'Offset',
  },
  scalingOffset: {
    id: 'dataPoint.config.scalingOffset',
    defaultMessage: 'Scaling Offset',
  },
  scalingFactor: {
    id: 'dataPoint.config.scalingFactor',
    defaultMessage: 'Scaling Factor',
  },
  byteCount: {
    id: 'dataPoint.config.byteCount',
    defaultMessage: 'Byte Count',
  },
  fps: {
    id: 'dataPoint.config.fps',
    defaultMessage: 'FPS',
  },
  colorMode: {
    id: 'dataPoint.config.colorMode',
    defaultMessage: 'Color Mode',
  },
  dimensionX: {
    id: 'dataPoint.config.dimensionX',
    defaultMessage: 'Dimension X',
  },
  dimensionY: {
    id: 'dataPoint.config.dimensionY',
    defaultMessage: 'Dimension Y',
  },
  symbolName: {
    id: 'dataPoint.config.symbolName',
    defaultMessage: 'Symbol Name',
  },
  indexGroupOffset: {
    id: 'dataPoint.config.indexGroupOffset',
    defaultMessage: 'Index Group Offset',
  },
  indexGroup: {
    id: 'dataPoint.config.indexGroup',
    defaultMessage: 'Index Group',
  },
  indexOffset: {
    id: 'dataPoint.config.indexOffset',
    defaultMessage: 'Index Offset',
  },
  range: {
    id: 'dataPoint.config.range',
    defaultMessage: 'Range',
  },
  coupling: {
    id: 'dataPoint.config.coupling',
    defaultMessage: 'Coupling',
  },
  samplingRate: {
    id: 'dataPoint.config.samplingRate',
    defaultMessage: 'Sampling Rate',
  },
  numberOfChannels: {
    id: 'dataPoint.config.numberOfChannels',
    defaultMessage: 'Number of Channels',
  },
  resolutionPerChannel: {
    id: 'dataPoint.config.resolutionPerChannel',
    defaultMessage: 'Resolution per Channel',
  },
  type: {
    id: 'dataPoint.config.type',
    defaultMessage: 'Type',
  },
  value: {
    id: 'dataPoint.config.value',
    defaultMessage: 'Value',
  },
});
