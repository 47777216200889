import React from 'react';
import Loader from './Loader';

export default function LoaderLocal() {
  return (
    <div className="loader-local">
      <Loader show />
    </div>
  );
}
