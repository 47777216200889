import { createAction } from 'redux-act';

export const addStateFilter = createAction(
  'add state filter for machine tools',
  (filter) => filter,
);

export const removeStateFilter = createAction(
  'remove state filter for machine tools',
  (filter) => filter,
);

export const reducer = {
  [addStateFilter]: (state, filter) => ({
    ...state,
    machine: {
      ...state.machine,
      tools: {
        ...state.machine.tools,
        filters: {
          ...state.machine.tools.filters,
          state: [
            ...state.machine.tools.filters.state,
            filter,
          ],
        },
      },
    },
  }),
  [removeStateFilter]: (state, filter) => {
    const selectedStates = state.machine.tools.filters.state;
    const index = selectedStates.indexOf(filter);

    if (index === -1) return state;

    return {
      ...state,
      machine: {
        ...state.machine,
        tools: {
          ...state.machine.tools,
          filters: {
            ...state.machine.tools.filters,
            state: [
              ...selectedStates.slice(0, index),
              ...selectedStates.slice(index + 1),
            ],
          },
        },
      },
    };
  },
};
