import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as selector from '../../../redux/selectors/aiApps.selector';

import Table from '../../common/table/table';
import { FormattedMessage, useIntl } from 'react-intl';
import commonMessages from '../../../messages/common.messages';
import aiAppConfigMessages from '../../../messages/aiApp.config.messages';
import { syncAnomalyModel } from '../../../redux/modules/aiApps.anomalyModel.sync.module';
import commonCoreMessages from '../../../messages/common.core.messages';
import aiAppMessages from '../../../messages/aiApp.config.messages';
import dynamicFieldMessages from '../../../messages/dynamicField.messages';
import { Todo } from '../../../../../common/types/common';

// TODO: this types should be moved to a common place and import from there
interface Influencer {
  uuid: string;
  label: string;
  rank: number;
}

interface Graph {
  data: string;
  content_type: string;
  title: string;
  id: number;
  anomaly_model_id: number;
  created_at: string;
  updated_at: string;
}

interface Anomaly {
  candidate_uuid: string;
  num_epochs: number;
  batch_size: number;
  status: string;
  influencers: Influencer[];
  model: null;
  connector_image_registry: string;
  connector_image_repository: string;
  connector_image_tag: string;
  env_str: string;
  custom: {
    key: string;
  };
  id: string;
  cycle_detection_id: number;
  created_at: string;
  updated_at: string;
  graph: Graph;
}

interface AnomalyDisplayProps {
  anomaly: Anomaly;
}

const AnomalyModelDisplay: React.FC<AnomalyDisplayProps> = ({ anomaly }) => {
  const dispatch = useDispatch();
  const { formatDate, formatMessage } = useIntl();

  const aiAppsDetails = useSelector(selector.getAiAppsDetails);
  const { appName, id, datatron } = aiAppsDetails as Todo;
  const {
    graph,
    created_at: createdAt,
    num_epochs: numEpochs,
    batch_size: batchSize,
    status,
    connector_image_registry: connectorImageRegistry,
    connector_image_repository: connectorImageRepository,
    connector_image_tag: connectorImageTag,
    env_str: envStr,
    custom,
  } = anomaly;

  const handleSyncAnomaly = () => {
    dispatch(
      syncAnomalyModel(id, {
        name: appName,
        datatronId: datatron.id,
        connectorImageRegistry,
        connectorImageRepository,
        connectorImageTag,
        envStr,
        custom,
      }),
    );
  };

  const renderDetailRow = (label: string, value: string | number | Date | undefined) => (
    <div className='detail-row'>
      <div className='detail-label'>{label}</div>
      <div className='detail-value'>{value}</div>
    </div>
  );

  const renderInfluencersTable = () => {
    const influencers = anomaly.influencers.map((influencer) => ({
      id: influencer.uuid,
      ...influencer,
    }));

    return (
      <div className='table-responsive container mt-5'>
        <Table headers={['uuid', 'label', 'rank']} rows={influencers} />
      </div>
    );
  };

  const renderAnomalyDetails = () => (
    <div className='ai-app-details--text'>
      <div className='ai-app-details--position w50'>
        {renderDetailRow(formatMessage(commonCoreMessages.datatron), datatron.name)}
        {renderDetailRow(formatMessage(commonCoreMessages.name), appName)}
        {renderDetailRow(formatMessage(aiAppMessages.numEpochs), numEpochs)}
        {renderDetailRow(formatMessage(aiAppMessages.batchSize), batchSize)}
        {renderDetailRow(formatMessage(commonMessages.status), status)}
        {renderDetailRow(
          formatMessage(dynamicFieldMessages.connectorImageRegistry),
          connectorImageRegistry,
        )}
        {renderDetailRow(
          formatMessage(dynamicFieldMessages.connectorImageRepository),
          connectorImageRepository,
        )}
        {renderDetailRow(formatMessage(dynamicFieldMessages.connectorImageTag), connectorImageTag)}
        {renderDetailRow(formatMessage(dynamicFieldMessages.envStr), envStr)}
        {renderDetailRow(formatMessage(dynamicFieldMessages.custom), JSON.stringify(custom))}
        {renderDetailRow(formatMessage(commonMessages.date), formatDate(createdAt))}
      </div>

      <div className='ai-app-details--position w50'>
        <div className='center-svg' dangerouslySetInnerHTML={{ __html: graph.data }} />
        {renderInfluencersTable()}
      </div>
    </div>
  );

  return (
    <div className='anomaly--details'>
      <div className='table--title'>
        <FormattedMessage tagName='b' {...aiAppConfigMessages.anomalyModel} />{' '}
        <FormattedMessage tagName='b' {...commonMessages.details} />
        <button
          className='add-anomaly-btn btn btn-success dropdown-toggle button light-grey-white'
          onClick={handleSyncAnomaly}
        >
          <span className='fa fa-refresh me-2' />
          <FormattedMessage tagName='b' {...aiAppConfigMessages.syncAnomaly} />
        </button>
      </div>
      {renderAnomalyDetails()}
    </div>
  );
};

export default AnomalyModelDisplay;
