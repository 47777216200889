import React from 'react';
import PropTypes from 'prop-types';

import { COLUMN_INBOX } from '../../../../redux/constants/column.constants.js';
import InboxCardContainer from './InboxCard.container';
import ActivitiesCardContainer from './ActivitiesCard.container';

function CardTemplate({ type, card }) {
  if (type === COLUMN_INBOX) {
    return (
      <InboxCardContainer
        event={card}
        type={type}
      />
    );
  }

  return (
    <ActivitiesCardContainer
      activity={card}
      type={type}
    />
  );
}

CardTemplate.propTypes = {
  type: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
};

export default CardTemplate;
