import React from 'react';
import PropTypes from 'prop-types';

import Kpi from '../../../_tools/kpi/Kpi';
import IndicatorsTitle from './IndicatorsTitle';

export const Indicators = ({
  child, main, shiftStartMoment, isShiftExist,
}) => (
  <div className="indicators-info">
    <IndicatorsTitle
      shiftStartMoment={shiftStartMoment}
      isShiftExist={isShiftExist}
    />
    <div className="round-charts round-charts-inline round-charts-center">
      <Kpi
        data={main}
        isActive
        background="kpi-background--light"
        percentageClass="kpi__percentage"
      />
      { child.map((indicator, index) => (
        <Kpi
          data={indicator}
          key={index}
          background="kpi-background--light"
          percentageClass="kpi__percentage"
        />
      ))}
    </div>
  </div>
);

Indicators.propTypes = {
  child: PropTypes.array.isRequired,
  main: PropTypes.object.isRequired,
  isShiftExist: PropTypes.bool.isRequired,
  shiftStartMoment: PropTypes.object,
};

export default Indicators;
