import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import AddModalFooter from '../../../../../modals/core/footers/AddModalFooter';
import commonCoreMessages from '../../../../../../messages/common.core.messages';
import * as form from '../../../../../../redux/constants/admin.user.add.form.constants';
import { FormField } from '../../../../../_tools';
import LocaleSelectContainer from '../../../../../_tools/formFields/select/LocaleSelect.container';
import PathButtonContainer from './PathButton.container';
import LocationModalContainer from './LocationModal.container';

export const AddUserForm = ({
  submitting,
  pristine,
  handleCancel,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit} className="form form-md">
    <div className="modal-form-in">
      <div className="tab-row">
        <div className="tab-col">
          <FormField
            label={commonCoreMessages.firstName.id}
            name={form.FIRST_NAME_FIELD}
            tabIndex={1}
            autoFocus
          />
          <FormField
            label={commonCoreMessages.lastName.id}
            name={form.LAST_NAME_FIELD}
            tabIndex={3}
          />
          <FormField
            label={commonCoreMessages.middleName.id}
            name={form.MIDDLE_NAME_FIELD}
            tabIndex={5}
          />
          <FormField
            label={commonCoreMessages.password.id}
            name={form.PASSWORD_FIELD}
            tabIndex={7}
          />
          <label>
            <FormattedMessage {...commonCoreMessages.location} />
            <Field
              name={form.LOCATION_ID_FIELD}
              component={PathButtonContainer}
              tabIndex={9}
            />
          </label>
        </div>
        <div className="tab-col">
          <FormField
            label={commonCoreMessages.position.id}
            name={form.POSITION_FIELD}
            tabIndex={2}
          />
          <FormField
            label={commonCoreMessages.email.id}
            name={form.EMAIL_FIELD}
            tabIndex={4}
          />
          <FormField
            label={commonCoreMessages.phoneNumber.id}
            name={form.PHONE_NUMBER_FIELD}
            tabIndex={6}
          />
          <FormField
            label={commonCoreMessages.passwordConfirmation.id}
            name={form.PASSWORD_CONFIRMATION_FIELD}
            tabIndex={8}
          />
          <label>
            <FormattedMessage {...commonCoreMessages.locale} />
            <Field
              name={form.LOCALE_FIELD}
              component={LocaleSelectContainer}
              tabIndex={10}
            />
          </label>
        </div>
      </div>
    </div>

    <div className="line" />

    <AddModalFooter
      onCancelClick={handleCancel}
      onAddClick={handleSubmit}
      isAddButtonDisabled={pristine || submitting}
    />

    <Field
      name={form.LOCATION_ID_FIELD}
      component={LocationModalContainer}
    />
  </form>
);

AddUserForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default AddUserForm;
