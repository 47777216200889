import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { isEmpty } from 'lodash';

import PendingEventInfo from './PendingEventInfo';
import calendarMessages, { getRepeatTypeMessage } from '../../../../../../messages/calendars.messages';
import commonMessages from '../../../../../../messages/common.messages';
import { capitalizeFirstChar } from '../../../../../../../../common/helpers/string.helper';
import { DATE_TIME_RFC2445_FORMAT } from '../../../../../../../../common/constants/index';

export const EventInfo = ({
  event,
  intl,
  pendingChanges,
}) => {
  const eventCurrentDate = intl.formatDate(event.startDateTime);
  const eventTimeStart = intl.formatTime(event._originalEvent.startDateTime);
  const eventTimeEnd = intl.formatTime(event._originalEvent.endDateTime);

  const recurrenceTypeLabel = [
    capitalizeFirstChar(intl.formatMessage(calendarMessages.repeatTypeTitle)),
    intl.formatMessage(getRepeatTypeMessage(event.repeatType)),
  ].join(' ');
  const recurrenceEndLabel = event.repeatEnd ? [
    capitalizeFirstChar(intl.formatMessage(calendarMessages.repeatEndTitle)),
    intl.formatDate(moment(event.repeatEnd, DATE_TIME_RFC2445_FORMAT).toISOString()),
  ].join(' ') : '';

  return (
    <div className="modal--single--text just--text">
      <p>
        <FormattedMessage {...commonMessages.time} />
        {': '}
        { eventTimeStart }
        {' - '}
        { eventTimeEnd }
        <br />
        <FormattedMessage {...commonMessages.date} />
        {': '}
        { eventCurrentDate }
        <br />
        { recurrenceTypeLabel }
        <br />
        { recurrenceEndLabel }
      </p>
      { !isEmpty(pendingChanges) && (<PendingEventInfo pendingChanges={pendingChanges} />)}
    </div>
  );
};

EventInfo.propTypes = {
  event: PropTypes.object.isRequired,

  pendingChanges: PropTypes.object,
};

export default injectIntl(EventInfo);
