import { createAction } from 'redux-act';

export const resetDataPointMetric = createAction(
  'reset data point metric',
);

export const reducer = {
  [resetDataPointMetric]: (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      dataPointMetric: {
        ...state.datatron.dataPointMetric,
        list: [],
        loading: false,
        loaded: false,
        error: null,
        moreAvailable: false,
        dataPointId: null,
        utcMode: true,
      },
    },
  }),
};
