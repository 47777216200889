import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RemoveAssistantModal from './RemoveAssistantModal';
import { getModalPayload } from '../../../../../redux/selectors/modals.selector';
import { closeModal } from '../../../../../redux/modules/modals.module';
import { getRemoveAssistantModalId } from '../../../../../core/modals/assistance.modals';
import * as inventoryTypes from '../../../../../../../common/constants/inventory';
import * as machineAssistantRemoveModule from '../../../../../redux/modules/machine.assistant.remove.module';
import * as datatronAssistantRemoveModule from '../../../../../redux/modules/datatron.assistant.remove.module';

export const mapStateToProps = (state, { inventoryType }) => {
  const modalId = getRemoveAssistantModalId(inventoryType);
  return {
    ...getModalPayload(state, modalId),
  };
};

export const mapDispatchToProps = (dispatch, { inventoryType }) => {
  const modalId = getRemoveAssistantModalId(inventoryType);
  let removeAssistant = null;

  switch (inventoryType) {
    case inventoryTypes.INVENTORY_MACHINES: {
      removeAssistant = machineAssistantRemoveModule.removeAssistant;
      break;
    }
    case inventoryTypes.INVENTORY_DATATRONS: {
      removeAssistant = datatronAssistantRemoveModule.removeAssistant;
      break;
    }
    default: break;
  }

  return {
    close: () => dispatch(closeModal(modalId)),
    removeAssistant: bindActionCreators(removeAssistant, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveAssistantModal);
