import { connect } from 'react-redux';

import EventMachineImage from './EventMachineImage';
import * as routes from '../../index/routes.js';

export function mapStateToProps(state, { machine }) {
  return {
    machineLink: routes.getMachineLink(machine.id),
    machineImage: machine.image,
  };
}

export default connect(mapStateToProps)(EventMachineImage);
