import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNumber } from 'lodash';

import { datePropType } from '../../../../../../../core/propTypes';

export const validateHour = (value) => {
  if (!value) return 0;
  const intValue = parseInt(value);
  if (!isNumber(intValue) || isNaN(intValue)) return 0;
  if (intValue > 23) return 23;
  if (intValue < 0) return 0;
  return intValue;
};

export const validateMinuteOrSeconds = (value) => {
  if (!value) return 0;
  const intValue = parseInt(value);
  if (!isNumber(intValue) || isNaN(intValue)) return 0;
  if (intValue > 59) return 59;
  if (intValue < 0) return 0;
  return intValue;
};

const TimeInput = ({ value, change, disabled }) => {
  const momentValue = moment(value);

  const handleHourChange = (event) => {
    if (!event) return;
    const hours = validateHour(event.target.value);
    change(moment(value).set('hours', hours));
  };

  const handleMinutesChange = (event) => {
    if (!event) return;
    const minutes = validateMinuteOrSeconds(event.target.value);
    change(moment(value).set('minutes', minutes));
  };

  const handleSecondsChange = (event) => {
    if (!event) return;
    const seconds = validateMinuteOrSeconds(event.target.value);
    change(moment(value).set('seconds', seconds));
  };

  const hourValue = !disabled ? momentValue.format('HH') : '';
  const minuteValue = !disabled ? momentValue.format('mm') : '';
  const secondValue = !disabled ? momentValue.format('ss') : '';

  return (
    <div className="datepicker-time">
      <input
        type="text"
        className="sm"
        placeholder="00"
        value={hourValue}
        onChange={handleHourChange}
        disabled={disabled}
      />
      <span className="datepicker-time-spacer">:</span>
      <input
        type="text"
        className="sm"
        placeholder="00"
        value={minuteValue}
        onChange={handleMinutesChange}
        disabled={disabled}
      />
      <span className="datepicker-time-spacer">:</span>
      <input
        type="text"
        className="sm"
        placeholder="00"
        value={secondValue}
        onChange={handleSecondsChange}
        disabled={disabled}
      />
    </div>
  );
};

TimeInput.propTypes = {
  value: datePropType,
  change: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TimeInput;
