import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Tab = ({
  label, isActive, onClick, tabIndex,
}) => {
  const style = classnames(
    'big-tab', isActive ? 'active' : '',
  );
  return (
    <button className={style} onClick={onClick} tabIndex={tabIndex}>
      <div className="big-tab-wrap">
        <div className="big-tab-in">
          { label }
        </div>
      </div>
    </button>
  );
};

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default Tab;
