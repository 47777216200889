import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Permission } from '../../../../../common/constants/permissions';
import permissionsList, {
  PermissionInstance,
} from '../../../../../common/permissions/permissionsList';
import { selectAppConfig } from '../../../redux/selectors/appConfig.selector';
import { getRoles } from '../../../redux/selectors/profile.selector';

/**
 * A hook that returns an instance of a permission class based on the permission
 * name.
 * @param permission The name of the permission class to instantiate the
 * permission instance for.
 * @returns An instance of the permission class.
 */
export const usePermissionInstance = <T extends Permission>(
  permission: T,
): PermissionInstance<T> => {
  const roles = useSelector(getRoles);
  const config = useSelector(selectAppConfig);
  return useMemo(
    () => new permissionsList[permission](roles, config),
    [permission, roles, config],
  );
};
