import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import EditModalFooter from '../../../core/footers/EditModalFooter';
import ModalLine from '../../../core/ModalLine';
import commonCoreMessages from '../../../../../messages/common.core.messages';
import commonFieldsMessages from '../../../../../messages/common.fields.messages';
import * as form from '../../../../../redux/constants/tools.edit.form.constants';
import { FormField, TextareaFormField } from '../../../../_tools';
import NewActivityTypeContainer from './NewActivityType.container';
import MachineSelectContainer from '../../../../_tools/formFields/select/MachineSelect.container';

export const EditToolForm = ({
  submitting,
  pristine,
  handleCancel,
  handleSubmit,
  isToolMounted,
  isToolAssigned,
  showMachinesSelect,
  showComment,
}) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form-subtitle">
      <FormattedMessage
        id="tools.modal.tool_details"
        defaultMessage="Tool Details"
      />
    </div>
    <div className="inputs--row">
      <div className="inputs--col--md2">
        <FormField
          label={commonCoreMessages.label.id}
          name={form.LABEL_FIELD}
          tabIndex={1}
          maxLength="255"
          autoFocus
        />
      </div>
      <div className="inputs--col--md2">
        <FormField
          label={commonCoreMessages.type.id}
          name={form.TYPE_FIELD}
          maxLength="255"
          tabIndex={2}
        />
      </div>
    </div>
    <div className="inputs--row">
      <div className="inputs--col--md2">
        <FormField
          label={commonCoreMessages.model.id}
          name={form.MODEL_FIELD}
          maxLength="255"
          tabIndex={3}
        />
      </div>
      <div className="inputs--col--md2">
        <FormField
          label={commonFieldsMessages.serialNumber.id}
          name={form.SERIAL_FIELD}
          tabIndex={4}
          canGenerateUUID
        />
      </div>
    </div>

    <div className="form-subtitle">
      <FormattedMessage
        id="tools.modal.set_new_activity"
        defaultMessage="Set New Activity"
      />
    </div>

    <label>
      <FormattedMessage {...commonCoreMessages.type} />
      <Field
        component={NewActivityTypeContainer}
        name={form.NA_TYPE}
        tabIndex={5}
        isClearable
        isToolMounted={isToolMounted}
        isToolAssigned={isToolAssigned}
      />
    </label>

    { showMachinesSelect && (
      <label>
        <FormattedMessage {...commonCoreMessages.machine} />
        <Field
          component={MachineSelectContainer}
          name={form.NA_MACHINE}
          tabIndex={6}
        />
      </label>
    ) }

    { showComment && (
      <TextareaFormField
        label={commonCoreMessages.comment.id}
        name={form.NA_COMMENT}
        tabIndex={7}
        cols="30"
        rows="10"
        maxLength="255"
        className="height--md"
      />
    ) }

    <ModalLine />

    <EditModalFooter
      onCancelClick={handleCancel}
      onSaveClick={handleSubmit}
      isSaveButtonDisabled={pristine || submitting}
      isSaveButtonLoading={submitting}
      cancelButtonTabIndex={8}
      saveButtonTabIndex={9}
    />
  </form>
);

EditToolForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isToolMounted: PropTypes.bool.isRequired,
  isToolAssigned: PropTypes.bool.isRequired,
  showMachinesSelect: PropTypes.bool.isRequired,
};

export default EditToolForm;
