import React from 'react';

import SearchResults from './searchResults/SearchResults';
import SelectedItems from './selectedItems/SelectedItems';
import { Todo, TodoFunction } from '../../../../../common/types/common';

interface Props {
  items?: Todo[],
  selectedItems?: Todo[],
  type: string,
  onSelect?: TodoFunction,
  onDeselect?: TodoFunction
  onDeselectAll?: TodoFunction
  navigationSelectedIndex?: number
  nameToHighlight?: string,
}

export const Group: React.FC<Props> = ({
  items = [],
  selectedItems = [],
  type,
  onSelect = () => {
  },
  onDeselect = () => {
  },
  onDeselectAll = () => {
  },
  navigationSelectedIndex,
  nameToHighlight,
}) => {
  const handleSelect = (item) => onSelect({ type, item });
  const handleDeselect = (item) => onDeselect({ type, item });
  const handleDeselectAll = () => onDeselectAll(type);
  const hasSelectedItems = selectedItems && selectedItems.length > 0;

  return (
    <>
      <SearchResults
        nameToHighlight={nameToHighlight}
        items={items}
        type={type}
        onSelect={handleSelect}
        navigationSelectedIndex={navigationSelectedIndex}
        shortView={hasSelectedItems}
      />

      {hasSelectedItems && (
        <SelectedItems
          type={type}
          list={selectedItems}
          onClick={handleDeselect}
          onClickTitle={handleDeselectAll}
        />
      )}
    </>
  );
};

export default Group;
