import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MenuItemContainer from './MenuItem.container';

const Menu = ({
  dashboardLink,
  maintenanceLink,
  inventoryLink,
  aiAppsLink,
  toolsLink,
  reportsLink,
  productsLink,
  experimentsLink,
  isToolsItemAvailable,
  isReportsItemAvailable,
  isExperimentsItemAvailable,
  isAiAppsItemAvailable,
}) => (
  <div className='header-menu'>
    <ul className='menu'>
      <MenuItemContainer to={dashboardLink}>
        <span className='icon icon-dashboard' />
        <FormattedMessage defaultMessage='Dashboard' id='topBar.dashboard' />
      </MenuItemContainer>
      <MenuItemContainer to={maintenanceLink}>
        <span className='icon icon-maintenance' />
        <FormattedMessage defaultMessage='Maintenance' id='topBar.maintenance' />
      </MenuItemContainer>
      <MenuItemContainer to={inventoryLink}>
        <span className='icon icon-inventory' />
        <FormattedMessage defaultMessage='Inventory' id='topBar.inventory' />
      </MenuItemContainer>
      {isToolsItemAvailable && (
        <MenuItemContainer to={toolsLink}>
          <span className='icon icon-tool' />
          <FormattedMessage defaultMessage='Tools' id='topBar.tools' />
        </MenuItemContainer>
      )}
      {isReportsItemAvailable && (
        <MenuItemContainer to={reportsLink}>
          <span className='icon icon-reports' />
          <FormattedMessage defaultMessage='Reports' id='topBar.reports' />
        </MenuItemContainer>
      )}
      <MenuItemContainer to={productsLink}>
        <span className='icon icon-products' />
        <FormattedMessage defaultMessage='Products' id='topBar.products' />
      </MenuItemContainer>
      {isExperimentsItemAvailable && (
        <MenuItemContainer to={experimentsLink}>
          <span className='icon icon-experiment' />
          <FormattedMessage defaultMessage='Experiments' id='topBar.experiments' />
        </MenuItemContainer>
      )}
      {isAiAppsItemAvailable && (
        <MenuItemContainer to={aiAppsLink}>
          <span className='icon icon-chart' />
          <FormattedMessage defaultMessage='AI Apps' id='topBar.aiApps' />
        </MenuItemContainer>
      )}
    </ul>
  </div>
);

Menu.propTypes = {
  dashboardLink: PropTypes.string.isRequired,
  maintenanceLink: PropTypes.string.isRequired,
  inventoryLink: PropTypes.string.isRequired,
  aiAppsLink: PropTypes.string.isRequired,
  toolsLink: PropTypes.string.isRequired,
  reportsLink: PropTypes.string.isRequired,
  productsLink: PropTypes.string.isRequired,
  experimentsLink: PropTypes.string.isRequired,
  isToolsItemAvailable: PropTypes.bool.isRequired,
  isReportsItemAvailable: PropTypes.bool.isRequired,
  isExperimentsItemAvailable: PropTypes.bool.isRequired,
  isAiAppsItemAvailable: PropTypes.bool.isRequired,
};

export default Menu;
