import { createAction } from 'redux-act';
import {
  take, race, put, call, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { app } from '../../components/index/routes';

export const pageDataLoad = createAction(
  'load data required for the page',
  (action, successAction, failureAction, callback) => ({
    action, successAction, failureAction, callback,
  }),
);

export const showSpinner = createAction(
  'show page spinner',
);

export const hideSpinner = createAction(
  'hide page spinner',
);

export const showErrorPage = createAction(
  'show error page',
  (error) => error,
);

export const reducer = {
  [showSpinner]: (state) => ({
    ...state,
    page: {
      ...state.page,
      isSpinnerShown: true,
    },
  }),
  [hideSpinner]: (state) => ({
    ...state,
    page: {
      ...state.page,
      isSpinnerShown: false,
    },
  }),
  [showErrorPage]: (state, error) => ({
    ...state,
    page: {
      ...state.page,
      error,
    },
  }),
};

export function* pageDataLoadSaga({ payload }) {
  const {
    action, successAction, failureAction, callback,
  } = payload;

  yield put(action);
  yield put(showSpinner());

  const { failure } = yield race({
    success: take(successAction.getType()),
    failure: take(failureAction.getType()),
  });

  yield put(hideSpinner());
  if (callback) {
    yield call(callback);
  }

  if (failure) {
    yield put(showErrorPage(failure.payload));
  }
}

export function* showErrorPageSaga() {
  yield put(push(app.prefix + app.error));
}

export function* watchPageDataLoad() {
  yield takeLatest(pageDataLoad.getType(), pageDataLoadSaga);
}

export function* watchShowErrorPage() {
  yield takeLatest(showErrorPage.getType(), showErrorPageSaga);
}
