import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import * as calendarMode from '../../redux/constants/calendar.constants';
import CalendarMonthly from './CalendarMonthly';
import CalendarWeekly from './CalendarWeekly';

export default function Calendar({ mode, selectWeeklyMode, selectMonthlyMode }) {
  const weeklyClasses = classNames(
    'calendar-weekly-select',
    {
      active: mode === calendarMode.WEEKLY_CALENDAR,
    },
  );
  const monthlyClasses = classNames(
    'calendar-monthly-select',
    {
      active: mode === calendarMode.MONTHLY_CALENDAR,
    },
  );

  return (
    <div>
      <div className="subheader">
        <div className="subheader-in">
          <div className="subheader--left">
            <ul className="menu tab-menu">
              <li>
                <FormattedMessage
                  id="dashboard.calendar.maintenance"
                  defaultMessage="Maintenance"
                  tagName="strong"
                />
              </li>
            </ul>
          </div>
          <div className="subheader--right">
            <ul className="menu tab-menu">
              <li className={weeklyClasses}>
                <a onClick={selectWeeklyMode}>
                  <FormattedMessage
                    id="dashboard.calendar.weekly"
                    defaultMessage="Weekly"
                  />
                </a>
              </li>
              <li className={monthlyClasses}>
                <a onClick={selectMonthlyMode}>
                  <FormattedMessage
                    id="dashboard.calendar.monthly"
                    defaultMessage="Monthly"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="scroll">
        {
          mode === calendarMode.MONTHLY_CALENDAR
            ? (<CalendarMonthly />)
            : (<CalendarWeekly />)
        }
      </div>
    </div>
  );
}

Calendar.propTypes = {
  mode: PropTypes.string.isRequired,
  selectWeeklyMode: PropTypes.func.isRequired,
  selectMonthlyMode: PropTypes.func.isRequired,
};
