import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import AddNewAssistanceButton from './AddNewAssistanceButton';
import ReportIcon from '../placeholderIcons/ReportIcon';

export const NoAssistance = ({ onClick, canAddAssistants }) => (
  <div className="inventory-empty">
    <div>
      <div className="pb15">
        <ReportIcon />
        <strong>
          <FormattedMessage
            id="inventory.no_assistance"
            defaultMessage="No assistants added"
          />
        </strong>
      </div>
      {
        canAddAssistants && (
          <AddNewAssistanceButton
            onClick={onClick}
            isTopButton={false}
          />
        )
      }
    </div>
  </div>
);

NoAssistance.propTypes = {
  onClick: PropTypes.func.isRequired,
  canAddAssistants: PropTypes.bool.isRequired,
};

export default NoAssistance;
