export const hour = 'hour';
export const day = 'day';
export const week = 'week';
export const month = 'month';
export const year = 'year';

export default [
  hour,
  day,
  week,
  month,
  year,
];

export const getSecondsAmount = (period) => {
  switch (period) {
    case hour: return 60 * 60;
    case day: return 60 * 60 * 24;
    case week: return 60 * 60 * 24 * 7;
    case month: return 60 * 60 * 24 * 30;
    case year: return 60 * 60 * 24 * 365;
    default: throw new Error(`machinePerformance enum: wrong period: ${period}`);
  }
};
