import { connect } from 'react-redux';

import { getLocation } from '../../../redux/selectors/location.selector';
import LocationComponent from './Location';

export const mapStateToProps = (state) => ({
  location: getLocation(state),
});

export default connect(mapStateToProps)(LocationComponent);
