import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import messages from '../../../../../../messages/tools.logs.messages';
import { mounted } from '../../../../../../../../common/enums/toolLogTypes';
import { LiveTimeOffset } from '../../../../../_tools';

const dateFormat = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const TableItem = ({
  item,
  isLatestItem,
  intl,
}) => {
  let duration = '';
  if (item.type === mounted) {
    duration = moment().from(moment().subtract(item.duration, 'seconds'), true);
    if (isLatestItem) {
      duration = <LiveTimeOffset timestamp={item.createdAt} />;
    }
  }
  return (
    <tr>
      <td>{ intl.formatMessage(messages[item.type]) }</td>
      <td>{ item.machine ? item.machine.name : '' }</td>
      <td>{ duration }</td>
      <td>{ intl.formatDate(item.createdAt, dateFormat) }</td>
    </tr>
  );
};

TableItem.propTypes = {
  item: PropTypes.object.isRequired,
  isLatestItem: PropTypes.bool.isRequired,

};

export default injectIntl(TableItem);
