import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';
import { reset as resetForm } from 'redux-form';

import { resetTempLocationPath } from './pathButton.tempLocationPath.write.module';
import { ADMIN_LOCATION_FORM_NAME } from '../constants/admin.location.form.constants';
import { ADMIN_LOCATION_FORM } from '../constants/pathButton.constants';

export const resetAdminLocationForm = createAction(
  'reset admin location form data',
);

export function* resetAdminLocationFormSaga() {
  yield put(resetForm(ADMIN_LOCATION_FORM_NAME));
  yield put(resetTempLocationPath(ADMIN_LOCATION_FORM));
}

export function* watchResetAdminLocationForm() {
  yield takeEvery(resetAdminLocationForm.getType(), resetAdminLocationFormSaga);
}
