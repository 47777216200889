import { connect } from 'react-redux';

import LoadMore from '../../../_tools/loadMore/LoadMore';
import { moreEventsAvailable } from '../../../../redux/selectors/maintenance.selector';
import { loadMoreMaintenanceEvents } from '../../../../redux/modules/maintenance.loadMore.events.module';

export function mapStateToProps(state) {
  return {
    show: moreEventsAvailable(state),
  };
}

export const mapDispatchToProps = {
  onClick: loadMoreMaintenanceEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadMore);
