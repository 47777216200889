import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import commonCoreMessages from '../../../messages/common.core.messages';
import {
  getAdminAccountsLink,
  getAdminOrganizationsLink,
  getAdminLocationsLink,
  getAdminUsersLink,
  getAdminRolesLink,
  getAdminCreateAccountLink,
  getAdminCreateOrganizationLink,
  getAdminCreateLocationLink,
  getAdminCreateUserLink,
} from '../../index/routes';

import TabsBar from '../../common/tabsBar/TabsBar';
import adminPageMessages from '../../../messages/adminPage.messages';

export const AdminPageTabs = ({
  canUseAccountsTab,
  canUseOrganizationsTab,
  canUseLocationsTab,
  canUseUsersTab,
  canUseRolesTab,
  isAccountsTabSelected,
  isOrganizationsTabSelected,
  isLocationsTabSelected,
  isUsersTabSelected,
  isRolesTabSelected,
  accountsCount,
  organizationsCount,
  locationsCount,
  usersCount,
  rolesCount,
  pushPath,
  intl,
}) => {
  const tabsBarList = [];
  const extraButtons = [];

  if (canUseAccountsTab) {
    tabsBarList.push({
      title: intl.formatMessage(commonCoreMessages.accounts),
      isActive: isAccountsTabSelected,
      count: accountsCount,
      hasCount: true,
      onClick: () => pushPath(getAdminAccountsLink()),
    });
    if (isAccountsTabSelected) {
      extraButtons.push({
        title: intl.formatMessage(
          adminPageMessages.addObject,
          { object: intl.formatMessage(commonCoreMessages.account) },
        ),
        onClick: () => pushPath(getAdminCreateAccountLink()),
      });
    }
  }
  if (canUseOrganizationsTab) {
    tabsBarList.push({
      title: intl.formatMessage(commonCoreMessages.organizations),
      isActive: isOrganizationsTabSelected,
      count: organizationsCount,
      hasCount: true,
      onClick: () => pushPath(getAdminOrganizationsLink()),
    });
    if (isOrganizationsTabSelected) {
      extraButtons.push({
        title: intl.formatMessage(
          adminPageMessages.addObject,
          { object: intl.formatMessage(commonCoreMessages.organization) },
        ),
        onClick: () => pushPath(getAdminCreateOrganizationLink()),
      });
    }
  }
  if (canUseLocationsTab) {
    tabsBarList.push({
      title: intl.formatMessage(commonCoreMessages.locations),
      isActive: isLocationsTabSelected,
      count: locationsCount,
      hasCount: true,
      onClick: () => pushPath(getAdminLocationsLink()),
    });
    if (isLocationsTabSelected) {
      extraButtons.push({
        title: intl.formatMessage(
          adminPageMessages.addObject,
          { object: intl.formatMessage(commonCoreMessages.location) },
        ),
        onClick: () => pushPath(getAdminCreateLocationLink()),
      });
    }
  }
  if (canUseUsersTab) {
    tabsBarList.push({
      title: intl.formatMessage(commonCoreMessages.users),
      isActive: isUsersTabSelected,
      count: usersCount,
      hasCount: true,
      onClick: () => pushPath(getAdminUsersLink()),
    });
    if (isUsersTabSelected) {
      extraButtons.push({
        title: intl.formatMessage(
          adminPageMessages.addObject,
          { object: intl.formatMessage(commonCoreMessages.user) },
        ),
        onClick: () => pushPath(getAdminCreateUserLink()),
      });
    }
  }
  if (canUseRolesTab) {
    tabsBarList.push({
      title: intl.formatMessage(commonCoreMessages.roles),
      isActive: isRolesTabSelected,
      count: rolesCount,
      hasCount: true,
      onClick: () => pushPath(getAdminRolesLink()),
    });
  }

  return <TabsBar list={tabsBarList} extraButtons={extraButtons} disableScroll />;
};

AdminPageTabs.propTypes = {
  canUseAccountsTab: PropTypes.bool.isRequired,
  canUseOrganizationsTab: PropTypes.bool.isRequired,
  canUseLocationsTab: PropTypes.bool.isRequired,
  canUseUsersTab: PropTypes.bool.isRequired,
  canUseRolesTab: PropTypes.bool.isRequired,
  isAccountsTabSelected: PropTypes.bool.isRequired,
  isOrganizationsTabSelected: PropTypes.bool.isRequired,
  isLocationsTabSelected: PropTypes.bool.isRequired,
  isUsersTabSelected: PropTypes.bool.isRequired,
  isRolesTabSelected: PropTypes.bool.isRequired,
  accountsCount: PropTypes.number,
  organizationsCount: PropTypes.number,
  locationsCount: PropTypes.number,
  usersCount: PropTypes.number,
  rolesCount: PropTypes.number,
  pushPath: PropTypes.func.isRequired,
};

export default injectIntl(AdminPageTabs);
