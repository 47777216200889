import { connect } from 'react-redux';

import OU from './OU';
import * as selector from '../../redux/selectors/ou.selector';

export function mapStateToProps(state) {
  return {
    isLoading: selector.loading(state),
  };
}

export default connect(mapStateToProps)(OU);
