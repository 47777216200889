import { reduxForm } from 'redux-form';

import CalendarModule from './CalendarModule';
import { CALENDAR_FORM_NAME } from '../../../../redux/constants/calendar.form.constants';

const form = {
  form: CALENDAR_FORM_NAME,
  destroyOnUnmount: true,
};

export default reduxForm(form)(CalendarModule);
