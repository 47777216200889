import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import DoneModalFooter from '../../core/footers/DoneModalFooter';
import commonMessages from '../../../../messages/common.messages';
import commonFieldsMessages from '../../../../messages/common.fields.messages';
import ModalLine from '../../core/ModalLine';

const dataPointIdToMessage = {
  availability: { id: 'machine.form.oee.data_points.availability.label' },
  performance: { id: 'machine.form.oee.data_points.performance.label' },
  cycleStart: commonFieldsMessages.cycleStart,
  cycleEnd: commonFieldsMessages.cycleEnd,
};

export const ConfirmationModal = ({
  close,
  title,
  titleValues,
  description,
  descriptionValues,
  list,
  intl,
}) => {
  const titleTranslated = intl.formatMessage(title, titleValues);
  const descriptionTranslated = intl.formatMessage(description, descriptionValues);

  return (
    <BasicModal
      close={close}
      modalClass="modal--sm"

    >
      <div className="modal--content">
        <h3>{ titleTranslated }</h3>

        <div className="modal--subtitle">
          { descriptionTranslated }
          <br />
          { (list && list.length > 0) && (
            <ul>
              { list.map((item) => (
                <li key={item}>
                  <b>
                    -
                    { intl.formatMessage(dataPointIdToMessage[item]) }
                  </b>
                </li>
              )) }
            </ul>
          ) }
        </div>

        <ModalLine />
        <DoneModalFooter
          onCancelClick={close}
          onDoneClick={close}
          hideCancelButton
          doneButtonIntl={commonMessages.ok}
        />
      </div>
    </BasicModal>
  );
};

ConfirmationModal.propTypes = {
  close: PropTypes.func.isRequired,
  title: PropTypes.object.isRequired,
  titleValues: PropTypes.object,
  description: PropTypes.object.isRequired,
  descriptionValues: PropTypes.object,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,

};

ConfirmationModal.defaultProps = {
  titleValues: {},
  descriptionValues: {},
};

export default injectIntl(ConfirmationModal);
