import { connect } from 'react-redux';

import Buttons from './Buttons';
import { openModal } from '../../../../../redux/modules/modals.module';
import { EXPERIMENT_EDIT, EXPERIMENT_ARCHIVE, EXPERIMENT_DOWNLOAD_LINKS } from '../../../../../redux/constants/modals.constants';
import PermissionedComponent from '../../../../common/permissions/PermissionedComponent.container';
import { EXPERIMENTS_PERMISSIONS } from '../../../../../../../common/constants/permissions';
import { ARCHIVED } from '../../../../../../../common/enums/experimentStatuses';

export const mapStateToProps = (state, { permissions, experiment }) => ({
  canEditExperiment: permissions.canEditExperiment() && experiment.status !== ARCHIVED,
  canArchiveExperiment: permissions.canArchiveExperiment() && experiment.status !== ARCHIVED,
});

export const mapDispatchToProps = (dispatch, { experiment }) => ({
  openEditModal: () => dispatch(openModal(EXPERIMENT_EDIT, { experiment })),
  openArchiveModal: () => dispatch(openModal(EXPERIMENT_ARCHIVE, { experiment })),
  openDownloadModal: () => dispatch(openModal(EXPERIMENT_DOWNLOAD_LINKS, { experimentId: experiment.id })),
});

export default PermissionedComponent(EXPERIMENTS_PERMISSIONS)(connect(mapStateToProps, mapDispatchToProps)(Buttons));
