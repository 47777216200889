import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import FormatSelector from './options/FormatSelector';
import SelectDateRange from './warnings/SelectDateRange';
import NoDataToExport from './warnings/NoDataToExport';

export const Download = ({
  downloadProps,
  afterDownloadButtonClick,
  fileFormat,
  onFileFormatChange,
  askForFileFormat,
  downloadButtonMessage,
  generateExportUrl,
  dateFiltersSelected,
  close,
  isExportFileEmpty,
}) => {
  const handleClick = () => {
    if (!dateFiltersSelected) return;

    window.location = generateExportUrl({
      ...downloadProps,
      fileFormat,
      localDateTime: moment().format('YYYYMMDD-HHmm'),
    });
    if (afterDownloadButtonClick) afterDownloadButtonClick();
  };

  return (
    <div className="export--line">
      { !dateFiltersSelected && <SelectDateRange /> }
      { dateFiltersSelected && isExportFileEmpty && <NoDataToExport /> }
      { askForFileFormat && (
        <FormatSelector
          value={fileFormat}
          onChange={onFileFormatChange}
        />
      ) }
      <div className="line" />
      <div className="central-form">
        <button
          className="button grey"
          onClick={close}
        >
          <FormattedMessage
            defaultMessage="Cancel"
            id="common.Cancel"
          />
        </button>
        <button
          className="button green"
          onClick={handleClick}
          disabled={!dateFiltersSelected || isExportFileEmpty}
        >
          { downloadButtonMessage }
        </button>
      </div>
    </div>
  );
};

Download.propTypes = {
  downloadButtonMessage: PropTypes.string.isRequired,
  dateFiltersSelected: PropTypes.bool.isRequired,
  isExportFileEmpty: PropTypes.bool.isRequired,
  downloadProps: PropTypes.object.isRequired,
  fileFormat: PropTypes.string.isRequired,
  askForFileFormat: PropTypes.bool.isRequired,
  onFileFormatChange: PropTypes.func.isRequired,
  generateExportUrl: PropTypes.func.isRequired,
  afterDownloadButtonClick: PropTypes.func,
  close: PropTypes.func.isRequired,
};

export default Download;
