import React from 'react';
import ModalLine from '../../../../core/ModalLine';
import DoneModalFooter from '../../../../core/footers/DoneModalFooter';
import TypeUuidFilterField from './TypeUuidFilterField';

interface TypeUuidFilterFrameProps {
  onCancelClick: (e?: React.MouseEvent) => void;
  onDoneClick: (e?: React.MouseEvent) => void;
}

const TypeUuidFilterFrame: React.FC<TypeUuidFilterFrameProps> = ({
  onDoneClick,
  onCancelClick,
}) => (
  <div className='modal-move modal-filters'>
    <div className='modal-form-in'>
      <TypeUuidFilterField />
    </div>

    <ModalLine />
    <DoneModalFooter onDoneClick={onDoneClick} onCancelClick={onCancelClick} />
  </div>
);

export default TypeUuidFilterFrame;
