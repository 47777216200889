import React, { useCallback, useState } from 'react';
import {
  Props as ChildProps,
  TableQuerySearchStateless,
} from './TableQuerySearchStateless';

export type Props = Omit<ChildProps, 'isOpen' | 'open' | 'close'>;

export const TableQuerySearch: React.FC<Props> = (props) => {
  const [isOpen, isOpenSet] = useState(false);
  const open = useCallback(() => isOpenSet(true), []);
  const close = useCallback(() => isOpenSet(false), []);

  return (
    <TableQuerySearchStateless
      open={open}
      close={close}
      isOpen={isOpen}
      {...props}
    />
  );
};
