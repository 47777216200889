import React from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { Todo } from '../../../../../common/types/common';
import { getReportChartData } from '../../../core/common/reportChart';
import { ChartType } from '../../../redux/constants/chart.type.constants';
import { getChartType as getDatatronChartType } from '../../../redux/selectors/datatron.selector';
import { getChartType } from '../../../redux/selectors/machine.selector';
import KpiChartContainer from '../../_tools/kpiChart/KpiChartContainer';
import LabelsWrapper from '../../_tools/labelsWrapper/LabelsWrapper';
import { useBoundingClientRect } from '../../_tools/useBoundingClientRect';
import GraphTabsContainer from './GraphTabsContainer';

interface Props {
  isDatatron?: boolean;
  startDateTime?: string;
  report: Todo;
  endDateTime?: string;
}

export const Graph: React.FC<Props> = ({
  isDatatron = false,
  startDateTime,
  endDateTime,
  report,
}) => {
  const startTimestamp = moment(startDateTime || moment())
    .startOf('hour')
    .toISOString();
  const endTimestamp = moment(endDateTime || moment())
    .endOf('hour')
    .toISOString();

  const { data, allowedProps, isEmptyChart } = getReportChartData({
    report,
    startDateTime: startTimestamp,
    endDateTime: endTimestamp,
  });

  const datatronChartType = useSelector(getDatatronChartType);
  const selectedChartType = useSelector(getChartType);
  const chartType: ChartType = isDatatron
    ? datatronChartType
    : selectedChartType;
  const chartStyle = { height: '200px' };

  const [ref, rect] = useBoundingClientRect<HTMLDivElement>();

  return (
    <div className="graph-medium">
      <div className="tabs-block">
        <GraphTabsContainer isDatatron={isDatatron} />
        <div className="tabs-content">
          <LabelsWrapper showNoData={isEmptyChart}>
            <div ref={ref} className="tabs-panel is-active" style={chartStyle}>
              <KpiChartContainer
                data={data}
                chartType={chartType}
                allowedProps={allowedProps}
                showAxisBottom
                width={rect.width}
                height={rect.height}
              />
            </div>
          </LabelsWrapper>
        </div>
      </div>
    </div>
  );
};

export default Graph;
