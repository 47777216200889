import { Todo } from '../../../../common/types/common';
import * as formConstants from '../../redux/constants/datatron.form.constants';
import { NO_FILE } from '../../redux/constants/fileUpload.component.constants';

export const getValuesFromDatatron = (datatron: Todo) => ({
  [formConstants.PHOTO_FIELD]: !datatron.imagePath ? NO_FILE : null,
  [formConstants.NAME_FIELD]: datatron.name || '',
  [formConstants.VENDOR_FIELD]: datatron.make || '',
  [formConstants.MODEL_FIELD]: datatron.model || '',
  [formConstants.YEAR_FIELD]: datatron.year || '',
  [formConstants.SERIAL_NUMBER_FIELD]: datatron.serialNumber || '',
  [formConstants.DEVICE_UUID_FIELD]: datatron.deviceUUID || '',
  [formConstants.MAC_ADDRESS_FIELD]: datatron.macAddress || '',
  [formConstants.NOTES_FIELD]: datatron.notes || '',
  [formConstants.LOCATION_FIELD]: datatron.location?.id || '',
  [formConstants.ORGANIZATION_FIELD]: {
    label: datatron.organization?.name,
    value: datatron.organization?.id,
  },
  [formConstants.IP_ADDRESS_FIELD]: datatron.ipAddress ? datatron.ipAddress : '',
  [formConstants.NETWORK_SPEED_FIELD]:
    datatron.networkSpeed || formConstants.NETWORK_SPEED_FIELD_DEFAULT,
  [formConstants.NODE_NAME_FIELD]: datatron.nodeName || '',
  [formConstants.NETMASK]: datatron.netmask || '',
  [formConstants.GATEWAY]: datatron.gateway || '',
  [formConstants.BOOT_ARGUMENTS]: datatron.bootArguments || '',
  [formConstants.OS_MODULES]: datatron.osModules || '',
  [formConstants.OS_CONFIG]: datatron.osConfig || formConstants.OS_CONFIG_DEFAULT,
});
