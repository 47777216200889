import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReportsTabs from './ReportsTabs';
import * as selector from '../../../redux/selectors/reportsTabs.selector';
import { selectReportsTab } from '../../../redux/modules/reportsTabs.select.module';
import { openModal } from '../../../redux/modules/modals.module';
import { REPORTS_TAB_ADD, REPORTS_TAB_EDIT, REPORTS_TAB_REMOVE } from '../../../redux/constants/modals.constants';

export const mapStateToProps = (state) => ({
  tabs: selector.getTabsWithCounts(state),
  loaded: selector.areTabsLoaded(state),
  selectedTabId: selector.getSelectedTabId(state),
});

export const mapDispatchToProps = (dispatch) => ({
  selectTab: bindActionCreators(selectReportsTab, dispatch),
  openAddTabModal: () => dispatch(openModal(REPORTS_TAB_ADD)),
  openEditTabModal: (tab) => dispatch(openModal(REPORTS_TAB_EDIT, { tab })),
  openRemoveTabModal: (tab) => dispatch(openModal(REPORTS_TAB_REMOVE, { tab })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTabs);
