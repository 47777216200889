import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import { GraphTabs } from './GraphTabs';
import * as machineGraphTabsModule from '../../../redux/modules/machine.graph.tabs.module';
import * as datatronGraphTabsModule from '../../../redux/modules/datatron.graph.tabs.module';
import { getChartType } from '../../../redux/selectors/machine.selector';
import { getChartType as getDatatronChartType } from '../../../redux/selectors/datatron.selector';
import * as chartTypes from '../../../redux/constants/chart.type.constants';
import { Todo } from '../../../../../common/types/common';

interface Props {
  isDatatron: Todo;
}

const useChartType = (isDatatron) => {
  const datatronChartType = useSelector(getDatatronChartType);
  const chartType = useSelector(getChartType);
  return isDatatron ? datatronChartType : chartType;
};

const useToChartFunctions = (isDatatron) => {
  const dispatch = useDispatch();
  return isDatatron ? {
    toLineChart: () => dispatch(datatronGraphTabsModule.toLineChart()),
    toStackedChart: () => dispatch(datatronGraphTabsModule.toStackedChart()),
    toStackedBarChart: () => dispatch(datatronGraphTabsModule.toStackedBarChart()),
  } : {
    toLineChart: () => dispatch(machineGraphTabsModule.toLineChart()),
    toStackedChart: () => dispatch(machineGraphTabsModule.toStackedChart()),
    toStackedBarChart: () => dispatch(machineGraphTabsModule.toStackedBarChart()),
  };
};

export const GraphTabsContainer: React.FC<Props> = ({ isDatatron }) => {
  const chartType = useChartType(isDatatron);
  const isLineChartSelected = chartType === chartTypes.LINE_CHART;
  const isStackedChartSelected = chartType === chartTypes.STACKED_CHART;
  const isStackedBarChartSelected = chartType === chartTypes.STACKED_BAR_CHART;
  const isStackedBarChartVisible = !isDatatron;
  const isStackedChartVisible = !!isDatatron;
  const {
    toLineChart,
    toStackedChart,
    toStackedBarChart,
  } = useToChartFunctions(isDatatron);
  return (
    <GraphTabs
      toLineChart={toLineChart}
      toStackedChart={toStackedChart}
      toStackedBarChart={toStackedBarChart}
      isLineChartSelected={isLineChartSelected}
      isStackedChartSelected={isStackedChartSelected}
      isStackedBarChartSelected={isStackedBarChartSelected}
      isStackedBarChartVisible={isStackedBarChartVisible}
      isStackedChartVisible={isStackedChartVisible}
    />
  );
};

export default GraphTabsContainer;
