import { connect } from 'react-redux';

import MeasurementsTab from './MeasurementsTab';
import * as selector from '../../../redux/selectors/experimentsPage.selector';
import { fetchMeasurements } from '../../../redux/modules/measurements.fetch.module';

export const mapStateToProps = (state) => {
  const isLoaded = selector.measurementsLoaded(state);
  const isLoading = selector.measurementsLoading(state);
  const pagination = selector.getMeasurementsPagination(state);

  return {
    paginationAvailable: isLoaded && !!pagination && pagination.total_pages > 1,
    pagination,
    measurements: selector.getMeasurements(state),
    isLoading,
  };
};

export const mapDispatchToProps = {
  choosePage: fetchMeasurements,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementsTab);
