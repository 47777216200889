import { defineMessages } from 'react-intl';
import * as toolLogTypes from '../../../common/enums/toolLogTypes';

export default defineMessages({
  [toolLogTypes.addedToStorage]: {
    id: 'tool.log.addedToStorage',
    defaultMessage: 'Added to Storage',
  },
  [toolLogTypes.assignedToMachine]: {
    id: 'tool.log.assignedToMachine',
    defaultMessage: 'Assigned to Machine',
  },
  [toolLogTypes.unassignedFromMachine]: {
    id: 'tool.log.unassignedFromMachine',
    defaultMessage: 'Unassigned from Machine',
  },
  [toolLogTypes.mounted]: {
    id: 'tool.log.mounted',
    defaultMessage: 'Mounted',
  },
  [toolLogTypes.unmounted]: {
    id: 'tool.log.unmounted',
    defaultMessage: 'Unmounted',
  },
  [toolLogTypes.expired]: {
    id: 'tool.log.expired',
    defaultMessage: 'Expired',
  },
});
