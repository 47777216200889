import React from 'react';
import { FormattedMessage } from 'react-intl';

import InventoryAddButtonContainer from '../InventoryAddButton.container';
import ReportIcon from '../../common/placeholderIcons/ReportIcon';

export const InventoryListEmptyPlaceholder = () => (
  <div className='reports-empty'>
    <div>
      <div className='pb15'>
        <ReportIcon />
        <p>
          <FormattedMessage
            id='inventory.empty_placeholder.title'
            defaultMessage='No Machines or DATATRON Added'
            tagName='strong'
          />
        </p>
        <FormattedMessage
          id='inventory.empty_placeholder.description'
          defaultMessage={
            'Click on "Add New" in order to add new Device, or broaden the location filter above'
          }
          tagName='p'
        />
      </div>
      <InventoryAddButtonContainer />
    </div>
  </div>
);
