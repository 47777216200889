import { connect } from 'react-redux';

import {
  ERROR,
} from '../../../../../../common/enums/dataPointCategories';

import CategoryFilter from './CategoryFilter';

export const mapStateToProps = (state, { filters, onChange }) => ({
  handleChange: onChange,
  selected: filters.indexOf(ERROR) !== -1,
  label: 'maintenance.stream.error',
  category: ERROR,
});

export default connect(mapStateToProps)(CategoryFilter);
