import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import AssignModal from './AssignModal';
import { assignUser } from '../../../../redux/modules/maintenance.columns.activity.assign.module';
import { closeModal } from '../../../../redux/modules/modals.module';
import { MAINTENANCE_ASSIGN } from '../../../../redux/constants/modals.constants';
import { ASSIGN_USER_FORM, USER_FIELD } from '../../../../redux/constants/maintenance.assign.user.form';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import { searchUsersByOrganization } from '../../../../redux/modules/users.organization.search.module';

export const mapStateToProps = (state) => {
  const { assignee, organizationId } = getModalPayload(state, MAINTENANCE_ASSIGN);
  const selectedUser = assignee ? { label: assignee.name, value: assignee } : null;
  return {
    organizationId,
    initialValues: {
      [USER_FIELD]: selectedUser,
    },
  };
};

export const mapDispatchToProps = (dispatch) => ({
  assignUser: bindActionCreators(assignUser, dispatch),
  search: bindActionCreators(searchUsersByOrganization, dispatch),
  close: () => dispatch(closeModal(MAINTENANCE_ASSIGN)),
});

const form = {
  form: ASSIGN_USER_FORM,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(form)(AssignModal));
