import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import labels from '../../../../messages/dynamicField.messages';
import ViewOptionsRouter from './viewOptions/ViewOptionsRouter';
import HighlightText from '../../../_tools/highlightText/HighlightText';
import { Todo } from '../../../../../../common/types/common';
import { dimensionsToString } from '../../../modals/datatronPage/dataPointForm/helpers';

const CUSTOM_CLASS_NAMES = {
  uuid: 'data-points-param-75',
  label: 'data-points-param-100',
};

type Props = {
  column: Todo;
  value?: any;
  highlight?: boolean;
  highlightString?: string;
};

export const ConfigItem: React.FC<Props> = ({ column, value, highlight, highlightString }) => {
  const { formatMessage } = useIntl();
  const key = column._key === 'name' && column._parent[0] === 'source' ? 'source' : column._key;
  const translation = labels[key] || { id: key };
  const customClassName = CUSTOM_CLASS_NAMES[key] || null;

  // dimensions should be converted from an array to a string and include the data type, passed in the `column.additional` field
  const valueToPrint =
    key === 'arrayDimensions' ? `${dimensionsToString(value)} ${column.additional}` : value;

  return (
    <div className={cn('data-points-param', customClassName)}>
      <div className='data-points-label'>{formatMessage(translation)}</div>
      <div className='data-points-label-in'>
        {highlight ? (
          <HighlightText text={valueToPrint} highlight={highlightString} />
        ) : (
          <ViewOptionsRouter column={column} value={valueToPrint} />
        )}
      </div>
    </div>
  );
};

export default ConfigItem;
