import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import commonMessages from '../../../../messages/common.messages';
import commandModalMessages from '../../../../messages/command.modal.messages';
import commonCoreMessages from '../../../../messages/common.core.messages';

interface DefaultContentProps {
  commandLabel: string;
  radioValue: boolean;
  onRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  countSelection: number;
}

export const DefaultContent: React.FC<DefaultContentProps> = ({
  commandLabel,
  radioValue,
  onRadioChange,
  countSelection,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <FormattedMessage
        defaultMessage={`${formatMessage(commandModalMessages.changeSetting)}${countSelection} ${formatMessage(commonCoreMessages.dataPoints)}`}
        id='datatron.modals.change_setting_message'
        tagName='h3'
      />
      <div className='flex-name-label data-point-action-label'>{commandLabel} </div>
      <div className='data-point-radio'>
        <label className='data-point-radio-label'>
          <input type='radio' value='yes' checked={radioValue} onChange={onRadioChange} />
          {formatMessage(commonMessages.yes)}
        </label>
      </div>
      {commandLabel !== formatMessage(commonMessages.archive) && (
        <div className='data-point-radio'>
          <label className='data-point-radio-label'>
            <input type='radio' value='no' checked={!radioValue} onChange={onRadioChange} />
            {formatMessage(commonMessages.no)}
          </label>
        </div>
      )}
    </>
  );
};
