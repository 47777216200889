import { connect } from 'react-redux';

import Modals from './Modals';
import { isModalOpen } from '../../redux/selectors/modals.selector';
import * as modals from '../../redux/constants/modals.constants';

export const mapStateToProps = (state) => ({
  isAdminPageRemoveLocationModalOpen: isModalOpen(state, modals.AP_LOCATION_REMOVE),
  isAdminPageRemoveOrganizationModalOpen: isModalOpen(state, modals.AP_ORGANIZATION_REMOVE),
  isAddDeviceModalOpen: isModalOpen(state, modals.DP_ADD_DEVICE),
  isEditDeviceModalOpen: isModalOpen(state, modals.DP_EDIT_DEVICE),
  isAddDataPointModalOpen: isModalOpen(state, modals.DP_ADD_DATA_POINT),
  isEditDataPointModalOpen: isModalOpen(state, modals.DP_EDIT_DATA_POINT),
  isEditArrayIndexModal: isModalOpen(state, modals.DP_EDIT_DATA_POINT_INDEX),
  isViewDataPointModalOpen: isModalOpen(state, modals.DP_VIEW_DATA_POINT),
  isReportRemoveModalOpen: isModalOpen(state, modals.REPORT_REMOVE),
  isReportDuplicateModalOpen: isModalOpen(state, modals.REPORT_DUPLICATE),
  isReportLegendDataPointModalOpen: isModalOpen(state, modals.REPORT_LEGEND_DATA_POINT),
  isReportsTabAddModalOpen: isModalOpen(state, modals.REPORTS_TAB_ADD),
  isReportsTabEditModalOpen: isModalOpen(state, modals.REPORTS_TAB_EDIT),
  isReportsTabRemoveModalOpen: isModalOpen(state, modals.REPORTS_TAB_REMOVE),
  isReportAddModalOpen: isModalOpen(state, modals.REPORT_ADD),
  isReportEditModalOpen: isModalOpen(state, modals.REPORT_EDIT),
  isToolAddModalOpen: isModalOpen(state, modals.TOOL_ADD),
  isToolEditModalOpen: isModalOpen(state, modals.TOOL_EDIT),
  isToolDetailsModalOpen: isModalOpen(state, modals.TOOL_DETAILS),
  isExperimentAddModalOpen: isModalOpen(state, modals.EXPERIMENT_ADD),
  isExperimentEditModalOpen: isModalOpen(state, modals.EXPERIMENT_EDIT),
  isExperimentArchiveModalOpen: isModalOpen(state, modals.EXPERIMENT_ARCHIVE),
  isExperimentDownloadModalOpen: isModalOpen(state, modals.EXPERIMENT_DOWNLOAD),
  isExperimentDownloadLinksModalOpen: isModalOpen(state, modals.EXPERIMENT_DOWNLOAD_LINKS),
  isMeasurementMergeModalOpen: isModalOpen(state, modals.MEASUREMENT_MERGE),
  isMeasurementArchiveModalOpen: isModalOpen(state, modals.MEASUREMENT_ARCHIVE),
  isMeasurementDownloadModalOpen: isModalOpen(state, modals.MEASUREMENT_DOWNLOAD),
  isConfirmationModalOpen: isModalOpen(state, modals.CONFIRMATION),
  isSyncFailedModalOpen: isModalOpen(state, modals.SMP_SYNC_FAILED),
  isAddCycleDetectionModalOpen: isModalOpen(state, modals.CYCLE_DETECTION_ADD),
  isAddAiAppModalOpen: isModalOpen(state, modals.AI_APP_ADD),
  isAddAnomalyModelModalOpen: isModalOpen(state, modals.ANOMALY_MODEL_ADD),
});

export default connect(mapStateToProps)(Modals);
