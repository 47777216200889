import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import commonMessages from '../../../../messages/common.messages';
import { Button } from '../../../_tools';

const Footer = ({
  onCancelClick,
  onSaveClick,
  isSaveButtonDisabled,
  isSaveButtonLoading,
  hideCancelButton,
  cancelButtonTabIndex,
  saveButtonTabIndex,
}) => (
  <div className={cn('modal-buttons', { 'justify-right': hideCancelButton })}>
    <Button
      type="button"
      className={cn('button__cancel', { hide: hideCancelButton })}
      onClick={onCancelClick}
      tabIndex={cancelButtonTabIndex}
    >
      <FormattedMessage {...commonMessages.cancel} />
    </Button>
    <Button
      type="submit"
      className="button__save"
      onClick={onSaveClick}
      disabled={isSaveButtonDisabled}
      showLoader={isSaveButtonLoading}
      tabIndex={saveButtonTabIndex}
    >
      <FormattedMessage {...commonMessages.save} />
    </Button>
  </div>
);

Footer.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  isSaveButtonDisabled: PropTypes.bool,
  isSaveButtonLoading: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  cancelButtonTabIndex: PropTypes.number,
  saveButtonTabIndex: PropTypes.number,
};

Footer.defaultProps = {
  isSaveButtonDisabled: false,
  isSaveButtonLoading: false,
  hideCancelButton: false,
};

export default Footer;
