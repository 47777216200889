import { z } from 'zod';
import { COMMAND_STATES, COMMAND_STATUSES } from './config_repository';

/** A zod schema for a command-journal message */
export const commandJournalMessageSchema = z.object({
  commandId: z.number().int(),
  commandName: z.string(),
  commandJournalId: z.number().int(),
  state: z.enum(COMMAND_STATES),
  status: z.enum(COMMAND_STATUSES),
  timestamp: z
    .string()
    .or(z.date())
    .transform((d) => new Date(d)),
});

export type CommandJournalMessageInput = z.input<
  typeof commandJournalMessageSchema
>;

export type CommandJournalMessage = z.infer<typeof commandJournalMessageSchema>;
