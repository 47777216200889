import { connect } from 'react-redux';

import DownloadMeasurementModal from './DownloadMeasurementModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import { MEASUREMENT_DOWNLOAD } from '../../../../redux/constants/modals.constants';

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(MEASUREMENT_DOWNLOAD)),
});

export default connect(null, mapDispatchToProps)(DownloadMeasurementModal);
