import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import InProgressColumnContainer from './InProgressColumn.container';
import PrioritizedColumnContainer from './PrioritizedColumn.container';
import InboxColumnContainer from './InboxColumn.container';
import Scheduled from './Scheduled';
import SubHeader from './SubHeader';
import ScheduledHeader from './ScheduledHeader';

export class Columns extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <DndProvider backend={HTML5Backend} debugMode>
        <div className="col--3 maintenances-wrap">
          <div className="col--3--center">
            <SubHeader />
            <div className="maintenance-wrap">
              <div className="maintenance">
                <InProgressColumnContainer />
                <PrioritizedColumnContainer />
                <InboxColumnContainer showLoadMoreButton />
              </div>
            </div>
          </div>
          <div className="col--3--right col--3--right__hide">
            <ScheduledHeader />
            <Scheduled />
          </div>
        </div>
      </DndProvider>
    );
  }
}

export default Columns;
