import { defineMessages } from 'react-intl';
import * as types from '../../../common/enums/locationTypes';

export default defineMessages({
  [types.organization]: {
    id: 'locations.types.organization',
    defaultMessage: 'Organization',
  },
  [types.country]: {
    id: 'locations.types.country',
    defaultMessage: 'Country',
  },
  [types.state]: {
    id: 'locations.types.state',
    defaultMessage: 'State',
  },
  [types.city]: {
    id: 'locations.types.city',
    defaultMessage: 'City',
  },
  [types.district]: {
    id: 'locations.types.district',
    defaultMessage: 'District',
  },
  [types.factory]: {
    id: 'locations.types.factory',
    defaultMessage: 'Factory',
  },
  [types.hall]: {
    id: 'locations.types.hall',
    defaultMessage: 'Hall',
  },
  [types.floor]: {
    id: 'locations.types.floor',
    defaultMessage: 'Floor',
  },
  [types.assembly]: {
    id: 'locations.types.assembly',
    defaultMessage: 'Assembly',
  },
  [types.line]: {
    id: 'locations.types.line',
    defaultMessage: 'Line',
  },
  [types.production]: {
    id: 'locations.types.production',
    defaultMessage: 'Production',
  },
});
