import React from 'react';
import PropTypes from 'prop-types';

import CheckboxListItem from './CheckboxListItem';

export const CheckboxList = ({
  options,
  selected,
  onChange,
}) => (
  <ul className="checkbox-list">
    { options.map((item, index) => (
      <CheckboxListItem
        key={index + 1}
        item={item}
        checked={selected.indexOf(item.value) >= 0}
        onChange={onChange}
      />
    )) }
  </ul>
);

CheckboxList.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxList;
