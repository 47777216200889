import { createAction } from '@reduxjs/toolkit';
import { getDatatron } from '../selectors/datatron.selector';
import { getDatatrons } from '../selectors/inventory.selector';
import { AppReducerMapBuilder } from './util';

export const updateActiveStatusOfDatatron = createAction(
  'update active status of datatron',
  (datatronId: string, newActive: boolean) => ({
    payload: { datatronId, newActive },
  }),
);

export const reducer = AppReducerMapBuilder.new()
  .add(updateActiveStatusOfDatatron, (state, { datatronId, newActive }) => {
    // it seems like the datatron is in two places in the state tree
    const newDetails = {
      ...getDatatron(state),
      active: newActive,
    };
    // inventory.datatrons.list
    const newInventoryDatatrons = getDatatrons(state).map((datatron) => {
      if (datatron.id !== datatronId) return datatron;
      return {
        ...datatron,
        active: newActive,
      };
    });
    return {
      ...state,
      datatron: {
        ...state.datatron,
        details: newDetails,
      },
      inventory: {
        ...state.inventory,
        datatrons: newInventoryDatatrons,
      },
    };
  })
  .build();
