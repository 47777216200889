import * as inventoryTypes from '../../../../common/constants/inventory';
import { SMP_ASSISTANT_REMOVE, DP_ASSISTANT_REMOVE } from '../../redux/constants/modals.constants';

export const getRemoveAssistantModalId = (inventoryType) => {
  switch (inventoryType) {
    case inventoryTypes.INVENTORY_MACHINES: return SMP_ASSISTANT_REMOVE;
    case inventoryTypes.INVENTORY_DATATRONS: return DP_ASSISTANT_REMOVE;
    default: return null;
  }
};
