import React from 'react';
import PropTypes from 'prop-types';

import EmptyData from './labels/EmptyData';
import LoadingInProgress from './labels/LoadingInProgress';
import MissingReportDetails from './labels/MissingReportDetails';

function LabelsWrapper({
  children, showLoadingInProgress, showNoData, showMissingReportDetails, isSmall,
}) {
  return (
    <div className="rel">
      { children }
      { showLoadingInProgress && <LoadingInProgress isSmall={isSmall} /> }
      { showNoData && <EmptyData isSmall={isSmall} /> }
      { showMissingReportDetails && <MissingReportDetails isSmall={isSmall} /> }
    </div>
  );
}

LabelsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  showLoadingInProgress: PropTypes.bool,
  showNoData: PropTypes.bool,
  showMissingReportDetails: PropTypes.bool,
  isSmall: PropTypes.bool,
};

LabelsWrapper.defaultProps = {
  showLoadingInProgress: false,
  showMissingReportDetails: false,
  showNoData: false,
  isSmall: false,
};

export default LabelsWrapper;
