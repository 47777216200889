import React from 'react';

interface Props {
  dataPointId: string;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
}

// handleRowSelection toggles the inclusion of a data point's ID in the selectedRows array

export const DataPointCheckboxRow: React.FC<Props> = ({
  dataPointId,
  selectedRows,
  setSelectedRows,
}) => {
  const handleRowSelection = (id: string) => {
    setSelectedRows((currentRows) =>
      currentRows.includes(id) ? currentRows.filter((rowId) => rowId !== id) : [...currentRows, id],
    );
  };

  return (
    <div className='data-points-actions d-flex align-items-center '>
      <input
        className='data-point-checkbox'
        type='checkbox'
        checked={selectedRows.includes(dataPointId)}
        onChange={() => handleRowSelection(dataPointId)}
      />
    </div>
  );
};
