import { connect } from 'react-redux';

import { DropdownCheckboxSelect } from '../../../../_tools';
import { addStatusFilter, removeStatusFilter } from '../../../../../redux/modules/measurements.filter.module';
import { getMeasurementsFilters } from '../../../../../redux/selectors/experimentsPage.selector';
import * as measurementStatuses from '../../../../../../../common/enums/measurementStatuses';
import messages from '../../../../../messages/measurements.status.messages';

export const mapStateToProps = (state) => ({
  selected: getMeasurementsFilters(state).status,
  options: [
    {
      id: measurementStatuses.IN_PROGRESS,
      label: messages[measurementStatuses.IN_PROGRESS],
    },
    {
      id: measurementStatuses.COMPLETED,
      label: messages[measurementStatuses.COMPLETED],
    },
    {
      id: measurementStatuses.ARCHIVED,
      label: messages[measurementStatuses.ARCHIVED],
    },
  ],
});

export const mapDispatchToProps = {
  addFilter: addStatusFilter,
  removeFilter: removeStatusFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownCheckboxSelect);
