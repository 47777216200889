import { NEW_PASSWORD_FIELD, NEW_PASSWORD_CONFIRMATION_FIELD } from '../../redux/constants/admin.user.form.constants';

export const validateNewPassword = (body) => {
  const errors = {};

  if (body[NEW_PASSWORD_FIELD] && body[NEW_PASSWORD_FIELD] !== body[NEW_PASSWORD_CONFIRMATION_FIELD]) {
    errors[NEW_PASSWORD_FIELD] = 'users.error.password.noMatch';
    errors[NEW_PASSWORD_CONFIRMATION_FIELD] = 'users.error.password.noMatch';
  }

  return errors;
};
