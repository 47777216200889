import { connect } from 'react-redux';

import LanguageInput from './LanguageInput';
import * as selector from '../../../../../redux/selectors/profile.selector';
import { selectAppLocales } from '../../../../../redux/selectors/appConfig.selector';

export function mapStateToProps(state) {
  return {
    language: selector.userLocale(state),
    locales: selectAppLocales(state),
  };
}

export default connect(mapStateToProps)(LanguageInput);
