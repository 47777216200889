import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import DatePickerContainer from '../../../../../_tools/formFields/datePickerV2/DatePicker.container';
import * as form from '../../../../../../redux/constants/report.form.constants';
import ModalLine from '../../../../core/ModalLine';
import DoneModalFooter from '../../../../core/footers/DoneModalFooter';

export const DatesFrame = ({ onCancelClick, onDoneClick }) => (
  <div className="modal-move modal-datepicker">
    <div className="modal-form-in datepicker-styled">
      <div className="datepicker-dropdowns--flex">
        <Field
          component={DatePickerContainer}
          name={form.DATES_FIELD}
          showCloseButton={false}
          allowClearDates
          closeAfterDateSelected={false}
          keepDropdownAlwaysOpen
        />
      </div>
    </div>
    <ModalLine />
    <DoneModalFooter onDoneClick={onDoneClick} onCancelClick={onCancelClick} />
  </div>
);

DatesFrame.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
};

export default DatesFrame;
