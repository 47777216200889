export type NotificationState = {
  id: string;
  title: string;
  description: string;
  type: string;
}[];


export const notificationsInitialState: NotificationState = [];

export const notificationsTestState: NotificationState = [
  {
    id: '123',
    title: 'New Augur',
    description: 'New Augur was added',
    type: 'event',
  },
];
