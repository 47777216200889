export const LEFT_AXIS_WIDTH = 32;
export const BOTTOM_AXIS_OFFSET = 15;
export const OFFSET_ABOVE_TOP = 0.15;
export const Y_AXIS_PADDING = 10;
export const MARGIN = {
  top: 0,
  right: 0,
  bottom: 0,
  left: LEFT_AXIS_WIDTH,
} as const;
