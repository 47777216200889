import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import { getDatatron } from '../selectors/datatron.selector';
import * as api from '../../core/api';
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from '../constants/notification.constants';
import notificationMessage from '../../messages/notification.message';
import { sendNotification } from './notifications.module';
import { getExportUrl } from '../../core/common/datatron.device';

export const downloadDevice = createAction(
  'download datatron device',
  (deviceId) => deviceId,
);

export function* downloadDeviceSaga({ payload: deviceId }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const {
    response,
    error,
  } = yield call(api.datatrons.downloadDevice, datatron.id, deviceId);

  if (response) {
    const downloadUrl = yield call(getExportUrl, { datatronId: datatron.id, deviceId });

    const win = window.open(downloadUrl, '_blank');
    win.focus();

    yield put(sendNotification(
      notificationMessage.download_success,
      null,
      NOTIFICATION_EVENT,
    ));
  } else {
    yield put(sendNotification(
      notificationMessage.download_failed,
      error,
      NOTIFICATION_ERROR,
    ));
  }
}

export function* watchDownloadDevice() {
  yield takeEvery(downloadDevice.getType(), downloadDeviceSaga);
}
