import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { INVENTORY_MACHINES } from '../../../../../../../common/constants/inventory';

export const InventoryMachine = ({ machine, select, isSelectedForPreview }) => {
  const handleClick = () => select(INVENTORY_MACHINES, machine.id);

  const hasAlert = !machine.isAvailable && !machine.isMaintenancePlanned;
  const itemClassName = classNames(
    'inventory--item',
    'inventory--item-machine',
    { alert: hasAlert },
    { active: isSelectedForPreview },
  );

  return (
    <div
      className={itemClassName}
      onClick={handleClick}
    >
      <div>
        <div className="inventory--item--title">
          {machine.name}
        </div>
        <div className="inventory--item--content">
          <div className="info-dialog--list">
            <div className="info-dialog--item">
              <div className="info-dialog--text">
                {machine.organization.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

InventoryMachine.propTypes = {
  machine: PropTypes.object.isRequired,
  select: PropTypes.func.isRequired,
  isSelectedForPreview: PropTypes.bool,
};

InventoryMachine.defaultProps = {
  isSelectedForPreview: false,
};

export default InventoryMachine;
