import React from 'react';


import FiltersSearchInput from './FiltersSearchInput';
import SearchDropdown from '../../search/SearchDropdown';
import { Todo, TodoFunction } from '../../../../../common/types/common';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';

interface Props {
  query: string;
  results: Todo;
  selectedFilters: Todo;
  onQueryChange: TodoFunction;
  onFilterAdd: TodoFunction;
  onFilterRemove: TodoFunction;
  onFiltersRemoveByType: TodoFunction;
  close: TodoFunction;
  categoriesIncludedForDisplay?: Todo[];
  canApplyItemFilters?: boolean;
  navigationSelectedIndex?: number;
}

export const Filters: React.FC<Props> = ({
  query,
  results,
  selectedFilters,
  onQueryChange,
  onFilterAdd,
  onFilterRemove,
  onFiltersRemoveByType,
  close,
  categoriesIncludedForDisplay,
  canApplyItemFilters = true,
  navigationSelectedIndex,
}) => {

  const handleQueryChange = (event) => {
    onQueryChange(event.target.value);
  };

  const clickRef = useClickOutside(close);

  return (
    <div className="search-inline" ref={clickRef}>
      <div className="search-block">
        <div className="search-text">
          <FiltersSearchInput
            value={query}
            onChange={handleQueryChange}
          />
        </div>

        <SearchDropdown
          nameToHighlight={query}
          selectItem={onFilterAdd}
          deselectItem={onFilterRemove}
          deselectAllItems={onFiltersRemoveByType}
          results={results}
          selectedResults={selectedFilters}
          categoriesIncludedForDisplay={categoriesIncludedForDisplay || undefined}
          close={close}
          filtersMode
          canApplyItemFilters={canApplyItemFilters}
          navigationSelectedIndex={navigationSelectedIndex}
        />
      </div>
    </div>
  );
};
