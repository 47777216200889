import { connect } from 'react-redux';

import MachineFormDetails from './MachineFormDetails';
import { CALENDAR_PERMISSIONS } from '../../../../../../common/constants/permissions';
import PermissionedComponent from '../../../common/permissions/PermissionedComponent.container';
import { getFormFields } from '../../../../redux/selectors/machine.page.form.selector';
import * as form from '../../../../redux/constants/machine.form.constants';
import { selectIsOeeHidden, selectIsAltasigmaIntegrationEnabled } from '../../../../redux/selectors/appConfig.selector';

export const mapStateToProps = (state, { permissions }) => {
  const formValues = getFormFields(state);
  const oeeEnabled = !selectIsOeeHidden(state);
  const altasigmaEnabled = selectIsAltasigmaIntegrationEnabled(state);

  return {
    oeeEnabled,
    altasigmaEnabled,
    canSelectCalendars: permissions.availableToAll(),
    canSetDmcConfig: formValues[form.DMC_FLAG] === true,
  };
};

export default PermissionedComponent(CALENDAR_PERMISSIONS)(connect(mapStateToProps)(MachineFormDetails));
