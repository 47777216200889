import React from 'react';
import PropTypes from 'prop-types';

import Arc from './Arc';
import KpiMotion from '../Kpi.motion';

export const THICKNESS_COEFFICIENT = 0.17;

function RadialChart({
  data, width, height, fill, background,
}) {
  const outerRadius = height / 2;
  const thickness = height * THICKNESS_COEFFICIENT;
  const innerRadius = outerRadius - (thickness / 2);

  const transform = `translate(${outerRadius}, ${outerRadius})`;

  return (
    <svg width={width} height={height}>
      <g transform={transform}>
        <Arc
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          className={background}
          data={1}
        />
        <KpiMotion from={0} to={data}>
          <Arc
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            className={fill}
          />
        </KpiMotion>
      </g>
    </svg>
  );
}

RadialChart.propTypes = {
  data: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};

RadialChart.defaultProps = {
  data: 0,
  width: 0,
  height: 0,
};

export default RadialChart;
