import { createAction } from 'redux-act';
import { touch, startSubmit, stopSubmit } from 'redux-form';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import * as form from '../constants/report.form.constants';
import * as selector from '../selectors/report.form.selector';
import { getSelectedTabId } from '../selectors/reportsTabs.selector';
import * as api from '../../core/api';
import { initializeReport } from '../../core/common/report';
import { fetchReportsTabs } from './reportsTabs.fetch.module';
import { closeModal } from './modals.module';
import { getApiPayload } from '../../core/common/report.form';
import { REPORT_ADD } from '../constants/modals.constants';
import { validateDates } from '../../core/validation/reports.validation';

export const createReport = createAction(
  'create report',
  (options) => options,
);

export const createReportSuccess = createAction(
  'create report - success',
  (report) => report,
);

export const createReportFail = createAction(
  'create report - fail',
  (error) => error,
);

export const reducer = {
  [createReportSuccess]: (state, report) => ({
    ...state,
    reports: {
      ...state.reports,
      data: [
        ...state.reports.data,
        report,
      ],
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    form.REPORT_FORM_NAME,
    ...form.REPORT_PROPS,
  ));
}

export function* createReportSaga({ payload: { untitledTabName } }) {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const fields = yield call(selector.getFormFields, state);
  yield put(startSubmit(form.REPORT_FORM_NAME));

  const preValidationErrors = yield call(validateDates, fields);
  if (!isEmpty(preValidationErrors)) {
    yield put(stopSubmit(form.REPORT_FORM_NAME, preValidationErrors));
    yield put(createReportFail(preValidationErrors));
    return;
  }

  let reportsTabId = yield call(getSelectedTabId, state);
  if (!reportsTabId) {
    const tabCall = yield call(api.reports.createTab, untitledTabName);
    reportsTabId = tabCall.response.id;
    yield put(fetchReportsTabs());
  }

  const apiCallBody = yield call(getApiPayload, fields, reportsTabId);

  const { response, error } = yield call(api.reports.create, apiCallBody);

  if (response) {
    yield put(stopSubmit(form.REPORT_FORM_NAME));
    yield put(createReportSuccess(initializeReport(response)));
    yield put(closeModal(REPORT_ADD));
  } else {
    yield put(stopSubmit(form.REPORT_FORM_NAME, error));
    yield put(createReportFail(error));
  }
}

export function* watchCreateReport() {
  yield takeEvery(createReport.getType(), createReportSaga);
}
