import { createAction } from 'redux-act';
import {
  put, takeLatest, delay,
} from 'redux-saga/effects';

import { fetchAdminLocations } from './admin.locations.fetch.module';
import { SORT_WAY_ASC, SORT_WAY_DESC } from '../../../../common/constants';
import { closeComponent } from './components.visibility.module';
import { AP_LOCATIONS_NAME_FILTER_INPUT } from '../constants/components.constants';

export const DEBOUNCE_TIME_IN_MILLISECONDS = 500;

export const adminSortLocations = createAction(
  'admin: sort locations',
  (option) => option,
);

export const adminFilterLocationsByName = createAction(
  'admin: filter locations by name',
  (query = '') => query,
);

export const adminResetLocationsFilters = createAction(
  'admin: reset locations filters',
);

export const reducer = {
  [adminSortLocations]: (state, option) => {
    const prevOption = state.admin.locations.filters.sort.option;
    const prevWay = state.admin.locations.filters.sort.way;

    let newOption = null;
    let newWay = null;

    if (prevOption === option && prevWay === SORT_WAY_ASC) {
      newOption = prevOption;
      newWay = SORT_WAY_DESC;
    } else if (prevOption !== option) {
      newOption = option;
      newWay = SORT_WAY_ASC;
    }

    return {
      ...state,
      admin: {
        ...state.admin,
        locations: {
          ...state.admin.locations,
          filters: {
            ...state.admin.locations.filters,
            sort: { option: newOption, way: newWay },
          },
        },
      },
    };
  },
  [adminFilterLocationsByName]: (state, q) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        filters: {
          ...state.admin.locations.filters,
          q,
        },
      },
    },
  }),
  [adminResetLocationsFilters]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      locations: {
        ...state.admin.locations,
        filters: {
          ...state.admin.locations.filters,
          q: '',
          sort: {
            option: null,
            way: null,
          },
        },
      },
    },
  }),
};

export function* adminResetLocationsFiltersSaga() {
  yield put(closeComponent(AP_LOCATIONS_NAME_FILTER_INPUT));
  yield put(fetchAdminLocations());
}

export function* watchAdminResetLocationsFilters() {
  yield takeLatest(
    [
      adminResetLocationsFilters.getType(),
    ],
    adminResetLocationsFiltersSaga,
  );
}

export function* fetchAdminLocationsSaga() {
  yield delay(DEBOUNCE_TIME_IN_MILLISECONDS);
  yield put(fetchAdminLocations());
}

export function* watchAdminLocationsFiltersChange() {
  yield takeLatest(
    [
      adminSortLocations.getType(),
      adminFilterLocationsByName.getType(),
      adminResetLocationsFilters.getType(),
    ],
    fetchAdminLocationsSaga,
  );
}
