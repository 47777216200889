import { connect } from 'react-redux';

import ReportHeader from './ReportHeader';
import { openModal } from '../../../../redux/modules/modals.module';
import { REPORT_REMOVE, REPORT_DUPLICATE, REPORT_EDIT } from '../../../../redux/constants/modals.constants';

export const mapDispatchToProps = (dispatch) => ({
  openRemoveModal: (report) => dispatch(openModal(REPORT_REMOVE, { report })),
  openDuplicateModal: (report) => dispatch(openModal(REPORT_DUPLICATE, { report })),
  openEditModal: (report) => dispatch(openModal(REPORT_EDIT, { report })),
});

export default connect(null, mapDispatchToProps)(ReportHeader);
