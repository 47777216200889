import { flattenDeep } from 'lodash';

export const flatModalConfig = (modalConfig = {}) => {
  const result = [{ ...modalConfig, children: undefined }];

  if (modalConfig.children && modalConfig.children.length) {
    result.push(...modalConfig.children.map(flatModalConfig));
  }

  return flattenDeep(result);
};

export const getActivePath = (flattenConfig = [], path = []) => {
  const flattenConfigAsObject = flattenConfig.reduce((result, item) => ({
    ...result,
    [item.id]: item,
  }), {});

  return path.reduce((result, componentId) => {
    const component = flattenConfigAsObject[componentId];
    if (!component) return result;
    return [...result, component];
  }, []);
};
