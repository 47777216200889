import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';

import * as eventsApi from '../../core/api/events.js';
import { getGroupedEventsFilters } from '../selectors/machine.selector';

export const fetchEventsSummaryByMachine = createAction(
  'fetch events summary by machine',
  (machineId) => machineId,
);

export const fetchEventsSummaryByMachineSuccess = createAction(
  'fetch events summary by machine successfully',
  (events) => events,
);

export const fetchEventsSummaryByMachineFail = createAction(
  'fetch events summary by machine fail',
  (error) => error,
);

export const reducer = {
  [fetchEventsSummaryByMachine]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      summary: {
        ...state.machine.summary,
        loaded: false,
        loading: true,
        error: null,
      },
    },
  }),

  [fetchEventsSummaryByMachineSuccess]: (state, summary) => ({
    ...state,
    machine: {
      ...state.machine,
      summary: {
        ...state.machine.summary,
        loaded: true,
        loading: false,
        data: summary.map((item) => ({
          ...item,
          totalAmount: item.amount,
          events: {
            loaded: false,
            loading: false,
            data: [],
            error: null,
          },
        })),
        error: null,
      },
    },
  }),

  [fetchEventsSummaryByMachineFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      summary: {
        ...state.machine.summary,
        loaded: false,
        loading: false,
        error,
      },
    },
  }),
};

export function* fetchEventsSummaryByMachineSaga({ payload: machineId }) {
  if (!machineId) {
    return;
  }

  const state = yield select();
  const filters = yield call(getGroupedEventsFilters, state);

  const { response, error } = yield call(eventsApi.getEventsSummaryByMachine, machineId, filters);

  if (response) {
    yield put(fetchEventsSummaryByMachineSuccess(response));
  } else {
    yield put(fetchEventsSummaryByMachineFail(error));
  }
}

export function* watchFetchEventsSummaryByMachine() {
  yield takeEvery(fetchEventsSummaryByMachine.getType(), fetchEventsSummaryByMachineSaga);
}
