import { connect } from 'react-redux';

import ColumnEventInfo from './EventInfo';
import { isLoaded, getAnomaly } from '../../../../../redux/selectors/anomaly.selector';
import PermissionedComponent from '../../../../common/permissions/PermissionedComponent.container';
import { EVENTS_PERMISSIONS } from '../../../../../../../common/constants/permissions';

export function mapStateToProps(state, { event, canSeeAnomaly, permissions }) {
  const canCreateNewComments = permissions.canCreateNewComments();
  if (!canSeeAnomaly) return { canCreateNewComments };

  return {
    isAnomalyLoaded: isLoaded(state, event.id),
    anomaly: getAnomaly(state, event.id),
    canCreateNewComments,
  };
}

export default PermissionedComponent(EVENTS_PERMISSIONS)(
  connect(mapStateToProps)(ColumnEventInfo),
);
