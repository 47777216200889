import { createAction } from 'redux-act';
import {
  put, call, select, takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch } from 'redux-form';

import {
  NEW_ASSISTANT_FORM_NAME,
  SELECTED_ASSISTANT_NAME,
  ASSISTANT_COMMENT_NAME,
} from '../constants/machine.newAssistance.form.constants';
import { getNewAssistantFormFields } from '../selectors/machine.selector';
import { getUserById } from '../selectors/usersSearch.selector';
import * as api from '../../core/api';
import { closeModal } from './modals.module';
import { ASSISTANT_ADD_NEW } from '../constants/modals.constants';

export const addNewAssistant = createAction(
  'add new assistant to machine',
  (machineId) => machineId,
);

export const addNewAssistantSuccess = createAction(
  'add new assistant to machine - success',
  (newAssistant) => newAssistant,
);

export const addNewAssistantFail = createAction(
  'add new assistant to machine - fail',
  (error) => error,
);

export const reducer = {
  [addNewAssistant]: (state) => ({
    ...state,
    machine: {
      ...state.machine,
      newAssistant: {
        ...state.machine.newAssistant,
        creating: true,
      },
    },
  }),
  [addNewAssistantSuccess]: (state, newAssistant) => ({
    ...state,
    machine: {
      ...state.machine,
      details: {
        ...state.machine.details,
        assistants: [
          ...state.machine.details.assistants,
          newAssistant,
        ],
      },
      newAssistant: {
        ...state.machine.newAssistant,
        creating: false,
      },
    },
  }),
  [addNewAssistantFail]: (state, error) => ({
    ...state,
    machine: {
      ...state.machine,
      newAssistant: {
        ...state.machine.newAssistant,
        creating: false,
        error,
      },
    },
  }),
};

export function* touchAllFieldsSaga() {
  yield put(touch(
    NEW_ASSISTANT_FORM_NAME,
    SELECTED_ASSISTANT_NAME,
    ASSISTANT_COMMENT_NAME,
  ));
}

export function* getFields(state) {
  return yield call(getNewAssistantFormFields, state);
}

export function* addNewAssistantSaga({ payload: machineId }) {
  const state = yield select();

  yield call(touchAllFieldsSaga);
  const fields = yield call(getFields, state);

  yield put(startSubmit(NEW_ASSISTANT_FORM_NAME));

  const { response, error } = yield call(api.machines.addNewAssistant, machineId, fields);

  if (response) {
    const user = yield call(getUserById, state, fields.userId);

    const addedAssistant = {
      ...user,
      comment: fields.comment,
    };

    yield put(addNewAssistantSuccess(addedAssistant));

    yield put(stopSubmit(NEW_ASSISTANT_FORM_NAME));
    yield put(closeModal(ASSISTANT_ADD_NEW));
  } else {
    yield put(addNewAssistantFail(error));
    yield put(stopSubmit(NEW_ASSISTANT_FORM_NAME, error));
  }
}

export function* watchAddNewAssistant() {
  yield takeEvery(addNewAssistant.getType(), addNewAssistantSaga);
}
