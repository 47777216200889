import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import commonMessages from '../../../../../../messages/common.messages';

const SelectAllButton = ({
  onClick,
  selected,
}) => (
  <li>
    <label className="checkbox checkbox-white">
      <input
        type="checkbox"
        onChange={onClick}
        checked={selected}
      />
      <span><FormattedMessage tagName="b" {...commonMessages.selectAll} /></span>
    </label>
  </li>
);

SelectAllButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default SelectAllButton;
