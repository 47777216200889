import { createAction } from 'redux-act';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as aiAppsApi from '../../core/api/aiApps';
import { sendNotification } from './notifications.module';
import notificationMessage from '../../messages/notification.message';
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from '../../core/notifications';
import { stringifyObject } from '../../../../common/helpers/string.helper';
import { Todo } from '../../../../common/types/common';

interface State {
  aiApps: {
    loaded: boolean;
    loading: boolean;
    error: Todo;
    //TODO: import types from interface
    cycleDetectionDetails: Todo;
  };
}

interface Device {
  [key: string]: unknown;
}

export const syncAnomalyModel = createAction(
  'sync anomalyModel',
  (appId: string, body: { [key: string]: unknown }) => ({ appId, body }),
);

export const syncAnomalyModelSuccess = createAction(
  'sync anomalyModel - success',
  (item: Device) => item,
);

export const syncAnomalyModelFail = createAction(
  'sync anomalyModel - failed',
  (error: Todo) => error,
);

export const reducer = {
  [syncAnomalyModel.getType()]: (state: State) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [syncAnomalyModelSuccess.getType()]: (state: State) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: true,
      loading: false,
      error: null,
    },
  }),

  [syncAnomalyModelFail.getType()]: (state: State, error: Todo) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: false,
      error,
    },
  }),
};

/**
 * Saga to handle syncing an anomaly model.
 *
 * This saga syncs an anomaly model based on the provided appId and body.
 * It dispatches success or failure actions and sends corresponding notifications based on the response.
 *
 * @param {object} action - The action object.
 * @param {string} action.payload.appId - The appId parameter.
 * @param {object} action.payload.body - The body object.
 */

export function* syncAnomalyModelSaga(action: ReturnType<typeof syncAnomalyModel>) {
  const {
    payload: { appId, body },
  } = action;

  const { response, error } = yield call(aiAppsApi.syncAnomalies, appId, body);

  if (response) {
    yield put(syncAnomalyModelSuccess(response));
    yield put(
      sendNotification(
        notificationMessage.successfully_synced_event,
        notificationMessage.successfully_synced_event_description,
        NOTIFICATION_EVENT,
      ),
    );
  } else {
    yield put(syncAnomalyModelFail(error));

    yield put(
      sendNotification(
        notificationMessage.server_error,
        {
          ...notificationMessage.something_happened,
          values: { error: stringifyObject(error) || '' },
        },
        NOTIFICATION_ERROR,
      ),
    );
  }
}

/**
 * Watcher saga to listen for the `syncAnomalyModel` action and sync the `syncAnomalyModelSaga`.
 */
export function* watchSyncAnomalyModel() {
  yield takeLatest(syncAnomalyModel, syncAnomalyModelSaga);
}
