import React from 'react';

import { ScaleLinear } from 'd3-scale';
import HelpLines from './HelpLines';
import AxisLeft from './AxisLeft';
import AxisBottom from './AxisBottom';
import ChartRouter from './chartRouter/ChartRouter';
import { Todo } from '../../../../../common/types/common';
import { XScale } from './types';

type Props = XScale & {
  yScale: ScaleLinear<number, number>;
  translate: string;
  chartWidth: number;
  chartHeight: number;
  allowedProps: Todo[];
  data: Todo[];
  leftAxisWidth: number;
  showVerticalLines?: boolean;
  showAxisBottom?: boolean;
  isLeftAxisInPercent?: boolean;
};

export const KpiChart: React.FC<Props> = ({
  yScale,
  translate,
  chartWidth,
  chartHeight,
  allowedProps,
  data,
  leftAxisWidth,
  showVerticalLines = true,
  showAxisBottom = false,
  isLeftAxisInPercent = true,
  ...rest
}) => (
  <svg style={{ width: '100%', height: '100%' }}>
    <AxisLeft
      scale={yScale}
      width={leftAxisWidth}
      isPercent={isLeftAxisInPercent}
    />
    <g transform={translate}>
      <rect
        width={chartWidth}
        height={chartHeight}
        className="chart-border-rect"
        fill="none"
      />
      <HelpLines
        width={chartWidth}
        height={chartHeight}
        xScale={rest.xScale}
        yScale={yScale}
        showVerticalLines={showVerticalLines}
      />
      <ChartRouter
        yScale={yScale}
        allowedProps={allowedProps}
        height={chartHeight}
        data={data}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </g>
    {
        showAxisBottom && (
          <AxisBottom
            scale={rest.xScale}
            height={chartHeight}
          />
        )
      }
  </svg>
);
