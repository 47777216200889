import { connect } from 'react-redux';

import PathButtonContainer from '../../../../_tools/pathButton/PathButton.container';
import { selectProfileLocation } from '../../../../../redux/selectors/profile.selector';
import { getPreSavedPathNames } from '../../../../../redux/selectors/pathButton.selector';
import { PROFILE_PAGE } from '../../../../../redux/constants/pathButton.constants';

export const mapStateToProps = (state) => ({
  location: selectProfileLocation(state),
  tempLocationPath: getPreSavedPathNames(state, PROFILE_PAGE),
});

export default connect(mapStateToProps)(PathButtonContainer);
