import { connect } from 'react-redux';

import InventoryAddButton from './InventoryAddButton';
import { getNewMachineLink, getNewDatatronLink } from '../index/routes';
import PermissionedComponent from '../common/permissions/PermissionedComponent.container';
import { MACHINES_PERMISSIONS, DATATRONS_PERMISSIONS } from '../../../../common/constants/permissions';

export function mapStateToProps(state, { permissions }) {
  return {
    newMachineLink: getNewMachineLink(),
    newDatatronLink: getNewDatatronLink(),
    canCreateMachines: permissions[MACHINES_PERMISSIONS].canCreateMachines(),
    canCreateDatatrons: permissions[DATATRONS_PERMISSIONS].canCreateDatatrons(),
  };
}

export default PermissionedComponent([MACHINES_PERMISSIONS, DATATRONS_PERMISSIONS])(
  connect(mapStateToProps)(InventoryAddButton),
);
