import {
  MACHINES_MANAGER, Role,
} from '../constants/permissions';

class MachinesPermissions {
  private isMachinesManager: boolean;

  constructor(roles: Role[]) {
    this.isMachinesManager = roles.indexOf(MACHINES_MANAGER) !== -1;
  }

  canAddAssistants() {
    return this.isMachinesManager;
  }

  canDeleteAssistants() {
    return this.isMachinesManager;
  }

  canCreateMachines() {
    return this.isMachinesManager;
  }

  canUpdateMachines() {
    return this.isMachinesManager;
  }

  canDeleteMachines() {
    return this.isMachinesManager;
  }

  canSubscribeMachinesToDataPoints() {
    return this.isMachinesManager;
  }

  canUpdateMachinesSubscriptionsToDataPoints() {
    return this.isMachinesManager;
  }

  canDeleteMachinesSubscriptionsToDataPoints() {
    return this.isMachinesManager;
  }
}

export default MachinesPermissions;
