import classnames from 'classnames';
import { IntlMessage } from 'client/js/core/common/intl';
import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import placeholdersMessages from '../../../messages/placeholders.messages';

export interface Props {
  value: string;
  onQueryChange: (query: string) => void;
  placeholder?: IntlMessage;
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const TableQuerySearchStateless: React.FC<Props> = ({
  value,
  onQueryChange,
  isOpen,
  open,
  close,
  placeholder = placeholdersMessages.searchByTitle,
}) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const openInput = useCallback(() => {
    open();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const closeInput = useCallback(() => {
    close();
    if (value !== '') {
      onQueryChange('');
    }
  }, [close, value, onQueryChange]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onQueryChange(event.target.value);
    },
    [onQueryChange],
  );

  const queryStyle = classnames('search-text', 'table-search', {
    compressed: !isOpen,
  });

  return (
    <div className={queryStyle}>
      <div className="input-group">
        <input
          type="text"
          ref={(input) => {
            inputRef.current = input;
          }}
          className="search-input"
          placeholder={intl.formatMessage(placeholder)}
          onChange={onChange}
          value={value}
        />
        <div className="input-group-button">
          <button
            className="search-button search-button--trigger"
            onClick={openInput}
          >
            <span className="icon icon-search" />
          </button>
          <button type="button" className="search-button search-button--submit">
            <span className="icon icon-search" />
          </button>
        </div>
        <div className="input-group-button last">
          <button
            className="search-button search-button--close"
            onClick={closeInput}
          >
            <span className="icon icon-close" />
          </button>
        </div>
      </div>
    </div>
  );
};
