import React from 'react';
import { FormattedMessage } from 'react-intl';

import StatusFilterContainer from './statusFilter/StatusFilter.container';
import commonMessages from '../../../../messages/common.messages';
import commonCoreMessages from '../../../../messages/common.core.messages';
import messages from '../../../../messages/experimentsPage.messages';
import QueryFilterContainer from './queryFilter/QueryFilter.container';

const TableHeader = () => (
  <thead>
    <tr>
      <td className="td-category">
        <div className="menu-tags white--btn">
          <StatusFilterContainer />
        </div>
      </td>
      <FormattedMessage {...messages.series} tagName="td" />
      <FormattedMessage {...commonMessages.name} tagName="td" />
      <FormattedMessage {...commonMessages.start} tagName="td" />
      <FormattedMessage {...commonMessages.end} tagName="td" />
      <FormattedMessage {...commonCoreMessages.machine} tagName="td" />
      <FormattedMessage {...commonMessages.responsible} tagName="td" />
      <FormattedMessage {...commonCoreMessages.comment} tagName="td" />
      <td className="td-edit td-edit-fixed">
        <div className="flex-name">
          <div className="rel-wrap">
            <div className="flex">
              <div className="flex-icon">
                <QueryFilterContainer />
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </thead>
);

export default TableHeader;
