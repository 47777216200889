import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import BasicModal from '../../../modals/core/BasicModal';
import AssignUserContainer from './form/assignSearch/AssignUser.container';
import UserOption from './form/assignSearch/UserOption';
import Footer from './form/footer/Footer';
import { USER_FIELD } from '../../../../redux/constants/maintenance.assign.user.form';

export class AssignModal extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    assignUser: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    organizationId: PropTypes.string.isRequired,
    pristine: PropTypes.bool.isRequired,
  };

  componentDidMount = () => {
    this.search('');
  };

  search = (q) => {
    const { organizationId, search } = this.props;
    search(organizationId, q);
  };

  render = () => {
    const {
      close, pristine, assignUser,
    } = this.props;

    return (
      <BasicModal close={close}>
        <div className="modal--content">
          <FormattedMessage
            id="assistance.assign_activity"
            defaultMessage="Assign Activity"
            tagName="h3"
          />
          <form className="form">
            <div className="like-label">
              <FormattedMessage
                id="assistance.assign_to"
                defaultMessage="Assign to"
              />
              <div className="input-group">
                <Field
                  name={USER_FIELD}
                  component={AssignUserContainer}
                  components={{
                    Option: (option) => <UserOption {...option} />,
                  }}
                  blurInputOnSelect
                  // TODO: This was removed in react-select 2.x
                  // autosize={false}
                  openMenuOnFocus
                />
              </div>
            </div>
            <div className="line" />
            <Footer
              assignButtonDisabled={pristine}
              onCancel={close}
              onAssign={assignUser}
            />
          </form>
        </div>
      </BasicModal>
    );
  };
}

export default AssignModal;
