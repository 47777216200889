import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import BasicModal from '../../core/BasicModal';
import ModalLine from '../../core/ModalLine';
import EditModalFooter from '../../core/footers/EditModalFooter';
import commonMessages from '../../../../messages/common.messages';
import * as form from '../../../../redux/constants/measurement.merge.form.constants';
import ExperimentSelectContainer from '../../../_tools/formFields/select/ExperimentSelect.container';

export const MergeMeasurementModal = ({
  close,
  submitting,
  pristine,
  handleSubmit,
}) => (
  <BasicModal close={close}>
    <div className="modal--content">
      <h3 className="first-title">
        <FormattedMessage
          id="experimentsPage.modals.merge_experiment.title"
          defaultMessage="Merge Measurement"
        />
      </h3>

      <form className="form" onSubmit={handleSubmit}>
        <label>
          <FormattedMessage {...commonMessages.experiment} />
          <Field
            name={form.EXPERIMENT_FIELD}
            tabIndex={1}
            component={ExperimentSelectContainer}
            autoFocus
          />
        </label>
      </form>

      <ModalLine />

      <EditModalFooter
        onCancelClick={close}
        onSaveClick={handleSubmit}
        isSaveButtonDisabled={pristine || submitting}
        isSaveButtonLoading={submitting}
        cancelButtonTabIndex={2}
        saveButtonTabIndex={3}
      />
    </div>
  </BasicModal>
);

MergeMeasurementModal.propTypes = {
  close: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default MergeMeasurementModal;
