import { isNumber } from '../../../../common/validation/helpers';

export const getAllOrganizations = (state) => state.admin.organizations.all.list;

export const getOrganizations = (state) => state.admin.organizations.list;
export const getPagination = (state) => state.admin.organizations.pagination;
export const getTotalCount = (state) => {
  const pagination = getPagination(state);
  if (pagination && isNumber(pagination.total)) return pagination.total;
  return null;
};
export const areOrganizationsLoaded = (state) => state.admin.organizations.loaded;

export const getOrganization = (state) => state.admin.organizations.selected.item;
export const isOrganizationLoaded = (state) => state.admin.organizations.selected.loaded;
export const isOrganizationLoading = (state) => state.admin.organizations.selected.loading;

export const getRemoveError = (state) => {
  const organization = getOrganization(state);
  if (organization && organization._remove && organization._remove.error) return organization._remove.error;
  return null;
};

export const getFilters = (state) => state.admin.organizations.filters;
export const getSort = (state) => getFilters(state).sort;
export const getNameFilterValue = (state) => getFilters(state).q;

export const getFiltersQuery = (state) => {
  const query = { q: getNameFilterValue(state) };

  const sort = getSort(state);
  if (sort.option && sort.way) {
    query[sort.option] = sort.way;
  }
  return query;
};
