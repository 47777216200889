import React from 'react';
import PropTypes from 'prop-types';
import { Element as ScrollElement } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import * as machineForm from '../../../../redux/constants/machine.form.constants';
import * as machineOeeSource from '../../../../../../common/enums/machineOeeSource';
import { FormField } from '../../../_tools';

import PhotoField from '../../../_tools/formFields/photoField/PhotoField';
import OrganizationSelectContainer from '../../../_tools/formFields/select/OrganizationSelect.container';
import ScheduleCalendarSelectContainer from '../../../_tools/formFields/select/ScheduleCalendarSelect.container';
import ShiftCalendarSelectContainer from '../../../_tools/formFields/select/ShiftCalendarSelect.container';
import Radio from '../../../_tools/formFields/radio/Radio';
import LocationModalContainer from './LocationModal.container';
import PathButtonContainer from './PathButton.container';
import MachineSubscriptionDataPointSelectContainer from '../../../_tools/formFields/select/MachineSubscriptionDataPointSelect.container';

import fileUploadMessages from '../../../_tools/fileUpload/FileUpload.message';
import formMessages from './MachineFormDetails.messages';
import oeeSourceMessages from '../../../../messages/machine.oeeSource.messages';
import commonFieldsMessages from '../../../../messages/common.fields.messages';

import AltasigmaDataPoints from './datapoints/altasigma/AltasigmaDataPoints';
import OeeDataPoints from './datapoints/oee/OeeDataPoints';

const OEE_SOURCE_OPTIONS = [
  { label: oeeSourceMessages[machineOeeSource.CYCLE_TIME], value: machineOeeSource.CYCLE_TIME },
  { label: oeeSourceMessages[machineOeeSource.PERFORMANCE], value: machineOeeSource.PERFORMANCE },
];

export const MachineFormDetails = ({
  photoUrl,
  location,
  canSelectCalendars,
  canSelectDataPoints,
  canSetDmcConfig,
  oeeEnabled,
  altasigmaEnabled,
}) => (
  <div className="central-form">
    <FormattedMessage
      id="newMachine.machine_details"
      defaultMessage="Machine Details"
      tagName="h1"
    />
    <div className="form--row">
      <div className="form--left">
        <ScrollElement name={machineForm.PHOTO_FIELD}>
          <Field
            name={machineForm.PHOTO_FIELD}
            placeholder={fileUploadMessages.add_photo.id}
            fakePreviewUrl={photoUrl}
            component={PhotoField}
          />
        </ScrollElement>
      </div>
      <div className="form--right">
        <ScrollElement name={machineForm.NAME_FIELD}>
          <FormField
            label={formMessages.machineName.id}
            name={machineForm.NAME_FIELD}
            placeholder={formMessages.machineNamePlaceholder.id}
            autoFocus
          />
        </ScrollElement>

        <ScrollElement name={machineForm.TYPE_FIELD}>
          <FormField
            label={formMessages.machineType.id}
            name={machineForm.TYPE_FIELD}
            placeholder={formMessages.machineTypePlaceholder.id}
          />
        </ScrollElement>
      </div>
    </div>

    <div className="inputs--row">
      <div className="inputs--col">
        <ScrollElement name={machineForm.VENDOR_FIELD}>
          <FormField
            label={formMessages.vendor.id}
            name={machineForm.VENDOR_FIELD}
            placeholder={formMessages.vendorPlaceholder.id}
          />
        </ScrollElement>
      </div>
      <div className="inputs--col">
        <ScrollElement name={machineForm.MODEL_FIELD}>
          <FormField
            label={formMessages.model.id}
            name={machineForm.MODEL_FIELD}
            placeholder={formMessages.modelPlaceholder.id}
          />
        </ScrollElement>
      </div>
    </div>

    <div className="inputs--row">
      <div className="inputs--col--md">
        <ScrollElement name={machineForm.SERIAL_NUMBER_FIELD}>
          <FormField
            label={formMessages.serialNumber.id}
            name={machineForm.SERIAL_NUMBER_FIELD}
            placeholder={formMessages.serialNumberPlaceholder.id}
          />
        </ScrollElement>
      </div>
      <div className="inputs--col--sm">
        <ScrollElement name={machineForm.YEAR_FIELD}>
          <FormField
            label={formMessages.year.id}
            name={machineForm.YEAR_FIELD}
            placeholder={formMessages.yearPlaceholder.id}
          />
        </ScrollElement>
      </div>
    </div>

    <div className="like-label machine-form--location">
      <FormattedMessage
        id="newMachine.selectLocation"
        defaultMessage="Select Location"
      />
      <ScrollElement name={machineForm.LOCATION_FIELD}>
        <Field
          name={machineForm.LOCATION_FIELD}
          component={PathButtonContainer}
          location={location}
        />
      </ScrollElement>
    </div>

    <label className="newMachine-organization">
      <FormattedMessage
        id="newMachine.selectOrganization"
        defaultMessage="Select Organization"
      />
      <ScrollElement name={machineForm.ORGANIZATION_FIELD}>
        <Field
          name={machineForm.ORGANIZATION_FIELD}
          component={OrganizationSelectContainer}
        />
      </ScrollElement>
    </label>

    { canSelectCalendars && (
      <div className="inputs--row">
        <div className="inputs--col">
          <label className="newMachine-shift">
            <FormattedMessage
              id="newMachine.selectShift"
              defaultMessage="Select Shift"
            />
            <ScrollElement name={machineForm.SHIFT_CALENDAR_FIELD}>
              <Field
                name={machineForm.SHIFT_CALENDAR_FIELD}
                component={ShiftCalendarSelectContainer}
                isClearable
              />
            </ScrollElement>
          </label>
        </div>
        <div className="inputs--col">
          <label className="newMachine-schedule">
            <FormattedMessage
              id="newMachine.selectSchedule"
              defaultMessage="Select Schedule"
            />
            <ScrollElement name={machineForm.SCHEDULE_CALENDAR_FIELD}>
              <Field
                name={machineForm.SCHEDULE_CALENDAR_FIELD}
                component={ScheduleCalendarSelectContainer}
                isClearable
              />
            </ScrollElement>
          </label>
        </div>
      </div>
    )}

    <>
      <ScrollElement name={machineForm.CYCLE_SECONDS_PER_PART}>
        <FormField
          label={formMessages.cycleSecondsPerPart.id}
          name={machineForm.CYCLE_SECONDS_PER_PART}
          type="number"
          min={0}
          placeholder={formMessages.cycleTimePlaceholder.id}
        />
      </ScrollElement>

      <div className="inputs--row">
        <div className="inputs--col">
          <ScrollElement name={machineForm.PERFORMANCE_MAX_FIELD}>
            <FormField
              label={formMessages.maxPerformance.id}
              name={machineForm.PERFORMANCE_MAX_FIELD}
              type="number"
              placeholder={formMessages.maxPerformancePlaceholder.id}
            />
          </ScrollElement>
        </div>
        <div className="inputs--col">
          <ScrollElement name={machineForm.PERFORMANCE_PLANNED_FIELD}>
            <FormField
              label={formMessages.plannedPerformance.id}
              name={machineForm.PERFORMANCE_PLANNED_FIELD}
              type="number"
              placeholder={formMessages.plannedPerformancePlaceholder.id}
            />
          </ScrollElement>
        </div>
      </div>

      <label className="radio-item-cols">
        <FormattedMessage {...formMessages.oeeSource} />
        <ScrollElement name={machineForm.OEE_SOURCE}>
          <Field
            name={machineForm.OEE_SOURCE}
            options={OEE_SOURCE_OPTIONS}
            component={Radio}
            translateOptionsLabels
          />
        </ScrollElement>
      </label>

      { canSelectDataPoints && (
        <OeeDataPoints
          canSetDmcConfig={canSetDmcConfig}
          altasigmaOnly={!oeeEnabled && altasigmaEnabled}
        />
      ) }
    </>

    { canSelectDataPoints && (
      <>
        <AltasigmaDataPoints />
        <div>
          <label className="pb14">
            <FormattedMessage
              id="machine.form.rfid.data_point.label"
              defaultMessage="Select Data Point for RFID"
            />
          </label>
          <div className="inputs--row">
            <div className="inputs--col">
              <label>
                <FormattedMessage {...commonFieldsMessages.rfid} />
                <ScrollElement name={machineForm.RFID_DATA_POINT_ID}>
                  <Field
                    name={machineForm.RFID_DATA_POINT_ID}
                    component={MachineSubscriptionDataPointSelectContainer}
                  />
                </ScrollElement>
              </label>
            </div>
          </div>
        </div>
      </>
    ) }

    <ScrollElement name={machineForm.NOTES_FIELD}>
      <FormField
        label={formMessages.notes.id}
        name={machineForm.NOTES_FIELD}
        placeholder={formMessages.notesPlaceholder.id}
        component="textarea"
        cols="30"
        rows="10"
      />
    </ScrollElement>

    <Field
      name={machineForm.LOCATION_FIELD}
      component={LocationModalContainer}
      location={location}
    />
  </div>
);

MachineFormDetails.propTypes = {
  photoUrl: PropTypes.string,
  location: PropTypes.object,
  canSelectCalendars: PropTypes.bool,
  canSelectOeeDataPoints: PropTypes.bool,
  oeeEnabled: PropTypes.bool.isRequired,
  altasigmaEnabled: PropTypes.bool.isRequired,
  canSetDmcConfig: PropTypes.bool.isRequired,
};

MachineFormDetails.defaultProps = {
  photoUrl: '',
  location: null,
  canSelectCalendars: true,
  canSelectOeeDataPoints: false,
};

export default MachineFormDetails;
