import React from 'react';
import PropTypes from 'prop-types';

import HighlightText from '../../../../_tools/highlightText/HighlightText';

export const Name = ({ name, filterByName }) => (
  <div className="calendar--name simple">
    <HighlightText text={name} highlight={filterByName} />
  </div>
);

Name.propTypes = {
  name: PropTypes.string.isRequired,
  filterByName: PropTypes.string.isRequired,
};

export default Name;
