import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import MetricDataContainer from './metricData/MetricData.container';
import ConfigItems
  from '../../../datatron/details/dataTab/device/dataPointsTable/list/dataPoint/dataPointTableRow/ConfigItems';

export const ViewDataPointModal = ({
  close, fields, deviceId, dataPoint, getScaledValue,
}) => (
  <BasicModal close={close} modalClass="modal--md">
    <div className="modal--content">
      <h4 className="first-title">
        <FormattedMessage
          id="datatron.data.dataPoint.most_recent_data"
          defaultMessage="Most Recent Data of Data Point"
        />
      </h4>

      <ConfigItems dataPoint={dataPoint} fields={fields} className="data-points-params2" />
      <MetricDataContainer deviceId={deviceId} dataPointId={dataPoint.id} getScaledValue={getScaledValue} />
    </div>
  </BasicModal>
);

ViewDataPointModal.propTypes = {
  close: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  deviceId: PropTypes.string.isRequired,
  dataPoint: PropTypes.object.isRequired,
  getScaledValue: PropTypes.func.isRequired,
};

export default ViewDataPointModal;
