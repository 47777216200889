import { connect } from 'react-redux';

import NoCards from './NoCards';
import * as column from '../../../../redux/constants/column.constants.js';

export function mapStateToProps(state, { type }) {
  return {
    isInProgress: type === column.COLUMN_IN_PROGRESS,
    isPrioritized: type === column.COLUMN_PRIORITIZED,
    isInbox: type === column.COLUMN_INBOX,
  };
}

export default connect(mapStateToProps)(NoCards);
