import { connect } from 'react-redux';

import Select from '../../../../_tools/formFields/select/Select';
import {
  searchUsersByOrganization,
} from '../../../../../redux/modules/users.organization.search.module';
import { getAssistants } from '../../../../../redux/selectors/assistance.selector';

export const mapStateToProps = (state, { input, inventoryType }) => ({
  options: getAssistants(state, inventoryType).map((item) => ({
    label: item.name,
    value: item.id,
  })),
  onChange: (option) => {
    input.onChange(option.value);
  },
});

export const mapDispatchToProps = (dispatch, { organizationId }) => ({
  onInputChange: (query) => {
    dispatch(searchUsersByOrganization(organizationId, query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);
