import { connect } from 'react-redux';

import Image from './Image';

export function mapStateToProps(state, { url }) {
  return {
    showPlaceholderOnly: (!url || url.length === 0),
  };
}

export default connect(mapStateToProps)(Image);
