import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Machine from './machines/Machine';
import Datatron from './datatrons/Datatron';

function List({ machines, selectedLocation, datatrons }) {
  return (
    <table className="table table-scroll table-likes machines-list">
      <thead>
        <tr>
          <td className="td-icon" />
          <td className="td-title">
            <FormattedMessage
              id="common.name"
              defaultMessage="Name"
            />
          </td>
          <td className="td-content">
            <FormattedMessage
              id="location.tabs.location"
              defaultMessage="Location"
            />
          </td>
        </tr>
      </thead>
      <tbody>
        {
          machines.map((machine) => (
            <Machine
              key={machine.id}
              machine={machine}
              selectedLocation={selectedLocation}
            />
          ))
        }
        {
          datatrons.map((datatron) => (
            <Datatron
              key={datatron.id}
              datatron={datatron}
              selectedLocation={selectedLocation}
            />
          ))
        }
      </tbody>
    </table>
  );
}

List.propTypes = {
  machines: PropTypes.array.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  datatrons: PropTypes.array.isRequired,
};

export default List;
