import React, { useEffect } from 'react';
import { Todo, TodoFunction } from '../../../../../../../common/types/common';
import { ArrayFullDisplay } from './ArrayFullDisplay';
import { ArrayButtons } from './ArrayButtons';
import ModalLine from '../../../core/ModalLine';
import { useIntl } from 'react-intl';
import commonCoreMessages from '../../../../../messages/common.core.messages';
import { countArrayDimensions, dimensionsAreValid, generateNewState } from '../helpers';

export type ArrayField = {
  value: Todo
  field: Todo
};

export type ArrayDefinition = {
  arrayDimensions: ArrayField
  arrayIndex: ArrayField
  dimensionsDefinition: Todo
};

interface Props {
  field: Todo
  type?: string
  updateHandler: TodoFunction
}

export type Action = {
  index: number[]
  newValue: Todo
};


export const ArrayFormPage: React.FC<Props> = ({ type = 'TYPE', updateHandler, field }) => {
  const dimensionsCount = countArrayDimensions(field);

  const dimensions = field.value || (dimensionsCount === 2 ? [[0, 0]] : [[[0, 0]]]);

  /**
   * Set the dimensions correctly if they are a default value
   */
  useEffect(() => {
    if (field.value === '') {
      const newDimensions = dimensionsCount === 2 ? [[0, 0]] : [[[0, 0]]];
      updateHandler(newDimensions);
    }
  }, [dimensionsCount, field, updateHandler]);

  /**
   * Re-use generateNewState, which used to be a React reducer to generate the new value to propagate into the
   * form state
   */
  const updateState = ({ index, newValue }) => {
    const newState = generateNewState(field.value, { index, newValue });
    updateHandler(newState, !dimensionsAreValid(newState));
  };

  const { formatMessage } = useIntl();
  const title = formatMessage(commonCoreMessages.dataPointArray);

  return (<>
      <h3>{title}</h3>

      <div style={{ overflow: 'scroll' }}>
        <ArrayFullDisplay dimensions={dimensions} type={type}/>
        <ArrayButtons dimensions={dimensions} updateState={updateState} dimensionsCount={dimensionsCount}/>
      </div>

      <ModalLine/>
    </>

  );
};