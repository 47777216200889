import { commentField } from '../forms/eventComment.form';

export const validateComment = (form) => {
  const errors = {};

  if (!form[commentField]) {
    return errors;
  }

  if (form[commentField].length > 1024) {
    errors[commentField] = 'comments.create.error.text_is_too_long';
  }

  return errors;
};
