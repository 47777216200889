import { defineMessages } from 'react-intl';

export default defineMessages({
  searchByTitle: {
    id: 'placeholders.searchByTitle',
    defaultMessage: 'Search by Title...',
  },
  searchByEmail: {
    id: 'placeholders.searchByEmail',
    defaultMessage: 'Search by Email...',
  },
  searchByTool: {
    id: 'placeholders.searchByTool',
    defaultMessage: 'Search by Label, Type, Model, Serial...',
  },
  searchByAiApp: {
    id: 'placeholders.searchByAiApp',
    defaultMessage: 'Search by Name, Status',
  },
  searchByExperiment: {
    id: 'placeholders.searchByExperiment',
    defaultMessage: 'Search by Name or Series...',
  },
  noDataToDisplay: {
    id: 'placeholders.noDataToDisplay',
    defaultMessage: 'No Data To Display',
  },
  noDataAdded: {
    id: 'placeholders.noDataToAdded',
    defaultMessage: 'No Data Added',
  },
  noDataSelected: {
    id: 'placeholders.noDataToSelected',
    defaultMessage: 'No Data Selected',
  },
  noToolsAdded: {
    id: 'tools.empty_placeholder.title',
    defaultMessage: 'No Tools Added',
  },
  noToolsAddedDescription: {
    id: 'tools.empty_placeholder.description',
    defaultMessage: 'Click on "Add New" in order to add new Tool',
  },
  noToolsFound: {
    id: 'tools.empty_placeholder.with_filters.title',
    defaultMessage: 'No Tools Found',
  },
  noToolsFoundDescription: {
    id: 'tools.empty_placeholder.with_filters.description',
    defaultMessage: 'Change filters or add new Tool',
  },
  noToolsFoundOnMachinePageDescription: {
    id: 'tools.empty_placeholder.machine_page.with_filters.description',
    defaultMessage: 'Change filters or add new Tool on Tools page',
  },
  noToolsAddedOnMachinePageDescription: {
    id: 'tools.machine_page.empty_placeholder.description',
    defaultMessage: 'Use Tool page to add Tools to Machine',
  },
  noExperimentsAdded: {
    id: 'experiments.empty_placeholder.title',
    defaultMessage: 'No Experiments Added',
  },
  noExperimentsAddedDescription: {
    id: 'experiments.empty_placeholder.description',
    defaultMessage: 'Click on "Add New" in order to add new Experiment',
  },
  noExperimentsFound: {
    id: 'experiments.empty_placeholder.with_filters.title',
    defaultMessage: 'No Experiments Found',
  },
  noExperimentsFoundDescription: {
    id: 'experiments.empty_placeholder.with_filters.description',
    defaultMessage: 'Change filters or add new Experiment',
  },
  noMeasurementsAdded: {
    id: 'measurements.empty_placeholder.title',
    defaultMessage: 'No Measurements added',
  },
  noMeasurementsFound: {
    id: 'measurements.empty_placeholder.with_filters.title',
    defaultMessage: 'No Measurements found',
  },
  searchByDataPoint: {
    id: 'placeholders.search_by_data_point',
    defaultMessage: 'Search by UUID, Label, Identifier...',
  },
});
