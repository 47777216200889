import { defineMessages } from 'react-intl';
import * as calendarTypes from '../../../common/enums/calendarTypes';

export default defineMessages({
  [calendarTypes.SHIFT_CALENDAR]: {
    id: 'calendars.type.shift',
    defaultMessage: 'Shift',
  },
  [calendarTypes.SCHEDULE_CALENDAR]: {
    id: 'calendars.type.schedule',
    defaultMessage: 'Schedule',
  },
});
