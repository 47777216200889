import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type BoundingRect = Pick<DOMRect, 'width' | 'height' | 'top' | 'left'>;

export const useBoundingClientRect = <T extends HTMLElement>(): [
  MutableRefObject<T | null>,
  BoundingRect,
] => {
  const ref = useRef<T | null>(null);
  const [rect, rectSet] = useState<BoundingRect>({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });

  const updateRect = useCallback(() => {
    if (ref.current) {
      const newRect = ref.current.getBoundingClientRect();
      rectSet({
        width: newRect.width,
        height: newRect.height,
        top: newRect.top,
        left: newRect.left,
      });
    }
  }, []);

  useEffect(() => {
    updateRect();
    window.addEventListener('resize', updateRect);
    return () => window.removeEventListener('resize', updateRect);
  }, [updateRect]);

  return [ref, rect];
};
