import { defineMessages } from 'react-intl';

import * as entities from '../../../common/constants/entities';

export default defineMessages({
  [`select_${[entities.MACHINES]}`]: {
    id: 'search.machines.select',
    defaultMessage: 'Select Machines',
  },
  [`selected_${[entities.MACHINES]}`]: {
    id: 'search.machines.selected',
    defaultMessage: 'Selected Machines',
  },
  [`select_${[entities.LOCATIONS]}`]: {
    id: 'search.locations.select',
    defaultMessage: 'Select Locations',
  },
  [`selected_${[entities.LOCATIONS]}`]: {
    id: 'search.locations.selected',
    defaultMessage: 'Selected Locations',
  },
  [`select_${[entities.ORGANIZATIONS]}`]: {
    id: 'search.organizations.select',
    defaultMessage: 'Select Organizations',
  },
  [`selected_${[entities.ORGANIZATIONS]}`]: {
    id: 'search.organizations.selected',
    defaultMessage: 'Selected Organizations',
  },
  [`select_${[entities.TAGS]}`]: {
    id: 'search.tags.select',
    defaultMessage: 'Select Tags',
  },
  [`selected_${[entities.TAGS]}`]: {
    id: 'search.tags.selected',
    defaultMessage: 'Selected Tags',
  },
  [`select_${[entities.DATATRONS]}`]: {
    id: 'search.datatrons.select',
    defaultMessage: 'Select DATATRONs',
  },
  [`selected_${[entities.DATATRONS]}`]: {
    id: 'search.datatrons.selected',
    defaultMessage: 'Selected DATATRONs',
  },
});
