import React from 'react';

import InventoryLocation from './InventoryLocation';
import { InventoryListEmptyPlaceholder } from './InventoryListEmptyPlaceholder';
import { ErrorPlaceholder } from './ErrorPlaceholder';
import Loader from '../../_tools/loader/Loader';
import {
  inventoryLoading,
  isInventoryEmpty,
  selectInventoryError,
  selectInventoryLocations,
} from '../../../redux/selectors/inventory.selector';
import { useSelector } from 'react-redux';
import { selectLocationFilter } from '../../../redux/selectors/location.selector';

type LocationType = {
  id: string;
  name: string;
  path: string[];
};

export const InventoryList: React.FC = () => {
  const locations = useSelector(selectInventoryLocations);
  const isLoading = useSelector(inventoryLoading);
  const error = useSelector(selectInventoryError);

  const locationFilter = useSelector(selectLocationFilter);

  const filteredLocations = locations.filter(
    (loc: LocationType) =>
      !locationFilter || locationFilter === loc.name || loc.path.indexOf(locationFilter) > -1,
  );

  const isEmpty = useSelector(isInventoryEmpty) || filteredLocations.length === 0;

  const emptyPlaceholder = !!error ? <ErrorPlaceholder /> : <InventoryListEmptyPlaceholder />;
  return (
    <div>
      <div className='inventory-blocks'>
        {filteredLocations.map((location) => (
          <InventoryLocation location={location} key={location.id} />
        ))}
      </div>
      <Loader show={isLoading} />
      {isEmpty && emptyPlaceholder}
    </div>
  );
};

export default InventoryList;
