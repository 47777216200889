let locale;

try {
  locale = localStorage.getItem('locale') || 'en';
} catch (err) {
  locale = 'en';
}

export type IntlState = {
  locale: string;
  messages: Record<string, unknown>;
};

export const intlState: IntlState = {
  locale,
  messages: {},
};
