import { connect } from 'react-redux';

import ItemFiltersContainer from './ItemFilters.container';
import { LOCATIONS } from '../../../../../common/constants/entities';
import messages from './ItemFilters.messages';

export const mapStateToProps = () => ({
  itemType: LOCATIONS,
  label: messages.dashboard_filter_locationCount,
});

export default connect(mapStateToProps)(ItemFiltersContainer);
