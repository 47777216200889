import React from 'react';
import { FormattedMessage } from 'react-intl';

function ScheduledHeader() {
  return (
    <div className="subheader">
      <div className="subheader-in">
        <div className="subheader--left alert-info">
          <ul className="menu tab-menu">
            <li>
              <span className="icon-calendar" />
              <FormattedMessage
                id="maintenance.calendar.scheduled"
                defaultMessage="Scheduled"
                tagName="strong"
              />
            </li>
          </ul>
        </div>
        <div className="subheader--right alert-info">
          <ul className="menu tab-menu">
            <li className="calendar-weekly-select active">
              <FormattedMessage
                id="maintenance.calendar.weekly"
                defaultMessage="Weekly"
                tagName="a"
              />
            </li>
            <li className="calendar-monthly-select">
              <FormattedMessage
                id="maintenance.calendar.monthly"
                defaultMessage="Monthly"
                tagName="a"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ScheduledHeader;
