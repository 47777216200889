import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import * as newAssistanceForm from '../../../../../redux/constants/machine.newAssistance.form.constants';
import { NEW_ASSISTANT_FORM_NAME } from '../../../../../redux/constants/machine.newAssistance.form.constants';
import Modal from '../../../../_tools/modal/Modal';
import NewAssistantSearchContainer from './NewAssistantSearch.container';
import NewAssistantComment from './NewAssistantComment';
import { Todo } from '../../../../../../../common/types/common';
import { ASSISTANT_ADD_NEW } from '../../../../../redux/constants/modals.constants';
import * as inventoryTypes from '../../../../../../../common/constants/inventory';
import * as machineNewAssistantAddModule from '../../../../../redux/modules/machine.newAssistant.add.module';
import * as datatronNewAssistantAddModule from '../../../../../redux/modules/datatron.newAssistant.add.module';
import { closeModal } from '../../../../../redux/modules/modals.module';
import { useDispatch, useSelector } from 'react-redux';
import { getModalPayload } from '../../../../../redux/selectors/modals.selector';

interface Props {
  submitting: boolean,
  target: Todo,
  inventoryType: string,
}

const getAssistantType = (inventoryType: string) => {
  switch (inventoryType) {
    case inventoryTypes.INVENTORY_MACHINES: {
      return machineNewAssistantAddModule.addNewAssistant;
    }
    case inventoryTypes.INVENTORY_DATATRONS: {
      return datatronNewAssistantAddModule.addNewAssistant;
    }
    default:
      return () => {
      };
  }
};

export const NewAssistantModal: React.FC<Props> = ({
  inventoryType,
}) => {
  const { submitting, target } = useSelector((state) => getModalPayload(state, ASSISTANT_ADD_NEW));

  const dispatch = useDispatch();

  const close = () => dispatch(closeModal(ASSISTANT_ADD_NEW));
  const addNewAssistant = (id) => dispatch(getAssistantType(inventoryType)(id));

  const handleSubmit = (e) => {
    e.preventDefault();
    addNewAssistant(target.id);
  };
  const { formatMessage } = useIntl();

  const modalLabel = formatMessage({
    id: 'machine.assistant.new',
    defaultMessage: 'New Assistant',
  });

  return (
    <Modal
      isOpen
      onClose={close}
      contentLabel={modalLabel}
    >
      <div className="modal--header">
        <span className="modal--close" onClick={close}>&times;</span>
      </div>
      <div className="modal--content">
        <FormattedMessage
          id="assistance.add_new_assistant"
          defaultMessage="Add new Assistant"
          tagName="h3"
        />
        <form className="form" onSubmit={handleSubmit}>
          <div className="like-label">
            <FormattedMessage
              id="assistance.select_assistant"
              defaultMessage="Select Assistant"
            />
            <div className="search-block search-field-style">
              <div className="input-group">
                <Field
                  name={newAssistanceForm.SELECTED_ASSISTANT_NAME}
                  component={NewAssistantSearchContainer}
                  inventoryType={inventoryType}
                  organizationId={target.organization.id}
                />
                <div className="input-group-button">
                  <button
                    type="submit"
                    className="button__search"
                  >
                    <span className="icon icon-search"/>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Field
            name={newAssistanceForm.ASSISTANT_COMMENT_NAME}
            component={NewAssistantComment}
          />

          <div className="line"/>

          <div className="text-center">
            <button type="button" className="button__cancel" onClick={close}>
              <FormattedMessage
                id="common.cancel"
                defaultMessage="Cancel"
              />
            </button>
            <button type="submit" className="button__save" disabled={submitting}>
              <FormattedMessage
                id="assistance.add_assistant"
                defaultMessage="Add Assistant"
              />
            </button>
          </div>
        </form>
      </div>

    </Modal>
  );
};

const form = {
  form: NEW_ASSISTANT_FORM_NAME,
  destroyOnUnmount: true,
};

// @ts-ignore
export default reduxForm(form)(NewAssistantModal);
