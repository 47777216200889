import { createAction } from '@reduxjs/toolkit';
import { Todo } from '../../../../common/types/common';

export const openModal = createAction(
  'open modal',
  (id, payload: Todo = {}, path: Todo[] = []) => ({ payload: { id, payload, path } }),
);

export const closeModal = createAction<Todo>('close modal');

export const setModalPath = createAction(
  'set modal path',
  (id, path = []) => ({ payload: { id, path } }),
);

export const goBackModalPath = createAction<Todo>('go back in modal path');

export const reducer = {
  [openModal.type]: (state, { id, payload, path }) => ({
    ...state,
    modals: {
      ...state.modals,
      visible: {
        ...state.modals.visible,
        [id]: true,
      },
      payloads: {
        ...state.modals.payloads,
        [id]: payload,
      },
      paths: {
        ...state.modals.paths,
        [id]: path,
      },
    },
  }),
  [closeModal.type]: (state, id) => ({
    ...state,
    modals: {
      ...state.modals,
      visible: {
        ...state.modals.visible,
        [id]: false,
      },
      payloads: {
        ...state.modals.payloads,
        [id]: null,
      },
      paths: {
        ...state.modals.paths,
        [id]: null,
      },
    },
  }),
  [setModalPath.type]: (state, { id, path }) => ({
    ...state,
    modals: {
      ...state.modals,
      paths: {
        ...state.modals.paths,
        [id]: path,
      },
    },
  }),
  [goBackModalPath.type]: (state, id) => {
    const newPath = [...state.modals.paths[id]];
    newPath.pop();
    return {
      ...state,
      modals: {
        ...state.modals,
        paths: {
          ...state.modals.paths,
          [id]: newPath,
        },
      },
    };
  },
};
