import { createAction } from 'redux-act';

export const selectReportsTab = createAction(
  'select reports tab',
  (id) => id,
);

export const reducer = {
  [selectReportsTab]: (state, id) => ({
    ...state,
    reports: {
      ...state.reports,
      tabs: {
        ...state.reports.tabs,
        selectedTabId: id,
      },
    },
  }),
};
