import React from 'react';

function Archive() {
  return (
    <div className="col--3 maintenances-wrap">
      <div className="col--3--center">

        <div className="subheader">
          <div className="subheader-in">
            <div className="subheader--left alert-info">
              <ul className="menu tab-menu">
                <li>
                  <button className="close-archive">
                    <span className="icon-close color-link" />
                    <span>Close Archive</span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="subheader--right alert-info">
              <ul className="menu tab-menu">
                <li>
                  <a>
                    <span className="icon icon-archive color-link" />
                    <strong>Archived (32)</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="archives--wrap alert-info">
          <div className="maintenance--col--in">

            <div className="maintenance--top">
              <div className="maintenance-title">
                Archive (25)
              </div>

              <div className="pull-right">
                <div className="filter--block">
                  <button className="filter--btn" type="button">
                    <span className="icon icon-filter" />
                    <span>Filter (0)</span>
                  </button>
                </div>
              </div>

              <div className="search-inline">
                <div className="search-block">
                  <div className="input-group">
                    <div className="input-group-button">
                      <button type="submit" className="button__search">
                        <span className="icon icon-search" />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="input-group-field search-input"
                      placeholder="Search here..."
                      value=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="archives--cols">
              <div className="archives">

                <div className="archives--col alert-info">

                  <div className="maintenance--item anomaly">
                    <a className="more-less">
                      <span className="icon icon-caret-right" />
                      <span className="icon icon-caret-down" />
                    </a>
                    <div className="maintenance--item--top">
                      <a href="" className="wrench">
                        <span className="icon-wrench" />
                      </a>
                      <a className="maintenance--item--title">
                        Disk array OXO2 latency warning
                      </a>
                      <div className="maintenance--item--top--row">
                        <div className="maintenance--item--top--left">
                          <div className="maintenance-machine">
                            <span className="icon icon-cog" />
                            <a href="">Machine 119</a>
                          </div>
                        </div>
                        <div className="maintenance--item--top--right">
                          <div className="maintenance-location">
                            <span className="icon icon-location" />
                            Line 605
                          </div>
                        </div>
                      </div>
                      <div className="maintenance--item--top--row">
                        <div className="maintenance--item--top--left">
                          <div className="maintenance-date">
                            <span className="icon icon-calendar" />
                            <span>3/1/2013</span>
                          </div>
                        </div>
                        <div className="maintenance--item--top--right">
                          <div className="maintenance--item--time">
                            <span className="icon icon-time-filled" />
                            <span>1:10 AM</span>
                          </div>
                        </div>
                      </div>
                      <div className="maintenance--text--sm">
                        <div>
                          002313 ATA - SAX - Backend Returned Error
                          002313 ATA - SAX - Backend Returned002313
                          ATA - SAX - Backend Returned Error
                        </div>
                      </div>
                      <div className="maintenance--text">
                        <p>
                          002313 ATA - SAX - Backend Returned
                          Error 002313 ATA - SAX - Backend
                          Returned002313 ATA - SAX -
                          Backend Returned Error
                        </p>
                        <p>
                          <img src="/img/text001.png" alt="" />
                        </p>
                        <div className="feedbacks">
                          <div className="feedback-write">
                            <div className="feedback--item yours">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                <span>You</span>
                              </div>
                              <form className="feedback-comment--form">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="commentField"
                                    value=""
                                    className="input-group-field"
                                    placeholder="Type comment here..."
                                  />
                                  <div className="input-group-button">
                                    <button
                                      className="button__submit"
                                      type="submit"
                                      disabled=""
                                    >
                                      <span>Submit</span>
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="feedback--item">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                Mike Brown
                              </div>
                              <div className="feedback-comment">
                                All users on MySpace will know that
                                there are millions of people
                                out there.
                              </div>
                            </div>
                            <div className="feedback--item">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                Mike Brown
                              </div>
                              <div className="feedback-comment">
                                All users on MySpace will
                                know that there are
                                millions of people out there.
                              </div>
                            </div>
                            <div className="feedback--item">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                Mike Brown
                              </div>
                              <div className="feedback-comment">
                                All users on MySpace will know
                                that there are millions
                                of people out there.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="maintenance--item--bottom">
                      <ul>
                        <li>
                          <a>
                            <span className="icon icon-comment" />
                            3
                          </a>
                        </li>
                        <li className="width-lg">
                          <a>
                            <span className="icon icon-person" />
                            <span>&nbsp;</span>
                            <span>Unassigned</span>
                          </a>
                        </li>
                        <div className="filter--block filter--block--sm">
                          <button className="restore--btn" type="button">
                            <span className="icon-return" />
                          </button>
                        </div>
                      </ul>
                    </div>
                  </div>

                </div>
                <div className="archives--col alert-info">

                  <div className="maintenance--item anomaly">
                    <a className="more-less">
                      <span className="icon icon-caret-right" />
                      <span className="icon icon-caret-down" />
                    </a>
                    <div className="maintenance--item--top">
                      <a href="" className="wrench">
                        <span className="icon-wrench" />
                      </a>
                      <a className="maintenance--item--title">
                        Disk array OXO2 latency warning
                      </a>
                      <div className="maintenance--item--top--row">
                        <div className="maintenance--item--top--left">
                          <div className="maintenance-machine">
                            <span className="icon icon-cog" />
                            <a href="">Machine 119</a>
                          </div>
                        </div>
                        <div className="maintenance--item--top--right">
                          <div className="maintenance-location">
                            <span className="icon icon-location" />
                            Line 605
                          </div>
                        </div>
                      </div>
                      <div className="maintenance--item--top--row">
                        <div className="maintenance--item--top--left">
                          <div className="maintenance-date">
                            <span className="icon icon-calendar" />
                            <span>3/1/2013</span>
                          </div>
                        </div>
                        <div className="maintenance--item--top--right">
                          <div className="maintenance--item--time">
                            <span className="icon icon-time-filled" />
                            <span>1:10 AM</span>
                          </div>
                        </div>
                      </div>
                      <div className="maintenance--text--sm">
                        <div>
                          002313 ATA - SAX - Backend Returned Error
                          002313 ATA - SAX - Backend Returned002313
                          ATA - SAX - Backend Returned Error
                        </div>
                      </div>
                      <div className="maintenance--text">
                        <p>
                          002313 ATA - SAX - Backend Returned
                          Error 002313 ATA - SAX - Backend
                          Returned002313 ATA - SAX -
                          Backend Returned Error
                        </p>
                        <p>
                          <img src="/img/text001.png" alt="" />
                        </p>
                        <div className="feedbacks">
                          <div className="feedback-write">
                            <div className="feedback--item yours">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                <span>You</span>
                              </div>
                              <form className="feedback-comment--form">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="commentField"
                                    value=""
                                    className="input-group-field"
                                    placeholder="Type comment here..."
                                  />
                                  <div className="input-group-button">
                                    <button
                                      className="button__submit"
                                      type="submit"
                                      disabled=""
                                    >
                                      <span>Submit</span>
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="feedback--item">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                Mike Brown
                              </div>
                              <div className="feedback-comment">
                                All users on MySpace will know that
                                there are millions of people
                                out there.
                              </div>
                            </div>
                            <div className="feedback--item">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                Mike Brown
                              </div>
                              <div className="feedback-comment">
                                All users on MySpace will
                                know that there are
                                millions of people out there.
                              </div>
                            </div>
                            <div className="feedback--item">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                Mike Brown
                              </div>
                              <div className="feedback-comment">
                                All users on MySpace will know
                                that there are millions
                                of people out there.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="maintenance--item--bottom">
                      <ul>
                        <li>
                          <a>
                            <span className="icon icon-comment" />
                            3
                          </a>
                        </li>
                        <li className="width-lg">
                          <a>
                            <span className="icon icon-person" />
                            <span>&nbsp;</span>
                            <span>Unassigned</span>
                          </a>
                        </li>
                        <div className="filter--block filter--block--sm">
                          <button className="restore--btn" type="button">
                            <span className="icon-return" />
                          </button>
                        </div>
                      </ul>
                    </div>
                  </div>

                </div>
                <div className="archives--col alert-info">

                  <div className="maintenance--item anomaly">
                    <a className="more-less">
                      <span className="icon icon-caret-right" />
                      <span className="icon icon-caret-down" />
                    </a>
                    <div className="maintenance--item--top">
                      <a href="" className="wrench">
                        <span className="icon-wrench" />
                      </a>
                      <a className="maintenance--item--title">
                        Disk array OXO2 latency warning
                      </a>
                      <div className="maintenance--item--top--row">
                        <div className="maintenance--item--top--left">
                          <div className="maintenance-machine">
                            <span className="icon icon-cog" />
                            <a href="">Machine 119</a>
                          </div>
                        </div>
                        <div className="maintenance--item--top--right">
                          <div className="maintenance-location">
                            <span className="icon icon-location" />
                            Line 605
                          </div>
                        </div>
                      </div>
                      <div className="maintenance--item--top--row">
                        <div className="maintenance--item--top--left">
                          <div className="maintenance-date">
                            <span className="icon icon-calendar" />
                            <span>3/1/2013</span>
                          </div>
                        </div>
                        <div className="maintenance--item--top--right">
                          <div className="maintenance--item--time">
                            <span className="icon icon-time-filled" />
                            <span>1:10 AM</span>
                          </div>
                        </div>
                      </div>
                      <div className="maintenance--text--sm">
                        <div>
                          002313 ATA - SAX - Backend Returned Error
                          002313 ATA - SAX - Backend Returned002313
                          ATA - SAX - Backend Returned Error
                        </div>
                      </div>
                      <div className="maintenance--text">
                        <p>
                          002313 ATA - SAX - Backend Returned
                          Error 002313 ATA - SAX - Backend
                          Returned002313 ATA - SAX -
                          Backend Returned Error
                        </p>
                        <p>
                          <img src="/img/text001.png" alt="" />
                        </p>
                        <div className="feedbacks">
                          <div className="feedback-write">
                            <div className="feedback--item yours">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                <span>You</span>
                              </div>
                              <form className="feedback-comment--form">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="commentField"
                                    value=""
                                    className="input-group-field"
                                    placeholder="Type comment here..."
                                  />
                                  <div className="input-group-button">
                                    <button
                                      className="button__submit"
                                      type="submit"
                                      disabled=""
                                    >
                                      <span>Submit</span>
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="feedback--item">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                Mike Brown
                              </div>
                              <div className="feedback-comment">
                                All users on MySpace will know that
                                there are millions of people
                                out there.
                              </div>
                            </div>
                            <div className="feedback--item">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                Mike Brown
                              </div>
                              <div className="feedback-comment">
                                All users on MySpace will
                                know that there are
                                millions of people out there.
                              </div>
                            </div>
                            <div className="feedback--item">
                              <div className="feedback-person">
                                <span className="icon icon-comment" />
                                Mike Brown
                              </div>
                              <div className="feedback-comment">
                                All users on MySpace will know
                                that there are millions
                                of people out there.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="maintenance--item--bottom">
                      <ul>
                        <li>
                          <a>
                            <span className="icon icon-comment" />
                            3
                          </a>
                        </li>
                        <li className="width-lg">
                          <a>
                            <span className="icon icon-person" />
                            <span>&nbsp;</span>
                            <span>Unassigned</span>
                          </a>
                        </li>
                        <div className="filter--block filter--block--sm">
                          <button className="restore--btn" type="button">
                            <span className="icon-return" />
                          </button>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="col--3--right">

        <div className="subheader">
          <div className="subheader-in">
            <div className="subheader--left alert-info">
              <ul className="menu tab-menu">
                <li className="passive">
                  <span className="icon-calendar" />
                  <strong>Sheduled</strong>
                </li>
              </ul>
            </div>
            <div className="subheader--right alert-info">
              <ul className="menu tab-menu">
                <li className="calendar-weekly-select active">
                  <a>Weekly</a>
                </li>
                <li className="calendar-monthly-select">
                  <a>Monthly</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="scroll">
          <div className="history">
            <ul>
              <li>
                <span className="history-square success" />
                Planned
              </li>
              <li>
                <span className="history-square alert" />
                Over Capacity
              </li>
            </ul>
          </div>

          <div className="calendar alert-info">
            <div className="calendar--item">
              <div className="calendar--item--left">
                <strong className="week-name">Week 37</strong>
                <p>12.11.16/19.11.16</p>
                <div className="calendar-users">
                  <span className="icon icon-person" />
                  {' '}
                  12
                </div>
              </div>
              <div className="calendar--item--right">
                <img src="/img/calendar.png" alt="" />
              </div>
            </div>

            <div className="calendar--item">
              <div className="calendar--item--left">
                <strong className="week-name">Week 37</strong>
                <p>12.11.16/19.11.16</p>
                <div className="calendar-users">
                  <span className="icon icon-person" />
                  {' '}
                  12
                </div>
              </div>
              <div className="calendar--item--right">
                <img src="/img/calendar.png" alt="" />
              </div>
            </div>

            <div className="calendar--item">
              <div className="calendar--item--left">
                <strong className="week-name">Week 37</strong>
                <p>12.11.16/19.11.16</p>
                <div className="calendar-users">
                  <span className="icon icon-person" />
                  {' '}
                  12
                </div>
              </div>
              <div className="calendar--item--right">
                <img src="/img/calendar.png" alt="" />
              </div>
            </div>

            <div className="calendar--item">
              <div className="calendar--item--left">
                <strong className="week-name">Week 37</strong>
                <p>12.11.16/19.11.16</p>
                <div className="calendar-users">
                  <span className="icon icon-person" />
                  {' '}
                  12
                </div>
              </div>
              <div className="calendar--item--right">
                <img src="/img/calendar.png" alt="" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

Archive.propTypes = {
};

export default Archive;
