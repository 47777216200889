import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { injectIntl } from 'react-intl';

export function UnitSelect({
  options, intl, change, value,
}) {
  const optionsLocalized = options.map((option) => ({
    label: intl.formatMessage(option.label, { count: -1 }),
    value: option.value,
  }));

  const onSelectChange = (option) => change(option.value);

  return (
    <Select
      value={value}
      isClearable={false}
      onChange={onSelectChange}
      options={optionsLocalized}
    />
  );
}

UnitSelect.propTypes = {
  options: PropTypes.array.isRequired,

  value: PropTypes.string,
  change: PropTypes.func.isRequired,
};

export default injectIntl(UnitSelect);
