import { connect } from 'react-redux';

import Commenting from './Commenting';
import * as module from '../../../../../redux/modules/maintenance.expanded.module.js';

export const mapDispatchToProps = {
  expandCollapse: module.collapseExpand,
};

export default connect(null, mapDispatchToProps)(Commenting);
