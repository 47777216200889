import React from 'react';
import PropTypes from 'prop-types';
import { TransitionMotion, spring } from 'react-motion';

import EventContainer from './event/Event.container';

function EventsMotion({ events }) {
  function willEnter() {
    return {
      opacity: 0,
    };
  }

  function getStyles() {
    return events.map((event) => ({
      data: event,
      key: event.id,
      style: {
        opacity: spring(1),
      },
    }));
  }

  return (
    <div className="positions">
      <button className="button__positions-more hide">20 new Events</button>
      <TransitionMotion
        willEnter={willEnter}
        styles={getStyles()}
      >
        {(interpolatedStyles) => (
          <div>
            {
              interpolatedStyles.map(({ key, style, data }) => (
                <div
                  key={key}
                  style={{ ...style, overflow: 'hidden' }}
                >
                  <EventContainer
                    event={data}
                  />
                </div>
              ))
            }
          </div>
        )}
      </TransitionMotion>
    </div>
  );
}

EventsMotion.propTypes = {
  events: PropTypes.array.isRequired,
};

export default EventsMotion;
