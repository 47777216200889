import { connect } from 'react-redux';

import Select from './Select';
import * as selector from '../../../../redux/selectors/search.selector';
import { search } from '../../../../redux/modules/search.module';
import { ORGANIZATIONS } from '../../../../../../common/constants/entities';
import { initializeOrganizationAsOption } from '../../../../core/common/organization';

export const mapStateToProps = (state, { input }) => ({
  options: selector
    .getOrganizationSearchResults(state)
    .map(initializeOrganizationAsOption),
  onChange: (option) => input.onChange(option),
});

export const mapDispatchToProps = (dispatch) => ({
  onInputChange: (query) => {
    dispatch(search(query, true, [ORGANIZATIONS]));
    return undefined;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);
