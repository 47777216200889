import { createAction } from 'redux-act';

import * as entryTypes from '../constants/configRepoExplorer.entry.types.constants';

export const selectEntries = createAction(
  'select config repo explorer entries',
  // eslint-disable-next-line
  (entryType = entryTypes.DATATRONS, selected) => ({ entryType, selected }),
);

export const reducer = {
  [selectEntries]: (state, { entryType, selected }) => ({
    ...state,
    configRepoExplorer: {
      ...state.configRepoExplorer,
      [entryType]: {
        ...state.configRepoExplorer[entryType],
        selected,
      },
    },
  }),
};
