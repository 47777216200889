import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UnsubscribeDataPointModal from './UnsubscribeDataPointModal';
import { SMP_UNSUBSCRIBE_DATA_POINT } from '../../../../../redux/constants/modals.constants';
import { closeModal } from '../../../../../redux/modules/modals.module';
import { isModalOpen, getModalPayload } from '../../../../../redux/selectors/modals.selector';
import { unsubscribeMachine } from '../../../../../redux/modules/machine.dataPoints.unsubscribe.module';

export const mapStateToProps = (state) => ({
  isOpen: isModalOpen(state, SMP_UNSUBSCRIBE_DATA_POINT),
  ...getModalPayload(state, SMP_UNSUBSCRIBE_DATA_POINT),
});

export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(SMP_UNSUBSCRIBE_DATA_POINT)),
  unsubscribe: bindActionCreators(unsubscribeMachine, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribeDataPointModal);
