import { createAction } from 'redux-act';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import * as aiAppsApi from '../../core/api/aiApps';
import { initialState } from '../state/app.state';
import { getFilters, getPage, getSort } from '../selectors/aiApps.selector';

export const fetchAiApps = createAction('fetch aiApps');

export const fetchAiAppsSuccess = createAction('fetch aiApps - success', (aiApps) => aiApps);

export const fetchAiAppsFail = createAction('fetch aiApps - failed', (error) => error);

export const updateAiAppsFilters = createAction(
  'update aiApps filters',
  (queryString: string) => queryString,
);

export const updateAiAppsSort = createAction(
  'update aiApps sort',
  (sort: { sortBy: string; sortOrder: 'DESC' | 'ASC' }) => sort,
);

export const updateAiAppsPage = createAction('update aiApps page', (page: number) => page);

export const reducer = {
  [fetchAiApps.getType()]: (state) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: true,
      error: null,
    },
  }),

  [fetchAiAppsSuccess.getType()]: (state, aiApps) => {
    const { list, page, totalPages, total, limit } = aiApps;
    return {
      ...state,
      aiApps: {
        ...state.aiApps,
        list,
        pagination: {
          page,
          totalPages,
          total,
          limit,
        },
        loaded: true,
        loading: false,
        error: null,
      },
    };
  },

  [fetchAiAppsFail.getType()]: (state, error) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      loaded: false,
      loading: false,
      error,
    },
  }),

  [updateAiAppsFilters.getType()]: (state, queryString) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      filters: {
        queryString,
      },
      sort: initialState.aiApps.sort,
      pagination: {
        ...state.aiApps.pagination,
        page: initialState.aiApps.pagination.page,
      },
    },
  }),

  [updateAiAppsSort.getType()]: (state, sort) => ({
    ...state,
    aiApps: {
      ...state.aiApps,
      sort,
    },
  }),

  [updateAiAppsPage.getType()]: (state, page) => ({
    ...state,
    aiApps: {
      ...state.aiApps,

      pagination: {
        ...state.aiApps.pagination,
        page,
      },
    },
  }),
};

export function* fetchAiAppsSaga() {
  const state = yield select();
  const { queryString } = yield call(getFilters, state);
  const page = yield call(getPage, state);
  const { sortBy, sortOrder } = yield call(getSort, state);

  const payload = {
    page,
    queryString,
    sortBy,
    sortOrder,
  };

  const { response, error } = yield call(aiAppsApi.fetch, payload);

  if (response) {
    yield put(fetchAiAppsSuccess(response));
  } else {
    yield put(fetchAiAppsFail(error));
  }
}

export function* watchFetchAIApps() {
  yield takeEvery(fetchAiApps, fetchAiAppsSaga);
}
