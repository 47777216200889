import React from 'react';
import { Todo } from '../../../../common/types/common';


interface Props {
  isBusy: boolean
  children?: Todo
}


const Busy: React.FC<Props> = ({ isBusy, children = (<div/>) }) => {
  if (isBusy) {
    return (
      <div className="busy">
        <div className="sk-fading-circle">
          <div className="sk-circle1 sk-circle"/>
          <div className="sk-circle2 sk-circle"/>
          <div className="sk-circle3 sk-circle"/>
          <div className="sk-circle4 sk-circle"/>
          <div className="sk-circle5 sk-circle"/>
          <div className="sk-circle6 sk-circle"/>
          <div className="sk-circle7 sk-circle"/>
          <div className="sk-circle8 sk-circle"/>
          <div className="sk-circle9 sk-circle"/>
          <div className="sk-circle10 sk-circle"/>
          <div className="sk-circle11 sk-circle"/>
          <div className="sk-circle12 sk-circle"/>
        </div>
      </div>
    );
  }

  return children;
};

export default Busy;
