import { connect } from 'react-redux';

import LocationModule from './LocationModule';
import { adminCreateLocation } from '../../../../../redux/modules/admin.location.create.module';

export const mapStateToProps = () => ({
  isAddForm: true,
  loaded: true,
  formType: 'add',
});

export const mapDispatchToProps = {
  adminCreateLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationModule);
