import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ColumnHeader from './header/ColumnHeader';
import CardsTemplate from './cards/Cards.template';
import LoadMoreContainer from './loadMore/LoadMore.container';

export default class Column extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const isOverAndCanDrop = this.props.canDrop && this.props.isOver;
    const nextIsOverAndCanDrop = nextProps.canDrop && nextProps.isOver;
    const leaved = isOverAndCanDrop && !nextIsOverAndCanDrop;

    if (leaved) {
      this.setState({ ...this.state, row: -1, animate: true });
      return;
    }

    if (!nextProps.isDragging) {
      this.setState({ animate: false });
    }
  }

  handleDrag({ row, cardId }) {
    this.setState({ row, cardId, animate: true });
  }

  render() {
    const {
      cards, title, connectDropTarget, type, noCards, canDrop, isOver,
      showLoadMoreButton, entriesAmount,
    } = this.props;

    const className = classNames('maintenance--col--in',
      {
        'maintenance--col--in_is-over': canDrop && isOver,
      });

    const cardCount = entriesAmount || cards.length;

    return connectDropTarget(
      <div className="maintenance--col">
        <div className={className}>
          <ColumnHeader
            title={title}
            cardsCount={cardCount}
            type={type}
          />
          <div className="maintenance--items--wrap">
            <CardsTemplate
              cards={cards}
              row={this.state.row}
              cardId={this.state.cardId}
              animate={this.state.animate && canDrop}
              type={type}
              noCards={noCards}
            />
            { showLoadMoreButton && <LoadMoreContainer /> }
          </div>
        </div>
      </div>,
    );
  }
}

Column.propTypes = {
  cards: PropTypes.array.isRequired,
  title: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  canDrop: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  noCards: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  showLoadMoreButton: PropTypes.bool,
  entriesAmount: PropTypes.number,
};
