import React from 'react';
import PropTypes from 'prop-types';
import { line } from 'd3-shape';

function ThumbChart({
  data, xScale, yScale, getX, getY,
}) {
  const strokePath = line()
    .x((d) => xScale(getX(d)))
    .y((d) => yScale(getY(d)))
    .defined((d) => getY(d) !== null)(data);

  return (
    <g>
      <path
        d={strokePath}
        className="slider__thumb-chart"
      />
    </g>
  );
}

ThumbChart.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  getX: PropTypes.func.isRequired,
  getY: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default ThumbChart;
