import { connect } from 'react-redux';

import ModalsRouter from './ModalsRouter';
import {
  CP_EVENT_REMOVE,
  CP_CALENDAR_REMOVE,
  CP_NEW_CALENDAR,
  CP_CALENDAR_EDIT,
  CP_CALENDAR_CLONE,
  CP_EVENT_EDIT,
} from '../../../redux/constants/modals.constants';
import { isModalOpen } from '../../../redux/selectors/modals.selector';

export const mapStateToProps = (state) => ({
  isNewCalendarModalOpen: isModalOpen(state, CP_NEW_CALENDAR),
  isEditCalendarModalOpen: isModalOpen(state, CP_CALENDAR_EDIT),
  isEventRemoveModalOpen: isModalOpen(state, CP_EVENT_REMOVE),
  isCalendarRemoveModalOpen: isModalOpen(state, CP_CALENDAR_REMOVE),
  isCalendarCloneModalOpen: isModalOpen(state, CP_CALENDAR_CLONE),
  isEventEditModalOpen: isModalOpen(state, CP_EVENT_EDIT),
});

export default connect(mapStateToProps)(ModalsRouter);
