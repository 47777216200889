import React from 'react';
import PropTypes from 'prop-types';

import ColumnEventContainer from './inbox/InboxEvent.container.js';

function InboxCard(props) {
  if (props.canCreateActivities) {
    return props.connectDragSource(
      <div>
        <ColumnEventContainer {...props} />
      </div>,
    );
  }

  return (
    <div>
      <ColumnEventContainer {...props} />
    </div>
  );
}

InboxCard.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  canCreateActivities: PropTypes.bool.isRequired,
};

export default InboxCard;
