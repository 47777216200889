import moment from 'moment';
import { createAction } from 'redux-act';
import { put, call, takeLatest } from 'redux-saga/effects';

import * as api from '../../core/api';
import { mergeObjectInList, replaceObjectInList, compareById } from '../../../../common/helpers/object';
import { getCalendarById } from '../selectors/calendar.selector';
import { closeModal } from './modals.module';
import { CP_EVENT_REMOVE } from '../constants/modals.constants';
import { reloadCalendarEvents } from './calendars.fetch.calendarEvents.module';
import * as calendarEntryAction from '../constants/calendars.entry.constants';
import { resetFilterQueries } from './calendars.query.module';
import { overwriteEventPayload } from '../../core/common/calendar.events';

export const deleteCalendarEvent = createAction(
  'delete calendar event',
  (calendarId, event, date, actionType) => ({
    calendarId, event, date, actionType,
  }),
);

export const deleteCalendarEventSuccess = createAction(
  'delete calendar event - success',
  (calendarId, eventId) => ({ calendarId, eventId }),
);

export const deleteCalendarEventFail = createAction(
  'delete calendar event - fail',
  (calendarId, eventId, error) => ({ calendarId, eventId, error }),
);

export const reducer = {
  [deleteCalendarEvent]: (state, { eventId, calendarId }) => {
    const calendar = getCalendarById(state, calendarId);
    if (!calendar) return state;

    const newCalendar = {
      ...calendar,
      events: {
        ...calendar.events,
        list: mergeObjectInList(
          calendar.events.list,
          {
            id: eventId,
            _delete: {
              loaded: false,
              loading: true,
              error: null,
            },
          },
          compareById,
        ),
      },
    };

    return {
      ...state,
      machineCalendars: {
        ...state.machineCalendars,
        list: replaceObjectInList(
          state.machineCalendars.list,
          newCalendar,
          compareById,
        ),
      },
    };
  },
  [deleteCalendarEventFail]: (state, { calendarId, eventId, error }) => {
    const calendar = getCalendarById(state, calendarId);
    if (!calendar) return state;

    const newCalendar = {
      ...calendar,
      events: {
        ...calendar.events,
        list: mergeObjectInList(
          calendar.events.list,
          {
            id: eventId,
            _delete: {
              loaded: false,
              loading: false,
              error,
            },
          },
          compareById,
        ),
      },
    };

    return {
      ...state,
      machineCalendars: {
        ...state.machineCalendars,
        list: replaceObjectInList(
          state.machineCalendars.list,
          newCalendar,
          compareById,
        ),
      },
    };
  },
};

export function* deleteAllEvents({ calendarId, event }) {
  const eventId = event.id;

  const {
    response, error,
  } = yield call(
    api.calendars.deleteEvent,
    eventId,
  );

  if (response) {
    yield put(deleteCalendarEventSuccess(calendarId, eventId));
    yield put(closeModal(CP_EVENT_REMOVE));
    yield put(reloadCalendarEvents(calendarId));
  } else {
    yield put(deleteCalendarEventFail(calendarId, eventId, error));
  }

  yield put(resetFilterQueries());
}

export function* deleteCurrentEvent({ calendarId, event, date }) {
  const eventId = event.id;

  const {
    response, error,
  } = yield call(
    api.calendars.deleteSingleEventOccurrence,
    eventId,
    date,
  );

  if (response) {
    yield put(deleteCalendarEventSuccess(calendarId, eventId));
    yield put(closeModal(CP_EVENT_REMOVE));
    yield put(reloadCalendarEvents(calendarId));
  } else {
    yield put(deleteCalendarEventFail(calendarId, eventId, error));
  }
}

export function* deleteCurrentAndFutureEvents({ calendarId, event, date }) {
  const eventId = event.id;

  const repeatEnd = moment(date)
    .startOf('day')
    .subtract(1, 'second')
    .toISOString();

  const {
    response, error,
  } = yield call(
    api.calendars.updateEvent,
    eventId,
    { ...event, repeatEnd },
  );

  if (response) {
    yield put(deleteCalendarEventSuccess(calendarId, eventId));
    yield put(closeModal(CP_EVENT_REMOVE));
    yield put(reloadCalendarEvents(calendarId));
  } else {
    yield put(deleteCalendarEventFail(calendarId, eventId, error));
  }
}

export function* unsetEventRepeat({ calendarId, event }) {
  const eventId = event.id;

  const {
    response, error,
  } = yield call(
    api.calendars.updateEvent,
    eventId,
    {
      ...event,
      repeatEnd: null,
      repeatType: null,
    },
  );

  if (response) {
    yield put(deleteCalendarEventSuccess(calendarId, eventId));
    yield put(closeModal(CP_EVENT_REMOVE));
    yield put(reloadCalendarEvents(calendarId));
  } else {
    yield put(deleteCalendarEventFail(calendarId, eventId, error));
  }
}

export function* deleteCalendarEventSaga({ payload }) {
  const newPayload = overwriteEventPayload(payload);

  switch (newPayload.actionType) {
    case calendarEntryAction.NON_RECURRENT:
    case calendarEntryAction.ALL: {
      yield call(deleteAllEvents, newPayload);
      break;
    }
    case calendarEntryAction.CURRENT_AND_FUTURE: {
      yield call(deleteCurrentAndFutureEvents, newPayload);
      break;
    }
    case calendarEntryAction.CURRENT_ONLY: {
      yield call(deleteCurrentEvent, newPayload);
      break;
    }
    case calendarEntryAction.UNSET_REPEAT: {
      yield call(unsetEventRepeat, newPayload);
      break;
    }
    default: break;
  }
}

export function* watchDeleteCalendarEvent() {
  yield takeLatest(deleteCalendarEvent.getType(), deleteCalendarEventSaga);
}
