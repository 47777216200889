import { connect } from 'react-redux';

import CardButtons from './CardButtons';
import PermissionedComponent from '../../../common/permissions/PermissionedComponent.container';
import { MACHINES_PERMISSIONS } from '../../../../../../common/constants/permissions';
import { selectIsAltasigmaIntegrationEnabled } from '../../../../redux/selectors/appConfig.selector';
import { syncMachineToAltasigma } from '../../../../redux/modules/machine.syncToAltasigma.module';

export const mapStateToProps = (state, { permissions }) => ({
  canUpdateMachines: permissions.canUpdateMachines(),
  canSync: permissions.canUpdateMachines() && selectIsAltasigmaIntegrationEnabled(state),
});

export const mapDispatchToProps = {
  syncMachineToAltasigma,
};

export default PermissionedComponent(MACHINES_PERMISSIONS)(connect(mapStateToProps, mapDispatchToProps)(CardButtons));
