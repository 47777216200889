import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Item from './Item';
import Counter from '../../common/counter/Counter';
import { Todo, TodoFunction } from '../../../../../common/types/common';
import { useClickOutside } from '../../../core/common/hooks/useClickOutside';

interface Options {
  id: string,
  label: Todo,
}

interface Props {
  selected: string[],
  options: Options[]
  addFilter: TodoFunction,
  removeFilter: TodoFunction,
}

export const DropdownCheckboxSelect: React.FC<Props> = ({
  selected,
  options,
  addFilter,
  removeFilter,
}) => {
  const [isOpen, isOpenSet] = useState(false);

  const open = () => {
    isOpenSet(true);
  };

  const close = () => {
    isOpenSet(false);
  };

  const handleFilterChange = (id) => {
    if (!selected) {
      addFilter(id);
    } else {
      removeFilter(id);
    }

    close();
  };

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  const styles = {
    main: classNames(
      'filter--block',
      { opened: isOpen },
    ),
    button: classNames(
      'filter--btn',
      { 'no-filter': selected.length === 0 },
    ),
  };

  const clickRef = useClickOutside(close);

  return (
    <div ref={clickRef}>
      <div className={styles.main}>
        <button
          className={styles.button}
          type="button"
          onClick={toggle}
        >
          <span className="icon icon-filter"/>
          <FormattedMessage
            id="tools.filters.state.label"
            defaultMessage="Status"
          />
          <Counter value={selected.length}/>
        </button>
        {isOpen &&
        <div className="filter--dropdown">
          <ul>
            {options.map((option) => (
              <Item
                key={option.id}
                selected={selected.indexOf(option.id) >= 0}
                handleChange={handleFilterChange}
                {...option}
              />
            ))}
          </ul>
        </div>}
      </div>
    </div>
  );
};

export default DropdownCheckboxSelect;
