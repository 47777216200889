import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BasicModal from '../../core/BasicModal';
import ModalLine from '../../core/ModalLine';
import ArchiveModalFooter from '../../core/footers/ArchiveModalFooter';
import messages from '../../../../messages/experimentsPage.messages';

export const ArchiveMeasurementModal = ({
  close,
  archive,
}) => (
  <BasicModal close={close}>
    <div className="modal--content">
      <FormattedMessage {...messages.archiveMeasurementModalText} tagName="h3" />
      <ModalLine />
      <ArchiveModalFooter onCancelClick={close} onArchiveClick={archive} />
    </div>
  </BasicModal>
);

ArchiveMeasurementModal.propTypes = {
  close: PropTypes.func.isRequired,
  archive: PropTypes.func.isRequired,
};

export default ArchiveMeasurementModal;
